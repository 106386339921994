import React, { useState, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { UnitTreeItem } from "../../../globalTypes";
import styles from "../../../styles/treeComponent.module.css";
import { FormControlLabel, Radio } from "@material-ui/core";

interface TreeProps {
  units: Array<UnitTreeItem> | null;
  updateSelectedUnit: Function;
  updateExpandedUnit: Function;
  isAnyUnitSelected: Function;
}

interface TreeNodeProps {
  uniquePrefix: string;
  unit: UnitTreeItem;
  updateSelectedUnit: Function;
  updateExpandedUnit: Function;
  isAnyUnitSelected: Function;
}

const RadioUnitTreeNode = ({
  uniquePrefix,
  unit,
  updateSelectedUnit,
  updateExpandedUnit,
  isAnyUnitSelected,
}: TreeNodeProps) => {
  const hasChild = unit.childUnitTreeItems !== null;

  const handleClick = (event: any) => {
    event.stopPropagation();
    updateExpandedUnit(unit.unitID);
  };

  return (
    <li className={styles.treeLi} hidden={unit.isHidden === true}>
      <div
        className={styles.treeRow}
        id={uniquePrefix + unit.name.replaceAll(" ", "_s_")}
      >
        {hasChild ? (
          <span
            className={styles.treeArrow}
            style={{ marginRight: "7px", marginLeft:'-19px' }}
            onClick={(e) => handleClick(e)}
          >
            {" "}
            {unit.isExpanded ? "⯆" : "⯈"}{" "}
          </span>
        ) : null}

        <FormControlLabel
          value={unit.unitID}
          control={<Radio color="primary" size="small" style={{padding: "5px"}} />}
          label={unit.name}
          className={styles.labelContent}
        />
      </div>
      {hasChild && unit.isExpanded ? (
        <RadioUnitTree
          units={unit.childUnitTreeItems}
          updateSelectedUnit={updateSelectedUnit}
          updateExpandedUnit={updateExpandedUnit}
          isAnyUnitSelected={isAnyUnitSelected}
        />
      ) : null}
    </li>
  );
};

const RadioUnitTree = ({
  units,
  updateSelectedUnit,
  updateExpandedUnit,
  isAnyUnitSelected,
}: TreeProps) => {
  return (
    <ul className={styles.treeUl}>
      {units &&
        units.map((unit) => (
          <RadioUnitTreeNode
            unit={unit}
            key={unit.unitID}
            updateSelectedUnit={updateSelectedUnit}
            updateExpandedUnit={updateExpandedUnit}
            isAnyUnitSelected={isAnyUnitSelected}
            uniquePrefix="chkUnitNewSalt_"
          />
        ))}
    </ul>
  );
};

export default RadioUnitTree;
