import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const CSVFileIcon = () => (
  // <SvgIcon>
  //   <path d="M4,6H2v14c0,1.1,0.9,2,2,2h14v-2H4V6z"/>
  //   <path d="M20,2H8C6.9,2,6,2.9,6,4v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V4C22,2.9,21.1,2,20,2z M9.4,11.9c0.3,0,0.5,0,0.8-0.1
  //       c0.2-0.1,0.5-0.2,0.7-0.3v1.4c-0.5,0.2-1.1,0.4-1.7,0.4c-0.9,0-1.6-0.3-2.1-0.8c-0.5-0.6-0.7-1.4-0.7-2.4c0-0.7,0.1-1.2,0.3-1.7
  //       c0.2-0.5,0.6-0.9,1-1.1c0.4-0.3,0.9-0.4,1.5-0.4c0.6,0,1.2,0.1,1.8,0.4l-0.5,1.3c-0.2-0.1-0.4-0.2-0.7-0.3C9.8,8.2,9.6,8.1,9.3,8.1
  //       C9,8.1,8.7,8.3,8.5,8.6C8.2,9,8.1,9.4,8.1,10C8.1,11.2,8.6,11.9,9.4,11.9z M13.3,8.9c0.1,0.1,0.4,0.3,1,0.5c0.5,0.2,0.9,0.5,1.1,0.8
  //       c0.2,0.3,0.3,0.6,0.3,1.1c0,0.4-0.1,0.7-0.3,1c-0.2,0.3-0.5,0.5-0.8,0.7c-0.4,0.2-0.8,0.3-1.2,0.3c-0.4,0-0.7,0-1-0.1
  //       c-0.3-0.1-0.5-0.2-0.8-0.3v-1.5c0.3,0.2,0.6,0.3,1,0.4c0.3,0.1,0.6,0.1,0.9,0.1c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0.2-0.2,0.2-0.3
  //       c0-0.1,0-0.2-0.1-0.2c0-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.4-0.2-0.8-0.4c-0.4-0.2-0.7-0.4-0.9-0.5c-0.2-0.2-0.3-0.4-0.4-0.6
  //       c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.6,0.2-1,0.6-1.4c0.4-0.3,0.9-0.5,1.6-0.5c0.6,0,1.2,0.2,1.9,0.5l-0.5,1.3c-0.6-0.3-1-0.4-1.4-0.4
  //       c-0.2,0-0.4,0-0.5,0.1c-0.1,0.1-0.1,0.2-0.1,0.3C13.1,8.6,13.2,8.8,13.3,8.9z M19.6,13.2h-1.9l-1.9-6.3h1.8l0.8,3.2
  //       c0.2,0.7,0.3,1.2,0.3,1.5c0-0.2,0.1-0.5,0.1-0.8c0.1-0.3,0.1-0.5,0.1-0.7l0.8-3.2h1.8L19.6,13.2z"/>
  // </SvgIcon>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.4231 0.620117H5.27341C4.96682 0.620117 4.7183 0.868636 4.7183 1.17522V22.8242C4.7183 23.1308 4.96682 23.3793 5.27341 23.3793H21.3714C21.6779 23.3793 21.9265 23.1308 21.9265 22.8242V6.12351C21.9265 5.9763 21.868 5.83511 21.7639 5.731L16.8156 0.782707C16.7115 0.678597 16.5703 0.620117 16.4231 0.620117Z" fill="white"/>
<path d="M17.4851 18.3833H16.6524V17.5506H16.5644C16.1733 18.1891 15.5979 18.5082 14.838 18.5082C14.279 18.5082 13.8414 18.3677 13.5254 18.0867C13.2093 17.8057 13.0513 17.4328 13.0513 16.9678C13.0513 15.9722 13.6687 15.3928 14.9038 15.2296L16.5863 15.0059C16.5863 14.1003 16.2008 13.6476 15.4299 13.6476C14.7539 13.6476 14.1438 13.8662 13.5994 14.3033V13.429C14.151 13.096 14.7868 12.9294 15.5067 12.9294C16.8255 12.9294 17.4851 13.5922 17.4851 14.9174V18.3833ZM16.6524 15.675L15.3135 15.8593C14.9015 15.9172 14.5906 16.0193 14.3811 16.1656C14.1714 16.312 14.0666 16.5714 14.0666 16.9435C14.0666 17.2146 14.1633 17.436 14.3567 17.6076C14.5499 17.7793 14.8074 17.865 15.1291 17.865C15.57 17.865 15.9341 17.7106 16.2214 17.4016C16.5087 17.0926 16.6524 16.7014 16.6524 16.2279V15.675ZM20.0201 17.2731L19.1175 20.0486H18.4574L19.1175 17.2731H20.0201Z" fill="#33C481"/>
<path fillRule="evenodd" clipRule="evenodd" d="M5.27372 0C4.6245 0 4.09821 0.526285 4.09821 1.17551V22.8245C4.09821 23.4737 4.6245 24 5.27372 24H21.3717C22.0209 24 22.5472 23.4737 22.5472 22.8245V6.1238C22.5472 5.81204 22.4233 5.51305 22.2029 5.29259L17.2546 0.344303C17.0341 0.123847 16.7352 0 16.4234 0H5.27372ZM5.33903 22.7592V1.24082H15.7554V5.61601C15.7554 6.26523 16.2816 6.79152 16.9309 6.79152H21.3064V22.7592H5.33903ZM20.7062 5.5507L16.9962 1.84066V5.5507H20.7062Z" fill="#797774"/>
<path d="M1.1102 19.2168H9.99184C10.605 19.2168 11.102 18.7197 11.102 18.1066V9.22495C11.102 8.61181 10.605 8.11475 9.99184 8.11475H1.1102C0.497066 8.11475 0 8.61181 0 9.22495V18.1066C0 18.7197 0.497066 19.2168 1.1102 19.2168Z" fill="#107C41"/>
<path d="M2.48438 16.9966L4.67281 13.6566L2.66842 10.3353H4.28141L5.37562 12.4582C5.47618 12.6595 5.54529 12.8098 5.58298 12.9088H5.59708C5.66972 12.7479 5.74507 12.5914 5.82367 12.4398L6.99322 10.3353H8.47395L6.4178 13.6382L8.52599 16.9966H6.95067L5.68679 14.6647C5.63339 14.5782 5.58298 14.4743 5.53583 14.3534H5.51712C5.49517 14.4279 5.44637 14.5285 5.37102 14.6553L4.06919 16.9966H2.48438Z" fill="white"/>
</svg>

);

export default CSVFileIcon