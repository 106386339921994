import axios from 'axios';
import { addError } from './AddError';

export const getExportedCSVData = async (token: string, userId: number, orgId: number, rowsArray: Array<string>, 
                                            columnCount: number, inputType: string, classificationvalue:string, 
                                            courseStatus: string, dateEffectiveFrom: string , dateEffectiveTo: string, 
                                            includeInactiveUsers : boolean, isEnabledateArchived: boolean, 
                                            selectedCourseName: string, correctDataToBeFixedRows: boolean = false, 
                                            filterFirstName: string = "", filterLastName: string = "", 
                                            filterUserName: string = "", filterEmail: string = "",filterSubject: string = "",
                                            filterBody: string = "",isDateCourseCompletionDateEnabled: boolean = true,
                                            includeInactiveUnits: boolean = false, shortfallUsersOnly: number = 0,
                                            profileName: string = "",profilePeriodName: string = "", profileID: number = -1, 
                                            profilePeriodID: number = -1, unitIDs: string = "", policyIDs: string = "",
                                            acceptanceStatus: string = "", extraInputData: Array<string> = [], 
                                            extraInputColumnCount: number = 0,
                                            isIncompleteWithDetails: boolean = false,isHistoricCourse: boolean = false,
                                            isCourseModulePresentForCPD: boolean = false, isPolicyPresentForCPD: boolean = false, 
                                            isExtCertPresentForCPD: boolean = false, isEventPresentForCPD: boolean = false
                                        ) => {

    try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/userreport/GetExportedCSVData`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        UserID : userId,
        OrgID : orgId,
        InputType : inputType,
        ClassificationValue: classificationvalue,
        FirstNameFilter: filterFirstName,
        LastNameFilter: filterLastName,
        EmailFilter: filterEmail,
        UserNameFilter: filterUserName, 
        SubjectFilter: filterSubject,    
        BodyFilter: filterBody,    
        CourseStatus:courseStatus,
        IsIncompleteWithDetails: isIncompleteWithDetails,
        DateEffectiveFrom:dateEffectiveFrom,
        DateEffectiveTo:dateEffectiveTo,
        IncludeInactiveUsers:includeInactiveUsers,
        IncludeInactiveUnits:includeInactiveUnits,
        IsEnabledateArchived:isEnabledateArchived,
        IsDateCourseCompletionDateEnabled:isDateCourseCompletionDateEnabled,
        SelectedCourseName: selectedCourseName,
        ShortfallUsers: shortfallUsersOnly,
        ProfileID: profileID,
        ProfilePeriodID: profilePeriodID,
        ProfileName: profileName,
        ProfilePeriodName: profilePeriodName,        
        IsHistoricCourse:isHistoricCourse,
        InputData : {
          SortedDataRowCount: rowsArray.length,
          SortedDataColumnCount: columnCount,
          SortedDataArray: rowsArray
        },
        CorrectDataToBeFixedRows: correctDataToBeFixedRows,  
        
        UnitIDs : unitIDs,
        PolicyIDs : policyIDs,
        AcceptanceStatus : acceptanceStatus,
        ExtraInputData : {
                            SummaryTableRowCount: extraInputData.length,
                            SummaryTableColumnCount: extraInputColumnCount,
                            SummaryTableDataArray: extraInputData
                         },
        IsCourseModulePresentForCPD: isCourseModulePresentForCPD,
        IsPolicyPresentForCPD: isPolicyPresentForCPD,
        IsExtCertPresentForCPD: isExtCertPresentForCPD,
        IsEventPresentForCPD: isEventPresentForCPD
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, window.location.href.toLowerCase().replace("altlogin-", ""), "Rest Exception", error.response, "GetEndQuizInfoEx.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, window.location.href.toLowerCase().replace("altlogin-", ""), "Rest Exception", error.request, "GetEndQuizInfoEx.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, window.location.href.toLowerCase().replace("altlogin-", ""), "Rest Exception", error.message, "GetEndQuizInfoEx.ts", 1)
    }
    return null;
  }
};

export default getExportedCSVData;
