import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const DownArrowIcon = () => (
    <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.70996 5.5L0.946822 0.25L10.4731 0.249999L5.70996 5.5Z" fill="white"/>
    </svg>
);

export default DownArrowIcon
