import React, { useContext, useEffect, useState } from 'react'

// Libsimport
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../../contexts/UIContext';
import { LoaderContext } from '../../../contexts/LoaderContext';
import { TranslationsContext } from '../../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';
import CloseWhiteIcon from "@material-ui/icons/Close";
import Modal from '../../../Components/Modal';
import styles from '../../../styles/dashboard.module.css'
import responsivestyles from "../../../styles/responsiveGridLayout.module.css";


//Intro Lib
import { Steps, Hints } from "intro.js-react";
//General Functions
import getColor from '../../../functions/getColor';
//Components
import CustomTooltip from '../../../Components/CustomTooltip';
import LoaderContainer from '../../../Components/LoaderContainer';
//MUI Components
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import BrandedCard from '../../../Components/BrandedCard';
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';
import { MyExternalCertificates, MyTrainingItemsResult, UnitTreeItem } from '../../../globalTypes';
import { Checkbox, CircularProgress, TableContainer, TablePagination, TextField } from '@material-ui/core';

import GetExternalCertificatesAssignedUsers from '../../../API/Administration/ExternalCertificate/GetExternalCertificatesAssignedUsers';
import CloudUploadIcon from "../../../CustomIcons/CloudUploadIcon";
import PDFWhiteIcon from "../../../CustomIcons/PDFWhiteIcon";
import FileInfoIcon from '../../../CustomIcons/Administration/ImportAccounts_FileInfoIcon';

import EditIcon from "../../../CustomIcons/SupportDetailsEditIcon";
import SaveIcon from '../../../CustomIcons/SaveIcon';
import CloseIcon from '../../../CustomIcons/Administration/closeIcon';
import CancelIcon from '../../../CustomIcons/SupportDetailCancelIcon';
import CertificateFileDropBox from '../../MyExternalCertificate/CertificateFileDropBox';
import SaveMyExternalCertificates from '../../../API/Administration/ExternalCertificate/SaveMyExternalCertificates';
import UpArrowIcon from '../../../CustomIcons/UpArrowIcon';
import DownArrowIcon from '../../../CustomIcons/DownArrowIcon';
import GuideIcon from '../../../CustomIcons/GuideIcon';
import UnbrandedCard from '../../../Components/UnbrandedCard';
import ErrorIcon from '../../../CustomIcons/Administration/errorIcon';
import DownloadButtonIcon from '../../../CustomIcons/DownloadButtonIcon';


import JSZip from 'jszip';
// import JSZipUtils from 'jszip-utils';


// @ts-ignore
import * as saveAs from 'file-saver';

// @ts-ignore
import * as JSZipUtils from 'jszip-utils';

const CertificateLists = (props: any) => {
  const { certificateIdToEdit } = props;
  const { isCalledFrom, certificateHasNoDueDates } = props;
  const { translations: { allResourcesToTranslations } } = useContext(TranslationsContext)
  const { color } = useContext(CorperateThemeContext).theme;
  const { setMessage } = useContext(LoaderContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { oidcUser } = useReactOidc();
  const [showConfirmModal, setshowConfirmModal] = useState<boolean>(false);
  const [showPDFModal, setshowPDFModal] = useState<boolean>(false);

  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);

  const orgId = oidcUser.profile.organisation_id ?? -1;
  const requesteduserId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";


  const [selectedFile, setSelectedFile] = useState<string>("");
  const [selectedFileName, setSelectedFileName] = useState<string>("");
  const [selectedFileData, setSelectedFileData] = useState<string>("");
  const [selectedFileSize, setSelectedFileSize] = useState<number>(0);
  const [selectedFileExtension, setSelectedFileExtension] = useState<string>("");
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [hasNoValidDates, setHasNoValidDates] = useState<boolean>(false);

  const [externalCertificatesAssignedUsers, setExternalCertificatesAssignedUsers] = useState<Array<MyExternalCertificates>>([]);


  const [certificateID, setCertificateID] = useState<number>(0);
  const [filterDateFrom, setFilterDateFrom] = useState("");
  const [filterDateTo, setFilterDateTo] = useState("");


  const [organisationID, setOrganisationID] = useState<number>(0);
  const [uploadedCertificateName, setUploadedCertificateName] = useState("");

  //rowshorting
  const [currentSortBy, setCurrentSortBy] = useState<string>("");
  const [reverseNextSort, setReverseNextSort] = useState<boolean>(false);

  const [certificateNameDescending, setCertificateNameDescending] = useState<boolean>(false);
  const [validFromDescending, setValidFromDescending] = useState<boolean>(false);
  const [validToDescending, setValidToDescending] = useState<boolean>(false);
  const [modifiedDateDescending, setModifiedDateDescending] = useState<boolean>(false);
  const [statusDescending, setStatusDescending] = useState<boolean>(false);
  const [modifiedByDescending, setModifiedByDescending] = useState<boolean>(false);
  const [userIDToEdit, setUserIDToEdit] = useState<number>(0);

  const [lastNameDescending, setLastNameDescending] = useState<boolean>(false);
  const [firstNameDescending, setFirstNameDescending] = useState<boolean>(false);
  const [emailDescending, setEmailDescending] = useState<boolean>(false);
  const [saveSuccessuploadFile, setSaveSuccessuploadFile] = React.useState(false);
  const [showWarningModaluploadFile, setShowWarningModaluploadFile] = useState<boolean>(false);
  const [modalPopupMessageuploadFile, setModalPopupMessageuploadFile] = useState('');
  const [hasErroreduploadFile, setHasErroreduploadFile] = useState<boolean>(false);
  const [isRowEdit, setIsRowEdit] = useState<boolean>(false);
  const [downloadcertificateListData, setDownloadcertificateListData] = useState<any>([])
  const [assignAll, setAssignAll] = React.useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { updateBackButtonRoute } = useContext(UIContext);

  //modelpopup
  const handleClosePopup = () => {
    setShowWarningModal(false);
  };
  const handleClosePopupuploadFile = () => {
    setShowWarningModaluploadFile(false);
  };

  useEffect(() => {
    if (showWarningModaluploadFile) {
      setTimeout(() => {
        setShowWarningModaluploadFile(false);
        setSaveSuccessuploadFile(false);
      }, 5000)
    }
  }, [showWarningModaluploadFile])

  useEffect(() => {
    if (showWarningModal) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      }, 5000)
    }
  }, [showWarningModal])

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "#fff" }
    } else {
      return {}
    }
  }

  const handleConfirmModal = (value: boolean) => {
    setshowConfirmModal(value);
  }
  const handleCancelConfirmPopup = () => {
    handleConfirmModal(false);
    setIsSaving(false);
  }

  const handlePDFModal = (value: boolean) => {
    setshowPDFModal(value);
  }
  const handleCancelPDFPopup = () => {
    handlePDFModal(false);
    setIsSaving(false);
  }
  //modelpopup

  //paging

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //paging

  const loadMyExternalCertificate = () => {
    GetExternalCertificatesAssignedUsers(oidcUser.access_token, requesteduserId, certificateIdToEdit, orgDomain, parseInt(orgId), isCalledFrom).then((result: any) => {
      if (result.isSuccess && result.error == '') {
        if (result.externalCertificatesAssignedUsers !== null) {

          let validCertificateList = result.externalCertificatesAssignedUsers;
          setExternalCertificatesAssignedUsers(validCertificateList);
        }
      }
      // else {
      //   setShowWarningModal(true);
      //   setHasErrored(true);
      //   setSaveSuccess(false);
      //   setModalPopupMessage("Can not load My External Certificate!");
      // }
      setLoading(false);
      setPage(0);
    });

  };


  useEffect(() => {
    updateBackButtonRoute("");
    setLoading(true);
    loadMyExternalCertificate();
  }, [isCalledFrom, certificateIdToEdit]);

  const handlePDFpopup = (orgID: number, UploadedCertificateName: string) => {
    handlePDFmModal(true);
    setOrganisationID(orgID);
    setUploadedCertificateName(UploadedCertificateName);
  }
  const handlePDFmModal = (value: boolean) => {
    setshowPDFModal(value);
  }

  const handleEditCertificatepopup = (UECUserId: number, CertificateID: number, hasNovaliddatesBool: boolean) => {
    handleConfirmModalUpload(true);
    setUserIDToEdit(UECUserId);
    setCertificateID(CertificateID);
    setIsRowEdit(true);
    setHasNoValidDates(hasNovaliddatesBool);
    let updatedSelectedcertificateId = externalCertificatesAssignedUsers.map((item: MyExternalCertificates) => {

      if (item.certificateID == CertificateID && item.userID == UECUserId && !hasNovaliddatesBool) {
        setFilterDateFrom(item.validFromDate);
        setFilterDateTo(item.validToDate);

        if (item.validFromDate !== undefined && item.validFromDate !== null) {
          let tempDateSt = item.validFromDate.trim().split("/");
          let val = formatDate(new Date(parseInt(tempDateSt[2]), parseInt(tempDateSt[1]) - 1, parseInt(tempDateSt[0])));
          setFilterDateFrom(val);
        }
        if (item.validToDate !== undefined && item.validToDate !== null) {
          let tempDateEnd = item.validToDate.trim().split("/");
          let valEndDate = formatDate(new Date(parseInt(tempDateEnd[2]), parseInt(tempDateEnd[1]) - 1, parseInt(tempDateEnd[0])));
          setFilterDateTo(valEndDate);
        }
      }
      return item;
    });
    setExternalCertificatesAssignedUsers([...updatedSelectedcertificateId]);

  }
  const handleUploadCertificatepopup = (UECUserId: number, CertificateID: number, hasNovaliddatesBool: boolean) => {
    handleResetFileUpload();
    handleConfirmModalUpload(true);
    setIsRowEdit(false);
    setUserIDToEdit(UECUserId);
    setCertificateID(CertificateID);
    setHasNoValidDates(hasNovaliddatesBool);
  }

  const handleConfirmModalUpload = (value: boolean) => {
    setshowConfirmModal(value);
  }


  const handleFileUpload = (byteArray: string, fileExtension: string, filename: string) => {
    setShowWarningModal(false);
    setModalPopupMessage("");
    setSelectedFile(filename);
    setSelectedFileName(filename);
    setSelectedFileData(byteArray);
    setSelectedFileExtension(fileExtension);
    setIsFileUploading(false);
  }

  const handleSetSelectedFileName = (filename: string) => {
    setSelectedFile(filename);
    setSelectedFileName(filename);
  }

  const handleSetSelectedFileSize = (filesize: number) => {
    setSelectedFileSize(filesize);
  }

  const handleResetFileUpload = () => {
    setSelectedFile("");
    setSelectedFileName("");
    setSelectedFileSize(0);
  };

  const handleResetData = () => {

    setFilterDateFrom('');
    setFilterDateTo('');
    setIsFileUploading(false);
    handleConfirmModal(false);
    handlePDFModal(false);
  }
  const SaveCertificate = () => {
    setIsSaving(true);
    SaveMyExternalCertificates(oidcUser.access_token, requesteduserId, userIDToEdit, orgDomain, parseInt(orgId), filterDateFrom, filterDateTo, certificateID,
      selectedFileName, selectedFileData, selectedFileExtension)
      .then((res) => {
        if (!!res && res.isSuccess) {
          setShowWarningModal(true);
          setHasErrored(false);
          setSaveSuccess(true);
          setModalPopupMessage("Certificate have been saved successful. ");
          setIsSaving(false);
          handleConfirmModalUpload(false);
          handleResetFileUpload();
          handleResetData();
          loadMyExternalCertificate();
        }
        else {
          setIsSaving(false);
          setShowWarningModal(true);
          setHasErrored(true);
          setSaveSuccess(false);
          setModalPopupMessage("Certificate save have been failed.");
        }
      })
  }
  const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
  {
    if (!isNaN(givenDate.getTime())) {
      const year = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(givenDate);
      const month = new Intl.DateTimeFormat('en-AU', { month: 'numeric' }).format(givenDate);
      const day = new Intl.DateTimeFormat('en-AU', { day: 'numeric' }).format(givenDate);

      return (year.toString() + "-" +
        (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" +
        (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
    }
    else {
      return "";
    }
  }



  //sorting

  const sortResultsByAsync = async (
    sortBy: "CertificateName" | "LastName" | "FirstName" | "Email" | "ValidFrom" | "ValidTo" | "ModifiedDate" | "ModifiedByUserName" | "Status",
    sortGivenItemInDesc: boolean
  ) => {
    if (externalCertificatesAssignedUsers == null || externalCertificatesAssignedUsers.length <= 0) {
      return externalCertificatesAssignedUsers;
    }
    var result = externalCertificatesAssignedUsers.slice(0);
    setCurrentSortBy(sortBy);
    switch (sortBy) {

      case "CertificateName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.certificateName === null) {
              return -1;
            }
            if (a === null || a.certificateName === null) {
              return 1;
            }
            if (a.certificateName.toLowerCase() > b.certificateName.toLowerCase()) {
              return -1;
            }
            if (a.certificateName.toLowerCase() < b.certificateName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setCertificateNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.certificateName === null) {
              return 1;
            }
            if (a === null || a.certificateName === null) {
              return -1;
            }
            if (a.certificateName.toLowerCase() < b.certificateName.toLowerCase()) {
              return -1;
            }
            if (a.certificateName.toLowerCase() > b.certificateName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setCertificateNameDescending(true);
          }
        }
        setExternalCertificatesAssignedUsers(result);
        break;
      case "Status":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.status === null) {
              return -1;
            }
            if (a === null || a.status === null) {
              return 1;
            }
            if (a.status.toLowerCase() > b.status.toLowerCase()) {
              return -1;
            }
            if (a.status.toLowerCase() < b.status.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setStatusDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.status === null) {
              return 1;
            }
            if (a === null || a.status === null) {
              return -1;
            }
            if (a.status.toLowerCase() < b.status.toLowerCase()) {
              return -1;
            }
            if (a.status.toLowerCase() > b.status.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setStatusDescending(true);
          }
        }
        setExternalCertificatesAssignedUsers(result);
        break;
      case "ValidFrom":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.validFromDate === null || b.validFromDate.trim() === "") { return -1; }
            if (a === null || a.validFromDate === null || a.validFromDate.trim() === "") { return 1; }
            var dta = new Date(a.validFromDate.split("/").reverse().join("-"))
            var dtb = new Date(b.validFromDate.split("/").reverse().join("-"))
            if (dta > dtb) { return -1; }
            if (dta < dtb) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setValidFromDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.validFromDate === null || b.validFromDate.trim() === "") { return 1; }
            if (a === null || a.validFromDate === null || a.validFromDate.trim() === "") { return -1; }
            var dta = new Date(a.validFromDate.split("/").reverse().join("-"))
            var dtb = new Date(b.validFromDate.split("/").reverse().join("-"))
            if (dta > dtb) { return 1; }
            if (dta < dtb) { return -1; }
            return 0;
          })
          if (reverseNextSort) {
            setValidFromDescending(true)
          }
        }
        setExternalCertificatesAssignedUsers(result);
        break;
      case "ValidTo":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.validToDate === null || b.validToDate.trim() === "") { return -1; }
            if (a === null || a.validToDate === null || a.validToDate.trim() === "") { return 1; }
            var dta = new Date(a.validToDate.split("/").reverse().join("-"))
            var dtb = new Date(b.validToDate.split("/").reverse().join("-"))
            if (dta > dtb) { return -1; }
            if (dta < dtb) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setValidToDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.validToDate === null || b.validToDate.trim() === "") { return 1; }
            if (a === null || a.validToDate === null || a.validToDate.trim() === "") { return -1; }
            var dta = new Date(a.validToDate.split("/").reverse().join("-"))
            var dtb = new Date(b.validToDate.split("/").reverse().join("-"))
            if (dta > dtb) { return 1; }
            if (dta < dtb) { return -1; }
            return 0;
          })
          if (reverseNextSort) {
            setValidToDescending(true)
          }
        }
        setExternalCertificatesAssignedUsers(result);
        break;
      case "ModifiedDate":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.modifiedDate === null || b.modifiedDate.trim() === "") { return -1; }
            if (a === null || a.modifiedDate === null || a.modifiedDate.trim() === "") { return 1; }
            var dta = new Date(a.modifiedDate.split("/").reverse().join("-"))
            var dtb = new Date(b.modifiedDate.split("/").reverse().join("-"))
            if (dta > dtb) { return -1; }
            if (dta < dtb) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setModifiedDateDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.modifiedDate === null || b.modifiedDate.trim() === "") { return 1; }
            if (a === null || a.modifiedDate === null || a.modifiedDate.trim() === "") { return -1; }
            var dta = new Date(a.modifiedDate.split("/").reverse().join("-"))
            var dtb = new Date(b.modifiedDate.split("/").reverse().join("-"))
            if (dta > dtb) { return 1; }
            if (dta < dtb) { return -1; }
            return 0;
          })
          if (reverseNextSort) {
            setModifiedDateDescending(true)
          }
        }
        setExternalCertificatesAssignedUsers(result);
        break;
      case "ModifiedByUserName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.modifiedByUserName === null) {
              return -1;
            }
            if (a === null || a.modifiedByUserName === null) {
              return 1;
            }
            if (a.modifiedByUserName.toLowerCase() > b.modifiedByUserName.toLowerCase()) {
              return -1;
            }
            if (a.modifiedByUserName.toLowerCase() < b.modifiedByUserName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setModifiedByDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.modifiedByUserName === null) {
              return 1;
            }
            if (a === null || a.modifiedByUserName === null) {
              return -1;
            }
            if (a.modifiedByUserName.toLowerCase() < b.modifiedByUserName.toLowerCase()) {
              return -1;
            }
            if (a.modifiedByUserName.toLowerCase() > b.modifiedByUserName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setModifiedByDescending(true);
          }
        }
        setExternalCertificatesAssignedUsers(result);
        break;

      case "LastName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) {
              return -1;
            }
            if (a === null || a.lastName === null) {
              return 1;
            }
            if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
              return -1;
            }
            if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) {
              return 1;
            }
            if (a === null || a.lastName === null) {
              return -1;
            }
            if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
              return -1;
            }
            if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastNameDescending(true);
          }
        }
        setExternalCertificatesAssignedUsers(result);
        break;
      case "FirstName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.firstName === null) {
              return -1;
            }
            if (a === null || a.firstName === null) {
              return 1;
            }
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
              return -1;
            }
            if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.firstName === null) {
              return 1;
            }
            if (a === null || a.firstName === null) {
              return -1;
            }
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
              return 1;
            }
            if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstNameDescending(true);
          }
        }
        setExternalCertificatesAssignedUsers(result);
        break;
      case "Email":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return -1;
            }
            if (a === null || a.email === null) {
              return 1;
            }
            if (a.email.toLowerCase() > b.email.toLowerCase()) {
              return -1;
            }
            if (a.email.toLowerCase() < b.email.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setEmailDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return 1;
            }
            if (a === null || a.email === null) {
              return -1;
            }
            if (a.email.toLowerCase() < b.email.toLowerCase()) {
              return -1;
            }
            if (a.email.toLowerCase() > b.email.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setEmailDescending(true);
          }
        }
        setExternalCertificatesAssignedUsers(result);
        break;


      default:
        break;
    }
    return result.slice(0);
  };

  const sortResultsBy = async (
    sortBy: "CertificateName" | "LastName" | "FirstName" | "Email" | "ValidFrom" | "ValidTo" | "ModifiedDate" | "ModifiedByUserName" | "Status",
    sortGivenItemInDesc: boolean
  ) => {
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy);
      setExternalCertificatesAssignedUsers(result);
      setPage(0);
    });
  };



  const clickFirstNameAsc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(false);
    sortResultsBy("FirstName", false);
  };

  const clickFirstNameDesc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(true);
    sortResultsBy("FirstName", true);
  };

  const clickLastNameAsc = () => {
    setReverseNextSort(false);
    setLastNameDescending(false);
    sortResultsBy("LastName", false);
  };

  const clickLastNameDesc = () => {
    setReverseNextSort(false);
    setLastNameDescending(true);
    sortResultsBy("LastName", true);
  };


  const clickEmailAsc = () => {
    setReverseNextSort(false);
    setEmailDescending(false);
    sortResultsBy("Email", false);
  };

  const clickEmailDesc = () => {
    setReverseNextSort(false);
    setEmailDescending(true);
    sortResultsBy("Email", true);
  };

  const clickCertificateNameAsc = () => {
    setReverseNextSort(false);
    setCertificateNameDescending(false);
    sortResultsBy("CertificateName", false);
  };

  const clickCertificateNameDesc = () => {
    setReverseNextSort(false);
    setCertificateNameDescending(true);
    sortResultsBy("CertificateName", true);
  };

  const clickValidFromAsc = () => {
    setReverseNextSort(false);
    setValidFromDescending(false);
    sortResultsBy("ValidFrom", false);
  };

  const clickValidFromDesc = () => {
    setReverseNextSort(false);
    setValidFromDescending(true);
    sortResultsBy("ValidFrom", true);
  };



  const clickValidToAsc = () => {
    setReverseNextSort(false);
    setValidToDescending(false);
    sortResultsBy("ValidTo", false);
  };

  const clickValidToDesc = () => {
    setReverseNextSort(false);
    setValidToDescending(true);
    sortResultsBy("ValidTo", true);
  };

  const clickStatusAsc = () => {
    setReverseNextSort(false);
    setStatusDescending(false);
    sortResultsBy("Status", false);
  };

  const clickStatusDesc = () => {
    setReverseNextSort(false);
    setStatusDescending(true);
    sortResultsBy("Status", true);
  };

  const clickModifiedDateAsc = () => {
    setReverseNextSort(false);
    setModifiedDateDescending(false);
    sortResultsBy("ModifiedDate", false);
  };

  const clickModifiedDateDesc = () => {
    setReverseNextSort(false);
    setModifiedDateDescending(true);
    sortResultsBy("ModifiedDate", true);
  };



  const clickModifiedByAsc = () => {
    setReverseNextSort(false);
    setModifiedByDescending(false);
    sortResultsBy("ModifiedByUserName", false);
  };

  const clickModifiedByDesc = () => {
    setReverseNextSort(false);
    setModifiedByDescending(true);
    sortResultsBy("ModifiedByUserName", true);
  };
  const GetDate_yymmdd = () => {
    var d = new Date(Date.now());
    let day = d.getFullYear().toString().substring(2, 4) + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0")
    return day;
  }


  const handleAllAssignUnassign = (event: any) => {
    event.stopPropagation();
    setAssignAll(!assignAll);
    let updatedExternalCertificateOptions = [...externalCertificatesAssignedUsers];
    updatedExternalCertificateOptions.map((user: any) => {
      user.granted = !assignAll;
    });
    setDownloadcertificateListData(updatedExternalCertificateOptions);
  }

  const handleAssignUnassign = (event: any, userID: number, certificateId: number) => {
    event.stopPropagation();
    let updatedExternalCertificateOptions = [...externalCertificatesAssignedUsers];
    updatedExternalCertificateOptions.map((user: any) => {
      if (user.userID === userID && user.certificateID == certificateId) {
        user.granted = !user.granted;
      }
    })
    setDownloadcertificateListData(updatedExternalCertificateOptions);
  }

  const exportPdf = () => {
    setIsDownloading(true);
    if (downloadcertificateListData.length != 0) {
      let updatedCertificateDataLocal = [];
      updatedCertificateDataLocal = downloadcertificateListData.filter((item: any) => item.granted);

      if (updatedCertificateDataLocal.length > 1) {
        setShowWarningModal(false);
        setHasErrored(false);


        var adminConsoleUrl = "https://" + orgDomain;
        //var adminConsoleUrl = "http://localhost:3000" // For localhost  

        let urls = [];
        for (let i = 0; i < updatedCertificateDataLocal.length; i++) {
          urls.push(adminConsoleUrl + `/General/ExternalCertificate/${orgId}/` + updatedCertificateDataLocal[i].fileName)
        }

        let zip = new JSZip();
        let count = 0;
        let zipFilename = externalCertificatesAssignedUsers[0].certificateName + "_" + GetDate_yymmdd() + ".zip";

        urls.forEach(async function (url) {
          let urlArr = url.split('/');
          let filename = urlArr[urlArr.length - 1];
          try {
            let file = await JSZipUtils.getBinaryContent(url)
            zip.file(filename, file, { binary: true, createFolders: true });
            count++;
            if (count === urls.length) {
              zip.generateAsync({ type: "blob" }).then(function (content) {
                saveAs(content, zipFilename);
              });
            }
          } catch (err: any) {
            //console.log(err);
            setShowWarningModal(true);
            setHasErrored(true);
            setModalPopupMessage(err.toString());
          }
        });
        setIsDownloading(false);

      }
      else if (updatedCertificateDataLocal.length === 1) {
        var adminConsoleUrl = "https://" + orgDomain;
        // var adminConsoleUrl = "http://localhost:3000" // For localhost  //http://localhost:3000/General/ExternalCertificate/45/test_638501740498118637.pdf


        const pdfUrl = adminConsoleUrl + `/General/ExternalCertificate/${orgId}/` + updatedCertificateDataLocal[0].fileName;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = updatedCertificateDataLocal[0].fileName; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setIsDownloading(false);

      }
      else {
        setShowWarningModal(true);
        setHasErrored(true);
        setModalPopupMessage("Please select Certificate to download!");
      }

    } else {
      setShowWarningModal(true);
      setHasErrored(true);
      setModalPopupMessage("Please select Certificate to download!");
    }
    setIsDownloading(false);
  }


  if (loading) return <LoaderContainer />;
  return (
    <>

      <Modal modalOpen={showConfirmModal} setModalOpen={handleConfirmModal} type="warning">
        <div className={styles.modalContentWrappercertificate}>
          <div className={responsivestyles.rowrule} style={{ width: '600px' }} >
            <div className={`${responsivestyles.columnrule_4} ${responsivestyles.columnrule_s_12}`}>
              <div className={`${styles.leftrightPadding10} ${styles.inputContainer}`}>
                <CustomTooltip displayText={[allResourcesToTranslations.draganddropcustomtooltip]}>
                  <div className="policydocguideme">
                    <CertificateFileDropBox selectedFile={selectedFile} uploadFile={handleFileUpload} setSelectedFile={handleSetSelectedFileName} setSelectedFileSize={handleSetSelectedFileSize} />
                  </div>
                </CustomTooltip>
              </div>
            </div>
            <div className={`${responsivestyles.columnrule_8} ${responsivestyles.columnrule_s_12}`} >
              <div style={{ paddingLeft: "75px" }}>
                <span>Uploading File</span>
                <br></br><br></br>
                {selectedFileName.toString().length > 0 ?
                  <div className={styles.importfileContainer} style={{ padding: "5px", backgroundColor: "#eee" }}>
                    <div style={{ width: "100%", display: "inline-flex" }}>
                      <div style={{ float: "left", width: "90%", display: "flex" }}>
                        <div> <FileInfoIcon></FileInfoIcon></div>
                        <div style={{ paddingLeft: "10px" }}><span>{selectedFileName.toString()}</span>
                        </div>
                      </div>
                      <div style={{ float: "right", width: "10%" }}>
                        <span onClick={handleResetFileUpload} style={{ cursor: "pointer" }}>
                          <CloseIcon />
                        </span>
                      </div>
                    </div>
                    <div><span>Selected File Size: {selectedFileSize.toFixed(0)}  KB</span>
                    </div>
                  </div> :
                  <div className={styles.importfileContainer} style={{ padding: "5px", height: "60px", backgroundColor: "#eee" }}>
                    No File attached
                  </div>
                }
                {!hasNoValidDates ?
                  <>
                    <div className={styles.inputContainer} style={{ paddingTop: "20px" }}>

                      <div className="dateFrom">
                        <TextField className={styles.input} label={allResourcesToTranslations.entervalidfrom} variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                          type="date" defaultValue={filterDateFrom} onChange={(e) => setFilterDateFrom(formatDate(new Date(e.target.value)))} />
                      </div>

                    </div>
                    <div className={styles.inputContainer}>
                      <div className="dateTo">
                        <TextField className={styles.input} label={allResourcesToTranslations.entervalidto} variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                          type="date" defaultValue={filterDateTo} onChange={(e) => setFilterDateTo(formatDate(new Date(e.target.value)))} />
                      </div>
                    </div>
                  </> :
                  <div className={styles.inputContainer} style={{ paddingTop: "70px" }}>&nbsp;</div>
                }
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    top: "10px"
                  }}
                >
                  <div className={styles.loaderContainer}>
                    <CircularProgress
                      style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
                      color="primary"
                      size="10" />
                  </div>
                  <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
                    <span className="saveData">
                      <Button
                        disabled={isRowEdit ? (((filterDateFrom === "" || filterDateTo === "") && !hasNoValidDates) || (filterDateFrom > filterDateTo && !hasNoValidDates) || isSaving)
                          : (selectedFileName.toString().length <= 0 || ((filterDateFrom === "" || filterDateTo === "") && !hasNoValidDates) || (filterDateFrom > filterDateTo && !hasNoValidDates) || isSaving)}

                        onClick={SaveCertificate}
                        variant="contained"
                        color="primary"
                        className="save"
                      >
                        <SaveIcon />
                      </Button>
                    </span>
                  </CustomTooltip>
                  <CustomTooltip displayText={[allResourcesToTranslations.return]}>
                    <span onClick={handleCancelConfirmPopup}>
                      <Button
                        variant="contained"
                        color="primary"
                        className="close" style={{ backgroundColor: "#F2B05C" }}
                      >
                        <CloseWhiteIcon />
                      </Button>
                    </span>
                  </CustomTooltip>
                </div>
              </div>
            </div>
          </div>

        </div>
      </Modal>
      <Modal modalOpen={showPDFModal} setModalOpen={handlePDFModal} type="fileviewer">
        <div>
          <iframe
            src={`/General/ExternalCertificate/${organisationID}/${uploadedCertificateName}#zoom=150`}
            title="viewer"
            style={{ width: "100%", height: "750px" }}
            id="viewer"
          ></iframe>
        </div>
      </Modal>
      <div className={styles.mainWrapper}>
        <div style={{ width: '100%', clear: "both" }} >
          {externalCertificatesAssignedUsers != null && externalCertificatesAssignedUsers.length > 0 && (
            <UnbrandedCard>
              <div className={styles.mainWrapper}>
                <div className={styles.Wrapper}>
                  <div style={{ padding: "20px" }}>
                    <div>
                      <div style={{ display: "flex", marginRight: "-5px", paddingTop: "20px", width: "100%", clear: "both" }}>
                        {isCalledFrom != "notprovidedcertificates" &&
                          <CustomTooltip displayText={[allResourcesToTranslations.downloadcertificates]}>
                            <Button onClick={() => { exportPdf(); }} className={`${styles.moduleListButton} downloadfile`} variant="contained" color="primary">
                              <DownloadButtonIcon />
                            </Button>
                          </CustomTooltip>
                        }
                        <div className={styles.loaderContainer}>
                          <CircularProgress
                            style={{ width: isDownloading ? "20px" : "0px", padding: "15px 10px" }}
                            color="primary"
                            size="10"
                          />
                        </div>
                      </div>
                      <div className={styles.paginationControlsContainer}>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 20, 100]}
                            component="div"
                            count={externalCertificatesAssignedUsers.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage} />
                        </div>
                      </div>
                      <div className={`${styles.tableSection}`}>
                        <div className={styles.tableContainer}>
                          <BrandedCard>
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    {isCalledFrom != "notprovidedcertificates" &&
                                      <TableCell
                                        style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }} align="left">
                                        <div className={`${styles.tableHeadCellContainer} chkselectfile`} style={{ display: "flex", }}>
                                          <div>
                                            <Checkbox
                                              size="small"
                                              color="primary"
                                              checked={assignAll}
                                              className={styles.checkBox}
                                              style={{ color: 'white' }}
                                              onClick={(e: any) => handleAllAssignUnassign(e)}
                                            />
                                            {"Select"}
                                          </div>
                                        </div>
                                      </TableCell>
                                    }
                                    <TableCell
                                      style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                      align="left">
                                      <div className={styles.tableHeadCellContainer} style={{ display: "flex", }}  >
                                        <div
                                          onClick={() => {
                                            setFirstNameDescending(!firstNameDescending);
                                            sortResultsBy("FirstName", !firstNameDescending);
                                          }}
                                        >
                                          {allResourcesToTranslations.firstname}
                                        </div>
                                        <div className={styles.columnHeadArea}>
                                          <span
                                            style={{
                                              opacity:
                                                currentSortBy === "FirstName" &&
                                                  firstNameDescending === false
                                                  ? "100%"
                                                  : "50%",
                                              margin: "2px",
                                              height: "6px",
                                              display: "inline-flex",
                                            }}
                                            onClick={clickFirstNameAsc}
                                          >
                                            <UpArrowIcon />{" "}
                                          </span>
                                          <span
                                            style={{
                                              opacity:
                                                currentSortBy === "FirstName" &&
                                                  firstNameDescending === true
                                                  ? "100%"
                                                  : "50%",
                                              margin: "0px",
                                              height: "6px",
                                              display: "inline-flex",
                                            }}
                                            onClick={clickFirstNameDesc}
                                          >
                                            <DownArrowIcon />{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                      align="left">
                                      <div className={styles.tableHeadCellContainer} style={{ display: "flex", }}  >
                                        <div
                                          onClick={() => {
                                            setLastNameDescending(!lastNameDescending);
                                            sortResultsBy("LastName", !lastNameDescending);
                                          }}
                                        >
                                          {allResourcesToTranslations.lastname}
                                        </div>
                                        <div className={styles.columnHeadArea}>
                                          <span
                                            style={{
                                              opacity:
                                                currentSortBy === "LastName" &&
                                                  lastNameDescending === false
                                                  ? "100%"
                                                  : "50%",
                                              margin: "2px",
                                              height: "6px",
                                              display: "inline-flex",
                                            }}
                                            onClick={clickLastNameAsc}
                                          >
                                            <UpArrowIcon />{" "}
                                          </span>
                                          <span
                                            style={{
                                              opacity:
                                                currentSortBy === "LastName" &&
                                                  lastNameDescending === true
                                                  ? "100%"
                                                  : "50%",
                                              margin: "0px",
                                              height: "6px",
                                              display: "inline-flex",
                                            }}
                                            onClick={clickLastNameDesc}
                                          >
                                            <DownArrowIcon />{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                      align="left">
                                      <div className={styles.tableHeadCellContainer} style={{ display: "flex", }}  >
                                        <div
                                          onClick={() => {
                                            setEmailDescending(!emailDescending);
                                            sortResultsBy("Email", !emailDescending);
                                          }}
                                        >
                                          {allResourcesToTranslations.emailaddress}
                                        </div>
                                        <div className={styles.columnHeadArea}>
                                          <span
                                            style={{
                                              opacity:
                                                currentSortBy === "Email" &&
                                                  emailDescending === false
                                                  ? "100%"
                                                  : "50%",
                                              margin: "2px",
                                              height: "6px",
                                              display: "inline-flex",
                                            }}
                                            onClick={clickEmailAsc}
                                          >
                                            <UpArrowIcon />{" "}
                                          </span>
                                          <span
                                            style={{
                                              opacity:
                                                currentSortBy === "Email" &&
                                                  emailDescending === true
                                                  ? "100%"
                                                  : "50%",
                                              margin: "0px",
                                              height: "6px",
                                              display: "inline-flex",
                                            }}
                                            onClick={clickEmailDesc}
                                          >
                                            <DownArrowIcon />{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </TableCell>
                                    {!certificateHasNoDueDates &&
                                      <TableCell
                                        style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                        align="center">
                                        <div className={styles.tableHeadCellContainer} style={{ display: "inline-flex", alignItems: "center" }}>
                                          <div
                                            onClick={() => {
                                              setValidFromDescending(!validFromDescending);
                                              sortResultsBy("ValidFrom", !validFromDescending);
                                            }}
                                          >
                                            {allResourcesToTranslations.validfrom}
                                          </div>
                                          <div className={styles.columnHeadArea}>
                                            <span
                                              style={{
                                                opacity: currentSortBy === "ValidFrom" &&
                                                  validFromDescending === false
                                                  ? "100%"
                                                  : "50%",
                                                margin: "2px",
                                                height: "6px",
                                                display: "inline-flex",
                                              }}
                                              onClick={clickValidFromAsc}
                                            >
                                              <UpArrowIcon />{" "}
                                            </span>
                                            <span
                                              style={{
                                                opacity: currentSortBy === "ValidFrom" &&
                                                  validFromDescending === true
                                                  ? "100%"
                                                  : "50%",
                                                margin: "0px",
                                                height: "6px",
                                                display: "inline-flex",
                                              }}
                                              onClick={clickValidFromDesc}
                                            >
                                              <DownArrowIcon />{" "}
                                            </span>
                                          </div>
                                        </div>
                                      </TableCell>
                                    }
                                    {!certificateHasNoDueDates &&
                                      <TableCell
                                        style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                        align="center">
                                        <div className={styles.tableHeadCellContainer} style={{ display: "inline-flex", alignItems: "center" }}>
                                          <div
                                            onClick={() => {
                                              setValidToDescending(!validToDescending);
                                              sortResultsBy("ValidTo", !validToDescending);
                                            }}
                                          >
                                            {allResourcesToTranslations.validto}
                                          </div>
                                          <div className={styles.columnHeadArea}>
                                            <span
                                              style={{
                                                opacity: currentSortBy === "ValidTo" &&
                                                  validToDescending === false
                                                  ? "100%"
                                                  : "50%",
                                                margin: "2px",
                                                height: "6px",
                                                display: "inline-flex",
                                              }}
                                              onClick={clickValidToAsc}
                                            >
                                              <UpArrowIcon />{" "}
                                            </span>
                                            <span
                                              style={{
                                                opacity: currentSortBy === "ValidTo" &&
                                                  validToDescending === true
                                                  ? "100%"
                                                  : "50%",
                                                margin: "0px",
                                                height: "6px",
                                                display: "inline-flex",
                                              }}
                                              onClick={clickValidToDesc}
                                            >
                                              <DownArrowIcon />{" "}
                                            </span>
                                          </div>
                                        </div>
                                      </TableCell>
                                    }
                                    <TableCell className="LastModifiedDate"
                                      style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                      align="left">
                                      <div className={styles.tableHeadCellContainer} style={{ display: "flex", }}>

                                        <div
                                          onClick={() => {
                                            setModifiedDateDescending(!modifiedDateDescending);
                                            sortResultsBy("ModifiedDate", !modifiedDateDescending);
                                          }}
                                        >
                                          {allResourcesToTranslations.lastmodifieddate}
                                        </div>
                                        <div className={styles.columnHeadArea}>
                                          <span
                                            style={{
                                              opacity: currentSortBy === "ModifiedDate" &&
                                                modifiedDateDescending === false
                                                ? "100%"
                                                : "50%",
                                              margin: "2px",
                                              height: "6px",
                                              display: "inline-flex",
                                            }}
                                            onClick={clickModifiedDateAsc}
                                          >
                                            <UpArrowIcon />{" "}
                                          </span>
                                          <span
                                            style={{
                                              opacity: currentSortBy === "ModifiedDate" &&
                                                modifiedDateDescending === true
                                                ? "100%"
                                                : "50%",
                                              margin: "0px",
                                              height: "6px",
                                              display: "inline-flex",
                                            }}
                                            onClick={clickModifiedDateDesc}
                                          >
                                            <DownArrowIcon />{" "}
                                          </span>
                                        </div>

                                      </div>
                                    </TableCell>
                                    <TableCell
                                      style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                      align="left">
                                      <div className={styles.tableHeadCellContainer} style={{ display: "flex", }}>

                                        <div
                                          onClick={() => {
                                            setModifiedByDescending(!modifiedByDescending);
                                            sortResultsBy("ModifiedByUserName", !modifiedByDescending);
                                          }}
                                        >
                                          {allResourcesToTranslations.lastmodifiedby}
                                        </div>
                                        <div className={styles.columnHeadArea}>
                                          <span
                                            style={{
                                              opacity: currentSortBy === "ModifiedByUserName" &&
                                                modifiedByDescending === false
                                                ? "100%"
                                                : "50%",
                                              margin: "2px",
                                              height: "6px",
                                              display: "inline-flex",
                                            }}
                                            onClick={clickModifiedByAsc}
                                          >
                                            <UpArrowIcon />{" "}
                                          </span>
                                          <span
                                            style={{
                                              opacity: currentSortBy === "ModifiedByUserName" &&
                                                modifiedByDescending === true
                                                ? "100%"
                                                : "50%",
                                              margin: "0px",
                                              height: "6px",
                                              display: "inline-flex",
                                            }}
                                            onClick={clickModifiedByDesc}
                                          >
                                            <DownArrowIcon />{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                      align="left">
                                      <div className={styles.tableHeadCellContainer} style={{ display: "flex", }}>

                                        <div
                                          onClick={() => {
                                            setStatusDescending(!statusDescending);
                                            sortResultsBy("Status", !statusDescending);
                                          }}
                                        >
                                          {allResourcesToTranslations.status}
                                        </div>
                                        <div className={styles.columnHeadArea}>
                                          <span
                                            style={{
                                              opacity: currentSortBy === "Status" &&
                                                statusDescending === false
                                                ? "100%"
                                                : "50%",
                                              margin: "2px",
                                              height: "6px",
                                              display: "inline-flex",
                                            }}
                                            onClick={clickStatusAsc}
                                          >
                                            <UpArrowIcon />{" "}
                                          </span>
                                          <span
                                            style={{
                                              opacity: currentSortBy === "Status" &&
                                                statusDescending === true
                                                ? "100%"
                                                : "50%",
                                              margin: "0px",
                                              height: "6px",
                                              display: "inline-flex",
                                            }}
                                            onClick={clickStatusDesc}
                                          >
                                            <DownArrowIcon />{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </TableCell>
                                    <TableCell className="ViewCertificate"
                                      style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                      align="left">
                                      <div className={styles.tableHeadCellContainer} style={{ display: "flex", }}>
                                        <div>
                                          {allResourcesToTranslations.viewpdf}
                                        </div>
                                      </div>
                                    </TableCell>
                                    <TableCell className="Action"
                                      style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0", width: "10%" }}
                                      align="left">
                                      <div className={styles.tableHeadCellContainer}>
                                        <div>
                                          {allResourcesToTranslations.actions}
                                        </div>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {externalCertificatesAssignedUsers.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                    .map((result: any, i) => {
                                      return (
                                        <TableRow style={rowBackgroundColor(i)}>
                                          {isCalledFrom != "notprovidedcertificates" &&
                                            <TableCell align="left" className={"assignCheckbox"}>
                                              <Checkbox
                                                size="small"
                                                color="primary"
                                                checked={result.granted}
                                                className={styles.checkBox}
                                                onClick={(e: any) => handleAssignUnassign(e, result.userID, result.certificateID)}
                                              />
                                            </TableCell>
                                          }
                                          <TableCell align="left">
                                            <span className={result.status == "Closed" ? styles.certificatetitleexpired : styles.certificatetitle}>{result.firstName}</span>
                                          </TableCell>
                                          <TableCell align="left">
                                            <span className={result.status == "Closed" ? styles.certificatetitleexpired : styles.certificatetitle}>{result.lastName}</span>
                                          </TableCell>
                                          <TableCell align="left">
                                            <span className={result.status == "Closed" ? styles.certificatetitleexpired : styles.certificatetitle}>{result.email}</span>
                                          </TableCell>
                                          {!certificateHasNoDueDates &&
                                            <TableCell align="center" >
                                              {(!result.hasNoValidDates && result.validFromDate === null && result.fileName !== "") ?
                                                <CustomTooltip displayText={[allResourcesToTranslations.enterdate]}>
                                                  <span ><ErrorIcon></ErrorIcon> </span>
                                                </CustomTooltip>
                                                : <span className={result.status == "Closed" ? styles.certificatetitleexpired : styles.certificatetitle}>{!result.hasNoValidDates ? result.validFromDate : ''}</span>
                                              }
                                            </TableCell>
                                          }
                                          {!certificateHasNoDueDates &&
                                            <TableCell align="center">
                                              {(!result.hasNoValidDates && result.validToDate === null && result.fileName !== "") ?
                                                <CustomTooltip displayText={[allResourcesToTranslations.enterdate]}>
                                                  <span><ErrorIcon></ErrorIcon> </span>
                                                </CustomTooltip>
                                                : <span className={result.status == "Closed" ? styles.certificatetitleexpired : styles.certificatetitle}> {!result.hasNoValidDates ? result.validToDate : ''}</span>
                                              }
                                            </TableCell>
                                          }
                                          <TableCell align="left">
                                            <span className={result.status == "Closed" ? styles.certificatetitleexpired : styles.certificatetitle}>{result.modifiedDate}</span>
                                          </TableCell>
                                          <TableCell align="left">
                                            <span className={result.status == "Closed" ? styles.certificatetitleexpired : styles.certificatetitle}>{result.modifiedByUserName}</span>
                                          </TableCell>
                                          <TableCell align="left">
                                            <span className={result.status == "Closed" ? styles.certificatetitleexpired : styles.certificatetitle}>{result.status}</span>
                                          </TableCell>
                                          <TableCell align="left">
                                            <CustomTooltip displayText={[allResourcesToTranslations.viewcertificate]}>
                                              <Button className={styles.moduleListButton} variant="contained" color="primary" disabled={result.status == "New"} onClick={() => handlePDFpopup(result.orgID, result.fileName)}>
                                                <PDFWhiteIcon />
                                              </Button>
                                            </CustomTooltip>

                                          </TableCell>
                                          <TableCell align="left">
                                            {result.status == "New" ?
                                              <CustomTooltip displayText={[allResourcesToTranslations.uploadcertificate]}>
                                                <Button onClick={() => handleUploadCertificatepopup(result.userID, result.certificateID, result.hasNoValidDates)} className={styles.moduleListButton} variant="contained" color="primary">
                                                  <CloudUploadIcon />
                                                </Button>
                                              </CustomTooltip>
                                              :
                                              result.isUserEditable && result.status == "Valid" ?
                                                <CustomTooltip displayText={[allResourcesToTranslations.editcertificate]}>
                                                  <Button onClick={() => handleEditCertificatepopup(result.userID, result.certificateID, result.hasNoValidDates)} className={styles.moduleListButton} variant="contained" color="primary">
                                                    <EditIcon />
                                                  </Button></CustomTooltip> :
                                                <>
                                                  <CustomTooltip displayText={[allResourcesToTranslations.learnereditentryguideme]}>
                                                    <Button style={{ backgroundColor: "rgba(0, 0, 0, 0.12)", boxShadow: "none" }} variant="contained" color="primary">
                                                      <EditIcon />
                                                    </Button>
                                                  </CustomTooltip>
                                                </>
                                            }
                                          </TableCell>
                                        </TableRow>)
                                    })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </BrandedCard>
                        </div>
                      </div>
                      <div className={styles.paginationAddedUsersControl}>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 20, 100]}
                            component="div"
                            count={externalCertificatesAssignedUsers.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </UnbrandedCard>
          )}

          {externalCertificatesAssignedUsers != null && externalCertificatesAssignedUsers.length === 0 && (
            <BrandedCard>
              <div className={styles.noResultsCard} style={{ marginTop: "20px" }}>
                <div>{allResourcesToTranslations.sntemrepgmmnoresm}</div>
                <br />
                <div>{allResourcesToTranslations.sntemrepgmmselbkprevm}</div>
              </div>
            </BrandedCard>
          )}
        </div>
        {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
      </div>
    </>
  )
}

export default CertificateLists
