import React, { useState, createContext } from 'react'; 
import { InfopathQuestionType } from '../globalTypes'

interface IInfopathContext {
    currentQuizQuestions: Array<InfopathQuestionType>;
    setCurrentQuizQuestions: (quizQuestions: Array<InfopathQuestionType> | any) => void;
    currentSelection: number | null;
    setCurrentSelection: (currentSelection: number | any) => void;
    ipQuizSubmissionStillInProgress: boolean,
    setIpQuizSubmissionStillInProgress: (ipQuizSubmissionStillInProgress: boolean) => void,
    quizStillInProgress: boolean,
    setQuizStillInProgress: (quizStillInProgress: boolean) => void,
    hasLoadedSessionData: boolean,
    setHasLoadedSessionData: (hasLoadedSessionData: boolean) => void,
}

export const InfopathContext = createContext<IInfopathContext>({
    currentQuizQuestions: [],
    setCurrentQuizQuestions: () => {},
    currentSelection: null,
    setCurrentSelection: () => {},
    ipQuizSubmissionStillInProgress: false,
    setIpQuizSubmissionStillInProgress: () => {},
    quizStillInProgress: false,
    setQuizStillInProgress: () => {},
    hasLoadedSessionData: false,
    setHasLoadedSessionData: () => {},
})

export const InfopathProvider = (props: any) : JSX.Element => {
    const [ currentQuizQuestions, setCurrentQuizQuestions ] = useState([])
    const [ currentSelection, setCurrentSelection ] = useState(0);    
    const [ ipQuizSubmissionStillInProgress, setIpQuizSubmissionStillInProgress ] = useState<boolean>(false);
    const [ quizStillInProgress, setQuizStillInProgress ] = useState<boolean>(false);
    const [ hasLoadedSessionData, setHasLoadedSessionData ] = useState<boolean>(false);

    return (
       <InfopathContext.Provider value={{
        currentQuizQuestions, 
        setCurrentQuizQuestions,
        currentSelection, 
        setCurrentSelection,
        ipQuizSubmissionStillInProgress,
        setIpQuizSubmissionStillInProgress,
        quizStillInProgress,
        setQuizStillInProgress,
        hasLoadedSessionData,
        setHasLoadedSessionData,
       }}>
           {props.children}
       </InfopathContext.Provider>
    )
}