import axios from 'axios';
import { addError } from '../../AddError';

const searchUsersList = async (token: string, userId: number,orgDomain: string,unitIds: string, firstName: string, lastName: string,userName: string, email: string,inactiveUser: boolean,userIdfilter: number, orgId:number) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/administration/SearchUsersList`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
   
        "RequestingUserID" : userId,  
        "OrganisationID":orgId,
        "ParentUnitIDs" : unitIds,
        "FirstName" : firstName,
        "LastName" : lastName,
        "UserName" :  userName,
        "Email" : email,
        "IncludeInactiveUsers" : inactiveUser,  //default value false
        "UserID" :userIdfilter             //default value 0  else userid
     
    },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "GetAllPrimaryLanguages.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "GetAllPrimaryLanguages.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "GetAllPrimaryLanguages.ts", 1)
    }
    return null;
  }
};

export default searchUsersList;