import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const RandomIcon = () => (
    <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.757886 15.9507C0.75398 15.5699 0.906317 15.3531 1.2481 15.3296H3.99798C5.1659 15.3296 5.73424 15.3609 6.72833 14.566C6.93926 14.398 7.13847 14.2027 7.32596 13.9801C8.2146 12.9235 8.53099 11.6443 8.86301 10.2888C9.50556 7.66005 10.529 4.88282 13.2534 3.78326C14.8042 3.15829 16.9291 3.27742 18.6067 3.27742H19.636L19.6379 1.31462C19.6379 0.336143 19.8684 0.166229 20.5734 0.836122L24.3916 4.46487C24.8526 4.90235 24.7881 5.1875 24.3487 5.60936L20.5793 9.22248C19.9211 9.88847 19.6243 9.70098 19.6379 8.7811V6.47651C12.9976 6.37495 13.2769 6.80266 11.4254 13.1344C10.7418 15.0953 9.70477 16.4702 8.38061 17.3706C6.9959 18.3119 5.70885 18.6186 4.08001 18.6186H2.05666C0.896552 18.6186 0.757886 18.5639 0.757886 17.3764V15.9507ZM0.802806 5.56248C0.7989 5.94333 0.951237 6.16011 1.29302 6.18355H4.16399C5.33191 6.18355 5.90024 6.1523 6.89434 6.94719C7.10527 7.11515 7.30448 7.31045 7.49197 7.5331C7.71853 7.80262 7.90797 8.08777 8.07007 8.38268C8.36303 7.4667 8.72629 6.5683 9.20674 5.74607C9.34346 5.51366 9.48798 5.2871 9.64227 5.06641C9.30244 4.71486 8.93722 4.40824 8.54662 4.14262C7.16191 3.20126 5.87485 2.89463 4.24602 2.89463H2.10158C0.941472 2.89463 0.802806 2.94931 0.802806 4.13676V5.56248ZM11.2203 16.146C11.7984 16.814 12.5152 17.3647 13.4214 17.7299C14.9721 18.3549 16.9779 18.2358 18.6556 18.2358H19.6848L19.6868 20.1986C19.6868 21.177 19.9172 21.347 20.6223 20.6771L24.4405 17.0483C24.9014 16.6108 24.8369 16.3257 24.3975 15.9038L20.6281 12.2907C19.97 11.6247 19.6731 11.8122 19.6868 12.7321V15.0367C14.8471 15.1109 13.7456 14.9019 12.7886 12.3337C12.7359 12.5173 12.6636 12.7692 12.4117 13.6325C12.4058 13.652 12.4 13.6696 12.3941 13.6871C12.0679 14.6168 11.6754 15.4312 11.2203 16.146Z" fill="#FFFFFF"/>
</svg>

    );

export default RandomIcon
