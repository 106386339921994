import axios from 'axios';
import { addError } from './AddError';

export const editUser = async (token: string, userId: number, orgDomain: string, firstName: string, lastName: string, email: string, password: string | null, langCode: string | null, swapFirstLastNames: boolean  ) => {

    try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/user/edit`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        UserID : userId,
        UserDetailsToChange: {
            FirstName: firstName,
            LastName: lastName,
            Email: email,
            Password: password,
            Language: langCode,
            SwapFirstLastNames: swapFirstLastNames.toString()
        }
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "EditUser.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "EditUser.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "EditUser.ts", 1)
    }
    return null;
  }
};

export default editUser