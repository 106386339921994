
import axios from 'axios';
import { addError } from './AddError';

export const updatePolicyAsAccepted = async (token: string, userId: number, orgDomain: string, policyId: number) => {
  
  try {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/api/userpolicies/UpdatePolicyAsAccepted`,
    headers:{
      'Content-Type': 'application/json',
      'Authorization' : `Bearer ${token}`
    },
    data: {
      userid: userId,
      policyid: policyId,
    },
  });
  const data = await response.data;
  return data;
} catch (error: any) {
  if (error.response) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log(error.response);
    };
    addError(token, userId, orgDomain, "Rest Exception", error.response, "UpdatePolicyAsAccepted.ts", 1)
  } else if (error.request) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log(error.request);
    };
    addError(token, userId, orgDomain, "Rest Exception", error.request, "UpdatePolicyAsAccepted.ts", 1)
  } else if (error.message) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log(error.message);
    };
    addError(token, userId, orgDomain, "Rest Exception", error.message, "UpdatePolicyAsAccepted.ts", 1)
  }
  return null;
}
};

export default updatePolicyAsAccepted;
