//React + Typescript
import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CurrentAdminReportResultItem } from '../../globalTypes';
import { useHistory } from 'react-router-dom';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Steps, Hints } from "intro.js-react";
//Context
import { LoaderContext } from '../../contexts/LoaderContext';
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';
import { UIContext } from '../../contexts/UIContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Components
import BrandedCard from '../../Components/BrandedCard';
import LoaderContainer from '../../Components/LoaderContainer';
import CustomTooltip from '../../Components/CustomTooltip'
import GuideIcon from '../../CustomIcons/GuideIcon';

//General Functions
import getColor from '../../functions/getColor';
import serialToArray from '../../functions/serialToArray'
import { getCurrentTimeDDMMYYYYFormat } from '../../functions/getCurrentTimeDDMMYYYYFormat'
import { get2DigitDayOrMonth } from '../../functions/get2DigitDayOrMonth'
//API functions
import { getExportedXLSXData } from '../../API/GetExportedXLSXData'
import { getExportedCSVData } from '../../API/GetExportedCSVData'
import { getExportedPDFData } from '../../API/GetExportedPDFData'
import { addError } from '../../API/AddError'
//Components
import ExcelFileIcon from '../../CustomIcons/ExcelFileIcon'
import CSVFileIcon from '../../CustomIcons/CSVFileIcon'
import PDFIcon from '../../CustomIcons/PDFFileIcon';
import UpArrowIcon from '../../CustomIcons/UpArrowIcon';
import DownArrowIcon from '../../CustomIcons/DownArrowIcon';
//MUI Components
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table';
import GetAppIcon from '@material-ui/icons/GetApp';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import BrushIcon from '@material-ui/icons/Brush';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import ViewListIcon from '@material-ui/icons/ViewList';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
// Style
import styles from '../../styles/currentAdminReport.module.css'

const CurrentAdminReportResult = (props: any) => {
    const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
    const { oidcUser } = useReactOidc();
    const history = useHistory();
    const { profile: { user_id, family_name, given_name, website  }} = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const { theme: { color }} = useContext(CorperateThemeContext);
    const { setMessage } = useContext(LoaderContext);
    const [adminReportResult, setAdminReportResult] = useState<Array<CurrentAdminReportResultItem>>(props?.location?.state?.resultItems ?? [])
    const [inputFilterFirstName, setInputFilterFirstName] = useState<string>(props?.location?.state?.inputFilterFirstName ?? "")
    const [inputFilterLastName, setInputFilterLastName] = useState<string>(props?.location?.state?.inputFilterLastName ?? "")
    const [inputFilterUserName, setInputFilterUserName] = useState<string>(props?.location?.state?.inputFilterUserName ?? "")
    const [inputFilterEmail, setInputFilterEmail] = useState<string>(props?.location?.state?.inputFilterEmail ?? "")
    const [filterUnitName, setFilterUnitName]  = useState(!!props && !!props.location && !!props.location.state && !!props.location.state.filterUnitName ? props.location.state.filterUnitName.toString() : "");
    const [filterCourseName, setFilterCourseName]  = useState(!!props && !!props.location && !!props.location.state && !!props.location.state.filterCourseName ? props.location.state.filterCourseName.toString() : "");
    const [filterPastCourseName, setFilterPastCourseName]  = useState(!!props && !!props.location && !!props.location.state && !!props.location.state.filterPastCourseName ? props.location.state.filterPastCourseName.toString() : "");
    const [resultError, setResultError] =  useState<string>(props?.location?.state?.resultError ?? "")
    const [inputFilterIncludeInactive, setInputFilterIncludeInactive] = useState<boolean>(props?.location?.state?.inputFilterIncludeInactive ?? "")
    const [filterDateTo, setFilterDateTo] = useState<string>(props?.location?.state?.inputFilterDateTo ?? "");

    const [selectedUnits, setSelectedUnits] = useState<string>(props?.location?.state?.selectedUnits ?? "");
    const [selectedCourses, setSelectedCourses] = useState<string>(props?.location?.state?.selectedCourses ?? "");
    const [selectedPastCourses, setSelectedPastCourses] = useState<string>(props?.location?.state?.selectedPastCourses ?? "");
    const [classificationList, setClassificationList] = useState<any[]>(props?.location?.state?.classificationList ?? []);
    const [groupingOption, setGroupingOption] = useState<boolean>(props?.location?.state?.groupingOption ?? false);
    const [selectedClassification, setSelectedClassification] = useState<any>(props?.location?.state?.selectedClassification ?? {});
    const [groupBy, setGroupBy] = useState<string>(props?.location?.state?.groupBy ?? "");
    const [selectedClassificationID, setSelectedClassificationID] = useState<number>(props?.location?.state?.selectedClassificationID ?? -1);
    
    const [exporting, setExporting] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true)
    const [currentSortBy, setCurrentSortBy] = useState<string>("")
    const [sortInProgress, setSortInProgress] = useState<boolean>(false)
    const [firstNameDescending, setFirstNameDescending] = useState<boolean>(false)
    const [lastNameDescending, setLastNameDescending] = useState<boolean>(false)
    const [unitDescending, setUnitDescending] = useState<boolean>(false)
    const [emailDescending, setEmailDescending] = useState<boolean>(false)
    const [externalIDDescending, setExternalIDDescending] = useState<boolean>(false)
    const [courseDescending, setCourseDescending] = useState<boolean>(false)
    const [moduleDescending, setModuleDescending] = useState<boolean>(false)
    const [lessonStatusDescending, setLessonStatusDescending] = useState<boolean>(false)
    const [quizStatusDescending, setQuizStatusDescending] = useState<boolean>(false)
    const [quizStatusDateDescending, setQuizStatusDateDescending] = useState<boolean>(false)
    const [lastCourseCompletionDateDescending, setLastCourseCompletionDateDescending] = useState<boolean>(false)    
    const [quizScoreDescending, setQuizScoreDescending] = useState<boolean>(false)
    const [reverseNextSort, setReverseNextSort] = useState<boolean>(false)
    const [reportRunTimeTaken, setReportRunTimeTaken] = useState<string>("")
    const { setTitle, setTitleEnglish, updateBackButtonRoute, updateBackButtonRouteProps, showReportDueDate, setshowReportDueDate, menuSelection, EnableActivityLogs } = useContext(UIContext);
    const [exportError, setExportError] = useState<string>("")
    const [inputHistoricData, setInputHistoricData] = useState<boolean>(
        props?.location?.state?.inputHistoricData ?? false
    );  
    const [reportTitle, setReportTitle] = useState<string>(props?.location?.state?.reportTitle)
    const [exportReportTitle, setExportReportTitle] = useState<string>(props?.location?.state?.exportreportTitle)   
    const showHideDueDate = () => {
        setshowReportDueDate(!showReportDueDate)
    }  
    const [intro, setIntro] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: ".currentAdminReportList",
            title: "Report Data", intro: allResourcesToTranslations.emrepprvgmmrepdta        
          },      
        ]
    })

    useEffect(() => 
      {
                    let updateSteps = [...intro.steps]
                    updateSteps.push( 
                    {
                    element: ".xlxs",
                    title: "Export", intro:allResourcesToTranslations.sntemrepgmmexpxls
                    },
                    {
                    element: ".csv",
                    title: "Export", intro:allResourcesToTranslations.sntemrepgmmexpcsv
                    },
                    {
                        element: ".pdf",
                        title: "Export", intro:allResourcesToTranslations.sntemrepgmmexppdf
                    }
                    //{
                    //    element: ".rowShading",
                    //    intro: "Click icon to Show/Hide Row Shading, also applies to Export XLSX file."
                    //}           
                )      
      
                          setIntro({...intro, steps: [...updateSteps]})              
    },[menuSelection])
      
    const onExit = () => {
        setIntro({...intro, stepsEnabled: false });
    };
    const toggleBanding = () => setEnableRowBanding(!enableRowBanding)
    const rowBackgroundColor = (index: number) => {
        if (enableRowBanding) {
            return { backgroundColor: index % 2 == 0 ? "#eee" : ""}
        } else {
            return {}
        }
    }
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage)
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }
    const GetDate_yymmdd = () => {
        var d =  new Date(Date.now());
        let day = d.getFullYear().toString().substring(2,4)  + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0")
         return day;         
      }
    
    const buildRequestStringArray = () => {
        let resultArray : Array<string> = []
        for (let i = 0; i < adminReportResult.length; i++) {
            const r = adminReportResult[i]
            if(showReportDueDate)
            {
                resultArray.push(`${r.unit}<#>${r.userLastName}<#>${r.userFirstName}<#>${r.email}<#>${r.externalID}<#>${r.course}<#>${r.module}<#>${r.lessonStatus}<#>${r.quizStatus}<#>${(r.quizStatus.toLowerCase() == "passed" || r.quizStatus.toLowerCase() == "failed") ? r.quizScore : ""}<#>${r.quizStatusDate}<#>${r.lastCourseCompletionDateTxt}`)
            }
            else
            {
            resultArray.push(`${r.unit}<#>${r.userLastName}<#>${r.userFirstName}<#>${r.email}<#>${r.externalID}<#>${r.course}<#>${r.module}<#>${r.lessonStatus}<#>${r.quizStatus}<#>${(r.quizStatus.toLowerCase() == "passed" || r.quizStatus.toLowerCase() == "failed") ? r.quizScore : ""}<#>${r.quizStatusDate}`)
            }
        }
        return resultArray
    }
    const GetClassificationname = () => {        
        const r = adminReportResult[0]
        let classificationValue  = r.classificationValue != null ? r.classificationValue : "";
 
        return classificationValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Grouping Option" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {r.classificationValue}</span></div> : ""
          
        
     }
    const GetInputFilterFirstName = () => {        
        let curValue  = inputFilterFirstName != null ? inputFilterFirstName : ""; 
        return curValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"First Name" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {inputFilterFirstName}</span></div> : ""
    }

    const GetInputFilterLastName = () => {       
        let curValue  = inputFilterLastName != null ? inputFilterLastName : ""; 
        return curValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Last Name" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {inputFilterLastName}</span></div> : ""
    }

    const GetInputFilterUserName = () => {        
        let curValue  = inputFilterUserName != null ? inputFilterUserName : ""; 
        return curValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Username" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {inputFilterUserName}</span></div> : ""
    }

    const GetInputFilterEmail = () => {       
        let curValue  = inputFilterEmail != null ? inputFilterEmail : ""; 
        return curValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Email" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {inputFilterEmail}</span></div> : ""
    }

   
    const GetInputFilterIncludeInactive = () => {       
        let curValue  = inputFilterIncludeInactive != null ? inputFilterIncludeInactive : ""; 
        return curValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Include Inactive Accounts" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {inputFilterIncludeInactive ? "Yes" : "No"}</span></div> : ""
    }
    const GetInputFilterHistoricData = () => {       
        let curValue  = inputHistoricData != null ? inputHistoricData : ""; 
        return curValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Include Historic Course" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {inputHistoricData ? "Yes" : "No"}</span></div> : ""
    }
    const GetHistoricDateTo = () => {        
       
        let DateEffectiveTo  = filterDateTo != "" ? filterDateTo : "";
        if (DateEffectiveTo !== "" && DateEffectiveTo !== undefined && DateEffectiveTo !== null) {
            const tokens = DateEffectiveTo.split("-");
            if (tokens !== null && tokens !== undefined && tokens.length == 3) {
                DateEffectiveTo = get2DigitDayOrMonth(tokens[2]) + "/" + get2DigitDayOrMonth(tokens[1]) + "/" + tokens[0];
            }
        }       
        return DateEffectiveTo != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Historic Date" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {DateEffectiveTo}</span></div> : ""          
        
     }
    const handleXLSXExport = () => {
        setMessage("Exporting XLSX");
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started CurrentAdmin Report XLSX-EXPORT in NEWSALT ClientApp", null, "CurrentAdminReportResult.tsx", 6, EnableActivityLogs);
        setExporting(true)
        const r = adminReportResult[0]
        let classificationvalue  = r.classificationValue ;
        getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(),showReportDueDate? 12:11,exportReportTitle,enableRowBanding,classificationvalue,"","",filterDateTo,inputFilterIncludeInactive,inputFilterIncludeInactive, "", inputFilterFirstName, inputFilterLastName, inputFilterUserName, inputFilterEmail,"","",showReportDueDate).then((xlxsRes) => {            
            if (xlxsRes.isSuccess) {
                const fileData = xlxsRes.xlsxDataOutput.fileData
                var a = window.document.createElement('a');                
                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )
                let filename = exportReportTitle.toString() + "-";
                a.download = filename + GetDate_yymmdd() + ".xlsx" ;
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)

            }
            else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
                setExportError(xlxsRes.error);
            }
            setExporting(false)
            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed CurrentAdmin Report XLSX-EXPORT in NEWSALT ClientApp", null, "CurrentAdminReportResult.tsx", 6, EnableActivityLogs);
        })
    }

    const handleCSVExport = () => {
        setMessage("Exporting CSV");
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started CurrentAdmin Report CSV-EXPORT in NEWSALT ClientApp", null, "CurrentAdminReportResult.tsx", 6, EnableActivityLogs);
        setExporting(true)
        const r = adminReportResult[0]
        let classificationvalue  = r.classificationValue ;
        getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), showReportDueDate? 12:11, exportReportTitle,classificationvalue,"","",filterDateTo,inputFilterIncludeInactive,inputFilterIncludeInactive, "",false, inputFilterFirstName, inputFilterLastName, inputFilterUserName, inputFilterEmail,"","", showReportDueDate).then((csvRes) => {            
            if (csvRes.isSuccess) {
                const fileData = csvRes.csvDataOutput.fileData
                var a = window.document.createElement('a');
                
                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )
                let filename = exportReportTitle.toString() + "-";
                a.download = filename + GetDate_yymmdd() + ".csv" ;
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)

            }
            else if (csvRes.error !== undefined && csvRes.error !== null) {
                setExportError(csvRes.error);
            }
            setExporting(false)
            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed CurrentAdmin Report CSV-EXPORT in NEWSALT ClientApp", null, "CurrentAdminReportResult.tsx", 6, EnableActivityLogs);
        })
    }
    const handlePDFXExport = () => {
        setMessage("Exporting PDF");
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started CurrentAdmin Report PDF-EXPORT in NEWSALT ClientApp", null, "CurrentAdminReportResult.tsx", 6, EnableActivityLogs);
        setExporting(true)
        const r = adminReportResult[0]
        let classificationvalue  = r.classificationValue ;
        getExportedPDFData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), showReportDueDate? 12:11,exportReportTitle, enableRowBanding,classificationvalue,"","",filterDateTo,inputFilterIncludeInactive,inputFilterIncludeInactive, "", inputFilterFirstName, inputFilterLastName, inputFilterUserName, inputFilterEmail,"","", showReportDueDate ).then((pdfRes) => {            
            if (pdfRes.isSuccess) {
                const fileData = pdfRes.pdfDataOutput.fileData
                var a = window.document.createElement('a');
                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )                
                let filename = exportReportTitle.toString() + "-";
                a.download = filename+ GetDate_yymmdd() + ".PDF" ;
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)
            }
            else if (pdfRes.error !== undefined && pdfRes.error !== null) {
                setExportError(pdfRes.error);
            }
            setExporting(false)
            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed CurrentAdmin Report PDF-EXPORT in NEWSALT ClientApp", null, "CurrentAdminReportResult.tsx", 6, EnableActivityLogs);
        })
    }

    const sortResultsByAsync = async (sortBy: "FirstName" | "LastName" | "UnitID" | "Email" | "ExternalID" | "Course" | "Module" | "LessonStatus" | "QuizStatus" | "QuizScore" | "QuizStatusDate" | "CourseCompletionDate", sortGivenItemInDesc: boolean ) => {
        if (adminReportResult == null || adminReportResult.length <= 0) {
            return adminReportResult;
        }
        var result = adminReportResult.slice(0)
        setCurrentSortBy(sortBy)
        switch(sortBy) {
            case "UnitID":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.unit === null) { return -1; }
                        if (a === null || a.unit === null) { return 1; }
                        if(a.unit.toLowerCase() > b.unit.toLowerCase()) { return -1; }
                        if(a.unit.toLowerCase() < b.unit.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setUnitDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.unit === null) { return 1; }
                        if (a === null || a.unit === null) { return -1; }
                        if(a.unit.toLowerCase() > b.unit.toLowerCase()) { return 1; }
                        if(a.unit.toLowerCase() < b.unit.toLowerCase()) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setUnitDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "Email":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.email === null) { return -1; }
                        if (a === null || a.email === null) { return 1; }
                        if(a.email.toLowerCase() > b.email.toLowerCase()) { return -1; }
                        if(a.email.toLowerCase() < b.email.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setEmailDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.email === null) { return 1; }
                        if (a === null || a.email === null) { return -1; }
                        if(a.email.toLowerCase() < b.email.toLowerCase()) { return -1; }
                        if(a.email.toLowerCase() > b.email.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setEmailDescending(true) 
                    }
                }
                setAdminReportResult(result);
                break;
            case "FirstName":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.userFirstName === null) { return -1; }
                        if (a === null || a.userFirstName === null) { return 1; }
                        if(a.userFirstName.toLowerCase() > b.userFirstName.toLowerCase()) { return -1; }
                        if(a.userFirstName.toLowerCase() < b.userFirstName.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setFirstNameDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.userFirstName === null) { return 1; }
                        if (a === null || a.userFirstName === null) { return -1; }
                        if(a.userFirstName.toLowerCase() < b.userFirstName.toLowerCase()) { return -1; }
                        if(a.userFirstName.toLowerCase() > b.userFirstName.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setFirstNameDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "LastName":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.userLastName === null) { return -1; }
                        if (a === null || a.userLastName === null) { return 1; }
                        if(a.userLastName.toLowerCase() > b.userLastName.toLowerCase()) { return -1; }
                        if(a.userLastName.toLowerCase() < b.userLastName.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setLastNameDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.userLastName === null) { return 1; }
                        if (a === null || a.userLastName === null) { return -1; }
                        if(a.userLastName.toLowerCase() < b.userLastName.toLowerCase()) { return -1; }
                        if(a.userLastName.toLowerCase() > b.userLastName.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setLastNameDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "ExternalID":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.externalID === null) { return -1; }
                        if (a === null || a.externalID === null) { return 1; }
                        if(a.externalID.toLowerCase() > b.externalID.toLowerCase()) { return -1; }
                        if(a.externalID.toLowerCase() < b.externalID.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setExternalIDDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.externalID === null) { return 1; }
                        if (a === null || a.externalID === null) { return -1; }
                        if(a.externalID.toLowerCase() < b.externalID.toLowerCase()) { return -1; }
                        if(a.externalID.toLowerCase() > b.externalID.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setExternalIDDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "Course":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.course === null) { return -1; }
                        if (a === null || a.course === null) { return 1; }
                        if(a.course.toLowerCase() > b.course.toLowerCase()) { return -1; }
                        if(a.course.toLowerCase() < b.course.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCourseDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.course === null) { return 1; }
                        if (a === null || a.course === null) { return -1; }
                        if(a.course.toLowerCase() < b.course.toLowerCase()) { return -1; }
                        if(a.course.toLowerCase() > b.course.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCourseDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "Module":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.module === null) { return -1; }
                        if (a === null || a.module === null) { return 1; }
                        if(a.module.toLowerCase() > b.module.toLowerCase()) { return -1; }
                        if(a.module.toLowerCase() < b.module.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setModuleDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.module === null) { return 1; }
                        if (a === null || a.module === null) { return -1; }
                        if(a.module.toLowerCase() < b.module.toLowerCase()) { return -1; }
                        if(a.module.toLowerCase() > b.module.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setModuleDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "LessonStatus":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.lessonStatus === null) { return -1; }
                        if (a === null || a.lessonStatus === null) { return 1; }
                        if(a.lessonStatus.toLowerCase() > b.lessonStatus.toLowerCase()) { return -1; }
                        if(a.lessonStatus.toLowerCase() < b.lessonStatus.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setLessonStatusDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.lessonStatus === null) { return 1; }
                        if (a === null || a.lessonStatus === null) { return -1; }
                        if(a.lessonStatus.toLowerCase() < b.lessonStatus.toLowerCase()) { return -1; }
                        if(a.lessonStatus.toLowerCase() > b.lessonStatus.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setLessonStatusDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "QuizStatus":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.quizStatus === null) { return -1; }
                        if (a === null || a.quizStatus === null) { return 1; }
                        if(a.quizStatus.toLowerCase() > b.quizStatus.toLowerCase()) { return -1; }
                        if(a.quizStatus.toLowerCase() < b.quizStatus.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setQuizStatusDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.quizStatus === null) { return 1; }
                        if (a === null || a.quizStatus === null) { return -1; }
                        if(a.quizStatus.toLowerCase() < b.quizStatus.toLowerCase()) { return -1; }
                        if(a.quizStatus.toLowerCase() > b.quizStatus.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setQuizStatusDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "QuizScore":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if(a.quizScore > b.quizScore) { return -1; }
                        if(a.quizScore < b.quizScore) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setQuizScoreDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if(a.quizScore > b.quizScore) { return 1; }
                        if(a.quizScore < b.quizScore) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setQuizScoreDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "QuizStatusDate":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.quizStatusDateWithTime === null) { return -1; }
                        if (a === null || a.quizStatusDateWithTime === null) { return 1; }
                        if(a.quizStatusDateWithTime > b.quizStatusDateWithTime) { return -1; }
                        if(a.quizStatusDateWithTime < b.quizStatusDateWithTime) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setQuizStatusDateDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.quizStatusDateWithTime === null) { return 1; }
                        if (a === null || a.quizStatusDateWithTime === null) { return -1; }
                        if(a.quizStatusDateWithTime > b.quizStatusDateWithTime) { return 1; }
                        if(a.quizStatusDateWithTime < b.quizStatusDateWithTime) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setQuizStatusDateDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "CourseCompletionDate":
                    if (sortGivenItemInDesc) {
                        result.sort((a, b) => {
                            if (b === null || b.lastCourseCompletionDate === null) { return -1; }
                            if (a === null || a.lastCourseCompletionDate === null) { return 1; }
                            if(a.lastCourseCompletionDate > b.lastCourseCompletionDate) { return -1; }
                            if(a.lastCourseCompletionDate < b.lastCourseCompletionDate) { return 1; }
                            return 0;
                        })
                        if (reverseNextSort) {
                            setLastCourseCompletionDateDescending(false)
                        }
                    } else {
                        result.sort((a, b) => {
                            if (b === null || b.lastCourseCompletionDate === null) { return 1; }
                            if (a === null || a.lastCourseCompletionDate === null) { return -1; }
                            if(a.lastCourseCompletionDate > b.lastCourseCompletionDate) { return 1; }
                            if(a.lastCourseCompletionDate < b.lastCourseCompletionDate) { return -1; }
                            return 0;
                        })
                        if (reverseNextSort) {
                            setLastCourseCompletionDateDescending(true)
                        }
                    }
                    setAdminReportResult(result);
                    break;
            default:
                break;
        }
        return result.slice(0);
    }

    const sortResultsBy = async (sortBy: "FirstName" | "LastName" | "UnitID" | "Email" | "ExternalID" | "Course" | "Module" | "LessonStatus" | "QuizStatus" | "QuizScore" | "QuizStatusDate" | "CourseCompletionDate", sortGivenItemInDesc: boolean ) => {
        setSortInProgress(true);
        setExportError("");
        await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
            setCurrentSortBy(sortBy)
            setAdminReportResult(result)
            setPage(0)
            setSortInProgress(false);
        }); 
    }

    const clickUnitAsc = () => {
        setReverseNextSort(false);
        setUnitDescending(false)
        sortResultsBy("UnitID", false)
    }

    const clickUnitDesc = () => {
        setReverseNextSort(false);
        setUnitDescending(true)
        sortResultsBy("UnitID", true)
    }

    const clickFirstNameAsc = () => {
        setReverseNextSort(false);
        setFirstNameDescending(false);        
        sortResultsBy("FirstName", false);
    }

    const clickFirstNameDesc = () => {
        setReverseNextSort(false);
        setFirstNameDescending(true)
        sortResultsBy("FirstName", true)
    }

    const clickLastNameAsc = () => {
        setReverseNextSort(false);
        setLastNameDescending(false);        
        sortResultsBy("LastName", false);
    }

    const clickLastNameDesc = () => {
        setReverseNextSort(false);
        setLastNameDescending(true)
        sortResultsBy("LastName", true)
    }

    const clickEmailAsc = () => {
        setReverseNextSort(false);
        setEmailDescending(false);
        sortResultsBy("Email", false);
    }

    const clickEmailDesc = () => {
        setReverseNextSort(false);
        setEmailDescending(true)
        sortResultsBy("Email", true)
    }

    const clickExternalIDAsc = () => {
        setReverseNextSort(false);
        setExternalIDDescending(false);        
        sortResultsBy("ExternalID", false);
    }

    const clickExternalIDDesc = () => {
        setReverseNextSort(false);
        setExternalIDDescending(true)
        sortResultsBy("ExternalID", true)
    }

    const clickCourseAsc = () => {
        setReverseNextSort(false);
        setCourseDescending(false);
        sortResultsBy("Course", false);
    }

    const clickCourseDesc = () => {
        setReverseNextSort(false);
        setCourseDescending(true)
        sortResultsBy("Course", true)
    }

    const clickModuleAsc = () => {
        setReverseNextSort(false);
        setModuleDescending(false);
        sortResultsBy("Module", false);
    }

    const clickModuleDesc = () => {
        setReverseNextSort(false);
        setModuleDescending(true)
        sortResultsBy("Module", true)
    }

    const clickLessonStatusAsc = () => {
        setReverseNextSort(false);
        setLessonStatusDescending(false);
        sortResultsBy("LessonStatus", false);
    }

    const clickLessonStatusDesc = () => {
        setReverseNextSort(false);
        setLessonStatusDescending(true)
        sortResultsBy("LessonStatus", true)
    }

    const clickQuizStatusAsc = () => {
        setReverseNextSort(false);
        setQuizStatusDescending(false);
        sortResultsBy("QuizStatus", false);
    }

    const clickQuizStatusDesc = () => {
        setReverseNextSort(false);
        setQuizStatusDescending(true)
        sortResultsBy("QuizStatus", true)
    }

    const clickQuizScoreAsc = () => {
        setReverseNextSort(false);
        setQuizScoreDescending(false);
        sortResultsBy("QuizScore", false);
    }

    const clickQuizScoreDesc = () => {
        setReverseNextSort(false);
        setQuizScoreDescending(true)
        sortResultsBy("QuizScore", true)
    }

    const clickQuizStatusDateAsc = () => {
        setReverseNextSort(false);
        setQuizStatusDateDescending(false);
        sortResultsBy("QuizStatusDate", false);
    }

    const clickQuizStatusDateDesc = () => {
        setReverseNextSort(false);
        setQuizStatusDateDescending(true)
        sortResultsBy("QuizStatusDate", true)
    }

    const clickLastCourseCompletionDateAsc = () => {
        setReverseNextSort(false);
        setLastCourseCompletionDateDescending(false)
        sortResultsBy("CourseCompletionDate", false)
    }

    const clickLastCourseCompletionDateDesc = () => {
        setReverseNextSort(false);
        setLastCourseCompletionDateDescending(true)
        sortResultsBy("CourseCompletionDate", true)
    }

    const initialSort = () => {
        setCurrentSortBy("UnitID");
        setUnitDescending(false)
        setLastNameDescending(false)
        setFirstNameDescending(false)
        setCourseDescending(false)
        setReportRunTimeTaken(getCurrentTimeDDMMYYYYFormat())
        setPage(0)
    }

    const logNavigation = (message: string) => {
        addError(oidcUser.access_token, userId, orgDomain, message, null, "currentadminreport.tsx", 6, EnableActivityLogs)
    }

    const NavigateToQuizHistory = (moduleID: number, userId: number) => {
        history.push({
            pathname: `${moduleID}/${userId}/quizhistory`,               
            state: {
                page: "CurrentAdminReport",
                inputFilterFirstName: inputFilterFirstName,
                inputFilterLastName: inputFilterLastName,
                inputFilterEmail: inputFilterEmail,
                inputFilterUserName: inputFilterUserName,  
                inputFilterDateTo: filterDateTo,  
                inputFilterIncludeInactive:inputFilterIncludeInactive,          
                inputHistoricData: inputHistoricData,                    
                adminReportResultItems: adminReportResult,
                reportTitle: reportTitle,
                exportreportTitle: exportReportTitle ,
                resultError: resultError
            },
        });
    }
     
    useEffect(() => {  
        setTitle( reportTitle /*TODO:// Add to translations context  */)
        setTitleEnglish(pageTitles.currentadminreport)
        updateBackButtonRoute("/currentadminreport");
        updateBackButtonRouteProps({

            inputFilterFirstName: inputFilterFirstName,
            inputFilterLastName: inputFilterLastName,
            inputFilterEmail: inputFilterEmail,
            inputFilterUserName: inputFilterUserName, 
            filterCourseName: filterCourseName,
            filterPastCourseName: filterPastCourseName,
            filterUnitName: filterUnitName, 
            inputFilterDateTo: filterDateTo,  
            inputFilterIncludeInactive: inputFilterIncludeInactive,          
            inputHistoricData: inputHistoricData,
            
            selectedUnits: selectedUnits,
            selectedCourses: selectedCourses,
            selectedPastCourses: selectedPastCourses,
            classificationList: classificationList,
            groupingOption: groupingOption,
            selectedClassification: selectedClassification,
            groupBy: groupBy,
            selectedClassificationID: selectedClassificationID
            
        });     
        initialSort()
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Completed CurrentAdmin Report in NEWSALT ClientApp", null, "CurrentAdminReportResult.tsx", 6, EnableActivityLogs); 
    },[])

    if (resultError !== null && resultError !== undefined && resultError.trimLeft().trimRight().length > 0)
    {
        //setExportError("");
        return (<BrandedCard>
            <div className={styles.noResultsCard}>
                <div style={{color:"red"}}>Error: {resultError}</div>
                <br/>
                <div>Select the Back Button to return to Previous Page</div>
            </div>
        </BrandedCard>)
    }
    else if (adminReportResult === null || adminReportResult.length < 1) {
        return (<BrandedCard>
            <div className={styles.noResultsCard}>
                <div>No Results to Display</div>
                <div>Select the Back Button to return to Previous Page</div>
                </div>
                </BrandedCard>)
    } else {
    }

    if (exporting) return <LoaderContainer/>
    
    return (
        <>
        
        <Steps  enabled={intro.stepsEnabled}   steps={intro.steps}  initialStep={intro.initialStep}
          onExit={onExit}
            options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
          }}/>
         <CustomTooltip displayText={["Guide Me"]}>
            <span style={{right:"105px"}} onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
            <GuideIcon />
            </span>
         </CustomTooltip>
         <BrandedCard>
           <div className={styles.reportPageTitleSection}>                
                <div className={styles.exportBtnContainer}>
                    <div className={styles.reportContainer}>
                        <div className={styles.reportDetailContainer}>
                            <span className={styles.reportDetailTitle}>{"Report run by" /* TODO Tranlation */}</span>
                            <span className={styles.reportDetail}> {`${given_name} ${family_name}`}</span>
                        </div>
                        <div className={styles.reportDetailContainer}>
                            <span className={styles.reportDetailTitle}>{"Report run at" /* TODO Tranlation */}</span>
                            <span className={styles.reportDetail}>
                                { reportRunTimeTaken }
                            </span>
                        </div>                          
                      

                        { GetClassificationname() }                
                        { GetInputFilterFirstName() }      
                        { GetInputFilterLastName() }      
                        { GetInputFilterUserName() }      
                        { GetInputFilterEmail() }  
                        { GetInputFilterIncludeInactive() }    
                        { GetInputFilterHistoricData() } 
                        { GetHistoricDateTo() }
                        
                    </div>   
                   
                    <div className={styles.reportExportContainer}>
                                <CustomTooltip displayText={["Export to XLSX"] /*TODO: Add translations */}>                                    
                                        <Button  className="xlxs" color="primary" onClick={handleXLSXExport} id="btnNewSaltCARReportExportXLSX">
                                            <ExcelFileIcon/>
                                            {/* <GetAppIcon/> */}
                                        </Button>
                                </CustomTooltip>
                                <CustomTooltip displayText={["Export to CSV"] /*TODO: Add translations */}>         
                                    <Button   className="csv" color="primary" onClick={handleCSVExport} id="btnNewSaltCARReportExportCSV">
                                        <CSVFileIcon/>
                                         {/* <GetAppIcon/> */}
                                    </Button>
                                </CustomTooltip>
                                <CustomTooltip displayText={["Export to PDF"] /*TODO: Add translations */}>        
                                    <Button  className="pdf" color="primary" onClick={handlePDFXExport} id="btnNewSaltCARReportExportPDF">
                                        <PDFIcon/>
                                         {/* <GetAppIcon/> */}
                                    </Button>
                                </CustomTooltip>
                                <CustomTooltip  displayText={[allResourcesToTranslations.showhidecoursecompletiondate]}> 
                                    <Button   id="btnShowHide"
                                    onClick={() => { showHideDueDate() }}           
                                    className={`${styles.ShowHideBtn} `}
                                    variant="contained"
                                    color="primary">
                                    { showReportDueDate ? <VisibilityIcon /> : <VisibilityOffIcon /> }                        
                                    </Button> 
                                    
                                </CustomTooltip>
                                {/* <CustomTooltip displayText={["Show/Hide Row Shading"]}>        
                                    <Button className="rowShading" color="primary" onClick={toggleBanding}>
                                        <BrushIcon/>
                                        <CalendarViewDayIcon/>
                                    </Button>
                                </CustomTooltip> */}
                    </div>
                    
                </div>
               
            </div>
            </BrandedCard>
            <hr/>
            {<div style={{color:"red"}}>{(exportError === undefined || exportError === null  || exportError === "") ? "" : ("Export Error: " + exportError)}</div>}
            <hr/>
            <div className={ styles.paginationControlsContainer }>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 100]}
                        component="div"
                        count={adminReportResult.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    </div> 
            <div className={styles.tableSection}>
                <div className={styles.tableContainer}> 
                    <BrandedCard>
                        <TableContainer>
                            <Table>
                                <TableHead className="currentAdminReportList">
                                    <TableRow>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0"}} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setUnitDescending(!unitDescending);
                                                        sortResultsBy("UnitID", !unitDescending);
                                                    }}>
                                                {"Unit Pathway" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "UnitID" && unitDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickUnitAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "UnitID" && unitDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickUnitDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color}} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setLastNameDescending(!lastNameDescending);
                                                        sortResultsBy("LastName", !lastNameDescending);
                                                    }}>
                                                {"Last Name" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "LastName" && lastNameDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickLastNameAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "LastName" && lastNameDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickLastNameDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color}} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setFirstNameDescending(!firstNameDescending);
                                                        sortResultsBy("FirstName", !firstNameDescending);
                                                    }}>
                                                {"First Name" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "FirstName" && firstNameDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickFirstNameAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "FirstName" && firstNameDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickFirstNameDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>                                       
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color}} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setEmailDescending(!emailDescending);
                                                        sortResultsBy("Email", !emailDescending);
                                                    }}>
                                                {"Email" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "Email" && emailDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickEmailAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "Email" && emailDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickEmailDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setExternalIDDescending(!externalIDDescending);
                                                        sortResultsBy("ExternalID", !externalIDDescending);
                                                    }}>
                                                {"External ID" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "ExternalID" && externalIDDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickExternalIDAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "ExternalID" && externalIDDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickExternalIDDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color}} align="left">
                                            <div className={styles.tableHeadCellContainer} >
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setCourseDescending(!courseDescending);
                                                        sortResultsBy("Course", !courseDescending);
                                                    }}>
                                                {"Course" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "Course" && courseDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickCourseAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "Course" && courseDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickCourseDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color}} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setModuleDescending(!moduleDescending);
                                                        sortResultsBy("Module", !moduleDescending);
                                                    }}>
                                                {"Module" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "Module" && moduleDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickModuleAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "Module" && moduleDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickModuleDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color}} align="left">
                                        <div className={styles.tableHeadCellContainer}>
                                            <div 
                                                onClick={() => 
                                                    {
                                                        setLessonStatusDescending(!lessonStatusDescending);
                                                        sortResultsBy("LessonStatus", !lessonStatusDescending)
                                                    }}>
                                                {"Lesson Status" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "LessonStatus" && lessonStatusDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickLessonStatusAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "LessonStatus" && lessonStatusDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickLessonStatusDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color}} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setQuizStatusDescending(!quizStatusDescending);
                                                        sortResultsBy("QuizStatus", !quizStatusDescending);
                                                    }}>
                                                {"Quiz Status" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "QuizStatus" && quizStatusDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickQuizStatusAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "QuizStatus" && quizStatusDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickQuizStatusDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setQuizScoreDescending(!quizScoreDescending);
                                                        sortResultsBy("QuizScore", !quizScoreDescending);
                                                    }}>
                                                {"Score" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "QuizScore" && quizScoreDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickQuizScoreAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "QuizScore" && quizScoreDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickQuizScoreDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>                                       
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color}} align="left"> 
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setQuizStatusDateDescending(!quizStatusDateDescending);
                                                        sortResultsBy("QuizStatusDate", !quizStatusDateDescending);
                                                    }}>
                                                {"Quiz Status Date" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "QuizStatusDate" && quizStatusDateDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickQuizStatusDateAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "QuizStatusDate" && quizStatusDateDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickQuizStatusDateDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        {showReportDueDate &&
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color}} align="left">                                            
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setLastCourseCompletionDateDescending(!lastCourseCompletionDateDescending);
                                                        sortResultsBy("CourseCompletionDate", !lastCourseCompletionDateDescending);
                                                    }}>
                                                {"Course Completion Date" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "CourseCompletionDate" && lastCourseCompletionDateDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickLastCourseCompletionDateAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "CourseCompletionDate" && lastCourseCompletionDateDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickLastCourseCompletionDateDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>}     
                                        <TableCell className="hideForPrint" style={{color: getColor(color.substring(1)),backgroundColor: color, borderRadius: "0 8px 0 0",}} align="center">
                                            {allResourcesToTranslations.history}
                                        </TableCell>                                   
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        adminReportResult.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((result: CurrentAdminReportResultItem, i ) => {
                                            return(
                                                <TableRow key={`currentadminreportresult-${i}`} style={rowBackgroundColor(i)}>
                                                    <TableCell align="left"> 
                                                        {result.unit}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {result.userLastName}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {result.userFirstName}
                                                    </TableCell>                                                    
                                                    <TableCell align="left" >
                                                        {result.email}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {result.externalID}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {result.course}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {result.module}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {result.lessonStatus}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {result.quizStatus}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        { (result.quizStatus.toLowerCase() == "passed" || result.quizStatus.toLowerCase() == "failed") ? (result.quizScore + "%") : ""}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {result.quizStatusDate}
                                                    </TableCell>                                                   
                                                    {showReportDueDate && <TableCell align="left">
                                                        {result.lastCourseCompletionDateTxt}
                                                    </TableCell>}      
                                                    <TableCell className="hideForPrint history" align="center">
                                                            {/* <Link
                                                            onClick={() => { logNavigation(`Opened ${result.moduleID} quizhistory`) }}
                                                            to={{
                                                                pathname: `${result.moduleID}/${result.userID}/quizhistory`
                                                            }}
                                                            > */}
                                                            <CustomTooltip displayText={[allResourcesToTranslations.viewhistory]}>
                                                                <Button variant="contained" color="primary" onClick={() => { NavigateToQuizHistory(result.moduleID,result.userID); logNavigation(`Opened ${result.moduleID} quizhistory`) }}>
                                                                <ViewListIcon/>
                                                                </Button>
                                                            </CustomTooltip>
                                                            {/* </Link> */}
                                                    </TableCell>                                              
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </BrandedCard>
                </div>
            </div>
            <div className={ styles.paginationControlsContainer }>
                <TablePagination
                    component="div"
                    count={adminReportResult.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </>
      );    
}

export default CurrentAdminReportResult
