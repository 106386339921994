import React from "react";

const CloudUploadLargeIcon = () => {

 return (    
<svg width="38px" height="38px" viewBox="0 0 0.95 0.95" xmlns="http://www.w3.org/2000/svg"><path fill="#63C2FF" fillRule="evenodd" d="M0.546 0.142A0.214 0.214 0 0 1 0.76 0.369V0.427A0.19 0.19 0 0 1 0.95 0.608V0.617a0.19 0.19 0 0 1 -0.18 0.19H0.522V0.621h0.117L0.475 0.427 0.31 0.621H0.427V0.807H0.19A0.19 0.19 0 0 1 0 0.617 0.19 0.19 0 0 1 0.152 0.431a0.142 0.142 0 0 1 0.204 -0.174 0.222 0.222 0 0 1 0.19 -0.114"/></svg>

)
}

export default CloudUploadLargeIcon;