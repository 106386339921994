import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const ToggleRightIcon = () => (
    // <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    // <path d="M16 5.0918H8C4.13401 5.0918 1 8.2258 1 12.0918C1 15.9578 4.13401 19.0918 8 19.0918H16C19.866 19.0918 23 15.9578 23 12.0918C23 8.2258 19.866 5.0918 16 5.0918Z" stroke="#21394F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    // <path d="M16 15.0918C17.6569 15.0918 19 13.7487 19 12.0918C19 10.4349 17.6569 9.0918 16 9.0918C14.3431 9.0918 13 10.4349 13 12.0918C13 13.7487 14.3431 15.0918 16 15.0918Z" stroke="#21394F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    // </svg>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 5.0918H8C4.13401 5.0918 1 8.2258 1 12.0918C1 15.9578 4.13401 19.0918 8 19.0918H16C19.866 19.0918 23 15.9578 23 12.0918C23 8.2258 19.866 5.0918 16 5.0918Z" fill="#63C2FF" stroke="#63C2FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>#
    <path d="M16 15.0918C17.6569 15.0918 19 13.7487 19 12.0918C19 10.4349 17.6569 9.0918 16 9.0918C14.3431 9.0918 13 10.4349 13 12.0918C13 13.7487 14.3431 15.0918 16 15.0918Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>


);

export default ToggleRightIcon
