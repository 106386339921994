import React from 'react';

//MUI Components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";

//Styles
import styles from "../../styles/currentAdminReport.module.css";

const PeriodSection = (props:any) => {

    const {frequencyData, handlePeriodChange, periodType, everyInterval, dateVal, handlePeriodSectionFromChange, reportId} = props;


    const periodTypeList = [
        {
          value: "1",
          label: `All data up to and including ${dateVal}`,
        },
        {
          value: "2",
          label: <span style={{display:'flex'}}>Every <b>&nbsp; {everyInterval} &nbsp;</b> up to {dateVal}</span>,
        },

        {
          value: "3",
          label:
          <span style={{display:'flex'}}>
            <p style={{color:'rgba(0,0,0,0.8)'}}>From &nbsp;</p>
           
                  {/* { <TextField
                    name="endDate"
                    variant="filled"
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    onChange={() => {}}
                  /> } */}
                  { <div className={styles.inputContainerDatePicker}>
                  <TextField
                    name={"periodFromDate"}
                    label=""
                    variant="outlined"
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    onChange={handlePeriodSectionFromChange}
                    defaultValue={frequencyData.periodFromDate}
                  />
                </div> }
              <br />
            <p style={{display:'block',color:'rgba(0,0,0,0.8)'}}>&nbsp; and including {dateVal} </p>
            </span>,
        },
      ];

    return (
        <>
        {(reportId === 19 || reportId === 22) && frequencyData !== null && frequencyData !== undefined && frequencyData.frequencyValue === "M" && (
            <FormControl component="fieldset" style={{ margin: "10px", width: '98%'  }}>
              <FormLabel component="legend">Period</FormLabel>
              <div className={styles.childContainer}>
                <RadioGroup
                  name="periodType"
                  value={periodType}
                  onChange={handlePeriodChange}
                >
                  {periodTypeList.map((item) => {
                    return (
                      <FormControlLabel
                        value={item.value}
                        control={<Radio color="primary" />}
                        label={item.label}
                      />
                    );
                  })}
                </RadioGroup>
              </div>
            </FormControl>
          )}
          </>
    )
}

export default PeriodSection;