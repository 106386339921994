import React from 'react';
import styles from '../styles/loader.module.css';

interface Props {
  color: string
}

export const LoaderAnimation = ({color}: Props): JSX.Element => {

  return (
      <div>
        <div className={styles.ldsripple}>
          <div style={{ borderColor: color }}></div>
          <div style={{ borderColor: color }}></div>
        </div>
      </div>
  );
};

export default LoaderAnimation;