import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
// Contexts
// import { SessionContext } from '../../contexts/SessionContext';
// import { MyTrainingListContext } from '../../contexts/MyTrainingListContext';
import { InfopathContext } from '../../contexts/InfopathContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
// MUI components
import CircularProgress from '@material-ui/core/CircularProgress';
// Style
import styles from '../../styles/infopathQuiz.module.css'

interface Props {
    increasePageCount: () => void
    decreasePageCount: () => void 
    submitScore: () => void
    currentPageNumber: number
    currentSelection: number | null
    questionCount: number
}

export const InfopathPageNavigation = ({increasePageCount, decreasePageCount, submitScore, currentPageNumber, questionCount, currentSelection}: Props): JSX.Element => {
    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
    const { ipQuizSubmissionStillInProgress, setIpQuizSubmissionStillInProgress, quizStillInProgress, hasLoadedSessionData } = useContext(InfopathContext)
    const onFirstPage = (currentPageNumber === 0);
    const onLastPage = (currentPageNumber === (questionCount + 1));
    const selectionMade = (currentSelection !== null && currentSelection !== 0);

    const handleClick = async () => {
      setIpQuizSubmissionStillInProgress(true);
      await submitScore();
    }

    return (
      <div className={styles.navButtonsContainer}>
        <div className={styles.loaderContainer}>
          <CircularProgress 
          style={{ width: ((quizStillInProgress || ipQuizSubmissionStillInProgress || !hasLoadedSessionData) ? "20px" : "0px") }}
          color="primary" 
          size="10"/>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={decreasePageCount}
          disabled={onFirstPage || quizStillInProgress || ipQuizSubmissionStillInProgress || !hasLoadedSessionData}
        >
          { `◀ ${allResourcesToTranslations.back}`}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={increasePageCount}
          disabled={onLastPage || quizStillInProgress || ipQuizSubmissionStillInProgress || (!onFirstPage && !selectionMade) || !hasLoadedSessionData}
        >
           { `${allResourcesToTranslations.next} ▶`}
        </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!onLastPage || quizStillInProgress || ipQuizSubmissionStillInProgress || !hasLoadedSessionData}
            onClick={() => handleClick()}
          >
            {allResourcesToTranslations.submit}
          </Button>
      </div>
    );
}

export default InfopathPageNavigation
