import React, { useState, useEffect, useContext } from "react";
import { UnitTreeItem, UnitMoveDetails } from "../../../globalTypes";

//LIBS
import { Steps } from "intro.js-react";

//ICONS
import ProceedIcon from "../../../CustomIcons/ProceedIcon";
import ToggleLeftIcon from "../../../CustomIcons/ToggleLeftIcon";
import ToggleRightIcon from "../../../CustomIcons/ToggleRightIcon";
import GuideIcon from "../../../CustomIcons/GuideIcon";

//MUI
import {   
         Button, CircularProgress
} from "@material-ui/core";
import CloseWhiteIcon from "@material-ui/icons/Close";

//Contexts
import { UIContext } from "../../../contexts/UIContext";
import { LoaderContext } from "../../../contexts/LoaderContext";

//API Functions
import { getRelevantUnitTree } from "../../../API/GetRelevantUnitTree";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import moveUnit from "../../../API/Administration/Unit/MoveUnit";

// Components
import Modal from '../../../Components/Modal';
import UnbrandedCard from "../../../Components/UnbrandedCard";
import LoaderContainer from "../../../Components/LoaderContainer";
import CustomTooltip from "../../../Components/CustomTooltip";

//Styles
import styles from "../../../styles/moveUnit.module.css";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { useHistory } from "react-router-dom";

import RadioUnitsForMoveWithDND from "./RadioUnitsForMoveWithDND";
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';


const MoveUnitWithDND = () => {
  const { setMessage } = useContext(LoaderContext);
  const history = useHistory();
  const {
    translations: { allResourcesToTranslations }, pageTitles
  } = useContext(TranslationsContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute, IsEbookEnabled, EnableActivityLogs, timeZoneId } =
    useContext(UIContext);
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
      .toLowerCase()
      .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";

  const [units, setUnits] = useState<Array<UnitTreeItem>>([]);
  const [unitsTarget, setUnitsTarget] = useState<Array<UnitTreeItem>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isResetClicked, setisResetClicked] = useState<boolean>(false); ;
  const [isSaving, setIsSaving] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);  
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);

  const [selectedUnitCallbackForSource, setSelectedUnitCallbackForSource] = useState<number>(0);
  const [selectedunitlevelForSource, setSelectedunitlevelForSource] = useState<any>("toplevel");
  const [selectedUnitCallbackForTarget, setSelectedUnitCallbackForTarget] = useState<number>(0);
  const [isTopLevelSelectedAfterMove, setIsTopLevelSelectedAfterMove] = useState<boolean>(false);
  
  const [moveUnitStarted, setMoveUnitStarted] = useState<boolean>(false);    
  const [windowMidModelMessage, setWindowMidModelMessage] = useState<string>("");       
  const [windowMidModelMessages, setWindowMidModelMessages] = useState<string[]>([]);    
  const [showWindowMidWarningModalForConfirmation, setShowWindowMidWarningModalForConfirmation] = useState<boolean>(false);   
  const [switchToOppositeOfDragAndDrop, setSwitchToOppositeOfDragAndDrop] = useState<boolean>(false);    
    
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: "#switchToNormalUnitMove",
        title: allResourcesToTranslations.unitmoveswitchtotreeselectionguidemetitle,
        intro: allResourcesToTranslations.unitmoveswitchtotreeselectionguidememsg,
      },
      {
        element: "#unitsMoveTreeWithDND",
        title: allResourcesToTranslations.unitmovecommonguidemetitle,
        intro: allResourcesToTranslations.unitmovecommonguidememsg,
      },     
    ],
  });

  const setGuideMe = () => {
    let updateSteps = [...intro.steps];
    setIntro({ ...intro, steps: [...updateSteps] });
  };

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  // const handleMoveAsTopLevelUnit = (isTopLevel: boolean) => {
  //   setIsTopLevelSelectedAfterMove(isTopLevel);
  // }

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  // const handleSubmit = () => {
  //   setWindowMidModelMessage(allResourcesToTranslations.unitmoveconfirmationmessage);
  //   setShowWindowMidWarningModalForConfirmation(true);
  //   setMoveUnitStarted(true);
  // };
  


  const handleDragEndOnUnitTreeLocal = (fromUnitID: number, toUnitID: number, isToTopLevel: boolean) => {
    if (fromUnitID > 0 && (isToTopLevel || toUnitID > 0)) {
      let updatedUnits = units;
      let collectedDetailsOuter: UnitMoveDetails = { fromUnitName: "", toUnitName: "", isSourceFound: false, isTargetFound: false, isBothFound: false}
      const collectUnitNames = (unitArray: Array<UnitTreeItem> | null, collectedDetails: UnitMoveDetails) => {
        if (unitArray === null) return collectedDetails; //Break if no children
        if (unitArray.length === 0) return collectedDetails;
        for (let idx = 0; idx < unitArray.length; idx++) {
          let curUnit = unitArray[idx];
          if (!collectedDetails.isSourceFound && curUnit != null && curUnit.unitID == fromUnitID) {
            collectedDetails.isSourceFound = true;
            collectedDetails.fromUnitName = curUnit.name;
            if (isToTopLevel) {
              collectedDetails.isBothFound = true;
              break;
            }
            else if (collectedDetails.isTargetFound) {
              collectedDetails.isBothFound = true;
              break;
            }
          }
          if (!isToTopLevel && !collectedDetails.isTargetFound && curUnit != null && curUnit.unitID == toUnitID) {
            collectedDetails.toUnitName = curUnit.name;
            collectedDetails.isTargetFound = true;
            if (collectedDetails.isSourceFound) {
              collectedDetails.isBothFound = true;
              break;
            }
          }
          if (!!curUnit.childUnitTreeItems && curUnit.childUnitTreeItems.length > 0) {
            let collectedDetailsLoc = collectUnitNames(curUnit.childUnitTreeItems, collectedDetails);
            collectedDetails.fromUnitName = collectedDetailsLoc.fromUnitName;
            collectedDetails.toUnitName = collectedDetailsLoc.toUnitName;
            collectedDetails.isSourceFound = collectedDetailsLoc.isSourceFound;
            collectedDetails.isTargetFound = collectedDetailsLoc.isTargetFound;
            collectedDetails.isBothFound = collectedDetailsLoc.isBothFound;
            if (collectedDetails.isBothFound) {
              break;
            }
          }
        }
        return collectedDetails;
      };
      let collectedDetailsLocOuter = collectUnitNames(updatedUnits, collectedDetailsOuter);
      collectedDetailsOuter.fromUnitName = collectedDetailsLocOuter.fromUnitName;
      collectedDetailsOuter.toUnitName = collectedDetailsLocOuter.toUnitName;
      collectedDetailsOuter.isSourceFound = collectedDetailsLocOuter.isSourceFound;
      collectedDetailsOuter.isTargetFound = collectedDetailsLocOuter.isTargetFound;
      collectedDetailsOuter.isBothFound = collectedDetailsLocOuter.isBothFound;
      let unitMoveNamesPrefix = "";
      if (collectedDetailsOuter.isBothFound) {
        unitMoveNamesPrefix = isToTopLevel ? "Unit '" + collectedDetailsOuter.fromUnitName + "' is moved as a Top Level unit. " : "Unit '" + collectedDetailsOuter.fromUnitName + "' will be moved as a child of unit '" + collectedDetailsOuter.toUnitName + "'. ";
      }
      setSelectedUnitCallbackForSource(fromUnitID);
      setSelectedUnitCallbackForTarget(toUnitID);
      setIsTopLevelSelectedAfterMove(isToTopLevel);
      let combined = unitMoveNamesPrefix + allResourcesToTranslations.unitmoveconfirmationmessage;
      setWindowMidModelMessages(combined.trim().split(".").filter((e) => !!e && e.trim() != ""));
      setShowWindowMidWarningModalForConfirmation(true);
      setMoveUnitStarted(true);
    }
  }

  const handleWindowMidModalForConfirmation = (value: boolean) => {
      setShowWindowMidWarningModalForConfirmation(value);
  }

  const handleCancelFromWindowMidModalForConfirmation = () => {
      setShowWindowMidWarningModalForConfirmation(false);
      setMoveUnitStarted(false);
  }

  const handleSaveConfirmProceedFromWindowMidModalForConfirmation = () => {
      setisResetClicked(false);
      setShowWindowMidWarningModalForConfirmation(false);
      if (moveUnitStarted) {            
        if (isTopLevelSelectedAfterMove) {
          if (selectedUnitCallbackForSource <= 0) {
            setShowWarningModal(true);
            setModalPopupMessage(allResourcesToTranslations.invalidunittomoveerror);
            setHasErrored(true);
            return;
          }
        }
        else {
          if (selectedUnitCallbackForSource <= 0) {
            setShowWarningModal(true);
            setModalPopupMessage(allResourcesToTranslations.invalidunittomoveerror);
            setHasErrored(true);
            return;
          }
          if (selectedUnitCallbackForTarget <= 0) {
            setShowWarningModal(true);
            setModalPopupMessage(allResourcesToTranslations.invalidunittargetformoveerror);
            setHasErrored(true);
            return;
          }
          if (selectedUnitCallbackForSource == selectedUnitCallbackForTarget) {
            setShowWarningModal(true);
            setModalPopupMessage(allResourcesToTranslations.unitstobedifferenterror);
            setHasErrored(true);
            return;
          }
        }
        setIsSaving(true);
        setSaveClicked(true);
        moveUnit(
          oidcUser.access_token,
          userId,
          orgDomain,
          parseInt(orgId),
          selectedUnitCallbackForSource,
          selectedUnitCallbackForTarget,
          isTopLevelSelectedAfterMove
        ).then((unitMoveRes) => {
          if (!!unitMoveRes && unitMoveRes.isSuccess) {
            setShowWarningModal(true);
            setModalPopupMessage(allResourcesToTranslations.unitmovedsuccessmessage);
            setSaveSuccess(true);
            setHasErrored(false);
            setIsSaving(false);
            setSaveClicked(false); 
            setMoveUnitStarted(false);
            refreshPage();
            setSelectedUnitCallbackForSource(0);
            setSelectedUnitCallbackForTarget(0);
            setIsTopLevelSelectedAfterMove(false);
            setisResetClicked(true);
          }
          else {
            setShowWarningModal(true);
            setModalPopupMessage(allResourcesToTranslations.unitmovedfailuremessage + "! " + 
                (!!unitMoveRes && !!unitMoveRes.error && !!unitMoveRes.error.trim() ? allResourcesToTranslations.cpdmsgreason + ": " + unitMoveRes.error : ""));
            setHasErrored(true);
            setIsSaving(false);
            setSaveClicked(false);
            setMoveUnitStarted(false);
          }
        }).catch((error) => {
            console.error(error);
            setShowWarningModal(true);
            setModalPopupMessage(allResourcesToTranslations.unitmovedfailuremessage + "! " + 
                (!!error && !!error.trim() ? allResourcesToTranslations.cpdmsgreason + ": " + error : ""));
            setHasErrored(true);
            setIsSaving(false);
            setSaveClicked(false);
            setMoveUnitStarted(false);
        });
      }
  }

  const refreshPage = (isLoadingToUpdate : boolean = false) => {
    if (isLoadingToUpdate) {
      setLoading(true);
    }
    getRelevantUnitTree(
      oidcUser.access_token,
      userId,
      orgDomain,
      true,
      parseInt(orgId)
    ).then((unitTreeRes) => {
      if (!!unitTreeRes && unitTreeRes.isSuccess) {
        setUnits(unitTreeRes.unitTreeItems);
        if (isLoadingToUpdate) {
          setLoading(false);
        }
        setGuideMe();
      }
      else {
        console.log("Failed to get the relevant unit tree");
      }
    });
    getRelevantUnitTree(
      oidcUser.access_token,
      userId,
      orgDomain,
      true,
      parseInt(orgId)
    ).then((unitTreeRes) => {
      if (!!unitTreeRes && unitTreeRes.isSuccess) {
        setUnitsTarget(unitTreeRes.unitTreeItems);
        if (isLoadingToUpdate) {
          setLoading(false);
        }
        setGuideMe();
      }
      else {
        console.log("Failed to get the relevant unit tree");
      }
    });
  }

  const toggleSwitchToNormalUnitMove = () => {
    setSwitchToOppositeOfDragAndDrop(true);
    history.location.pathname = history.location.pathname.replace(`/moveunitdnd`, `/moveunit`);                
    history.push({
        pathname: ``
    });
  }

  useEffect(() => {
    setTitle(allResourcesToTranslations.moveunit);
    setTitleEnglish(pageTitles.moveunit);
    updateBackButtonRoute("");
    refreshPage(true);
  
  }, []);

  useEffect(() => {
    if (showWarningModal) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      }, 5000)
    }
  }, [showWarningModal])


  if (loading) return <LoaderContainer />;
  return (
    <>
      <div id="fullCard">
      <Modal modalOpen={showWindowMidWarningModalForConfirmation} setModalOpen={handleWindowMidModalForConfirmation} type="warning" hideCloseBtn={true}>
          <div className={styles.modalContentWrapper}>
              <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>Confirmation</h2>
              <div className={styles.inputContainer}>

                {!!windowMidModelMessage ? <p>{windowMidModelMessage}</p> : (!!windowMidModelMessages && windowMidModelMessages.length > 0 ? (
                  windowMidModelMessages.map((m, index) => (<><div>{m.trim() + ((index != (windowMidModelMessages.length-1)) ? "." : "")}</div><br /></>))
                ) : "") }
              </div>
              <div
                  style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                      top: "10px",
                  }}
              >
                  <CustomTooltip displayText={[allResourcesToTranslations.return]}>
                      <span className={styles.returnIcon} onClick={handleCancelFromWindowMidModalForConfirmation}>
                          <CloseWhiteIcon />
                      </span>
                  </CustomTooltip>
                  <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
                      <span className="saveData">
                          <Button
                              onClick={handleSaveConfirmProceedFromWindowMidModalForConfirmation}
                              variant="contained"
                              color="primary"
                              className="save"
                          >
                              <ProceedIcon />
                          </Button>
                      </span>
                  </CustomTooltip>
              </div>
          </div>
      </Modal>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }}/>
      <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific}>
          <GuideIcon />
        </span>
      </CustomTooltip>
      <UnbrandedCard>
      <div className={styles.mainWrapper}>
					<div className={styles.Wrapper}>           
              <div className={styles.unittreesection}>
                <div>
                  <span style={{marginLeft: "10px", float: "left"}} id="switchToNormalUnitMove">              
                    {/* <Checkbox disabled={false} id="switchToNormalUnitMoveCheck"
                                  size="small"
                                  color="primary"
                                  checked={switchToOppositeOfDragAndDrop}
                                  className={styles.checkBox}
                                  onClick={(e) => { toggleSwitchToNormalUnitMove(); }}
                              />  */}
                      <CustomTooltip displayText={[allResourcesToTranslations.switchtoselectionmove]}>
                         <span style={{marginTop: "-3px", float: "left"}} onClick={(e) => { toggleSwitchToNormalUnitMove(); }}>
                            <span style={{float: "left"}}><ToggleRightIcon /></span>
                            <span id="switchToNormalUnitMoveLabel" style={{fontSize: '14px', marginTop: "4px", marginLeft: "5px", float: "left"}}
                              onClick={(e) => { toggleSwitchToNormalUnitMove(); }}>
                                  {allResourcesToTranslations.moveunitmethodtoggle2label}</span> 
                          </span>
                      </CustomTooltip>
                  </span>   
                  <div className={styles.loaderContainer}>
                    <CircularProgress
                      style={{ width: moveUnitStarted ? "20px" : "0px", padding: "15px 10px" }}
                      color="primary"
                      size="10"/>
                  </div>
                  <RadioUnitsForMoveWithDND   isResetClicked={isResetClicked} 
                                                calledFrom="moveunit"
                                                givenUnits={units} 
                                                selectedUnitId={selectedUnitCallbackForSource}
                                                setSelectedUnitCallback={setSelectedUnitCallbackForSource} 
                                                setSelectedunitlevel={setSelectedunitlevelForSource}
                                                handleDragEndOnUnitTreeGiven={handleDragEndOnUnitTreeLocal}
                                        />
                </div>
              </div>    
              <div style={{ clear: "both" }}></div>
        </div>
      </div>
      </UnbrandedCard>
      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}      
      </div>
    </>
  );
};

export default MoveUnitWithDND;
