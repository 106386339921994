import axios from 'axios';
import { addError } from './AddError';

export const startLesson = async (token: string, userId: number, orgDomain: string, sessionId: string) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/usersessions/startlesson`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        lessonsessionid: sessionId
      },
    });
    const data = await response.data;
    if (!data) console.error("The lesson failed to start")
    return data;

  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "StartLesson.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "StartLesson.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "StartLesson.ts", 1)
    }
    return null;
  }
};

export default startLesson;