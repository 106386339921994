//React + Typescript
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from "react-router";

// React YouTube player
import ReactPlayer from 'react-player'

// Libsimport
import { useReactOidc } from '@axa-fr/react-oidc-context';

//Contexts
import { UIContext } from '../../../contexts/UIContext';
import { LoaderContext } from '../../../contexts/LoaderContext';
import { TranslationsContext } from '../../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';

//Intro Lib
import { Steps } from "intro.js-react";

//API Functions 
import { GetTipVideos } from '../../../API/Administration/Organisation/GetTipVideos';
import styles from "../../../styles/tipvideos.module.css";

//Components
import CustomTooltip from '../../../Components/CustomTooltip';
import LoaderContainer from '../../../Components/LoaderContainer';
import GuideIcon from '../../../CustomIcons/GuideIcon';
import TipVideosEditButtonIcon from '../../../CustomIcons/Administration/TipVideosEditButtonIcon';
import BrandedCard from '../../../Components/BrandedCard';

//MUI Components
import TextField from '@material-ui/core/TextField';

import { TipVideo } from '../../../globalTypes';
import { TablePagination } from '@material-ui/core';

const TipVideos = (props: any): JSX.Element => {
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext)
  const history = useHistory();
  const { color } = useContext(CorperateThemeContext).theme;
  const { setMessage } = useContext(LoaderContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection, showUnitAdminUI, showOrgAdminUI, showSuperAdminUI} = useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { oidcUser } = useReactOidc();
  const [tipVideos, setTipVideos] = useState<Array<TipVideo>>([]);
  const [showConfirmModalDelete, setshowConfirmModalDelete] = useState<boolean>(false);
  const [tipVideoFilter, setTipVideoFilter] = useState<string>("");

  const [rowsPerPage, setRowsPerPage] = React.useState<number>(8);
  const [page, setPage] = React.useState<number>(0);

  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  const language = useRef<string>("en-AU");
  
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: "#videos",
        title: allResourcesToTranslations.tvlistsectiontitle,
        intro: allResourcesToTranslations.tvlistsectionmsg
      },

    ]
  })

  const setGuideMe = () => {
    let updateSteps = []
      updateSteps.push(
        {
          element: ".tipVideoFilter",
          title: allResourcesToTranslations.tvfiltertitle,
          intro: allResourcesToTranslations.tvfiltermsg
        },
        {
          element: "#tipVideoManagement",
          title: allResourcesToTranslations.tvmanageredirecttitle,
          intro: allResourcesToTranslations.tvmanageredirectmsg
        },
        {
          element: "#videos",
          title: allResourcesToTranslations.tvlistsectiontitle,
          intro: allResourcesToTranslations.tvlistsectionmsg
        },
      )
      setIntro({ ...intro, steps: [...updateSteps] })
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };
  
  const areTipVideosManagedBySuperAdmin = () => {
    if (!!history.location.pathname && history.location.pathname.includes('/tipvideossuper')) {
      return true;
    }
    return false;
  }

  const loadTipVideos = () => {
    GetTipVideos(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((result: any) => {
      if (result.isSuccess && result.error == '') {
        if (result.tipVideos !== null) {
          //let isSAdmin = areTipVideosManagedBySuperAdmin();
          //let curOrgId = parseInt(orgId);
          //setTipVideos(isSAdmin ? [...result.tipVideos] : [...(result.tipVideos as TipVideo[]).filter(tv => !!tv && tv.orgID != null && tv.orgID == curOrgId)]);
          setTipVideos([...result.tipVideos]);
        }
      }
      else {
      }
      setLoading(false);
    });
  };

  const handleTipVideoManageRedirect = () => {
    history.location.pathname = history.location.pathname.replace(`/tipvideos`, `/managetipvideos`);                
    history.push({
        pathname: ``
    });
  }

  useEffect(() => {
    setGuideMe();
  }, [])

  useEffect(() => {
    setGuideMe();
  }, [tipVideos])

  useEffect(() => {
    setTitle(allResourcesToTranslations.tipvideos)
		setTitleEnglish(pageTitles.tipvideos);
    updateBackButtonRoute("");
    setLoading(true);
    loadTipVideos();
    return () => { };
  }, []);

  if (loading) return <LoaderContainer />;
  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }} />
      <CustomTooltip displayText={[allResourcesToTranslations.guideme]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific} style={{ right: '95px', top: '100px' }}>
          <GuideIcon />
        </span>
      </CustomTooltip>
      <BrandedCard>
        <div style={{padding: "25px"}}>
        <div className={`${styles.inputContainer}`}>
          <TextField className={`${styles.input} tipVideoFilter`} variant="outlined" label={allResourcesToTranslations.tipvideosearch}
              autoComplete="off" InputLabelProps={{ shrink: true, }}
              value={tipVideoFilter} onChange={(e) => { setTipVideoFilter(e.target.value); e.stopPropagation(); }} />
          {
            (showOrgAdminUI || showSuperAdminUI) &&
            <CustomTooltip displayText={[allResourcesToTranslations.tvgotomanage]}>
                <span id={"tipVideoManagement"} style={{float: "right"}} 
                      onClick={handleTipVideoManageRedirect}><TipVideosEditButtonIcon /></span>
            </CustomTooltip>
          }
        </div>
        <br />
        <div className={styles.mainWrapper} id={"videos"}>
          <span style={{fontFamily: "Open Sans, sans-serif", fontWeight: "bold", color: "#21394F"}}>{allResourcesToTranslations.tipvideostopheading}</span>
          <div style={{ width: '100%', clear: "both" }} >
            {tipVideos != null && tipVideos.length > 0 ? (
              <div>
                
                <div className={styles.paginationControlsContainer}>
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <TablePagination
                      rowsPerPageOptions={[1, 2, 4, 8, 16, 40, 120]}
                      component="div"
                      count={Math.round(tipVideos.length / 4)}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
                <div className={`${styles.tableSection}`}>
                  <div className={styles.tableContainer} >
                    {/* <BrandedCard>                     */}
                        {!!tipVideos && tipVideos.length > 0 ? tipVideos
                          .filter(tv => !!tv && ( (!!tipVideoFilter === false || tipVideoFilter.trim() == "") || 
                                        (!!tv.title && tv.title.trim().toLowerCase().includes(tipVideoFilter.trim().toLowerCase())) ||
                                        (!!tv.url && tv.url.trim().toLowerCase().includes(tipVideoFilter.trim().toLowerCase())) ||
                                        (!!tv.category && tv.category.trim().toLowerCase().includes(tipVideoFilter.trim().toLowerCase()))))
                          .slice(
                            page * (4 * rowsPerPage),
                            page * (4 * rowsPerPage) + (4 * rowsPerPage)
                          )
                          .map((result: TipVideo, i) => {
                            return (
                              <>
                                {/* <iframe id="ytplayer" width="340" height="260"
                                src={!!result && !!result.Url ? result.Url : "<empty>"}></iframe> */}
                                <div style={{float: "left", margin: "15px", borderRadius: "100px"}}>
                                  {
                                  (!!result && !!result.url) ? 
                                  <>
                                  <ReactPlayer id={"tipvideo-" + i.toString()}  key={"tipvideo-" + i.toString()} width="340px" height="260px" url={result.url} style={{borderRadius: "100px"}} /> 
                                  {/* { areTipVideosManagedBySuperAdmin() && <div style={{fontFamily: "'Open Sans', sans-serif", color: "#21394F", width: "100%", textAlign: "center", paddingTop: "10px"}}>{!!result.orgID ? result.orgName : "Default"}</div>} */}
                                  <div style={{fontFamily: "'Open Sans', sans-serif", color: "#21394F", width: "100%", textAlign: "center", paddingTop: "10px"}}>{!!result.orgID ? result.orgName : "Default"}</div>
                                  <div style={{fontFamily: "'Open Sans', sans-serif", fontWeight:"bold", color: "#21394F", width: "100%", textAlign: "center", paddingTop: "10px"}}>{result.category }</div>
                                  <div style={{fontFamily: "'Open Sans', sans-serif", color: "#21394F", width: "100%", textAlign: "center", paddingTop: "10px"}}>{result.title}</div>
                                  </>
                                  : 
                                  <span key={"tipvideoEmpty-" + i.toString()}>{"<empty>"}</span>
                                  }
                                </div>
                              </>
                            );
                          }) : <div style={{color: "red"}}>{allResourcesToTranslations.tvmnovideos}</div>}
                    {/* </BrandedCard> */}
                  </div>
                </div>
                <div className={styles.paginationAddedUsersControl}>
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <TablePagination
                      rowsPerPageOptions={[1, 2, 4, 8, 16, 40, 120]}
                      component="div"
                      count={Math.round(tipVideos.length / 4)}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              </div>
            ) : <div style={{color: "red"}}>{allResourcesToTranslations.tvmnovideos}</div>}
          </div>

        </div>
        </div>
      </BrandedCard>
    </>
  );
};

export default TipVideos;
