//React + Typescript
import React, { useEffect, useState, useContext, useRef } from 'react';
import { User, Language, UnitTreeItem, CourseTreeItem, CPDItem } from '../../globalTypes';
import { useHistory } from 'react-router-dom';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
import moment from 'moment';
import { Steps, Hints } from "intro.js-react";
//Contexts
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { UIContext } from '../../contexts/UIContext';
import { CPDPanelContext } from '../../contexts/CPDPanelContext';
//API Functions
import { addError } from '../../API/AddError'
import { getClassificationListByType } from '../../API/GetClassificationListByType';
import getClassificationType from '../../API/GetClassificationType';
import getOrgPolicies from "../../API/GetOrgPolicies";
import getExternalCertificateBasicDetails from "../../API/GetExternalCertificateBasicDetails";
import getOrgCPDProfiles from "../../API/GetOrgCPDProfile";
import { getUserCPDs } from '../../API/UserCPDs';

//Components
import LoaderContainer from '../../Components/LoaderContainer';
//import MUICustomSelect from '../../Components/MUICustomSelect';
import ResetIcon from "../../CustomIcons/ResetIcon";
import BrandedCard from '../../Components/BrandedCard'
import CustomTooltip from "../../Components/CustomTooltip";
import GuideIcon from '../../CustomIcons/GuideIcon';
import EmailPreviewIcon from '../../CustomIcons/EmailPreviewIcon';
import loadingGif from '../../CustomIcons/GIFs/loading_dots_blue.gif';
//MUI Components
import { makeStyles, Checkbox } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
//Styles
import styles from '../../styles/currentAdminReport.module.css'
import getFullEmailReport from '../../API/GetEmailReport';
import Units from '../ReportCommonComponents/Units';
import Courses from '../ReportCommonComponents/Courses';
import CommonPopupModalForResult from '../../Components/CommonPopupModalForResult';
import { getUserModulesOfQuizStatusPending } from '../../API/GetUserModulesOfQuizStatusPending'
import CircularProgress from '@material-ui/core/CircularProgress';
import PolicySelection from "../PolicyBuilderReport/PolicySelection";
import ExternalCertificateSelection from "../ExternalCertificates/ExternalCertificateSelection";

// Infor/Error/Warning/Settings Pop-up
import InfoPopUp from "../../Components/InfoPopUp";

const EmailReportSearch = (props: any) => { 
    const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
    const { handlePreviewEmailReport, isSearchClicked, units, courseList, setPageReset } = props;
    const [classificationList, setClassificationList] = useState<Array<any>>([]);
    const [isResetClicked, setisResetClicked] = useState<boolean>(false);
    const [filterUnitName, setFilterUnitName] = useState("");
    const [selectedProfileID, setSelectedProfileID] = useState<any>(-1);
    const [selectedProfileName, setSelectedProfileName] = useState<string>("");
    const [selectedCPDProfilePeriodList, setSelectedCPDProfilePeriodList] = useState<any>([]);
    const [orgCPDProfileResult, setOrgCPDProfileResult] = useState<any>(null);  
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [filterCourseStatus, setFilterCourseStatus] = useState<any>(0);
    const [filterPolicyStatus, setFilterPolicyStatus] = useState<any>(0);
    const [filterExternalCertificateStatus, setFilterExternalCertificateStatus] = useState<any>(1);
    const [filterEmailReportType, setFilterEmailReportType] = useState<any>(0);
    const [selectedClassification, setSelectedClassification] = useState<any>({});
    const [selectedCPD, setSelectedCPD] = useState<any>({});
    const [cpdList, setCPDList] = useState<CPDItem[]>([]);
    const [cpdListToPass, setCPDListToPass] = useState<any[]>([]);
    const [groupingOption, setGroupingOption] = useState<boolean>(false);
    const [filterIsNewStarterOnly, setFilterIsNewStarterOnly] = useState<boolean>(false);
    const [recipientType, setRecipientType] = useState('users');
    const [filterFirstName, setFilterFirstName] = useState("");
    const [filterLastName,setFilterLastName] = useState("");
    const [filterUserName,setFilterUserName] = useState("");
    const [filterEmail,setFilterEmail] = useState("");
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
    const [modalPopupMessage, setModalPopupMessage] = useState('');
    const [hasErrored, setHasErrored] = useState<boolean>(false);
    const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
    const [isERTypeFocussed, setIsERTypeFocussed] = React.useState(false);
    const [isCourseStatusFocussed, setIsCourseStatusFocussed] = React.useState(false);
    const [isPolicyStatusFocussed, setIsPolicyStatusFocussed] = React.useState(false);
    const [isGroupingOptionFocussed, setIsGroupingOptionFocussed] = React.useState(false);
    const [isCPDProfileFocussed, setIsCPDProfileFocussed] = React.useState(false);
    const [isCPDLoadingNow, setCPDLoadingNow] = React.useState(false);
    //const [isERTypeFocussed, setIsERTypeFocussed] = React.useState(false);
    
    const [searchPolicy, setSearchPolicy] = useState("");
    const [orgPolicyOptions, setOrgPolicyOptions] = useState<any>([]);

    const [searchExternalCertificate, setSearchExternalCertificate] = useState("");
    const [orgExternalCertificateOptions, setOrgExternalCertificateOptions] = useState<any>([]);

    const [loading, setLoading] = useState<boolean>(false);

    const { setMessage } = useContext(LoaderContext);
    const {IsCPDEnabled, IsPolicyEnabled, EnableActivityLogs, IsExtCertEnabled} = useContext(UIContext);
    
    const [isToContinueRunReport, setIsToContinueRunReport] = useState<boolean>(false);

    const { oidcUser } = useReactOidc();
    const { profile: { user_id, website } } = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const [intro, setIntro] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
            {
                element: ".unitFilterButtons",
                title: allResourcesToTranslations.emrepgmunitst, 
                intro: allResourcesToTranslations.emrepgmunitsm, 
            },
        ]
    })

    const onExit = () => {
        setIntro({ ...intro, stepsEnabled: false });
    };

    const setGuideMe = (groupingOption: boolean) => {
        let updateSteps = [];
            updateSteps.push({
                element: ".emailReportType",
                title: allResourcesToTranslations.emrepgmemtt, 
                intro: allResourcesToTranslations.emrepgmemtm2, 
            });
            updateSteps.push({
                element: ".unitFilterButtons",
                title: allResourcesToTranslations.emrepgmunitst, 
                intro: allResourcesToTranslations.emrepgmunitsm, 
            });
            updateSteps.push({
                element: ".unitFilterSearch",
                title: allResourcesToTranslations.unitfiltersearchtitle, 
                intro: allResourcesToTranslations.unitfiltersearch,
            });
            if (parseInt(filterEmailReportType.toString()) == 0)
            {
                updateSteps.push(
                {
                    element: (units === null || units.length <= 10) === true ? ".courseFilterButtonsnew" : ".courseFilterButtons",
                    title: allResourcesToTranslations.emrepgmcst, 
                    intro: allResourcesToTranslations.emrepgmcsm,
                },
                {
                    element: (units === null || units.length <= 10) === true ? ".courseFilterSearchnew" : ".courseFilterSearch",
                    title: allResourcesToTranslations.emrepgmfcst, 
                    intro: allResourcesToTranslations.emrepgmfcsm,
                },
                {
                    element: ".courseStatus",
                    title: allResourcesToTranslations.emrepgmfcstt, 
                    intro: allResourcesToTranslations.emrepgmfcstm,
                });
            }
            if (parseInt(filterEmailReportType.toString()) == 1)
            {
                updateSteps.push(
                // {
                //     element: (units === null || units.length <= 10) ? ".policyFilterButtons" : ".policyFilterButtons2",
                //     title: allResourcesToTranslations.policies, 
                //     intro: allResourcesToTranslations.emrepgmpsm,
                // },
                // {
                //     element: (units === null || units.length <= 10) ? ".policySearch" : ".policySearch2",
                //     title: allResourcesToTranslations.emrepgmfpst, 
                //     intro: allResourcesToTranslations.emrepgmfpsm,
                // },
                {
                    element: (units === null || units.length <= 10) ? "#policySectionFull" : "#policySectionFull2",
                    title: allResourcesToTranslations.emrepgmfpfst, 
                    intro: allResourcesToTranslations.emrepgmfpfsm,
                },
                {
                    element: ".policyStatus",
                    title: allResourcesToTranslations.emrepgmfpstt, 
                    intro: allResourcesToTranslations.emrepgmfpstm,
                });
            }
            if (parseInt(filterEmailReportType.toString()) == 4)
            {
                updateSteps.push(
                // {
                //     element: (units === null || units.length <= 10) ? "#externalCertificateFilterButtons1" : "#externalCertificateFilterButtons2",
                //     title: allResourcesToTranslations.extcertificate, 
                //     intro: allResourcesToTranslations.emrepgmecsm,
                // },
                // {
                //     element:  (units === null || units.length <= 10) ? "#externalCertificateSearch1" : "#externalCertificateSearch2",
                //     title: allResourcesToTranslations.emrepgmfecst, 
                //     intro: allResourcesToTranslations.emrepgmfecsm,
                // },
                {
                    element: (units === null || units.length <= 10) ? "#extCertSectionFull" : "#extCertSectionFull2",
                    title: allResourcesToTranslations.emrepgmfecfst, 
                    intro: allResourcesToTranslations.emrepgmfecfsm,
                },
                {
                    element: "#extCertStatus2",
                    title: allResourcesToTranslations.emrepgmfecstt, 
                    intro: allResourcesToTranslations.emrepgmfecstm,
                });
            }
            if (parseInt(filterEmailReportType.toString()) <= 1)
            {
                updateSteps.push(
                {
                    element: ".dateFrom",
                    title: allResourcesToTranslations.emrepgmfdft, 
                    intro: (parseInt(filterEmailReportType.toString()) == 1) ? allResourcesToTranslations.emrepgmfdfma : allResourcesToTranslations.emrepgmfdfm,
                },
                {
                    element: ".dateTo",
                    title: allResourcesToTranslations.emrepgmfdtt, 
                    intro: (parseInt(filterEmailReportType.toString()) == 1) ? allResourcesToTranslations.emrepgmfdtma : allResourcesToTranslations.emrepgmfdtm,
                });
            }
            if (groupingOption)
            {
                updateSteps.push({
                    element: ".groupby",
                    title: allResourcesToTranslations.emrepgmfct, 
                    intro: allResourcesToTranslations.emrepgmfcm,
                });
            }
            updateSteps.push(  
            {
                element:  ".firstName",
                title: allResourcesToTranslations.emrepgmfnt, 
                intro: allResourcesToTranslations.firstnamemsg,
            } ,
            {
                element:  ".lastName",
                title: allResourcesToTranslations.emrepgmlnt, 
                intro: allResourcesToTranslations.lastnamemsg,
            } ,
            {
                element:  ".userName",
                title: allResourcesToTranslations.emrepgmunt, 
                intro: allResourcesToTranslations.usernamemsg,
            } ,
            {
                element:  ".email",
                title: allResourcesToTranslations.emrepgmfet, 
                intro: allResourcesToTranslations.emailmsg,
            }           
            );
            if (parseInt(filterEmailReportType.toString()) <= 1 || parseInt(filterEmailReportType.toString()) == 4)
            {
                updateSteps.push(
                {
                    element: ".recipient",
                    title: allResourcesToTranslations.emrepgmfrtt, 
                    intro: allResourcesToTranslations.emrepgmfrtm,
                }        
                );
            }
            if (parseInt(filterEmailReportType.toString()) == 3)
            {
                updateSteps.push(
                {
                    element: "#isNewStartersOnly",
                    title: allResourcesToTranslations.emrepgmfnst, 
                    intro: allResourcesToTranslations.emrepgmfnsm,
                }        
                );
            }
            if (parseInt(filterEmailReportType.toString()) == 2)
            {
                updateSteps.push(
                {
                    element: ".cpdProfiles",
                    title: allResourcesToTranslations.emrepgmfcpt, 
                    intro: allResourcesToTranslations.emrepgmfcpm,
                }        
                );
            }
            updateSteps.push(
            {
                element: ".runReport",
                title: allResourcesToTranslations.emrepgmbpt, 
                intro: allResourcesToTranslations.emrepgmbpm,
            },
            {
                element: ".resetAll",
                title: allResourcesToTranslations.emrepgmrat, 
                intro: allResourcesToTranslations.emrepgmram,
            }            
            );
        setIntro({ ...intro, steps: [...updateSteps] })
    };

    const resetAll = () => {
        setRecipientType('users');
        setisResetClicked(!isResetClicked);

        setFromDate('');
        setToDate('');

        setFilterCourseStatus(0);
        setFilterPolicyStatus(0);
        setFilterEmailReportType(0);
        setSelectedClassification({ value: "0", classificationID: 0, text: allResourcesToTranslations.emrepgmpsel });
        setSelectedCPD({ value: "0", cpdID: 0, text: allResourcesToTranslations.emrepgmpsel });
    }

    const handleClosePopup = () => {
        setShowWarningModal(false);
        if (isToContinueRunReport) {
            doTheActualRunOfERSearch();
            setIsToContinueRunReport(false);
        }
    };

    const getValidClassificationID = (selectedClassification: any) => {
        var classID: number = 0;
        if ((!!selectedClassification) && (!!selectedClassification.classificationID)) {
            classID = ((typeof selectedClassification.classificationID) !== 'number') ?
                parseInt(selectedClassification.classificationID.toString()) :
                selectedClassification.classificationID;
        }
        return classID;
    }

    const getStatus = (status: string) => {
        switch (status) {
            case "0":
                return "Incomplete"
            case "1":
                return "Complete"
            case "2":
                return "Fail"
            case "3":
                return "Not Started"
            case "4":
                return "Expired (Time Elapsed)"
            case "5":
                return "Expired (New Content)"
            default:
                return ""
        }
    }

    const doTheActualRunOfERSearch = () => {
        setIsFetchingData(true);
        let unitIds: Array<number> = [];
        let allUnitIds: Array<number> = [];
        let courseIds: Array<number> = [];
        let allCourseIds: Array<number> = [];
        let arrUnitNames: Array<string> = [];
        let arrCourseNames: Array<string> = [];

        let strUnitNames: string = "";
        let strCourseNames: string = "";

        const nextDate = new Date();
        nextDate.setDate(nextDate.getDate() + 1);

        const filterDateFrom = (fromDate === null || fromDate === undefined || fromDate.length < 8) ? "1990-01-01" : fromDate;
        const filterDateTo = (toDate === null || toDate === undefined || toDate.length < 8) ? formatDate(nextDate) : toDate;

        const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) { 
                    if (filterUnitName !== "") {
                        if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
                        unitIds.push(unit.unitID);
                        arrUnitNames.push(unit.name);
                        }
                    } else {
                        unitIds.push(unit.unitID);
                        arrUnitNames.push(unit.name);
                    }
                }
                allUnitIds.push(unit.unitID);
                getSelectedUnitIds(unit.childUnitTreeItems);
            }
        }
        getSelectedUnitIds(units)

        const getSelectedCourses = () => {
            for (let i = 0; i < courseList.length; i++) {
                if (courseList[i].isSelected) {
                    courseIds.push(courseList[i].courseID);
                    arrCourseNames.push(courseList[i].name);
                }
                allCourseIds.push(courseList[i].courseID);
            }
        }
        getSelectedCourses();

        strUnitNames = (units.length === arrUnitNames.length) || arrUnitNames.length === 0 ? "ALL UNITS" : arrUnitNames.join(",");
        strCourseNames = (courseList.length === arrCourseNames.length) || arrCourseNames.length === 0 ? "ALL COURSES" : arrCourseNames.join(",");
        setMessage(allResourcesToTranslations.fetchingyourreport);
        addError(oidcUser.access_token, userId, orgDomain, "Started Email Report in NEWSALT ClientApp", null, "EmailReport/Search.tsx", 6, EnableActivityLogs);

        let dynSelectedPolicyIDs = (!!orgPolicyOptions ? 
            ((orgPolicyOptions.some((op : any) => op.isSelected)) ? 
                orgPolicyOptions.filter((op : any) => op.isSelected).map((op : any) => op.policyID.toString()).join(",")
                :
                orgPolicyOptions.map((op : any) => op.policyID.toString()).join(",")
            ) : 
            '');
        let CourseQuizStatus = getStatus(filterCourseStatus.toString());

        let dynSelectedExternalCertificateIDs = (!!orgExternalCertificateOptions ? 
            ((orgExternalCertificateOptions.some((op : any) => op.isSelected)) ? 
            orgExternalCertificateOptions.filter((op : any) => op.isSelected).map((op : any) => op.externalCertificateId.toString()).join(",")
                :
                orgExternalCertificateOptions.map((op : any) => op.externalCertificateId.toString()).join(",")
            ) : 
            '');

        getFullEmailReport(oidcUser.access_token, userId, orgDomain, parseInt(orgId), unitIds.length > 0 ? unitIds.toString() : allUnitIds.toString(),
            courseIds.length > 0 ? courseIds.toString() : allCourseIds.toString(), getValidClassificationID(selectedClassification),
            ((fromDate === null || fromDate === undefined || fromDate.length < 8) ? "1990-01-01" : fromDate),
            ((toDate === null || toDate === undefined || toDate.length < 8) ? formatDate(nextDate) : toDate),
            parseInt(filterCourseStatus), recipientType === "admins" ? 1 : 2, true, 
            filterFirstName, filterLastName, filterUserName, filterEmail, parseInt(filterEmailReportType), 
            parseInt(selectedCPD.value), dynSelectedPolicyIDs, parseInt(filterPolicyStatus), 
            1, //Always 1 for the incomplete CPD profiles
            filterIsNewStarterOnly, dynSelectedExternalCertificateIDs, parseInt(filterExternalCertificateStatus)).then((reportRes) => {
                setIsFetchingData(false);
                if (reportRes.isSuccess) {
                    if (reportRes.emailReportItem !== null && reportRes.emailReportItem.length > 0) {
                        isSearchClicked(true);
                        handlePreviewEmailReport({
                            selectedEmailReportType: parseInt(filterEmailReportType),
                            selectedUnitIds: unitIds.length > 0 ? unitIds.toString() : allUnitIds.toString(),
                            selectedCourseIds: courseIds.length > 0 ? courseIds.toString() : allCourseIds.toString(),
                            selectedUnitName: strUnitNames,
                            selectedCourseName: strCourseNames,
                            selectedcourseQuizStatus: CourseQuizStatus.toUpperCase(),
                            courseQuizStatusId: parseInt(filterCourseStatus),
                            selectedRecipients: recipientType.toUpperCase(),
                            selectedGrouping: (!!selectedClassification === false || !!selectedClassification.text === false || selectedClassification.text.trim() === allResourcesToTranslations.emrepgmpsel) ? "N/A" : selectedClassification.text.trim(),
                            selectedClassificationId: parseInt(selectedClassification.classificationID),
                            inputFilterFromDate: filterDateFrom,
                            inputFilterToDate: filterDateTo,
                            inputFilterFirstName: filterFirstName,
                            inputFilterLastName: filterLastName,
                            inputFilterUserName: filterUserName,
                            inputFilterEmail: filterEmail,
                            resultBodyItems: reportRes === null ? null : reportRes.emailReportBodyItem,
                            resultItems: (reportRes === null ? null : reportRes.emailReportItem),
                            resultError: (reportRes === null || reportRes === undefined || 
                                reportRes.isSuccess || reportRes.error === null || 
                                reportRes.error === undefined) ? "" : reportRes.error,

                            selectedPolicyIDs: dynSelectedPolicyIDs,
                            selectedPolicyStatus: filterPolicyStatus,
                            selectedCPDProfileStatus: 1, //Always 1 for the incomplete CPD profiles
                            selectedCPDProfileID: parseInt(selectedCPD.value),
                            selectedCPDProfileName: !!selectedCPD.text ? selectedCPD.text : "",
                            newStarterOnlyFlag: filterIsNewStarterOnly,
                            selectedExternalCertificateStatus: parseInt(filterExternalCertificateStatus),
                            selectedExternalCertificateIDs: dynSelectedExternalCertificateIDs                            
                        });
                    } else {
                        setShowWarningModal(true);
                        setModalPopupMessage('No records found with this selection. Please change the criteria and try again.');
                        setHasErrored(false);
                        return;
                    }
                } else {
                    setShowWarningModal(true);
                    setModalPopupMessage('Fetching users for this selection has failed');
                    setHasErrored(true);
                    return;
                }
            }).catch((error: any) => {
                setShowWarningModal(true);
                setModalPopupMessage(error);
                setHasErrored(true);
                return;
            });
    }

    const runReport = () => {
        if (filterCourseStatus != 0 && filterCourseStatus != 3){
            doTheActualRunOfERSearch();
            return;
        }

        setIsFetchingData(true);
        let unitIds: Array<number> = [];
        let allUnitIds: Array<number> = [];
        let courseIds: Array<number> = [];
        let allCourseIds: Array<number> = [];
        let arrUnitNames: Array<string> = [];
        let arrCourseNames: Array<string> = [];

        let strUnitNames: string = "";
        let strCourseNames: string = "";
        
        const firstName: string = oidcUser.profile.given_name ?? "";
        const lastName: string = oidcUser.profile.family_name ?? "";
        const email: string = oidcUser.profile.email ?? "";
        const userName: string = oidcUser.profile.user_name ?? "";

        const nextDate = new Date();
        nextDate.setDate(nextDate.getDate() + 1);
        const nextDateString = formatDate(nextDate);

        const filterDateFrom = (fromDate === null || fromDate === undefined || fromDate.length < 8) ? "1990-01-01" : fromDate;
        const filterDateTo = (toDate === null || toDate === undefined || toDate.length < 8) ? formatDate(nextDate) : toDate;

        const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) { 
                    if (filterUnitName !== "") {
                        if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
                            unitIds.push(unit.unitID);
                            arrUnitNames.push(unit.name);
                        }
                    } else {
                        unitIds.push(unit.unitID);
                        arrUnitNames.push(unit.name);
                    }
                }                
                allUnitIds.push(unit.unitID);
                getSelectedUnitIds(unit.childUnitTreeItems);
            }
        }
        getSelectedUnitIds(units)

        const getSelectedCourses = () => {
            for (let i = 0; i < courseList.length; i++) {
                if (courseList[i].isSelected) {
                    courseIds.push(courseList[i].courseID);
                    arrCourseNames.push(courseList[i].name);
                }
                allCourseIds.push(courseList[i].courseID);
            }
        }
        getSelectedCourses();

        strUnitNames = (units.length === arrUnitNames.length) || arrUnitNames.length === 0 ? "ALL UNITS" : arrUnitNames.join(",");
        strCourseNames = (courseList.length === arrCourseNames.length) || arrCourseNames.length === 0 ? "ALL COURSES" : arrCourseNames.join(",");
        //setLoading(true);
        let dynSelectedPolicyIDs = (!!orgPolicyOptions ? 
            ((orgPolicyOptions.some((op : any) => op.isSelected)) ? 
                orgPolicyOptions.filter((op : any) => op.isSelected).map((op : any) => op.policyID.toString()).join(",")
                :
                orgPolicyOptions.map((op : any) => op.policyID.toString()).join(",")
            ) : 
            '');
        let CourseQuizStatus = getStatus(filterCourseStatus.toString());

        // PENDING QUIZ STATUS CHECK
        setMessage(allResourcesToTranslations.emrepgmstm);
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started the check for the pending quiz status updates for the input in NEWSALT ClientApp", null, "EmailReport/Search.tsx", 6, EnableActivityLogs);
        
        if(filterEmailReportType === 0) {
        getUserModulesOfQuizStatusPending(
            oidcUser.access_token, userId, orgDomain, parseInt(orgId), 
            unitIds.length > 0 ? unitIds.toString() : allUnitIds.toString(), 
            (!!courseIds && courseIds.length > 0 ? 
                        courseIds.toString() : 
                        allCourseIds.toString()),
            firstName, lastName, userName, email,
            ((filterDateTo === null || filterDateTo === undefined || filterDateTo.trimLeft().trimRight() === "") ? nextDateString : filterDateTo),
            "Unit_User", "LAST_NAME", false,
            getValidClassificationID(selectedClassification), 
            filterFirstName, filterLastName, filterUserName, filterEmail
            ).then((reportRes) => {
                if (!!reportRes) {
                    if (reportRes.isSuccess && !!reportRes.error == false) {
                        if (!!reportRes.pendingItems && reportRes.pendingItems.length > 0) {
                            setShowWarningModal(true);
                            setHasErrored(false);
                            setModalPopupMessage("As there are '" + reportRes.pendingItems.length.toString() + 
                                "' pending quiz status updates, the results may not be complete until this processing has finished");
                            setIsToContinueRunReport(true);
                            setLoading(false);
                            return;
                        }
                        else {
                            doTheActualRunOfERSearch();
                            return;
                        }
                    }
                    else {
                        console.log("Checking the pending status updates failed");
                        doTheActualRunOfERSearch();
                        return;
                    }
                }
                else {
                    console.log("Checking the pending status updates failed");
                    doTheActualRunOfERSearch();
                    return;
                }
            }); 
        }
        else {
            doTheActualRunOfERSearch();
            return;
        }
    }

    const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
    {
        if (!isNaN(givenDate.getTime())) {
            const year = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(givenDate);
            const month = new Intl.DateTimeFormat('en-AU', { month: 'numeric' }).format(givenDate);
            const day = new Intl.DateTimeFormat('en-AU', { day: 'numeric' }).format(givenDate);

            return (year.toString() + "-" +
                (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" +
                (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
        }
        else {
            return "";
        }
    }

    const handleRecipientChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = (event.target as HTMLInputElement).value;
        setRecipientType(val);
    };



    const handleExternalCertificateOptionsUpdate = (data:any) => {  
      setOrgExternalCertificateOptions(data);
    }; 
  
    const handleSelectAllExternalCertificates = () => {
      if(orgExternalCertificateOptions.length != 0) {
        let updatedExternalCertificateOptions = orgExternalCertificateOptions.map((item:any) => {
          return {
            ...item,
            isSelected: true
          }
        })
  
        setOrgExternalCertificateOptions(updatedExternalCertificateOptions);
      }
    }
  
    const handleUnSelectAllExternalCertificates = () => {
      if(orgExternalCertificateOptions.length != 0) {
        let updatedExternalCertificateOptions = orgExternalCertificateOptions.map((item:any) => {
          return {
            ...item,
            isSelected: false
          }
        })
  
        setOrgExternalCertificateOptions(updatedExternalCertificateOptions);
      }
    }

    const handleExternalCertificateSearch = (e:any) => {
        setSearchExternalCertificate(e.target.value);
    }



    const handlePolicyOptionsUpdate = (data:any) => {  
      setOrgPolicyOptions(data);
    }; 
  
    const handleSelectAllPolicies = () => {
      if(orgPolicyOptions.length != 0) {
        let updatedPolicyOptions = orgPolicyOptions.map((item:any) => {
          return {
            ...item,
            isSelected: true
          }
        })
  
        setOrgPolicyOptions(updatedPolicyOptions);
      }
    }
  
    const handleUnSelectAllPolicies = () => {
      if(orgPolicyOptions.length != 0) {
        let updatedPolicyOptions = orgPolicyOptions.map((item:any) => {
          return {
            ...item,
            isSelected: false
          }
        })
  
        setOrgPolicyOptions(updatedPolicyOptions);
      }
    }

    const handlePolicySearch = (e:any) => {
        setSearchPolicy(e.target.value);
      }

    const loadCPDs = () => {
        setCPDLoadingNow(true);
        getUserCPDs(oidcUser.access_token, userId, orgDomain, true).then((cpdData) => {
            if (!!cpdData && cpdData.length > 0) {
                setCPDList(cpdData); 
                getOrgCPDProfiles(
                    oidcUser.access_token,
                    userId,
                    orgDomain,
                    parseInt(orgId)).then(
                      (orgCPDProfRes) => {
                        if (!!orgCPDProfRes && orgCPDProfRes.isSuccess){
                            if (!!orgCPDProfRes === false || !!orgCPDProfRes.cpdProfileItems === false || 
                              orgCPDProfRes.cpdProfileItems.length <= 0) {
                            }
                            else { 
                              let isValid = !!orgCPDProfRes && !!orgCPDProfRes.cpdProfileItems;
                              if (isValid){
                                orgCPDProfRes.cpdProfileItems.map((cpd : any) => {
                                    if (!!cpd){
                                        let cpdToAdd : CPDItem = {id: 0, name: '', period: '', requiredPoints: 0, earnedPoints: 0};
                                        cpdToAdd.id = cpd.profileID;
                                        cpdToAdd.name = cpd.profileName;                               
                                        cpdList.push(cpdToAdd);  
                                        cpdListToPass.push({value: cpdToAdd.id, text: cpdToAdd.name});    
                                        
                                        if (!!cpdList && cpdList.length > 0) {
                                            let firstPObj = {
                                                value: cpdList[0].id,
                                                cpdID: cpdList[0].id,
                                                text: cpdList[0].name
                                            }
                                            setSelectedCPD(firstPObj);      
                                        }               
                                    }
                                });
                              }                      
                            }
                          }
                          setCPDLoadingNow(false);
                        }
                      
                    ).catch(error => {
                        console.log("Error " + error);
                        setCPDLoadingNow(false);
                    });         
            }
            else {
                setCPDLoadingNow(false);
            }
        });
    }

    useEffect(() => {
        resetAll();
    }, [setPageReset]);

    useEffect(() => {
        setGuideMe(groupingOption);
        if (parseInt(filterEmailReportType) === 1) {
            getOrgPolicies(
                oidcUser.access_token,
                userId,
                orgDomain,
                parseInt(orgId),
                true
              ).then((orgPoliciesRes) => {
                if (!!orgPoliciesRes && !!orgPoliciesRes.isSuccess && orgPoliciesRes.isSuccess) {
                    let updatedItems = [];
                    if(orgPoliciesRes.policyItems){
                        updatedItems = orgPoliciesRes.policyItems.map((item:any) => {
                        return {
                        ...item,
                        isSelected: false,
                        isHidden: false
                        }                     
                    })
                    }                   
                  setOrgPolicyOptions(updatedItems);
                }
              });
        }
        if (parseInt(filterEmailReportType) === 4) {
            getExternalCertificateBasicDetails(
                oidcUser.access_token,
                userId,
                orgDomain,
                parseInt(orgId)
              ).then((orgExternalCertificatesRes) => {
                if (!!orgExternalCertificatesRes && !!orgExternalCertificatesRes.isSuccess && orgExternalCertificatesRes.isSuccess) {
                    let updatedItems = [];
                    if(orgExternalCertificatesRes.externalCertificates){
                        updatedItems = orgExternalCertificatesRes.externalCertificates.map((item:any) => {
                        return {
                        ...item,
                        isSelected: false,
                        isHidden: false
                        }                     
                    })
                    }                   
                  setOrgExternalCertificateOptions(updatedItems);
                }
              });
        }
    }, [filterEmailReportType, isERTypeFocussed]);


    useEffect(() => {
        updateCurrentProfileSelection(selectedCPD);
    }, [selectedCPD]);

    const updateCurrentProfileSelection = (givenSelectedCPDProfile: any) => {
        if (!!givenSelectedCPDProfile && !!givenSelectedCPDProfile.value && !!givenSelectedCPDProfile.text) {
            setSelectedProfileID(givenSelectedCPDProfile.value);
            setSelectedProfileName(givenSelectedCPDProfile.text);
        }
        else {
            setSelectedCPDProfilePeriodList(null);
            setSelectedProfileName("");
        }
    }

    useEffect(() => {
        getClassificationType(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((classType) => {

            if (classType !== null && classType.classificationTypeIDs !== null &&
                classType.classificationTypeIDs.length > 0 && classType.classificationTypeIDs[0] > 0) {
                getClassificationListByType(oidcUser.access_token, userId, orgDomain, parseInt(orgId), classType.classificationTypeIDs[0]).then((data) => {

                    if (data !== null && data.classificationlistItem !== null) {
                        setClassificationList(data.classificationlistItem);
                        setGroupingOption(true)
                        setGuideMe(true)
                    }
                    else {
                        setGroupingOption(false)
                        setGuideMe(false)
                    }
                    
                    setSelectedClassification({ value: "0", classificationID: "0", text: allResourcesToTranslations.emrepgmpsel });
                    setSelectedCPD({ value: "0", cpdID: "0", text: allResourcesToTranslations.emrepgmpsel });
                }).catch(error => {
                })
            }
            else {
                setGroupingOption(false);
                setGuideMe(false);
            }
        }).catch(error => {
        });
    }, []);
    if (loading) return <LoaderContainer />;
    return (
        <>
            <Steps enabled={intro.stepsEnabled} steps={intro.steps} initialStep={intro.initialStep} onExit={onExit}
                options={{
                    nextLabel: 'Next',
                    prevLabel: 'Back',
                    tooltipClass: 'sample-class',
                    showBullets: true,
                }} />
            <CustomTooltip displayText={[allResourcesToTranslations.guideme]}>
                <span onClick={() => setIntro({ ...intro, stepsEnabled: true })} className={styles.guideMeButtonSpecific}>
                    <GuideIcon />
                </span>
            </CustomTooltip>
            <BrandedCard>
                <div className={styles.reportWrraper}>
                    <div className={styles.unittreesection}>
                        <div style={{ paddingTop: '20px'}} className="emailReportType">
                             <FormControl variant="outlined" className={styles.formControl}>
                                <InputLabel id="selNewSaltERType" focused={isERTypeFocussed} style={{fontWeight: 'bolder', fontSize: '40', color: isERTypeFocussed ? '#63C2FF' : 'black'}}>{allResourcesToTranslations.emrepgmemtylbl + ":"}</InputLabel>
                                <Select
                                    id="selNewSaltERType"
                                    native
                                    value={filterEmailReportType}
                                    label={allResourcesToTranslations.emrepgmemtylbl + ":"}
                                    variant="outlined"
                                    onChange={(e) => { 
                                        setCPDLoadingNow(false); 
                                        setFilterEmailReportType(e.target.value); 
                                        if (e.target.value == '2' || e.target.value == 2) 
                                        { 
                                            loadCPDs(); 
                                        } 
                                    }}
                                    
                                    >  
                                    <optgroup label={allResourcesToTranslations.emrepgmemtylbl + ":"}>
                                        <option value={0}>{allResourcesToTranslations.mytraininggmtcoursestatuses}</option>
                                        {IsPolicyEnabled ? <option value={1}>{allResourcesToTranslations.emrepgmpas}</option> : <></>}
                                        {IsCPDEnabled ? <option value={2}>{allResourcesToTranslations.mytraininggmtcpdprof}</option> : <></>}
                                        <option value={3}>{allResourcesToTranslations.emrepgmwe}</option>
                                        {IsExtCertEnabled ? <option value={4}>{allResourcesToTranslations.extcertificate}</option> : <></>}
                                    </optgroup>
                                </Select>
                            </FormControl> 
                        </div>
                        <br/>
                        <div>
                            <Units isResetClicked={isResetClicked} givenUnits={units} givenFilterUnitName={filterUnitName} setGivenFilterUnitName={setFilterUnitName} />
                        </div>
                        {parseInt(filterEmailReportType) === 0 &&
                            <div hidden={(units === null || units.length <= 10) === false} className={styles.paddingTop}>
                                <Courses isResetClicked={isResetClicked} givenCourses={courseList} hasSmallUnits={true} />
                            </div>
                        }
                        {parseInt(filterEmailReportType) === 4 &&
                            <div hidden={(units === null || units.length <= 10) === false} className={styles.paddingTop} id="extCertSectionFull">
                                <ExternalCertificateSelection 
                                sectionFlag={(units === null || units.length <= 10)}
                                selectAllExternalCertificates={handleSelectAllExternalCertificates} 
                                handleExternalCertificateSearch={handleExternalCertificateSearch} 
                                searchExternalCertificate={searchExternalCertificate} 
                                deselectAllExternalCertificates={handleUnSelectAllExternalCertificates} 
                                orgExternalCertificateOptions={orgExternalCertificateOptions} 
                                updateExternalCertificateOptions={handleExternalCertificateOptionsUpdate} /> 
                                <span className={styles.error} hidden={orgExternalCertificateOptions.length > 0}>{allResourcesToTranslations.emrepgmecam}</span>     
                            </div>
                        }
                        {parseInt(filterEmailReportType) === 1 &&
                            <div hidden={(units === null || units.length <= 10) === false} className={styles.paddingTop} id="policySectionFull">
                                <PolicySelection 
                                sectionFlag={(units === null || units.length <= 10)}
                                selectAllPolicies={handleSelectAllPolicies} 
                                handlePolicySearch={handlePolicySearch} 
                                searchPolicy={searchPolicy} 
                                deselectAllPolicies={handleUnSelectAllPolicies} 
                                orgPolicyOptions={orgPolicyOptions} 
                                updatePolicyOptions={handlePolicyOptionsUpdate} /> 
                                <span className={styles.error} hidden={orgPolicyOptions.length > 0}>{allResourcesToTranslations.emrepgmpam}</span>     
                            </div>
                        }
                    </div>
                    <div style={{ display: 'inline-block', width: '49%', verticalAlign: 'top', paddingTop: '80px' }}>
                        {parseInt(filterEmailReportType) === 0 &&
                            <div hidden={(units === null || units.length <= 10) === true} style={{ paddingTop: '15px' }}>
                                <Courses isResetClicked={isResetClicked} givenCourses={courseList} hasSmallUnits={false} />
                            </div>
                        }
                        {parseInt(filterEmailReportType) === 4 &&
                            <div hidden={(units === null || units.length <= 10) === true} className={styles.paddingTop} id="extCertSectionFull2">
                                <ExternalCertificateSelection 
                                sectionFlag={(units === null || units.length <= 10)}
                                selectAllExternalCertificates={handleSelectAllExternalCertificates} 
                                handleExternalCertificateSearch={handleExternalCertificateSearch} 
                                searchExternalCertificate={searchExternalCertificate} 
                                deselectAllExternalCertificates={handleUnSelectAllExternalCertificates} 
                                orgExternalCertificateOptions={orgExternalCertificateOptions} 
                                updateExternalCertificateOptions={handleExternalCertificateOptionsUpdate} /> 
                                <span className={styles.error} hidden={orgExternalCertificateOptions.length > 0}>{allResourcesToTranslations.emrepgmecam}</span>
                            </div>
                        }
                        {parseInt(filterEmailReportType) === 1 &&
                            <div hidden={(units === null || units.length <= 10) === true} className={styles.paddingTop} id="policySectionFull2">
                                <PolicySelection 
                                    sectionFlag={(units === null || units.length <= 10)}
                                    selectAllPolicies={handleSelectAllPolicies} 
                                    handlePolicySearch={handlePolicySearch} 
                                    searchPolicy={searchPolicy} 
                                    deselectAllPolicies={handleUnSelectAllPolicies} 
                                    orgPolicyOptions={orgPolicyOptions} 
                                    updatePolicyOptions={handlePolicyOptionsUpdate} />    
                                    <span className={styles.error} hidden={orgPolicyOptions.length > 0}>{allResourcesToTranslations.emrepgmpam}</span>  
                            </div>
                        }
                        {parseInt(filterEmailReportType) === 0 &&
                            <>
                                <div style={{ paddingTop: '20px'}} className="courseStatus">
                                <FormControl variant="outlined" className={styles.formControl}>
                                    <InputLabel id="grouping2">{allResourcesToTranslations.emrepgmcrsqz}</InputLabel>
                                    <Select
                                        id="selNewSaltERCourseStatus"
                                        native
                                        value={filterCourseStatus}
                                        label={allResourcesToTranslations.emrepgmcrsqz}
                                        className={styles.input} variant="outlined"
                                        onChange={(e) => setFilterCourseStatus(e.target.value)}>
                                        <optgroup label={allResourcesToTranslations.emrepgmcs}>
                                            <option value={0}>{allResourcesToTranslations.incomplete}</option>
                                            <option value={1}>{allResourcesToTranslations.complete}</option>
                                        </optgroup>
                                        <optgroup label={allResourcesToTranslations.quizstatus}>
                                            <option value={2}>{allResourcesToTranslations.emrepgmsf}</option>
                                            <option value={3}>{allResourcesToTranslations.notstarted}</option>
                                            <option value={4}>{allResourcesToTranslations.expiredtimeelapsed}</option>
                                            <option value={5}>{allResourcesToTranslations.expirednewcontent}</option>
                                        </optgroup>
                                    </Select>
                                </FormControl> 
                                </div>
                            </>
                        }
                        {parseInt(filterEmailReportType) === 1 &&
                            <>
                            <div style={{ paddingTop: '20px'}} className="policyStatus">
                                <FormControl variant="outlined" className={styles.formControl}>
                                    <InputLabel id="grouping4">{allResourcesToTranslations.emrepgmpol}</InputLabel>
                                    <Select
                                        id="selPolicyStatus"
                                        native
                                        value={filterPolicyStatus}
                                        label={allResourcesToTranslations.emrepgmpol}
                                        className={styles.input} variant="outlined"
                                        onChange={(e) => setFilterPolicyStatus(e.target.value)}>
                                        <optgroup label={allResourcesToTranslations.emrepgmpol}>
                                            <option value={0}>{allResourcesToTranslations.accepted}</option>
                                            <option value={1}>{allResourcesToTranslations.notaccepted}</option>
                                        </optgroup>
                                    </Select>
                                </FormControl>
                            </div>
                            </>
                        }
                        {parseInt(filterEmailReportType) === 4 &&
                            <>
                            <div style={{ paddingTop: '20px'}} className="extCertStatus" id="extCertStatus2">
                                <FormControl variant="outlined" className={styles.formControl}>
                                    <InputLabel id="grouping5">{allResourcesToTranslations.emrepgmecsta}</InputLabel>
                                    <Select
                                        id="selExtCertStatus"
                                        native
                                        value={filterExternalCertificateStatus}
                                        label={allResourcesToTranslations.emrepgmecsta}
                                        className={styles.input} variant="outlined"
                                        onChange={(e) => setFilterExternalCertificateStatus(e.target.value)}>
                                        <optgroup label={allResourcesToTranslations.emrepgmecsta}>
                                            {/* <option value={1}>Outstanding</option> */}
                                            <option value={1}>{allResourcesToTranslations.notprovidedcertificates}</option>
                                            <option value={2}>{allResourcesToTranslations.providedcertificates}</option>
                                            {/* <option value={3}>Expired</option> */}
                                            <option value={3}>{allResourcesToTranslations.emrepgmclsd}</option>
                                        </optgroup>
                                    </Select>
                                </FormControl>
                            </div>
                            </>
                        }
                        {parseInt(filterEmailReportType) <= 1 &&
                            <>
                                <div className={styles.inputContainer}>
                                    <div className="dateFrom"><TextField className={styles.input} label={allResourcesToTranslations.sntemrepgmmdtfrml} variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                                        type="date" defaultValue={fromDate} onChange={(e) => setFromDate(formatDate(new Date(e.target.value)))} /></div>
                                </div>
                                <div className={styles.inputContainer}>
                                    <div className="dateTo"><TextField className={styles.input} label={allResourcesToTranslations.sntemrepgmmdttol} variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                                        type="date" defaultValue={toDate} onChange={(e) => setToDate(formatDate(new Date(e.target.value)))} /></div>
                                </div>
                            </>
                        }
                        {groupingOption &&
                            <div className="groupby">
                                <div className={styles.groupingOption}>
                                    <FormControl variant="outlined" className={styles.formControl}>
                                        <InputLabel id="grouping2">{allResourcesToTranslations.groupingoption}</InputLabel>
                                        <Select name="groupingOption"
                                            value={selectedClassification.value}
                                            native
                                            onChange={(e) => setSelectedClassification({ value: e.target.value, classificationID: e.target.value, text: (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].text })}
                                            className={styles.input}
                                            variant="outlined"
                                            label={allResourcesToTranslations.groupingoption}
                                            id="groupingOption"
                                        >
                                            {classificationList.map((item: any, index: number) => {
                                                return <option key={"Option: " + item.text + "_" + index.toString()} value={item.value}>{item.text}</option>
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        } 
                        {
                        
                        <>
                            <div  className={`${styles.inputContainer} ${groupingOption ? styles.noPaddingTop : styles.paddingTop}`}>
                                <div className="firstName"><TextField  className={styles.input}  label={allResourcesToTranslations.firstname} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                                    value={filterFirstName} onChange={(e) => setFilterFirstName(e.target.value)}/></div>
                            </div>
                            <div className={styles.inputContainer}>
                                <div className="lastName"><TextField   className={styles.input}  label={allResourcesToTranslations.lastname} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}} 
                                value={filterLastName} onChange={(e) => setFilterLastName(e.target.value)} /></div>
                            </div>
                            <div className={styles.inputContainer}>
                                <div className="userName"><TextField  className={styles.input}  label={allResourcesToTranslations.username} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}} 
                                value={filterUserName} onChange={(e) => setFilterUserName(e.target.value)} /></div>
                            </div>                        
                            <div className={styles.inputContainer}>
                                <div className="email"><TextField  className={styles.input}  label={allResourcesToTranslations.emrepprvgmtembod} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}} 
                                value={filterEmail} onChange={(e) => setFilterEmail(e.target.value)} /></div>
                            </div>
                        </>}
                        {parseInt(filterEmailReportType) === 3 &&   
                            <CustomTooltip displayText={[allResourcesToTranslations.emrepgmnsom]}>
                                <div className={`${styles.inactiveContainer}`} id="isNewStartersOnly" >
                                    <Checkbox
                                        size="small"
                                        color="primary"
                                        checked={filterIsNewStarterOnly}
                                        className={styles.checkBox}
                                        onClick={() => setFilterIsNewStarterOnly(!filterIsNewStarterOnly)}
                                    />{" "}
                                    {allResourcesToTranslations.emrepgmnwstronly}
                                </div>
                            </CustomTooltip>
                        }
                        {
                            isCPDLoadingNow && <nav  style={{width:"40px", height: "40px"}}><img src={loadingGif} id={"loading_emailreport_cpd" } style={{width:"40px", height: "40px"}}  /></nav>
                        }
                        {parseInt(filterEmailReportType) === 2 && selectedCPD && !isCPDLoadingNow &&
                            <div className="cpdProfiles">
                                <div className={styles.groupingOption}>
                                    <FormControl variant="outlined" className={styles.formControl}>
                                        <InputLabel id="cpdProfiles">{allResourcesToTranslations.mytraininggmtcpdprof}</InputLabel>
                                        <Select name="cpdProfiles"
                                            value={selectedCPD.value}
                                            native
                                            onChange={(e) => setSelectedCPD({ value: e.target.value, cpdID: e.target.value, text: (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].text })}
                                            className={styles.input}
                                            variant="outlined"
                                            label={allResourcesToTranslations.mytraininggmtcpdprof}
                                            id="cpdProfiles"
                                        >
                                            {cpdList.map((item: CPDItem) => {
                                                return <option key={"Option: " + item.id} value={item.id}>{item.name}</option>
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        } 
                        {parseInt(filterEmailReportType) !== 2 && parseInt(filterEmailReportType) !== 3 &&
                        <div className={styles.paddingTop10}>
                            <FormControl component="fieldset" style={{ width: '100%' }} className="recipient">
                                <FormLabel component="legend">{allResourcesToTranslations.emrepgmrept}</FormLabel>
                                <div className={styles.childContainer}>
                                    <RadioGroup
                                        name="periodType"
                                        value={recipientType}
                                        onChange={handleRecipientChange}
                                        style={{ display: 'flex', flexDirection: 'row' }}
                                    >
                                        <FormControlLabel
                                            value={"users"}
                                            control={<Radio color="primary" />}
                                            label={allResourcesToTranslations.accounts}
                                        />
                                        <FormControlLabel
                                            value={"admins"}
                                            control={<Radio color="primary" />}
                                            label={allResourcesToTranslations.emrepgmral}
                                        />
                                    </RadioGroup>
                                </div>
                            </FormControl>
                        </div>
                        }
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }} >
                            <div className={styles.loaderContainer}>
                                <CircularProgress
                                    style={{ width: isFetchingData ? "20px" : "0px" }}
                                    color="primary"
                                    size="10"
                                />
                            </div>
                            <div className="runReport">
                                <CustomTooltip displayText={[allResourcesToTranslations.emrepgmprem]} >
                                    <span >
                                        <Button
                                            onClick={runReport}
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            id="btnNewSaltPreviewEmailReport"
                                        >
                                            <EmailPreviewIcon />
                                        </Button>
                                    </span>
                                </CustomTooltip>
                            </div>
                            <div className="resetAll" style={{marginRight: "-10px"}}>
                                <CustomTooltip displayText={[allResourcesToTranslations.resetall]}>
                                    <span className={styles.emailResetIcon} onClick={resetAll}>
                                        <ResetIcon />
                                    </span>
                                </CustomTooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </BrandedCard>
            {showWarningModal && <CommonPopupModalForResult saveSuccess={false} hasErrored={hasErrored} 
                handleClose={handleClosePopup} titleText={hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
        </>
    )
}

export default EmailReportSearch
