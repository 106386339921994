import React from "react";

//Styles
import styles from "../../styles/currentAdminReport.module.css";

interface ReportSaveModalProps {
    saveSuccess : boolean;
}

const ReportSaveModal = (props:ReportSaveModalProps) => {

    const { saveSuccess } = props;

    return  (
        <div className={styles.brandedCard}>
          {saveSuccess ? (<h3  className={styles.savedContent}>Settings Saved</h3>) : (<h3 className={styles.warningContent}>Warning</h3>)}

          {saveSuccess ?(<p className={styles.textContent}>Scheduler Report Settings have been saved successfully</p>) 
                        : (<p  className={styles.textContent}>Please check your settings and try again.</p>)}

          {saveSuccess ? (
            <div
              className={styles.colorBar}
              style={{ backgroundColor: "rgb(75, 202, 129)" }}
            />
          ) : (
            <div
              className={styles.colorBar}
              style={{ backgroundColor: "#F2B05C" }}
            />
          )}
        </div>
      )
}

export default ReportSaveModal;