import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const DeleteIcon = () => (    
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_201_55)">
    <path d="M3 21.75C3 22.3467 3.23706 22.919 3.65901 23.341C4.08097 23.7629 4.65327 24 5.25 24H18.75C19.3467 24 19.919 23.7629 20.341 23.341C20.7629 22.919 21 22.3467 21 21.75V6.00001H3V21.75ZM15.75 9.75C15.75 9.55109 15.829 9.36033 15.9697 9.21967C16.1103 9.07902 16.3011 9.00001 16.5 9.00001C16.6989 9.00001 16.8897 9.07902 17.0303 9.21967C17.171 9.36033 17.25 9.55109 17.25 9.75V20.25C17.25 20.4489 17.171 20.6397 17.0303 20.7803C16.8897 20.921 16.6989 21 16.5 21C16.3011 21 16.1103 20.921 15.9697 20.7803C15.829 20.6397 15.75 20.4489 15.75 20.25V9.75ZM11.25 9.75C11.25 9.55109 11.329 9.36033 11.4697 9.21967C11.6103 9.07902 11.8011 9.00001 12 9.00001C12.1989 9.00001 12.3897 9.07902 12.5303 9.21967C12.671 9.36033 12.75 9.55109 12.75 9.75V20.25C12.75 20.4489 12.671 20.6397 12.5303 20.7803C12.3897 20.921 12.1989 21 12 21C11.8011 21 11.6103 20.921 11.4697 20.7803C11.329 20.6397 11.25 20.4489 11.25 20.25V9.75ZM6.75 9.75C6.75 9.55109 6.82902 9.36033 6.96967 9.21967C7.11032 9.07902 7.30109 9.00001 7.5 9.00001C7.69891 9.00001 7.88968 9.07902 8.03033 9.21967C8.17098 9.36033 8.25 9.55109 8.25 9.75V20.25C8.25 20.4489 8.17098 20.6397 8.03033 20.7803C7.88968 20.921 7.69891 21 7.5 21C7.30109 21 7.11032 20.921 6.96967 20.7803C6.82902 20.6397 6.75 20.4489 6.75 20.25V9.75ZM21.75 1.50001H16.125L15.6844 0.623446C15.591 0.436046 15.4473 0.278409 15.2692 0.16827C15.0912 0.0581305 14.8859 -0.000141936 14.6766 8.21846e-06H9.31875C9.10987 -0.000794775 8.90498 0.0572604 8.72757 0.167522C8.55015 0.277784 8.40739 0.435793 8.31563 0.623446L7.875 1.50001H2.25C2.05109 1.50001 1.86033 1.57903 1.71967 1.71968C1.57902 1.86033 1.5 2.0511 1.5 2.25001L1.5 3.75001C1.5 3.94892 1.57902 4.13968 1.71967 4.28034C1.86033 4.42099 2.05109 4.50001 2.25 4.50001H21.75C21.9489 4.50001 22.1397 4.42099 22.2803 4.28034C22.421 4.13968 22.5 3.94892 22.5 3.75001V2.25001C22.5 2.0511 22.421 1.86033 22.2803 1.71968C22.1397 1.57903 21.9489 1.50001 21.75 1.50001V1.50001Z" fill="#FFFFFF"/>
    </g>
    <defs>
    <clipPath id="clip0_201_55">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
);

export default DeleteIcon
