//React + Typescript
import React, { useEffect, useState, useContext } from "react";
import { UnitTreeItem } from "../../../globalTypes";

//MUI Components
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import {
  Button, ButtonGroup, FormControl, IconButton, RadioGroup, Radio, Checkbox
} from "@material-ui/core";

// Contexts
import { TranslationsContext } from "../../../contexts/TranslationsContext";

//Styles
import styles from "../../../styles/moveUnit.module.css";
import RadioUnitTree from "../AddUnit/RadioUnitTree";
import ExpandAllIcon from "../../../CustomIcons/ExpandAllIcon";
import CollapseAllIcon from "../../../CustomIcons/CollapseAllIcon";

export const RadioUnitsForMove = (props: any) => {
  const { isResetClicked,
    givenUnitsForSource, givenUnitsForTarget, 
    selectedUnitIdForSource, setSelectedUnitCallbackForSource, setSelectedunitlevelForSource, 
    selectedUnitIdForTarget, setSelectedUnitCallbackForTarget, setSelectedunitlevelForTarget, 
    handleMoveAsTopLevelUnit } = props;
  const [unitsForSource, setUnitsForSource] = useState<Array<UnitTreeItem>>(givenUnitsForSource);
  const [unitsForTarget, setUnitsForTarget] = useState<Array<UnitTreeItem>>(givenUnitsForTarget);
  const [selectedUnitForSource, setSelectedUnitForSource] = useState<any>("");
  const [selectedUnitForTarget, setSelectedUnitForTarget] = useState<any>("");
  const [moveAsTopLevelUnit, setMoveAsTopLevelUnit] = useState<boolean>(false);
  const [interactionCountForSource, setInteractionCountForSource] = useState<number>(0); //Required to force update the tree
  const [filterUnitNameForSource, setFilterUnitNameForSource] = useState("");
  //const [unitlevelForSource, setUnitLevelForSource] = useState<any>("toplevel");
  const [interactionCountForTarget, setInteractionCountForTarget] = useState<number>(0); //Required to force update the tree
  const [filterUnitNameForTarget, setFilterUnitNameForTarget] = useState("");
  //const [unitlevelForTarget, setUnitLevelForTarget] = useState<any>("toplevel");

  const { translations: { allResourcesToTranslations }, } = useContext(TranslationsContext);
  
  useEffect(() => {
      setUnitsForSource([...givenUnitsForSource]);
      if(selectedUnitIdForSource !== 0) {
        setUnitSelectedForSource(givenUnitsForSource);
      }
  },[givenUnitsForSource])
  
  useEffect(() => {
      setUnitsForTarget([...givenUnitsForTarget]);
      if(selectedUnitIdForTarget !== 0) {
        setUnitSelectedForTarget(givenUnitsForTarget);
      }
  },[givenUnitsForTarget])
  
  useEffect(() => {
    if (isResetClicked) {
      if (selectedUnitIdForSource <= 0) {
        resetAllUnitSelectionForSource();
        setSelectedUnitForSource("");
        setSelectedUnitCallbackForSource(0);
      }
      if (selectedUnitIdForTarget <= 0) {
        resetAllUnitSelectionForTarget();
        setSelectedUnitForTarget("");
        setSelectedUnitCallbackForTarget(0);
        setMoveAsTopLevelUnit(false);
      }
    }
  },[isResetClicked])
  
  const resetAllUnitSelectionForSource = () => {
    let updatedUnitsSource = unitsForSource;
    const updateAllUnselectionForSource = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.isSelected === true) {
          unit.isSelected = !unit.isSelected;
          setSelectedUnitForSource(unit.unitID);
          setSelectedUnitCallbackForSource(unit.unitID);
        }
        updateAllUnselectionForSource(unit.childUnitTreeItems);
      }
    };
    updateAllUnselectionForSource(updatedUnitsSource);
    setUnitsForSource(updatedUnitsSource);
  }
  
  const resetAllUnitSelectionForTarget = () => {
    let updatedUnitsTarget = unitsForTarget;
    const updateAllUnselectionForTarget = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.isSelected === true) {
          unit.isSelected = !unit.isSelected;
          setSelectedUnitForTarget(unit.unitID);
          setSelectedUnitCallbackForTarget(unit.unitID);
        }
        updateAllUnselectionForTarget(unit.childUnitTreeItems);
      }
    };
    updateAllUnselectionForTarget(updatedUnitsTarget);
    setUnitsForTarget(updatedUnitsTarget);
  }

  const updateSelectedUnitForSource = (id: any) => {
    let updatedUnits = unitsForSource;
    const updateSelectedForSource = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.unitID.toString() === id.target.value.toString()) {
          unit.isSelected = !unit.isSelected;
          setSelectedUnitForSource(unit.unitID);
          setSelectedUnitCallbackForSource(unit.unitID);
        }
        updateSelectedForSource(unit.childUnitTreeItems);
      }
    };
    updateSelectedForSource(updatedUnits);
    setInteractionCountForSource(interactionCountForSource + 1);
    setUnitsForSource(updatedUnits);
  };

  const updateSelectedUnitForTarget = (id: any) => {
    let updatedUnits = unitsForTarget;
    let isTopLevelRemovedForAnySelection = false;
    const updateSelectedForTarget = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.unitID.toString() === id.target.value.toString()) {
          unit.isSelected = !unit.isSelected;
          setSelectedUnitForTarget(unit.unitID);
          setSelectedUnitCallbackForTarget(unit.unitID);
          if (moveAsTopLevelUnit && !isTopLevelRemovedForAnySelection) {
            toggleMoveAsTopLevelUnit();
          }
        }
        updateSelectedForTarget(unit.childUnitTreeItems);
      }
    };
    updateSelectedForTarget(updatedUnits);
    setInteractionCountForTarget(interactionCountForTarget + 1);
    setUnitsForTarget(updatedUnits);
  };

  const updateExpandedUnitForSource = (id: number) => {
    let updatedUnits = unitsForSource;
    const checkForExpandedForSource = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.unitID === id) {
          unit.isExpanded = !unit.isExpanded;
        }
        checkForExpandedForSource(unit.childUnitTreeItems);
      }
    };
    checkForExpandedForSource(updatedUnits);
    setInteractionCountForSource(interactionCountForSource + 1);
    setUnitsForSource(updatedUnits);
  };

  const updateExpandedUnitForTarget = (id: number) => {
    let updatedUnits = unitsForTarget;
    const checkForExpandedForTarget = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.unitID === id) {
          unit.isExpanded = !unit.isExpanded;
        }
        checkForExpandedForTarget(unit.childUnitTreeItems);
      }
    };
    checkForExpandedForTarget(updatedUnits);
    setInteractionCountForTarget(interactionCountForTarget + 1);
    setUnitsForTarget(updatedUnits);
  };

  const setUnitSelectedForSource = (givenUnits: Array<UnitTreeItem> | null) => {
    let updatedUnits = givenUnits;
    const checkIfAnyUnitSelectedForSource = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return false; //Break if no children
      if (unitArray.length === 0) return false;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.isSelected) {
          setSelectedUnitForSource(unit.unitID);
          setSelectedUnitCallbackForSource(unit.unitID);
          return true;
        }
        if (checkIfAnyUnitSelectedForSource(unit.childUnitTreeItems)) {
          return true;
        }
      }
      return false;
    };
    return checkIfAnyUnitSelectedForSource(updatedUnits);
  };

  const setUnitSelectedForTarget = (givenUnits: Array<UnitTreeItem> | null) => {
    let updatedUnits = givenUnits;
    const checkIfAnyUnitSelectedForTarget = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return false; //Break if no children
      if (unitArray.length === 0) return false;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.isSelected) {
          setSelectedUnitForTarget(unit.unitID);
          setSelectedUnitCallbackForTarget(unit.unitID);
          return true;
        }
        if (checkIfAnyUnitSelectedForTarget(unit.childUnitTreeItems)) {
          return true;
        }
      }
      return false;
    };
    return checkIfAnyUnitSelectedForTarget(updatedUnits);
  };

  const isAnyUnitSelectedForSource = (givenUnits: Array<UnitTreeItem> | null) => {
    let updatedUnits = givenUnits;
    const checkIfAnyUnitSelectedForSource = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return false; //Break if no children
      if (unitArray.length === 0) return false;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.isSelected) {
          return true;
        }
        if (checkIfAnyUnitSelectedForSource(unit.childUnitTreeItems)) {
          return true;
        }
      }
      return false;
    };
    return checkIfAnyUnitSelectedForSource(updatedUnits);
  };

  const expandAllUnitsForSource = () => {
    let updatedUnits = unitsForSource;
    const checkForSelectedForSource = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        if (!unitArray[i].isHidden) {
          const unit = unitArray[i];
          unit.isExpanded = true;
          checkForSelectedForSource(unit.childUnitTreeItems);
        }
      }
    };
    checkForSelectedForSource(updatedUnits);
    setInteractionCountForSource(interactionCountForSource + 1);
    setUnitsForSource(updatedUnits);
  };

  const colapseAllUnitsForSource = () => {
    let updatedUnits = unitsForSource;
    const checkForSelectedForSource = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        if (!unitArray[i].isHidden) {
          const unit = unitArray[i];
          unit.isExpanded = false;
          checkForSelectedForSource(unit.childUnitTreeItems);
        }
      }
    };
    checkForSelectedForSource(updatedUnits);
    setInteractionCountForSource(interactionCountForSource + 1);
    setUnitsForSource(updatedUnits);
  };

  const isGivenItemNameMatchedForSource = (
    item: UnitTreeItem,
    givenFilterUnitName: string
  ): boolean | undefined => {
    if (
      item === null ||
      item === undefined ||
      item.name === null ||
      item.name === undefined
    ) {
      return false;
    }
    if (
      givenFilterUnitName === null ||
      givenFilterUnitName === undefined ||
      givenFilterUnitName.trimLeft().trimRight() === ""
    ) {
      return true;
    } else {
      if (item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase())) {
        return true;
      }
      return false;
    }
  };

  const isAnyChildItemNameMatchedForSource = (
    list: UnitTreeItem[] | null,
    givenFilterUnitName: string
  ): boolean | undefined => {
    if (list === null || list === undefined || list.length <= 0) {
      return false;
    }
    if (
      givenFilterUnitName === null ||
      givenFilterUnitName === undefined ||
      givenFilterUnitName.trimLeft().trimRight() === ""
    ) {
      return true;
    } else {
      var newList = list.filter((e) => {
        return (
          isGivenItemNameMatchedForSource(e, givenFilterUnitName) === true ||
          isAnyChildItemNameMatchedForSource(
            e.childUnitTreeItems,
            givenFilterUnitName
          ) === true
        );
      });
      if (newList !== null && newList !== undefined && newList.length > 0) {
        return true;
      }
      return false;
    }
  };

  const updateUnitItemBasedOnNameMatchForSource = (
    item: UnitTreeItem,
    givenFilterUnitName: string
  ): boolean | undefined => {
    let isMatchFound = false;
    if (
      (givenFilterUnitName === null ||
        givenFilterUnitName === undefined ||
        givenFilterUnitName.trimLeft().trimRight() === "") &&
      item !== null &&
      item !== undefined
    ) {
      isMatchFound = true;
      item.isDisabled = !isMatchFound;
      item.isHidden = !isMatchFound;
      if (
        item.childUnitTreeItems !== null &&
        item.childUnitTreeItems !== undefined &&
        item.childUnitTreeItems.length > 0
      ) {
        item.childUnitTreeItems.forEach(function (e) {
          updateUnitItemBasedOnNameMatchForSource(
            e,
            givenFilterUnitName
          );
        });
      }
    } else if (
      givenFilterUnitName !== null &&
      givenFilterUnitName !== undefined &&
      givenFilterUnitName.trimLeft().trimRight() !== "" &&
      item !== null &&
      item !== undefined
    ) {
      isMatchFound =
        item.name !== null &&
        item.name !== undefined &&
        item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase());
      if (isMatchFound) {
        item.isDisabled = false;
        item.isHidden = false;
      } else if (
        !isMatchFound &&
        (item.childUnitTreeItems == null ||
          item.childUnitTreeItems === undefined ||
          item.childUnitTreeItems.length <= 0)
      ) {
        item.isDisabled = true;
        item.isHidden = true;
      } else if (!isMatchFound) {
        let curAnyChildNameMatched = isAnyChildItemNameMatchedForSource(
          item.childUnitTreeItems,
          givenFilterUnitName
        );
        item.isDisabled = curAnyChildNameMatched === true;
        item.isHidden = curAnyChildNameMatched === false;
        isMatchFound = curAnyChildNameMatched === true;
      }
      if (
        item.childUnitTreeItems !== null &&
        item.childUnitTreeItems !== undefined &&
        item.childUnitTreeItems.length > 0
      ) {
        item.childUnitTreeItems.forEach(function (e) {
          updateUnitItemBasedOnNameMatchForSource(
            e,
            givenFilterUnitName
          );
        });
      }
    }
    return isMatchFound;
  };

  // useEffect(() => {
  //   setFilterUnitName("");
  // }, [isResetClicked]);

  // const handleUnitLevelForSource = (event:any) => {
  //   const val = (event.target as HTMLInputElement).value;
  //   setSelectedunitlevelForSource(val);
  //   setUnitLevelForSource(val);
  // }

  useEffect(() => {
    if (unitsForSource !== null && unitsForSource !== undefined && unitsForSource.length > 0) {
      var newUnits = unitsForSource.map(function (e) {
        const retFlag = !(
          e !== null &&
          e !== undefined &&
          e.name !== null &&
          e.name !== undefined &&
          updateUnitItemBasedOnNameMatchForSource(e, filterUnitNameForSource.toLowerCase())
        );
        return e;
      });
      setUnitsForSource(newUnits);
    }
  }, [filterUnitNameForSource]);



  const isAnyUnitSelectedForTarget = (givenUnits: Array<UnitTreeItem> | null) => {
    let updatedUnits = givenUnits;
    const checkIfAnyUnitSelectedForTarget = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return false; //Break if no children
      if (unitArray.length === 0) return false;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.isSelected) {
          return true;
        }
        if (checkIfAnyUnitSelectedForTarget(unit.childUnitTreeItems)) {
          return true;
        }
      }
      return false;
    };
    return checkIfAnyUnitSelectedForTarget(updatedUnits);
  };

  const expandAllUnitsForTarget = () => {
    let updatedUnits = unitsForTarget;
    const checkForSelectedForTarget = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        if (!unitArray[i].isHidden) {
          const unit = unitArray[i];
          unit.isExpanded = true;
          checkForSelectedForTarget(unit.childUnitTreeItems);
        }
      }
    };
    checkForSelectedForTarget(updatedUnits);
    setInteractionCountForTarget(interactionCountForTarget + 1);
    setUnitsForTarget(updatedUnits);
  };

  const colapseAllUnitsForTarget = () => {
    let updatedUnits = unitsForTarget;
    const checkForSelectedForTarget = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        if (!unitArray[i].isHidden) {
          const unit = unitArray[i];
          unit.isExpanded = false;
          checkForSelectedForTarget(unit.childUnitTreeItems);
        }
      }
    };
    checkForSelectedForTarget(updatedUnits);
    setInteractionCountForTarget(interactionCountForTarget + 1);
    setUnitsForTarget(updatedUnits);
  };

  const isGivenItemNameMatchedForTarget = (
    item: UnitTreeItem,
    givenFilterUnitName: string
  ): boolean | undefined => {
    if (
      item === null ||
      item === undefined ||
      item.name === null ||
      item.name === undefined
    ) {
      return false;
    }
    if (
      givenFilterUnitName === null ||
      givenFilterUnitName === undefined ||
      givenFilterUnitName.trimLeft().trimRight() === ""
    ) {
      return true;
    } else {
      if (item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase())) {
        return true;
      }
      return false;
    }
  };

  const isAnyChildItemNameMatchedForTarget = (
    list: UnitTreeItem[] | null,
    givenFilterUnitName: string
  ): boolean | undefined => {
    if (list === null || list === undefined || list.length <= 0) {
      return false;
    }
    if (
      givenFilterUnitName === null ||
      givenFilterUnitName === undefined ||
      givenFilterUnitName.trimLeft().trimRight() === ""
    ) {
      return true;
    } else {
      var newList = list.filter((e) => {
        return (
          isGivenItemNameMatchedForTarget(e, givenFilterUnitName) === true ||
          isAnyChildItemNameMatchedForTarget(
            e.childUnitTreeItems,
            givenFilterUnitName
          ) === true
        );
      });
      if (newList !== null && newList !== undefined && newList.length > 0) {
        return true;
      }
      return false;
    }
  };

  const updateUnitItemBasedOnNameMatchForTarget = (
    item: UnitTreeItem,
    givenFilterUnitName: string
  ): boolean | undefined => {
    let isMatchFound = false;
    if (
      (givenFilterUnitName === null ||
        givenFilterUnitName === undefined ||
        givenFilterUnitName.trimLeft().trimRight() === "") &&
      item !== null &&
      item !== undefined
    ) {
      isMatchFound = true;
      item.isDisabled = !isMatchFound;
      item.isHidden = !isMatchFound;
      if (
        item.childUnitTreeItems !== null &&
        item.childUnitTreeItems !== undefined &&
        item.childUnitTreeItems.length > 0
      ) {
        item.childUnitTreeItems.forEach(function (e) {
          updateUnitItemBasedOnNameMatchForTarget(
            e,
            givenFilterUnitName
          );
        });
      }
    } else if (
      givenFilterUnitName !== null &&
      givenFilterUnitName !== undefined &&
      givenFilterUnitName.trimLeft().trimRight() !== "" &&
      item !== null &&
      item !== undefined
    ) {
      isMatchFound =
        item.name !== null &&
        item.name !== undefined &&
        item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase());
      if (isMatchFound) {
        item.isDisabled = false;
        item.isHidden = false;
      } else if (
        !isMatchFound &&
        (item.childUnitTreeItems == null ||
          item.childUnitTreeItems === undefined ||
          item.childUnitTreeItems.length <= 0)
      ) {
        item.isDisabled = true;
        item.isHidden = true;
      } else if (!isMatchFound) {
        let curAnyChildNameMatched = isAnyChildItemNameMatchedForTarget(
          item.childUnitTreeItems,
          givenFilterUnitName
        );
        item.isDisabled = curAnyChildNameMatched === true;
        item.isHidden = curAnyChildNameMatched === false;
        isMatchFound = curAnyChildNameMatched === true;
      }
      if (
        item.childUnitTreeItems !== null &&
        item.childUnitTreeItems !== undefined &&
        item.childUnitTreeItems.length > 0
      ) {
        item.childUnitTreeItems.forEach(function (e) {
          updateUnitItemBasedOnNameMatchForTarget(
            e,
            givenFilterUnitName
          );
        });
      }
    }
    return isMatchFound;
  };

  const toggleMoveAsTopLevelUnit = () => {
    if (!moveAsTopLevelUnit) {
      setSelectedUnitForTarget("");
      setSelectedUnitCallbackForTarget(0);
    }
    setMoveAsTopLevelUnit(!moveAsTopLevelUnit);
    handleMoveAsTopLevelUnit(!moveAsTopLevelUnit);
  }

  // useEffect(() => {
  //   setFilterUnitName("");
  // }, [isResetClicked]);

  // const handleUnitLevelForTarget = (event:any) => {
  //   const val = (event.target as HTMLInputElement).value;
  //   setSelectedunitlevelForTarget(val);
  //   setUnitLevelForTarget(val);
  // }

  useEffect(() => {
    if (unitsForTarget !== null && unitsForTarget !== undefined && unitsForTarget.length > 0) {
      var newUnits = unitsForTarget.map(function (e) {
        const retFlag = !(
          e !== null &&
          e !== undefined &&
          e.name !== null &&
          e.name !== undefined &&
          updateUnitItemBasedOnNameMatchForTarget(e, filterUnitNameForTarget.toLowerCase())
        );
        return e;
      });
      setUnitsForTarget(newUnits);
    }
  }, [filterUnitNameForTarget]);

  return (
    <>
      <div id={"part1"}
        style={{float: "left", margin: "10px", 
        width: "48%"}}>
        <div>
          <h4 className={styles.sectionTitle}>Unit Pathway</h4>
          <p className={styles.blankContent}>{allResourcesToTranslations.unitmovelabelforsource}</p>
          <div style={{marginTop:'15px'}}>            
            <ButtonGroup
              variant="contained"
              color="primary"
              aria-label="Filter"
              className="unitFilterButtons"
            >
              <Button id="unitsForMoveExpandSrc"
                className={styles.button}
                size="small"
                onClick={expandAllUnitsForSource}
              >
                <ExpandAllIcon />
                <span style={{ marginLeft: "5px", fontSize: "12px" }}>
                  {"Expand All" /*TODO Add translation */}
                </span>
              </Button>
              <Button id="unitsForMoveCollapseSrc"
                className={styles.button}
                size="small"
                onClick={colapseAllUnitsForSource}
              >
                <CollapseAllIcon />
                <span style={{ marginLeft: "5px", fontSize: "12px" }}>
                  {"Collapse All" /*TODO Add translation */}
                </span>
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className="unitFilterSearch">
            <TextField  id="unitsForMoveSearchSrc"
              className={styles.input}
              label={"Unit Search"}
              variant="outlined"
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              value={filterUnitNameForSource}
              onChange={(e) => setFilterUnitNameForSource(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className={styles.unittreeContianer}>
          <div className={styles.treeContianer}  id="unitsForMoveTreeSrc">          
            <FormControl 
              component="fieldset" className="unittreeContianer"
              style={{ margin: "10px", width: "96%" }}
            >
              <RadioGroup 
                name="units2"
                value={selectedUnitForSource}
                onChange={(e) => {
                  updateSelectedUnitForSource(e);
                }}
              >
                <div className={styles.radioUnitTree}>
                  <RadioUnitTree
                    units={unitsForSource}
                    updateSelectedUnit={updateSelectedUnitForSource}
                    updateExpandedUnit={updateExpandedUnitForSource}
                    isAnyUnitSelected={isAnyUnitSelectedForSource}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
      <div id={"part2"} 
        style={{ float: "left", margin: "10px", width: "48%" }}>
        <div>
          <h4 className={styles.sectionTitle}>Unit Pathway</h4>
          <p className={styles.blankContent}>{allResourcesToTranslations.unitmovelabelfortarget}</p>
          <div style={{marginTop:'15px'}}>            
            <ButtonGroup
              variant="contained"
              color="primary"
              aria-label="Filter"
              className="unitFilterButtons"
            >
              <Button  id="unitsForMoveExpandTgt"
                className={styles.button}
                size="small"
                onClick={expandAllUnitsForTarget}
              >
                <ExpandAllIcon />
                <span style={{ marginLeft: "5px", fontSize: "12px" }}>
                  {"Expand All" /*TODO Add translation */}
                </span>
              </Button>
              <Button  id="unitsForMoveCollapseTgt"
                className={styles.button}
                size="small"
                onClick={colapseAllUnitsForTarget}
              >
                <CollapseAllIcon />
                <span style={{ marginLeft: "5px", fontSize: "12px" }}>
                  {"Collapse All" /*TODO Add translation */}
                </span>
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className="unitFilterSearch">
            <TextField  id="unitsForMoveSearchTgt"
              className={styles.input}
              label={"Unit Search"}
              variant="outlined"
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              value={filterUnitNameForTarget}
              onChange={(e) => setFilterUnitNameForTarget(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </div>
        </div>
        <br />
        <span style={{marginLeft: "-10px"}}>              
          <Checkbox disabled={false} id="moveAsTopLevelUnit"
                        size="small"
                        color="primary"
                        checked={moveAsTopLevelUnit}
                        className={styles.checkBox}
                        onClick={(e) => { toggleMoveAsTopLevelUnit(); }}
                    /> 
          <span id="moveAsTopLevelUnitLabel" style={{fontSize: '14px'}}
                onClick={(e) => { toggleMoveAsTopLevelUnit(); }}>
                    {allResourcesToTranslations.moveastoplevelunitlabel}</span> 
        </span>
        <br />
        <div id="secondUnitTreeContainer" style={{marginTop: "-8px"}}>
          <div className={styles.unittreeContianer2}>
            <div className={styles.treeContianer}  id="unitsForMoveTreeTgt">          
              <FormControl
                component="fieldset" className="unittreeContianer"
                style={{ margin: "10px", width: "96%" }}
              >
                <RadioGroup 
                  name="units1"
                  value={selectedUnitForTarget}
                  onChange={(e) => {
                    updateSelectedUnitForTarget(e);
                  }}
                >
                  <div className={styles.radioUnitTree}>
                    <RadioUnitTree
                      units={unitsForTarget}
                      updateSelectedUnit={updateSelectedUnitForTarget}
                      updateExpandedUnit={updateExpandedUnitForTarget}
                      isAnyUnitSelected={isAnyUnitSelectedForTarget}
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RadioUnitsForMove;