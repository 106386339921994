import React, { useEffect, useContext, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import BrandedCard from "../../../Components/BrandedCard";

//Styles
import styles from "../../../styles/addAccount.module.css";
import {
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
} from "@material-ui/core";
import getColor from "../../../functions/getColor";

import { CorperateThemeContext } from "../../../contexts/CorperateThemeContext";
import UpArrowIcon from "../../../CustomIcons/UpArrowIcon";
import DownArrowIcon from "../../../CustomIcons/DownArrowIcon";
import ActiveIcon from "../../../CustomIcons/Status_ActiveIcon";
import InActiveIcon from "../../../CustomIcons/Status_InActiveIcon";
import getOneUserDetails from "../../../API/Administration/Users/GetOneUserDetails";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import CustomTooltip from "../../../Components/CustomTooltip";
import UnbrandedCard from "../../../Components/UnbrandedCard";
import LoaderContainer from "../../../Components/LoaderContainer";

interface UnitListSectionProps {
  usersData: any;
}

const UnitListSection = ({ usersData }: UnitListSectionProps) => {
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
      .toLowerCase()
      .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";

  const [enableRowBanding, setEnableRowBanding] =  useState<boolean>(true); 
  const [unitNameDescending, setUnitNameDescending] =  useState<boolean>(false);
  const [unitIDDescending, setUnitIDDescending] =  useState<boolean>(false);
  const [currentSortBy, setCurrentSortBy] =  useState<string>("");
  const [unitPathwayDescending, setUnitPathwayDescending] =  useState<boolean>(false);
  const [statusDescending, setStatusDescending] =   useState<boolean>(false);
  const [reverseNextSort, setReverseNextSort] =  useState<boolean>(false);
  const [sortInProgress, setSortInProgress] =  useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] =  useState<number>(10);
  const [page, setPage] =  useState<number>(0);
  const [usersList, setUsersList] =  useState<Array<any>>(usersData);
  const history = useHistory();
  const [activeUsersDescending, setActiveUsersDescending] =  useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [inActiveUsersDescending, setInActiveUsersDescending] =  useState<boolean>(false);
  const {
    theme: { color },
  } = useContext(CorperateThemeContext);

  useEffect(() => {
    setUsersList(usersData);
    setLoading(false);
  }, [usersData]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
    } else {
      return {};
    }
  };

  const sortResultsByAsync = async (
    sortBy: "UnitID" | "UnitName" | "UnitPathway" | "Status"| "ActiveUsers"| "InActiveUsers",
    sortGivenItemInDesc: boolean
  ) => {
    if (usersList == null || usersList.length <= 0) {
      return usersList;
    }
    var result = usersList.slice(0);
    setCurrentSortBy(sortBy);
    switch (sortBy) {

      case "UnitPathway":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return -1;
            }
            if (a === null || a.email === null) {
              return 1;
            }
            if (a.unitPathway.toLowerCase() > b.unitPathway.toLowerCase()) {
              return -1;
            }
            if (a.unitPathway.toLowerCase() < b.unitPathway.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitPathwayDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return 1;
            }
            if (a === null || a.email === null) {
              return -1;
            }
            if (a.unitPathway.toLowerCase() < b.unitPathway.toLowerCase()) {
              return -1;
            }
            if (a.unitPathway.toLowerCase() > b.unitPathway.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitPathwayDescending(true);
          }
        }
        setUsersList(result);
        break;
    case "UnitName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.unitName === null) {
              return -1;
            }
            if (a === null || a.unitName === null) {
              return 1;
            }
            if (a.unitName.toLowerCase() > b.unitName.toLowerCase()) {
              return -1;
            }
            if (a.unitName.toLowerCase() < b.unitName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.unitName === null) {
              return 1;
            }
            if (a === null || a.unitName === null) {
              return -1;
            }
            if (a.unitName.toLowerCase() < b.unitName.toLowerCase()) {
              return -1;
            }
            if (a.unitName.toLowerCase() > b.unitName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitNameDescending(true);
          }
        }
        setUsersList(result);
        break;
    case "UnitID":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.unitID === null) {
              return -1;
            }
            if (a === null || a.unitID === null) {
              return 1;
            }
            if (a.unitID > b.unitID) {
              return -1;
            }
            if (a.unitID < b.unitID) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitIDDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.unitID === null) {
              return 1;
            }
            if (a === null || a.unitID === null) {
              return -1;
            }
            if (a.unitID < b.unitID) {
              return -1;
            }
            if (a.unitID > b.unitID) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitIDDescending(true);
          }
        }
        setUsersList(result);
        break;
    case "Status":
          if (sortGivenItemInDesc) {
            result.sort((a, b) => {
              if (b === null || b.active === null) {
                return -1;
              }
              if (a === null || a.active === null) {
                return 1;
              }
              if (a.active.toString().toLowerCase() > b.active.toString().toLowerCase()) {
                return -1;
              }
              if (a.active.toString().toLowerCase() < b.active.toString().toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setStatusDescending(false);
            }
          } else {
            result.sort((a, b) => {
              if (b === null || b.active === null) {
                return 1;
              }
              if (a === null || a.active === null) {
                return -1;
              }
              if (a.active.toString().toLowerCase() < b.active.toString().toLowerCase()) {
                return -1;
              }
              if (a.active.toString().toLowerCase() > b.active.toString().toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setStatusDescending(true);
            }
          }
          setUsersList(result);
          break;
    
          case "ActiveUsers":
            if (sortGivenItemInDesc) {
              result.sort((a, b) => {
                if (b === null || b.activeUsers === null) {
                  return -1;
                }
                if (a === null || a.activeUsers === null) {
                  return 1;
                }
                if (a.activeUsers > b.activeUsers) {
                  return -1;
                }
                if (a.activeUsers < b.activeUsers) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setActiveUsersDescending(false);
              }
            } else {
              result.sort((a, b) => {
                if (b === null || b.activeUsers === null) {
                  return 1;
                }
                if (a === null || a.activeUsers === null) {
                  return -1;
                }
                if (a.activeUsers < b.activeUsers) {
                  return -1;
                }
                if (a.activeUsers > b.activeUsers) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setActiveUsersDescending(true);
              }
            }
            setUsersList(result);
            break;
    
            case "InActiveUsers":
              if (sortGivenItemInDesc) {
                result.sort((a, b) => {
                  if (b === null || b.InActiveUsers === null) {
                    return -1;
                  }
                  if (a === null || a.InActiveUsers === null) {
                    return 1;
                  }
                  if (a.InActiveUsers > b.InActiveUsers) {
                    return -1;
                  }
                  if (a.InActiveUsers < b.InActiveUsers) {
                    return 1;
                  }
                  return 0;
                });
                if (reverseNextSort) {
                  setInActiveUsersDescending(false);
                }
              } else {
                result.sort((a, b) => {
                  if (b === null || b.InActiveUsers === null) {
                    return 1;
                  }
                  if (a === null || a.InActiveUsers === null) {
                    return -1;
                  }
                  if (a.InActiveUsers < b.InActiveUsers) {
                    return -1;
                  }
                  if (a.InActiveUsers > b.InActiveUsers) {
                    return 1;
                  }
                  return 0;
                });
                if (reverseNextSort) {
                  setInActiveUsersDescending(true);
                }
              }
              setUsersList(result);
              break;
          
    
    
          default:
        break;
    }
    return result.slice(0);
  };

  const sortResultsBy = async (
    sortBy:"UnitID" | "UnitName" | "UnitPathway" | "Status" | "ActiveUsers"| "InActiveUsers",
    sortGivenItemInDesc: boolean
  ) => {
    setSortInProgress(true);
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy);
      setUsersList(result);
      setPage(0);
      setSortInProgress(false);
    });
  };
  const clickStatusAsc = () => {
    setReverseNextSort(false);
    setStatusDescending(false);
    sortResultsBy("Status", false);
  };

  const clickStatusDesc = () => {
    setReverseNextSort(false);
    setStatusDescending(true);
    sortResultsBy("Status", true);
  };
  const clickUnitNameAsc = () => {
    setReverseNextSort(false);
    setUnitNameDescending(false);
    sortResultsBy("UnitName", false);
  };

  const clickUnitNameDesc = () => {
    setReverseNextSort(false);
    setUnitNameDescending(true);
    sortResultsBy("UnitName", true);
  };

  const clickUnitIDAsc = () => {
    setReverseNextSort(false);
    setUnitIDDescending(false);
    sortResultsBy("UnitID", false);
  };

  const clickUnitIDDesc = () => {
    setReverseNextSort(false);
    setUnitIDDescending(true);
    sortResultsBy("UnitID", true);
  };


  const clickUnitPathwayAsc = () => {
    setReverseNextSort(false);
    setUnitPathwayDescending(false);
    sortResultsBy("UnitPathway", false);
  };

  const clickUnitPathwayDesc = () => {
    setReverseNextSort(false);
    setUnitPathwayDescending(true);
    sortResultsBy("UnitPathway", true);
  };


  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };


  const clickActiveUsersAsc = () => {
    setReverseNextSort(false);
    setActiveUsersDescending(false);
    sortResultsBy("ActiveUsers", false);
  };

  const clickActiveUsersDesc = () => {
    setReverseNextSort(false);
    setActiveUsersDescending(true);
    sortResultsBy("ActiveUsers", true);
  };

  
  const clickInActiveUsersAsc = () => {
    setReverseNextSort(false);
    setInActiveUsersDescending(false);
    sortResultsBy("InActiveUsers", false);
  };

  const clickInActiveUsersDesc = () => {
    setReverseNextSort(false);
    setInActiveUsersDescending(true);
    sortResultsBy("InActiveUsers", true);
  };

  const handleUnitDetails = (unitID: any) => {

    history.push({
      pathname: `unitDetails/`+ unitID
    })
  
  }
  
  if (loading) return <LoaderContainer />;

  return (
    <div style={{ marginTop: "36px" }}>
      <UnbrandedCard>
        <div style={{ padding: "0px 30px 0px 23px" }}>
          <div className={styles.paginationControlsContainer}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "95%",
              }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 100]}
                component="div"
                count={usersList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </div>

          <div className={styles.tableSection}>
            <div className={styles.tableContainer}>
              <BrandedCard>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>

                      <TableCell
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                            borderRadius: "8px 0 0 0", 
                          }}
                          align="left"
                        >
                          <div
                            className={styles.tableHeadCellContainer}
                            style={{ display: "flex",  }}
                          >
                            <div
                              onClick={() => {
                                setUnitIDDescending(!unitIDDescending);
                                sortResultsBy("UnitID", !unitIDDescending);
                              }}
                            >
                              {"UnitID" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "UnitID" &&
                                    unitIDDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickUnitIDAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "UnitID" &&
                                    unitIDDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickUnitIDDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                          }}
                          align="left"
                        >
                          <div
                            className={styles.tableHeadCellContainer}
                            style={{ display: "flex" }}
                          >
                            <div
                              onClick={() => {
                                setUnitNameDescending(!unitNameDescending);
                                sortResultsBy("UnitName", !unitNameDescending);
                              }}
                            >
                              {"Unit Name" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "UnitName" &&
                                    unitNameDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickUnitNameAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "LastName" &&
                                    unitNameDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickUnitNameDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>  
                        <TableCell
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,                            
                          }}
                          align="left"
                        >
                          <div
                            className={styles.tableHeadCellContainer}
                            style={{ display: "flex" }}
                          >
                            <div
                              onClick={() => {
                                setUnitPathwayDescending(!unitPathwayDescending);
                                sortResultsBy("UnitPathway", !unitPathwayDescending);
                              }}
                            >
                              {"Unit Pathway" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "UnitPathway" &&
                                      unitPathwayDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickUnitPathwayAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "UnitPathway" &&
                                      unitPathwayDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickUnitPathwayDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                         style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color
                        }}
                          align="left"
                        >
                          <div
                            className={styles.tableHeadCellContainer}
                            style={{ display: "flex" }}
                          >
                            <div
                              onClick={() => {
                                setStatusDescending(!statusDescending);
                                sortResultsBy("Status", !statusDescending);
                              }}
                            >
                              {"Unit Status" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "Status" &&
                                    statusDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickStatusAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "Status" &&
                                    statusDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickStatusDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                            borderRadius: "0 8px 0 0" 
                          }}
                          align="left"
                        >
                          <div
                            className={styles.tableHeadCellContainer}
                            style={{ display: "flex",  }}
                          >
                            <div
                              onClick={() => {
                                setUnitIDDescending(!activeUsersDescending);
                                sortResultsBy("ActiveUsers", !activeUsersDescending);
                              }}
                            >
                              {"Active/Inactive Accounts" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "ActiveUsers" &&
                                    activeUsersDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickActiveUsersAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "ActiveUsers" &&
                                    activeUsersDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickActiveUsersDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                       
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {usersList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((result: any, i) => {
                          return (
                            <TableRow
                              key={`reportScheduler-${i}`}
                              style={rowBackgroundColor(i)}
                            >
                              <TableCell align="left">
                                <Link style={{color: '#63C2FF', textDecoration: 'underline'}} to={`/unitDetails/${result.unitID}`} >{result.unitID}</Link>
                                 {/* <a style={{color: '#63C2FF', textDecoration: 'underline'}} href={'/unitDetails/' + result.unitID} target="_Blank">{result.unitID}</a> */}
                                {/* <span style={{ color: '#63C2FF', textDecoration: 'underline', cursor: "pointer" }} onClick={() => handleUnitDetails(result.unitID)}>{result.unitID}</span>                                 */}
                              </TableCell>                              
                              <TableCell align="left">
                              <Link style={{color: '#63C2FF', textDecoration: 'underline'}} to={`/unitDetails/${result.unitID}`} >{result.unitName}</Link>
                                {/* <a style={{color: '#63C2FF', textDecoration: 'underline'}} href={'/unitDetails/' + result.unitID} target="_Blank">{result.unitName}</a> */}
                                {/* <span style={{ color: '#63C2FF', textDecoration: 'underline', cursor: "pointer" }} onClick={() => handleUnitDetails(result.unitID)}>{result.unitName}</span> */}
                              </TableCell>                             
                              <TableCell align="left">
                                {result.unitPathway}
                              </TableCell>
                              <TableCell align="left">
                              { result.active ?
                                <CustomTooltip displayText={["Active"]}>
                                <span style={{paddingRight: "5px"}}><ActiveIcon></ActiveIcon></span>
                                </CustomTooltip>  
                                : 
                                <CustomTooltip displayText={["Inactive"]}>
                                <span style={{paddingRight: "5px"}}><InActiveIcon></InActiveIcon></span>
                                </CustomTooltip>                                
                              }
                              {result.active ? "Active" : "Inactive"}</TableCell> 
                              <TableCell align="left">
                               <CustomTooltip displayText={["Active Accounts"]}>
                              <span style={{paddingRight: "5px"}}><ActiveIcon></ActiveIcon>&nbsp;{result.activeUsers}</span>
                               </CustomTooltip>  &nbsp;&nbsp;
                               <CustomTooltip displayText={["Inactive Accounts"]}>
                              <span style={{paddingRight: "5px"}}><InActiveIcon></InActiveIcon>&nbsp;{result.inActiveUsers}</span>
                               </CustomTooltip>  
                              </TableCell>                               
                            </TableRow>                           
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </BrandedCard>

              <div className={styles.paginationAddedUsersControl}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "95%",
                  }}
                >
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={usersList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </UnbrandedCard>
    </div>
  );
};

export default UnitListSection;
