import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { MyTrainingItem } from '../../globalTypes'
//Contexts
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Components
import CustomTooltip from '../../Components/CustomTooltip';
//MUI Components
import Button from '@material-ui/core/Button';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import styles from '../../styles/courseList.module.css';

interface Props {
    trainingListItem: MyTrainingItem
    logNavigation: (message: string) => void
}

const StartCourseButton = ({ trainingListItem, logNavigation }: Props) => {
    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
    const { id, name } = trainingListItem
    return (
        <Link
            onClick={() => logNavigation(`Opened ${name}`)}
            to={{
            pathname: `mytraining/${id}`,
            }}
        >
            <CustomTooltip displayText={[allResourcesToTranslations.start]}>
                <Button
                    className={styles.courseListButton}
                    variant="contained"
                    color="primary"
                >
                <PlayArrowIcon />
            </Button>
            </CustomTooltip>
        </Link>
    )
}

export default StartCourseButton
