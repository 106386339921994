import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const CertificateIcon = () => (
  <SvgIcon>
    <path d="M17.9,15.6c0-2.3-1.8-4.1-4.1-4.1c-2.3,0-4.1,1.8-4.1,4.1c0,1.5,0.8,2.8,2.1,3.6v4.4c0,0.1,0,0.2,0.1,0.3
        C12,24,12.1,24,12.2,24l0,0c0,0,0.1,0,0.1,0l0,0c0.1,0,0.1-0.1,0.2-0.1l1.3-1.3l1.3,1.3c0,0,0,0,0.1,0c0,0,0,0,0.1,0
        c0,0,0.1,0,0.1,0l0,0c0.1,0,0.1,0,0.2,0h0c0.1,0,0.1-0.1,0.2-0.2c0,0,0,0,0,0c0,0,0-0.1,0-0.2l0,0v-4.4
        C17.1,18.5,17.9,17.1,17.9,15.6z M15,22.6l-0.9-0.9c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.1c0,0,0,0,0,0
        l-0.9,0.9v-3c0.8,0.2,1.6,0.2,2.4,0L15,22.6z M13.8,18.7c-1.7,0-3-1.4-3-3s1.4-3,3-3s3,1.4,3,3C16.8,17.3,15.5,18.7,13.8,18.7z
        M21.2,3.5v14.8c-0.1,1.9-1.6,3.4-3.5,3.5c-0.5,0-1-0.4-1-1c0-0.5,0.4-1,1-1h0.1c0.8,0,1.5-0.7,1.5-1.5V3.5c0-0.8-0.7-1.5-1.5-1.5
        l0,0H7.2C6.4,2,5.7,2.6,5.7,3.5c0,0,0,0,0,0v14.8c0,0.8,0.7,1.5,1.5,1.5h0h2.6c0.5,0,1,0.4,1,1s-0.4,1-1,1H7.2
        c-1.9,0-3.5-1.5-3.5-3.5V3.5C3.8,1.5,5.3,0,7.2,0h10.5C19.7,0,21.2,1.5,21.2,3.5z M7.9,5.5V5.2c0-0.2,0.2-0.4,0.4-0.4h5.2
        c0.2,0,0.4,0.2,0.4,0.4v0.2c0,0.2-0.2,0.4-0.4,0.4H8.3C8.1,5.9,7.9,5.7,7.9,5.5z M7.9,7c0-0.2,0.2-0.4,0.4-0.4h3.8
        c0.2,0,0.4,0.2,0.4,0.4v0.2c0,0.2-0.2,0.4-0.4,0.4H8.3c-0.2,0-0.4-0.2-0.4-0.4V7z"/>
  </SvgIcon>
);

export default CertificateIcon
