//React + Typescript
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom'
// Libs
import { useReactOidc, getUserManager } from '@axa-fr/react-oidc-context';
//Contexts
import { TranslationsContext } from '../contexts/TranslationsContext'
import { UIContext } from '../contexts/UIContext'
import { LoaderContext } from '../contexts/LoaderContext';
//API Functions
import { addError } from '../API/AddError'
//Components
import SupportModal from './SupportModal'
import ReleaseNotesModal from './ReleaseNotesModal'
//MUI Components
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import FeedbackIcon from '@material-ui/icons/Feedback';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import ManageTipVideosIcon from '../CustomIcons/NavigationMenu/ManageTipVideosIcon';
//Styles
import styles from '../styles/userControls.module.css';
import { getOrgSupportDetails } from '../API/GetOrgSupportDetails';
import ManageTipVideosIconNonWhiteMenu from '../CustomIcons/NavigationMenu/ManageTipVideosMenuNonWhiteIcon';

export const UserControls = (): JSX.Element => {
  const history = useHistory();
  const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [ showReleaseNotes, setShowReleaseNotes ] = useState<boolean>(false)
  const [ showSupportModal, setShowSupportModal ] = useState<boolean>(false)

  const { oidcUser, logout } = useReactOidc();
  const orgDomain = oidcUser.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0;
  const { setMessage } = useContext(LoaderContext)
  const { showSSOExitButton, showSuperAdminUI, showOrgAdminUI, showUnitAdminUI, displayName, IsAccSettingsChangedAndNotSaved, IsUserModuleAccessChangedAndNotSaved, IsReportSchedulerChangedAndNotSaved, setIsLoggingOut, EnableActivityLogs, superAdminSelectedBasicOrgData } = useContext(UIContext)
  const [defaultEmailAdress, setdefaultEmailAdress] = useState<string>("")
  const [defaultPhoneNumber, setdefaultPhoneNumber] = useState<string>("")
  const [defaultReplyToAddress, setdefaultReplyToAddress] = useState<string>("")  
  const [emailAddress, setemailAddress] = useState<string>("")
  const [phoneNumber, setphoneNumber] = useState<string>("")
  const [replyToAddress, setreplyToAddress] = useState<string>("")
  const {  showTermsAndConditionsModel, setshowTermsAndConditionsModel} = useContext(UIContext);
 

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    addError(oidcUser.access_token, userId, orgDomain, "Opened user options dropdown", null, "UserControls.tsx", 6, EnableActivityLogs)
    setAnchorEl(event.currentTarget);
  }
  
  const handleClose = () => { 
    addError(oidcUser.access_token, userId, orgDomain, "Closed user options dropdown", null, "UserControls.tsx", 6, EnableActivityLogs)
    setAnchorEl(null) 
  }
  
  const handleLogout = () => {
    if (IsReportSchedulerChangedAndNotSaved && history.location.pathname.toLowerCase().indexOf("/reportscheduler") >= 0) {
      setAnchorEl(null) 
      history.push(history.location.pathname);
      setIsLoggingOut(true)
    } else if (IsAccSettingsChangedAndNotSaved && history.location.pathname === "/accountsettings") {
      setAnchorEl(null) 
      history.push("/accountsettings");
      setIsLoggingOut(true)
    } else if (IsUserModuleAccessChangedAndNotSaved && history.location.pathname.toLowerCase().includes("/accountdetails/")) {
      setAnchorEl(null) 
      let idx = history.location.pathname.toLowerCase().indexOf("/accountdetails/");
      history.push(history.location.pathname.substring(idx, history.location.pathname.length - idx));
      setIsLoggingOut(true)
    } else {
    setMessage(allResourcesToTranslations.loggingout) 
    logout(); 
    addError(oidcUser.access_token, userId, orgDomain, "Logged out", null, "UserControls.tsx", 6, EnableActivityLogs)
    getUserManager().signoutRedirect()
         .catch((error) => {
           //TODO Error API here
         });
    getUserManager().signoutRedirectCallback()
         .then(() => {
          getUserManager().removeUser()
          })
         .catch((error) => {
           //TODO Error API here
         });
        }
  };

  const openFeedbackTab = () => {
    addError(oidcUser.access_token, userId, orgDomain, "Selected send feedback tab", null, "UserControls.tsx", 6, EnableActivityLogs)
    //window.open("https://forms.office.com/r/jcwZ8Jrc0s", "_blank")
    //window.open("https://forms.office.com/Pages/ResponsePage.aspx?id=Xmy4xx8Tu0yZEuy62r_ho5wshv5DxxFNpYSXqqkepxJUM0RNWVYwOFRNQ08yUTlTNkk2Wk9aUFlKRC4u", "_blank")
    window.open("https://forms.office.com/r/7xAimSd5q6", "_blank")
  }

  const handleSelection = (selection: string) => {
    addError(oidcUser.access_token, userId, orgDomain, `Selected ${selection} tab`, null, "UserControls.tsx", 6, EnableActivityLogs)
    handleClose()
  }

  const handleOpenReleaseNotes = () => {
    addError(oidcUser.access_token, userId, orgDomain, `Selected release notes`, null, "UserControls.tsx", 6, EnableActivityLogs)
    setShowReleaseNotes(true)
    setAnchorEl(null) 
  }

  const handleOpenSupportModal = () => {

    getOrgSupportDetails(oidcUser.access_token, userId, orgDomain, !orgId? -1 : parseInt(orgId)).then(dataResult => {           
      setemailAddress(dataResult.supportDetails.emailAddress=="" ? dataResult.supportDetails.defaultEmailAddress : dataResult.supportDetails.emailAddress );
      setphoneNumber(dataResult.supportDetails.phoneNumber=="" ? dataResult.supportDetails.defaultPhoneNumber : dataResult.supportDetails.phoneNumber );
      setreplyToAddress(dataResult.supportDetails.replyToAddress=="" ? dataResult.supportDetails.defaultReplyToAddress : dataResult.supportDetails.replyToAddress );
   
      setShowSupportModal(true);
      setAnchorEl(null); 
      setshowTermsAndConditionsModel(false);
      }).catch((error:any) => {           
        addError(oidcUser.access_token, userId, orgDomain, `Selected support modal`, null, "UserControls.tsx", 6, EnableActivityLogs)
    });

    
  }

  const handleSSOExit = () => {
    addError(oidcUser.access_token, userId, orgDomain, `Logged out via SSO`, null, "UserControls.tsx", 6, EnableActivityLogs)
    handleClose()
  } 

  const renderExitOrLogout = () => {
    if(showSSOExitButton) {
      return(
        <Link to='/loggedout'>
          <MenuItem onClick={() => handleSSOExit() }>
            <CancelPresentationIcon />
            <span style={{marginLeft:'5px'}}>
              {allResourcesToTranslations.exit}
            </span>
          </MenuItem>
        </Link>
      )
    } else {
      return(
        <MenuItem onClick={() => handleLogout() }>
          <ExitToAppIcon />
          <span style={{marginLeft:'5px'}}>
            {allResourcesToTranslations.logout}
          </span>
        </MenuItem>   
      )
    }
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.userNameDiv}>
          <span className={styles.userName}>{displayName}</span>
        </div>
        <IconButton
          className={`${styles.iconButton} accountSettings`}      
          onClick={ handleOpen }
          color="primary"
          aria-label={allResourcesToTranslations.accountsettings}
          disableRipple={true}
          style={{ padding: 0 } /* Overide MUI default padding */}
        >
          <AccountCircleIcon style={{ fontSize: 30 }} />
        </IconButton>
        <Menu
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Link to="/accountsettings" style={{color: 'black'}}>
            <MenuItem onClick={() => handleSelection("Account Settings") }>
              <SettingsIcon />
              <span style={{marginLeft:'5px'}}>
                {allResourcesToTranslations.accountsettings}
              </span>
            </MenuItem>
          </Link>
          <Link to="/help" style={{color: 'black'}}>
            <MenuItem onClick={() => handleSelection("help") }>
                <HelpOutlineIcon />
                <span style={{marginLeft:'5px'}}>
                  {allResourcesToTranslations.help}
                </span>
            </MenuItem>
          </Link>
          <MenuItem onClick={() => handleOpenSupportModal() }>
            <HeadsetMicIcon />
            <span style={{marginLeft:'5px'}}>
              {allResourcesToTranslations.support}
            </span>
          </MenuItem>
          {
            (!!superAdminSelectedBasicOrgData !== true || (superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData.orgID !== true) || (superAdminSelectedBasicOrgData != null && superAdminSelectedBasicOrgData.orgID <= 0)) && 
            (showOrgAdminUI || showSuperAdminUI || showUnitAdminUI) &&
            <Link to="/tipvideos" style={{color: 'black'}}>
              <MenuItem onClick={() => handleSelection("Tip Video")}>
                <ManageTipVideosIconNonWhiteMenu />
                <span style={{marginLeft:'5px'}}>
                  {allResourcesToTranslations.tipvideos}
                </span>
              </MenuItem>
            </Link>
          }
          {
          (showOrgAdminUI || showSuperAdminUI || showUnitAdminUI) ? ( 
          <MenuItem onClick={openFeedbackTab}>
              <FeedbackIcon />
              <span style={{marginLeft:'5px'}}>
                {allResourcesToTranslations.feedback}
              </span>
          </MenuItem>
             ) : (null)
          }
          {
            (showOrgAdminUI || showSuperAdminUI || showUnitAdminUI) ? (
              <MenuItem onClick={() => handleOpenReleaseNotes() }>
                <NewReleasesIcon />
                <span style={{marginLeft:'5px'}}>
                  {allResourcesToTranslations.releasenotes}
                </span>
              </MenuItem>
            ) : (null)
          }
          {
            renderExitOrLogout()
          }
        </Menu>
      </div>
      <ReleaseNotesModal modalOpen={showReleaseNotes} setModalOpen={setShowReleaseNotes}/> 
      <SupportModal modalOpen={showSupportModal} setModalOpen={setShowSupportModal}  emailAddress={emailAddress} phoneNumber={phoneNumber} replyToAddress={replyToAddress}/>
    </>
  );
};



export default UserControls;
