//React + Typescript
import React, { useEffect, useState } from "react";
import { UnitTreeItem } from "../../../globalTypes";
//MUI Components
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import {
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  RadioGroup, FormControlLabel, FormLabel,Radio
} from "@material-ui/core";


//Styles
import styles from "../../../styles/addUnit.module.css";
import RadioUnitTree from "./RadioUnitTree";
import ExpandAllIcon from "../../../CustomIcons/ExpandAllIcon";
import CollapseAllIcon from "../../../CustomIcons/CollapseAllIcon";

export const RadioUnits = (props: any) => {
  const { isResetClicked, givenUnits, selectedUnitId, setSelectedUnitCallback, setSelectedunitlevel } = props;
  const [units, setUnits] = useState<Array<UnitTreeItem>>(givenUnits);
  const [selectedUnit, setSelectedUnit] = useState<any>("");
  const [interactionCount, setInteractionCount] = useState<number>(0); //Required to force update the tree
  const [filterUnitName, setFilterUnitName] = useState("");
  const [unitlevel, setUnitLevel] = useState<any>("toplevel");
  useEffect(() => {
      setUnits([...givenUnits]);
      if(selectedUnitId !== 0) {
        setUnitSelected(givenUnits);
      }
  },[givenUnits])

  const updateSelectedUnit = (id: any) => {
    let updatedUnits = units;
    const updateSelected = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.unitID == id.target.value) {
          unit.isSelected = !unit.isSelected;
          setSelectedUnit(unit.unitID);
          setSelectedUnitCallback(unit.unitID);
        }
        updateSelected(unit.childUnitTreeItems);
      }
    };
    updateSelected(updatedUnits);
    setInteractionCount(interactionCount + 1);
    setUnits(updatedUnits);
  };

  const updateExpandedUnit = (id: number) => {
    let updatedUnits = units;
    const checkForExpanded = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.unitID === id) {
          unit.isExpanded = !unit.isExpanded;
        }
        checkForExpanded(unit.childUnitTreeItems);
      }
    };
    checkForExpanded(updatedUnits);
    setInteractionCount(interactionCount + 1);
    setUnits(updatedUnits);
  };

  const setUnitSelected = (givenUnits: Array<UnitTreeItem> | null) => {
    let updatedUnits = givenUnits;
    const checkIfAnyUnitSelected = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return false; //Break if no children
      if (unitArray.length === 0) return false;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.isSelected) {
          setSelectedUnit(unit.unitID);
          setSelectedUnitCallback(unit.unitID);
          return true;
        }
        if (checkIfAnyUnitSelected(unit.childUnitTreeItems)) {
          return true;
        }
      }
      return false;
    };
    return checkIfAnyUnitSelected(updatedUnits);
  };

  const isAnyUnitSelected = (givenUnits: Array<UnitTreeItem> | null) => {
    let updatedUnits = givenUnits;
    const checkIfAnyUnitSelected = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return false; //Break if no children
      if (unitArray.length === 0) return false;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.isSelected) {
          return true;
        }
        if (checkIfAnyUnitSelected(unit.childUnitTreeItems)) {
          return true;
        }
      }
      return false;
    };
    return checkIfAnyUnitSelected(updatedUnits);
  };

  const expandAllUnits = () => {
    let updatedUnits = units;
    const checkForSelected = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        if (!unitArray[i].isHidden) {
          const unit = unitArray[i];
          unit.isExpanded = true;
          checkForSelected(unit.childUnitTreeItems);
        }
      }
    };
    checkForSelected(updatedUnits);
    setInteractionCount(interactionCount + 1);
    setUnits(updatedUnits);
  };

  const colapseAllUnits = () => {
    let updatedUnits = units;
    const checkForSelected = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        if (!unitArray[i].isHidden) {
          const unit = unitArray[i];
          unit.isExpanded = false;
          checkForSelected(unit.childUnitTreeItems);
        }
      }
    };
    checkForSelected(updatedUnits);
    setInteractionCount(interactionCount + 1);
    setUnits(updatedUnits);
  };

  const isGivenItemNameMatched = (
    item: UnitTreeItem,
    givenFilterUnitName: string
  ): boolean | undefined => {
    if (
      item === null ||
      item === undefined ||
      item.name === null ||
      item.name === undefined
    ) {
      return false;
    }
    if (
      givenFilterUnitName === null ||
      givenFilterUnitName === undefined ||
      givenFilterUnitName.trimLeft().trimRight() === ""
    ) {
      return true;
    } else {
      if (item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase())) {
        return true;
      }
      return false;
    }
  };

  const isAnyChildItemNameMatched = (
    list: UnitTreeItem[] | null,
    givenFilterUnitName: string
  ): boolean | undefined => {
    if (list === null || list === undefined || list.length <= 0) {
      return false;
    }
    if (
      givenFilterUnitName === null ||
      givenFilterUnitName === undefined ||
      givenFilterUnitName.trimLeft().trimRight() === ""
    ) {
      return true;
    } else {
      var newList = list.filter((e) => {
        return (
          isGivenItemNameMatched(e, givenFilterUnitName) === true ||
          isAnyChildItemNameMatched(
            e.childUnitTreeItems,
            givenFilterUnitName
          ) === true
        );
      });
      if (newList !== null && newList !== undefined && newList.length > 0) {
        return true;
      }
      return false;
    }
  };

  const updateUnitItemBasedOnNameMatch = (
    item: UnitTreeItem,
    givenFilterUnitName: string
  ): boolean | undefined => {
    let isMatchFound = false;
    if (
      (givenFilterUnitName === null ||
        givenFilterUnitName === undefined ||
        givenFilterUnitName.trimLeft().trimRight() === "") &&
      item !== null &&
      item !== undefined
    ) {
      isMatchFound = true;
      item.isDisabled = !isMatchFound;
      item.isHidden = !isMatchFound;
      if (
        item.childUnitTreeItems !== null &&
        item.childUnitTreeItems !== undefined &&
        item.childUnitTreeItems.length > 0
      ) {
        item.childUnitTreeItems.forEach(function (e) {
          let isMatchFoundSub: boolean | undefined = false;
          isMatchFoundSub = updateUnitItemBasedOnNameMatch(
            e,
            givenFilterUnitName
          );
        });
      }
    } else if (
      givenFilterUnitName !== null &&
      givenFilterUnitName !== undefined &&
      givenFilterUnitName.trimLeft().trimRight() !== "" &&
      item !== null &&
      item !== undefined
    ) {
      isMatchFound =
        item.name !== null &&
        item.name !== undefined &&
        item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase());
      if (isMatchFound) {
        item.isDisabled = false;
        item.isHidden = false;
      } else if (
        !isMatchFound &&
        (item.childUnitTreeItems == null ||
          item.childUnitTreeItems === undefined ||
          item.childUnitTreeItems.length <= 0)
      ) {
        item.isDisabled = true;
        item.isHidden = true;
      } else if (!isMatchFound) {
        let curAnyChildNameMatched = isAnyChildItemNameMatched(
          item.childUnitTreeItems,
          givenFilterUnitName
        );
        item.isDisabled = curAnyChildNameMatched === true;
        item.isHidden = curAnyChildNameMatched === false;
        isMatchFound = curAnyChildNameMatched === true;
      }
      if (
        item.childUnitTreeItems !== null &&
        item.childUnitTreeItems !== undefined &&
        item.childUnitTreeItems.length > 0
      ) {
        item.childUnitTreeItems.forEach(function (e) {
          let isMatchFoundSub: boolean | undefined = false;
          isMatchFoundSub = updateUnitItemBasedOnNameMatch(
            e,
            givenFilterUnitName
          );
        });
      }
    }
    return isMatchFound;
  };

  useEffect(() => {
    setFilterUnitName("");
  }, [isResetClicked]);

  const handleUnitLevel = (event:any) => {
    const val = (event.target as HTMLInputElement).value;
    setSelectedunitlevel(val);
    setUnitLevel(val);
  }
  useEffect(() => {
    if (units !== null && units !== undefined && units.length > 0) {
      var newUnits = units.map(function (e) {
        const retFlag = !(
          e !== null &&
          e !== undefined &&
          e.name !== null &&
          e.name !== undefined &&
          updateUnitItemBasedOnNameMatch(e, filterUnitName.toLowerCase())
        );
        return e;
      });
      setUnits(newUnits);
    }
  }, [filterUnitName]);
  return (
    <>
      <div>
        <div>
          <h4 className={styles.sectionTitle}>Unit Pathway</h4>
          <p className={styles.blankContent}>Select an option below to add the Unit to</p>

          <div className="unitoption" style={{marginTop:'15px'}}>
                <FormControl component="fieldset" style={{ width: '100%' }}>                               
                    <div className={styles.childContainer}>
                        <RadioGroup
                            name="periodType"
                            value={unitlevel}
                            onChange={handleUnitLevel}
                            style={{ display: 'flex', flexDirection: 'row' }}
                        >
                            <FormControlLabel
                                value={"toplevel"}
                                control={<Radio color="primary" />}
                                label={"Add a top level Unit"}
                            />
                            <FormControlLabel
                                value={"subunit"}
                                control={<Radio color="primary" />}
                                label={"Add a sub Unit of.."}
                            />
                            
                        </RadioGroup>
                    </div>
                </FormControl>
          </div>

          <div style={{marginTop:'15px'}}>            
            <ButtonGroup
              variant="contained"
              color="primary"
              aria-label="Filter"
              className="unitFilterButtons"
            >
              <Button
                className={styles.button}
                size="small"
                onClick={expandAllUnits}
              >
                <ExpandAllIcon />
                <span style={{ marginLeft: "5px", fontSize: "12px" }}>
                  {"Expand All" /*TODO Add translation */}
                </span>
              </Button>
              <Button
                className={styles.button}
                size="small"
                onClick={colapseAllUnits}
              >
                <CollapseAllIcon />
                <span style={{ marginLeft: "5px", fontSize: "12px" }}>
                  {"Collapse All" /*TODO Add translation */}
                </span>
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className="unitFilterSearch">
            <TextField
              className={styles.input}
              label={"Unit Search"}
              variant="outlined"
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              value={filterUnitName}
              onChange={(e) => setFilterUnitName(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </div>
        </div>
        <div className="unittreeContianer">
        <div className={styles.treeContianer}>
        
          <FormControl
            component="fieldset" className="unittreeContianer"
            style={{ margin: "10px", width: "96%" }}
          >
            <RadioGroup
              name="units"
              value={selectedUnit}
              onChange={(e) => {
                updateSelectedUnit(e);
              }}
            >
              <div className={styles.radioUnitTree}>
                <RadioUnitTree
                  units={units}
                  updateSelectedUnit={updateSelectedUnit}
                  updateExpandedUnit={updateExpandedUnit}
                  isAnyUnitSelected={isAnyUnitSelected}
                />
              </div>
            </RadioGroup>
          </FormControl>
          </div>
        </div>
      </div>
    </>
  );
};

export default RadioUnits;
