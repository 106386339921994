import React, { useState, createContext, useEffect } from "react";
import { OrgBasicData } from "../globalTypes";

interface IUIContext {
  menuSelection: string;
  setMenuSelection: (menuSelection: string) => void;
  myTrainingSelection: string; //All or Course or Policy
  setMyTrainingSelection: (myTrainingSelection: string) => void;
  showCPD: boolean
  setShowCPD: (showCPD: boolean) => void;
  showWelcomeModal: boolean;
  setShowWelcomeModal: (showWelcomeModal: boolean) => void;
  title: string;
  setTitle: (title: string) => void;
  titleEnglish: string;
  setTitleEnglish: (titleEnglish: string) => void;
  subComponentName: string;
  setSubComponentName: (subComponentName: string) => void;
  pageHelpVideoURL: string;
  setPageHelpVideoURL: (url: string) => void;
  allTipVideos: any,
  setAllTipVideos: (allTipVideos: any) => void;
  tipVideosReloadRequired: boolean;
  setTipVideosReloadRequired: (tipVideosReloadRequired: boolean) => void;
  showPolicyUI: boolean;
  setShowPolicyUI: (showPolicyUI: boolean) => void;
  showCourseUI: boolean;
  setShowCourseUI: (showPolicyUI: boolean) => void;
  showQuizUI: boolean;
  setShowQuizUI: (showPolicyUI: boolean) => void;
  showMyCertificateUI: boolean;
  setShowMyCertificateUI: (showMyCertificateUI: boolean) => void;
  showSuperAdminUI: boolean;
  showUnitAdminUI: boolean;
  showOrgAdminUI: boolean;
  setAdminLevel: (adminLevel: number) => void
  showBackButton: boolean
  setShowBackButton: (showBackButton: boolean) => void
  backRoute: string,
  updateBackButtonRoute: (backRoute: string) => void,
  backRouteProps: any,
  updateBackButtonRouteProps: (backRouteProps: any) => void,
  accountMgmntInputProps: any,
  setAccountMgmntInputProps: (accountMgmntInputProps: any) => void,
  unitMgmntInputProps: any,
  setUnitMgmntInputProps: (unitMgmntInputProps: any) => void,
  showSSOExitButton: boolean,
  setShowSSOExitButton: (showSSOExitButton: boolean) => void,
  showLastPassDate: boolean,
  setShowLastPassDate: (showLastPassDate: boolean) => void,
  showScrollArrow: boolean,
  setShowScrollArrow: (showScrollArrow: boolean) => void,
  checkScrollReminder: () => void,
  displayName: string,
  setDisplayName: (displayName: string) => void,
  infopathQuestionCount: number,
  setInfopathQuestionCount: (infopathQuestionCount: number) => void,
  sideMenuOpen: boolean,
  setSideMenuOpen: (sideMenuOpen: boolean) => void,
  modulePlayerLayoutSideMenuOpen: boolean,
  setModulePlayerLayoutSideMenuOpen: (modulePlayerLayoutSideMenuOpen: boolean) => void,
  quizStatusDate:string,
  setquizStatusDate: (quizStatusDate: string) => void,
  expandedMenuSelection: string,
  setExpandedMenuSelection: (expandedMenuSelection: string) => void  
  showReportDueDate: boolean,
  setshowReportDueDate: (showReportDueDate: boolean) => void,
  EnablePasswordRule: boolean,
  setEnablePasswordRule: (EnablePasswordRule: boolean) => void,
  IsPasswordRuleCapital: boolean,
  setIsPasswordRuleCapital: (IsPasswordRuleCapital: boolean) => void,
  IsPasswordRuleNumber: boolean,
  setIsPasswordRuleNumbere: (IsPasswordRuleNumber: boolean) => void,
  IsPasswordRuleSpecialChar: boolean,
  setIsPasswordRuleSpecialChar: (IsPasswordRuleSpecialChar: boolean) => void,
  IsAccSettingsChangedAndNotSaved: boolean,
  setIsAccSettingsChangedAndNotSaved: (IsAccSettingsChangedAndNotSaved: boolean) => void,
  IsUserModuleAccessChangedAndNotSaved: boolean,
  setIsUserModuleAccessChangedAndNotSaved: (IsAccSettingsChangedAndNotSaved: boolean) => void,
  IsReportSchedulerChangedAndNotSaved: boolean,
  setIsReportSchedulerChangedAndNotSaved: (IsReportSchedulerChangedAndNotSaved: boolean) => void,
  IsLoggingOut: boolean,
  setIsLoggingOut: (IsAccSettingsChangedAndNotSaved: boolean) => void,
  IsCPDEnabled: boolean,
  setIsCPDEnabled: (IsCPDEnabled: boolean) => void,
  IsPolicyEnabled: boolean,
  setIsPolicyEnabled: (IsIsPolicyEnabled: boolean) => void,
  IsExtCertEnabled: boolean,
  setIsExtCertEnabled: (IsExtCertEnabled: boolean) => void,
  EnableActivityLogs: boolean,
  setEnableActivityLogs: (EnableActivityLogs: boolean) => void,
  IsEbookEnabled: boolean,
  setIsEbookEnabled: (IsEbookEnabled: boolean) => void,
  timeZoneId: number,
  setTimeZoneId: (timeZoneId: number) => void,
  showTermsAndConditionsModel: boolean;
  setshowTermsAndConditionsModel: (showTermsAndConditionsModel: boolean) => void;
  disablePasswordField: boolean,
  setDisablePasswordField: (disablePasswordField: boolean) => void,
  passwordLockout: boolean,
  setPasswordLockout: (passwordLockout: boolean) => void,
  showWelcomePopup: boolean;
  setShowWelcomePopup: (showWelcomeModal: boolean) => void,
  IsSSOEnabled: boolean,
  setIsSSOEnabled: (IsCPDEnabled: boolean) => void;
  emailSettingsRuleInputProps: any,
  setEmailSettingsRuleInputProps: (emailSettingsRuleInputProps: any) => void,
  IsLinkEnabled: boolean,
  setIsLinkEnabled: (IsIsLinkEnabled: boolean) => void,
  latestMyTrainingResultForProgress: any,
  setLatestMyTrainingResultForProgress:  (latestMyTrainingResultForProgress: any) => void,
  latestUserCPDs: any,
  setLatestUserCPDs:  (latestUserCPDs: any) => void,
  isCPDAssignPointsGuidemeClicked: boolean,
  setIsCPDAssignPointsGuidemeClicked: (isCPDAssignPointsGuidemeClicked: boolean) => void,
  // Super admin related
  superAdminSelectedBasicOrgData: OrgBasicData | null;
  setSuperAdminSelectedBasicOrgData: (orgBasicData: OrgBasicData | null) => void,
}

export const UIContext = createContext<IUIContext>({ 
    menuSelection: "My Dashboard",
    setMenuSelection: () => {},
    myTrainingSelection: "all",
    setMyTrainingSelection: () => {},
    showCPD: true,
    setShowCPD: () => {},
    showWelcomeModal: false,
    setShowWelcomeModal: () => {},
    title: "My Dashboard",
    setTitle: () => {},
    titleEnglish: "My Dashboard",
    setTitleEnglish: () => {},
    subComponentName: "",
    setSubComponentName: () => {},
    pageHelpVideoURL: "",
    setPageHelpVideoURL: () => {},
    allTipVideos: null,
    setAllTipVideos: () => {},
    tipVideosReloadRequired: false,
    setTipVideosReloadRequired: () => {},
    showPolicyUI: true,
    setShowPolicyUI: () => {},
    showCourseUI: true,
    setShowCourseUI: () => {},
    showQuizUI: true,
    setShowQuizUI: () => {},
    showMyCertificateUI: false,
    setShowMyCertificateUI: () => {},    
    showSuperAdminUI: false,
    showUnitAdminUI: false,
    showOrgAdminUI: false,
    setAdminLevel: () => {},
    showBackButton: false,
    setShowBackButton: () => {},
    backRoute: "",
    updateBackButtonRoute: () => {},
    backRouteProps: "",
    updateBackButtonRouteProps: () => {},
    accountMgmntInputProps: "",
    setAccountMgmntInputProps: () => {},
    unitMgmntInputProps: "",
    setUnitMgmntInputProps: () => {},
    showSSOExitButton: false,
    setShowSSOExitButton: () => {},
    showLastPassDate: false,
    setShowLastPassDate: () => {},
    showScrollArrow: false,
    setShowScrollArrow: () => {},
    checkScrollReminder: () => {},
    displayName: "",
    setDisplayName: () => {},
    infopathQuestionCount: 0,
    setInfopathQuestionCount: () => {},
    sideMenuOpen: true,
    setSideMenuOpen: () => {},
    modulePlayerLayoutSideMenuOpen: true,
    setModulePlayerLayoutSideMenuOpen: () => {},
    quizStatusDate:"",
    setquizStatusDate: () => {},
    expandedMenuSelection: "",
    setExpandedMenuSelection: () => {},
    showReportDueDate: false,
    setshowReportDueDate: () => {},

    EnablePasswordRule:false,
    setEnablePasswordRule: () => {},

    IsPasswordRuleCapital: false,
    setIsPasswordRuleCapital:  () => {},
    IsPasswordRuleNumber: false,
    setIsPasswordRuleNumbere: () => {},
    IsPasswordRuleSpecialChar: false,
    setIsPasswordRuleSpecialChar:  () => {},
    IsAccSettingsChangedAndNotSaved: false,
    setIsAccSettingsChangedAndNotSaved:  () => {},
    IsUserModuleAccessChangedAndNotSaved: false,
    setIsUserModuleAccessChangedAndNotSaved:  () => {},
    IsReportSchedulerChangedAndNotSaved: false,
    setIsReportSchedulerChangedAndNotSaved:  () => {},
    IsLoggingOut: false,
    setIsLoggingOut:  () => {},
    IsCPDEnabled: false,
    setIsCPDEnabled:  () => {},
    IsPolicyEnabled: false,
    setIsPolicyEnabled:  () => {},
    IsExtCertEnabled: false,
    setIsExtCertEnabled:  () => {},
    EnableActivityLogs:false,
    setEnableActivityLogs: () => {},
    IsEbookEnabled: false,
    setIsEbookEnabled:  () => {},
    timeZoneId: 0,
    setTimeZoneId: () => {},
    showTermsAndConditionsModel: false,
    setshowTermsAndConditionsModel: () => {},
    disablePasswordField: false,
    setDisablePasswordField: () => {},
    passwordLockout: false,
    setPasswordLockout: () => {},
    showWelcomePopup: false,
    setShowWelcomePopup: () => {},
    IsSSOEnabled: false,
    setIsSSOEnabled:  () => {},
    emailSettingsRuleInputProps: "",
    setEmailSettingsRuleInputProps: () => {},
    IsLinkEnabled: false,
    setIsLinkEnabled:  () => {},
    latestMyTrainingResultForProgress: null,
    setLatestMyTrainingResultForProgress:  () => {},
    latestUserCPDs: null,
    setLatestUserCPDs:  () => {},
    isCPDAssignPointsGuidemeClicked: false,
    setIsCPDAssignPointsGuidemeClicked:  () => {},
    // Super admin related
    superAdminSelectedBasicOrgData: null,
    setSuperAdminSelectedBasicOrgData: () => {},
})

const updateInitialSelection = (): string => { //Ensures that the menu selection works on site reload
  const pathname = window.location.pathname
  if (pathname.includes("index")){
    return "My Dashboard"
  } else if (pathname.includes("mytraining")) {
    return "My Training"
  } else if (pathname.includes("theme")) {
    return "Theme"
  } else if (pathname.includes("myreport")) {
    return "My Report"
  } else {
    return ""
  }
}

export const UIProvider = (props: any): JSX.Element => {
  const [menuSelection, setMenuSelection] = useState<string>(updateInitialSelection());
  const [myTrainingSelection, setMyTrainingSelection] = useState<string>("All");
  const [showCPD, setShowCPD] = useState<boolean>(false)
  const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>(true)
  const [showTermsAndConditionsModel, setshowTermsAndConditionsModel] = useState<boolean>(true)
  const [showPolicyUI, setShowPolicyUI] = useState<boolean>(true)
  const [tipVideosReloadRequired, setTipVideosReloadRequired] = useState<boolean>(false)
  const [showCourseUI, setShowCourseUI] = useState<boolean>(true)
  const [showQuizUI, setShowQuizUI] = useState<boolean>(true)
  const [showMyCertificateUI, setShowMyCertificateUI] = useState<boolean>(false)
  
  const [showSuperAdminUI, setSuperShowAdminUI] = useState<boolean>(false)
  const [showUnitAdminUI, setShowUnitAdminUI] = useState<boolean>(false)
  const [showOrgAdminUI, setShowOrgAdminUI] = useState<boolean>(false)
  const [title, setTitle] = useState<string>("");
  const [titleEnglish, setTitleEnglish] = useState<string>("");
  const [subComponentName, setSubComponentName] = useState<string>("");
  const [pageHelpVideoURL, setPageHelpVideoURL] = useState<string>("");
  const [allTipVideos, setAllTipVideos] = useState<any>(null);
  const [showBackButton, setShowBackButton] = useState<boolean>(false)
  const [backRoute, setBackRoute] = useState<string>("")
  const [backRouteProps, updateBackButtonRouteProps] = useState<any>(null)
  const [accountMgmntInputProps, setAccountMgmntInputProps] = useState<any>(null)
  const [unitMgmntInputProps, setUnitMgmntInputProps] = useState<any>(null)
  const [emailSettingsRuleInputProps, setEmailSettingsRuleInputProps] = useState<any>(null)
  const [showSSOExitButton, setShowSSOExitButton] = useState<boolean>(false)
  const [showLastPassDate, setShowLastPassDate] = useState<boolean>(false)
  const [showScrollArrow, setShowScrollArrow] = useState<boolean>(false)
  const [displayName, setDisplayName] = useState<string>("");
  const [infopathQuestionCount, setInfopathQuestionCount] = useState<number>(0)
  const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(true)
  const [modulePlayerLayoutSideMenuOpen, setModulePlayerLayoutSideMenuOpen] = useState<boolean>(false)
  const [quizStatusDate, setquizStatusDate] = useState<string>("");
  const [expandedMenuSelection, setExpandedMenuSelection] = useState<string>("")
  const [showReportDueDate, setshowReportDueDate] = useState<boolean>(false)
  const [EnablePasswordRule, setEnablePasswordRule] = useState<boolean>(false)
  const [IsPasswordRuleCapital, setIsPasswordRuleCapital] = useState<boolean>(false)
  const [IsPasswordRuleNumber, setIsPasswordRuleNumbere] = useState<boolean>(false)
  const [IsPasswordRuleSpecialChar, setIsPasswordRuleSpecialChar] = useState<boolean>(false)
  const [IsAccSettingsChangedAndNotSaved, setIsAccSettingsChangedAndNotSaved] = useState<boolean>(false)
  const [IsUserModuleAccessChangedAndNotSaved, setIsUserModuleAccessChangedAndNotSaved] = useState<boolean>(false)
  const [IsReportSchedulerChangedAndNotSaved, setIsReportSchedulerChangedAndNotSaved] = useState<boolean>(false)
  const [IsLoggingOut, setIsLoggingOut] = useState<boolean>(false)
  const [IsCPDEnabled, setIsCPDEnabled] = useState<boolean>(false)
  const [IsPolicyEnabled, setIsPolicyEnabled] = useState<boolean>(false);
  const [IsExtCertEnabled, setIsExtCertEnabled] = useState<boolean>(false);
  const [EnableActivityLogs, setEnableActivityLogs] = useState<boolean>(false);
  const [IsEbookEnabled, setIsEbookEnabled] = useState<boolean>(false);
  const [timeZoneId, setTimeZoneId] = useState<number>(0);
  const [disablePasswordField, setDisablePasswordField] = useState<boolean>(false)
  const [passwordLockout, setPasswordLockout] = useState<boolean>(false);
  const [showWelcomePopup, setShowWelcomePopup] = useState<boolean>(true);
  const [IsSSOEnabled, setIsSSOEnabled] = useState<boolean>(false);
  const [IsLinkEnabled, setIsLinkEnabled] = useState<boolean>(false);
  const [latestMyTrainingResultForProgress, setLatestMyTrainingResultForProgress] = useState<any>(null);
  const [latestUserCPDs, setLatestUserCPDs] = useState<any>(null);
  const [isCPDAssignPointsGuidemeClicked, setIsCPDAssignPointsGuidemeClicked] = useState<boolean>(false);
  // Super admin related
  const [superAdminSelectedBasicOrgData, setSuperAdminSelectedBasicOrgData] = useState<OrgBasicData | null>(null);

  const updateBackButtonRoute = (goBackRoute: string) => {
    if (goBackRoute === "") {
      setShowBackButton(false)
    } else {
      setBackRoute(goBackRoute)
      setShowBackButton(true)
    }
  }

  const setAdminLevel = (adminLevel: number) => {
    switch (adminLevel) {
      case 1:
        setSuperShowAdminUI(true)
        break;
      case 2:
        setShowOrgAdminUI(true)
        break;
      case 3:
        setShowUnitAdminUI(true)
        break;
      default:
        break
    }
  }

  const checkScrollReminder = () => {
      setTimeout(function() {  
        if (window.scrollY === 0 && window.innerHeight < document.body.scrollHeight) {
          setShowScrollArrow(true)
        }
      }, 4000);
  }

  // useEffect(() => {
  //   let k = 1 + 3;
  //   console.log('showCPD ' + showCPD)
  // }, [showCPD])

  return (
    <UIContext.Provider
      value={{
        menuSelection,
        setMenuSelection,
        myTrainingSelection,
        setMyTrainingSelection,
        showCPD,
        setShowCPD,
        showWelcomeModal, 
        setShowWelcomeModal,
        title,
        setTitle,
        titleEnglish,
        setTitleEnglish,
        subComponentName,
        setSubComponentName,
        pageHelpVideoURL,
        setPageHelpVideoURL,
        allTipVideos,
        setAllTipVideos,
        tipVideosReloadRequired,
        setTipVideosReloadRequired,
        showPolicyUI,
        setShowPolicyUI,
        showCourseUI, 
        setShowCourseUI,
        showQuizUI, 
        setShowQuizUI,
        showMyCertificateUI,
        setShowMyCertificateUI,
        showSuperAdminUI, 
        showUnitAdminUI, 
        showOrgAdminUI, 
        setAdminLevel,
        showBackButton,
        setShowBackButton,
        backRoute,
        updateBackButtonRoute,
        backRouteProps,
        updateBackButtonRouteProps,
        accountMgmntInputProps,
        setAccountMgmntInputProps,
        unitMgmntInputProps,
        setUnitMgmntInputProps,
        showSSOExitButton, 
        setShowSSOExitButton,
        showLastPassDate,
        setShowLastPassDate,
        showScrollArrow, 
        setShowScrollArrow,
        checkScrollReminder,
        displayName, 
        setDisplayName,
        infopathQuestionCount, 
        setInfopathQuestionCount,
        sideMenuOpen, 
        setSideMenuOpen,
        modulePlayerLayoutSideMenuOpen, 
        setModulePlayerLayoutSideMenuOpen,
        quizStatusDate,
        setquizStatusDate,
        expandedMenuSelection,
        setExpandedMenuSelection,
        showReportDueDate,
        setshowReportDueDate,
        EnablePasswordRule,
        setEnablePasswordRule,
        IsPasswordRuleCapital,
        setIsPasswordRuleCapital,
        IsPasswordRuleNumber,
        setIsPasswordRuleNumbere,
        IsPasswordRuleSpecialChar,
        setIsPasswordRuleSpecialChar,
        IsAccSettingsChangedAndNotSaved,
        setIsAccSettingsChangedAndNotSaved,
        IsUserModuleAccessChangedAndNotSaved,
        setIsUserModuleAccessChangedAndNotSaved,
        IsReportSchedulerChangedAndNotSaved,
        setIsReportSchedulerChangedAndNotSaved,
        IsLoggingOut,
        setIsLoggingOut,
        IsCPDEnabled,
        setIsCPDEnabled,
        IsPolicyEnabled,
        setIsPolicyEnabled,
        IsExtCertEnabled,
        setIsExtCertEnabled,
        EnableActivityLogs,
        setEnableActivityLogs,
        IsEbookEnabled,
        setIsEbookEnabled,
        timeZoneId,
        setTimeZoneId,
        showTermsAndConditionsModel, 
        setshowTermsAndConditionsModel,
        disablePasswordField,
        setDisablePasswordField,
        passwordLockout,
        setPasswordLockout,
        showWelcomePopup,
        setShowWelcomePopup,
        IsSSOEnabled,
        setIsSSOEnabled,
        emailSettingsRuleInputProps,
        setEmailSettingsRuleInputProps,
        IsLinkEnabled,
        setIsLinkEnabled,
        latestMyTrainingResultForProgress,
        setLatestMyTrainingResultForProgress,
        latestUserCPDs,
        setLatestUserCPDs,
        isCPDAssignPointsGuidemeClicked,
        setIsCPDAssignPointsGuidemeClicked,
        // Super admin related
        superAdminSelectedBasicOrgData, 
        setSuperAdminSelectedBasicOrgData
      }}
    >
      {props.children}
    </UIContext.Provider>
  );
};
