import React from 'react'
import SideMenuDivider from './SideMenuDivider'

interface Props {
    showReportMenuItems: boolean
    children: React.ReactNode
}

const SideMenuReports = ({ showReportMenuItems, children }: Props) => {
    if (!showReportMenuItems) return null
    return (
        <>
            <SideMenuDivider sectionName="Reports"/>
            { children }
        </>
    )
}

export default SideMenuReports
