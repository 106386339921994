import * as React from "react"

export function SGFlag() {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 28.3 28.3"
      xmlSpace="preserve"
    >
      <style>{".prefix__st1{fill:#fff}"}</style>
      <path
        d="M1.6 0C.7 0 0 .7 0 1.6v25c0 .9.7 1.6 1.6 1.6h25c.9 0 1.6-.7 1.6-1.6v-25c0-.9-.7-1.6-1.6-1.6h-25z"
        fill="red"
      />
      <path
        className="prefix__st1"
        d="M12.1 5.7l-.7-.7-.7.7.1-1-1-.1.9-.5-.5-.9 1 .4.3-1 .3 1 1-.3-.6.9.8.4-1 .1.1 1zM15.9 9l-.7-.7-.8.7.1-1-1-.1.9-.5-.5-.9 1 .4.3-1 .3 1 1-.3-.6.9.9.5-1 .1.1.9zM8.4 8.9l-.7-.8-.7.7.1-1-1-.1.9-.5-.6-.9 1 .4.3-1 .3 1 1-.3-.6.8.9.5-1 .1.1 1.1zM10.4 12.9l-.7-.7-.7.6.1-1-1-.1.9-.5-.5-.9 1 .4.3-1 .3 1 1-.3-.6.9.9.5-1 .1v1zM14.4 12.9l-.7-.7-.7.6.1-1-1-.1.9-.5-.5-.9 1 .4.3-1 .3 1 1-.3-.6.9.9.5-1 .1v1z"
      />
      <path
        d="M8.7 2.1C6 2.6 3.9 4.9 3.9 7.8c0 2.9 2.1 5.2 4.8 5.7-.3.1-.7.1-1 .1-3.2 0-5.8-2.6-5.8-5.8C1.9 4.6 4.5 2 7.7 2c.4 0 .7 0 1 .1z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
      />
    </svg>
  )
}

export default SGFlag
