import React, { useState, useRef, useContext, useEffect } from "react";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { UIContext } from "../../../contexts/UIContext";
import styles from "../../../styles/organisationmanagement.module.css";
import responsivestyles from "../../../styles/responsiveGridLayout.module.css";
import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, InputLabel, Radio, RadioGroup, Select, TextField } from "@material-ui/core";

import CloseWhiteIcon from "@material-ui/icons/Close";
import Modal from '../../../Components/Modal';
import CustomTooltip from "../../../Components/CustomTooltip";
import SaveIcon from "../../../CustomIcons/SaveIcon";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";
import { Steps } from "intro.js-react";
import GuideIcon from "../../../CustomIcons/GuideIcon";
import ProceedIcon from "../../../CustomIcons/ProceedIcon";
import GetOrgComplianceDetails from "../../../API/Administration/Organisation/GetOrgComplianceDetails";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import SaveOrgComplianceDetails from "../../../API/Administration/Organisation/SaveOrgComplianceDetails";
import CustomisedDatePicker from "../../../Components/CustomisedDatePicker";
import ErrorIcon from "../../../CustomIcons/Administration/errorIcon";
import LoaderContainer from "../../../Components/LoaderContainer";

const GlobalComplianceRules = () => {
	const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext);
	const { setTitle, setTitleEnglish, updateBackButtonRoute, IsEbookEnabled, EnableActivityLogs, timeZoneId } = useContext(UIContext);
	const { oidcUser } = useReactOidc();
	const {
		profile: { user_id, website,  },
	} = oidcUser || {};
	const userId = user_id ? parseInt(user_id) : 0;
	const orgId = oidcUser.profile.organisation_id ?? -1;
	const orgDomain = website
		? website
			.toLowerCase()
			.replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
		: "";
	const inputEl = useRef<HTMLInputElement>(null);
	const [strdefaultquizpassmark, setStrDefaultquizpassmark] = useState<string>('');
	const [defaultquizpassmark, setDefaultquizpassmark] = useState<number>();
	const [strnoofquiztoask, setStrNoofquiztoask] = useState<string>('');
	const [noofquiztoask, setNoofquiztoask] = useState<number>();
	const [expireSetting, setExpireSetting] = useState<number>(0);
	const [expireLessonafter, setExpireLessonafter] = useState<number>(0);
	const [expireQuizafter, setExpireQuizafter] = useState<number>(0);
	const [expireLessondate, setExpireLessondate] = useState("");
	const [expireQuizdate, setExpireQuizdate] = useState("");
	const [yearlyIncreamentLesson, setYearlyIncreamentLesson] = useState<number>(1);
	const [yearlyIncreamentQuiz, setYearlyIncreamentQuiz] = useState<number>(1);
	const [applyLessonOverriding, setApplyLessonOverriding] = useState<boolean>(false);
	const [applyQuizOverriding, setApplyQuizOverriding] = useState<boolean>(false);
	const [showConfirmModal, setshowConfirmModal] = useState<boolean>(false);
	

	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [saveSuccess, setSaveSuccess] = React.useState(false);
	const [modalPopupMessage, setModalPopupMessage] = useState('');
	const [hasErrored, setHasErrored] = useState<boolean>(false);

	const [intro, setIntro] = useState({
		stepsEnabled: false,
		initialStep: 0,
		steps: [
			{
				element: ".defaultquizpassmark",
				title: allResourcesToTranslations.defaultquizpassmark,
				intro: allResourcesToTranslations.defaultquizpassmarkguideme,
			},
			{
				element: ".noofquiztoask",
				title: allResourcesToTranslations.noofquiztoask,
				intro: allResourcesToTranslations.noofquiztoaskguideme,
			},
			{
				element: ".expirelesson",
				title: allResourcesToTranslations.expirelesson,
				intro: allResourcesToTranslations.expirelessonguideme,
			},
			{
				element: ".expirequiz",
				title: allResourcesToTranslations.expirequiz,
				intro: allResourcesToTranslations.expirequizguideme,
			},
			{
				element: ".expireLessondate",
				title: allResourcesToTranslations.expirelessondate,
				intro: allResourcesToTranslations.expirelessondateguideme,
			},
			{
				element: ".lessondateyearlyincrement",
				title: allResourcesToTranslations.yearlyincrement,
				intro: allResourcesToTranslations.lessonyearincrement,
			},
			{
				element: ".expireQuizdate",
				title: allResourcesToTranslations.expirequizdate,
				intro: allResourcesToTranslations.expirequizdateguideme,
			},
			{
				element: ".quizdateyearlyincrement",
				title: allResourcesToTranslations.yearlyincrement,
				intro: allResourcesToTranslations.quizyearincrement,
			},
			{
				element: ".applyLessonOverriding",
				title: "Apply to unit tree",
				intro: allResourcesToTranslations.applylessonguideme,
			},
			{
				element: ".applyQuizOverriding",
				title: "Apply to unit tree",
				intro: allResourcesToTranslations.applyquizguideme,
			},
			{
				element: ".enableonetime",
			 	title: allResourcesToTranslations.enableonetime,
			 	intro: allResourcesToTranslations.onetimecompletionguideme,
			},
			{
				element: ".saveData",
				title: "Save",
				intro: allResourcesToTranslations.saveglobalcompliancerulesguideme
			}
		],
	});

	const onExit = () => {
		setIntro({ ...intro, stepsEnabled: false });
	};

	useEffect(() => {
		setTitle(allResourcesToTranslations.compliancerules);
		setTitleEnglish(pageTitles.globalcompliancerules);
		updateBackButtonRoute("");
		setLoading(true);
		GetOrgComplianceDetails(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((result) => {
			if (result.isSuccess && result.error == '') {
				if (result.orgComplianceDetails !== null) {
					setDefaultquizpassmark(result.orgComplianceDetails.defaultQuizMark);
					setNoofquiztoask(result.orgComplianceDetails.numberOfQuizQuestions);
					setStrDefaultquizpassmark(result.orgComplianceDetails.defaultQuizMark);
					setStrNoofquiztoask(result.orgComplianceDetails.numberOfQuizQuestions);
					setExpireLessonafter(result.orgComplianceDetails.defaultLessonFrequency);
					setExpireQuizafter(result.orgComplianceDetails.defaultQuizFrequency);
					setYearlyIncreamentLesson(result.orgComplianceDetails.yearlyIncreamentLesson);
					setYearlyIncreamentQuiz(result.orgComplianceDetails.yearlyIncreamentQuiz);

					setExpireLessondate(result.orgComplianceDetails.defaultLessonCompletionDate !== null ? formatDate(new Date(result.orgComplianceDetails.defaultLessonCompletionDate)) : "");
					setExpireQuizdate(result.orgComplianceDetails.defaultQuizCompletionDate !== null ? formatDate(new Date(result.orgComplianceDetails.defaultQuizCompletionDate)) : "");
					//setExpireQuizdate(result.orgComplianceDetails.defaultQuizCompletionDate);

					if (result.orgComplianceDetails.enableOneTimeCourseComplition){
						setExpireSetting(2);
					}
					else {
						if (result.orgComplianceDetails.defaultQuizFrequency !== 0 && result.orgComplianceDetails.defaultLessonFrequency !== 0 && result.orgComplianceDetails.defaultLessonCompletionDate === null && result.orgComplianceDetails.defaultQuizCompletionDate === null){
							setExpireSetting(0);
						}
						else if(result.orgComplianceDetails.defaultQuizFrequency === 0 && result.orgComplianceDetails.defaultLessonFrequency === 0 && result.orgComplianceDetails.defaultLessonCompletionDate !== null && result.orgComplianceDetails.defaultQuizCompletionDate !== null) {
							setExpireSetting(1);
						}
					}
				}
			}
			else {
				setShowModal(true);
				setHasErrored(true);
				setSaveSuccess(false);
				setModalPopupMessage("Can not load Compliance Rules details!");
			}
			setLoading(false);
		});
		// at the end, return a cleanup method
		return () => { };
	}, []);

	const handleExpirySettings = (event: React.ChangeEvent<HTMLInputElement>) => {
		const val = (event.target as HTMLInputElement).value;
		setExpireSetting(parseInt(val));
	};

	const handleClosePopup = () => {
		setShowModal(false);
	};

	const handleSave = () => {
		setIsSaving(true);
		handleConfirmModal(true);
	}
	const handleConfirmModal = (value: boolean) => {
		setshowConfirmModal(value);
	}

	const handleProceed = () => {
		handleConfirmModal(false);
		let varexpirelessonafter = expireLessonafter;
		let varexpirequizafter = expireQuizafter;
		let varexpirelessondate = expireLessondate;
		let varexpirequizdate = expireQuizdate;
		if (expireSetting == 0) {
			if (expireLessondate.trim() !== "" || expireQuizdate.trim() !== ""){
				setExpireLessondate("");
				setExpireQuizdate("");
				varexpirelessondate = "";
				varexpirequizdate = "";
			}
		}
		else if (expireSetting == 1) {
			if (expireLessonafter > 0 || expireQuizafter> 0) {
				setExpireLessonafter(0);
				setExpireQuizafter(0);
				varexpirelessonafter = 0;
				varexpirequizafter = 0;
			}
		}
		else {
			setExpireLessondate("");
			setExpireQuizdate("");
			varexpirelessondate = "";
			varexpirequizdate = "";
			setExpireLessonafter(0);
			setExpireQuizafter(0);
			varexpirelessonafter = 0;
			varexpirequizafter = 0;
		}
		SaveOrgComplianceDetails(oidcUser.access_token, userId, parseInt(orgId), orgDomain, strdefaultquizpassmark, noofquiztoask !== undefined && noofquiztoask > 0 ? noofquiztoask.toString() : "", varexpirelessonafter, varexpirequizafter, varexpirelessondate, varexpirequizdate, expireSetting === 2, applyLessonOverriding, applyQuizOverriding, yearlyIncreamentLesson, yearlyIncreamentQuiz).then((result) => {
			if (!!result && result.isSuccess && result.error == '') {
				setShowModal(true);
				setHasErrored(false);
				setSaveSuccess(true);
				setModalPopupMessage("Global Compliance Rules saved successfully!");
			}
			else {
				setShowModal(true);
				setHasErrored(true);
				setSaveSuccess(false);
				setModalPopupMessage("Can not save Global Compliance Rules! Please try again." + result.error !== "" ? ";" +  result.error : "");
			}
			setLoading(false);
			setIsSaving(false);
		});
	}

	const handleCancelFromConfirmPopup = () => {
		handleConfirmModal(false);
		setIsSaving(false);
	}

	const handleDefaultQuizPassMark = (e: any) => {
		if (isNaN(Number(e.target.value))){
			return false;
		} else {
			if (Number(e.target.value) > 100){
				return false;
			}
			if (e.target.value === ""){
				setStrDefaultquizpassmark("");
				setDefaultquizpassmark(0);
				return;
			}	
			setStrDefaultquizpassmark(e.target.value);
			setDefaultquizpassmark(Number(e.target.value));
		}
	}

	const handlestrNoofquiztoask = (e: any) => {
		if (isNaN(Number(e.target.value))){
			return false;
		} else {
			if (Number(e.target.value) > 100){
				return false;
			}
			if (e.target.value === ""){
				setStrNoofquiztoask("");
				setNoofquiztoask(0);
				return;
			}	
			setStrNoofquiztoask(e.target.value);
			setNoofquiztoask(Number(e.target.value));
		}
	}

	const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
	{
		if (!isNaN(givenDate.getTime())) {
			const year = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(givenDate);
			const month = new Intl.DateTimeFormat('en-AU', { month: 'numeric' }).format(givenDate);
			const day = new Intl.DateTimeFormat('en-AU', { day: 'numeric' }).format(givenDate);

			return (year.toString() + "-" +
				(parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" +
				(parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
		}
		else {
			return "";
		}
	}

	const handleLessonDateSelection = (selectedDate: string) => {
		setExpireLessondate(selectedDate);
	  }
	
	  const handleQuizDateSelection = (selectedDate: string) => {
		setExpireQuizdate(selectedDate);
	  }

	  const handleLessonYearlyIncementSelection = (selectedIncrementYear: number) => {
		setYearlyIncreamentLesson(selectedIncrementYear);
	  }
	
	  const handleQuizYearlyIncementSelection = (selectedIncrementYear: number) => {
		setYearlyIncreamentQuiz(selectedIncrementYear);
	  } 

	  useEffect(() => {
		setYearlyIncreamentLesson(yearlyIncreamentLesson);
	  },[yearlyIncreamentLesson]);

	  useEffect(() => {
		setYearlyIncreamentQuiz(yearlyIncreamentQuiz);
	  },[yearlyIncreamentQuiz]);

	useEffect(() => {
		if (showModal && !hasErrored) {
			setTimeout(() => {
				setShowModal(false);
				setSaveSuccess(false);
			}, 3000)
		}
	}, [showModal])
	if (loading) return <LoaderContainer />;
	return (
		<>
			<Steps
				enabled={intro.stepsEnabled}
				steps={intro.steps}
				initialStep={intro.initialStep}
				onExit={onExit}
				options={{
					nextLabel: "Next",
					prevLabel: "Back",
					tooltipClass: "sample-class",
					showBullets: true,
				}}
			/>
			<CustomTooltip displayText={[allResourcesToTranslations.guideme]}>
				<span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
					className={styles.guideMeButtonSpecific}>
					<GuideIcon />
				</span>
			</CustomTooltip>
			<Modal modalOpen={showConfirmModal} setModalOpen={handleConfirmModal} type="warning">
				<div className={styles.modalContentWrapper}>
					<h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>{allResourcesToTranslations.confirmation}</h2>
					<div className={styles.inputContainer}>
						<p>
							{allResourcesToTranslations.confirmmessage} <br></br><br></br>
							<b>{allResourcesToTranslations.proceedmessage} </b>
						</p>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							position: "relative",
							top: "10px",
						}}
					>
						<CustomTooltip displayText={[allResourcesToTranslations.return]}>
							<span className={styles.returnIcon} onClick={handleCancelFromConfirmPopup}>
								<CloseWhiteIcon />
							</span>
						</CustomTooltip>
						<CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
							<span className="saveData">
								<Button
									onClick={handleProceed}
									variant="contained"
									color="primary"
									className="save"
								>
									<ProceedIcon />
								</Button>
							</span>
						</CustomTooltip>
					</div>
				</div>
			</Modal>
			<div className={styles.Wrapper}>
				<div id="attentionInfo" className={styles.icon_and_text_container_leftshift}>
					<div className={styles.icon_and_text_container_icon}>
					<ErrorIcon></ErrorIcon>
					</div>
					<div className={styles.icon_and_text_container_text}>
					<div>
						<span style={{fontWeight: "normal", color: "red", fontSize:"12px", fontStyle: "normal", marginTop:"20px"}}>
							{allResourcesToTranslations.informationpanel}</span>
					</div>
					</div>
				</div>    
				<div className={styles.lableContainer} >
					<span className={styles.labelText}>{allResourcesToTranslations.quizsettings}</span>
				</div>
				<div className={styles.inputContainer}>
					<span className={styles.labelTextitalic}>{allResourcesToTranslations.quizsettingsdetail}</span>
				</div>
				<div className={responsivestyles.rowrule} style={{ width: '100%'}}>
					<div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
						<div className={`${styles.inputContainer} ${styles.monthbasedexpiryleft}` } >
							<div className="defaultquizpassmark" style={{ paddingLeft: '0px', paddingBottom: '20px' }} >
								<TextField
									className={styles.input}
									style={{ marginBottom: "15px" }}
									label={allResourcesToTranslations.defaultquizpassmark}
									name="defaultquizpassmark"
									inputProps={{ maxLength: 4 }}
									variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
									value={strdefaultquizpassmark}
									type="text"
									onChange={(e) => handleDefaultQuizPassMark(e)}
								/>
							</div>
						</div>
					</div>
					<div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
						<div className={`${styles.inputContainer} ${styles.monthbasedexpiryright}`}>
							<div className="noofquiztoask" style={{paddingLeft: '0px' }} >
							<TextField
								className={styles.input}
								style={{ marginBottom: "15px" }}
								label={allResourcesToTranslations.noofquiztoask}
								name="noofquiztoask"
								inputProps={{ maxLength: 4 }}
								variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
								value={strnoofquiztoask}
								type="text"
								onChange={(e) => handlestrNoofquiztoask(e)}
							/>
							</div>
						</div>
					</div>					
				</div>
				<div className={styles.lableContainer} >
					<span className={styles.labelText}>{allResourcesToTranslations.expirysettings}</span>
				</div>
				<div className={styles.inputContainer}>
					<span className={styles.labelTextitalic}>{allResourcesToTranslations.expirysettingsinfo}</span>
				</div>
				<div className={styles.inputContainer}>
					<div className={styles.radiodivpadging}>
						<RadioGroup
							name="expirysettings"
							value={expireSetting}
							onChange={handleExpirySettings}
							style={{ display: 'flex' }}
						>
							<FormControlLabel
								value={0}
								className={expireSetting !== 0 ? styles.disabledText : ""}
								control={<Radio color="primary" className={styles.radiopadging} />}
								label={allResourcesToTranslations.expireperiod}
							/>
							 <div className={responsivestyles.rowrule}>
							 	<div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
							  		<div className={`${styles.inputContainer} ${styles.datebasedexpiryleft}` } >
										<FormControl variant="outlined" className={styles.formControl}>
											<InputLabel>{allResourcesToTranslations.expirelesson}</InputLabel>
											<Select className={`${styles.input} expirelesson`}
												value={expireLessonafter}
												disabled={expireSetting !== 0}
												native
												onChange={(e: any) => setExpireLessonafter(e.target.value)}
												variant="outlined"
												label={allResourcesToTranslations.expirelesson}
												id="expirelesson">
												<option value={0}>Please select</option>
												<option value={1}>1 Month</option>
												<option value={2}>2 Months</option>
												<option value={3}>3 Months</option>
												<option value={4}>4 Months</option>
												<option value={5}>5 Months</option>
												<option value={6}>6 Months</option>
												<option value={7}>7 Months</option>
												<option value={8}>8 Months</option>
												<option value={9}>9 Months</option>
												<option value={10}>10 Months</option>
												<option value={11}>11 Months</option>
												<option value={12}>12 Months</option>
												<option value={13}>13 Months</option>
												<option value={14}>14 Months</option>
												<option value={15}>15 Months</option>
												<option value={16}>16 Months</option>
												<option value={17}>17 Months</option>
												<option value={18}>18 Months</option>
												<option value={19}>19 Months</option>
												<option value={20}>20 Months</option>
												<option value={21}>21 Months</option>
												<option value={22}>22 Months</option>
												<option value={23}>23 Months</option>
												<option value={24}>24 Months</option>
												<option value={25}>25 Months</option>
												<option value={26}>26 Months</option>
												<option value={27}>27 Months</option>
												<option value={28}>28 Months</option>
												<option value={29}>29 Months</option>
												<option value={30}>30 Months</option>
												<option value={31}>31 Months</option>
												<option value={32}>32 Months</option>
												<option value={33}>33 Months</option>
												<option value={34}>34 Months</option>
												<option value={35}>35 Months</option>
												<option value={36}>36 Months</option>
											</Select>
										</FormControl>
									</div>
								</div>
								
								<div  className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
                                 <div className={`${styles.inputContainer} ${styles.datebasedexpiryright}` }>
										<FormControl variant="outlined" className={styles.formControl}>
											<InputLabel>{allResourcesToTranslations.expirequiz}</InputLabel>
											<Select className={`${styles.input} expirequiz`}
												value={expireQuizafter}
												disabled={expireSetting !== 0}
												native
												onChange={(e: any) => setExpireQuizafter(e.target.value)}
												variant="outlined"
												label={allResourcesToTranslations.expirequiz}
												id="expirequiz">
												<option value={0}>Please select</option>
												<option value={1}>1 Month</option>
												<option value={2}>2 Months</option>
												<option value={3}>3 Months</option>
												<option value={4}>4 Months</option>
												<option value={5}>5 Months</option>
												<option value={6}>6 Months</option>
												<option value={7}>7 Months</option>
												<option value={8}>8 Months</option>
												<option value={9}>9 Months</option>
												<option value={10}>10 Months</option>
												<option value={11}>11 Months</option>
												<option value={12}>12 Months</option>
												<option value={13}>13 Months</option>
												<option value={14}>14 Months</option>
												<option value={15}>15 Months</option>
												<option value={16}>16 Months</option>
												<option value={17}>17 Months</option>
												<option value={18}>18 Months</option>
												<option value={19}>19 Months</option>
												<option value={20}>20 Months</option>
												<option value={21}>21 Months</option>
												<option value={22}>22 Months</option>
												<option value={23}>23 Months</option>
												<option value={24}>24 Months</option>
												<option value={25}>25 Months</option>
												<option value={26}>26 Months</option>
												<option value={27}>27 Months</option>
												<option value={28}>28 Months</option>
												<option value={29}>29 Months</option>
												<option value={30}>30 Months</option>
												<option value={31}>31 Months</option>
												<option value={32}>32 Months</option>
												<option value={33}>33 Months</option>
												<option value={34}>34 Months</option>
												<option value={35}>35 Months</option>
												<option value={36}>36 Months</option>
											</Select>
										</FormControl>
									</div>
								</div>
							</div>
							<FormControlLabel
								value={1}
								className={expireSetting !== 1 ? styles.disabledText : ""}
								control={<Radio color="primary" className={styles.radiopadging} />}
								label={allResourcesToTranslations.expiredate}
							/>							
							<div className={responsivestyles.rowrule}>
								<div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
								  <div className={`${styles.inputContainer} ${styles.datebasedexpiryleft}` } >
									<div className={styles.inputContainer}>
										<div>
										<CustomisedDatePicker isDisabled={expireSetting !== 1} inputDate={expireLessondate} lableText={allResourcesToTranslations.expirelessondate}
                          					handleDateSelection={handleLessonDateSelection} yearlyIncrementdatepickerclassName="expireLessondate" yearlyIncrementddlclassName="lessondateyearlyincrement" yearlyincrementValue={yearlyIncreamentLesson} handleYearSelection={handleLessonYearlyIncementSelection}></CustomisedDatePicker>
										</div>
									</div>
									<div className="applyLessonOverriding" style={{ width: '100%' }} >
										<Checkbox
											style={{ paddingLeft: '0px' }}
											size="small"
											color="primary"
											checked={applyLessonOverriding}
											className={styles.checkBox}
											onClick={() => setApplyLessonOverriding(!applyLessonOverriding)}
										/>{" "}
										<span className={styles.labelText}>{allResourcesToTranslations.applylesson}</span>
									</div>
									<div className={styles.notesContainer}>
										<span className={styles.labelTextSmallNormal} style={{ paddingLeft: '0px' }}>{allResourcesToTranslations.applylessontext}</span>
									</div>
									</div>
								</div>
								<div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>

									<div className={`${styles.inputContainer} ${styles.datebasedexpiryright}` }>
									<div className={styles.inputContainer}>
										<div>
										<CustomisedDatePicker isDisabled={expireSetting !== 1} inputDate={expireQuizdate} lableText={allResourcesToTranslations.expirequizdate}
                              				handleDateSelection={handleQuizDateSelection}  yearlyIncrementdatepickerclassName="expireQuizdate" yearlyIncrementddlclassName="quizdateyearlyincrement"  yearlyincrementValue={yearlyIncreamentQuiz} handleYearSelection={handleQuizYearlyIncementSelection}></CustomisedDatePicker>
										</div>
									</div>
									<div className="applyQuizOverriding" style={{ width: '100%'}} >
										<Checkbox
											style={{ paddingLeft: '0px' }}
											size="small"
											color="primary"
											checked={applyQuizOverriding}
											className={styles.checkBox}
											onClick={() => setApplyQuizOverriding(!applyQuizOverriding)}
										/>{" "}
										<span className={styles.labelText}>{allResourcesToTranslations.applyquiz}</span>
									</div>
									<div className={styles.notesContainer}>
										<span className={styles.labelTextSmallNormal} style={{ paddingLeft: '0px' }}>{allResourcesToTranslations.applylessontext}</span>
									</div>
									</div>
								</div>
							</div>	

							<FormControlLabel
								value={2}
								className={`${expireSetting !== 2 ? styles.disabledText : ""} enableonetime`}
								control={<Radio color="primary" className={styles.radiopadging} />}
								label={allResourcesToTranslations.enableonetime}
							/>						
						</RadioGroup>
					</div>
				</div>
				<div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", width: "100%", paddingTop: "10px" }}>
					<CustomTooltip displayText={[allResourcesToTranslations.saveglobalcompliancerulesguideme]}>
						<span className="saveData" style={{ marginRight: "-5px" }}>
							<Button
								disabled = {(strdefaultquizpassmark.trim() === ""|| strdefaultquizpassmark.trim() === "%") 
								|| (expireSetting == 0 && (expireLessonafter == 0 || expireQuizafter == 0 ))
								|| (expireSetting == 1 && (expireLessondate == "" || expireQuizdate == "" || yearlyIncreamentLesson == 0 || yearlyIncreamentQuiz == 0))}
								onClick={handleSave}
								variant="contained"
								color="primary"
								className="save"
							>
								<SaveIcon />
							</Button>
						</span>
					</CustomTooltip>
					<div className={styles.loaderContainer}>
						<CircularProgress
							style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
							color="primary"
							size="10"
						/>
					</div>
				</div>
				{showModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}

			</div>
		</>
	);
};

export default GlobalComplianceRules;
