import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const OrganisationAddAdminIcon = () => (    
<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.79248 9.09393C7.79248 11.4033 9.66489 13.2757 11.9743 13.2757C14.2837 13.2757 16.1561 11.4033 16.1561 9.09393C16.1561 6.78452 14.2837 4.91211 11.9743 4.91211C9.66489 4.91211 7.79248 6.78452 7.79248 9.09393ZM9.88339 9.09393C9.88339 10.2492 10.8191 11.1848 11.9743 11.1848C13.1295 11.1848 14.0652 10.2492 14.0652 9.09393C14.0652 7.9387 13.1295 7.00302 11.9743 7.00302C10.8191 7.00302 9.88339 7.9387 9.88339 9.09393Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M23.4741 12.2324C23.4741 5.88129 18.3253 0.732422 11.9741 0.732422C5.62298 0.732422 0.474121 5.88129 0.474121 12.2324C0.474121 18.5836 5.62298 23.7324 11.9741 23.7324C12.3613 23.7324 12.7439 23.7133 13.1212 23.6759C12.8254 23.0386 12.6227 22.3492 12.5305 21.6253C12.3464 21.6361 12.1609 21.6415 11.9741 21.6415C9.68667 21.6415 7.58949 20.825 5.95858 19.468C7.28735 17.6186 9.45667 16.4142 11.9072 16.4142C12.5554 16.4142 13.1839 16.4985 13.7823 16.6567C14.2394 16.0195 14.8009 15.4622 15.4417 15.0099C14.3504 14.5672 13.1573 14.3233 11.9072 14.3233C8.89526 14.3233 6.21471 15.7378 4.49284 17.9385C3.28325 16.3557 2.56503 14.3777 2.56503 12.2324C2.56503 7.03547 6.77717 2.82333 11.9741 2.82333C17.1711 2.82333 21.3832 7.03547 21.3832 12.2324C21.3832 12.8202 21.3293 13.3955 21.2261 13.9535C21.9285 14.1345 22.5882 14.4218 23.187 14.7972C23.3749 13.9725 23.4741 13.114 23.4741 12.2324Z" fill="white"/>
<circle cx="19.4741" cy="20.7324" r="5.5" stroke="white"/>
<rect x="18.9741" y="18.7324" width="1" height="4" fill="white"/>
<rect x="21.4741" y="20.2324" width="1" height="4" transform="rotate(90 21.4741 20.2324)" fill="white"/>
</svg>
);

export default OrganisationAddAdminIcon
