import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
require("highcharts/modules/exporting")(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

//Example : How to add line chart in page
//import LineChart from "../../Components/HighChart/StakedColumnChartComponent";
/* <StakedColumnChart categoriesArray={categoriesArray} seriesData={seriesData} ChartTitle={"License Report"} 
             yAxisTitle={"Total License"} themecolor={color}   /> */


export const StakedColumnChartComponent = ({ categoriesArray, seriesData, ChartTitle, yAxisTitle, themecolor }) => {

  Highcharts.setOptions({
    //colors: [themecolor, '#21394f', '#63c2ff'],
    colors: ['#20B786','#21394f'],
    font:['Open Sans sans-serif']  
  }); 

  const stakedColumnChart = {
    chart: {
      type: 'column'
    },
    title: {
      text: ChartTitle,
      align: 'left',
      fontWeight: 'bold',      
    },     
    credits: {
      //text: 'Example.com',
      //href: 'http://www.example.com',
      enabled: false
    },
    xAxis: {
      categories: categoriesArray
    },
    yAxis: {
     // min: 0, -- commented to allow nagative value in chart
      title: {
        text: yAxisTitle,
        fontWeight: 'bold'
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: ( // theme
            Highcharts.defaultOptions.title.style &&  Highcharts.defaultOptions.title.style.color) || 'gray',
          textOutline: 'none'
        }
      }
    },
    legend: {
      align: 'center',
      x:0,
      verticalAlign: 'top',
      y: 0,
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: true,
      font: Highcharts.defaultOptions.font
    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
    plotOptions: {
      series: {
            maxPointWidth: 70
      },
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true
        }
      }
    },
    series: seriesData
  };

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={stakedColumnChart} />
    </>
  )

}

export default StakedColumnChartComponent;
