//React + Typescript
import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom'
//Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { LoaderContext } from '../../contexts/LoaderContext';
import { SessionContext } from '../../contexts/SessionContext';
import { CPDPanelContext } from '../../contexts/CPDPanelContext';
import { MyTrainingListContext } from '../../contexts/MyTrainingListContext';
//API Functions
import { addError } from '../../API/AddError';
import { deleteDME } from '../../API/DeleteDME';
import { startQuiz } from '../../API/StartQuiz';
import { startLesson } from '../../API/StartLesson';
import { setScormValue } from '../../API/SetScormValue';
import { getEndQuizInfoEx } from '../../API/GetEndQuizInfoEx';
import { getUserSessionDme } from '../../API/UserSessions';
import { getQuizListByModule } from '../../API/GetQuizListByModule';
import { processScormRequest } from '../../API/ProcessScormRequest';
import { decideAndUpdateCourseCompletion } from '../../API/DecideAndUpdateCourseCompletion';
import { sendScormTrainingQAs } from '../../API/SendScormTrainingQAs';
import { getSpecificAppConfig } from '../../API/GetSpecificAppConfig';
import { getPreQuizStartResult } from '../../API/PreQuizStartResult';
import { getPreLessonStartResult } from '../../API/PreLessonStartResult';
import { getLessonListByModule } from '../../API/GetLessonListByModule';
import { updateSAPassLaunchPageDetails } from '../../API/UpdateSAPassLaunchPageDetails'
//Components
import SAExitButton from './SAExitButton';
import ModuleMultiSectionLayout from './ModuleMultiSectionLayout';
import LoaderContainer from '../../Components/LoaderContainer';
//Styles
import styles from '../../styles/quizPlayerStyle.module.css';
//import useWindowCloseHook from '../../Components/useWindowsCloseHook';

export const LectoraQuizPlayer = ({ learningModuleData, isLesson }) => {

  const [isModuleSingleSection, setIsModuleSingleSection] = useState(true);
  const [givenQuizInfo, setGivenQuizInfo] = useState(!!learningModuleData.quizType ? learningModuleData.quizType : "");
  const history = useHistory()
  let { courseId } = useParams();
  const [endQuizInfo, setEndQuizInfo] = useState(null)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showExitButton, setShowExitButton] = useState(false)
  const [moduleData] = useState(learningModuleData) //This may no longer be required
  const [moduleSource, setModuleSource] = useState(""); 
  const [currentCompletedSection, setCurrentCompletedSection] = useState(""); 
  const [loading, setLoading] = useState(true);
  const { oidcUser } = useReactOidc();
  const orgId = oidcUser?.profile.profile ? parseInt(oidcUser.profile.profile) : -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0;
  const oidcUniqueID = ((oidcUser?.session_state === undefined || oidcUser?.session_state === null) ? "" : oidcUser?.session_state);
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  const { cpdList, currentCpd, setProcessedScormResult } = useContext(CPDPanelContext);
  const { saReviewOnlyValue, setSaReviewOnlyValue, saIsRestarted, setCurSessionID, 
          setCurBookmark, setCurQuizPassmark, setCurCustomCourseProgress, setCurBrowserTabUniqueID, 
          setCurSelectedModuleID, setIsCurSessionLesson, setShowResultsModal, 
          multiSectionCurrentSelection, setMultiSectionCurrentSelection,
          multiSectionSessionDetails, setMultiSectionSessionDetails, setMultiSectionCurrentSelectionOnQuizOpen } = useContext(SessionContext)
  //const { trainingListUpdateRequired, setTrainingListUpdateRequired } = useContext(MyTrainingListContext)
  const { setTrainingListUpdateRequired, setDashboardUpdateRequired } = useContext(MyTrainingListContext)

  const { stopLoadingTimeout } = useContext(LoaderContext)

  const scriptsUrls = (!!learningModuleData.quizType && learningModuleData.quizType === "SA" && !!learningModuleData.quizInfo &&
                        learningModuleData.quizInfo.toString().trim().split("<%$%>").length > 0 &&
                        learningModuleData.quizInfo.toString().trim().split("<%$%>").filter((w) => !!w && w.trim().startsWith("ScormSchemaVersion<:#:>")).length > 0 &&
                        learningModuleData.quizInfo.toString().trim().split("<%$%>").filter((w) => !!w && w.trim().startsWith("ScormSchemaVersion<:#:>"))[0].toString().replace("ScormSchemaVersion<:#:>", "").trim().toLowerCase().includes("scorm") &&
                        learningModuleData.quizInfo.toString().trim().split("<%$%>").filter((w) => !!w && w.trim().startsWith("ScormSchemaVersion<:#:>"))[0].toString().replace("ScormSchemaVersion<:#:>", "").trim().includes("2004")
                      ) ? 
  [
    "/js/setupAPIGlobals.js", //This contains window vars for scorm API and needs to be loaded first
    "/js/ie9-js.js",
    "/js/v.X.4/sscompat.js",
    "/js/v.X.4/sscorlib.js",
    "/js/v.X.4/ssfx.Core.js",
    "/js/v.X.4/API_BASE.js",
    "/js/v.X.4/API_1484_11.js",
    "/js/v.X.4/Controls.js",
    "/js/v.X.4/Player.js",
    "/js/v.X.4/APIWrapper.js",
    "/js/v.X.4/SCORM_API_wrapper.js",
    "/js/v.X.4/scofunctions.js",
  ] 
  :
  [
    "/js/setupAPIGlobals.js", //This contains window vars for scorm API and needs to be loaded first
    "/js/ie9-js.js",
    "/js/v.X.4/sscompat.js",
    "/js/v.X.4/sscorlib.js",
    "/js/v.X.4/ssfx.Core.js",
    "/js/v.X.4/API_BASE.js",
    "/js/v.X.4/API.js",
    "/js/v.X.4/Controls.js",
    "/js/v.X.4/Player.js",
    "/js/v.X.4/APIWrapper.js",
    "/js/v.X.4/SCORM_API_wrapper.js",
    "/js/v.X.4/scofunctions.js",
  ];
  const buildScript = (src) => {
    const script = document.createElement("script");
    script.setAttribute("class", "scormPlayerScript");
    script.type = "text/javascript";
    script.src = src;
    script.setAttribute("async", "");
    script.setAttribute("defer", "");
    script.addEventListener("load", () => {
      window.loadedScriptsCount++;
    });
    return script;
  };

  let injectScripts = (targetEl, scripts) => {
    return new Promise((resolve, reject) => {
      window.loadedScriptsCount = 0; //Need to store this in the window because state is too slow
        if (document.getElementsByClassName("scormPlayerScript").length > 0) {  //Stops the scripts getting re-added if already exisits
          resolve(true) 
        } else {
          let sIndex = 0;
          const startTime = performance.now();
          const timeOutDuration = 1000 * 20; 
          let currentTime = startTime;
    
          const injectScript = () => {
            if(currentTime > startTime + timeOutDuration) { //20 seccond timeout
              clearInterval(scriptLoadInterval)
              reject(Error(`Scripts took to long to load`))
            }
            if (sIndex >= scripts.length) {
              clearInterval(scriptLoadInterval)
              resolve(true) 
            } else if (sIndex === window.loadedScriptsCount || sIndex === 0) {
              const newScript = buildScript(scripts[sIndex]);
              targetEl.prepend(newScript);
              sIndex++;
            }
          }
          var scriptLoadInterval = setInterval(injectScript, 100)
        };
    })
  }

  const _json2xmlScoreActual = () => {
    var AdaptiveScore = 
      (window !== null && window !== undefined && window.dme !== null && window.dme !== undefined &&
      window.dme["cmi.core.score.raw"] !== null && window.dme["cmi.core.score.raw"] !== undefined && window.dme["cmi.core.score.raw"] !== NaN) 
        ? parseInt(window.dme["cmi.core.score.raw"]) : 0; 
    var xml = "<xml xmlns=\"NetFrameWork\">";
    var p = "";
    if (window !== null && window !== undefined) {
      if (window.dme !== null && window.dme !== undefined) {
        for (var key in window.dme) {
            if (key === null || key === undefined) {
              continue;
            }
            if (AdaptiveScore > 0) {
                if (key === "cmi.core.lesson_score") {
                    xml += "<dme><name>" + key + "</name><value>100</value></dme>"; 
                }
                else {
                  xml += "<dme><name>" + key + "</name><value>" + window.dme[key] + "</value></dme>";
                }
            }
            else {
              xml += "<dme><name>" + key + "</name><value>" + window.dme[key] + "</value></dme>";
            }
        }
      }
      if (window._pgs !== null && window._pgs !== undefined) {
        for (key in window._pgs) {
          let curVal = ((key === null || key === undefined || window._pgs[key] === null || window._pgs[key] === undefined) 
                          ? "" : window._pgs[key])
          if (p !== null && p !== undefined) {
              p += (";" + curVal); 
          }
          else {
              p = curVal;
          }
        }
      }
    }
    xml += "<dme><name>pgarr</name><value>" + p + "</value></dme>";
    xml += "</xml>";
    return xml;
  }

  const getLocalOrgDomain = () => {
    return (orgDomain === undefined ? null : orgDomain);
  }

  const getLocalLearningModuleData = () => {
    return learningModuleData;
  } 
  
  const sectionDetailsChangeHandler = (value) => {
    //console.log("sectionDetailsChangeHandler => value =>  " + value);
    if (!!value && value.trim() != "") {
      setMultiSectionSessionDetails(value);
    }
  }

  const fetchProcessScormRequestResolutionAfterDeciding = async () => {
      var learningModuleDataLocal = getLocalLearningModuleData();
      const res = await decideAndUpdateCourseCompletion(
                    ((window === null || window === undefined) ? null : window._getAPIBearerKey()), 
                    ((window === null || window === undefined || window._currentUserId === NaN) ? -1 : window._currentUserId), 
                    getLocalOrgDomain(), 
                    ((window === null || window === undefined) ? false : window._currentIsLesson), 
                    ((window === null || window === undefined) ? null : window._curSessionID), 
                    ((window === null || window === undefined || window._curProfileID === NaN) ? -1 : window._curProfileID), 
                    ((window === null || window === undefined || window._curProfileID === NaN) ? -1 : window._curProfileID), 
                    ((window !== null && window !== undefined && window.dme !== null && window.dme !== undefined &&
                      window.dme.ModuleID !== null && window.dme.ModuleID !== undefined && window.dme.ModuleID !== NaN) 
                            ? parseInt(window.dme.ModuleID.toString()): -1),
                    ((window !== null && window !== undefined && window.dme !== null && window.dme !== undefined &&
                      window.dme.Write !== null && window.dme.Write !== undefined && window.dme.Write !== NaN) 
                            ? (parseInt(window.dme.Write.toString()) === 1 ? true : false) : false), 
                    null, 
                    null, 
                    _json2xmlScoreActual(), 
                    'en-AU', 
                    !(window !== null && window !== undefined && 
                      window._currentToolBookLocation !== null && window._currentToolBookLocation !== undefined &&
                      (window._currentToolBookLocation.toLowerCase().includes("\\shared\\") || 
                            window._currentToolBookLocation.toLowerCase().includes("/shared/"))), 
                    ((window === null || window === undefined) ? null : window._oidcTabUniqueID), 
                    ((window === null || window === undefined || window._curCourseID === NaN) ? -1 : window._curCourseID), 
                    ((window === null || window === undefined) ? false : window._isRestartedSACourse), 
                    ((window === null || window === undefined) ? false : window._isRestartedSACourse),
                    (window !== null && window !== undefined && window.dme !== null && window.dme !== undefined &&
                      window.dme["cmi.core.score.raw"] !== null && window.dme["cmi.core.score.raw"] !== undefined && 
                      window.dme["cmi.core.score.raw"] !== NaN) 
                            ? parseInt(window.dme["cmi.core.score.raw"].toString()) : -1,
                    (window !== null && window !== undefined && window.dme !== null && window.dme !== undefined &&
                      window.dme["cmi.core.quizpassmark"] !== null && window.dme["cmi.core.quizpassmark"] !== undefined && 
                      window.dme["cmi.core.quizpassmark"] !== NaN) 
                            ? parseInt(window.dme["cmi.core.quizpassmark"].toString()) : -1,
                    (window !== null && window !== undefined && window.dme !== null && window.dme !== undefined &&
                      window.dme["cmi.core.exit"] !== null && window.dme["cmi.core.exit"] !== undefined && 
                      window.dme["cmi.core.exit"] !== NaN) 
                            ? window.dme["cmi.core.exit"].toString() : '',
                    (learningModuleDataLocal === null || learningModuleDataLocal === undefined || 
                      learningModuleDataLocal.quizStatus === NaN) 
                            ? -1 : learningModuleData.quizStatus, 
                    (learningModuleDataLocal === null || learningModuleDataLocal === undefined) 
                            ? "" : learningModuleDataLocal.quizType
          );
      return res;
  }

  const setupAPI = (dmeData) => {
    return new Promise((resolve, reject) => {
        let _w = window;
        _w._oidcTabUniqueID = oidcUniqueID;
        _w._LocationBefore = _w.Location;
        _w._currentUserId = userId; //Set in the window so setupAPIGlobals file has access has access
        _w._setScormValue = setScormValue; //Set in the window so setupAPIGlobals file has access has access
        _w._getEndQuizInfo = getEndQuizInfoEx; //Set in the window so setupAPIGlobals file has access has access
        _w._processScormRequest = processScormRequest; //Set in the window so setupAPIGlobals file has access has access
        _w._sectionDetailsChangeHandler = sectionDetailsChangeHandler;
        //_w._addError = addError; //Set in the window so setupAPIGlobals file has access has access
        //addError(token, userId, window.location.href.toLowerCase().replace("altlogin-", "")
        _w._sendScormTrainingQAs = sendScormTrainingQAs; //Set in the window so setupAPIGlobals file has access has access
        _w._currentIsLesson = isLesson;
        _w.dme = { ModuleID: moduleData.id.toString() };
        _w._pgs = { "": "" };
        _w.buildDme(dmeData);
        _w._curCourseID = moduleData.courseID;
        _w._curProfileID = (cpdList === null || cpdList.length <= 0) ? -1 : cpdList[currentCpd].id;
        _w._isdbUpdatedForCompletedCourse  = false;
        _w._isRestartedSACourse = ((learningModuleData.quizType === "SA") && 
                                   ((learningModuleData.quizStatus === 2 && saIsRestarted === 1) ||
                                    (learningModuleData.quizStatus !== 2)
                                   )); 

        const fetchSpecificAppConfig = async () => {
          const result = await getSpecificAppConfig(oidcUser.access_token, "SCORM_Debug");
          return result;
        };

        const fetchLessonListByModule = async () => {
          const courseItemData = await getLessonListByModule(
            oidcUser.access_token,
            userId,
            orgDomain,
            moduleData.id,
            !orgId? -1 : orgId,
            true
          );
          return courseItemData;
        };
    
        const fetchQuizListByModule = async () => {
          const courseItemData = await getQuizListByModule(
            oidcUser.access_token,
            userId,
            orgDomain,
            moduleData.id,
            orgId,
            true
          );
          return courseItemData;
        };

        if(!isLesson) {
          fetchQuizListByModule().then((data) => {
            if (!(data === null || data.length <= 0)) {
                _w._currentToolBookLocation = data[0].toolbookLocation;
                setModuleSource(
                  document.location.origin  + _w._currentToolBookLocation /* To support the non-sso users of SSO orgs */
                );
              }
          });
        } 
        else {          
          fetchLessonListByModule().then((data) => {
            if (!(data === null || data.length <= 0)) {
              _w._currentToolBookLocation = data[0].toolbookLocation;
              setModuleSource(
                document.location.origin  + _w._currentToolBookLocation /* To support the non-sso users of SSO orgs */
              );
            }
          });
        }

        const isScorm2004 = (!!learningModuleData.quizType && learningModuleData.quizType === "SA" && !!learningModuleData.quizInfo &&
                              learningModuleData.quizInfo.toString().trim().split("<%$%>").length > 0 &&
                              learningModuleData.quizInfo.toString().trim().split("<%$%>").filter((w) => !!w && w.trim().startsWith("ScormSchemaVersion<:#:>")).length > 0 &&
                              learningModuleData.quizInfo.toString().trim().split("<%$%>").filter((w) => !!w && w.trim().startsWith("ScormSchemaVersion<:#:>"))[0].toString().replace("ScormSchemaVersion<:#:>", "").trim().toLowerCase().includes("scorm") &&
                              learningModuleData.quizInfo.toString().trim().split("<%$%>").filter((w) => !!w && w.trim().startsWith("ScormSchemaVersion<:#:>"))[0].toString().replace("ScormSchemaVersion<:#:>", "").trim().includes("2004")
                            );
        if (!isScorm2004) {
          const Div = document.getElementById("API");
          if (!!Div && !!_w) {
            Div.LMSTerminate = _w.LMSTerminate;
          }
          if (!!_w.API && !!Div) {
            Div.LMSInitialize = _w.API.LMSInitialize = _w.LMSInitialize;
            Div.LMSSetValue = _w.API.LMSSetValue = _w.LMSSetValue;
            Div.LMSGetValue = _w.API.LMSGetValue = _w.LMSGetValue;
            Div.LMSFinish = _w.API.LMSFinish = _w.LMSFinish;
            Div.LMSCommit = _w.API.LMSCommit = _w.LMSCommit;
            Div.LMSGetLastError = _w.API.LMSGetLastError = _w.LMSGetLastError;
            Div.LMSGetErrorString = _w.API.LMSGetErrorString = _w.LMSGetErrorString;
            Div.LMSGetDiagnostic = _w.API.LMSGetDiagnostic = _w.LMSGetDiagnostic;
          }
          // Reset other scorm version initializations
          if (!!_w.API_1484_11) {
            _w.API_1484_11.Initialize = null;
            _w.API_1484_11.SetValue = null;
            _w.API_1484_11.GetValue = null;
            _w.API_1484_11.Terminate = null;
            _w.API_1484_11.Commit = null;
            _w.API_1484_11.GetLastError = null;
            _w.API_1484_11.GetErrorString = null;
            _w.API_1484_11.GetDiagnostic = null;
            _w.API_1484_11 = _w.API;
            //_w.location.reload(true);
          }
        }
        else {
          const DivScorm2004 = document.getElementById("API");
          if (!!DivScorm2004 && !!_w.API_1484_11) {
            //DivScorm2004.LMSTerminate = _w.API_1484_11.Terminate = _w.LMSTerminate;
            DivScorm2004.LMSTerminate = _w.LMSTerminate;
            DivScorm2004.LMSInitialize = _w.API_1484_11.Initialize = _w.LMSInitialize;
            DivScorm2004.LMSSetValue = _w.API_1484_11.SetValue = _w.LMSSetValue;
            DivScorm2004.LMSGetValue = _w.API_1484_11.GetValue = _w.LMSGetValue;
            DivScorm2004.LMSFinish = _w.API_1484_11.Terminate = _w.LMSFinish;
            DivScorm2004.LMSCommit = _w.API_1484_11.Commit = _w.LMSCommit;
            DivScorm2004.LMSGetLastError = _w.API_1484_11.GetLastError = _w.LMSGetLastError;
            DivScorm2004.LMSGetErrorString = _w.API_1484_11.GetErrorString = _w.LMSGetErrorString;
            DivScorm2004.LMSGetDiagnostic = _w.API_1484_11.GetDiagnostic = _w.LMSGetDiagnostic;
          }
          // Reset other scorm version initializations
          if (!!_w.API) {
            _w.API.LMSInitialize = null;
            _w.API.LMSSetValue = null;
            _w.API.LMSGetValue = null;
            _w.API.LMSFinish = null;
            _w.API.LMSCommit = null;
            _w.API.LMSGetLastError = null;
            _w.API.LMSGetErrorString = null;
            _w.API.LMSGetDiagnostic = null;
            _w.API = _w.API_1484_11;
            //_w.location.reload(true);
          }
        }
        window.close = _w.LMSTerminate;
  
        setTimeout(() => {
          setLoading(false);
        }, 1000); //Slight delay make transition between loader and quiz better// };
    
        fetchSpecificAppConfig().then((data) => {
          _w.blnDebug = data === "true";
        });
        _w.blnComplete = false;
    
        resolve(true)
    })
  }

  
  const injectRemovelogsScript = () => {
    setTimeout(() => {
        const isScorm2004 = (!!learningModuleData.quizType && learningModuleData.quizType === "SA" && !!learningModuleData.quizInfo &&
                              learningModuleData.quizInfo.toString().trim().split("<%$%>").length > 0 &&
                              learningModuleData.quizInfo.toString().trim().split("<%$%>").filter((w) => !!w && w.trim().startsWith("ScormSchemaVersion<:#:>")).length > 0 &&
                              learningModuleData.quizInfo.toString().trim().split("<%$%>").filter((w) => !!w && w.trim().startsWith("ScormSchemaVersion<:#:>"))[0].toString().replace("ScormSchemaVersion<:#:>", "").trim().toLowerCase().includes("scorm") &&
                              learningModuleData.quizInfo.toString().trim().split("<%$%>").filter((w) => !!w && w.trim().startsWith("ScormSchemaVersion<:#:>"))[0].toString().replace("ScormSchemaVersion<:#:>", "").trim().includes("2004")
                            );
        const iframeHead = document.getElementById('API').children[0].contentDocument.getElementsByTagName('head')[0];
        const hideLogsScript = document.createElement('script')
        hideLogsScript.type = "text/javascript"
        hideLogsScript.src = "/js/hideConsoleLogs.js"
        if (!!iframeHead) {
          iframeHead.appendChild(hideLogsScript)
        }
        const hideBtn = document.createElement('link')
        hideBtn.rel = 'stylesheet'; 
        hideBtn.type = 'text/css';
        hideBtn.href = '/js/hideExitBtn.css'; 
        if (!!iframeHead) {
          iframeHead.appendChild(hideBtn);
        }
        setLoading(false);
      }, !!learningModuleData && !!learningModuleData.quizType && learningModuleData.quizType.toString().toLowerCase() === "lectoralq" ? 1000 : 500); //Slight delay make transition between loader and quiz better
  }

  const handleExit = () => {
    let isArtRise360 = !!learningModuleData && !!learningModuleData.quizType && 
                        (learningModuleData.quizType.toString().toLowerCase() === "rise360" || learningModuleData.quizType.toString().toLowerCase() === "storyline360");
    let isLectoraLQ = !!learningModuleData && !!learningModuleData.quizType && learningModuleData.quizType.toString().toLowerCase() === "lectoralq";
    if (isArtRise360 || isLectoraLQ) {
        if ((!!window && !!window.dme && !!window.dme["cmi.core.custom_show_summary"] && window.dme["cmi.core.custom_show_summary"] === 1)) {
            setShowResultsModal(true);
        }
        setShowConfirmModal(false);
        setTrainingListUpdateRequired(true);
        setDashboardUpdateRequired(true);
        window.onbeforeunload = function (e) {};
        addError(oidcUser.access_token, userId, orgDomain, `Finished ${learningModuleData.name}`, null, "LectoraQuizPlayer.js", 6);
        history.push(`/mytraining/${courseId}`);
    }
    else 
    {
      setShowConfirmModal(false);
      setTrainingListUpdateRequired(true);
      setDashboardUpdateRequired(true);
      window.onbeforeunload = function (e) {};
      addError(oidcUser.access_token, userId, orgDomain, `Finished ${learningModuleData.name}`, null, "LectoraQuizPlayer.js", 6);
      history.push(`/mytraining/${courseId}`);
    }
  }

  
  const handleCancel = () => {
    setShowConfirmModal(false);
  }

  const handleShowModal = () => {
    let isArtRise360 = !!learningModuleData && !!learningModuleData.quizType && 
                          (learningModuleData.quizType.toString().toLowerCase() === "rise360" || learningModuleData.quizType.toString().toLowerCase() === "storyline360");
    let isLectoraLQ = !!learningModuleData && !!learningModuleData.quizType && learningModuleData.quizType.toString().toLowerCase() === "lectoralq";
    if (window !== undefined && window !== null && window._isRestartedSACourse && isArtRise360 !== true) {     
      fetchProcessScormRequestResolutionAfterDeciding().then(() => {                  
      });              
    }

    if (window !== undefined && window !== null && window._isRestartedSACourse && 
        window.dme !== null && window.dme !== undefined && window.dme["cmi.core.exit"] !== null && window.dme["cmi.core.exit"] !== undefined && 
        window.dme["cmi.core.exit"] !== null && window.dme["cmi.core.exit"].toString() !== "logout") { 
        setShowConfirmModal(true);
      }
      else if (isArtRise360 || isLectoraLQ) { // Pop-up for consistency purpose across all courses
        setShowConfirmModal(true);
      } 
      else {
        setShowConfirmModal(false);
        setTrainingListUpdateRequired(true);
        setDashboardUpdateRequired(true);
        window.onbeforeunload = function (e) {};
        addError(oidcUser.access_token, userId, orgDomain, `Finished ${learningModuleData.name}`, null, "LectoraQuizPlayer.js", 6);
        history.push(`/mytraining/${courseId}`);
      }

  }

  const checkIfMultiSectionsPresent = (givenQuizInfo) => {
    if (!!givenQuizInfo === false || !givenQuizInfo.toString().toLowerCase().includes("<%$%>ismultisection<:#:>yes<%$%>sectionscostartlist<:#:>")) {
      return false;
    }
    const keyValArr = givenQuizInfo.split("<%$%>");
    let matched = !!keyValArr && keyValArr.length > 0 ? 
        keyValArr.filter((word) => word.startsWith('SectionSCOStartList<:#:>')) : '';
    if (!!keyValArr && keyValArr.length > 0 && !!matched && matched.length > 0 && !!matched[0]) {
        let quizPagesSplitOrg = matched[0].replace('SectionSCOStartList<:#:>', '').split("<&>").filter(e => !!e && e.toString().trim() != "");
        return !!quizPagesSplitOrg && quizPagesSplitOrg.length > 1;
    }
    return false;
  }

  useEffect(() => {
    if (!!learningModuleData && 
      !!learningModuleData.quizType &&
      learningModuleData.quizType.toString().toLowerCase().trim() == "lectoralq" &&
      !!learningModuleData.quizInfo && 
      learningModuleData.quizInfo.toString().trim() != "" &&
      learningModuleData.quizInfo.toString().toLowerCase().includes("<%$%>ismultisection<:#:>yes<%$%>sectionscostartlist<:#:>") &&
      checkIfMultiSectionsPresent(learningModuleData.quizInfo.toString().trim())) {
        setIsModuleSingleSection(false);
    }
    else {
      setIsModuleSingleSection(true);
    }
  }, [currentCompletedSection, learningModuleData.quizType, givenQuizInfo]);

  const updateCurrentSectionBookmarkAndModuleSource = (sectionSequence, sectionScoStartList, sectionToSuspendDataList, sectionToBookmarkList, sectionToDefBkmList) => {
    if (!!window === false || !!sectionScoStartList === false || sectionSequence <= 0) {
      return false;
    }

    // Check the current toolbook location is valid
    let curTBLoc = "";
    curTBLoc = !!window._currentToolBookLocation ? window._currentToolBookLocation.toString() : "";
    let slashIdx = !!curTBLoc ? curTBLoc.lastIndexOf("\\") : -1;
    slashIdx = (!!curTBLoc && slashIdx < 0) ? curTBLoc.lastIndexOf("/") : slashIdx;
    if (!!curTBLoc === false || slashIdx < 0) {
      return false;
    }
    
    // Get the current section based on the passed section sequence
    const replacedTxt1 = !!sectionScoStartList ? sectionScoStartList.replace("SectionSCOStartList<:#:>", "") : "";
    const keyValArr1 = !!replacedTxt1 ? replacedTxt1.split("<&>") : [];
    if (!!keyValArr1 === false || keyValArr1.length < sectionSequence) {
      return false;
    }
    if ((sectionSequence-1) >= keyValArr1.length) {
      return false;
    }
    let scoStartOfCurSec = keyValArr1[sectionSequence-1];

    // Get suspend data for the current section
    let finalSusDataValToUpdate = "";
    const replacedTxt2 = !!sectionToSuspendDataList ? sectionToSuspendDataList.replace("SectionToScormSuspendData<:#:>", "") : "";
    const keyValArr2 = !!replacedTxt2 ? replacedTxt2.split("<&>") : [];
    if (!!keyValArr2 && keyValArr2.length > 0 && !!scoStartOfCurSec) {
      let matchedData = keyValArr2.filter((key) => !!key && key.trim().startsWith(scoStartOfCurSec + "<=>>"));
      if (!!matchedData && matchedData.length > 0 && !!matchedData[0] && 
          (finalSusDataValToUpdate=matchedData[0].trim().replace(scoStartOfCurSec + "<=>>", "").trim()) != "") {
          finalSusDataValToUpdate = finalSusDataValToUpdate;
      }
      else {
        finalSusDataValToUpdate = "";
      }
    }
    else {
      finalSusDataValToUpdate = "";
    }

    // Get the bookmark lesson location for the current section
    let finalBkmValToUpdate = "";
    const replacedTxt3 = !!sectionToBookmarkList ? sectionToBookmarkList.replace("SectionToBookmark<:#:>", "") : "";
    const keyValArr3 = !!replacedTxt3 ? replacedTxt3.split("<&>") : [];
    if (!!keyValArr3 && keyValArr3.length > 0 && !!scoStartOfCurSec) {
      let matchedData = keyValArr3.filter((key) => !!key && key.trim().startsWith(scoStartOfCurSec + "<=>>"));
      if (!!matchedData && matchedData.length > 0 && !!matchedData[0] && (finalBkmValToUpdate=matchedData[0].trim().replace(scoStartOfCurSec + "<=>>", "").trim()) != "") {
        finalBkmValToUpdate = finalBkmValToUpdate;
      }
      else {
        finalBkmValToUpdate = "";
      }
    }
    else {
      finalBkmValToUpdate = "";
    }

    // Get default bookmark pages for the current section
    let finalDefBkmToUpdate = "";
    const replacedTxt4 = !!sectionToDefBkmList ? sectionToDefBkmList.replace("SectionDefaultBkms<:#:>", "") : "";
    const keyValArr4 = !!replacedTxt4 ? replacedTxt4.split("<&>") : [];
    if (!!keyValArr4 === false || keyValArr4.length < sectionSequence) {
      return false;
    }
    if ((sectionSequence-1) >= keyValArr4.length) {
      return false;
    }
    finalDefBkmToUpdate = keyValArr4[sectionSequence-1];

    // Finally update the module source by changing the toolbook location as per the current section SCO start
    if (!!curTBLoc && slashIdx >= 0) {
      let tbPart1 = curTBLoc.substring(0, slashIdx+1);
      let tbPart2 = scoStartOfCurSec;
      if (moduleSource != document.location.origin + tbPart1 + tbPart2 && !!window && !!window.LMSInitialize) {
        window._curScormSuspendData = finalSusDataValToUpdate;
        window._curBookmark = finalBkmValToUpdate;
        window._currentToolBookLocation = tbPart1 + tbPart2;
        setModuleSource(
          document.location.origin + window._currentToolBookLocation 
        );
        window.LMSInitialize();

        if (!!finalSusDataValToUpdate && finalSusDataValToUpdate.trim() != "") {
          window.LMSSetValue("cmi.suspend_data", finalSusDataValToUpdate);
        }
        else {
          window.LMSSetValue("cmi.suspend_data", (!!finalBkmValToUpdate && finalBkmValToUpdate.trim() != "") ?  (";~;VarVisitedPages=" + finalBkmValToUpdate.trim() + "%2F;") : 
                                                    ((!!finalDefBkmToUpdate && finalDefBkmToUpdate.trim() != "") ?  (";~;VarVisitedPages=" + finalDefBkmToUpdate.trim() + "%2F;") : ""));
        }
      }
    }

    return true;
  }

  useEffect(() => {
    if (!!multiSectionCurrentSelection && multiSectionCurrentSelection.trim().startsWith("Section ")) { 
      let sectionSeq = 0;
      try { sectionSeq = parseInt(multiSectionCurrentSelection.trim().replace("Section ", "")); } catch (err) { sectionSeq = -1; }
      if (sectionSeq > 0 &&
          !!learningModuleData && 
          !!learningModuleData.quizType &&
          learningModuleData.quizType.toString().toLowerCase().trim() == "lectoralq" &&
          !!learningModuleData.quizInfo && 
          learningModuleData.quizInfo.toString().trim() != "" &&
          learningModuleData.quizInfo.toString().toLowerCase().includes("<%$%>ismultisection<:#:>yes<%$%>sectionscostartlist<:#:>")) {
          const keyValArr = !!learningModuleData.quizInfo ? learningModuleData.quizInfo.split("<%$%>") : [];
          let matched1 = !!keyValArr && keyValArr.length > 0 ? keyValArr.filter((word) => word.trim().toLowerCase().startsWith('sectionscostartlist<:#:>')) : '';
          let matched2 = !!keyValArr && keyValArr.length > 0 ? keyValArr.filter((word) => word.trim().toLowerCase().startsWith('sectiontoscormsuspenddata<:#:>')) : '';
          let matched3 = !!keyValArr && keyValArr.length > 0 ? keyValArr.filter((word) => word.trim().toLowerCase().startsWith('sectiontobookmark<:#:>')) : '';
          let matched4 = !!keyValArr && keyValArr.length > 0 ? keyValArr.filter((word) => word.trim().toLowerCase().startsWith('sectiondefaultbkms<:#:>')) : '';
          if (!!matched1 && matched1.length > 0 && !!matched1[0]) {
                let retval = updateCurrentSectionBookmarkAndModuleSource(sectionSeq, matched1[0], matched2[0], matched3[0], matched4[0]);
          }
      }
    }
  }, [multiSectionCurrentSelection, multiSectionSessionDetails, moduleSource]);
  

  useEffect(() => {
    setTrainingListUpdateRequired(false)
    stopLoadingTimeout();
    setCurSelectedModuleID(learningModuleData.id);
    setIsCurSessionLesson(isLesson)

    const fetchPreQuizData = async () => {
      const result = await getPreQuizStartResult(oidcUser.access_token, userId, orgDomain, learningModuleData.id, false, ((oidcUser?.session_state === undefined || oidcUser?.session_state === null) ? "" : oidcUser?.session_state), isLesson ? "lesson" : learningModuleData.quizType);
      setCurSessionID(result.sessionID);
      setCurBrowserTabUniqueID(oidcUniqueID);
      return result;
    };

    const sendStartQuiz = async (sessionId) => {
      const success = await startQuiz(oidcUser.access_token, userId, orgDomain, sessionId, ((oidcUser?.session_state === undefined || oidcUser?.session_state === null) ? "" : oidcUser?.session_state));
      return success;
    };

    const fetchPreLessonData = async () => {
      const result = await getPreLessonStartResult(oidcUser.access_token, userId, orgDomain, learningModuleData.id, true);
      return result;
    };

    const sendStartLesson = async (sessionId) => {
      const success = await startLesson(oidcUser.access_token, userId, orgDomain, sessionId);
      return success;
    };

    const fetchDme = async (isForLesson) => {
      const fetchedDme = await getUserSessionDme(oidcUser.access_token, userId, orgDomain, learningModuleData.id, isForLesson);
        return fetchedDme;
    };

    const fetchEndQuizInfo = async () => {
      const fetchedInfo = await getEndQuizInfoEx(oidcUser.access_token, userId, orgDomain, learningModuleData.id, undefined, 0, ((oidcUser?.session_state === undefined || oidcUser?.session_state === null) ? "" : oidcUser?.session_state));
      return fetchedInfo;
    }

    const sendDeleteDme = async (type) => {
      if (type === "SA" || type === "Rise360" || type === "Storyline360" || type === "LectoraLQ") {
        const success = await deleteDME(oidcUser.access_token, userId, orgDomain, learningModuleData.id)
        return Boolean(success)
      }
      return Boolean(true) //Delete only required for SA & Rise360 & Storyline360, otherwise we return success. 
    };

    const fetchProcessedScormRequest = async () => {
      const processResult = await processScormRequest(oidcUser.access_token, userId, orgDomain, window._currentIsLesson, null, window._curProfileID, 
        window._curProfileID, moduleData.id, false, null, null, null, 'en-AU', 
        learningModuleData.quizType !== "SA" && learningModuleData.quizType !== "Rise360" && 
        learningModuleData.quizType !== "Storyline360" && learningModuleData.quizType !== "LectoraLQ" && learningModuleData.quizType !== "InfoPath", 
        ((oidcUser?.session_state === undefined || oidcUser?.session_state === null) ? "" : oidcUser?.session_state)); // TO DO change culture based on selection
      return processResult;
    };

    const mainPageHead = document.getElementsByTagName("head")[0];

    if (!isLesson) {
      fetchPreQuizData().then((data) => {
        sendStartQuiz(data[0].sessionID).then(() => {
          fetchDme(isLesson).then((dmeData) => {
            injectScripts(mainPageHead, scriptsUrls).then(() => {
              window._curSessionID = data[0].sessionID;
              window._curBookmark = data[0].bookmark;
              window._curQuizPassmark = data[0].quizPassmark;
              window._curScormSuspendData = data[0].currentScormSuspendData;
              window._curLatestUserQuizStatus = data[0].currentModuleStatus;
              window._curCustomCourseProgress = data[0].customCourseProgress;
              window._curLessonStatus = data[0].currentLessonStatus;
              window._curLessonStatusID = data[0].currentModuleStatus;
              window._latestQuizScore = data[0].latestQuizScore;
              window._curQuizInfo = data[0].quizInfo;
              window._contentType = learningModuleData.quizType;
              window._contentSubType = data[0].contentSubType;
              window._initialLatestSessionDetails = data[0].latestSessionDetails;
              window._latestSessionDetails = window._initialLatestSessionDetails;
              
              window._sectionDetailsChangeHandler = sectionDetailsChangeHandler;
              setMultiSectionSessionDetails(data[0].latestSessionDetails);
              //console.log("window._curQuizPassmark => " + window._curQuizPassmark);
              //console.log("window._curBookmark => " + window._curBookmark);
              setupAPI(dmeData).then((setupRes) => {
                injectRemovelogsScript()
                setCurSessionID(data[0].sessionID)
                setCurBookmark(data[0].bookmark)
                setCurQuizPassmark(data[0].quizPassmark)
                setCurCustomCourseProgress(data[0].customCourseProgress)
                setCurBrowserTabUniqueID(oidcUniqueID);
                sendDeleteDme(learningModuleData.quizType).then((deleteRes) => {
                  if (learningModuleData.quizType === "SA")
                  {
                    if (saReviewOnlyValue === 0) {
                      updateSAPassLaunchPageDetails(oidcUser.access_token, userId, learningModuleData.id, false, learningModuleData.quizStatus !== 1, orgDomain).then((res) => {
                        fetchProcessedScormRequest().then((scormProcessRes) => {
                          if (scormProcessRes !== undefined && scormProcessRes !== null && scormProcessRes.resultContent === "") {
                            setProcessedScormResult(scormProcessRes);
                          }
                          window.xml2json(scormProcessRes.resultContent);
                        });
                      });
                      setSaReviewOnlyValue(-1);
                    }
                    else if (saReviewOnlyValue === 1) {
                      updateSAPassLaunchPageDetails(oidcUser.access_token, userId, learningModuleData.id, true, false, orgDomain).then((res) => {
                        fetchProcessedScormRequest().then((scormProcessRes) => {
                          if (scormProcessRes !== undefined && scormProcessRes !== null && scormProcessRes.resultContent === "") {
                            setProcessedScormResult(scormProcessRes);
                          }
                          window.xml2json(scormProcessRes.resultContent);
                        });
                      });
                      setSaReviewOnlyValue(-1);
                    }
                  } else {
                    fetchProcessedScormRequest().then((scormProcessRes) => {
                      if (scormProcessRes !== undefined && scormProcessRes !== null && scormProcessRes.resultContent === "") {
                        setProcessedScormResult(scormProcessRes);
                      }
                      window.xml2json(scormProcessRes.resultContent);
                    })
                  }
                });
              })
            });
          });
        });
      });

      if (!isLesson)
      {
        fetchEndQuizInfo().then((data) => {
          setEndQuizInfo(data);
          window._currentToolBookLocation = data[0].toolbookLocation;
          //console.log("After Load => Session Details => " + data[0].latestSessionDetails);
          if (!!data && !!data[0] && !!data[0].latestSessionDetails && !!data[0].info && 
              data[0].info.toString().trim().toLowerCase().includes("<%$%>ismultisection<:#:>yes<%$%>sectionscostartlist<:#:>") &&
              !data[0].latestSessionDetails.toString().trim().toLowerCase().includes("<%$%>currentsection<:#:>") &&
              !data[0].latestSessionDetails.toString().trim().toLowerCase().startsWith("currentsection<:#:>")) {
            setMultiSectionCurrentSelection("Section 1");
          }
          if (!!learningModuleData.quizType && 
              learningModuleData.quizType.toString().trim().toLowerCase() == "lectoralq" &&
              !!data && !!data[0] && !!data[0].info && 
              data[0].info.toString().trim().toLowerCase().includes("<%$%>ismultisection<:#:>yes<%$%>sectionscostartlist<:#:>")) {
            window._initialLatestSessionDetails = data[0].latestSessionDetails;
            window._latestSessionDetails = data[0].latestSessionDetails;
            setMultiSectionSessionDetails(data[0].latestSessionDetails);
            if (!!data[0] === false || !!data[0].latestSessionDetails === false || 
              (!data[0].latestSessionDetails.toString().trim().toLowerCase().includes("<%$%>currentsection<:#:>") &&
               !data[0].latestSessionDetails.toString().trim().toLowerCase().startsWith("currentsection<:#:>"))) {
              setMultiSectionCurrentSelection("Section 1");
            }
            else {
              let curSessionDetails = "";
              curSessionDetails = !!data[0].latestSessionDetails ? data[0].latestSessionDetails.toString() : ""; 
              const keyValArr = !!curSessionDetails ? curSessionDetails.split("<%$%>") : [];
              let matched = (!!keyValArr && keyValArr.length > 0) ? keyValArr.filter((word) => word.trim().toLowerCase().startsWith('currentsection<:#:>')) : [];
              let currentSectionValue = "";
              currentSectionValue = (!!matched && matched.length > 0 && !!matched[0]) ? matched[0].trim().replace('CurrentSection<:#:>', '') : "";
              if (!!currentSectionValue) {
                setMultiSectionCurrentSelection(currentSectionValue);
              }
              else {
                setMultiSectionCurrentSelection("Section 1");
              }
            }
          }
        });
      }
    } else {
      fetchPreLessonData().then((data) => {
        sendStartLesson(data[0].sessionID).then(() => {
          fetchDme(isLesson).then((dmeData) => {
            injectScripts(mainPageHead, scriptsUrls).then(() => {
              setupAPI(dmeData).then(() => {
                window._curSessionID = data[0].sessionID;
                setCurSessionID(data[0].sessionID);
                setCurBrowserTabUniqueID(oidcUniqueID);
                //console.log("After Load => Session Details => " + data[0].latestSessionDetails);
                if (!!data && !!data[0] && !!data[0].latestSessionDetails && !!data[0].info && 
                  data[0].info.toString().trim().toLowerCase().includes("<%$%>ismultisection<:#:>yes<%$%>sectionscostartlist<:#:>") &&
                  !data[0].latestSessionDetails.toString().trim().toLowerCase().includes("<%$%>currentsection<:#:>") &&
                  !data[0].latestSessionDetails.toString().trim().toLowerCase().startsWith("currentsection<:#:>")) {
                  setMultiSectionCurrentSelection("Section 1");
                }
                if (!!learningModuleData.quizType && learningModuleData.quizType.toString().trim().toLowerCase() == "lectoralq" &&
                    !!data[0].info && data[0].info.toString().trim().toLowerCase().includes("<%$%>ismultisection<:#:>yes<%$%>sectionscostartlist<:#:>")) {
                  window._initialLatestSessionDetails = data[0].latestSessionDetails;
                  window.latestSessionDetails = data[0].latestSessionDetails;
                  setMultiSectionSessionDetails(data[0].latestSessionDetails);
                  if (!!data[0].latestSessionDetails === false || 
                    (!data[0].latestSessionDetails.toString().trim().toLowerCase().includes("<%$%>currentsection<:#:>") &&
                     !data[0].latestSessionDetails.toString().trim().toLowerCase().startsWith("currentsection<:#:>"))) {
                    setMultiSectionCurrentSelection("Section 1");
                  }
                  else {
                    let curSessionDetails = "";
                    curSessionDetails = !!data[0].latestSessionDetails ? data[0].latestSessionDetails.toString() : ""; 
                    const keyValArr = !!curSessionDetails ? curSessionDetails.split("<%$%>") : [];
                    let matched = (!!keyValArr && keyValArr.length > 0) ? keyValArr.filter((word) => word.trim().toLowerCase().startsWith('currentsection<:#:>')) : [];
                    let currentSectionValue = "";
                    currentSectionValue = (!!matched && matched.length > 0 && !!matched[0]) ? matched[0].trim().replace('CurrentSection<:#:>', '') : "";
                    if (!!currentSectionValue) {
                      setMultiSectionCurrentSelection(currentSectionValue);
                    }
                    else {
                      setMultiSectionCurrentSelection("Section 1");
                    }
                  }
                }
                sendDeleteDme(learningModuleData.quizType).then((deleteRes) => {                  
                });
              })
            });
          });
        });
      });
    }

    window.onbeforeunload = function (e) {
      let isArtRise360 = !!learningModuleData && !!learningModuleData.quizType && 
                (learningModuleData.quizType.toString().toLowerCase() === "rise360" || 
                 learningModuleData.quizType.toString().toLowerCase() === "storyline360");    
      let isLectoraLQ = !!learningModuleData && !!learningModuleData.quizType && 
                 (learningModuleData.quizType.toString().toLowerCase() === "lectoralq");    
       if (window !== undefined && window !== null && window._isRestartedSACourse && isArtRise360 !== true) {   

        fetchProcessScormRequestResolutionAfterDeciding().then(() => {
        }); 
        e.preventDefault();
        e.returnValue = '';         
      }
      else if (isArtRise360 || isLectoraLQ) {
        e.preventDefault();
        e.returnValue = '';   
      }
      else
      {
        console.log("Other cases! No Action!");
      }

    }

  
    //Adding return function to the window for SCORM API to tap into
    window._getAPIBearerKey = function () {
      return oidcUser.access_token;
    }

    //Adding return function to the window for SCORM API to tap into
    window._returnToModuleList = function () {
      let isArtRise360 = !!learningModuleData && !!learningModuleData.quizType && 
                          (learningModuleData.quizType.toString().toLowerCase() === "rise360" ||
                           learningModuleData.quizType.toString().toLowerCase() === "storyline360");  
      let isLectoraLQ = !!learningModuleData && !!learningModuleData.quizType && 
                           (learningModuleData.quizType.toString().toLowerCase() === "lectoralq");  
       if (isArtRise360 || isLectoraLQ)   {
        //if (isArtRise360) {
          if ((!!window && !!window.dme && !!window.dme["cmi.core.custom_show_summary"] && window.dme["cmi.core.custom_show_summary"] === 1)) {
              setShowResultsModal(true);
          }

          // Reset the multi section states locally and in session context
          setIsModuleSingleSection(false);
          setMultiSectionSessionDetails("");
          setMultiSectionCurrentSelection("");
          setMultiSectionCurrentSelectionOnQuizOpen("");

          history.push(`/mytraining/${courseId}`);
        // }
        // else {
        //   sendProcessedScormRequestToWrite().then((scormProcessRes) => {
        //     if (scormProcessRes !== undefined && scormProcessRes !== null && scormProcessRes.resultContent === "") {
        //       if ((!!window && !!window.dme && !!window.dme["cmi.core.custom_show_summary"] && window.dme["cmi.core.custom_show_summary"] === 1)) {
        //           setShowResultsModal(true);
        //       } 
        //     }  
        //     history.push(`/mytraining/${courseId}`);
        //   }).catch((error) => {
        //     history.push(`/mytraining/${courseId}`);
        //   });
        // }
      }
      else {
        setShowResultsModal(true);

        // Reset the multi section states locally and in session context
        //setIsModuleSingleSection(false);
        setMultiSectionSessionDetails("");
        setMultiSectionCurrentSelection("");
        setMultiSectionCurrentSelectionOnQuizOpen("");
        
        history.push(`/mytraining/${courseId}`);
      }
    }
    setShowExitButton(learningModuleData.quizType === "SA" || learningModuleData.quizType === "Rise360" || 
                      learningModuleData.quizType === "Storyline360" || learningModuleData.quizType === "LectoraLQ");
    // setShowExitButton(learningModuleData.quizType === "SA" || learningModuleData.quizType === "Rise360" || 
    //                   learningModuleData.quizType === "Storyline360");

    return () => { 
      // Reset the multi section states locally and in session context
      setIsModuleSingleSection(false);
      setMultiSectionSessionDetails("");
      setMultiSectionCurrentSelection("");
      setMultiSectionCurrentSelectionOnQuizOpen("");

      setTrainingListUpdateRequired(true);
      setDashboardUpdateRequired(true);
      setShowExitButton(false);   
    } // Remove button always on quiz close
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    //Cant dynamically render as the iFrame needs to be present in order for SCORM API to setup, Need to use display none instead
    <>
      <div style={{ display: loading ? "block" : "none" }}>
        <LoaderContainer />
      </div>
        {
          isModuleSingleSection == false ? 
          <div style={{ display: loading ? "none" : "block" }}>          
            <ModuleMultiSectionLayout 
              learningModuleData={learningModuleData} 
              showExitButton={showExitButton}
              handleExitProceed={handleExit} 
              handleCancel={handleCancel} 
              showModal={showConfirmModal} 
              handleModal={handleShowModal} 
              courseWindow={window}
              curDocumentElement={document.getElementById("API")}
              currentCompletedSection={currentCompletedSection}
              /> 
            <div style={{ display: loading ? "none" : "block" }} id="API">
              <iframe
                    id="iframe"
                    title={learningModuleData.name}
                    frameBorder="1"
                    scrolling="Yes"
                    className={styles.iframe}
                    src={moduleSource}
                  />
              {
                showExitButton ? 
                  <SAExitButton id="SALectoraExitButton" 
                                handleExitProceed={handleExit} 
                                handleCancel={handleCancel} 
                                showModal={showConfirmModal} 
                                handleModal={handleShowModal} 
                                quizType={(!!learningModuleData && !!learningModuleData.quizType) ? learningModuleData.quizType.toString().toLowerCase() : "" } /> : null
              }
            </div>
          </div>
          :  
            (!!learningModuleData.quizType && learningModuleData.quizType === "SA" && !!learningModuleData.quizInfo &&
              learningModuleData.quizInfo.toString().trim().split("<%$%>").length > 0 &&
              learningModuleData.quizInfo.toString().trim().split("<%$%>").filter((w) => !!w && w.trim().startsWith("ScormSchemaVersion<:#:>")).length > 0 &&
              learningModuleData.quizInfo.toString().trim().split("<%$%>").filter((w) => !!w && w.trim().startsWith("ScormSchemaVersion<:#:>"))[0].toString().replace("ScormSchemaVersion<:#:>", "").trim().toLowerCase().includes("scorm") &&
              learningModuleData.quizInfo.toString().trim().split("<%$%>").filter((w) => !!w && w.trim().startsWith("ScormSchemaVersion<:#:>"))[0].toString().replace("ScormSchemaVersion<:#:>", "").trim().includes("2004"))                       
              ?     
              <div key="Scorm2004API" style={{ display: loading ? "none" : "block" }} 
                    id={"API"}>
                <iframe
                  id="iframe"
                  title={learningModuleData.name}
                  frameBorder="1"
                  scrolling="Yes"
                  className={styles.iframe}
                  src={moduleSource}
                />
                {
                  showExitButton ? 
                    <SAExitButton id="SALectoraExitButton" 
                                  handleExitProceed={handleExit} 
                                  handleCancel={handleCancel} 
                                  showModal={showConfirmModal} 
                                  handleModal={handleShowModal} 
                                  quizType={(!!learningModuleData && !!learningModuleData.quizType) ? learningModuleData.quizType.toString().toLowerCase() : "" } /> : null
                }
              </div>
              :     
              <div key="Scorm1p2API" style={{ display: loading ? "none" : "block" }} 
                    id={"API"}>
                <iframe
                  id="iframe"
                  title={learningModuleData.name}
                  frameBorder="1"
                  scrolling="Yes"
                  className={styles.iframe}
                  src={moduleSource}
                />
                {
                  showExitButton ? 
                    <SAExitButton id="SALectoraExitButton" 
                                  handleExitProceed={handleExit} 
                                  handleCancel={handleCancel} 
                                  showModal={showConfirmModal} 
                                  handleModal={handleShowModal} 
                                  quizType={(!!learningModuleData && !!learningModuleData.quizType) ? learningModuleData.quizType.toString().toLowerCase() : "" } /> : null
                }
              </div>
        }
    </>
  );
};

export default LectoraQuizPlayer;
