//React + Typescript
import React, { useContext, useEffect, useState, useRef } from 'react';

// Libsimport
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';
//Intro Lib
import { Steps, Hints } from "intro.js-react";
//API Functions 
import { addError } from '../../API/AddError'
import { GetOrgLinks } from '../../API/Administration/Organisation/GetOrgLinks';
import CloseWhiteIcon from "@material-ui/icons/Close";
import Modal from '../../Components/Modal';
import ProceedIcon from "../../CustomIcons/ProceedIcon";
import styles from "../../styles/organisationmanagement.module.css";
import responsivestyles from "../../styles/responsiveGridLayout.module.css";


//General Functions
import getColor from '../../functions/getColor';
import validateEmail from '../../functions/valudateEmail';
//Components
import CustomTooltip from '../../Components/CustomTooltip';
import UnbrandedCard from '../../Components/UnbrandedCard';
import LoaderContainer from '../../Components/LoaderContainer';
import GuideIcon from '../../CustomIcons/GuideIcon';
//MUI Components
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';

import CachedIcon from '@material-ui/icons/Cached';
import EditIcon from '../../CustomIcons/SupportDetailsEditIcon';
import ReverttIcon from '../../CustomIcons/SupportDetailsReverttIcon';
import SaveIcon from '../../CustomIcons/SupportDetailSaveIcon';
import CancelIcon from '../../CustomIcons/SupportDetailCancelIcon';

import moment from 'moment';
import BrandedCard from '../../Components/BrandedCard';
import CommonPopupModalForResult from '../../Components/CommonPopupModalForResult';
import { OrgLinks } from '../../globalTypes';
import { Checkbox, CircularProgress, TableContainer, TablePagination } from '@material-ui/core';
import DeleteIcon from '../../CustomIcons/DeleteIcon';
import SaveOrgLinks from '../../API/Administration/Organisation/SaveOrgLinks';
import UpArrowIcon from '../../CustomIcons/Administration/UpArrowIcon';
import DownArrowIcon from '../../CustomIcons/Administration/DownArrowIcon';
import { cursorTo } from 'readline';

const Links = (props: any): JSX.Element => {
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext)
  const { color } = useContext(CorperateThemeContext).theme;
  const { setMessage } = useContext(LoaderContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection} = useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { oidcUser } = useReactOidc();
  const [orgLinks, setorgLinks] = useState<Array<OrgLinks>>([]);
  const [showConfirmModal, setshowConfirmModal] = useState<boolean>(false);

  const [disclaimermessage, setdisclaimermessage] = useState<string>("");

  const [caption, setCaption] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [linkID, setlinkID] = useState<number>(0);
  const [linkOrder, setlinkOrder] = useState<number>(999);
  const [showDisclaimer, setshowDisclaimer] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);


  const [captionadd, setCaptionadd] = useState<string>("");
  const [urladd, setUrladd] = useState<string>("");
  const [showDisclaimeradd, setshowDisclaimeradd] = useState<boolean>(false);



  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);

  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  const language = useRef<string>("en-AU");


  const loadOrgLinks = () => {
    GetOrgLinks(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((result: any) => {
      if (result.isSuccess && result.error == '') {
        if (result.orgLinks !== null) {
          setorgLinks([...result.orgLinks]);
          setdisclaimermessage(result.orgAppConfigItems[0].disclaimer.replace("\r\n","<br>"))
        }
      }
      else {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage("Can not load Organisation links!");
      }
      setLoading(false);
    });
  };
 
  useEffect(() => {
    setTitle(allResourcesToTranslations.links)
    setTitleEnglish(pageTitles.links)
    updateBackButtonRoute("");
    setMenuSelection("Modify Links")
    setLoading(true);
    loadOrgLinks();
    return () => { };
  }, []);
  const handleClosePopup = () => {
    setShowWarningModal(false);
  };
  useEffect(() => {
    if (showWarningModal) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      }, 5000)
    }
  }, [showWarningModal])

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "#fff" }
    } else {
      return {}
    }
  }
 
  const handleCancel = (name: any) => {
    const updatedSelectedLink = orgLinks.map((item: OrgLinks) => {
      if (!!item && item.caption === name) {
        item.isBeingEdit = false;
      }
      return item;
    });
    setorgLinks([...updatedSelectedLink]);
  };
  const handleDisclaimer = (index: number,name: string) => {
    handleConfirmModal(true);
    setlinkID(index);
    setUrl(name);
  }
  const handleConfirmModal = (value: boolean) => {
    setshowConfirmModal(value);
  }
  const handleCancelConfirmPopup = () => {
    handleConfirmModal(false);
    setIsSaving(false);
  }
  const handleProceed = () => {
    handleConfirmModal(false);
    if (linkID > 0) {
      window.open(url, "_blank")
    }
  }  
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const createWelcomeMessageMarkup = () => {
    return {
       __html: disclaimermessage };
 };
 
  if (loading) return <LoaderContainer />;
  return (
    <>
       <Modal modalOpen={showConfirmModal} setModalOpen={handleConfirmModal} type="warning">
        <div className={styles.modalContentWrapper}>
          <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>DISCLAIMER</h2>
          <div className={styles.inputContainer}>
            <p>
              <div className={styles.welcomeMessage} dangerouslySetInnerHTML={createWelcomeMessageMarkup()} />
            </p>
            <p>                          
							<b>{allResourcesToTranslations.proceedmessage} </b>
						</p>
					</div>            
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "10px"}}
          >
            <CustomTooltip displayText={[allResourcesToTranslations.return]}>
              <span className={styles.returnIcon} onClick={handleCancelConfirmPopup}>
                <CloseWhiteIcon />
              </span>
            </CustomTooltip>
            <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
              <span className="saveData">
                <Button
                 onClick={() => handleProceed()}                
                  variant="contained"
                  color="primary"
                  className="save"
                >
                  <ProceedIcon />
                </Button>
              </span>
            </CustomTooltip>
          </div>
        </div>
      </Modal>

      <div className={styles.mainWrapper}>
        <div style={{ width: '100%', clear: "both" }} >
          {orgLinks != null && orgLinks.length > 0 && (
            <div>

              <div className={styles.paginationControlsContainer}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={orgLinks.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
              <div className={`${styles.tableSection}`}>
                <div className={styles.tableContainer}>
                  <BrandedCard>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className="Caption"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                              align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div
                                >
                                  {"Link Caption"/* TODO translate */}
                                </div>

                              </div>
                            </TableCell>
                            <TableCell className="Url"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }}
                              align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div >
                                  {"Url" /* TODO translate */}
                                </div>
                              </div>
                            </TableCell>                       
                           
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {orgLinks.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                            .map((result: any, i) => {
                              return (
                                <TableRow style={rowBackgroundColor(i)}>
                                  <TableCell align="left" width={"25%"}>
                                  <span className={result.caption}>{result.caption}</span>
                                  </TableCell>
                                  <TableCell align="left" width={"25%"}>     
                                  {result.showDisclaimer ?
                                      <span>
                                        <a style={{cursor:"pointer"}}  onClick={() => handleDisclaimer(result.linkID,result.url)}  target="_blank">{result.url}</a>
                                      </span> 
                                      :                                
                                    <span>
                                    <a href={result.url} target="_blank">{result.url}</a>
                                    </span>   
                                  }                                                                
                                  </TableCell>                                  
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </BrandedCard>
                </div>
              </div>
              <div className={styles.paginationAddedUsersControl}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={orgLinks.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}

      </div>

    </>
  );
};
export default Links;
