import axios from 'axios';
import { addError } from '../AddError';

export const SaveOrgAccountManagers = async (token: string, userId: number, orgDomain: string, orgId: number | null, firstName: string,    
   lastName: string, email: string,isActive: boolean ,id: number,act:string 
   ,selectedOrgID: number = -1, selectedOrgDomainName: string = "", selectOrgSiteName: string = "" ) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/SuperAdministration/SaveOrgAccountManagers`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {        
        userId: userId,
        orgID: orgId,      
        orgDomainName: orgDomain,      
        active: isActive,        
        firstName: firstName,
        lastName: lastName,        
        email: email,        
        iD:id,
        act:act,
        selectedOrgID: selectedOrgID,
        selectedOrgDomainName: selectedOrgDomainName, 
        selectOrgSiteName: selectOrgSiteName
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "SaveOrgAccountManagers.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "SaveOrgAccountManagers.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "SaveOrgAccountManagers.ts", 1)
    }
    return null;
  }
};

export default SaveOrgAccountManagers;