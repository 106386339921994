import axios from 'axios';
import { addError } from '../../AddError';

export const SaveOrgComplianceDetails = async (token: string, userId: number, orgId: number | null, orgDomain: string, defaultQuizMark: string, numberOfQuizQuestions: string, 
  defaultLessonFrequency: number, defaultQuizFrequency: number, defaultLessonCompletionDate: string, defaultQuizCompletionDate: string,
  enableOneTimeCourseComplition: boolean, applyOverridingLessonRules: boolean, applyOverridingQuizRules: boolean, yearlyIncrementLesson: number, yearlyIncrementQuiz: number ) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/organisation/SaveOrgComplianceDetails`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        userId: userId,
        orgId: orgId,
        DefaultQuizMark: defaultQuizMark,
        NumberOfQuizQuestions: numberOfQuizQuestions,
        DefaultLessonFrequency: parseInt(defaultLessonFrequency.toString()), 
        DefaultQuizFrequency: parseInt(defaultQuizFrequency.toString()), 
        DefaultLessonCompletionDate: defaultLessonCompletionDate == "" ? null : defaultLessonCompletionDate,         
        DefaultQuizCompletionDate: defaultQuizCompletionDate == "" ? null : defaultQuizCompletionDate,
        EnableOneTimeCourseComplition: enableOneTimeCourseComplition, 
        ApplyOverridingLessonRules: applyOverridingLessonRules,         
        ApplyOverridingQuizRules: applyOverridingQuizRules,
        YearlyIncrementLesson: defaultLessonCompletionDate == "" ? 0 : yearlyIncrementLesson,
        YearlyIncrementQuiz: defaultQuizCompletionDate == "" ? 0 : yearlyIncrementQuiz
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "SaveOrgComplianceDetails.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "SaveOrgComplianceDetails.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "SaveOrgComplianceDetails.ts", 1)
    }
    return null;
  }
};

export default SaveOrgComplianceDetails;