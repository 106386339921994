import React, { useEffect, useState } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context';
import Button from '@material-ui/core/Button'
//Styles
import styles from '../../styles/currentAdminReport.module.css'
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import getByteArrayOfGiven from '../../API/GetByteArrayOfGiven';


export const TestLargeData = () => {
    const {  logout } = useReactOidc();
    const [givenSize, setGivenSize] = useState<number>(0)
    const [resultSize, setSesultSize] = useState<number>(0)
    const { oidcUser } = useReactOidc();
    
    useEffect(() => {
    },[]) 

    const handleGetByteArray = () => {
        return new Promise(resolve => {
            getByteArrayOfGiven(oidcUser.access_token, givenSize).then((data) => {
              //console.log("GetByteArrayOfGiven Result: " + ((data !== null && data !== undefined) ? (data.isSuccess.toString() + " Length: " + ((data.ByteArray !== null && data.ByteArray !== undefined) ? data.ByteArray.length.toString() : "null/empty") ) : "null") );
    
            setSesultSize(0)
            if (data !== null && data !== undefined && data.isSuccess) {
              if (data.byteArray !== null && data.byteArray !== undefined) {
                setSesultSize(data.byteArray.length)
              } else {
                setSesultSize(0)
              }
              resolve({ success: true })
            } else {
              resolve({ success: false })
            }
          })
        })
    }

    return (
        <div >            
            <div className={styles.inputContainer}>
                <TextField  className={styles.input}  
                            label={"Provide byte array size"} 
                            variant="outlined" 
                            autoComplete="off" 
                            InputLabelProps={{shrink: true,}} 
                            value={givenSize} 
                            onChange={(e) => setGivenSize(parseInt(e.target.value))} />
                <br/>
                <TextField  className={styles.input}  
                            label={"Returned byte array size"} 
                            variant="outlined" 
                            autoComplete="off"  
                            InputLabelProps={{shrink: true,}} 
                            value={resultSize} />
                <br/>
                <Button
                    onClick={() => {
                        handleGetByteArray();
                    }}
                    variant="contained"
                    color="primary"
                    title="Get ByteArray"
                >
                    <SaveIcon />
                </Button>
            </div>
        </div>
    )
}

export default TestLargeData;
