import React, { useContext } from 'react'
import { TranslationsContext } from "../../contexts/TranslationsContext";
import { UIContext } from '../../contexts/UIContext';
import styles from '../../styles/learningModuleList.module.css';

interface Props {
    isColorRed: boolean;
    daysUntilDue: string;
    lastCompletionDate: string;
    dueDate: string;
    oneTimeCourseCompletion:boolean;
}

const DateDisplay = ({ isColorRed, daysUntilDue, lastCompletionDate, dueDate,oneTimeCourseCompletion}: Props) => {
    const {translations: { allResourcesToTranslations }} = useContext(TranslationsContext);
    const {showLastPassDate} = useContext(UIContext)
    const showPassDate = lastCompletionDate !== " " && showLastPassDate ? true : false;
          let dueText = showLastPassDate ? allResourcesToTranslations.dueon.replace("{DUEDATE}", dueDate) : ` `;
          const textColor = isColorRed ? (!oneTimeCourseCompletion ?  "red" : "#21394F") : "#21394F"; //TODO make global warning error and success colors
          if (parseInt(daysUntilDue) < 0) {
            dueText = allResourcesToTranslations.overdue;
          } else if (parseInt(daysUntilDue) === 1) {
            dueText = allResourcesToTranslations.duein1day;
          } else if (parseInt(daysUntilDue) < 37) {
            dueText = allResourcesToTranslations.dueinxdays.replace("{DAYSUNTILDUE}", daysUntilDue)
          }          
          else if (dueDate === "") {
            dueText = allResourcesToTranslations.noduedate;
          }
          dueText = showLastPassDate ? dueText : ` `;

          return (
              <div className={`${styles.moduleDates} dueDate`}>
                <span style={{ display: showPassDate? "inline-block" : "none" }}>
                  {allResourcesToTranslations.lastpassdate.replace("{LASTCOMPLETIONDATE}",lastCompletionDate)}
                </span>
                <span style={{ color:textColor, display: showLastPassDate? "inline-block" : "none" }}>
                  {/* {dueText}  */}
                  {oneTimeCourseCompletion ? allResourcesToTranslations.onetimeonlycourselistpage : dueText}          
                </span>
              </div>
          );
}

export default DateDisplay
