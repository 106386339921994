import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const PasswordInValidIcon = () => (
 
<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.78271 0.527466C3.01271 0.527466 0.782715 2.75747 0.782715 5.52747C0.782715 8.29747 3.01271 10.5275 5.78271 10.5275C8.55271 10.5275 10.7827 8.29747 10.7827 5.52747C10.7827 2.75747 8.55271 0.527466 5.78271 0.527466Z" fill="#DA4453"/>
<path d="M6.91526 5.65259L8.15276 4.4151C8.23151 4.3376 8.27026 4.24135 8.27026 4.12885C8.27026 4.01635 8.23151 3.92134 8.15276 3.84259L7.58026 3.27008C7.50151 3.19258 7.40651 3.15259 7.29401 3.15259C7.18151 3.15259 7.08651 3.19133 7.00776 3.27008L5.77026 4.50757L4.53276 3.27008C4.45401 3.19258 4.35901 3.15259 4.24651 3.15259C4.13401 3.15259 4.03901 3.19133 3.96026 3.27008L3.38776 3.84259C3.30901 3.92134 3.27026 4.01635 3.27026 4.12885C3.27026 4.24135 3.30901 4.33635 3.38776 4.4151L4.62526 5.65259L3.38776 6.89008C3.30901 6.96758 3.27026 7.06383 3.27026 7.17633C3.27026 7.28883 3.30901 7.38384 3.38776 7.46259L3.96026 8.0351C4.03901 8.11385 4.13401 8.15259 4.24651 8.15259C4.35901 8.15259 4.45401 8.11385 4.53276 8.0351L5.77026 6.79761L7.00776 8.0351C7.08651 8.11385 7.18151 8.15259 7.29401 8.15259C7.40651 8.15259 7.50151 8.11385 7.58026 8.0351L8.15276 7.46259C8.23151 7.38509 8.27026 7.28883 8.27026 7.17633C8.27026 7.06383 8.23151 6.96883 8.15276 6.89008L6.91526 5.65259Z" fill="white"/>
</svg>


);

export default PasswordInValidIcon