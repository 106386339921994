import React, { useContext } from 'react';
import { CorperateThemeContext } from '../contexts/CorperateThemeContext';
import styles from '../styles/brandedCardDashboard.module.css';

interface Props {
  children: any;
  Width?: string;
}

const BrandedCardDashBoard = ({children, Width}: Props): JSX.Element => {
  const { color } = useContext(CorperateThemeContext).theme;
  const WidthValue = Width !== undefined? Width + '%' : "45%";
  return (
    <div className={styles.brandedCard} style={{width: WidthValue}}>
      {children}
      <div className={styles.colorBar} style={{ backgroundColor: color }} />
    </div>
  );
};

export default BrandedCardDashBoard;
