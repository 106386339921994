import React, { useContext, useEffect } from "react";
import styles from "../styles/navbar.module.css";
import NavLogo from "./NavLogo";
import UserControls from "./UserControls"
import SAExitButton from '../Players/Lectora/SAExitButton';
// import SaltLogo from "./SaltLogo";
import { CorperateThemeContext } from '../contexts/CorperateThemeContext'

export const TopNavbar = (props: any) => {
  const { isMultiSectionModulePlayer, moduleName, sectionCount, learningModuleData, showExitButton, handleExitProceed, handleCancel, showConfirmModal, handleModal } = props;
  const { color } = useContext(CorperateThemeContext).theme

  // useEffect(() => {
  //   let k = 0;
  // }, [showConfirmModal]);

  return (
    <>
      <div className={!!props && !!isMultiSectionModulePlayer && isMultiSectionModulePlayer ? styles.navbar_MulSecModPlayer : styles.navbar}>
        {
          !!props && !!isMultiSectionModulePlayer && isMultiSectionModulePlayer ? 
          <>
          </>
          :
          <>
            <NavLogo/>
            <UserControls/>
          </>
        }
        {
          !!props && !!isMultiSectionModulePlayer && isMultiSectionModulePlayer ? 
          <></>// <div style={{ backgroundColor: color, height:"5px", width: "100%", zIndex: 10000, padding: "2px" }} />
          :
          <div style={{ backgroundColor: color, height:"5px", width: "100%" }} />
        }
      </div>
    </>
  );
}

export default TopNavbar;
