//React + Typescript
import React, { useState, useContext, useEffect } from "react";
import { UnitComplianceReportResultItem } from "../../globalTypes";
// Libs
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { Steps, Hints } from "intro.js-react";
//Context
import { LoaderContext } from "../../contexts/LoaderContext";
import { CorperateThemeContext } from "../../contexts/CorperateThemeContext";
import { UIContext } from "../../contexts/UIContext";
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Components
import BrandedCard from "../../Components/BrandedCard";
import LoaderContainer from "../../Components/LoaderContainer";
import CustomTooltip from "../../Components/CustomTooltip";

//General Functions
import getColor from "../../functions/getColor";
import serialToArray from "../../functions/serialToArray";
import { getCurrentTimeDDMMYYYYFormat } from "../../functions/getCurrentTimeDDMMYYYYFormat";
//API functions
import { getExportedXLSXData } from "../../API/GetExportedXLSXData";
import { getExportedCSVData } from "../../API/GetExportedCSVData";
import { getExportedPDFData } from "../../API/GetExportedPDFData";
import { addError } from "../../API/AddError";
//Components
import ExcelFileIcon from "../../CustomIcons/ExcelFileIcon";
import CSVFileIcon from "../../CustomIcons/CSVFileIcon";
import PDFIcon from "../../CustomIcons/PDFFileIcon";
import UpArrowIcon from "../../CustomIcons/UpArrowIcon";
import DownArrowIcon from "../../CustomIcons/DownArrowIcon";
//MUI Components
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import CalendarViewDayIcon from "@material-ui/icons/CalendarViewDay";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import BrushIcon from "@material-ui/icons/Brush";

import ActiveIcon from "../../CustomIcons/Status_ActiveIcon";
import InActiveIcon from "../../CustomIcons/Status_InActiveIcon";
// Style
import styles from "../../styles/currentAdminReport.module.css";
import GuideIcon from "../../CustomIcons/GuideIcon";

const UnitComplianceReportResult = (props: any) => {
  const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, family_name, given_name, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
      .toLowerCase()
      .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";
  const {
    theme: { color },
  } = useContext(CorperateThemeContext);
  const { setMessage } = useContext(LoaderContext);
  const [unitComplianceReport, setUnitComplianceReportResult] = useState<
    Array<UnitComplianceReportResultItem>
  >(props?.location?.state?.resultItems ?? []);
  const [exporting, setExporting] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true);
  const [currentSortBy, setCurrentSortBy] = useState<string>("");
  const [openCriteria, setOpenCriteria] = useState(false);
  const [sortInProgress, setSortInProgress] = useState<boolean>(false);
  const [courseNameDescending, setCourseNameDescending] =
    useState<boolean>(false);
  const [moduleNameDescending, setModuleNameDescending] = useState<boolean>(false);
  const [unitDescending, setUnitDescending] = useState<boolean>(false);
  const [lessonFrequencyDescending, setLessonFrequencyDescending] = useState<boolean>(false);
  const [quizFrequencyDescending, setQuizFrequencyDescending] =
    useState<boolean>(false);
  const [courseDescending, setCourseDescending] = useState<boolean>(false);
  const [dateLessonExpiredDescending, setDateLessonExpiredDescending] = useState<boolean>(false);
  const [dateDateQuizExpiredDescending, setDateQuizExpiredDescending] = useState<boolean>(false);
  const [lessonYearlyIncrementDescending, setLessonYearlyIncrementDescending] = useState<boolean>(false);
  const [quizYearlyIncrementDescending, setQuizYearlyIncrementDescending] = useState<boolean>(false);

  const [enableOneTimeCourseComplitionDescending, setEnableOneTimeCourseComplitionDescending] = useState<boolean>(false);

  const [lessonExpiryDescending, setLessonExpiryDescending] =useState<boolean>(false);
  const [quizExpiryDescending, setQuizExpiryDescending] = useState<boolean>(false);

  const [passmarkDescending, setPassmarkDescending] = useState<boolean>(false);
  const [defaultDescending, setdefaultDescending] = useState<boolean>(false);
  const [reverseNextSort, setReverseNextSort] = useState<boolean>(false);
  const [reportRunTimeTaken, setReportRunTimeTaken] = useState<string>("");
  const { setTitle, setTitleEnglish, updateBackButtonRoute, EnableActivityLogs } = useContext(UIContext);
  const [exportError, setExportError] = useState<string>("");
  const [resultError, setResultError] = useState<string>(
    props?.location?.state?.resultError ?? ""
  );
  const [includeInactive, setIncludeInactive] = useState<boolean>(
    props?.location?.state?.includeInactive ?? false
  );
  const [selectedUnitNames, setSelectedUnitNames] = useState<string>(
    props?.location?.state?.selectedUnits ?? []
  );
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".unitComplianceReportList",
        title: "Report Data", intro: allResourcesToTranslations.emrepprvgmmrepdta
      },
    ]
  })

  useEffect(() => {
    let updateSteps = [...intro.steps];
    updateSteps.push(
      {
        element: ".xlxs",
        title: "Export", intro: allResourcesToTranslations.sntemrepgmmexpxls
      },
      {
        element: ".csv",
        title: "Export", intro: allResourcesToTranslations.sntemrepgmmexpcsv
      },
      {
        element: ".pdf",
        title: "Export", intro: allResourcesToTranslations.sntemrepgmmexppdf
      }
      // {
      //     element: ".rowShading",
      //     intro: "Click icon to Show/Hide Row Shading, also applies to Export XLSX file."
      // }           
    );
    setIntro({ ...intro, steps: [...updateSteps] });
  }, [])

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  //const toggleBanding = () => setEnableRowBanding(!enableRowBanding);

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
    } else {
      return {};
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const GetDate_yymmdd = () => {
    var d = new Date(Date.now());
    let day = d.getFullYear().toString().substring(2, 4) + (d.getMonth() + 1).toString() + d.getDate().toString()
    return day;
  }

  const buildRequestStringArray = () => {
    let resultArray: Array<string> = [];
    for (let i = 0; i < unitComplianceReport.length; i++) {
      const r = unitComplianceReport[i];      
       if (includeInactive) {
        resultArray.push(`${r.unitPathway}<#>${r.courseName}<#>${r.moduleName}<#>${r.lessonExpiry.toString()}<#>${r.quizExpiry.toString()}<#>${r.enableOneTimeCourseComplition.toString()}<#>${r.yearlyIncrementLesson.toString()}<#>${r.yearlyIncrementQuiz.toString()}<#>${r.quizPassmark.toString()}<#>${r.isUsingDefault}`);
      }
      else {
        resultArray.push(`${r.unitPathway}<#>${r.courseName}<#>${r.moduleName}<#>${r.lessonExpiry.toString()}<#>${r.quizExpiry.toString()}<#>${r.enableOneTimeCourseComplition.toString()}<#>${r.yearlyIncrementLesson.toString()}<#>${r.yearlyIncrementQuiz.toString()}<#>${r.quizPassmark.toString()}<#>${r.isUsingDefault}`);
      }
    }
    return resultArray;
  };

  const handleXLSXExport = () => {
    setMessage("Exporting XLSX");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started Unit Compliance Report XLSX-EXPORT in NEWSALT ClientApp",
      null,
      "UnitComplianceReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId), buildRequestStringArray(), 10, "UnitComplianceReport", enableRowBanding, "", "", "", "", includeInactive, false, "", "", "", "", "", "", "", false, includeInactive).then((xlxsRes) => {
      if (xlxsRes.isSuccess) {
        const fileData = xlxsRes.xlsxDataOutput.fileData
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(
          new Blob([serialToArray(atob(fileData))], {
            type: ''
          })
        );

        a.download = "UnitComplianceReport-" + GetDate_yymmdd() + ".xlsx";
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)

      }
      else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
        setExportError(xlxsRes.error);
      }
      setExporting(false);
      // Activity log
      addError(oidcUser.access_token, userId, orgDomain, "Completed Unit Compliance Report XLSX-EXPORT in NEWSALT ClientApp", null, "UnitComplianceReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const handleCSVExport = () => {
    setMessage("Exporting CSV");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started UnitCompliance Report CSV-EXPORT in NEWSALT ClientApp",
      null,
      "UnitComplianceReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId), buildRequestStringArray(), 10, "UnitComplianceReport", "", "", "", "", includeInactive, false, "", false, "", "", "", "", "", "", false, includeInactive).then((csvRes) => {
      if (csvRes.isSuccess) {
        const fileData = csvRes.csvDataOutput.fileData
        var a = window.document.createElement('a');

        a.href = window.URL.createObjectURL(
          new Blob([serialToArray(atob(fileData))], {
            type: ''
          })
        )
        a.download = "UnitComplianceReport-" + GetDate_yymmdd() + ".csv";
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)

      }
      else if (csvRes.error !== undefined && csvRes.error !== null) {
        setExportError(csvRes.error);
      }
      setExporting(false)
      // Activity log
      addError(oidcUser.access_token, userId, orgDomain, "Completed UnitCompliance Report CSV-EXPORT in NEWSALT ClientApp", null, "UnitComplianceReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const handlePDFXExport = () => {
    setMessage("Exporting PDF");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started UnitCompliance Report PDF-EXPORT in NEWSALT ClientApp",
      null,
      "UnitComplianceReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedPDFData(oidcUser.access_token, userId, parseInt(orgId), buildRequestStringArray(), 10, "UnitComplianceReport", enableRowBanding, "", "", "", "", includeInactive, false, "", "", "", "", "", "", "", false, includeInactive).then((pdfRes) => {
      if (pdfRes.isSuccess) {
        const fileData = pdfRes.pdfDataOutput.fileData
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(
          new Blob([serialToArray(atob(fileData))], {
            type: ''
          })
        )
        a.download = "UnitComplianceReport-" + GetDate_yymmdd() + ".PDF";
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)
      }
      else if (pdfRes.error !== undefined && pdfRes.error !== null) {
        setExportError(pdfRes.error);
      }
      setExporting(false)
      // Activity log
      addError(oidcUser.access_token, userId, orgDomain, "Completed UnitCompliance Report PDF-EXPORT in NEWSALT ClientApp", null, "UnitComplianceReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const sortResultsByAsync = async (
    sortBy:
      | "CourseName"
      | "ModuleName"
      | "UnitPathway"
      | "LessonFrequency"
      | "QuizFrequency"
      | "LessonExpiryDate"
      | "QuizExpiryDate"
      | "LessonExpiryYearlyIncrement"
      | "QuizExpiryYearlyIncrement"
      | "Passmark"
      | "Default"
      | "LessonExpiry"
      | "QuizExpiry"
      | "EnableOneTimeCourseComplition",
    sortGivenItemInDesc: boolean
  ) => {
    if (unitComplianceReport == null || unitComplianceReport.length <= 0) {
      return unitComplianceReport;
    }
    var result = unitComplianceReport.slice(0);
    setCurrentSortBy(sortBy);
    switch (sortBy) {
      case "UnitPathway":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.unitPathway === null) {
              return -1;
            }
            if (a === null || a.unitPathway === null) {
              return 1;
            }
            if (a.unitPathway.toLowerCase() > b.unitPathway.toLowerCase()) {
              return -1;
            }
            if (a.unitPathway.toLowerCase() < b.unitPathway.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.unitPathway === null) {
              return 1;
            }
            if (a === null || a.unitPathway === null) {
              return -1;
            }
            if (a.unitPathway.toLowerCase() > b.unitPathway.toLowerCase()) {
              return 1;
            }
            if (a.unitPathway.toLowerCase() < b.unitPathway.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitDescending(true);
          }
        }
        setUnitComplianceReportResult(result);
        break;
      case "CourseName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.courseName === null) {
              return -1;
            }
            if (a === null || a.courseName === null) {
              return 1;
            }
            if (a.courseName.toLowerCase() > b.courseName.toLowerCase()) {
              return -1;
            }
            if (a.courseName.toLowerCase() < b.courseName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setCourseNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.courseName === null) {
              return 1;
            }
            if (a === null || a.courseName === null) {
              return -1;
            }
            if (a.courseName.toLowerCase() < b.courseName.toLowerCase()) {
              return -1;
            }
            if (a.courseName.toLowerCase() > b.courseName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setCourseNameDescending(true);
          }
        }
        setUnitComplianceReportResult(result);
        break;
      case "ModuleName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.moduleName === null) {
              return -1;
            }
            if (a === null || a.moduleName === null) {
              return 1;
            }
            if (a.moduleName.toLowerCase() > b.moduleName.toLowerCase()) {
              return -1;
            }
            if (a.moduleName.toLowerCase() < b.moduleName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setModuleNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.moduleName === null) {
              return 1;
            }
            if (a === null || a.moduleName === null) {
              return -1;
            }
            if (a.moduleName.toLowerCase() < b.moduleName.toLowerCase()) {
              return -1;
            }
            if (a.moduleName.toLowerCase() > b.moduleName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setModuleNameDescending(true);
          }
        }
        setUnitComplianceReportResult(result);
        break;
      case "LessonFrequency":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.lessonFrequency === null) {
              return -1;
            }
            if (a === null || a.lessonFrequency === null) {
              return 1;
            }
            if (a.lessonFrequency > b.lessonFrequency) {
              return -1;
            }
            if (a.lessonFrequency < b.lessonFrequency) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.lessonFrequency === null) {
              return 1;
            }
            if (a === null || a.lessonFrequency === null) {
              return -1;
            }
            if (a.lessonFrequency > b.lessonFrequency) {
              return 1;
            }
            if (a.lessonFrequency < b.lessonFrequency) {
              return -1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitDescending(true);
          }
        }
        setUnitComplianceReportResult(result);
        break;
      case "QuizFrequency":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.quizFrequency === null) {
              return -1;
            }
            if (a === null || a.quizFrequency === null) {
              return 1;
            }
            if (a.quizFrequency > b.quizFrequency) {
              return -1;
            }
            if (a.quizFrequency < b.quizFrequency) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.quizFrequency === null) {
              return 1;
            }
            if (a === null || a.quizFrequency === null) {
              return -1;
            }
            if (a.quizFrequency > b.quizFrequency) {
              return 1;
            }
            if (a.quizFrequency < b.quizFrequency) {
              return -1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitDescending(true);
          }
        }
        setUnitComplianceReportResult(result);
        break;

      case "LessonExpiryDate":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.lessonExpiryDateTxt === null || b.lessonExpiryDateTxt.trim() === "") { return -1; }
            if (a === null || a.lessonExpiryDateTxt === null || a.lessonExpiryDateTxt.trim() === "") { return 1; }
            var dta = a.lessonExpiryDate; // new Date(a.dateCreated.split("/").reverse().join("-"))
            var dtb = b.lessonExpiryDate; // new Date(b.dateCreated.split("/").reverse().join("-"))
            if (dta > dtb) { return -1; }
            if (dta < dtb) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setDateLessonExpiredDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.lessonExpiryDateTxt === null || b.lessonExpiryDateTxt.trim() === "") { return 1; }
            if (a === null || a.lessonExpiryDateTxt === null || a.lessonExpiryDateTxt.trim() === "") { return -1; }
            var dta = a.lessonExpiryDate; // new Date(a.dateCreated.split("/").reverse().join("-"))
            var dtb = b.lessonExpiryDate; // new Date(b.dateCreated.split("/").reverse().join("-"))
            if (dta > dtb) { return 1; }
            if (dta < dtb) { return -1; }
            return 0;
          })
          if (reverseNextSort) {
            setDateLessonExpiredDescending(true)
          }
        }
        setUnitComplianceReportResult(result);
        break;
      case "LessonExpiryYearlyIncrement":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.yearlyIncrementLesson === null) {
              return -1;
            }
            if (a === null || a.yearlyIncrementLesson === null) {
              return 1;
            }
            if (a.yearlyIncrementLesson > b.yearlyIncrementLesson) {
              return -1;
            }
            if (a.yearlyIncrementLesson < b.yearlyIncrementLesson) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLessonFrequencyDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.yearlyIncrementLesson === null) {
              return 1;
            }
            if (a === null || a.yearlyIncrementLesson === null) {
              return -1;
            }
            if (a.yearlyIncrementLesson > b.yearlyIncrementLesson) {
              return 1;
            }
            if (a.yearlyIncrementLesson < b.yearlyIncrementLesson) {
              return -1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLessonFrequencyDescending(true);
          }
        }
        setUnitComplianceReportResult(result);
        break;
      case "QuizExpiryYearlyIncrement":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.yearlyIncrementQuiz === null) {
              return -1;
            }
            if (a === null || a.yearlyIncrementQuiz === null) {
              return 1;
            }
            if (a.yearlyIncrementQuiz > b.yearlyIncrementQuiz) {
              return -1;
            }
            if (a.yearlyIncrementQuiz < b.yearlyIncrementQuiz) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setQuizYearlyIncrementDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.yearlyIncrementQuiz === null) {
              return 1;
            }
            if (a === null || a.yearlyIncrementQuiz === null) {
              return -1;
            }
            if (a.yearlyIncrementQuiz > b.yearlyIncrementQuiz) {
              return 1;
            }
            if (a.yearlyIncrementQuiz < b.yearlyIncrementQuiz) {
              return -1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setQuizYearlyIncrementDescending(true);
          }
        }
        setUnitComplianceReportResult(result);
        break;
      case "QuizExpiryDate":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.quizExpiryDateTxt === null || b.quizExpiryDateTxt.trim() === "") { return -1; }
            if (a === null || a.quizExpiryDateTxt === null || a.quizExpiryDateTxt.trim() === "") { return 1; }
            var dta = a.quizExpiryDate; // new Date(a.quizExpiryDateTxt.split("/").reverse().join("-"))
            var dtb = b.quizExpiryDate; // new Date(b.quizExpiryDateTxt.split("/").reverse().join("-"))
            if (dta > dtb) { return -1; }
            if (dta < dtb) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setDateQuizExpiredDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.quizExpiryDateTxt === null || b.quizExpiryDateTxt.trim() === "") { return 1; }
            if (a === null || a.quizExpiryDateTxt === null || a.quizExpiryDateTxt.trim() === "") { return -1; }
            var dta = a.quizExpiryDate; // new Date(a.dateArchived.split("/").reverse().join("-"))
            var dtb = b.quizExpiryDate; // new Date(b.dateArchived.split("/").reverse().join("-"))
            if (dta > dtb) { return 1; }
            if (dta < dtb) { return -1; }
            return 0;
          })
          if (reverseNextSort) {
            setDateQuizExpiredDescending(true)
          }
        }
        setUnitComplianceReportResult(result);
        break;
      case "Passmark":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.quizPassmark === null) {
              return -1;
            }
            if (a === null || a.quizPassmark === null) {
              return 1;
            }
            if (a.quizPassmark > b.quizPassmark) {
              return -1;
            }
            if (a.quizPassmark < b.quizPassmark) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setPassmarkDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.quizPassmark === null) {
              return 1;
            }
            if (a === null || a.quizPassmark === null) {
              return -1;
            }
            if (a.quizPassmark < b.quizPassmark) {
              return -1;
            }
            if (a.quizPassmark > b.quizPassmark) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setPassmarkDescending(true);
          }
        }
        setUnitComplianceReportResult(result);
        break;
      case "Default":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.isUsingDefault === null) {
              return -1;
            }
            if (a === null || a.isUsingDefault === null) {
              return 1;
            }
            if (a.isUsingDefault.toLowerCase() > b.isUsingDefault.toLowerCase()) {
              return -1;
            }
            if (a.isUsingDefault.toLowerCase() < b.isUsingDefault.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setdefaultDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.isUsingDefault === null) {
              return 1;
            }
            if (a === null || a.isUsingDefault === null) {
              return -1;
            }
            if (a.isUsingDefault.toLowerCase() < b.isUsingDefault.toLowerCase()) {
              return -1;
            }
            if (a.isUsingDefault.toLowerCase() > b.isUsingDefault.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setdefaultDescending(true);
          }
        }
        setUnitComplianceReportResult(result);
        break;
      case "LessonExpiry":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.lessonExpiry === null || b.lessonExpiry.trim() === "") { return -1; }
            if (a === null || a.lessonExpiry === null || a.lessonExpiry.trim() === "") { return 1; }
            var dta = a.lessonExpiry; // new Date(a.dateCreated.split("/").reverse().join("-"))
            var dtb = b.lessonExpiry; // new Date(b.dateCreated.split("/").reverse().join("-"))
            if (dta > dtb) { return -1; }
            if (dta < dtb) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setLessonExpiryDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.lessonExpiry === null || b.lessonExpiry.trim() === "") { return 1; }
            if (a === null || a.lessonExpiry === null || a.lessonExpiry.trim() === "") { return -1; }
            var dta = a.lessonExpiryDate; // new Date(a.dateCreated.split("/").reverse().join("-"))
            var dtb = b.lessonExpiryDate; // new Date(b.dateCreated.split("/").reverse().join("-"))
            if (dta > dtb) { return 1; }
            if (dta < dtb) { return -1; }
            return 0;
          })
          if (reverseNextSort) {
            setLessonExpiryDescending(true)
          }
        }
        setUnitComplianceReportResult(result);
        break;
      case "QuizExpiry":
            if (sortGivenItemInDesc) {
              result.sort((a, b) => {
                if (b === null || b.quizExpiry === null || b.quizExpiry.trim() === "") { return -1; }
                if (a === null || a.quizExpiry === null || a.quizExpiry.trim() === "") { return 1; }
                var dta = a.quizExpiry; // new Date(a.dateCreated.split("/").reverse().join("-"))
                var dtb = b.quizExpiry; // new Date(b.dateCreated.split("/").reverse().join("-"))
                if (dta > dtb) { return -1; }
                if (dta < dtb) { return 1; }
                return 0;
              })
              if (reverseNextSort) {
                setQuizExpiryDescending(false)
              }
            } else {
              result.sort((a, b) => {
                if (b === null || b.quizExpiry === null || b.quizExpiry.trim() === "") { return 1; }
                if (a === null || a.quizExpiry === null || a.quizExpiry.trim() === "") { return -1; }
                var dta = a.quizExpiry; // new Date(a.dateCreated.split("/").reverse().join("-"))
                var dtb = b.quizExpiry; // new Date(b.dateCreated.split("/").reverse().join("-"))
                if (dta > dtb) { return 1; }
                if (dta < dtb) { return -1; }
                return 0;
              })
              if (reverseNextSort) {
                setQuizExpiryDescending(true)
              }
            }
            setUnitComplianceReportResult(result);
            break;
          
      case "EnableOneTimeCourseComplition":
              if (sortGivenItemInDesc) {
                result.sort((a, b) => {
                  if (b === null || b.enableOneTimeCourseComplition === null) {
                    return -1;
                  }
                  if (a === null || a.enableOneTimeCourseComplition === null) {
                    return 1;
                  }
                  if (a.enableOneTimeCourseComplition > b.enableOneTimeCourseComplition) {
                    return -1;
                  }
                  if (a.enableOneTimeCourseComplition < b.enableOneTimeCourseComplition) {
                    return 1;
                  }
                  return 0;
                });
                if (reverseNextSort) {
                  setEnableOneTimeCourseComplitionDescending(false);
                }
              } else {
                result.sort((a, b) => {
                  if (b === null || b.enableOneTimeCourseComplition === null) {
                    return 1;
                  }
                  if (a === null || a.enableOneTimeCourseComplition === null) {
                    return -1;
                  }
                  if (a.enableOneTimeCourseComplition < b.enableOneTimeCourseComplition) {
                    return -1;
                  }
                  if (a.enableOneTimeCourseComplition > b.enableOneTimeCourseComplition) {
                    return 1;
                  }
                  return 0;
                });
                if (reverseNextSort) {
                  setEnableOneTimeCourseComplitionDescending(true);
                }
              }
              setUnitComplianceReportResult(result);
              break;
            
        default:
        break;
    }
    return result.slice(0);
  };

  const sortResultsBy = async (
    sortBy:
      | "CourseName"
      | "ModuleName"
      | "UnitPathway"
      | "LessonFrequency"
      | "QuizFrequency"
      | "LessonExpiryDate"
      | "QuizExpiryDate"
      | "LessonExpiryYearlyIncrement"
      | "QuizExpiryYearlyIncrement"
      | "Passmark"
      | "Default"
      | "LessonExpiry"
      | "QuizExpiry"
      | "EnableOneTimeCourseComplition",
    sortGivenItemInDesc: boolean
  ) => {
    setSortInProgress(true);
    setExportError("");
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy);
      setUnitComplianceReportResult(result);
      setPage(0);
      setSortInProgress(false);
    });
  };

  const clickUnitAsc = () => {
    setReverseNextSort(false);
    setUnitDescending(false);
    sortResultsBy("UnitPathway", false);
  };

  const clickUnitDesc = () => {
    setReverseNextSort(false);
    setUnitDescending(true);
    sortResultsBy("UnitPathway", true);
  };

  const clickCourseNameAsc = () => {
    setReverseNextSort(false);
    setCourseNameDescending(false);
    sortResultsBy("CourseName", false);
  };

  const clickCourseNameDesc = () => {
    setReverseNextSort(false);
    setCourseNameDescending(true);
    sortResultsBy("CourseName", true);
  };

  const clickModuleNameAsc = () => {
    setReverseNextSort(false);
    setModuleNameDescending(false);
    sortResultsBy("ModuleName", false);
  };

  const clickModuleNameDesc = () => {
    setReverseNextSort(false);
    setModuleNameDescending(true);
    sortResultsBy("ModuleName", true);
  };

  const clickLessonFrequencyAsc = () => {
    setReverseNextSort(false);
    setLessonFrequencyDescending(false);
    sortResultsBy("LessonFrequency", false);
  };

  const clickLessonFrequencyDesc = () => {
    setReverseNextSort(false);
    setLessonFrequencyDescending(true);
    sortResultsBy("LessonFrequency", true);
  };

  const clickQuizFrequencyAsc = () => {
    setReverseNextSort(false);
    setQuizFrequencyDescending(false);
    sortResultsBy("QuizFrequency", false);
  };

  const clickQuizFrequencyDesc = () => {
    setReverseNextSort(false);
    setQuizFrequencyDescending(true);
    sortResultsBy("QuizFrequency", true);
  };

  const clickDateCreatedAsc = () => {
    setReverseNextSort(false);
    setDateLessonExpiredDescending(false);
    sortResultsBy("LessonExpiryDate", false);
  };

  const clickDateCreatedDesc = () => {
    setReverseNextSort(false);
    setDateLessonExpiredDescending(true);
    sortResultsBy("LessonExpiryDate", true);
  };

  const clickDateArchivedAsc = () => {
    setReverseNextSort(false);
    setDateQuizExpiredDescending(false);
    sortResultsBy("QuizExpiryDate", false);
  };

  const clickDateArchivedDesc = () => {
    setReverseNextSort(false);
    setDateQuizExpiredDescending(true);
    sortResultsBy("QuizExpiryDate", true);
  };


  const clickYearlyIncrementLessonAsc = () => {
    setReverseNextSort(false);
    setLessonYearlyIncrementDescending(false);
    sortResultsBy("LessonExpiryYearlyIncrement", false);
  };

  const clickYearlyIncrementLessonDesc = () => {
    setReverseNextSort(false);
    setLessonYearlyIncrementDescending(true);
    sortResultsBy("LessonExpiryYearlyIncrement", true);
  };

  const clickYearlyIncrementQuizAsc = () => {
    setReverseNextSort(false);
    setQuizYearlyIncrementDescending(false);
    sortResultsBy("QuizExpiryYearlyIncrement", false);
  };

  const clickYearlyIncrementQuizDesc = () => {
    setReverseNextSort(false);
    setQuizYearlyIncrementDescending(true);
    sortResultsBy("QuizExpiryYearlyIncrement", true);
  };

  const clickDefaultAsc = () => {
    setReverseNextSort(false);
    setdefaultDescending(false);
    sortResultsBy("Default", false);
  };

  const clickDefaultDesc = () => {
    setReverseNextSort(false);
    setdefaultDescending(true);
    sortResultsBy("Default", true);
  };


  const clickLessonExpiryAsc = () => {
    setReverseNextSort(false);
    setLessonExpiryDescending(false);
    sortResultsBy("LessonExpiry", false);
  };

  const clickLessonExpiryDesc = () => {
    setReverseNextSort(false);
    setLessonExpiryDescending(true);
    sortResultsBy("LessonExpiry", true);
  };

  const clickQuizExpiryAsc = () => {
    setReverseNextSort(false);
    setQuizExpiryDescending(false);
    sortResultsBy("QuizExpiry", false);
  };

  const clickQuizExpiryDesc = () => {
    setReverseNextSort(false);
    setQuizExpiryDescending(true);
    sortResultsBy("QuizExpiry", true);
  };
  const clickEnableOneTimeCourseComplitionAsc = () => {
    setReverseNextSort(false);
    setEnableOneTimeCourseComplitionDescending(false);
    sortResultsBy("EnableOneTimeCourseComplition", false);
  };

  const clickEnableOneTimeCourseComplitionDesc = () => {
    setReverseNextSort(false);
    setEnableOneTimeCourseComplitionDescending(true);
    sortResultsBy("EnableOneTimeCourseComplition", true);
  };

  const initialSort = () => {
    setCurrentSortBy("UnitPathway");
    setUnitDescending(false);
    setModuleNameDescending(false);
    setCourseNameDescending(false);
    setLessonFrequencyDescending(false);
    setDateLessonExpiredDescending(false);
    setDateQuizExpiredDescending(false);
    setPassmarkDescending(false);
    setdefaultDescending(false);
    setQuizYearlyIncrementDescending(false);
    setLessonYearlyIncrementDescending(false);
    setReportRunTimeTaken(getCurrentTimeDDMMYYYYFormat());
    setPage(0);
  };

  useEffect(() => {
    initialSort();
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Completed unitcompliance Report in NEWSALT ClientApp",
      null,
      "UnitComplianceReportResult.tsx",
      6, EnableActivityLogs
    );
  }, []);

  if (
    resultError !== null &&
    resultError !== undefined &&
    resultError.trimLeft().trimRight().length > 0
  ) {
    setTitle(allResourcesToTranslations.unitcompliancereport);
    setTitleEnglish(pageTitles.unitcompliancereport)
    updateBackButtonRoute("/unitcompliancereport");
    //setExportError("");
    return (
      <BrandedCard>
        <div className={styles.noResultsCard}>
          <div style={{ color: "red" }}>Error: {resultError}</div>
          <br />
          <div>Select the Back Button to return to Previous Page</div>
        </div>
      </BrandedCard>
    );
  } else if (unitComplianceReport === null || unitComplianceReport.length < 1) {
    setTitle(allResourcesToTranslations.unitcompliancereport);
    setTitleEnglish(pageTitles.unitcompliancereport)
    //setExportError("");
    updateBackButtonRoute("/unitComplianceReport");
    return (
      <BrandedCard>
        <div className={styles.noResultsCard}>
          <div>No Results to Display</div>
          <div>Select the Back Button to return to Previous Page</div>
        </div>
      </BrandedCard>
    );
  } else {
    setTitle(allResourcesToTranslations.unitcompliancereport);
    setTitleEnglish(pageTitles.unitcompliancereport)
    updateBackButtonRoute("/unitComplianceReport");
  }

  const GetSelectedUnitsSummary = () => {
    if (!!selectedUnitNames) {
      let arr = selectedUnitNames.split(",");
      return selectedUnitNames != "" && arr.length > 5 ? (
        <div className={styles.reportDetailContainer}>
          <span className={styles.reportDetailTitle}>
            {"Unit(s) where applicable" /* TODO Tranlation */}
          </span>
          {selectedUnitNames.split(",", 5).map((step: any) => (
            <span key={step} className={styles.reportDetail}>
              {step}
            </span>
          ))}
          <CustomTooltip
            displayText={selectedUnitNames.split(",").splice(5, arr.length)}
            placement="bottom"
          >
            <span className={styles.reportDetail}>
              {arr.length > 5 ? "+" + (arr.length - 5) + " more..." : ""}
            </span>
          </CustomTooltip>
        </div>
      ) : (
        <div className={styles.reportDetailContainer}>
          <span className={styles.reportDetailTitle}>
            {"Unit(s) where applicable" /* TODO Tranlation */}
          </span>
          {selectedUnitNames.split(",").map((step: any) => (
            <span key={step} className={styles.reportDetail}>
              {step}
            </span>
          ))}
        </div>
      );
    } else {
      return "";
    }
  };

  if (exporting) return <LoaderContainer />;

  return (
    <>
      {
        <div style={{ color: "red" }}>
          {exportError === undefined ||
            exportError === null ||
            exportError === ""
            ? ""
            : "Export Error: " + exportError}
        </div>
      }
      <hr />
      <Steps enabled={intro.stepsEnabled} steps={intro.steps} initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: 'Next',
          prevLabel: 'Back',
          tooltipClass: 'sample-class',
          showBullets: true,
        }} />
      <CustomTooltip displayText={["Guide Me"]}>
        <span style={{ right: "105px" }} onClick={() => setIntro({ ...intro, stepsEnabled: true })} className={styles.guideMeButtonSpecific}>
          <GuideIcon />
        </span>
      </CustomTooltip>
      <BrandedCard>
        <div className={styles.reportPageTitleSection}>
          <div className={styles.exportBtnContainer}>
            <div className={styles.reportContainer}>
              <div className={styles.reportDetailContainer}>
                <span className={styles.reportDetailTitle}>{"Report run by" /* TODO Tranlation */}</span>
                <span className={styles.reportDetail}> {`${given_name} ${family_name}`}</span>
              </div>
              <div className={styles.reportDetailContainer}>
                <span className={styles.reportDetailTitle}>{"Report run at" /* TODO Tranlation */}</span>
                <span className={styles.reportDetail}>
                  {reportRunTimeTaken}
                </span>
              </div>
              {GetSelectedUnitsSummary()}
              <div className={styles.reportDetailContainer}>
                <span className={styles.reportDetailTitle}>
                  {"Include Inactive Units?" /* TODO Tranlation */}
                </span>
                <span className={styles.reportDetail}>
                  {" "}
                  {includeInactive ? "Yes" : "No"}
                </span>
              </div>
            </div>

            <div className={styles.reportExportContainer}>
              <CustomTooltip displayText={["Export to XLSX"] /*TODO: Add translations */}>
                <Button className="xlxs" color="primary" onClick={handleXLSXExport} id="btnNewSaltCARReportExportXLSX">
                  <ExcelFileIcon />
                </Button>
              </CustomTooltip>
              <CustomTooltip displayText={["Export to CSV"] /*TODO: Add translations */}>
                <Button className="csv" color="primary" onClick={handleCSVExport} id="btnNewSaltCARReportExportCSV">
                  <CSVFileIcon />
                </Button>
              </CustomTooltip>
              <CustomTooltip displayText={["Export to PDF"] /*TODO: Add translations */}>
                <Button className="pdf" color="primary" onClick={handlePDFXExport} id="btnNewSaltCARReportExportPDF">
                  <PDFIcon />
                </Button>
              </CustomTooltip>
            </div>

          </div>

        </div>
      </BrandedCard>
      <div
        className={styles.paginationControlsContainer}
        style={{ justifyContent: "center" }}
      >
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 100]}
          component="div"
          count={unitComplianceReport.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      <div className={styles.tableSection}>
        <div className={styles.tableContainer}>
          <BrandedCard>
            <TableContainer>
              <Table>
                <TableHead className="unitComplianceReportList">
                  <TableRow>
                    <TableCell
                      style={{
                        color: getColor(color.substring(1)),
                        backgroundColor: color,
                        borderRadius: "8px 0 0 0",
                      }}
                      align="left"
                    >
                      <div className={styles.tableHeadCellContainer}>
                        <div
                          onClick={() => {
                            setUnitDescending(!unitDescending);
                            sortResultsBy("UnitPathway", !unitDescending);
                          }}
                        >
                          {"Unit Pathway" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "UnitPathway" &&
                                  unitDescending === false
                                  ? "100%"
                                  : "50%",
                              margin: "2px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickUnitAsc}
                          >
                            <UpArrowIcon />{" "}
                          </span>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "UnitPathway" &&
                                  unitDescending === true
                                  ? "100%"
                                  : "50%",
                              margin: "0px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickUnitDesc}
                          >
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        color: getColor(color.substring(1)),
                        backgroundColor: color,
                      }}
                      align="left"
                    >
                      <div className={styles.tableHeadCellContainer}>
                        <div
                          onClick={() => {
                            setModuleNameDescending(!moduleNameDescending);
                            sortResultsBy("ModuleName", !moduleNameDescending);
                          }}
                        >
                          {"Module Name" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "ModuleName" &&
                                  moduleNameDescending === false
                                  ? "100%"
                                  : "50%",
                              margin: "2px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickModuleNameAsc}
                          >
                            <UpArrowIcon />{" "}
                          </span>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "ModuleName" &&
                                  moduleNameDescending === true
                                  ? "100%"
                                  : "50%",
                              margin: "0px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickModuleNameDesc}
                          >
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        color: getColor(color.substring(1)),
                        backgroundColor: color,
                      }}
                      align="left"
                    >
                      <div className={styles.tableHeadCellContainer}>
                        <div
                          onClick={() => {
                            setCourseNameDescending(!courseNameDescending);
                            sortResultsBy("CourseName", !courseNameDescending);
                          }}
                        >
                          {"Course Name" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "CourseName" &&
                                  courseNameDescending === false
                                  ? "100%"
                                  : "50%",
                              margin: "2px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickCourseNameAsc}
                          >
                            <UpArrowIcon />{" "}
                          </span>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "CourseName" &&
                                  courseNameDescending === true
                                  ? "100%"
                                  : "50%",
                              margin: "0px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickCourseNameDesc}
                          >
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    {/* <TableCell
                      style={{
                        color: getColor(color.substring(1)),
                        backgroundColor: color,
                      }}
                      align="center"
                    >
                      <div className={styles.tableHeadCellContainer}>
                        <div
                          onClick={() => {
                            setLessonFrequencyDescending(!lessonFrequencyDescending);
                            sortResultsBy("LessonFrequency", !lessonFrequencyDescending);
                          }}
                        >
                          {"Lesson Frequency"}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "LessonFrequency" &&
                                  lessonFrequencyDescending === false
                                  ? "100%"
                                  : "50%",
                              margin: "2px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickLessonFrequencyAsc}
                          >
                            <UpArrowIcon />{" "}
                          </span>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "LessonFrequency" &&
                                  lessonFrequencyDescending === true
                                  ? "100%"
                                  : "50%",
                              margin: "0px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickLessonFrequencyDesc}
                          >
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        color: getColor(color.substring(1)),
                        backgroundColor: color,
                      }}
                      align="center"
                    >
                      <div className={styles.tableHeadCellContainer}>
                        <div
                          onClick={() => {
                            setQuizFrequencyDescending(!quizFrequencyDescending);
                            sortResultsBy("QuizFrequency", !quizFrequencyDescending);
                          }}
                        >
                          {"Quiz Frequency"}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "QuizFrequency" &&
                                  quizFrequencyDescending === false
                                  ? "100%"
                                  : "50%",
                              margin: "2px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickQuizFrequencyAsc}
                          >
                            <UpArrowIcon />{" "}
                          </span>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "QuizFrequency" &&
                                  quizFrequencyDescending === true
                                  ? "100%"
                                  : "50%",
                              margin: "0px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickQuizFrequencyDesc}
                          >
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        color: getColor(color.substring(1)),
                        backgroundColor: color,
                      }}
                      align="left"
                    >
                      <div className={styles.tableHeadCellContainer}>
                        <div
                          onClick={() => {
                            setDateLessonExpiredDescending(!dateLessonExpiredDescending);
                            sortResultsBy(
                              "LessonExpiryDate",
                              !dateLessonExpiredDescending
                            );
                          }}
                        >
                          {"Lesson Expiry Date"}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "LessonExpiryDate" &&
                                  dateLessonExpiredDescending === false
                                  ? "100%"
                                  : "50%",
                              margin: "2px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickDateCreatedAsc}
                          >
                            <UpArrowIcon />{" "}
                          </span>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "LessonExpiryDate" &&
                                  dateLessonExpiredDescending === true
                                  ? "100%"
                                  : "50%",
                              margin: "0px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickDateCreatedDesc}
                          >
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        color: getColor(color.substring(1)),
                        backgroundColor: color,
                      }}
                      align="left"
                    >
                      <div className={styles.tableHeadCellContainer}>
                        <div
                          onClick={() => {
                            setDateQuizExpiredDescending(!dateDateQuizExpiredDescending);
                            sortResultsBy(
                              "QuizExpiryDate",
                              !dateDateQuizExpiredDescending
                            );
                          }}
                        >
                          {"Quiz Expiry Date"}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "QuizExpiryDate" &&
                                  dateDateQuizExpiredDescending === false
                                  ? "100%"
                                  : "50%",
                              margin: "2px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickDateArchivedAsc}
                          >
                            <UpArrowIcon />{" "}
                          </span>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "QuizExpiryDate" &&
                                  dateDateQuizExpiredDescending === true
                                  ? "100%"
                                  : "50%",
                              margin: "0px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickDateArchivedDesc}
                          >
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell> */}

                    <TableCell
                      style={{
                        color: getColor(color.substring(1)),
                        backgroundColor: color,
                      }}
                      align="center"
                    >
                      <div className={styles.tableHeadCellContainer}>
                        <div
                          onClick={() => {
                            setLessonExpiryDescending(!lessonExpiryDescending);
                            sortResultsBy("LessonExpiry", !lessonExpiryDescending);
                          }}
                        >
                          {"Lesson Frequency/Date"  /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "LessonExpiry" &&
                                lessonExpiryDescending === false
                                  ? "100%"
                                  : "50%",
                              margin: "2px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickLessonExpiryAsc}
                          >
                            <UpArrowIcon />{" "}
                          </span>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "LessonExpiry" &&
                                lessonExpiryDescending === true
                                  ? "100%"
                                  : "50%",
                              margin: "0px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickLessonExpiryDesc}
                          >
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        color: getColor(color.substring(1)),
                        backgroundColor: color,
                      }}
                      align="center"
                    >
                      <div className={styles.tableHeadCellContainer}>
                        <div
                          onClick={() => {
                            setQuizFrequencyDescending(!quizExpiryDescending);
                            sortResultsBy("QuizFrequency", !quizExpiryDescending);
                          }}
                        >
                          {"Quiz Frequency/Date" }
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "QuizFrequency" &&
                                quizExpiryDescending === false
                                  ? "100%"
                                  : "50%",
                              margin: "2px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickQuizExpiryAsc}
                          >
                            <UpArrowIcon />{" "}
                          </span>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "QuizFrequency" &&
                                quizExpiryDescending === true
                                  ? "100%"
                                  : "50%",
                              margin: "0px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickQuizExpiryDesc}
                          >
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    
                    <TableCell
                      style={{
                        color: getColor(color.substring(1)),
                        backgroundColor: color,
                      }}
                      align="center"
                    >
                      <div className={styles.tableHeadCellContainer}>
                        <div
                          onClick={() => {
                            setCourseNameDescending(!enableOneTimeCourseComplitionDescending);
                            sortResultsBy("EnableOneTimeCourseComplition", !enableOneTimeCourseComplitionDescending);
                          }}
                        >
                          {"One Time Course Completion" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "EnableOneTimeCourseComplition" &&
                                enableOneTimeCourseComplitionDescending === false
                                  ? "100%"
                                  : "50%",
                              margin: "2px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickEnableOneTimeCourseComplitionAsc}
                          >
                            <UpArrowIcon />{" "}
                          </span>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "EnableOneTimeCourseComplition" &&
                                enableOneTimeCourseComplitionDescending === true
                                  ? "100%"
                                  : "50%",
                              margin: "0px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickEnableOneTimeCourseComplitionDesc}
                          >
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        color: getColor(color.substring(1)),
                        backgroundColor: color,
                      }}
                      align="center"
                    >
                      <div className={styles.tableHeadCellContainer}>
                        <div
                          onClick={() => {
                            setLessonYearlyIncrementDescending(!lessonYearlyIncrementDescending);
                            sortResultsBy(
                              "LessonExpiryYearlyIncrement",
                              !lessonYearlyIncrementDescending
                            );
                          }}
                        >
                          {"Lesson Expiry Increment" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "LessonExpiryYearlyIncrement" &&
                                  lessonYearlyIncrementDescending === false
                                  ? "100%"
                                  : "50%",
                              margin: "2px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickYearlyIncrementLessonAsc}
                          >
                            <UpArrowIcon />{" "}
                          </span>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "LessonExpiryYearlyIncrement" &&
                                  lessonYearlyIncrementDescending === true
                                  ? "100%"
                                  : "50%",
                              margin: "0px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickYearlyIncrementLessonDesc}
                          >
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        color: getColor(color.substring(1)),
                        backgroundColor: color,
                      }}
                      align="center"
                    >
                      <div className={styles.tableHeadCellContainer}>
                        <div
                          onClick={() => {
                            setQuizYearlyIncrementDescending(!quizYearlyIncrementDescending);
                            sortResultsBy(
                              "QuizExpiryYearlyIncrement",
                              !quizYearlyIncrementDescending
                            );
                          }}
                        >
                          {"Quiz Expiry Increment" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "QuizExpiryYearlyIncrement" &&
                                  quizYearlyIncrementDescending === false
                                  ? "100%"
                                  : "50%",
                              margin: "2px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickYearlyIncrementQuizAsc}
                          >
                            <UpArrowIcon />{" "}
                          </span>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "QuizExpiryYearlyIncrement" &&
                                  quizYearlyIncrementDescending === true
                                  ? "100%"
                                  : "50%",
                              margin: "0px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickYearlyIncrementQuizDesc}
                          >
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        color: getColor(color.substring(1)),
                        backgroundColor: color,
                      }}
                      align="center"
                    >
                      <div className={styles.tableHeadCellContainer}>
                        <div
                          onClick={() => {
                            setPassmarkDescending(!passmarkDescending);
                            sortResultsBy(
                              "Passmark",
                              !passmarkDescending
                            );
                          }}
                        >
                          {"Passmark %" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "Passmark" &&
                                  passmarkDescending === false
                                  ? "100%"
                                  : "50%",
                              margin: "2px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickDateArchivedAsc}
                          >
                            <UpArrowIcon />{" "}
                          </span>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "Passmark" &&
                                  passmarkDescending === true
                                  ? "100%"
                                  : "50%",
                              margin: "0px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickDateArchivedDesc}
                          >
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        color: getColor(color.substring(1)),
                        backgroundColor: color,
                        borderRadius: "0 8px 0 0",
                      }}
                      align="center"
                    >
                      <div className={styles.tableHeadCellContainer}>
                        <div
                          onClick={() => {
                            setdefaultDescending(!defaultDescending);
                            sortResultsBy(
                              "Default",
                              !defaultDescending
                            );
                          }}
                        >
                          {"Default" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "Default" &&
                                  defaultDescending === false
                                  ? "100%"
                                  : "50%",
                              margin: "2px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickDefaultAsc}
                          >
                            <UpArrowIcon />{" "}
                          </span>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "Default" &&
                                  defaultDescending === true
                                  ? "100%"
                                  : "50%",
                              margin: "0px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickDefaultDesc}
                          >
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {unitComplianceReport
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((result: UnitComplianceReportResultItem, i) => {
                      return (
                        <TableRow
                          key={`UnitComplianceReportResult-${i}`}
                          style={rowBackgroundColor(i)}
                        >
                          <TableCell align="left">
                            {result.unitPathway}
                          </TableCell>
                          <TableCell align="left">{result.moduleName}</TableCell>
                          <TableCell align="left">{result.courseName}</TableCell>
                          {/* <TableCell align="center">{(result.lessonFrequency.toString().trim() == "0" || result.lessonFrequency.toString().trim().startsWith("-")) ? "" : result.lessonFrequency.toString()}</TableCell>
                          <TableCell align="center">{(result.quizFrequency.toString().trim() == "0" || result.quizFrequency.toString().trim().startsWith("-")) ? "" : result.quizFrequency.toString()}</TableCell>
                          <TableCell align="left">
                            {result.lessonExpiryDateTxt}
                          </TableCell>
                          <TableCell align="left">
                            {result.quizExpiryDateTxt}
                          </TableCell> */}

                          <TableCell align="center">
                            {result.lessonExpiry}
                          </TableCell>
                          <TableCell align="center">
                            {result.quizExpiry}
                          </TableCell>

                          <TableCell align="center">
                              {result.enableOneTimeCourseComplition.toLowerCase() === "active" ?
                                <span>
                                <CustomTooltip displayText={["Active"]}>
                                  <ActiveIcon></ActiveIcon>
                                </CustomTooltip>&nbsp;{result.enableOneTimeCourseComplition}
                                </span>
                                :
                                <span>&nbsp;</span>                               
                              }
                          </TableCell>

                          
                          <TableCell align="center">
                            {result.yearlyIncrementLesson}
                          </TableCell>
                          <TableCell align="center">
                            {result.yearlyIncrementQuiz}
                          </TableCell>
                          <TableCell align="center">
                            {result.quizPassmark.toString()}
                          </TableCell>
                          <TableCell align="center">
                            {result.isUsingDefault}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </BrandedCard>
        </div>
      </div>
      <div
        className={styles.paginationControlsContainer}
        style={{ justifyContent: "center" }}
      >
        <TablePagination
          component="div"
          count={unitComplianceReport.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default UnitComplianceReportResult;
