//React + Typescript
import React, { useEffect, useState, useContext, useRef } from 'react'
import { CourseProgressResult, MyTrainingItemsResult } from '../../globalTypes';
//Libs
import { decode } from 'html-entities';
import { useReactOidc } from '@axa-fr/react-oidc-context';
 //Intro Lib
 import { Steps, Hints } from "intro.js-react";
// import {intro} from   "intro.js";

//Contexts
import { UIContext } from '../../contexts/UIContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { CPDPanelContext } from '../../contexts/CPDPanelContext';
import { CourseProgressPanelContext } from '../../contexts/CourseProgressContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { SessionContext } from '../../contexts/SessionContext';
import { MyTrainingListContext } from '../../contexts/MyTrainingListContext';
//API Functions
import { addError } from '../../API/AddError'
import { getOrgNotes } from '../../API/GetOrgNotes'
import { getUserCPDs } from '../../API/UserCPDs';
import { getUserCourseProgress } from '../../API/UserCourseProgress';
//import { getUserCoursesPolicies } from "../../API/UserCoursesPolicies";
import { getUserCoursesPoliciesOnlyWithProgress } from "../../API/UserCoursesPoliciesOnlyWithProgress";
import { getUserCoursesPoliciesOnlyWithCPDPoints } from '../../API/UserCoursesPoliciesOnlyWithCPDPoints';
//Components
import WelcomeModal from './WelcomeModal';
import CPDPanel from '../../Components/CPDPanel';
import SupportCard from './SupportCard';
import WelcomeMessageCard from './WelcomeMessageCard';
import SaltAdminCard from './SaltAdminCard';
import LoaderContainer from '../../Components/LoaderContainer';
import CourseCompletionPanel from '../../Components/CourseCompletionPanel';
import IntroCard from './IntroCard';
import CustomTooltip from '../../Components/CustomTooltip';
//MUI Components
import Button from '@material-ui/core/Button';
import GuideIcon from '../../CustomIcons/GuideIcon';
//Styles
import styles from '../../styles/dashboard.module.css'

import responsivestyles from '../../styles/responsiveGridLayout.module.css'
import PrivacyFeatureCard from './PrivacyFeature';

export const Dashboard = () => {
    const { translations: { allResourcesToTranslations }, currentLanguage, pageTitles} = useContext(TranslationsContext)
    const { multiSectionCurrentSelection, multiSectionSessionDetails, setMultiSectionCurrentSelection, setMultiSectionSessionDetails, setMultiSectionCurrentSelectionOnQuizOpen } = useContext(SessionContext)
    const { showCPD, setShowCPD, showWelcomeModal, setShowWelcomeModal, setTitle, setTitleEnglish,
            setMenuSelection, setShowPolicyUI, setShowCourseUI, setShowQuizUI, setShowMyCertificateUI, updateBackButtonRoute, 
            showOrgAdminUI, showSuperAdminUI, showUnitAdminUI, showQuizUI, showPolicyUI, showMyCertificateUI, 
            EnableActivityLogs, showWelcomePopup, latestMyTrainingResultForProgress, setLatestMyTrainingResultForProgress, setLatestUserCPDs, latestUserCPDs} = useContext(UIContext);
    const { cpdList, setCpdList, currentCpd, setCurrentCpd } = useContext(CPDPanelContext);
    const { setMessage } = useContext(LoaderContext);
    const { myTrainingList, setMyTrainingList, dashboardUpdateRequired, setDashboardUpdateRequired } = useContext(MyTrainingListContext)
    const {
      coursesAssigned,
      setCoursesAssigned,
      coursesCompleted,
      setCoursesCompleted,
      totalCourseProgress,
      setTotalCourseProgress,
      assignedQuizesCount,
      setAssignedQuizesCount,
      passedQuizesCount, 
      setPassedQuizesCount,
      totalQuizProgress, 
      setTotalQuizProgress,
      totalLessonProgress, 
      setTotalLessonProgress,
      totalPolicyProgress, 
      setTotalPolicyProgress,
      totalCertificateProgress,
      setTotalCertificateProgress
    } = useContext(CourseProgressPanelContext);  
    const [welcomeSrc, setWelcomeSrc] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const { oidcUser } = useReactOidc();
    const { profile: { profile, user_id, website }} = oidcUser || {};
    const orgId = profile ? parseInt(profile) : -1;
    const userId = user_id ? parseInt(user_id) : 0;
    const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";

    const [isCPDLoading, setCPDLoading] = useState<boolean>(true);

    const [intro, setIntro] = useState({
      stepsEnabled: false, 
      initialStep: 0,
      steps: [            
        {
          element: ".course",
          title: allResourcesToTranslations.coursecompletion,
          intro: allResourcesToTranslations.mydashgmmcrscomp,
        }
      ]
    })
    
    const onExit = () => {
      setIntro({...intro, stepsEnabled: false });
    };

    const handleCpdUpdate = (cpdSelection: number) => {
      setCurrentCpd(cpdSelection);
    }

    const handleOpenWelcomeModal = () => {
      addError(oidcUser.access_token, userId, orgDomain, "Opened Welcome Message pop-up", null, "Dashboard.tsx", 6, EnableActivityLogs)
      setShowWelcomeModal(true)
    }

    useEffect(() => {		    
      setGuideMe(true);
       }, [coursesAssigned,showCPD]); 
     
    const setGuideMe = (clearOldList: boolean = false) => {
      let updateSteps = clearOldList ? [] : [...intro.steps]        
      
      if(coursesAssigned > 0 )
      {
        updateSteps.push(      
          {
            element: ".course",
            title: allResourcesToTranslations.coursecompletion,
            intro: allResourcesToTranslations.mydashgmmcrscomp,
          },            
        );
      }  
      if (coursesAssigned > 0 && showQuizUI) {           
        updateSteps.push(        
          {
            element: ".quiz",
            title: allResourcesToTranslations.quizcompletion,
            intro: allResourcesToTranslations.mytraininggmmquizcomp,
          }
        );
      }
      if (showPolicyUI) {           
        updateSteps.push(        
          {
            element: ".policy",
            title: allResourcesToTranslations.policyacceptance,
            intro: allResourcesToTranslations.mytraininggmmpolicyacc,
          }
        );
      }
      if (showMyCertificateUI) {           
        updateSteps.push(        
          {
            element: ".mycertificate",
            title: allResourcesToTranslations.mydashgmtextcert,
            intro: allResourcesToTranslations.mydashgmmextcert,
          }
        );
      }            
      if(showCPD)
      {
        updateSteps.push(
          {
            element: "#CPDCard",
            title: allResourcesToTranslations.mytraininggmtcpdcard,
            intro: allResourcesToTranslations.mytraininggmmcpdcard1,
          },
          {
            element: ".profile",
            title: allResourcesToTranslations.cpdprofile,
            intro: allResourcesToTranslations.mytraininggmmcpdprof,                                
          },{
            element: ".period",
            title: allResourcesToTranslations.cpdperiod,
            intro: allResourcesToTranslations.mytraininggmmcpdprd,                                
          },{
            element: ".points",
            title: allResourcesToTranslations.mydashgmtcpdpts,
            intro: allResourcesToTranslations.mytraininggmmreqd,
          
          },{
            element: ".earnedPoints",
            title: allResourcesToTranslations.mydashgmterncpdpts,
            intro: allResourcesToTranslations.mytraininggmmearned,
          
        })
      }
      updateSteps.push( 
        {
          element: ".support",
          title: allResourcesToTranslations.mydashgmtcnt,
          intro: allResourcesToTranslations.mydashgmmcnt,
        },
        {
          element: ".welcome",
          title: allResourcesToTranslations.welcomemessage,
          intro: allResourcesToTranslations.mydashgmmwelmsg,
        },
        {
          element: ".PrivacyFeature",
          title: allResourcesToTranslations.mydashgmtprifea,
          intro: allResourcesToTranslations.mydashgmmprifea,
      });
      updateSteps.push({
        element:'.myTraining',
        title: allResourcesToTranslations.mytraining,
        intro: allResourcesToTranslations.mydashgmmmytrain,
      },
      {
        element:'.myReport',
        title: allResourcesToTranslations.myreport,
        intro: allResourcesToTranslations.mydashgmmmyrep,
      },
      {
        element: ".accountSettings",
        title: allResourcesToTranslations.accountsettings,
        intro: allResourcesToTranslations.mydashgmmaccset1 + `:
        <ul>
          <li>` + allResourcesToTranslations.mydashgmmaccset2 + `</li>
          <li>` + allResourcesToTranslations.mydashgmmaccset3 + `</li>
          <li>` + allResourcesToTranslations.mydashgmmaccset4 + `</li>
          <li>` + allResourcesToTranslations.mydashgmmaccset5 + `</li>
        </ul>`,                      
      });            
      setIntro({ ...intro, steps: [...updateSteps] });
    };  
      

    const isMounted = useRef(false)
    useEffect(() => {
        
        // Reset the multi section states in session context
        if (!!multiSectionSessionDetails && multiSectionSessionDetails.trim() != "") {
          setMultiSectionSessionDetails("")
        }
        if (!!multiSectionCurrentSelection && multiSectionCurrentSelection.trim() != "") {
          setMultiSectionCurrentSelection("")
        }
        setMultiSectionCurrentSelectionOnQuizOpen("");

        isMounted.current = true
        setTitle(allResourcesToTranslations.mydashboard)
        setTitleEnglish(pageTitles.mydashboard)
        setMenuSelection("My Dashboard")
        updateBackButtonRoute("")
        //setMessage(allResourcesToTranslations.checkingforcpds)
         
        if (dashboardUpdateRequired) {
          getOrgNotes(oidcUser.access_token, userId, orgDomain, orgId, currentLanguage ).then((data) => {
              var html = decode(data);
              html = html.replace('<a href="http://demo.saltcompliance.com/MyTraining.aspx">My Training</a>', '<a href="'+ document.location.origin + '/MyTraining">My Training</a>')
              if (isMounted.current) {                
                  setWelcomeSrc(html);
                  setShowWelcomeModal(showWelcomePopup && showWelcomeModal);
              }
              let cpdId = -1
              let cpdPrdId = -1
              if (dashboardUpdateRequired) {
                  // getUserCPDs(oidcUser.access_token, userId, orgDomain).then((cpdData) => {
                  //     if(isMounted.current){
                  //         setCpdList(cpdData)
                  //         setMessage(allResourcesToTranslations.fetchingyourprogress)
                  //     }
                  //     let cpdId = -1
                  //     let cpdPrdId = -1
                  //     cpdId = (!!cpdData && cpdData.length > 0) ? cpdData[currentCpd].id : -1
                  //     cpdPrdId = (!!cpdData && cpdData.length > 0) ? cpdData[currentCpd].periodID : -1
                  //getUserCourseProgress(oidcUser.access_token, userId, orgDomain, cpdId, !orgId? -1 : orgId).then((cProg: CourseProgressResult) => { 
                  //getUserCoursesPolicies(oidcUser.access_token, userId, orgDomain, !orgId ? -1 : orgId, cpdId, cpdPrdId).then((trainingResult : MyTrainingItemsResult | null) => {
                  getUserCoursesPoliciesOnlyWithProgress(oidcUser.access_token, userId, orgDomain, !orgId ? -1 : orgId, cpdId, cpdPrdId).then((trainingResult : MyTrainingItemsResult | null) => {
                      if(isMounted.current){
                      setLatestMyTrainingResultForProgress((trainingResult as MyTrainingItemsResult));
                      let cProg = !!trainingResult ? (trainingResult as MyTrainingItemsResult).overallProgress : null;
                      setCoursesAssigned((!!cProg !== true || cProg == null) ? 0 : cProg.assignedCoursesCount)
                      setCoursesCompleted((!!cProg !== true || cProg == null) ? 0 : cProg.completedCoursesCount)
                      setTotalCourseProgress((!!cProg !== true || cProg == null) ? 0 : cProg.totalCourseProgress)
                      setAssignedQuizesCount((!!cProg !== true || cProg == null) ? 0 : cProg.assignedQuizzesCount)
                      setPassedQuizesCount((!!cProg !== true || cProg == null) ? 0 : cProg.passedQuizzesCount)
                      setTotalQuizProgress((!!cProg !== true || cProg == null) ? 0 : cProg.totalQuizProgress)
                      setTotalLessonProgress(Math.round((!!cProg !== true || cProg == null || cProg.assignedLessonsCount <= 0) ? 0 : (cProg.completedLessonsCount / cProg.assignedLessonsCount) * 100))
                      setTotalPolicyProgress(Math.round((!!cProg !== true || cProg == null || cProg.assignedPoliciesCount <= 0) ? 0 : (cProg.acceptedPoliciesCount / cProg.assignedPoliciesCount) * 100))
                      setTotalCertificateProgress(Math.round((!!cProg !== true || cProg == null || cProg.assignedCertificateCount <= 0) ? 0 : (cProg.acceptedCertificateCount / cProg.assignedCertificateCount) * 100))
                      setGuideMe(true);
                      setLoading(false)
                          //if ((trainingResult as MyTrainingItemsResult).doesUserHaveCPD) {
                          if (cpdId !== -1 && cpdPrdId !== -1) {
                              setShowCPD(true)
                              setCpdList(null)
                              // updateSteps.splice(1,0,
                              //   {
                              //     element: "#CPDCard",
                              //     title: "CPD Card", intro: `CPD stands for Continuing Professional Development and this card lists all the assigned CPD profiles and their details`
                              //   },
                              //   {
                              //     element: ".profile",
                              //     title: "CPD Profile", intro: "If you are assigned more than one CPD Profile, a drop-down menu will appear, listing the CPD Profile titles, the displayed points will reflect the selected CPD Profile.",

                              //   },{
                              //     element: ".period",
                              //     title: "CPD Period", intro: "The CPD Period specifies the date range that CPD Points can be earned.",

                              //   },{
                              //     element: ".points",
                              //     title: "Total CPD Points", intro: "The Required Points states the total # of CPD Points that may earned during the CPD Period.",

                              //   },{
                              //     element: ".earnedPoints",
                              //     title: "Earned Points", intro: "The Points Earned value is the total count of CPD Points you have earned during the current CPD Period. Points are reset to 0 when the new CPD Period commences.",

                              //   })
                          }
                          else {
                              setShowCPD(true)
                              setCpdList(null)
                              // updateSteps.splice(1,0,
                              // {
                              //   element: "#CPDCard",
                              //   title: "CPD Card", intro: `CPD stands for Continuing Professional Development and this card lists all the assigned CPD profiles and their details`
                              // })
                          }
                      //}
                      setShowPolicyUI((!!cProg !== true || cProg == null) ? false : cProg.assignedPoliciesCount !== 0)
                      setShowCourseUI((!!cProg !== true || cProg == null) ? false : cProg.assignedCoursesCount !== 0)
                      setShowQuizUI((!!cProg !== true || cProg == null) ? false : cProg.assignedQuizzesCount !== 0)
                      setShowMyCertificateUI((!!cProg !== true || cProg == null) ? false : cProg.assignedCertificateCount !== 0)
                      if (!!trainingResult && !!trainingResult.myTrainingItems && trainingResult.myTrainingItems.length > 0) {
                        let myTrainingListCopy = [];
                        myTrainingListCopy = myTrainingList.filter(t => !!t).map(t => { t.isCPDReady = true; return t; });
                        setMyTrainingList(myTrainingListCopy)
                      }
                      setLoading(false);

                      getUserCPDs(oidcUser.access_token, userId, orgDomain).then((cpdData) => {
                        setCPDLoading(false);
                        if (isMounted.current){
                            setLatestUserCPDs(cpdData);
                            let cpdIdTmp = !!cpdData && cpdData.length > 0 ? cpdData[0].id : -1
                            let cpdPrdIdTmp = !!cpdData && cpdData.length > 0 ? cpdData[0].periodID : -1
                            setShowCPD(cpdIdTmp > 0 && cpdPrdIdTmp > 0); 
                            setCpdList(cpdData)
                           
                        }
                        cpdId = (!!cpdData && cpdData.length > 0) ? cpdData[currentCpd].id : -1
                        cpdPrdId = (!!cpdData && cpdData.length > 0) ? cpdData[currentCpd].periodID : -1
                      }).catch((e) => {
                        setCPDLoading(false);
                      });
                          setDashboardUpdateRequired(false)

                      // getUserCoursesPoliciesOnlyWithCPDPoints(oidcUser.access_token, userId, orgDomain, !orgId ? -1 : orgId, cpdId, cpdPrdId).then((trainingResult : MyTrainingItemsResult | null) => {
                      //   if(isMounted.current){

                      //   }
                      // });
                    }
                })
              }
              else {
                if (cpdList !== null) {
                  if (cpdList.length > 0) {
                    setShowCPD(true);
                  }
                }
                setLoading(false)
                setDashboardUpdateRequired(false)
                setCPDLoading(false)
              }
            //})
          })
        }
        else {
          if (!!latestMyTrainingResultForProgress) {      
            let cProg = !!latestMyTrainingResultForProgress ? (latestMyTrainingResultForProgress as MyTrainingItemsResult).overallProgress : null;      
            setShowPolicyUI((!!cProg !== true || cProg == null) ? false : cProg.assignedPoliciesCount !== 0)
            setShowCourseUI((!!cProg !== true || cProg == null) ? false : cProg.assignedCoursesCount !== 0)
            setShowQuizUI((!!cProg !== true || cProg == null) ? false : cProg.assignedQuizzesCount !== 0)
            setShowMyCertificateUI((!!cProg !== true || cProg == null) ? false : cProg.assignedCertificateCount !== 0)
          }
          if (cpdList !== null) {
            if (cpdList.length > 0) {
              setShowCPD(true);
            }
          }
          setLoading(false)
          setDashboardUpdateRequired(false)
          setCPDLoading(false)
        }

        return () => {
            isMounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    if (loading) {
      return <LoaderContainer/>
    }

    return (
        <>
         <Steps
          enabled={intro.stepsEnabled}
          steps={intro.steps}
          initialStep={intro.initialStep}
          onExit={onExit}
          options={{
            nextLabel: 'Next',
            prevLabel: 'Back', 
            tooltipClass: 'sample-class',
              showBullets: true,
            
          }}
        />
        <div className={styles.guideMeButton}>
        <CustomTooltip displayText={[allResourcesToTranslations.guideme]}>
                    {/* <Button variant="outlined" color="primary" style={{backgroundColor:'white',position:'absolute',top:'-57px',right:'0px'}} onClick={handleIntro} className={styles.guideMeButtonSpecific}>
                    <GuideIcon />
                    </Button> */}
                    <span onClick={() => setIntro({ ...intro, stepsEnabled: true })} className={styles.guideMeButtonSpecific}>
                        <GuideIcon />
                    </span>
        </CustomTooltip>       
        </div>
        <div>
            <div className={responsivestyles.rowrule} style={{ width: '100%' }}>
              <div className={ showCPD ? `${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}` :`${responsivestyles.columnrule_s_12}`}>
                <div style={{ padding:" 0 5px 5px 5px" }}>
                <CourseCompletionPanel 
                  assignedCoursesCount={coursesAssigned}
                  completedCoursesCount={coursesCompleted}
                  totalCourseProgress={totalCourseProgress}
                  assignedQuizesCount={assignedQuizesCount}
                  passedQuizesCount={passedQuizesCount}
                  totalQuizProgress={totalQuizProgress}
                  lessonCompletionProgress={totalLessonProgress}
                  policyAcceptanceProgress={totalPolicyProgress}
                  CertificateProgress={totalCertificateProgress}
                />
              </div>
            </div>
            {showCPD &&            
              <div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`} >
                <div style={{ padding:" 0 5px 5px 5px" }}>
                  <CPDPanel showCPD={showCPD} setCurrentCpd={handleCpdUpdate} currentCpd={currentCpd} cpdList={cpdList} isCPDLoading={isCPDLoading} calledFrom='mydashboard'/>
                </div>
              </div>
            }
          </div>
          <div style={{ width: "100%", paddingTop: "10px"}}>
            <div style={{ width: '100%' }} className={responsivestyles.rowrule} >
              <div className={`${responsivestyles.columnrule_4}`}>
                <div style={{ padding:" 0 5px 5px 5px" }}>
                  <SupportCard isAdmin={(showOrgAdminUI || showSuperAdminUI || showUnitAdminUI)} />
                </div>
              </div>              
              <div className={`${responsivestyles.columnrule_4}`}>
                <div style={{ padding:" 0 5px 5px 5px" }}>
                   <WelcomeMessageCard isAdmin={(showOrgAdminUI || showSuperAdminUI || showUnitAdminUI)} handleOpenWelcomeModal={handleOpenWelcomeModal} />
                </div>
              </div>              
              <div className={ `${responsivestyles.columnrule_4}`}>
                <div style={{ padding:" 0 5px 5px 5px" }}>
                  <PrivacyFeatureCard isAdmin={(showOrgAdminUI || showSuperAdminUI || showUnitAdminUI)} />
                  </div>
                </div>
            </div>
          </div>
          {/* <div   style={{width:"100%" , float: "left" }}>
                    <SaltAdminCard isAdmin={(showOrgAdminUI || showSuperAdminUI || showUnitAdminUI)}/>
                   
                </div> */}
        </div>
            <WelcomeModal showModal={showWelcomeModal} setShowModal={setShowWelcomeModal} welcomeSrc={welcomeSrc}/>
        </>
    );
}

export default Dashboard
