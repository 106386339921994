//React + Typescript
import React, { useEffect, useState } from "react";
import Modal from './Modal';

import CustomTooltip from "./CustomTooltip";

import ProceedIcon from '../CustomIcons/ProceedIcon';
import CloseIcon from '../CustomIcons/CloseIcon';

import {    
  SelectedSettings,
} from "../globalTypes";

//Styles
import styles from "../styles/currentAdminReport.module.css";

// This is a new reusable pop-up component which can be used for showing any given list of settings information or 
//      any given error messages or any given warning messages to the user 
//      as a pop-up on top of any page upon clicking a button or navigating.
// This pop-up can be made as either modal or modaless. 
// This also can be made to be with or without the right top close indication for basic closing.
// This has two types of messages - one type is array of settings objects and 
// other type is just a simple string array for error/warning/info messages.
// But we can have only one type at a time either error or info (info & warning & settings are treated same)
// But we need the givenMessages always even when the setting list is given as simple messages are used as the main title info
// Close/Cancel button is shown at bottom to choose for not continuing the workflow but sets the pop-up to hide
// Proceed button is shown at bottom to choose for a continuation of the workflow (and pop-up to hide) 
// But whenever the isError flag is given as true, then the proceed button is not shown
// This component has all the callbacks to change the states of this component from outside as well
// If settings info array is given, then the sub-array values of each settings has the expander or collapser
//      attached to it based on the subvalues in each settings so that we can either view less info or all info of that specific settings
// If any specific settings info needs bullet formatting for its sub-items, 
//      then pass its title in the array 'givenSubBulletRequiredSettingTitles'
// If any given info contains '???', it is highlighted with red. 
//      To avail this highlight, pass true for isInfoErrorHighlightRequired
// The given flag givenFlagToHideTopRightCloseBtn decides to display the top-right close button or not

interface Props {
    isError: boolean; 
    givenPopUpHeading: string;
    givenShowPopUp: boolean;
    givenSettingsList: any;
    givenMessages: any;
    givenFlagToHideTopRightCloseBtn: boolean;
    onHandleShowPopUp: any;
    onPopUpClose: any;
    onPopUpProceed: any;
    isInfoErrorHighlightRequired: boolean;
    givenSubBulletRequiredSettingTitles: any
  }
  
  const InfoOrErrorPopUp = ({
    isError, 
    givenPopUpHeading, 
    givenShowPopUp, 
    givenSettingsList,
    givenMessages,
    givenFlagToHideTopRightCloseBtn,
    onHandleShowPopUp,
    onPopUpClose,
    onPopUpProceed,
    isInfoErrorHighlightRequired,
    givenSubBulletRequiredSettingTitles
  }: Props) => {

    const [simpleMessages, setSimpleMessages] = 
        useState<Array<string>>((typeof givenMessages !== 'object' && !Array.isArray(givenMessages)) ? 
                ["Do you want to proceed without saving changes?"] :
                givenMessages); // Default for saving purposes

    const [subBulletRequiredSettingTitles, setSubBulletRequiredSettingTitles] = 
        useState<Array<string>>((typeof givenSubBulletRequiredSettingTitles !== 'object' && !Array.isArray(givenSubBulletRequiredSettingTitles)) ? 
                null :
                givenSubBulletRequiredSettingTitles); // Default for saving purposes

    const [settingsList, setSettingsList] = 
        React.useState<Array<SelectedSettings> | null>((typeof givenSettingsList !== 'object' && !Array.isArray(givenSettingsList)) ? 
            null :
            givenSettingsList);

    const clickShowAll = (sectionTitle: string) => {
        if (settingsList !== null && 
            settingsList !== undefined && 
            settingsList.length > 0){
            var newModalPopUpSettingsMessages = 
                settingsList.map(ms => setAllAsDisplayRowCount(ms, sectionTitle));
            setSettingsList(newModalPopUpSettingsMessages);
        }
    }

    const clickShowLess = (sectionTitle: string) => {
        if (settingsList !== null && 
            settingsList !== undefined && 
            settingsList.length > 0){
            var newModalPopUpSettingsMessages = 
                settingsList.map(ms => resetDisplayRowCount(ms, sectionTitle));
            setSettingsList(newModalPopUpSettingsMessages);
        }
    }
  
    const setAllAsDisplayRowCount = (given: SelectedSettings, title: string) => {
      if (given !== null && given !== undefined && title !== null && title !== undefined && 
            title.trimLeft().trimRight().toLowerCase() !== "" && 
            given.title.trimLeft().trimRight().toLowerCase().startsWith(title.trimLeft().trimRight().toLowerCase())) {
            if (given.rowDisplayValues !== null && given.rowDisplayValues !== undefined) {
                given.currentDisplayRowCount = given.rowDisplayValues.length;
            }
      }
      return given;
    }
  
    const resetDisplayRowCount = (given: SelectedSettings, title: string) => {
      if (given !== null && given !== undefined && title !== null && title !== undefined && 
            title.trimLeft().trimRight().toLowerCase() !== "" && 
            given.title.trimLeft().trimRight().toLowerCase().startsWith(title.trimLeft().trimRight().toLowerCase())) {
            given.currentDisplayRowCount = 3;
      }
      return given;
    }
    const [isErrorOrWarning, setIsErrorOrWarning] = useState<string>("warning");
    useEffect(() => {
       setSimpleMessages(givenMessages);
       setSubBulletRequiredSettingTitles(givenSubBulletRequiredSettingTitles);
       setSettingsList(givenSettingsList);

       if(isError) {
        setIsErrorOrWarning("error");
       } else {
        setIsErrorOrWarning("warning");
       }
    },[givenMessages, givenSubBulletRequiredSettingTitles, givenSettingsList, isError])

    return ( 
        <Modal
            key="commonModalPopup"
            type={isErrorOrWarning}
            modalOpen={givenShowPopUp}
            setModalOpen={onHandleShowPopUp}
            style={{ height: "232px", width: "381px", display: "flex"}}
            hideCloseBtn={givenFlagToHideTopRightCloseBtn}
            
        >
            <div>
            {
                isError ? <h2 style={{ color: "#ff0000", width: "500px" }}>{givenPopUpHeading} </h2> : <h2 style={{ color: "#e7a652", width: "500px" }}>{givenPopUpHeading}</h2>
            }
            </div>
            {
            (!!settingsList === false || settingsList === null ||
                settingsList === undefined ||
                settingsList.length <= 0) ? (
                <p>
                    {simpleMessages.map((e) => (
                        <>
                        <span style={{fontSize: "16px"}}>{e}</span>
                        </>
                    ))} 
                </p>
                ) : 
                ( 
                    <p>
                    {simpleMessages.map((e) => (
                        <>
                        <div style={{fontSize: "16px"}}>{e}</div>
                        <div>
                        <div className={styles.popupsplitleft}>
                        { 
                            !!settingsList && 
                            settingsList.
                            filter(ssm => settingsList.indexOf(ssm) >= 0 && (settingsList.indexOf(ssm) % 2) === 0).
                            map((eee) => (
                            <>
                                <div style={{ margin: "10px" }}>
                                <div style={{fontSize: "14px", fontWeight: "bold"}}>{eee.title}</div>
                                {
                                    eee.rowDisplayValues && 
                                    eee.rowDisplayValues.
                                    filter((rd, index) => index < eee.currentDisplayRowCount).
                                    map((ee) => (
                                        <>
                                        <div style={{fontSize: "14px", color: "#21394F"}}>
                                            {
                                                (isInfoErrorHighlightRequired && ee !== null && 
                                                    ee !== undefined && ee.indexOf("???") >= 0) ?
                                                    ee.split("???").map(
                                                        (ees) => (
                                                        (ees.trimLeft().trimRight() === "" ? 
                                                        <>{<span style={{color:"red", fontWeight: "bold"}}> ??? </span>}</>
                                                        : 
                                                        <>{ees}{((ee.split("???").indexOf(ees) !== (ee.split("???").length-1)) ? 
                                                        <span style={{color:"red", fontWeight: "bold"}}> ??? </span> : "")}</>)
                                                        )
                                                )
                                                :
                                                ((!!subBulletRequiredSettingTitles && 
                                                    subBulletRequiredSettingTitles.some(
                                                        cbe => !!cbe && eee.title.toLowerCase().startsWith(
                                                                            cbe.toString().toLowerCase()))) ?
                                                <>
                                                    <table style={{width:"100%"}}>
                                                    <tr>
                                                        <td style={{width:"1%"}}>•</td>
                                                        <td style={{width:"95%", textAlign: "left"}}>{ee}</td>
                                                    </tr>
                                                    </table> 
                                                </>
                                                : ee)
                                            }
                                        </div>
                                        </>
                                    ))
                                }
                                {                                  
                                    (eee.rowDisplayValues.length > eee.currentDisplayRowCount) 
                                    ? 
                                    <>
                                        <div style={{fontSize: "14px"}}>
                                            <span onClick={() => clickShowAll(eee.title)} 
                                                  style={{fontSize: "14px", color: "blue"}}>+ Show All...</span>
                                        </div>
                                    </>
                                    :
                                    <></>
                                }
                                {                                  
                                    (eee.rowDisplayValues.length > 3 && eee.currentDisplayRowCount > 3) 
                                    ? 
                                    <>
                                        <div style={{fontSize: "14px"}}>
                                            <span onClick={() => clickShowLess(eee.title)} 
                                                style={{fontSize: "14px", color: "blue"}}>- Show only 3...</span>
                                        </div>
                                    </>
                                    :
                                    <></>
                                }
                                </div>
                                <br/>
                            </>
                            ))
                        }
                        </div>
                        <div className={styles.popupsplitright}>
                        { 
                            !!settingsList && 
                            settingsList.
                            filter(ssm => settingsList.indexOf(ssm) >= 0 && (settingsList.indexOf(ssm) % 2) !== 0).
                            map((eee) => (
                            <>
                                <div style={{ margin: "10px" }}>
                                <div style={{fontSize: "14px", fontWeight: "bold"}}>{eee.title}</div>
                                {
                                    eee.rowDisplayValues && 
                                    eee.rowDisplayValues.
                                    filter((rd, index) => index < eee.currentDisplayRowCount).
                                        map((ee) => (
                                            <>
                                                <div style={{fontSize: "14px", color: "#21394F"}}>
                                                {
                                                    (isInfoErrorHighlightRequired && ee !== null && 
                                                        ee !== undefined && ee.indexOf("???") >= 0) ?
                                                        ee.split("???").map(
                                                            (ees) => (
                                                            (ees.trimLeft().trimRight() === "" ? 
                                                            <>{<span style={{color:"red", fontWeight: "bold"}}> ??? </span>}</>
                                                            : 
                                                            <>{ees}{((ee.split("???").indexOf(ees) !== (ee.split("???").length-1)) ? 
                                                            <span style={{color:"red", fontWeight: "bold"}}> ??? </span> : "")}</>)
                                                            )
                                                    )
                                                    :
                                                    ( (!!subBulletRequiredSettingTitles && 
                                                        subBulletRequiredSettingTitles.some(
                                                            cbe => !!cbe && eee.title.toLowerCase().startsWith(
                                                                                cbe.toString().toLowerCase()))) ?
                                                    <>
                                                        <table style={{width:"100%"}}>
                                                        <tr>
                                                            <td style={{width:"1%"}}>•</td>
                                                            <td style={{width:"95%", textAlign: "left"}}>{ee}</td>
                                                        </tr>
                                                        </table> 
                                                    </>
                                                    : ee)
                                                }
                                                </div>
                                            </>
                                        ))
                                }
                                {                                  
                                    (eee.rowDisplayValues.length > eee.currentDisplayRowCount) 
                                    ? 
                                    <>
                                        <div style={{fontSize: "14px"}}>
                                            <span onClick={() => clickShowAll(eee.title)} 
                                                style={{fontSize: "14px", color: "blue"}}>+ Show All...</span>
                                        </div>
                                    </>
                                    :
                                    <></>
                                }
                                {                                  
                                    (eee.rowDisplayValues.length > 3 && eee.currentDisplayRowCount > 3) 
                                    ? 
                                    <>
                                        <div style={{fontSize: "14px"}}>
                                            <span onClick={() => clickShowLess(eee.title)} 
                                                  style={{fontSize: "14px", color: "blue"}}>- Show only 3...</span>
                                        </div>
                                    </>
                                    :
                                    <></>
                                }
                                </div>
                                <br/>
                            </>
                            ))
                        }
                        </div>
                        </div>
                        </>
                    ))}
                    </p>
                )
            }
            <div style={{ display: "flex", justifyContent: "center", top: "10px", }}>
                <CustomTooltip displayText={["Return"]} hidden={isError ? true : false}>
                    <span className={styles.returnIcon}>
                    <span
                        onClick={onPopUpClose}
                        >
                        <CloseIcon />
                    </span>
                    </span>
                </CustomTooltip>
                <CustomTooltip displayText={["Proceed"]} hidden={isError ? true : false}>
                    <span className={styles.proceedIcon}>
                    <span
                        onClick={onPopUpProceed}
                        >
                        <ProceedIcon />
                    </span>
                    </span>
                </CustomTooltip>
            </div>
        </Modal> 
    )
}

export default InfoOrErrorPopUp;