import React from 'react'
import Modal from '../Components/Modal'
import packageJson from '../../package.json'
import styles from '../styles/releaseNotesModal.module.css'
import SaltLogo from '../Components/SaltLogo'

interface Props {
    modalOpen: boolean;
    setModalOpen: (openModal: boolean) => void
}

const ReleaseNotesModal = ({modalOpen, setModalOpen}: Props) => {
    return (
        <Modal modalOpen={modalOpen} setModalOpen={setModalOpen}>
            <div className={styles.container}>
                <div className={styles.logoContainer}>
                    <SaltLogo/>
                </div>
                <span>
                    Client version: {packageJson.version} | API version: {packageJson.serverVersion}
                </span>
                <hr></hr>
                <div className={styles.contentContainer}>
                <ul className={styles.releaseNoteContainer}>
                    <div style={{marginLeft: "-20px"}}><li className={styles.listTitle}>Release Notes</li></div>
                    {
                         packageJson.releaseNotes.map((rn, i) => (
                            (rn !== null &&
                                rn.length > 1) ? ( 
                                    <li key={`note-${i}`}>{rn[0]}
                                     <ul className={styles.subreleaseNoteContainer}>
                                        {
                                        rn.map((b, i) => (
                                            <li key={`note2-${i}`} hidden={i == 0}>{b}</li>
                                        ))
                                        }
                                     </ul>
                                    </li>
                                )
                                 :
                                  <li key={`note-${i}`}>{rn[0]}</li>
                         ))
                    }
                </ul>
                <ul className={styles.releaseNoteContainer}>
                    <div style={{marginLeft: "-20px"}}><li className={styles.listTitle}>Supported Browsers</li></div>
                    {
                        packageJson.supportedBrowsers.map((sb, i) => (
                            <li key={`support-${i}`}>{sb}</li>
                        ))
                    }
                </ul>
                <a href="https://content.saltcompliance.com/contentfiles/Salt/SaltReleaseNotes.pdf" target="_blank">View full release history</a>
                </div>
            </div>
        </Modal>
    )
}

export default ReleaseNotesModal
