//React + Typescript
import React, { useEffect, useState, useContext, useRef } from 'react';
import { CourseProgressResult, MyTrainingItemsResult, MyTrainingItem, CPDPointsItem } from '../../globalTypes';
//Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Intro Lib
import { Steps, Hints } from "intro.js-react";
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { SessionContext } from '../../contexts/SessionContext';
import { CPDPanelContext } from '../../contexts/CPDPanelContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { MyTrainingListContext } from '../../contexts/MyTrainingListContext';
import { CourseProgressPanelContext } from '../../contexts/CourseProgressContext';
//API Fucntions
import { addError } from '../../API/AddError'
import { getUserCPDs } from '../../API/UserCPDs';
//import { getMyTrainingList } from '../../API/UserTrainingList';
import { getUserCoursesPolicies } from '../../API/UserCoursesPolicies';
import { getUserCoursesPoliciesOnlyWithProgress } from '../../API/UserCoursesPoliciesOnlyWithProgress';
import { getUserCoursesPoliciesOnlyWithCPDPoints } from '../../API/UserCoursesPoliciesOnlyWithCPDPoints';
import { getUserCourseProgress } from '../../API/UserCourseProgress';
//Components
import MyTrainingList from './MyTrainingList';
import MyTrainingFilterButtons from './MyTrainingFilterButtons';
import CPDPanel from '../../Components/CPDPanel';
import LoaderContainer from '../../Components/LoaderContainer';
import CourseCompletionPanel from '../../Components/CourseCompletionPanel';
import CustomTooltip from '../../Components/CustomTooltip';
//MUI Components
import Button from '@material-ui/core/Button';
import GuideIcon from '../../CustomIcons/GuideIcon';
//Styles
import styles from '../../styles/myTraining.module.css'

import responsivestyles from "../../styles/responsiveGridLayout.module.css";

export const MyTraining = (): JSX.Element => {
  const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
  const [loading, setLoading] = useState<boolean>(true);
  const { cpdList, setCpdList, currentCpd, setCurrentCpd, isCPDLoaded, setCPDLoaded } = useContext(CPDPanelContext);
  const { setMessage } = useContext(LoaderContext);
  const {
    coursesAssigned,
    setCoursesAssigned,
    coursesCompleted,
    setCoursesCompleted,
    totalCourseProgress,
    setTotalCourseProgress,
    assignedQuizesCount,
    setAssignedQuizesCount,
    passedQuizesCount, 
    setPassedQuizesCount,
    totalQuizProgress, 
    setTotalQuizProgress,
    totalLessonProgress, 
    setTotalLessonProgress,
    totalPolicyProgress, 
    setTotalPolicyProgress,
    totalCertificateProgress,
    setTotalCertificateProgress
  } = useContext(CourseProgressPanelContext);  
  const { setCurSessionID, multiSectionSessionDetails, multiSectionCurrentSelection, setMultiSectionCurrentSelection, setMultiSectionSessionDetails, setMultiSectionCurrentSelectionOnQuizOpen } = useContext(SessionContext);
  const { showCPD, setShowCPD, setTitle, setMenuSelection, setShowCourseUI, setTitleEnglish,showMyCertificateUI,
    setShowPolicyUI,setShowMyCertificateUI, setShowQuizUI, updateBackButtonRoute, checkScrollReminder, setShowScrollArrow, 
    myTrainingSelection, setMyTrainingSelection, showPolicyUI, showCourseUI, showQuizUI, 
    showLastPassDate, EnableActivityLogs, setLatestMyTrainingResultForProgress, latestMyTrainingResultForProgress, latestUserCPDs, setLatestUserCPDs} = useContext(UIContext)
  const { myTrainingList, setMyTrainingList, trainingListUpdateRequired, setTrainingListUpdateRequired } = useContext(MyTrainingListContext)
  const { oidcUser } = useReactOidc();
  const orgId = oidcUser?.profile.profile ? parseInt(oidcUser.profile.profile) : -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0;
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";

  const [isPageCPDEvaluated, setPageCPDEvaluated] = useState<boolean>(false);

  const [isCPDLoading, setCPDLoading] = useState<boolean>(true);

  const [intro, setIntro] = useState({
    stepsEnabled: false, 
    initialStep: 0,
    steps: [            
      {
        element: "#filterButtonsGroup",
        title: allResourcesToTranslations.filter, 
        intro: allResourcesToTranslations.mytraininggmmfilter,
      }
    ]
  })
  useEffect(() => {		    
    setGuideMe(true);
     }, [coursesAssigned,showCPD]); 
  const setGuideMe = (clearOldList: boolean = false) => {
    let updateSteps = clearOldList ? [] : [...intro.steps]   
    if (showPolicyUI && showCourseUI)
      { 
          updateSteps.push(
          {          
              element: "#filterButtonsGroup",
              title: allResourcesToTranslations.filter, 
              intro: allResourcesToTranslations.mytraininggmmfilter,
          });
    }
    updateSteps.push(
    {
      element: ".list",
      title: allResourcesToTranslations.mytraininggmtassignedcourses,
      intro: allResourcesToTranslations.mytraininggmmassignedcourses,
    },      
    {
      element: ".status",
      title: allResourcesToTranslations.mytraininggmtcoursestatuses, 
      intro:  `<ul>
      <li>` + allResourcesToTranslations.mytraininggmmcoursestatuses1 + `</li>
      <li>` + allResourcesToTranslations.mytraininggmmcoursestatuses2 + `</li>
      </ul>`, 
    },
    {
      element: ".action",
      title: allResourcesToTranslations.start, 
      intro: allResourcesToTranslations.mytraininggmmaction,
    });
    if (showLastPassDate) {    
      updateSteps.push(
      {
        element: ".lastCompletionDate",
        title: allResourcesToTranslations.lastcompletiondate, 
        intro: allResourcesToTranslations.mytraininggmmlastcompdt,
      },
      {
        element: ".courseDueDate",
        title: allResourcesToTranslations.duedate, 
        intro: allResourcesToTranslations.mytraininggmmduedate,
      });     
    }  
    if(coursesAssigned > 0 )
    {
      updateSteps.push(      
        {
          element: ".course",
          title: allResourcesToTranslations.coursecompletion,
          intro: allResourcesToTranslations.mydashgmmcrscomp,
        },            
      );
    }  
    if (coursesAssigned > 0 && showQuizUI) {           
      updateSteps.push(        
        {
          element: ".quiz",
          title: allResourcesToTranslations.quizcompletion,
          intro: allResourcesToTranslations.mytraininggmmquizcomp,
        }
      );
    }
    if (showPolicyUI) {           
      updateSteps.push(        
        {
          element: ".policy",
          title: allResourcesToTranslations.policyacceptance,
          intro: allResourcesToTranslations.mytraininggmmpolicyacc,
        }
      );
    }
    if (showMyCertificateUI) {           
      updateSteps.push(        
        {
          element: ".mycertificate",
          title: allResourcesToTranslations.mydashgmtextcert,
          intro: allResourcesToTranslations.mydashgmmextcert,
        }
      );
    }    
    let elem = document.getElementById("CPDPointsColumn");        
    if(showCPD || !!elem)
    {
        updateSteps.push(
        {
            element: "#CPDPointsColumn",
            title: allResourcesToTranslations.mytrainingcpdpointsgmt,
            intro: allResourcesToTranslations.mytrainingcpdpointsgmm,
        },
        {
            element: "#CPDCard",
            title: allResourcesToTranslations.mytraininggmtcpdcard,
            intro: allResourcesToTranslations.mytraininggmmcpdcard1,
        },
        {
          element: ".profile",
          title: allResourcesToTranslations.cpdprofile,
          intro: allResourcesToTranslations.mytraininggmmcpdprof,                                
        },{
          element: ".period",
          title: allResourcesToTranslations.cpdperiod,
          intro: allResourcesToTranslations.mytraininggmmcpdprd,                                
        },{
          element: ".points",
          title: allResourcesToTranslations.mydashgmtcpdpts,
          intro: allResourcesToTranslations.mytraininggmmreqd,
        
        },{
          element: ".earnedPoints",
          title: allResourcesToTranslations.mydashgmterncpdpts,
          intro: allResourcesToTranslations.mytraininggmmearned,        
      })
    }            
    setIntro({ ...intro, steps: [...updateSteps] });
  };  

  const onExit = () => {
    setIntro({...intro, stepsEnabled: false });
  };

  const logNavigation = (message: string) => {
    addError(oidcUser.access_token, userId, orgDomain, message, null, "MyTraining.tsx", 6, EnableActivityLogs);
  };

  const handleCpdUpdate = (cpdSelection: number) => {
    setCPDLoading(true)
    setCurrentCpd(!!cpdSelection ? parseInt(cpdSelection.toString()) : -1);
    //getUserCoursesPoliciesOnlyWithProgress(oidcUser.access_token, userId, orgDomain, orgId, 
    getUserCoursesPolicies(oidcUser.access_token, userId, orgDomain, orgId, 
                            ((cpdList === null || cpdList.length <= 0) ? -1 : cpdList[cpdSelection].id), 
                            ((cpdList === null || cpdList.length <= 0) ? -1 : cpdList[cpdSelection].periodID)).
                            then((trainingResult : MyTrainingItemsResult | null) => {
      if (!!trainingResult && !!(trainingResult as MyTrainingItemsResult).myTrainingItems) {
        let myTrainingListItemsUpdated: Array<MyTrainingItem> = [];
        if (!!trainingResult && !!trainingResult.myTrainingItems){
          trainingResult.myTrainingItems.forEach(fcp => {
            const mappedStatus = (fcp.type != null && fcp.type.toLowerCase() == "policy") ? (fcp.status === 1 ? 5 : 4) :  (fcp.status);
            // Policy and Course have different Enums.
            // So we are mapping this to the Traiing list items StatusEnum in the course list
            // See Enum in comments above
            const newItem: MyTrainingItem = {
                id: fcp.id,
                type: fcp.type,
                name: fcp.name,
                notes: fcp.notes,
                status: mappedStatus,
                pointsAvailable: fcp.pointsAvailable,
                pointsCalculated: fcp.pointsCalculated,
                isCPDReady: fcp.isCPDReady,
                policyFileName: fcp.policyFileName,
                policyConfirmationMessage: fcp.policyConfirmationMessage,
                lastCompletionDate: fcp.lastCompletionDate,
                lastCompletionDateInDate: fcp.lastCompletionDateInDate,
                coursePublishType: fcp.coursePublishType,                    
                oneTimeCourseCompletion: (fcp.type != null && fcp.type.toLowerCase() == "policy") ? true : fcp.oneTimeCourseCompletion,

                due: fcp.due,
                courseID: fcp.courseID,
                description: fcp.description,
                dueInDays: fcp.dueInDays,
                eBookID: fcp.eBookID,
                isColorRed: fcp.isColorRed,

                modulesWithPoints: fcp.modulesWithPoints
            }
            myTrainingListItemsUpdated.push(newItem)
          });
          trainingResult.myTrainingItems = myTrainingListItemsUpdated;
        }
        setMyTrainingList(trainingResult.myTrainingItems)
        setCPDLoading(false)
      }
    })
  }

  const handleMyTrainingSelection = (selection: string) => {
    logNavigation(`Filtered course list by ${selection}`)
    setMyTrainingSelection(selection)
  }

  const isMounted = useRef<boolean>(false) 
  useEffect(() => {

    // Reset the multi section states in session context
    if (!!multiSectionSessionDetails && multiSectionSessionDetails.trim() != "") {
      setMultiSectionSessionDetails("")
    }
    if (!!multiSectionCurrentSelection && multiSectionCurrentSelection.trim() != "") {
      setMultiSectionCurrentSelection("")
    }
    setMultiSectionCurrentSelectionOnQuizOpen("");

    isMounted.current = true;
    setTitle(allResourcesToTranslations.mytraining)
    setTitleEnglish(pageTitles.mytraining)
    setMenuSelection("My Training")
    //setMessage(allResourcesToTranslations.checkingforcpds)
    setShowCPD(false);
    //setShowCPD(true);
    //setCpdList(null);
    updateBackButtonRoute("")
    setCurSessionID("") //Resetting session to nothing as the session should be complete at this point
    let cpdId = -1;
    let cpdPrdId = -1;
    let currentMyTrainingList = myTrainingList;
      if (trainingListUpdateRequired) {
          // getUserCPDs(oidcUser.access_token, userId, orgDomain).then((cpdData) => {
          //   if(isMounted.current){
          //     setCpdList(cpdData)
          //     setMessage(allResourcesToTranslations.fetchingyourtrainingplan)
          //   }

          //   cpdId = !!cpdData && cpdData.length > 0 ? cpdData[currentCpd].id : -1
          //   cpdPrdId = !!cpdData && cpdData.length > 0 ? cpdData[currentCpd].periodID : -1
          //getUserCoursesPolicies(oidcUser.access_token, userId, orgDomain, orgId, cpdId, cpdPrdId).then((trainingResult : MyTrainingItemsResult) => {
          getUserCoursesPoliciesOnlyWithProgress(oidcUser.access_token, userId, orgDomain, orgId, cpdId, cpdPrdId).then((trainingResult : MyTrainingItemsResult) => {
              setLatestMyTrainingResultForProgress((trainingResult as MyTrainingItemsResult));
              //setPageCPDEvaluated(true);
              //if (!!trainingResult && (trainingResult as MyTrainingItemsResult).doesUserHaveCPD) {
              setShowCPD(true);
              setCpdList(null);
              //}
              //else {
              //  setShowCPD(false);
              //  setCpdList([]);
              //}
              let myTrainingListItemsUpdated: Array<MyTrainingItem> = [];
          if (!!trainingResult && !!trainingResult.myTrainingItems){
            trainingResult.myTrainingItems.forEach(fcp => {
              const mappedStatus = (fcp.type != null && fcp.type.toLowerCase() == "policy") ? (fcp.status === 1 ? 5 : 4) :  (fcp.status);
              // Policy and Course have different Enums.
              // So we are mapping this to the Traiing list items StatusEnum in the course list
              // See Enum in comments above
              const newItem: MyTrainingItem = {
                  id: fcp.id,
                  type: fcp.type,
                  name: fcp.name,
                  notes: fcp.notes,
                  status: mappedStatus,
                  pointsAvailable: fcp.pointsAvailable,
                  pointsCalculated: fcp.pointsCalculated,
                  isCPDReady: fcp.isCPDReady,
                  policyFileName: fcp.policyFileName,
                  policyConfirmationMessage: fcp.policyConfirmationMessage,
                  lastCompletionDate: fcp.lastCompletionDate,
                  lastCompletionDateInDate: fcp.lastCompletionDateInDate,
                  coursePublishType: fcp.coursePublishType,                    
                  oneTimeCourseCompletion: (fcp.type != null && fcp.type.toLowerCase() == "policy") ? true : fcp.oneTimeCourseCompletion,

                  due: fcp.due,
                  courseID: fcp.courseID,
                  description: fcp.description,
                  dueInDays: fcp.dueInDays,
                  eBookID: fcp.eBookID,
                  isColorRed: fcp.isColorRed,

                  modulesWithPoints: fcp.modulesWithPoints
              }
              myTrainingListItemsUpdated.push(newItem)
            });
            trainingResult.myTrainingItems = myTrainingListItemsUpdated;
          }
          else {
            setShowCPD(false);
            setCpdList([]);
          }
          if(isMounted.current){
            setMessage(allResourcesToTranslations.fetchingyourprogress)
          }
          return trainingResult //State update takes a few milisecconds and breaks the loader so we do this at the end
        }).then((tld : MyTrainingItemsResult | null) => { 
          let moduleIDs : Array<number> = [];
          let policyIDs : Array<number> = [];
          //getUserCourseProgress(oidcUser.access_token, userId, orgDomain, cpdId, !orgId? -1 : orgId).then((cProg: CourseProgressResult) => { 
            if(isMounted.current){
              setLatestMyTrainingResultForProgress((tld as MyTrainingItemsResult));
              let cProg = !!tld ? (tld as MyTrainingItemsResult).overallProgress : null;
              setCoursesAssigned((!!cProg !== true || cProg == null) ? 0 : cProg.assignedCoursesCount)
              setCoursesCompleted((!!cProg !== true || cProg == null) ? 0 : cProg.completedCoursesCount)
              setTotalCourseProgress((!!cProg !== true || cProg == null) ? 0 : cProg.totalCourseProgress)
              setAssignedQuizesCount((!!cProg !== true || cProg == null) ? 0 : cProg.assignedQuizzesCount)
              setPassedQuizesCount((!!cProg !== true || cProg == null) ? 0 : cProg.passedQuizzesCount)
              setTotalQuizProgress((!!cProg !== true || cProg == null) ? 0 : cProg.totalQuizProgress)
              setTotalLessonProgress((!!cProg !== true || cProg == null || cProg.assignedLessonsCount <= 0) ? 0 : Math.round((cProg.completedLessonsCount / cProg.assignedLessonsCount) * 100))
              setTotalPolicyProgress((!!cProg !== true || cProg == null || cProg.assignedPoliciesCount <= 0) ? 0 : Math.round((cProg.acceptedPoliciesCount / cProg.assignedPoliciesCount) * 100))
              setTotalCertificateProgress(Math.round((!!cProg !== true || cProg == null || cProg.assignedCertificateCount <= 0) ? 0 : (cProg.acceptedCertificateCount / cProg.assignedCertificateCount) * 100))
                   
              if (!!tld && !!tld.myTrainingItems) {
                setMyTrainingList(tld.myTrainingItems);
                currentMyTrainingList = tld.myTrainingItems;
                moduleIDs = tld.myTrainingItems.filter(t => !!t && !!t.type && t.type.trim().toLowerCase() == "course").map(t => t.id);
                policyIDs = tld.myTrainingItems.filter(t => !!t && !!t.type && t.type.trim().toLowerCase() == "policy").map(t => t.id);
              }
            
              //if ((tld as MyTrainingItemsResult).doesUserHaveCPD) {
                if (cpdId !== -1) {
                  setShowCPD(true)
                  setCpdList(null);
                 
                }
                else {
                  setShowCPD(true)
                  setCpdList(null);
                
                }
              //}
           
              setShowPolicyUI((!!cProg !== true || cProg == null) ? false : cProg.assignedPoliciesCount !== 0)
              setShowCourseUI((!!cProg !== true || cProg == null) ? false : cProg.assignedCoursesCount !== 0)
              setShowQuizUI((!!cProg !== true || cProg == null) ? false : cProg.assignedQuizzesCount !== 0)
              setShowMyCertificateUI((!!cProg !== true || cProg == null) ? false : cProg.assignedCertificateCount !== 0)



              setLoading(false)              
              checkScrollReminder()
              setTrainingListUpdateRequired(true);

              getUserCPDs(oidcUser.access_token, userId, orgDomain).then((cpdData) => {
                setLatestUserCPDs(cpdData);
                setCPDLoading(false)
                let cpdIdTmp = -1;
                let cpdPrdIdTmp = -1;
                if(isMounted.current){
                  cpdIdTmp = !!cpdData && cpdData.length > 0 ? cpdData[0].id : -1
                  cpdPrdIdTmp = !!cpdData && cpdData.length > 0 ? cpdData[0].periodID : -1
                  setShowCPD(cpdIdTmp > 0 && cpdPrdIdTmp > 0); 
                  setCpdList(cpdData)
                  //setMessage(allResourcesToTranslations.fetchingyourtrainingplan)
                  
                }
          
                cpdId = !!cpdData && cpdData.length > 0 ? cpdData[currentCpd].id : -1
                cpdPrdId = !!cpdData && cpdData.length > 0 ? cpdData[currentCpd].periodID : -1
                if (cpdIdTmp > 0 && cpdPrdIdTmp > 0) {
                  getUserCoursesPoliciesOnlyWithCPDPoints(oidcUser.access_token, userId, orgDomain, !orgId ? -1 : orgId, cpdId, cpdPrdId, '', policyIDs.join(",")).
                  then((trainingCPDResult : any | null) => {
                    if(isMounted.current){
                      if (!!trainingCPDResult && !!trainingCPDResult.isSuccess &&
                          !!currentMyTrainingList && currentMyTrainingList.length > 0) {
                        let courseCPDs : CPDPointsItem[] | null = !!trainingCPDResult.courseCPDs ? (trainingCPDResult.courseCPDs as CPDPointsItem[]) : null;
                        let courses = !!courseCPDs ? courseCPDs.filter(c => !!c && !!c.id && !!c.type && c.type == 1).map(c => c.id) : null;
                        let policyCPDs : CPDPointsItem[] | null = !!trainingCPDResult.policyCPDs ? (trainingCPDResult.policyCPDs as CPDPointsItem[]) : null;
                        let policies = !!policyCPDs ? policyCPDs.filter(c => !!c && !!c.id && !!c.type && c.type == 3).map(c => c.id) : null;
                        let newTrainingList : MyTrainingItem[] = [];
                        for (let idxNew = 0; idxNew < currentMyTrainingList.length; idxNew++) {
                          let curVal = currentMyTrainingList[idxNew];
                          let cid : number | undefined = curVal.courseID;
                          if (!!cid && !!curVal && !!curVal.type && curVal.type.toLowerCase().trim() == "course" && !!courseCPDs && !!courses && courses.includes(cid)) {
                            let cCPDs = courseCPDs.filter(c => !!c && c.id == cid);
                            let cCPD = !!cCPDs && cCPDs.length > 0 ? cCPDs[0] : null;
                            curVal.pointsAvailable = !!cCPD ? cCPD.pointsAvailable : 0.0;
                            curVal.pointsCalculated = !!cCPD ? cCPD.pointsEarned : 0.0;
                          }
                          else {
                            let pid : number | undefined = curVal.id;
                            if (!!pid && !!curVal && !!curVal.type && curVal.type.toLowerCase().trim() == "policy" && !!policyCPDs && !!policies && policies.includes(pid)) {
                              let pCPDs = policyCPDs.filter(c => !!c && c.id == pid);
                              let pCPD = !!pCPDs && pCPDs.length > 0 ? pCPDs[0] : null;
                              curVal.pointsAvailable = !!pCPD ? pCPD.pointsAvailable : 0.0;
                              curVal.pointsCalculated = !!pCPD ? pCPD.pointsEarned : 0.0;
                            }
                          }
                          curVal.isCPDReady = true;
                          newTrainingList.push(curVal);
                        }
                        setMyTrainingList(newTrainingList)
                        setTrainingListUpdateRequired(false);
                      }
                    }
                  });
                }
              });              
            }          
          //})
        })
      //})
    } else {
      if (!!latestMyTrainingResultForProgress) {      
        let cProg = !!latestMyTrainingResultForProgress ? (latestMyTrainingResultForProgress as MyTrainingItemsResult).overallProgress : null;      
        setShowPolicyUI((!!cProg !== true || cProg == null) ? false : cProg.assignedPoliciesCount !== 0)
        setShowCourseUI((!!cProg !== true || cProg == null) ? false : cProg.assignedCoursesCount !== 0)
        setShowQuizUI((!!cProg !== true || cProg == null) ? false : cProg.assignedQuizzesCount !== 0)
        setShowMyCertificateUI((!!cProg !== true || cProg == null) ? false : cProg.assignedCertificateCount !== 0)        
      }
      if (cpdList !== null) {
        if (cpdList.length > 0) {
          let cpdIdTmp = !!cpdList && cpdList.length > 0 ? cpdList[0].id : -1
          let cpdPrdIdTmp = !!cpdList && cpdList.length > 0 ? cpdList[0].periodID : -1
          setShowCPD(cpdIdTmp > 0 && cpdPrdIdTmp > 0);          
        }
      }
      setLoading(false)     
      setTrainingListUpdateRequired(false)
      checkScrollReminder()
      setCPDLoading(false)
    }    
    setGuideMe(true);
    return () => {
      isMounted.current = false
      setShowScrollArrow(false)
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <LoaderContainer/>
  }

  return (
    <>
      <Steps
          enabled={intro.stepsEnabled}
          steps={intro.steps}
          initialStep={intro.initialStep}
          onExit={onExit}
          options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
          }}
        />
     <div className={styles.guideMeButton}>
              <CustomTooltip displayText={[allResourcesToTranslations.guideme]}>
                  <span onClick={() => {setGuideMe(true); setIntro({ ...intro, stepsEnabled: true })}} className={styles.guideMeButtonSpecific}>
                      {/* <span onClick={() => {setGuideMe(); setIntro({...intro, stepsEnabled: true}); }} className={styles.guideMeButtonSpecific}> */}
                      <GuideIcon />
                  </span>
              </CustomTooltip>       
      </div>
      <div className={responsivestyles.rowrule} style={{ width: '100%' }}>
              <div className={ showCPD ? `${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}` :`${responsivestyles.columnrule_s_12}` }>
                <div style={{ padding:" 0 5px 5px 5px" }}>
                  <CourseCompletionPanel
                    assignedCoursesCount={coursesAssigned}
                    completedCoursesCount={coursesCompleted}
                    totalCourseProgress={totalCourseProgress}
                    assignedQuizesCount={assignedQuizesCount}
                    passedQuizesCount={passedQuizesCount}
                    totalQuizProgress={totalQuizProgress}
                    lessonCompletionProgress={totalLessonProgress}
                    policyAcceptanceProgress={totalPolicyProgress}
                    CertificateProgress={totalCertificateProgress}
                  />
                </div>
            </div>
            {showCPD &&            
              <div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
                <div style={{ padding:" 0 5px 5px 5px" }}>
                <CPDPanel showCPD={showCPD} setCurrentCpd={handleCpdUpdate} currentCpd={currentCpd} cpdList={cpdList} isCPDLoading={isCPDLoading} calledFrom='mytraining'/>
                </div>
              </div>
            }
      </div>
      <div>
        <div className={styles.filterButtonContainer}>
          <MyTrainingFilterButtons 
            currentSelection={myTrainingSelection} 
            setCurrentSelection={handleMyTrainingSelection} 
          />
        </div>
        <MyTrainingList logNavigation={logNavigation} />   
      </div>
    </>
  );
};

export default MyTraining;
