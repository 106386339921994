//React + Typescript
import React, { useContext } from 'react'
//Components
import BrandedCard from '../../Components/BrandedCard';
//Context
import { TranslationsContext } from '../../contexts/TranslationsContext';
//MUI Components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

interface Props {
    courseName: string;
    moduleName: string;
    userName: string;
    quizScore: number;
    quizPassmark: number
}

export const QuizSummaryOverviewCard = ({courseName, moduleName, userName, quizScore, quizPassmark}: Props): JSX.Element => {
  const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)

    return (
      <BrandedCard>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {allResourcesToTranslations.course}
              </TableCell>
              <TableCell align="center">
                {allResourcesToTranslations.module}
              </TableCell>
              <TableCell align="center">
                {allResourcesToTranslations.name}
              </TableCell>
              <TableCell align="center">
                {allResourcesToTranslations.passmark}
              </TableCell>
              <TableCell align="center">
                {allResourcesToTranslations.quizscore}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center" style={{height: "70px"}}>
                {courseName}
              </TableCell>
              <TableCell align="center">
                {moduleName}
              </TableCell>
              <TableCell align="center">
                {userName}
              </TableCell>
              <TableCell align="center">
                {quizPassmark}%
              </TableCell>
              <TableCell align="center">
                {quizScore}%
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </BrandedCard>
    );
}

export default QuizSummaryOverviewCard;
