import React from 'react';
import { InfopathCompletedPageType } from '../../globalTypes'

interface Props {
  completedPage: InfopathCompletedPageType;
}

export default function InfopathComplete({ completedPage }: Props) : JSX.Element {
  return <p>{completedPage.text.split('<NLCR/>').filter(function(str) {
    if (str === null || str.length <= 0 || str.trimLeft().trimRight().length <= 0) {
        return false;
    }
    return true;
  }).map(str => <p>{str}</p>)}</p>
}
