import React, { useContext } from 'react'
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';
import styles from '../../styles/logoutcard.module.css';
 
const LogoutCard = ({children}: any) => {
    const { color, logoUri } = useContext(CorperateThemeContext).theme;
    return (
      <div className={styles.logoutCard}>
          <div className={styles.logoContainer} style={{borderColor: color}}>
            <img src={ logoUri } alt="Background" ></img>
          </div>
          <div className={styles.contentCard}>
              <div>
                { children }
              </div>
          </div>
      </div>
    );
}

export default LogoutCard
