import React, { useState, useEffect, useContext } from "react";

//Styles
import styles from "../../../styles/organisationmanagement.module.css";
import LoaderContainer from "../../../Components/LoaderContainer";
import { TranslationsContext } from "../../../contexts/TranslationsContext";

interface Props {
  showChart: boolean // If Enable IE
  showPreExpiry: boolean // IF Pre-expiryEnable and IE true
  showPostExpiry: boolean // IF Post-expiryEnable and IE true
  showManagerNotification: boolean // IF mgr Notification is Enable
  totalCourseCompletionDays: number
  preexpirydays: number
  noOfPostexpiryNotification: number
  postExpriryEveryDay: number
  managerNotificationEveryDaysNo: number
  reminderType: string
}

const EmailReminderTimeline = ({ showChart, showPreExpiry, showPostExpiry, showManagerNotification, totalCourseCompletionDays, preexpirydays, noOfPostexpiryNotification, postExpriryEveryDay, managerNotificationEveryDaysNo, reminderType}: Props) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [initDate, setInitDate] = useState<Date>(new Date());
    const [courseEndDate, setCourseEndDate] = useState<Date>(new Date());
    const [courseExpiry, setCourseExpiry] = useState<Date>(new Date());
    const [preExpiryDate, setPreExpiryDate] = useState<Date>(new Date());
    const [firstPostExpiryDate, setFirstpostExpiryDate] = useState<Date>(new Date());
    const [secondPostExpiryDate, setSecondPostExpiryDate] = useState<Date>(new Date());
    const [lastPostExpiryDate, setLastPostExpiryDate] = useState<Date>(new Date());
    const [firstManagerNotificationDate, setFirstManagerNotification] = useState<Date>(new Date());
    const [secondManagerNotificationDate, setSecondManagerNotification] = useState<Date>(new Date());
    const {
      translations: { allResourcesToTranslations }, pageTitles
    } = useContext(TranslationsContext);

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const [tableColumns, settableColumns] = useState({
      Columns : [{
      no: 0,
      Date: (new Date()).toDateString(),
      LeftBorder: false,
      HasSamePostNMgrNotification: false,
      IsPreExpiry: false,
      IsPostExpiry: false,
      IsManagerNotification: false,
    }]
  });
    const setColumnDesign = () => {
      let courseEndDate = new Date(new Date(initDate).setDate(initDate.getDate() + (totalCourseCompletionDays - 1))); //Exclude Today
      setCourseEndDate(courseEndDate);

      let courseExpiryDate = new Date(new Date(initDate).setDate(initDate.getDate() + (totalCourseCompletionDays))); 
      setCourseExpiry(courseExpiryDate);

      let preExpiryDate = (preexpirydays > 0 && showPreExpiry) ? new Date(new Date(courseExpiryDate).setDate(courseExpiryDate.getDate() - preexpirydays)) : courseEndDate;
      setPreExpiryDate(preExpiryDate);     

      let firstPostExpiryDate = new Date(new Date(courseExpiryDate).setDate(courseExpiryDate.getDate()));
      setFirstpostExpiryDate(firstPostExpiryDate);
      let secondPostExpiryDate = new Date(new Date(firstPostExpiryDate).setDate(firstPostExpiryDate.getDate() + postExpriryEveryDay));
      setSecondPostExpiryDate(secondPostExpiryDate);
      let lastPostExpiryDate = new Date(new Date(courseExpiryDate).setDate(courseExpiryDate.getDate() + (postExpriryEveryDay * (noOfPostexpiryNotification - 1))));
      setLastPostExpiryDate(lastPostExpiryDate);

      let firstManagerNotificationDate = new Date(new Date(courseExpiryDate).setDate(courseExpiryDate.getDate() + managerNotificationEveryDaysNo));
      setFirstManagerNotification(firstManagerNotificationDate);
      let secondManagerNotificationDate = new Date(new Date(firstManagerNotificationDate).setDate(firstManagerNotificationDate.getDate() + managerNotificationEveryDaysNo));
      setSecondManagerNotification(secondManagerNotificationDate);

      let updateColumns =[];
      updateColumns.push(
        {
          no: 1,
          Date: (new Date()).toDateString(),
          LeftBorder: false,
          HasSamePostNMgrNotification: false,
          IsPreExpiry: showPreExpiry && (preExpiryDate.toDateString() === (new Date()).toDateString()),
          IsPostExpiry: false,
          IsManagerNotification: false,
          showYear: false
        });

        
      // For first 7
      if (totalCourseCompletionDays <= 7) // Show all Columns
      {
        updateColumns.push(
        {
          no: 2,
          Date: totalCourseCompletionDays > 1 ? new Date(new Date(initDate).setDate(initDate.getDate() + 1)).toDateString() : '',
          LeftBorder: totalCourseCompletionDays > 1 ? (initDate.getMonth() !== (new Date(new Date(initDate).setDate(initDate.getDate() + 1))).getMonth() && initDate.getFullYear() !== (new Date(new Date(initDate).setDate(initDate.getDate() + 1))).getFullYear()) ? true : false : false,
          HasSamePostNMgrNotification: false,
          IsPreExpiry:  showPreExpiry && (new Date(new Date(initDate).setDate(initDate.getDate() + 1)).toDateString() === preExpiryDate.toDateString()) ? true : false,
          IsPostExpiry: false,
          IsManagerNotification: false,
          showYear: totalCourseCompletionDays > 1 ? initDate.getFullYear() !== (new Date(new Date(initDate).setDate(initDate.getDate() + 1))).getFullYear() ? true : false : false,
        });

        for (let i = 2; i <7; i++) {
          updateColumns.push(
            {
              no: 1 + i,
              Date: totalCourseCompletionDays > i ? (new Date(new Date(initDate).setDate(initDate.getDate() + i)).toDateString()) : '',
              LeftBorder: totalCourseCompletionDays > i ? ((new Date(new Date(initDate).setDate(initDate.getDate() + (i - 1)))).getMonth() !== (new Date(new Date(initDate).setDate(initDate.getDate() + i))).getMonth() ? true : false) : false,
              HasSamePostNMgrNotification: false,
              IsPreExpiry: showPreExpiry && (new Date(new Date(initDate).setDate(initDate.getDate() + i)).toDateString() === preExpiryDate.toDateString()) ? true : false,
              IsPostExpiry: false,
              IsManagerNotification: false,
              showYear: totalCourseCompletionDays > i ? ((new Date(new Date(initDate).setDate(initDate.getDate() + (i - 1)))).getFullYear() !== (new Date(new Date(initDate).setDate(initDate.getDate() + i))).getFullYear() ? true : false) : false,
            });  
        }

      } else { // Show start date, End Date and PReExpiry
        if(preexpirydays == 1) {
          for (let i = 1; i <6; i++) {
          updateColumns.push(
            {
              no: 1 + i,
              Date: '',
              LeftBorder: false,
              HasSamePostNMgrNotification: false,
              IsPreExpiry: showPreExpiry && (courseEndDate.toDateString() === preExpiryDate.toDateString()),
              IsPostExpiry: false,
              IsManagerNotification: false,
              showYear: false
            });
          }
          updateColumns.push(
            {
              no: 7,
              Date: courseEndDate.toDateString(),
              LeftBorder: true,
              HasSamePostNMgrNotification: false,
              IsPreExpiry: showPreExpiry && (courseEndDate.toDateString() === preExpiryDate.toDateString()),
              IsPostExpiry: false,
              IsManagerNotification: false,
              showYear: false
            });
        }
        else {
          let differenceDays = (totalCourseCompletionDays) - preexpirydays;
          let percentagePreExpiryReside = Math.round(((1.0 * (differenceDays)) / (1.0 * (totalCourseCompletionDays))) * 100);
          
          for (let i = 1; i <6; i++) {
            updateColumns.push(
            {
              no: 1 + i,
              Date:   (percentagePreExpiryReside > 0 && percentagePreExpiryReside < 20 && i === 1) && showPreExpiry ? preExpiryDate.toDateString() 
                    : (percentagePreExpiryReside >= 20 && percentagePreExpiryReside < 40 && i === 2) && showPreExpiry ? preExpiryDate.toDateString() 
                    : (percentagePreExpiryReside >= 40 && percentagePreExpiryReside < 60 && i === 3) && showPreExpiry ? preExpiryDate.toDateString() 
                    : (percentagePreExpiryReside >= 60 && percentagePreExpiryReside < 80 && i === 4) && showPreExpiry ? preExpiryDate.toDateString() 
                    : (percentagePreExpiryReside >= 80 && percentagePreExpiryReside < 100 && i === 5) && showPreExpiry ? preExpiryDate.toDateString() 
                    : '',
              LeftBorder: (percentagePreExpiryReside > 0 && percentagePreExpiryReside < 20 && i === 1) && showPreExpiry ? preExpiryDate.getMonth() !== initDate.getMonth()
                        : (percentagePreExpiryReside >= 20 && percentagePreExpiryReside < 40 && i === 2) && showPreExpiry ? preExpiryDate.getMonth() !== initDate.getMonth()
                        : (percentagePreExpiryReside >= 40 && percentagePreExpiryReside < 60 && i === 3) && showPreExpiry ? preExpiryDate.getMonth() !== initDate.getMonth()
                        : (percentagePreExpiryReside >= 60 && percentagePreExpiryReside < 80 && i === 4) && showPreExpiry ? preExpiryDate.getMonth() !== initDate.getMonth()
                        : (percentagePreExpiryReside >= 80 && percentagePreExpiryReside < 100 && i === 5) && showPreExpiry ? preExpiryDate.getMonth() !== initDate.getMonth()
                        : false,
              HasSamePostNMgrNotification: false,
              IsPreExpiry:  (percentagePreExpiryReside > 0 && percentagePreExpiryReside < 20 && i === 1) && showPreExpiry ? true
                          : (percentagePreExpiryReside >= 20 && percentagePreExpiryReside < 40 && i === 2) && showPreExpiry ? true
                          : (percentagePreExpiryReside >= 40 && percentagePreExpiryReside < 60 && i === 3) && showPreExpiry ? true
                          : (percentagePreExpiryReside >= 60 && percentagePreExpiryReside < 80 && i === 4) && showPreExpiry ? true
                          : (percentagePreExpiryReside >= 80 && percentagePreExpiryReside < 100 && i === 5) && showPreExpiry ? true
                      : false,
              IsPostExpiry: false,
              IsManagerNotification: false,
              showYear: (percentagePreExpiryReside > 0 && percentagePreExpiryReside < 20 && i === 1) && showPreExpiry ? preExpiryDate.getFullYear() !== initDate.getFullYear()
                    : (percentagePreExpiryReside >= 20 && percentagePreExpiryReside < 40 && i === 2) && showPreExpiry ? preExpiryDate.getFullYear() !== initDate.getFullYear()
                    : (percentagePreExpiryReside >= 40 && percentagePreExpiryReside < 60 && i === 3) && showPreExpiry ? preExpiryDate.getFullYear() !== initDate.getFullYear()
                    : (percentagePreExpiryReside >= 60 && percentagePreExpiryReside < 80 && i === 4) && showPreExpiry ? preExpiryDate.getFullYear() !== initDate.getFullYear()
                    : (percentagePreExpiryReside >= 80 && percentagePreExpiryReside < 100 && i === 5) && showPreExpiry ? preExpiryDate.getFullYear() !== initDate.getFullYear()
                    : false
            });  
          }
          updateColumns.push(
            {
              no: 7,
              Date: new Date(new Date(courseEndDate).setDate(courseEndDate.getDate())).toDateString(),
              LeftBorder: preExpiryDate.getMonth() !== courseEndDate.getMonth(),
              HasSamePostNMgrNotification: false,
              IsPreExpiry: (preExpiryDate.toDateString() === courseEndDate.toDateString()) && showPreExpiry,
              IsPostExpiry: false,
              IsManagerNotification: false,
              showYear: preExpiryDate.getFullYear() !== courseEndDate.getFullYear()
            });  
        }
      }
      let hasFirstPostNotification = showPostExpiry ? false : true;
      let hasSecondPostNotification = showPostExpiry ? false : true;
      let hasLastPostNotification = showPostExpiry ? false : true;
      let hasOneManagerNotification = showManagerNotification ? false : true;

      updateColumns.push(
        {
          no: 8,
          Date: new Date(new Date(courseExpiryDate).setDate(courseExpiryDate.getDate())).toDateString(),
          LeftBorder: courseEndDate.getMonth() !== courseExpiryDate.getMonth(),
          HasSamePostNMgrNotification: showPostExpiry && showManagerNotification,
          IsPreExpiry: false,
          IsPostExpiry: showPostExpiry,
          IsManagerNotification: showManagerNotification,
          showYear: courseEndDate.getFullYear() !== courseExpiryDate.getFullYear()
        });
        
        if (!hasFirstPostNotification && courseExpiryDate.toDateString() === firstPostExpiryDate.toDateString()) {
          hasFirstPostNotification = true;
        }

        if (!hasOneManagerNotification && courseExpiryDate.toDateString() === firstManagerNotificationDate.toDateString()) {
          hasOneManagerNotification = true;
        }

        if (!hasLastPostNotification && courseExpiryDate.toDateString() === lastPostExpiryDate.toDateString()) {
          hasLastPostNotification = true;
        }

      // end first 7  

      // post notification and Manager notification

      if ((showPostExpiry && postExpriryEveryDay < 8) || (showManagerNotification && managerNotificationEveryDaysNo < 8)) {
      
        let currentTotalPostExpirySet = 1;
        let currentDate = new Date();
        for (let i = 1; i < 8; i++) {          
          
          currentDate = new Date(new Date(courseExpiryDate).setDate(courseExpiryDate.getDate() + i));

          if (showPostExpiry && ((i + 1) % postExpriryEveryDay === 0) && (noOfPostexpiryNotification >= currentTotalPostExpirySet) ? true : false) {
            currentTotalPostExpirySet++;
          }

          updateColumns.push(
          {
            no: i + 8,
            Date: currentDate.toDateString(),
            LeftBorder: ((new Date(new Date(courseExpiryDate).setDate(courseExpiryDate.getDate() + (i)))).getMonth() !== (currentDate).getMonth() ? true : false),
            HasSamePostNMgrNotification: (showPostExpiry && ((i) % postExpriryEveryDay === 0) && (noOfPostexpiryNotification >= currentTotalPostExpirySet)) && (showManagerNotification && ((i)  % managerNotificationEveryDaysNo === 0) ? true : false),
            IsPreExpiry: false,
            IsPostExpiry: showPostExpiry && ((i) % postExpriryEveryDay === 0) && (noOfPostexpiryNotification  >= (currentTotalPostExpirySet)) ? true : false,
            IsManagerNotification: showManagerNotification && ((i)  % managerNotificationEveryDaysNo === 0) ? true : false,
            showYear: ((new Date(new Date(courseExpiryDate).setDate(courseExpiryDate.getDate() + (i)))).getFullYear() !== (currentDate).getFullYear() ? true : false)
          });
          if (!hasOneManagerNotification && currentDate.toDateString() === firstManagerNotificationDate.toDateString()) {
            hasOneManagerNotification = true;
          }

          if (!hasLastPostNotification && currentDate.toDateString() === lastPostExpiryDate.toDateString()) {
            hasLastPostNotification = true;
          }
        }
        if (!hasOneManagerNotification || !hasLastPostNotification) {
          let lastProcessedDate = new Date(new Date(courseExpiryDate).setDate(courseExpiryDate.getDate() + 7));
          if (!hasOneManagerNotification && !hasLastPostNotification) {
            if (firstManagerNotificationDate > lastPostExpiryDate) {
              if (dateDiffInDays(lastProcessedDate, lastPostExpiryDate) > 1) {
                updateColumns.push(
                  {
                    no: 17,
                    Date: '...',
                    LeftBorder: false,
                    HasSamePostNMgrNotification: false,
                    IsPreExpiry: false,
                    IsPostExpiry: false,
                    IsManagerNotification: false,
                    showYear: false
                  });
                }
                updateColumns.push(
                  {
                    no: 18,
                    Date:  (lastPostExpiryDate).toDateString(),
                    LeftBorder: lastProcessedDate.getMonth() !== lastPostExpiryDate.getMonth(),
                    HasSamePostNMgrNotification: false,
                    IsPreExpiry: false,
                    IsPostExpiry: true,
                    IsManagerNotification: false,
                    showYear: lastProcessedDate.getFullYear() !== lastPostExpiryDate.getFullYear()
                  });

              if (dateDiffInDays(lastPostExpiryDate, firstManagerNotificationDate) > 1) {
                updateColumns.push(
                  {
                    no: 19,
                    Date: '...',
                    LeftBorder: false,
                    HasSamePostNMgrNotification: false,
                    IsPreExpiry: false,
                    IsPostExpiry: false,
                    IsManagerNotification: false,
                    showYear: false
                  });
                }
                updateColumns.push(
                  {
                    no: 20,
                    Date:  (firstManagerNotificationDate).toDateString(),
                    LeftBorder: lastProcessedDate.getMonth() !== firstManagerNotificationDate.getMonth(),
                    HasSamePostNMgrNotification: false,
                    IsPreExpiry: false,
                    IsPostExpiry: false,
                    IsManagerNotification: true,
                    showYear: lastProcessedDate.getFullYear() !== firstManagerNotificationDate.getFullYear()
                  });
            } else {
              if (dateDiffInDays(lastProcessedDate, firstManagerNotificationDate) > 1) {
                updateColumns.push(
                  {
                    no: 17,
                    Date: '...',
                    LeftBorder: false,
                    HasSamePostNMgrNotification: false,
                    IsPreExpiry: false,
                    IsPostExpiry: false,
                    IsManagerNotification: false,
                    showYear: false
                  });
                }
                updateColumns.push(
                  {
                    no: 18,
                    Date:  (firstManagerNotificationDate).toDateString(),
                    LeftBorder: lastProcessedDate.getMonth() !== firstManagerNotificationDate.getMonth(),
                    HasSamePostNMgrNotification: false,
                    IsPreExpiry: false,
                    IsPostExpiry: false,
                    IsManagerNotification: true,
                    showYear: lastProcessedDate.getFullYear() !== firstManagerNotificationDate.getFullYear()
                  });
                  if (dateDiffInDays(firstManagerNotificationDate, lastPostExpiryDate) > 1) {
                    updateColumns.push(
                      {
                        no: 19,
                        Date: '...',
                        LeftBorder: false,
                        HasSamePostNMgrNotification: false,
                        IsPreExpiry: false,
                        IsPostExpiry: false,
                        IsManagerNotification: false,
                        showYear: false
                      });
                    }
                    updateColumns.push(
                      {
                        no: 20,
                        Date:  (lastPostExpiryDate).toDateString(),
                        LeftBorder: lastPostExpiryDate.getMonth() !== firstManagerNotificationDate.getMonth(),
                        HasSamePostNMgrNotification: false,
                        IsPreExpiry: false,
                        IsPostExpiry: true,
                        IsManagerNotification: false,
                        showYear: lastProcessedDate.getFullYear() !== firstManagerNotificationDate.getFullYear()
                      });
            }
          }
          else if (!hasOneManagerNotification && hasLastPostNotification) {
            if (dateDiffInDays(lastProcessedDate, firstManagerNotificationDate) > 1) {
              updateColumns.push(
                {
                  no: 19,
                  Date: '...',
                  LeftBorder: false,
                  HasSamePostNMgrNotification: false,
                  IsPreExpiry: false,
                  IsPostExpiry: false,
                  IsManagerNotification: false,
                  showYear: false
                });
              }
              updateColumns.push(
                {
                  no: 20,
                  Date:  (firstManagerNotificationDate).toDateString(),
                  LeftBorder: lastProcessedDate.getMonth() !== firstManagerNotificationDate.getMonth(),
                  HasSamePostNMgrNotification: false,
                  IsPreExpiry: false,
                  IsPostExpiry: false,
                  IsManagerNotification: true,
                  showYear: lastProcessedDate.getFullYear() !== firstManagerNotificationDate.getFullYear()
                });
          }
          else if (hasOneManagerNotification && !hasLastPostNotification) {
            if (dateDiffInDays(lastProcessedDate, lastPostExpiryDate) > 1) {
              updateColumns.push(
                {
                  no: 19,
                  Date: '...',
                  LeftBorder: false,
                  HasSamePostNMgrNotification: false,
                  IsPreExpiry: false,
                  IsPostExpiry: false,
                  IsManagerNotification: false,
                  showYear: false
                });
              }
              updateColumns.push(
                {
                  no: 20,
                  Date:  (lastPostExpiryDate).toDateString(),
                  LeftBorder: lastProcessedDate.getMonth() !== lastPostExpiryDate.getMonth(),
                  HasSamePostNMgrNotification: false,
                  IsPreExpiry: false,
                  IsPostExpiry: true,
                  IsManagerNotification: false,
                  showYear: lastProcessedDate.getFullYear() !== lastPostExpiryDate.getFullYear()
                });
          }
        }
      }
      else {
        let listOfRequiredDatesTodisplay = [];
        let lastProcessedDate = new Date(new Date(courseExpiryDate).setDate(courseExpiryDate.getDate()));
        let LastProcessedNo = 8;
        if (showManagerNotification && showPostExpiry) {
          let templist = [];
          templist.push({date:secondPostExpiryDate, isPost: true, isManagerNotification: false});
          templist.push({date:lastPostExpiryDate, isPost: true, isManagerNotification: false});
          templist.push({date:firstManagerNotificationDate, isPost: false, isManagerNotification: true});
          templist.push({date:secondManagerNotificationDate, isPost: false, isManagerNotification: true});
          templist.sort((a: any, b: any) => a.date - b.date);

          // Remaining post notification First and Second and last are already added
          let temppostexpirylist = [];
          for ( let j = 2; j < noOfPostexpiryNotification -1; j++) {
            var date = (new Date(new Date(courseExpiryDate).setDate(courseExpiryDate.getDate() + (j * postExpriryEveryDay))))
            temppostexpirylist.push(date);
          }

          for (let index = 0; index < templist.length; ++index) {
            const currentElement = templist[index];
            temppostexpirylist.forEach(ele => {
              if (ele < currentElement.date) {
                  listOfRequiredDatesTodisplay.push({date: ele, isPost: true, isManagerNotification: false});
                }
              });
            listOfRequiredDatesTodisplay.push({date: currentElement.date, isPost: currentElement.isPost, isManagerNotification: currentElement.isManagerNotification});
          }
        }
        else if (!showManagerNotification && showPostExpiry) {
          listOfRequiredDatesTodisplay.push({date:secondPostExpiryDate, isPost: true, isManagerNotification: false});
          listOfRequiredDatesTodisplay.push({date:lastPostExpiryDate, isPost: true, isManagerNotification: false});
        }
        else if (showManagerNotification && !showPostExpiry) {
          listOfRequiredDatesTodisplay.push({date:firstManagerNotificationDate, isPost: false, isManagerNotification: true});          
          listOfRequiredDatesTodisplay.push({date:secondManagerNotificationDate, isPost: false, isManagerNotification: true});
        }       

        listOfRequiredDatesTodisplay.sort((a: any, b: any) => a.date - b.date);
        listOfRequiredDatesTodisplay.forEach(ele => {
          LastProcessedNo++;
          if (dateDiffInDays(lastProcessedDate, ele.date) > 1) {
            updateColumns.push(
              {
                no: LastProcessedNo,
                Date: '...',
                LeftBorder: false,
                HasSamePostNMgrNotification: false,
                IsPreExpiry: false,
                IsPostExpiry: false,
                IsManagerNotification: false,
                showYear: false
              });
              LastProcessedNo++;
            }
            if(lastProcessedDate.toDateString() !== ele.date.toDateString()) {
              updateColumns.push(
                {
                  no: LastProcessedNo,
                  Date:  (ele.date).toDateString(),
                  LeftBorder: lastProcessedDate.getMonth() !== (ele.date).getMonth(),
                  HasSamePostNMgrNotification: (((ele.date).toDateString() === secondPostExpiryDate.toDateString() || (ele.date).toDateString() === lastPostExpiryDate.toDateString()) && ((ele.date).toDateString() === firstManagerNotificationDate.toDateString() || (ele.date).toDateString() === secondManagerNotificationDate.toDateString()))
                                                || (postExpriryEveryDay === managerNotificationEveryDaysNo && (lastPostExpiryDate >= ele.date)),
                  IsPreExpiry: false,
                  IsPostExpiry: ele.isPost,
                  IsManagerNotification: ele.isManagerNotification,
                  showYear: lastProcessedDate.getFullYear() !== (ele.date).getFullYear()
                });
                lastProcessedDate = ele.date;
              }
        });

      }
      settableColumns({ ...tableColumns, Columns: [...updateColumns] });
    }
    useEffect(() => {

      setLoading(false);

      let courseEndDate = new Date(new Date(initDate).setDate(initDate.getDate() + (totalCourseCompletionDays))); // Exclude today
      setCourseEndDate(courseEndDate);

      let courseExpiryDate = new Date(new Date(initDate).setDate(initDate.getDate() + (totalCourseCompletionDays))); 
      setCourseExpiry(courseExpiryDate);

      let preExpiryDate = (preexpirydays > 0) ? new Date(new Date(courseExpiryDate).setDate(courseExpiryDate.getDate() - preexpirydays)) : courseEndDate;
      setPreExpiryDate(preExpiryDate);     

      let firstPostExpiryDate = new Date(new Date(courseExpiryDate).setDate(courseExpiryDate.getDate()));
      setFirstpostExpiryDate(firstPostExpiryDate);
      let secondPostExpiryDate = new Date(new Date(firstPostExpiryDate).setDate(firstPostExpiryDate.getDate() + postExpriryEveryDay));
      setSecondPostExpiryDate(secondPostExpiryDate);
      let lastPostExpiryDate = new Date(new Date(courseExpiryDate).setDate(courseExpiryDate.getDate() + (postExpriryEveryDay * (noOfPostexpiryNotification - 1))));
      setLastPostExpiryDate(lastPostExpiryDate);

      let firstManagerNotificationDate = new Date(new Date(courseExpiryDate).setDate(courseExpiryDate.getDate() + managerNotificationEveryDaysNo));
      setFirstManagerNotification(firstManagerNotificationDate);
      let secondManagerNotificationDate = new Date(new Date(firstManagerNotificationDate).setDate(firstManagerNotificationDate.getDate() + managerNotificationEveryDaysNo));
      setSecondManagerNotification(secondManagerNotificationDate);

      setColumnDesign();
    }, [showChart, showPreExpiry, showPostExpiry, showManagerNotification, totalCourseCompletionDays, preexpirydays, noOfPostexpiryNotification, postExpriryEveryDay, managerNotificationEveryDaysNo]);

    function dateDiffInDays(a: Date, b: Date) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      // Discard the time and time-zone information.
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    
      return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    
    if (loading) return <LoaderContainer />;
    if (showChart && totalCourseCompletionDays > 0) return (
      <>
      <table className={styles.timelinechart}>
        <tr>
        {tableColumns != null && tableColumns.Columns.length > 0 && (
            tableColumns.Columns
              .map((result: any, i) => {
                return (
                      ((result.no === 1) ? <td className={styles.rowboderbottom}>{monthNames[new Date(result.Date).getMonth()].toString() + (result.showYear || result.showYear ? ' ' + new Date(result.Date).getFullYear().toString() : '')}</td>
                      : (result.no <= 7 && result.Date !== '' && totalCourseCompletionDays <= 7) ? <td className={result.LeftBorder || result.showYear ? `${styles.rowboderbottom} ${styles.rowboderleft}` : styles.rowboderbottom}>{result.LeftBorder || result.showYear ? (monthNames[new Date(result.Date).getMonth()].toString()  + (result.showYear ? ' ' + new Date(result.Date).getFullYear().toString() : '' )): ''}<span>{result.showYear}</span></td>
                      : (result.no <= 7 && result.Date === '' && totalCourseCompletionDays <= 7) ? <></>
                      : <td className={result.LeftBorder || result.showYear ? `${styles.rowboderbottom} ${styles.rowboderleft}` : styles.rowboderbottom}>{result.LeftBorder || result.showYear? (monthNames[new Date(result.Date).getMonth()].toString() + (result.showYear ? ' ' + new Date(result.Date).getFullYear().toString() : '')) : ''}<span>{result.showYear}</span></td>
                    ))
              })
        )}
           <td rowSpan={3} style={{alignContent: "flex-start", paddingTop: "21px"}}><div><i className={`${styles.arrow} ${styles.right}`}></i></div></td>
        </tr>
        <tr className={styles.blankrow}>
        {tableColumns != null && tableColumns.Columns.length > 0 && (
            tableColumns.Columns
              .map((result: any, i) => {
                return (
                  ((result.no === 1) ? <td className={result.LeftBorder || result.showYear? styles.rowboderleft : ''}></td>
                  : (result.no <= 7 && result.Date !== '' && totalCourseCompletionDays <= 7) ? <td className={result.LeftBorder || result.showYear ? styles.rowboderleft : ''}></td>
                  : (result.no <= 7 && result.Date === '' && totalCourseCompletionDays <= 7) ? <></>
                  : <td className={result.LeftBorder || result.showYear ? styles.rowboderleft : ''}></td>
                ));
              })
        )}
     
        </tr>
        <tr style={{height: "50px"}}>
        {tableColumns != null && tableColumns.Columns.length > 0 && (
            tableColumns.Columns
              .map((result: any, i) => {
                return (
                      ((result.no === 1) ? <td className={` ${(initDate.getMonth() === courseEndDate.getMonth()) ? (totalCourseCompletionDays === 1 ? styles.oneDayCourse : styles.ieStartDatevalue) : styles.ieStartDatevalue} ${(initDate.toDateString() === preExpiryDate.toDateString() && showPreExpiry) || (totalCourseCompletionDays === 1) ? styles.circlecolumns : '' }` }><div className={` ${(initDate.toDateString() === preExpiryDate.toDateString() && showPreExpiry) ? styles.preexpirydate : styles.numberRow }`}>{initDate.getDate()}</div></td>
                      : ((result.no <= 7 && result.Date !== '' && totalCourseCompletionDays <= 7)) ? 
                                                (<td className={` ${result.LeftBorder || result.showYear ? styles.rowboderleft : ''} ${result.Date === courseEndDate.toDateString() ? styles.ieEndDatevalue : styles.ieDatevalue } ${result.IsPreExpiry ? styles.circlecolumns : '' }`}>
                                                  <div className={` ${result.IsPreExpiry ? styles.preexpirydate : styles.numberRow }`}>{result.Date !== '' ? new Date(result.Date).getDate() : ''}</div></td>)
                      : (result.no <= 7 && result.Date === '' && totalCourseCompletionDays <= 7) ? <></>   
                      : ((result.no <= 7 && result.Date === '' && totalCourseCompletionDays > 7)) ? (<td className={` ${result.LeftBorder || result.showYear ? styles.rowboderleft : ''} ${styles.ieDatevalue } `}><div className={` ${styles.numberRow }`}>{result.Date !== '' ? new Date(result.Date).getDate() : ''}</div></td>)
                      : ((result.no <= 7 && result.Date !== '' && totalCourseCompletionDays > 7)) ? 
                                                (<td className={` ${result.LeftBorder || result.showYear ? styles.rowboderleft : ''} ${result.Date === courseEndDate.toDateString() ? styles.ieEndDatevalue : styles.ieDatevalue } ${(result.IsPreExpiry || result.IsPostExpiry || result.IsManagerNotification || result.HasSamePostNMgrNotification ) ? styles.circlecolumns : '' }`}>
                                                  <div className={` ${result.IsPreExpiry ? styles.preexpirydate : styles.numberRow }`}>{result.Date !== '' ? new Date(result.Date).getDate() : ''}
                                                  </div></td>)
                      : <td className={ ` ${result.LeftBorder || result.showYear ? styles.rowboderleft : ''} ${result.IsPostExpiry || result.IsManagerNotification || result.HasSamePostNMgrNotification ? styles.circlecolumns : ''}` }><div className={` ${result.HasSamePostNMgrNotification ? styles.postExpiryManagernotificationdate 
                                                                                                                                            : result.IsPostExpiry ? styles.postexpirydate 
                                                                                                                                            : result.IsManagerNotification ? styles.managernotificationdate : styles.numberRow }`}>{result.Date === '...' ? '...' : new Date(result.Date).getDate()}</div></td>
                    ))
              })
        )}
       </tr>
       </table>        
       <div>
          <div> 
            <ul className={styles.GraphNotation}>
              <li className={styles.courseDuration}><div className={styles.notationText}><span>{allResourcesToTranslations.initialenrolmentperiod}</span></div></li>
              <li className={styles.preExpiry}><div className={styles.notationText}><span>{allResourcesToTranslations.preexpiryreminder}</span></div></li>
              <li className={styles.postExpiry}><div className={styles.notationText}><span>{allResourcesToTranslations.postexpiryreminder}</span></div></li>
              <li className={styles.mgrNotify}><div className={styles.notationText}><span>{allResourcesToTranslations.managernotification}</span></div></li>
            </ul>
          </div>
          <div className={styles.GraphDetails}>
            <ul>
              <li className={styles.courseDurationList}><div className={styles.notationText}>{allResourcesToTranslations.enrollmentdate} <span style={{textDecoration: 'underline'}}>{initDate.getDate().toString() + '/' + (initDate.getMonth() + 1).toString()}</span>
                <br></br>
                {reminderType === 'c' ? allResourcesToTranslations.coursecomplitiondate : reminderType === 'p' ? allResourcesToTranslations.policycomplitiondate : allResourcesToTranslations.certycomplitiondate} <span style={{textDecoration: 'underline'}}>{totalCourseCompletionDays.toString()}</span> {allResourcesToTranslations.daysduedateis} <span>{courseEndDate.getDate().toString() + '/' + (courseEndDate.getMonth() + 1).toString() + ')'} </span>
                </div></li>
              <li className={styles.preExpiryList}><div className={styles.notationText}><span style={{textDecoration: 'underline'}}>{showPreExpiry ? preexpirydays.toString(): '-'}</span>{allResourcesToTranslations.daysbefore}<span>{showPreExpiry ? (preExpiryDate.getDate().toString() + '/' + (preExpiryDate.getMonth() + 1).toString()) : '-'}</span>{reminderType === 'c' ? allResourcesToTranslations.preexpirytext : reminderType === 'p' ? allResourcesToTranslations.preexpirytextpolicy : allResourcesToTranslations.preexpirytextcerty}</div></li>
              <li className={styles.postExpiryList}><div className={styles.notationText}>{allResourcesToTranslations.lernersreceive}<span style={{textDecoration: 'underline'}}>{showPostExpiry ? noOfPostexpiryNotification.toString() : '-'}</span> {allResourcesToTranslations.postexpiry} <span style={{textDecoration: 'underline'}}>{showPostExpiry? postExpriryEveryDay.toString(): '-'}</span> {allResourcesToTranslations.days}</div></li>
              <li className={styles.mgrNotifyList}><div className={styles.notationText}>{allResourcesToTranslations.managerevery} <span style={{textDecoration: 'underline'}}>{showManagerNotification ? managerNotificationEveryDaysNo.toString() : '-'}</span> {reminderType === 'c' ? allResourcesToTranslations.mgrnotification : reminderType === 'p' ? allResourcesToTranslations.mgrnotificationpolicy : allResourcesToTranslations.mgrnotificationcerty}</div></li>
            </ul>
          </div>
       </div>   
      </>
    );
    return (<></>);
};

export default EmailReminderTimeline;