//React + Typescript
import React, { useContext, useEffect, useState, useRef } from 'react';
// Libsimport
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../../contexts/UIContext';
import { LoaderContext } from '../../../contexts/LoaderContext';
import { TranslationsContext } from '../../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';
//Intro Lib
import { Steps, Hints } from "intro.js-react";
//API Functions 
import styles from "../../../styles/organisationmanagement.module.css";

//Components
import CustomTooltip from '../../../Components/CustomTooltip';
import LoaderContainer from '../../../Components/LoaderContainer';
import GuideIcon from '../../../CustomIcons/GuideIcon';
//MUI Components
import Button from '@material-ui/core/Button';
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';
import { Checkbox, CircularProgress, TextField } from '@material-ui/core';

import UnbrandedCard from '../../../Components/UnbrandedCard';
import SearchIcon from "@material-ui/icons/Search";
import ResetIcon from "../../../CustomIcons/ResetIcon";
//API
import GetArchiveUsersList from '../../../API/Administration/Users/GetArchiveUsersList';
import UserListSection from '../AccountManagement/UserListSection';
import SaveIcon from "../../../CustomIcons/SaveIcon";
import SaveArchiveUsers from '../../../API/Administration/Users/SaveArchiveUsers';

const ArchiveAccounts = (props: any): JSX.Element => {
    const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext)
    const { color } = useContext(CorperateThemeContext).theme;
    const { setMessage } = useContext(LoaderContext);
    const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection, showBackButton } = useContext(UIContext);
    const [loading, setLoading] = useState<boolean>(true);
    const { oidcUser } = useReactOidc();
    //user section
    const [usersList, setUsersList] = React.useState<Array<any>>([]);

    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
    const [saveSuccess, setSaveSuccess] = React.useState(false);
    const [modalPopupMessage, setModalPopupMessage] = useState('');
    const [hasErrored, setHasErrored] = useState<boolean>(false);
    const [filterDate, setFilterDate] = useState("");
    const [filterIncludeAccounts, setFilterIncludeAccounts] = useState<boolean>(false);
    const [selectedUserIds, setselectedUserIds] = useState<string>("");
    const [isSaving, setIsSaving] = useState(false);

    const [saveClicked, setSaveClicked] = useState(false);
  //user section

 

  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";

  const [userDetails, setUserDetails] = React.useState({
    firstName: '',
    lastName: ''
  })

  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".Date",
        title: "Date",
        intro: "Specify a date to select Accounts that have not logged in since this date."
      }
    ]
  });

  const setGuideMe = (clearOldList: boolean = false) => {
    let updateSteps = clearOldList ? [] : [...intro.steps]
    updateSteps.push(

      {
        element: ".Date",
        title: allResourcesToTranslations.archivedatetitle,
        intro: allResourcesToTranslations.archivedatemsg
      },
      {
        element: ".IncludeAccounts",
        title: allResourcesToTranslations.archiveincludeaccountstitle,
        intro: allResourcesToTranslations.archiveincludeaccountsmsg
      },
      {
        element: ".reset",
        title: allResourcesToTranslations.archiveresettitle,
        intro: allResourcesToTranslations.archiveresetmsg

      },
      {
        element: ".searchresult",
        title: allResourcesToTranslations.archivesearchtitle,
        intro: allResourcesToTranslations.archivesearchmsg
      });
    if (usersList.length > 0) {
      updateSteps.push(       
        {
          element: "#acMgmtAssignCol",
          title: allResourcesToTranslations.archiveaccounts,
          intro: allResourcesToTranslations.archivearchivemsg
        },
        {
          element: "#acMgmtUPCol",
          title: allResourcesToTranslations.unitpathwaydetails,
          intro: allResourcesToTranslations.archiveupmsg
          
        },
        {
          element: "#acMgmtLNCol",
          title: allResourcesToTranslations.lastname,
          intro: allResourcesToTranslations.archivelnmsg
        },
        {
          element: "#acMgmtFNCol",
          title: allResourcesToTranslations.firstname,
          intro: allResourcesToTranslations.archivefnmsg
        },
        {
          element: "#acMgmtEmailCol",
          title: allResourcesToTranslations.lastname,
          intro: allResourcesToTranslations.archiveemailmsg 
        },
        {
          element: "#acMgmtLastLoginCol",
          title: allResourcesToTranslations.archivelastlogintitle,
          intro: allResourcesToTranslations.archivelastloginmsg  
        },
        {
          element: ".Save",
          title: allResourcesToTranslations.archivesavetitle, 
          intro: allResourcesToTranslations.archivesavemsg 
        });
    }

    setIntro({ ...intro, steps: [...updateSteps] });
  };

  //#region user Section 
  const handleSearch = () => {   
   
      GetArchiveUsersList(oidcUser.access_token, userId, orgDomain, userDetails.firstName, userDetails.lastName, parseInt(orgId),
      ((filterDate === null || filterDate === undefined || filterDate.trimLeft().trimRight() === "") ? "1996-12-31" :  filterDate),
      filterIncludeAccounts
      ).then(
        (response) => {
          if (response !== null && response !== undefined && response.isSuccess &&
            response.getArchiveUsersItems !== null && response.getArchiveUsersItems !== undefined) {
            let filterusertype = response.getArchiveUsersItems;
            setUsersList(filterusertype);

            if (filterusertype.length <= 0) {
              setShowWarningModal(true);
              setModalPopupMessage('No records found with this selection. Please change the criteria and try again.');    
              setHasErrored(false);   
              setSaveSuccess(false);       
            }
          }

        }
      );
   
  };
  
  const handleClosePopup = () => {
    setShowWarningModal(false);
  };
  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  useEffect(() => {
    setTitle(allResourcesToTranslations.archiveaccounts)   
    setTitleEnglish(pageTitles.archiveaccounts);
    setMenuSelection(allResourcesToTranslations.archiveaccounts)
    setLoading(false);
    return () => { };
  }, []);

  useEffect(() => {
    setGuideMe(true);
  }, [usersList]);

    const handleResetData = () => {
    userDetails.firstName = '';
    userDetails.lastName = '';
    setFilterIncludeAccounts(false);
    setFilterDate("");
    setUsersList([]);
  }
  //endregion
  const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
  {
      if (!isNaN(givenDate.getTime())) {
          const year = new Intl.DateTimeFormat('en-AU', {year: 'numeric'}).format(givenDate);
          const month = new Intl.DateTimeFormat('en-AU', {month: 'numeric'}).format(givenDate);
          const day = new Intl.DateTimeFormat('en-AU', {day: 'numeric'}).format(givenDate);
  
          return (year.toString() + "-" + 
          (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" + 
          (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
      }
      else {
          return "";
      }
  }

  const setupdatedSelectedUsersFunction = (usersData: Array<any>[]) => {
  let userGrantedList = !!usersData !== true ? null : usersData.filter((item: any) => item.granted === true);
  const selectedIds = !!userGrantedList ? userGrantedList.map( ({userID}: any) => userID) : [];
  setselectedUserIds(selectedIds.join(',')); 
  }

  const handleSubmit = () => {
    let errorString = '';
    if (selectedUserIds === "") {
      errorString = errorString + 'Please select the accounts;'
    }
    if (errorString !== '') {
      setShowWarningModal(true);
      setModalPopupMessage(errorString);
      setHasErrored(true);
      return;
    }
  
    setIsSaving(true);
    setSaveClicked(true);
    setShowWarningModal(false);
    SaveArchiveUsers(
      oidcUser.access_token,
      userId,
      orgDomain,
      parseInt(orgId),
      selectedUserIds
    ).then((result) => {
      if (result !== null) {
        if (result.isSuccess && result.error == "" && result.addedUnitID != -1) {         
          setShowWarningModal(true);
          setModalPopupMessage('Accounts archived successfully!');
          setSaveSuccess(true);
          setHasErrored(false);
          setIsSaving(false);
          setSaveClicked(false);          
          setselectedUserIds("");  
          handleResetData();
          handleSearch();
          
        } else {
          setShowWarningModal(true);
          setModalPopupMessage(result.error);          
          setHasErrored(true);
          setIsSaving(false);
          setSaveClicked(false);
          return;
        }
      } else {
        setShowWarningModal(true);
        setModalPopupMessage('Archiving accounts failed, please try again.');
        setHasErrored(true);
        setIsSaving(false);
        setSaveClicked(false);
        return;
      }
    });
  };


  if (loading) return <LoaderContainer />;
  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }} />
      <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific} style={{ right: showBackButton ? '95px' : '25px', top: '100px' }}>
          <GuideIcon />
        </span>
      </CustomTooltip>
      <UnbrandedCard>
       
        <div className={styles.mainWrapper}>
          <div className={styles.Wrapper}>
            <div style={{ width: '100%' }} >
              <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                <b className={styles.labelText}>{allResourcesToTranslations.archiveaccountstitle}</b>
                <p
                  style={{
                    color: "#D3D6DF",
                    fontSize: "12px",
                    fontStyle: "italic",
                  }}> {allResourcesToTranslations.archiveaccountssubtitle}</p>
                <div
                  style={{
                    borderRadius: "5px",
                    width: "99%",
                  }}>
                  <>
                    <div>
                      <div  style={{ backgroundColor: '#fff', paddingTop:'10px', paddingBottom:'10px', display:'flex;' }}>
                        <div className={`${styles.listField} Date`} style={{ width: '25%', float:'left' }}>   
                            <TextField className={`${styles.input}`} label={"Date"} variant="outlined" autoComplete="off"  
                            InputLabelProps={{shrink: true,}}                      
                            type="date" defaultValue={filterDate} onChange={(e) => setFilterDate(formatDate(new Date(e.target.value)))}/>  
                        </div>
                       
                        <div className={`${styles.listField} IncludeAccounts`} style={{ width: '75%',float:'left' }}>
                            <div  style={{ marginLeft:'10px', backgroundColor:'#eee',textAlign:'left' }}>
                                      <Checkbox
                                          size="small"
                                          color="primary"
                                          checked={filterIncludeAccounts}
                                          className={styles.checkBox}
                                          onClick={() => setFilterIncludeAccounts(!filterIncludeAccounts)}
                                  /> {allResourcesToTranslations.archiveaccountsnotes}  
                              </div> 
                        </div>
                      </div>

                      <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: '35px' }}>
                        <div className="reset" style={{ marginTop: '5px' }}>
                          <CustomTooltip displayText={["Reset"]}>
                            <span className={styles.emailResetIcon} onClick={handleResetData}>
                              <ResetIcon />
                            </span>
                          </CustomTooltip>
                        </div>
                        <div className="searchresult" >
                          <CustomTooltip displayText={["Search"]}>
                            <Button
                              onClick={handleSearch}
                              className={styles.moduleListButton}
                              variant="contained"
                              color="primary"
                            >
                              <SearchIcon />
                            </Button>
                          </CustomTooltip>
                        </div>

                      </div>

                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </UnbrandedCard>
    <div style={{ position: 'fixed',  zIndex: 10000}}>
        {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
    </div>
    { usersList != null && usersList.length > 0 && (
        <div id="userList" style={{width: "100%"}}>
          <UserListSection usersData={usersList} isCalledFrom="ArchiveUsers"  setUpdatedSelectedUsers={setupdatedSelectedUsersFunction}
          profileID={-1} profilePeriodID={-1} selectedUnitIDs={[]} allUnitIDs={[]} filterFirstName={""} filterLastName={""} filterUserName={""} filterEmail={""}/>
        </div>
      )}                 
      <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", paddingTop: "10px" }}>
                      <CustomTooltip displayText={["Save"]}>
                        <span className="Save">
                          <Button disabled={( selectedUserIds === "" || isSaving)}
                            onClick={handleSubmit}
                            variant="contained"
                            color="primary"
                            className="save">
                            <SaveIcon />
                          </Button>
                        </span>
                      </CustomTooltip>
                      <div className={styles.loaderContainer}>
                        <CircularProgress
                          style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
                          color="primary"
                          size="10"/>
                      </div>
                </div>       
    
     
    </>
  );
};
export default ArchiveAccounts;
