//React + Typescript
import React, { useContext, useEffect, useState, useRef } from 'react';

import { useHistory, useLocation, useParams } from "react-router";
// Libsimport
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../../contexts/UIContext';
import { LoaderContext } from '../../../contexts/LoaderContext';
import { TranslationsContext } from '../../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';
//Intro Lib
import { Steps, Hints } from "intro.js-react";
//API Functions 
import CloseWhiteIcon from "@material-ui/icons/Close";
import Modal from '../../../Components/Modal';
import ProceedIcon from "../../../CustomIcons/ProceedIcon";
import styles from "../../../styles/organisationmanagement.module.css";


//General Functions
import getColor from '../../../functions/getColor';
//Components
import CustomTooltip from '../../../Components/CustomTooltip';
import LoaderContainer from '../../../Components/LoaderContainer';
import GuideIcon from '../../../CustomIcons/GuideIcon';
//MUI Components
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import BrandedCard from '../../../Components/BrandedCard';
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';
import UpArrowIcon from '../../../CustomIcons/UpArrowIcon';
import DownArrowIcon from '../../../CustomIcons/DownArrowIcon';
import GetOrgAdmins from '../../../API/Administration/Organisation/GetOrgAdmins';
import { Checkbox, FormControl, TableContainer, TablePagination, TextField } from '@material-ui/core';
import { OrgAdmins } from '../../../globalTypes';
import DeleteIcon from '../../../CustomIcons/DeleteIcon';

import EditIcon from "../../../CustomIcons/SupportDetailsEditIcon";
import UnbrandedCard from '../../../Components/UnbrandedCard';
import SearchIcon from "@material-ui/icons/Search";
import ResetIcon from "../../../CustomIcons/ResetIcon";
//API
import getUsersList from "../../../API/GetUsersList";
import SaveOrgAdministrator from '../../../API/Administration/Organisation/SaveOrgAdministrator';
import OrganisationAddAdminIcon from '../../../CustomIcons/Administration/OrganisationAddAdminIcon';

const OrganisationAdministratordetails = (props: any): JSX.Element => {
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext)
  const { color } = useContext(CorperateThemeContext).theme;
  const { setMessage } = useContext(LoaderContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection } = useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { oidcUser } = useReactOidc();
  //user section
  const [usersList, setUsersList] = React.useState<Array<any>>([]);
  const [firstNameDescending, setFirstNameDescending] = React.useState<boolean>(false)
  const [lastNameDescending, setLastNameDescending] = React.useState<boolean>(false)
  const [emailDescending, setEmailDescending] = React.useState<boolean>(false)
  const [currentSortBy, setCurrentSortBy] = React.useState<string>("")
  const [reverseNextSort, setReverseNextSort] = React.useState<boolean>(false)
  const [sortInProgress, setSortInProgress] = React.useState<boolean>(false)
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true)
  const [page, setPage] = React.useState<number>(0);

  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);

  //user section



  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";

  const [userDetails, setUserDetails] = React.useState({
    firstName: '',
    lastName: ''
  })



  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".firstName",
        title: "First and\or Last Name",
        intro: "Enter First and\or Last Name as search terms to find an account to add as an organisation admin"
      }
    ]
  });

  const setGuideMe = (clearOldList: boolean = false) => {
    let updateSteps = clearOldList ? [] : [...intro.steps]
    updateSteps.push(

      {
        element: ".firstName",
        title: "First and\or Last Name",
        intro: "Enter First and\or Last Name as search terms to find an account to add as an organisation admin"
      },
      {
        element: ".search",
        title: "Search",
        intro: "Click search once you have entered your search term(s) above."
      },
      {
        element: ".reset",
        title: "Reset Filters",
        intro: "Click to reset filters."

      });
    if (usersList.length > 0) {
      updateSteps.push(
        {
          element: ".searchresult",
          title: "Search Results",
          intro: "Results from your search will appear here, listing First and Last Name, as well as email address.",
        },
        {
          element: ".AddAdmin",
          title: "Add Administrator",
          intro: "Click here to add this account as an organisation admin.",
        });
    }

    setIntro({ ...intro, steps: [...updateSteps] });
  };

  //#region user Section 
  const handleSearch = () => {
    if (userDetails === null || userDetails === undefined ||
      ((userDetails.lastName === null || userDetails.lastName === undefined || userDetails.lastName.trimLeft().trimRight() === "")) &&
      (userDetails.firstName === null || userDetails.firstName === undefined || userDetails.firstName.trimLeft().trimRight() === "")) {
      setUsersList([]);
    }

    if (userDetails.firstName.trimLeft().trimRight() != "" || userDetails.lastName.trimLeft().trimRight() != "") {
      getUsersList(oidcUser.access_token, userId, orgDomain, userDetails.firstName, userDetails.lastName, parseInt(orgId)).then(
        (response) => {
          if (response !== null && response !== undefined && response.isSuccess &&
            response.listOfUsers !== null && response.listOfUsers !== undefined) {
            let filterusertype = response.listOfUsers.filter((u: any) => u.userTypeId === 3 || u.userTypeId === 4);
            setUsersList(filterusertype);

            if (filterusertype.length <= 0) {
              setShowWarningModal(true);
              setModalPopupMessage('No records found, please try again!!');
              setHasErrored(true);

            }
          }

        }
      );
    }
    else {
      setShowWarningModal(true);
      setModalPopupMessage('Enter as many details as required to search for the account.');
      setHasErrored(false);

    }
  };

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" }
    } else {
      return {}
    }
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortResultsByAsync = async (sortBy: "FirstName" | "LastName" | "Email", sortGivenItemInDesc: boolean) => {
    if (usersList == null || usersList.length <= 0) {
      return usersList;
    }
    var result = usersList.slice(0)
    setCurrentSortBy(sortBy)
    switch (sortBy) {

      case "Email":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.email === null) { return -1; }
            if (a === null || a.email === null) { return 1; }
            if (a.email.toLowerCase() > b.email.toLowerCase()) { return -1; }
            if (a.email.toLowerCase() < b.email.toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setEmailDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.email === null) { return 1; }
            if (a === null || a.email === null) { return -1; }
            if (a.email.toLowerCase() < b.email.toLowerCase()) { return -1; }
            if (a.email.toLowerCase() > b.email.toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setEmailDescending(true)
          }
        }
        setUsersList(result);
        break;
      case "FirstName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.firstName === null) { return -1; }
            if (a === null || a.firstName === null) { return 1; }
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) { return -1; }
            if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setFirstNameDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.firstName === null) { return 1; }
            if (a === null || a.firstName === null) { return -1; }
            if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) { return -1; }
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setFirstNameDescending(true)
          }
        }
        setUsersList(result);
        break;
      case "LastName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) { return -1; }
            if (a === null || a.lastName === null) { return 1; }
            if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) { return -1; }
            if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setLastNameDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) { return 1; }
            if (a === null || a.lastName === null) { return -1; }
            if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) { return -1; }
            if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setLastNameDescending(true)
          }
        }
        setUsersList(result);
        break;


      default:
        break;
    }
    return result.slice(0);
  }

  const sortResultsBy = async (sortBy: "FirstName" | "LastName" | "Email", sortGivenItemInDesc: boolean) => {
    setSortInProgress(true);
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy)
      setUsersList(result)
      setPage(0)
      setSortInProgress(false);
    });
  }

  const clickFirstNameAsc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(false);
    sortResultsBy("FirstName", false);
  }

  const clickFirstNameDesc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(true)
    sortResultsBy("FirstName", true)
  }

  const clickLastNameAsc = () => {
    setReverseNextSort(false);
    setLastNameDescending(false);
    sortResultsBy("LastName", false);
  }

  const clickLastNameDesc = () => {
    setReverseNextSort(false);
    setLastNameDescending(true)
    sortResultsBy("LastName", true)
  }

  const clickEmailAsc = () => {
    setReverseNextSort(false);
    setEmailDescending(false);
    sortResultsBy("Email", false);
  }

  const clickEmailDesc = () => {
    setReverseNextSort(false);
    setEmailDescending(true)
    sortResultsBy("Email", true)
  }
  const handleClosePopup = () => {
    setShowWarningModal(false);
  };
  const handleUserDetails = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {

    setUserDetails({
      ...userDetails,
      [event.target.name]: event.target.value,
    });

  };
  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };
  const handleAdd = (adduserID: number) => {
    SaveOrgAdministrator(oidcUser.access_token, userId, orgDomain, parseInt(orgId), adduserID)
      .then((res) => {
        if (!!res && res.isSuccess) {
          setShowWarningModal(true);
          setHasErrored(false);
          setSaveSuccess(true);
          setModalPopupMessage("Organisation Administrators have been saved successful. ");
          handleSearch();
        }
        else {
          setShowWarningModal(true);
          setHasErrored(true);
          setSaveSuccess(false);
          setModalPopupMessage("Organisation Administrators save have been failed.");
        }
      })
  }


  useEffect(() => {
    setTitle(allResourcesToTranslations.addneworgadmin)
    setTitleEnglish(pageTitles.orgadmindetails);
    updateBackButtonRoute("/organisationadmin");
    setMenuSelection(allResourcesToTranslations.addneworgadmin)
    setLoading(false);
    return () => { };
  }, []);

  useEffect(() => {
    setGuideMe(true);
  }, [usersList]);



  const handleResetData = () => {
    userDetails.firstName = '';
    userDetails.lastName = '';

    setUsersList([]);
  }
  //endregion

  if (loading) return <LoaderContainer />;
  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }} />
      <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific} style={{ right: '95px', top: '100px' }}>
          <GuideIcon />
        </span>
      </CustomTooltip>
      <UnbrandedCard>
       
        <div className={styles.mainWrapper}>
          <div className={styles.Wrapper}>
            <div style={{ width: '100%' }} >
              <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                <b className={styles.labelText}>{allResourcesToTranslations.addneworgadmin}</b>
                <p
                  style={{
                    color: "#D3D6DF",
                    fontSize: "12px",
                    fontStyle: "italic",
                  }}> Enter as many details as required to search for the account</p>
                <div
                  style={{
                    borderRadius: "5px",
                    width: "99%",
                  }}>
                  <>
                    <div>
                      <div className={styles.expandContainer} style={{ backgroundColor: '#fff' }}>
                        <div className={`${styles.listField}`} style={{ marginLeft: '30px' }}>
                          <TextField
                            className={`${styles.input} firstName`}
                            label={"First Name"}
                            name="firstName"
                            variant="outlined"
                            autoComplete="off"
                            InputLabelProps={{ shrink: true }}
                            value={userDetails.firstName}
                            onChange={handleUserDetails}
                            style={{ marginLeft: "-30px" }}
                          />
                        </div>

                        <div className={`${styles.listField}`}>
                          <TextField
                            className={`${styles.input} lastName`}
                            label={"Last Name"}
                            name="lastName"
                            variant="outlined"
                            autoComplete="off"
                            InputLabelProps={{ shrink: true }}
                            value={userDetails.lastName}
                            onChange={handleUserDetails}
                          />
                        </div>
                      </div>

                      <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: '35px' }}>
                        <div className="reset" style={{ marginTop: '5px' }}>
                          <CustomTooltip displayText={["Reset"]}>
                            <span className={styles.emailResetIcon} onClick={handleResetData}>
                              <ResetIcon />
                            </span>
                          </CustomTooltip>
                        </div>
                        <div className="search" >
                          <CustomTooltip displayText={["Search"]}>
                            <Button
                              onClick={handleSearch}
                              className={styles.moduleListButton}
                              variant="contained"
                              color="primary"
                            >
                              <SearchIcon />
                            </Button>
                          </CustomTooltip>
                        </div>

                      </div>

                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </UnbrandedCard>
    {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
     <div style={{ paddingTop: "20px"}}>
      {usersList != null && usersList.length > 0 && (
         <UnbrandedCard>
            <div style={{ padding: "15px" }}>           
              <div className={styles.expandContainer} style={{ backgroundColor: '#fff' }}>
                <div className={styles.listField}><b>Search Results</b></div>
                <div className={styles.lowerButtonPanel}><b>{usersList.length.toString()} Results</b></div>
              </div>
              <div className={styles.paginationControlsContainer}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={usersList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
              <div className={`${styles.tableSection} accountlist`}>
                <div className={styles.tableContainer}>
                  <BrandedCard>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow className='searchresult'>
                            <TableCell
                              style={{
                                color: getColor(color.substring(1)),
                                backgroundColor: color, borderRadius: "8px 0 0 0",
                              }}
                              align="left"
                            >
                              <div className={styles.tableHeadCellContainer}>
                                <div
                                  onClick={() => {
                                    setLastNameDescending(!lastNameDescending);
                                    sortResultsBy("LastName", !lastNameDescending);
                                  }}
                                >
                                  {"Last Name" /* TODO translate */}
                                </div>
                                <div className={styles.columnHeadArea}>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "LastName" &&
                                          lastNameDescending === false
                                          ? "100%"
                                          : "50%",
                                      margin: "2px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickLastNameAsc}
                                  >
                                    <UpArrowIcon />{" "}
                                  </span>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "LastName" &&
                                          lastNameDescending === true
                                          ? "100%"
                                          : "50%",
                                      margin: "0px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickLastNameDesc}
                                  >
                                    <DownArrowIcon />{" "}
                                  </span>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                color: getColor(color.substring(1)),
                                backgroundColor: color,
                              }}
                              align="left"
                            >
                              <div className={styles.tableHeadCellContainer}>
                                <div
                                  onClick={() => {
                                    setFirstNameDescending(!firstNameDescending);
                                    sortResultsBy(
                                      "FirstName",
                                      !firstNameDescending
                                    );
                                  }}
                                >
                                  {"First Name" /* TODO translate */}
                                </div>
                                <div className={styles.columnHeadArea}>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "FirstName" &&
                                          firstNameDescending === false
                                          ? "100%"
                                          : "50%",
                                      margin: "2px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickFirstNameAsc}
                                  >
                                    <UpArrowIcon />{" "}
                                  </span>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "FirstName" &&
                                          firstNameDescending === true
                                          ? "100%"
                                          : "50%",
                                      margin: "0px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickFirstNameDesc}
                                  >
                                    <DownArrowIcon />{" "}
                                  </span>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                color: getColor(color.substring(1)),
                                backgroundColor: color
                              }}
                              align="left"
                            >
                              <div className={styles.tableHeadCellContainer}>
                                <div
                                  onClick={() => {
                                    setEmailDescending(!emailDescending);
                                    sortResultsBy("Email", !emailDescending);
                                  }}
                                >
                                  {"Email" /* TODO translate */}
                                </div>
                                <div className={styles.columnHeadArea}>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "Email" &&
                                          emailDescending === false
                                          ? "100%"
                                          : "50%",
                                      margin: "2px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickEmailAsc}
                                  >
                                    <UpArrowIcon />{" "}
                                  </span>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "Email" &&
                                          emailDescending === true
                                          ? "100%"
                                          : "50%",
                                      margin: "0px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickEmailDesc}
                                  >
                                    <DownArrowIcon />{" "}
                                  </span>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                width: "200px",
                                color: getColor(color.substring(1)),
                                backgroundColor: color,
                                borderRadius: "0 8px 0 0",
                              }}
                              align="center">
                              <div className={styles.tableHeadCellContainer}>
                                <div>
                                  {allResourcesToTranslations.actions}
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {usersList
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((result: any, i) => {
                              return (
                                <TableRow
                                  key={`reportScheduler-${i}`}
                                  style={rowBackgroundColor(i)}>
                                  <TableCell align="left">
                                    {result.lastName}
                                  </TableCell>
                                  <TableCell align="left">
                                    {result.firstName}
                                  </TableCell>
                                  <TableCell align="left">{result.email}</TableCell>
                                  <TableCell align="center" className="AddAdmin">
                                    <CustomTooltip displayText={["Add Organisation Admin"]}>
                                      <Button  style={{backgroundColor: "#20B786"}} onClick={() => handleAdd(result.userID)} className={styles.moduleListButton} variant="contained" color="primary">
                                        <OrganisationAddAdminIcon />
                                      </Button>
                                    </CustomTooltip>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </BrandedCard>
                </div>
              </div>
              <div className={styles.paginationAddedUsersControl}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={usersList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>         
            </div>
        </UnbrandedCard>        
      )}
      </div>
      
      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}

     
    </>
  );
};
export default OrganisationAdministratordetails;
