import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const TemplateIcon = () => (    
 
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 18.8123H3" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21 14.8123H3" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17 10.8123H3" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21 6.81229H3" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


    
);

export default TemplateIcon
