//React + Typescript
import React, { useContext } from 'react';
import { QuizQADetailItem } from '../../globalTypes';
//General Functions
import getColor from '../../functions/getColor';
//Context
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Components
import UnbrandedCard from '../../Components/UnbrandedCard';
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';
//MUI Components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

interface Props {
    quizQADetailItems: Array<QuizQADetailItem> | null
}

export const ReportSummaryReportCard = ({quizQADetailItems}: Props) => {
  const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
  const { color } = useContext(CorperateThemeContext).theme

  const renderQuestionRows = (quizItemList: Array<QuizQADetailItem> | null) => {
      if (quizItemList !== null) {
          return quizItemList.map((qi, i) => (
              <TableRow key={i} >
                  <TableCell align="left">
                    {qi.question}
                  </TableCell>
                  <TableCell align="center">
                    {qi.correctAnswer}
                  </TableCell>
                  <TableCell align="center">
                    {qi.givenAnswer}
                  </TableCell>
                  <TableCell align="center">
                    {
                      qi.isCorrect? <span style={{color: "#23b787"}}> {allResourcesToTranslations.correct} </span> : <span style={{color: "#fe0606"}}> {allResourcesToTranslations.incorrect} </span>
                    }
                  </TableCell>
              </TableRow> 
          ))
      }
  }

  return (
      <UnbrandedCard>
      <Table>
        <TableHead>
          <TableRow >
            <TableCell style={{ color:  getColor(color.substring(1)), backgroundColor:color, borderRadius: "8px 0 0 0"}} align="left">
              {allResourcesToTranslations.question}
            </TableCell>
            <TableCell style={{ color:  getColor(color.substring(1)), backgroundColor:color}} align="center">
              {allResourcesToTranslations.correct}
            </TableCell>
            <TableCell style={{ color:  getColor(color.substring(1)), backgroundColor:color}} align="center">
              {allResourcesToTranslations.youranswer}
            </TableCell>
            <TableCell style={{ color:  getColor(color.substring(1)), backgroundColor:color, borderRadius: "0 8px 0 0"}} align="center">
              {allResourcesToTranslations.status}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderQuestionRows(quizQADetailItems)}
        </TableBody>
      </Table>
    </UnbrandedCard>
  )
}

export default ReportSummaryReportCard
