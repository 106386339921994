import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const CollapseAllIcon = () => (
    <svg  width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.20021 3.19974V16.4002H1.00012V0.999653H16.4007V3.19974H3.20021Z" fill="#FFFFFF"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M22.9999 7.60035V23.0009H7.59927V7.60035H22.9999ZM9.79936 9.80043H20.7998V20.8008H9.79936V9.80043Z" fill="#FFFFFF"/>
    <path d="M6.49978 6.50008H19.7003V4.3H4.2997V19.7006H6.49978V6.50008Z" fill="#FFFFFF"/>
    <rect x="12.5698" y="14.4055" width="5.50953" height="1.83651" fill="#FFFFFF"/>
    </svg>
);

export default CollapseAllIcon
