import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const ExcelFileIcon = () => (
  // <SvgIcon>
  //   <path d="M19.5,2h-15C3.1,2,2,3.1,2,4.5v15C2,20.9,3.1,22,4.5,22h15c1.4,0,2.5-1.1,2.5-2.5v-15C22,3.1,20.9,2,19.5,2z M16.5,17.5
  //       c-0.6,0-1.2,0-1.8,0c-0.1,0-0.2,0-0.3-0.2c-0.7-1.3-1.5-2.5-2.2-3.8c0-0.1-0.1-0.1-0.1-0.2c0,0.1-0.1,0.1-0.1,0.2
  //       c-0.7,1.3-1.5,2.5-2.2,3.8c-0.1,0.1-0.1,0.2-0.3,0.2c-0.6,0-1.2,0-1.8,0c-0.1,0-0.1,0-0.2,0c0-0.1,0.1-0.1,0.1-0.2
  //       c1.1-1.7,2.2-3.4,3.3-5.1c0.1-0.1,0.1-0.2,0-0.3c-1.1-1.7-2.2-3.4-3.3-5.1c0-0.1-0.1-0.1-0.1-0.2c0.5,0,0.9,0,1.4,0
  //       c0.2,0,0.4,0,0.6,0c0.1,0,0.2,0,0.3,0.2c0.7,1.3,1.5,2.5,2.2,3.8c0,0.1,0.1,0.1,0.1,0.2c0-0.1,0.1-0.1,0.1-0.2
  //       c0.7-1.3,1.5-2.5,2.2-3.8c0.1-0.1,0.1-0.2,0.3-0.2c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0c0,0.1-0.1,0.1-0.1,0.2
  //       c-1.1,1.7-2.2,3.4-3.3,5.1c-0.1,0.1-0.1,0.2,0,0.3c1.1,1.7,2.2,3.4,3.3,5.1c0,0.1,0.1,0.1,0.1,0.2C16.6,17.5,16.5,17.5,16.5,17.5z"
  //       />
  // </SvgIcon>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.4217 0.62146H5.27207C4.96548 0.62146 4.71696 0.869979 4.71696 1.17656V22.8255C4.71696 23.1321 4.96548 23.3806 5.27207 23.3806H21.37C21.6766 23.3806 21.9251 23.1321 21.9251 22.8255V6.12485C21.9251 5.97764 21.8666 5.83645 21.7625 5.73234L16.8142 0.784049C16.7101 0.67994 16.5689 0.62146 16.4217 0.62146Z" fill="white"></path>
<path fillRule="evenodd" clipRule="evenodd" d="M5.27372 0C4.6245 0 4.09821 0.526285 4.09821 1.17551V22.8245C4.09821 23.4737 4.6245 24 5.27372 24H21.3717C22.0209 24 22.5472 23.4737 22.5472 22.8245V6.1238C22.5472 5.81204 22.4233 5.51305 22.2029 5.29259L17.2546 0.344303C17.0341 0.123847 16.7352 0 16.4234 0H5.27372ZM5.33903 22.7592V1.24082H15.7554V5.61601C15.7554 6.26523 16.2816 6.79152 16.9309 6.79152H21.3064V22.7592H5.33903ZM20.7062 5.5507L16.9962 1.84065V5.5507H20.7062Z" fill="#797774"></path>
<path d="M1.1102 19.2181H9.99184C10.605 19.2181 11.102 18.7211 11.102 18.1079V9.22629C11.102 8.61315 10.605 8.11609 9.99184 8.11609H1.1102C0.497066 8.11609 0 8.61315 0 9.22629V18.1079C0 18.7211 0.497066 19.2181 1.1102 19.2181Z" fill="#107C41"></path>
<path d="M2.48304 16.9978L4.67147 13.6578L2.66708 10.3365H4.28007L5.37429 12.4594C5.47484 12.6607 5.54395 12.811 5.58164 12.9101H5.59574C5.66838 12.7491 5.74373 12.5926 5.82234 12.441L6.99188 10.3365H8.47262L6.41646 13.6394L8.52466 16.9978H6.94933L5.68545 14.6659C5.63205 14.5794 5.58164 14.4755 5.53449 14.3546H5.51578C5.49383 14.4291 5.44503 14.5297 5.36968 14.6565L4.06785 16.9978H2.48304Z" fill="white"></path>
<rect x="13.3219" y="16.6018" width="5.68996" height="1.17455" fill="#134A2C"></rect>
<rect x="13.3219" y="13.2697" width="5.68996" height="1.17455" fill="#107C41"></rect>
<rect x="13.3219" y="9.94019" width="5.68996" height="1.17455" fill="#33C481"></rect>
</svg>
);

export default ExcelFileIcon