//React + Typescript
import React, {useEffect, useContext} from 'react';
import { Link, useParams } from 'react-router-dom';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { SessionContext } from '../../contexts/SessionContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
//API Functions
import { addError } from '../../API/AddError'
//Components
import LoaderContainer from '../../Components/LoaderContainer';
//MUI Components
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import PageviewIcon from '@material-ui/icons/Pageview';
//Styles
import styles from '../../styles/quizResults.module.css';

interface Props {
    resultData: any,
    loading: boolean,
    handleModalClose: () => void,
    curSelectedModuleTechType: string
}

export const ResultsModalContent = ({resultData, loading, handleModalClose, curSelectedModuleTechType}: Props): JSX.Element => {
    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
    const { setShowResultsModal } = useContext(SessionContext) 
    const { updateBackButtonRoute, EnableActivityLogs } = useContext(UIContext) 
    let { courseId } = useParams<{ courseId: string }>();
    const { oidcUser } = useReactOidc();
    const orgDomain = oidcUser.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0;

    useEffect(() => {
      return () => {
        addError(oidcUser.access_token, userId, orgDomain, "Closed results popup", null, "ResultsModalContent.tsx", 6, EnableActivityLogs)
        setShowResultsModal(false)
      }
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    if (!resultData) return <LoaderContainer/>
    const passed = resultData.quizScore >= resultData.quizPassmark

    const completionMessage = (didPass: boolean) => {
      if (didPass) {
        return (
          <div>
            <h2 className={styles.boldText}>
              {allResourcesToTranslations.youthavecompletedyourquiz}
            </h2>
            <h3>
              {allResourcesToTranslations.congratulationsyouhavepassed.replace("{QUIZNAME}", resultData.moduleName)}
            </h3>
            <h3>
              {!!curSelectedModuleTechType && curSelectedModuleTechType.toString().toLowerCase().trim() === "storyline360" ? "" : allResourcesToTranslations.thepassmarkforthisquizis.replace("{PASSMARK}", resultData.quizPassmark.toString()).replace("{SCORE}", resultData.quizScore.toString().trim() == "" ? "0" : resultData.quizScore.toString().trim())}
            </h3>
            <br></br>
            <br></br>
            <div>
              <Link
              to={`/mytraining/${courseId}/quiz/summary`}
              onClick={() => updateBackButtonRoute(`/mytraining/${courseId}`) }
              >
                <Button variant="contained" color="primary">
                  <PageviewIcon />
                   {allResourcesToTranslations.viewquizsummaryreport}
                </Button>
              </Link>
              <Button variant="contained" color="primary" onClick={() => handleModalClose()}>
                <CloseIcon />
                  {allResourcesToTranslations.close}
              </Button>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <h2 className={styles.boldText}>
              {allResourcesToTranslations.youthavecompletedyourquiz}  
            </h2>
            <h3>
              {allResourcesToTranslations.youfailedthequiz.replace("{QUIZNAME}", resultData.moduleName)}
            </h3>
            <h3>
              {allResourcesToTranslations.thepassmarkforthisquizis.replace("{PASSMARK}", resultData.quizPassmark.toString()).replace("{SCORE}", resultData.quizScore.toString().trim() == "" ? "0" : resultData.quizScore.toString().trim())}
            </h3>
            <br></br>
            <br></br>
            <div>
              <Link 
                to={`/mytraining/${courseId}/quiz/summary`}
                onClick={() => updateBackButtonRoute(`/mytraining/${courseId}`) }
                >
                <Button variant="contained" color="primary">
                  <PageviewIcon />
                  {allResourcesToTranslations.viewquizsummaryreport}
                </Button>
              </Link>
              <Button variant="contained" color="primary" onClick={ () => handleModalClose() }>
                <CloseIcon />
                {allResourcesToTranslations.close}
              </Button>
            </div>
          </div>
        );
      }
    };

    return (
        <div className={styles.wrapper}>
            <div style={{display: loading? "none" : "block" }}>
                { completionMessage(passed) }
            </div>
        </div>
    )
}

export default ResultsModalContent;
