import React, { useContext } from 'react'
//Libs
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Components 
import CustomTooltip from '../../Components/CustomTooltip';
import BrandedCard from '../../Components/BrandedCardDashBoard';
//MUI Components
import Button from '@material-ui/core/Button';
import PageviewIcon from '@material-ui/icons/Pageview';
// Styles
import styles from '../../styles/dashboard.module.css'

interface Props {
    isAdmin: boolean;
    handleOpenWelcomeModal: Function;
}

export const WelcomeMessageCard = ({ isAdmin, handleOpenWelcomeModal }: Props ) => {
    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
    return(
		
		<BrandedCard Width='100'>
            <div className="welcome">
        	<div className={styles.showWelcome}>
	            <p style={{height:"45px"}}>
	                {allResourcesToTranslations.clickbelowtoseewelcomemessage}
	            </p>
	            <CustomTooltip displayText={[allResourcesToTranslations.showwelcomemessage]}>
	                <Button variant="contained" color="primary" onClick={() => handleOpenWelcomeModal()}>
	                    <PageviewIcon />
	                </Button>
	            </CustomTooltip>
	        </div>
	    </div>
	</BrandedCard>
	
    )
}

export default WelcomeMessageCard;