import React from 'react';
import styles from '../styles/warningCard.module.css';

const SuccessCard = ({children}: any): JSX.Element => {
  return (
    <div className={styles.warningCard}>
      {children}
      <div className={styles.colorBar} style={{ backgroundColor: "#3CB371" }} />
    </div>
  );
};

export default SuccessCard;
