//React + Typescript
import React, { useEffect, useState, useContext, useRef } from 'react'
import { SelectedSettings, CourseProgressResult, CourseTreeItem, UnitTreeItem } from '../../globalTypes';
//Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
//API Functions
import getRelevantUnitTree from '../../API/GetRelevantUnitTree';
import getCourseListByOrganisation from '../../API/GetCourseListByOrganisation';
//Components
import LoaderContainer from '../../Components/LoaderContainer';
//Styles
import EmailReportSearch from './Search';
import EmailSendPreview from './EmailSendPreview';
import SendProgress from './SendProgress';
import CheckIfAnyEmailReportIsInProgress from '../../API/CheckIfAnyEmailReportIsInProgress';


export const EmailReport = (props: any) => {
    const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
    const [emailPreviewData, setEmailPreviewData] = React.useState({
      selectedEmailReportType: 0,
      selectedUnitIds: "",
      selectedUnitName: "",
      selectedCourseIds: "",
      selectedCourseName: "",
      selectedcourseQuizStatus: "",
      courseQuizStatusId: 0,
      selectedRecipients: "",
      selectedGrouping: "",
      selectedClassificationId: 0,
      inputFilterFromDate: "",
      inputFilterToDate: "",
      inputFilterFirstName: "",
      inputFilterLastName: "",
      inputFilterUserName: "",
      inputFilterEmail: "",
      resultBodyItems: [],
      resultItems: [],
      resultError:[],

      selectedPolicyIDs: "",  
      selectedPolicyStatus: -1,
      selectedCPDProfileStatus: -1,  
      selectedCPDProfileID: -1, 
      selectedCPDProfileName: "",
      newStarterOnlyFlag: false,
      selectedExternalCertificateIDs: "",  
      selectedExternalCertificateStatus: -1
    });
    const [units, setUnits] = useState<Array<UnitTreeItem>>();
    const [courseList, setCourseList] = useState<Array<CourseTreeItem>>();
    const [emailReportId, setEmailReportId] = useState<number>(0);
    const [isSearchPageResetClicked, setSearchPageResetClicked] = useState<boolean>(false);
    const [isPreviewPageResetClicked, setPreviewPageResetClicked] = useState<boolean>(false);
    const [isProcessPageResetClicked, setisProcessPageResetClicked] = useState<boolean>(false);
    const { setTitle, setTitleEnglish, updateBackButtonRoute, menuSelection, setSubComponentName } = useContext(UIContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<string>("");
    const [isQueueInProgress, setIsQueueInProgress] = useState<boolean>(true);
    const [isQueueInProgressChecked, setIsQueueInProgressChecked] = useState<boolean>(false);
    const [unitsLoaded, setUnitsLoaded] = useState<boolean>(false);
    const [courseListLoaded, setCourseListLoaded] = useState<boolean>(false);
    const [isProcessFinished, setIsProcessFinished] = useState<boolean>(false);
    const { oidcUser } = useReactOidc();
    const { profile: { profile, user_id, website }} = oidcUser || {};
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const userId = user_id ? parseInt(user_id) : 0;
    const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";

    const handleSearchClicked = () => {   
      setisProcessPageResetClicked(!isProcessPageResetClicked);  
      setCurrentPage("preview");
      setSubComponentName("preview");    
    }

    const handleProcessFinished = () => {     
      setIsProcessFinished(true);
    }

   const handleBackButtonclick = (isFromMenu: Boolean) => {
      if (currentPage == "preview") {
        if (isFromMenu) {
            setSearchPageResetClicked(!isSearchPageResetClicked);
        }
        setCurrentPage("search"); 
        setSubComponentName("search");       
      }

      if (currentPage == "progress") {

        if (isProcessFinished) {
          // Progress finished for one item, prepare reset for new one
          setSearchPageResetClicked(!isSearchPageResetClicked);
          setPreviewPageResetClicked(!isPreviewPageResetClicked);
          setisProcessPageResetClicked(!isProcessPageResetClicked);
          setEmailReportId(0);
          setEmailPreviewData({
            selectedEmailReportType: 0,
            selectedUnitIds: "",
            selectedUnitName: "",
            selectedCourseIds: "",
            selectedCourseName: "",
            selectedcourseQuizStatus: "",
            courseQuizStatusId: 0,
            selectedRecipients: "",
            selectedGrouping: "",
            selectedClassificationId: 0,
            inputFilterFromDate: "",
            inputFilterToDate: "",
            inputFilterFirstName: "",
            inputFilterLastName: "",
            inputFilterUserName: "",
            inputFilterEmail: "",
            resultBodyItems: [],
            resultItems: [],
            resultError:[],

            selectedPolicyIDs: "",  
            selectedPolicyStatus: -1,
            selectedCPDProfileStatus: -1,  
            selectedCPDProfileID: -1,
            selectedCPDProfileName: "",  
            newStarterOnlyFlag: false,
            selectedExternalCertificateIDs: "",  
            selectedExternalCertificateStatus: -1
          });
          setCurrentPage("search");
          setSubComponentName("search");    
        }
      }

    }

    const handleSendPreviewEmailReport = (createdId: number) => {
      if (createdId > 0) {
        setEmailReportId(createdId);
        setCurrentPage("progress");
        setSubComponentName("progress");    
      }
    }

    const handlePreviewEmailReport = (selectedEmailPreviewData: any) => {
      setPreviewPageResetClicked(!isPreviewPageResetClicked);
      setisProcessPageResetClicked(!isProcessPageResetClicked);
      setEmailPreviewData({
        selectedEmailReportType: selectedEmailPreviewData.selectedEmailReportType,
        selectedUnitIds: selectedEmailPreviewData.selectedUnitIds,
        selectedUnitName: selectedEmailPreviewData.selectedUnitName,
        selectedCourseIds: selectedEmailPreviewData.selectedCourseIds,
        selectedCourseName: selectedEmailPreviewData.selectedCourseName,
        selectedcourseQuizStatus: selectedEmailPreviewData.selectedcourseQuizStatus,
        courseQuizStatusId: selectedEmailPreviewData.courseQuizStatusId,
        selectedRecipients:  selectedEmailPreviewData.selectedRecipients,
        selectedGrouping: selectedEmailPreviewData.selectedGrouping,
        selectedClassificationId: selectedEmailPreviewData.selectedClassificationId,
        inputFilterFromDate: selectedEmailPreviewData.inputFilterFromDate,
        inputFilterToDate:  selectedEmailPreviewData.inputFilterToDate,
        inputFilterFirstName: selectedEmailPreviewData.inputFilterFirstName,
        inputFilterLastName: selectedEmailPreviewData.inputFilterLastName,
        inputFilterUserName: selectedEmailPreviewData.inputFilterUserName,
        inputFilterEmail: selectedEmailPreviewData.inputFilterEmail,
        resultBodyItems: selectedEmailPreviewData.resultBodyItems,
        resultItems: selectedEmailPreviewData.resultItems,
        resultError: selectedEmailPreviewData.resultError,

        selectedPolicyIDs: selectedEmailPreviewData.selectedPolicyIDs,  
        selectedPolicyStatus: selectedEmailPreviewData.selectedPolicyStatus,
        selectedCPDProfileStatus: selectedEmailPreviewData.selectedCPDProfileStatus,  
        selectedCPDProfileID: selectedEmailPreviewData.selectedCPDProfileID,  
        selectedCPDProfileName: selectedEmailPreviewData.selectedCPDProfileName,  
        newStarterOnlyFlag: selectedEmailPreviewData.newStarterOnlyFlag,
        selectedExternalCertificateIDs: selectedEmailPreviewData.selectedExternalCertificateIDs,  
        selectedExternalCertificateStatus: selectedEmailPreviewData.selectedExternalCertificateStatus
      });
      setCurrentPage("preview");
      setSubComponentName("preview");  
    }

    useEffect(() => {  
      setTitle(allResourcesToTranslations.emailreport)
      setTitleEnglish(pageTitles.emailreport)
      updateBackButtonRoute("");
      setLoading(true);

      //Courses
      getCourseListByOrganisation(oidcUser.access_token, userId, orgDomain, parseInt(orgId), true).then((courseListRes) => {
        if (courseListRes.isSuccess) {
          setCourseList(courseListRes.courselistItem);
          setCourseListLoaded(true);
        }
      });
      
      //Units
      getRelevantUnitTree(oidcUser.access_token, userId, orgDomain, true, parseInt(orgId)).then((unitTreeRes) => {
        if (unitTreeRes.isSuccess) {
            setUnits(unitTreeRes.unitTreeItems);
            setUnitsLoaded(true);
          }
      });

      //Existing Queue
      CheckIfAnyEmailReportIsInProgress(oidcUser.access_token, userId ,parseInt(orgId)).then(response => {
        if (response.isSuccess) {
          if (response.isAnyInQueue && response.oldestEmailReportIDInQueue > 0) {
            setEmailReportId(response.oldestEmailReportIDInQueue);

            setIsQueueInProgress(true);
            setCurrentPage("progress");
            setSubComponentName("progress");  
            setIsQueueInProgressChecked(true);
          }
          else {
            setCurrentPage("search");
            setSubComponentName("search");  
            setIsQueueInProgress(false);
            setIsQueueInProgressChecked(true);
          }
        }
      });

    },[])

    useEffect(() => {
      handleBackButtonclick(true);
    }, [menuSelection])

    useEffect(() => {
      if(isQueueInProgressChecked && courseListLoaded && unitsLoaded) {
        setLoading(false);
      }
    }, [isQueueInProgressChecked, courseListLoaded, unitsLoaded])

    useEffect(() => {  
      if (emailReportId > 0) {
        setCurrentPage("progress");
        setSubComponentName("progress");  
      }
    },[currentPage, emailReportId, isSearchPageResetClicked, isPreviewPageResetClicked, emailPreviewData, isProcessFinished])

    if (loading) {
        return <LoaderContainer/>
    }
    return (
        <>
           <div hidden={currentPage !== "search"} >
               <EmailReportSearch handlePreviewEmailReport={handlePreviewEmailReport} setPageReset={isSearchPageResetClicked} isSearchClicked={handleSearchClicked} 
                  units={units} courseList={courseList}></EmailReportSearch>
            </div> 
            <div hidden={currentPage !== "preview"} >
                <EmailSendPreview handleSendPreviewEmailReport={handleSendPreviewEmailReport} 
                      handleBackButtonclick={handleBackButtonclick}
                      selectedEmailReportType={emailPreviewData.selectedEmailReportType}
                      selectedRecipientType={emailPreviewData.selectedRecipients} 
                      inputFilterFromDate={emailPreviewData.inputFilterFromDate} 
                      inputFilterToDate={emailPreviewData.inputFilterToDate} 
                      inputFilterFirstName={emailPreviewData.inputFilterFirstName} 
                      inputFilterLastName={emailPreviewData.inputFilterLastName} 
                      inputFilterUserName={emailPreviewData.inputFilterUserName} 
                      inputFilterEmail={emailPreviewData.inputFilterEmail} 
                      selectedCourseIds={emailPreviewData.selectedCourseIds}
                      selectedCourseName={emailPreviewData.selectedCourseName} 
                      selectedUnitIds={emailPreviewData.selectedUnitIds}
                      selectedUnitName={emailPreviewData.selectedUnitName} 
                      selectedGrouping={emailPreviewData.selectedGrouping} 
                      selectedClassificationId={emailPreviewData.selectedClassificationId}
                      selectedcourseQuizStatus={emailPreviewData.selectedcourseQuizStatus} 
                      courseQuizStatusId={emailPreviewData.courseQuizStatusId}
                      resultBodyItems={emailPreviewData.resultBodyItems} 
                      resultError={emailPreviewData.resultError} 
                      resultItems={emailPreviewData.resultItems}
                      setPageReset={isPreviewPageResetClicked}

                      selectedPolicyIDs={emailPreviewData.selectedPolicyIDs}
                      selectedPolicyStatus={emailPreviewData.selectedPolicyStatus}
                      selectedCPDProfileStatus={emailPreviewData.selectedCPDProfileStatus}
                      selectedCPDProfileID={emailPreviewData.selectedCPDProfileID}
                      selectedCPDProfileName={emailPreviewData.selectedCPDProfileName}
                      newStarterOnlyFlag={emailPreviewData.newStarterOnlyFlag}
                      selectedExternalCertificateIDs={emailPreviewData.selectedExternalCertificateIDs}
                      selectedExternalCertificateStatus={emailPreviewData.selectedExternalCertificateStatus}
                      ></EmailSendPreview>
            </div>
            <div hidden={currentPage !== "progress"} >
              <SendProgress emailReportId={emailReportId} handleBackButtonclick={handleBackButtonclick} 
                  isQueueInProgress={isQueueInProgress} setPageReset={isProcessPageResetClicked} setIsProcessFinished={handleProcessFinished}></SendProgress> 
            </div> 
        </>
    );
}

export default EmailReport