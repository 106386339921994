import axios from 'axios';
import { addError } from './AddError';

export const getPastCourseListByOrganisation = async (token: string, userId: number, orgDomain: string, orgId: number) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/userreport/GetPastCourseListByOrganisation`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
          UserID: userId,
          OrganisationID: orgId
        
      },
    });
    const data = await response.data;
    if (data.isSuccess) {
      for (let i = 0; i < data.pastCourseListItems.length; i++) {
        data.pastCourseListItems[i].isSelected = false        
      }
    }
    return data;
   
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "GetPastCourseListByOrganisation.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "GetPastCourseListByOrganisation.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "GetPastCourseListByOrganisation.ts", 1)
    }
    return null;
  }
};

export default getPastCourseListByOrganisation;