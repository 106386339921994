import React, { useContext } from 'react'
import { CorperateThemeContext } from '../contexts/CorperateThemeContext'

export const NavLogo = (): JSX.Element => {
    const { logoUri } = useContext(CorperateThemeContext).theme;
    
    return <img style={{ display: "block", height: "45px", margin: "10px"}}
                alt="Logo" 
                src={logoUri}
                 />
}

export default NavLogo;
