import axios from 'axios';
import { addError } from '../../AddError';

const SaveMultiUnitMultiCourseModuleAccess = async (token: string, adminuserId: number, orgDomain: string, orgId:number, unitIds: string, selectedCourseIDs: string, grantedModuleIDs: string, givenAssignmentType: number) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/unit/SaveMultiUnitMultiCourseModuleAccess`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        "RequestingUserID": adminuserId,
        "OrganisationID": orgId,
        "SelectedUnitIDs" : unitIds,
        "SelectedCourseIDs": selectedCourseIDs,
        "GrantedModuleIDs": grantedModuleIDs,
        "GivenAssignmentType": givenAssignmentType
    },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, adminuserId, orgDomain, "Rest Exception", error.response, "SaveMultiUnitMultiCourseModuleAccess.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, adminuserId, orgDomain, "Rest Exception", error.request, "SaveMultiUnitMultiCourseModuleAccess.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, adminuserId, orgDomain, "Rest Exception", error.message, "SaveMultiUnitMultiCourseModuleAccess.ts", 1)
    }
    return null;
  }
};

export default SaveMultiUnitMultiCourseModuleAccess;