//React + Typescript
import React, { useState, useContext, useEffect } from 'react';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';
//API Functions
import { addError } from '../../API/AddError'
import { setOrgTheme } from '../../API/SetOrgTheme';
import { setOrgConfig } from '../../API/SetOrgConfig'
import { uploadImage } from '../../API/UploadImage'
//Components
import CustomTooltip from '../../Components/CustomTooltip';
import ImageDropBox from './ImageDropBox'
//MUI Components
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import RefreshIcon from '@material-ui/icons/Refresh';
import PaletteIcon from '@material-ui/icons/Palette';
//Styles
import styles from '../../styles/theme.module.css'

import oidcConfig from '../../configuration';
export const Theme = () => {
  const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
  const { color, logoUri, backgroundUri } = useContext(CorperateThemeContext).theme;
  const setBranding = useContext(CorperateThemeContext).setTheme; 
  const { setTitle, setTitleEnglish, setMenuSelection, EnableActivityLogs, superAdminSelectedBasicOrgData } = useContext(UIContext);
  const [selectedColor, setSelectedColor] = useState<string>(color)
  const [selectedLogo, setSelectedLogo] = useState<string>(logoUri)
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [logoErrorMessage, setLogoErrorMessage] = useState<string>("");
  const [logoError, setLogoError] = useState<boolean>(false);
  const [backgroundErrorMessage, setBackgroundErrorMessage] = useState<string>("");
  const [backgroundError, setBackgroundError] = useState<boolean>(false);
  const [selectedBackground, setSelectedBackground] = useState(backgroundUri)
  const [savedMessageFlash, setSavedMessageFlash] = useState<boolean>(false)
  const [savedMessage, setSavedMessage] = useState<string>("")
  const { oidcUser } = useReactOidc();
  const orgId = oidcUser?.profile.profile ? parseInt(oidcUser.profile.profile) : -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0;
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";

  const handleColorSelection = (event: any) => {
    addError(oidcUser.access_token, userId, orgDomain, `Selected ${event.target.value}colour`, null, "Theme.tsx", 6, EnableActivityLogs, false, 
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : -1), 
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : ""), 
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : ""))
    setSelectedColor(event.target.value)
  }

  const handleLogoSelection = (event: any) => {
    addError(oidcUser.access_token, userId, orgDomain, `Selected logo url ${event.target.value}`, null, "Theme.tsx", 6, EnableActivityLogs, false, 
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : -1), 
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : ""), 
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : ""))
    setSelectedLogo(event.target.value)
  }

  const handleBackgroundSelection = (event: any) => {
    addError(oidcUser.access_token, userId, orgDomain, `Selected background url ${event.target.value}`, null, "Theme.tsx", 6, EnableActivityLogs, false, 
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : -1), 
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : ""), 
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : ""))
    setSelectedBackground(event.target.value)
  }

  const handleImageUpload = (byteArray: string, fileExtension: string, imageType: "Logo" | "Background") => {
    
    var currentDomain = window.location.host
    const key1 = "https://";
    const key2 = "http://";
    const key3 = ".com";
    const idx1 = currentDomain.indexOf(key1);
    const idx2 = currentDomain.indexOf(key2);
    const idx3 = currentDomain.indexOf(key3);
    currentDomain = (idx1 !== -1 && idx3 !== -1) ? currentDomain.substring(idx1 + key1.length, idx3 + key3.length) : 
                      ((idx2 !== -1 && idx3 !== -1) ? currentDomain.substring(idx2 + key2.length, idx3 + key3.length) : currentDomain);
    currentDomain = (window.location.host.toLowerCase().indexOf("localhost") !== -1 || 
                      (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomain) !== -1 && 
                       window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ? 
                       oidcConfig.stagingdomain : 
                       ((window.location.host.toLowerCase().indexOf("localhost") !== -1 || 
                        (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomainSA) !== -1 && 
                          window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ? 
                          oidcConfig.stagingdomainSA : 
                          ((window.location.host.toLowerCase().indexOf("localhost") !== -1 || 
                            (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomainForDeactTest) !== -1 && 
                            window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ? 
                            oidcConfig.stagingdomainForDeactTest : 
                            currentDomain.toLowerCase().replace("altlogin-", ""))) /* To support the non-sso users of SSO orgs */
    
      return new Promise(resolve => {
        uploadImage(oidcUser.access_token, userId, currentDomain, byteArray, fileExtension, 
          (superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.orgID : -1,
          (superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.domainName : "",
          (superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.siteName : "").then((res) => {
          if (!!res && res.isSuccess) {
            if (imageType === "Logo") {
              setSelectedLogo(res.imageUrl)
            } else {
              setSelectedBackground(res.imageUrl)
            }
            resolve({ success: true })
          } else {
            console.log(!!res && !!res.error ? res.error : "Upload failed with unknown error");
            resolve({ success: false })
          }
        })
      })
  }

  const checkIfImageExists = (url: string, callback: Function) => {
    const img = new Image();
    img.src = url
    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };
      img.onerror = () => {
        callback(false);
      };
    }
  }

  const resetErrorMessages = () => {
    setLogoError(false)
    setLogoErrorMessage("")
    setBackgroundError(false)
    setBackgroundErrorMessage("")
  }

  const handleSubmit = () => {

    var currentDomain = window.location.host
    const key1 = "https://";
    const key2 = "http://";
    const key3 = ".com";
    const idx1 = currentDomain.indexOf(key1);
    const idx2 = currentDomain.indexOf(key2);
    const idx3 = currentDomain.indexOf(key3);
    currentDomain = (idx1 !== -1 && idx3 !== -1) ? currentDomain.substring(idx1 + key1.length, idx3 + key3.length) : 
                      ((idx2 !== -1 && idx3 !== -1) ? currentDomain.substring(idx2 + key2.length, idx3 + key3.length) : currentDomain);
    currentDomain =
      (window.location.host.toLowerCase().indexOf("localhost") !== -1 || 
        (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomain) !== -1 && 
         window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ? 
         oidcConfig.stagingdomain : 
         ((window.location.host.toLowerCase().indexOf("localhost") !== -1 || 
          (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomainSA) !== -1 && 
            window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ? 
            oidcConfig.stagingdomainSA : 
            ((window.location.host.toLowerCase().indexOf("localhost") !== -1 || 
              (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomainForDeactTest) !== -1 && 
              window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ? 
              oidcConfig.stagingdomainForDeactTest : 
              currentDomain.toLowerCase().replace("altlogin-", ""))) /* To support the non-sso users of SSO orgs */
    

    setIsSaving(true)
    let error = false
    let logo = selectedLogo === ""? logoUri : selectedLogo
    let background = selectedBackground === ""? backgroundUri : selectedBackground
    checkIfImageExists(logo, (exists: boolean) => {
      if (!exists) {
        error = true
        setLogoError(true)
        setLogoErrorMessage(allResourcesToTranslations.unabletoupdatelogo)
      }
      checkIfImageExists(background , (exists: boolean) => {
        if (!exists) {
          error = true
          setBackgroundError(true)
          setBackgroundErrorMessage(allResourcesToTranslations.unabletoupdatebackground)
        }
        if (!error) {
          resetErrorMessages()
          setSavedMessage('Updated theme saved')
          setIsSaving(false)
          setBranding({color: selectedColor, logoUri: logo, backgroundUri: background}) //Sets an immediate update without needing to refresh page
          setOrgConfig(oidcUser.access_token, userId, currentDomain, orgId, selectedColor, logo, background, 
            (superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.orgID : -1,
              (superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.domainName : "",
                (superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.siteName : "")
          setOrgTheme(oidcUser.access_token, userId, currentDomain, orgId, selectedColor, 
            (superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.orgID : -1,
              (superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.domainName : "",
                (superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.siteName : "")
          addError(oidcUser.access_token, userId, orgDomain, "Saved theme settings", null, "Theme.tsx", 6, EnableActivityLogs)
          setSavedMessageFlash(true)
          setTimeout(() => { setSavedMessageFlash(false) }, 3000);
          
        } else {
          setSavedMessage(allResourcesToTranslations.pleasecheckimagelinks)
          addError( oidcUser.access_token, userId, orgDomain, 'unable to save theme invalid url', null, "Theme.tsx", 4);
          setSavedMessageFlash(true)
          setTimeout(() => { setSavedMessageFlash(false) }, 3000);
          setIsSaving(false)
        }
      })
    })
  }

const handleRevertDefaultBackground = () => {
  const defaultBackground = "https://content.saltcompliance.com/contentfiles/common/salt-login-background.jpg";
  setSelectedBackground(defaultBackground);
}
const handleRevertDefaultLogo = () => {
  const defaultLogo = "https://content.saltcompliance.com/contentfiles/common/salt-logo.svg";
  setSelectedLogo(defaultLogo); 
}
const handleRevertDefaultColor = () => {
  const defaultColor = "#743276";
  setSelectedColor(defaultColor);  
}
  // const handleRevert = () => {
  //   const defaultColor = "#743276";
  //   const defaultLogo = "https://content.saltcompliance.com/contentfiles/common/salt-logo.svg";
  //   const defaultBackground = "https://content.saltcompliance.com/contentfiles/common/salt-login-background.jpg";
  //   (document.getElementById('logoUrl') as HTMLInputElement).value = "";
  //   (document.getElementById('backgroundUrl') as HTMLInputElement).value ="";

  //   setSelectedColor(defaultColor);
  //   setSelectedLogo(defaultLogo);
  //   setSelectedBackground(defaultBackground);
  //   setSavedMessage(allResourcesToTranslations.reverttodefaulttheme);
  //   setBranding({color: defaultColor, logoUri: defaultLogo, backgroundUri: defaultBackground}) //Sets an immediate update without needing to refresh page
  //   setOrgConfig(oidcUser.access_token, userId, orgDomain, orgId, defaultColor, defaultLogo, defaultBackground)
  //   setOrgTheme(oidcUser.access_token, userId, orgDomain, orgId, defaultColor)
  //   addError(oidcUser.access_token, userId, orgDomain, "Reverted to Default theme", null, "Theme.tsx", 6, EnableActivityLogs)
  //   setSavedMessageFlash(true)
  //   setTimeout(() => { setSavedMessageFlash(false) }, 3000);
  // }

  useEffect(() => {   
    setSelectedColor(color);
    setSelectedLogo(logoUri);
    setSelectedBackground(backgroundUri);
  }, [superAdminSelectedBasicOrgData, color, logoUri, backgroundUri]); 

  useEffect(() => {
    setTitle(allResourcesToTranslations.theme0);
    setTitleEnglish(pageTitles.theme)
    setMenuSelection(allResourcesToTranslations.theme0);
    setSelectedColor(color);
    setSelectedLogo(logoUri);
    setSelectedBackground(backgroundUri);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
		<>
			<div className={styles.themeContolContainer}>
				<div className={styles.inputContainer} style={{ paddingLeft:"13px"}}>
					<div className={styles.inputField}>
            <CustomTooltip displayText={[allResourcesToTranslations.selectaccentcolor]}>
						  <input className={styles.colorInput} onChange={(e) => handleColorSelection(e)} id="colorPicker" type="color" value={selectedColor} />
            </CustomTooltip>
            <CustomTooltip displayText={['Revert to default Color']/*TODO: Add translations */}>
						<span>
							<Button
								onClick={() => {
									handleRevertDefaultColor();
								}}
								variant="outlined"
								color="primary"
								disabled={isSaving}
							>
								<RefreshIcon />
								<PaletteIcon />
							</Button>
						</span>
					 </CustomTooltip>
						<br></br>
						<span>{allResourcesToTranslations.selectaccentcolor}</span>
					</div>
					<div className={styles.inputField}>
            <CustomTooltip displayText={[allResourcesToTranslations.chooseLogoUrl]}>
						<ImageDropBox 
              selectedImage={selectedLogo} 
              uploadImage={handleImageUpload}
              imageType="Logo"
              sizeLimitMb={3}
              />
            </CustomTooltip>
            <CustomTooltip displayText={['Revert to default Logo']/*TODO: Add translations */}>
						<span>
							<Button
								onClick={() => {
									handleRevertDefaultLogo();
								}}
								variant="outlined"
								color="primary"
								disabled={isSaving}
							>
								<RefreshIcon />
								<PaletteIcon />
							</Button>
						</span>
					 </CustomTooltip>         
						<div>
							<TextField
								onInput={(e) => handleLogoSelection(e)}
								className={styles.input}
								label="Choose logo url"
								id="logoUrl"
								error={logoError}
								helperText={logoErrorMessage}
								placeholder={selectedLogo}
                value={selectedLogo}
								variant="outlined"
                title={selectedLogo}
								InputProps={{
									readOnly: false,
									style: {
										backgroundColor: "white", //We pass the style into the MUI children
									},
								}}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</div>
						<div>
							<span className={styles.themeFinePrint}>{allResourcesToTranslations.pixorhigher}</span>
						</div>
					</div>
					<div className={styles.inputField}>
          <CustomTooltip displayText={[allResourcesToTranslations.chooseBackgroundUrl]}>
            <ImageDropBox 
              selectedImage={selectedBackground} 
              uploadImage={handleImageUpload}
              imageType="Background"
              sizeLimitMb={5}
              maxHeight={100}
              />
          </CustomTooltip>

          <CustomTooltip displayText={['Revert to default background Image']/*TODO: Add translations */}>
						<span>
							<Button
								onClick={() => {
									handleRevertDefaultBackground();
								}}
								variant="outlined"
								color="primary"
								disabled={isSaving}
							>
								<RefreshIcon />
								<PaletteIcon />
							</Button>
						</span>
					</CustomTooltip>
              <div>
                <TextField
                onInput={(e) => handleBackgroundSelection(e)}
                className={styles.input}
                label="Choose background url"
                id="backgroundUrl"
                error={backgroundError}
                helperText={backgroundErrorMessage}
                placeholder={selectedBackground}
                value={selectedBackground}
                variant="outlined"
                title={selectedBackground}
                InputProps={{
                  readOnly: false,
                  style: {
                    backgroundColor: "white", //We pass the style into the MUI children
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              </div>
						<div>
							<span className={styles.themeFinePrint}>{allResourcesToTranslations.pixorhigher0}</span>
						</div>
					</div>
				</div>

				<div className={styles.inputContainer}>
					<span className={styles.themeFinePrint} style={{color:'#63c2ff'}}>{allResourcesToTranslations.leaveemptytokeep}</span>
				</div>				
        <div style={{width: '30%', minWidth: "300px !important"}}>
                <div className={styles.saveButtonContainer} style={{ float:'right'}}>
                  <CustomTooltip displayText={[allResourcesToTranslations.savetheme]}>
                    <span>
                      <Button
                        onClick={() => {
                          handleSubmit();
                        }}
                        variant="contained"
                        color="primary"
                        disabled={isSaving}
                      >
                        <SaveIcon />
                      </Button>
                    </span>
                  </CustomTooltip>
                  {/* <CustomTooltip displayText={[allResourcesToTranslations.reverttodefaulttheme]}>
                    <span>
                      <Button
                        onClick={() => {
                          handleRevert();
                        }}
                        variant="outlined"
                        color="primary"
                        disabled={isSaving}
                      >
                        <RefreshIcon />
                        <PaletteIcon />
                      </Button>
                    </span>
                  </CustomTooltip> */}
                  <span style={{ opacity: savedMessageFlash ? "1" : "0" }} className={styles.themeUpdatedText}>
                    {savedMessage}
                  </span> 
				        </div>
        </div>

			</div>
		</>
  );
};

export default Theme;
