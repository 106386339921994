//React + Typescript
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LearningModule } from '../../globalTypes';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { SessionContext } from '../../contexts/SessionContext';
import { UIContext } from '../../contexts/UIContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
//API Functions
import { addError } from '../../API/AddError'
// import { updateSAPassLaunchPageDetails } from '../../API/UpdateSAPassLaunchPageDetails'
//MUI Components
import Button from '@material-ui/core/Button';
//Styles
import styles from '../../styles/reviewModalStyle.module.css'

interface Props {
    setModalOpen: (isOpen: boolean) => any; 
    selectedLearningModule: LearningModule | null;  
}

export const SAReviewModalContent = ({setModalOpen, selectedLearningModule}: Props): JSX.Element => { 
    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
    const { setSaReviewOnlyValue, setSaIsRestarted } = useContext(SessionContext);
    const history = useHistory();
    const { oidcUser } = useReactOidc();
    const { EnableActivityLogs } = useContext(UIContext);
    const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0;
    const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";

    const createHeaderMarkup = () => {
    // Need to sanitze data for security in dangerouslySetInnerHTML. Only required if strings have html tags
    // more info -> https://zhenyong.github.io/react/tips/dangerously-set-inner-html.html
        return {
           __html: allResourcesToTranslations.youthaveselectedreview };
     }; 

    const handleContinue = () => {
      addError(oidcUser.access_token, userId, orgDomain, "Accepted review conditions", null, "SAReviewModalContent.tsx", 6, EnableActivityLogs)
      setSaReviewOnlyValue(-1);
      setSaIsRestarted(-1);
      if (selectedLearningModule != null){
        setSaReviewOnlyValue(1);
        setSaIsRestarted(0);
        let ticks = Math.floor(Date.now()/1000);
        history.push({
          pathname: `${selectedLearningModule.courseID}/quiz/${selectedLearningModule.id}/${ticks}`,
          state: {
            learningModule: selectedLearningModule,
            moduleType: selectedLearningModule.lessonType,
            isLesson: false,
          },
        });
      }
    };

    const handleReturn = () => {
      addError(oidcUser.access_token, userId, orgDomain, "Closed Salt Adaptive warning popup", null, "SAReviewModalContent.tsx", 6, EnableActivityLogs)
      setModalOpen(false)
    };

    return (
      <div className={styles.container}>
          <div>
            <h1 style={{ color: "#e7a652" }}>
              {allResourcesToTranslations.warningconfirmessagepopup}
            </h1>
          </div>
        <div className={styles.divider}></div>
        <div>
          {/* <p dangerouslySetInnerHTML={createHeaderMarkup()}></p> */}
          <p>You have selected the <b>Review</b> button.</p>
          <p>            
             {/* {allResourcesToTranslations.donotselecttherestartcoursebutton} */}
            When using <b>Review</b>, any attempts to restart this course will not be recorded in Salt.
            <br></br><br></br>To Reset this Module to complete it again, please close this Warning message and select the <b>Restart</b> button.
          </p>
          
        </div>
        <div>
            <Button color="primary" variant="contained" onClick={ () => handleReturn() }>
              {allResourcesToTranslations.return}
            </Button>
            <Button color="primary" variant="contained" onClick={ () => handleContinue() }>
              {allResourcesToTranslations.continue}
            </Button>
        </div>
      </div>
    );
}

export default SAReviewModalContent;