import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const SupportDetailsReverttIcon = () => (    
<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_641_5499)">
<path d="M23.7178 20.6484V14.6484H17.7178" stroke="white" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
<path d="M1.71777 4.64844V10.6484H7.71777" stroke="white" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
<path d="M21.2078 9.64845C20.7006 8.21524 19.8386 6.93385 18.7023 5.92387C17.566 4.91389 16.1923 4.20822 14.7095 3.87271C13.2267 3.5372 11.683 3.58279 10.2226 4.00523C8.76215 4.42766 7.43252 5.21317 6.35777 6.28845L1.71777 10.6485M23.7178 14.6485L19.0778 19.0085C18.003 20.0837 16.6734 20.8692 15.213 21.2917C13.7525 21.7141 12.2089 21.7597 10.7261 21.4242C9.24324 21.0887 7.86957 20.383 6.73324 19.373C5.59691 18.363 4.73494 17.0817 4.22777 15.6485" stroke="white" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_641_5499">
<rect width="24" height="24" fill="white" transform="translate(0.717773 0.648438)"/>
</clipPath>
</defs>
</svg>
);

export default SupportDetailsReverttIcon
