import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const SupportDetailIconMenu = () => (    
<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1079_3711)">
<path d="M15.7736 5C16.7503 5.19057 17.648 5.66826 18.3516 6.37194C19.0553 7.07561 19.533 7.97326 19.7236 8.95M15.7736 1C17.8028 1.22544 19.6952 2.13417 21.1398 3.57701C22.5845 5.01984 23.4956 6.91101 23.7236 8.94M22.7236 16.92V19.92C22.7247 20.1985 22.6676 20.4742 22.5561 20.7293C22.4445 20.9845 22.2809 21.2136 22.0756 21.4019C21.8704 21.5901 21.6281 21.7335 21.3643 21.8227C21.1005 21.9119 20.8209 21.9451 20.5436 21.92C17.4664 21.5856 14.5106 20.5341 11.9136 18.85C9.49739 17.3147 7.4489 15.2662 5.91357 12.85C4.22354 10.2412 3.17181 7.27099 2.84357 4.18C2.81858 3.90347 2.85144 3.62476 2.94007 3.36162C3.02869 3.09849 3.17114 2.85669 3.35833 2.65162C3.54553 2.44655 3.77337 2.28271 4.02736 2.17052C4.28134 2.05833 4.55591 2.00026 4.83357 2H7.83357C8.31887 1.99522 8.78936 2.16708 9.15733 2.48353C9.5253 2.79999 9.76564 3.23945 9.83357 3.72C9.96019 4.68007 10.195 5.62273 10.5336 6.53C10.6681 6.88792 10.6972 7.27691 10.6175 7.65088C10.5377 8.02485 10.3524 8.36811 10.0836 8.64L8.81357 9.91C10.2371 12.4135 12.31 14.4864 14.8136 15.91L16.0836 14.64C16.3555 14.3711 16.6987 14.1858 17.0727 14.1061C17.4467 14.0263 17.8356 14.0555 18.1936 14.19C19.1008 14.5286 20.0435 14.7634 21.0036 14.89C21.4893 14.9585 21.933 15.2032 22.2501 15.5775C22.5672 15.9518 22.7357 16.4296 22.7236 16.92Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1079_3711">
<rect width="24" height="24" fill="white" transform="translate(0.723633)"/>
</clipPath>
</defs>
</svg>

);

export default SupportDetailIconMenu
