import axios from 'axios';
import { ScormTrainingQAs } from '../globalTypes'
import { addError } from './AddError';

export const sendScormTrainingQAs = async (token: string, scormTrainingQAs: ScormTrainingQAs ) => {

    try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/usersessions/ScormTrainQA`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        QuizSessionID: scormTrainingQAs.QuizSessionID,
        UserID: scormTrainingQAs.UserID,
        ModuleID: scormTrainingQAs.ModuleID,
        AskedQuestion: scormTrainingQAs.AskedQuestion,
        Weighting: scormTrainingQAs.Weighting,
        Latency: scormTrainingQAs.Latency,
        Time: scormTrainingQAs.Time,
        Text: scormTrainingQAs.Text,
        CorrectResponse: scormTrainingQAs.CorrectResponse,
        StudentResponse: scormTrainingQAs.StudentResponse,
        Type: scormTrainingQAs.Type,
        ID: scormTrainingQAs.ID,
        IsCorrect: scormTrainingQAs.IsCorrect
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, 0, window.location.href.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */, "Rest Exception", error.response, "SendScormTrainingQAs.ts", 1) //TODO: add the correct params in setupAPIGlobals.js
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, 0, window.location.href.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */, "Rest Exception", error.request, "SendScormTrainingQAs.ts", 1) //TODO: add the correct params in setupAPIGlobals.js
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      };
      addError(token, 0, window.location.href.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */, "Rest Exception", error.message, "SendScormTrainingQAs.ts", 1) //TODO: add the correct params in setupAPIGlobals.js
    }
    return null;
  }
};

export default sendScormTrainingQAs;
