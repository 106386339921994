import axios from 'axios';
import { addError } from './AddError';

export const getFullCPDReport = async (
    token: string, 
    userId: number,
    orgDomain: string,
    orgId: number,
    unitIDs: string,
    profileID: number,
    profilePeriodID: number,
    profileName: string,
    profilePeriodName: string,
    firstName: string,
    lastName: string,
    userName: string,
    shortfallUsers: number,
    includeCourseModules: boolean = true,
    includePolicies: boolean = true,
    includeExternalCertificates: boolean = true
    ) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/userreport/GetFullCPDReport`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },

      data: {
        ReportRequestingUserID : userId,
        OrgID : orgId,
        UnitIDs : unitIDs,
        ProfileID : profileID,
        ProfilePeriodID : profilePeriodID,
        ProfileName : profileName,
        ProfilePeriodName : profilePeriodName,
        FirstName : firstName,
        LastName : lastName,
        UserName : userName,
        ShortfallUsers : shortfallUsers,
        IncludeCourseModules: includeCourseModules,
        IncludePolicies: includePolicies,
        IncludeExternalCertificates: includeExternalCertificates
      }
    });
    const data = await response.data;
  
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      };    
      addError(token, userId, orgDomain, "Rest Exception", error.response, "GetFullCPDReport.tsx", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      };    
      addError(token, userId, orgDomain, "Rest Exception", error.request, "GetFullCPDReport.tsx", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      };    
      addError(token, userId, orgDomain, "Rest Exception", error.message, "GetFullCPDReport.tsx", 1)
    }
    return null;
  }
};

export default getFullCPDReport;
