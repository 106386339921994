import React, { useContext } from 'react'
import styles from '../styles/bouncingArrow.module.css'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { UIContext } from '../contexts/UIContext'

export const BouncingArrow = () => {
  const { showScrollArrow } = useContext(UIContext)

  const scrollDown = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };
 
    return (
        <div className={styles.arrowContainer}>
          <div className={`${styles.arrow} ${styles.bounce}`} onClick={() =>scrollDown()} style={{display: showScrollArrow? "block" : "none", cursor: showScrollArrow? "pointer" : "none"}}>
            <ExpandMoreIcon fontSize="large" htmlColor={'#21394f'} />
          </div>
        </div>
    )
}

export default BouncingArrow;
