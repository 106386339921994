import React from "react";

const FileInfoIcon = () => {

    return (
<svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M2.56982 0.645996C1.46525 0.645996 0.569824 1.54143 0.569824 2.646V21.6086C0.569824 22.7132 1.46526 23.6086 2.56983 23.6086H16.211C17.3155 23.6086 18.211 22.7132 18.211 21.6086V2.646C18.211 2.63085 18.2108 2.61574 18.2104 2.60067V5.67222L13.283 0.646058L16.2268 0.646058C16.2216 0.646017 16.2163 0.645996 16.211 0.645996H2.56982Z" fill="#C4C4C4"/>
<path d="M13.2827 0.645996L18.2101 5.67216L13.2827 5.67216L13.2827 0.645996Z" fill="#767676"/>
</svg>

    )
}

export default FileInfoIcon;