import React, { useState, useEffect, useContext } from "react";
import { UnitTreeItem } from "../../../globalTypes";

//libs
import { Steps } from "intro.js-react";

//MUI
import { 
  TextField,
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Button, CircularProgress ,
} from "@material-ui/core";

//Contexts
import { UIContext } from "../../../contexts/UIContext";
import { LoaderContext } from "../../../contexts/LoaderContext";

//API Functions
import { getRelevantUnitTree } from "../../../API/GetRelevantUnitTree";
import { useReactOidc } from "@axa-fr/react-oidc-context";
//Components
import UnbrandedCard from "../../../Components/UnbrandedCard";
import LoaderContainer from "../../../Components/LoaderContainer";
import CustomTooltip from "../../../Components/CustomTooltip";
import GuideIcon from "../../../CustomIcons/GuideIcon";

//Styles
import styles from "../../../styles/addUnit.module.css";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { useHistory } from "react-router-dom";
import SaveIcon from "../../../CustomIcons/SaveIcon";

import RadioUnits from "../AddAccount/RadioUnits";
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';
import AccountManagement from "../AccountManagement/AccountManagement";
import SaveMoveUsersToUnit from "../../../API/Administration/Users/SaveMoveUsersToUnit";


const MoveAccountsToUnit = () => {
  const { setMessage } = useContext(LoaderContext);
  const history = useHistory();
  const {
    translations: { allResourcesToTranslations }, pageTitles,
  } = useContext(TranslationsContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute } =
    useContext(UIContext);
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
      .toLowerCase()
      .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";

  const [units, setUnits] = useState<Array<UnitTreeItem>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isResetClicked, setisResetClicked] = useState<boolean>(false); ;
  const [isSaving, setIsSaving] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);  
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [hasAccountsSearched, setHasAccountsSearched] = useState<boolean>(false);
  const [selectedUserIds, setselectedUserIds] = useState<string>("");
  const [selectedUnitCallback, setSelectedUnitCallback] = useState<number>(0);
  const [selectedunitlevel, setSelectedunitlevel] = useState<any>("toplevel");
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".unitFilterButtons",
        title: "Units",
        intro: "Select, unselect, expand and collapse all units.",
      },
    ],
  });

  const setGuideMe = () => {
    let updateSteps = [...intro.steps];
        if (updateSteps.length>1) {
            updateSteps.splice(1, updateSteps.length - 1);     
        }
        
    updateSteps.push(
      {
        element: ".unitFilterSearch",
        title: "Filter Units",
        intro: "Search unit/subunits by typing unit name.",
      },
      {
        element: ".firstName",
        title: "Filter",
        intro: "Filter Results by First Name.",
      },
      {
        element: ".lastName",
        title: "Filter",
        intro: "Filter Results by Last Name.",
      },
      {
        element: ".userName",
        title: "Filter",
        intro: "Filter Results by Username.",
      },
      {
        element: ".email",
        title: "Filter",
        intro: "Filter Results by Email Address.",
      },
      {
        element: ".userID",
        title: "Filter",
        intro: "Filter Results by Account ID.",
      },
      {
        element: ".includeInactive",
        title: "Filter",
        intro: "Filter Results by Including Inactive.",
      },
      {
        element: ".searchUsers",
        title: "Find Accounts",
        intro: "Click to find Accounts to be moved.",
      },
      {
        element: ".resetAll",
        title: "Reset",
        intro: ` Reset all the Fields.`,
      }
    );

    if (hasAccountsSearched) {
      updateSteps.push(
        {
            element: "#acMgmtAssignCol",
            title: "Assign All",
            intro: "Select account(s) to be moved. ",                
        },
        {
            element: "#acMgmtUPCol",
            title: "Unit Pathway",
            intro: "Shows the Unit Pathway of the account(s) to be moved. ",                
        },
        {
            element: "#acMgmtLNCol",
            title: "Last Name",
            intro: "Shows the Last Name of the account(s) to be moved. ",                
        },
        {
            element: "#acMgmtFNCol",
            title: "First Name",
            intro: "Shows the First Name of the account(s) to be moved. ",                
        },
        {
          element: "#DestinationUnitExpand",
          title: "Unit Expand",
          intro: "Expand all units in the unit tree. ",                
      },
      {
          element: "#DestinationUnitCollapse",
          title: "Unit Collapse",
          intro: "Collapse all units in the unit tree. ",                
      },
      {
          element: "#DestinationUnitSearch",
          title: "Destination Unit Search",
          intro: "Filter units by partial or full unit name. ",                
      },
      {
          element: "#DestinationUnits",
          title: "Destination Units",
          intro: "Select the destination unit for the selected account(s) above. ",                
      },
          {
              element: ".saveData",
              title: "Save",
              intro: "Click Save to move the selected account(s) to the selected destination unit. ",
          }
    );
    }
    setIntro({ ...intro, steps: [...updateSteps] });
  };

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  useEffect(() => {
    setTitle(allResourcesToTranslations.moveaccountstounit);
    setTitleEnglish(pageTitles.moveaccountstounit);
    updateBackButtonRoute("");
    setLoading(true);
    getRelevantUnitTree(
      oidcUser.access_token,
      userId,
      orgDomain,
      true,
      parseInt(orgId)
    ).then((unitTreeRes) => {
      if (unitTreeRes.isSuccess) {
        setUnits(unitTreeRes.unitTreeItems);
        setLoading(false);
        setGuideMe();
      }
    });
  
  }, []);

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  const setRecordsSearchedFunction = (hasSearched: boolean) => {
    setHasAccountsSearched(hasSearched);
}    
const setupdatedSelectedUsersFunction = (usersData: Array<any>[]) => {
  let userGrantedList = !!usersData !== true ? null : usersData.filter((item: any) => item.granted === true);
  const selectedIds = !!userGrantedList ? userGrantedList.map( ({userID}: any) => userID) : [];
  setselectedUserIds(selectedIds.join(','));  
}

  const handleSubmit = () => {
    let errorString = '';
    if (selectedUnitCallback === 0 && selectedunitlevel != "toplevel") {
      errorString = errorString + 'Please select the unit;'
    }
    if (errorString !== '') {
      setShowWarningModal(true);
      setModalPopupMessage(errorString);
      setHasErrored(true);
      return;
    }
    setIsSaving(true);
    setSaveClicked(true);

    SaveMoveUsersToUnit(
      oidcUser.access_token,
      userId,
      orgDomain,
      parseInt(orgId),
      selectedUserIds,
      selectedUnitCallback
    ).then((result) => {
      if (result !== null) {
        if (result.isSuccess && result.error == "" && result.addedUnitID != -1) {         
          setShowWarningModal(true);
          setModalPopupMessage('Accounts have been moved to selected unit successfully!');
          setSaveSuccess(true);
          setHasErrored(false);
          setIsSaving(false);
          setSaveClicked(false);

        } else {
          setShowWarningModal(true);
          setModalPopupMessage(result.error);          
          setHasErrored(true);
          setIsSaving(false);
          setSaveClicked(false);
          return;
        }
      } else {
        setShowWarningModal(true);
        setModalPopupMessage('Moving records to selected unit has failed, please try again.');
        setHasErrored(true);
        setIsSaving(false);
        setSaveClicked(false);
        return;
      }
    });
  };

  useEffect(() => {
    if (hasAccountsSearched) {
     setGuideMe();
    }
  }, [hasAccountsSearched])

  useEffect(() => {
    if (showWarningModal) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      }, 5000)
    }
  }, [showWarningModal])


  if (loading) return <LoaderContainer />;
  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }}/>
      <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific}>
          <GuideIcon />
        </span>
      </CustomTooltip>   
      <div>
        <AccountManagement isCalledFrom="MoveAccountsToUnit" setRecordsSearched={setRecordsSearchedFunction} setUpdatedSelectedUsers={setupdatedSelectedUsersFunction}></AccountManagement>
        </div>
      { hasAccountsSearched &&
        <UnbrandedCard>
        <div className={styles.mainWrapper}>          
            <div className={styles.Wrapper}>           
                <div className={styles.unittreesection}>
                  <div>
                    <RadioUnits isResetClicked={isResetClicked} givenUnits={units} calledFrom="moveunit"
                                setSelectedUnitCallback={setSelectedUnitCallback} setSelectedunitlevel={setSelectedunitlevel}/>
                  </div>
                </div>    
                <div style={{ clear: "both" }}></div>
                <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", paddingTop: "10px" }}>
                      <CustomTooltip displayText={["Save"]}>
                        <span className="saveData">
                          <Button disabled={(selectedUnitCallback === 0 || selectedUserIds === "" || isSaving)}
                            onClick={handleSubmit}
                            variant="contained"
                            color="primary"
                            className="save">
                            <SaveIcon />
                          </Button>
                        </span>
                      </CustomTooltip>
                      <div className={styles.loaderContainer}>
                        <CircularProgress
                          style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
                          color="primary"
                          size="10"/>
                      </div>
                </div>
          </div>
        </div>
        </UnbrandedCard>
      }
      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}      
    </>
  );
};

export default MoveAccountsToUnit;
