import axios from 'axios';
import { addError } from '../../AddError';

export const GetUserExternalCertificatesOnlyWithCPDPoints = async (token: string, requestedUserID: number,  orgDomain: string, orgId: number | null, selectedProfileID: number = -1, selectedTab: string) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/externalcertificate/GetUserExternalCertificatesOnlyWithCPDPoints`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {  
        RequestingUserID: requestedUserID,  
        OrgID: orgId,
        ProfileID: selectedProfileID,
        selectedTab: selectedTab
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, requestedUserID, orgDomain, "Rest Exception", error.response, "GetUserExternalCertificatesOnlyWithCPDPoints.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, requestedUserID, orgDomain, "Rest Exception", error.request, "GetUserExternalCertificatesOnlyWithCPDPoints.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, requestedUserID, orgDomain, "Rest Exception", error.message, "GetUserExternalCertificatesOnlyWithCPDPoints.ts", 1)
    }
    return null;
  }
};

export default GetUserExternalCertificatesOnlyWithCPDPoints;