import React, { useState, useRef, useContext, useEffect } from "react";
import styles from "../../../styles/organisationmanagement.module.css";
import responsivestyles from "../../../styles/responsiveGridLayout.module.css";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { UIContext } from "../../../contexts/UIContext";
import LoaderContainer from "../../../Components/LoaderContainer";
import { Steps } from "intro.js-react";
import CustomTooltip from "../../../Components/CustomTooltip";
import GuideIcon from "../../../CustomIcons/GuideIcon";
import UnbrandedCard from "../../../Components/UnbrandedCard";
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, CircularProgress, TextField, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SaveIcon from "../../../CustomIcons/SaveIcon";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import SaveOrgConfigItemDetails from "../../../API/Administration/Organisation/SaveOrgConfigItemDetails";
import MUICustomSelect from "../../../Components/MUICustomSelect";
import GetOrgConfigItemDetails from "../../../API/Administration/Organisation/GetOrgConfigItemDetails";
import getTimezoneList from "../../../API/GetTimezoneList";

const ConfigurationItems = () => {
	const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext);
	const { setTitle, setTitleEnglish, updateBackButtonRoute, IsEbookEnabled, EnableActivityLogs, timeZoneId } = useContext(UIContext);
	const { oidcUser } = useReactOidc();
	const {
		profile: { user_id, website, },
	} = oidcUser || {};
	const userId = user_id ? parseInt(user_id) : 0;
	const orgId = oidcUser.profile.organisation_id ?? -1;
	const orgDomain = website
		? website
			.toLowerCase()
			.replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
		: "";

	const [includeCertificateLogo, setIncludeCertificateLogo] = useState<boolean>(false);
	const [isCertificateWithoutSaltLogo, setIsCertificateWithoutSaltLogo] = useState<boolean>(true);
	const [passwordLockout, setPasswordLockout] = useState<boolean>(false);
	const [showLastPassed, setShowLastPassed] = React.useState(false);
	const [disablePasswordField, setDisablePasswordField] = useState<boolean>(false);
	const [advancedReporting, setAdvancedReporting] = useState<boolean>(false);
	const [showWelcomePopup, setShowWelcomePopup] = useState<boolean>(false);
	const [orgtimeZoneId, setTimeZoneID] = useState<number>(0);
	const [selectedTimezone, setSelectedTimezone] = useState<any>({});
	const [timezoneList, setTimezoneList] = useState<any>([]);
	const [isTimezoneOptionFocused, setIsTimezoneOptionFocused] = useState<boolean>(false);
	const [enablePasswordRule, setEnablePasswordRule] = React.useState(false);
	const [isPasswordRuleCapital, setIsPasswordRuleCapital] = useState<boolean>(false);
	const [isPasswordRuleNumber, setIsPasswordRuleNumber] = useState<boolean>(false);
	const [isPasswordRuleSpecialChar, setIsPasswordRuleSpecialChar] = useState<boolean>(false);
	const [expandEnablePasswordRule, setExpandEnablePasswordRule] = useState<boolean>(false);

	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [saveSuccess, setSaveSuccess] = React.useState(false);
	const [modalPopupMessage, setModalPopupMessage] = useState('');
	const [hasErrored, setHasErrored] = useState<boolean>(false);

	const [intro, setIntro] = useState({
		stepsEnabled: false,
		initialStep: 0,
		steps: [
			{
				element: ".orglogocerti",
				title: "Include Org. Logo on Certificate",
				intro: allResourcesToTranslations.orglogocertiguideme,
			},
			{
				element: ".saltlogocerti",
				title: allResourcesToTranslations.saltlogocerti,
				intro: allResourcesToTranslations.saltlogocertiguideme,
			},
			{
				element: ".activatepassword",
				title: allResourcesToTranslations.activatepassword,
				intro: allResourcesToTranslations.activatepasswordguideme,
			},
			{
				element: ".showlastpass",
				title: "Show on My training page",
				intro: allResourcesToTranslations.showlastpassguideme,
			},
			{
				element: ".disablepassword",
				title: "Dissable Password",
				intro: allResourcesToTranslations.disablepasswordguideme,
			},
			{
				element: ".showwelcomepopup",
				title: allResourcesToTranslations.showwelcomepopup,
				intro: allResourcesToTranslations.showwelcomepopupguideme,
			},
			{
				element: ".advancedreporting",
				title: allResourcesToTranslations.advancedreporting,
				intro: allResourcesToTranslations.advancedreportingnote,
			},
			{
				element: ".enablepasswordrule",
				title: allResourcesToTranslations.enablepasswordrule,
				intro: allResourcesToTranslations.enablepasswordrulenoteguideme,
			},
			{
				element: ".timezone",
				title: allResourcesToTranslations.timezone,
				intro: allResourcesToTranslations.timezoneguideme,
			},
			{
				element: ".saveData",
				title: "Save",
				intro: "Click here to save all changes",
			}
		],
	});

	const onExit = () => {
		setIntro({ ...intro, stepsEnabled: false });
	};

	const handleClosePopup = () => {
		setShowModal(false);
	};

	useEffect(() => {
		setTitle(allResourcesToTranslations.configurationitems);
		setTitleEnglish(pageTitles.configurationitems);
		updateBackButtonRoute("");
		setLoading(true);
		GetOrgConfigItemDetails(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((result) => {
			if (result.isSuccess && result.error == '') {
				if (result.orgConfigItemDetail !== null) {
					setIncludeCertificateLogo(result.orgConfigItemDetail.includeCertificateLogo);
					setIsCertificateWithoutSaltLogo(!result.orgConfigItemDetail.isCertificateWithoutSaltLogo);
					setPasswordLockout(result.orgConfigItemDetail.passwordLockout);
					setShowLastPassed(result.orgConfigItemDetail.showLastPassed);
					setDisablePasswordField(result.orgConfigItemDetail.disablePasswordField);
					setAdvancedReporting(result.orgConfigItemDetail.advancedReporting);
					setShowWelcomePopup(result.orgConfigItemDetail.showWelcomePopup);
					setTimeZoneID(result.orgConfigItemDetail.timeZoneID);
					setEnablePasswordRule(result.orgConfigItemDetail.enablePasswordRule);
					setIsPasswordRuleCapital(result.orgConfigItemDetail.isPasswordRuleCapital);
					setIsPasswordRuleNumber(result.orgConfigItemDetail.isPasswordRuleNumber);
					setIsPasswordRuleSpecialChar(result.orgConfigItemDetail.isPasswordRuleSpecialChar);

					if (result.orgConfigItemDetail.enablePasswordRule){
						setExpandEnablePasswordRule(true);
					}
						
				}
			}
			else {
				setShowModal(true);
				setHasErrored(true);
				setSaveSuccess(false);
				setModalPopupMessage("Can not load Organisation details!");
			}
			setLoading(false);
		});

		getTimezoneList(oidcUser.access_token, userId, orgDomain).then((result) => {
			if (result != null && result.isSuccess) {
				let updatedData = result.timeZoneItems.map((item: any) => {
					return {
						value: item.timeZoneID,
						text: item.flB_Name,
					};
				});

				let orgTimeZone = result.timeZoneItems.filter((tz: any) => tz.timeZoneID == timeZoneId);
				if (orgTimeZone != null && orgTimeZone.length > 0) {
					setSelectedTimezone({
						value: orgTimeZone[0].timeZoneID,
						text: orgTimeZone[0].flB_Name
					});
				}
				setTimezoneList([...updatedData]);
			}
		});
		// at the end, return a cleanup method
		return () => { };
	}, []);

	const handleEnablePasswordRule = () => {
		if (!enablePasswordRule){
			setExpandEnablePasswordRule(true);
		}
		setEnablePasswordRule(!enablePasswordRule);
	}

	const handleSave = () => {
		setIsSaving(true);
		SaveOrgConfigItemDetails(oidcUser.access_token, userId, parseInt(orgId), orgDomain, includeCertificateLogo, !isCertificateWithoutSaltLogo, passwordLockout,
			showLastPassed, disablePasswordField, advancedReporting, showWelcomePopup, orgtimeZoneId, enablePasswordRule, isPasswordRuleCapital, isPasswordRuleNumber, isPasswordRuleSpecialChar,"configurationitems").then((res) => {
				setIsSaving(false);
				if (!!res && res.isSuccess) {
					setShowModal(true);
					setHasErrored(false);
					setSaveSuccess(true);
					setModalPopupMessage("Organisation Configuration Items have been saved successfully!");
				}
				else {
					setShowModal(true);
					setHasErrored(true);
					setSaveSuccess(false);
					setModalPopupMessage("Organisation Configuration Items saving have been failed!");
				}
			})
	}

	useEffect(() => {
		if (showModal && !hasErrored) {
			setTimeout(() => {
				setShowModal(false);
				setSaveSuccess(false);
			}, 3000)
		}
	}, [showModal])

	if (loading) return <LoaderContainer />;


	return (
		<>
			<Steps
				enabled={intro.stepsEnabled}
				steps={intro.steps}
				initialStep={intro.initialStep}
				onExit={onExit}
				options={{
					nextLabel: "Next",
					prevLabel: "Back",
					tooltipClass: "sample-class",
					showBullets: true,
				}}
			/>
			<CustomTooltip displayText={["Guide Me"]}>
				<span
					onClick={() => setIntro({ ...intro, stepsEnabled: true })}
					className={styles.guideMeButtonSpecific}
				>
					<GuideIcon />
				</span>
			</CustomTooltip>
			<UnbrandedCard>
				<div className={styles.mainWrapper} style={{ marginTop: "0px" }}>
					<div className={styles.Wrapper}>
						<div className={styles.lableContainer} >
							<span className={styles.labelText}>{allResourcesToTranslations.basicsettings}</span>
						</div>
						<div className={styles.inputContainer}>
							<span className={styles.labelTextitalic}>{allResourcesToTranslations.selecttext}</span>
						</div>
						<div style={{ width: '100%'}} className={responsivestyles.rowrule}>
							<div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`} >
								<div className="orglogocerti" style={{ width: '100%', paddingLeft: '0px' }} >
									<Checkbox
										style={{ paddingLeft: '0px' }}
										size="small"
										color="primary"
										checked={includeCertificateLogo}
										className={styles.checkBox}
										onClick={() => setIncludeCertificateLogo(!includeCertificateLogo)}
									/>{" "}
									<span className={styles.labelText}>{allResourcesToTranslations.orglogocerti}</span>
								</div>
								<div className={styles.notesContainer}>
									<span className={styles.labelTextSmallNormal}>{allResourcesToTranslations.orglogocertinote}</span>
								</div>
								<div className="saltlogocerti" style={{ width: '100%', paddingLeft: '0px' }} >
									<Checkbox
										style={{ paddingLeft: '0px' }}
										size="small"
										color="primary"
										checked={isCertificateWithoutSaltLogo}
										className={styles.checkBox}
										onClick={() => setIsCertificateWithoutSaltLogo(!isCertificateWithoutSaltLogo)}
									/>{" "}
									<span className={styles.labelText}>{allResourcesToTranslations.saltlogocerti}</span>
								</div>
								<div className={styles.notesContainer}>
									<span className={styles.labelTextSmallNormal}>{allResourcesToTranslations.saltlogocertinote}</span>
								</div>
								<div className="activatepassword" style={{ width: '100%', paddingLeft: '0px' }} >
									<Checkbox
										style={{ paddingLeft: '0px' }}
										size="small"
										color="primary"
										checked={passwordLockout}
										className={styles.checkBox}
										onClick={() => setPasswordLockout(!passwordLockout)}
									/>{" "}
									<span className={styles.labelText}>{allResourcesToTranslations.activatepassword}</span>
								</div>
								<div className={styles.notesContainer}>
									<span className={styles.labelTextSmallNormal}>{allResourcesToTranslations.activatepasswordnote}</span>
								</div>
								<div className="showlastpass" style={{ width: '100%', paddingLeft: '0px' }} >
									<Checkbox
										style={{ paddingLeft: '0px' }}
										size="small"
										color="primary"
										checked={showLastPassed}
										className={styles.checkBox}
										onClick={() => setShowLastPassed(!showLastPassed)}
									/>{" "}
									<span className={styles.labelText}>{allResourcesToTranslations.showlastpass}</span>
								</div>
								<div className={styles.notesContainer}>
									<span className={styles.labelTextSmallNormal}>{allResourcesToTranslations.showlastpassnote}</span>
								</div>

							</div>
							<div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`} style={{ verticalAlign: "top" }} >
								<div className="disablepassword" style={{ width: '100%', paddingLeft: '0px' }} >
									<Checkbox
										style={{ paddingLeft: '0px' }}
										size="small"
										color="primary"
										checked={disablePasswordField}
										className={styles.checkBox}
										onClick={() => setDisablePasswordField(!disablePasswordField)}
									/>{" "}
									<span className={styles.labelText}>{allResourcesToTranslations.disablepassword}</span>
								</div>
								<div className={styles.notesContainer}>
									<span className={styles.labelTextSmallNormal}>{allResourcesToTranslations.disablepasswordnote}</span>
								</div>
								<div className="showwelcomepopup" style={{ width: '100%', paddingLeft: '0px' }} >
									<Checkbox
										style={{ paddingLeft: '0px' }}
										size="small"
										color="primary"
										checked={showWelcomePopup}
										className={styles.checkBox}
										onClick={() => setShowWelcomePopup(!showWelcomePopup)}
									/>{" "}
									<span className={styles.labelText}>{allResourcesToTranslations.showwelcomepopup}</span>
								</div>
								<div className={styles.notesContainer}>
									<span className={styles.labelTextSmallNormal}>{allResourcesToTranslations.showwelcomepopupnote}</span>
								</div>
								<div className="advancedreporting" style={{ width: '100%', paddingLeft: '0px' }} >
									<Checkbox
										style={{ paddingLeft: '0px' }}
										size="small"
										color="primary"
										checked={advancedReporting}
										disabled
										className={styles.checkBox}
										onClick={() => setAdvancedReporting(!advancedReporting)}
									/>{" "}
									<span className={styles.labelText}>{allResourcesToTranslations.advancedreporting}</span>
								</div>
								<div className={styles.notesContainer}>
									<span className={styles.labelTextSmallNormal}>{allResourcesToTranslations.advancedreportingnote}</span>
								</div>
								<div className="enablepasswordrule" style={{ width: '100%', paddingLeft: '0px' }} >
									<Checkbox
										style={{ paddingLeft: '0px' }}
										size="small"
										color="primary"
										checked={enablePasswordRule}
										className={styles.checkBox}
										onClick={() => handleEnablePasswordRule()}
									/>{" "}
									<span className={styles.labelText}>{allResourcesToTranslations.enablepasswordrule}</span>
								</div>
								<div className={styles.notesContainer}>
									<span className={styles.labelTextSmallNormal}>{allResourcesToTranslations.enablepasswordrulenote}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</UnbrandedCard>


			{(enablePasswordRule) && (

				<div>
					<UnbrandedCard>
						<Accordion style={{ margin: "20px 0px", borderRadius: "6px 6px 6px 6px", border: '1px solid #D3D6DF', position: 'inherit', boxShadow: "none" }} expanded={expandEnablePasswordRule} >
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="unitadmin-content"
								id="unitadmin-header"
								onClick={(e) =>  setExpandEnablePasswordRule(!expandEnablePasswordRule)}
							>
								<Typography><div className={styles.lableContainer} >
							<span className={styles.labelText}><b>Password Complexity Rules</b></span>
						</div></Typography>
							</AccordionSummary>
							<AccordionDetails style={{ paddingTop: "0px", width: '98%' }}>
								<Typography style={{width: '100%'}}>
								<div className={responsivestyles.rowrule} style={{ width: '100%'}} >
									<div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`} >
										<div className="maxchartext">
											<Checkbox
												style={{ paddingLeft: '0px' }}
												size="small"
												color="primary"
												checked={true}
												className={styles.checkBox}
												disabled
											/>{" "}
											<span className={styles.labelText}>{allResourcesToTranslations.maxchartext}</span>
										</div>
										<div className="IsPasswordRuleNumber">
											<Checkbox
												style={{ paddingLeft: '0px' }}
												size="small"
												color="primary"
												checked={true}
												className={styles.checkBox}
												disabled
											/>{" "}
											<span className={styles.labelText}>{allResourcesToTranslations.minchartext}</span>
										</div>
									</div>
									<div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`} >
										<div className="IsPasswordRuleCapital" >
											<Checkbox
												style={{ paddingLeft: '0px' }}
												size="small"
												color="primary"
												checked={isPasswordRuleCapital}
												className={styles.checkBox}
												onClick={() => setIsPasswordRuleCapital(!isPasswordRuleCapital)}
											/>{" "}
											<span className={styles.labelText}>{allResourcesToTranslations.mincapital}</span>
										</div>
										<div className="IsPasswordRuleNumber" >
											<Checkbox
												style={{ paddingLeft: '0px' }}
												size="small"
												color="primary"
												checked={isPasswordRuleNumber}
												className={styles.checkBox}
												onClick={() => setIsPasswordRuleNumber(!isPasswordRuleNumber)}
											/>{" "}
											<span className={styles.labelText}>{allResourcesToTranslations.minnumberrequired}</span>
										</div>
										<div className="IsPasswordRuleSpecialChar" >
											<Checkbox
												style={{ paddingLeft: '0px' }}
												size="small"
												color="primary"
												checked={isPasswordRuleSpecialChar}
												className={styles.checkBox}
												onClick={() => setIsPasswordRuleSpecialChar(!isPasswordRuleSpecialChar)}
											/>{" "}
											<span className={styles.labelText}>{allResourcesToTranslations.minspecialcharreq}</span>
										</div>
									</div>
								</div>
								</Typography>
							</AccordionDetails>
						</Accordion>
					</UnbrandedCard>
					
				</div>)}

			<UnbrandedCard>
				<div className={styles.mainWrapper}>
					<div className={styles.Wrapper}>
						<div className={styles.lableContainer} >
							<span className={styles.labelText}>{allResourcesToTranslations.additionalsettings}</span>
						</div>
						<div className={styles.inputContainer}>
							<span className={styles.labelTextitalic}>{allResourcesToTranslations.additionalsettingstext}</span>
						</div>
						<div className={styles.inputContainer}>
							<div style={{ marginTop: "20px" }} className="timezone">
								<MUICustomSelect
									idName={"timezoneOption"}
									labelName={allResourcesToTranslations.timezone}
									currentValue={selectedTimezone.value}
									setCurrentValue={(e: any) => {
										if (!!e.target) {
											setTimeZoneID(e.target.value);
											setSelectedTimezone({
												value: e.target.value,
												text: (e.target as HTMLSelectElement).options[
													(e.target as HTMLSelectElement).selectedIndex
												].text,
											});
										} else {
											let filtered = !!timezoneList
												? timezoneList.filter(
													(cur: any) =>
														!!cur && parseInt(cur.value) === parseInt(e)
												)
												: null;
											if (!!filtered && filtered.length > 0) {
												setTimeZoneID(filtered[0].value);
												setSelectedTimezone({
													value: filtered[0].value,
													text: filtered[0].text,
												});
											}
										}
									}}
									isFocussed={isTimezoneOptionFocused}
									setIsFocussed={setIsTimezoneOptionFocused}
									inputLabelStyle={{}}
									listOfItems={timezoneList}
								/>
							</div>
						</div>
					</div>
				</div>
			</UnbrandedCard>
			<div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", width: "100%", paddingTop: "10px" }}>
				<CustomTooltip displayText={[allResourcesToTranslations.saveconfigitems]}>
					<span className="saveData" style={{ marginRight: "-5px" }}>
						<Button
							disabled={orgtimeZoneId == 0}
							onClick={handleSave}
							variant="contained"
							color="primary"
							className="save"
						>
							<SaveIcon />
						</Button>
					</span>
				</CustomTooltip>
				<div className={styles.loaderContainer}>
					<CircularProgress
						style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
						color="primary"
						size="10"
					/>
				</div>
			</div>
			{showModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}

		</>
	);
};

export default ConfigurationItems;
