import axios from 'axios';
import { addError } from '../../AddError';
import { GroupingOptionsList } from '../../../globalTypes';

export const SaveOrgLinks = async (token: string, userId: number, orgDomain: string, orgId: number | null, linkID: number, caption: string, url: string, 
  showDisclaimer: boolean, linkOrder: number,act:string ) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/organisation/SaveOrgLinks`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {       
        UserId: userId,
        OrgId: orgId,
        LinkID: linkID,
        Caption: caption,
        Url: url,
        ShowDisclaimer: showDisclaimer,
        LinkOrder: linkOrder,
        Act:act
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "SaveOrgLinks.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "SaveOrgLinks.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "SaveOrgLinks.ts", 1)
    }
    return null;
  }
};

export default SaveOrgLinks;