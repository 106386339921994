import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const errorIcon = () => (
<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1412_2154)">
<path d="M8.85783 15.4273C12.5397 15.4273 15.5245 12.4426 15.5245 8.76066C15.5245 5.07876 12.5397 2.09399 8.85783 2.09399C5.17593 2.09399 2.19116 5.07876 2.19116 8.76066C2.19116 12.4426 5.17593 15.4273 8.85783 15.4273Z" fill="#DA4453" stroke="#DA4453" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
<path d="M8.85791 11.4275H8.86458" stroke="white" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
<path d="M8.85791 6.09399V8.76066" stroke="white" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1412_2154">
<rect width="16" height="16" fill="white" transform="translate(0.85791 0.760742)"/>
</clipPath>
</defs>
</svg>


);

export default errorIcon