import axios from 'axios';
import { addError } from './AddError';
import shuffle from '../functions/shuffleArray';

export const getInfoPathQuizData = async (token: string, userId: number, orgDomain: string, courseId: number, moduleId: number, questionCount: number) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/infopath/GetQuiz`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        courseId: courseId,
        moduleid: moduleId,
      },
    });
    let data = await response.data;
    //Shuffling the questions and picking the required question count 
    let qQuestions = []
    let qQuestionsFinal = []
    if (data[0].quizQuestions != undefined){      
      var isUnique = false;
      while (isUnique === false) {
        qQuestions = shuffle(data[0].quizQuestions)
        qQuestionsFinal = qQuestions
        if (qQuestions.length <= 0){
          addError(token, userId, orgDomain, "GetInfoPathQuiz-NoQuizQuestionsReturned-StillProceed", null, "GetInfoPathQuizData.ts", 6)
        }
        if (questionCount <= 0){
          addError(token, userId, orgDomain, "GetInfoPathQuiz-GivenOrgQuizQuestionCountZero-StillProceed", null, "GetInfoPathQuizData.ts", 6)
        }
        if (questionCount > qQuestions.length) {
          questionCount = qQuestions.length
          addError(token, userId, orgDomain, "GetInfoPathQuiz-LessorQuizBankQuestionCountUsed", null, "GetInfoPathQuizData.ts", 6)
        }
        if (questionCount <= qQuestions.length) {
          qQuestionsFinal = qQuestions.slice(0, questionCount)
        }
        const uniqueQNums = new Map();
        isUnique = true;
        for (let i = 0; i < qQuestionsFinal.length; i++) {
          qQuestionsFinal[i].questionIndex = i + 1;
          if (uniqueQNums.get(qQuestionsFinal[i].questionNumber) === true) {
            isUnique = false;
          }
          uniqueQNums.set(qQuestionsFinal[i].questionNumber, true);
        }
        if (isUnique === false){
          addError(token, userId, orgDomain, "GetInfoPathQuiz-DuplicatesSeen-Resuffling", null, "GetInfoPathQuizData.ts", 6)
        }
      }
      data[0].quizQuestions = qQuestionsFinal
    }
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "GetInfoPathQuizData.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "GetInfoPathQuizData.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "GetInfoPathQuizData.ts", 1)
    }
    return null;
  }
};

export default getInfoPathQuizData;
