import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const MyTrainingTooltipRise360 = () => (
<svg width="36" height="46" viewBox="0 0 36 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17" cy="16" r="15.75" fill="white" stroke="#E5683C" stroke-width="0.5"/>
<path d="M19.2454 20.5135C18.6795 20.5135 18.1276 20.4207 17.5896 20.2352C17.0516 20.0497 16.5878 19.7807 16.1982 19.4282L16.6296 18.7881C17.0377 19.1221 17.4505 19.3725 17.8679 19.5395C18.2946 19.7065 18.7491 19.7899 19.2315 19.7899C19.8158 19.7899 20.2796 19.674 20.6228 19.4421C20.9753 19.2009 21.1516 18.8624 21.1516 18.4264C21.1516 18.2223 21.1052 18.0554 21.0124 17.9255C20.9197 17.7864 20.7805 17.6658 20.595 17.5637C20.4188 17.4617 20.1915 17.3736 19.9132 17.2994C19.6442 17.2159 19.3289 17.1324 18.9671 17.0489C18.5497 16.9469 18.1879 16.8449 17.8818 16.7428C17.5757 16.6408 17.3206 16.5248 17.1166 16.395C16.9218 16.2651 16.7733 16.1074 16.6713 15.9219C16.5786 15.7271 16.5322 15.4859 16.5322 15.1984C16.5322 14.8366 16.6017 14.5166 16.7409 14.2383C16.8893 13.9601 17.0841 13.7328 17.3253 13.5566C17.5757 13.371 17.8633 13.2319 18.1879 13.1391C18.5218 13.0464 18.8743 13 19.2454 13C19.8019 13 20.3075 13.0928 20.762 13.2783C21.2165 13.4545 21.5829 13.6911 21.8612 13.9879L21.402 14.5444C21.133 14.2662 20.8037 14.0574 20.4141 13.9183C20.0338 13.7792 19.635 13.7096 19.2175 13.7096C18.9671 13.7096 18.7352 13.7374 18.5218 13.7931C18.3085 13.8395 18.1183 13.9183 17.9514 14.0296C17.7937 14.1409 17.6685 14.2847 17.5757 14.461C17.483 14.6279 17.4366 14.8273 17.4366 15.0592C17.4366 15.254 17.469 15.4117 17.534 15.5323C17.5989 15.6529 17.7056 15.7596 17.854 15.8523C18.0024 15.9358 18.1879 16.0147 18.4105 16.0889C18.6424 16.1538 18.9207 16.2234 19.2454 16.2976C19.7092 16.4089 20.1173 16.5202 20.4698 16.6315C20.8223 16.7428 21.1145 16.8727 21.3464 17.0211C21.5875 17.1695 21.7684 17.3504 21.889 17.5637C22.0096 17.7771 22.0699 18.0368 22.0699 18.3429C22.0699 19.0108 21.8148 19.5395 21.3046 19.9291C20.7944 20.3187 20.108 20.5135 19.2454 20.5135Z" fill="#E5683C"/>
<path d="M15.6176 13.9601C14.9776 13.9786 14.421 14.1549 13.9479 14.4888C13.4749 14.8227 13.1409 15.2819 12.9461 15.8663V20.3743H12V13.1252H12.8905V14.8645C13.1409 14.3543 13.4702 13.9322 13.8784 13.5983C14.2865 13.2551 14.7271 13.0835 15.2002 13.0835C15.2837 13.0835 15.3625 13.0835 15.4367 13.0835C15.5109 13.0835 15.5712 13.0881 15.6176 13.0974V13.9601Z" fill="#E5683C"/>
<path d="M35.0963 42.5458C35.0963 43.0902 35.055 43.5764 34.9725 44.0046C34.8899 44.4327 34.7599 44.7951 34.5826 45.0917C34.4052 45.3883 34.1758 45.6146 33.8945 45.7706C33.6131 45.9235 33.2752 46 32.8807 46C32.3823 46 31.9694 45.8639 31.6422 45.5917C31.318 45.3195 31.0749 44.9266 30.9128 44.4128C30.7538 43.896 30.6743 43.2737 30.6743 42.5458C30.6743 41.8303 30.7462 41.2156 30.8899 40.7018C31.0367 40.185 31.2722 39.789 31.5963 39.5137C31.9205 39.2355 32.3486 39.0963 32.8807 39.0963C33.3853 39.0963 33.8012 39.2339 34.1284 39.5092C34.4557 39.7813 34.6988 40.1758 34.8578 40.6926C35.0168 41.2064 35.0963 41.8241 35.0963 42.5458ZM31.4404 42.5458C31.4404 43.1697 31.4878 43.6896 31.5826 44.1055C31.6774 44.5214 31.8303 44.8333 32.0413 45.0413C32.2554 45.2461 32.5352 45.3486 32.8807 45.3486C33.2263 45.3486 33.5046 45.2461 33.7156 45.0413C33.9297 44.8364 34.0841 44.526 34.1789 44.1101C34.2768 43.6942 34.3257 43.1728 34.3257 42.5458C34.3257 41.9312 34.2783 41.4174 34.1835 41.0046C34.0887 40.5887 33.9358 40.2767 33.7248 40.0688C33.5138 39.8578 33.2324 39.7523 32.8807 39.7523C32.5291 39.7523 32.2477 39.8578 32.0367 40.0688C31.8257 40.2767 31.6728 40.5887 31.578 41.0046C31.4862 41.4174 31.4404 41.9312 31.4404 42.5458Z" fill="white"/>
<path d="M25.3579 43.0412C25.3579 42.6376 25.3854 42.2431 25.4405 41.8578C25.4986 41.4724 25.5949 41.1146 25.7295 40.7844C25.864 40.451 26.0475 40.159 26.2799 39.9082C26.5123 39.6575 26.8013 39.4617 27.1469 39.3211C27.4955 39.1773 27.913 39.1055 28.3992 39.1055C28.5368 39.1055 28.6851 39.1131 28.8441 39.1284C29.0032 39.1406 29.1347 39.162 29.2386 39.1926V39.8486C29.1255 39.8088 28.9955 39.7798 28.8487 39.7614C28.705 39.7431 28.5597 39.7339 28.4129 39.7339C27.8441 39.7339 27.3961 39.8578 27.0689 40.1055C26.7448 40.3532 26.5108 40.6896 26.3671 41.1146C26.2234 41.5367 26.1408 42.0122 26.1194 42.5412H26.1698C26.2646 42.3883 26.3854 42.2507 26.5322 42.1284C26.679 42.003 26.8564 41.9036 27.0643 41.8302C27.2753 41.7568 27.52 41.7201 27.7983 41.7201C28.1897 41.7201 28.5307 41.7997 28.8212 41.9587C29.1148 42.1177 29.3411 42.3486 29.5001 42.6513C29.6622 42.9541 29.7432 43.3211 29.7432 43.7522C29.7432 44.214 29.6576 44.6131 29.4863 44.9495C29.3151 45.2859 29.0704 45.5458 28.7524 45.7293C28.4374 45.9097 28.0613 45.9999 27.624 45.9999C27.2967 45.9999 26.9955 45.9373 26.7203 45.8119C26.4451 45.6865 26.205 45.4999 26.0001 45.2522C25.7983 45.0045 25.6408 44.6972 25.5276 44.3302C25.4145 43.9602 25.3579 43.5305 25.3579 43.0412ZM27.6148 45.3577C28.0307 45.3577 28.364 45.2247 28.6148 44.9587C28.8655 44.6926 28.9909 44.2905 28.9909 43.7522C28.9909 43.3119 28.8793 42.9633 28.6561 42.7064C28.4328 42.4495 28.0964 42.3211 27.6469 42.3211C27.3411 42.3211 27.075 42.3853 26.8487 42.5137C26.6224 42.6391 26.4466 42.7981 26.3212 42.9908C26.1958 43.1834 26.1331 43.3792 26.1331 43.5779C26.1331 43.7798 26.1622 43.9847 26.2203 44.1926C26.2815 44.4006 26.3732 44.5932 26.4955 44.7706C26.6178 44.948 26.7723 45.0902 26.9588 45.1972C27.1454 45.3042 27.364 45.3577 27.6148 45.3577Z" fill="white"/>
<path d="M24.0596 40.766C24.0596 41.0657 24.0015 41.3256 23.8853 41.5458C23.7691 41.766 23.607 41.9464 23.3991 42.0871C23.1911 42.2247 22.9465 42.3211 22.6652 42.3761V42.4128C23.1973 42.4801 23.5963 42.6513 23.8624 42.9266C24.1315 43.2018 24.2661 43.5626 24.2661 44.0091C24.2661 44.3975 24.1743 44.7415 23.9908 45.0412C23.8104 45.3409 23.5321 45.5764 23.156 45.7477C22.7829 45.9159 22.3043 45.9999 21.7202 45.9999C21.3655 45.9999 21.0367 45.9709 20.734 45.9128C20.4343 45.8577 20.1468 45.7645 19.8716 45.633V44.9174C20.1499 45.055 20.4526 45.1636 20.7798 45.2431C21.1071 45.3195 21.4236 45.3577 21.7294 45.3577C22.341 45.3577 22.7829 45.237 23.0551 44.9954C23.3272 44.7507 23.4633 44.4174 23.4633 43.9954C23.4633 43.7048 23.3869 43.4709 23.234 43.2935C23.0841 43.1131 22.867 42.9816 22.5826 42.899C22.3012 42.8134 21.9618 42.7706 21.5642 42.7706H20.8945V42.1192H21.5688C21.9327 42.1192 22.2416 42.0657 22.4954 41.9587C22.7493 41.8516 22.9419 41.7018 23.0734 41.5091C23.208 41.3134 23.2752 41.0825 23.2752 40.8165C23.2752 40.477 23.1621 40.2156 22.9358 40.0321C22.7095 39.8455 22.4022 39.7523 22.0138 39.7523C21.7752 39.7523 21.5581 39.7767 21.3624 39.8257C21.1667 39.8746 20.9832 39.9419 20.8119 40.0275C20.6407 40.1131 20.4694 40.2125 20.2982 40.3256L19.9129 39.8027C20.1575 39.6131 20.4557 39.4495 20.8074 39.3119C21.159 39.1743 21.5581 39.1055 22.0046 39.1055C22.6896 39.1055 23.2034 39.2614 23.5459 39.5734C23.8884 39.8853 24.0596 40.2828 24.0596 40.766Z" fill="white"/>
<path d="M14.4724 40.7889C14.9006 40.7889 15.2675 40.8837 15.5733 41.0733C15.8792 41.2629 16.1131 41.529 16.2752 41.8715C16.4373 42.211 16.5183 42.6085 16.5183 43.0642V43.5366H13.0458C13.055 44.1269 13.2018 44.5764 13.4862 44.8853C13.7706 45.1941 14.1712 45.3486 14.688 45.3486C15.0061 45.3486 15.2874 45.3195 15.5321 45.2614C15.7767 45.2033 16.0305 45.1177 16.2935 45.0045V45.6742C16.0397 45.7874 15.7874 45.87 15.5366 45.922C15.2889 45.9739 14.9954 45.9999 14.6559 45.9999C14.1727 45.9999 13.7507 45.9021 13.3899 45.7064C13.0321 45.5076 12.7538 45.2171 12.555 44.8348C12.3562 44.4525 12.2568 43.9847 12.2568 43.4311C12.2568 42.8899 12.347 42.422 12.5275 42.0275C12.711 41.6299 12.9678 41.3241 13.2981 41.11C13.6315 40.896 14.0229 40.7889 14.4724 40.7889ZM14.4633 41.4128C14.0565 41.4128 13.7324 41.5458 13.4908 41.8119C13.2492 42.0779 13.1055 42.4495 13.0596 42.9265H15.7201C15.7171 42.6269 15.6697 42.3639 15.5779 42.1376C15.4892 41.9082 15.3532 41.7308 15.1697 41.6055C14.9862 41.477 14.7507 41.4128 14.4633 41.4128Z" fill="white"/>
<path d="M11.3121 44.5321C11.3121 44.8532 11.231 45.1238 11.0689 45.344C10.9099 45.5611 10.6806 45.7247 10.3809 45.8348C10.0842 45.9449 9.72949 45.9999 9.31664 45.9999C8.96496 45.9999 8.66068 45.9724 8.4038 45.9174C8.14692 45.8623 7.92215 45.7843 7.72949 45.6834V44.9816C7.93438 45.0825 8.17903 45.1743 8.46344 45.2568C8.74784 45.3394 9.03836 45.3807 9.33499 45.3807C9.76924 45.3807 10.0842 45.3103 10.2799 45.1697C10.4757 45.029 10.5735 44.8379 10.5735 44.5963C10.5735 44.4587 10.5338 44.3379 10.4543 44.2339C10.3778 44.1269 10.2478 44.0244 10.0643 43.9265C9.88086 43.8256 9.62704 43.7155 9.30288 43.5963C8.98178 43.4739 8.7035 43.3532 8.46802 43.2339C8.23561 43.1116 8.05518 42.9632 7.92674 42.7889C7.80136 42.6146 7.73867 42.3883 7.73867 42.11C7.73867 41.685 7.90992 41.3593 8.25243 41.133C8.59799 40.9036 9.05059 40.7889 9.61022 40.7889C9.91297 40.7889 10.1958 40.8195 10.4588 40.8807C10.7249 40.9388 10.9726 41.0183 11.202 41.1192L10.9451 41.7293C10.7371 41.6406 10.5154 41.5657 10.2799 41.5045C10.0445 41.4434 9.80441 41.4128 9.55976 41.4128C9.20808 41.4128 8.93744 41.4709 8.74784 41.5871C8.56129 41.7033 8.46802 41.8623 8.46802 42.0642C8.46802 42.2201 8.51084 42.3486 8.59646 42.4495C8.68515 42.5504 8.82582 42.6452 9.01848 42.7339C9.21114 42.8226 9.46496 42.9265 9.77995 43.0458C10.0949 43.162 10.3671 43.2828 10.5965 43.4082C10.8258 43.5305 11.0017 43.6804 11.124 43.8577C11.2494 44.0321 11.3121 44.2568 11.3121 44.5321Z" fill="white"/>
<path d="M6.44502 40.8807V45.9082H5.68355V40.8807H6.44502ZM6.07346 39C6.19884 39 6.30588 39.0413 6.39456 39.1239C6.4863 39.2034 6.53218 39.3287 6.53218 39.5C6.53218 39.6682 6.4863 39.7936 6.39456 39.8761C6.30588 39.9587 6.19884 40 6.07346 40C5.94196 40 5.83187 39.9587 5.74319 39.8761C5.65756 39.7936 5.61475 39.6682 5.61475 39.5C5.61475 39.3287 5.65756 39.2034 5.74319 39.1239C5.83187 39.0413 5.94196 39 6.07346 39Z" fill="white"/>
<path d="M1.81192 39.2019C2.36238 39.2019 2.81651 39.2707 3.1743 39.4083C3.53516 39.5429 3.80427 39.7493 3.98164 40.0276C4.15901 40.3059 4.2477 40.6606 4.2477 41.0918C4.2477 41.4465 4.18348 41.7432 4.05504 41.9817C3.9266 42.2172 3.7584 42.4083 3.55045 42.5551C3.3425 42.7019 3.12079 42.815 2.88531 42.8946L4.72476 45.9083H3.8165L2.17431 43.1102H0.779815V45.9083H0V39.2019H1.81192ZM1.76605 39.8762H0.779815V42.4496H1.84403C2.39143 42.4496 2.79357 42.338 3.05045 42.1147C3.31039 41.8915 3.44036 41.5628 3.44036 41.1285C3.44036 40.6728 3.30274 40.3502 3.02752 40.1606C2.75535 39.971 2.33486 39.8762 1.76605 39.8762Z" fill="white"/>
</svg>


);

export default MyTrainingTooltipRise360
