import axios from 'axios';
import { addError } from '../../AddError';

export const addOrUpdateUser = async ( 
    type: string,
    token: string,
    userId: number,
    orgId: number,
    orgDomain: string,
    unitID: number,
    firstName: string,
    lastName: string,
    userName: string,
    email: string,
    active: boolean,
    userTypeID: number,
    externalID: string,
    TimeZoneID: number,
    DelinquencyManagerEmail: string,
    NotifyUnitAdmin: boolean,
    NotifyOrgAdmin: boolean,
    NotifyMgr: boolean,
    EbookNotification: boolean,
    password: string,
    userIdToUpadate: number,
    selectedClassificationId: number,
    isReactivated: boolean = false,
    isUnitChanged: boolean = false,
    ) => {

    let payloadData;
    if(type == "add") {
      payloadData = {
        "RequestingUserID" : userId,  
        "OrganisationID" : orgId,
         "unitID" : unitID,
         "firstName" : firstName,
         "lastName" : lastName,
         "userName" :  userName,
         "email" : email,
         "active" : active,
         "userTypeID" :userTypeID,   
         "externalID" : externalID,
         "TimeZoneID" : TimeZoneID,
         "DelinquencyManagerEmail":DelinquencyManagerEmail,
         "NotifyUnitAdmin":NotifyUnitAdmin,
         "NotifyOrgAdmin":NotifyOrgAdmin,
         "NotifyMgr": NotifyMgr,
         "EbookNotification":EbookNotification,
         "password":password,
         "SelectedClassificationId": selectedClassificationId,
         "IsReactivated": isReactivated,
         "IsUnitUpdated": isUnitChanged
     }
    } else {
      payloadData = {
        "RequestingUserID" : userId,  
        "OrganisationID" : orgId,
         "unitID" : unitID,
         "firstName" : firstName,
         "lastName" : lastName,
         "userName" :  userName,
         "email" : email,
         "active" : active,
         "userTypeID" : userTypeID,   
         "externalID" : externalID,
         "TimeZoneID" : TimeZoneID,
         "DelinquencyManagerEmail":DelinquencyManagerEmail,
         "NotifyUnitAdmin":NotifyUnitAdmin,
         "NotifyOrgAdmin":NotifyOrgAdmin,
         "NotifyMgr": NotifyMgr,
         "EbookNotification":EbookNotification,
         "password":password,   
         "userID" : userIdToUpadate,
         "updatedByUserID": userId,
         "SelectedClassificationId": selectedClassificationId,
         "IsReactivated": isReactivated,
         "IsUnitUpdated": isUnitChanged
     }
    }

    try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/api/administration/AddOrUpdateUser`,
          headers:{
            'Content-Type': 'application/json',
            'Authorization' : `Bearer ${token}`
          },    
          data: {...payloadData}
        });
        const data = await response.data;
        return data;
      } catch (error: any) {
        if (error.response) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.response);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.response, "checkIfSessionIsStillActive.ts", 1)
        } else if (error.request) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.request);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.request, "checkIfSessionIsStillActive.ts", 1)
        } else if (error.message) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.message);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.message, "checkIfSessionIsStillActive.ts", 1)
        }
        return null;
      }

}

export default addOrUpdateUser;