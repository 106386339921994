import axios from 'axios';
import { addError } from './AddError';



export const addOrUpdateReportScheduler = async ( 
    isUpdate: boolean, // True for update and false for insert
    token: string,
    userId: number,
    orgId: number,
    unitIds: string,
    courseIds: string,
    filterFirstName: string,
    filterLastName: string,
    filterUserName: string,
    filterEmail: string,
    filterSubject: string,
    filterBody: string,
    filterStatus: string,
    filterCourseStatus: string,
    filterIncludeInactive: string,
    filterGroupBy: string,
    filterSortBy: string,
    filterClassificationID: number,
    langCode: string,
    failCount: number,
    retryCount: number,
    filterDateTo: string | null,
    filterDateFrom: string | null,
    reportFromDate: string | null, // Default is null but if the second period section is given then use the 3rd options' from-date of it
    nextRunDate: string,
    nextRunDateUTC: string,
    lastUpdatedDate: string,
    lastUpdatedBy: number,
    filterTimeExpired: number,
    filterTimeExpiredPeriod: string,
    profileID: number,
    filterAcceptance: string,
    filterOnlyUsersWithShortfall: string,
    filterLicensingPeriod: string,
    orgDomain: string,
    isPeriodicFrequencyValue: string,
    frequencyType: string,
    frequencyIntervalValue: string,
    documentType: string,
    endAfter: string,
    periodType: number,
    startReportDate: string | null,
    reportTitle: string,
    reportId: number,
    isNoEndDate: boolean,
    scheduleId: number | -1, // -1 for insert
    reportEndDate: string | null,
    ccUserIds: string | null,
    filterHistoricCourseIDs: string | null,
    filterPolicyIDs: string | null,
    isTesting: boolean | false,
    selectedProfileID: number | -1,
    selectedProfilePeriodID: number | -1,
    paramWarningExpiredIn: string | '',
    isHistoryChecked: boolean | false
    ) => {
    
    try {
       //console.log('filterCourseStatus = ' + filterCourseStatus)
        //debugger
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/api/userreport/AddOrUpdateReportSchedule`,
          headers:{
            'Content-Type': 'application/json',
            'Authorization' : `Bearer ${token}`
          },
    
          data: {
            IsUpdate : isUpdate, 
            ScheduleID : scheduleId, 
            ReportTitle :reportTitle,
            ReportID: reportId,
            ReportRequestingUserID: userId, 
            OrganisationID: orgId,
            UnitIDs: unitIds,
            CCUserIDs: ccUserIds,
            CourseIDs: courseIds,
            HistoricCourseIDs: filterHistoricCourseIDs,
            PolicyIDs: filterPolicyIDs,
            UserFirstName: filterFirstName,
            UserLastName: filterLastName,
            UserName: filterUserName,
            UserEmail: filterEmail,
            Subject: filterSubject,
            Body: filterBody,
            Status: filterStatus,
            QuizStatus: filterCourseStatus,
            InclInactive: filterIncludeInactive,
            GroupBy: filterGroupBy,
            SortBy: filterSortBy,
            ClassificationID: filterClassificationID,
            LangCode: langCode,
            IsPeriodic: isPeriodicFrequencyValue,
            DocumentType: documentType,
            FailCount: failCount,
            RetryCountIfFailed: retryCount,
            DateTo: filterDateTo,
            DateFrom: filterDateFrom,
            ReportStartDate: startReportDate,
            ReportFromDate: reportFromDate,
            ReportEndDate: reportEndDate,
            NextRunDate: nextRunDate,
            NextRunDateUTC: nextRunDateUTC,
            LastUpdatedDate: lastUpdatedDate,
            TimeExpired: filterTimeExpired,
            TimeExpiredPeriod: filterTimeExpiredPeriod,
            Frequency: frequencyIntervalValue,
            FrequencyPeriod: frequencyType,
            ProfileID: profileID,
            NumberOfReports: endAfter,
            ReportPeriodType: periodType,
            Acceptance: filterAcceptance,
            OnlyUsersWithShortfall: filterOnlyUsersWithShortfall,
            LicensingPeriod: filterLicensingPeriod,
            NoEndDateFlag: isNoEndDate,
            LocalTestingFlag: isTesting,
            IsAdditionUsingCopy: false,
            SelectedCPDProfileID: selectedProfileID,
            SelectedCPDProfilePeriodID: selectedProfilePeriodID,
            ParamWarningExpiredIn: paramWarningExpiredIn,
            IsHistoricReport: isHistoryChecked
        }
        });
        const data = await response.data;
        return data;
      } catch (error: any) {
        if (error.response) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.response);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.response, "AddOrUpdateReportScheduler.ts", 1)
        } else if (error.request) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.request);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.request, "AddOrUpdateReportScheduler.ts", 1)
        } else if (error.message) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.message);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.message, "AddOrUpdateReportScheduler.ts", 1)
        }
        return null;
      }

}

export default addOrUpdateReportScheduler;