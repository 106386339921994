import React from "react";

const CloudUpload = () => {

 return (    
    <svg width="24px" height="24px" viewBox="0 0 0.6 0.6" xmlns="http://www.w3.org/2000/svg">
        <path fill="#767676" fillRule="evenodd" d="M0.345 0.09a0.135 0.135 0 0 1 0.135 0.143H0.48l0 0.037a0.12 0.12 0 0 1 0.12 0.114l0 0.006a0.12 0.12 0 0 1 -0.114 0.12l-0.006 0h0L0.48 0.51h-0.15v-0.118h0.074L0.3 0.27l-0.104 0.122L0.27 0.392 0.27 0.51H0.12a0.12 0.12 0 0 1 -0.12 -0.12 0.12 0.12 0 0 1 0.096 -0.118 0.09 0.09 0 0 1 0.129 -0.11A0.135 0.135 0 0 1 0.345 0.09Z"/>
    </svg>

)
}

export default CloudUpload;