import { Button, ButtonGroup, Checkbox, IconButton, TextField } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import SelectAllIcon from '../../CustomIcons/SelectAllIcon';
import UnSelectAllIcon from '../../CustomIcons/UnSelectAllIcon';
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Styles
import styles from '../../styles/externalCertificates.module.css'
//MUI Components
import SearchIcon from "@material-ui/icons/Search";

interface Props {
    selectAllExternalCertificates: Function
    deselectAllExternalCertificates: Function
    orgExternalCertificateOptions: any
    updateExternalCertificateOptions: Function
    handleExternalCertificateSearch: any
    searchExternalCertificate: string
    sectionFlag: boolean
}

const ExternalCertificateSelection = ({ selectAllExternalCertificates, searchExternalCertificate,
    handleExternalCertificateSearch, deselectAllExternalCertificates, orgExternalCertificateOptions,
    updateExternalCertificateOptions, sectionFlag }: Props) => {

    //const [searchExternalCertificate, setSearchExternalCertificate] = useState("");
    const [externalCertificates, setExternalCertificates] = useState<Array<any>>(orgExternalCertificateOptions);
    const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)

    const handleSelectAll = () => {
        selectAllExternalCertificates()
    }

    const handleDeselect = () => {
        deselectAllExternalCertificates()
    }

    useEffect(() => {
        setExternalCertificates(orgExternalCertificateOptions);
    }, [orgExternalCertificateOptions])

    useEffect(() => {
        if (externalCertificates.length > 0) {
            let filteredData = externalCertificates.map((item: any) => {
                if (!item.certificateName.toLowerCase().includes(searchExternalCertificate.toLowerCase())) {
                    return {
                        ...item,
                        isHidden: true
                    }
                } else {
                    return {
                        ...item,
                        isHidden: false
                    }
                }
            })

            setExternalCertificates(filteredData);
            updateExternalCertificateOptions(filteredData);
        }
    }, [searchExternalCertificate])

    const handleSelection = (event: any, extCertGiven: any) => {
        event.stopPropagation();

        let updateExternalCertificates = externalCertificates.map((item:any) => {
            if (!!extCertGiven && !!extCertGiven.externalCertificateId && 
                extCertGiven.externalCertificateId === item.externalCertificateId) {
                return {
                    ...item,
                    isSelected: !item.isSelected
                }
            }

            return item
        })
        setExternalCertificates(updateExternalCertificates);
        updateExternalCertificateOptions(updateExternalCertificates);
    }


    return (
        <div>
            <div id={sectionFlag ? "externalCertificateFilterButtons1" : "externalCertificateFilterButtons2"} className={"externalCertificateFilterButtonser"}>
                <h4 className={styles.sectionTitle}>{allResourcesToTranslations.extcertificate}</h4>
                <div className={"externalCertificateFilterButtons"} style={{ marginTop: '1px' }} id={"externalCertificateFilterButtons2"}>
                    <p className={styles.blankContent}>{allResourcesToTranslations.emrepsrchgmecsrchi}</p>
                    <ButtonGroup variant="contained" color="primary" aria-label="Filter">
                        <Button
                            className={styles.button}
                            size="small"
                            onClick={handleSelectAll}
                        >
                            <SelectAllIcon />
                            <span style={{ marginLeft: '5px', fontSize: '12px' }}>{allResourcesToTranslations.selectall}</span>
                        </Button>
                        <Button
                            className={styles.button}
                            size="small"
                            onClick={handleDeselect}
                        >
                            <UnSelectAllIcon />
                            <span style={{marginLeft:'5px', fontSize:'12px'}}>{allResourcesToTranslations.unselectall}</span>
                    </Button>
                </ButtonGroup>
            </div>
        </div>
        <div className={`externalCertificateSearcher ${styles.inputContainer}` }  id={sectionFlag ? "externalCertificateSearch1" : "externalCertificateSearch2"}>
            <div className={"externalCertificateSearch"}><TextField  className={styles.input} id={"externalCertificateSearch2"}
            label={allResourcesToTranslations.emrepsrchgmecsrch} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
            value={searchExternalCertificate} onChange={handleExternalCertificateSearch} 
            InputProps={{endAdornment: ( <IconButton><SearchIcon /></IconButton> )}}/></div>
        </div>
        <div className={styles.externalCertificateContainer}>
            <ul className={styles.ExternalCertificateUl}>
            { externalCertificates && externalCertificates.map((extCertCur:any) => (
                <li className={styles.treeLi} hidden={extCertCur.isHidden === true} 
                key={`externalCertificate-builder-${extCertCur.externalCertificateId}`}>
                    <div className={styles.treeRow}>
                        <Checkbox
                            color="primary"
                            checked={extCertCur.isSelected}
                            //disabled={unit.isDisabled}
                            style={{padding: "3px", marginLeft:'11px' }}
                            onClick={(e) => handleSelection(e, extCertCur)}
                            />
                        <div>                    
                            <span onClick={(e) => handleSelection(e, extCertCur)}>
                                { extCertCur.certificateName}
                            </span>
                        </div>
                    </div>
                </li>
            ))}
            </ul></div>
        </div>
    )

}

export default ExternalCertificateSelection;