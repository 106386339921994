import { CloseOutlined } from '@material-ui/icons';
import axios from 'axios';
import { addError } from '../AddError';

export const getFullSentEmailOrgDeactivationReport = async (
    token: string, 
    userId: number,
    orgDomain: string,
    orgId: number,    
    datefrom: string,   
    dateto: string,
    toEmail: string, 
    subject: string,
    body: string,
    emailid:number,orgDeactivationIDIfRelated:number,selectedOrgID: number = -1, selectedOrgDomainName: string = "", selectOrgSiteName: string = ""
    ) => {
      
    //     console.log({
    //     OrganisationID: orgId,
    //     ReportRequestingUserID : userId,      
    //     DateFrom : datefrom,
    //     DateTo: dateto, 
    //     ToEmail: toEmail,
    //     AdminUserID: userId,
    //     Subject:subject,
    //     Body: body,
    //     EmailId:emailid,
    //     InputType:inputType
    // })


  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/SuperAdministration/GetOrgDeactivationSentEmailReport`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },     
      data: {
        ReportRequestingUserID : userId,
        OrganisationID : orgId,     
        DateFrom : datefrom,
        DateTo : dateto,//Filter params
        ToEmail:  toEmail,//Filter params
        //adminUserID: 1,//Always 1 ( Salt admin ) // Not used
        Subject:subject,//Filter params,
        Body: body, //Filter params
        EmailId:emailid,
        InputType: "",          
        orgDomainName: orgDomain,        
        orgDeactivationIDIfRelated:orgDeactivationIDIfRelated,
        selectedOrgID: selectedOrgID,
        selectedOrgDomainName: selectedOrgDomainName, 
        selectOrgSiteName: selectOrgSiteName
      },
    });
    const data = await response.data; 
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      };    
      addError(token, userId, orgDomain, "Rest Exception", error.response, "GetFullSentEmailOrgDeactivationReport.tsx", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      };    
      addError(token, userId, orgDomain, "Rest Exception", error.request, "GetFullSentEmailOrgDeactivationReport.tsx", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      };    
      addError(token, userId, orgDomain, "Rest Exception", error.message, "GetFullSentEmailOrgDeactivationReport.tsx", 1)
    }
    return null;
  }
};

export default getFullSentEmailOrgDeactivationReport;
