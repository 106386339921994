import axios from 'axios';
import { LearningModule } from '../globalTypes';
import { cleanUpPreQuizLaunch } from './CleanUpPreQuizLaunch'
import { getSAPassLaunchPageDetails } from './GetSAPassLaunchPageDetails'


export const getUserCourseModules = async (token: string, userId: number, orgDomain: string, courseId: number, profileId: number | -1) => {

  const getModules = async () => {
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/usermodules/list`,
        headers:{
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
        },
        data: {
          userid: userId,
          courseid: courseId,
          profileid: profileId,
          currentculture: "en-au",
        },
      });
      const data = await response.data;
      return data;
      
    } catch (error: any) {
      if (error.response) {
        console.log(error.response);
      } else if (error.request) {
        console.log(error.request);
      } else if (error.message) {
        console.log(error.message);
      }
      return null;
    }
  }

  const updateReviewRestartFlag = async (lmList: Array<LearningModule>) => {
    let updatedLearningModuleList: Array<LearningModule> = []
    for (let i = 0; i < lmList.length; i++) {
      const lm = lmList[i];
      await cleanUpPreQuizLaunch(token, userId, orgDomain, lm.id) //Clear any leftover DME's 
      const details = await getSAPassLaunchPageDetails(token, userId, orgDomain, lm.id) //Fetch quiz details and see if user can Review or Restart course
      if (details !== undefined) {
        lm.showReviewRestart =  details.isTypeSA && details.canOpenSAPassPreLaunch 
      } else {
        lm.showReviewRestart = false
      }
      updatedLearningModuleList.push(lm)
    }
    return updatedLearningModuleList;
  }

  const learningModuleList: Array<LearningModule> = await getModules()
  const result = await updateReviewRestartFlag(learningModuleList)

  return result
  
};

export default getUserCourseModules;
