//React + Typescript
import { useContext, useEffect, useState, useRef } from 'react';

// Libsimport
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';
import { CourseProgressPanelContext } from "../../contexts/CourseProgressContext";
import { CPDPanelContext } from "../../contexts/CPDPanelContext";
import CloseWhiteIcon from "@material-ui/icons/Close";
import Modal from '../../Components/Modal';
import styles from '../../styles/dashboard.module.css'
import responsivestyles from "../../styles/responsiveGridLayout.module.css";
import { getUserCPDs } from '../../API/UserCPDs';
//Intro Lib
import { Steps, Hints } from "intro.js-react";
//General Functions
import getColor from '../../functions/getColor';
//Components
import CustomTooltip from '../../Components/CustomTooltip';
import LoaderContainer from '../../Components/LoaderContainer';
//MUI Components
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import BrandedCard from '../../Components/BrandedCard';
import CommonPopupModalForResult from '../../Components/CommonPopupModalForResult';
import { MyExternalCertificates, MyTrainingItemsResult, MyExternalCertificateOnlyWithCPD } from '../../globalTypes';
import { CircularProgress, TableContainer, TablePagination, TextField } from '@material-ui/core';
import { MyTrainingListContext } from '../../contexts/MyTrainingListContext'
import CourseCompletionPanel from "../../Components/CourseCompletionPanel";
import CPDPanel from '../../Components/CPDPanel';

import GetMyExternalCertificates from '../../API/Administration/ExternalCertificate/GetMyExternalCertificates';
import GetUserExternalCertificatesOnlyWithCPDPoints from '../../API/Administration/ExternalCertificate/GetUserExternalCertificatesOnlyWithCPDPoints';

import CloudUploadIcon from "../../CustomIcons/CloudUploadIcon";
import PDFWhiteIcon from "../../CustomIcons/PDFWhiteIcon";
import ToggleRightIcon from '../../CustomIcons/ToggleRightIcon';
import ToggleLeftIcon from '../../CustomIcons/ToggleLeftIcon';
import FileInfoIcon from '../../CustomIcons/Administration/ImportAccounts_FileInfoIcon';

import EditIcon from "../../CustomIcons/SupportDetailsEditIcon";
import SaveIcon from '../../CustomIcons/SaveIcon';
import CloseBlackIcon from '../../CustomIcons/Administration/closeIcon';
import CertificateFileDropBox from './CertificateFileDropBox';
import SaveMyExternalCertificates from '../../API/Administration/ExternalCertificate/SaveMyExternalCertificates';
import getUserCoursesPoliciesOnlyWithProgress from '../../API/UserCoursesPoliciesOnlyWithProgress';
import UpArrowIcon from '../../CustomIcons/UpArrowIcon';
import DownArrowIcon from '../../CustomIcons/DownArrowIcon';
import GuideIcon from '../../CustomIcons/GuideIcon';
import React from 'react';
import ErrorIcon from '../../CustomIcons/Administration/errorIcon';
const MyExternalCertificate = (props: any): JSX.Element => {

  const { myExternalCertificatesData } = props;
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext)
  const { color } = useContext(CorperateThemeContext).theme;
  const { setMessage } = useContext(LoaderContext);
  const { showCPD, setShowCPD, setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection,
    showQuizUI, showPolicyUI, showMyCertificateUI,
    setShowPolicyUI, setShowMyCertificateUI, setShowQuizUI, setShowCourseUI, latestUserCPDs, setLatestUserCPDs } = useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { oidcUser } = useReactOidc();
  const [showConfirmModal, setshowConfirmModal] = useState<boolean>(false);
  const [showPDFModal, setshowPDFModal] = useState<boolean>(false);

  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);


  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const requesteduserId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";

  const [showExpiredCertificate, setShowExpiredCertificate] = useState<boolean>(false);
  const { cpdList, setCpdList, currentCpd, setCurrentCpd, isCPDLoaded, setCPDLoaded } = useContext(CPDPanelContext);
  const [isCPDLoading, setCPDLoading] = useState<boolean>(true);
  const [isLocalCPDLoaded, setLocalCPDLoaded] = useState<boolean>(false);
  const { myTrainingList, setMyTrainingList, dashboardUpdateRequired, setDashboardUpdateRequired, setTrainingListUpdateRequired, myExtCertReloadRequired, setMyExtCertReloadRequired } = useContext(MyTrainingListContext)

  const [showExpiredCertificateUI, setShowExpiredCertificateUI] = useState<boolean>(false);

  const [selectedFile, setSelectedFile] = useState<string>("");
  const [selectedFileName, setSelectedFileName] = useState<string>("");
  const [selectedFileData, setSelectedFileData] = useState<string>("");
  const [selectedFileSize, setSelectedFileSize] = useState<number>(0);
  const [selectedFileExtension, setSelectedFileExtension] = useState<string>("");
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [hasNoValidDates, setHasNoValidDates] = useState<boolean>(false);
  const [myExternalCertificates, setMyExternalCertificates] = useState<Array<MyExternalCertificates>>([]);
  const [myExpiredExternalCertificates, setMyExpiredExternalCertificates] = useState<Array<MyExternalCertificates>>([]);
  const [myValidExternalCertificates, setMyValidExternalCertificates] = useState<Array<MyExternalCertificates>>([]);
  const [filterDateFrom, setFilterDateFrom] = useState("");
  const [filterDateTo, setFilterDateTo] = useState("");
  const [organisationID, setOrganisationID] = useState<number>(0);
  const [uploadedCertificateName, setUploadedCertificateName] = useState("");
  const [certificateID, setCertificateID] = useState<number>(0);
  const [isRowEdit, setIsRowEdit] = useState<boolean>(false);

  //rowshorting
  const [currentSortBy, setCurrentSortBy] = useState<string>("");
  const [reverseNextSort, setReverseNextSort] = useState<boolean>(false);

  const [certificateNameDescending, setCertificateNameDescending] = useState<boolean>(false);
  const [cpdPointsDescending, setCPDPointsDescending] = useState<boolean>(false);
  const [validFromDescending, setValidFromDescending] = useState<boolean>(false);
  const [validToDescending, setValidToDescending] = useState<boolean>(false);
  const [modifiedDateDescending, setModifiedDateDescending] = useState<boolean>(false);
  const [statusDescending, setStatusDescending] = useState<boolean>(false);
  const [modifiedByDescending, setModifiedByDescending] = useState<boolean>(false);
  const [saveSuccessuploadFile, setSaveSuccessuploadFile] = React.useState(false);
  const [showWarningModaluploadFile, setShowWarningModaluploadFile] = useState<boolean>(false);
  const [modalPopupMessageuploadFile, setModalPopupMessageuploadFile] = useState('');
  const [hasErroreduploadFile, setHasErroreduploadFile] = useState<boolean>(false);
  const [latestCPDSelectedLocal, setLatestCPDSelectedLocal] = useState<number>(-2);


  const {
    coursesAssigned,
    setCoursesAssigned,
    coursesCompleted,
    setCoursesCompleted,
    totalCourseProgress,
    setTotalCourseProgress,
    assignedQuizesCount,
    setAssignedQuizesCount,
    passedQuizesCount,
    setPassedQuizesCount,
    totalQuizProgress,
    setTotalQuizProgress,
    totalLessonProgress,
    setTotalLessonProgress,
    totalPolicyProgress,
    setTotalPolicyProgress,
    totalCertificateProgress,
    setTotalCertificateProgress
  } = useContext(CourseProgressPanelContext);


  const handleCpdUpdate = (cpdSelection: number) => {
    if (latestCPDSelectedLocal == cpdSelection) {
      return;
    }
    setCurrentCpd(cpdSelection);
    setLocalCPDLoaded(false);
    let selCpd = !!cpdSelection ? parseInt(cpdSelection.toString()) : -1;
    setLatestCPDSelectedLocal(selCpd);
    loadCPDPointsSpecifically(selCpd);
  }


  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".CertificateName",
        title: allResourcesToTranslations.certificatename,
        intro: allResourcesToTranslations.certificatenameguideme
      },

    ]
  })
  const setGuideMe = (clearOldList: boolean = false, showExpiredCertificateUI: boolean) => {
    let updateSteps = clearOldList ? [] : [...intro.steps]
    updateSteps.push(
      {
        element: ".CertificateName",
        title: allResourcesToTranslations.certificatename,
        intro: allResourcesToTranslations.certificatenameguideme
      });
    let elem = document.getElementById("CPDPointsID");
    if (showCPD || !!elem) {
      updateSteps.push(
        {
          element: ".CPDPoints",
          title: allResourcesToTranslations.myexcertcpdpointsgmt,
          intro: allResourcesToTranslations.myexcertcpdpointsgmm
        });
    }
    updateSteps.push(
      {
        element: ".ValidFrom",
        title: allResourcesToTranslations.validfrom,
        intro: allResourcesToTranslations.validfromguideme
      },
      {
        element: ".ValidTo",
        title: allResourcesToTranslations.validto,
        intro: allResourcesToTranslations.validtoguideme
      },
      {
        element: ".ModifiedDate",
        title: allResourcesToTranslations.lastmodifieddate,
        intro: allResourcesToTranslations.lastmodifieddateguideme
      },
      {
        element: ".ModifiedBy",
        title: allResourcesToTranslations.modifiedby,
        intro: allResourcesToTranslations.modifiedbyguideme
      },
      {
        element: ".Status",
        title: allResourcesToTranslations.status,
        intro: allResourcesToTranslations.statusguideme
      },
      {
        element: ".PDF",
        title: allResourcesToTranslations.viewpdf,
        intro: allResourcesToTranslations.viewpdfguideme
      },
      {
        element: ".Action",
        title: allResourcesToTranslations.actions,
        intro: allResourcesToTranslations.actionsguideme
      });
    if (showExpiredCertificateUI) {
      updateSteps.push(
        {
          element: ".ShowExpireCertificate",
          title: allResourcesToTranslations.showexpiredcerificate,
          intro: allResourcesToTranslations.showexpiredcerificateguideme
        });
    }
    if (showCPD || !!elem) {
      updateSteps.push(
        {
          element: "#CPDCard",
          title: allResourcesToTranslations.mytraininggmtcpdcard,
          intro: allResourcesToTranslations.mytraininggmmcpdcard1,
        },
        {
          element: ".profile",
          title: allResourcesToTranslations.cpdprofile,
          intro: allResourcesToTranslations.mytraininggmmcpdprof,
        }, {
        element: ".period",
        title: allResourcesToTranslations.cpdperiod,
        intro: allResourcesToTranslations.mytraininggmmcpdprd,
      }, {
        element: ".points",
        title: allResourcesToTranslations.mydashgmtcpdpts,
        intro: allResourcesToTranslations.mytraininggmmreqd,

      }, {
        element: ".earnedPoints",
        title: allResourcesToTranslations.mydashgmterncpdpts,
        intro: allResourcesToTranslations.mytraininggmmearned,
      })
    }
    if (coursesAssigned > 0) {
      updateSteps.push(
        {
          element: ".course",
          title: allResourcesToTranslations.coursecompletion,
          intro: allResourcesToTranslations.mydashgmmcrscomp,
        },
      );
    }
    if (coursesAssigned > 0 && showQuizUI) {
      updateSteps.push(
        {
          element: ".quiz",
          title: allResourcesToTranslations.quizcompletion,
          intro: allResourcesToTranslations.mytraininggmmquizcomp,
        }
      );
    }
    if (showPolicyUI) {
      updateSteps.push(
        {
          element: ".policy",
          title: allResourcesToTranslations.policyacceptance,
          intro: allResourcesToTranslations.mytraininggmmpolicyacc,
        }
      );
    }
    if (showMyCertificateUI) {
      updateSteps.push(
        {
          element: ".mycertificate",
          title: allResourcesToTranslations.mydashgmtextcert,
          intro: allResourcesToTranslations.mydashgmmextcert,
        }
      );
    }
    setIntro({ ...intro, steps: [...updateSteps] });
  };

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  //modelpopup
  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  const handleClosePopupuploadFile = () => {
    setShowWarningModaluploadFile(false);
  };

  useEffect(() => {
    if (showWarningModaluploadFile) {
      setTimeout(() => {
        setShowWarningModaluploadFile(false);
        setSaveSuccessuploadFile(false);
      }, 5000)
    }
  }, [showWarningModaluploadFile])

  useEffect(() => {
    if (showWarningModal) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      }, 5000)
    }
  }, [showWarningModal])

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "#fff" }
    } else {
      return {}
    }
  }

  const handleConfirmModal = (value: boolean) => {
    setshowConfirmModal(value);
  }

  const handleCancelConfirmPopup = () => {
    handleConfirmModal(false);
    setIsSaving(false);
  }

  const handlePDFModal = (value: boolean) => {
    setshowPDFModal(value);
  }

  const handleCancelPDFPopup = () => {
    handlePDFModal(false);
    setIsSaving(false);
  }
  //modelpopup

  //paging

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //paging

  const loadCPDPointsSpecifically = (cpdSelection: number = -1) => {
    if (!!myExternalCertificates !== true) {
      return;
    }
    setLatestCPDSelectedLocal(cpdSelection);
    setCPDLoading(true);
    GetUserExternalCertificatesOnlyWithCPDPoints(oidcUser.access_token, requesteduserId, orgDomain, parseInt(orgId),
      (cpdSelection < 0 || !!latestUserCPDs !== true || cpdSelection >= latestUserCPDs.length) ?
        (!!latestUserCPDs && latestUserCPDs.length > 0 ? latestUserCPDs[currentCpd].id : -1) : latestUserCPDs[cpdSelection].id,
      showExpiredCertificate ? 'closed' : 'valid').then((result: any) => {
        if (!!result && result.isSuccess) {
          let changedList = myExternalCertificates.filter(e => e.certificateID > 0);
          let retrievedList = (!!result.userExtCertCPDItems && result.userExtCertCPDItems.length > 0) ? result.userExtCertCPDItems as MyExternalCertificateOnlyWithCPD[] : null;
          let isAnyChanged = false;
          if (!!changedList && changedList.length > 0 && !!retrievedList && retrievedList.length > 0) {
            for (let eidx = 0; eidx < changedList.length; eidx++) {
              let curEItem = changedList[eidx];
              if (!!curEItem) {
                let matched = retrievedList.filter(e => e.certificateID == curEItem.certificateID);
                curEItem.cpdPoints = !!matched && matched.length > 0 ? matched[0].remainingPointsTxt : "0.00";
                isAnyChanged = true;
              }
            }
          }
          if (isAnyChanged) {
            setMyExternalCertificates(myExternalCertificates);
          }
        }
        else {
          setShowWarningModal(true);
          setHasErrored(true);
          setSaveSuccess(false);
          setModalPopupMessage("Can not load CPD Points of My External Certificate!");
        }

        setMyExtCertReloadRequired(false);
        setCPDLoading(false)
        setLocalCPDLoaded(true);
      }).catch((error: any) => {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage("Can not load CPD Points of My External Certificate due to error " + error);
        setMyExtCertReloadRequired(false);
        setCPDLoading(false)
        setLocalCPDLoaded(true);
      });
  }

  const loadMyExternalCertificate = () => {
    GetMyExternalCertificates(oidcUser.access_token, requesteduserId, orgDomain, parseInt(orgId), "").then((result: any) => {
      if (result.isSuccess) {
        if (result.myExternalCertificates !== null) {

          let expiredCertificateList = result.myExternalCertificates.filter((item: any) => item.status === "Closed");
          setMyValidExternalCertificates(result.myExternalCertificates.filter((item: any) => item.status === "Valid" || item.status === "New"));

          if (expiredCertificateList !== null && expiredCertificateList.length > 0) {
            setMyExpiredExternalCertificates(expiredCertificateList);
            setShowExpiredCertificateUI(true);
            setGuideMe(true, true);
          }
          else {
            setShowExpiredCertificateUI(false);
            setGuideMe(true, false);
          }

          let validCertificateList = showExpiredCertificate ? expiredCertificateList
            : result.myExternalCertificates.filter((item: any) => item.status === "Valid" || item.status === "New");
          setMyExternalCertificates(validCertificateList);
        }
      }
      else {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage("Can not load My External Certificate!");
      }
      setLoading(false);
    });

  };

  const loadCertificateProgress = () => {
    let cpdId = -1
    let cpdPrdId = -1
    getUserCoursesPoliciesOnlyWithProgress(oidcUser.access_token, requesteduserId, orgDomain, !orgId ? -1 : parseInt(orgId), cpdId, cpdPrdId).then((trainingResult: MyTrainingItemsResult | null) => {
      let cProg = !!trainingResult ? (trainingResult as MyTrainingItemsResult).overallProgress : null;

      setCoursesAssigned((!!cProg !== true || cProg == null) ? 0 : cProg.assignedCoursesCount);
      setCoursesCompleted((!!cProg !== true || cProg == null) ? 0 : cProg.completedCoursesCount);
      setTotalCourseProgress((!!cProg !== true || cProg == null) ? 0 : cProg.totalCourseProgress);
      setAssignedQuizesCount((!!cProg !== true || cProg == null) ? 0 : cProg.assignedQuizzesCount);
      setPassedQuizesCount((!!cProg !== true || cProg == null) ? 0 : cProg.passedQuizzesCount);
      setTotalQuizProgress((!!cProg !== true || cProg == null) ? 0 : cProg.totalQuizProgress);
      setTotalLessonProgress((!!cProg !== true || cProg == null || cProg.assignedLessonsCount <= 0) ? 0 : Math.round((cProg.completedLessonsCount / cProg.assignedLessonsCount) * 100));
      setTotalPolicyProgress((!!cProg !== true || cProg == null || cProg.assignedPoliciesCount <= 0) ? 0 : Math.round((cProg.acceptedPoliciesCount / cProg.assignedPoliciesCount) * 100));
      setTotalCertificateProgress(Math.round((!!cProg !== true || cProg == null || cProg.assignedCertificateCount <= 0) ? 0 : (cProg.acceptedCertificateCount / cProg.assignedCertificateCount) * 100))

      setShowPolicyUI((!!cProg !== true || cProg == null) ? false : cProg.assignedPoliciesCount !== 0)
      setShowCourseUI((!!cProg !== true || cProg == null) ? false : cProg.assignedCoursesCount !== 0)
      setShowQuizUI((!!cProg !== true || cProg == null) ? false : cProg.assignedQuizzesCount !== 0)
      setShowMyCertificateUI((!!cProg !== true || cProg == null) ? false : cProg.assignedCertificateCount !== 0)

      if (!showCPD) {
        setMyExtCertReloadRequired(false);
      }
    });

    //if (showCPD) {
    getUserCPDs(oidcUser.access_token, requesteduserId, orgDomain).then((cpdData) => {
      setLatestUserCPDs(cpdData);
      setCPDLoading(false);

      let cpdIdTmp = !!cpdData && cpdData.length > 0 ? cpdData[0].id : -1
      let cpdPrdIdTmp = !!cpdData && cpdData.length > 0 ? cpdData[0].periodID : -1
      setShowCPD(cpdIdTmp > 0 && cpdPrdIdTmp > 0);
      setCpdList(cpdData)

      cpdId = (!!cpdData && cpdData.length > 0) ? cpdData[currentCpd].id : -1
      cpdPrdId = (!!cpdData && cpdData.length > 0) ? cpdData[currentCpd].periodID : -1
    }).catch((e) => {
      setCPDLoading(false);
    });
    //}
  };

  useEffect(() => {
    //loadMyExternalCertificate();
    let validCertificateList = showExpiredCertificate ? myExpiredExternalCertificates : myValidExternalCertificates;
    setMyExternalCertificates(validCertificateList);
  }, [showExpiredCertificate]);

  useEffect(() => {
    //if (myExtCertReloadRequired)
    //  loadCertificateProgress();
    //if (myExtCertReloadRequired && !isLocalCPDLoaded && !isCPDLoading && showCPD) {
    if ((myExtCertReloadRequired || latestCPDSelectedLocal == -2) && !isLocalCPDLoaded && !isCPDLoading && showCPD) {
      loadCPDPointsSpecifically(!!currentCpd ? currentCpd : -1);
    }
  }, [isCPDLoading, showCPD, myExtCertReloadRequired, latestCPDSelectedLocal]);


  useEffect(() => {
    setTitle(allResourcesToTranslations.myextcertificate)
    setTitleEnglish(pageTitles.myextcertificates)
    updateBackButtonRoute("");
    setMenuSelection("My External Certificates")
    setLoading(true);
    //if (myExtCertReloadRequired)
    loadCertificateProgress();
    loadMyExternalCertificate();
  }, []);

  const handlePDFpopup = (orgID: number, UploadedCertificateName: string) => {
    handlePDFmModal(true);
    setOrganisationID(orgID);
    setUploadedCertificateName(UploadedCertificateName);
  }

  const handlePDFmModal = (value: boolean) => {
    setshowPDFModal(value);
  }

  const handleEditCertificatepopup = (UECUserId: number, CertificateID: number, hasNovaliddatesBool: boolean) => {
    handleConfirmModalUpload(true);
    setIsRowEdit(true);
    setCertificateID(CertificateID);
    setHasNoValidDates(hasNovaliddatesBool);
    let updatedSelectedcertificateId = myExternalCertificates.map((item: MyExternalCertificates) => {

      if (item.certificateID == CertificateID && item.userID == UECUserId && !hasNovaliddatesBool) {
        setFilterDateFrom(item.validFromDate);
        setFilterDateTo(item.validToDate);

        if (item.validFromDate !== undefined && item.validFromDate !== null) {
          let tempDateSt = item.validFromDate.trim().split("/");
          let val = formatDate(new Date(parseInt(tempDateSt[2]), parseInt(tempDateSt[1]) - 1, parseInt(tempDateSt[0])));
          setFilterDateFrom(val);
        }
        if (item.validToDate !== undefined && item.validToDate !== null) {
          let tempDateEnd = item.validToDate.trim().split("/");
          let valEndDate = formatDate(new Date(parseInt(tempDateEnd[2]), parseInt(tempDateEnd[1]) - 1, parseInt(tempDateEnd[0])));
          setFilterDateTo(valEndDate);
        }

      }
      return item;
    });
    setMyExternalCertificates([...updatedSelectedcertificateId]);

  }

  const handleUploadCertificatepopup = (UECUserId: number, CertificateID: number, hasNovaliddatesBool: boolean) => {
    handleResetFileUpload();
    handleConfirmModalUpload(true);
    setIsRowEdit(false);
    setCertificateID(CertificateID);
    setHasNoValidDates(hasNovaliddatesBool);
  }

  const handleConfirmModalUpload = (value: boolean) => {
    setshowConfirmModal(value);
  }


  const handleFileUpload = (byteArray: string, fileExtension: string, filename: string) => {
    setShowWarningModal(false);
    setModalPopupMessage("");
    setSelectedFile(filename);
    setSelectedFileName(filename);
    setSelectedFileData(byteArray);
    setSelectedFileExtension(fileExtension);
    setIsFileUploading(false);
  }

  const handleSetSelectedFileName = (filename: string) => {
    setSelectedFile(filename);
    setSelectedFileName(filename);
  }

  const handleSetSelectedFileSize = (filesize: number) => {
    setSelectedFileSize(filesize);
  }

  const handleResetFileUpload = () => {
    setSelectedFile("");
    setSelectedFileName("");
    setSelectedFileSize(0);
  };

  const handleResetData = () => {

    setFilterDateFrom('');
    setFilterDateTo('');
    setIsFileUploading(false);
    handleConfirmModal(false);
    handlePDFModal(false);
  }



  const SaveCertificate = () => {
    setIsSaving(true);
    SaveMyExternalCertificates(oidcUser.access_token, requesteduserId, requesteduserId, orgDomain, parseInt(orgId), filterDateFrom, filterDateTo, certificateID,
      selectedFileName, selectedFileData, selectedFileExtension)
      .then((res) => {
        if (!!res && res.isSuccess) {
          setTrainingListUpdateRequired(true);
          setDashboardUpdateRequired(true);
          setMyExtCertReloadRequired(true);
          setShowWarningModal(true);
          setHasErrored(false);
          setSaveSuccess(true);
          setModalPopupMessage("Certificate have been saved successful. ");
          setIsSaving(false);
          handleConfirmModalUpload(false);
          handleResetFileUpload();
          handleResetData();
          loadMyExternalCertificate();
          setShowExpiredCertificateUI(false);
          setShowExpiredCertificate(false);
          loadCertificateProgress();

        }
        else {
          setIsSaving(false);
          setShowWarningModal(true);
          setHasErrored(true);
          setSaveSuccess(false);
          setModalPopupMessage("Certificate save have been failed.");
        }
      })


  }
  const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
  {
    if (!isNaN(givenDate.getTime())) {
      const year = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(givenDate);
      const month = new Intl.DateTimeFormat('en-AU', { month: 'numeric' }).format(givenDate);
      const day = new Intl.DateTimeFormat('en-AU', { day: 'numeric' }).format(givenDate);

      return (year.toString() + "-" +
        (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" +
        (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
    }
    else {
      return "";
    }
  }



  //sorting

  const sortResultsByAsync = async (
    sortBy: "CertificateName" | "CPDPoints" | "ValidFrom" | "ValidTo" | "ModifiedDate" | "ModifiedByUserName" | "Status",
    sortGivenItemInDesc: boolean
  ) => {
    if (myExternalCertificates == null || myExternalCertificates.length <= 0) {
      return myExternalCertificates;
    }
    var result = myExternalCertificates.slice(0);
    setCurrentSortBy(sortBy);
    switch (sortBy) {

      case "CertificateName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.certificateName === null) {
              return -1;
            }
            if (a === null || a.certificateName === null) {
              return 1;
            }
            if (a.certificateName.toLowerCase() > b.certificateName.toLowerCase()) {
              return -1;
            }
            if (a.certificateName.toLowerCase() < b.certificateName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setCertificateNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.certificateName === null) {
              return 1;
            }
            if (a === null || a.certificateName === null) {
              return -1;
            }
            if (a.certificateName.toLowerCase() < b.certificateName.toLowerCase()) {
              return -1;
            }
            if (a.certificateName.toLowerCase() > b.certificateName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setCertificateNameDescending(true);
          }
        }
        setMyExternalCertificates(result);
        break;

      case "CPDPoints":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.cpdPoints === null) {
              return -1;
            }
            if (a === null || a.cpdPoints === null) {
              return 1;
            }
            if (a.cpdPoints.toLowerCase() > b.cpdPoints.toLowerCase()) {
              return -1;
            }
            if (a.cpdPoints.toLowerCase() < b.cpdPoints.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setCPDPointsDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.cpdPoints === null) {
              return 1;
            }
            if (a === null || a.cpdPoints === null) {
              return -1;
            }
            if (a.cpdPoints.toLowerCase() < b.cpdPoints.toLowerCase()) {
              return -1;
            }
            if (a.cpdPoints.toLowerCase() > b.cpdPoints.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setCPDPointsDescending(true);
          }
        }
        setMyExternalCertificates(result);
        break;
      case "Status":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.status === null) {
              return -1;
            }
            if (a === null || a.status === null) {
              return 1;
            }
            if (a.status.toLowerCase() > b.status.toLowerCase()) {
              return -1;
            }
            if (a.status.toLowerCase() < b.status.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setStatusDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.status === null) {
              return 1;
            }
            if (a === null || a.status === null) {
              return -1;
            }
            if (a.status.toLowerCase() < b.status.toLowerCase()) {
              return -1;
            }
            if (a.status.toLowerCase() > b.status.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setStatusDescending(true);
          }
        }
        setMyExternalCertificates(result);
        break;
      case "ValidFrom":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.validFromDate === null || b.validFromDate.trim() === "") { return -1; }
            if (a === null || a.validFromDate === null || a.validFromDate.trim() === "") { return 1; }
            var dta = new Date(a.validFromDate.split("/").reverse().join("-"))
            var dtb = new Date(b.validFromDate.split("/").reverse().join("-"))
            if (dta > dtb) { return -1; }
            if (dta < dtb) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setValidFromDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.validFromDate === null || b.validFromDate.trim() === "") { return 1; }
            if (a === null || a.validFromDate === null || a.validFromDate.trim() === "") { return -1; }
            var dta = new Date(a.validFromDate.split("/").reverse().join("-"))
            var dtb = new Date(b.validFromDate.split("/").reverse().join("-"))
            if (dta > dtb) { return 1; }
            if (dta < dtb) { return -1; }
            return 0;
          })
          if (reverseNextSort) {
            setValidFromDescending(true)
          }
        }
        setMyExternalCertificates(result);
        break;
      case "ValidTo":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.validToDate === null || b.validToDate.trim() === "") { return -1; }
            if (a === null || a.validToDate === null || a.validToDate.trim() === "") { return 1; }
            var dta = new Date(a.validToDate.split("/").reverse().join("-"))
            var dtb = new Date(b.validToDate.split("/").reverse().join("-"))
            if (dta > dtb) { return -1; }
            if (dta < dtb) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setValidToDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.validToDate === null || b.validToDate.trim() === "") { return 1; }
            if (a === null || a.validToDate === null || a.validToDate.trim() === "") { return -1; }
            var dta = new Date(a.validToDate.split("/").reverse().join("-"))
            var dtb = new Date(b.validToDate.split("/").reverse().join("-"))
            if (dta > dtb) { return 1; }
            if (dta < dtb) { return -1; }
            return 0;
          })
          if (reverseNextSort) {
            setValidToDescending(true)
          }
        }
        setMyExternalCertificates(result);
        break;
      case "ModifiedDate":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.modifiedDate === null || b.modifiedDate.trim() === "") { return -1; }
            if (a === null || a.modifiedDate === null || a.modifiedDate.trim() === "") { return 1; }
            var dta = new Date(a.modifiedDate.split("/").reverse().join("-"))
            var dtb = new Date(b.modifiedDate.split("/").reverse().join("-"))
            if (dta > dtb) { return -1; }
            if (dta < dtb) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setModifiedDateDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.modifiedDate === null || b.modifiedDate.trim() === "") { return 1; }
            if (a === null || a.modifiedDate === null || a.modifiedDate.trim() === "") { return -1; }
            var dta = new Date(a.modifiedDate.split("/").reverse().join("-"))
            var dtb = new Date(b.modifiedDate.split("/").reverse().join("-"))
            if (dta > dtb) { return 1; }
            if (dta < dtb) { return -1; }
            return 0;
          })
          if (reverseNextSort) {
            setModifiedDateDescending(true)
          }
        }
        setMyExternalCertificates(result);
        break;
      case "ModifiedByUserName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.modifiedByUserName === null) {
              return -1;
            }
            if (a === null || a.modifiedByUserName === null) {
              return 1;
            }
            if (a.modifiedByUserName.toLowerCase() > b.modifiedByUserName.toLowerCase()) {
              return -1;
            }
            if (a.modifiedByUserName.toLowerCase() < b.modifiedByUserName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setModifiedByDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.modifiedByUserName === null) {
              return 1;
            }
            if (a === null || a.modifiedByUserName === null) {
              return -1;
            }
            if (a.modifiedByUserName.toLowerCase() < b.modifiedByUserName.toLowerCase()) {
              return -1;
            }
            if (a.modifiedByUserName.toLowerCase() > b.modifiedByUserName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setModifiedByDescending(true);
          }
        }
        setMyExternalCertificates(result);
        break;
      default:
        break;
    }
    return result.slice(0);
  };

  const sortResultsBy = async (
    sortBy: "CertificateName" | "CPDPoints" | "ValidFrom" | "ValidTo" | "ModifiedDate" | "ModifiedByUserName" | "Status",
    sortGivenItemInDesc: boolean
  ) => {
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy);
      setMyExternalCertificates(result);
      setPage(0);
    });
  };

  const clickCertificateNameAsc = () => {
    setReverseNextSort(false);
    setCertificateNameDescending(false);
    sortResultsBy("CertificateName", false);
  };

  const clickCertificateNameDesc = () => {
    setReverseNextSort(false);
    setCertificateNameDescending(true);
    sortResultsBy("CertificateName", true);
  };

  const clickCPDPointsAsc = () => {
    setReverseNextSort(false);
    setCPDPointsDescending(false);
    sortResultsBy("CPDPoints", false);
  };

  const clickCPDPointsDesc = () => {
    setReverseNextSort(false);
    setCPDPointsDescending(true);
    sortResultsBy("CPDPoints", true);
  };

  const clickValidFromAsc = () => {
    setReverseNextSort(false);
    setValidFromDescending(false);
    sortResultsBy("ValidFrom", false);
  };

  const clickValidFromDesc = () => {
    setReverseNextSort(false);
    setValidFromDescending(true);
    sortResultsBy("ValidFrom", true);
  };



  const clickValidToAsc = () => {
    setReverseNextSort(false);
    setValidToDescending(false);
    sortResultsBy("ValidTo", false);
  };

  const clickValidToDesc = () => {
    setReverseNextSort(false);
    setValidToDescending(true);
    sortResultsBy("ValidTo", true);
  };

  const clickStatusAsc = () => {
    setReverseNextSort(false);
    setStatusDescending(false);
    sortResultsBy("Status", false);
  };

  const clickStatusDesc = () => {
    setReverseNextSort(false);
    setStatusDescending(true);
    sortResultsBy("Status", true);
  };

  const clickModifiedDateAsc = () => {
    setReverseNextSort(false);
    setModifiedDateDescending(false);
    sortResultsBy("ModifiedDate", false);
  };

  const clickModifiedDateDesc = () => {
    setReverseNextSort(false);
    setModifiedDateDescending(true);
    sortResultsBy("ModifiedDate", true);
  };



  const clickModifiedByAsc = () => {
    setReverseNextSort(false);
    setModifiedByDescending(false);
    sortResultsBy("ModifiedByUserName", false);
  };

  const clickModifiedByDesc = () => {
    setReverseNextSort(false);
    setModifiedByDescending(true);
    sortResultsBy("ModifiedByUserName", true);
  };


  //sorting

  if (loading) return <LoaderContainer />;
  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }} />
      <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific} style={{ right: '35px', top: '100px' }}>
          <GuideIcon />
        </span>
      </CustomTooltip>
      <Modal modalOpen={showConfirmModal} setModalOpen={handleConfirmModal} type="warning">
        <div className={styles.modalContentWrappercertificate}>
          <div className={responsivestyles.rowrule} style={{ width: '600px' }} >
            <div className={`${responsivestyles.columnrule_4} ${responsivestyles.columnrule_s_12}`}>
              <div className={`${styles.leftrightPadding10} ${styles.inputContainer}`}>
                <CustomTooltip displayText={[allResourcesToTranslations.draganddropcustomtooltip]}>
                  <div className="policydocguideme">
                    <CertificateFileDropBox selectedFile={selectedFile} uploadFile={handleFileUpload} setSelectedFile={handleSetSelectedFileName} setSelectedFileSize={handleSetSelectedFileSize} />
                  </div>
                </CustomTooltip>
              </div>
            </div>
            <div className={`${responsivestyles.columnrule_8} ${responsivestyles.columnrule_s_12}`} >
              <div style={{ paddingLeft: "25px" }}>
                <span>Uploading File</span>
                <br></br><br></br>
                {selectedFileName.toString().length > 0 ?
                  <div className={styles.importfileContainer} style={{ padding: "5px", backgroundColor: "#eee" }}>
                    <div style={{ width: "100%", display: "inline-flex" }}>
                      <div style={{ float: "left", width: "90%", display: "flex" }}>
                        <div> <FileInfoIcon></FileInfoIcon></div>
                        <div style={{ paddingLeft: "10px" }}><span>{selectedFileName.toString()}</span>
                        </div>
                      </div>
                      <div style={{ float: "right", width: "10%" }}>
                        <span onClick={handleResetFileUpload} style={{ cursor: "pointer" }}>
                          <CloseBlackIcon />
                        </span>
                      </div>
                    </div>
                    <div><span>Selected File Size: {selectedFileSize.toFixed(0)}  KB</span>
                    </div>
                  </div> :
                  <div className={styles.importfileContainer} style={{ padding: "5px", height: "60px", backgroundColor: "#eee" }}>
                    No File attached
                  </div>
                }
                {!hasNoValidDates ?
                  <><div className={styles.inputContainer} style={{ paddingTop: "20px" }}>
                    <div className="dateFrom">
                      <TextField className={styles.input} label={allResourcesToTranslations.entervalidfrom} variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                        type="date" defaultValue={filterDateFrom} onChange={(e) => setFilterDateFrom(formatDate(new Date(e.target.value)))} />
                    </div>
                  </div>
                    <div className={styles.inputContainer}>
                      <div className="dateTo">
                        <TextField className={styles.input} label={allResourcesToTranslations.entervalidto} variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                          type="date" defaultValue={filterDateTo} onChange={(e) => setFilterDateTo(formatDate(new Date(e.target.value)))} />
                      </div>
                    </div>
                  </>
                  :
                  <div className={styles.inputContainer} style={{ paddingTop: "70px" }}>&nbsp;</div>
                }
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    top: "10px"
                  }}
                >
                  <div className={styles.loaderContainer}>
                    <CircularProgress
                      style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
                      color="primary"
                      size="10" />
                  </div>
                  <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
                    <span className="saveData">
                      <Button
                        disabled={isRowEdit ? (((filterDateFrom === "" || filterDateTo === "") && !hasNoValidDates) || (filterDateFrom > filterDateTo && !hasNoValidDates) || isSaving)
                          : (selectedFileName.toString().length <= 0 || ((filterDateFrom === "" || filterDateTo === "") && !hasNoValidDates) || (filterDateFrom > filterDateTo && !hasNoValidDates) || isSaving)}
                        onClick={SaveCertificate}
                        variant="contained"
                        color="primary"
                        className="save"
                      >
                        <SaveIcon />
                      </Button>
                    </span>
                  </CustomTooltip>
                  <CustomTooltip displayText={[allResourcesToTranslations.return]}>
                    <span onClick={handleCancelConfirmPopup}>
                      <Button
                        variant="contained"
                        color="primary"
                        className="close" style={{ backgroundColor: "#F2B05C" }}
                      >
                        <CloseWhiteIcon />
                      </Button>
                    </span>
                  </CustomTooltip>
                </div>
              </div>
            </div>
          </div>

        </div>
      </Modal>
      <Modal modalOpen={showPDFModal} setModalOpen={handlePDFModal} type="fileviewer">
        <div>
          <iframe
            src={`/General/ExternalCertificate/${organisationID}/${uploadedCertificateName}#zoom=150`}
            title="viewer"
            style={{ width: "100%", height: "750px" }}
            id="viewer"
          ></iframe>
        </div>
      </Modal>
      <div className={styles.mainWrapper}>
        <div style={{ width: '100%', clear: "both" }} >
          <div className={responsivestyles.rowrule} style={{ width: '100%' }}>
            <div className={showCPD ? `${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}` : `${responsivestyles.columnrule_s_12}`}>
              <div style={{ padding: " 0 5px 5px 5px" }}>
                <CourseCompletionPanel
                  assignedCoursesCount={coursesAssigned}
                  completedCoursesCount={coursesCompleted}
                  totalCourseProgress={totalCourseProgress}
                  assignedQuizesCount={assignedQuizesCount}
                  passedQuizesCount={passedQuizesCount}
                  totalQuizProgress={totalQuizProgress}
                  lessonCompletionProgress={totalLessonProgress}
                  policyAcceptanceProgress={totalPolicyProgress}
                  CertificateProgress={totalCertificateProgress}
                />
              </div>
            </div>
            {showCPD &&
              <div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`} >
                <div style={{ padding: " 0 5px 5px 5px" }}>
                  <CPDPanel showCPD={showCPD} setCurrentCpd={handleCpdUpdate} currentCpd={currentCpd} cpdList={cpdList} isCPDLoading={isCPDLoading} calledFrom='myextcert' />
                </div>
              </div>
            }
          </div>
        </div>
        <div style={{ width: '100%', clear: "both" }} >
          {showExpiredCertificateUI &&
            <div style={{ paddingTop: "40px", paddingLeft: "10px" }} >
              <div className='ShowExpireCertificate'>
                <div onClick={() => setShowExpiredCertificate(!showExpiredCertificate)}>
                  <span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} >{showExpiredCertificate ? <ToggleRightIcon /> : <ToggleLeftIcon />}</span>
                </div>
                <div className={styles.inputContainer}>
                  <span id="allowLearnerswitch" className={styles.labelText} onClick={() => setShowExpiredCertificate(!showExpiredCertificate)}>
                    {allResourcesToTranslations.showexpiredcerificate}</span>
                </div>
              </div>
            </div>
          }
          {myExternalCertificates != null && myExternalCertificates.length > 0 && (
            <div>
              <div className={styles.paginationControlsContainer}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={myExternalCertificates.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
              <div className={`${styles.tableSection}`}>
                <div className={styles.tableContainer}>
                  <BrandedCard>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className="CertificateName"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                              align="left">
                              <div className={styles.tableHeadCellContainer} style={{ display: "flex", }}  >
                                <div
                                  onClick={() => {
                                    setCertificateNameDescending(!certificateNameDescending);
                                    sortResultsBy("CertificateName", !certificateNameDescending);
                                  }}
                                >
                                  {allResourcesToTranslations.certificatename}
                                </div>
                                <div className={styles.columnHeadArea}>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "CertificateName" &&
                                          certificateNameDescending === false
                                          ? "100%"
                                          : "50%",
                                      margin: "2px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickCertificateNameAsc}
                                  >
                                    <UpArrowIcon />{" "}
                                  </span>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "CertificateName" &&
                                          certificateNameDescending === true
                                          ? "100%"
                                          : "50%",
                                      margin: "0px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickCertificateNameDesc}
                                  >
                                    <DownArrowIcon />{" "}
                                  </span>
                                </div>
                              </div>
                            </TableCell>
                            {
                              showCPD &&
                              <TableCell className="CPDPoints" id={"CPDPointsID"}
                                style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                align="left">
                                <div className={styles.tableHeadCellContainer} style={{ display: "flex", }}  >
                                  <div
                                    onClick={() => {
                                      setCPDPointsDescending(!cpdPointsDescending);
                                      sortResultsBy("CPDPoints", !cpdPointsDescending);
                                    }}
                                  >
                                    {allResourcesToTranslations.cpdpoints}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "CPDPoints" &&
                                            cpdPointsDescending === false
                                            ? "100%"
                                            : "50%",
                                        margin: "2px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clickCPDPointsAsc}
                                    >
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "CPDPoints" &&
                                            cpdPointsDescending === true
                                            ? "100%"
                                            : "50%",
                                        margin: "0px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clickCPDPointsDesc}
                                    >
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                            }
                            <TableCell className="ValidFrom"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                              align="center">
                              <div className={styles.tableHeadCellContainer} style={{ display: "inline-flex", alignItems: "center" }}>
                                <div
                                  onClick={() => {
                                    setValidFromDescending(!validFromDescending);
                                    sortResultsBy("ValidFrom", !validFromDescending);
                                  }}
                                >
                                  {allResourcesToTranslations.validfrom}
                                </div>
                                <div className={styles.columnHeadArea}>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "ValidFrom" &&
                                          validFromDescending === false
                                          ? "100%"
                                          : "50%",
                                      margin: "2px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickValidFromAsc}
                                  >
                                    <UpArrowIcon />{" "}
                                  </span>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "ValidFrom" &&
                                          validFromDescending === true
                                          ? "100%"
                                          : "50%",
                                      margin: "0px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickValidFromDesc}
                                  >
                                    <DownArrowIcon />{" "}
                                  </span>
                                </div>
                              </div>
                            </TableCell>

                            <TableCell className="ValidTo"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                              align="center">
                              <div className={styles.tableHeadCellContainer} style={{ display: "inline-flex", alignItems: "center" }}  >
                                <div
                                  onClick={() => {
                                    setValidToDescending(!validToDescending);
                                    sortResultsBy("ValidTo", !validToDescending);
                                  }}
                                >
                                  {allResourcesToTranslations.validto}
                                </div>
                                <div className={styles.columnHeadArea}>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "ValidTo" &&
                                          validToDescending === false
                                          ? "100%"
                                          : "50%",
                                      margin: "2px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickValidToAsc}
                                  >
                                    <UpArrowIcon />{" "}
                                  </span>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "ValidTo" &&
                                          validToDescending === true
                                          ? "100%"
                                          : "50%",
                                      margin: "0px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickValidToDesc}
                                  >
                                    <DownArrowIcon />{" "}
                                  </span>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell className="ModifiedDate"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                              align="left">
                              <div className={styles.tableHeadCellContainer} style={{ display: "flex", }}  >

                                <div
                                  onClick={() => {
                                    setModifiedDateDescending(!modifiedDateDescending);
                                    sortResultsBy("ModifiedDate", !modifiedDateDescending);
                                  }}
                                >
                                  {allResourcesToTranslations.lastmodifieddate}
                                </div>
                                <div className={styles.columnHeadArea}>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "ModifiedDate" &&
                                          modifiedDateDescending === false
                                          ? "100%"
                                          : "50%",
                                      margin: "2px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickModifiedDateAsc}
                                  >
                                    <UpArrowIcon />{" "}
                                  </span>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "ModifiedDate" &&
                                          modifiedDateDescending === true
                                          ? "100%"
                                          : "50%",
                                      margin: "0px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickModifiedDateDesc}
                                  >
                                    <DownArrowIcon />{" "}
                                  </span>
                                </div>

                              </div>
                            </TableCell>

                            <TableCell className="ModifiedBy"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                              align="left">
                              <div className={styles.tableHeadCellContainer} style={{ display: "flex", }}  >

                                <div
                                  onClick={() => {
                                    setModifiedByDescending(!modifiedByDescending);
                                    sortResultsBy("ModifiedByUserName", !modifiedByDescending);
                                  }}
                                >
                                  {allResourcesToTranslations.lastmodifiedby}
                                </div>
                                <div className={styles.columnHeadArea}>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "ModifiedByUserName" &&
                                          modifiedByDescending === false
                                          ? "100%"
                                          : "50%",
                                      margin: "2px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickModifiedByAsc}
                                  >
                                    <UpArrowIcon />{" "}
                                  </span>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "ModifiedByUserName" &&
                                          modifiedByDescending === true
                                          ? "100%"
                                          : "50%",
                                      margin: "0px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickModifiedByDesc}
                                  >
                                    <DownArrowIcon />{" "}
                                  </span>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell className="Status"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                              align="left">
                              <div className={styles.tableHeadCellContainer} style={{ display: "flex", }}  >

                                <div
                                  onClick={() => {
                                    setStatusDescending(!statusDescending);
                                    sortResultsBy("Status", !statusDescending);
                                  }}
                                >
                                  {allResourcesToTranslations.status}
                                </div>
                                <div className={styles.columnHeadArea}>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "Status" &&
                                          statusDescending === false
                                          ? "100%"
                                          : "50%",
                                      margin: "2px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickStatusAsc}
                                  >
                                    <UpArrowIcon />{" "}
                                  </span>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "Status" &&
                                          statusDescending === true
                                          ? "100%"
                                          : "50%",
                                      margin: "0px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickStatusDesc}
                                  >
                                    <DownArrowIcon />{" "}
                                  </span>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell className="PDF"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                              align="left">
                              <div className={styles.tableHeadCellContainer} style={{ display: "flex", }}  >
                                <div >
                                  {allResourcesToTranslations.viewpdf}

                                </div>
                              </div>
                            </TableCell>
                            <TableCell className="Action"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0", width: "15%" }}
                              align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div >
                                  {allResourcesToTranslations.actions}
                                </div>
                              </div>
                            </TableCell>

                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {myExternalCertificates.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                            .map((result: any, i) => {
                              return (
                                <TableRow key={i.toString() + "_extcert-row"} style={rowBackgroundColor(i)}>
                                  <TableCell align="left">
                                    <span className={result.status == "Closed" ? styles.certificatetitleexpired : styles.certificatetitle}>{result.certificateName}</span>
                                  </TableCell>
                                  {
                                    showCPD &&
                                    <TableCell align="left">
                                      <span className={styles.certificatetitle}>{result.cpdPoints}</span>
                                    </TableCell>
                                  }
                                  <TableCell align="center" >
                                    {(!result.hasNoValidDates && result.validFromDate === null && result.fileName !== "") ?
                                      <CustomTooltip displayText={[allResourcesToTranslations.enterdate]}>
                                        <div><ErrorIcon></ErrorIcon> </div>
                                      </CustomTooltip>
                                      : <span className={result.status == "Closed" ? styles.certificatetitleexpired : styles.certificatetitle}>{!result.hasNoValidDates ? result.validFromDate : ''}</span>
                                    }
                                  </TableCell>
                                  <TableCell align="center">
                                    {(!result.hasNoValidDates && result.validToDate === null && result.fileName !== "") ?
                                      <CustomTooltip displayText={[allResourcesToTranslations.enterdate]}>
                                        <div><ErrorIcon></ErrorIcon> </div>
                                      </CustomTooltip>
                                      : <span className={result.status == "Closed" ? styles.certificatetitleexpired : styles.certificatetitle}> {!result.hasNoValidDates ? result.validToDate : ''}</span>
                                    }
                                  </TableCell>
                                  <TableCell align="left">
                                    <span className={result.status == "Closed" ? styles.certificatetitleexpired : styles.certificatetitle}>{result.modifiedDate}</span>
                                  </TableCell>
                                  <TableCell align="left">
                                    <span className={result.status == "Closed" ? styles.certificatetitleexpired : styles.certificatetitle}>{result.modifiedByUserName}</span>
                                  </TableCell>
                                  <TableCell align="left">
                                    <span className={result.status == "Closed" ? styles.certificatetitleexpired : styles.certificatetitle}>{result.status}</span>
                                  </TableCell>
                                  <TableCell align="left">
                                    <CustomTooltip displayText={[allResourcesToTranslations.viewcertificate]}>
                                      <Button className={styles.moduleListButton} variant="contained" color="primary" disabled={result.status == "New"} onClick={() => handlePDFpopup(result.orgID, result.fileName)}>
                                        <PDFWhiteIcon />
                                      </Button>
                                    </CustomTooltip>
                                  </TableCell>
                                  <TableCell align="left">
                                    {result.status == "New" ?
                                      <CustomTooltip displayText={[allResourcesToTranslations.uploadcertificate]}>
                                        <Button onClick={() => handleUploadCertificatepopup(result.userID, result.certificateID, result.hasNoValidDates)} className={styles.moduleListButton} variant="contained" color="primary">
                                          <CloudUploadIcon />
                                        </Button>
                                      </CustomTooltip>
                                      :
                                      result.isUserEditable && result.status === "Valid" ?
                                        <CustomTooltip displayText={[allResourcesToTranslations.editcertificate]}>
                                          <Button onClick={() => handleEditCertificatepopup(result.userID, result.certificateID, result.hasNoValidDates)} className={styles.moduleListButton} variant="contained" color="primary">
                                            <EditIcon />
                                          </Button></CustomTooltip> :
                                        <>
                                          <CustomTooltip displayText={[allResourcesToTranslations.learnereditentryguideme]}>
                                            <Button style={{ backgroundColor: "rgba(0, 0, 0, 0.12)", boxShadow: "none" }} variant="contained" color="primary">
                                              <EditIcon />
                                            </Button>
                                          </CustomTooltip>
                                        </>
                                    }
                                  </TableCell>


                                </TableRow>)
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </BrandedCard>
                </div>
              </div>
              <div className={styles.paginationAddedUsersControl}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={myExternalCertificates.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          )}


          {myExternalCertificates.length <= 0 && (
            <BrandedCard>
              <div className={styles.noResultsCard} style={{ marginTop: "20px" }}>
                <div>No Results to Display</div>
              </div>
            </BrandedCard>
          )}

        </div>
        {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored}
          handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
      </div>
    </>
  );
};
export default MyExternalCertificate;
