//React + Typescript
import React, { useEffect, useState, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import { LearningModule, QuizResults, Intro, MyTrainingItemsResult } from "../../globalTypes";
// Libs
import { useReactOidc } from "@axa-fr/react-oidc-context";
//Contexts
import { UIContext } from "../../contexts/UIContext";
import { LoaderContext } from "../../contexts/LoaderContext";
import { SessionContext } from "../../contexts/SessionContext";
import { CPDPanelContext } from "../../contexts/CPDPanelContext";
import { TranslationsContext } from "../../contexts/TranslationsContext";
import { MyTrainingListContext } from "../../contexts/MyTrainingListContext";
import { CourseProgressPanelContext } from "../../contexts/CourseProgressContext";
//Intro Lib
import { Steps, Hints } from "intro.js-react";
//API Functions
import { addError } from "../../API/AddError";
import { deleteDME } from "../../API/DeleteDME";
import { getUserCPDs } from "../../API/UserCPDs";
import { getUserCourseModules } from "../../API/UserCourseModules";
//import { getUserCoursesPolicies } from "../../API/UserCoursesPolicies";
import { getUserCoursesPoliciesOnlyWithProgress } from "../../API/UserCoursesPoliciesOnlyWithProgress";
import { checkSessionCompleted } from "../../API/CheckSessionCompleted";
import { checkIfSessionIsStillActive } from "../../API/CheckIfSessionIsStillActive";
import { getQuizSessionDetails } from "../../API/GetQuizSessionDetails";
import { executeCertificateDME } from "../../API/ExecuteCertificateDME";
import { executeProfilePointDME } from "../../API/ExecuteProfilePointDME";
//Components
import Modal from "../../Components/Modal";
import CPDPanel from "../../Components/CPDPanel";
import LearningModuleList from "./LearningModuleList";
import LoaderContainer from "../../Components/LoaderContainer";
import ResultsModalContent from "./ResultsModalContent";
import SAReviewModalContent from "./SAReviewModalContent";
import SARestartModalContent from "./SARestartModalContent";
import CourseCompletionPanel from "../../Components/CourseCompletionPanel";
import CustomTooltip from "../../Components/CustomTooltip";
//MUI Components
import GuideIcon from '../../CustomIcons/GuideIcon';

//Styles
import styles from "../../styles/learningModuleList.module.css";
import responsivestyles from '../../styles/responsiveGridLayout.module.css'
import getUserCourseModulesWithoutCPD from "../../API/UserCourseModulesWithoutCPD";

const LearningModules = (props: any): JSX.Element => {
  const history = useHistory();
  const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext);
  const [moduleList, setModuleList] = useState<null | void | Promise<any> | Array<LearningModule>>(null);
  const [SASelectedModule, setSASelectedModule] = useState<null | LearningModule>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [mapSessionCompletion, setMapSessionCompletion] = useState<Map<any, any>>(new Map());
  const [restartModalOpen, setRestartModalOpen] = useState<boolean>(false);
  const [reviewModalOpen, setReviewModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [resultData, setResultData] = useState<null | void | Promise<any> | QuizResults>(null);
  const { cpdList, setCpdList, currentCpd, setCurrentCpd } = useContext(CPDPanelContext);
  const { setMessage } = useContext(LoaderContext);
  const { getCourseFromContext, trainingListUpdateRequired } = useContext(MyTrainingListContext);
  const { showCPD, setShowCPD, setTitle, setTitleEnglish, setMenuSelection, updateBackButtonRoute,backRoute, 
    checkScrollReminder, setShowScrollArrow, showLastPassDate, EnableActivityLogs } = useContext(UIContext);
  const {
    coursesAssigned,
    setCoursesAssigned,
    coursesCompleted,
    setCoursesCompleted,
    totalCourseProgress,
    setTotalCourseProgress,
    assignedQuizesCount,
    setAssignedQuizesCount,
    passedQuizesCount,
    setPassedQuizesCount,
    totalQuizProgress,
    setTotalQuizProgress,
    totalLessonProgress,
    setTotalLessonProgress,
    totalPolicyProgress,
    setTotalPolicyProgress,
    totalCertificateProgress,
    setTotalCertificateProgress
  } = useContext(CourseProgressPanelContext);
  const {
    curSessionID,
    curBrowserTabUniqueID,
    curSelectedModuleID,
    setCurCourseName,
    isCurSessionLesson,
    currentCourseID,
    setCurrentCourseID,
    curSelectedModuleTechType,
    setCurSessionID,
    showResultsModal,
    setShowResultsModal,
  } = useContext(SessionContext);
  const [intro, setIntro] = useState<Intro>({
    stepsEnabled: false,
    initialStep: 0,
    steps: [     
      {
        element: ".welcome",
        intro: `<ul style="position:'relative',left:'-20px'">
        <li>` + allResourcesToTranslations.learnmodsgmmwelcome1 + `</li>
        <li>` + allResourcesToTranslations.learnmodsgmmwelcome2 + `</li>
        <li>` + allResourcesToTranslations.learnmodsgmmwelcome3 + `</li>
        <li>` + allResourcesToTranslations.learnmodsgmmwelcome4 + `</li>
        <li>` + allResourcesToTranslations.learnmodsgmmwelcome5 + `</li>
        </ul>`,        
      },      
    ]})
  const courseId: number = parseInt(props.match.params.courseId);
  const { oidcUser } = useReactOidc();
  const { profile: { profile, user_id, website }} = oidcUser || {};
  const orgId = profile ? parseInt(profile) : -1;
  const userId = user_id ? parseInt(user_id) : 0;
  const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";

  const [isCPDLoading, setCPDLoading] = useState<boolean>(true);

  const logNavigation = (message: string) => {
    addError(oidcUser.access_token, userId, orgDomain, message, null, "LearningModuleList.tsx", 6, EnableActivityLogs);
  };

  const handleCpdUpdate = (cpdSelection: number) => {
    setCurrentCpd(cpdSelection);
    getUserCourseModules(oidcUser.access_token, userId, orgDomain, courseId, cpdList === null || cpdList.length <= 0 ? -1 : cpdList[cpdSelection].id).then((mList) => {
      setModuleList(mList);
    });
  };

  const handleCPDIntro = () => {
    let updateSteps = [...intro.steps]
    if (!!updateSteps && updateSteps.some(s => !!s && s.intro.includes("This area shows the total value of CPD Points that may be earned for successfully Passing a Module."))) {
      return;
    }
    let filter = updateSteps.filter((item)  => item.element == ".points")
      

    if (filter.length == 0) {
      
      updateSteps.splice(1,0,
        {
          element: "#CPDCard",
          title: allResourcesToTranslations.mytraininggmtcpdcard, 
          intro: allResourcesToTranslations.mytraininggmmcpdcard1,
        },
        {
          element: ".profile",
          title: allResourcesToTranslations.cpdprofile, 
          intro: allResourcesToTranslations.mytraininggmmcpdprof,        
        },{
          element: ".period",
          title: allResourcesToTranslations.cpdperiod, 
          intro: allResourcesToTranslations.mytraininggmmcpdprd,
        
        },{
          element: ".points",
          title: allResourcesToTranslations.mydashgmtcpdpts, 
          intro: allResourcesToTranslations.mytraininggmmreqd,
        
        },{
          element: ".earnedPoints",
          title: allResourcesToTranslations.mydashgmterncpdpts, 
          intro: allResourcesToTranslations.mytraininggmmearned,
        },
        {
          element: ".cpdPoints",
          intro: allResourcesToTranslations.learnmodsgmmcpdpttop,
          position:'top'
        },
        
        {
          element: ".status",
          intro: `<div ><ul style="width:280px;position:relative;left:-38px;top:10px">
          <li>` + allResourcesToTranslations.learnmodsgmmstatuses1 + `</li>
          <li>` + allResourcesToTranslations.learnmodsgmmstatuses2 + `</li>
          <li>` + allResourcesToTranslations.learnmodsgmmstatuses3 + `</li>
          <li>` + allResourcesToTranslations.learnmodsgmmstatuses4 + `</li>
          <li>` + allResourcesToTranslations.learnmodsgmmstatuses5 + `</li>
          </ul> </div>`,
          position:'bottom'          
        },
        {
          element: '.actions',
          intro: allResourcesToTranslations.learnmodsgmmstart,          
        },
        {
          element: ".dueDate",
          intro: `<ul>
          <li>` + allResourcesToTranslations.learnmodsgmmdue1 + `</li>
          <li>` + allResourcesToTranslations.learnmodsgmmdue2 + `</li>
          <ul>`,         
        }
        )
          
    }
    //     if(backRoute == '/mytraining') {
    //       updateSteps.push({
    //         element:'.back',
    //         intro: 'Select this button to return to the top level of your My Training page.',
              
    //       })
      
            
      //    }


    setIntro({...intro, steps: [...updateSteps]})
  }

//  useEffect(() => {
//if(backRoute == '/mytraining') {
//  handleCPDIntro()
//}
 // },[backRoute])

  const onExit = () => {
    setIntro({...intro, stepsEnabled: false });
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setCurSessionID("");
  };

  useEffect(() => {
    if (!!currentCpd && parseInt(currentCpd.toString()) >= 0 && !!cpdList && cpdList.length > currentCpd) {
      handleCpdUpdate(currentCpd);
    }
  }, [currentCpd, cpdList]);

  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    // Fetch the course from context
    const courseId: number = parseInt(props.match.params.courseId);
    const course = getCourseFromContext(courseId);
    updateBackButtonRoute("/mytraining");
    setMenuSelection("My Training");
    let cpdId = -1;
    let cpdPrdId = -1;
    setLoading(true);
    setShowCPD(false);
    window.scrollTo(0, 0);
    setMessage(allResourcesToTranslations.fetchingmodules);

    if (course !== null) {
      setCurCourseName(course.name);
      setTitle(`${allResourcesToTranslations.mytraining} / ${course.name}`);
      setTitleEnglish(pageTitles.moduleslist)
      setMenuSelection("My Training");
      setCurrentCourseID(course.id);
      if (curSessionID !== "" && !isCurSessionLesson) {
        //Checks if a quiz has just been completed
        let updatedMap = mapSessionCompletion.set(curSessionID, false);
        setMapSessionCompletion(updatedMap);
        setMessage(allResourcesToTranslations.calculationresult);
        let sessionCompleted = false;
        let sessionTableActivityFlag = false;

        const checkSessionProgress = async () => {
          if (mapSessionCompletion.get(curSessionID) === true) {
            clearInterval(dmeCheck);
            return;
          }
          if (trainingListUpdateRequired === false) {
            return;
          }
          // JUST COMMENTING OUT THE INFOPATH ONLY CONDITION AS curSelectedModuleTechType IS NOT HAVING CORRECT VALUE
          //if (curSelectedModuleTechType === "InfoPath") {
            sessionTableActivityFlag = await checkIfSessionIsStillActive(oidcUser.access_token, userId, orgDomain, isCurSessionLesson, curSessionID, curSelectedModuleTechType === "Lectora");
            //sessionTableActivityFlag = await checkIfSessionIsStillActive(oidcUser.access_token, userId, orgDomain, isCurSessionLesson, curSessionID, false);
            if (sessionTableActivityFlag === true) {
              sessionCompleted = await checkSessionCompleted(oidcUser.access_token, userId, orgDomain, curSessionID, isCurSessionLesson);
            }
          //}
          // Case 1. Quiz is fully completed by passing but there is a delay => sessionCompleted is true; sessionTableActivityFlag is true/false
          // Case 2. Quiz is fully completed by failing but there is a delay => sessionCompleted is true; sessionTableActivityFlag is true/false
          // Case 3. Quiz is fully completed by failing but there is a delay => sessionCompleted is false; sessionTableActivityFlag is false
          // Now Dependency on SessionInProgress_ DME is removed and added additional flag in the Quiz and Sessions tables to handle the progress check
          // This entry is deleted when the quiz is fully completed
          // Oterwise the date completion of session should be present whic is confirmed by the flag sessionCompleted
          if ((sessionTableActivityFlag !== true || sessionCompleted === true) && mapSessionCompletion.get(curSessionID) === false) {
            clearInterval(dmeCheck);
            let updatedMap2 = mapSessionCompletion.set(curSessionID, true);
            setMapSessionCompletion(updatedMap2);
            if (sessionCompleted) {
              if (curSelectedModuleTechType === "Lectora") {
                executeProfilePointDME(oidcUser.access_token, userId, orgDomain, curSelectedModuleID, curSessionID, ((oidcUser?.session_state === undefined || oidcUser?.session_state === null) ? "" : oidcUser?.session_state));
                executeCertificateDME(oidcUser.access_token, userId, orgDomain, curSelectedModuleID, currentCourseID, curSessionID, "en-AU", ((oidcUser?.session_state === undefined || oidcUser?.session_state === null) ? "" : oidcUser?.session_state)); // TO DO hard coded culture
              }
            }
            // getUserCPDs(oidcUser.access_token, userId, orgDomain).then((cpdData) => {
            //   if (isMounted.current) {
            //     setCpdList(cpdData);
            //   }
            //   cpdId = !!cpdData && cpdData.length > 0 ? cpdData[currentCpd].id : -1; //If no CPD list value is defaulted to -1
            //   cpdPrdId = !!cpdData && cpdData.length > 0 ? cpdData[currentCpd].periodID : -1; //If no CPD list value is defaulted to -1
            getUserCourseModulesWithoutCPD(oidcUser.access_token, userId, orgDomain, courseId, cpdId)
            //getUserCourseModules(oidcUser.access_token, userId, orgDomain, courseId, cpdId)
              .then((mList) => {
                  if (isMounted.current) {
                    setModuleList(mList);
                  }
                  //getUserCourseProgress(oidcUser.access_token, userId, orgDomain, cpdId, !orgId ? -1 : orgId).then((cProg: CourseProgressResult) => {
                  //getUserCoursesPolicies(oidcUser.access_token, userId, orgDomain, !orgId ? -1 : orgId, cpdId, cpdPrdId).then((trainingResult : MyTrainingItemsResult | null) => {
                  getUserCoursesPoliciesOnlyWithProgress(oidcUser.access_token, userId, orgDomain, !orgId ? -1 : orgId, cpdId, cpdPrdId).then((trainingResult : MyTrainingItemsResult | null) => {
                    let cProg = !!trainingResult ? (trainingResult as MyTrainingItemsResult).overallProgress : null;
                    //if (!!trainingResult && (trainingResult as MyTrainingItemsResult).doesUserHaveCPD) {
                      setShowCPD(true);
                      setCpdList(null);
                      //handleCPDIntro()
                    //}
                    //else {
                    //  setShowCPD(false);
                    //  setCpdList([]);
                    //}
                    if (isMounted.current) {
                      setCoursesAssigned((!!cProg !== true || cProg == null) ? 0 : cProg.assignedCoursesCount);
                      setCoursesCompleted((!!cProg !== true || cProg == null) ? 0 : cProg.completedCoursesCount);
                      setTotalCourseProgress((!!cProg !== true || cProg == null) ? 0 : cProg.totalCourseProgress);
                      setAssignedQuizesCount((!!cProg !== true || cProg == null) ? 0 : cProg.assignedQuizzesCount);
                      setPassedQuizesCount((!!cProg !== true || cProg == null) ? 0 : cProg.passedQuizzesCount);
                      setTotalQuizProgress((!!cProg !== true || cProg == null) ? 0 : cProg.totalQuizProgress);
                      setTotalLessonProgress((!!cProg !== true || cProg == null || cProg.assignedLessonsCount <= 0) ? 0 : Math.round((cProg.completedLessonsCount / cProg.assignedLessonsCount) * 100));
                      setTotalPolicyProgress((!!cProg !== true || cProg == null || cProg.assignedPoliciesCount <= 0) ? 0 : Math.round((cProg.acceptedPoliciesCount / cProg.assignedPoliciesCount) * 100));
                      setTotalCertificateProgress(Math.round((!!cProg !== true || cProg == null || cProg.assignedCertificateCount <= 0) ? 0 : (cProg.acceptedCertificateCount / cProg.assignedCertificateCount) * 100))
                  
                    }
                  });

                  if (showResultsModal) {
                    getQuizSessionDetails(oidcUser.access_token, userId, orgDomain, curSessionID, !!curSelectedModuleTechType ? curSelectedModuleTechType : "").then((resultData) => {
                      if (resultData.quizPassmark > 0) {
                        if (isMounted.current) {
                          setResultData(resultData);
                          setLoading(false);
                          checkScrollReminder();
                          setModalOpen(true);
                        }
                        clearInterval(dmeCheck);

                        getUserCPDs(oidcUser.access_token, userId, orgDomain).then((cpdData) => {
                          setCPDLoading(false)
                          if (isMounted.current) {
                            let cpdIdTmp = !!cpdData && cpdData.length > 0 ? cpdData[0].id : -1
                            let cpdPrdIdTmp = !!cpdData && cpdData.length > 0 ? cpdData[0].periodID : -1
                            setShowCPD(cpdIdTmp > 0 && cpdPrdIdTmp > 0); 
                            setCpdList(cpdData);
                            if (cpdIdTmp > 0 && cpdPrdIdTmp > 0) {
                              handleCPDIntro()
                            }
                    
                            // TO DO
                            // Set the CPD as Ready
                            if (!!mList && mList.length > 0) {
                              mList.map(m => {
                                if (!!m) {
                                  m.isCPDReady = true;
                                  return m;
                                }
                                return m;
                              })
                              setModuleList([...mList]);
                            }
                          }
                          cpdId = !!cpdData && cpdData.length > 0 ? cpdData[currentCpd].id : -1; //If no CPD list value is defaulted to -1
                          cpdPrdId = !!cpdData && cpdData.length > 0 ? cpdData[currentCpd].periodID : -1; //If no CPD list value is defaulted to -1
                        });
                      }
                    });
                  }
                  else {
                    getUserCPDs(oidcUser.access_token, userId, orgDomain).then((cpdData) => {
                      setCPDLoading(false);
                      if (isMounted.current) {
                        let cpdIdTmp = !!cpdData && cpdData.length > 0 ? cpdData[0].id : -1
                        let cpdPrdIdTmp = !!cpdData && cpdData.length > 0 ? cpdData[0].periodID : -1
                        setShowCPD(cpdIdTmp > 0 && cpdPrdIdTmp > 0); 
                        setCpdList(cpdData);
                        if (cpdIdTmp > 0 && cpdPrdIdTmp > 0) {
                          handleCPDIntro()
                        }
                    
                        // TO DO
                        // Set the CPD as Ready
                        if (!!mList && mList.length > 0) {
                          mList.map(m => {
                            if (!!m) {
                              m.isCPDReady = true;
                              return m;
                            }
                            return m;
                          })
                          setModuleList([...mList]);
                        }
                      }
                      cpdId = !!cpdData && cpdData.length > 0 ? cpdData[currentCpd].id : -1; //If no CPD list value is defaulted to -1
                      cpdPrdId = !!cpdData && cpdData.length > 0 ? cpdData[currentCpd].periodID : -1; //If no CPD list value is defaulted to -1
                    });
                  }  
                })
                .then(() => {
                  getQuizSessionDetails(oidcUser.access_token, userId, orgDomain, curSessionID, !!curSelectedModuleTechType ? curSelectedModuleTechType : "")
                    .then((resultData) => {
                      if (isMounted.current) {
                        if (showResultsModal) {
                          setResultData(resultData); //Result data is passed into the modal
                        }
                        setLoading(false);
                        checkScrollReminder();
                        if (cpdId !== -1) {
                          setShowCPD(true);
                          handleCPDIntro()
                        }
                      }
                      clearInterval(dmeCheck);
                    })
                    .then(() => {
                      if (curSelectedModuleTechType === "SA") {
                        deleteDME(oidcUser.access_token, userId, orgDomain, curSelectedModuleID).then(() => {
                          if (isMounted.current) {
                            setLoading(false);
                            checkScrollReminder();
                            if (cpdId !== -1) {
                              setShowCPD(true);
                              handleCPDIntro()
                            }
                          }
                          clearInterval(dmeCheck);
                        });
                      } else {
                        if (isMounted.current) {
                          setLoading(false);
                          checkScrollReminder();
                          if (cpdId !== -1) {
                            setShowCPD(true);
                            handleCPDIntro()
                          }
                        }
                        clearInterval(dmeCheck);
                      }
                    });
                });
            //});
          }
        };
        //let dmeCheck = setInterval(checkSessionProgress, 1000); //Polls the API to Check to make sure the session is fully finished
        
        // INCREASED DELAY AS 3SEC TO REDUCE API LOAD DURING THE HEAVY LOAD SEASON OF SALT (JAN 2024)
        let dmeCheck = setInterval(checkSessionProgress, 3000); //Polls the API to Check to make sure the session is fully finished 

        //setTrainingListUpdateRequired(true)
      } else if (curSessionID !== "" && isCurSessionLesson) {
        //If lesson has just been completed
        // getUserCPDs(oidcUser.access_token, userId, orgDomain).then((cpdData) => {
        //   if (isMounted.current) {
        //     setCpdList(cpdData);
        //   }
        //   cpdId = !!cpdData && cpdData.length > 0 ? cpdData[currentCpd].id : -1; //If no CPD list value is defaulted to -1
          //getUserCourseModules(oidcUser.access_token, userId, orgDomain, courseId, cpdId).then((mList) => {
          getUserCourseModulesWithoutCPD(oidcUser.access_token, userId, orgDomain, courseId, cpdId).then((mList) => {
            if (isMounted.current) {
              setModuleList(mList);
            }
            //getUserCourseProgress(oidcUser.access_token, userId, orgDomain, cpdId, orgId).then((cProg: CourseProgressResult) => {
            //getUserCoursesPolicies(oidcUser.access_token, userId, orgDomain, !orgId ? -1 : orgId, cpdId, cpdPrdId).then((trainingResult : MyTrainingItemsResult | null) => {
            getUserCoursesPoliciesOnlyWithProgress(oidcUser.access_token, userId, orgDomain, !orgId ? -1 : orgId, cpdId, cpdPrdId).
            then((trainingResult : MyTrainingItemsResult | null) => {
              let cProg = !!trainingResult ? (trainingResult as MyTrainingItemsResult).overallProgress : null;
              //if (!!trainingResult && (trainingResult as MyTrainingItemsResult).doesUserHaveCPD) {
                setShowCPD(true);
                setCpdList(null);
                //handleCPDIntro()
              //}
              //else {
              //  setShowCPD(false);
              //  setCpdList([]);
              //}
              if (isMounted.current) {
                setCoursesAssigned((!!cProg !== true || cProg == null) ? 0 : cProg.assignedCoursesCount);
                setCoursesCompleted((!!cProg !== true || cProg == null) ? 0 : cProg.completedCoursesCount);
                setTotalCourseProgress((!!cProg !== true || cProg == null) ? 0 : cProg.totalCourseProgress);
                setAssignedQuizesCount((!!cProg !== true || cProg == null) ? 0 : cProg.assignedQuizzesCount);
                setPassedQuizesCount((!!cProg !== true || cProg == null) ? 0 : cProg.passedQuizzesCount);
                setTotalQuizProgress((!!cProg !== true || cProg == null) ? 0 : cProg.totalQuizProgress);
                setTotalLessonProgress((!!cProg !== true || cProg == null || cProg.assignedLessonsCount <= 0) ? 0 : Math.round((cProg.completedLessonsCount / cProg.assignedLessonsCount) * 100));
                setTotalPolicyProgress((!!cProg !== true || cProg == null || cProg.assignedPoliciesCount <= 0) ? 0 : Math.round((cProg.acceptedPoliciesCount / cProg.assignedPoliciesCount) * 100));
                setTotalCertificateProgress(Math.round((!!cProg !== true || cProg == null || cProg.assignedCertificateCount <= 0) ? 0 : (cProg.acceptedCertificateCount / cProg.assignedCertificateCount) * 100))
                  
                setLoading(false);
                checkScrollReminder();

                getUserCPDs(oidcUser.access_token, userId, orgDomain).then((cpdData) => {
                  setCPDLoading(false)
                  if (isMounted.current) {
                    let cpdIdTmp = !!cpdData && cpdData.length > 0 ? cpdData[0].id : -1
                    let cpdPrdIdTmp = !!cpdData && cpdData.length > 0 ? cpdData[0].periodID : -1
                    setShowCPD(cpdIdTmp > 0 && cpdPrdIdTmp > 0); 
                    setCpdList(cpdData);
                    if (cpdIdTmp > 0 && cpdPrdIdTmp > 0) {
                      handleCPDIntro()
                    }
                  }
                  cpdId = !!cpdData && cpdData.length > 0 ? cpdData[currentCpd].id : -1; //If no CPD list value is defaulted to -1
                    
                  // TO DO
                  // Set the CPD as Ready
                  if (!!mList && mList.length > 0) {
                    mList.map(m => {
                      if (!!m) {
                        m.isCPDReady = true;
                        return m;
                      }
                      return m;
                    })
                    setModuleList([...mList]);
                  }
                });
              }
            });
          });
        //});
      } else {
        //if there is state from the previous component no refetch required
        if (isMounted.current) {
          setMessage(allResourcesToTranslations.buildingmodulelist);
        }
        cpdId = cpdList === null || cpdList.length <= 0 ? -1 : cpdList[currentCpd].id; //If no CPD list value is defaulted to -1
        //getUserCourseModules(oidcUser.access_token, userId, orgDomain, courseId, cpdId)
        getUserCourseModulesWithoutCPD(oidcUser.access_token, userId, orgDomain, courseId, cpdId)
          .then((mList) => {
            if (isMounted.current) {
              setModuleList(mList);
            }
          })
          .then(() => {
            if (isMounted.current) {
              setLoading(false);
              setCPDLoading(false);
              if (cpdId !== -1) {
                let cpdIdTmp = !!cpdList && cpdList.length > 0 ? cpdList[0].id : -1
                let cpdPrdIdTmp = !!cpdList && cpdList.length > 0 ? cpdList[0].periodID : -1
                setShowCPD(cpdIdTmp > 0 && cpdPrdIdTmp > 0); 
                if (cpdIdTmp > 0 && cpdPrdIdTmp > 0) {
                  handleCPDIntro()
                }
              }
              getUserCourseModules(oidcUser.access_token, userId, orgDomain, courseId, cpdId)
                .then((mList) => {
                  if (isMounted.current) {
                    setModuleList(mList);
                  }
                })
              checkScrollReminder();
            }
          });
      }
    } else {
      history.push("/mytraining"); //if user has refreshed page or if they have exited mid quiz send them back to My Training to fetch everying from scratch
    }

    return () => {
      setShowResultsModal(false);
      setShowScrollArrow(false);
      isMounted.current = false;
    };
  }, [trainingListUpdateRequired,backRoute]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <LoaderContainer />;
  }

  return (
    <>
      <Steps
          enabled={intro.stepsEnabled}
          steps={intro.steps}
          initialStep={intro.initialStep}
          onExit={onExit}
          options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
          }}
        />
      <div className={styles.guideMeButton}>
             <CustomTooltip displayText={[allResourcesToTranslations.guideme]}>
                {/* <Button variant="outlined" color="primary" style={{backgroundColor:'white',position:'absolute',top:'87px',right:'90px'}} onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
                    <GuideIcon />
                    </Button> */}
               <span onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
                      <GuideIcon />
                    </span>
            </CustomTooltip>
      </div>
      <div className={responsivestyles.rowrule} style={{ width: '100%' }}>
              <div className={ showCPD ? `${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}` :`${responsivestyles.columnrule_s_12}` } >
              <div style={{ padding:" 0 5px 5px 5px" }}>
              <CourseCompletionPanel
                assignedCoursesCount={coursesAssigned}
                completedCoursesCount={coursesCompleted}
                totalCourseProgress={totalCourseProgress}
                assignedQuizesCount={assignedQuizesCount}
                passedQuizesCount={passedQuizesCount}
                totalQuizProgress={totalQuizProgress}
                lessonCompletionProgress={totalLessonProgress}
                policyAcceptanceProgress={totalPolicyProgress}
                CertificateProgress={totalCertificateProgress}
              />
              </div>
            </div>
            {showCPD &&            
              <div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
                <div style={{ padding:" 0 5px 5px 5px" }}>
                  <CPDPanel showCPD={showCPD} setCurrentCpd={handleCpdUpdate} currentCpd={currentCpd} cpdList={cpdList} isCPDLoading={isCPDLoading} calledFrom='modulelist'/>
                </div>
              </div>
            }
      </div>
      <div style={{ display: loading ? "none" : "block" }}>
        <br></br>
        <LearningModuleList
          showCPD={showCPD}
          showLastPassDate={showLastPassDate}
          learningModules={moduleList}
          logNavigation={logNavigation}
          setSASelectedModule={setSASelectedModule}
          setReviewModalOpen={setReviewModalOpen}
          setRestartModalOpen={setRestartModalOpen}
        />
      </div>
      <Modal modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <ResultsModalContent resultData={resultData} loading={loading} handleModalClose={handleModalClose} curSelectedModuleTechType={curSelectedModuleTechType}/>
      </Modal>

      <Modal modalOpen={restartModalOpen} setModalOpen={setRestartModalOpen} type="warning">
        <SARestartModalContent setModalOpen={setRestartModalOpen} selectedLearningModule={SASelectedModule} />
      </Modal>

      <Modal modalOpen={reviewModalOpen} setModalOpen={setReviewModalOpen} type="warning">
        <SAReviewModalContent setModalOpen={setReviewModalOpen} selectedLearningModule={SASelectedModule} />
      </Modal>
    </>
  );
};

export default LearningModules;
