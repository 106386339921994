import React, { useContext, useEffect } from "react";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { InfopathContext } from "../../contexts/InfopathContext";
import { InfopathQuestionType } from '../../globalTypes'

interface Props {
    question: InfopathQuestionType
    updatePageAudit: (questionDuration: number, answerId: number | null, questionId: number) => void 
    currentSelection: number | null
}

export const InfopathQuestion = ({ question, updatePageAudit, currentSelection }: Props) : JSX.Element => {
  const currentTime = new Date().getTime();
  const {currentQuizQuestions, setCurrentQuizQuestions, setCurrentSelection} = useContext(InfopathContext);
  let selectedAnswer: number | null = 0
  
  const getToolbookQuestionNumber = () => {
    for (let i = 0; i < currentQuizQuestions.length; i++) {
      if (question.id === currentQuizQuestions[i].id) {
        return currentQuizQuestions[i].questionNumber
      }
    }
    return 0
  }

  const checkPreviousSelection = () => {
    setCurrentSelection(0)
    for (let i = 0; i < currentQuizQuestions.length; i++) {
      if (question.id === currentQuizQuestions[i].id) {
        setCurrentSelection(currentQuizQuestions[i].userSelection);
        selectedAnswer = currentQuizQuestions[i].userSelection
        return;
      }
    }
  };

  const handleSelection = (answerId: number, questionId: number) => {
    setCurrentSelection(answerId);
    const updatedQuizData = currentQuizQuestions;
    for (let i = 0; i < updatedQuizData.length; i++) {
      const q = updatedQuizData[i];
      if (questionId === q.id) {
        updatedQuizData[i].userSelection = answerId;
        setCurrentQuizQuestions(updatedQuizData); 
        selectedAnswer = currentQuizQuestions[i].userSelection
        return;
      }
    }
  };

  useEffect(() => {  
    checkPreviousSelection();
    return () => { //Fired on component unmount
      checkPreviousSelection();
      const closeTime: number = new Date().getTime()
      const questionDuration: number = closeTime - currentTime
      updatePageAudit(questionDuration, selectedAnswer, getToolbookQuestionNumber())
    }
  },[question.answers]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <h3>{question.questionText.split('<NLCR/>').filter(function(str) {
                                    if (str === null || str.length <= 0 || str.trimLeft().trimRight().length <= 0) {
                                        return false;
                                    }
                                    return true;
                                  }).map(str => <p>{str}</p>)}</h3>
      <div>
        <RadioGroup aria-label={question.questionText} name="answers">
          {question.answers.map((a, i) => {
            return(
              <div key={`${a.id}-${i}`} style={{marginBottom: "6px"}}>
                <FormControlLabel 
                  value={a.answerText.split('<NLCR/>').filter(function(str) {
                    if (str === null || str.length <= 0 || str.trimLeft().trimRight().length <= 0) {
                        return false;
                    }
                    return true;
                  }).map(str => <p>{str}</p>)} 
                  control={<Radio color="primary"  />}
                  label={a.answerText.split('<NLCR/>').filter(function(str) {
                    if (str === null || str.length <= 0 || str.trimLeft().trimRight().length <= 0) {
                        return false;
                    }
                    return true;
                  }).map(str => <p>{str}</p>)} 
                  checked={currentSelection === a.id}
                  onClick={() => handleSelection(a.id, question.id)}
                  />
              </div>
            )
          })}
        </RadioGroup>
      </div>
    </>
  );
}


export default InfopathQuestion