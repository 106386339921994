import React, { useEffect, useContext, useState, ChangeEvent } from "react";
import { useHistory } from 'react-router-dom';
import BrandedCard from "../../../Components/BrandedCard";

//Styles
import styles from "../../../styles/addAccount.module.css";
import {
  Button,
  Checkbox,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
} from "@material-ui/core";
import getColor from "../../../functions/getColor";

import { CorperateThemeContext } from "../../../contexts/CorperateThemeContext";
import UpArrowIcon from "../../../CustomIcons/UpArrowIcon";
import DownArrowIcon from "../../../CustomIcons/DownArrowIcon";
import ActiveIcon from "../../../CustomIcons/Status_ActiveIcon";
import InActiveIcon from "../../../CustomIcons/Status_InActiveIcon";
import getOneUserDetails from "../../../API/Administration/Users/GetOneUserDetails";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import CustomTooltip from "../../../Components/CustomTooltip";
import ExcelFileIcon from "../../../CustomIcons/ExcelFileIcon";
import { addError } from "../../../API/AddError";
import { UIContext } from "../../../contexts/UIContext";
import getExportedXLSXData from "../../../API/GetExportedXLSXData";
import serialToArray from "../../../functions/serialToArray";
import LoaderContainer from "../../../Components/LoaderContainer";
import getFullUserDetailReport from "../../../API/GetFullUserDetailReport";

const UserListSection = (props: any) => {
const { usersData, unitIdToEdit } = props;
const { oidcUser } = useReactOidc();
const {
    profile: { user_id, website },
} = oidcUser || {};

const userId = user_id ? parseInt(user_id) : 0;
const orgId = oidcUser.profile.organisation_id ?? -1;
const orgDomain = website
    ? website
      .toLowerCase()
      .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
: "";

const { EnableActivityLogs } = useContext(UIContext);
const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true);
const [firstNameDescending, setFirstNameDescending] =
useState<boolean>(false);
const [lastNameDescending, setLastNameDescending] =
useState<boolean>(false);
const [statusDescending, setStatusDescending] =
useState<boolean>(false);
const [emailDescending, setEmailDescending] = useState<boolean>(false);
const [userIDDescending, setUserIDDescending] = useState<boolean>(false);
const [lastLoginDescending, setLastLoginDescending] = useState<boolean>(false);
const [currentSortBy, setCurrentSortBy] = useState<string>("");
const [unitPathwayDescending, setUnitPathwayDescending] = useState<boolean>(false);
const [reverseNextSort, setReverseNextSort] = useState<boolean>(false);
const [rowsPerPage, setRowsPerPage] = useState<number>(10);
const [page, setPage] = useState<number>(0);
const [usersList, setUsersList] = useState<Array<any>>(usersData);
const [usersListRowCount, setusersListRowCount] = useState<string>(usersList.length.toString() + " Results");
const [exportError, setExportError] = useState<string>("");
const [exporting, setExporting] = useState<boolean>(false);
const [includeInactive, setIncludeInactive] = useState<boolean>(false);
const [loading, setLoading] = useState<boolean>(true);
const {theme: { color } } = useContext(CorperateThemeContext);
const history = useHistory();

  useEffect(() => {
    setUsersList(usersData);
    setLoading(false);
  }, [usersData]);

  const handleInactiveUser = (includeInactive: boolean) => {
      getFullUserDetailReport(oidcUser.access_token, userId, orgDomain, parseInt(orgId)
          , unitIdToEdit, includeInactive
    ).then((response) => {
      if (
        response !== null &&
        response !== undefined &&
        response.isSuccess &&
        response.userDetailReportReportItems !== null &&
        response.userDetailReportReportItems !== undefined
      ) {
        
        if(response.userDetailReportReportItems.length > 0) {
          let updatedUsersList = response.userDetailReportReportItems.map((item: any) => {
            return {
              ...item,
              isChecked: false,
            };
          });
          setUsersList(updatedUsersList);    
          setusersListRowCount(updatedUsersList.length.toString() + " Results");
         }
        }
    });

  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
    } else {
      return {};
    }
  };

  const sortResultsByAsync = async (
    sortBy: "FirstName" | "LastName" | "Status" | "Email" | "UnitPathway" | "UserID" | "LastLogin",
    sortGivenItemInDesc: boolean
  ) => {
    if (usersList == null || usersList.length <= 0) {
      return usersList;
    }
    var result = usersList.slice(0);
    setCurrentSortBy(sortBy);
    switch (sortBy) {

      case "UnitPathway":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return -1;
            }
            if (a === null || a.email === null) {
              return 1;
            }
            if (a.unitPathway.toLowerCase() > b.unitPathway.toLowerCase()) {
              return -1;
            }
            if (a.unitPathway.toLowerCase() < b.unitPathway.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitPathwayDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return 1;
            }
            if (a === null || a.email === null) {
              return -1;
            }
            if (a.unitPathway.toLowerCase() < b.unitPathway.toLowerCase()) {
              return -1;
            }
            if (a.unitPathway.toLowerCase() > b.unitPathway.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitPathwayDescending(true);
          }
        }
        setUsersList(result);
        break;
      case "Email":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return -1;
            }
            if (a === null || a.email === null) {
              return 1;
            }
            if (a.email.toLowerCase() > b.email.toLowerCase()) {
              return -1;
            }
            if (a.email.toLowerCase() < b.email.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setEmailDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return 1;
            }
            if (a === null || a.email === null) {
              return -1;
            }
            if (a.email.toLowerCase() < b.email.toLowerCase()) {
              return -1;
            }
            if (a.email.toLowerCase() > b.email.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setEmailDescending(true);
          }
        }
        setUsersList(result);
        break;
      case "FirstName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.firstName === null) {
              return -1;
            }
            if (a === null || a.firstName === null) {
              return 1;
            }
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
              return -1;
            }
            if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.firstName === null) {
              return 1;
            }
            if (a === null || a.firstName === null) {
              return -1;
            }
            if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
              return -1;
            }
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstNameDescending(true);
          }
        }
        setUsersList(result);
        break;
      case "LastName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) {
              return -1;
            }
            if (a === null || a.lastName === null) {
              return 1;
            }
            if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
              return -1;
            }
            if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) {
              return 1;
            }
            if (a === null || a.lastName === null) {
              return -1;
            }
            if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
              return -1;
            }
            if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastNameDescending(true);
          }
        }
        setUsersList(result);
        break;

      case "Status":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.active === null) {
              return -1;
            }
            if (a === null || a.active === null) {
              return 1;
            }
            if (a.active.toString().toLowerCase() > b.active.toString().toLowerCase()) {
              return -1;
            }
            if (a.active.toString().toLowerCase() < b.active.toString().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setStatusDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.active === null) {
              return 1;
            }
            if (a === null || a.active === null) {
              return -1;
            }
            if (a.active.toString().toLowerCase() < b.active.toString().toLowerCase()) {
              return -1;
            }
            if (a.active.toString().toLowerCase() > b.active.toString().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setStatusDescending(true);
          }
        }
        setUsersList(result);
        break;

      case "UserID":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.userID === null) {
              return -1;
            }
            if (a === null || a.userID === null) {
              return 1;
            }
            if (a.userID > b.userID) {
              return -1;
            }
            if (a.userID < b.userID) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUserIDDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.userID === null) {
              return 1;
            }
            if (a === null || a.userID === null) {
              return -1;
            }
            if (a.userID < b.userID) {
              return -1;
            }
            if (a.userID > b.userID) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUserIDDescending(true);
          }
        }
        setUsersList(result);
        break;
      case "LastLogin":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.lastLoginDate === null || b.lastLoginDate.trimLeft().trimRight() === "") { return 1; }
            if (a === null || a.lastLoginDate === null || a.lastLoginDate.trimLeft().trimRight() === "") { return -1; }
            let maintoken1 = a.lastLoginDate.split(" ");
            let timetoken1 = maintoken1[1].split(":");
            let tokens1 = maintoken1[0].split("/");
            let aDate = new Date(parseInt(tokens1[2].trimLeft().trimRight()), parseInt(tokens1[1].trimLeft().trimRight()) - 1, parseInt(tokens1[0].trimLeft().trimRight()), parseInt(timetoken1[0].trimLeft().trimRight()), parseInt(timetoken1[1].trimLeft().trimRight()));

            let maintoken2 = b.lastLoginDate.split(" ");
            let timetoken2 = maintoken2[1].split(":");
            let tokens2 = maintoken2[0].split("/");
            let bDate = new Date(parseInt(tokens2[2].trimLeft().trimRight()), parseInt(tokens2[1].trimLeft().trimRight()) - 1, parseInt(tokens2[0].trimLeft().trimRight()), parseInt(timetoken2[0].trimLeft().trimRight()), parseInt(timetoken2[1].trimLeft().trimRight()));
            if (aDate > bDate) { return -1; }
            if (aDate < bDate) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setLastLoginDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (a === null || a.lastLoginDate === null || a.lastLoginDate.trimLeft().trimRight() === "") { return 1; }
            if (b === null || b.lastLoginDate === null || b.lastLoginDate.trimLeft().trimRight() === "") { return -1; }
            let maintoken1 = a.lastLoginDate.split(" ");
            let timetoken1 = maintoken1[1].split(":");
            let tokens1 = maintoken1[0].split("/");
            let aDate = new Date(parseInt(tokens1[2].trimLeft().trimRight()), parseInt(tokens1[1].trimLeft().trimRight()) - 1, parseInt(tokens1[0].trimLeft().trimRight()), parseInt(timetoken1[0].trimLeft().trimRight()), parseInt(timetoken1[1].trimLeft().trimRight()));

            let maintoken2 = b.lastLoginDate.split(" ");
            let timetoken2 = maintoken2[1].split(":");
            let tokens2 = maintoken2[0].split("/");
            let bDate = new Date(parseInt(tokens2[2].trimLeft().trimRight()), parseInt(tokens2[1].trimLeft().trimRight()) - 1, parseInt(tokens2[0].trimLeft().trimRight()), parseInt(timetoken2[0].trimLeft().trimRight()), parseInt(timetoken2[1].trimLeft().trimRight()));
            if (aDate < bDate) { return -1; }
            if (aDate > bDate) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setLastLoginDescending(true)
          }
        }
        setUsersList(result);
        break;


      default:
        break;
    }
    return result.slice(0);
  };

  const sortResultsBy = async (
    sortBy: "FirstName" | "LastName" | "Status" | "Email" | "UnitPathway" | "UserID" | "LastLogin",
    sortGivenItemInDesc: boolean
  ) => {
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy);
      setUsersList(result);
      setPage(0);
    });
  };

  const clickFirstNameAsc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(false);
    sortResultsBy("FirstName", false);
  };

  const clickFirstNameDesc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(true);
    sortResultsBy("FirstName", true);
  };

  const clickLastNameAsc = () => {
    setReverseNextSort(false);
    setLastNameDescending(false);
    sortResultsBy("LastName", false);
  };

  const clickLastNameDesc = () => {
    setReverseNextSort(false);
    setLastNameDescending(true);
    sortResultsBy("LastName", true);
  };

  const clickStatusAsc = () => {
    setReverseNextSort(false);
    setStatusDescending(false);
    sortResultsBy("Status", false);
  };

  const clickStatusDesc = () => {
    setReverseNextSort(false);
    setStatusDescending(true);
    sortResultsBy("Status", true);
  };

  const clickUnitPathwayAsc = () => {
    setReverseNextSort(false);
    setUnitPathwayDescending(false);
    sortResultsBy("UnitPathway", false);
  };

  const clickUnitPathwayDesc = () => {
    setReverseNextSort(false);
    setUnitPathwayDescending(true);
    sortResultsBy("UnitPathway", true);
  };

  const clickEmailAsc = () => {
    setReverseNextSort(false);
    setEmailDescending(false);
    sortResultsBy("Email", false);
  };

  const clickEmailDesc = () => {
    setReverseNextSort(false);
    setEmailDescending(true);
    sortResultsBy("Email", true);
  };

  const clickUserIDAsc = () => {
    setReverseNextSort(false);
    setUserIDDescending(false);
    sortResultsBy("UserID", false);
  };

  const clickUserIDDesc = () => {
    setReverseNextSort(false);
    setUserIDDescending(true);
    sortResultsBy("UserID", true);
  };


  const clickLastLoginAsc = () => {
    setReverseNextSort(false);
    setLastLoginDescending(false);
    sortResultsBy("LastLogin", false);
  };

  const clickLastLoginDesc = () => {
    setReverseNextSort(false);
    setLastLoginDescending(true);
    sortResultsBy("LastLogin", true);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const GetDate_yymmdd = () => {
    var d = new Date(Date.now());
    let day = d.getFullYear().toString().substring(2, 4) + (d.getMonth() + 1).toString() + d.getDate().toString()
    return day;
  }

  const buildRequestStringArray = () => {
    let resultArray: Array<string> = [];
    for (let i = 0; i < usersList.length; i++) {
      const r = usersList[i];     
     // resultArray.push(`${r.userID}<#>${r.unitPathway}<#>${r.lastName}<#>${r.firstName.toString()}<#>${r.active ? "Active" : "Inactive"}<#>${r.email}`);
     if (includeInactive) {
      resultArray.push(`${r.unitPathway}<#>${r.lastName}<#>${r.firstName}<#>${r.userName}<#>${r.email}<#>${r.externalID}<#>${r.status}<#>${r.userID}<#>${r.unitID}<#>${r.groupBy}<#>${r.value}<#>${r.delinquencyManagerEmail}<#>${r.notifyMgr}<#>${r.createdByUserName}<#>${r.updatedByUserName}<#>${r.dateCreated}<#>${(r.lastLoginDate == null || r.lastLoginDate == "") ? "Never" : r.lastLoginDate}<#>${r.dateUpdated}<#>${r.dateArchived}`);
      }
      else {
        resultArray.push(`${r.unitPathway}<#>${r.lastName}<#>${r.firstName}<#>${r.userName}<#>${r.email}<#>${r.externalID}<#>${r.status}<#>${r.userID}<#>${r.unitID}<#>${r.groupBy}<#>${r.value}<#>${r.delinquencyManagerEmail}<#>${r.notifyMgr}<#>${r.createdByUserName}<#>${r.updatedByUserName}<#>${r.dateCreated}<#>${(r.lastLoginDate == null || r.lastLoginDate == "") ? "Never" : r.lastLoginDate}<#>${r.dateUpdated}`);
      }
    }
    return resultArray;
  };

  const handleXLSXExport = () => {
   
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started User Detail Report XLSX-EXPORT in NEWSALT ClientApp",
      null,
      "UserDetailReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), includeInactive?19:18, "UserDetailReport", enableRowBanding, "","","","",includeInactive,false, "", "", "", "", "", "", "").then((xlxsRes) => {
        if (xlxsRes.isSuccess) {
            const fileData = xlxsRes.xlsxDataOutput.fileData
            var a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(
                new Blob([serialToArray(atob(fileData))], {
                    type: ''
                })
            );            
            a.download = "AccountDetailReport-"+  GetDate_yymmdd() + ".xlsx" ;
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)

        }
        else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
            setExportError(xlxsRes.error);
        }
        setExporting(false);
        // Activity log
        addError(oidcUser.access_token, userId, orgDomain, "Completed User Detail Report XLSX-EXPORT in NEWSALT ClientApp", null, "Unit-UserListSection.tsx", 6, EnableActivityLogs);
    })
  };

  const handleUserDetails = (userID: any) => {
    history.push({
      pathname: `/accountDetails/`+ userID
    })
    
  }
  if (loading) return <LoaderContainer />;
  return (
      <>
      <div style={{ float: "right" }} className={styles.reportExportContainer}>
      <span className="includeinactivesuser"> <Checkbox
          size="small"
          color="primary"
          checked={includeInactive}
          className={styles.checkBox}
          onClick={() => {
            setIncludeInactive(!includeInactive);
            handleInactiveUser(!includeInactive);
          }}
        />{" "}
        <b>{"Include Inactive Accounts"}</b></span>&nbsp;&nbsp;&nbsp;&nbsp;
        <span><b>{usersListRowCount}</b></span>&nbsp;&nbsp;
        <CustomTooltip displayText={["Export to XLSX"] /*TODO: Add translations */}>
          <Button className="xlxsuser" color="primary" onClick={handleXLSXExport} id="btnNewSaltCARReportExportXLSX">
            <ExcelFileIcon />
          </Button>
        </CustomTooltip>
      </div>

      <div className={styles.paginationControlsContainer}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "95%",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 100]}
            component="div"
            count={usersList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </div>
      <div className="userListSection">
        <div className={styles.tableSection}>
          <div className={styles.tableContainer}>
            <BrandedCard>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>

                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                          borderRadius: "8px 0 0 0",
                        }}
                        align="left"
                      >
                        <div
                          className={styles.tableHeadCellContainer}
                          style={{ display: "flex", }}
                        >
                          <div
                            onClick={() => {
                              setUserIDDescending(!userIDDescending);
                              sortResultsBy("UserID", !userIDDescending);
                            }}
                          >
                            {"Account ID" /* TODO translate */}
                          </div>
                          <div className={styles.columnHeadArea}>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "UserID" &&
                                    userIDDescending === false
                                    ? "100%"
                                    : "50%",
                                margin: "2px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickUserIDAsc}
                            >
                              <UpArrowIcon />{" "}
                            </span>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "UserID" &&
                                    userIDDescending === true
                                    ? "100%"
                                    : "50%",
                                margin: "0px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickUserIDDesc}
                            >
                              <DownArrowIcon />{" "}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                        }}
                        align="left"
                      >
                        <div
                          className={styles.tableHeadCellContainer}
                          style={{ display: "flex" }}
                        >
                          <div
                            onClick={() => {
                              setUnitPathwayDescending(!unitPathwayDescending);
                              sortResultsBy("UnitPathway", !unitPathwayDescending);
                            }}
                          >
                            {"Unit Pathway" /* TODO translate */}
                          </div>
                          <div className={styles.columnHeadArea}>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "UnitPathway" &&
                                    unitPathwayDescending === false
                                    ? "100%"
                                    : "50%",
                                margin: "2px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickUnitPathwayAsc}
                            >
                              <UpArrowIcon />{" "}
                            </span>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "UnitPathway" &&
                                    unitPathwayDescending === true
                                    ? "100%"
                                    : "50%",
                                margin: "0px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickUnitPathwayDesc}
                            >
                              <DownArrowIcon />{" "}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                        }}
                        align="left"
                      >
                        <div
                          className={styles.tableHeadCellContainer}
                          style={{ display: "flex" }}
                        >
                          <div
                            onClick={() => {
                              setLastNameDescending(!lastNameDescending);
                              sortResultsBy("LastName", !lastNameDescending);
                            }}
                          >
                            {"Last Name" /* TODO translate */}
                          </div>
                          <div className={styles.columnHeadArea}>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "LastName" &&
                                    lastNameDescending === false
                                    ? "100%"
                                    : "50%",
                                margin: "2px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickLastNameAsc}
                            >
                              <UpArrowIcon />{" "}
                            </span>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "LastName" &&
                                    lastNameDescending === true
                                    ? "100%"
                                    : "50%",
                                margin: "0px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickLastNameDesc}
                            >
                              <DownArrowIcon />{" "}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                        }}
                        align="left"
                      >
                        <div
                          className={styles.tableHeadCellContainer}
                          style={{ display: "flex" }}
                        >
                          <div
                            onClick={() => {
                              setFirstNameDescending(!firstNameDescending);
                              sortResultsBy(
                                "FirstName",
                                !firstNameDescending
                              );
                            }}
                          >
                            {"First Name" /* TODO translate */}
                          </div>
                          <div className={styles.columnHeadArea}>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "FirstName" &&
                                    firstNameDescending === false
                                    ? "100%"
                                    : "50%",
                                margin: "2px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickFirstNameAsc}
                            >
                              <UpArrowIcon />{" "}
                            </span>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "FirstName" &&
                                    firstNameDescending === true
                                    ? "100%"
                                    : "50%",
                                margin: "0px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickFirstNameDesc}
                            >
                              <DownArrowIcon />{" "}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                        }}
                        align="left"
                      >
                        <div
                          className={styles.tableHeadCellContainer}
                          style={{ display: "flex" }}
                        >
                          <div
                            onClick={() => {
                              setStatusDescending(!statusDescending);
                              sortResultsBy("Status", !statusDescending);
                            }}
                          >
                            {"Status" /* TODO translate */}
                          </div>
                          <div className={styles.columnHeadArea}>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "Status" &&
                                    statusDescending === false
                                    ? "100%"
                                    : "50%",
                                margin: "2px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickStatusAsc}
                            >
                              <UpArrowIcon />{" "}
                            </span>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "Status" &&
                                    statusDescending === true
                                    ? "100%"
                                    : "50%",
                                margin: "0px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickStatusDesc}
                            >
                              <DownArrowIcon />{" "}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                        }}
                        align="left"
                      >
                        <div
                          className={styles.tableHeadCellContainer}
                          style={{ display: "flex" }}
                        >
                          <div
                            onClick={() => {
                              setEmailDescending(!emailDescending);
                              sortResultsBy("Email", !emailDescending);
                            }}
                          >
                            {"Email" /* TODO translate */}
                          </div>
                          <div className={styles.columnHeadArea}>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "Email" &&
                                    emailDescending === false
                                    ? "100%"
                                    : "50%",
                                margin: "2px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickEmailAsc}
                            >
                              <UpArrowIcon />{" "}
                            </span>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "Email" &&
                                    emailDescending === true
                                    ? "100%"
                                    : "50%",
                                margin: "0px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickEmailDesc}
                            >
                              <DownArrowIcon />{" "}
                            </span>
                          </div>
                        </div>
                      </TableCell>

                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                          borderRadius: "0 8px 0 0",
                        }}
                        align="left"
                      >
                        <div
                          className={styles.tableHeadCellContainer}
                          style={{ display: "flex" }}
                        >
                          <div
                            onClick={() => {
                              setLastLoginDescending(!lastLoginDescending);
                              sortResultsBy("LastLogin", !lastLoginDescending);
                            }}
                          >
                            {"Last Login" /* TODO translate */}
                          </div>
                          <div className={styles.columnHeadArea}>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "LastLogin" &&
                                    lastLoginDescending === false
                                    ? "100%"
                                    : "50%",
                                margin: "2px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickLastLoginAsc}
                            >
                              <UpArrowIcon />{" "}
                            </span>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "LastLogin" &&
                                    lastLoginDescending === true
                                    ? "100%"
                                    : "50%",
                                margin: "0px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickLastLoginDesc}
                            >
                              <DownArrowIcon />{" "}
                            </span>
                          </div>
                        </div>
                      </TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((result: any, i) => {
                        return (
                          <TableRow
                            key={`reportScheduler-${i}`}
                            style={rowBackgroundColor(i)}
                          >
                            <TableCell align="left">
                            <span style={{ color: '#63C2FF', textDecoration: 'underline', cursor: "pointer" }} onClick={() => handleUserDetails(result.userID)}>{result.userID}</span>   
                            </TableCell>
                            <TableCell align="left">
                              {result.unitPathway}
                            </TableCell>
                            <TableCell align="left">
                            <span style={{ color: '#63C2FF', textDecoration: 'underline', cursor: "pointer" }} onClick={() => handleUserDetails(result.userID)}>{result.lastName}</span>
                            </TableCell>
                            <TableCell align="left">
                              {result.firstName}
                            </TableCell>
                            <TableCell align="left">
                              {result.active ?
                                <CustomTooltip displayText={["Active"]}>
                                  <span style={{ paddingRight: "5px" }}><ActiveIcon></ActiveIcon></span>
                                </CustomTooltip>
                                :
                                <CustomTooltip displayText={["Inactive"]}>
                                  <span style={{ paddingRight: "5px" }}><InActiveIcon></InActiveIcon></span>
                                </CustomTooltip>
                              }
                              {result.active ? "Active" : "Inactive"}</TableCell>
                            <TableCell align="left">{result.email}</TableCell>
                            <TableCell align="left">
                              {result.lastLogin}
                            </TableCell>

                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </BrandedCard>
          </div>
        </div>
      </div>
      <div className={styles.paginationAddedUsersControl}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "95%",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 100]}
            component="div"
            count={usersList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </div>
      </>

  );
};

export default UserListSection;
