import React from "react";

const SendEmailReportIcon = () => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M2.88251 1.53906H18.9261C20.3562 1.53906 21.5196 2.8243 21.5193 4.40422V13.8017L19.8281 12.1743V4.45717L11.4268 10.4255C11.4203 10.4299 11.4135 10.4336 11.4067 10.4373C11.4017 10.44 11.3967 10.4427 11.3918 10.4457C11.3797 10.4532 11.3673 10.4603 11.3549 10.4672C11.2895 10.5043 11.2226 10.5342 11.1541 10.5538C11.1501 10.555 11.1461 10.5559 11.142 10.5567C11.139 10.5573 11.136 10.558 11.133 10.5588C11.0577 10.5787 10.9816 10.5906 10.9054 10.5906H10.9037C10.8276 10.5906 10.7515 10.5784 10.6762 10.5588C10.6732 10.558 10.6702 10.5573 10.6671 10.5567C10.6631 10.5559 10.6591 10.555 10.6551 10.5538C10.5865 10.5342 10.5194 10.5043 10.4543 10.4672C10.4419 10.4603 10.4295 10.4532 10.4174 10.4457C10.4125 10.4427 10.4074 10.44 10.4024 10.4373C10.3957 10.4336 10.3889 10.4299 10.3824 10.4255L1.98107 4.45717V14.597C1.98107 15.1459 2.38541 15.5929 2.88251 15.5929H12V17.4616H2.88251C1.45268 17.4616 0.289307 16.1763 0.289307 14.5967V4.4039C0.289307 2.8243 1.45268 1.53906 2.88251 1.53906ZM10.9043 8.2157L17.659 3.40805H4.14964L10.9043 8.2157Z" fill="#FFFFFF"/>
<rect x="13.0409" y="19.0078" width="4.31598" height="5.75464" transform="rotate(-90 13.0409 19.0078)" fill="#FFFFFF"/>
<path d="M23.2465 16.7711L18.1887 21.4433L18.1887 12.0989L23.2465 16.7711Z" fill="#FFFFFF"/>
</svg>

    )
}

export default SendEmailReportIcon;