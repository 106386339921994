import React, { useContext } from "react"
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';

export const ErrorPageImage = () => {
  const { color } = useContext(CorperateThemeContext).theme;


  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="274px"
      height="171px"
      viewBox="0 0 274 171"
    >
      <path fill={ color } opacity={ "0.2" }
        d="M174,52.7c-4-5.5-12.2-5.5-16.2,0l-62.4,85.7
	c-2,2.7-6.1,2.7-8.1,0l-19.1-26.2c-4-5.5-12.2-5.5-16.2,0l-31.2,42.9c-4.8,6.6-0.1,15.9,8.1,15.9h62.4c0.5,0,1,0,1.5-0.1
	c0.3,0,0.6-0.1,1-0.1h146.3c8.2,0,12.9-9.3,8.1-15.9L174,52.7z"
      />
      <path fill={ color } opacity={ "0.1" }
        d="M176.8,19.4c-1,1.2,0,2.8,1.5,2.8h64.2
	c1.1,0,2-0.9,1.8-2c-1.2-5.8-7.7-10.3-15.5-10.3c-0.4,0-0.9,0-1.3,0c-0.9,0.1-1.8-0.4-2.2-1.2c-2.7-4.8-7.7-8-13.6-8
	c-6.6,0-12.2,4.1-14.5,9.9c-0.4,1-1.5,1.7-2.5,1.5c-0.8-0.1-1.7-0.2-2.6-0.2C185.9,12,180.4,14.9,176.8,19.4z"
      />
      <path fill={ color } opacity={ "0.1" }
        d="M19.5,57.2c-1.1,1.1-0.1,2.7,1.4,2.7h40.8
	c1.1,0,2-0.9,1.7-2c-1.1-3.5-5.2-6.2-10.1-6.2c-0.1,0-0.2,0-0.4,0c-0.9,0-1.8-0.5-2.2-1.2c-1.8-2.9-5.1-4.8-8.7-4.8
	c-4.1,0-7.7,2.4-9.3,5.9c-0.5,1-1.5,1.6-2.6,1.6c-0.3,0-0.7,0-1,0C25.2,53.2,21.9,54.7,19.5,57.2z"
      />
      <circle fill={ color } opacity={ "0.3" }
        cx="3.5" cy="87.5" r="3.5" />
      <circle fill={ color } opacity={ "0.3" }
        cx="101.5" cy="35.5" r="3.5" />
      <circle fill={ color } opacity={ "0.3" }
        cx="270.5" cy="75.5" r="3.5" />
      <path fill={ color } opacity={ "0.1" }
        d="M96,72l1.3,4.1h4.4l-3.5,2.6l1.3,4.1L96,80.3l-3.5,2.6l1.3-4.1l-3.5-2.6h4.4L96,72z" />
      <path fill={ color } opacity={ "0.1" }
       d="M244,43l1.3,4.1h4.4l-3.5,2.6l1.3,4.1l-3.5-2.6l-3.5,2.6l1.3-4.1l-3.5-2.6h4.4L244,43z" />
      <path fill={ color } opacity={ "0.1" }
       d="M227,80l1.3,4.1h4.4l-3.5,2.6l1.3,4.1l-3.5-2.6l-3.5,2.6l1.3-4.1l-3.5-2.6h4.4L227,80z" />
    </svg>
  );
}

export default ErrorPageImage;
