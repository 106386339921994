import axios from 'axios';
import { addError } from './AddError';

export const getUserSessionDme = async (token: string, userId: number, orgDomain: string, courseItemId: number, isLesson: boolean) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/usersessions/list`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        userid: userId,
        courseitemid: courseItemId,
        iscourseitemlesson: isLesson
      },
    });
    const data = await response.data;
    return data;

  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      };
      addError(token, userId, orgDomain, "Rest Exception", error.response, "GetUserSessionDme.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      };
      addError(token, userId, orgDomain, "Rest Exception", error.request, "GetUserSessionDme.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      };
      addError(token, userId, orgDomain, "Rest Exception", error.message, "GetUserSessionDme.ts", 1)
    }
    return null;
  }
};

export default getUserSessionDme;