import React, { useState, createContext } from 'react';

interface ICPDContext {
  cpdList: any
  setCpdList: (cpdList: any) => void;
  currentCpd: number;
  setCurrentCpd: (cpdIndex: number) => void;
  isCPDLoading: boolean
  setCPDLoading: (isCPDLoaded: boolean) => void;
  isCPDLoaded: boolean
  setCPDLoaded: (isCPDLoaded: boolean) => void;
  isCPDLoadRequired: boolean
  setCPDLoadRequired: (isCPDLoaded: boolean) => void;
  processedScormResult: any;
  setProcessedScormResult: (processedScormResult: any) => void;
}

export const CPDPanelContext = createContext<ICPDContext>({ 
    cpdList: [],
    setCpdList: () => {},
    currentCpd: 0,
    setCurrentCpd: () => {}, 
    isCPDLoading: false,
    setCPDLoading: () => {}, 
    isCPDLoaded: false,
    setCPDLoaded: () => {}, 
    isCPDLoadRequired: false,
    setCPDLoadRequired: () => {}, 
    processedScormResult: {},
    setProcessedScormResult: () => {}
});

export const CPDPanelProvider = (props: any): JSX.Element => {
  const [cpdList, setCpdList] = useState<Array<any>>([])
  const [currentCpd, setCurrentCpd] = useState<number>(0) // latest processedScormResult
  const [isCPDLoaded, setCPDLoaded] = useState<boolean>(false) 
  const [isCPDLoading, setCPDLoading] = useState<boolean>(false) 
  const [isCPDLoadRequired, setCPDLoadRequired] = useState<boolean>(false) 
  const [processedScormResult, setProcessedScormResult] = useState<number>(0) // selected CPD index

  return (
    <CPDPanelContext.Provider value={{ cpdList, setCpdList, currentCpd, setCurrentCpd, 
                                       processedScormResult, setProcessedScormResult, isCPDLoaded, setCPDLoaded,
                                       isCPDLoading, setCPDLoading, isCPDLoadRequired, setCPDLoadRequired}}>
      {props.children}
    </CPDPanelContext.Provider>
  );
};
