import React, { useContext } from "react";
import { TranslationsContext } from "../../contexts/TranslationsContext";
import { LearningModule } from "../../globalTypes";
//Components
import CPDTitle from "./CPDTitle";
import DateDisplay from "./DateDisplay";
import LessonRow from "./LessonRow";
import QuizRow from './QuizRow';
import QuicFactsRow from './QuickFactsRow'
//MUI Components
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import BrandedCard from "../../Components/BrandedCard";
//Styles
import styles from "../../styles/learningModuleList.module.css";

interface Props {
	showCPD: boolean;
	showLastPassDate: boolean;
	learningModules: null | void | Promise<any> | Array<LearningModule>;
	setSASelectedModule: (selectedModule: LearningModule) => void;
    setReviewModalOpen: (reviewModalOpen: boolean) => void;
    setRestartModalOpen: (restartModalOpen: boolean) => void; 
	logNavigation: (message: string) => void;
}

const LearningModuleList = ({ learningModules, showCPD, setSASelectedModule, setReviewModalOpen, setRestartModalOpen, logNavigation, }: Props) => {
	const {translations: { allResourcesToTranslations }} = useContext(TranslationsContext);

	if (learningModules !== null && Array.isArray(learningModules)) {
		return (
			<>
				{learningModules.map((learningModule) => {
					const { id, name, isColorRed, dueInDays, lastCompletionDate, due ,oneTimeCourseCompletion} = learningModule
					return (
					<div key={id} className="welcome">
						<h3 className={styles.moduleTitle}>{name}</h3>
						<div className={styles.moduleBar}>
							<div></div>
							<DateDisplay isColorRed={isColorRed} daysUntilDue={dueInDays} lastCompletionDate={lastCompletionDate} dueDate={due} oneTimeCourseCompletion={oneTimeCourseCompletion} />
						</div>
						<BrandedCard>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell align="left">{allResourcesToTranslations.contenttype}</TableCell>
										<CPDTitle showCPD={showCPD} />
										<TableCell align="center" style={{ width: "200px" }} className="status">
											{allResourcesToTranslations.status}
										</TableCell>
										<TableCell align="center" style={{ width: "181px" }} className="actions">
											{allResourcesToTranslations.actions}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									
									<LessonRow 
										learningModule={learningModule} 
										showCPD={showCPD} 
										logNavigation={logNavigation} 
									/>
									
									<QuizRow 
										learningModule={learningModule}
										showCPD={showCPD}
										logNavigation={logNavigation}
										setSASelectedModule={setSASelectedModule}
										setReviewModalOpen={setReviewModalOpen}
										setRestartModalOpen={setRestartModalOpen}
									/>
									<QuicFactsRow
										learningModule={learningModule}
										showCPD={showCPD}
										logNavigation={logNavigation}
									/>
								</TableBody>
							</Table>
						</BrandedCard>
						<br />
					</div>
					)}
				)}
			</>
		);
	} else {
		return null;
	}
};

export default LearningModuleList;
