import React from 'react'
import TableCell from '@material-ui/core/TableCell';

import loadingGif from '../../CustomIcons/GIFs/loading_dots_blue.gif';

interface Props {
    showCPD: boolean
    pointsAvailable: number
    isQuickFacts: boolean
    isLesson: boolean
    isCPDReady: boolean
    type: string
    id: number
}

const CPDScore = ({ showCPD, pointsAvailable, isQuickFacts, isLesson, isCPDReady, type, id }: Props) => {
    if (!showCPD) return null;
    return <TableCell align="center">{ 
            isQuickFacts || isLesson ? "" : 
            (isCPDReady ? 
                pointsAvailable.toFixed(2) : 
                <div style={{alignContent: "center"}}><img src={loadingGif} 
                                                                 id={"loading_" + `${(!!type ? type : "")}-${id}` } 
                                                                 style={{width:"40px", height: "40px"}}  /></div>) 
                                    }</TableCell>
}

export default CPDScore
