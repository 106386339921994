import axios from 'axios';

export const getByteArrayOfGiven = async (token: string, size: number) => {
    try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/userreport/GetByteArrayOfGiven`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        Count: size
      },
    });
    const data = await response.data;
    //console.log("GetByteArrayOfGiven Result IsSuccess: " + ((data !== null && data !== undefined) ? (data.isSuccess.toString() + " Length: " + ((data.byteArray !== null && data.byteArray !== undefined) ? data.byteArray.length.toString() : "null/empty") ) : "null") );
    return data;
  } catch (error: any) {
    if (error.response) {
      console.log(error.response);
    } else if (error.request) {
      console.log(error.request);
    } else if (error.message) {
      console.log(error.message);
    }
    return null;
  }
};

export default getByteArrayOfGiven
