import React, { useState, createContext } from "react";

interface ILoaderContext {
  message: string;
  setMessage: (message: string) => void;
  startLoadingTimeout: (timeoutInSec: number | null) => void;
  stopLoadingTimeout: () => void;
}

export const LoaderContext = createContext<ILoaderContext>({ 
    message: "",
    setMessage: () => {},
    startLoadingTimeout: () => {},
    stopLoadingTimeout: () => {}
});

export const LoaderProvider = (props: any): JSX.Element => {
  const [message, setMessage] = useState(""); //Default message is empty
  let timeoutFunction: any = null;

  const stopLoadingTimeout = () => {
    clearInterval(timeoutFunction)
  }

  //Set the loader timeout here 
  const startLoadingTimeout = (sec: number | null) => {
    const defaultDuration = 3600; // Increased to 60mins to accomodate long running reports
    let secsRemaining = sec !== null? sec : defaultDuration;
    timeoutFunction = setInterval(() => {
        secsRemaining--
        if (secsRemaining < 0) {
            clearInterval(timeoutFunction)
            window.location.href = "/timeout" // Redirects to timeout page
        }
    }, 1000)
  }

  return (
    <LoaderContext.Provider value={{ message, setMessage, startLoadingTimeout, stopLoadingTimeout }}>
      {props.children} 
    </LoaderContext.Provider>
  );
};
