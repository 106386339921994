//React + Typescript
import React, { useEffect, useState} from 'react';
import {  UnitTreeItem } from '../../globalTypes';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Components
import UnitFilterButtons from '../CurrentAdminReport/UnitFilterButtons'
import UnitTree from '../CurrentAdminReport/UnitTree'
//MUI Components
import TextField from '@material-ui/core/TextField';
import SearchIcon from "@material-ui/icons/Search";
import { Button, IconButton } from '@material-ui/core';
//Styles
import styles from '../../styles/currentAdminReport.module.css'

export const Units = (props: any) => {
    const {isResetClicked, givenUnits, givenFilterUnitName, setGivenFilterUnitName, untitreeTitle= "", unitTreeTitleInfo= "", selectedUnitId=""} = props;
    const [units, setUnits] = useState<Array<UnitTreeItem>>(givenUnits);
    const [interactionCount, setInteractionCount] = useState<number>(0) //Required to force update the tree
    const [filterUnitName, setFilterUnitName]  = useState(!!givenFilterUnitName ? givenFilterUnitName : "");

    useEffect(() => {
        setUnits([...givenUnits]);
    },[givenUnits])

    const updateSelectedUnit = (id: number) => {
        let updatedUnits = units
        const updateSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.unitID === id) {
                    unit.isSelected = !unit.isSelected
                } 
                updateSelected(unit.childUnitTreeItems);
            }
        }
        updateSelected(updatedUnits);
        setInteractionCount(interactionCount + 1);
        setUnits(updatedUnits);
    }

    const updateExpandedUnit = (id: number) => {
        let updatedUnits = units
        const checkForExpanded = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.unitID === id) {
                    unit.isExpanded = !unit.isExpanded
                } 
                checkForExpanded(unit.childUnitTreeItems)
            }
        }
        checkForExpanded(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits);
    }

    const selectAllChildUnits = (id: number) => {
        let updatedUnits = units
        let parentList = [id]
        const updateAllChildUnits = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (parentList.includes(unit.parentUnitID) || unit.unitID === id) {
                    parentList.push(unit.unitID)
                    unit.isSelected = true;
                }
                updateAllChildUnits(unit.childUnitTreeItems)
            }
        }
        updateAllChildUnits(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits);
    }

    const deselectAllChildUnits = (id: number) => {
        let updatedUnits = units
        let parentList = [id]
        const updateAllChildUnitsToUnselected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (parentList.includes(unit.parentUnitID) || unit.unitID === id) {
                    parentList.push(unit.unitID)
                    unit.isSelected = false;
                }
                updateAllChildUnitsToUnselected(unit.childUnitTreeItems)
            }
        }
        updateAllChildUnitsToUnselected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits);
    }

    const isAnyUnitSelected = (givenUnits: Array<UnitTreeItem> | null) => {
        let updatedUnits = givenUnits
        const checkIfAnyUnitSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return false //Break if no children
            if (unitArray.length === 0) return false;
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.isSelected) {
                    return true;
                }
                if (checkIfAnyUnitSelected(unit.childUnitTreeItems)) {
                    return true;
                }
            }
            return false;
        }
        return checkIfAnyUnitSelected(updatedUnits);
    }

    const expandAllUnits = () => {
        let updatedUnits = units
        const checkForSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                if (!unitArray[i].isHidden) {
                    const unit = unitArray[i];
                    unit.isExpanded = true;
                    checkForSelected(unit.childUnitTreeItems);
                }
            }
        }
        checkForSelected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits);
    }

    const colapseAllUnits = () => {
        let updatedUnits = units
        const checkForSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                if (!unitArray[i].isHidden) {
                    const unit = unitArray[i];
                    unit.isExpanded = false;
                    checkForSelected(unit.childUnitTreeItems);
                }
            }
        }
        checkForSelected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits);
    }

    const selectAllUnits = () => {
        let updatedUnits = units
        const setAllSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                if (!unitArray[i].isHidden) {
                    const unit = unitArray[i];
                    if (!unit.isDisabled) {
                        unit.isSelected = true;
                    }
                    setAllSelected(unit.childUnitTreeItems);
                }                
            }
        }
        setAllSelected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits);
    }

    const deselectAllUnits = () => {
        let updatedUnits = units
        const setAllDeselected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                if (!unitArray[i].isHidden) {
                    const unit = unitArray[i];
                    if (!unit.isDisabled) {
                        unit.isSelected = false;
                    }
                    setAllDeselected(unit.childUnitTreeItems);
                }
            }
        }
        setAllDeselected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits);
    }

    const isGivenItemNameMatched = (item: UnitTreeItem, givenFilterUnitName: string): boolean | undefined =>
    {
        if (item === null || item === undefined || item.name === null || item.name === undefined) {
            return false;
        }
        if (givenFilterUnitName === null || givenFilterUnitName === undefined || givenFilterUnitName.trimLeft().trimRight() === "") {
            return true;
        }
        else
        {
            if (item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase())) {
                return true;
            }
            return false;
        }
    }

    const isAnyChildItemNameMatched = (list: UnitTreeItem[] | null, givenFilterUnitName: string): boolean | undefined =>
    {
        if (list === null || list === undefined || list.length <= 0) {
            return false;
        }
        if (givenFilterUnitName === null || givenFilterUnitName === undefined || givenFilterUnitName.trimLeft().trimRight() === "") {
            return true;
        }
        else
        {
            var newList = list.filter((e) => { return (isGivenItemNameMatched(e, givenFilterUnitName) === true || isAnyChildItemNameMatched(e.childUnitTreeItems, givenFilterUnitName) === true); });
            if (newList !== null && newList !== undefined && newList.length > 0) {
                return true;
            }
            return false;
        }
    }

    const updateUnitItemBasedOnNameMatch = (item: UnitTreeItem, givenFilterUnitName: string): boolean | undefined =>
    {
        let isMatchFound = false;
        if ((givenFilterUnitName === null || givenFilterUnitName === undefined || givenFilterUnitName.trimLeft().trimRight() === "") && 
            item !== null && item !== undefined) {
            isMatchFound = true;
            item.isDisabled = !isMatchFound;
            item.isHidden = !isMatchFound;
            if (item.childUnitTreeItems !== null && item.childUnitTreeItems !== undefined && item.childUnitTreeItems.length > 0)
            {
                item.childUnitTreeItems.forEach(function (e) {            
                    let isMatchFoundSub : boolean | undefined = false;
                    isMatchFoundSub = updateUnitItemBasedOnNameMatch(e, givenFilterUnitName);
                });   
            }
        }
        else if (givenFilterUnitName !== null && givenFilterUnitName !== undefined && givenFilterUnitName.trimLeft().trimRight() !== "" && 
            item !== null && item !== undefined) {
                
            isMatchFound = (item.name !== null && item.name !== undefined && item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase()));
            if (isMatchFound) {
                item.isDisabled = false;
                item.isHidden = false;
            }
            else if (!isMatchFound && (item.childUnitTreeItems == null || item.childUnitTreeItems === undefined || item.childUnitTreeItems.length <= 0)){
                item.isDisabled = true;
                item.isHidden = true;
            }
            else if (!isMatchFound){
                let curAnyChildNameMatched = isAnyChildItemNameMatched(item.childUnitTreeItems, givenFilterUnitName);
                item.isDisabled = (curAnyChildNameMatched === true);
                item.isHidden = (curAnyChildNameMatched === false);
                isMatchFound = (curAnyChildNameMatched === true);
            }
            if (item.childUnitTreeItems !== null && item.childUnitTreeItems !== undefined && item.childUnitTreeItems.length > 0)
            {
                item.childUnitTreeItems.forEach(function (e) {            
                    let isMatchFoundSub : boolean | undefined = false;
                    isMatchFoundSub = updateUnitItemBasedOnNameMatch(e, givenFilterUnitName);
                });   
            }
        }
        return isMatchFound;
    }

    useEffect(() => {
        if (isResetClicked) {
            setFilterUnitName("");
            if (!!setGivenFilterUnitName) {
                setGivenFilterUnitName("");
            }
            deselectAllUnits();
        }
    },[isResetClicked])

    useEffect(() => {
        if (units !== null && units !== undefined && units.length > 0) {
            var newUnits = units.map(function(e) { 
                const retFlag = !(e !== null && e !== undefined && e.name !== null && e.name !== undefined && updateUnitItemBasedOnNameMatch(e, filterUnitName.toLowerCase())); 
                return e;
            });
            setUnits(newUnits);
        }
    },[filterUnitName]) 

    return (
        <>
            <div>
                <div>
                    <h4 className={styles.sectionTitle}>{untitreeTitle !== "" ? untitreeTitle : "Unit"}</h4>
                    <p className={styles.blankContent}>{unitTreeTitleInfo !== "" ? unitTreeTitleInfo : "Leave blank for all Units"}</p>
                    <div className="unitFilterButtons"><UnitFilterButtons expandAllUnits={expandAllUnits} colapseAllUnits={colapseAllUnits} selectAllUnits={selectAllUnits} deselectAllUnits={deselectAllUnits}/></div>
                </div>
                <div className={styles.inputContainer}>
                        <div className="unitFilterSearch"><TextField  className={styles.input}  label={"Unit Search"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                        value={filterUnitName} onChange={(e) => {
                                                                    setFilterUnitName(e.target.value);
                                                                    if (!!setGivenFilterUnitName) {
                                                                        setGivenFilterUnitName(e.target.value);
                                                                    }
                                                                }}
                        InputProps={{
                            endAdornment: (                            
                                <IconButton>
                                  <SearchIcon />
                                </IconButton>                            
                            )
                          }}
                        /></div>
                </div>
                <div className={` ${styles.treeContianer} unittree`}>
                    <UnitTree units={units} updateSelectedUnit={updateSelectedUnit} updateExpandedUnit={updateExpandedUnit} selectAllChildUnits={selectAllChildUnits} deselectAllChildUnits={deselectAllChildUnits} isAnyUnitSelected={isAnyUnitSelected} />
                </div>
            </div>
        </>
    )
}

export default Units
