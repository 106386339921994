import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const MyTrainingCourseIcon = () => (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 13H24" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
<path d="M11 18H32" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
<path d="M11 23H32" stroke="#fff" strokeWidth="2" strokeLinecap="round"/>
<path d="M14.6665 38.5H29.3332" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M22 31.1666V38.5" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M36.6665 5.5H7.33317C5.30813 5.5 3.6665 7.14162 3.6665 9.16667V27.5C3.6665 29.525 5.30813 31.1667 7.33317 31.1667H36.6665C38.6915 31.1667 40.3332 29.525 40.3332 27.5V9.16667C40.3332 7.14162 38.6915 5.5 36.6665 5.5Z" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
   
);

export default MyTrainingCourseIcon
