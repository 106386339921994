//React + typescript
import React, { useContext } from 'react'
//Context
import { TranslationsContext } from '../contexts/TranslationsContext';
import { CorperateThemeContext } from '../contexts/CorperateThemeContext';
//Functions
import getColor from '../functions/getColor';
import generateGradientCss from '../functions/generateGradientCss';
//Components
import CustomTooltip from './CustomTooltip';
//MUI Components
import styles from '../styles/courseIcon.module.css';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import  MyTrainingCourseIcon  from '../CustomIcons/MyTrainingCourseIcon';
import  MyTrainingCourseIconOneTimeIcon  from '../CustomIcons/MyTrainingCourseIconOneTimeIcon';

interface Props {
    iconType: string,
    IsOneTime: boolean,
    CoursePublishType:string
}

const CourseIcon = ({iconType,IsOneTime,CoursePublishType}: Props): JSX.Element => {
    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
    const {color} = useContext(CorperateThemeContext).theme
    const backgroundCSS = generateGradientCss(color);

        switch(iconType) {
            case "Course":
                return (
                    //<CustomTooltip displayText={[allResourcesToTranslations.course]}>
                    //    <div className={styles.courseIconSmall} style={{backgroundImage: backgroundCSS}}>
                    //        <SchoolRoundedIcon style={{color: getColor(color.substring(1))}}/>
                    //    </div>
                    //</CustomTooltip>
                    <CustomTooltip displayText={CoursePublishType === '' ?
                        [`${allResourcesToTranslations.course}`, `${IsOneTime ? allResourcesToTranslations.onetimeonlycourse : ""}`]
                        : [`coursepublishtype${CoursePublishType}`,
                            `${allResourcesToTranslations.coursepublishtype} ${CoursePublishType}`,
                        `${IsOneTime ? allResourcesToTranslations.onetimeonlycourse : ""}`
                        ]}               
                        
                        >
                       {IsOneTime ?                       
                        <div className={styles.courseIconSmallOneTime} style={{backgroundImage: backgroundCSS}}>                          
                            <MyTrainingCourseIconOneTimeIcon></MyTrainingCourseIconOneTimeIcon>                          
                        </div>
                        :
                        <div className={styles.courseIconSmall} style={{backgroundImage: backgroundCSS}}>                            
                            <MyTrainingCourseIcon></MyTrainingCourseIcon>                            
                        </div>
                        }
                    </CustomTooltip>
                )
            case "Policy":
                return(
                    <CustomTooltip  displayText={[allResourcesToTranslations.policy]}>
                        <div className={styles.courseIconSmall} style={{backgroundImage: backgroundCSS}}>
                            <PlaylistAddCheckIcon style={{color: getColor(color.substring(1))}}/>
                        </div>
                    </CustomTooltip>
                ) 
            default:
                return(
                    <div className={styles.courseIconSmall} style={{backgroundImage: backgroundCSS}}>
                    </div>
                )
        }
}

export default CourseIcon;