import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const MyTrainingCourseIconOneTimeIcon = () => (
    <svg width="72" height="59" viewBox="0 0 72 59" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 4C0 1.79086 1.79086 0 4 0H40L20 20L0 40V4Z" fill="#F9A847"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M19 13.5C19 17.6421 15.6421 21 11.5 21C7.35786 21 4 17.6421 4 13.5C4 9.35786 7.35786 6 11.5 6C15.6421 6 19 9.35786 19 13.5ZM12.6034 16.1004C12.2897 16.1004 12.1328 15.9464 12.1328 15.6384C12.1328 15.3418 12.2897 15.1935 12.6034 15.1935H13.5617V11.3176L12.8344 11.754C12.6975 11.8338 12.572 11.8538 12.4579 11.8139C12.3496 11.7739 12.2669 11.7026 12.2098 11.6C12.1528 11.4973 12.1357 11.3861 12.1585 11.2663C12.1813 11.1408 12.2612 11.0381 12.398 10.9583L13.5788 10.2481C13.6929 10.1797 13.8069 10.1226 13.921 10.077C14.0351 10.0257 14.1406 10 14.2376 10C14.3574 10 14.4572 10.0342 14.537 10.1027C14.6169 10.1654 14.6568 10.2709 14.6568 10.4192V15.1935H15.5295C15.8433 15.1935 16.0001 15.3418 16.0001 15.6384C16.0001 15.9464 15.8433 16.1004 15.5295 16.1004H12.6034ZM7.12834 16.0406C7.2139 16.1091 7.31942 16.1433 7.44491 16.1433C7.5761 16.1433 7.68448 16.1205 7.77004 16.0748C7.8556 16.0235 7.93546 15.9522 8.00961 15.8609L8.93365 14.6888L9.86626 15.8609C10.0146 16.0492 10.2028 16.1433 10.431 16.1433C10.5621 16.1433 10.6677 16.1062 10.7475 16.0321C10.8331 15.9579 10.8787 15.8638 10.8844 15.7497C10.8901 15.6356 10.8416 15.5187 10.739 15.3989L9.54969 13.9444L10.6449 12.6011C10.7475 12.4756 10.796 12.3558 10.7903 12.2418C10.7903 12.1277 10.7504 12.0336 10.6705 11.9594C10.5907 11.8853 10.4851 11.8482 10.3539 11.8482C10.2285 11.8482 10.1229 11.871 10.0374 11.9166C9.95182 11.9623 9.86911 12.0336 9.78925 12.1305L8.94221 13.2086L8.10372 12.1305C8.02957 12.0336 7.94686 11.9623 7.8556 11.9166C7.77004 11.871 7.66166 11.8482 7.53047 11.8482C7.40498 11.8482 7.29946 11.8853 7.2139 11.9594C7.13404 12.0279 7.09126 12.1191 7.08556 12.2332C7.07986 12.3473 7.12834 12.4671 7.23101 12.5925L8.32618 13.9444L7.14545 15.3989C7.04848 15.5244 7 15.6442 7 15.7583C7.0057 15.8723 7.04848 15.9665 7.12834 16.0406Z" fill="white"/>
    <path d="M39 28H52" stroke="#fff" stroke-width="2" stroke-linecap="round"/>
    <path d="M39 33H60" stroke="#fff" stroke-width="2" stroke-linecap="round"/>
    <path d="M39 38H60" stroke="#fff" stroke-width="2" stroke-linecap="round"/>
    <path d="M42.6665 53.5H57.3332" stroke="#fff" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
    <path d="M50 46.1666V53.5" stroke="#fff" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
    <path d="M64.6665 20.5H35.3332C33.3081 20.5 31.6665 22.1416 31.6665 24.1667V42.5C31.6665 44.525 33.3081 46.1667 35.3332 46.1667H64.6665C66.6915 46.1667 68.3332 44.525 68.3332 42.5V24.1667C68.3332 22.1416 66.6915 20.5 64.6665 20.5Z" stroke="#fff" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
    </svg>
    
   
);

export default MyTrainingCourseIconOneTimeIcon
