import axios from 'axios';
import { addError } from './AddError';

export const getUpdatedPageTranslation = async (token: string, userId: number, orgDomain: string, pageFullName: string, toLanguage: string) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/languagetranslator/GetUpdatedPageTranslation`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        FromLanguage : 'en-au',
        PageFullname: pageFullName,
        PageType: 'NewSaltReactPage',
        AddIfNotPresent: false,
        ToLanguage: toLanguage,
        NewResourceTextsToTranslateAndAdd: {
          test: "Hello"
        }
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "GetUpdatedPageTranslation.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "GetUpdatedPageTranslation.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "GetUpdatedPageTranslation.ts", 1)
    }
    return null;
  }
};

export default getUpdatedPageTranslation