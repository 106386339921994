import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const AssignedByUserIcon = () => (    
    
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.36719 9.47466C8.36719 11.8845 10.321 13.8383 12.7308 13.8383C15.1406 13.8383 17.0945 11.8845 17.0945 9.47466C17.0945 7.06484 15.1406 5.11102 12.7308 5.11102C10.321 5.11102 8.36719 7.06484 8.36719 9.47466ZM10.549 9.47466C10.549 10.6801 11.5254 11.6565 12.7308 11.6565C13.9363 11.6565 14.9126 10.6801 14.9126 9.47466C14.9126 8.2692 13.9363 7.29284 12.7308 7.29284C11.5254 7.29284 10.549 8.2692 10.549 9.47466Z" fill="#63C2FF"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.7307 0.749634C19.358 0.749634 24.7307 6.12236 24.7307 12.7496C24.7307 19.3769 19.358 24.7496 12.7307 24.7496C6.10344 24.7496 0.730713 19.3769 0.730713 12.7496C0.730713 6.12236 6.10344 0.749634 12.7307 0.749634ZM22.5489 12.7496C22.5489 15.0296 21.7711 17.1285 20.4674 18.7954C18.674 16.4467 15.8442 14.9315 12.6609 14.9315C9.51799 14.9315 6.72089 16.4075 4.92416 18.7038C3.66198 17.0522 2.91253 14.9882 2.91253 12.7496C2.91253 7.32672 7.3078 2.93145 12.7307 2.93145C18.1536 2.93145 22.5489 7.32672 22.5489 12.7496ZM12.7307 22.5678C15.0762 22.5678 17.2296 21.7452 18.9183 20.3729C17.5373 18.4016 15.2496 17.1133 12.6609 17.1133C10.1038 17.1133 7.84017 18.37 6.45362 20.2998C8.15544 21.7158 10.3438 22.5678 12.7307 22.5678Z" fill="#63C2FF"/>
    </svg>

);

export default AssignedByUserIcon
