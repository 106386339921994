import axios from "axios";
import { addError } from './AddError';

export const updateEndQuizInfo = async (
  token: string, 
  userId: number,
  orgDomain: string,
  moduleId: number,
  quizId: number,
  courseId: number,
  passmark: number,
  quizSessionId: string,
  duration: number = 46664,
  score: number,
  oldCourseStatus: number,
  newCourseStatus: number,
  newQuizStatus: number,
  quizFrequency: number,
  quizCompletionDate: Date
) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/usersessions/UpdateEndQuizInfo`,
      headers: {
        "Content-Type": "application/json",
        'Authorization' : `Bearer ${token}`
      },
      data: {
        userid: userId,
        moduleid: moduleId,
        quizSessionid: quizSessionId,
        duration: duration, // This is a hardcoded value in the original system too :'(
        score: score,
        courseId: courseId,
        quizId: quizId,
        passmark: passmark,
        OldCourseStatus: oldCourseStatus,
        NewCourseStatus: newCourseStatus,
        NewQuizStatus: newQuizStatus,
        QuizFrequency: quizFrequency,
        QuizCompletionDate: quizCompletionDate,
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      };
      addError(token, userId, orgDomain, "Rest Exception", error.response, "UpdateEndQuizInfo.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      };
      addError(token, userId, orgDomain, "Rest Exception", error.request, "UpdateEndQuizInfo.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      };
      addError(token, userId, orgDomain, "Rest Exception", error.message, "UpdateEndQuizInfo.ts", 1)
    }
    return null;
  }
};

export default updateEndQuizInfo;
