import React, { useEffect } from "react";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core';
import styles from '../styles/currentAdminReport.module.css'
import { Height } from '@material-ui/icons';
import MenuItem from "@material-ui/core/MenuItem";

export const MUICustomSelect = ({
    idName,
    labelName,
    currentValue,
    setCurrentValue,
    listOfItems,
    isFocussed,
    setIsFocussed,
    inputLabelStyle,
    isInputLabelVisible = true,
    backColorOnFocus = 'white',
    formStylePostfix = '',
    isTheListMenu = false // As menu-group or as option-group
}: any): JSX.Element => {

    const useStyles = makeStyles((theme) => ({
        selectRoot: {
            '&:focus':{
            backgroundColor: backColorOnFocus,
            }
        }
    }));

    const customClasses = useStyles();

    return (
        <FormControl variant="outlined" className={formStylePostfix === '' ? styles.formControl : `${styles.formControl} ${formStylePostfix}`}>
            {isInputLabelVisible ? <InputLabel id={idName} style={inputLabelStyle}>{labelName}</InputLabel> : <></>}
            <Select
                key= {"Select " + idName + " " + labelName}
                id={idName}
                native
                onFocus={(e) => setIsFocussed(true)}
                onMouseDown={(e) => setIsFocussed(true)}
                onMouseOut={(e) => setIsFocussed(false)}
                value={currentValue || ''}
                label={labelName}
                classes={{root: customClasses.selectRoot}} variant="outlined"
                onChange={(e) => setCurrentValue(e.target.value)}
                open={isFocussed}
                onOpen={() => {setIsFocussed(true)}}
                onClose={() => {setIsFocussed(false)}}
                >  
                    
                {!!listOfItems && listOfItems.map((item: any) => {
                    return (!!item === false || (!!item.value === false && item.value !== 0) ||  !!item.text === false) ?
                    '' :
                    <option key={"Option: " + item.value + " " + item.text} value={item.value}>{item.text}</option>
                })}
                    
            </Select>
        </FormControl>
  );
};

export default MUICustomSelect;