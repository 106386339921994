import React, { useContext } from 'react'
import { LearningModule } from '../../globalTypes'
//Context
import { TranslationsContext } from "../../contexts/TranslationsContext";
//Components
import CPDScore  from './CPDScore';
import ActionButtons from './ActionButtons';
//MUI Components
import TableCell from "@material-ui/core/TableCell";
import TableRow from '@material-ui/core/TableRow'
//Styles
import styles from "../../styles/learningModuleList.module.css";

interface Props {
    showCPD: boolean
    learningModule: LearningModule
    logNavigation: (message: string) => void;
    setSASelectedModule: (selectedModule: LearningModule) => void;
    setReviewModalOpen: (reviewModalOpen: boolean) => void;
    setRestartModalOpen: (restartModalOpen: boolean) => void; 
}

const QuizRow = ({ showCPD, learningModule, logNavigation, setSASelectedModule, setReviewModalOpen, setRestartModalOpen}: Props) => {
    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext);
    const { quizType, quizPoints, quizStatusName, profilePointsGainedByUser, isCPDReady, id } = learningModule

    const getStatusCSSClass = (status: string) => {
        switch(status) {
            case "Passed":
            return styles.passed
            case "Failed":
            return styles.failed
            case "Completed":
            return styles.passed
            default:
            return
        }
    };

    const getStatusTranslation = (status: string) => {
        switch(status) {
            case "Passed":
            return allResourcesToTranslations.passed
            case "Failed":
            return allResourcesToTranslations.failed
            case "Completed":
            return allResourcesToTranslations.completed
            case "In Progress":
            return allResourcesToTranslations.inprogress
            case "Not Started":
            return allResourcesToTranslations.notstarted
            case "Expired (Time Elapsed)":
            return allResourcesToTranslations.expiredtimeelapsed
            case "Expired (New Content)":
            return allResourcesToTranslations.expirednewcontent
            default:
            return ""
        }
    }

    const textValue = (quizType === "SA" || quizType === "Rise360" || quizType === "Storyline360" || quizType === "LectoraLQ") ? 
            allResourcesToTranslations.lessonandquiz : allResourcesToTranslations.quiz;
    return (
        <TableRow>
            <TableCell align="left">
                <span className={styles.rowTitle}>{textValue}</span>
            </TableCell>
            <CPDScore isLesson={false} showCPD={showCPD} type={quizType} id={id}
                        isCPDReady={isCPDReady} pointsAvailable={quizPoints != null && quizPoints != undefined && quizPoints >= 0 && (quizPoints - profilePointsGainedByUser) >= 0 ? (quizPoints - profilePointsGainedByUser) : 0.0} isQuickFacts={false} />
            <TableCell align="center">
                <span className={getStatusCSSClass(quizStatusName)}>{getStatusTranslation(quizStatusName)}</span>
            </TableCell>
            <TableCell align="center">
                <ActionButtons 
                    learningModule={learningModule}
                    logNavigation={logNavigation}
                    setSASelectedModule={setSASelectedModule} 
                    setReviewModalOpen={setReviewModalOpen}
                    setRestartModalOpen={setRestartModalOpen}
                />
            </TableCell>
        </TableRow>
    );
}

export default QuizRow
