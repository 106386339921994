import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const SupportDetailCancelIcon = () => (    
<svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.3257 18.75C22.7775 15.2982 22.7775 9.70178 19.3257 6.25C15.8739 2.79822 10.2775 2.79822 6.82568 6.25C3.3739 9.70178 3.3739 15.2982 6.82568 18.75C10.2775 22.2018 15.8739 22.2018 19.3257 18.75Z" stroke="white" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
<path d="M10.5752 10.0001L15.5752 15.0001" stroke="white" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
<path d="M10.5752 14.9999L15.5752 9.99994" stroke="white" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
</svg>


);

export default SupportDetailCancelIcon
