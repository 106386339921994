import React from "react";

const SingleSignOnIcon = () => {

    return (    
<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.542 11.875 30.083 6.333m3.167 -3.167 -3.167 3.167 3.167 -3.167Zm-15.216 15.216a8.708 8.708 0 1 1 -12.315 12.315 8.708 8.708 0 0 1 12.314 -12.314l0.002 -0.002Zm0 0L24.542 11.875l-6.508 6.508ZM24.542 11.875l4.75 4.75L34.833 11.083l-4.75 -4.75 -5.542 5.542Z" stroke="#21394F" stroke-width="3.1666666666666665" stroke-linecap="round" stroke-linejoin="round"/></svg>

    )
}

export default SingleSignOnIcon;