import axios from 'axios';
import { addError } from './AddError';

export const processScormRequest = async (token: string, userId: number, orgDomain: string, isLesson: boolean, sessionId: string, profileId: number, selectedProfileId: number, moduleId: number, write: boolean, dme: string, value: string, xmlData: string, currentCulture: string, isTypeLectora: boolean, uniqueID: string ) => {

  const checkLessonCompletedXML = (xmlString: string) => {
    let result = xmlString;
    const xml = new DOMParser().parseFromString(xmlString,"text/xml");
    const dmeValues = xml.getElementsByTagName('dme')
    const dmeValuesArray = Array.from(dmeValues)
    const currentPageNumber = dmeValuesArray.find(dme => dme?.firstElementChild?.innerHTML === "salt.variables.VarPageInChapter")?.children[1].innerHTML
    const maxPageNumber = dmeValuesArray.find(dme => dme?.firstElementChild?.innerHTML === "salt.variables.VarPagesInChapter")?.children[1].innerHTML

    if (currentPageNumber === maxPageNumber) {
      for (let i = 0; i < dmeValues.length; i ++) {
        if (dmeValues[i].children[0].innerHTML === "cmi.core.lesson_status") {
          dmeValues[i].children[1].innerHTML = "completed";
          result = new XMLSerializer().serializeToString(xml);
          break
        }
      }    
    }
    return result;
  }
  const xmlPostData = isLesson ? checkLessonCompletedXML(xmlData) : xmlData; 

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/usersessions/ProcessRequest`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        isLesson: isLesson,
        sessionId: sessionId,
        userId : userId,
        profileId : profileId,
        selectedProfileId : selectedProfileId,
        moduleID : moduleId,
        DME: dme,
        value: value,
        write: write,
        xmlData: xmlPostData,
        currentCulture: currentCulture,
        isTypeLectora: isTypeLectora,
        UniqueID: uniqueID
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "ProcessScormRequest.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "ProcessScormRequest.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "ProcessScormRequest.ts", 1)
    }
    return null;
  }
};

export default processScormRequest
