import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const AssignUnitsIcon = () => (    
<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M7.54224 8.37518C7.54224 10.6846 9.41465 12.557 11.7241 12.557C14.0335 12.557 15.9059 10.6846 15.9059 8.37518C15.9059 6.06577 14.0335 4.19336 11.7241 4.19336C9.41465 4.19336 7.54224 6.06577 7.54224 8.37518ZM9.63315 8.37518C9.63315 9.5304 10.5688 10.4661 11.7241 10.4661C12.8793 10.4661 13.815 9.5304 13.815 8.37518C13.815 7.21995 12.8793 6.28427 11.7241 6.28427C10.5688 6.28427 9.63315 7.21995 9.63315 8.37518Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23.2239 11.5137C23.2239 5.16254 18.075 0.0136719 11.7239 0.0136719C5.37274 0.0136719 0.223877 5.16254 0.223877 11.5137C0.223877 17.8648 5.37274 23.0137 11.7239 23.0137C12.111 23.0137 12.4937 22.9945 12.871 22.9572C12.5752 22.3198 12.3725 21.6305 12.2803 20.9066C12.0962 20.9173 11.9107 20.9228 11.7239 20.9228C9.43642 20.9228 7.33924 20.1063 5.70833 18.7493C7.03711 16.8999 9.20643 15.6955 11.657 15.6955C12.3052 15.6955 12.9337 15.7798 13.5321 15.938C13.9892 15.3008 14.5507 14.7435 15.1915 14.2911C14.1002 13.8484 12.907 13.6046 11.657 13.6046C8.64502 13.6046 5.96446 15.0191 4.2426 17.2198C3.03301 15.637 2.31479 13.6589 2.31479 11.5137C2.31479 6.31672 6.52692 2.10458 11.7239 2.10458C16.9208 2.10458 21.133 6.31672 21.133 11.5137C21.133 12.1015 21.079 12.6767 20.9758 13.2347C21.6782 13.4157 22.338 13.703 22.9368 14.0784C23.1247 13.2537 23.2239 12.3953 23.2239 11.5137Z" fill="white"/>
<path d="M19.2239 16.0137C18.5837 16.0137 18.0648 16.5686 18.0648 17.2531C18.0648 17.863 18.4767 18.37 19.0193 18.4734V19.3682H17.7921C17.4532 19.3682 17.1784 19.662 17.1784 20.0244V20.554C16.6358 20.6573 16.2239 21.1643 16.2239 21.7742C16.2239 22.4587 16.7428 23.0137 17.383 23.0137C18.0231 23.0137 18.5421 22.4587 18.5421 21.7742C18.5421 21.1643 18.1301 20.6573 17.5875 20.554V20.0244C17.5875 19.9036 17.6791 19.8056 17.7921 19.8056H20.6557C20.7687 19.8056 20.8602 19.9036 20.8602 20.0244V20.554C20.3176 20.6573 19.9057 21.1643 19.9057 21.7742C19.9057 22.4587 20.4246 23.0137 21.0648 23.0137C21.7049 23.0137 22.2239 22.4587 22.2239 21.7742C22.2239 21.1643 21.8119 20.6573 21.2693 20.554V20.0244C21.2693 19.662 20.9946 19.3682 20.6557 19.3682H19.4284V18.4734C19.971 18.37 20.383 17.863 20.383 17.2531C20.383 16.5686 19.864 16.0137 19.2239 16.0137ZM18.4739 17.2531C18.4739 16.8102 18.8097 16.4511 19.2239 16.4511C19.6381 16.4511 19.9739 16.8102 19.9739 17.2531C19.9739 17.6961 19.6381 18.0551 19.2239 18.0551C18.8097 18.0551 18.4739 17.6961 18.4739 17.2531ZM16.633 21.7742C16.633 21.3313 16.9688 20.9722 17.383 20.9722C17.7972 20.9722 18.133 21.3313 18.133 21.7742C18.133 22.2171 17.7972 22.5762 17.383 22.5762C16.9688 22.5762 16.633 22.2171 16.633 21.7742ZM21.0648 20.9722C21.479 20.9722 21.8148 21.3313 21.8148 21.7742C21.8148 22.2171 21.479 22.5762 21.0648 22.5762C20.6506 22.5762 20.3148 22.2171 20.3148 21.7742C20.3148 21.3313 20.6506 20.9722 21.0648 20.9722Z" fill="white"/>
<circle cx="19.2239" cy="20.0137" r="5.5" stroke="white"/>
</svg>

);

export default AssignUnitsIcon
