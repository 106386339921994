//React + Typescript
import React, { useEffect, useState, useContext, useRef } from 'react';
import { User, Language, UnitTreeItem, CourseTreeItem } from '../../globalTypes';
import { useHistory } from 'react-router-dom';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Steps, Hints } from "intro.js-react";
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { CPDPanelContext } from '../../contexts/CPDPanelContext';
//API Functions
import { getRelevantUnitTree } from '../../API/GetRelevantUnitTree';
import { getCourseListByOrganisation } from '../../API/GetCourseListByOrganisation'
import { addError } from '../../API/AddError'
import { getClassificationListByType } from '../../API/GetClassificationListByType';
import getClassificationType from '../../API/GetClassificationType';
//Components
import LoaderContainer from '../../Components/LoaderContainer';
import BrandedCard from '../../Components/BrandedCard'
import UnitFilterButtons from '../CurrentAdminReport/UnitFilterButtons'
import CourseFilterButtons from '../CurrentAdminReport/CourseFilterButtons'
import CourseListTree from '../CurrentAdminReport/CourseListTree'
import UnitTree from '../CurrentAdminReport/UnitTree'
import ButtonPanel from '../CurrentAdminReport/ButtonPanel'
import CustomTooltip from "../../Components/CustomTooltip";
import GuideIcon from '../../CustomIcons/GuideIcon';
//import MUICustomSelect from '../../Components/MUICustomSelect';
//MUI Components
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from "@material-ui/icons/Search";
//Styles
import styles from '../../styles/currentAdminReport.module.css'
import getFullWarningReport from '../../API/GetFullWarningReport';
import { IconButton } from '@material-ui/core';


interface Props {
    
}

const WarningReport = (props: Props) => { 
    const {translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext);
    const [units, setUnits] = useState<Array<UnitTreeItem>>([])
    const [courseList, setCourseList] = useState<Array<CourseTreeItem>>([]) 
    const [interactionCount, setInteractionCount] = useState<number>(0) //Required to force update the tree
    const [loading, setLoading] = useState<boolean>(true)
    const [isSmallUnitTree, setSmallUnitTree] = useState<boolean>(true);
    const [warningPeriod, setwarningPeriod] = useState<any>(30);
    const [dayType, setDayType] = useState<any>("dd");
    const [filterUnitName, setFilterUnitName]  = useState("");
    const [filterCourseName, setFilterCourseName]  = useState("");
    const [filterIncludeInactive, setFilterIncludeInactive] = useState<boolean>(false);

    const [isGroupingOptionFocussed, setIsGroupingOptionFocussed] = useState<boolean>(false);

    //const [classificationList, setClassificationList] = useState<Array<any>>([]);
    const [classificationList, setClassificationList] = useState([]);
    const [selectedClassification, setSelectedClassification] = useState<any>({});
    const [groupingOption, setGroupingOption] = useState<boolean>(false);
    const { setMessage } = useContext(LoaderContext);
    const { setTitle, setTitleEnglish, updateBackButtonRoute, EnableActivityLogs } = useContext(UIContext);
    const { oidcUser } = useReactOidc();
    const { profile: { user_id, website }} = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    //const orgId = oidcUser. ?? -1;
    const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const history = useHistory();

    const [intro, setIntro] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: ".unitFilterButtons",
            title: "Units", intro: allResourcesToTranslations.emrepgmunitsm,    
          },      
        ]
      })

const setGuideMe = (groupingOption: boolean) => {
        let updateSteps = [...intro.steps]
                    updateSteps.push(       
                        {
                            element: ".unitFilterSearch",
                            title: "Filter Units",
                            intro: allResourcesToTranslations.unitfiltersearch                  
                        }
                    );   
                    if (units.length <= 10) {
                    updateSteps.push(      
                        {
                        element: ".courseFilterButtons",                   
                        title: "Courses", intro: allResourcesToTranslations.emrepgmcsm
                        },
                        {
                            element: ".courseFilterSearch",
                            title: "Filter Courses",
                            intro: allResourcesToTranslations.emrepgmfcsm
                        }
                    ); 
                    }
                else{
                        updateSteps.push(      
                        {
                            element: ".courseFilterButtonsnew",
                            title: "Courses",
                            intro: allResourcesToTranslations.emrepgmcsm
                        },
                        {
                            element: ".courseFilterSearchnew",
                            title: "Filter Courses",
                            intro: allResourcesToTranslations.emrepgmfcsm
                        }
                        ); 
                    }
                    updateSteps.push(    
                        {
                        element:  ".expiredIn",
                        title: "Expired in value", 
                        intro: allResourcesToTranslations.wrrptgmexval
                    });
                    if (groupingOption)
                    {
                        updateSteps.push({
                            element: ".groupby",
                            title: "Grouping Option", intro: allResourcesToTranslations.repgmgroupingoption
                        });
                    }
                    updateSteps.push( 
                    {
                        element:  ".resetAll",
                        title: "Reset", intro: allResourcesToTranslations.emrepgmram
                    },
                    {
                        element:  ".scheduleReport",
                        title: "Schedule report", intro: allResourcesToTranslations.repgmschedulereport
                    },
                    {
                        element:  ".runReport",
                        title: "Run report", intro: allResourcesToTranslations.sntemrepgmmrunrep
                    }          
                )
                setIntro({...intro, steps: [...updateSteps]})              
};
  
      const onExit = () => {
        setIntro({...intro, stepsEnabled: false });
      };
      

    const updateSelectedUnit = (id: number) => {
        let updatedUnits = units
        const updateSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.unitID === id) {
                    unit.isSelected = !unit.isSelected
                } 
                updateSelected(unit.childUnitTreeItems)
            }
        }
        updateSelected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const updateExpandedUnit = (id: number) => {
        let updatedUnits = units
        const checkForExpanded = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.unitID === id) {
                    unit.isExpanded = !unit.isExpanded
                } 
                checkForExpanded(unit.childUnitTreeItems)
            }
        }
        checkForExpanded(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const selectAllChildUnits = (id: number) => {
        let updatedUnits = units
        let parentList = [id]
        const updateAllChildUnits = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (parentList.includes(unit.parentUnitID) || unit.unitID === id) {
                    parentList.push(unit.unitID)
                    unit.isSelected = true;
                }
                updateAllChildUnits(unit.childUnitTreeItems)
            }
        }
        updateAllChildUnits(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const deselectAllChildUnits = (id: number) => {
        let updatedUnits = units
        let parentList = [id]
        const updateAllChildUnitsToUnselected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (parentList.includes(unit.parentUnitID) || unit.unitID === id) {
                    parentList.push(unit.unitID)
                    unit.isSelected = false;
                }
                updateAllChildUnitsToUnselected(unit.childUnitTreeItems)
            }
        }
        updateAllChildUnitsToUnselected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const isAnyUnitSelected = (givenUnits: Array<UnitTreeItem> | null) => {
        let updatedUnits = givenUnits
        const checkIfAnyUnitSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return false //Break if no children
            if (unitArray.length === 0) return false;
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.isSelected) {
                    return true;
                }
                if (checkIfAnyUnitSelected(unit.childUnitTreeItems)) {
                    return true;
                }
            }
            return false;
        }
        return checkIfAnyUnitSelected(updatedUnits);
    }

    const expandAllUnits = () => {
        let updatedUnits = units
        const checkForSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                if (!unitArray[i].isHidden) {
                    const unit = unitArray[i];
                    unit.isExpanded = true;
                    checkForSelected(unit.childUnitTreeItems);
                }
            }
        }
        checkForSelected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const colapseAllUnits = () => {
        let updatedUnits = units
        const checkForSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                if (!unitArray[i].isHidden) {
                    const unit = unitArray[i];
                    if (unit.isExpanded) unit.isExpanded = false;
                    checkForSelected(unit.childUnitTreeItems);
                }
            }
        }
        checkForSelected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const selectAllUnits = () => {
        let updatedUnits = units
        const setAllSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                if (!unitArray[i].isHidden) {
                    const unit = unitArray[i];
          	        if (!unit.isDisabled) {
              		    unit.isSelected = true;
	  	            }
                    setAllSelected(unit.childUnitTreeItems);
                }
            }
        }
        setAllSelected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const deselectAllUnits = () => {
        let updatedUnits = units
        const setAllDeselected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                if (!unitArray[i].isHidden) {
                    const unit = unitArray[i];
                    if (!unit.isDisabled) {
                        unit.isSelected = false;
                    }
                    setAllDeselected(unit.childUnitTreeItems);
                }
            }
        }
        setAllDeselected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const updateSelectedCourse = (id: number) => {
        let updatedCourses = courseList 
        for (let i = 0; i < updatedCourses.length; i++) {
            if (updatedCourses[i].courseID === id) {
                updatedCourses[i].isSelected = !updatedCourses[i].isSelected;
            }
        }
        setInteractionCount(interactionCount + 1)
        setCourseList(updatedCourses)
    }

    const selectAllCourses = () => {
        let updatedCourses = courseList 
        for (let i = 0; i < updatedCourses.length; i++) {
            if (!updatedCourses[i].isHidden) {
                updatedCourses[i].isSelected = true;
            }
        }
        setInteractionCount(interactionCount + 1)
        setCourseList(updatedCourses)
    }

    const deselectAllCourses = () => {
        let updatedCourses = courseList 
        for (let i = 0; i < updatedCourses.length; i++) {
            if (!updatedCourses[i].isHidden) {
                updatedCourses[i].isSelected = false;
            }
        }
        setInteractionCount(interactionCount + 1)
        setCourseList(updatedCourses)
    }

    const resetAll = () => {
        deselectAllCourses();
        deselectAllUnits();
        setSelectedClassification({value:0, classificationID: 0});
        setDayType("dd");
        setwarningPeriod(30);
        setFilterIncludeInactive(false);
    }

    const getValidClassificationID = (selectedClassification: any) => {
        var classID : number = 0;
        if ((!!selectedClassification) && (!!selectedClassification.classificationID)) {
            classID = ((typeof selectedClassification.classificationID) !== 'number') ? 
                parseInt(selectedClassification.classificationID.toString()) : 
                selectedClassification.classificationID;
        }
        return classID;
    }

    const runReport = () => {
        let unitIds: Array<number> = []
        let courseIds: Array<number> = []
        let allUnitIds: Array<number> = [];
        let allCourseIds: Array<number> = [];
        const date = new Date()
        const nextDate = new Date()
        nextDate.setDate(nextDate.getDate()+1);
        const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) { 
                    if (filterUnitName !== "") {
                        if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
                        unitIds.push(unit.unitID)
                        }
                    } else {
                        unitIds.push(unit.unitID)
                    }
                } 
                allUnitIds.push(unit.unitID);
                getSelectedUnitIds(unit.childUnitTreeItems)
            }
        }
        getSelectedUnitIds(units)

        const getSelectedCourses = () => {
            for (let i = 0; i < courseList.length; i++) {
                if (courseList[i].isSelected) {
                    courseIds.push(courseList[i].courseID)
                }
                allCourseIds.push(courseList[i].courseID);
            }
        }
        getSelectedCourses()
        setMessage("Fetching your report");
        setLoading(true)

        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started CourseStatus Report in NEWSALT ClientApp", null, "CourseStatusReport.tsx", 6, EnableActivityLogs);
        getFullWarningReport(oidcUser.access_token, 
            userId, 
            orgDomain, 
            parseInt(orgId), 
            unitIds.length > 0 ? unitIds.toString() : allUnitIds.toString(), 
            courseIds.length > 0 ? courseIds.toString() : allCourseIds.toString(), 
            !!warningPeriod ? parseInt(warningPeriod.toString().trim()) : 30, 
            dayType, getValidClassificationID(selectedClassification),filterIncludeInactive).then((result) => {
            setLoading(false);
            history.push({
                pathname: `warningreport/result`,
                state: {        
                    inputFilterIncludeInactive: (filterIncludeInactive ? "Yes" : "No"),               
                    resultItems: (result === null ? null : result.warningreportItem),
                    resultError: ((result === null || result === undefined || result.isSuccess || result.error === null || result.error === undefined) ? "" : result.error)
                },
            });
        });
       
    }


    const setModalOpen = () => 
    {
        history.push({
            pathname: `/WR/reportscheduler/-1`,
            state: {
                selectedUnits: units,
                selectedCourses: courseList,
                isIncludeInactive: filterIncludeInactive,
                classificationList: classificationList,
                groupingOption: groupingOption,
                warningPeriod: !!warningPeriod ? parseInt(warningPeriod.toString().trim()) : 30, 
                dayType: dayType,
                selectedClassification: selectedClassification,
                groupBy: ((selectedClassification === null || selectedClassification === undefined || 
                    selectedClassification.value === null || selectedClassification.value === undefined) ? "" : selectedClassification.value),
                selectedClassificationID: getValidClassificationID(selectedClassification),            
                reportId: 29,  // 29 is for Warning Report
            },
        }); 
    }

    const isGivenItemNameMatched = (item: UnitTreeItem, givenFilterUnitName: string): boolean | undefined =>
    {
        if (item === null || item === undefined || item.name === null || item.name === undefined) {
            return false;
        }
        if (givenFilterUnitName === null || givenFilterUnitName === undefined || givenFilterUnitName.trimLeft().trimRight() === "") {
            return true;
        }
        else
        {
            if (item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase())) {
                return true;
            }
            return false;
        }
    }

    const isAnyChildItemNameMatched = (list: UnitTreeItem[] | null, givenFilterUnitName: string): boolean | undefined =>
    {
        if (list === null || list === undefined || list.length <= 0) {
            return false;
        }
        if (givenFilterUnitName === null || givenFilterUnitName === undefined || givenFilterUnitName.trimLeft().trimRight() === "") {
            return true;
        }
        else
        {
            var newList = list.filter((e) => { return (isGivenItemNameMatched(e, givenFilterUnitName) === true || isAnyChildItemNameMatched(e.childUnitTreeItems, givenFilterUnitName) === true); });
            if (newList !== null && newList !== undefined && newList.length > 0) {
                return true;
            }
            return false;
        }
    }

    const updateUnitItemBasedOnNameMatch = (item: UnitTreeItem, givenFilterUnitName: string): boolean | undefined =>
    {
        let isMatchFound = false;
        if ((givenFilterUnitName === null || givenFilterUnitName === undefined || givenFilterUnitName.trimLeft().trimRight() === "") && 
            item !== null && item !== undefined) {
            isMatchFound = true;
            item.isDisabled = !isMatchFound;
            item.isHidden = !isMatchFound;
            if (item.childUnitTreeItems !== null && item.childUnitTreeItems !== undefined && item.childUnitTreeItems.length > 0)
            {
                item.childUnitTreeItems.forEach(function (e) {            
                    let isMatchFoundSub : boolean | undefined = false;
                    isMatchFoundSub = updateUnitItemBasedOnNameMatch(e, givenFilterUnitName);
                });   
            }
        }
        else if (givenFilterUnitName !== null && givenFilterUnitName !== undefined && givenFilterUnitName.trimLeft().trimRight() !== "" && 
            item !== null && item !== undefined) {
                
            isMatchFound = (item.name !== null && item.name !== undefined && item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase()));
            if (isMatchFound) {
                item.isDisabled = false;
                item.isHidden = false;
            }
            else if (!isMatchFound && (item.childUnitTreeItems == null || item.childUnitTreeItems === undefined || item.childUnitTreeItems.length <= 0)){
                item.isDisabled = true;
                item.isHidden = true;
            }
            else if (!isMatchFound){
                let curAnyChildNameMatched = isAnyChildItemNameMatched(item.childUnitTreeItems, givenFilterUnitName);
                item.isDisabled = (curAnyChildNameMatched === true);
                item.isHidden = (curAnyChildNameMatched === false);
                isMatchFound = (curAnyChildNameMatched === true);
            }
            if (item.childUnitTreeItems !== null && item.childUnitTreeItems !== undefined && item.childUnitTreeItems.length > 0)
            {
                item.childUnitTreeItems.forEach(function (e) {            
                    let isMatchFoundSub : boolean | undefined = false;
                    isMatchFoundSub = updateUnitItemBasedOnNameMatch(e, givenFilterUnitName);
                });   
            }
        }
        return isMatchFound;
    }

    useEffect(() => {
        setTitle(allResourcesToTranslations.warningreport)
        setTitleEnglish(pageTitles.warningreport)
        updateBackButtonRoute("");
        //getUnitTree(oidcUser.access_token, userId, orgDomain, true, "A", parseInt(orgId)).then((unitTreeRes) => {
        getRelevantUnitTree(oidcUser.access_token, userId, orgDomain, true, parseInt(orgId)).then((unitTreeRes) => {
            if (unitTreeRes.isSuccess) {
                setUnits(unitTreeRes.unitTreeItems)
                setSmallUnitTree(unitTreeRes.unitTreeItems === null || unitTreeRes.unitTreeItems.length <= 10);
            }
            getCourseListByOrganisation(oidcUser.access_token, userId, orgDomain, parseInt(orgId), true).then((courseListRes) => {
                if (courseListRes.isSuccess) {
                    setCourseList(courseListRes.courselistItem)
                }
                
                getClassificationType(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((classType) => {
                   
                    if(classType !== null && classType.classificationTypeIDs !== null && 
                       classType.classificationTypeIDs.length > 0 && classType.classificationTypeIDs[0] > 0) {
                        getClassificationListByType(oidcUser.access_token, userId, orgDomain, parseInt(orgId), classType.classificationTypeIDs[0]).then((data) => {
                            
                            if (data !== null && data.classificationlistItem !== null) {
                                setClassificationList(data.classificationlistItem)
                                setGroupingOption(true)
                                units.length = unitTreeRes.unitTreeItems.length;
                                setGuideMe(true);
                            }
                            else {
                                setGroupingOption(false);
                            }
                        })
                    }
                    else {
                        setGroupingOption(false);
                    }
                    setLoading(false);
                    units.length = unitTreeRes.unitTreeItems.length;
                    setGuideMe(groupingOption);
                })                               
            })
        })
    },[]) 

    useEffect(() => {
           if (units !== null && units !== undefined && units.length > 0) {
            var newUnits = units.map(function(e) {  
                  const retFlag = !(e !== null && e !== undefined && e.name !== null && e.name !== undefined && updateUnitItemBasedOnNameMatch(e, filterUnitName.toLowerCase())); 
                return e;
            });
            setUnits(newUnits);
        }
    },[filterUnitName]) 

    useEffect(() => {
          if (courseList !== null && courseList !== undefined && courseList.length > 0) {
            var newCourses = courseList.map(function(e) { 
                if (filterCourseName === null || filterCourseName === undefined || filterCourseName.trimRight().trimLeft() === ""){
                    e.isHidden = false; 
                }
                else {
                    e.isHidden = !(e !== null && e !== undefined && e.name !== null && e.name !== undefined && e.name.toLowerCase().includes(filterCourseName.toLowerCase())); 
                }
                return e;
            });
            setCourseList(newCourses);
        }
    },[filterCourseName]) 

    if (loading) return <LoaderContainer/>

    return (
        <>
          <Steps enabled={intro.stepsEnabled} steps={intro.steps} initialStep={intro.initialStep} onExit={onExit}
            options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
            }} />      
            <CustomTooltip displayText={["Guide Me"]}>               
                <span onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
                        <GuideIcon />
                    </span>
            </CustomTooltip>     
            <BrandedCard>                     
                <div className={styles.reportWrraper}>
                <div className={styles.unittreesection}>
                        <div>
                            <h4 className={styles.sectionTitle}>Unit</h4>
                            <p className={styles.blankContent}>Leave blank for all Units</p>
                            <div className="unitFilterButtons"><UnitFilterButtons expandAllUnits={expandAllUnits} colapseAllUnits={colapseAllUnits} selectAllUnits={selectAllUnits} deselectAllUnits={deselectAllUnits}/></div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="unitFilterSearch"><TextField  className={styles.input}  label={"Unit Search"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                            value={filterUnitName} onChange={(e) => setFilterUnitName(e.target.value)} 
                            InputProps={{endAdornment: ( <IconButton><SearchIcon /></IconButton> )}}/></div>
                        </div>
                        <div className={styles.treeContianer}>
                            <UnitTree units={units} updateSelectedUnit={updateSelectedUnit} updateExpandedUnit={updateExpandedUnit} selectAllChildUnits={selectAllChildUnits} deselectAllChildUnits={deselectAllChildUnits} isAnyUnitSelected={isAnyUnitSelected} />
                        </div>
                         <div hidden={isSmallUnitTree === false}>
                            <h4 className={styles.sectionTitle}>Course</h4>
                            <p className={styles.blankContent}>Leave blank for all Courses</p>
                            <div className="courseFilterButtons"><CourseFilterButtons selectAllCourses={selectAllCourses} deselectAllCourses={deselectAllCourses}/></div>
                        </div>
                        <div className={styles.inputContainer} hidden={isSmallUnitTree === false}>
                            <div className="courseFilterSearch"> <TextField  className={styles.input}  label={"Course Search"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}           
                            value={filterCourseName} onChange={(e) => setFilterCourseName(e.target.value)}
                            InputProps={{endAdornment: ( <IconButton><SearchIcon /></IconButton> )}}/></div>
                        </div>
                        <div className={styles.courseTreeContianer} hidden={isSmallUnitTree === false}>                        
                            <CourseListTree courseList={courseList} updateSelectedCourse={updateSelectedCourse} uniquePrefix="chkNewSaltCSR_" />
                        </div> 
                </div>
                
                <div className={styles.coursetreesection}>
                        <div hidden={isSmallUnitTree === true}>
                            <h4 className={styles.sectionTitle}>Course</h4>
                            <p className={styles.blankContent}>Leave blank for all Courses</p>
                            <div className="courseFilterButtonsnew"> <CourseFilterButtons selectAllCourses={selectAllCourses} deselectAllCourses={deselectAllCourses}/></div>
                        </div>
                        <div className={styles.inputContainer} hidden={isSmallUnitTree === true}>
                            <div className="courseFilterSearchnew"><TextField  className={styles.input}  label={"Course Search"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                            value={filterCourseName} onChange={(e) => setFilterCourseName(e.target.value)}
                            InputProps={{endAdornment: ( <IconButton><SearchIcon /></IconButton> )}}/></div>
                        </div>                       
                        <div className={styles.courseTreeContianer} hidden={isSmallUnitTree === true}>
                            <CourseListTree courseList={courseList} updateSelectedCourse={updateSelectedCourse} uniquePrefix="chkNewSaltCSR_" />
                        </div>
                        <div className="expiredIn" style={{textAlign:'left', height:'65px'}}>
                            <div className={`${styles.daysField}`} style={{textAlign:'left'}} >
                            <FormControl variant="outlined" style={{width:'48%', minWidth:'48.5%', textAlign:'initial'}}>
                            <TextField  className={styles.expiredIn} label={"Expired in"} variant="outlined" autoComplete="off" InputLabelProps={{shrink: true,}}                      
                                            value={!!warningPeriod ? parseInt(warningPeriod.toString().trim()) : 30} onChange={(e) => setwarningPeriod(e.target.value)}/>
                            </FormControl>
                            <FormControl variant="outlined" style={{width:'49%',minWidth:'49%', textAlign:'initial', marginTop:'10px', marginLeft:'12px'}}>
                                <Select
                                            value={dayType}
                                            name="daysType"
                                            style={{ backgroundColor: "white" }}
                                            onChange={(e) => setDayType(e.target.value)}
                                        >
                                            <MenuItem value={"dd"}>Day/s</MenuItem>
                                            <MenuItem value={"mm"}>Month/s</MenuItem>
                                        </Select>
                                </FormControl>
                            </div> 
                        </div>
                        {groupingOption && 
                          <div className="groupby">
                            <div className={styles.groupingOption}>
                                <FormControl variant="outlined" className={styles.formControl}>
                        
                                    <InputLabel id="grouping">Grouping Option</InputLabel>
                                        <Select
                                        value={selectedClassification.value}
                                        native
                                        onChange={(e) => setSelectedClassification({value: e.target.value, classificationID: e.target.value})}
                                        className={styles.input}
                                        variant="outlined" 
                                        label="Grouping Option"
                                        id="grouping"
                                        >
                                        {classificationList.map((item:any) => {
                                            return <option value={item.value}>{item.text}</option>
                                        })}
                                    </Select>
                                </FormControl> 
                                {/* <MUICustomSelect idName={"groupingOption"} labelName={"Grouping Option"}
                                    currentValue={selectedClassification.value} 
                                    setCurrentValue={(e : any) => 
                                    {
                                        if (!!e.target) {
                                            setSelectedClassification({ value: e.target.value, classificationID: e.target.value, 
                                                text: (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].text });
                                        }
                                        else {
                                            let filtered = !!classificationList ? classificationList.filter((cur : any) => !!cur && 
                                                            parseInt(cur.classificationID) === parseInt(e)) : null;
                                            if (!!filtered && filtered.length > 0){                                                                
                                                setSelectedClassification({ value: filtered[0].value, classificationID: filtered[0].value, 
                                                    text: filtered[0].text });
                                            }
                                        }
                                    } }
                                    isFocussed={isGroupingOptionFocussed} setIsFocussed={setIsGroupingOptionFocussed}
                                    inputLabelStyle={{}} listOfItems={classificationList}/>*/}
                            </div>
                           </div>
                        }
                        {/* <div className="includeInactive">
                            <div>
                                    <Checkbox
                                        size="small"
                                        color="primary"
                                        checked={filterIncludeInactive}
                                        className={styles.checkBox}
                                        onClick={() => setFilterIncludeInactive(!filterIncludeInactive)}
                                /> {"Include Inactive"}  
                            </div> 
                        </div> */}

                        <div>
                            <ButtonPanel openReportSchedulerModal={() => setModalOpen()} showScheduler runReport={runReport}  resetAll={resetAll}/>
                            
                        </div>
                    </div>    
                </div>
            </BrandedCard>
        </>
    )
}

export default WarningReport
