import React, { useContext, useEffect } from 'react'
import { LoaderContext } from '../contexts/LoaderContext';
import { TranslationsContext } from '../contexts/TranslationsContext';

export const LoaderMessage = () => {
    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
    const { message, setMessage, startLoadingTimeout, stopLoadingTimeout } = useContext(LoaderContext);
    useEffect(() => {
        startLoadingTimeout(null)//Overide default loading timeout here
      return () => {
        stopLoadingTimeout() // Stops the timeout 
        setMessage(allResourcesToTranslations.loading) //Defaults loading message to "Loading" when component is dismounted
      } 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return <span>{message}</span>;
}

export default LoaderMessage;
