import React, { useState, useContext} from 'react';
import { Link } from 'react-router-dom'
import ErrorPageImage from './ErrorPageImage'
import { TranslationsContext } from '../../contexts/TranslationsContext';
import Button from '@material-ui/core/Button'
import style from '../../styles/notFound.module.css'

interface Props {
    errorMessage: string | null
}

export const ErrorPage = ({errorMessage}:Props) => {
    const messageToDisplay = !errorMessage? "Opps, something went wrong" : errorMessage
    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)

    const openFeedbackTab = () => {
        //window.open("https://forms.office.com/r/jcwZ8Jrc0s")
        //window.open("https://forms.office.com/Pages/ResponsePage.aspx?id=Xmy4xx8Tu0yZEuy62r_ho5wshv5DxxFNpYSXqqkepxJUM0RNWVYwOFRNQ08yUTlTNkk2Wk9aUFlKRC4u")
        //window.open("https://forms.office.com/r/7xAimSd5q6");
        window.open("https://forms.office.com/r/7xAimSd5q6", "_blank")
    }

    return (
        <div className={style.parentContainer}>
                <ErrorPageImage/>
                <span>{messageToDisplay}</span>
                <div>
                    <Link to="/">
                        <Button variant="contained" color="primary">
                            {allResourcesToTranslations.takemehome}
                        </Button>
                    </Link>
                    <Button 
                        onClick={ openFeedbackTab } 
                        variant="contained" 
                        color="primary"
                        >
                            {allResourcesToTranslations.feedback}
                    </Button> 
                </div>
        </div>
    )
}

export default ErrorPage;