//React + Typescript
import React, { useEffect, useState, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { User, Language } from '../../globalTypes';
// Libs
import { useReactOidc, getUserManager } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { LoaderContext } from '../../contexts/LoaderContext';
//API Functions
import { getUser } from '../../API/GetUser';
import { addError } from '../../API/AddError'
import { editUser } from '../../API/EditUser';
import { getAllPrimaryLanguages } from '../../API/GetAllPrimaryLanguages';
import { getUpdatedPageTranslation } from '../../API/GetUpdatedPageTranslation';
//General Functions
import validateEmail from '../../functions/valudateEmail';
//Intro Lib
import { Steps, Hints } from "intro.js-react";
//Components
import NameDisplay from './NameDisplay'
import LanguageOptions from './LanguageOptions'
import CustomTooltip from "../../Components/CustomTooltip";
import LoaderContainer from '../../Components/LoaderContainer';
import Modal from '../../Components/Modal';
//MUI Components
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import LockIcon from '@material-ui/icons/Lock';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import CachedIcon from '@material-ui/icons/Cached';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import GuideIcon from '../../CustomIcons/GuideIcon';
import ProceedIcon from '../../CustomIcons/ProceedIcon';
import CloseIcon from '../../CustomIcons/CloseIcon';
//Styles
import styles from "../../styles/accountSettings.module.css";
import PasswordChecklist, { RuleNames } from 'react-password-checklist'
import { getOrgDomainConfig } from '../../API/GetOrgDomainConfig';


export const AccountSettings = () => {
  const history = useHistory();
  const { color } = useContext(CorperateThemeContext).theme;
  const { setMessage } = useContext(LoaderContext)
  const { translations: { allResourcesToTranslations }, pageTitles, currentLanguage, updateSiteLanguage } = useContext(TranslationsContext)
  const { setMenuSelection, setTitle, setTitleEnglish, setDisplayName, setIsAccSettingsChangedAndNotSaved, IsLoggingOut, setIsLoggingOut, EnableActivityLogs, disablePasswordField, superAdminSelectedBasicOrgData } = useContext(UIContext);
  const [userData, setUserData] = useState<null | User>(null);
  const [languages, setLanguages] = useState<null | Array<Language>>(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setModalOpen] = useState<boolean>(false);
  const [previousLocation, setPreviousLocation] = useState<string>('');
  const [goBack, setGoBack] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [savedMessage, setSavedMessage] = useState<string>("")
  const [savedMessageFlash, setSavedMessageFlash] = useState<boolean>(false)
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState<null | string>(null);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState<null | string>(null);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState<null | string>(null);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<null | string>(null);
  const [swapFirstLastNames, setSwapFirstLastNames] = useState<boolean>(true)
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [confirmPassword, setConfirmPassword] = useState<string>("")
  const [language, setLanguage] = useState<string>(currentLanguage);
  const [userDetails, setUserDetails] = useState<any>({
    firstName: '',
    lastName: '',
    email: '',
    language: currentLanguage
  })
  const { oidcUser, logout } = useReactOidc();
  const { profile: { user_id, website } } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser?.profile.profile ? parseInt(oidcUser.profile.profile) : -1;
  const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";

  const [enablePasswordRule, setEnablePasswordRule] = useState<boolean>(false)
  const [IsPasswordRuleCapital, setIsPasswordRuleCapital] = useState<boolean>(false)
  const [IsPasswordRuleNumber, setIsPasswordRuleNumbere] = useState<boolean>(false)
  const [IsPasswordRuleSpecialChar, setIsPasswordRuleSpecialChar] = useState<boolean>(false)
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".userName",
        title: "Account Setting", intro: `Please contact your Organisation\`s Administrator to change your Username.`
      },
      {
        element: ".firstName",
        title: "Account Setting", intro: "You may update your own First Name."
      },
      {
        element: ".lastName",
        title: "Account Setting", intro: "You may update your own Last Name."
      },
      {
        element: ".swap",
        title: "Account Setting", intro: "Select this button to rotate your First and Last Names on your Course Completion Certificate."
      },
      {
        element: ".email",
        title: "Account Setting", intro: "You may update your Email Address here."
      }
    ]
  })
	useEffect(() => {
		let updateSteps = [...intro.steps];
		if (!disablePasswordField) {
			updateSteps.push(
        {
          element: ".password",
          title: "Account Setting", intro: `Please enter a new password to set a new one.
          <ul>
          <li>Maximum Allowed Total Characters - 16</li>
          <li>Minimum Required Total Characters - 8</li>
          <li>Minimum Required Special-Characters - 1</li>
          <li>Minimum Required Capital-Letters - 1</li>
          <li>Minimum Required Number-Digits - 1</li>
          </ul>
          `
        },
        {
          element: ".confirmPassword",
          title: "Confirm Password", intro: ` Repeat the Password in the Confirm New Password box.
          <ul>
          <li>Maximum Allowed Total Characters - 16</li>
          <li>Minimum Required Total Characters - 8</li>
          <li>Minimum Required Special-Characters - 1</li>
          <li>Minimum Required Capital-Letters - 1</li>
          <li>Minimum Required Number-Digits - 1</li>
          </ul>
          `
        });			
		}
		updateSteps.push({
      element: ".save",
      title: "Save", intro: "Select the blue Save button to apply any changes on this page."
    }
		);
		setIntro({ ...intro, steps: [...updateSteps] });
	}, []);

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };
  const resetErrorMessages = () => {
    setPasswordError(false);
    setPasswordErrorMessage(null);
    setEmailError(false);
    setEmailErrorMessage(null);
    setFirstNameError(false);
    setFirstNameErrorMessage(null);
    setLastNameError(false);
    setLastNameErrorMessage(null);
  };

  const handleSwapFirstNames = () => {
    const updatedValue = !swapFirstLastNames
    setSwapFirstLastNames(updatedValue)
  }

  const handleUpdateFirstName = (event: any) => {
    setFirstName(event.target.value)
  }

  const handleUpdateLastName = (event: any) => {
    setLastName(event.target.value)
  }

  const handleEmail = (event: any) => {
    setEmail(event.target.value)
  }

  const handlePassword = (event: any) => {
    setPassword(event.target.value)
    validatePassword();
  }
  const handleConfirmPassword = (event: any) => {
    setConfirmPassword(event.target.value)
    validatePassword();
  }

  // booleans for password validations
  const [containsUL, setContainsUL] = useState(false) // uppercase letter
  const [containsLL, setContainsLL] = useState(false) // lowercase letter
  const [containsN, setContainsN] = useState(false) // number
  const [containsSC, setContainsSC] = useState(false) // special character
  const [contains8C, setContains8C] = useState(false) // min 8 characters
  const [contains16C, setContains16C] = useState(false) // max 16 characters
  const [passwordMatch, setPasswordMatch] = useState(false) // passwords match

  const getRulesArray = () => {
    var arr: RuleNames[] = ["minLength", "maxLength", "match", "capital", "number", "specialChar"]
    if (!IsPasswordRuleCapital) {
      const index = arr.indexOf("capital");
      if (index > -1) {
        arr.splice(index, 1);
      }
    }
    if (!IsPasswordRuleNumber) {
      const index = arr.indexOf("number");
      if (index > -1) {
        arr.splice(index, 1);
      }
    }
    if (!IsPasswordRuleSpecialChar) {
      const index = arr.indexOf("specialChar");
      if (index > -1) {
        arr.splice(index, 1);
      }
    }
    return arr;
  };

  const validatePassword = () => {
    if (!enablePasswordRule) {
      return
    }
    if (password === "" && confirmPassword === "") {
      setIsPasswordValid(true);
      return
    }

    setIsPasswordValid(false);

    var allValid = true;
    // has uppercase letter
    if (password.toLowerCase() != password) {
      setContainsUL(true)
    }
    else {
      setContainsUL(false)
      allValid = false
    }

    // has lowercase letter
    // if (password.toUpperCase() != password) setContainsLL(true)
    // else setContainsLL(false)

    // has number
    if (/[0-9]/g.test(password)) {
      setContainsN(true)
    }
    else {
      setContainsN(false)
      allValid = false
    }

    // has special character    
    if (/[^0-9A-Za-z]/g.test(password)) {
      setContainsSC(true)
    }
    else {
      setContainsSC(false)
      allValid = false
    }

    // has 8 characters
    if (password.length >= 8) {
      setContains8C(true)
    }
    else {
      setContains8C(false)
      allValid = false
    }

    // has <16   characters
    if (password.length >= 8 && password.length <= 16, EnableActivityLogs) {
      setContains16C(true)
    }
    else {
      setContains16C(false)
      allValid = false
    }

    // passwords match
    if (password !== "" && password === confirmPassword) {
      setPasswordMatch(true)
    }
    else {
      setPasswordMatch(false)
      allValid = false
    }

    // all validations passed
    setIsPasswordValid(allValid && passwordMatch)
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsSaving(true)
    setSaveClicked(true)
    let error = false;
    let passwordUpdate = false

    resetErrorMessages();
    const [firstName, lastName, email, password, confirmedPassword, language] = [
      event.target.firstname.value,
      event.target.lastname.value,
      event.target.email.value,
      event.target.password.value,
      event.target.confirmPassword.value,
      event.target.language.value
    ];

    setUserDetails(
      {
        firstName,
        lastName,
        email,
        language
      }
    )
    if (userDetails.firstName != firstName ||
      userDetails.lastName != lastName ||
      userDetails.email != email ||
      password != "" ||
      confirmPassword != "" ||
      userDetails.language.toLowerCase() != language.toLowerCase()
    ) {
      setIsAccSettingsChangedAndNotSaved(true);
    }
    else {
      setIsAccSettingsChangedAndNotSaved(false);
    }

    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      `clicked save button - Data:
        firstName: ${firstName}
        lastName: ${lastName}
        email: ${email}
        doPasswordsMatch: ${confirmedPassword === password}
        swapFirstAndLastNames: ${swapFirstLastNames}
        language: ${language}
        `,
      null,
      "AccountSettings.tsx",
      6
    );

    if (firstName.trim().length < 1) {
      setFirstNameError(true);
      setFirstNameErrorMessage(allResourcesToTranslations.pleaseenterfirstname);
      addError(oidcUser.access_token, userId, orgDomain, 'clicked save button - Error: No firstname entered', null, "AccountSettings.tsx", 3);
      error = true;
    }

    if (lastName.trim().length < 1) {
      setLastNameError(true);
      setLastNameErrorMessage(allResourcesToTranslations.pleaseenterlastname);
      addError(oidcUser.access_token, userId, orgDomain, 'clicked save button - Error: No lastname entered', null, "AccountSettings.tsx", 3);
      error = true;
    }

    if (password !== confirmedPassword) {
      setPasswordError(true);
      setPasswordErrorMessage(allResourcesToTranslations.passwordsdonotmatch);
      addError(oidcUser.access_token, userId, orgDomain, 'clicked save button - Error: Passwords do not match', null, "AccountSettings.tsx", 3);
      error = true
    }

    if (password !== "" && confirmedPassword !== "") {
      passwordUpdate = true;
    }

    if (!validateEmail(email)) {
      setEmailError(true);
      setEmailErrorMessage(allResourcesToTranslations.emailsdonotmatch);
      addError(oidcUser.access_token, userId, orgDomain, 'clicked save button - Error: Invalid Email', null, "AccountSettings.tsx", 3);
      error = true;
    }

    if (!error) {
      const newPassword = passwordUpdate ? password : null;

      //const newPassword = isPasswordChanged ? password : null; 
      const languageUpdateRequired = currentLanguage !== language;
      editUser(oidcUser.access_token, userId, orgDomain, firstName, lastName, email, newPassword, language, swapFirstLastNames).then((data) => {
        if (data.isSuccess) {
          setDisplayName(!swapFirstLastNames ? `${firstName} ${lastName}` : `${lastName} ${firstName}`)
          addError(oidcUser.access_token, userId, orgDomain, 'saving user settings', null, "AccountSettings.tsx", 6, EnableActivityLogs);
          if (languageUpdateRequired && isMounted.current) {
            getUpdatedPageTranslation(oidcUser.access_token, userId, orgDomain, "Full Client Side Translation", language).then((langRes) => {
              if (langRes.isSuccess && isMounted.current) {
                setSavedMessage(langRes.allResourcesToTranslations.settingsupdated)
                setSavedMessageFlash(true)
                updateSiteLanguage(langRes.allResourcesToTranslations, language)
                setTitle(langRes.allResourcesToTranslations.accountsettings)
                setTitleEnglish(pageTitles.accountsettings);
              }
              setTimeout(() => {
                if (isMounted.current) {
                  setSavedMessageFlash(false)
                }
              }, 10000);
              if (isMounted.current) {
                setIsSaving(false)
                setSaveClicked(false)
                setGoBack(false)
                setIsAccSettingsChangedAndNotSaved(false);
                setModalOpen(false);
                setPassword("");
                setConfirmPassword("");
                setIsPasswordValid(true);
                setSwapFirstLastNames(true);
              }
            })
          } else {
            setSavedMessageFlash(true)
            setSavedMessage(allResourcesToTranslations.settingsupdated)
            setTimeout(() => { setSavedMessageFlash(false) }, 10000);
            setIsSaving(false)
            setSaveClicked(false)
            setGoBack(false)
            setIsAccSettingsChangedAndNotSaved(false);
            setModalOpen(false);
            setPassword("");
            setConfirmPassword("");
            setIsPasswordValid(true)
          }
        } else {
          setSavedMessage(data.error)
          setIsSaving(false)
          setSaveClicked(false)
          setGoBack(false)
          setModalOpen(false);
          setIsAccSettingsChangedAndNotSaved(false);
          setPassword("");
          setConfirmPassword("");
          setIsPasswordValid(true)
          setSavedMessageFlash(true)
          addError(oidcUser.access_token, userId, orgDomain, 'unable to save user settings', null, "AccountSettings.tsx", 3);
          setTimeout(() => { setSavedMessageFlash(false) }, 3000);
        }
      });
    } else {
      setIsSaving(false)
      setSaveClicked(false)
      setGoBack(false)
      setIsAccSettingsChangedAndNotSaved(false);
      setPassword("");
      setConfirmPassword("");
      setIsPasswordValid(true)
      setModalOpen(false);
    }
  };

  const isMounted = useRef<boolean>(false);

  let swapUserName = "";

  useEffect(() => {
    isMounted.current = true;
    if (isMounted.current) {
      setTitle(allResourcesToTranslations.accountsettings)
      setTitleEnglish(pageTitles.accountsettings);
    }
    setMenuSelection("") //Unset side menu selection

    getUser(oidcUser.access_token, userId, orgDomain).then((userData) => {
      if (isMounted.current) {
        setUserData(userData)
        setFirstName(userData?.firstName)
        setLastName(userData?.lastName)
        setEmail(userData?.email)
        setUserDetails({
          ...userDetails,
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          email: userData?.email
        }
        )
        if (userDetails.firstName != firstName ||
          userDetails.lastName != lastName ||
          userDetails.email != email ||
          password != "" ||
          confirmPassword != "" ||
          userDetails.language.toLowerCase() != language.toLowerCase()
        ) {
          setIsAccSettingsChangedAndNotSaved(true);
        }
        else {
          setIsAccSettingsChangedAndNotSaved(false);
        }
      }
      getAllPrimaryLanguages(oidcUser.access_token, userId, orgDomain).then((langData) => {
        if (isMounted.current) {
          setLanguages(langData);
          setLoading(false);
        }
      });

    console.log("Page Account Settings getOrgDomainConfig " + ((superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.siteName : ""))
    getOrgDomainConfig(oidcUser.access_token, userId, orgDomain, 
        (superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.orgID : -1,
        (superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.domainName : "",
        (superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.siteName : "").then((configResult) => {
        if (configResult.isSuccess) {
          setEnablePasswordRule(configResult.config.enablePasswordRule)
          setIsPasswordRuleCapital(configResult.config.isPasswordRuleCapital)
          setIsPasswordRuleNumbere(configResult.config.isPasswordRuleNumber)
          setIsPasswordRuleSpecialChar(configResult.config.isPasswordRuleSpecialChar)
        }
      });

    });
    return () => {
      isMounted.current = false
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    // @ts-ignore: Unreachable code error
    const unblock = history.block((location, action) => {
      if (goBack) {
        if (saveClicked) {
          if (userDetails.firstName != firstName ||
            userDetails.lastName != lastName ||
            userDetails.email != email ||
            password != "" ||
            confirmPassword != "" ||
            userDetails.language.toLowerCase() != language.toLowerCase() ||
            !swapFirstLastNames
          ) {
            setIsAccSettingsChangedAndNotSaved(true);
            setPreviousLocation(location.pathname)
            setModalOpen(true);
            return false
          }
          else {
            return true
          }
        }
        return true
      } else {
        if (userDetails.firstName != firstName ||
          userDetails.lastName != lastName ||
          userDetails.email != email ||
          password != "" ||
          confirmPassword != "" ||
          userDetails.language.toLowerCase() != language.toLowerCase() ||
          !swapFirstLastNames
        ) {
          setIsAccSettingsChangedAndNotSaved(true);
          setPreviousLocation(location.pathname)
          setModalOpen(true);
          return false
        }
        else {
          return true
        }
      }
    });

    if (userDetails.firstName != firstName ||
      userDetails.lastName != lastName ||
      userDetails.email != email ||
      password != "" ||
      confirmPassword != "" ||
      userDetails.language.toLowerCase() != language.toLowerCase()
    ) {
      setIsAccSettingsChangedAndNotSaved(true);
    }
    else {
      setIsAccSettingsChangedAndNotSaved(false);
    }

    if (!saveClicked) {
      if (goBack) {
        history.push(previousLocation)

      }
    }

    return () => {
      unblock();
    };
  }, [firstName, userDetails, goBack, lastName, email, password, confirmPassword, language, saveClicked, swapFirstLastNames]);

  const handleModal = (value: boolean) => {
    setModalOpen(value);
  }


  useEffect(() => {
    isMounted.current = true;
    if (isMounted.current) {
      setTitle(allResourcesToTranslations.accountsettings)
      setTitleEnglish(pageTitles.accountsettings);
    }
    setMenuSelection("") //Unset side menu selection
    validatePassword();
    return () => {
      isMounted.current = false
    }
  }, [password]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    isMounted.current = true;
    if (isMounted.current) {
      setTitle(allResourcesToTranslations.accountsettings)
      setTitleEnglish(pageTitles.accountsettings);
    }
    setMenuSelection("") //Unset side menu selection
    validatePassword();
    return () => {
      isMounted.current = false
    }
  }, [confirmPassword]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <LoaderContainer />
  }

  return (
    <>
      <Modal type="warning" modalOpen={showModal} setModalOpen={handleModal} style={{ height: '232px', width: '381px' }}
        hideCloseBtn={true}>
        <div style={{ position: 'relative', top: '-30px' }}>
          <h3 style={{ color: '#e7a652' }}>Unsaved Changes</h3>
          <p>Do you want to proceed without saving changes?</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', top: '10px' }}>


          <CustomTooltip displayText={["Return"]}>
            <span className={styles.returnIcon} >
              <span
                onClick={() => {
                  setModalOpen(false)
                  setIsLoggingOut(false)
                }}

              >
                <CloseIcon />
              </span>
            </span>

          </CustomTooltip>
          <CustomTooltip displayText={["Proceed"]}>
            <span className={styles.proceedIcon}>
              <span
                onClick={() => {
                  if (IsLoggingOut) {
                    setMessage(allResourcesToTranslations.loggingout)
                    logout();
                    addError(oidcUser.access_token, userId, orgDomain, "Logged out", null, "UserControls.tsx", 6, EnableActivityLogs)
                    getUserManager().signoutRedirect()
                      .catch((error) => {
                        //TODO Error API here
                      });
                    getUserManager().signoutRedirectCallback()
                      .then(() => {
                        getUserManager().removeUser()
                      })
                      .catch((error) => {
                        //TODO Error API here
                      });
                  } else {
                    setSaveClicked(false)
                    setIsAccSettingsChangedAndNotSaved(false);
                    setIsSaving(false);
                    setGoBack(true)
                  }
                }}

              >
                <ProceedIcon />
              </span>
            </span>

          </CustomTooltip>

        </div>
      </Modal>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: 'Next',
          prevLabel: 'Back',
          tooltipClass: 'sample-class',
          showBullets: true,
        }}
      />
      <div className={styles.guideMeButton}>
        <CustomTooltip displayText={["Guide Me"]}>
          <span onClick={() => setIntro({ ...intro, stepsEnabled: true })} className={styles.guideMeButtonSpecific}>
            <GuideIcon />
          </span>
        </CustomTooltip>
      </div>
      <form noValidate onSubmit={handleSubmit} style={{ paddingLeft: "13px" }}>
        <div>
          <div className={styles.inputContainer}>
            <TextField
              disabled

              className={`${styles.disabledInput} userName`}
              label={allResourcesToTranslations.username}
              variant="outlined"
              value={userData?.username}
              autoComplete="off"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <LockIcon className={styles.lockIcon} color="disabled"></LockIcon>
          </div>
          <div className={styles.inputContainer}>
            <TextField
              name="firstname"
              className={`${styles.input} firstName`}
              label={allResourcesToTranslations.firstname}
              variant="outlined"
              error={firstNameError}
              helperText={firstNameErrorMessage}
              defaultValue={firstName}
              autoComplete="off"
              onChange={(e) => { handleUpdateFirstName(e) }}
              InputProps={{
                readOnly: false,
                style: {
                  backgroundColor: "white", //We pass the style into the MUI children
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className={styles.inputContainer}>
            <TextField
              name="lastname"
              className={`${styles.input} lastName`}
              label={allResourcesToTranslations.lastname}
              variant="outlined"
              error={lastNameError}
              helperText={lastNameErrorMessage}
              defaultValue={lastName}
              autoComplete="off"
              onChange={(e) => { handleUpdateLastName(e) }}
              InputProps={{
                readOnly: false,
                style: {
                  backgroundColor: "white", //We pass the style into the MUI children
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className={styles.inputContainer}>
            <div className={`${styles.nameDisplayContaine}`}>
              <CustomTooltip displayText={[allResourcesToTranslations.changeorderofdisplayname]}>
                <span className="swap">
                  <Button
                    onClick={() => handleSwapFirstNames()}
                    type="submit"
                    variant="outlined"
                    color="primary"
                    size="small"
                  >
                    <CachedIcon />
                  </Button>
                </span>
              </CustomTooltip>
              <NameDisplay firstName={firstName} lastName={lastName} swapFirstLastNames={swapFirstLastNames} />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <TextField
              name="email"
              onChange={(e) => handleEmail(e)}
              className={`${styles.input} email`}
              label={allResourcesToTranslations.emailaddress}
              variant="outlined"
              error={emailError}
              helperText={emailErrorMessage}
              defaultValue={userData?.email}
              autoComplete="off"
              InputProps={{
                readOnly: false,
                style: {
                  backgroundColor: "white", //We pass the style into the MUI children
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <>
            {!disablePasswordField &&
              <div className="passwordFields">
                <div className={styles.inputContainer}>
                  <TextField
                    name="password"
                    className={`${styles.input} password`}
                    type="password"
                    label={allResourcesToTranslations.newpassword}
                    variant="outlined"
                    onChange={e => {
                      setPassword(e.target.value);
                      validatePassword();
                    }}
                    error={passwordError}
                    autoComplete="off"
                    InputProps={{
                      readOnly: false,
                      style: {
                        backgroundColor: "white",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }} />
                </div><div className={styles.inputContainer}>
                  <TextField
                    name="confirmPassword"
                    className={`${styles.input} confirmPassword`}
                    type="password"
                    label={allResourcesToTranslations.confirmnewpassword}
                    variant="outlined"
                    error={passwordError}
                    onChange={e => {
                      setConfirmPassword(e.target.value);
                      validatePassword();
                    }}
                    helperText={passwordErrorMessage}
                    autoComplete="off"
                    InputProps={{
                      readOnly: false,
                      style: {
                        backgroundColor: "white",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }} />
                </div><div className={styles.inputContainer}>
                  <span className={styles.passwordFinePrint}>
                    {allResourcesToTranslations.leaveemptytokeeppw}
                  </span>
                </div><div className={styles.inputContainer}>
                  {enablePasswordRule ?
                    <PasswordChecklist
                      rules={getRulesArray()}
                      minLength={8}
                      maxLength={16}
                      value={password}
                      valueAgain={confirmPassword}
                      //onChange={validatePassword}   
                      onChange={(isValid: boolean) => {
                        setIsPasswordValid(isValid);
                        //console.log(isSaving);
                        //console.log(isPasswordValid);
                      }}
                      messages={{
                        minLength: `${allResourcesToTranslations.passwordminlength}`,
                        maxLength: `${allResourcesToTranslations.passwordmaxlength}`,
                        capital: `${allResourcesToTranslations.passworduppercase}`,
                        number: `${allResourcesToTranslations.passwordnumber}`,
                        specialChar: `${allResourcesToTranslations.passwordspecial}`,
                        match: `${allResourcesToTranslations.passwordmatch}`,
                      }} /> : ''}
                </div>
              </div>
            }
          </>
          <div className={styles.inputContainer}>
            <FormControl style={{ width: "30%" }}>
              <FormLabel>
                {allResourcesToTranslations.language}
              </FormLabel>
              <NativeSelect
                name="language"
                className={styles.selectDropdown}
                variant="outlined"
                onChange={(e) => setLanguage(e.target.value)}
                defaultValue={currentLanguage}
              >
                <LanguageOptions languages={languages} />
              </NativeSelect>
            </FormControl>
          </div>
          <div style={{ width: '30%', minWidth: '300px !important;' }}>
            <div className={styles.saveButtonContainer} style={{ float: 'right' }}>
              <CustomTooltip displayText={[allResourcesToTranslations.savesettings]}>
                <span>
                  <Button
                    disabled={isSaving || ((password !== "" || confirmPassword !== "") && !isPasswordValid)}
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="save"
                  >
                    <SaveIcon />
                  </Button>
                </span>
              </CustomTooltip>
              <div className={styles.loaderContainer}>
                <CircularProgress
                  style={{ width: isSaving ? "20px" : "0px" }}
                  color="primary"
                  size="10"
                />
                <span style={{ opacity: savedMessageFlash ? "1" : "0" }} className={styles.profileUpdatedText}>
                  {savedMessage}
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AccountSettings;
