import React from 'react';
import styles from '../../styles/loaderScreen.module.css'
import LoaderContainer from '../../Components/LoaderContainer';

const CustomCallback = () => (
  <div className={styles.container}>
    <LoaderContainer/>
  </div>
);

export default CustomCallback;
