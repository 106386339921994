import React, { useState, useContext, useEffect } from "react";

//Styles
import styles from "../../../styles/organisationmanagement.module.css";

import { useHistory } from "react-router";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import LoaderContainer from "../../../Components/LoaderContainer";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";
import BrandedCard from "../../../Components/BrandedCard";
import CustomTooltip from "../../../Components/CustomTooltip";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@material-ui/core";
import { OrgEmailRule } from "../../../globalTypes";
import getColor from "../../../functions/getColor";
import { CorperateThemeContext } from "../../../contexts/CorperateThemeContext";
import UpArrowIcon from "../../../CustomIcons/UpArrowIcon";
import DownArrowIcon from "../../../CustomIcons/DownArrowIcon";
import DeleteIcon from "../../../CustomIcons/DeleteIcon";
import EditIcon from "../../../CustomIcons/SupportDetailsEditIcon"; 
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import SaveOrgEmailRuleReminderEscalation from "../../../API/Administration/Organisation/SaveOrgEmailRuleReminderEscalation";
import CloseWhiteIcon from "@material-ui/icons/Close";
import Modal from '../../../Components/Modal';
import ProceedIcon from "../../../CustomIcons/ProceedIcon";
import GuideIcon from "../../../CustomIcons/GuideIcon";
import { Steps } from "intro.js-react";

const OrgReminderEmailRules = (props: any) => {
  const { coursesEmailRules, refreshRules } = props; 
  const history = useHistory();
  const {
    translations: { allResourcesToTranslations } } = useContext(TranslationsContext);
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
      .toLowerCase()
      .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";
  const [loading, setLoading] = useState<boolean>(true);
  const { theme: { color } } = useContext(CorperateThemeContext);

  const [orgEmailRuleResult, setOrgEmailRuleResult] = useState<Array<OrgEmailRule>>([]);
  const [orgEmailRuleRefresh, setOrgEmailRuleRefresh] = useState<boolean>(true);
  const [selectedReminderType, setSelectedReminderType] = useState<string>(props?.location?.state?.selectedReminderType ?? 'c');
  const [currentSortBy, setCurrentSortBy] = React.useState<string>("")
  const [reverseNextSort, setReverseNextSort] = React.useState<boolean>(false)
  const [sortInProgress, setSortInProgress] = React.useState<boolean>(false)
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);

  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true)
  const [courseNameDescending, setCourseNameDescending] = React.useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [showConfirmModalDeleteSchedule, setshowConfirmModalDeleteSchedule] = useState<boolean>(false);
  const [deletedRepoeScheduleID, setdeletedRepoeScheduleID] = useState<number>(0);
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".btnStopEmail",
        title: "Start/Stop Automated Emails", intro: allResourcesToTranslations.automatedemailingnotes
      }
    ]
  });
    const setGuideMe = (clearOldList: boolean = false) => {
    let updateSteps = clearOldList ? [] : [...intro.steps]
    updateSteps.push(
      {
        element: ".btnStopEmail",
        title: "Start/Stop Automated Emails", 
        intro: allResourcesToTranslations.automatedemailingnotes + "."
      },
      {
        element: ".addNewRule",
        title: "Add New", 
        intro: allResourcesToTranslations.addorgemailruleguideme
      },
      {
        element: ".coursenameguideme",
        title: allResourcesToTranslations.coursename, 
        intro: allResourcesToTranslations.coursenamecolumnguideme
      },
      {
        element: ".ieguideme",
        title: allResourcesToTranslations.initialenrolmentperiod, 
        intro: allResourcesToTranslations.ieguideme
      },
      {
        element: ".preguideme",
        title: allResourcesToTranslations.preexpirynotification, 
        intro: allResourcesToTranslations.preguideme
      },
      {
        element: ".postguideme",
        title: allResourcesToTranslations.postexpirynotification, 
        intro: allResourcesToTranslations.postguideme
      },
      {
        element: ".ruleDisable",
        title: allResourcesToTranslations.emailruleenabledisable, 
        intro: allResourcesToTranslations.ruledisable
      },
      {
        element: ".ruleedit",
        title: allResourcesToTranslations.editemailreminder, 
        intro: allResourcesToTranslations.ruleedit
      },
      {
        element: ".ruledelete",
        title: allResourcesToTranslations.deleteemailreminder, 
        intro: allResourcesToTranslations.ruledelete
      });


    setIntro({ ...intro, steps: [...updateSteps] });
  };

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };
  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortResultsByAsync = async (sortBy: "CourseName", sortGivenItemInDesc: boolean) => {
    if (orgEmailRuleResult == null || orgEmailRuleResult.length <= 0) {
      return orgEmailRuleResult;
    }
    var result = orgEmailRuleResult.slice(0)
    setCurrentSortBy(sortBy)
    switch (sortBy) {

      case "CourseName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.courseName === null) { return -1; }
            if (a === null || a.courseName === null) { return 1; }
            if (a.courseName.toLowerCase() > b.courseName.toLowerCase()) { return -1; }
            if (a.courseName.toLowerCase() < b.courseName.toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setCourseNameDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.courseName === null) { return 1; }
            if (a === null || a.courseName === null) { return -1; }
            if (a.courseName.toLowerCase() < b.courseName.toLowerCase()) { return -1; }
            if (a.courseName.toLowerCase() > b.courseName.toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setCourseNameDescending(true)
          }
        }
        setOrgEmailRuleResult(result);
        break;


      default:
        break;
    }
    return result.slice(0);
  }

  const sortResultsBy = async (sortBy: "CourseName", sortGivenItemInDesc: boolean) => {
    setSortInProgress(true);
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy)
      setOrgEmailRuleResult(result)
      setPage(0)
      setSortInProgress(false);
    });
  }

  const clickCourseNameAsc = () => {
    setReverseNextSort(false);
    setCourseNameDescending(false);
    sortResultsBy("CourseName", false);
  }

  const clickCourseNameDesc = () => {
    setReverseNextSort(false);
    setCourseNameDescending(true)
    sortResultsBy("CourseName", true)
  }

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" }
    } else {
      return {}
    }
  }

  const handleDisableEmailRule = (index: number, dateEnabled: string) => {
    SaveOrgEmailRuleReminderEscalation(oidcUser.access_token, userId, orgDomain, parseInt(orgId), index, 0).then((res) => {
      setIsSaving(false);
      if (!!res && res.isSuccess) {
        refreshRules();
        setShowWarningModal(true);
        setHasErrored(false);
        setSaveSuccess(true);
        setModalPopupMessage("Organisation reminder rule has been " + dateEnabled.toLowerCase() + "d" + " successfully!");
      }
      else {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage("Organisation reminder rule saving failed!");
      }
    });
  }

  const handleEditEmailRule = (courseIdToUpdate: number, remescIdToUpdate: number) => {
    history.push({
      pathname: `orgemailrulesadd/` + courseIdToUpdate + `/` + remescIdToUpdate,
      state: {
        selectedTab: 'c'
      },
    });
  }

  const handleDeleteEmailRule = (index: number) => {
    handleConfirmModalDeleteSchedule(true);
    setdeletedRepoeScheduleID(index);
  }

  const handleConfirmModalDeleteSchedule = (value: boolean) => {
    setshowConfirmModalDeleteSchedule(value);
  }

  const handleCancelFromConfirmPopupDeleteSchedule = () => {
    handleConfirmModalDeleteSchedule(false);
    setIsSaving(false);
  }

  const handleProceedDeleteSchedule = () => {
    handleConfirmModalDeleteSchedule(false);
    if (deletedRepoeScheduleID > 0) {
      SaveOrgEmailRuleReminderEscalation(oidcUser.access_token, userId, orgDomain, parseInt(orgId), deletedRepoeScheduleID, 1).then((res) => {
        setIsSaving(false);
        if (!!res && res.isSuccess) {
          refreshRules();
          setShowWarningModal(true);
          setHasErrored(false);
          setSaveSuccess(true);
          setModalPopupMessage("Organisation reminder rule has been deleted successfully!");
        }
        else {
          setShowWarningModal(true);
          setHasErrored(true);
          setSaveSuccess(false);
          setModalPopupMessage("Organisation reminder rule deleted failed!");
        }
      });
    }
  }

  useEffect(() => {
    if (!!coursesEmailRules) {
      setOrgEmailRuleResult(coursesEmailRules);
      setLoading(false);
      setGuideMe(true);
    }
  }, []);

  if (loading) return <LoaderContainer />;
  return (
    <>
    <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }} />
      <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific}>
          <GuideIcon />
        </span>

      </CustomTooltip>
      <Modal modalOpen={showConfirmModalDeleteSchedule} setModalOpen={handleConfirmModalDeleteSchedule} type="warning">
        <div className={styles.modalContentWrapper}>
          <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>{allResourcesToTranslations.confirmation}</h2>
          <div className={styles.inputContainer}>
            <p>
              Are you sure you want to delete this reminder email rule?
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "10px",
            }}
          >
            <CustomTooltip displayText={[allResourcesToTranslations.return]}>
              <span className={styles.returnIcon} onClick={handleCancelFromConfirmPopupDeleteSchedule}>
                <CloseWhiteIcon />
              </span>
            </CustomTooltip>
            <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
              <span className="saveData">
                <Button
                  onClick={handleProceedDeleteSchedule}
                  variant="contained"
                  color="primary"
                  className="save"
                >
                  <ProceedIcon />
                </Button>
              </span>
            </CustomTooltip>
          </div>
        </div>
      </Modal>
      <div>
        <div style={{ width: '100%', clear: "both" }} >
          {orgEmailRuleResult != null && orgEmailRuleResult.length > 0 && (
            <div>

              <div className={styles.paginationControlsContainer}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={orgEmailRuleResult.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
              <div className={`${styles.tableSection}`}>
                <div className={styles.tableContainer}>
                  <BrandedCard>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>

                            <TableCell
                              style={{
                                color: getColor(color.substring(1)),
                                backgroundColor: color,
                                borderRadius: "8px 0 0 0",
                              }} className="coursenameguideme"
                              align="left"
                            >
                              <div className={styles.tableHeadCellContainer}>
                                <div
                                  onClick={() => {
                                    setCourseNameDescending(!courseNameDescending);
                                    sortResultsBy("CourseName", !courseNameDescending);
                                  }}
                                >
                                  {allResourcesToTranslations.coursename}
                                </div>
                                <div className={styles.columnHeadArea}>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "CourseName" &&
                                          courseNameDescending === false
                                          ? "100%"
                                          : "50%",
                                      margin: "2px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickCourseNameAsc}
                                  >
                                    <UpArrowIcon />{" "}
                                  </span>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "CourseName" &&
                                          courseNameDescending === true
                                          ? "100%"
                                          : "50%",
                                      margin: "0px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickCourseNameDesc}
                                  >
                                    <DownArrowIcon />{" "}
                                  </span>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                color: getColor(color.substring(1)),
                                backgroundColor: color,
                              }} className="ieguideme"
                              align="center"
                            >
                              <div className={styles.tableHeadCellContainer}>
                                <div>
                                  {allResourcesToTranslations.initialenrolmentperiod}
                                </div>

                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                color: getColor(color.substring(1)),
                                backgroundColor: color,
                              }} className="preguideme"
                              align="center"
                            >
                              <div className={styles.tableHeadCellContainer}>
                                <div>
                                  {allResourcesToTranslations.preexpirynotification}
                                </div>

                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                color: getColor(color.substring(1)),
                                backgroundColor: color,
                              }} className="postguideme"
                              align="center"
                            >
                              <div className={styles.tableHeadCellContainer}>
                                <div>
                                  {allResourcesToTranslations.postexpirynotification}
                                </div>

                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                width: "200px",
                                color: getColor(color.substring(1)),
                                backgroundColor: color,
                                borderRadius: "0 8px 0 0",
                              }}
                              align="center"
                            >
                              <div className={styles.tableHeadCellContainer}>
                                <div>
                                  {allResourcesToTranslations.actions}
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {orgEmailRuleResult
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((result: any, i) => {
                              return (
                                <TableRow
                                  key={`reportScheduler-${i}`}
                                  style={rowBackgroundColor(i)}
                                >
                                  <TableCell align="left" width={"40%"}>
                                    {result.courseName}
                                  </TableCell>

                                  <TableCell align="center" className="dateEnabledToggele">
                                    {result.remindUsers === "Enabled" ? <span > {result.remindUsers}</span> : <span style={{ color: "red" }}>{result.remindUsers}</span>}
                                  </TableCell>
                                  <TableCell align="center" className="dateEnabledToggele">
                                    {result.quizExpiryWarn === "Enabled" ? <span > {result.quizExpiryWarn}</span> : <span style={{ color: "red" }}>{result.quizExpiryWarn}</span>}
                                  </TableCell>
                                  <TableCell align="center" className="dateEnabledToggele">
                                    {result.postExpReminder === "Enabled" ? <span > {result.postExpReminder}</span> : <span style={{ color: "red" }}>{result.postExpReminder}</span>}
                                  </TableCell>
                                  <TableCell align="left">
                                    {result.dateEnabled === "Disable" ?
                                      <CustomTooltip displayText={["Toggle to " + [result.dateEnabled] + " all notifications for the given course."]}>
                                        <Button className="ruleDisable" onClick={() => handleDisableEmailRule(result.remEscId, result.dateEnabled)} variant="contained" color="primary" >
                                          <NotificationsActiveIcon />
                                        </Button>
                                      </CustomTooltip>
                                      :
                                      <CustomTooltip displayText={["Toggle to " + [result.dateEnabled] + " all notifications for the given course."]}>
                                        <Button onClick={() => handleDisableEmailRule(result.remEscId, result.dateEnabled)} variant="contained" color="primary" style={{ backgroundColor: "red" }} >
                                          <NotificationsOffIcon />
                                        </Button>
                                      </CustomTooltip>
                                    }
                                    <CustomTooltip displayText={[allResourcesToTranslations.ruleedit]}>
                                      <Button className="ruleedit" onClick={() => handleEditEmailRule(result.courseId, result.remEscId)} variant="contained" color="primary">
                                        <EditIcon />
                                      </Button>
                                    </CustomTooltip>
                                    <CustomTooltip displayText={[allResourcesToTranslations.ruledelete]}>
                                      <Button className="ruledelete" onClick={() => handleDeleteEmailRule(result.remEscId)} variant="contained" style={{ backgroundColor: "red" }}>
                                        <DeleteIcon />
                                      </Button>
                                    </CustomTooltip>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </BrandedCard>
                </div>
              </div>
              <div className={styles.paginationAddedUsersControl}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={orgEmailRuleResult.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          )}

          {orgEmailRuleResult != null && orgEmailRuleResult.length === 0 && (
            <BrandedCard>
              <div className={styles.noResultsCard} style={{ marginTop: "20px" }}>
               <div>{allResourcesToTranslations.sntemrepgmmnoresm}</div>
               <br />
               <div>{allResourcesToTranslations.sntemrepgmmselbkprevm}</div>                          
              </div>
            </BrandedCard>
          )}
        </div>
      </div>

      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
    </>
  );
};

export default OrgReminderEmailRules;
