import React, {useEffect, useState} from 'react'
import styles from '../../styles/sideMenu.module.css';
import { Link } from 'react-router-dom';
import CustomTooltip from '../../Components/CustomTooltip';

interface Props {
    icon: React.ReactNode
    optionName: string
    displayName: string
    isSelected: boolean
    customClickEvent: Function
    sideMenuOpen: boolean
    route?: string
    isNested?: boolean
    className?: string
    isMultiSectionModulePlayer?: boolean
}

export const SideMenuOption = ({ icon, optionName, displayName, isSelected, customClickEvent, sideMenuOpen, route, isNested, className, isMultiSectionModulePlayer}: Props) => {
    const [isMouseHover, setIsMouseHover] = useState<boolean>(false);

	useEffect(() => {
	}, [])

    const handleClick = () => {
        customClickEvent(optionName)
    }  

    const handleMouseOver = () => {
        setIsMouseHover(true);
    } 

    const handleMouseEnter = () => {
        setIsMouseHover(true);        
    }   

    const handleMouseLeave = () => {
        setIsMouseHover(false);
    }   

    const baseAccordionStyle = isNested ? (sideMenuOpen? styles.accordionNested : styles.accordionNestedsmall) : (!!isMultiSectionModulePlayer && isMultiSectionModulePlayer ? styles.accordion_MulSecModPlayer : styles.accordion); //Nested accordion options have a different background colour 
    return (
        !!isMultiSectionModulePlayer && isMultiSectionModulePlayer ?
        <div className={ isSelected ? styles.accordionSelected : baseAccordionStyle } onClick={handleClick} >
            <CustomTooltip displayText={[!!displayName ? displayName : ""]}>
                <div className={styles.sideMenuTextIconContainer}>
                    { icon }
                    <span className={isSelected ? 
                                    (isMouseHover ? styles.sideMenuText_MulSecModPlayer_MouseHoverSelected : styles.sideMenuText_MulSecModPlayer_Selected) : 
                                    (isMouseHover ? styles.sideMenuText_MulSecModPlayer_MouseHover : styles.sideMenuText_MulSecModPlayer)} 
                        title={!!displayName && displayName.length > 22 ? displayName: ''} style={{ width: sideMenuOpen? "150px" : "0px" }}
                        onMouseOver={handleMouseOver} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                        { !!displayName ? displayName : "" }
                    </span>
                </div>
            </CustomTooltip>
        </div>
        :
        <Link to={route? route : ""} onClick={handleClick} className={`${className}`}>
            <div className={ isSelected ? styles.accordionSelected : baseAccordionStyle } onClick={handleClick} >
                <div className={styles.sideMenuTextIconContainer}>
                    { icon }
                    <span className={styles.sideMenuText} title={!!displayName &&  displayName.length > 22 ? displayName: ''} style={{ width: sideMenuOpen? "150px" : "0px" }}>
                        { !!displayName ? displayName : "" }
                    </span>
                </div>
            </div>
        </Link>

    )
}

export default SideMenuOption;
