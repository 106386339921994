import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const Status_ActiveIcon = () => (
<svg width="10px" height="10px" viewBox="0 0 0.15 0.15" xmlns="http://www.w3.org/2000/svg">
    <path cx="6" cy="6" r="6" fill="green" d="M0.15 0.075A0.075 0.075 0 0 1 0.075 0.15A0.075 0.075 0 0 1 0 0.075A0.075 0.075 0 0 1 0.15 0.075z"/>
 </svg>
);

export default Status_ActiveIcon
