import React from 'react'
import Modal from '../../Components/Modal';
import styles from '../../styles/dashboard.module.css'

interface Props {
    showModal: boolean
    setShowModal: (isOpen: boolean) => any
    welcomeSrc: string
}

const WelcomeModal = ({ showModal, setShowModal, welcomeSrc }: Props) => {
    if (!showModal) return null
    // Need to sanitze data for security in dangerouslySetInnerHTML. Only required if strings have html tags
    // more info -> https://zhenyong.github.io/react/tips/dangerously-set-inner-html.html
    const createWelcomeMessageMarkup = () => {
        return {
           __html: welcomeSrc };
     };
     
    return (
        <div>
            <Modal modalOpen={showModal} setModalOpen={setShowModal}>
                <div className={styles.modalContentWrapper}>
                    <div className={styles.welcomeMessage} dangerouslySetInnerHTML={createWelcomeMessageMarkup()} />
                </div>
            </Modal>
        </div>
    )
}

export default WelcomeModal;
