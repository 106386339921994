import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const ExternalCertificateIcon = () => ( 
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.583 3.167H9.5a3.167 3.167 0 0 0 -3.167 3.167v25.333a3.167 3.167 0 0 0 3.167 3.167h19a3.167 3.167 0 0 0 3.167 -3.167V14.25z" stroke="#21394F" stroke-width="3.1666666666666665" stroke-linecap="round" stroke-linejoin="round"/><path d="M20.583 3.167v11.083h11.083" stroke="#21394F" stroke-width="3.1666666666666665" stroke-linecap="round" stroke-linejoin="round"/><path d="m22.167 23.75 -3.167 -3.167 -3.167 3.167" stroke="#21394F" stroke-width="3.1666666666666665" stroke-linecap="round" stroke-linejoin="round"/><path d="M19 26.917v-6.333" stroke="#21394F" stroke-width="3.1666666666666665" stroke-linecap="round" stroke-linejoin="round"/></svg>   

    
    );

export default ExternalCertificateIcon
