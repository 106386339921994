import axios from 'axios';
import addError from './AddError';
import oidcConfig from '../configuration';

export const getOIDCConfig = async () => {

  var currentDomain = window.location.host
    const key1 = "https://";
    const key2 = "http://";
    const key3 = ".com/";
    const idx1 = currentDomain.indexOf(key1);
    const idx2 = currentDomain.indexOf(key2);
    const idx3 = currentDomain.indexOf(key3);
    currentDomain = (idx1 !== -1 && idx3 !== -1) ? currentDomain.substring(idx1 + key1.length, idx3 + key3.length) : 
                      ((idx2 !== -1 && idx3 !== -1) ? currentDomain.substring(idx2 + key2.length, idx3 + key3.length) : currentDomain);

    let isSA = false;
    if ((window.location.host.includes(oidcConfig.stagingdomain + ":3000") || window.location.host.includes(oidcConfig.stagingdomain + ":3009") || window.location.host.includes(oidcConfig.stagingdomain + ":3007")) && !window.location.host.includes("altlogin-")) {
      currentDomain = oidcConfig.stagingdomain;
      isSA = false;
    }
    if ((window.location.host.includes(oidcConfig.stagingdomainSA + ":3000") || window.location.host.includes(oidcConfig.stagingdomainSA + ":3009") || window.location.host.includes(oidcConfig.stagingdomainSA + ":3007")) && !window.location.host.includes("altlogin-")) {
      currentDomain = oidcConfig.stagingdomainSA;
      isSA = true;
    }
    if ((window.location.host.includes(oidcConfig.stagingdomainForDeactTest + ":3000") || window.location.host.includes(oidcConfig.stagingdomainForDeactTest + ":3009") || window.location.host.includes(oidcConfig.stagingdomainForDeactTest + ":3007")) && !window.location.host.includes("altlogin-")) {
      currentDomain = oidcConfig.stagingdomainForDeactTest;
      isSA = false;
    }
    //currentDomain = currentDomain.toLowerCase().replace("altlogin-", ""); // To support the non-sso users of SSO orgs
    try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/user/GetOIDCConfig`,
      headers:{
        'Content-Type': 'application/json',
      },
      data: {
        Domain: currentDomain
      },
    });
    const data = await response.data;
    
    // console.log('getOIDCConfig isSA' + isSA.toString());
    // console.log('getOIDCConfig data' + data);
    // console.log('getOIDCConfig data.config' + (!!data ? data.config : "undefined"));
    // console.log('getOIDCConfig data.config.clientID' + (!!data && !!data.config ? data.config.clientID : "undefined"));
    // console.log('getOIDCConfig data.config.clientSecret' + (!!data && !!data.config ? data.config.clientSecret : "undefined"));

    if (data.isSuccess) { 
        return { //Remapping to match the OIDC library params
            isSuccess: true,
            error: '',
            config: {
                authority: data.config.authority,
                automaticSilentRenew: data.config.automaticSilentRenew,
                client_id: data.config.clientID,
                client_secret: data.config.clientSecret,
                loadUserInfo: data.config.loadUserInfo,
                monitorSession: data.config.monitorSession,
                origin: data.config.origin,
                post_logout_redirect_uri: data.config.monitorSession.postLogoutRedirectUri,
                redirect_uri: data.config.redirectUri,
                response_type: data.config.responseType,
                scope: data.config.scope,
                silent_redirect_uri: data.config.silentRedirectUri
            }
        }
    } else {
        return data;
    }
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
        addError("Token not yet generated", 0, window.location.hostname, "Rest Exception", error.response, "ApplyAllProfilePoints.ts", 1)
      } else if (error.request) {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          console.log(error.request);
        }; 
        addError("Token not yet generated", 0, window.location.hostname, "Rest Exception", error.request, "ApplyAllProfilePoints.ts", 1)
      } else if (error.message) {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          console.log(error.message);
        }; 
        addError("Token not yet generated", 0, window.location.hostname, "Rest Exception", error.message, "ApplyAllProfilePoints.ts", 1)
      }
  }
};

export default getOIDCConfig