import React from 'react';

//MUI Components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Button from "@material-ui/core/Button";

//Components
import ExcelFileIcon from '../../CustomIcons/ExcelFileIcon'
import CSVFileIcon from '../../CustomIcons/CSVFileIcon'
import PDFIcon from '../../CustomIcons/PDFFileIcon';
import CustomTooltip from "../../Components/CustomTooltip";

//Styles
import styles from "../../styles/currentAdminReport.module.css";

const DocumentExportSection = (props:any) => {

    const { handleDocumentChange, documentType} = props;

      const documentTypeList = [
        {
          value: "C",
          label: "CSV",
        },
    
        {
          value: "E",
          label: "XLSX",
        },
    
        {
          value: "P",
          label: "PDF",
        },
      ];

      const handleLabel = (label:string) => {


        return (
          <CustomTooltip displayText={[label] } >                                    
                    <Button color="primary" onClick={handleDocumentChange} style={{marginLeft:'-12px', height: '5px'}}>
                        {label == 'CSV' && <CSVFileIcon />}
                        {label == 'XLSX' && <ExcelFileIcon />}
                        {label == 'PDF' && <PDFIcon />}
                      
                    </Button>
            </CustomTooltip>
        )
      }

    return (
        <>
          <FormControl component="fieldset" style={{ margin: "10px", width: '98%' }}>
            <FormLabel component="legend">Document Type</FormLabel>
            <div className={styles.childContainer} style={{display: 'block'}}>
              <RadioGroup
                row
                name="documentType"
                value={documentType}
                onChange={handleDocumentChange}
              >
                {documentTypeList.map((item,index) => (
                  <FormControlLabel
                    value={item.value}
                    control={<Radio color="primary" />}
                    label={handleLabel(item.label)}
                    key={`item-${index}`}
                  />
                ))}
              </RadioGroup>
            </div>
          </FormControl>
          </>
    )
}

export default DocumentExportSection;