import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const MyTrainingTooltipStoryLine = () => (
<svg width="55" height="48" viewBox="0 0 55 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="27" cy="16" r="15.75" fill="white" stroke="#CA6EB9" stroke-width="0.5"/>
<path d="M29.4346 10.5H30.3807V19.0153C30.3807 19.2843 30.4549 19.4976 30.6033 19.6553C30.7517 19.8037 30.9605 19.8779 31.2295 19.8779C31.3315 19.8779 31.4521 19.864 31.5912 19.8362C31.7303 19.8083 31.8602 19.7712 31.9808 19.7249L32.1478 20.4901C31.9715 20.5643 31.7535 20.6246 31.4938 20.671C31.2434 20.7174 31.0254 20.7406 30.8399 20.7406C30.4039 20.7406 30.0607 20.62 29.8102 20.3788C29.5598 20.1284 29.4346 19.7805 29.4346 19.3353V10.5Z" fill="#C663B4"/>
<path d="M25.0471 20.7962C24.4813 20.7962 23.9294 20.7034 23.3914 20.5179C22.8534 20.3324 22.3896 20.0634 22 19.7109L22.4313 19.0709C22.8395 19.4048 23.2522 19.6552 23.6697 19.8222C24.0963 19.9892 24.5509 20.0727 25.0332 20.0727C25.6176 20.0727 26.0814 19.9567 26.4246 19.7248C26.7771 19.4836 26.9533 19.1451 26.9533 18.7091C26.9533 18.505 26.9069 18.3381 26.8142 18.2082C26.7214 18.0691 26.5823 17.9485 26.3968 17.8464C26.2205 17.7444 25.9933 17.6563 25.715 17.5821C25.446 17.4986 25.1306 17.4151 24.7688 17.3316C24.3514 17.2296 23.9897 17.1276 23.6836 17.0255C23.3775 16.9235 23.1224 16.8075 22.9183 16.6777C22.7235 16.5478 22.5751 16.3901 22.4731 16.2046C22.3803 16.0098 22.3339 15.7686 22.3339 15.4811C22.3339 15.1193 22.4035 14.7993 22.5426 14.521C22.6911 14.2428 22.8858 14.0155 23.127 13.8393C23.3775 13.6538 23.665 13.5146 23.9897 13.4219C24.3236 13.3291 24.6761 13.2827 25.0471 13.2827C25.6037 13.2827 26.1092 13.3755 26.5637 13.561C27.0182 13.7372 27.3846 13.9738 27.6629 14.2706L27.2038 14.8271C26.9348 14.5489 26.6055 14.3402 26.2159 14.201C25.8356 14.0619 25.4367 13.9923 25.0193 13.9923C24.7688 13.9923 24.537 14.0201 24.3236 14.0758C24.1103 14.1222 23.9201 14.201 23.7531 14.3123C23.5955 14.4236 23.4702 14.5674 23.3775 14.7437C23.2847 14.9106 23.2383 15.1101 23.2383 15.342C23.2383 15.5368 23.2708 15.6944 23.3357 15.815C23.4007 15.9356 23.5073 16.0423 23.6557 16.135C23.8042 16.2185 23.9897 16.2974 24.2123 16.3716C24.4442 16.4365 24.7225 16.5061 25.0471 16.5803C25.5109 16.6916 25.9191 16.8029 26.2715 16.9142C26.624 17.0255 26.9162 17.1554 27.1481 17.3038C27.3893 17.4522 27.5702 17.6331 27.6907 17.8464C27.8113 18.0598 27.8716 18.3195 27.8716 18.6256C27.8716 19.2935 27.6165 19.8222 27.1064 20.2118C26.5962 20.6014 25.9098 20.7962 25.0471 20.7962Z" fill="#C663B4"/>
<path d="M54.3695 41.6043C54.3695 42.1318 54.3295 42.6031 54.2495 43.018C54.1695 43.4329 54.0435 43.7841 53.8716 44.0716C53.6997 44.3591 53.4774 44.5784 53.2048 44.7296C52.9321 44.8777 52.6046 44.9518 52.2223 44.9518C51.7392 44.9518 51.3391 44.8199 51.022 44.5562C50.7078 44.2924 50.4722 43.9116 50.3152 43.4137C50.161 42.9128 50.084 42.3097 50.084 41.6043C50.084 40.9108 50.1536 40.3151 50.2929 39.8172C50.4352 39.3163 50.6634 38.9325 50.9775 38.6658C51.2917 38.3961 51.7066 38.2612 52.2223 38.2612C52.7113 38.2612 53.1144 38.3946 53.4315 38.6613C53.7486 38.9251 53.9842 39.3074 54.1384 39.8083C54.2925 40.3062 54.3695 40.9049 54.3695 41.6043ZM50.8264 41.6043C50.8264 42.2089 50.8723 42.7127 50.9642 43.1158C51.0561 43.5189 51.2043 43.8212 51.4088 44.0227C51.6162 44.2213 51.8874 44.3206 52.2223 44.3206C52.5572 44.3206 52.8269 44.2213 53.0314 44.0227C53.2389 43.8241 53.3885 43.5233 53.4804 43.1203C53.5752 42.7172 53.6227 42.2119 53.6227 41.6043C53.6227 41.0086 53.5767 40.5107 53.4849 40.1106C53.393 39.7075 53.2448 39.4052 53.0403 39.2037C52.8358 38.9992 52.5631 38.8969 52.2223 38.8969C51.8815 38.8969 51.6088 38.9992 51.4043 39.2037C51.1998 39.4052 51.0516 39.7075 50.9598 40.1106C50.8709 40.5107 50.8264 41.0086 50.8264 41.6043Z" fill="white"/>
<path d="M44.9316 42.0845C44.9316 41.6932 44.9583 41.3109 45.0117 40.9375C45.068 40.5641 45.1613 40.2173 45.2917 39.8972C45.4221 39.5742 45.6 39.2911 45.8252 39.0481C46.0504 38.8051 46.3305 38.6154 46.6654 38.4791C47.0033 38.3398 47.4078 38.2701 47.8791 38.2701C48.0124 38.2701 48.1562 38.2776 48.3103 38.2924C48.4644 38.3042 48.5918 38.325 48.6926 38.3546V38.9903C48.5829 38.9518 48.457 38.9236 48.3147 38.9059C48.1754 38.8881 48.0347 38.8792 47.8924 38.8792C47.3411 38.8792 46.907 38.9992 46.5898 39.2393C46.2757 39.4793 46.049 39.8053 45.9097 40.2173C45.7704 40.6263 45.6904 41.0872 45.6696 41.5999H45.7185C45.8104 41.4517 45.9275 41.3183 46.0697 41.1998C46.212 41.0783 46.3839 40.9819 46.5854 40.9108C46.7899 40.8397 47.027 40.8041 47.2967 40.8041C47.676 40.8041 48.0065 40.8812 48.2881 41.0353C48.5726 41.1894 48.7919 41.4132 48.946 41.7066C49.1031 42 49.1816 42.3556 49.1816 42.7735C49.1816 43.221 49.0986 43.6078 48.9327 43.9338C48.7667 44.2598 48.5296 44.5117 48.2214 44.6896C47.9161 44.8644 47.5516 44.9519 47.1278 44.9519C46.8106 44.9519 46.5187 44.8911 46.252 44.7696C45.9852 44.6481 45.7526 44.4673 45.554 44.2272C45.3584 43.9872 45.2058 43.6893 45.0961 43.3337C44.9865 42.975 44.9316 42.5586 44.9316 42.0845ZM47.1189 44.3295C47.5219 44.3295 47.845 44.2005 48.088 43.9427C48.331 43.6849 48.4525 43.2951 48.4525 42.7735C48.4525 42.3467 48.3444 42.0089 48.128 41.7599C47.9117 41.511 47.5857 41.3865 47.15 41.3865C46.8536 41.3865 46.5958 41.4487 46.3765 41.5732C46.1571 41.6947 45.9867 41.8488 45.8652 42.0355C45.7437 42.2223 45.6829 42.4119 45.6829 42.6046C45.6829 42.8002 45.7111 42.9988 45.7674 43.2003C45.8267 43.4018 45.9156 43.5885 46.0341 43.7604C46.1527 43.9323 46.3024 44.0701 46.4831 44.1739C46.6639 44.2776 46.8758 44.3295 47.1189 44.3295Z" fill="white"/>
<path d="M43.6736 39.8794C43.6736 40.1699 43.6173 40.4218 43.5046 40.6352C43.392 40.8486 43.2349 41.0234 43.0334 41.1598C42.8319 41.2931 42.5948 41.3865 42.3221 41.4398V41.4754C42.8378 41.5406 43.2246 41.7066 43.4824 41.9733C43.7432 42.24 43.8736 42.5898 43.8736 43.0225C43.8736 43.3989 43.7847 43.7323 43.6069 44.0227C43.432 44.3132 43.1623 44.5414 42.7978 44.7073C42.4362 44.8703 41.9724 44.9519 41.4063 44.9519C41.0625 44.9519 40.7439 44.9237 40.4505 44.8674C40.1601 44.814 39.8815 44.7236 39.6147 44.5962V43.9027C39.8844 44.0361 40.1779 44.1413 40.495 44.2183C40.8121 44.2924 41.1188 44.3295 41.4152 44.3295C42.008 44.3295 42.4362 44.2124 42.7 43.9783C42.9638 43.7412 43.0956 43.4181 43.0956 43.0091C43.0956 42.7276 43.0215 42.5009 42.8734 42.329C42.7281 42.1541 42.5177 42.0267 42.2421 41.9466C41.9694 41.8637 41.6404 41.8222 41.2552 41.8222H40.6061V41.1909H41.2596C41.6123 41.1909 41.9116 41.139 42.1576 41.0353C42.4036 40.9316 42.5903 40.7863 42.7178 40.5996C42.8482 40.4099 42.9134 40.1862 42.9134 39.9283C42.9134 39.5994 42.8037 39.346 42.5844 39.1681C42.3651 38.9874 42.0672 38.897 41.6908 38.897C41.4597 38.897 41.2492 38.9207 41.0596 38.9681C40.8699 39.0155 40.6921 39.0807 40.5261 39.1637C40.3601 39.2467 40.1942 39.343 40.0282 39.4527L39.6548 38.9459C39.8919 38.7621 40.1808 38.6036 40.5216 38.4702C40.8625 38.3368 41.2492 38.2701 41.6819 38.2701C42.3458 38.2701 42.8437 38.4213 43.1757 38.7236C43.5076 39.0259 43.6736 39.4112 43.6736 39.8794Z" fill="white"/>
<path d="M34.3821 39.9016C34.797 39.9016 35.1526 39.9935 35.449 40.1772C35.7454 40.361 35.9721 40.6188 36.1292 40.9508C36.2863 41.2797 36.3648 41.665 36.3648 42.1066V42.5645H32.9995C33.0084 43.1365 33.1507 43.5722 33.4263 43.8715C33.7019 44.1709 34.0902 44.3205 34.591 44.3205C34.8992 44.3205 35.1719 44.2924 35.409 44.2361C35.6461 44.1797 35.8921 44.0968 36.147 43.9871V44.6362C35.901 44.7458 35.6565 44.8258 35.4135 44.8762C35.1734 44.9266 34.8889 44.9518 34.5599 44.9518C34.0916 44.9518 33.6826 44.857 33.3329 44.6673C32.9862 44.4746 32.7165 44.1931 32.5238 43.8226C32.3312 43.4521 32.2349 42.9987 32.2349 42.4623C32.2349 41.9377 32.3223 41.4842 32.4972 41.1019C32.675 40.7166 32.9239 40.4203 33.244 40.2128C33.5671 40.0053 33.9464 39.9016 34.3821 39.9016ZM34.3732 40.5062C33.979 40.5062 33.6649 40.6351 33.4307 40.893C33.1966 41.1508 33.0573 41.5109 33.0128 41.9733H35.5913C35.5883 41.6828 35.5424 41.4279 35.4535 41.2086C35.3675 40.9863 35.2356 40.8144 35.0578 40.6929C34.88 40.5684 34.6518 40.5062 34.3732 40.5062Z" fill="white"/>
<path d="M29.2296 39.9016C29.8075 39.9016 30.2447 40.0439 30.5411 40.3284C30.8374 40.6099 30.9856 41.0634 30.9856 41.6887V44.8629H30.2565V41.7376C30.2565 41.3316 30.1632 41.0278 29.9765 40.8263C29.7927 40.6248 29.5097 40.524 29.1274 40.524C28.588 40.524 28.2086 40.6766 27.9893 40.9819C27.77 41.2872 27.6603 41.7302 27.6603 42.3111V44.8629H26.9224V39.9905H27.5181L27.6292 40.6929H27.6692C27.773 40.521 27.9048 40.3773 28.0649 40.2617C28.2249 40.1432 28.4042 40.0542 28.6028 39.995C28.8014 39.9327 29.0103 39.9016 29.2296 39.9016Z" fill="white"/>
<path d="M25.3574 39.9905V44.8629H24.6194V39.9905H25.3574ZM24.9973 38.1678C25.1188 38.1678 25.2225 38.2079 25.3085 38.2879C25.3974 38.3649 25.4419 38.4864 25.4419 38.6524C25.4419 38.8154 25.3974 38.9369 25.3085 39.017C25.2225 39.097 25.1188 39.137 24.9973 39.137C24.8699 39.137 24.7632 39.097 24.6772 39.017C24.5942 38.9369 24.5527 38.8154 24.5527 38.6524C24.5527 38.4864 24.5942 38.3649 24.6772 38.2879C24.7632 38.2079 24.8699 38.1678 24.9973 38.1678Z" fill="white"/>
<path d="M23.0593 44.8629H22.3169V37.9456H23.0593V44.8629Z" fill="white"/>
<path d="M16.9863 39.9905H17.7776L18.8535 42.8179C18.9157 42.9838 18.9735 43.1439 19.0268 43.298C19.0802 43.4491 19.1276 43.5944 19.1691 43.7337C19.2106 43.873 19.2432 44.0078 19.2669 44.1382H19.298C19.3395 43.99 19.3988 43.7959 19.4759 43.5558C19.5529 43.3128 19.6359 43.0653 19.7248 42.8134L20.7428 39.9905H21.5386L19.4181 45.5786C19.3054 45.8779 19.1736 46.1387 19.0224 46.361C18.8742 46.5833 18.6934 46.7537 18.48 46.8722C18.2667 46.9938 18.0088 47.0545 17.7065 47.0545C17.5672 47.0545 17.4442 47.0456 17.3375 47.0278C17.2308 47.013 17.139 46.9952 17.0619 46.9745V46.3832C17.1271 46.398 17.2056 46.4114 17.2975 46.4232C17.3924 46.4351 17.4902 46.441 17.5909 46.441C17.7747 46.441 17.9332 46.4055 18.0666 46.3343C18.2029 46.2662 18.32 46.1654 18.4178 46.032C18.5156 45.8987 18.6001 45.7401 18.6712 45.5563L18.9379 44.8717L16.9863 39.9905Z" fill="white"/>
<path d="M16.2442 39.9016C16.342 39.9016 16.4442 39.9075 16.5509 39.9194C16.6576 39.9283 16.7525 39.9416 16.8354 39.9594L16.7421 40.644C16.6621 40.6233 16.5731 40.607 16.4753 40.5951C16.3775 40.5833 16.2857 40.5773 16.1997 40.5773C16.0041 40.5773 15.8189 40.6173 15.644 40.6974C15.4721 40.7744 15.321 40.887 15.1906 41.0352C15.0602 41.1805 14.9579 41.3568 14.8838 41.5643C14.8097 41.7688 14.7727 41.997 14.7727 42.2489V44.8629H14.0303V39.9905H14.6438L14.7238 40.8885H14.7549C14.8557 40.7077 14.9772 40.5433 15.1194 40.3951C15.2617 40.2439 15.4262 40.1239 15.6129 40.035C15.8026 39.9461 16.013 39.9016 16.2442 39.9016Z" fill="white"/>
<path d="M12.7545 42.4178C12.7545 42.8179 12.7026 43.175 12.5989 43.4892C12.4952 43.8033 12.3455 44.0686 12.1499 44.285C11.9543 44.5013 11.7172 44.6673 11.4386 44.7829C11.163 44.8955 10.8503 44.9518 10.5006 44.9518C10.1746 44.9518 9.87523 44.8955 9.60256 44.7829C9.33287 44.6673 9.09873 44.5013 8.90016 44.285C8.70456 44.0686 8.55193 43.8033 8.44227 43.4892C8.33557 43.175 8.28223 42.8179 8.28223 42.4178C8.28223 41.8843 8.37262 41.4309 8.55341 41.0575C8.73419 40.6811 8.99204 40.3951 9.32694 40.1995C9.6648 40.0009 10.0664 39.9016 10.5317 39.9016C10.9762 39.9016 11.3645 40.0009 11.6964 40.1995C12.0313 40.398 12.2907 40.6855 12.4744 41.0619C12.6611 41.4353 12.7545 41.8873 12.7545 42.4178ZM9.04687 42.4178C9.04687 42.809 9.09873 43.1484 9.20246 43.4358C9.30619 43.7233 9.46623 43.9456 9.68258 44.1027C9.89894 44.2598 10.1775 44.3383 10.5184 44.3383C10.8562 44.3383 11.1333 44.2598 11.3497 44.1027C11.569 43.9456 11.7305 43.7233 11.8342 43.4358C11.938 43.1484 11.9898 42.809 11.9898 42.4178C11.9898 42.0296 11.938 41.6947 11.8342 41.4131C11.7305 41.1286 11.5705 40.9093 11.3541 40.7552C11.1378 40.601 10.8577 40.524 10.5139 40.524C10.0071 40.524 9.63517 40.6914 9.39807 41.0263C9.16393 41.3612 9.04687 41.8251 9.04687 42.4178Z" fill="white"/>
<path d="M6.88182 44.3473C7.00333 44.3473 7.12781 44.3369 7.25525 44.3162C7.38269 44.2954 7.48642 44.2702 7.56644 44.2406V44.8141C7.48049 44.8526 7.36046 44.8852 7.20634 44.9119C7.05519 44.9386 6.90701 44.9519 6.76179 44.9519C6.50394 44.9519 6.26981 44.9074 6.05938 44.8185C5.84896 44.7267 5.68003 44.5725 5.55259 44.3562C5.42811 44.1398 5.36587 43.8405 5.36587 43.4582V40.5686H4.67236V40.2085L5.37032 39.9195L5.66373 38.8615H6.10829V39.9906H7.53532V40.5686H6.10829V43.436C6.10829 43.7412 6.17793 43.9694 6.31723 44.1206C6.45949 44.2717 6.64768 44.3473 6.88182 44.3473Z" fill="white"/>
<path d="M4.08993 43.1336C4.08993 43.5189 3.99361 43.8479 3.80097 44.1205C3.61129 44.3902 3.34456 44.5962 3.00077 44.7385C2.65698 44.8807 2.25243 44.9519 1.78712 44.9519C1.54113 44.9519 1.30848 44.94 1.08917 44.9163C0.869852 44.8926 0.668319 44.8585 0.484568 44.814C0.300818 44.7696 0.139295 44.7148 0 44.6496V43.9249C0.222279 44.0168 0.493459 44.1027 0.813541 44.1828C1.13362 44.2598 1.46852 44.2983 1.81824 44.2983C2.14425 44.2983 2.41988 44.2554 2.64512 44.1694C2.87036 44.0805 3.04078 43.9546 3.15636 43.7916C3.27491 43.6256 3.33419 43.427 3.33419 43.1958C3.33419 42.9736 3.28528 42.7883 3.18748 42.6401C3.08968 42.489 2.92667 42.3527 2.69847 42.2312C2.47322 42.1067 2.165 41.9748 1.77379 41.8355C1.49816 41.7377 1.25514 41.631 1.04471 41.5154C0.834287 41.3969 0.657946 41.2635 0.515687 41.1153C0.373429 40.9671 0.265253 40.7952 0.19116 40.5996C0.120031 40.404 0.084466 40.1803 0.084466 39.9283C0.084466 39.5816 0.171896 39.2852 0.346755 39.0392C0.524579 38.7903 0.769085 38.6006 1.08028 38.4702C1.39443 38.3368 1.75452 38.2701 2.16055 38.2701C2.50731 38.2701 2.82739 38.3027 3.1208 38.3679C3.41717 38.4331 3.68835 38.5206 3.93434 38.6302L3.69872 39.2793C3.46459 39.1815 3.21564 39.1 2.95187 39.0348C2.69106 38.9696 2.42136 38.937 2.14277 38.937C1.86418 38.937 1.62856 38.9785 1.43592 39.0615C1.24624 39.1415 1.10102 39.2556 1.00026 39.4038C0.899489 39.552 0.849106 39.7283 0.849106 39.9328C0.849106 40.161 0.896525 40.3507 0.991364 40.5018C1.08917 40.653 1.24328 40.7878 1.4537 40.9064C1.66709 41.022 1.94865 41.1435 2.29837 41.2709C2.68069 41.4102 3.00521 41.5584 3.27195 41.7155C3.53868 41.8696 3.7417 42.0593 3.88099 42.2845C4.02029 42.5068 4.08993 42.7898 4.08993 43.1336Z" fill="white"/>
</svg>


   
);

export default MyTrainingTooltipStoryLine
