import * as React from "react"

function SvgComponent() {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 28.3 28.3"
      xmlSpace="preserve"
    >
      <style>{".prefix__st0{fill:#fff}.prefix__st1{fill:red}"}</style>
      <path
        className="prefix__st0"
        d="M2 0h24.3c1.1 0 2 .9 2 2v24.3c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0z"
      />
      <path
        className="prefix__st1"
        d="M0 2C0 .9.9 0 2 0h24.3c1.1 0 2 .9 2 2v1H0V2zM0 10.1h28.3v3H0zM0 5h28.3v3H0z"
      />
      <path d="M0 2C0 .9.9 0 2 0h12.6v13.1H0V2z" fill="#00008b" />
      <path
        className="prefix__st1"
        d="M0 20.2h28.3v3H0zM0 25.2h28.3v1c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2v-1zM0 15.1h28.3v3H0z"
      />
      <path
        className="prefix__st0"
        d="M2.4 1l.3 1h1.1l-.8.7.3 1-.9-.6-.8.6.3-1-.9-.6h1.1L2.4 1zM2.4 5l.3 1h1.1l-.8.7.3 1-.9-.6-.8.7.3-1-.9-.7h1.1L2.4 5zM2.4 9.1l.3 1h1.1l-.8.7.3 1-.9-.6-.9.6.3-1-.8-.7h1.1l.3-1zM7.3 1l.3 1h1.1l-.9.7.3 1-.8-.6-.9.6.3-1-.8-.6H7L7.3 1zM7.3 5l.3 1h1.1l-.9.7.3 1-.8-.6-.9.7.3-1-.8-.7H7L7.3 5zM7.3 9.1l.3 1h1.1l-.9.6.3 1-.9-.6-.9.6.3-1-.9-.6H7l.3-1zM12.2 1l.3 1h1.1l-.9.6.3 1-.9-.6-.9.6.3-1-.9-.6h1.1l.5-1zM12.2 5l.3 1h1.1l-.9.6.3 1-.9-.6-.9.6.3-1-.9-.6h1.1l.5-1zM12.2 9.1l.3 1h1.1l-.9.6.3 1-.9-.6-.9.6.3-1-.9-.6h1.1l.5-1z"
      />
    </svg>
  )
}

export default SvgComponent
