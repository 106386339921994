import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const MoveIconMenu = () => (    

<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 19.2404L12 22.2404L9 19.2404" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19 9.24036L22 12.2404L19 15.2404" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 9.24036L2 12.2404L5 15.2404" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2 12.2404H22" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 5.24036L12 2.24036L15 5.24036" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 2.24036V22.2404" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);

export default MoveIconMenu
