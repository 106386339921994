import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const ViewAccountsIcon = () => (    
<svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M7.47546 8.47967C7.47546 10.7891 9.34787 12.6615 11.6573 12.6615C13.9667 12.6615 15.8391 10.7891 15.8391 8.47967C15.8391 6.17026 13.9667 4.29785 11.6573 4.29785C9.34787 4.29785 7.47546 6.17026 7.47546 8.47967ZM9.56637 8.47967C9.56637 9.6349 10.5021 10.5706 11.6573 10.5706C12.8125 10.5706 13.7482 9.6349 13.7482 8.47967C13.7482 7.32444 12.8125 6.38876 11.6573 6.38876C10.5021 6.38876 9.56637 7.32444 9.56637 8.47967Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23.1571 11.6182C23.1571 5.26703 18.0082 0.118164 11.6571 0.118164C5.30597 0.118164 0.157104 5.26703 0.157104 11.6182C0.157104 17.9693 5.30597 23.1182 11.6571 23.1182C12.0442 23.1182 12.4269 23.099 12.8042 23.0617C12.5084 22.4243 12.3057 21.7349 12.2135 21.0111C12.0294 21.0218 11.8439 21.0273 11.6571 21.0273C9.36965 21.0273 7.27247 20.2108 5.64156 18.8538C6.97033 17.0044 9.13965 15.8 11.5902 15.8C12.2384 15.8 12.8669 15.8843 13.4653 16.0425C13.9224 15.4053 14.4839 14.848 15.1247 14.3956C14.0334 13.9529 12.8403 13.7091 11.5902 13.7091C8.57825 13.7091 5.89769 15.1236 4.17583 17.3243C2.96623 15.7414 2.24801 13.7634 2.24801 11.6182C2.24801 6.42121 6.46015 2.20907 11.6571 2.20907C16.8541 2.20907 21.0662 6.42121 21.0662 11.6182C21.0662 12.206 21.0123 12.7812 20.9091 13.3392C21.6115 13.5202 22.2712 13.8075 22.87 14.1829C23.0579 13.3582 23.1571 12.4998 23.1571 11.6182Z" fill="white"/>
<circle cx="19.1571" cy="20.1182" r="5.5" stroke="white"/>
<path d="M26.9478 26.7224L23.7287 23.5033C24.4638 22.5223 24.9049 21.3092 24.9049 19.9921C24.9049 16.753 22.2701 14.1182 19.031 14.1182C15.792 14.1182 13.1571 16.753 13.1571 19.9921C13.1571 23.2311 15.792 25.866 19.031 25.866C20.3482 25.866 21.5613 25.4249 22.5422 24.6898L25.7613 27.9089L26.9478 26.7224ZM13.7165 19.9921C13.7165 17.0616 16.1005 14.6776 19.031 14.6776C21.9615 14.6776 24.3455 17.0616 24.3455 19.9921C24.3455 22.9226 21.9615 25.3066 19.031 25.3066C16.1005 25.3066 13.7165 22.9226 13.7165 19.9921ZM23.1055 24.2137C23.1553 24.1656 23.2043 24.1167 23.2524 24.0669C23.2932 24.0246 23.3374 23.9858 23.3769 23.9424L26.1568 26.7224L25.761 27.1182L22.9814 24.3379C23.0247 24.2985 23.0633 24.2543 23.1055 24.2137Z" fill="white"/>
</svg>
);

export default ViewAccountsIcon
