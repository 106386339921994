import React from 'react'
import Checkbox from '@material-ui/core/Checkbox';
import { CourseTreeItem } from '../../globalTypes'
import styles from '../../styles/treeComponent.module.css'


interface Props {
    uniquePrefix: string
    courseList: Array<CourseTreeItem>
    updateSelectedCourse: Function
}

const CourseListTree = ({ courseList, updateSelectedCourse, uniquePrefix }: Props) => {

    const handleSelection = (event: any, id: number) => {
        event.stopPropagation();
        updateSelectedCourse(id)
    }

    return (
		<ul className={styles.treeUl}>
			{ !!courseList && courseList.map((course) => {
				return (
					<li key={course.courseID}  hidden={course.isHidden === true} className={styles.treeLi} onClick={(e) => handleSelection(e, course.courseID)}  id={uniquePrefix+course.name.replaceAll(" ", "_s_")}>
						<div className={styles.treeRow}>
							<Checkbox 
								color="primary" 
                                checked={course.isSelected} 
                                style={{ padding: "3px" }} 
                                onClick={(e) => handleSelection(e, course.courseID)}
                                />
							<div>
								<span> {course.name} </span>
							</div>
						</div>
					</li>
				);
			})}
		</ul>
	);
}

export default CourseListTree
