import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const ManageTipVideosIcon = () => (    
<svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.0533 8.65184C29.8949 8.01905 29.5723 7.43927 29.1182 6.97105C28.664 6.50283 28.0943 6.16275 27.4666 5.98517C25.1733 5.42517 15.9999 5.42517 15.9999 5.42517C15.9999 5.42517 6.82662 5.42517 4.53328 6.0385C3.90561 6.21608 3.33592 6.55616 2.88174 7.02438C2.42757 7.4926 2.105 8.07238 1.94662 8.70517C1.5269 11.0326 1.3216 13.3936 1.33328 15.7585C1.31832 18.1412 1.52364 20.5202 1.94662 22.8652C2.12123 23.4783 2.45102 24.036 2.90414 24.4845C3.35726 24.9329 3.91838 25.2569 4.53328 25.4252C6.82662 26.0385 15.9999 26.0385 15.9999 26.0385C15.9999 26.0385 25.1733 26.0385 27.4666 25.4252C28.0943 25.2476 28.664 24.9075 29.1182 24.4393C29.5723 23.9711 29.8949 23.3913 30.0533 22.7585C30.4698 20.4486 30.675 18.1056 30.6666 15.7585C30.6816 13.3758 30.4763 10.9968 30.0533 8.65184V8.65184Z" stroke="#21394F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13 20.1184L20.6667 15.7584L13 11.3984V20.1184Z" stroke="#21394F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);

export default ManageTipVideosIcon
