//React + Typescript
import React, { useContext, useState } from "react";
// Libs
import { makeStyles } from '@material-ui/core/styles';
//Contexts
import { UIContext } from '../contexts/UIContext';
import { TranslationsContext } from '../contexts/TranslationsContext'
import { CorperateThemeContext } from '../contexts/CorperateThemeContext';
//Components
import BrandedCard from "../Components/BrandedCard";
//MUI Components
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
// import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
//Styles
import styles from "../styles/courseCompletionPanel.module.css";
import PieChart from "../Components/HighChart/MyTraining_PieChartComponent";

import responsivestyles from '../styles/responsiveGridLayout.module.css'
const useStyles = makeStyles({
  backgroundGrey: {
    color: "#d4d4d4", //Progress background style 
    position: "absolute"
  },
  complete: {
    color: "#3bb18b", //Progress completion style 
  },
});

interface Props {
  assignedCoursesCount: number;
  completedCoursesCount: number;
  totalCourseProgress: number;
  assignedQuizesCount: number;
  passedQuizesCount: number;
  totalQuizProgress: number;
  lessonCompletionProgress: number;
  policyAcceptanceProgress: number;

  CertificateProgress?:number
}

export const CourseCompletionPanel = ({
    assignedCoursesCount, 
    totalCourseProgress, 
    assignedQuizesCount,
    totalQuizProgress,
    policyAcceptanceProgress,
    CertificateProgress,
}: Props): JSX.Element => {
  const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
  const { showPolicyUI, showQuizUI , showMyCertificateUI } = useContext(UIContext);
  const classes = useStyles();
  const courseDisplayPercentage = Math.round(totalCourseProgress);
  const mycertificateProgressPercentage = Math.round((CertificateProgress != undefined && CertificateProgress != null) ? CertificateProgress : 0);
  const quizDisplayPercentage = Math.round(totalQuizProgress) 
  const { theme: { color }} = useContext(CorperateThemeContext); 
  const isComplete = (score: number | undefined) => {
    if (score !== undefined){
      if(score === 100) {
        return true
      }
      return false 
    } else {
      return false
    }
  }
  let totalCard =0 ;
  if(assignedCoursesCount > 0 ){totalCard++; }
  if(showQuizUI){totalCard++; }
  if(showPolicyUI){totalCard++;}
  if(showMyCertificateUI){totalCard++;}

  return (

      <>
  
      <BrandedCard>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ paddingLeft: "20px" }}>
                {allResourcesToTranslations.myprogress}
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <div className={responsivestyles.rowrule} style={{ width: '100%', height:"180px"  }}>
          {assignedCoursesCount > 0 &&
          <div className={(totalCard === 4) ? `${responsivestyles.columnrule_3} ${responsivestyles.columnrule_s_6} course`: (totalCard === 3) ? `${responsivestyles.columnrule_4} ${responsivestyles.columnrule_s_6} course` :  (totalCard === 2) ? `${responsivestyles.columnrule_s_6} course` :`course`} style={{ minWidth: "150px"}}>
            <p className={styles.progressText}>
              {allResourcesToTranslations.coursecompletion}
            </p><PieChart ChartTitle={allResourcesToTranslations.coursecompletion} DisplayPercentage={courseDisplayPercentage} themecolor={color} />
          </div>
          }
          {showQuizUI &&
            <div className={(totalCard === 4) ? `${responsivestyles.columnrule_3} ${responsivestyles.columnrule_s_6} quiz`: (totalCard === 3) ? `${responsivestyles.columnrule_4} ${responsivestyles.columnrule_s_6} quiz`:  (totalCard === 2) ? `${responsivestyles.columnrule_s_6} quiz` :`quiz`} style={{ minWidth: "150px" }}>
              <p className={styles.progressText}>
                {allResourcesToTranslations.quizcompletion}
              </p>
              <PieChart ChartTitle={allResourcesToTranslations.quizcompletion} DisplayPercentage={quizDisplayPercentage} themecolor={color} />
            </div>
          }
          {showPolicyUI &&
          <div className={(totalCard === 4) ? `${responsivestyles.columnrule_3} ${responsivestyles.columnrule_s_6} policy`: (totalCard === 3) ? `${responsivestyles.columnrule_4} ${responsivestyles.columnrule_s_6} policy`:  (totalCard === 2) ? `${responsivestyles.columnrule_s_6} policy` :`policy`} style={{ minWidth: "150px" }}>
              <p className={styles.progressText}>
                {allResourcesToTranslations.policyacceptance}
              </p>
              <PieChart ChartTitle={allResourcesToTranslations.policyacceptance} DisplayPercentage={policyAcceptanceProgress} themecolor={color} />
            </div>
          }
          {showMyCertificateUI &&
          <div className={(totalCard === 4) ? `${responsivestyles.columnrule_3} ${responsivestyles.columnrule_s_6} mycertificate`: (totalCard === 3) ? `${responsivestyles.columnrule_4} ${responsivestyles.columnrule_s_6} mycertificate`: (totalCard === 2) ? `${responsivestyles.columnrule_s_6} mycertificate` :`mycertificate`} style={{ minWidth: "150px" }}>
              <p className={styles.progressText}>
                {allResourcesToTranslations.extcertificate}
              </p>
              <PieChart ChartTitle={allResourcesToTranslations.extcertificate} DisplayPercentage={mycertificateProgressPercentage} themecolor={color} />
          </div>
          }
        </div>
        {/* <div className={styles.progressContainer} style={{ display: "none" }}>
          <div className={`${styles.progressGauge} course` }>
            <Box position="relative" display="inline-flex">
            <CircularProgress
                variant="determinate"
                value={100}
                size={50}
                className={classes.backgroundGrey}
              />
              <CircularProgress
                variant="determinate"
                value={assignedCoursesCount <= 0 || assignedCoursesCount === undefined ? 0 : courseDisplayPercentage}
                size={50}
                className={isComplete(courseDisplayPercentage)? classes.complete : undefined}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <span className={isComplete(courseDisplayPercentage)? styles.progressPercentageComplete : styles.progressPercentage}>
                  {assignedCoursesCount <= 0 || assignedCoursesCount === undefined ? `-` : `${courseDisplayPercentage}%`}
                </span>
              </Box>
            </Box>
            <div>
              <span className={styles.progressText}>
                {allResourcesToTranslations.coursecompletion}
              </span>
            </div>
          </div>
          <div className={`${styles.progressGauge} quiz`} style={{ display: showQuizUI? "block" : "none" }}>
            <Box position="relative" display="inline-flex">
            <CircularProgress
                variant="determinate"
                value={100}
                size={50}
                className={classes.backgroundGrey}
              />
              <CircularProgress
                variant="determinate"
                value={assignedQuizesCount <= 0 || assignedQuizesCount === undefined ? 0 : quizDisplayPercentage}
                size={50}
                className={isComplete(quizDisplayPercentage)? classes.complete : undefined}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <span className={isComplete(quizDisplayPercentage)? styles.progressPercentageComplete : styles.progressPercentage}>
                  {assignedQuizesCount <= 0 || assignedQuizesCount === undefined ? `-` : `${quizDisplayPercentage}%`}
                </span>
              </Box>
            </Box>
            <div>
              <span className={styles.progressText}>
                {allResourcesToTranslations.quizcompletion}
              </span>
            </div>
          </div>
          <div className={`${styles.progressGauge} policy`} style={{ display: showPolicyUI? "block" : "none"}}>
            <Box position="relative" display="inline-flex">
            <CircularProgress
                variant="determinate"
                value={100}
                size={50}
                className={classes.backgroundGrey}
              />
              <CircularProgress
                variant="determinate"
                value={policyAcceptanceProgress === undefined || isNaN(policyAcceptanceProgress) ? 0 : policyAcceptanceProgress}
                size={50}
                className={isComplete(policyAcceptanceProgress)? classes.complete : undefined}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <span className={ isComplete(policyAcceptanceProgress)? styles.progressPercentageComplete : styles.progressPercentage}>
                  { isNaN(policyAcceptanceProgress) ? `-` : `${policyAcceptanceProgress}%` }
                </span>
              </Box>
            </Box>
            <div>
              <span className={styles.progressText}>
                {allResourcesToTranslations.policyacceptance}
              </span>
            </div>
          </div>
    </div> */}
      </BrandedCard></>
  );


};

export default CourseCompletionPanel;
