import React, { useState, useEffect, useContext } from "react";
import { UnitTreeItem } from "../../../globalTypes";

//libs
import { Steps } from "intro.js-react";

//MUI
import { 
  TextField,
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Button, CircularProgress ,
} from "@material-ui/core";

//Contexts
import { UIContext } from "../../../contexts/UIContext";
import { LoaderContext } from "../../../contexts/LoaderContext";

//API Functions
import { getRelevantUnitTree } from "../../../API/GetRelevantUnitTree";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import addOrUpdateUnit from "../../../API/Administration/Unit/AddOrUpdateUnit";
import getOneUnitDetails from "../../../API/Administration/Unit/GetOneUnitDetails";
//Components
import UnbrandedCard from "../../../Components/UnbrandedCard";
import LoaderContainer from "../../../Components/LoaderContainer";
import CustomTooltip from "../../../Components/CustomTooltip";
import GuideIcon from "../../../CustomIcons/GuideIcon";

//Styles
import styles from "../../../styles/addUnit.module.css";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { useHistory } from "react-router-dom";
import SaveIcon from "../../../CustomIcons/SaveIcon";

import RadioUnits from "./RadioUnits";
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';


const AddUnit = () => {
  const { setMessage } = useContext(LoaderContext);
  const history = useHistory();
  const {
    translations: { allResourcesToTranslations }, pageTitles,
  } = useContext(TranslationsContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute, IsEbookEnabled, EnableActivityLogs, timeZoneId } =
    useContext(UIContext);
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
      .toLowerCase()
      .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";

  const [units, setUnits] = useState<Array<UnitTreeItem>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [unitName, setUnitName] = useState<string>(""); 
  const [accountActive, setAccountActive] = useState<boolean>(true);
  const [isResetClicked, setisResetClicked] = useState<boolean>(false); ;
  const [isSaving, setIsSaving] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);  
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);

  const [selectedUnitCallback, setSelectedUnitCallback] = useState<number>(0);
  const [selectedunitlevel, setSelectedunitlevel] = useState<any>("toplevel");
   const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".unitName",
        title: "Units",
        intro: "Enter a name for the new unit.",
      },     
    ],
  });

  const setGuideMe = () => {
    let updateSteps = [...intro.steps];
    updateSteps.push(
      
      {
        element: ".unitoption",
        title: "Unit option",
        intro: "Select 'Add a top level unit' to add a new top level unit to your existing unit tree, select 'Add a sub unit of' to add the new unit as a sub unit of the selected unit below.",
      },
      {
        element: ".unitFilterButtons",
        title: "Units",
        intro: "Expand or Collapse the existing unit tree below.",
      },
      {
        element: ".unitFilterSearch",
        title: "Filter Units",
        intro: "Search unit/subunits by typing unit name.",
      },
      {
        element: ".unittreeContianer",
        title: "Units",
        intro: "The current unit tree is displayed here, if 'Add a sub unit of' is selected above, choose a unit to add the new unit as a sub unit of.",
      },
      {
        element: ".saveData",
        title: "Save",
        intro: "Click Save to create the new unit.",
      }
      );
   

    setIntro({ ...intro, steps: [...updateSteps] });
  };

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  useEffect(() => {
    setTitle(allResourcesToTranslations.addunit);
    setTitleEnglish(pageTitles.addunit);
    updateBackButtonRoute("");
    setLoading(true);
    getRelevantUnitTree(
      oidcUser.access_token,
      userId,
      orgDomain,
      true,
      parseInt(orgId)
    ).then((unitTreeRes) => {
      if (unitTreeRes.isSuccess) {
        setUnits(unitTreeRes.unitTreeItems);
        setLoading(false);
        setGuideMe();
      }
    });
  
  }, []);

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  const handleSubmit = () => {
    let errorString = '';
    if (selectedUnitCallback === 0 && selectedunitlevel != "toplevel") {
      errorString = errorString + 'Please select the unit;'
    }
    if (errorString !== '') {
      setShowWarningModal(true);
      setModalPopupMessage(errorString);
      setHasErrored(true);
      return;
    }
    setIsSaving(true);
    setSaveClicked(true);
    addOrUpdateUnit(
       "add",
       oidcUser.access_token,
       userId,
       parseInt(orgId),
       orgDomain,
       selectedUnitCallback,
       unitName,      
       accountActive      
     ).then((result) => {
       if (result !== null) {
         if (result.isSuccess && result.error == "" && result.addedUnitID != -1) {
          
           setShowWarningModal(true);
           setModalPopupMessage('Unit Added successfully!');
           setSaveSuccess(true);
           setHasErrored(false);
           setIsSaving(false);
           setSaveClicked(false);          
           getOneUnitDetails(oidcUser.access_token,
            userId,
            orgDomain,
            parseInt(orgId), parseInt(result.addedUnitID)).then((result) => {
              if (result.isSuccess && result.error == null) {
                if (result.unitDetailsItems !== null && result.unitDetailsItems.length > 0) {
                  let payload = {
                    unitData: {                      
                      unitID: result.unitDetailsItems[0].unitID,
                      unitIdToUpadate: result.unitDetailsItems[0].unitID,
                      name: result.unitDetailsItems[0].name,
                      parentUnitID: result.unitDetailsItems[0].parentUnitID,
                      hierarchy: result.unitDetailsItems[0].hierarchy,
                      active: result.unitDetailsItems[0].active,                     
                      pathway: result.unitDetailsItems[0].pathway,
                      email: result.unitDetailsItems[0].email,
                      organisation: result.unitDetailsItems[0].organisation,
                      organisationID: result.unitDetailsItems[0].organisationID,
                      createdBy: result.unitDetailsItems[0].createdByUserName,
                      updatedBy: result.unitDetailsItems[0].updatedByUserName,
                      dateCreated: result.unitDetailsItems[0].dateCreated,
                      dateUpdated: result.unitDetailsItems[0].dateUpdated
                    }
                  };

                  localStorage.setItem('unitData', JSON.stringify(payload));
                  history.push({
                    pathname: `unitDetails/` + result.unitDetailsItems[0].unitID,
                    state: payload.unitData,
                  });
                }
              }
            })

         } else {
           setShowWarningModal(true);
           setModalPopupMessage(result.error);          
           setHasErrored(true);
           setIsSaving(false);
           setSaveClicked(false);
           return;
         }
       } else {
         setShowWarningModal(true);
         setModalPopupMessage('Saving record has failed, please try again.');
         setHasErrored(true);
         setIsSaving(false);
         setSaveClicked(false);
         return;
       }
     });
  };

  useEffect(() => {
    if (showWarningModal) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      }, 5000)
    }
  }, [showWarningModal])


  if (loading) return <LoaderContainer />;
  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }}/>
      <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific}>
          <GuideIcon />
        </span>
      </CustomTooltip>

      <div className="unitName" style={{ backgroundColor: "white", height: "45px", paddingBottom: "10px" }}>
				<TextField
					className={styles.input}
					label={"Unit Name"}
					name="UnitName"
					variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
					value={unitName}
					onChange={(e) => setUnitName(e.target.value)}
					placeholder={allResourcesToTranslations.requiredfield}/>
			</div>
      <UnbrandedCard>
      <div className={styles.mainWrapper}>
					<div className={styles.Wrapper}>           
              <div className={styles.unittreesection}>
                <div>
                  <RadioUnits isResetClicked={isResetClicked} givenUnits={units}
                              setSelectedUnitCallback={setSelectedUnitCallback} setSelectedunitlevel={setSelectedunitlevel}/>
                </div>
              </div>    
              <div style={{ clear: "both" }}></div>
              <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", paddingTop: "10px" }}>
                    <CustomTooltip displayText={["Save"]}>
                      <span className="saveData">
                        <Button disabled={(unitName.trim() === ""  || isSaving)}
                          onClick={handleSubmit}
                          variant="contained"
                          color="primary"
                          className="save">
                          <SaveIcon />
                        </Button>
                      </span>
                    </CustomTooltip>
                    <div className={styles.loaderContainer}>
                      <CircularProgress
                        style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
                        color="primary"
                        size="10"/>
                    </div>
              </div>
        </div>
      </div>
      </UnbrandedCard>
      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}      
    </>
  );
};

export default AddUnit;
