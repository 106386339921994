import React, { useContext } from 'react';
import { CorperateThemeContext } from '../contexts/CorperateThemeContext';
import styles from '../styles/brandedCard.module.css';

interface Props {
  children: any;
  minWidth?: string;
}

const BrandedCard = ({children, minWidth}: Props): JSX.Element => {
  const { color } = useContext(CorperateThemeContext).theme;
  const minWidthValue = minWidth !== undefined? minWidth + '%' : "49%";
  return (
    <div className={styles.brandedCard} style={{minWidth: minWidthValue}}>
      {children}
      <div className={styles.colorBar} style={{ backgroundColor: color }} />
    </div>
  );
};

export default BrandedCard;
