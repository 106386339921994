import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const MyTrainingTooltipLectora = () => (
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.8788 23.4579V7.42505C12.8788 6.57084 12.2218 5.91376 11.3676 5.91376C10.5791 5.91376 9.85626 6.57084 9.85626 7.42505V24.9692C9.85626 25.8234 10.5133 26.4805 11.3676 26.4805H28.0575C25.1006 29.8316 20.8296 32 15.9671 32C7.16222 31.9343 0 24.8378 0 15.9671C0 7.16222 7.16222 0 15.9671 0C16.0986 0 16.2957 0 16.4271 0V17.9384C16.4271 18.7269 17.0842 19.4497 17.9384 19.4497C18.7926 19.4497 19.4497 18.7926 19.4497 17.9384V0.328542C26.6119 1.90554 32 8.34497 32 15.9671C32 18.6612 31.3429 21.2238 30.1602 23.4579H12.8788Z" fill="white"/>
</svg>


   
);

export default MyTrainingTooltipLectora
