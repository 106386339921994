import axios from 'axios';
import { addError } from '../../AddError';

const GetCPDProfilesListWithGivenItemPoints = async (token: string, requestinguserId: number, orgDomain: string, orgId: number, itemID: number, itemType: string, langCode: string) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/CPDProfiles/GetCPDProfilesListWithGivenItemPoints`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        "RequestingUserID": requestinguserId,
        "OrgID": orgId,
        "ItemID": itemID,
        "ItemType": itemType
    },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.response, "GetCPDProfilesListWithGivenItemPoints.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.request, "GetCPDProfilesListWithGivenItemPoints.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.message, "GetCPDProfilesListWithGivenItemPoints.ts", 1)
    }
    return null;
  }
};

export default GetCPDProfilesListWithGivenItemPoints;