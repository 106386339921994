import React, { useContext,useEffect,useRef,useState } from 'react'

import { useReactOidc } from '@axa-fr/react-oidc-context';
//Libs
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Components 
import CustomTooltip from '../../Components/CustomTooltip';
import BrandedCard from '../../Components/BrandedCardDashBoard';
//MUI Components
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
// Styles
import styles from '../../styles/dashboard.module.css';

//API Function
import { getOrgSupportDetails } from '../../API/GetOrgSupportDetails';
import addError from '../../API/AddError';
import { UIContext } from '../../contexts/UIContext';

interface Props {
    isAdmin: boolean
}

export const SupportCard = ({ isAdmin }: Props ) => {
    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
    
    const { oidcUser } = useReactOidc();
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
    const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    
    const [defaultReplyToAddress, setdefaultReplyToAddress] = useState<string>("")  
    const [replyToAddress, setreplyToAddress] = useState<string>("")
    const { EnableActivityLogs} = useContext(UIContext);
    
    useEffect(() => {  
        getOrgSupportDetails(oidcUser.access_token, userId, orgDomain, !orgId? -1 : parseInt(orgId)).then(dataResult => {           
            setreplyToAddress(dataResult.supportDetails.replyToAddress);          
            setdefaultReplyToAddress(dataResult.supportDetails.defaultReplyToAddress);              
        }).catch((error:any) => {            
            addError(oidcUser.access_token, userId, orgDomain, "SupportCard getOrgSupportDetails in NEWSALT ClientApp", null, "SupportCard.tsx", 6, EnableActivityLogs);          
          });      
      },[])
    
    
    
    
    return(
        
		<BrandedCard Width='100'>
            <div className="support">
            <div className={styles.showWelcome}>
                <p style={{height:"45px"}}>
                    {allResourcesToTranslations.clickbelowtocontactsupport}
                </p>
                <CustomTooltip displayText={[allResourcesToTranslations.contactsupport]}>
                    {/* <a href="mailto:support@saltcompliance.com"> */}
                    <a href={`mailto:${replyToAddress === null ? defaultReplyToAddress : replyToAddress}`}>
                        <Button variant="contained" color="primary">
                            <EmailIcon />
                        </Button>
                    </a>
                </CustomTooltip>
            </div>
            </div>
        </BrandedCard>
       
    )
}

export default SupportCard;