import React, { useState, useEffect, useContext, } from "react";
import { useHistory } from "react-router-dom";
import { UnitTreeItem, CourseTreeItem } from "../../globalTypes";


//libs
import { Steps, Hints } from "intro.js-react";

//MUI
import {  Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';


//Contexts
import { UIContext } from "../../contexts/UIContext";
import { LoaderContext } from "../../contexts/LoaderContext";
import { CPDPanelContext } from '../../contexts/CPDPanelContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';

//API Functions
import { getRelevantUnitTree } from "../../API/GetRelevantUnitTree";
import { useReactOidc } from "@axa-fr/react-oidc-context";

//Components
import BrandedCard from "../../Components/BrandedCard";
import LoaderContainer from "../../Components/LoaderContainer";
import CustomTooltip from "../../Components/CustomTooltip";
import Units from "../ReportCommonComponents/Units";
import GuideIcon from '../../CustomIcons/GuideIcon';
import ButtonPanel from '../CurrentAdminReport/ButtonPanel'

//Styles
import styles from "../../styles/policybuilder.module.css";
import ExternalCertificateSelection from "../ExternalCertificates/ExternalCertificateSelection";
import getExternalCertificateBasicDetails from "../../API/GetExternalCertificateBasicDetails";
import getFullExternalCertificatesReport from "../../API/GetFullExternalCertificatesReport";


const ExternalCertificatesReport = () => {
  const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext);
  const history = useHistory();
  const { setMessage } = useContext(LoaderContext);
  const { currentLanguage} = useContext(TranslationsContext)
  const { setTitle, setTitleEnglish, updateBackButtonRoute } = useContext(UIContext);
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";

  const [units, setUnits] = useState<Array<UnitTreeItem>>([]);

  const [filterDateFrom, setFilterDateFrom] = useState("");
  const [filterDateTo, setFilterDateTo] = useState("");
  const [searchPolicy, setSearchPolicy] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const [isResetClicked, setisResetClicked] = useState<boolean>(false);
  //const [orgPolicyOptions, setOrgPolicyOptions] = useState<any>([]);
  const [certificatestatus, setCertificatestatus] = useState<any>("3");
  const [filterArchivedCertificates, setFilterArchivedCertificates] = useState<boolean>(false);  
  const [filterFirstName, setFilterFirstName] = useState("");
  const [filterLastName,setFilterLastName] = useState("");
  const [filterUserName,setFilterUserName] = useState("");
  const [filterEmail, setFilterEmail] = useState(""); 
  const [searchExternalCertificate, setSearchExternalCertificate] = useState("");
  const [filterUnitName, setFilterUnitName] = useState("");
  const [orgExternalCertificateOptions, setOrgExternalCertificateOptions] = useState<any>([]);
  const [intro, setIntro] = useState({
      stepsEnabled: false,
      initialStep: 0,
      steps: [
        {
          element: ".unitFilterButtons",
          title: "Units", intro: allResourcesToTranslations.emrepgmunitsm,
        }     
      ]
    })

    useEffect(() => 
    {
                  let updateSteps = [...intro.steps]
                  updateSteps.push( {
                    element: ".unitFilterSearch",
                    title: "Filter Unit", intro: allResourcesToTranslations.unitfiltersearch
                  },
                  {
                      element: ".externalCertificateFilterButtons",
                      title: "External Certificate", intro: allResourcesToTranslations.exrptgmselectcert
                  },
                  {
                      element: ".externalCertificateSearcher",
                      title: "Filter Certificate", intro: allResourcesToTranslations.exrptgmsearchcert
                  },
                  {
                    element:  ".includeClosedCertificate",
                    title: "Filter", intro: allResourcesToTranslations.exrptgmarccert
                 }, 
                  {
                    element: ".certificatestatus",
                    title: "Filter", intro: allResourcesToTranslations.exrptgmarccertstatus
                  },
                  {
                    element: ".dateFrom",
                    title: "Filter", intro: allResourcesToTranslations.emrepgmfdfm
                  },
                  {
                    element: ".dateTo",
                    title: "Filter", intro: allResourcesToTranslations.emrepgmfdtm
                  },
                  {
                    element:  ".firstName",
                    title: "Filter", intro: allResourcesToTranslations.firstnamemsg
                  } ,
                  {
                      element:  ".lastName",
                      title: "Filter", intro: allResourcesToTranslations.lastnamemsg
                  } ,
                  {
                      element:  ".userName",
                      title: "Filter", intro: allResourcesToTranslations.usernamemsg
                  } ,
                  {
                      element:  ".email",
                      title: "Filter", intro: allResourcesToTranslations.emailmsg
                  },
                  {
                      element:  ".runReport",
                      title: "Run Report", intro: allResourcesToTranslations.sntemrepgmmrunrep
                  },
                  {
                    element:  ".resetAll",
                    title: "Reset Filters", intro: allResourcesToTranslations.emrepgmram
                  } 

                  )
                setIntro({...intro, steps: [...updateSteps]})              
      },[])
      
      const onExit = () => {
        setIntro({...intro, stepsEnabled: false });
      };

    
  useEffect(() => {
    setTitle(allResourcesToTranslations.extcertificatereport);
    setTitleEnglish(pageTitles.extcertificatereport)
    updateBackButtonRoute("");
    setLoading(true);
    getRelevantUnitTree(
      oidcUser.access_token,
      userId,
      orgDomain,
      true,
      parseInt(orgId)
    ).then((unitTreeRes) => {
      if (unitTreeRes.isSuccess) {
        setUnits(unitTreeRes.unitTreeItems);       
      
          getExternalCertificateBasicDetails(
              oidcUser.access_token,
              userId,
              orgDomain,
              parseInt(orgId)
            ).then((orgExternalCertificatesRes) => {
              if (!!orgExternalCertificatesRes && !!orgExternalCertificatesRes.isSuccess && orgExternalCertificatesRes.isSuccess) {
                  let updatedItems = [];
                  if(orgExternalCertificatesRes.externalCertificates){
                      updatedItems = orgExternalCertificatesRes.externalCertificates.map((item:any) => {
                      return {
                      ...item,
                      isSelected: false,
                      isHidden: false
                      }                     
                  })
                  }                   
                setOrgExternalCertificateOptions(updatedItems);
                    setLoading(false);
              }
            });
        
      }
    });
  }, []);

  const handlerunReport = () => {

    let unitIds: Array<number> = [];
    let unitData: Array<string> = [];
    let allUnitIds: Array<number> = [];
    setLoading(true);
    setMessage("Fetching your report");
    const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return //Break if no children
      if (unitArray.length === 0) return
      for (let i = 0; i < unitArray.length; i++) {
          const unit = unitArray[i];
          if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) { 
            if (filterUnitName !== "") {
                if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
                unitIds.push(unit.unitID)
                }
            } else {
                unitIds.push(unit.unitID)
            }
          } 
          allUnitIds.push(unit.unitID);
          getSelectedUnitIds(unit.childUnitTreeItems);
      }
    }

    getSelectedUnitIds(units);
    
    setLoading(true);
    setMessage("Fetching your report");

    let dynSelectedExternalCertificateIDs = (!!orgExternalCertificateOptions ? 
      ((orgExternalCertificateOptions.some((op : any) => op.isSelected)) ? 
      orgExternalCertificateOptions.filter((op : any) => op.isSelected).map((op : any) => op.externalCertificateId.toString()).join(",")
          :
          orgExternalCertificateOptions.map((op : any) => op.externalCertificateId.toString()).join(",")
      ) : 
      '');

    const date = new Date()
    const nextDate = new Date()
    nextDate.setDate(nextDate.getDate()+1);
    const dateString = `${date.getUTCFullYear()}-${("0" + (date.getUTCMonth() + 1)).slice(-2)}-${('0' + date.getUTCDate()).slice(-2)}` 
    const nextDateString = formatDate(nextDate); //`${nextDate.getUTCFullYear()}/${("0" + (nextDate.getUTCMonth() + 1)).slice(-2)}/${('0' + (nextDate.getUTCDate())).slice(-2)}` 
    getFullExternalCertificatesReport(oidcUser.access_token, userId, orgDomain, parseInt(orgId), 
    unitIds.length > 0 ? unitIds.toString() : allUnitIds.toString(), 
    dynSelectedExternalCertificateIDs.toString(),
    ((filterDateFrom === null || filterDateFrom === undefined || filterDateFrom.trimLeft().trimRight() === "") ? "" :  filterDateFrom),
    ((filterDateTo === null || filterDateTo === undefined || filterDateTo.trimLeft().trimRight() === "") ? "" : filterDateTo),
    parseInt(certificatestatus),filterArchivedCertificates,filterFirstName,filterLastName,filterUserName,filterEmail).then((reportRes) => {        
        setLoading(false);
        history.push({
            pathname: `externalcertificatesreport/result`,               
            state: {
                includeArchivedCertificates: filterArchivedCertificates,
                selectedUnits: unitData.toString(),                           
                resultItems: (reportRes === null ? null : reportRes.externalCertificatesReportItems),  
                resultSelectedCertificate: (reportRes === null ? null : reportRes.selectedCertificateName), 
                resultExternalCertificatesReportUniqueCertificateListItems: (reportRes === null ? null : reportRes.externalCertificatesReportUniqueCertificateListItems),                
                certificatestatus: certificatestatus,
                certificateIDs: !!orgExternalCertificateOptions ? orgExternalCertificateOptions.filter((o : any)=>o.isSelected).map((o : any)=>o.externalCertificateId.toString()).join(',') : "",
                unitIDs: !!unitIds ? unitIds.join(',') : "",
                inputFilterFirstName: filterFirstName,
                inputFilterLastName: filterLastName,
                inputFilterUserName: filterUserName,
                inputFilterEmail: filterEmail,
                filterDateFrom: ((filterDateFrom === null || filterDateFrom === undefined || filterDateFrom.trimLeft().trimRight() === "") ? "" :  filterDateFrom),
                filterDateTo: ((filterDateTo === null || filterDateTo === undefined || filterDateTo.trimLeft().trimRight() === "") ? "" : filterDateTo),
                resultError: ((reportRes === null || reportRes === undefined || reportRes.isSuccess || reportRes.error === null || reportRes.error === undefined) ? "" : reportRes.error)
            },
        });
      })
  };

  const resetAll = () => {  
    setFilterDateFrom('');
    setFilterDateTo('');
    setSearchPolicy("");
    setCertificatestatus("3");
    if(orgExternalCertificateOptions.length > 0) {
      let update = orgExternalCertificateOptions.map((item:any) => {
        
            return {
                ...item,
                isSelected: false
            }
    })
    setOrgExternalCertificateOptions([...update]);
    }else {
      setOrgExternalCertificateOptions([]);
    }
    setFilterArchivedCertificates(false);
    setisResetClicked(!isResetClicked);
  };

  const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
  {
      if (!isNaN(givenDate.getTime())) {
          const year = new Intl.DateTimeFormat('en-AU', {year: 'numeric'}).format(givenDate);
          const month = new Intl.DateTimeFormat('en-AU', {month: 'numeric'}).format(givenDate);
          const day = new Intl.DateTimeFormat('en-AU', {day: 'numeric'}).format(givenDate);
  
          return (year.toString() + "-" + 
          (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" + 
          (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
      }
      else {
          return "";
      }
  }


  const handleCertificatestatus = (event:any) => {
    const val = (event.target as HTMLInputElement).value;
    setCertificatestatus(val);
  }
    
  const handleExternalCertificateOptionsUpdate = (data:any) => {  
    setOrgExternalCertificateOptions(data);
  }; 

  const handleSelectAllExternalCertificates = () => {
    if(orgExternalCertificateOptions.length != 0) {
      let updatedExternalCertificateOptions = orgExternalCertificateOptions.map((item:any) => {
        return {
          ...item,
          isSelected: true
        }
      })

      setOrgExternalCertificateOptions(updatedExternalCertificateOptions);
    }
  }

  const handleUnSelectAllExternalCertificates = () => {
    if(orgExternalCertificateOptions.length != 0) {
      let updatedExternalCertificateOptions = orgExternalCertificateOptions.map((item:any) => {
        return {
          ...item,
          isSelected: false
        }
      })

      setOrgExternalCertificateOptions(updatedExternalCertificateOptions);
    }
  }

  const handleExternalCertificateSearch = (e:any) => {
      setSearchExternalCertificate(e.target.value);
  }

  if (loading) return <LoaderContainer />;

  return (
    <>
         <Steps  enabled={intro.stepsEnabled}   steps={intro.steps}  initialStep={intro.initialStep}
          onExit={onExit}
            options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
          }}/>
         <CustomTooltip displayText={["Guide Me"]}>
            <span onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
            <GuideIcon />
            </span>
         </CustomTooltip>
    <BrandedCard>
      <div className={styles.reportWrraper}>
        <div className={styles.unittreesection}>
          <div>
            <Units isResetClicked={isResetClicked} givenUnits={units} givenFilterUnitName={filterUnitName} setGivenFilterUnitName={setFilterUnitName}/>
          </div>
        </div>
        
        <div className={styles.rightSection}>  
        {/* <PolicySelection selectAllPolicies={handleSelectAllPolicies} handlePolicySearch={handlePolicySearch} searchPolicy={searchPolicy} deselectAllPolicies={handleUnSelectAllPolicies} orgPolicyOptions={orgPolicyOptions} updatePolicyOptions={handlePolicyOptionsUpdate} sectionFlag={true} />       */}

        
        <ExternalCertificateSelection 
        sectionFlag={(units === null || units.length <= 10)}
        selectAllExternalCertificates={handleSelectAllExternalCertificates} 
        handleExternalCertificateSearch={handleExternalCertificateSearch} 
        searchExternalCertificate={searchExternalCertificate} 
        deselectAllExternalCertificates={handleUnSelectAllExternalCertificates} 
        orgExternalCertificateOptions={orgExternalCertificateOptions} 
        updateExternalCertificateOptions={handleExternalCertificateOptionsUpdate} /> 
        <span className={styles.error} hidden={orgExternalCertificateOptions.length > 0}>{allResourcesToTranslations.emrepgmecam}</span>     
        <div style={{marginTop:'15px'}} className={`${styles.inactiveContainer} includeClosedCertificate`} >                           
                <Checkbox
                    size="small"
                    color="primary"
                    checked={filterArchivedCertificates}
                    className={styles.checkBox}
                    onClick={() => setFilterArchivedCertificates(!filterArchivedCertificates)}
            /> {allResourcesToTranslations.includearchivedcertificates}                             
        </div> 
        <div style={{paddingTop:'15px'}} className="certificatestatus">
              <FormControl component="fieldset" style={{ width: '100%' }} className="recipient">                                
                  <div style={{color:"rgba(0, 0, 0, 0.54)", fontSize:"14px"}}>Certificate upload status</div>
                  <div className={styles.childContainer}>
                      <RadioGroup
                          name="periodType"
                          value={certificatestatus}
                          onChange={handleCertificatestatus}
                          style={{ display: 'flex', flexDirection: 'row' }}
                      >
                          <FormControlLabel
                              value={"1"}
                              control={<Radio color="primary" />}
                              label={"Provided"}
                          />
                          <FormControlLabel
                              value={"2"}
                              control={<Radio color="primary" />}
                              label={"Not Provided"}
                          />
                          <FormControlLabel
                              value={"3"}
                              control={<Radio color="primary" />}
                              label={"Both"}
                          />
                      </RadioGroup>
                  </div>
              </FormControl>
        </div>
      
        <div style={{paddingTop:'15px', color:"rgba(0, 0, 0, 0.54)", fontSize:"14px"}}>Valid Certificate Dates</div>
        <div className={styles.inputContainer}>
                          <div className="dateFrom"><TextField className={styles.input} label={"Date From"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                          type="date" defaultValue={filterDateFrom} onChange={(e) => setFilterDateFrom(formatDate(new Date(e.target.value)))}/></div>
        </div>
        <div className={styles.inputContainer}>
                          <div className="dateTo"><TextField  className={styles.input}  label={"Date To"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                          type="date" defaultValue={filterDateTo} onChange={(e) => setFilterDateTo(formatDate(new Date(e.target.value)))}/></div>
        </div>
        <div style={{paddingTop:'15px', color:"rgba(0, 0, 0, 0.54)", fontSize:"14px"}}>Optional/Additional Filters</div> 
        <div className={styles.inputContainer}>
        <div className="firstName"><TextField  className={styles.input}  label={"First Name"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                value={filterFirstName} onChange={(e) => setFilterFirstName(e.target.value)}/></div>
        </div>
        <div className={styles.inputContainer}>
            <div className="lastName"><TextField   className={styles.input}  label={"Last Name"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}} 
            value={filterLastName} onChange={(e) => setFilterLastName(e.target.value)} /></div>
        </div>
        <div className={styles.inputContainer}>
            <div className="userName"><TextField  className={styles.input}  label={"Username"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}} 
            value={filterUserName} onChange={(e) => setFilterUserName(e.target.value)} /></div>
        </div>
        <div className={styles.inputContainer}>
            <div className="email"><TextField  className={styles.input}  label={"Email"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}} 
            value={filterEmail} onChange={(e) => setFilterEmail(e.target.value)} /></div>
        </div>              
               
        <div>
          <ButtonPanel openReportSchedulerModal={() => false} showScheduler={false} runReport={handlerunReport} resetAll={resetAll}/>          
        </div> 
        </div>

        <div></div>
      </div>
    </BrandedCard>
    </>
  );
};

export default ExternalCertificatesReport;
