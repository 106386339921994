import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const SentEmailIconMenu = () => (
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 16.0757C14.2091 16.0757 16 14.2848 16 12.0757C16 9.86654 14.2091 8.07568 12 8.07568C9.79086 8.07568 8 9.86654 8 12.0757C8 14.2848 9.79086 16.0757 12 16.0757Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16 8.07567V13.0757C16 13.8713 16.3161 14.6344 16.8787 15.197C17.4413 15.7596 18.2044 16.0757 19 16.0757C19.7957 16.0757 20.5587 15.7596 21.1213 15.197C21.6839 14.6344 22 13.8713 22 13.0757V12.0757C21.9999 9.8187 21.2362 7.62815 19.8333 5.8602C18.4303 4.09226 16.4706 2.8509 14.2726 2.33797C12.0747 1.82504 9.76794 2.07072 7.72736 3.03504C5.68677 3.99937 4.03241 5.62563 3.03327 7.6494C2.03413 9.67316 1.74898 11.9754 2.22418 14.1818C2.69938 16.3882 3.90699 18.3689 5.65064 19.8019C7.39429 21.235 9.57144 22.036 11.8281 22.0748C14.0847 22.1136 16.2881 21.3879 18.08 20.0157" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);

export default SentEmailIconMenu
