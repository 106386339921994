import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const SupportDetailSaveIcon = () => (    
<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.7178 21.6088H5.71777C5.18734 21.6088 4.67863 21.3981 4.30356 21.023C3.92849 20.6479 3.71777 20.1392 3.71777 19.6088V5.60876C3.71777 5.07833 3.92849 4.56962 4.30356 4.19455C4.67863 3.81948 5.18734 3.60876 5.71777 3.60876H16.7178L21.7178 8.60876V19.6088C21.7178 20.1392 21.5071 20.6479 21.132 21.023C20.7569 21.3981 20.2482 21.6088 19.7178 21.6088Z" stroke="white" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
<path d="M17.7178 21.6088V13.6088H7.71777V21.6088" stroke="white" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
<path d="M7.71777 3.60876V8.60876H15.7178" stroke="white" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
</svg>

);

export default SupportDetailSaveIcon
