import axios from 'axios';
import { addError } from './AddError';

export const getFullUserDetailReport = async (
    token: string, 
    userId: number,
    orgDomain: string,
    orgId: number,
    unitIds: string,
    includeInactive: boolean
    ) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/userreport/GetFullUserDetailReport`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },

      data: {
        ReportRequestingUserID : userId,
        OrganisationID : orgId,
        UnitIDs : unitIds,
        IncludeInactiveUsers : includeInactive
      }
    });
    const data = await response.data;
  
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      };    
      addError(token, userId, orgDomain, "Rest Exception", error.response, "GetFullUserDetailReport.tsx", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      };    
      addError(token, userId, orgDomain, "Rest Exception", error.request, "GetFullUserDetailReport.tsx", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      };    
      addError(token, userId, orgDomain, "Rest Exception", error.message, "GetFullUserDetailReport.tsx", 1)
    }
    return null;
  }
};

export default getFullUserDetailReport;
