import React from "react";
import { Language } from "../../globalTypes";

interface Props {
    languages: Array<Language> | null;
}

export const LanguageOptions = ({ languages }: Props) => {
    if (languages === null) return null;
    return (
        <>
            {languages.map((lang, i) => {
                return (
                    <option key={"lang-" + i} value={lang.langCode}>
                        {lang.langNameInOwnLanguage}
                    </option>
                );
            })}
        </>
    );
};

export default LanguageOptions;
