//React + Typescript
import React, { useEffect, useState, useContext, useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom';
import { ErrorResourceToLangValue, UserImportResultInfo } from "../../../globalTypes";
//Libs
import { decode } from 'html-entities';
import { useReactOidc,getUserManager } from '@axa-fr/react-oidc-context';
//Intro Lib
import { Steps, Hints } from "intro.js-react";
// import {intro} from   "intro.js";

//Contexts
import { UIContext } from "../../../contexts/UIContext";
import { LoaderContext } from "../../../contexts/LoaderContext";
import { TranslationsContext } from '../../../contexts/TranslationsContext';
import { CorperateThemeContext } from "../../../contexts/CorperateThemeContext";
//Components
import LoaderContainer from '../../../Components/LoaderContainer';
import GuideIcon from "../../../CustomIcons/GuideIcon";
import FileDownloadIcon from "../../../CustomIcons/Administration/ImportAccounts_FileDownloadIcon";
import FileInfoIcon from "../../../CustomIcons/Administration/ImportAccounts_FileInfoIcon";
import CSVFileIcon from "../../../CustomIcons/CSVFileIcon";
import ExcelFileIcon from "../../../CustomIcons/ExcelFileIcon";
import ErrorIcon from "../../../CustomIcons/Administration/errorIcon";
import WarningIcon from "../../../CustomIcons/Administration/warningIcon";
import CloseIcon from '../../../CustomIcons/Administration/closeIcon';
import SaveIcon from "../../../CustomIcons/SaveIcon";
import { ImportUsersReadGivenFile } from '../../../API/Administration/Users/ImportUsersReadGivenFile'
import FileDropBox from './FileDropBox'

//Styles
import styles from '../../../styles/importAccounts.module.css'
import CustomTooltip from '../../../Components/CustomTooltip';
import BrandedCard from '../../../Components/BrandedCard';
import UnbrandedCard from '../../../Components/UnbrandedCard';
import { Button, FormControl, FormControlLabel, InputLabel, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Theme, createStyles, withStyles } from '@material-ui/core';

import CircularProgress from "@material-ui/core/CircularProgress";
import addError from '../../../API/AddError';
import serialToArray from '../../../functions/serialToArray';
import getImportUsersSampleCsv from '../../../API/Administration/Users/GetImportUsersSampleCsv';
import GetImportUsersSampleXlsx from '../../../API/Administration/Users/GetImportUsersSampleXlsx';
import { getExportedXLSXData } from '../../../API/GetExportedXLSXData'
import getColor from '../../../functions/getColor';
import UpArrowIcon from '../../../CustomIcons/UpArrowIcon';
import DownArrowIcon from '../../../CustomIcons/DownArrowIcon';
import LinearProgressWithLabel from '../../../Components/LinearProgressBar';
import LinearProgressBarWithStripes from '../../../Components/LinearProgressBarWithStripes';
import { clear } from 'console';
import getExportedCSVData from '../../../API/GetExportedCSVData';

import CloseWhiteIcon from "@material-ui/icons/Close";
import Modal from '../../../Components/Modal';
import ProceedIcon from '../../../CustomIcons/ProceedIcon';
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';
import ImportUsersPreviewGivenSeqID from '../../../API/Administration/Users/ImportUsersPreviewGivenSeqID';
import ImportUsersCommitGivenSeqID from '../../../API/Administration/Users/ImportUsersCommitGivenSeqID';
import addEmailingReport from '../../../API/AddEmailingReport';
import GetWelcomeEmailTemplate from '../../../API/Administration/Users/GetWelcomeEmailTemplate';
//import TriggerStatusUpdateOrgWiseForToday from '../../../API/Administration/Users/TriggerStatusUpdateOrgWiseForToday';
import TriggerStatusUpdateGivenUsersOnly from '../../../API/Administration/Users/TriggerStatusUpdateGivenUsersOnly';
import DeleteImportUsersSummary from '../../../API/Administration/Users/DeleteImportUsersSummary';


export const ImportAccounts = () => {
  const history = useHistory();
  const { translations: { allResourcesToTranslations }, currentLanguage, pageTitles } = useContext(TranslationsContext)
  const [loading, setLoading] = useState<boolean>(true);
  const { oidcUser,logout } = useReactOidc();
  const { profile: { profile, user_id, website } } = oidcUser || {};
  const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection, EnableActivityLogs,IsLoggingOut ,setIsLoggingOut} = useContext(UIContext);
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = user_id ? parseInt(user_id) : 0;
  const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";

  const { theme: { color }, } = useContext(CorperateThemeContext);
  const { setMessage } = useContext(LoaderContext)
  const [userImportResult, setUserImportResult] = useState<Array<UserImportResultInfo>>([]);
  const [userImportErrorResult, setUserImportErrorResult] = useState<Array<UserImportResultInfo>>([]);
  const [activeAccountsResult, setActiveAccountsResult] = useState<Array<UserImportResultInfo>>([]);
  const [deactiveAccountsResult, setDeactiveAccountsResult] = useState<Array<UserImportResultInfo>>([]);
  const [updatedAccountsResult, setUpdatedAccountsResult] = useState<Array<UserImportResultInfo>>([]);
  const [emailBodyTemplate, setEmailBodyTemplate] = useState<string>("");

  const [enableUserImportErrorResult, setEnableUserImportErrorResult] = useState<boolean>(false);
  const [enableUserImportResult, setEnableUserImportResult] = useState<boolean>(false);
  const [enableactiveAccountsResult, setEnableactiveAccountsResult] = useState<boolean>(false);
  const [enabledeactiveAccountsResult, setEnabledeactiveAccountsResult] = useState<boolean>(false);
  const [enableupdatedAccountsResult, setEnableupdatedAccountsResult] = useState<boolean>(false);


  const [totalErrorUsersCount, setTotalErrorUsersCount] = useState<number>(0);
  const [totalUsersCount, setTotalUsersCount] = useState<number>(0);
  const [totalActiveUsersCount, setTotalActiveUsersCount] = useState<number>(0);
  const [totalUpdatedUsersCount, setTotalUpdatedUsersCount] = useState<number>(0);
  const [totalDeactivatedUsersCount, setTotalDeactivatedUsersCount] = useState<number>(0);
  const [errorResourcesToLangValues, setErrorResourcesToLangValues] = useState<Array<ErrorResourceToLangValue>>([]);
  const [lastErrorResourcesToLangValues, setLastErrorResourcesToLangValues] = useState<Array<ErrorResourceToLangValue>>([]);


  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true);
  const [currentSortBy, setCurrentSortBy] = useState<string>("");

  const [filteruniquefield, setFilteruniquefield] = useState<any>(1);
  const [selectedFile, setSelectedFile] = useState<string>("");
  const [selectedFileName, setSelectedFileName] = useState<string>("")
  const [exporting, setExporting] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [saveProgress, setSaveProgress] = useState<number>(0);
  const [exportError, setExportError] = useState<string>("");
  const [listOfErrors, setListOfErrors] = useState<string>("");
  const [latestUploadedData, setLatestUploadedData] = useState<any>(null);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [enableFileUploading, setenableFileUploading] = useState<boolean>(false);
  const [sortInProgress, setSortInProgress] = useState<boolean>(false);

  const [reverseNextSort, setReverseNextSort] = useState<boolean>(false);
  const [lastnameDescending, setLastnameDescending] = useState<boolean>(false);
  const [firstnameDescending, setFirstnameDescending] = useState<boolean>(false);
  const [usernameDescending, setUsernameDescending] = useState<boolean>(false);

  const [previewProgressStartTime, setPreviewProgressStartTime] = useState<number>(0);
  const [commitProgressStartTime, setcommitProgressStartTime] = useState<number>(0);

  const [totalUsersInFile, setTotalUsersInFile] = useState<number>(0);
  const [totalUsersSavedFromFile, setTotalUsersSavedFromFile] = useState<number>(0);
  const [emailDescending, setEmailDescending] = useState<boolean>(false);
  const [passwordDescending, setPasswordDescending] = useState<boolean>(false);
  const [externalIDDescending, setExternalIDDescending] = useState<boolean>(false);
  const [archiveDescending, setarchiveDescending] = useState<boolean>(false);
  const [unitIDDescending, setUnitIDDescending] = useState<boolean>(false);
  const [classificationNameDescending, setClassificationNameDescending] = useState<boolean>(false);
  const [classificationOptionDescending, setClassificationOptionDescending] = useState<boolean>(false);

  const [notifyUnitAdminDescending, setNotifyUnitAdminDescending] = useState<boolean>(false);
  const [notifyOrgAdminDescending, setNotifyOrgAdminDescending] = useState<boolean>(false);
  const [managerNotificationDescending, setManagerNotificationDescending] = useState<boolean>(false);
  const [managerToNotifyDescending, setManagerToNotifyDescending] = useState<boolean>(false);
  //const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSaveAndCommitCalled, setIsSaveAndCommitCalled] = useState<boolean>(false);
  const [sendWelcomeEmailTo, setSendWelcomeEmailTo] = useState<any>(0);
  const [sendWelcomeEmailTime, setSendWelcomeEmailTime] = useState<any>(0);
  const [importUsersSummaryId, setImportUsersSummaryId] = useState<number>(0);
  const [showWelcomeEmailModal, setShowWelcomeEmailModal] = useState<boolean>(false);
  const [showConfirmModal, setshowConfirmModal] = useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [isFileReadDone, setIsFileReadDone] = useState<boolean>(true);
  const [isSavingData, setIsSavingData] = useState<boolean>(false);
  const [isPreviewingData, setIsPreviewingData] = useState<boolean>(false);
  const [confirmNoteMessage, setConfirmNoteMessage] = useState<string>("By selecting this option, you will enrol all accounts in to Salt."); 


  const [showModal,setModalOpen] = useState<boolean>(false);
  const [goBack,setGoBack] = useState<boolean>(false);

  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".CSVSampleDownload",
        title: "Download Sample CSV File", intro: "Click to download a CSV sample import file.",
      }, {
        element: ".XLSXSampleDownload",
        title: "Download Sample XLSX File", intro: "Click to download a XLSX sample import file.",
      }, {
        element: ".uniquefield",
        title: "Unique Field", intro: "When updating accounts, select a unique field to match existing accounts, this can be email address or username. Account ID is to be used only when updating both username and email address at once.",
      }
    ]
  })

  const setGuideMe = (filename:string) => {    
        
   let updateSteps = [...intro.steps]   
    if (filename!=null && filename !="")
    {
      if(updateSteps.length>3)
      { 
        updateSteps.splice(3, updateSteps.length -3); 
      }
      updateSteps.push({
          element: ".importeduserList",
          title: "Imported Data", intro: "All columns are sortable by clicking on the column title or relevant arrows."  
      },
      {
        element:".saveData",
        title: "Imported Data", intro: "Save uploaded file."  
      },
      {
        element:".cancelData",
        title: "Imported Data", intro: "Cancel uploaded file."  
      }
      );
    }
    else{
      if(updateSteps.length>3)
      { 
        updateSteps.splice(3, updateSteps.length -3); 
      }
      updateSteps.push({
        element: ".fileUpload",
        title: "Upload File", intro: "Drag and drop your completed export file(.CSV\.XLSX) here or browse for a completed export file. Doing so will upload this file for verification and a preview of additions-updates will be shown." 
      });
    }        
    
    setIntro({...intro, steps: [...updateSteps]})        
};

const onExit = () => {
  setIntro({...intro, stepsEnabled: false });
};


  const handleWelcomeEmailModal = (value: boolean) => {
    setShowWelcomeEmailModal(value);
  }
  const handleConfirmModal = (value: boolean) => {
    setshowConfirmModal(value);   
  }

  useEffect(() => {
    setGuideMe("");
    setTitle(allResourcesToTranslations.importaccounts);
    setTitleEnglish(pageTitles.importaccounts);
    updateBackButtonRoute("");
    setMenuSelection(allResourcesToTranslations.supportdetails)
    setLoading(false);
    // at the end, return a cleanup method
    return () => { };
  }, [])

  const handleXLSXSampleDownload = () => {
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started Download Sample XLSX Export in NEWSALT ClientApp",
      null,
      "ImportAccounts.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    GetImportUsersSampleXlsx(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((xlxsRes) => {
      if (xlxsRes.isSuccess) {
        const fileData = xlxsRes.xlsxDataOutput.fileData
        let a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(
          new Blob([serialToArray(atob(fileData))], {
            type: ''
          })
        );

        a.download = xlxsRes.xlsxDataOutput.resultFileFullName;
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)

      }
      else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
        setExportError(xlxsRes.error);
      }
      setExporting(false);
      // Activity log
      addError(oidcUser.access_token, userId, orgDomain, "Completed Download Sample XLSX Export in NEWSALT ClientApp", null, "ImportAccounts.tsx", 6, EnableActivityLogs);
    })
  };

  const handleCSVSampleDownload = () => {
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started Download Sample CSV Export in NEWSALT ClientApp",
      null,
      "ImportAccounts.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getImportUsersSampleCsv(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((csvRes) => {
      if (csvRes.isSuccess) {
        const fileData = csvRes.csvDataOutput.fileData
        let a = window.document.createElement('a');

        a.href = window.URL.createObjectURL(
          new Blob([serialToArray(atob(fileData))], {
            type: ''
          })
        )
        a.download = csvRes.csvDataOutput.resultFileFullName;
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)

      }
      else if (csvRes.error !== undefined && csvRes.error !== null) {
        setExportError(csvRes.error);
      }
      setExporting(false)
      // Activity log
      addError(oidcUser.access_token, userId, orgDomain, "Completed Download Sample CSV Export in NEWSALT ClientApp", null, "ImportAccounts.tsx", 6, EnableActivityLogs);
    })
  };

  useEffect(() => {

    let timeRequiredForCurTotalUsersInSec = 0;
    if (isPreviewingData) {
         // Prepare timeslot for progres
      if (totalUsersInFile < 10) 
        timeRequiredForCurTotalUsersInSec = 2;
      else if (totalUsersInFile >= 10 && totalUsersInFile < 25) 
        timeRequiredForCurTotalUsersInSec = 3;
      else if (totalUsersInFile >= 25 && totalUsersInFile < 100) 
        timeRequiredForCurTotalUsersInSec = 4;
      else if (totalUsersInFile >= 100 && totalUsersInFile < 500) 
        timeRequiredForCurTotalUsersInSec = 5;
      else if (totalUsersInFile >= 500 && totalUsersInFile < 1000) 
        timeRequiredForCurTotalUsersInSec = 7;
      else if (totalUsersInFile >= 1000 && totalUsersInFile < 2000) 
        timeRequiredForCurTotalUsersInSec = 15;
      else if (totalUsersInFile >= 2000 && totalUsersInFile < 3000) 
        timeRequiredForCurTotalUsersInSec = 20;
      else if (totalUsersInFile >= 3000 && totalUsersInFile < 4000) 
        timeRequiredForCurTotalUsersInSec = 35;
      else if (totalUsersInFile >= 4000 && totalUsersInFile < 5000) 
        timeRequiredForCurTotalUsersInSec = 50;
      else if (totalUsersInFile >= 5000 && totalUsersInFile < 7000) 
        timeRequiredForCurTotalUsersInSec = 90;
      else if (totalUsersInFile >= 7000 && totalUsersInFile < 8500) 
        timeRequiredForCurTotalUsersInSec = 120;
      else if (totalUsersInFile >= 8500 && totalUsersInFile < 10000) 
        timeRequiredForCurTotalUsersInSec = 180;
      else if (totalUsersInFile >= 10000) 
        timeRequiredForCurTotalUsersInSec = 240;
    
    const timer = setInterval(() => {
        let curDateTime : number = parseInt((new Date()).getTime().toString());
        let diffMS = curDateTime - previewProgressStartTime;
        let diffSec = (diffMS * 1.0 / 1000.0);

        if (!!timeRequiredForCurTotalUsersInSec && timeRequiredForCurTotalUsersInSec > 0) {
          let curProgress = progress >= 98 ? progress : ((100 * diffSec) / timeRequiredForCurTotalUsersInSec);
          setProgress(curProgress > 98 ? (isPreviewingData ? 98 : 100) : curProgress);
          if (curProgress >= 98 || !isPreviewingData){
            clearInterval(timer);
          }
        }
      }, 500);
      return () => {
          clearInterval(timer);
      };
    }
}, [isPreviewingData, progress, previewProgressStartTime]);

  useEffect(() => {

    let timeRequiredForCurTotalUsersInSec = 0;
    if(isSavingData) {

      if (totalUsersInFile < 10) 
        timeRequiredForCurTotalUsersInSec = 2;
      else if (totalUsersInFile >= 10 && totalUsersInFile < 25) 
        timeRequiredForCurTotalUsersInSec = 3;
      else if (totalUsersInFile >= 25 && totalUsersInFile < 100) 
        timeRequiredForCurTotalUsersInSec = 4;
      else if (totalUsersInFile >= 100 && totalUsersInFile < 300) 
        timeRequiredForCurTotalUsersInSec = 7;
      else if (totalUsersInFile >= 300 && totalUsersInFile < 500) 
        timeRequiredForCurTotalUsersInSec = 10;
      else if (totalUsersInFile >= 500 && totalUsersInFile < 750) 
        timeRequiredForCurTotalUsersInSec = 16;
      else if (totalUsersInFile >= 750 && totalUsersInFile < 1000) 
        timeRequiredForCurTotalUsersInSec = 27;
      else if (totalUsersInFile >= 1000 && totalUsersInFile < 2000) 
        timeRequiredForCurTotalUsersInSec = 40;
      else if (totalUsersInFile >= 2000 && totalUsersInFile < 3000) 
        timeRequiredForCurTotalUsersInSec = 120;
      else if (totalUsersInFile >= 3000 && totalUsersInFile < 4000) 
        timeRequiredForCurTotalUsersInSec = 180;
      else if (totalUsersInFile >= 4000 && totalUsersInFile < 5000) 
        timeRequiredForCurTotalUsersInSec = 240;
      else if (totalUsersInFile >= 5000 && totalUsersInFile < 7000) 
        timeRequiredForCurTotalUsersInSec = 380;
      else if (totalUsersInFile >= 7000 && totalUsersInFile < 8500) 
        timeRequiredForCurTotalUsersInSec = 480;
      else if (totalUsersInFile >= 8500 && totalUsersInFile < 10000) 
        timeRequiredForCurTotalUsersInSec = 520;
      else if (totalUsersInFile >= 10000) 
        timeRequiredForCurTotalUsersInSec = 540;        
    
      const timer = setInterval(() => {
        let curDateTime : number = parseInt((new Date()).getTime().toString());
        let diffMS = curDateTime - commitProgressStartTime;
        let diffSec = (diffMS * 1.0 / 1000.0);

        if (!!timeRequiredForCurTotalUsersInSec && timeRequiredForCurTotalUsersInSec > 0) {
          let curProgress = saveProgress >= 98 ? saveProgress : ((100 * diffSec) / timeRequiredForCurTotalUsersInSec);
          if (isSavingData) {
            setProgress(0);
            setSaveProgress(curProgress > 98 ? (isSavingData ? 98 : 100) : curProgress);
            if (curProgress >= 98 || !isSavingData){
              clearInterval(timer);
            }
          }
        }
      }, 500);
      return () => {
          clearInterval(timer);
      };
    }
  }, [isSavingData, saveProgress, commitProgressStartTime]);

  const triggerPreviewProgress = (givenLatestUploadedData: any, fileExtension: string, filename: string, summarySeqId: number) => {
      setProgress(0);
      setTotalUsersInFile(givenLatestUploadedData.valueRowCount);
      setIsPreviewingData(true);
      setPreviewProgressStartTime(parseInt((new Date()).getTime().toString()));
      window.onbeforeunload = function (e:any) {e.preventDefault(); e.returnValue = '';};
      ImportUsersPreviewGivenSeqID(oidcUser.access_token, userId, orgDomain, parseInt(orgId), summarySeqId,filename, currentLanguage).then((res) => {
        window.onbeforeunload = function () {};
        if (!!res && res.isSuccess && res.userImportResultsInfo !== null && res.userImportResultsInfo.length > 0) 
        {     
          setProgress(100);
          setIsPreviewingData(false);
          // Collect the current result into final cumulative array
          let finalArr = res.userImportResultsInfo;        
          setUserImportResult(finalArr);
          setTotalUsersCount(!!finalArr ? finalArr.length : 0);  
          //Total import users end

          //Error users           
          setUserImportErrorResult((!!finalArr ? 
              finalArr.filter((item:any) => !!item &&                              
                              item.errorFieldsCSV != null && item.errorFieldsCSV != undefined &&
                              item.errorResourcesCSV != null && item.errorResourcesCSV != undefined &&
                              !!item.addOrUpdate && item.addOrUpdate === 1 &&  
                              item.errorFieldsCSV.toString() != "" &&                               
                              item.errorResourcesCSV.toString() != "") : []));

          setTotalErrorUsersCount((!!finalArr ? 
              finalArr.filter((item:any) => !!item &&                                  
                            item.errorFieldsCSV != null && item.errorFieldsCSV != undefined &&
                            item.errorResourcesCSV != null && item.errorResourcesCSV != undefined &&
                            !!item.addOrUpdate && item.addOrUpdate === 1 &&  
                            item.errorFieldsCSV.toString() != "" &&                               
                            item.errorResourcesCSV.toString() != "").length : 0));
          //Error users 

          //Active users count    
          setActiveAccountsResult((!!finalArr ? 
            finalArr.filter((item:any) => !!item && 
                            item.updatedFieldsCSV != null && item.updatedFieldsCSV != undefined &&
                            item.errorFieldsCSV != null && item.errorFieldsCSV != undefined &&
                            item.errorResourcesCSV != null && item.errorResourcesCSV != undefined &&
                            !!item.addOrUpdate && item.addOrUpdate === 3 &&  
                            item.updatedFieldsCSV.toString().trim().replaceAll(";", "") === "" && 
                            item.errorFieldsCSV.toString().trim().replaceAll(";", "") === "" && 
                            item.errorResourcesCSV.toString().trim().replaceAll(";", "") === "") : [])); 
      
          setTotalActiveUsersCount((!!finalArr ?
            finalArr.filter((item:any) => !!item && 
                            item.updatedFieldsCSV != null && item.updatedFieldsCSV != undefined &&
                            item.errorFieldsCSV != null && item.errorFieldsCSV != undefined &&
                            item.errorResourcesCSV != null && item.errorResourcesCSV != undefined &&
                            !!item.addOrUpdate && item.addOrUpdate === 3 &&  
                            item.updatedFieldsCSV.toString().trim().replaceAll(";", "") === "" && 
                            item.errorFieldsCSV.toString().trim().replaceAll(";", "") === "" && 
                            item.errorResourcesCSV.toString().trim().replaceAll(";", "") ==="").length : 0 )); 
          //Active users count
        
          //Updated users count    
          setUpdatedAccountsResult((!!finalArr ? 
            finalArr.filter((item:any) => !!item && 
                            item.updatedFieldsCSV != null && item.updatedFieldsCSV != undefined &&
                            item.errorFieldsCSV != null && item.errorFieldsCSV != undefined &&
                            item.errorResourcesCSV != null && item.errorResourcesCSV != undefined &&
                            !!item.addOrUpdate && item.addOrUpdate === 2 &&  
                            item.updatedFieldsCSV.toString().trim().replaceAll(";", "") !== "" && 
                            item.errorFieldsCSV.toString().trim().replaceAll(";", "") === "" && 
                            item.errorResourcesCSV.toString().trim().replaceAll(";", "") === "") : [])); 

          setTotalUpdatedUsersCount((!!finalArr ?
            finalArr.filter((item:any) => !!item && 
                            item.updatedFieldsCSV != null && item.updatedFieldsCSV != undefined &&
                            item.errorFieldsCSV != null && item.errorFieldsCSV != undefined &&
                            item.errorResourcesCSV != null && item.errorResourcesCSV != undefined &&
                            !!item.addOrUpdate && item.addOrUpdate === 2 &&                          
                            item.updatedFieldsCSV.toString().trim().replaceAll(";", "") !== "" && 
                            item.errorFieldsCSV.toString().trim().replaceAll(";", "") === "" && 
                            item.errorResourcesCSV.toString().trim().replaceAll(";", "") === "").length : 0 )); 
          //Updated users count


          //Deactive users count    
          setDeactiveAccountsResult((!!finalArr ? 
            finalArr.filter((item:any) => !!item && 
                            item.updatedFieldsCSV != null && item.updatedFieldsCSV != undefined &&
                            item.errorFieldsCSV != null && item.errorFieldsCSV != undefined &&
                            item.errorResourcesCSV != null && item.errorResourcesCSV != undefined &&
                            item.updatedFieldsCSV.toString().trim().replaceAll(";", "") !== "" && 
                            !!item.archive && item.archive.toString().trim() === "1" &&
                            item.updatedFieldsCSV.toString().trim().includes(";8") &&
                            item.errorFieldsCSV.toString().trim().replaceAll(";", "") === "" && 
                            item.errorResourcesCSV.toString().trim().replaceAll(";", "") === "") : []));  

          setTotalDeactivatedUsersCount((!!finalArr ?
            finalArr.filter((item:any) => !!item && 
                            item.updatedFieldsCSV != null && item.updatedFieldsCSV != undefined &&
                            item.errorFieldsCSV != null && item.errorFieldsCSV != undefined &&
                            item.errorResourcesCSV != null && item.errorResourcesCSV != undefined &&
                            item.updatedFieldsCSV.toString().trim().replaceAll(";", "") !== "" && 
                            !!item.archive && item.archive.toString().trim() === "1" &&
                            item.updatedFieldsCSV.toString().trim().includes(";8") &&
                            item.errorFieldsCSV.toString().trim().replaceAll(";", "") === "" && 
                            item.errorResourcesCSV.toString().trim().replaceAll(";", "") === "").length : 0 )); 

          //Deactive users count

          //Error Reason start 
          setErrorResourcesToLangValues(!!lastErrorResourcesToLangValues && lastErrorResourcesToLangValues.length > 0 ?
            lastErrorResourcesToLangValues : res.errorResourcesToLangValues)
          //Error Reason end 

          setEnableUserImportResult(true);
          setEnableUserImportErrorResult(false);
        }
        else {
          setIsPreviewingData(false);
          setUserImportResult([]);
          setProgress(100);
          setHasErrored(true);
          setShowWarningModal(true);
          setSaveSuccess(false);          
          setModalPopupMessage(res.error !== "" ? res.error : "Can not generate Preview, Please try again!");
        }
      });

  }  

  const handleProceed = () => {
    handleConfirmModal(false);
    setSaveProgress(0);
    setIsSavingData(true);
    setIsSaveAndCommitCalled(true);
    setcommitProgressStartTime(parseInt((new Date()).getTime().toString()));
    window.onbeforeunload = function (e: any) {e.preventDefault(); e.returnValue = '';};
         
    //Final commit for all imported users
    ImportUsersCommitGivenSeqID(oidcUser.access_token, userId, orgDomain, parseInt(orgId), importUsersSummaryId, selectedFileName,"", (sendWelcomeEmailTo == 1 ? true : false), (sendWelcomeEmailTo == 2 ? false : true)).then((res) => {
    
      if (!!res && res.isSuccess) {
        addError(oidcUser.access_token, userId, orgDomain, "Import Account - Email Report", "Import Account: Selected Value for 'Send a welcome Email from import file to: " + sendWelcomeEmailTo + "' and 'Send Welcome email: " + sendWelcomeEmailTime +"' ", "ImportAccounts.ts", 1)
        let checkIfStatusUpdateRequired: number = 0;     
        if (!!res.statusUpdateRequiredUserIds && res.statusUpdateRequiredUserIds.trim() != '')
        {
          checkIfStatusUpdateRequired = res.statusUpdateRequiredUserIds.split(',').length;
        }
        // Update status for saved accounts
        if (checkIfStatusUpdateRequired > 0) {
          //TriggerStatusUpdateOrgWiseForToday(oidcUser.access_token, userId, orgDomain, parseInt(orgId));
          TriggerStatusUpdateGivenUsersOnly(oidcUser.access_token, userId, orgDomain, parseInt(orgId), res.statusUpdateRequiredUserIds);
        }

        // Delete Import user's Summary and details
        DeleteImportUsersSummary(oidcUser.access_token, userId, orgDomain, importUsersSummaryId);

        let popupMessage = 'Imported accounts have been saved successfully!';
        
        //unblock page load
        window.onbeforeunload = function () {};
        if (sendWelcomeEmailTime == 1) {
          const nextDate = new Date();
          nextDate.setDate(nextDate.getDate() + 1);
          let addedUserIds = '';
          let modifiedUserIds = '';
          let allUserIdsToSendWelEmail = '';
          if (!!res.addedUserIds && res.addedUserIds.trim() !== "") {
              var arrTokens = res.addedUserIds.split(',');
              let k=0;
              
              for (k=0; k< arrTokens.length; k++)   
              {
                let value = arrTokens[k];
                value = !!value ? value.trim() : value;
                if (!!value)
                {
                  addedUserIds = addedUserIds !== ''? (addedUserIds + ',') : '';
                  addedUserIds = addedUserIds + value;
                }
              }
          }
          let totalUsersToSendWelEmail: number = (!!res.addedUserIds && res.addedUserIds.trim() !== "") ? (addedUserIds.split(',')).length : 0;
          allUserIdsToSendWelEmail = addedUserIds;
          if (!!res.modifiedUserIds && res.modifiedUserIds.trim() !== "") {
            var arrTokens = res.modifiedUserIds.split(',');
            let k=0;
          
            for (k=0; k< arrTokens.length; k++)   
            {
              let value = arrTokens[k];
              value = !!value ? value.trim() : value;
              if (!!value)
              {
                modifiedUserIds = modifiedUserIds !== ''? (modifiedUserIds + ', ') : '';
                modifiedUserIds = modifiedUserIds + value;
              }
            }

            if (sendWelcomeEmailTo == 1) {
              totalUsersToSendWelEmail = totalUsersToSendWelEmail + (modifiedUserIds.split(',')).length;
              allUserIdsToSendWelEmail = (allUserIdsToSendWelEmail == '' ? modifiedUserIds : (modifiedUserIds == '' ? '' :(allUserIdsToSendWelEmail + ',' + modifiedUserIds)));
            }
        }

        if (totalUsersToSendWelEmail > 0) {
            //Get retrievedFormattedUserToIDsList
            addError(oidcUser.access_token, userId, orgDomain, "Import Account - Email Report", "Sending emails to:" + allUserIdsToSendWelEmail.toString().replaceAll(" ",""), "ImportAccounts.ts", 1)
           
            addEmailingReport(oidcUser.access_token,
              orgDomain, userId, parseInt(orgId), '', '', allUserIdsToSendWelEmail.toString().replaceAll(" ",""),
              totalUsersToSendWelEmail, 'Salt Login Details', emailBodyTemplate,
              0, "1990-01-01", formatDate(nextDate), 2, 0,
              totalUsersToSendWelEmail,'', 3, '', 0, '', 0, 0, 0, true, true).then(response => {
                if (!!response && response.isSuccess) {
                 
                  setSaveProgress(100);
                  popupMessage = popupMessage + ";; Welcome emails are queued and will start sending soon."
                  setShowWarningModal(true);
                  setModalPopupMessage(popupMessage);       
                  setSaveSuccess(true);
                  setHasErrored(false);
                } else {
                  setSaveProgress(100);
                  const errorVal = popupMessage + "Sending welcome emails have failed! Please try again sending them from Email report or it will be picked up by overnight job.";
                  setModalPopupMessage(errorVal);
                  setHasErrored(true);
                  setShowWarningModal(true);
                }
              }).catch((error) => {
                setSaveProgress(100);
                const errorVal = popupMessage + "Sending welcome emails have failed! Please try again sending them from Email report or it will be picked up by overnight job.";
                setModalPopupMessage(errorVal);
                setHasErrored(true);
                setShowWarningModal(true);
              });
          }
          else {          
            setSaveProgress(100);
            setShowWarningModal(true);
            setModalPopupMessage(popupMessage);       
            setSaveSuccess(true);
            setHasErrored(false);
          }
        } else {          
          setSaveProgress(100);
          setShowWarningModal(true);
          setModalPopupMessage(popupMessage);       
          setSaveSuccess(true);
          setHasErrored(false);
        }
      }
      else {
        setSaveProgress(100);
        setIsSavingData(false);
        setSaveSuccess(false);
        setShowWarningModal(true);
        setModalPopupMessage('Import accounts have been failed!,' + res.error);
        setHasErrored(true);
      }
      setIsSavingData(false);
    }).catch((error) => {
      setSaveProgress(100);
      setIsSavingData(false);
      setHasErrored(true);
      setShowWarningModal(true);
      setSaveSuccess(false);          
      setModalPopupMessage("Import accounts have been failed!");
      return;
    });
  }

  const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
  {
      if (!isNaN(givenDate.getTime())) {
          const year = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(givenDate);
          const month = new Intl.DateTimeFormat('en-AU', { month: 'numeric' }).format(givenDate);
          const day = new Intl.DateTimeFormat('en-AU', { day: 'numeric' }).format(givenDate);

          return (year.toString() + "-" +
              (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" +
              (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
      }
      else {
          return "";
      }
  }
  
  const handleSetSelectedFileName = (filename: string) => {
    setSelectedFileName(filename);
    setGuideMe(filename);
  }

  const handleFileUpload = (byteArray: string, fileExtension: string, filename: string) => {
    setExportError("");
    setShowWarningModal(false);
    setModalPopupMessage("");
    setProgress(0);
    setSelectedFile("");
    setSelectedFileName(filename);
    setIsFileUploading(true);
    setenableFileUploading(true);  
    //return new Promise(resolve => {
      setProgress(0);
      setIsFileReadDone(false);
      ImportUsersReadGivenFile(oidcUser.access_token, userId, orgDomain, parseInt(orgId), byteArray, fileExtension, filename, parseInt(filteruniquefield)).then((res) => {
        setIsFileReadDone(true);
        setLatestUploadedData(res);
        if (!!res && res.isSuccess) {
          if (res.valueRowCount > 0 && res.importSummarySeqId > 0) {   
            setImportUsersSummaryId(res.importSummarySeqId);
            setTotalUsersInFile(res.valueRowCount);
            triggerPreviewProgress(res, fileExtension, filename, parseInt(res.importSummarySeqId));
          }
          else {
            setHasErrored(true);
            setShowWarningModal(true);
            setSaveSuccess(false);          
            setModalPopupMessage("Uploaded file is Empty!, Please enter valid data");
          }
          setSelectedFile(res.fileName)
          //resolve({ success: true })
          setIsFileUploading(false);
        } else {
          setSelectedFile("")
          setImportUsersSummaryId(0);
          //resolve({ success: false });
          setIsFileUploading(false);
          setHasErrored(true);
          setShowWarningModal(true);
          setSaveSuccess(false);          
          setModalPopupMessage("Reading file has failed!");
          handleResetFileUpload();
        }       
      })
    //})
  }

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
    } else {
      return {};
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEnableUserImport = () => {
    setEnableUserImportResult(true);
    setEnableUserImportErrorResult(false);
    setEnableactiveAccountsResult(false);
    setEnableupdatedAccountsResult(false);
    setEnabledeactiveAccountsResult(false);
  };

  const handleEnableShowErrors = () => {
    setEnableUserImportResult(false);
    setEnableUserImportErrorResult(true);
    setEnableactiveAccountsResult(false);
    setEnableupdatedAccountsResult(false);
    setEnabledeactiveAccountsResult(false);
  };

  const handleEnableAddedAccounts = () => {
    setEnableUserImportErrorResult(false);
    setEnableUserImportResult(false);
    setEnableactiveAccountsResult(true);
    setEnableupdatedAccountsResult(false);
    setEnabledeactiveAccountsResult(false);
  };

  const handleEnableUpdatedAccounts = () => {
    setEnableUserImportErrorResult(false);
    setEnableUserImportResult(false);
    setEnableactiveAccountsResult(false);
    setEnableupdatedAccountsResult(true);
    setEnabledeactiveAccountsResult(false);
  };

  const handleEnableDeactivatedAccounts = () => {
    setEnableUserImportErrorResult(false);
    setEnableUserImportResult(false);
    setEnableactiveAccountsResult(false);
    setEnableupdatedAccountsResult(false);
    setEnabledeactiveAccountsResult(true);
  };

  const handleResetFileUpload = () => {
    setGuideMe("");
    setUserImportResult([]);
    setUserImportErrorResult([]);
    setErrorResourcesToLangValues([]);
    setActiveAccountsResult([]);
    setUpdatedAccountsResult([]);
    setDeactiveAccountsResult([]);

    setEnableUserImportResult(false);
    setEnableUserImportErrorResult(false);
    setEnableactiveAccountsResult(false);
    setEnableupdatedAccountsResult(false);
    setEnabledeactiveAccountsResult(false);

    setTotalUsersCount(0);
    setTotalErrorUsersCount(0);
    setTotalActiveUsersCount(0);
    setTotalUpdatedUsersCount(0);
    setTotalDeactivatedUsersCount(0);
    setImportUsersSummaryId(0);

    setenableFileUploading(false);
    setFilteruniquefield(1);
    setSelectedFile("");
    setSelectedFileName("");
    setProgress(0);
    setSaveProgress(0);
    setSaveSuccess(false);
    setIsSaveAndCommitCalled(false);
    setSendWelcomeEmailTo(0);
    setSendWelcomeEmailTime(0);
  };

  const handleSendWelcomeEmailTo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value;
    setSendWelcomeEmailTo(parseInt(val));
  };

  const handleSendWelcomeEmailTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value;
    setSendWelcomeEmailTime(parseInt(val));
  };

const getErrorReason = (str: string ) => {  
  str = !!str ? (str.includes(';') ? str.substring(1, str.length).toString() : str) : str;
  let error = '';
 
  let errormessage = "Errors: ";
  if (!!str && !!str.split(';')) 
  {
    var arrTokens = str.split(';');
    let k=0;
    for (k=0; k<arrTokens.length; k++)   
    {
      let value = arrTokens[k];
      value = !!value ? value.trim() : value;
      if (!!value)
      {
        error = error !== ''? (error + ', ') : '';
        error = error + (errorResourcesToLangValues.find(v => v.errorResource.toLowerCase().trim() === value.toLowerCase().trim())?.langValue);
      }
    } 
  }

  // {str.split(';').map((value: string) => {   
  //   error = (error !== undefined && error !== null && error !== '')? (error + ', ') : '';
  //   let message = (errorResourcesToLangValues.find(v => v.errorResource.toLowerCase().trim() === value.toLowerCase().trim())?.langValue);        
  //   error =  (message !== undefined || message !== '') ?  (error + message) : ''
  // }) }   

  return errormessage + error;
 }

const handleConfirmEmailProceed = () => {
    handleWelcomeEmailModal(false);
    GetWelcomeEmailTemplate(oidcUser.access_token, userId, orgDomain, parseInt(orgId), 0).then((emailresult) => {
      if (emailresult.isSuccess && emailresult.error == null) {
        if (emailresult.emailReportBodyItem !== null && emailresult.emailReportBodyItem.length > 0) {
          setEmailBodyTemplate(emailresult.emailReportBodyItem[0].body);        
        }
      }
    });
    handleConfirmModal(true);
  }

  const handleClosePopup = () => {
    setShowWarningModal(false);
    setHasErrored(false);
    setSaveSuccess(false);
    setModalPopupMessage("");
    handleResetFileUpload();
  };

  const handleCancelFromConfirmPopup = () => {
    handleConfirmModal(false);
    handleWelcomeEmailModal(false);
  }

  const handleSave = () => {
    if(totalUsersCount === totalDeactivatedUsersCount) {
      setConfirmNoteMessage(`By selecting this option, you will deactivate all these(${totalUsersCount}) accounts in to Salt.`);
      setshowConfirmModal(true);
    } else {
      setConfirmNoteMessage("By selecting this option, you will enrol all accounts in to Salt.");
      handleWelcomeEmailModal(true);
    }
  }

  //#region Pageshorting  

  const sortResultsByAsync = async (
    sortBy:
      | "LastName"
      | "FirstName"
      | "UserName"
      | "UnitID"
      | "Email"
      | "ExternalID"
      | "Archive"
      | "ClassificationName"
      | "ClassificationOption"
      | "NotifyUnitAdmin"
      | "NotifyOrgAdmin"
      | "ManagerNotification"
      | "ManagerToNotify"
      | "Password",
    sortGivenItemInDesc: boolean
  ) => {
    if (userImportResult == null || userImportResult.length <= 0) {
      return userImportResult;
    }
    var result = userImportResult.slice(0);
    setCurrentSortBy(sortBy);
    switch (sortBy) {
      case "LastName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.lastname === null) {
              return -1;
            }
            if (a === null || a.lastname === null) {
              return 1;
            }
            if (a.lastname.toLowerCase() > b.lastname.toLowerCase()) {
              return -1;
            }
            if (a.lastname.toLowerCase() < b.lastname.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastnameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.lastname === null) {
              return 1;
            }
            if (a === null || a.lastname === null) {
              return -1;
            }
            if (a.lastname.toLowerCase() < b.lastname.toLowerCase()) {
              return -1;
            }
            if (a.lastname.toLowerCase() > b.lastname.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastnameDescending(true);
          }
        }
        setUserImportResult(result);
        break;
      case "FirstName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.firstname === null) {
              return -1;
            }
            if (a === null || a.firstname === null) {
              return 1;
            }
            if (a.firstname > b.firstname) {
              return -1;
            }
            if (a.firstname < b.firstname) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstnameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.firstname === null) {
              return 1;
            }
            if (a === null || a.firstname === null) {
              return -1;
            }
            if (a.firstname > b.firstname) {
              return 1;
            }
            if (a.firstname < b.firstname) {
              return -1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstnameDescending(true);
          }
        }
        setUserImportResult(result);
        break;
      case "UserName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.username === null) {
              return -1;
            }
            if (a === null || a.username === null) {
              return 1;
            }
            if (a.username.toLowerCase() > b.username.toLowerCase()) {
              return -1;
            }
            if (a.username.toLowerCase() < b.username.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUsernameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.username === null) {
              return 1;
            }
            if (a === null || a.username === null) {
              return -1;
            }
            if (a.username.toLowerCase() < b.username.toLowerCase()) {
              return -1;
            }
            if (a.username.toLowerCase() > b.username.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUsernameDescending(true);
          }
        }
        setUserImportResult(result);
        break;
      case "Email":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return -1;
            }
            if (a === null || a.email === null) {
              return 1;
            }
            if (a.email.toLowerCase() > b.email.toLowerCase()) {
              return -1;
            }
            if (a.email.toLowerCase() < b.email.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setEmailDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return 1;
            }
            if (a === null || a.email === null) {
              return -1;
            }
            if (a.email.toLowerCase() < b.email.toLowerCase()) {
              return -1;
            }
            if (a.email.toLowerCase() > b.email.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setEmailDescending(true);
          }
        }
        setUserImportResult(result);
        break;
      case "ExternalID":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.externalID === null) {
              return -1;
            }
            if (a === null || a.externalID === null) {
              return 1;
            }
            if (a.externalID.toLowerCase() > b.externalID.toLowerCase()) {
              return -1;
            }
            if (a.externalID.toLowerCase() < b.externalID.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setExternalIDDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.externalID === null) {
              return 1;
            }
            if (a === null || a.externalID === null) {
              return -1;
            }
            if (a.externalID.toLowerCase() < b.externalID.toLowerCase()) {
              return -1;
            }
            if (a.externalID.toLowerCase() > b.externalID.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setExternalIDDescending(true);
          }
        }
        setUserImportResult(result);
        break;
      case "Archive":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.archive === null) {
              return -1;
            }
            if (a === null || a.archive === null) {
              return 1;
            }
            if (a.archive.toLowerCase() > b.archive.toLowerCase()) {
              return -1;
            }
            if (a.archive.toLowerCase() < b.archive.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setarchiveDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.archive === null) {
              return 1;
            }
            if (a === null || a.archive === null) {
              return -1;
            }
            if (a.archive.toLowerCase() < b.archive.toLowerCase()) {
              return -1;
            }
            if (a.archive.toLowerCase() > b.archive.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setarchiveDescending(true);
          }
        }
        setUserImportResult(result);
        break;
      case "UnitID":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.unitID === null) {
              return -1;
            }
            if (a === null || a.unitID === null) {
              return 1;
            }
            if (a.unitID > b.unitID) {
              return -1;
            }
            if (a.unitID < b.unitID) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitIDDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.unitID === null) {
              return 1;
            }
            if (a === null || a.unitID === null) {
              return -1;
            }
            if (a.unitID < b.unitID) {
              return -1;
            }
            if (a.unitID > b.unitID) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitIDDescending(true);
          }
        }
        setUserImportResult(result);
        break;
      case "ClassificationName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.classificationName === null) {
              return -1;
            }
            if (a === null || a.classificationName === null) {
              return 1;
            }
            if (a.classificationName.toLowerCase() > b.classificationName.toLowerCase()) {
              return -1;
            }
            if (a.classificationName.toLowerCase() < b.classificationName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setClassificationNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.classificationName === null) {
              return 1;
            }
            if (a === null || a.classificationName === null) {
              return -1;
            }
            if (a.classificationName.toLowerCase() < b.classificationName.toLowerCase()) {
              return -1;
            }
            if (a.classificationName.toLowerCase() > b.classificationName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setClassificationNameDescending(true);
          }
        }
        setUserImportResult(result);
        break;
      case "ClassificationOption":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.classificationOption === null) {
              return -1;
            }
            if (a === null || a.classificationOption === null) {
              return 1;
            }
            if (a.classificationOption.toLowerCase() > b.classificationOption.toLowerCase()) {
              return -1;
            }
            if (a.classificationOption.toLowerCase() < b.classificationOption.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setClassificationOptionDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.classificationOption === null) {
              return 1;
            }
            if (a === null || a.classificationOption === null) {
              return -1;
            }
            if (a.classificationOption.toLowerCase() < b.classificationOption.toLowerCase()) {
              return -1;
            }
            if (a.classificationOption.toLowerCase() > b.classificationOption.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setClassificationOptionDescending(true);
          }
        }
        setUserImportResult(result);
        break;
      case "NotifyUnitAdmin":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.notifyUnitAdmin === null) {
              return -1;
            }
            if (a === null || a.notifyUnitAdmin === null) {
              return 1;
            }
            if (a.notifyUnitAdmin.toLowerCase() > b.notifyUnitAdmin.toLowerCase()) {
              return -1;
            }
            if (a.notifyUnitAdmin.toLowerCase() < b.notifyUnitAdmin.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setNotifyUnitAdminDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.notifyUnitAdmin === null) {
              return 1;
            }
            if (a === null || a.notifyUnitAdmin === null) {
              return -1;
            }
            if (a.notifyUnitAdmin.toLowerCase() < b.notifyUnitAdmin.toLowerCase()) {
              return -1;
            }
            if (a.notifyUnitAdmin.toLowerCase() > b.notifyUnitAdmin.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setNotifyUnitAdminDescending(true);
          }
        }
        setUserImportResult(result);
        break;
      case "NotifyOrgAdmin":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.notifyOrgAdmin === null) {
              return -1;
            }
            if (a === null || a.notifyOrgAdmin === null) {
              return 1;
            }
            if (a.notifyOrgAdmin.toLowerCase() > b.notifyOrgAdmin.toLowerCase()) {
              return -1;
            }
            if (a.notifyOrgAdmin.toLowerCase() < b.notifyOrgAdmin.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setNotifyOrgAdminDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.notifyOrgAdmin === null) {
              return 1;
            }
            if (a === null || a.notifyOrgAdmin === null) {
              return -1;
            }
            if (a.notifyOrgAdmin.toLowerCase() < b.notifyOrgAdmin.toLowerCase()) {
              return -1;
            }
            if (a.notifyOrgAdmin.toLowerCase() > b.notifyOrgAdmin.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setNotifyOrgAdminDescending(true);
          }
        }
        setUserImportResult(result);
        break;
      case "ManagerNotification":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.managerNotification === null) {
              return -1;
            }
            if (a === null || a.managerNotification === null) {
              return 1;
            }
            if (a.managerNotification.toLowerCase() > b.managerNotification.toLowerCase()) {
              return -1;
            }
            if (a.managerNotification.toLowerCase() < b.managerNotification.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setManagerNotificationDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.managerNotification === null) {
              return 1;
            }
            if (a === null || a.managerNotification === null) {
              return -1;
            }
            if (a.managerNotification.toLowerCase() < b.managerNotification.toLowerCase()) {
              return -1;
            }
            if (a.managerNotification.toLowerCase() > b.managerNotification.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setManagerNotificationDescending(true);
          }
        }
        setUserImportResult(result);
        break;
      case "ManagerToNotify":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.managerToNotify === null) {
              return -1;
            }
            if (a === null || a.managerToNotify === null) {
              return 1;
            }
            if (a.managerToNotify.toLowerCase() > b.managerToNotify.toLowerCase()) {
              return -1;
            }
            if (a.managerToNotify.toLowerCase() < b.managerToNotify.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setManagerToNotifyDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.managerToNotify === null) {
              return 1;
            }
            if (a === null || a.managerToNotify === null) {
              return -1;
            }
            if (a.managerToNotify.toLowerCase() < b.managerToNotify.toLowerCase()) {
              return -1;
            }
            if (a.managerToNotify.toLowerCase() > b.managerToNotify.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setManagerToNotifyDescending(true);
          }
        }
        setUserImportResult(result);
        break;
      case "Password":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.password === null) {
              return -1;
            }
            if (a === null || a.password === null) {
              return 1;
            }
            if (a.password.toLowerCase() > b.password.toLowerCase()) {
              return -1;
            }
            if (a.password.toLowerCase() < b.password.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setPasswordDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.password === null) {
              return 1;
            }
            if (a === null || a.password === null) {
              return -1;
            }
            if (a.password.toLowerCase() < b.password.toLowerCase()) {
              return -1;
            }
            if (a.password.toLowerCase() > b.password.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setPasswordDescending(true);
          }
        }
        setUserImportResult(result);
        break;

      default:
        break;
    }
    return result.slice(0);
  };

  const sortResultsBy = async (
    sortBy:
      | "LastName"
      | "FirstName"
      | "UserName"
      | "UnitID"
      | "Email"
      | "ExternalID"
      | "Archive"
      | "ClassificationName"
      | "ClassificationOption"
      | "NotifyUnitAdmin"
      | "NotifyOrgAdmin"
      | "ManagerNotification"
      | "ManagerToNotify"
      | "Password",
    sortGivenItemInDesc: boolean
  ) => {
    setSortInProgress(true);
    setExportError("");
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy);
      setUserImportResult(result);
      setPage(0);
      setSortInProgress(false);
    });
  };

  const clickLastNameAsc = () => {
    setReverseNextSort(false);
    setLastnameDescending(false);
    sortResultsBy("LastName", false);
  };

  const clickLastNameDesc = () => {
    setReverseNextSort(false);
    setLastnameDescending(true);
    sortResultsBy("LastName", true);
  };

  const clickFirstNameAsc = () => {
    setReverseNextSort(false);
    setFirstnameDescending(false);
    sortResultsBy("FirstName", false);
  };

  const clickFirstNameDesc = () => {
    setReverseNextSort(false);
    setFirstnameDescending(true);
    sortResultsBy("FirstName", true);
  };

  const clickUserNameAsc = () => {
    setReverseNextSort(false);
    setUsernameDescending(false);
    sortResultsBy("UserName", false);
  };

  const clickUserNameDesc = () => {
    setReverseNextSort(false);
    setUsernameDescending(true);
    sortResultsBy("UserName", true);
  };

  const clickUnitIDAsc = () => {
    setReverseNextSort(false);
    setUnitIDDescending(false);
    sortResultsBy("UnitID", false);
  };

  const clickUnitIDDesc = () => {
    setReverseNextSort(false);
    setUnitIDDescending(true);
    sortResultsBy("UnitID", true);
  };

  const clickEmailAsc = () => {
    setReverseNextSort(false);
    setEmailDescending(false);
    sortResultsBy("Email", false);
  };

  const clickEmailDesc = () => {
    setReverseNextSort(false);
    setEmailDescending(true);
    sortResultsBy("Email", true);
  };

  const clickExternalIDAsc = () => {
    setReverseNextSort(false);
    setExternalIDDescending(false);
    sortResultsBy("ExternalID", false);
  };

  const clickExternalIDDesc = () => {
    setReverseNextSort(false);
    setExternalIDDescending(true);
    sortResultsBy("ExternalID", true);
  };

  const clickArchiveAsc = () => {
    setReverseNextSort(false);
    setarchiveDescending(false);
    sortResultsBy("Archive", false);
  };

  const clickArchiveDesc = () => {
    setReverseNextSort(false);
    setarchiveDescending(true);
    sortResultsBy("Archive", true);
  };

  const clickClassificationNameAsc = () => {
    setReverseNextSort(false);
    setClassificationNameDescending(false);
    sortResultsBy("ClassificationName", false);
  };

  const clickClassificationNameDesc = () => {
    setReverseNextSort(false);
    setClassificationNameDescending(true);
    sortResultsBy("ClassificationName", true);
  };

  const clickClassificationOptionAsc = () => {
    setReverseNextSort(false);
    setClassificationNameDescending(false);
    sortResultsBy("ClassificationOption", false);
  };

  const clickClassificationOptionDesc = () => {
    setReverseNextSort(false);
    setClassificationNameDescending(true);
    sortResultsBy("ClassificationOption", true);
  };
  const clickNotifyUnitAdminAsc = () => {
    setReverseNextSort(false);
    setNotifyUnitAdminDescending(false);
    sortResultsBy("NotifyUnitAdmin", false);
  };

  const clickNotifyUnitAdminDesc = () => {
    setReverseNextSort(false);
    setNotifyUnitAdminDescending(true);
    sortResultsBy("NotifyUnitAdmin", true);
  };

  const clickNotifyOrgAdminAsc = () => {
    setReverseNextSort(false);
    setNotifyOrgAdminDescending(false);
    sortResultsBy("NotifyOrgAdmin", false);
  };

  const clickNotifyOrgAdminDesc = () => {
    setReverseNextSort(false);
    setNotifyOrgAdminDescending(true);
    sortResultsBy("NotifyOrgAdmin", true);
  };

  const clickManagerNotificationAsc = () => {
    setReverseNextSort(false);
    setManagerNotificationDescending(false);
    sortResultsBy("ManagerNotification", false);
  };

  const clickManagerNotificationDesc = () => {
    setReverseNextSort(false);
    setManagerNotificationDescending(true);
    sortResultsBy("ManagerNotification", true);
  };

  const clickManagerToNotifyAsc = () => {
    setReverseNextSort(false);
    setManagerToNotifyDescending(false);
    sortResultsBy("ManagerToNotify", false);
  };

  const clickManagerToNotifyDesc = () => {
    setReverseNextSort(false);
    setManagerToNotifyDescending(true);
    sortResultsBy("ManagerToNotify", true);
  };
  const clickPasswordAsc = () => {
    setReverseNextSort(false);
    setPasswordDescending(false);
    sortResultsBy("Password", false);
  };

  const clickPasswordDesc = () => {
    setReverseNextSort(false);
    setPasswordDescending(true);
    sortResultsBy("Password", true);
  };
  //#endregion

//#region export to csv,xlsx error data
const buildRequestStringArray = () => {
  let resultArray: Array<string> = []
  const r = userImportErrorResult[0];

  for (let i = 0; i < userImportErrorResult.length; i++) {
      const r = userImportErrorResult[i];
      resultArray.push(`${r.username}<#>${r.password}<#>${r.firstname}<#>${r.lastname}<#>${r.email}<#>${r.externalID}<#>${r.unitID}<#>${r.archive}<#>${r.classificationName}<#>${r.classificationOption}<#>${r.notifyUnitAdmin}<#>${r.notifyOrgAdmin}<#>${r.managerNotification}<#>${r.managerToNotify}<#>${r.userID}`);
      
  }
  return resultArray;
}
const GetDate_yymmdd = () => {
  var d = new Date(Date.now());
  let day = d.getFullYear().toString().substring(2, 4) + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0")
  return day;
}
const handleXLSXExport = () => {  
  setExportError("");
  // Activity log 
  addError(oidcUser.access_token, userId, orgDomain, "Started ImportAccounts Error Report XLSX-EXPORT in NEWSALT ClientApp", null, "ImportAccounts.tsx", 6, EnableActivityLogs);
  setExporting(true)  
  getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId), buildRequestStringArray(), 15, "ImportAccounts", enableRowBanding,"", "", "", "", false, false, "", "", "", "", "").then((xlxsRes:any) => {
      if (xlxsRes.isSuccess) {
          const fileData = xlxsRes.xlsxDataOutput.fileData
          var a = window.document.createElement('a');

          a.href = window.URL.createObjectURL(
              new Blob([serialToArray(atob(fileData))], {
                  type: ''
              })
          )
          a.download = "ErrorImportAccounts - " + GetDate_yymmdd() + ".xlsx";
          document.body.appendChild(a)
          a.click();
          document.body.removeChild(a)

      }
      else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
          setExportError(xlxsRes.error);
      }
      setExporting(false)
      // Activity log 
      addError(oidcUser.access_token, userId, orgDomain, "Completed ImportAccounts Report XLSX-EXPORT in NEWSALT ClientApp", null, "ImportAccounts.tsx", 6, EnableActivityLogs);
  })
}
const handleCSVExport = () => { 
  setExportError("");
  // Activity log 
  addError(oidcUser.access_token, userId, orgDomain, "Started ImportAccounts CSV-EXPORT in NEWSALT ClientApp", null, "ImportAccounts.tsx", 6, EnableActivityLogs);
  setExporting(true)
 
  getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId), buildRequestStringArray(), 15, "ImportAccounts", "", "", "", "", false, false, "", false, "", "", "", "").then((csvRes) => {
      if (csvRes.isSuccess) {
          const fileData = csvRes.csvDataOutput.fileData;
          var a = window.document.createElement('a');

          a.href = window.URL.createObjectURL(
              new Blob([serialToArray(atob(fileData))], {
                  type: ''
              })
          )
          a.download = "ErrorImportAccounts - " + GetDate_yymmdd() + ".csv";
          document.body.appendChild(a)
          a.click();
          document.body.removeChild(a)

      }
      else if (csvRes.error !== undefined && csvRes.error !== null) {
          setExportError(csvRes.error);
      }
      setExporting(false)
      // Activity log 
      addError(oidcUser.access_token, userId, orgDomain, "Completed ImportAccounts CSV-EXPORT in NEWSALT ClientApp", null, "ImportAccounts.tsx", 6, EnableActivityLogs);
  })
}
//#endregion

const handleModal = (value:boolean) => {
  setModalOpen(value);
}

React.useEffect(() => {
  if (totalActiveUsersCount <= 0){
    setSendWelcomeEmailTo(1);
  } else {
    setSendWelcomeEmailTo(0);
  }

},[totalActiveUsersCount]);

React.useEffect(() => {
  // @ts-ignore: Unreachable code error
  const unblock = history.block((location, action) => {      
  if ((saveProgress != 100 && saveProgress != 0) || isSavingData) {
      setModalOpen(true);
      return false
    }
    else {
      setModalOpen(false);
      return true
    }        
}); 

return () => {
  unblock();
};

},[saveProgress, isSavingData]);


if (loading) {
    return <LoaderContainer />
}

  return (
    <>
     <Modal type = "warning" modalOpen={showModal} setModalOpen={handleModal} style={{height:'232px', width:'381px'}}
              hideCloseBtn={true}>
            <div style={{position:'relative', top:'-30px'}}>
                <h3 style={{color:'#e7a652'}}>Please Wait</h3>
                <p>Please wait for your import to finish processing.</p>
            </div>
            <div style={{display:'flex', justifyContent:'center', position:'relative', top:'10px', bottom:'20px' }}>      
                <CustomTooltip displayText={["Return"]}>
                  <span className={styles.returnIcon} >
                  <span onClick={() => 
                          {
                            setModalOpen(false)                            
                          }} >
                        <CloseWhiteIcon />
                      </span>
                </span>              
                </CustomTooltip>              
             </div>
      </Modal>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }}
      />
      <CustomTooltip displayText={[allResourcesToTranslations.guideme]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific}>
          <GuideIcon />
        </span>
      </CustomTooltip>      
      <Modal modalOpen={showWelcomeEmailModal} setModalOpen={handleWelcomeEmailModal} type="warning">
        <div className={styles.modalContentWrapper}>
          <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>Confirmation</h2>
          <div className={styles.inputContainer}>
            <span className={styles.labelText}>Send a welcome email from this import file to:</span>
            <div className={styles.radiodivpadging}>
              <RadioGroup
                name="sentWelcomeEmailTo"
                value={sendWelcomeEmailTo}
                onChange={handleSendWelcomeEmailTo}
                style={{ display: 'flex' }}
              >
                <FormControlLabel
                  value={0}
                  disabled={totalActiveUsersCount== 0}
                  control={<Radio color="primary" className={styles.radiopadging} />}
                  label={"New accounts in this import file only"}
                />
                <FormControlLabel
                  value={1}
                  disabled={totalUpdatedUsersCount == 0}
                  control={<Radio color="primary" className={styles.radiopadging} />}
                  label={"All active accounts"}
                />
                <FormControlLabel
                  value={2}
                  control={<Radio color="primary" className={styles.radiopadging} />}
                  label={"Do not send welcome email"}
                />
              </RadioGroup>
            </div>
          </div>
          <div className={styles.inputContainer} style={{ paddingTop: "15px" }}>
            <span className={styles.labelText}>Send the welcome email:</span>
            <div className={styles.radiodivpadging}>
              <RadioGroup
                name="sentWelcomeEmailTo"
                value={sendWelcomeEmailTime}
                aria-disabled={sendWelcomeEmailTo == 2}
                onChange={handleSendWelcomeEmailTime}
                style={{ display: 'flex' }}
              >
                <FormControlLabel
                  value={0}
                  disabled={sendWelcomeEmailTo == 2}
                  control={<Radio color="primary" className={styles.radiopadging} />}
                  label={"During the overnight job"}
                />
                <FormControlLabel
                  value={1}
                  disabled={sendWelcomeEmailTo == 2}
                  control={<Radio color="primary" className={styles.radiopadging} />}
                  label={"Now"}
                />
              </RadioGroup>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "10px",
            }}
          >
            <CustomTooltip displayText={[allResourcesToTranslations.return]}>
              <span className={styles.returnIcon} onClick={handleCancelFromConfirmPopup}>
                <CloseWhiteIcon />
              </span>
            </CustomTooltip>
            <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
              <span className="saveData">
                <Button
                  onClick={handleConfirmEmailProceed}
                  variant="contained"
                  color="primary"
                  className="save"
                >
                  <ProceedIcon />
                </Button>
              </span>
            </CustomTooltip>
          </div>
        </div>
      </Modal>
      <Modal modalOpen={showConfirmModal} setModalOpen={handleConfirmModal} type="warning">
        <div className={styles.modalContentWrapper}>
          <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>Confirmation</h2>
          <div className={styles.inputContainer}>
            <p>
              <span className={styles.labelText}>Note:</span> {confirmNoteMessage} </p>
            <p>Are you sure you want to proceed?</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "10px",
            }}
          >
            <CustomTooltip displayText={[allResourcesToTranslations.return]}>
              <span className={styles.returnIcon} onClick={handleCancelFromConfirmPopup}>
                <CloseWhiteIcon />
              </span>
            </CustomTooltip>
            <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
              <span className="saveData">
                <Button
                  onClick={handleProceed}
                  variant="contained"
                  color="primary"
                  className="save"
                >
                  <ProceedIcon />
                </Button>
              </span>
            </CustomTooltip>
          </div>
        </div>
      </Modal>
      <UnbrandedCard>
        <div className={styles.mainWrapper}>
          { (!isSaveAndCommitCalled) &&
            <div style={{ width: '100%', height:'230px'}}>
            <div style={{ width: '50%', float: "left" }}>
              <div className={styles.Wrapper}>
                <div className={styles.inputContainer} >
                  <span className={styles.labelText}>{allResourcesToTranslations.sampleimportfile}</span>
                </div>
                <div className={styles.inputContainer}>
                  <span className={styles.labelTextitalic}>{allResourcesToTranslations.sampleimportfiledownload}</span>
                </div>
                <div className={styles.inputContainer}>
                  {/*<FileDownloadIcon /> */}
                  <CustomTooltip displayText={[allResourcesToTranslations.downloadsamplecsv]}>
                    <Button onClick={() => { handleCSVSampleDownload(); }}
                      className="CSVSampleDownload">
                      <CSVFileIcon></CSVFileIcon>
                    </Button>
                  </CustomTooltip>&nbsp;&nbsp;&nbsp;
                  <CustomTooltip displayText={[allResourcesToTranslations.downloadsamplexlsx]}>
                    <Button onClick={() => { handleXLSXSampleDownload(); }}
                      className="XLSXSampleDownload">
                      <ExcelFileIcon></ExcelFileIcon>
                    </Button>
                  </CustomTooltip>
                </div>
                <div className={styles.inputContainer} style={{ paddingTop: "7px" }}>
                  <FormControl variant="outlined" className={styles.formControl}>
                    <InputLabel>Unique Field</InputLabel>
                    <Select className={`${styles.input} uniquefield`}
                      value={filteruniquefield}
                      disabled={isFileUploading}
                      native
                      onChange={(e) => setFilteruniquefield(e.target.value)}
                      variant="outlined"
                      label="Unique Field"
                      id="uniquefield">
                      <option value={1}>{allResourcesToTranslations.emailaddress}</option>
                      <option value={2}>{allResourcesToTranslations.username}</option>
                      <option value={3}>{allResourcesToTranslations.vwsntemgmmaccid}</option>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div style={{ width: '50%', float: "right" }}>
              <div className={styles.Wrapper}>
                <div className={styles.inputContainer} >
                  <span className={styles.labelText}>{allResourcesToTranslations.uploadfile}</span>
                </div>
                {selectedFileName === "" ? (<span></span>)
                  :
                  (
                    <div className={styles.inputContainer}>
                      <div className={styles.importfileContainer}>
                        <div style={{ width: "100%", display: "inline-flex" }}>
                          <div style={{ float: "left", width: "95%", display: "flex" }}>
                            <div> <FileInfoIcon></FileInfoIcon></div>
                            <div>
                              <span>{selectedFileName + " (" + Math.round(progress).toString() + " %)"}</span>
                            </div>
                          </div>
                          <div style={{ float: "right", width: "5%" }}>
                            {!isFileReadDone &&
                              <div className={styles.loaderContainer}>
                                <CircularProgress
                                  style={{ width: !isFileReadDone ? "20px" : "0px" }}
                                  color="primary"
                                  size="10"
                                />
                              </div>
                            }
                            {userImportResult.length <= 0 ? (<span></span>) :
                              (<span onClick={handleResetFileUpload}>
                                <CloseIcon />
                              </span>
                              )}
                          </div>
                        </div>
                        { userImportResult.length <= 0 && isFileReadDone &&
                        <div style={{ width: "100%" }}>
                          <LinearProgressBarWithStripes value={progress} />
                        </div>
                        }
                      </div>
                    </div>
                  )
                }
                <div className={styles.inputContainer}>

                  <div>
                    <div hidden={enableFileUploading} style={{ paddingTop: "20px" }}>
                      <CustomTooltip displayText={[allResourcesToTranslations.draganddropcustomtooltip]}>
                        <div className="fileUpload">
                          <FileDropBox selectedFile={selectedFile} uploadFile={handleFileUpload} setSelectedFile={handleSetSelectedFileName} />
                        </div>
                      </CustomTooltip>
                    </div>
                    {userImportResult.length <= 0 ? (<span></span>) :
                      (
                        <>
                          <p><a style={{ textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }} onClick={handleEnableUserImport}>{allResourcesToTranslations.previewreportreviewcommit}</a></p>

                          {(totalActiveUsersCount >= 1) ? (
                            <div style={{ display: "flex" }} className={styles.warningText}>
                              <div><WarningIcon></WarningIcon></div>
                              <div style={{paddingTop: "3px"}}>{totalActiveUsersCount}&nbsp;{allResourcesToTranslations.accountsaddedmessage}&nbsp;&nbsp;<CustomTooltip displayText={[allResourcesToTranslations.showaddedmessage]}><a onClick={handleEnableAddedAccounts}>{allResourcesToTranslations.showaaccounts}</a></CustomTooltip> </div>
                            </div>) : (<span></span>)
                          }

                          {(totalUpdatedUsersCount >= 1) ? (
                            <div style={{ display: "flex" }} className={styles.warningText}>
                              <div><WarningIcon></WarningIcon></div>
                              <div style={{paddingTop: "3px"}}>{totalUpdatedUsersCount}&nbsp;{allResourcesToTranslations.accountsupdatedmessage}&nbsp;&nbsp;
                                <CustomTooltip displayText={[allResourcesToTranslations.showsupdatedmessage]}><a onClick={handleEnableUpdatedAccounts}>{allResourcesToTranslations.showaaccounts}</a></CustomTooltip></div>
                            </div>) : (<span></span>)
                          }

                          {(totalDeactivatedUsersCount >= 1) ? (
                            <div style={{ display: "flex" }} className={styles.warningText}>
                              <div><WarningIcon></WarningIcon></div>
                              <div style={{paddingTop: "3px"}}>{totalDeactivatedUsersCount}&nbsp;{allResourcesToTranslations.accountsdeactivateddmessage}&nbsp;&nbsp;<CustomTooltip displayText={[allResourcesToTranslations.showsdeactivateddmessage]}><a onClick={handleEnableDeactivatedAccounts}>{allResourcesToTranslations.showaaccounts}</a></CustomTooltip></div>
                            </div>) : (<span></span>)
                          }

                          {(totalErrorUsersCount >= 1) ? (
                            <div style={{ display: "flex" }} className={styles.errorText}>
                              <div><ErrorIcon></ErrorIcon></div>
                              <div style={{paddingTop: "3px"}}>{totalErrorUsersCount}&nbsp;{allResourcesToTranslations.errorsdetected}&nbsp;&nbsp;<CustomTooltip displayText={[allResourcesToTranslations.showserrors]}><a onClick={handleEnableShowErrors}>{allResourcesToTranslations.showserrors}</a></CustomTooltip></div>
                            </div>) : (<span></span>)
                          }

                        </>
                      )
                    }
                  </div>

                </div>
              </div>
            </div>
            </div>
          }
          {(userImportResult.length <= 0) || (isSaveAndCommitCalled) ? (<div style={{ clear: 'both' }}><span>&nbsp;</span></div>)
            :
            (
          <div style={{ clear: 'both' }}>
            <div style={{ width: '100%' }}>
              <div className={styles.Wrapper}>
                {<div style={{ color: "red" }}>{(exportError === undefined || exportError === null || exportError === "") ? "" : ("Export Error: " + exportError)}</div>}

                <div style={{ width: '100%' }}>
                  <div className={styles.labelText} style={{ width: '30%', float: "left", paddingTop: "15px" }}>
                    {enableUserImportResult ? <span>&nbsp;</span> : <span></span>}
                    {enableUserImportErrorResult ? <span>{allResourcesToTranslations.errors}</span> : <span></span>}
                    {enableactiveAccountsResult ? <span>{allResourcesToTranslations.accountsaddedmessage}</span> : <span></span>}
                    {enableupdatedAccountsResult ? <span>{allResourcesToTranslations.accountsupdatedmessage}</span> : <span></span>}
                    {enabledeactiveAccountsResult ? <span>{allResourcesToTranslations.accountsdeactivateddmessage}</span> : <span></span>}
                  </div>
                  <div className={styles.paginationControlsContainer}
                    style={{ justifyContent: "center", width: '40%', float: "left" }}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 20, 100]}
                      component="div"
                      count={enableUserImportResult ? totalUsersCount :
                        (enableUserImportErrorResult ? totalErrorUsersCount :
                          (enableactiveAccountsResult ? totalActiveUsersCount :
                            (enableupdatedAccountsResult ? totalUpdatedUsersCount :
                              (enabledeactiveAccountsResult ? totalDeactivatedUsersCount : totalUsersCount))))
                      }
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>

                <div className={styles.labelText} style={{width: '30%',float:"right", textAlign:"right", paddingTop:"15px"}}>
                  {enableUserImportResult ? <span>{totalUsersCount} Accounts</span> :<span></span>}
                  {enableUserImportErrorResult ? <span style={{color:"red"}}>{totalErrorUsersCount} {allResourcesToTranslations.errors}                   
                  <CustomTooltip displayText={[allResourcesToTranslations.exporttocsv]}>
                                  <Button className="csv" color="primary" onClick={handleCSVExport} id="btnNewSaltCSRReportExportCSV">
                                      <CSVFileIcon />
                                  </Button>
                  </CustomTooltip>
                  <CustomTooltip displayText={[allResourcesToTranslations.exporttoxlsx]}>
                                  <Button className="xlxs" color="primary" onClick={handleXLSXExport} id="btnNewSaltCSRReportExportXLSX">
                                      <ExcelFileIcon />
                                  </Button>
                              </CustomTooltip> 
                  </span> :<span></span>}
                  {enableactiveAccountsResult ? <span>{totalActiveUsersCount} {allResourcesToTranslations.accounts}</span> :<span></span>}
                  {enableupdatedAccountsResult ? <span>{totalUpdatedUsersCount} {allResourcesToTranslations.accounts}</span> :<span></span>}
                  {enabledeactiveAccountsResult ? <span>{totalDeactivatedUsersCount} {allResourcesToTranslations.accounts}</span> :<span></span>}
                </div>
              </div>      
              <div className={styles.tableSection} style={{ clear:"both" }}>
                <div className={styles.tableContainer}>
                  <BrandedCard>
                    <TableContainer>
                      <Table>
                        <TableHead className="importeduserList" >
                          <TableRow>      
                              <TableCell style={{ color: getColor(color.substring(1)),backgroundColor: color,borderRadius: "8px 0 0 0" }} align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div onClick={() => {
                                    setUsernameDescending(!usernameDescending);
                                    sortResultsBy("UserName", !usernameDescending);
                                  }}>
                                    {allResourcesToTranslations.username}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span style={{ opacity: currentSortBy === "UserName" && usernameDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickUserNameAsc}>
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span style={{ opacity: currentSortBy === "UserName" && usernameDescending === true ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickUserNameDesc}>
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                                <div className={styles.tableHeadCellContainer}>
                                  <div onClick={() => {
                                    setUsernameDescending(!passwordDescending);
                                    sortResultsBy("Password", !passwordDescending);
                                  }}>
                                   {allResourcesToTranslations.password}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span style={{ opacity: currentSortBy === "Password" && passwordDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickPasswordAsc}>
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span style={{ opacity: currentSortBy === "Password" && passwordDescending === true ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickPasswordDesc}>
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                                <div className={styles.tableHeadCellContainer}>
                                  <div onClick={() => {
                                    setFirstnameDescending(!firstnameDescending);
                                    sortResultsBy("FirstName", !firstnameDescending);
                                  }}>
                                    {allResourcesToTranslations.firstname}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span style={{ opacity: currentSortBy === "FirstName" && firstnameDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickFirstNameAsc} >
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span style={{ opacity: currentSortBy === "FirstName" && firstnameDescending === true ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickFirstNameDesc} >
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                                <div className={styles.tableHeadCellContainer}>
                                  <div onClick={() => {
                                    setLastnameDescending(!lastnameDescending);
                                    sortResultsBy("LastName", !lastnameDescending);
                                  }}>
                                   {allResourcesToTranslations.lastname}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span style={{ opacity: currentSortBy === "LastName" && lastnameDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickLastNameAsc}>
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span style={{ opacity: currentSortBy === "LastName" && lastnameDescending === true ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickLastNameDesc}>
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                                <div className={styles.tableHeadCellContainer}>
                                  <div onClick={() => { setEmailDescending(!emailDescending); sortResultsBy("Email", !emailDescending); }}>
                                  {allResourcesToTranslations.emailaddress}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span style={{ opacity: currentSortBy === "Email" && emailDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickEmailAsc}>
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span style={{ opacity: currentSortBy === "Email" && emailDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickEmailDesc}>
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="center">
                                <div className={styles.tableHeadCellContainer}>
                                  <div onClick={() => { setExternalIDDescending(!externalIDDescending); sortResultsBy("ExternalID", !externalIDDescending); }}>
                                  {allResourcesToTranslations.externalid}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span style={{ opacity: currentSortBy === "ExternalID" && externalIDDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickExternalIDAsc}>
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span style={{ opacity: currentSortBy === "ExternalID" && externalIDDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickExternalIDDesc}>
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="center">
                                <div className={styles.tableHeadCellContainer}>
                                  <div onClick={() => { setUnitIDDescending(!unitIDDescending); sortResultsBy("UnitID", !unitIDDescending); }}>
                                    {allResourcesToTranslations.unitid}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span style={{ opacity: currentSortBy === "UnitID" && unitIDDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickUnitIDAsc}>
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span style={{ opacity: currentSortBy === "UnitID" && unitIDDescending === true ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickUnitIDDesc}>
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="center">
                                <div className={styles.tableHeadCellContainer}>
                                  <div onClick={() => { setarchiveDescending(!archiveDescending); sortResultsBy("Archive", !archiveDescending); }}>
                                  {allResourcesToTranslations.archive}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span style={{ opacity: currentSortBy === "Archive" && archiveDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickArchiveAsc}>
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span style={{ opacity: currentSortBy === "Archive" && archiveDescending === true ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickArchiveDesc}>
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="center">
                                <div className={styles.tableHeadCellContainer}>
                                  <div onClick={() => { setClassificationNameDescending(!classificationNameDescending); sortResultsBy("ClassificationName", !classificationNameDescending); }}>
                                    {allResourcesToTranslations.groupby}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span style={{ opacity: currentSortBy === "ClassificationName" && classificationNameDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickClassificationNameAsc}>
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span style={{ opacity: currentSortBy === "ClassificationName" && classificationNameDescending === true ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickClassificationNameDesc}>
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="center">
                                <div className={styles.tableHeadCellContainer}>
                                  <div onClick={() => { setClassificationOptionDescending(!classificationOptionDescending); sortResultsBy("ClassificationName", !classificationOptionDescending); }}>
                                  {allResourcesToTranslations.groupbyvalue}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span style={{ opacity: currentSortBy === "classificationOption" && classificationOptionDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickClassificationOptionAsc}>
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span style={{ opacity: currentSortBy === "classificationOption" && classificationOptionDescending === true ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickClassificationOptionDesc}>
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                                <div className={styles.tableHeadCellContainer}>
                                  <div onClick={() => { setNotifyUnitAdminDescending(!notifyUnitAdminDescending); sortResultsBy("ClassificationName", !notifyUnitAdminDescending); }}>
                                  {allResourcesToTranslations.notifyunitadmin}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span style={{ opacity: currentSortBy === "notifyUnitAdmin" && notifyUnitAdminDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickNotifyUnitAdminAsc}>
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span style={{ opacity: currentSortBy === "notifyUnitAdmin" && notifyUnitAdminDescending === true ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickNotifyUnitAdminDesc}>
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                                <div className={styles.tableHeadCellContainer}>
                                  <div onClick={() => { setNotifyOrgAdminDescending(!notifyOrgAdminDescending); sortResultsBy("NotifyOrgAdmin", !notifyOrgAdminDescending); }}>
                                  {allResourcesToTranslations.notifyorgadmin}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span style={{ opacity: currentSortBy === "notifyOrgAdmin" && notifyOrgAdminDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickNotifyOrgAdminAsc}>
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span style={{ opacity: currentSortBy === "notifyOrgAdmin" && notifyOrgAdminDescending === true ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickNotifyOrgAdminDesc}>
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                                <div className={styles.tableHeadCellContainer}>
                                  <div onClick={() => { setManagerNotificationDescending(!managerNotificationDescending); sortResultsBy("ManagerNotification", !managerNotificationDescending); }}>
                                  {allResourcesToTranslations.notifymanager}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span style={{ opacity: currentSortBy === "managerNotification" && managerNotificationDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickManagerNotificationAsc}>
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span style={{ opacity: currentSortBy === "managerNotification" && managerNotificationDescending === true ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickManagerNotificationDesc}>
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }} align="left">
                                <div className={styles.tableHeadCellContainer}>
                                  <div onClick={() => { setManagerToNotifyDescending(!managerToNotifyDescending); sortResultsBy("ManagerToNotify", !managerToNotifyDescending); }}>
                                  {allResourcesToTranslations.manageremailaddress}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span style={{ opacity: currentSortBy === "managerToNotify" && managerToNotifyDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickManagerToNotifyAsc}>
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span style={{ opacity: currentSortBy === "managerToNotify" && managerToNotifyDescending === true ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickManagerToNotifyDesc}>
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {enableUserImportErrorResult ? (
                              userImportErrorResult
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((result: UserImportResultInfo, i) => {
                                  return (
                                    <><TableRow key={`userImportResult-${i}`} style={rowBackgroundColor(i)}>
                                      {(result.errorFieldsCSV.toString().trim().split(';')).find((value: string) => value == "1")
                                        ? (<TableCell align="left" className={styles.erroeFields}><div> <ErrorIcon></ErrorIcon></div><div className={styles.wrraptxt}>{result.username}</div></TableCell>) : (<TableCell align="left"><div className={styles.wrraptxt}>{result.username}</div></TableCell>)
                                      }
                                      {(result.errorFieldsCSV.toString().trim().split(';')).find((value: string) => value == "2")
                                        ? (<TableCell align="left" className={styles.erroeFields}><div> <ErrorIcon></ErrorIcon></div><div>{result.password}</div></TableCell>) : (<TableCell align="left"><div>{result.password}</div></TableCell>)
                                      }
                                      {(result.errorFieldsCSV.toString().trim().split(';')).find((value: string) => value == "3")
                                        ? (<TableCell align="left" className={styles.erroeFields}><div> <ErrorIcon></ErrorIcon></div><div className={styles.wrraptxt}>{result.firstname}</div></TableCell>) : (<TableCell align="left"><div className={styles.wrraptxt}>{result.firstname}</div></TableCell>)
                                      }
                                      {(result.errorFieldsCSV.toString().trim().split(';')).find((value: string) => value == "4")
                                        ? (<TableCell align="left" className={styles.erroeFields}><div> <ErrorIcon></ErrorIcon></div><div className={styles.wrraptxt}>{result.lastname}</div></TableCell>) : (<TableCell align="left"><div className={styles.wrraptxt}>{result.lastname}</div></TableCell>)
                                      }
                                      {(result.errorFieldsCSV.toString().trim().split(';')).find((value: string) => value == "5")
                                        ? (<TableCell align="left" className={styles.erroeFields}><div><ErrorIcon></ErrorIcon></div><div className={styles.wrraptxt}>{result.email}</div></TableCell>) : (<TableCell align="left"><div className={styles.wrraptxt}>{result.email}</div></TableCell>)
                                      }
                                      {(result.errorFieldsCSV.toString().trim().split(';')).find((value: string) => value == "6")
                                        ? (<TableCell align="center" className={styles.erroeFields}><div><ErrorIcon></ErrorIcon></div><div>{(result.externalID === "^" ? "" : result.externalID)}</div></TableCell>) : (<TableCell align="left">{(result.externalID === "^" ? "" : result.externalID)}</TableCell>)
                                      }
                                      {(result.errorFieldsCSV.toString().trim().split(';')).find((value: string) => value == "7")
                                        ? (<TableCell align="center" className={styles.erroeFields}><div><ErrorIcon></ErrorIcon></div><div>{result.unitID}</div></TableCell>) : (<TableCell align="left">{result.unitID}</TableCell>)
                                      }
                                      {(result.errorFieldsCSV.split(';')).find((value: string) => value == "8")
                                        ? (<TableCell align="center" className={styles.erroeFields}><div><ErrorIcon></ErrorIcon></div><div>{result.archive}</div></TableCell>) : (<TableCell align="left">{result.archive}</TableCell>)
                                      }
                                      {(result.errorFieldsCSV.toString().trim().split(';')).find((value: string) => value == "9")
                                        ? (<TableCell align="center" className={styles.erroeFields}><div><ErrorIcon></ErrorIcon></div><div>{result.classificationName}</div></TableCell>) : (<TableCell align="left">{result.classificationName}</TableCell>)
                                      }
                                      {(result.errorFieldsCSV.toString().trim().split(';')).find((value: string) => value == "10")
                                        ? (<TableCell align="center" className={styles.erroeFields}><div><ErrorIcon></ErrorIcon></div><div>{result.classificationOption}</div></TableCell>) : (<TableCell align="left">{result.classificationOption}</TableCell>)
                                      }
                                      {(result.errorFieldsCSV.toString().trim().split(';')).find((value: string) => value == "11")
                                        ? (<TableCell align="center" className={styles.erroeFields}><div><ErrorIcon></ErrorIcon></div><div>{result.notifyUnitAdmin}</div></TableCell>) : (<TableCell align="left">{result.notifyUnitAdmin}</TableCell>)
                                      }
                                      {(result.errorFieldsCSV.toString().trim().split(';')).find((value: string) => value == "12")
                                        ? (<TableCell align="center" className={styles.erroeFields}><div><ErrorIcon></ErrorIcon></div><div>{result.notifyOrgAdmin}</div></TableCell>) : (<TableCell align="left">{result.notifyOrgAdmin}</TableCell>)
                                      }
                                      {(result.errorFieldsCSV.toString().trim().split(';')).find((value: string) => value == "13")
                                        ? (<TableCell align="center" className={styles.erroeFields}><div><ErrorIcon></ErrorIcon></div><div>{result.managerNotification}</div></TableCell>) : (<TableCell align="left">{result.managerNotification}</TableCell>)
                                      }
                                      {(result.errorFieldsCSV.toString().trim().split(';')).find((value: string) => value == "14")
                                        ? (<TableCell align="center" className={styles.erroeFields}><div><ErrorIcon></ErrorIcon></div><div className={styles.wrraptxt}>{(result.managerToNotify === "^" ? "" : result.managerToNotify)}</div></TableCell>) : (<TableCell align="left"><div className={styles.wrraptxt}>{result.managerToNotify}</div></TableCell>)
                                      }
                                    </TableRow>

                                      <TableRow key={`userImportResult-${i}`} style={rowBackgroundColor(i)}>
                                        <TableCell align="center" colSpan={14}  className={styles.errorReasontext}>
                                        <div><ErrorIcon></ErrorIcon></div>                                   
                                        <div>{getErrorReason(result.errorResourcesCSV.toLowerCase())}</div>                                     
                                        </TableCell>
                                      </TableRow></>
                                  );
                                })
                            ) : (<span></span>)
                            }

                            {enableUserImportResult ?
                              (
                                userImportResult
                                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                  .map((result: UserImportResultInfo, i) => {
                                    return (
                                      <TableRow key={`userImportResult-${i}`} style={rowBackgroundColor(i)}>
                                        <TableCell align="left"><div className={styles.wrraptxt}>{result.username}</div></TableCell>
                                        <TableCell align="left"><div>{result.password}</div></TableCell>
                                        <TableCell align="left"><div className={styles.wrraptxt}>{result.firstname}</div></TableCell>
                                        <TableCell align="left"><div className={styles.wrraptxt}>{result.lastname}</div></TableCell>
                                        <TableCell align="left"><div className={styles.wrraptxt}>{result.email}</div></TableCell>
                                        <TableCell align="center">{(result.externalID === "^" ? "" : result.externalID)}</TableCell>
                                        <TableCell align="center">{result.unitID}</TableCell>
                                        <TableCell align="center">{result.archive}</TableCell>
                                        <TableCell align="center">{result.classificationName}</TableCell>
                                        <TableCell align="center">{result.classificationOption}</TableCell>
                                        <TableCell align="center">{result.notifyUnitAdmin}</TableCell>
                                        <TableCell align="center">{result.notifyOrgAdmin}</TableCell>
                                        <TableCell align="center">{result.managerNotification}</TableCell>
                                        <TableCell align="center"><div className={styles.wrraptxt}>{(result.managerToNotify === "^" ? "" : result.managerToNotify)}</div></TableCell>
                                      </TableRow>
                                    );
                                  })
                              ) : (<span></span>)
                            }


                            {enableactiveAccountsResult ?
                              (
                                activeAccountsResult
                                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                  .map((result: UserImportResultInfo, i) => {
                                    return (
                                      <TableRow key={`userImportResult-${i}`} style={rowBackgroundColor(i)}>
                                        <TableCell align="left"><div className={styles.wrraptxt}>{result.username}</div></TableCell>
                                        <TableCell align="left"><div>{result.password}</div></TableCell>
                                        <TableCell align="left"><div className={styles.wrraptxt}>{result.firstname}</div></TableCell>
                                        <TableCell align="left"><div className={styles.wrraptxt}>{result.lastname}</div></TableCell>
                                        <TableCell align="left"><div className={styles.wrraptxt}>{result.email}</div></TableCell>
                                        <TableCell align="center">{(result.externalID === "^" ? "" : result.externalID)}</TableCell>
                                        <TableCell align="center">{result.unitID}</TableCell>
                                        <TableCell align="center">{result.archive}</TableCell>
                                        <TableCell align="center">{result.classificationName}</TableCell>
                                        <TableCell align="center">{result.classificationOption}</TableCell>
                                        <TableCell align="center">{result.notifyUnitAdmin}</TableCell>
                                        <TableCell align="center">{result.notifyOrgAdmin}</TableCell>
                                        <TableCell align="center">{result.managerNotification}</TableCell>
                                        <TableCell align="center"><div className={styles.wrraptxt}>{(result.managerToNotify === "^" ? "" : result.managerToNotify)}</div></TableCell>
                                      </TableRow>
                                    );
                                  })
                              ) : (<span></span>)
                            }

                            {enableupdatedAccountsResult ?
                              (
                                updatedAccountsResult
                                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                  .map((result: UserImportResultInfo, i) => {
                                    return (
                                      <TableRow key={`userImportResult-${i}`} style={rowBackgroundColor(i)}>
                                        <TableCell align="left"   className={(result.updatedFieldsCSV.toString().trim().split(';')).find((value: string) => value == "1") ? styles.activeaccountbg : ""} ><div className={styles.wrraptxt}>{result.username}</div></TableCell>
                                        <TableCell align="left"   className={(result.updatedFieldsCSV.toString().trim().split(';')).find((value: string) => value == "2") ? styles.activeaccountbg : ""} ><div>{result.password}</div></TableCell>
                                        <TableCell align="left"   className={(result.updatedFieldsCSV.toString().trim().split(';')).find((value: string) => value == "3") ? styles.activeaccountbg : ""} ><div className={styles.wrraptxt}>{result.firstname}</div></TableCell>
                                        <TableCell align="left"   className={(result.updatedFieldsCSV.toString().trim().split(';')).find((value: string) => value == "4") ? styles.activeaccountbg : ""} ><div className={styles.wrraptxt}>{result.lastname}</div></TableCell>
                                        <TableCell align="left"   className={(result.updatedFieldsCSV.toString().trim().split(';')).find((value: string) => value == "5") ? styles.activeaccountbg : ""} ><div className={styles.wrraptxt}>{result.email}</div></TableCell>
                                        <TableCell align="center" className={(result.updatedFieldsCSV.toString().trim().split(';')).find((value: string) => value == "6") ? styles.activeaccountbg : ""} >{(result.externalID == "^" && (result.updatedFieldsCSV.toString().trim().split(';')).find((value: string) => value == "6")) ? "" : (result.externalID === "^" ? "" : result.externalID)}</TableCell>
                                        <TableCell align="center" className={(result.updatedFieldsCSV.toString().trim().split(';')).find((value: string) => value == "7") ? styles.activeaccountbg : ""} >{result.unitID}</TableCell>
                                        <TableCell align="center" className={(result.updatedFieldsCSV.toString().trim().split(';')).find((value: string) => value == "8") ? styles.activeaccountbg : ""} >{result.archive}</TableCell>
                                        <TableCell align="center" className={(result.updatedFieldsCSV.toString().trim().split(';')).find((value: string) => value == "9") ? styles.activeaccountbg : ""} >{result.classificationName}</TableCell>
                                        <TableCell align="center" className={(result.updatedFieldsCSV.toString().trim().split(';')).find((value: string) => value == "10") ? styles.activeaccountbg : ""} >{result.classificationOption}</TableCell>
                                        <TableCell align="center" className={(result.updatedFieldsCSV.toString().trim().split(';')).find((value: string) => value == "11") ? styles.activeaccountbg : ""} >{result.notifyUnitAdmin}</TableCell>
                                        <TableCell align="center" className={(result.updatedFieldsCSV.toString().trim().split(';')).find((value: string) => value == "12") ? styles.activeaccountbg : ""} >{result.notifyOrgAdmin}</TableCell>
                                        <TableCell align="center" className={(result.updatedFieldsCSV.toString().trim().split(';')).find((value: string) => value == "13") ? styles.activeaccountbg : ""} >{result.managerNotification}</TableCell>
                                        <TableCell align="center" className={(result.updatedFieldsCSV.toString().trim().split(';')).find((value: string) => value == "14") ? styles.activeaccountbg : ""} ><div className={styles.wrraptxt}>{(result.managerToNotify == "^" && (result.updatedFieldsCSV.toString().trim().split(';')).find((value: string) => value == "14")) ? "" : (result.managerToNotify === "^" ? "" : result.managerToNotify)}</div></TableCell>

                                      </TableRow>
                                    );
                                  })
                              ) : (<span></span>)
                            }


                            {enabledeactiveAccountsResult ?
                              (
                                deactiveAccountsResult
                                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                  .map((result: UserImportResultInfo, i) => {
                                    return (
                                      <TableRow key={`userImportResult-${i}`} style={rowBackgroundColor(i)}>
                                        <TableCell align="left"><div className={styles.wrraptxt}>{result.username}</div></TableCell>
                                        <TableCell align="left"><div>{result.password}</div></TableCell>
                                        <TableCell align="left"><div className={styles.wrraptxt}>{result.firstname}</div></TableCell>
                                        <TableCell align="left"><div className={styles.wrraptxt}>{result.lastname}</div></TableCell>
                                        <TableCell align="left"> <div className={styles.wrraptxt}>{result.email}</div></TableCell>
                                        <TableCell align="center">{(result.externalID === "^" ? "" : result.externalID)}</TableCell>
                                        <TableCell align="center">{result.unitID}</TableCell>
                                        <TableCell align="center">{result.archive}</TableCell>
                                        <TableCell align="center">{result.classificationName}</TableCell>
                                        <TableCell align="center">{result.classificationOption}</TableCell>
                                        <TableCell align="center">{result.notifyUnitAdmin}</TableCell>
                                        <TableCell align="center">{result.notifyOrgAdmin}</TableCell>
                                        <TableCell align="center">{result.managerNotification}</TableCell>
                                        <TableCell align="center"><div className={styles.wrraptxt}>{(result.managerToNotify === "^" ? "" : result.managerToNotify)}</div></TableCell>
                                      </TableRow>
                                    );
                                  })
                              ) : (<span></span>)
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </BrandedCard>
                  </div>
                </div>
                <div style={{ clear: 'both' }}></div>
                <div style={{ width: '100%', clear: 'both' }} >
                  <div style={{ width: "30%", float: "left" }}
                  ><span>&nbsp;</span></div>
                  <div
                    style={{ width: "40%", float: "left" }}
                    className={styles.paginationControlsContainer}
                  >
                    <TablePagination
                      component="div"
                      count={enableUserImportResult ? totalUsersCount :
                        (enableUserImportErrorResult ? totalErrorUsersCount :
                          (enableactiveAccountsResult ? totalActiveUsersCount :
                            (enableupdatedAccountsResult ? totalUpdatedUsersCount :
                              (enabledeactiveAccountsResult ? totalDeactivatedUsersCount : totalUsersCount))))
                      }
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
               
                  <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", width: "30%", paddingTop: "10px" }}>                    
                    
                  {(totalActiveUsersCount>0) || (totalUpdatedUsersCount > 0) || (totalDeactivatedUsersCount > 0) ?
                  (
                    <CustomTooltip displayText={[allResourcesToTranslations.commitaccountswithouterrors]}>
                      <span className="saveData">
                        <Button
                          onClick={handleSave}
                          variant="contained"
                          color="primary"
                          className="save"
                        >
                          <SaveIcon />
                        </Button>
                      </span>
                    </CustomTooltip>
                  ):(<span></span>)}
                    <CustomTooltip displayText={[allResourcesToTranslations.canceluploadedfile]}>
                      <span  className={`${styles.cancelIcon} cancelData`} onClick={handleResetFileUpload}>
                        <CloseWhiteIcon />
                      </span>
                    </CustomTooltip>
                  </div>
                </div>
                <div style={{ clear: 'both' }}></div>
              </div>
            </div>
          </div>
           )}
            { (isSaveAndCommitCalled) &&
              <div style={{ width: '100%'}} className={styles.Wrapper}>
                <div className={styles.inputContainer} >
                  <span className={styles.labelText}> {allResourcesToTranslations.importaccountstatus}</span>
                </div>
                <div className={styles.importAccountSentStatus}><span>{Math.round(saveProgress).toString() + " % Accounts Imported"}</span></div>
                {/* <div className={styles.importAccountSentStatus}><span>{allResourcesToTranslations.accountsimported}: {totalUsersSavedFromFile} / {totalUsersInFile}</span> </div>  */}
                <div style={{ padding: '50px 25px' }}>
                    <LinearProgressWithLabel value={saveProgress} />
                </div>
                <div className={styles.inputContainer}> 
                 <span className={styles.labelText}>Note : </span><span>{allResourcesToTranslations.importaccountspagenavigationwarrningmessage}.</span>
                </div>
              </div>
            }
          {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />} 
        </div>
      </UnbrandedCard>
    </>
  );
}

export default ImportAccounts
