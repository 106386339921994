import React, { useContext,useEffect,useRef,useState } from 'react'

import { useReactOidc } from '@axa-fr/react-oidc-context';
import Modal from '../Components/Modal'
import styles from '../styles/supportModal.module.css'
import Button from '@material-ui/core/Button'
import AustraliaFlag from '../Flags/AustraliaFlag'
import GlobeFlag from '../Flags/GlobeFlag'
import NZFlag from '../Flags/NZFlag'
import SGFlag from '../Flags/SGFlag'
import USFlag from '../Flags/USFlag'
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { TranslationsContext } from '../contexts/TranslationsContext';

import TermsAndConditionsCard from '../Layout/TermsAndConditionsCard';
import { UIContext } from '../contexts/UIContext';

interface Props {
    modalOpen: boolean;  
    emailAddress:string;
    phoneNumber:string;
    replyToAddress:string;
    setModalOpen: (openModal: boolean) => void
}


const renderEmail = (str:string) => {
    if (str !== null) {
        return str.split(';').map((qi, i) => (
            <span key={qi + i.toString()}> { qi }</span>           
        ))
    }
}
const SupportModal = ({modalOpen, setModalOpen,emailAddress,phoneNumber,replyToAddress }: Props) => {
    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)    
    return (
        <Modal modalOpen={modalOpen} setModalOpen={setModalOpen}>
            <div className={styles.container} >
                <div className={styles.modalcontent}>
                    <div className={styles.modalTextWrapper}>
                        <h5 className={styles.panelTitle}>{ allResourcesToTranslations.techsupportclick2 }</h5>
                        <p>                       
                            {allResourcesToTranslations.sendanemailto} {renderEmail(emailAddress)}
                        </p>   
                        <Button variant="contained" color="primary" className={styles.contactsupportbtn}>
                                    <MailOutlineIcon/>                           
                                    <a href={`mailto:${replyToAddress}`}>  { allResourcesToTranslations.contactsupport }                            
                                    </a>
                        </Button>
                    </div>
                    <div className={styles.modalTextWrapper}>
                            <h6 className={styles.panelTitle}>
                                { allResourcesToTranslations.forurgentqueries }
                            </h6>

                            {(phoneNumber) === "1800 676 011" ? (
                            <ul>
                            <li>
                                <div className={styles.contactNumber}>
                                    <AustraliaFlag />
                                        <span>AUS 1800 676 011</span>                               
                                </div>
                            </li>
                                <li>
                                <div className={styles.contactNumber}>
                                    <NZFlag />
                                    <span>NZ 0800 629 691</span>
                                </div>
                            </li>
                            <li>
                                <div className={styles.contactNumber}>
                                    <SGFlag />
                                    <span>SG 800 852 3070</span>
                                </div>
                            </li>
                            <li>
                                <div className={styles.contactNumber}>
                                    <USFlag />
                                    <span>US +1 415 528 3488</span>
                                </div>
                            </li>
                            <li>
                                <div className={styles.contactNumber}>
                                    <GlobeFlag />
                                    <span>IID +61 2 8823 4103</span>
                                </div>
                            </li> 
                        </ul>
                            ):(
                                <ul>
                                <li>
                                    <div className={styles.contactNumber}>                                                              
                                        <span> {phoneNumber}</span>
                                    </div>
                                </li>
                            </ul>
                            )
                            }       
                            
                    </div>                     
                </div> 
                <div className={styles.center}>
                        <div className={styles.finePrintContainer}>
                                <span>
                                    <a href="https://www.grcsolutions.com.au/" target="_blank">GRC Solutions</a>
                                </span> 
                                &nbsp;|&nbsp;                 
                                <span><TermsAndConditionsCard/></span>  
                                &nbsp;|&nbsp;                    
                                <span>
                                   <a href="https://www.grcsolutions.com.au/privacy-policy" target="_blank">
                                        { allResourcesToTranslations.privacypolicy }
                                    </a>
                                </span>
                        </div>
                    </div>   
            </div> 
        </Modal>        
    );
}

export default SupportModal;
