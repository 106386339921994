import React, { useContext } from 'react';
import CustomTooltip from './CustomTooltip';
import styles from '../styles/historyProgressBar.module.css'
import { TranslationsContext } from '../contexts/TranslationsContext';

export interface Props {
    userScore: number;
    passmark: number; 
}

const HistoryProgressBar = ({ userScore, passmark }: Props) => {
  const scoreColor = userScore >= passmark ? "#23b787" : "#fe0606";
  const { translations: {allResourcesToTranslations}} = useContext(TranslationsContext)
  return (
    <>
      <div className={styles.scoreDisplay}>
        <span style={{ color: scoreColor }}>{`${userScore}%`}</span>
      </div>
        <div className={styles.barContainer}>
          <div className={styles.background}>
            <div className={styles.bar} style={{ backgroundColor: scoreColor, width: `${userScore}%` }}/>
            <CustomTooltip displayText={[`${allResourcesToTranslations.passmark} ${passmark}%`]}>
                <div className={styles.passmark} style={{ marginLeft: `${passmark - 1}%`}} /*We subract one from the display % so the end with be covered if the passmark is 100%*//>
            </CustomTooltip>
          </div>
        </div>
    </>
  );
};

export default HistoryProgressBar;