import React from 'react'
import { MyTrainingItem } from '../../globalTypes'
import CertificateButton from './CertificateButton'
import StartCourseButton from './StartCourseButton'
import ViewPolicyButton from './ViewPolicyButton'

interface Props {
    trainingListItem: MyTrainingItem;
    logNavigation: (message: string) => void
}

const MyTrainingListActionButtons = ({ trainingListItem, logNavigation }: Props) => {
    const { type } = trainingListItem
    switch (type) {
        case "Course":
            return(
                <>
                    <CertificateButton 
                        trainingListItem={trainingListItem}
                        logNavigation={logNavigation}
                    />
                    <StartCourseButton
                        trainingListItem={trainingListItem}
                        logNavigation={logNavigation}
                    />
                </>
            )
        case "Policy":
            return(
                <ViewPolicyButton
                    trainingListItem={trainingListItem}
                    logNavigation={logNavigation}
                />
            )
        default:
            return null;
    }
}

export default MyTrainingListActionButtons
