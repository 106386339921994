import axios from 'axios';
import { addError } from '../AddError';

export const SaveOrgDeactivations = async (token: string, userId: number, orgDomain: string, orgId: number | null, deactivationDate: string,
   isExtended: boolean, isActive: boolean,   
  toEmail: string, accountManagerID: number, cc: string ,id: number 
  ,selectedOrgID: number = -1, selectedOrgDomainName: string = "", selectOrgSiteName: string = "") => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/SuperAdministration/SaveOrgDeactivations`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        orgID: orgId,      
        orgDomainName: orgDomain,
        userId: userId,        
        deactivationStartDate:  deactivationDate == "" ? null : deactivationDate,     
        isExtended: isExtended,
        isActive: isActive,         
        toEmail: toEmail,
        accountManagerID: accountManagerID,
        cc:cc,
        iD:id,
        selectedOrgID: selectedOrgID,
        selectedOrgDomainName: selectedOrgDomainName, 
        selectOrgSiteName: selectOrgSiteName
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "SaveOrgDeactivation.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "SaveOrgDeactivation.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "SaveOrgDeactivation.ts", 1)
    }
    return null;
  }
};

export default SaveOrgDeactivations;