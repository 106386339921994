//React + Typescript
import React, { useEffect, useState, useContext } from "react";
import { UnitTreeItem } from "../../../globalTypes";

//MUI Components
import {
  FormControl, RadioGroup
} from "@material-ui/core";

// Contexts
import { TranslationsContext } from "../../../contexts/TranslationsContext";

//Styles
import styles from "../../../styles/moveUnit.module.css";
import RadioUnitTreeWithDND from "../MoveUnit/RadioUnitTreeWithDND";

export const RadioUnitsForMoveWithDND = (props: any) => {
  const { isResetClicked, givenUnits, selectedUnitId, setSelectedUnitCallback, setSelectedunitlevel, handleDragEndOnUnitTreeGiven, calledFrom } = props;
  const [units, setUnits] = useState<Array<UnitTreeItem>>(givenUnits);
  const [selectedUnit, setSelectedUnit] = useState<any>("");
  const [interactionCount, setInteractionCount] = useState<number>(0); //Required to force update the tree
  const [filterUnitName, setFilterUnitName] = useState("");
  const [unitlevel, setUnitLevel] = useState<any>("toplevel");
  const [currentUnitSubTreeToSuppress, setCurrentUnitSubTreeToSuppress] = useState<number>(0);

  const { translations: { allResourcesToTranslations }, } = useContext(TranslationsContext);
  
  useEffect(() => {
    // For DND set all units as expanded by default
    if (!!givenUnits)
    {
      setUnitsWithGivenExpansionAndNoChildPlaceHolder(givenUnits, true, currentUnitSubTreeToSuppress);
      setUnits([...givenUnits]);
      if(selectedUnitId !== 0) {
        setUnitSelected(givenUnits);
      }
    }
  },[givenUnits])
  
  useEffect(() => {
    if (isResetClicked) {
      if (selectedUnitId <= 0) {
        resetAllUnitSelection();
        setSelectedUnit("");
        setSelectedUnitCallback(0);
      }
    }
  },[isResetClicked])
  
  const resetAllUnitSelection = () => {
    let updatedUnitsSource = units;
    const updateAllUnselection = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.isSelected === true) {
          unit.isSelected = !unit.isSelected;
          setSelectedUnit(unit.unitID);
          setSelectedUnitCallback(unit.unitID);
        }
        updateAllUnselection(unit.childUnitTreeItems);
      }
    };
    updateAllUnselection(updatedUnitsSource);
    setUnits(updatedUnitsSource);
  }
  
  const setUnitsWithGivenExpansionAndNoChildPlaceHolder = (unitArrayGiven: Array<UnitTreeItem>, expansionFlag: boolean, hierarchyParentToSuppress: number = 0) => {
    let updatedUnits = unitArrayGiven;
    const updateAllExpanded = (unitArray: Array<UnitTreeItem> | null, expansionFlagLoc: boolean, hierarchyParentToSuppressLoc: number = 0) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (!!unit !== true) {
          continue;
        }
        unit.isExpanded = (unit.name == "--drop here--" || (hierarchyParentToSuppressLoc > 0 && hierarchyParentToSuppressLoc == unit.unitID)) ? false : expansionFlagLoc;
        unit.isSelected = unit.name == "--drop here--" ? false : unit.isSelected;
        unit.isDisabled = unit.name == "--drop here--" ? true : unit.isDisabled;
        setSelectedUnit(unit.unitID);
        setSelectedUnitCallback(unit.unitID);
        if (!!unit.childUnitTreeItems === false && unit.name != "--drop here--") {
          unit.childUnitTreeItems = [];
          let noChildPlaceHolder: UnitTreeItem = {
            childUnitTreeItems: null,
            isDisabled: false,
            isExpanded: false,
            isSelected: false,
            isHidden: false,
            name: "--drop here--",
            parentUnitID: unit.unitID,
            unitHierarchy: unit.unitHierarchy + " > " + unit.name,
            unitID: -1
          }
          unit.childUnitTreeItems.push(noChildPlaceHolder);
        }
        updateAllExpanded(unit.childUnitTreeItems, (hierarchyParentToSuppressLoc > 0 && hierarchyParentToSuppressLoc == unit.unitID) ? false : expansionFlagLoc, hierarchyParentToSuppressLoc);
      }
    };
    updateAllExpanded(updatedUnits, expansionFlag, hierarchyParentToSuppress);
    setUnits(updatedUnits);
    return updatedUnits;
  }

  const updateSelectedUnit = (id: any) => {
    let updatedUnits = units;
    const updateSelected = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.unitID == id.target.value) {
          unit.isSelected = !unit.isSelected;
          setSelectedUnit(unit.unitID);
          setSelectedUnitCallback(unit.unitID);
        }
        updateSelected(unit.childUnitTreeItems);
      }
    };
    updateSelected(updatedUnits);
    setInteractionCount(interactionCount + 1);
    setUnits(updatedUnits);
  };

  const updateExpandedUnit = (id: number) => {
    let updatedUnits = units;
    const checkForExpanded = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.unitID === id) {
          unit.isExpanded = !unit.isExpanded;
        }
        checkForExpanded(unit.childUnitTreeItems);
      }
    };
    checkForExpanded(updatedUnits);
    setInteractionCount(interactionCount + 1);
    setUnits(updatedUnits);
  };

  const setUnitSelected = (givenUnits: Array<UnitTreeItem> | null) => {
    let updatedUnits = givenUnits;
    const checkIfAnyUnitSelected = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return false; //Break if no children
      if (unitArray.length === 0) return false;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.isSelected) {
          setSelectedUnit(unit.unitID);
          setSelectedUnitCallback(unit.unitID);
          return true;
        }
        if (checkIfAnyUnitSelected(unit.childUnitTreeItems)) {
          return true;
        }
      }
      return false;
    };
    return checkIfAnyUnitSelected(updatedUnits);
  };

  const isAnyUnitSelected = (givenUnits: Array<UnitTreeItem> | null) => {
    let updatedUnits = givenUnits;
    const checkIfAnyUnitSelected = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return false; //Break if no children
      if (unitArray.length === 0) return false;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.isSelected) {
          return true;
        }
        if (checkIfAnyUnitSelected(unit.childUnitTreeItems)) {
          return true;
        }
      }
      return false;
    };
    return checkIfAnyUnitSelected(updatedUnits);
  };

  const expandAllUnits = () => {
    let updatedUnits = units;
    const checkForSelected = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        if (!unitArray[i].isHidden) {
          const unit = unitArray[i];
          unit.isExpanded = true;
          checkForSelected(unit.childUnitTreeItems);
        }
      }
    };
    checkForSelected(updatedUnits);
    setInteractionCount(interactionCount + 1);
    setUnits(updatedUnits);
  };

  const colapseAllUnits = () => {
    let updatedUnits = units;
    const checkForSelected = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        if (!unitArray[i].isHidden) {
          const unit = unitArray[i];
          unit.isExpanded = false;
          checkForSelected(unit.childUnitTreeItems);
        }
      }
    };
    checkForSelected(updatedUnits);
    setInteractionCount(interactionCount + 1);
    setUnits(updatedUnits);
  };

  const isGivenItemNameMatched = (
    item: UnitTreeItem,
    givenFilterUnitName: string
  ): boolean | undefined => {
    if (
      item === null ||
      item === undefined ||
      item.name === null ||
      item.name === undefined
    ) {
      return false;
    }
    if (
      givenFilterUnitName === null ||
      givenFilterUnitName === undefined ||
      givenFilterUnitName.trimLeft().trimRight() === ""
    ) {
      return true;
    } else {
      if (item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase())) {
        return true;
      }
      return false;
    }
  };

  const isAnyChildItemNameMatched = (
    list: UnitTreeItem[] | null,
    givenFilterUnitName: string
  ): boolean | undefined => {
    if (list === null || list === undefined || list.length <= 0) {
      return false;
    }
    if (
      givenFilterUnitName === null ||
      givenFilterUnitName === undefined ||
      givenFilterUnitName.trimLeft().trimRight() === ""
    ) {
      return true;
    } else {
      var newList = list.filter((e) => {
        return (
          isGivenItemNameMatched(e, givenFilterUnitName) === true ||
          isAnyChildItemNameMatched(
            e.childUnitTreeItems,
            givenFilterUnitName
          ) === true
        );
      });
      if (newList !== null && newList !== undefined && newList.length > 0) {
        return true;
      }
      return false;
    }
  };

  const updateUnitItemBasedOnNameMatch = (
    item: UnitTreeItem,
    givenFilterUnitName: string
  ): boolean | undefined => {
    let isMatchFound = false;
    if (
      (givenFilterUnitName === null ||
        givenFilterUnitName === undefined ||
        givenFilterUnitName.trimLeft().trimRight() === "") &&
      item !== null &&
      item !== undefined
    ) {
      isMatchFound = true;
      item.isDisabled = !isMatchFound;
      item.isHidden = !isMatchFound;
      if (
        item.childUnitTreeItems !== null &&
        item.childUnitTreeItems !== undefined &&
        item.childUnitTreeItems.length > 0
      ) {
        item.childUnitTreeItems.forEach(function (e) {
          let isMatchFoundSub: boolean | undefined = false;
          isMatchFoundSub = updateUnitItemBasedOnNameMatch(
            e,
            givenFilterUnitName
          );
        });
      }
    } else if (
      givenFilterUnitName !== null &&
      givenFilterUnitName !== undefined &&
      givenFilterUnitName.trimLeft().trimRight() !== "" &&
      item !== null &&
      item !== undefined
    ) {
      isMatchFound =
        item.name !== null &&
        item.name !== undefined &&
        item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase());
      if (isMatchFound) {
        item.isDisabled = false;
        item.isHidden = false;
      } else if (
        !isMatchFound &&
        (item.childUnitTreeItems == null ||
          item.childUnitTreeItems === undefined ||
          item.childUnitTreeItems.length <= 0)
      ) {
        item.isDisabled = true;
        item.isHidden = true;
      } else if (!isMatchFound) {
        let curAnyChildNameMatched = isAnyChildItemNameMatched(
          item.childUnitTreeItems,
          givenFilterUnitName
        );
        item.isDisabled = curAnyChildNameMatched === true;
        item.isHidden = curAnyChildNameMatched === false;
        isMatchFound = curAnyChildNameMatched === true;
      }
      if (
        item.childUnitTreeItems !== null &&
        item.childUnitTreeItems !== undefined &&
        item.childUnitTreeItems.length > 0
      ) {
        item.childUnitTreeItems.forEach(function (e) {
          let isMatchFoundSub: boolean | undefined = false;
          isMatchFoundSub = updateUnitItemBasedOnNameMatch(
            e,
            givenFilterUnitName
          );
        });
      }
    }
    return isMatchFound;
  };

  const handleUnitLevel = (event:any) => {
    const val = (event.target as HTMLInputElement).value;
    setSelectedunitlevel(val);
    setUnitLevel(val);
  }

  // const handleDragStartOnUnitSubTreeLocal = (subTreeRootUnitID: number) => {
  //   if (subTreeRootUnitID > 0) {
  //     setCurrentUnitSubTreeToSuppress(subTreeRootUnitID);
  //     setUnitsWithGivenExpansionAndNoChildPlaceHolder(units, true, subTreeRootUnitID);
  //   }
  // }

  const handleDragEndOnUnitTreeLocal = (fromUnitID: number, toUnitID: number, isToTopLevel: boolean) => {
    if (fromUnitID > 0 && (isToTopLevel || toUnitID > 0)) {
      handleDragEndOnUnitTreeGiven(fromUnitID, toUnitID, isToTopLevel);
    }
  }

  useEffect(() => {
    if (units !== null && units !== undefined && units.length > 0) {
      var newUnits = units.map(function (e) {
        const retFlag = !(
          e !== null &&
          e !== undefined &&
          e.name !== null &&
          e.name !== undefined &&
          updateUnitItemBasedOnNameMatch(e, filterUnitName.toLowerCase())
        );
        return e;
      });
      setUnits(newUnits);
    }
  }, [filterUnitName]);
  return (
    <>
      <div id={"part1"}
        style={{float: "left", margin: "10px", 
        width: "96%"}}>
        <div>
          <h4 className={styles.sectionTitle}>{allResourcesToTranslations.unitpathwaydetails + " "}<span className={styles.blankContent}>{allResourcesToTranslations.unitmovelabelforcommondnd}</span></h4>          
          
        </div>
        <div className={styles.unittreeContianer}>
          <div className={styles.treeContianer} id="unitsMoveTreeWithDND">          
            <FormControl 
              component="fieldset" className="unittreeContianer"
              style={{ margin: "10px", width: "96%" }}
            >
              <RadioGroup
                name="units2"
                value={selectedUnit}
                onChange={(e) => {
                  updateSelectedUnit(e);
                }}
              >
                <div className={styles.radioUnitTree}>
                  <RadioUnitTreeWithDND
                    parentUnit={null}
                    handleDragEndOnUnitTree={handleDragEndOnUnitTreeLocal}
                    units={units}
                    originalUnits={units}
                    updateSelectedUnit={updateSelectedUnit}
                    updateExpandedUnit={updateExpandedUnit}
                    isAnyUnitSelected={isAnyUnitSelected}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
    </>
  );
};

export default RadioUnitsForMoveWithDND;