import axios from 'axios';
import { addError } from '../../AddError';

const GetOrgCourseModuleProfilePoints = async (token: string, requestinguserId: number, orgDomain: string, orgId: number, 
                                                givenProfileID: number, givenProfilePeriodID: number, includeOnlyOrgGrantedCourses: boolean = true,
                                                includeInactiveCourses: boolean = false, includeInactiveModules: boolean = false, 
                                                includeInactiveProfiles: boolean = false, langCode: string = "en-au") => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/CPDProfiles/GetOrgCourseModuleProfilePoints`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        "RequestingUserID": requestinguserId,
        "OrgID": orgId,
        "GivenProfileID": givenProfileID,
        "GivenProfilePeriodID": givenProfilePeriodID,
        "IncludeOnlyOrgGrantedCourses": includeOnlyOrgGrantedCourses,
        "IncludeInactiveCourses": includeInactiveCourses,
        "IncludeInactiveModules": includeInactiveModules,
        "IncludeInactiveProfiles": includeInactiveProfiles,
    },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.response, "GetOrgCourseModuleProfilePoints.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.request, "GetOrgCourseModuleProfilePoints.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.message, "GetOrgCourseModuleProfilePoints.ts", 1)
    }
    return null;
  }
};

export default GetOrgCourseModuleProfilePoints;