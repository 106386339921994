import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const SSOOktaIcon = () => (
<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_67_134)">
<rect x="3" y="3" width="40" height="40" rx="8" fill="#63C2FF"/>
<path d="M18.7645 26.9733C19.1748 27.3136 19.5852 27.6438 19.9955 27.9841C19.9755 28.0141 19.9455 28.0642 19.9154 28.1142C19.405 28.8448 18.9046 29.5855 18.3942 30.3161C18.3842 30.3361 18.3641 30.3561 18.3541 30.3761C18.2641 30.4862 18.194 30.4962 18.0839 30.4061C17.9938 30.3361 17.9238 30.266 17.8337 30.196C17.8037 30.1659 17.7636 30.1459 17.7136 30.1059C17.5835 30.286 17.4534 30.4662 17.3233 30.6463C16.9029 31.2368 16.4826 31.8373 16.0622 32.4278C16.0322 32.4679 16.0122 32.4979 15.9822 32.5379C15.9121 32.608 15.842 32.618 15.762 32.5679C15.742 32.5579 15.7219 32.5379 15.7019 32.5179C15.3216 32.1976 14.9413 31.8874 14.561 31.5671C14.551 31.5571 14.5309 31.5471 14.5209 31.5271C14.4209 31.427 14.4108 31.3569 14.5209 31.2568C14.641 31.1367 14.7611 31.0166 14.8812 30.8865C15.5218 30.236 16.1623 29.5855 16.8028 28.9449C17.4334 28.3144 18.0539 27.6739 18.6844 27.0333C18.7144 27.0033 18.7445 26.9733 18.7645 26.9633V26.9733Z" fill="white"/>
<path d="M30.4343 18.1259C30.7246 17.9258 30.9948 17.7456 31.265 17.5554C31.7754 17.2052 32.2759 16.8549 32.7863 16.5046C32.8263 16.4745 32.8663 16.4445 32.9164 16.4245C32.9965 16.3845 33.0665 16.4045 33.1166 16.4645C33.1266 16.4745 33.1366 16.4946 33.1466 16.5146C33.4068 16.965 33.667 17.4153 33.9272 17.8657C34.0073 18.0058 33.9773 18.0759 33.8071 18.1559C33.3167 18.3861 32.8363 18.6163 32.3459 18.8465C31.0849 19.447 29.8238 20.0375 28.5728 20.638C28.5127 20.668 28.4427 20.6981 28.3926 20.7181C28.1224 20.2677 27.8522 19.8173 27.5719 19.357C27.602 19.3369 27.642 19.2969 27.682 19.2669C28.4026 18.7565 29.1132 18.246 29.8338 17.7356C29.8638 17.7156 29.8839 17.6956 29.9139 17.6856C30.054 17.5955 30.1141 17.6055 30.2041 17.7556C30.2742 17.8757 30.3442 17.9958 30.4343 18.1359V18.1259Z" fill="white"/>
<path d="M19.5051 14.8331C19.4751 14.7631 19.4551 14.713 19.425 14.653C19.0547 13.8523 18.6944 13.0516 18.3241 12.251C18.3041 12.2209 18.2941 12.1809 18.2741 12.1509C18.224 12.0208 18.2441 11.9507 18.3842 11.9007C18.5443 11.8306 18.7145 11.7806 18.8846 11.7205C19.1848 11.6104 19.4751 11.5003 19.7753 11.3902C20.0055 11.3102 20.0656 11.3402 20.1356 11.5704C20.3458 12.341 20.566 13.1217 20.7762 13.8923C21.0764 14.9832 21.3667 16.0641 21.6669 17.155C21.6769 17.2051 21.6969 17.2651 21.707 17.3052C21.2165 17.4953 20.7261 17.6755 20.2257 17.8656C20.2257 17.8556 20.1857 17.8156 20.1757 17.7856C19.7853 16.9749 19.395 16.1642 19.0147 15.3535C19.0047 15.3335 18.9947 15.3035 18.9847 15.2835C18.9246 15.1334 18.9446 15.0633 19.0948 15.0033C19.1949 14.9632 19.2949 14.9332 19.395 14.8932C19.425 14.8832 19.4651 14.8631 19.5051 14.8431V14.8331Z" fill="white"/>
<path d="M28.3326 15.8842C28.3826 15.8141 28.4326 15.7641 28.4627 15.704C28.9731 14.9834 29.4835 14.2728 29.9939 13.5522C29.9939 13.5522 29.9939 13.5422 30.0039 13.5322C30.1341 13.3521 30.2041 13.342 30.3743 13.4822C30.5844 13.6623 30.8046 13.8425 31.0148 14.0126C31.1749 14.1427 31.325 14.2728 31.4852 14.4029C31.6553 14.543 31.6553 14.6031 31.5052 14.7532C30.8146 15.4538 30.124 16.1544 29.4335 16.845C28.7629 17.5255 28.0924 18.2061 27.4218 18.8867C27.3818 18.9267 27.3517 18.9868 27.3217 19.0268C26.8813 18.6665 26.471 18.3362 26.0607 17.9959C26.0807 17.9559 26.1207 17.9059 26.1507 17.8558C26.6512 17.1352 27.1516 16.4046 27.652 15.684C27.672 15.654 27.692 15.6239 27.722 15.5939C27.8021 15.5038 27.8722 15.4938 27.9723 15.5639C28.0924 15.654 28.2024 15.7541 28.3426 15.8742L28.3326 15.8842Z" fill="white"/>
<path d="M24.3292 28.6848C24.8297 28.4947 25.3101 28.3045 25.8105 28.1144C25.8205 28.1344 25.8505 28.1744 25.8705 28.2145C26.2608 29.0351 26.6512 29.8458 27.0415 30.6665C27.0415 30.6865 27.0615 30.6965 27.0615 30.7165C27.1216 30.8567 27.0915 30.9167 26.9614 30.9668C26.8213 31.0268 26.6812 31.0768 26.5211 31.1369C26.5511 31.207 26.5711 31.267 26.6011 31.317C26.9714 32.1177 27.3317 32.9184 27.702 33.719C27.702 33.7391 27.7221 33.7491 27.7221 33.7691C27.8121 33.9793 27.7921 34.0193 27.5819 34.1094C27.2917 34.2195 27.0015 34.3195 26.7112 34.4296C26.5511 34.4897 26.391 34.5497 26.2308 34.6098C26.0307 34.6798 25.9706 34.6498 25.9106 34.4497C25.7304 33.7991 25.5503 33.1486 25.3701 32.488C25.0298 31.247 24.6895 29.9959 24.3492 28.7549C24.3492 28.7349 24.3392 28.7049 24.3292 28.6848Z" fill="white"/>
<path d="M19.8354 18.1059C19.4251 18.4562 19.0348 18.7964 18.6344 19.1367C18.6244 19.1367 18.5944 19.1067 18.5744 19.0867C17.9238 18.4562 17.2833 17.8156 16.6328 17.1851C16.6227 17.1751 16.6027 17.1551 16.5927 17.1451C16.4526 16.9949 16.4526 16.9349 16.6027 16.8048C16.7028 16.7147 16.8029 16.6346 16.913 16.5446C16.873 16.5045 16.8429 16.4645 16.8129 16.4245C16.1824 15.7839 15.5418 15.1434 14.9113 14.5029C14.8813 14.4728 14.8513 14.4428 14.8212 14.4028C14.7512 14.3127 14.7512 14.2426 14.8413 14.1726C14.9213 14.1025 15.0014 14.0325 15.0915 13.9624C15.3917 13.7122 15.692 13.452 15.9922 13.2018C16.1924 13.0316 16.2424 13.0416 16.3926 13.2518C17.0631 14.1926 17.7237 15.1334 18.3942 16.0742C18.8446 16.7047 19.285 17.3352 19.7353 17.9657C19.7654 18.0158 19.7954 18.0558 19.8254 18.0959L19.8354 18.1059Z" fill="white"/>
<path d="M17.6735 25.2719C17.8937 25.7623 18.1539 26.2027 18.4942 26.633C18.4642 26.6531 18.4242 26.6731 18.3941 26.7031C17.6635 27.2235 16.9229 27.744 16.1923 28.2644C16.1723 28.2744 16.1523 28.2944 16.1323 28.3044C15.9621 28.4145 15.9121 28.4045 15.802 28.2244C15.7319 28.1143 15.6719 27.9942 15.5918 27.8641C15.4817 27.9441 15.3716 28.0142 15.2715 28.0843C14.581 28.5546 13.8904 29.035 13.2098 29.5054C13.0197 29.6355 12.9696 29.6255 12.8495 29.4154C12.6193 29.015 12.3891 28.6147 12.1489 28.2144C12.1289 28.1843 12.1089 28.1443 12.0889 28.1143C12.0288 27.9842 12.0489 27.9241 12.179 27.8541C12.199 27.8441 12.229 27.834 12.249 27.824C14.0205 26.9833 15.802 26.1426 17.5735 25.3019C17.5935 25.2919 17.6235 25.2819 17.6635 25.2619L17.6735 25.2719Z" fill="white"/>
<path d="M18.3642 19.487C18.104 19.9474 17.8538 20.4078 17.5836 20.8782C17.4435 20.8081 17.2833 20.7381 17.1232 20.668C16.4226 20.3478 15.732 20.0275 15.0315 19.7172C15.0315 19.7172 15.0215 19.7172 15.0114 19.7172C14.8013 19.6172 14.7812 19.5671 14.8913 19.3669C14.9514 19.2569 15.0215 19.1468 15.0915 19.0267C15.0315 18.9966 14.9714 18.9566 14.9214 18.9366C14.1207 18.5563 13.32 18.176 12.5194 17.7956C12.4693 17.7756 12.4293 17.7556 12.3792 17.7256C12.2992 17.6755 12.2792 17.6055 12.3192 17.5154C12.3392 17.4754 12.3592 17.4353 12.3792 17.3953C12.5994 17.005 12.8296 16.6146 13.0498 16.2243C13.1999 15.9741 13.24 15.9641 13.4702 16.1242C14.6812 16.9649 15.9022 17.8056 17.1132 18.6363C17.4935 18.8966 17.8638 19.1568 18.2441 19.417C18.2842 19.447 18.3242 19.477 18.3442 19.4971L18.3642 19.487Z" fill="white"/>
<path d="M14.2008 21.9492C13.9706 21.9291 13.7504 21.8991 13.5302 21.8791C12.7996 21.809 12.069 21.749 11.3284 21.6789C11.3084 21.6789 11.2884 21.6789 11.2684 21.6789C11.0882 21.6489 11.0482 21.5989 11.0782 21.4187C11.1082 21.2085 11.1483 20.9984 11.1883 20.7782C11.2383 20.508 11.2784 20.2377 11.3284 19.9775C11.3684 19.7573 11.4285 19.7173 11.6387 19.7774C13.1399 20.1677 14.6412 20.558 16.1424 20.9483C16.5728 21.0584 17.0031 21.1685 17.4135 21.2786C17.3234 21.809 17.2433 22.3195 17.1633 22.8599C16.9731 22.8499 16.7629 22.8299 16.5628 22.8199C15.8322 22.7598 15.1016 22.6998 14.3609 22.6397C14.3609 22.6397 14.3509 22.6397 14.3409 22.6397C14.1207 22.6197 14.0807 22.5697 14.1107 22.3495C14.1307 22.2294 14.1508 22.0993 14.1808 21.9592L14.2008 21.9492Z" fill="white"/>
<path d="M31.6553 20.9583C32.0857 20.8482 32.486 20.7381 32.8964 20.638C33.4168 20.4979 33.9372 20.3678 34.4577 20.2377C34.6578 20.1876 34.7279 20.2177 34.7679 20.4178C34.858 20.9082 34.9481 21.3886 35.0281 21.879C35.0582 22.0592 35.0081 22.1192 34.818 22.1393C34.3776 22.1793 33.9372 22.2193 33.4969 22.2594C32.7462 22.3294 31.9956 22.3995 31.245 22.4695C30.6245 22.5296 30.004 22.5796 29.3934 22.6397C29.2133 22.6597 29.0331 22.6697 28.873 22.6897C28.7729 22.1593 28.6728 21.6489 28.5728 21.1284C28.873 21.0484 29.1833 20.9583 29.5035 20.8682C30.094 20.7081 30.6745 20.5479 31.265 20.3878C31.305 20.3778 31.3351 20.3678 31.3751 20.3578C31.5052 20.3378 31.5653 20.3678 31.5953 20.4979C31.6153 20.568 31.6253 20.648 31.6353 20.7181C31.6453 20.7881 31.6653 20.8682 31.6854 20.9583H31.6553Z" fill="white"/>
<path d="M27.6518 26.493C27.9721 26.0627 28.2223 25.6123 28.4225 25.1219C28.4725 25.1419 28.5226 25.1519 28.5626 25.1719C29.3833 25.5422 30.194 25.9226 31.0146 26.2929C31.0447 26.3029 31.0747 26.3229 31.1047 26.3329C31.2248 26.403 31.2448 26.463 31.1848 26.5831C31.1147 26.7132 31.0347 26.8433 30.9546 26.9934C31.0146 27.0235 31.0747 27.0635 31.1247 27.0835C31.9054 27.4538 32.696 27.8241 33.4767 28.1944C33.5067 28.2045 33.5367 28.2245 33.5668 28.2345C33.7669 28.3346 33.7869 28.3846 33.6769 28.5848C33.4367 28.9951 33.2065 29.4055 32.9663 29.8158C32.8462 30.026 32.7861 30.036 32.596 29.8959C31.1748 28.9151 29.7536 27.9342 28.3224 26.9534C28.1022 26.8033 27.892 26.6532 27.6618 26.493H27.6518Z" fill="white"/>
<path d="M24.1589 17.2652C24.209 17.055 24.269 16.8348 24.3291 16.6046C24.5092 15.924 24.6793 15.2435 24.8595 14.5629C24.8595 14.5229 24.8795 14.4928 24.8895 14.4528C24.9396 14.3227 24.9996 14.2827 25.1397 14.3327C25.2799 14.3828 25.41 14.4328 25.5701 14.4828C25.5901 14.4128 25.6101 14.3527 25.6301 14.2827C25.8603 13.432 26.1005 12.5913 26.3307 11.7405C26.3407 11.7005 26.3507 11.6705 26.3608 11.6305C26.4108 11.4803 26.4708 11.4503 26.621 11.5104C26.8211 11.5804 27.0213 11.6605 27.2315 11.7305C27.4917 11.8206 27.7419 11.9207 28.0021 12.0108C28.2423 12.1008 28.2623 12.1409 28.1522 12.3711C27.5317 13.7122 26.9112 15.0633 26.2907 16.4044C26.0905 16.8348 25.8904 17.2652 25.6902 17.6955C25.6802 17.7255 25.6602 17.7556 25.6502 17.7756C25.1497 17.6054 24.6593 17.4353 24.1489 17.2551L24.1589 17.2652Z" fill="white"/>
<path d="M28.5928 24.6913C28.6829 24.1609 28.7629 23.6604 28.843 23.13C28.9731 23.13 29.1132 23.14 29.2633 23.15C30.004 23.2101 30.7446 23.2701 31.4852 23.3302C31.5553 23.3302 31.6253 23.3402 31.6954 23.3502C31.8855 23.3802 31.9256 23.4202 31.8955 23.6104C31.8755 23.7405 31.8555 23.8706 31.8255 24.0207C31.9956 24.0408 32.1458 24.0608 32.2959 24.0708C33.1066 24.1508 33.9072 24.2209 34.7179 24.301C34.9281 24.321 34.9681 24.381 34.9281 24.5912C34.848 25.0616 34.7579 25.542 34.6779 26.0124C34.6378 26.2226 34.5878 26.2526 34.3776 26.2026C33.1966 25.8923 32.0257 25.592 30.8447 25.2818C30.1341 25.0916 29.4235 24.9115 28.7129 24.7213C28.6628 24.7113 28.6128 24.6913 28.5828 24.6813L28.5928 24.6913Z" fill="white"/>
<path d="M14.3809 25.0318C13.9005 25.1519 13.4501 25.272 12.9897 25.3921C12.5193 25.5122 12.049 25.6423 11.5686 25.7624C11.3584 25.8124 11.3083 25.7824 11.2683 25.5722C11.1782 25.0818 11.0982 24.6014 11.0081 24.111C10.9781 23.9308 11.0281 23.8708 11.2183 23.8508C12.2191 23.7607 13.2299 23.6706 14.2308 23.5805C15.1215 23.5005 16.0123 23.4104 16.903 23.3303C17.0031 23.3303 17.1032 23.3203 17.1832 23.3103C17.2833 23.8408 17.3834 24.3412 17.4835 24.8716C17.3234 24.9217 17.1432 24.9717 16.9631 25.0217C16.2425 25.2219 15.5219 25.4121 14.8012 25.6122C14.7612 25.6222 14.7112 25.6423 14.6711 25.6423C14.551 25.6523 14.491 25.6222 14.471 25.4921C14.4409 25.342 14.4109 25.1919 14.3809 25.0217V25.0318Z" fill="white"/>
<path d="M26.1806 27.8541C26.631 27.5639 27.0113 27.2136 27.3716 26.8233C27.5017 26.9534 27.6318 27.0735 27.762 27.1936C28.2924 27.714 28.8328 28.2444 29.3633 28.7649C29.3933 28.7949 29.4233 28.8249 29.4534 28.855C29.5735 28.9851 29.5735 29.0451 29.4333 29.1652C29.3333 29.2553 29.2232 29.3454 29.1031 29.4354C29.1531 29.4855 29.1831 29.5355 29.2232 29.5756C29.8437 30.2061 30.4742 30.8366 31.0947 31.4671C31.1248 31.4972 31.1448 31.5172 31.1748 31.5472C31.2649 31.6573 31.2649 31.7374 31.1548 31.8274C30.7544 32.1677 30.3641 32.498 29.9638 32.8283C29.8437 32.9283 29.7736 32.9183 29.6635 32.7682C29.2932 32.2478 28.9229 31.7273 28.5526 31.2069C27.802 30.146 27.0413 29.0751 26.2907 28.0143C26.2507 27.9642 26.2207 27.9142 26.1806 27.8441V27.8541Z" fill="white"/>
<path d="M20.3958 28.1642C20.8762 28.4044 21.3666 28.5746 21.887 28.6746C21.807 29.0049 21.7269 29.3152 21.6468 29.6254C21.4967 30.2059 21.3466 30.7764 21.1965 31.3569C21.1865 31.4069 21.1765 31.457 21.1564 31.507C21.1064 31.6571 21.0463 31.6872 20.8962 31.6371C20.7561 31.5971 20.626 31.537 20.4659 31.487C20.4358 31.6071 20.3958 31.7172 20.3658 31.8173C20.1356 32.638 19.9154 33.4486 19.6852 34.2693C19.6252 34.4895 19.5751 34.5195 19.3549 34.4395C18.8946 34.2693 18.4342 34.1092 17.9838 33.939C17.8136 33.879 17.7836 33.8189 17.8637 33.6488C18.6844 31.8573 19.5051 30.0558 20.3358 28.2643C20.3458 28.2343 20.3658 28.2043 20.3958 28.1642Z" fill="white"/>
<path d="M23.5385 31.8774C23.5385 31.9475 23.5385 32.0175 23.5385 32.0976C23.5986 32.8382 23.6586 33.5788 23.7187 34.3094C23.7287 34.4696 23.7487 34.6397 23.7587 34.7998C23.7587 34.96 23.7287 35 23.5585 35C23.0481 35 22.5477 35 22.0373 35C21.8571 35 21.8171 34.95 21.8271 34.7698C21.8771 34.1093 21.9372 33.4487 21.9872 32.7781C22.0373 32.2177 22.0773 31.6672 22.1274 31.1168C22.1874 30.3361 22.2575 29.5655 22.3175 28.7648C22.4977 28.7748 22.6778 28.7948 22.848 28.7948C23.0181 28.7948 23.1882 28.7948 23.3584 28.7948C23.5385 28.7948 23.7087 28.7648 23.8988 28.7448C23.9188 28.975 23.9489 29.2052 23.9689 29.4253C24.0389 30.1259 24.099 30.8265 24.1691 31.5271C24.1691 31.5471 24.1691 31.5671 24.1691 31.5872C24.1791 31.8173 24.149 31.8574 23.9188 31.8574C23.8088 31.8574 23.6887 31.8574 23.5786 31.8574C23.5686 31.8574 23.5585 31.8674 23.5385 31.8774Z" fill="white"/>
<path d="M23.7287 17.2452C23.1882 17.1852 22.6778 17.1952 22.1474 17.2652C22.1273 17.1051 22.1073 16.965 22.0973 16.8149C22.0172 16.0142 21.9472 15.2135 21.8671 14.4128C21.8471 14.2027 21.8971 14.1526 22.1073 14.1526C22.2374 14.1526 22.3575 14.1526 22.5077 14.1526C22.5077 14.0726 22.5077 14.0125 22.5077 13.9525C22.4676 13.392 22.4176 12.8315 22.3775 12.2711C22.3475 11.9208 22.3175 11.5805 22.2875 11.2302C22.2775 11.05 22.3275 11 22.4976 11C22.9981 11 23.4985 11 23.9989 11C24.1891 11 24.2291 11.04 24.2091 11.2202C24.1791 11.6205 24.149 12.0309 24.119 12.4312C24.069 12.9917 24.0289 13.5521 23.9789 14.1126C23.9188 14.8232 23.8588 15.5338 23.7987 16.2544C23.7787 16.5046 23.7587 16.7448 23.7387 16.995C23.7387 17.0751 23.7187 17.1451 23.7087 17.2452H23.7287Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_67_134" x="0" y="0" width="50" height="50" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="2"/>
<feGaussianBlur stdDeviation="2.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_67_134"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_67_134" result="shape"/>
</filter>
</defs>
</svg>


);

export default SSOOktaIcon