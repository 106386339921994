import React, { useEffect, useState } from "react";
import CloseIcon from '@material-ui/icons/Close';

//Styles
import styles from "../styles/organisationmanagement.module.css";
import { FormControl, InputLabel, Select, TextField } from "@material-ui/core";

interface CustomisedDatePickerResultProps {
    inputDate : string;
    lableText: string;
    isDisabled: boolean;
    yearlyIncrementddlclassName: string;
    yearlyIncrementdatepickerclassName: string;
    handleDateSelection: any;
    yearlyincrementValue: number;
    handleYearSelection: any;
}

const CustomisedDatePicker = (props:CustomisedDatePickerResultProps) => {
    const { inputDate, lableText, isDisabled, yearlyIncrementddlclassName,yearlyIncrementdatepickerclassName, handleDateSelection, yearlyincrementValue, handleYearSelection} = props;
    const [givenDate, setGivenDate] = useState(inputDate);
    const [givenYearlyincrementValue, setGivenYearlyincrementValue] = useState<number>(yearlyincrementValue);
    const [customisedYears, setCustomisedYears] = useState<number>(0);

    const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
    {
      if (!isNaN(givenDate.getTime())) {
        const year = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(givenDate);
        const month = new Intl.DateTimeFormat('en-AU', { month: 'numeric' }).format(givenDate);
        const day = new Intl.DateTimeFormat('en-AU', { day: 'numeric' }).format(givenDate);
    
        return (year.toString() + "-" +
          (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" +
          (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
      }
      else {
        return "";
      }
    };

    // const setCustomisedDateFromYears = (IncrementYear: string, pickedDate: string) => // To format as yyyy-MM-dd
    // {
    //   var passedDate = pickedDate === "" ? new Date() : new Date(pickedDate);
    //   if (parseInt(IncrementYear) === 0) {
    //     return false;
    //   }
    //   else {
    //     if (parseInt(IncrementYear) > 0)
    //     {
    //        let maxyear: number = parseInt(new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(new Date())) + 5;
    //        let IncrementedYear: number = new Date(passedDate).getFullYear() + parseInt(IncrementYear);

    //        passedDate = new Date((passedDate).setFullYear(IncrementedYear > maxyear ? maxyear : IncrementedYear));
    //     }
    //     const year = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(passedDate);
    //     const month = new Intl.DateTimeFormat('en-AU', { month: 'numeric' }).format(passedDate);
    //     const day = new Intl.DateTimeFormat('en-AU', { day: 'numeric' }).format(passedDate);

    //     let newDate = (year.toString() + "-" +
    //       (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" +
    //       (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));

    //     setGivenDate(newDate);
    //     setCustomisedYears(parseInt(IncrementYear));
    //     handleDateSelection(newDate);
    //   }
    // };

    const setSelectedGivenDate = (pickedDate: string) => // To format as yyyy-MM-dd
    {
      setGivenDate(pickedDate);
      handleDateSelection(pickedDate);
    }

    const setSelectedYearlyIncrement = (pickedYear: number) => // To format as yyyy-MM-dd
    {
      setGivenYearlyincrementValue(pickedYear);
      handleYearSelection(pickedYear);
    }

    useEffect(() => {
      setGivenDate(inputDate);
    },[inputDate]);
    
  useEffect(() => {
    setSelectedYearlyIncrement(givenYearlyincrementValue);
  },[givenYearlyincrementValue]);

    return  (
      <div className={styles.inputContainer} style={{ paddingTop: '10px', display:"flex", width:"100%" }}>
                      <div className={`${styles.inputContainer} ${yearlyIncrementdatepickerclassName}`} style={{  width:"100%"}}>
                        <TextField className={styles.input} label={lableText} variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true }}
                          inputProps={{ min: formatDate(new Date())}}
                          type="date" defaultValue={givenDate} disabled={isDisabled} onChange={(e) => setSelectedGivenDate(formatDate(new Date(e.target.value)))} />
                       </div>
                     <div style={{ width:"20%", paddingLeft: "10px"}}>
                      <FormControl variant="outlined" className={styles.formControl}>
                        <InputLabel>Yearly Increment</InputLabel>
                        <Select 
                          style={{width: "100%"}}
                          value={givenYearlyincrementValue}
                          native
                          disabled={isDisabled} 
                          onChange={(e: any) => setSelectedYearlyIncrement(parseInt(e.target.value))}
                          variant="outlined"
                          className={yearlyIncrementddlclassName}
                          label="Yearly Increment">
                          <option value={0}>Please select</option>
                          <option value={1}>1 Year</option>
                          <option value={2}>2 Year</option>
                          <option value={3}>3 Year</option>
                          <option value={4}>4 Year</option>
                          <option value={5}>5 Year</option>
                        </Select>
                      </FormControl>
                      </div>
                    </div>
    )
}

export default CustomisedDatePicker;