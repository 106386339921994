import React, { useState, useEffect } from 'react'
import Checkbox from '@material-ui/core/Checkbox';
import { UnitTreeItem } from '../../globalTypes'
import styles from '../../styles/treeComponent.module.css'


interface TreeProps {
    units: Array<UnitTreeItem> | null
    updateSelectedUnit: Function
    updateExpandedUnit: Function
    selectAllChildUnits: Function 
    deselectAllChildUnits: Function 
    isAnyUnitSelected: Function 
}
 
interface TreeNodeProps {
    uniquePrefix: string
    unit: UnitTreeItem
    updateSelectedUnit: Function
    updateExpandedUnit: Function
    selectAllChildUnits: Function 
    deselectAllChildUnits: Function 
    isAnyUnitSelected: Function 
}

const UnitTreeNode = ({ uniquePrefix, unit, updateSelectedUnit, updateExpandedUnit, selectAllChildUnits, deselectAllChildUnits, isAnyUnitSelected }: TreeNodeProps) => {

    const hasChild = unit.childUnitTreeItems !== null;

    const handleClick = (event: any) => {
        event.stopPropagation();
        updateExpandedUnit(unit.unitID)
    }

    const handleSelection = (event: any) => {
        event.stopPropagation();
        updateSelectedUnit(unit.unitID)
    }

    const handleSelectAllChildren = (event: any) => {
        if (unit.childUnitTreeItems === null) updateSelectedUnit(unit.unitID)
        event.stopPropagation();
        selectAllChildUnits(unit.unitID)
    }

    const handleSelectOrUnselectAllChildren = (event: any) => {
        let isTopLevelUnitSelected = (unit !== null && unit !== undefined) ? unit.isSelected : false;
        if (unit.childUnitTreeItems === null){
            updateSelectedUnit(unit.unitID)
            if (unit !== null && unit !== undefined) {
                unit.isSelected = !isTopLevelUnitSelected;
            }
        } 
        event.stopPropagation();
        if (isAnyUnitSelected(unit.childUnitTreeItems) || isTopLevelUnitSelected) {
            deselectAllChildUnits(unit.unitID);
        }
        else 
        {
            selectAllChildUnits(unit.unitID);
        }
    }

    return (
        <li className={styles.treeLi} hidden={unit.isHidden === true}>
            <div className={styles.treeRow} id={uniquePrefix+unit.name.replaceAll(" ", "_s_")}>
            { hasChild ? <span className={styles.treeArrow} onClick={(e) => handleClick(e) }> { unit.isExpanded ? "⯆" : "⯈" } </span> : null }
                <Checkbox
                    color="primary"
                    checked={unit.isSelected}
                    disabled={unit.isDisabled}
                    style={!hasChild ? { padding: "3px", marginLeft:'11px' } : {padding:"3px"}}
                    onClick={(e) => handleSelection(e)}
                    />
                <div>
                    
                    <span onClick={handleSelectOrUnselectAllChildren}>
                        { unit.name }
                    </span>
                </div>
            </div>
            { hasChild && unit.isExpanded ? <UnitTree units={unit.childUnitTreeItems} updateSelectedUnit={updateSelectedUnit} updateExpandedUnit={updateExpandedUnit} selectAllChildUnits={selectAllChildUnits} deselectAllChildUnits={deselectAllChildUnits} isAnyUnitSelected={isAnyUnitSelected}/> : null }
        </li>
    )
}

const UnitTree = ({ units, updateSelectedUnit, updateExpandedUnit, selectAllChildUnits, deselectAllChildUnits, isAnyUnitSelected }: TreeProps) => {
    return (
        <ul className={styles.treeUl}>
            { units && units.map((unit) => (
                <UnitTreeNode unit={unit} key={unit.unitID} updateSelectedUnit={updateSelectedUnit} updateExpandedUnit={updateExpandedUnit} selectAllChildUnits={selectAllChildUnits} deselectAllChildUnits={deselectAllChildUnits} isAnyUnitSelected={isAnyUnitSelected} uniquePrefix="chkUnitNewSalt_"/>
            ))}
        </ul>
    )
}

export default UnitTree
