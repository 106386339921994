import axios from 'axios';
import { addError } from './AddError';

export const getFullEmailReport = async (
    token: string, 
    userId: number,
    orgDomain: string,
    orgId: number,
    unitIds: string,
    courseIds: string,
    classificationId: number,
    givenDateFrom: string,
    givenDateTo: string,
    courseStatus: number,
    recipientType: number,
    includeInactive: boolean,
    filterFirstName: string,
    filterLastName: string,
    filterUserName: string,
    filterEmail: string,
    selectedEmailReportType: number,
    cpdProfileID: number,

    selectedPolicyIDs: string,
    selectedPolicyStatus: number,
    selectedCPDProfileStatus: number,
    newStarterOnlyFlag: boolean,
    externalCertificateIDs: string,
    externalCertificateStatus: number,
    ) => {

  try {
    //debugger
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/userreport/GetFullEmailReport`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },

    //   data: {
    //     "ReportRequestingUserID" :58731,
    //     "RecipientType" :1,  //Administrators    = 1,  Users= 2
    //     "OrganisationID" : 45,
    //     "UnitIDs" : "3012, 3020, 3024, 3025, 3041, 3043, 3115, 3116, 3117",
    //     "CourseIDs" : "27, 41, 48, 49, 50, 51, 52, 53, 54, 58, 59, 61, 62, 63, 70, 71, 72, 74, 79, 80, 81, 82, 83, 84, 85, 86, 108, 110, 112, 113, 114, 115, 116, 117, 160, 165, 167, 175, 176, 177, 178, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190",   
    //     "classificationID" : 0,
    //     "courseModuleStatus" :1,
    //     "quizDateFromOrg" :"2000-01-01",
    //     "quizDateToOrg" :"2022-10-20",
    //     "includeInactive":true
    // }

      data: {
        ReportRequestingUserID : userId,
        OrganisationID : orgId,

        userFirstName : filterFirstName, //Filter params
        userLastName : filterLastName, //Filter params
        userName :  filterUserName, //Filter params
        userEmail : filterEmail,//Filter params        
        RecipientType: recipientType,
        UnitIDs : unitIds,
        classificationID : classificationId,

        CourseIDs : courseIds,
        courseModuleStatus: courseStatus,
        quizDateFromOrg : givenDateFrom,
        quizDateToOrg : givenDateTo,
        includeInactive : includeInactive,

        EmailReportType: selectedEmailReportType,

        PolicyIDs: selectedPolicyIDs,
        PolicyAcceptanceType: selectedPolicyStatus,
        PolicyDateFromOrg : givenDateFrom,
        PolicyDateToOrg : givenDateTo,
        
        CPDProfileID: cpdProfileID,
        CPDProfileStatus: selectedCPDProfileStatus,
        
        IsNewStartersOnly: newStarterOnlyFlag,

        ExternalCertificateIDs: externalCertificateIDs,
        ExternalCertificateStatus: externalCertificateStatus,
        ExternalCertificateDateFromOrg : givenDateFrom,
        ExternalCertificateDateToOrg : givenDateTo,
      }
    });
    const data = await response.data;
  
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      };    
        addError(token, userId, orgDomain, "Rest Exception", error.response, "GetFullEmailReport.tsx", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      };    
        addError(token, userId, orgDomain, "Rest Exception", error.request, "GetFullEmailReport.tsx", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      };    
        addError(token, userId, orgDomain, "Rest Exception", error.message, "GetFullEmailReport.tsx", 1)
    }
    return null;
  }
};

export default getFullEmailReport;
