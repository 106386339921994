import React from "react";
import styles from "../../styles/pdfplayer.module.css";

interface Props {
  url: string;
}

export const PDFPlayer = ({url}: Props) => {
    return (
      <>
        <div className={styles.fillScreen}>
          <iframe
            src={url}
            title="viewer"
            style={{width:"100%", height:"100%"}}
            id="viewer"
          ></iframe>
        </div>
      </>
    );
};

export default PDFPlayer;
