import React, { useContext, useState } from 'react'
import Button from "@material-ui/core/Button"
import CustomTooltip from '../../../Components/CustomTooltip'
import AssessmentIcon from '@material-ui/icons/Assessment';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import RefreshIcon from '@material-ui/icons/Refresh';
import ResetIcon from "../../../CustomIcons/ResetIcon";
import styles from "../../../styles/organisationmanagement.module.css";
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import ViewAccountsIcon from '../../../CustomIcons/Administration/ViewAccountsIcon';
import ResetAccountsIcon from '../../../CustomIcons/Administration/ResetAccountsIcon';
import AssignAccountsIcon from '../../../CustomIcons/Administration/AssignAccountsIcon';
import AssignUnitsIcon from '../../../CustomIcons/Administration/AssignUnitsIcon';
import AssignPointsIcon from '../../../CustomIcons/Administration/AssignPointsIcon';

import PropTypes from "prop-types";
import CircularProgress from '@material-ui/core/CircularProgress';

// interface Props {
//   isCalledFrom: string;
//   handleAssignUnitsClick: () => any;
//   handleAssignAccountsClick: () => any;
//   handleViewAccountsClick: () => any;
//   handleResetAccountsClick: () => any;
//   handleAssignPointsClick: () => any;
// }

//const AssignButtonPanel = ({ isCalledFrom, handleAssignUnitsClick, handleAssignAccountsClick, handleViewAccountsClick, handleResetAccountsClick, handleAssignPointsClick }: Props) => {
const AssignButtonPanel = (properties: any) => {

    const {
      isCalledFrom, 
      handleAssignUnitsClick, 
      handleAssignAccountsClick, 
      handleViewAccountsClick,
      handleResetAccountsClick,
      handleAssignPointsClick,
      isResetInProgress
    } 
    = properties;

    const {
        translations: { allResourcesToTranslations },
    } = useContext(TranslationsContext);

    const [currentPage, setcurrentPage] = useState<string>("");

    const handleAssignUnits = () => {
      setcurrentPage('AssignUnits');
      handleAssignUnitsClick();
    }

    const handleAssignPoints = () => {
      setcurrentPage('AssignPoints');
      handleAssignPointsClick();
    }

    const handleAssignAccounts = () => {
      setcurrentPage('AssignAccounts');
      handleAssignAccountsClick();
    }

    const handleViewAccounts = () => {
      setcurrentPage('ViewAccounts');
      handleViewAccountsClick();
    }

    const handleResetAccounts = () => {
      setcurrentPage('ResetAccounts');
      handleResetAccountsClick();
    }

    return (
        <div className={styles.lowerMiddleButtonPanel} >  
          <span id="assignButtonPanel">
          {
            (!!isCalledFrom && isCalledFrom.toLowerCase() == "profile") &&
            <span id="btnNewSaltAssignPoints2">
              <CustomTooltip displayText={[allResourcesToTranslations.assignpoints]}>
                <span className="AssignPoints">
                  <Button
                    onClick={handleAssignPoints}
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small" 
                    id="btnNewSaltAssignPoints"
                    disabled={currentPage == 'AssignPoints'}
                  >
                    <AssignPointsIcon />
                    <span style={{paddingLeft: '7px'}}>{allResourcesToTranslations.assignpoints}</span>
                  </Button>
                </span>
              </CustomTooltip>  
            </span>
          }
          <span id="btnNewSaltAssignUnits2">
            <CustomTooltip displayText={[allResourcesToTranslations.assignunits]}>
              <span className="AssignUnits">
                <Button
                  onClick={handleAssignUnits}
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small" 
                  id="btnNewSaltAssignUnits"
                  disabled={currentPage == 'AssignUnits'}
                >
                  <AssignUnitsIcon />
                  <span style={{paddingLeft: '7px'}}>{allResourcesToTranslations.assignunits}</span>
                </Button>
              </span>
            </CustomTooltip>   
          </span>
          <span id="btnNewSaltAssignAccounts2">     
            <CustomTooltip displayText={[allResourcesToTranslations.assignaccounts]}>
              <span className="AssignAccounts">
                <Button
                  onClick={handleAssignAccounts}
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small" 
                  id="btnNewSaltAssignAccounts"
                  disabled={currentPage == 'AssignAccounts'}
                >
                  <AssignAccountsIcon />
                  <span style={{paddingLeft: '7px'}}>{allResourcesToTranslations.assignaccounts}</span>
                </Button>
              </span>
            </CustomTooltip>
          </span>  
          <span id="btnNewSaltViewAccounts2">  
            <CustomTooltip displayText={[allResourcesToTranslations.viewaccounts]}>
              <span className="ViewAccounts">
                <Button
                  onClick={handleViewAccounts}
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  id="btnNewSaltViewAccounts"
                  disabled={currentPage == 'ViewAccounts'}
                >
                  <ViewAccountsIcon />
                  <span style={{paddingLeft: '7px'}}>{allResourcesToTranslations.viewaccounts}</span>
                </Button>
              </span>
            </CustomTooltip>
          </span>  
          {
            (!!isCalledFrom && isCalledFrom.toLowerCase() == "policy") &&
            <span id="btnNewSaltResetAccounts2">
              <CustomTooltip displayText={[allResourcesToTranslations.resetaccounts]}>
                <span className="ResetAccounts" id="btnNewSaltResetAccounts2">
                  <Button
                    onClick={handleResetAccounts}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="small" 
                    id="btnNewSaltResetAccounts"
                    disabled={isResetInProgress}
                  >
                    <ResetAccountsIcon />
                    <span style={{paddingLeft: '7px'}}>{allResourcesToTranslations.resetaccceptances}</span>
                    <div className={styles.loaderContainer}>
                    <CircularProgress
                      style={{ width: isResetInProgress ? "20px" : "0px", padding: "0px 5px" }}
                      color="primary"
                      size="10"
                    />
                </div>
                  </Button>
                </span>
              </CustomTooltip>
            </span>   
          }
          </span>
        </div>
    )
}

export default AssignButtonPanel

AssignButtonPanel.propTypes = {
  isCalledFrom: PropTypes.string.isRequired,
  handleAssignUnitsClick: PropTypes.func, 
  handleAssignAccountsClick: PropTypes.func, 
  handleViewAccountsClick: PropTypes.func, 
  handleResetAccountsClick: PropTypes.func, 
  handleAssignPointsClick: PropTypes.func,
  isResetInProgress: PropTypes.bool
}

AssignButtonPanel.defaultProps = {
  isCalledFrom: "policy",
  handleAssignUnitsClick: () => {},
  handleAssignAccountsClick: () => {},
  handleViewAccountsClick: () => {},
  handleResetAccountsClick: () => {},
  handleAssignPointsClick: () => {},
  isResetInProgress: false
}
