import React, { useState, createContext } from "react";

type Theme = {
  color: string;
  logoUri: string;
  backgroundUri: string;
};

interface IThemeContext {
  theme: Theme;
  setTheme: (branding: Theme) => void;
}

const initialState: Theme = {
  color: "#85cfff", //"#6A2876",
  logoUri:"/images/Salt-logo-charcoal.png",
  backgroundUri: ""
};

export const CorperateThemeContext = createContext<IThemeContext>({ //Setting the inital state  for context
    theme: initialState,
    setTheme: () => {}
});

export const CorperateThemeProvider = (props: any): JSX.Element => {
  const [theme, setTheme] = useState(initialState); //Setting the inital state  for context's state

  return (
    <CorperateThemeContext.Provider value={{ theme, setTheme }}>
      {props.children}
    </CorperateThemeContext.Provider>
  );
};
