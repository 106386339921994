import React, { useContext } from 'react';
import ErrorPageImage from './ErrorPageImage'
import style from '../../styles/notFound.module.css'
import { TranslationsContext } from '../../contexts/TranslationsContext';
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'

//This is for Authetication and Authorization errors. Naming it 'GenericError' so people to not give people clues on the error
export const GenericError = ({children}: any) => {
    const { translations: { allResourcesToTranslations } } = useContext(TranslationsContext)
    //window.location.href = window.location.origin // Redirect to homepage. TODO:// Fix AUTH error properly 
    return (
        <div className={style.parentContainer}>
            <div>
                <ErrorPageImage/>
                {/* <div className={style.errorText}>
                    <span>
                        {allResourcesToTranslations.useriseithernotauthenticated}
                    </span>
                </div> */}
                <br/>
                <br/>
                <div className={style.errorText}>
                    <span>
                        {children}
                    </span>
                </div>
                <br/>
                {/* <div className={style.errorText}>
                    <span>
                        { allResourcesToTranslations.pleaseloginagain }
                    </span>
                </div> */}
                <Button variant="contained" style={{backgroundColor:"#58ABE0", color:"white"}} onClick={() => {window.location.href = window.location.origin}}>
                    {allResourcesToTranslations.takemehome}
                </Button>
            </div>
        </div>
    )
}

export default GenericError;