import React from "react";

const ConfigItemIconMenu = () => {

    return (    
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.7 6.3C14.5168 6.48693 14.4141 6.73825 14.4141 7C14.4141 7.26176 14.5168 7.51307 14.7 7.7L16.3 9.3C16.4869 9.48323 16.7382 9.58586 17 9.58586C17.2617 9.58586 17.513 9.48323 17.7 9.3L21.47 5.53C21.9728 6.64119 22.1251 7.87924 21.9064 9.07915C21.6878 10.2791 21.1087 11.3838 20.2463 12.2463C19.3838 13.1087 18.279 13.6878 17.0791 13.9065C15.8792 14.1251 14.6412 13.9728 13.53 13.47L6.61998 20.38C6.22215 20.7778 5.68259 21.0013 5.11998 21.0013C4.55737 21.0013 4.0178 20.7778 3.61998 20.38C3.22215 19.9822 2.99866 19.4426 2.99866 18.88C2.99866 18.3174 3.22215 17.7778 3.61998 17.38L10.53 10.47C10.0271 9.35881 9.87489 8.12077 10.0935 6.92086C10.3121 5.72095 10.8913 4.61616 11.7537 3.75372C12.6161 2.89129 13.7209 2.31217 14.9208 2.09354C16.1207 1.87492 17.3588 2.02717 18.47 2.53L14.71 6.29L14.7 6.3Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
)
}

export default ConfigItemIconMenu;