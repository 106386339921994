//React + Typescript
import React, { useContext, useEffect, useState } from 'react';
import { CourseTreeItem } from '../../globalTypes';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
//API Functions
import { getCourseListByOrganisation } from '../../API/GetCourseListByOrganisation';
//Components
import CourseFilterButtons from '../CurrentAdminReport/CourseFilterButtons'
import CourseListTree from '../CurrentAdminReport/CourseListTree'
//Icons
import AlertGreenIcon from "../../CustomIcons/AlertGreenIcon";
//MUI Components
import TextField from '@material-ui/core/TextField';
import SearchIcon from "@material-ui/icons/Search";
import { Button, IconButton } from '@material-ui/core';
//import { Warning } from '@material-ui/icons';
//Styles
import styles from '../../styles/currentAdminReport.module.css'
import { TranslationsContext } from '../../contexts/TranslationsContext';

export const Courses = (props: any) => {
    const {isResetClicked, givenCourses, hasSmallUnits, CourseTitle = "Course", setUpdatedPastCourses, filterPastCourseName, setFilterPastCourseName } = props;
    const [courseList, setCourseList] = useState<Array<CourseTreeItem>>(givenCourses);    
    const [filterCourseName, setFilterCourseName] = useState(!!filterPastCourseName ? filterPastCourseName : "");    
   
    const [courseNotes, setCourseNotes]  = useState("Leave blank for all Courses");
    const [courseSearch, setcourseSearch]  = useState("Course Search");  
    const [interactionCount, setInteractionCount] = useState<number>(0); //Required to force update the tree
    
    const {
        translations: { allResourcesToTranslations },
      } = useContext(TranslationsContext);
      
    const updateLabel = (title:string) =>{
        if (!!title)
        {        
            if(title.toString() == "Past Course")
            {   
                setcourseSearch("Past Course Search");
                // setCourseNotes("Please select for " + title + " Search");
                // setCourseNotes("You must select a " + title + " below to get historic data");
                setCourseNotes("To report on an inactive course, please make your selection below");
            }            
        }        
    }
    const updateSelectedCourse = (id: number) => {
        let updatedCourses = courseList 
        if (!!updatedCourses)
        {
            for (let i = 0; i < updatedCourses.length; i++) {
                if (updatedCourses[i].courseID === id) {
                    updatedCourses[i].isSelected = !updatedCourses[i].isSelected;
                }
            }
        }
        setInteractionCount(interactionCount + 1)
        setCourseList(updatedCourses);
        if (!!setUpdatedPastCourses) {
            setUpdatedPastCourses(updatedCourses);
        }
    }

    const selectAllCourses = () => {
        let updatedCourses = courseList 
        if (!!updatedCourses)
        {
            for (let i = 0; i < updatedCourses.length; i++) {
                if (!updatedCourses[i].isHidden) {
                    updatedCourses[i].isSelected = true;
                }
            }
        }
        setInteractionCount(interactionCount + 1)
        setCourseList(updatedCourses);
        if (!!setUpdatedPastCourses) {
            setUpdatedPastCourses(updatedCourses);
        }
    }

    const deselectAllCourses = () => {
        let updatedCourses = courseList 
        if (!!updatedCourses)
        {
            for (let i = 0; i < updatedCourses.length; i++) {
                if (!updatedCourses[i].isHidden) {
                    updatedCourses[i].isSelected = false;
                }
            }
        }
        setInteractionCount(interactionCount + 1)
        setCourseList(updatedCourses);
        if (!!setUpdatedPastCourses) {
            setUpdatedPastCourses(updatedCourses);
        }
    }

    useEffect(() => {
        setCourseList(givenCourses);
    }, [givenCourses])


    useEffect(() => {
        updateLabel(CourseTitle);
    }, [CourseTitle])

    useEffect(() => {
        updateLabel(CourseTitle);
    }, [])

    useEffect(() => {
        if (isResetClicked) {
            if (!!setFilterCourseName) {
                setFilterCourseName("");
            }
            if (!!setFilterPastCourseName) {
                setFilterPastCourseName("");
            }
            deselectAllCourses();
            updateLabel(CourseTitle);
        }
    },[isResetClicked])
    
    useEffect(() => {
        if (courseList !== null && courseList !== undefined && courseList.length > 0) {
            var newCourses = courseList.map(function(e) { 
                if (filterCourseName === null || filterCourseName === undefined || filterCourseName.trimRight().trimLeft() === ""){
                    e.isHidden = false; 
                }
                else {
                    e.isHidden = !(e !== null && e !== undefined && e.name !== null && e.name !== undefined && e.name.toLowerCase().includes(filterCourseName.toLowerCase())); 
                }
                return e;
            });
            setCourseList(newCourses);
            if (!!setUpdatedPastCourses) {
                setUpdatedPastCourses(newCourses);
            }
        }
    },[filterCourseName]) 
    return (
        <>   
            <div>
                <div>
                    <h4 className={CourseTitle == "Past Course" ? styles.sectionTitleWithPadding : styles.sectionTitle}>{CourseTitle}</h4>   
                    {/* {CourseTitle == "Past Course" ? <><p  className={styles.blankContentwithorangecolor}><Warning style={{'color':'orange'}}></Warning>{courseNotes}</p></> : <p className={styles.blankContent}>{courseNotes}</p>}  */}
                    {CourseTitle == "Past Course" ? 
                        <>
                            {/* <ul style={{"paddingLeft":"0px"}}>
                                <li style={{"display":"inline"}}><AlertGreenIcon /></li>
                                <li style={{"display":"inline"}}><span className={styles.blankContentwithgreencolor}>{courseNotes}</span></li>
                            </ul> */}
                            <div className={styles.children_centering_container}>
                                <div className={styles.icon_and_text_container}>
                                    <div className={styles.icon_and_text_container_icon}>
                                        <AlertGreenIcon />
                                    </div>
                                    <div className={styles.icon_and_text_container_text}>
                                    <div>
                                        <span className={styles.blankContentwithgreencolor}>{courseNotes}</span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </> 
                        : 
                        CourseTitle !== allResourcesToTranslations.courseenrolments &&
                        <p className={styles.blankContent}>                          
                        {courseNotes}
                        </p>
                    } 
                    <div className ={hasSmallUnits ? "courseFilterButtonsnew" : "courseFilterButtons" }> <CourseFilterButtons selectAllCourses={selectAllCourses} deselectAllCourses={deselectAllCourses}/></div>
                </div>
                <div className={styles.inputContainer}>
                    <div className={hasSmallUnits ? "courseFilterSearchnew" : "courseFilterSearch"}>
                    <TextField  className={styles.input}  label={courseSearch} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                    value={filterCourseName} onChange={
                        (e) => { 
                            if (!!setFilterCourseName) {
                                setFilterCourseName(e.target.value); 
                            }
                            if (!!setFilterPastCourseName) {
                                setFilterPastCourseName(e.target.value);
                            }
                        }
                    }
                    InputProps={{
                        endAdornment: (                            
                            <IconButton>
                              <SearchIcon />
                            </IconButton>                            
                        )
                      }}
                    /></div>
                </div>    
                            
                   {CourseTitle == "Past Course" ?
                    <div className={`${styles.pastcourseTreeContianer} courseTree`}>
                        <CourseListTree courseList={courseList} updateSelectedCourse={updateSelectedCourse} uniquePrefix="chkNewSaltSR_" />
                    </div>
                    :
                    <div  className={`${styles.courseTreeContianer} courseTree`}>
                        <CourseListTree courseList={courseList} updateSelectedCourse={updateSelectedCourse} uniquePrefix="chkNewSaltSR_" />
                    </div>
                 }
            </div>
        </>
    )
}

export default Courses
