import axios from 'axios';
import { addError } from './AddError';

export const addEmailingReport = async ( 
    token: string,
    orgDomain: string,
    userId: number,
    orgId: number,
    unitIds: string,
    courseIds: string,
    userIds: string,
    totalMailsToGoOut: number,
    emailSubject: string,
    emailBodyTemplate: string,
    quizStatusId: number, 
    dateFrom: string,
    dateTo: string,
    recipientType: number,
    classificationId: number,
    totalUserCount: number,
    formattedUserToIDsList: string,
    
    emailReportType: number,
    selectedPolicyIDs: string,
    selectedPolicyStatus: number,
    selectedExternalCertificateIDs: string,
    selectedExternalCertificateStatus: number,
    selectedCPDProfileStatus: number,
    selectedCPDProfileID: number,
    newStarterOnlyFlag: boolean,
    calledFromImportAccounts: boolean = false
    ) => {
    try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/api/userreport/AddEmailingReport`,
          headers:{
            'Content-Type': 'application/json',
            'Authorization' : `Bearer ${token}`
          },
    
          data: {
            ReportRequestingUserID : userId,
            OrgId : orgId, 
            SelectedUnits :unitIds,
            SelectedCourses: courseIds,
            SelectedUserIDs: userIds,
            TotalMailsToGoOut: totalMailsToGoOut, 
            EmailSubject: emailSubject,
            EmailBodyTemplate: emailBodyTemplate,
            QuizStatusId: quizStatusId, 
            DateFrom: dateFrom,
            DateTo: dateTo,
            RecipientType: recipientType,
            ClassificationId: classificationId,
            TotalUserCount: totalUserCount,
            FormattedUserToIDsList: formattedUserToIDsList,
            
            EmailReportType: emailReportType,
            SelectedPolicies: selectedPolicyIDs,
            SelectedPolicyStatus: parseInt(!!selectedPolicyStatus ? selectedPolicyStatus.toString() : "0"),
            SelectedExternalCertificates: !!selectedExternalCertificateIDs ? selectedExternalCertificateIDs.trim() : "",
            SelectedExternalCertificateStatus: parseInt(!!selectedExternalCertificateStatus ? selectedExternalCertificateStatus.toString() : "0"),
            SelectedCPDProfileStatus: parseInt(!!selectedCPDProfileStatus ? selectedCPDProfileStatus.toString() : "0"),
            SelectedCPDProfileID: parseInt(!!selectedCPDProfileID ? selectedCPDProfileID.toString() : "0"),
            NewStarterOnlyFlag: newStarterOnlyFlag,
            CalledFromImportAccounts: calledFromImportAccounts
        }
        });
        const data = await response.data;
        return data;
      } catch (error: any) {
        if (error.response) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.response);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.response, "AddEmailingReport.ts", 1)
        } else if (error.request) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.request);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.request, "AddEmailingReport.ts", 1)
        } else if (error.message) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.message);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.message, "AddEmailingReport.ts", 1)
        }
        return null;
      }
}
export default addEmailingReport;