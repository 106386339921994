import React from "react";

export const WarningReportIconMenu = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.29 4.36002L1.82002 18.5C1.64539 18.8024 1.55299 19.1453 1.55201 19.4945C1.55103 19.8438 1.64151 20.1872 1.81445 20.4905C1.98738 20.7939 2.23675 21.0468 2.53773 21.2239C2.83871 21.401 3.18082 21.4962 3.53002 21.5H20.47C20.8192 21.4962 21.1613 21.401 21.4623 21.2239C21.7633 21.0468 22.0127 20.7939 22.1856 20.4905C22.3585 20.1872 22.449 19.8438 22.448 19.4945C22.4471 19.1453 22.3547 18.8024 22.18 18.5L13.71 4.36002C13.5318 4.06613 13.2807 3.82314 12.9812 3.65451C12.6817 3.48587 12.3438 3.39728 12 3.39728C11.6563 3.39728 11.3184 3.48587 11.0188 3.65451C10.7193 3.82314 10.4683 4.06613 10.29 4.36002V4.36002Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 9.5V13.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 17.5H12.01" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);

export default WarningReportIconMenu;
