import axios from 'axios';
import { addError } from '../../AddError';

const GetOrgExtCertProfilePoints = async (token: string, requestinguserId: number, orgDomain: string, orgId: number, 
                                                givenExtCertID: number, givenProfileID: number, givenProfilePeriodID: number,
                                                includeInactiveExtCerts: boolean = false, includeInactiveProfiles: boolean = false, langCode: string = "en-au") => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/CPDProfiles/GetOrgExtCertProfilePoints`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        "RequestingUserID": requestinguserId,
        "OrgID": orgId,
        "GivenExtCertID": givenExtCertID,
        "GivenProfileID": givenProfileID,
        "GivenProfilePeriodID": givenProfilePeriodID,
        "IncludeInactiveExternalCertificates": includeInactiveExtCerts,
        "IncludeInactiveProfiles": includeInactiveProfiles,
    },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.response, "GetOrgExtCertProfilePoints.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.request, "GetOrgExtCertProfilePoints.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.message, "GetOrgExtCertProfilePoints.ts", 1)
    }
    return null;
  }
};

export default GetOrgExtCertProfilePoints;