import React, { useContext, useState, useEffect } from 'react';


//Styles
import styles from "../../../styles/emailReport.module.css";
import BrandedCard from "../../../Components/BrandedCard";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';
import getColor from "../../../functions/getColor";
import { CorperateThemeContext } from "../../../contexts/CorperateThemeContext";
import UpArrowIcon from "../../../CustomIcons/UpArrowIcon";
import DownArrowIcon from "../../../CustomIcons/DownArrowIcon";
import sendOrGetUserSentEmails from "../../../API/Administration/Users/SendOrGetUserSentEmails";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import LoaderContainer from "../../../Components/LoaderContainer";
import CustomTooltip from "../../../Components/CustomTooltip";
import EmailIcon from "@material-ui/icons/EmailOutlined";
import Modal from "../../../Components/Modal";
import ReportSaveModal from '../../../Components/CommonPopupModalForResult';
import GuideIcon from '../../../CustomIcons/GuideIcon';
import { Steps } from 'intro.js-react';
import { TranslationsContext } from '../../../contexts/TranslationsContext';
import { UIContext } from '../../../contexts/UIContext';

const ViewSentEmail = (props: any) => {
  const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
  const { userIdToEdit } = props;
  let selectedEmailReportType = 0;
  const { theme: { color }, } = useContext(CorperateThemeContext);
  const { setTitleEnglish } = useContext(UIContext);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [resendEmailStatus, setResendEmailStatus] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentSortBy, setCurrentSortBy] = useState<string>("");
  const [sortInProgress, setSortInProgress] = useState<boolean>(false);
  const [toEmailDescending, setToEmailDescending] = useState<boolean>(false);
  const [dateSentDescending, setDateSentDescending] = useState<boolean>(false);
  const [userEmailDescending, setUserEmailDescending] = useState<boolean>(false);
  const [bodyDescending, setBodyDescending] = useState<boolean>(false);
  const [subjectDescending, setSubjectDescending] = useState<boolean>(false);
  const [bccDescending, setBccDescending] = useState<boolean>(false);
  const [hasRecords, setHasRecords] = useState<boolean>();
  const [emailSubject, setEmailSubject] = useState<string>(!!selectedEmailReportType ? (selectedEmailReportType == 0 ? "Salt Courses" : (selectedEmailReportType == 1 ? "Salt Policies" : (selectedEmailReportType == 2 ? "Continuous Professional Development Incomplete" : "Salt Login Details"))) : "");
  const [emailReportResult, setEmailReportResult] = useState<
    Array<any>
  >([]);
  const [reverseNextSort, setReverseNextSort] = useState<boolean>(false);
  const [isUserSelected, setIsUserSelected] = useState<boolean>(true);
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const requestingUserId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
      .toLowerCase()
      .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";

  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".viewSentEmailsList",
        title: allResourcesToTranslations.vwsntemgmtlist,
        intro: allResourcesToTranslations.vwsntemgmmlist,
      },
      {
        element: ".ActionColumn",
        title: allResourcesToTranslations.sntemrepgmreseml,
        intro: allResourcesToTranslations.sntemrepgmresemlm,
      }
    ],
  });

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  useEffect(() => {
    setTitleEnglish(pageTitles.viewsentemails);
    setLoading(true);
    sendOrGetUserSentEmails(oidcUser.access_token,
      requestingUserId,
      orgDomain,
      parseInt(orgId),
      parseInt(userIdToEdit), '', 0).then((result) => {
        setLoading(false);
        if (result.isSuccess && result.error == null) {
          if (result.getUserSentEmailsItems.length > 0) {
            setHasRecords(true);
            setEmailReportResult([...result.getUserSentEmailsItems]);
          } else {
            setHasRecords(false);
          }
        }
      })
  }, [])

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const sortResultsByAsync = async (
    sortBy:
      | "DateSent"
      | "toEmail"
      | "Subject"
      | "Body"
      | "Bcc",
    sortGivenItemInDesc: boolean
  ) => {
    if (emailReportResult == null || emailReportResult.length <= 0) {
      return emailReportResult;
    }
    var result = emailReportResult.slice(0);
    setCurrentSortBy(sortBy);
    switch (sortBy) {

      case "DateSent":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.dateCreatedText === null) {
              return -1;
            }
            if (a === null || a.dateCreatedText === null) {
              return 1;
            }
            if (a.dateCreatedText.toLowerCase() > b.dateCreatedText.toLowerCase()) {
              return -1;
            }
            if (a.dateCreatedText.toLowerCase() < b.dateCreatedText.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setToEmailDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.dateCreatedText === null) {
              return 1;
            }
            if (a === null || a.dateCreatedText === null) {
              return -1;
            }
            if (a.dateCreatedText.toLowerCase() < b.dateCreatedText.toLowerCase()) {
              return -1;
            }
            if (a.dateCreatedText.toLowerCase() > b.dateCreatedText.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setToEmailDescending(true);
          }
        }
        setEmailReportResult(result);
        break;
      case "toEmail":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.toEmail === null) {
              return -1;
            }
            if (a === null || a.toEmail === null) {
              return 1;
            }
            if (a.toEmail.toLowerCase() > b.toEmail.toLowerCase()) {
              return -1;
            }
            if (a.toEmail.toLowerCase() < b.toEmail.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setDateSentDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.toEmail === null) {
              return 1;
            }
            if (a === null || a.toEmail === null) {
              return -1;
            }
            if (a.toEmail.toLowerCase() < b.toEmail.toLowerCase()) {
              return -1;
            }
            if (a.toEmail.toLowerCase() > b.toEmail.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setDateSentDescending(true);
          }
        }
        setEmailReportResult(result);
        break;
      case "Subject":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.subject === null) {
              return -1;
            }
            if (a === null || a.subject === null) {
              return 1;
            }
            if (a.subject.toLowerCase() > b.subject.toLowerCase()) {
              return -1;
            }
            if (a.subject.toLowerCase() < b.subject.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setSubjectDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.subject === null) {
              return 1;
            }
            if (a === null || a.subject === null) {
              return -1;
            }
            if (a.subject.toLowerCase() < b.subject.toLowerCase()) {
              return -1;
            }
            if (a.subject.toLowerCase() > b.subject.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUserEmailDescending(true);
          }
        }
        setEmailReportResult(result);
        break;

      case "Body":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.body === null) {
              return -1;
            }
            if (a === null || a.body === null) {
              return 1;
            }
            if (a.body.toLowerCase() > b.body.toLowerCase()) {
              return -1;
            }
            if (a.body.toLowerCase() < b.body.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setBodyDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.body === null) {
              return 1;
            }
            if (a === null || a.body === null) {
              return -1;
            }
            if (a.body.toLowerCase() < b.body.toLowerCase()) {
              return -1;
            }
            if (a.body.toLowerCase() > b.body.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setBodyDescending(true);
          }
        }
        setEmailReportResult(result);
        break;
      case "Bcc":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.bcc === null) {
              return -1;
            }
            if (a === null || a.bcc === null) {
              return 1;
            }
            if (a.bcc.toLowerCase() > b.bcc.toLowerCase()) {
              return -1;
            }
            if (a.bcc.toLowerCase() < b.bcc.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setBccDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.bcc === null) {
              return 1;
            }
            if (a === null || a.bcc === null) {
              return -1;
            }
            if (a.bcc.toLowerCase() < b.bcc.toLowerCase()) {
              return -1;
            }
            if (a.bcc.toLowerCase() > b.bcc.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setBccDescending(true);
          }
        }
        setEmailReportResult(result);
        break;
      default:
        break;
    }
    return result.slice(0);
  };

  const sortResultsBy = async (
    sortBy:
      | "DateSent"
      | "toEmail"
      | "Subject"
      | "Body"
      | "Bcc",
    sortGivenItemInDesc: boolean
  ) => {
    setSortInProgress(true);
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy);
      setEmailReportResult(result);
      setPage(0);
      setSortInProgress(false);
    });
  };

  const clickSubjectAsc = () => {
    setReverseNextSort(false);
    setSubjectDescending(false);
    sortResultsBy("Subject", false);
  };

  const clickSubjectDesc = () => {
    setReverseNextSort(false);
    setSubjectDescending(true);
    sortResultsBy("Subject", true);
  };

  const clickBodyAsc = () => {
    setReverseNextSort(false);
    setSubjectDescending(false);
    sortResultsBy("Body", false);
  };

  const clickBodyDesc = () => {
    setReverseNextSort(false);
    setSubjectDescending(true);
    sortResultsBy("Body", true);
  };

  const clickDateSentAsc = () => {
    setReverseNextSort(false);
    setDateSentDescending(false);
    sortResultsBy("DateSent", false);
  };

  const clickDateSentDesc = () => {
    setReverseNextSort(false);
    setDateSentDescending(true);
    sortResultsBy("DateSent", true);
  };

  const clickFirstNameAsc = () => {
    setReverseNextSort(false);
    setToEmailDescending(false);
    sortResultsBy("toEmail", false);
  };

  const clickFirstNameDesc = () => {
    setReverseNextSort(false);
    setToEmailDescending(true);
    sortResultsBy("toEmail", true);
  };

  const clickBccAsc = () => {
    setReverseNextSort(false);
    setBccDescending(false);
    sortResultsBy("Bcc", false);
  };

  const clickBccDesc = () => {
    setReverseNextSort(false);
    setBccDescending(true);
    sortResultsBy("Bcc", true);
  };

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
    } else {
      return {};
    }
  };

  const handleResendEmail = (emailId: any) => {
    setShowWarningModal(false);
    setModalPopupMessage('Sending Email in progress...');
    sendOrGetUserSentEmails(oidcUser.access_token,
      requestingUserId,
      orgDomain,
      parseInt(orgId),
      parseInt(userIdToEdit), 'resend', emailId).then((result) => {
        if (!!result && result.isSuccess && (result.error === null || result.error.toString().trim() === "")) {
          if (!!result.getUserSentEmailsItems) {
            setEmailReportResult([...result.getUserSentEmailsItems]);
          } 
          setShowWarningModal(true);
          setResendEmailStatus(true);
          setHasErrored(false);
          setModalPopupMessage('Resend email is successful!');
        } else {
          setShowWarningModal(true);
          setResendEmailStatus(false);
          setHasErrored(true);
          setModalPopupMessage('Resend email has failed!' + ((!!result && !!result.error) ? (' Reason: ' + result.error.toString().trim()) : ''));
        }
      })
  }

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  useEffect(() => {
    if (setResendEmailStatus && resendEmailStatus) {
      setTimeout(() => {
        setShowWarningModal(false);
        setResendEmailStatus(false);
      }, 5000)
    }
  }, [showWarningModal])

  if (loading) return <LoaderContainer />;

  return (
    <>
       {hasRecords && <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }}
      />}
       {hasRecords && <CustomTooltip displayText={[allResourcesToTranslations.guideme]}>
        <span
          onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific_ViewSendEmail}
        >
          <GuideIcon />
        </span>
      </CustomTooltip> }
    <div>
 
      <p style={{ fontWeight: "lighter", fontSize: "12px", marginBottom: "10px", color: "#D3D6DF" }} className="UserId">{allResourcesToTranslations.vwsntemgmmaccid + ": " + userIdToEdit.toString()} </p>
      {hasRecords && <div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <TablePagination
            component="div"
            count={emailReportResult.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
        <div className={styles.tableSection}>
          <div className={styles.tableContainer}>
            <BrandedCard>
              <TableContainer>
                <Table>
                  <TableHead className="viewSentEmailsList">
                    <TableRow>
                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                          borderRadius: "8px 0 0 0"
                        }}
                        align="left"
                      >
                        <div className={styles.tableHeadCellContainer}>
                          <div
                            onClick={() => {
                              setDateSentDescending(!dateSentDescending);
                              sortResultsBy("DateSent", !dateSentDescending);
                            }}
                          >
                            {allResourcesToTranslations.sntemrepgmmdtsntl}
                          </div>
                          <div className={styles.columnHeadArea}>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "DateSent" &&
                                    dateSentDescending === false
                                    ? "100%"
                                    : "50%",
                                margin: "2px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickDateSentAsc}
                            >
                              <UpArrowIcon />{" "}
                            </span>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "DateSent" &&
                                    dateSentDescending === true
                                    ? "100%"
                                    : "50%",
                                margin: "0px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickDateSentDesc}
                            >
                              <DownArrowIcon />{" "}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                        }}
                        align="left"
                      >
                        <div className={styles.tableHeadCellContainer}>
                          <div
                            onClick={() => {
                              setToEmailDescending(!toEmailDescending);
                              sortResultsBy(
                                "toEmail",
                                !toEmailDescending
                              );
                            }}
                          >
                            {allResourcesToTranslations.sntemrepgmmemtgtl}
                          </div>
                          <div className={styles.columnHeadArea}>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "toEmail" &&
                                    toEmailDescending === false
                                    ? "100%"
                                    : "50%",
                                margin: "2px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickFirstNameAsc}
                            >
                              <UpArrowIcon />{" "}
                            </span>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "toEmail" &&
                                    toEmailDescending === true
                                    ? "100%"
                                    : "50%",
                                margin: "0px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickFirstNameDesc}
                            >
                              <DownArrowIcon />{" "}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                        }}
                        align="left"
                      >
                        <div className={styles.tableHeadCellContainer}>
                          <div
                            onClick={() => {
                              setBccDescending(!bccDescending);
                              sortResultsBy(
                                "Bcc",
                                !bccDescending
                              );
                            }}
                          >
                            {allResourcesToTranslations.sntemrepgmmemccl}
                          </div>
                          <div className={styles.columnHeadArea}>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "Bcc" &&
                                    bccDescending === false
                                    ? "100%"
                                    : "50%",
                                margin: "2px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickBccAsc}
                            >
                              <UpArrowIcon />{" "}
                            </span>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "Bcc" &&
                                    bccDescending === true
                                    ? "100%"
                                    : "50%",
                                margin: "0px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickBccDesc}
                            >
                              <DownArrowIcon />{" "}
                            </span>
                          </div>
                        </div>

                      </TableCell>

                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                        }}
                        align="left"
                      >
                        <div className={styles.tableHeadCellContainer}>
                          <div
                            onClick={() => {
                              setSubjectDescending(!subjectDescending);
                              sortResultsBy(
                                "Subject",
                                !subjectDescending
                              );
                            }}
                          >
                            {allResourcesToTranslations.emrepprvgmtsub}
                          </div>
                          <div className={styles.columnHeadArea}>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "Subject" &&
                                    subjectDescending === false
                                    ? "100%"
                                    : "50%",
                                margin: "2px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickSubjectAsc}
                            >
                              <UpArrowIcon />{" "}
                            </span>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "Subject" &&
                                    subjectDescending === true
                                    ? "100%"
                                    : "50%",
                                margin: "0px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickSubjectDesc}
                            >
                              <DownArrowIcon />{" "}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                        }}
                        align="left"
                      >
                        <div className={styles.tableHeadCellContainer}>
                          <div
                            onClick={() => {
                              setBodyDescending(!bodyDescending);
                              sortResultsBy(
                                "Body",
                                !bodyDescending
                              );
                            }}
                          >
                            {allResourcesToTranslations.sntemrepgmmbdyl}
                          </div>
                          <div className={styles.columnHeadArea}>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "Body" &&
                                    bodyDescending === false
                                    ? "100%"
                                    : "50%",
                                margin: "2px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickBodyAsc}
                            >
                              <UpArrowIcon />{" "}
                            </span>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "Body" &&
                                    bodyDescending === true
                                    ? "100%"
                                    : "50%",
                                margin: "0px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickBodyDesc}
                            >
                              <DownArrowIcon />{" "}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                          borderRadius: "0 8px 0 0"
                        }}
                        align="left"
                        className="ActionColumn"
                      >
                        <div className={styles.tableHeadCellContainer}>
                          <div

                          >
                            {allResourcesToTranslations.action}
                          </div>

                        </div>
                      </TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {emailReportResult
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((result: any, i) => {
                        return (
                          <TableRow
                            key={`currentemailReportResult-${i}`}
                            style={rowBackgroundColor(i)}
                          >
                            <TableCell align="left" style={{verticalAlign: "top"}}>
                              {result.dateCreatedText}
                            </TableCell>
                            <TableCell align="left" style={{verticalAlign: "top"}}>
                              {result.toEmail}
                            </TableCell>
                            <TableCell align="left" style={{verticalAlign: "top"}}>
                              {result.bcc}
                            </TableCell>
                            <TableCell align="left" style={{verticalAlign: "top"}}>
                              {result.subject}
                            </TableCell>
                            <TableCell align="left" style={{verticalAlign: "top"}}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: result.body //bodyFormatted
                                }}></div>
                            </TableCell>
                            <TableCell align="left" style={{verticalAlign: "top"}}>
                              <CustomTooltip displayText={[allResourcesToTranslations.sntemrepgmreseml]}>
                                <Button variant="contained" color="primary" onClick={() => handleResendEmail(result.emailid)}>
                                  <EmailIcon />
                                </Button>
                              </CustomTooltip>
                            </TableCell>


                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </BrandedCard>
          </div>
        </div>
        <div hidden={isUserSelected} style={{ width: "98%", marginTop: "10px", color: "red" }}><label>{allResourcesToTranslations.emrepgmselac}</label></div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <TablePagination
            component="div"
            count={emailReportResult.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </div>}
      {!hasRecords && <div>
        <BrandedCard>
          <div className={styles.noResultsCard}>
            <div>{allResourcesToTranslations.sntemrepgmmnoresm}</div>
            <div>{allResourcesToTranslations.sntemrepgmmselbkprevm}</div>
          </div>
        </BrandedCard>
      </div>}
      {showWarningModal && <ReportSaveModal saveSuccess={resendEmailStatus} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={resendEmailStatus ? 'Success' : hasErrored ? 'Error' : 'Resend Email'} bodyText={modalPopupMessage} />}
    </div>
  </>
  )

}

export default ViewSentEmail;
