import axios from 'axios';
import { addError } from '../../AddError';

const SetProfileAccessSpecific = async (token: string, requestinguserId: number, orgDomain: string, orgId: number, profileID: number, profilePeriodID: number, 
                                        NewlyChangedToGrantAccessUnitsCSV: string, newlyChangedToGrantAccessUsersCSV: string, 
                                        givenUnitsCSVToGrantAccess: string, givenUsersCSVToGrantAccess: string, 
                                        givenUnitsCSVToDenyAccess: string, givenUsersCSVToDenyAccess: string, langCode: string = "en-au") => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/CPDProfiles/SetProfileAccessSpecific`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        "RequestingUserID": requestinguserId,
        "OrgID": orgId,
        "ProfileID": profileID,
        "ProfilePeriodID": profilePeriodID,
        "GivenUnitsCSVToGrantAccess": givenUnitsCSVToGrantAccess,
        "GivenUsersCSVToGrantAccess": givenUsersCSVToGrantAccess,
        "GivenUnitsCSVToDenyAccess": givenUnitsCSVToDenyAccess,
        "GivenUsersCSVToDenyAccess": givenUsersCSVToDenyAccess,
        "NewlyChangedToGrantAccessUnitsCSV": NewlyChangedToGrantAccessUnitsCSV,
        "NewlyChangedToGrantAccessUsersCSV": newlyChangedToGrantAccessUsersCSV,
    },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.response, "SetProfileAccessSpecific.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.request, "SetProfileAccessSpecific.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.message, "SetProfileAccessSpecific.ts", 1)
    }
    return null;
  }
};

export default SetProfileAccessSpecific;