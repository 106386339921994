import React, { useState, useEffect, useContext, } from "react";
import { useHistory } from "react-router-dom";
import { UnitTreeItem, CourseTreeItem } from "../../globalTypes";
//import SelectPolicy from 'react-select'
//import SelectAcceptance from 'react-select'

//libs
import { Steps, Hints } from "intro.js-react";

//MUI
import { Button, Checkbox, FormControl, FormControlLabel, FormLabel, InputLabel, Radio, RadioGroup } from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

//Contexts
import { UIContext } from "../../contexts/UIContext";
import { LoaderContext } from "../../contexts/LoaderContext";
import { CPDPanelContext } from '../../contexts/CPDPanelContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';

//API Functions
import { getRelevantUnitTree } from "../../API/GetRelevantUnitTree";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import getFullPolicyBuilderReport from "../../API/GetFullPolicyBuilderReport";
import getOrgPolicies from "../../API/GetOrgPolicies";

//Components
import BrandedCard from "../../Components/BrandedCard";
import LoaderContainer from "../../Components/LoaderContainer";
import CustomTooltip from "../../Components/CustomTooltip";
import ResetIcon from "../../CustomIcons/ResetIcon";
import Units from "../ReportCommonComponents/Units";
import GuideIcon from '../../CustomIcons/GuideIcon';
//import { MultiSelect } from "../../Components/MultiSelect";
import ButtonPanel from '../CurrentAdminReport/ButtonPanel'
import PolicySelection from "./PolicySelection";

//Styles
import styles from "../../styles/policybuilder.module.css";
import CommonPopupModalForResult from "../../Components/CommonPopupModalForResult";

const PolicyBuilderReport = () => {
  const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext);
  const history = useHistory();
  const { setMessage } = useContext(LoaderContext);
  const { currentLanguage} = useContext(TranslationsContext)
  const { setTitle, setTitleEnglish, updateBackButtonRoute } = useContext(UIContext);
  const { cpdList, currentCpd } = useContext(CPDPanelContext);
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";

  const [units, setUnits] = useState<Array<UnitTreeItem>>([]);

  const [filterDateFrom, setFilterDateFrom] = useState("");
  const [filterDateTo, setFilterDateTo] = useState("");
  const [searchPolicy, setSearchPolicy] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const [isResetClicked, setisResetClicked] = useState<boolean>(false);
  const [orgPolicyOptions, setOrgPolicyOptions] = useState<any>([]);
  const [policyAcceptance, setPolicyAcceptance] = useState<any>("both");
  const [filterIncludeInactiveUsers, setFilterIncludeInactiveUsers] = useState<boolean>(false);
  const [isARTypeFocussed, setIsARTypeFocussed] = React.useState(false);
  const [filterAdminReportType, setFilterAdminReportType] = useState<any>(0);
  const [showScheduler, setshowScheduler] = useState<boolean>(true);
  const [filterUnitName, setFilterUnitName] = useState("");
  // Extra states for report scheduler input
  const [givenFirstName, setGivenFirstName] = useState<string>("");
  const [givenLastName, setGivenLastName] = useState<string>("");
  const [givenUserName, setGivenUserName] = useState<string>("");
  const [courseList, setCourseList] = useState<Array<CourseTreeItem>>([]) 
  const [filterEmail, setFilterEmail] = useState("");
  const [filterSubject, setFilterSubject] = useState("");
  const [filterBody, setFilterBody] = useState("");
  const [filterQuizStatus, setFilterQuizStatus] = useState("");
  const [filterSortBy, setFilterSortBy] = useState("0");
  const [filterExpiryValue, setFilterExpiryValue] = useState(0);
  const [filterExpiryUnit, setFilterExpiryUnit] = useState("d");
  const [filterAcceptance, setFilterAcceptance] = useState("0");
  const [filterLicensingPeriod, setFilterLicensingPeriod] = useState("");
  const [filterHistoricCourseIds, setFilterHistoricCourseIds] = useState("");
  const [filterPolicyIds, setFilterPolicyIds] = useState("");
  const [filterCourseStatus, setFilterCourseStatus] = useState("");
  const [classificationList, setClassificationList] = useState([]);
  const [selectedClassification, setSelectedClassification] = useState<any>({});
  const [groupingOption, setGroupingOption] = useState<boolean>(false);
  const [selectedProfileID, setSelectedProfileID] = useState<any>(-1);
  const [selectedProfilePeriodID, setSelectedProfilePeriodID] = useState<any>(-1);
  const [shortfallUsers, setShortfallUsers] = useState<boolean>(false);
  const [orgCPDProfileResult, setOrgCPDProfileResult] = useState<any>(null);

  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);

  const [intro, setIntro] = useState({
      stepsEnabled: false,
      initialStep: 0,
      steps: [
        {
          element: ".adminReportType",
          title: "Admin Report Type", intro: allResourcesToTranslations.emrepgmadminreporttype
        }            
      ]
    })

    useEffect(() => 
    {
                  let updateSteps = [...intro.steps]
                  updateSteps.push( 
                  {
                    element: ".unitFilterButtons",
                    title: "Units", intro: allResourcesToTranslations.emrepgmunitsm,
                  },
                  {
                    element: ".unitFilterSearch",
                    title: "Filter Unit", intro: allResourcesToTranslations.unitfiltersearch
                  },
                  {
                      element: ".policyFilterButtons",
                      title: "Policies", intro: allResourcesToTranslations.emrepgmpsm
                  },
                  {
                      element: ".policySearch",
                      title: "Filter Policy", intro: allResourcesToTranslations.emrepgmfpsm
                  },
                  {
                    element: ".policyAcceptance",
                    title: "Filter", intro: allResourcesToTranslations.pbrptgmpolicyacceptance
                  },
                  {
                    element: ".dateFrom",
                    title: "Filter", intro: allResourcesToTranslations.emrepgmfdfm
                  },
                  {
                    element: ".dateTo",
                    title: "Filter", intro: allResourcesToTranslations.emrepgmfdtm
                  },
                  {
                    element:  ".includeInactive",
                    title: "Filter", intro: allResourcesToTranslations.prrepgminactiveaccount
                }, 
                {
                    element:  ".runReport",
                    title: "Run Report", intro: allResourcesToTranslations.sntemrepgmmrunrep
                },
                {
                  element:  ".scheduleReport",
                  title: "Schedule Report", intro: allResourcesToTranslations.repgmschedulereport
                },
                {
                  element:  ".resetAll",
                  title: "Reset Filters", intro: allResourcesToTranslations.emrepgmram
                } 

                  )
                setIntro({...intro, steps: [...updateSteps]})              
      },[])
      
      const onExit = () => {
        setIntro({...intro, stepsEnabled: false });
      };

    
  useEffect(() => {
    setTitle(allResourcesToTranslations.policybuilderreport);
    setTitleEnglish(pageTitles.policybuilderreport)
    updateBackButtonRoute("");
    setLoading(true);
    getPoliciesList(parseInt(filterAdminReportType));
    getRelevantUnitTree(
      oidcUser.access_token,
      userId,
      orgDomain,
      true,
      parseInt(orgId)
    ).then((unitTreeRes) => {
      if(!!unitTreeRes) {
        if (!!unitTreeRes.isSuccess && unitTreeRes.isSuccess) {
          setUnits(unitTreeRes.unitTreeItems);        
          setLoading(false);
        }
      }
    });
  }, []);

  useEffect(() => {
      getPoliciesList(parseInt(filterAdminReportType));
      // if (filterAdminReportType === 1) {
      //   setshowScheduler(false);
      // } else { 
      //   setshowScheduler(true);
      // }
  }, [filterAdminReportType, showScheduler]);

  const getPoliciesList = (ReportType: number) => {
    getOrgPolicies(
      oidcUser.access_token,
      userId,
      orgDomain,
      parseInt(orgId),
      ReportType === 0 ? true : false
    ).then((orgPoliciesRes) => {
      //debugger
      if (!!orgPoliciesRes && !!orgPoliciesRes.isSuccess && orgPoliciesRes.isSuccess) {
        let updatedItems = [];
        if(orgPoliciesRes.policyItems){
          updatedItems = orgPoliciesRes.policyItems.map((item:any) => {
            return {
              ...item,
              isSelected: false,
              isHidden: false
            }
          })
        }
        setOrgPolicyOptions(updatedItems);        
      }
    });
  };

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  const handlerunReport = () => {

    let unitIds: Array<number> = [];
    let unitData: Array<string> = [];
    let allUnitIds: Array<number> = [];
    setLoading(true);
    setMessage("Fetching your report");
    const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return //Break if no children
      if (unitArray.length === 0) return
      for (let i = 0; i < unitArray.length; i++) {
          const unit = unitArray[i];
          if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) { 
            if (filterUnitName !== "") {
                if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
                    unitIds.push(unit.unitID);
                }
            } else {
                unitIds.push(unit.unitID);
            }
          }   
          allUnitIds.push(unit.unitID);
          getSelectedUnitIds(unit.childUnitTreeItems);
      }
    }

    getSelectedUnitIds(units);
    let policyIds: Array<number> = [];
    let policyData: Array<string> = [];
    let allPolicyIds: Array<number> = [];
    setLoading(true);
    setMessage("Fetching your report");
    const getSelectedPolicyIds = (policyArray: any) => {
        if (policyArray === null) return //Break if no children
        if (policyArray.length === 0) return
        for (let i = 0; i < policyArray.length; i++) {
            const policy = policyArray[i];
            if (policy.isSelected) {
                policyIds.push(policy.policyID);              
            } 
            allPolicyIds.push(policy.policyID);
        }
    }

    getSelectedPolicyIds(orgPolicyOptions);

    if (allPolicyIds.length === 0) {
      setShowWarningModal(true);
      setModalPopupMessage(allResourcesToTranslations.addorassignpolicy);
      setHasErrored(true);

      setLoading(false);
      setMessage("");

      return;
    }
    const nextDate = new Date()
    nextDate.setDate(nextDate.getDate()+1);
    const nextDateString = formatDate(nextDate); 
    getFullPolicyBuilderReport(oidcUser.access_token, userId, orgDomain, parseInt(orgId), 
    unitIds.length > 0 ? unitIds.toString() : allUnitIds.toString(), 
    policyIds.length > 0 ? policyIds.toString() : allPolicyIds.toString(),
    ((filterDateFrom === null || filterDateFrom === undefined || filterDateFrom.trimLeft().trimRight() === "") ? "1996-12-31" :  filterDateFrom),
    ((filterDateTo === null || filterDateTo === undefined || filterDateTo.trimLeft().trimRight() === "") ? nextDateString : filterDateTo),
    policyAcceptance,filterIncludeInactiveUsers, true).then((reportRes) => {        
        setLoading(false);
        history.push({
            pathname: `policybuilderreport/result`,               
            state: {
                includeInactiveUsers: filterIncludeInactiveUsers,
                selectedUnits: unitData.toString(),                           
                resultItems: (reportRes === null ? null : reportRes.policyBuilderReportItems),
                resultTotalItems: (reportRes === null ? null : reportRes.policyBuilderTotalsReportItems),
                resultGrandTotalItems: (reportRes === null ? null : reportRes.policyBuilderGrandTotalReportItems),
                policyAcceptance: policyAcceptance,
                policyIDs: !!orgPolicyOptions ? orgPolicyOptions.filter((o : any)=>o.isSelected).map((o : any)=>o.policyID.toString()).join(',') : "",
                unitIDs: !!unitIds ? unitIds.join(',') : "",
                filterDateFrom: ((filterDateFrom === null || filterDateFrom === undefined || filterDateFrom.trimLeft().trimRight() === "") ? "1996-12-31" :  filterDateFrom),
                filterDateTo: ((filterDateTo === null || filterDateTo === undefined || filterDateTo.trimLeft().trimRight() === "") ? nextDateString : filterDateTo),
                resultError: ((reportRes === null || reportRes === undefined || reportRes.isSuccess || reportRes.error === null || reportRes.error === undefined) ? "" : reportRes.error)
            },
        });
      })
  };

  const handlePolicySearch = (e:any) => {
    setSearchPolicy(e.target.value);
  }

  const resetAll = () => {  
    setFilterDateFrom('');
    setFilterDateTo('');
    setSearchPolicy("");
    setPolicyAcceptance("both");
    setFilterAcceptance("both");
    if(orgPolicyOptions.length > 0) {
      let updatePolicies = orgPolicyOptions.map((item:any) => {
        
            return {
                ...item,
                isSelected: false
            }
    })
    setOrgPolicyOptions([...updatePolicies]);
    }else {
      setOrgPolicyOptions([]);
    }
    setFilterIncludeInactiveUsers(false);
    setisResetClicked(!isResetClicked);
  };
  const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
  {
      if (!isNaN(givenDate.getTime())) {
          const year = new Intl.DateTimeFormat('en-AU', {year: 'numeric'}).format(givenDate);
          const month = new Intl.DateTimeFormat('en-AU', {month: 'numeric'}).format(givenDate);
          const day = new Intl.DateTimeFormat('en-AU', {day: 'numeric'}).format(givenDate);
  
          return (year.toString() + "-" + 
          (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" + 
          (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
      }
      else {
          return "";
      }
  }
  const handlePolicyOptionsUpdate = (data:any) => {  
    setOrgPolicyOptions(data);
  }; 

  const handleSelectAllPolicies = () => {
    if(orgPolicyOptions.length != 0) {
      let updatedPolicyOptions = orgPolicyOptions.map((item:any) => {
        return {
          ...item,
          isSelected: true
        }
      })

      setOrgPolicyOptions(updatedPolicyOptions);
    }
  }

  const handleUnSelectAllPolicies = () => {
    if(orgPolicyOptions.length != 0) {
      let updatedPolicyOptions = orgPolicyOptions.map((item:any) => {
        return {
          ...item,
          isSelected: false
        }
      })
      setOrgPolicyOptions(updatedPolicyOptions);
    }
  }

  const handlePolicyAcceptance = (event:any) => {
    const val = (event.target as HTMLInputElement).value;
    setPolicyAcceptance(val);
    setFilterAcceptance(val);
  }
    
  const getValidClassificationID = (selectedClassification: any) => {
      var classID : number = 0;
      if ((!!selectedClassification) && (!!selectedClassification.classificationID)) {
          classID = ((typeof selectedClassification.classificationID) !== 'number') ? 
              parseInt(selectedClassification.classificationID.toString()) : 
              selectedClassification.classificationID;
      }
      return classID;
  }

  const setModalOpen = (isOpen: boolean) => 
  {
    let policyIdsLocal: Array<number> = [];
    const getSelectedPolicyIds = (policyArray: any) => {
        if (policyArray === null) return //Break if no children
        if (policyArray.length === 0) return
        for (let i = 0; i < policyArray.length; i++) {
            const policy = policyArray[i];
            if (policy.isSelected) {
              policyIdsLocal.push(policy.policyID);              
            } 
        }
    }
    getSelectedPolicyIds(orgPolicyOptions);

    let cpdProfileId = -1
    cpdProfileId = (
        (cpdList !== null && 
        cpdList !== undefined && 
        cpdList.length > 0 && 
        currentCpd >= 0 && 
        cpdList.length > currentCpd) ? 
        cpdList[currentCpd].id : -1)
    history.push({
        pathname: `/PBR/reportscheduler/-1`,
        state: {                
            selectedUnits: units,
            profileID: cpdProfileId,
            reportId: 36,  // 36 is for PBR
            langCode: currentLanguage,
            isIncludeInactive: filterIncludeInactiveUsers,
            dateFrom: filterDateFrom,
            dateTo: filterDateTo,
            acceptance: policyAcceptance,
            policyIds: !!policyIdsLocal ? policyIdsLocal.map((o : any)=>o.toString()).join(',') : "",
            policyIdsArr: !!policyIdsLocal ? policyIdsLocal : null,
            
            // Extra default for scheduler
            firstName: givenFirstName,
            lastName: givenLastName,
            userName: givenUserName,
            selectedProfileID: !!selectedProfileID ? selectedProfileID : -1,
            selectedProfilePeriodID: !!selectedProfilePeriodID ? selectedProfilePeriodID : -1,
            onlyUsersWithShortfall: shortfallUsers,
            givenOrgCPDList: orgCPDProfileResult,
            selectedCourses: courseList,
            email: filterEmail,
            classificationList: classificationList,
            groupingOption: groupingOption,
            selectedClassification: selectedClassification,
            groupBy: ((selectedClassification === null || selectedClassification === undefined || 
                selectedClassification.value === null || selectedClassification.value === undefined) ? "" : selectedClassification.value),
            selectedClassificationID: !!selectedClassification ? getValidClassificationID(selectedClassification.classificationID) : 0,
            subject: filterSubject,
            body: filterBody,
            status: filterCourseStatus,
            quizStatus: filterQuizStatus,
            sortBy: filterSortBy,
            expiryValue: filterExpiryValue,
            expiryUnit: filterExpiryUnit,
            licensingPeriod: filterLicensingPeriod,
            historicCourseIds: filterHistoricCourseIds
        },
    }); 
  }


  if (loading) return <LoaderContainer />;

  return (
    <>
         <Steps  enabled={intro.stepsEnabled}   steps={intro.steps}  initialStep={intro.initialStep}
          onExit={onExit}
            options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
          }}/>
         <CustomTooltip displayText={["Guide Me"]}>
            <span onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
            <GuideIcon />
            </span>
         </CustomTooltip>
    <BrandedCard>
      <div className={styles.reportWrraper}>
        <div className={styles.unittreesection}>
        <div style={{ paddingTop: '20px'}} className="adminReportType">
                <FormControl variant="outlined" className={styles.formControl} style={{width: "100%"}}>
                  <InputLabel id="selNewSaltARType" focused={isARTypeFocussed} style={{fontWeight: 'bolder', fontSize: '40', color: isARTypeFocussed ? '#63C2FF' : 'black'}}>I want to report on :</InputLabel>
                  <Select
                      id="selNewSaltARType"
                      native
                      value={filterAdminReportType}
                      label="I want to report on:"
                      variant="outlined"
                      onChange={(e) => {
                              setFilterAdminReportType(e.target.value);
                              if (!!e.target.value && (e.target.value === "0")) {
                                  setshowScheduler(true);
                              }
                              else if (!!e.target.value && (e.target.value === "1")) {
                                setshowScheduler(false);
                              }
                          }
                      }>  
                      <optgroup label="I want to report on:">
                          <option value={0}>Current Policies Report</option>
                          <option value={1}>Inactive Policies Report</option>
                      </optgroup>
                  </Select>
              </FormControl>
          </div>
          <br/>
          <div>
            <Units isResetClicked={isResetClicked} givenUnits={units} givenFilterUnitName={filterUnitName} setGivenFilterUnitName={setFilterUnitName}/>
          </div>
        </div>
        
        <div className={styles.rightSection}>  
        <PolicySelection selectAllPolicies={handleSelectAllPolicies} handlePolicySearch={handlePolicySearch} searchPolicy={searchPolicy} deselectAllPolicies={handleUnSelectAllPolicies} orgPolicyOptions={orgPolicyOptions} updatePolicyOptions={handlePolicyOptionsUpdate} sectionFlag={true} />      
        <span className={styles.error} hidden={orgPolicyOptions.length > 0}>{allResourcesToTranslations.addorassignpolicy}</span>
        <div style={{marginTop:'15px'}} className="policyAcceptance">
                            <FormControl component="fieldset" style={{ width: '100%' }} className="recipient">
                                <FormLabel component="legend">Policy Acceptance</FormLabel>
                                <div className={styles.childContainer}>
                                    <RadioGroup
                                        name="periodType"
                                        value={policyAcceptance}
                                        onChange={handlePolicyAcceptance}
                                        style={{ display: 'flex', flexDirection: 'row' }}
                                    >
                                        <FormControlLabel
                                            value={"accepted"}
                                            control={<Radio color="primary" />}
                                            label={"Accepted"}
                                        />
                                        <FormControlLabel
                                            value={"not_accepted"}
                                            control={<Radio color="primary" />}
                                            label={"Not Accepted"}
                                        />
                                        <FormControlLabel
                                            value={"both"}
                                            control={<Radio color="primary" />}
                                            label={"Both"}
                                        />
                                    </RadioGroup>
                                </div>
                            </FormControl>
                        </div>
          <div className={styles.inputContainer}>
                            <div className="dateFrom"><TextField className={styles.input} label={"Date From"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                            type="date" defaultValue={filterDateFrom} onChange={(e) => setFilterDateFrom(formatDate(new Date(e.target.value)))}/></div>
          </div>
          <div className={styles.inputContainer}>
                            <div className="dateTo"><TextField  className={styles.input}  label={"Date To"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                            type="date" defaultValue={filterDateTo} onChange={(e) => setFilterDateTo(formatDate(new Date(e.target.value)))} /></div>                    
          </div>
          <div className={`${styles.inactiveContainer} includeInactive`} >                           
                  <Checkbox
                      size="small"
                      color="primary"
                      checked={filterIncludeInactiveUsers}
                      className={styles.checkBox}
                      onClick={() => setFilterIncludeInactiveUsers(!filterIncludeInactiveUsers)}
              /> {"Include Inactive Accounts"}
          </div>
          <div>
              <ButtonPanel openReportSchedulerModal={setModalOpen} showScheduler={showScheduler} runReport={handlerunReport} resetAll={resetAll}/>
          </div> 
        </div>

        <div></div>
        <div style={{ position: 'fixed',  zIndex: 10000}}>
          {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
        </div>
      </div>
    </BrandedCard>
    </>
  );
};

export default PolicyBuilderReport;
