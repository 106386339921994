import React, { StrictMode, useEffect } from "react";
import styles from "../../styles/pdfplayer.module.css";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

interface Props {
  url: string;
}

export const DocumentViewer = ({url}: Props) => {
  const getExtension = (filename: string) => {  
    return filename.split('.').pop();
  }
  const docs = [
    //DocumentViewer not able to open document in localhost due to microsoft app
    
    //{ uri: require(url) ,fileType:'docx' }
  //  {
  //        uri:
  //          "https://testemailnotification17.saltcompliance.com/General/Policy/161/SaltReleaseNotes%20(1)%20(3).docx", fileType: 'docx'
  //   },
   {
       uri:url, fileType: getExtension(url)
    }
  ]

    return (
      <>
       <StrictMode>
        <div className={styles.fillScreen}>
         <DocViewer  style={{width:"100%", height:"90%"}} pluginRenderers={DocViewerRenderers}  documents={docs}  />
        </div>
        </StrictMode>
      </>
    );
};

export default DocumentViewer;
