import React, { useContext } from 'react'
import { TranslationsContext } from "../../contexts/TranslationsContext";
import TableCell from "@material-ui/core/TableCell";

interface Props {
    showCPD: boolean
}

const CPDTitle = ({ showCPD }: Props) => {
    const {translations: { allResourcesToTranslations }} = useContext(TranslationsContext);
    
    if (!showCPD) return null;
    return (
        <TableCell align="center" className="cpdPoints">
            {allResourcesToTranslations.cpdpointsavaliable}
        </TableCell>
    )
}

export default CPDTitle
