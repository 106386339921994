import axios from 'axios';
import { addError } from '../AddError';

const GetOrganisationEmailDefaultTemplates = async (token: string, requestinguserId: number, orgDomain: string, orgId:number= -1
  ,selectedOrgID: number = -1, selectedOrgDomainName: string = "", selectOrgSiteName: string = ""
) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/SuperAdministration/GetOrgEmailDefaultTemplateList`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        "ReportRequestingUserID": requestinguserId,
        "OrganisationID": orgId,      
        "OrgDomainName": orgDomain,
        "SelectedOrgID": selectedOrgID,
        "SelectedOrgDomainName": selectedOrgDomainName, 
        "SelectOrgSiteName": selectOrgSiteName
    },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.response, "GetOrganisationEmailTemplates.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.request, "GetOrganisationEmailTemplates.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.message, "GetOrganisationEmailTemplates.ts", 1)
    }
    return null;
  }
};

export default GetOrganisationEmailDefaultTemplates;