import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const PersonalDataIcon = () => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 12.5926C7.33818 12.5926 2 13.9556 2 16.6667V20H18V16.6667C18 13.9556 12.6618 12.5926 10 12.5926Z" fill="white"/>
<path d="M13.622 7.7037C13.622 9.74815 12.0074 11.4074 10.0001 11.4074C7.99286 11.4074 6.36377 9.74815 6.36377 7.7037C6.36377 5.65926 7.99286 4 10.0001 4C12.0074 4 13.622 5.65926 13.622 7.7037Z" fill="white"/>
<path d="M16.2222 9.33332L17.9999 11.1111L21.5555 7.55554" stroke="white" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
</svg>

);

export default PersonalDataIcon
