export const get2DigitDayOrMonth = (givenDayOrMonthTxt) => {
    if (givenDayOrMonthTxt === null || get2DigitDayOrMonth === undefined){
        return "";
    }
    var given = givenDayOrMonthTxt.toString();
    if (given.length < 2) {
        return "0" + given;
    }
    return given;
}

export default get2DigitDayOrMonth