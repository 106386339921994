import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const SSOMSIcon = () => (
<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_67_132)">
<rect x="3" y="3" width="40" height="40" rx="8" fill="#63C2FF"/>
<path d="M28.6587 23.3959C30.3971 23.3959 32.1456 23.4364 33.884 23.3858C34.8745 23.3555 35.2586 23.6992 35.2384 24.7301C35.1777 27.742 35.1777 30.7538 35.2283 33.7657C35.2384 34.7259 34.9756 35.1201 33.975 34.9685C30.4577 34.4429 26.9506 33.9376 23.4233 33.4726C22.3721 33.3311 21.978 32.8763 22.0083 31.805C22.0689 29.4197 22.0588 27.0244 22.0083 24.629C21.9881 23.6688 22.3519 23.3252 23.3121 23.3454C25.0909 23.3959 26.8698 23.3555 28.6587 23.3555C28.6587 23.3656 28.6587 23.3757 28.6587 23.3858V23.3959Z" fill="white"/>
<path d="M28.507 22.4357C26.7686 22.4357 25.0201 22.3953 23.2817 22.4559C22.4024 22.4862 22.0183 22.2335 22.0385 21.2936C22.0789 18.8275 22.0688 16.3614 22.0385 13.8953C22.0385 13.1069 22.271 12.733 23.1402 12.6218C26.6979 12.1467 30.2454 11.601 33.7829 11.0451C34.8138 10.8834 35.2181 11.1259 35.1776 12.2781C35.0867 15.2496 35.1069 18.2211 35.1776 21.1824C35.1978 22.2335 34.8542 22.5064 33.8536 22.466C32.0748 22.3953 30.2959 22.4458 28.5171 22.4458L28.507 22.4357Z" fill="white"/>
<path d="M15.8833 23.4463C17.1972 23.4463 18.5212 23.4564 19.8352 23.4463C20.573 23.4463 20.9671 23.6585 20.957 24.5075C20.9166 27.0545 20.9267 29.6116 20.957 32.1585C20.957 32.9469 20.6841 33.149 19.9059 33.0176C17.3488 32.6134 14.7918 32.2596 12.2246 31.916C11.4261 31.8048 10.9713 31.5521 11.0016 30.6223C11.0623 28.5706 11.0623 26.5289 11.0016 24.4772C10.9713 23.5575 11.3655 23.3553 12.174 23.3857C13.4071 23.4362 14.6503 23.3958 15.8934 23.3958C15.8934 23.416 15.8934 23.4261 15.8934 23.4463H15.8833Z" fill="white"/>
<path d="M15.9441 22.4356C14.6706 22.4356 13.3871 22.4053 12.1136 22.4457C11.3353 22.466 11.0018 22.2436 11.022 21.3946C11.0725 19.3833 11.0725 17.372 11.022 15.3607C11.0018 14.5724 11.2747 14.2692 12.0428 14.1681C14.7616 13.8144 17.4703 13.4101 20.1891 12.9957C20.8258 12.8946 21.0381 13.1574 21.028 13.7436C21.028 16.331 21.028 18.9285 21.028 21.5159C21.028 22.1324 20.7854 22.4154 20.1284 22.3952C18.7337 22.3548 17.3389 22.3851 15.9441 22.3851C15.9441 22.3952 15.9441 22.4053 15.9441 22.4154V22.4356Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_67_132" x="0" y="0" width="50" height="50" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="2"/>
<feGaussianBlur stdDeviation="2.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_67_132"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_67_132" result="shape"/>
</filter>
</defs>
</svg>


);

export default SSOMSIcon