import React, { useState, useEffect, useContext } from "react";

//Styles
import styles from "../../../styles/organisationmanagement.module.css";

import { useHistory, useLocation, useParams } from "react-router";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { UIContext } from "../../../contexts/UIContext";
import LoaderContainer from "../../../Components/LoaderContainer";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import BrandedCard from "../../../Components/BrandedCard";
import { Steps } from "intro.js-react";
import CustomTooltip from "../../../Components/CustomTooltip";
import GuideIcon from "../../../CustomIcons/GuideIcon";
import { Button, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@material-ui/core";
import UnbrandedCard from "../../../Components/UnbrandedCard";
import getColor from "../../../functions/getColor";
import { CorperateThemeContext } from "../../../contexts/CorperateThemeContext";
import UpArrowIcon from "../../../CustomIcons/UpArrowIcon";
import DownArrowIcon from "../../../CustomIcons/DownArrowIcon";
import EditIcon from "../../../CustomIcons/SupportDetailsEditIcon"; 
import { Policies } from "../../../globalTypes";
import GetPolicyBuilderList from "../../../API/Administration/PolicyBuilder/GetPolicyBuilderList";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";
import LinearProgressWithLabel from "../../../Components/LinearProgressBar";
import HardDiskIcon from "../../../CustomIcons/HardDiskIcon";
import LinearProgressBarNoLabel from "../../../Components/LinearProgressBarNoLabel";

const PolicyBuilderList = () => {
  const history = useHistory();
  const {
    translations: { allResourcesToTranslations }, pageTitles
  } = useContext(TranslationsContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute } =
    useContext(UIContext);
    const { oidcUser } = useReactOidc();
    const {
      profile: { user_id, website },
    } = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const orgDomain = website
      ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
      : "";
  const [loading, setLoading] = useState<boolean>(true);
  const { theme: { color } } = useContext(CorperateThemeContext);
  const [policyResult, setPolicyResult] = useState<Array<Policies>>([]);

  const [currentSortBy, setCurrentSortBy] = React.useState<string>("");
  const [availableDiskSpace, setAvailableDiskSpace] = React.useState<string>("");
  const [totalDiskSpace, setTotalDiskSpace] = React.useState<string>("");
  const [availableDiskSpaceInt, setAvailableDiskSpaceInt] = React.useState<number>(0);
  const [totalDiskSpaceInt, setTotalDiskSpaceInt] = React.useState<number>(0);
  const [reverseNextSort, setReverseNextSort] = React.useState<boolean>(false)
  const [sortInProgress, setSortInProgress] = React.useState<boolean>(false)
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);
  
  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true);
  const [policyNameDescending, setPolicyNameDescending] = React.useState<boolean>(false);
  const [fileNameDescending, setFileNameDescending] = React.useState<boolean>(false);  
  const [activeDescending, setActiveDescending] = React.useState<boolean>(false); 
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [showConfirmModal, setshowConfirmModal] = useState<boolean>(false);  

  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element:  ".addNewPolicy",
        title: "Add New", intro: allResourcesToTranslations.addpolicyguideme 
      }
    ]
});


const setGuideMe = () => {
  let updateSteps = [...intro.steps];
      updateSteps.push(        
      {
        element:  ".policynamecolumnguideme",
        title: allResourcesToTranslations.policyname, intro: allResourcesToTranslations.policynamecolumnguideme
      },      
      {
        element:  ".filenameguideme",
        title: allResourcesToTranslations.filename, intro: allResourcesToTranslations.filenamesizeguideme
      },
      {
        element:  ".activeguideme",
        title: allResourcesToTranslations.active, intro: allResourcesToTranslations.activecolumnguideme
      },
      {
        element:  ".actioncolumnguideme0",
        title: "Edit Policy", intro: allResourcesToTranslations.actioncolumnguideme
      });
  
  
  setIntro({ ...intro, steps: [...updateSteps] });
  };  
  const handleClosePopup = () => {
		setShowWarningModal(false);
	};
 
 const onExit = () => {
 setIntro({ ...intro, stepsEnabled: false });
 };
  
  useEffect(() => {
    setTitle(allResourcesToTranslations.policybuilder);
		setTitleEnglish(pageTitles.policybuilder);
    updateBackButtonRoute("");   
    LoadPolicies();
  }, []);

  const LoadPolicies = () => {
    GetPolicyBuilderList(oidcUser.access_token,
      userId,
      orgDomain,
      parseInt(orgId),"en-au").then((result:any) => {       
        if (result.isSuccess && result.error == null) {
          setPolicyResult([...result.policyBuilderItems]);   
          setAvailableDiskSpace(result.totalAllotedStorage);
          setTotalDiskSpace(result.totalOrgAvailableStorage);
          setAvailableDiskSpaceInt(result.intTotalAllotedStorage);
          setTotalDiskSpaceInt(result.intTotalOrgAvailableStorage);
          setLoading(false);
          setGuideMe();    
        } else {
          setShowWarningModal(true);
          setHasErrored(true);
          setSaveSuccess(false);
          setModalPopupMessage('Getting Policy builder list has failed! Please try again!');
        }
      });
  }

  const handleEdit = (policyId: number) => {
    history.push({
      pathname: `policybuilderdetails/`+ policyId.toString()
  });
};

  const handleAddNewPolicy = () => {
    history.push({
      pathname: `policybuilderdetails/-1` 
  });
  }
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const sortResultsByAsync = async (sortBy: 
    "PolicyName" 
  | "FileName"
  | "Active" , sortGivenItemInDesc: boolean) => {
    if (policyResult == null || policyResult.length <= 0) {
      return policyResult;
    }
    var result = policyResult.slice(0)
    setCurrentSortBy(sortBy)
    switch (sortBy) {
  
      case "PolicyName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.policyName === null) { return -1; }
            if (a === null || a.policyName === null) { return 1; }
            if (a.policyName.toLowerCase() > b.policyName.toLowerCase()) { return -1; }
            if (a.policyName.toLowerCase() < b.policyName.toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setPolicyNameDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.policyName === null) { return 1; }
            if (a === null || a.policyName === null) { return -1; }
            if (a.policyName.toLowerCase() < b.policyName.toLowerCase()) { return -1; }
            if (a.policyName.toLowerCase() > b.policyName.toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setPolicyNameDescending(true)
          }
        }
        setPolicyResult(result);
        break;
     
      case "FileName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.policyFileName === null) { return -1; }
            if (a === null || a.policyFileName === null) { return 1; }
            if (a.policyFileName.toLowerCase() > b.policyFileName.toLowerCase()) { return -1; }
            if (a.policyFileName.toLowerCase() < b.policyFileName.toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setFileNameDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.policyFileName === null) { return 1; }
            if (a === null || a.policyFileName === null) { return -1; }
            if (a.policyFileName.toLowerCase() < b.policyFileName.toLowerCase()) { return -1; }
            if (a.policyFileName.toLowerCase() > b.policyFileName.toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setFileNameDescending(true)
          }
        }
        setPolicyResult(result);
        break;
      
      case "Active":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.active === null) { return -1; }
            if (a === null || a.active === null) { return 1; }
            if (a.active.toString().toLowerCase() > b.active.toString().toLowerCase()) { return -1; }
            if (a.active.toString().toLowerCase() < b.active.toString().toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setActiveDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.active === null) { return 1; }
            if (a === null || a.active === null) { return -1; }
            if (a.active.toString().toLowerCase() < b.active.toString().toLowerCase()) { return -1; }
            if (a.active.toString().toLowerCase() > b.active.toString().toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setActiveDescending(true)
          }
        }
      setPolicyResult(result);
      break;
         
      default:
        break;
    }
    return result.slice(0);
  }
  
  const sortResultsBy = async (sortBy:   "PolicyName" 
  | "FileName"
  | "Active", sortGivenItemInDesc: boolean) => {
    setSortInProgress(true);
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy)
      setPolicyResult(result)
      setPage(0)
      setSortInProgress(false);
    });
  }
  
  const clickPolicyNameAsc = () => {
    setReverseNextSort(false);
    setPolicyNameDescending(false);
    sortResultsBy("PolicyName", false);
  }
  
  const clickPolicyNameDesc = () => {
    setReverseNextSort(false);
    setPolicyNameDescending(true)
    sortResultsBy("PolicyName", true)
  }

  const clickFileNameAsc = () => {
    setReverseNextSort(false);
    setFileNameDescending(false);
    sortResultsBy("FileName", false);
  }
  
  const clickFileNameDesc = () => {
    setReverseNextSort(false);
    setFileNameDescending(true)
    sortResultsBy("FileName", true)
  }

  const clickActiveAsc = () => {
    setReverseNextSort(false);
    setActiveDescending(false);
    sortResultsBy("Active", false);
  }
  
  const clickActiveDesc = () => {
    setReverseNextSort(false);
    setActiveDescending(true)
    sortResultsBy("Active", true)
  }
  
  

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" }
    } else {
      return {}
    }
  }

  const handleConfirmModal = (value: boolean) => {
		setshowConfirmModal(value);
	}
  
  if (loading) return <LoaderContainer />;  
  return ( 
   <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }}/>
      <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific}>
          <GuideIcon />
        </span>
      </CustomTooltip>   

      <UnbrandedCard>
      <div className={styles.mainWrapper}>
					<div className={styles.Wrapper}>             
            <div style={{ width: '100%' }} >
              <div style={{float:"left"}}>
                <CustomTooltip displayText={[allResourcesToTranslations.addnewpolicy]}>               
                    <Button                    
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={`saveData addNewPolicy`}
                        onClick={handleAddNewPolicy}                     
                      >
                        <div style={{marginBottom: "-5px"}}>
                          <EditIcon/>
                        </div><span style={{marginLeft: "5px"}}> {allResourcesToTranslations.addnewpolicy}</span>
                      </Button>                     
                </CustomTooltip>
              </div>
              {/* <div style={{float:"right"}}>
              <span className={styles.labelText}>Available Disk Space:</span> {availableDiskSpace}
              </div> */}
            </div>

            <div style={{ width: '100%', clear:"both" }} >
            {policyResult != null && policyResult.length > 0 && (
              <div>              

                <div className={styles.paginationControlsContainer}>
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 20, 100]}
                      component="div"
                      count={policyResult.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
                <div  className={`${styles.tableSection}`}>
                  <div className={styles.tableContainer}>
                    <BrandedCard>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>                              

                              <TableCell
                                style={{
                                  color: getColor(color.substring(1)),
                                  backgroundColor: color,
                                  borderRadius: "8px 0 0 0",
                                }}   className="policynamecolumnguideme"
                                align="left"
                              >
                                <div className={styles.tableHeadCellContainer}>
                                  <div
                                    onClick={() => {
                                      setPolicyNameDescending(!policyNameDescending);
                                      sortResultsBy("PolicyName", !policyNameDescending);
                                    }}
                                  >
                                  {allResourcesToTranslations.policyname}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "PolicyName" &&
                                          policyNameDescending === false
                                            ? "100%"
                                            : "50%",
                                        margin: "2px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clickPolicyNameAsc}
                                    >
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "PolicyName" &&
                                          policyNameDescending === true
                                            ? "100%"
                                            : "50%",
                                        margin: "0px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clickPolicyNameDesc}
                                    >
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  color: getColor(color.substring(1)),
                                  backgroundColor: color,
                                }} className="filenameguideme"
                                align="left"
                              >
                                <div className={styles.tableHeadCellContainer}>
                                <div
                                    onClick={() => {
                                      setFileNameDescending(!fileNameDescending);
                                      sortResultsBy("FileName", !fileNameDescending);
                                    }}
                                  >
                                  {allResourcesToTranslations.filename}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "FileName" &&
                                          fileNameDescending === false
                                            ? "100%"
                                            : "50%",
                                        margin: "2px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clickFileNameAsc}
                                    >
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "FileName" &&
                                          fileNameDescending === true
                                            ? "100%"
                                            : "50%",
                                        margin: "0px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clickFileNameDesc}
                                    >
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                             
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  color: getColor(color.substring(1)),
                                  backgroundColor: color,
                                }} className="activeguideme"
                                align="left"
                              >
                                <div className={styles.tableHeadCellContainer}>
                                <div
                                    onClick={() => {
                                      setActiveDescending(!activeDescending);
                                      sortResultsBy("Active", !activeDescending);
                                    }}
                                  >
                                  {allResourcesToTranslations.active}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "Active" &&
                                          activeDescending === false
                                            ? "100%"
                                            : "50%",
                                        margin: "2px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clickActiveAsc}
                                    >
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "Active" &&
                                          activeDescending === true
                                            ? "100%"
                                            : "50%",
                                        margin: "0px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clickActiveDesc}
                                    >
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                             
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "200px",
                                  color: getColor(color.substring(1)),
                                  backgroundColor: color,
                                  borderRadius: "0 8px 0 0",
                                }}
                                align="center"
                              >
                                <div className={styles.tableHeadCellContainer}>
                                  <div>
                                    {allResourcesToTranslations.actions}
                                  </div>                              
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {policyResult
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((result: any, i) => {
                                return (
                                  <TableRow
                                    key={`policybuilder-${i}`}
                                    style={rowBackgroundColor(i)}
                                  >
                                    <TableCell align="left" width={"40%"}>
                                      {result.policyName}
                                    </TableCell>

                                    <TableCell align="left" className="dateEnabledToggele">
                                      <a href={`${result.strPolicyLink}`} target="_blank">
                                      {`${result.policyFileName}` + ` (` + `${result.policyFileSize})`}</a>
                                    </TableCell>
                                    <TableCell align="center" className="dateEnabledToggele">
                                    <Checkbox
																									size="small"
																									color="primary"
																									checked={result.active}
																									className={styles.checkBox}			
                                                  disabled																						
																								/>
                                    </TableCell>
                                    <TableCell align="center" className="dateEnabledToggele">
                                      <CustomTooltip displayText={["Edit"]}>
                                          <Button onClick={() => handleEdit(result.policyId)} className={`${styles.moduleListButton} actioncolumnguideme${i}`} variant="contained" color="primary">
                                              <EditIcon />
                                          </Button>
                                      </CustomTooltip>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </BrandedCard>
                  </div>
                </div>
                <div className={styles.paginationAddedUsersControl}>
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 20, 100]}
                      component="div"
                      count={policyResult.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                </div>               
              </div>
            )}
          </div>
          
        </div>
      </div>
      </UnbrandedCard>
      <UnbrandedCard>
      <div className={styles.mainWrapper}>
          <div className={styles.Wrapper}>
                <div className={styles.inputContainer} style={{ width: '50%', float: 'left' }}>
                  <div style={{float: 'left', marginBottom: '10px'}}> <HardDiskIcon/> </div>
                  <div style={{ paddingTop: '7px', paddingLeft: '30px' }}><span className={styles.labelText}> {allResourcesToTranslations.remainingdiskspace}</span></div>
                </div>
                <div className={styles.inputContainer} style={{ width: '45%', float: 'right', textAlign: 'right', paddingTop: '7px', paddingRight: '20px'}}>
                  <span className={styles.labelText}> {totalDiskSpace} Available</span>
                  <span> | {availableDiskSpace} Total</span>
                </div>
                 <div  style={{ width: '100%', clear: "both" }}>
                    <LinearProgressBarNoLabel value={Math.round((availableDiskSpaceInt - totalDiskSpaceInt) * 100 /availableDiskSpaceInt)} />
                </div>
              </div>    
      </div>
      </UnbrandedCard>     
      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}      
      </>
  );
};

export default PolicyBuilderList;
