import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
require("highcharts/modules/exporting")(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

//Example : How to add barchart in page
//import BarChart from "../../Components/HighChart/PieChartComponent";
/* <PieChart categoriesArray={categoriesArray} seriesData={seriesPieData} ChartTitle={"License Report"} 
            seriesName={"License Quota"} primarycolor ={"#63c2ff"} secondarycolor={"#21394f"}   />*/

export const PieChartComponent = ({seriesData, ChartTitle,seriesName,innerSize, themecolor }) => {

Highcharts.setOptions({
  //colors: [themecolor, '#21394f', '#63c2ff']
  colors: ['#20B786', '#21394f'],
  font:['Open Sans sans-serif']
  });
  
  const pieChart = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
     title: {
       text: ChartTitle,
       align: 'center',
       enabled: false
     },
    credits: {
    //text: 'Example.com',
     //href: 'http://www.example.com',
     enabled: false
     },      
     legend: {
      align: 'center',
      x:0,
      verticalAlign: 'top',
      y: 0,
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: true,
      font: Highcharts.defaultOptions.font
  },
  tooltip: {
      pointFormat: '<b>Total:{point.y}</b>'
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        size: 200,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.0f}%'
        },      
        showInLegend: true
      }
    },
  series: [{     
        innerSize: innerSize,
        name: seriesName,      
        colorByPoint: true,
        data: seriesData       
  }]
      
      // series: [{    
      //     data: [
      //                 {
      //                 name: 'Chrome',
      //                 y: 14.77
      //                 }, {
      //                 name: 'Edge',
      //                 y: 14.77
      //                 }
      //             ]
      //   }]
    };
  return( 
  <>
  <HighchartsReact highcharts={Highcharts} options={pieChart} />
  </>
  )

}

export default PieChartComponent;
