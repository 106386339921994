import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const TipVideosEditButtonIcon = () => (
<svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_113_3400)">
<circle cx="37.5" cy="33.5" r="33.5" fill="#21394F"/>
<path d="M36.4167 21.3333H25.3333C24.4935 21.3333 23.688 21.6669 23.0942 22.2608C22.5003 22.8547 22.1667 23.6601 22.1667 24.5V46.6666C22.1667 47.5065 22.5003 48.312 23.0942 48.9058C23.688 49.4997 24.4935 49.8333 25.3333 49.8333H47.5C48.3398 49.8333 49.1453 49.4997 49.7392 48.9058C50.333 48.312 50.6667 47.5065 50.6667 46.6666V35.5833" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M48.2917 18.9583C48.9215 18.3284 49.7759 17.9745 50.6667 17.9745C51.5575 17.9745 52.4118 18.3284 53.0417 18.9583C53.6715 19.5882 54.0254 20.4425 54.0254 21.3333C54.0254 22.2241 53.6715 23.0784 53.0417 23.7083L38 38.75L31.6667 40.3333L33.25 34L48.2917 18.9583Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<filter id="filter0_d_113_3400" x="0" y="0" width="75" height="75" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_113_3400"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_113_3400" result="shape"/>
</filter>
</defs>
</svg>


);

export default TipVideosEditButtonIcon