import React, { useContext } from 'react'
import LoaderMessage from './LoaderMessage'
import LoaderAnimation from './LoaderAnimation';
import styles from '../styles/loader.module.css';
import { CorperateThemeContext } from '../contexts/CorperateThemeContext';

export const LoaderContainer = () => {
    const { color } = useContext(CorperateThemeContext).theme;

    return (
        <div className={styles.loaderBox}>
            <div>
                <LoaderAnimation color={color}/>
                <LoaderMessage/>
            </div>    
        </div>
    )
}

export default LoaderContainer;
