import React from 'react';

export const EmailPreviewIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M2.88251 1.53906H18.9261C20.3562 1.53906 21.5196 2.8243 21.5193 4.40422V10.9612C21.0063 10.5716 20.438 10.2511 19.8281 10.0137V4.45717L11.4268 10.4255C11.4203 10.4299 11.4135 10.4336 11.4067 10.4373C11.4017 10.44 11.3967 10.4427 11.3918 10.4457C11.3797 10.4532 11.3673 10.4603 11.3549 10.4672C11.2895 10.5043 11.2226 10.5342 11.1541 10.5538C11.1501 10.555 11.1461 10.5559 11.142 10.5567C11.139 10.5573 11.136 10.558 11.133 10.5588C11.0577 10.5787 10.9816 10.5906 10.9054 10.5906H10.9037C10.8276 10.5906 10.7515 10.5784 10.6762 10.5588C10.6732 10.558 10.6702 10.5573 10.6671 10.5567C10.6631 10.5559 10.6591 10.555 10.6551 10.5538C10.5865 10.5342 10.5194 10.5043 10.4543 10.4672C10.4419 10.4603 10.4295 10.4532 10.4174 10.4457C10.4125 10.4427 10.4074 10.44 10.4024 10.4373C10.3957 10.4336 10.3889 10.4299 10.3824 10.4255L1.98107 4.45717V14.597C1.98107 15.1459 2.38541 15.5929 2.88251 15.5929H10.3527C10.3109 15.9023 10.2893 16.2182 10.2893 16.5391C10.2893 16.8518 10.3098 17.1597 10.3496 17.4616H2.88251C1.45268 17.4616 0.289307 16.1763 0.289307 14.5967V4.4039C0.289307 2.8243 1.45268 1.53906 2.88251 1.53906ZM10.9043 8.2157L17.659 3.40805H4.14964L10.9043 8.2157Z" fill="#FFFFFF"/>
<g clipPath="url(#clip0_201_66)">
<path fillRule="evenodd" clipRule="evenodd" d="M21.8617 22.2344L23.4045 20.6915L20.7927 18.0797C21.3028 17.2875 21.603 16.3461 21.603 15.3336C21.603 12.5214 19.316 10.2344 16.5037 10.2344C13.6915 10.2344 11.4045 12.5214 11.4045 15.3336C11.4045 18.1458 13.6915 20.4328 16.5037 20.4328C17.5163 20.4328 18.4577 20.1326 19.2499 19.6225L21.8617 22.2344ZM19.7048 18.7375L19.7173 18.7246L19.7177 18.7242C19.7275 18.7141 19.7374 18.7041 19.7475 18.6944C19.7329 18.7084 19.7189 18.723 19.7048 18.7375ZM19.6486 18.7933C19.6541 18.7883 19.6595 18.7832 19.6648 18.778C19.6785 18.7648 19.6917 18.7512 19.7048 18.7375C19.6865 18.7565 19.6682 18.7755 19.6486 18.7933ZM19.748 18.6939L19.7481 18.6938C19.7871 18.6561 19.8254 18.6178 19.8631 18.5788L19.8644 18.5775L19.8644 18.5775C19.8729 18.5687 19.8817 18.56 19.8905 18.5514L19.8924 18.5496L19.9064 18.536L19.9064 18.536C19.9205 18.5224 19.9346 18.5087 19.9482 18.4947C19.9534 18.4893 19.9585 18.4839 19.9635 18.4784M19.748 18.6939C19.7875 18.6558 19.8262 18.617 19.8644 18.5775C19.8781 18.5634 19.8922 18.5497 19.9064 18.536M12.6959 15.3336C12.6959 13.2342 14.4044 11.5258 16.5037 11.5258C18.6031 11.5258 20.3116 13.2342 20.3116 15.3336C20.3116 17.4329 18.6031 19.1414 16.5037 19.1414C14.4044 19.1414 12.6959 17.4329 12.6959 15.3336Z" fill="#FFFFFF"/>
</g>
<defs>
<clipPath id="clip0_201_66">
<rect width="12" height="12" fill="white" transform="translate(11.4045 10.2344)"/>
</clipPath>
</defs>
</svg>

);

export default EmailPreviewIcon
