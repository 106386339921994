import React, { useEffect, useContext } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context';
import Button from '@material-ui/core/Button'
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext'
import styles from '../../styles/ssoExitPage.module.css'
import LogoutCard from './LogoutCard'


export const SSOExitPage = () => {
    const { backgroundUri } = useContext(CorperateThemeContext).theme
    const {  logout } = useReactOidc();
    
    useEffect(() => {
        return () => {
            logout(); //Logs user out if they try and go anywhere else
        }
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={styles.logoutContainer} style={{ backgroundImage: `url(${backgroundUri})` }}>
            <LogoutCard>
                <div className={styles.logoutModalContent}>
                    <h3>You have logged out</h3> 
                    <div>
                        <Button 
                            style={{ marginLeft: "0px" }}
                            onClick={ () => logout() }
                            variant="contained" color="primary">GO TO SSO LOGIN</Button>
                    </div>
                </div>
            </LogoutCard> 
        </div>
    )
}

export default SSOExitPage;
