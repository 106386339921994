import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const OrgSettingsIconMenu = () => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 22L12 22L3 22L3 4C3 3.46957 3.21072 2.96086 3.58579 2.58579C3.96086 2.21071 4.46957 2 5 2L19 2C19.5304 2 20.0391 2.21071 20.4142 2.58579C20.7893 2.96086 21 3.46957 21 4L21 22Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 22V12H15V22" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    
);

export default OrgSettingsIconMenu
