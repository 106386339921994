import React, { useEffect, useContext } from "react";
import { Link, useHistory } from 'react-router-dom';
import BrandedCard from "../../../Components/BrandedCard";

//Styles
import styles from "../../../styles/addAccount.module.css";
import {
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
} from "@material-ui/core";
import getColor from "../../../functions/getColor";
import { Checkbox } from "@material-ui/core";
import { CorperateThemeContext } from "../../../contexts/CorperateThemeContext";
import UpArrowIcon from "../../../CustomIcons/UpArrowIcon";
import DownArrowIcon from "../../../CustomIcons/DownArrowIcon";
import ActiveIcon from "../../../CustomIcons/Status_ActiveIcon";
import InActiveIcon from "../../../CustomIcons/Status_InActiveIcon";
import AssignedByUnitIcon from "../../../CustomIcons/Administration/AssignedByUnitIcon";
import AssignedByUserIcon from "../../../CustomIcons/Administration/AssignedByUserIcon";
import getOneUserDetails from "../../../API/Administration/Users/GetOneUserDetails";
import GetProfileUsers from "../../../API/Administration/CPDProfiles/GetProfileUsers";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import CustomTooltip from "../../../Components/CustomTooltip";
import LoaderContainer from "../../../Components/LoaderContainer";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import PropTypes from "prop-types";
import UnbrandedCard from "../../../Components/UnbrandedCard";

interface UserListSectionProps {
  usersData: any;
  isCalledFrom: string;
  setUpdatedSelectedUsers: Function;
  needLatestUsers: boolean;
  profileID: number;
  profilePeriodID: number;
  selectedUnitIDs: Array<number>;
  allUnitIDs: Array<number>;
  filterFirstName: string,
  filterLastName: string,
  filterUserName: string,
  filterEmail: string
}
const UserListSection = ({ usersData, isCalledFrom = "AccountManagement", setUpdatedSelectedUsers, needLatestUsers = false, 
                            profileID = -1, profilePeriodID = -1, selectedUnitIDs = [], allUnitIDs = [], 
                            filterFirstName = "", filterLastName = "", filterUserName = "", filterEmail = ""}: UserListSectionProps) => {
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
      .toLowerCase()
      .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";
    const {
      translations: { allResourcesToTranslations },
  } = useContext(TranslationsContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true);
  const [firstNameDescending, setFirstNameDescending] =
    React.useState<boolean>(false);
  const [lastNameDescending, setLastNameDescending] =
    React.useState<boolean>(false);
  const [statusDescending, setStatusDescending] =
    React.useState<boolean>(false);
  const [emailDescending, setEmailDescending] = React.useState<boolean>(false);
  const [userIDDescending, setUserIDDescending] = React.useState<boolean>(false);
  const [lastLoginDescending, setLastLoginDescending] = React.useState<boolean>(false);
  const [assignTypeDescending, setAssignTypeDescending] = React.useState<boolean>(false);
  const [currentSortBy, setCurrentSortBy] = React.useState<string>("");
  const [unitPathwayDescending, setUnitPathwayDescending] = React.useState<boolean>(false);
  const [reverseNextSort, setReverseNextSort] = React.useState<boolean>(false);
  const [sortInProgress, setSortInProgress] = React.useState<boolean>(false);
  const [assignAll, setAssignAll] = React.useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);
  const [usersList, setUsersList] = React.useState<Array<any>>(usersData);
  const history = useHistory();

  const {
    theme: { color },
  } = useContext(CorperateThemeContext);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
    } else {
      return {};
    }
  };

  const sortResultsByAsync = async (
    sortBy: "FirstName" | "LastName" | "Status" | "Email" | "UnitPathway" | "UserID" | "LastLogin" | "AssignType",
    sortGivenItemInDesc: boolean
  ) => {
    if (usersList == null || usersList.length <= 0) {
      return usersList;
    }
    var result = usersList.slice(0);
    setCurrentSortBy(sortBy);
    switch (sortBy) {

      case "UnitPathway":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return -1;
            }
            if (a === null || a.email === null) {
              return 1;
            }
            if (a.unitPathway.toLowerCase() > b.unitPathway.toLowerCase()) {
              return -1;
            }
            if (a.unitPathway.toLowerCase() < b.unitPathway.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitPathwayDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return 1;
            }
            if (a === null || a.email === null) {
              return -1;
            }
            if (a.unitPathway.toLowerCase() < b.unitPathway.toLowerCase()) {
              return -1;
            }
            if (a.unitPathway.toLowerCase() > b.unitPathway.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitPathwayDescending(true);
          }
        }
        setUsersList(result);
        break;
      case "Email":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return -1;
            }
            if (a === null || a.email === null) {
              return 1;
            }
            if (a.email.toLowerCase() > b.email.toLowerCase()) {
              return -1;
            }
            if (a.email.toLowerCase() < b.email.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setEmailDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return 1;
            }
            if (a === null || a.email === null) {
              return -1;
            }
            if (a.email.toLowerCase() < b.email.toLowerCase()) {
              return -1;
            }
            if (a.email.toLowerCase() > b.email.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setEmailDescending(true);
          }
        }
        setUsersList(result);
        break;
      case "FirstName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.firstName === null) {
              return -1;
            }
            if (a === null || a.firstName === null) {
              return 1;
            }
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
              return -1;
            }
            if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.firstName === null) {
              return 1;
            }
            if (a === null || a.firstName === null) {
              return -1;
            }
            if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
              return -1;
            }
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstNameDescending(true);
          }
        }
        setUsersList(result);
        break;
      case "LastName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) {
              return -1;
            }
            if (a === null || a.lastName === null) {
              return 1;
            }
            if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
              return -1;
            }
            if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) {
              return 1;
            }
            if (a === null || a.lastName === null) {
              return -1;
            }
            if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
              return -1;
            }
            if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastNameDescending(true);
          }
        }
        setUsersList(result);
        break;
      case "Status":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.active === null) {
              return -1;
            }
            if (a === null || a.active === null) {
              return 1;
            }
            if (a.active.toString().toLowerCase() > b.active.toString().toLowerCase()) {
              return -1;
            }
            if (a.active.toString().toLowerCase() < b.active.toString().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setStatusDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.active === null) {
              return 1;
            }
            if (a === null || a.active === null) {
              return -1;
            }
            if (a.active.toString().toLowerCase() < b.active.toString().toLowerCase()) {
              return -1;
            }
            if (a.active.toString().toLowerCase() > b.active.toString().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setStatusDescending(true);
          }
        }
        setUsersList(result);
        break;
      case "UserID":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.userID === null) {
              return -1;
            }
            if (a === null || a.userID === null) {
              return 1;
            }
            if (a.userID > b.userID) {
              return -1;
            }
            if (a.userID < b.userID) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUserIDDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.userID === null) {
              return 1;
            }
            if (a === null || a.userID === null) {
              return -1;
            }
            if (a.userID < b.userID) {
              return -1;
            }
            if (a.userID > b.userID) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUserIDDescending(true);
          }
        }
        setUsersList(result);
        break;
      case "LastLogin":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.lastLoginDate === null || b.lastLoginDate.trimLeft().trimRight() === "") { return 1; }
            if (a === null || a.lastLoginDate === null || a.lastLoginDate.trimLeft().trimRight() === "") { return -1; }
            let maintoken1 = a.lastLoginDate.split(" ");
            let timetoken1 = maintoken1[1].split(":");
            let tokens1 = maintoken1[0].split("/");
            let aDate = new Date(parseInt(tokens1[2].trimLeft().trimRight()), parseInt(tokens1[1].trimLeft().trimRight()) - 1, parseInt(tokens1[0].trimLeft().trimRight()), parseInt(timetoken1[0].trimLeft().trimRight()), parseInt(timetoken1[1].trimLeft().trimRight()));

            let maintoken2 = b.lastLoginDate.split(" ");
            let timetoken2 = maintoken2[1].split(":");
            let tokens2 = maintoken2[0].split("/");
            let bDate = new Date(parseInt(tokens2[2].trimLeft().trimRight()), parseInt(tokens2[1].trimLeft().trimRight()) - 1, parseInt(tokens2[0].trimLeft().trimRight()), parseInt(timetoken2[0].trimLeft().trimRight()), parseInt(timetoken2[1].trimLeft().trimRight()));
            if (aDate > bDate) { return -1; }
            if (aDate < bDate) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setLastLoginDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (a === null || a.lastLoginDate === null || a.lastLoginDate.trimLeft().trimRight() === "") { return 1; }
            if (b === null || b.lastLoginDate === null || b.lastLoginDate.trimLeft().trimRight() === "") { return -1; }
            let maintoken1 = a.lastLoginDate.split(" ");
            let timetoken1 = maintoken1[1].split(":");
            let tokens1 = maintoken1[0].split("/");
            let aDate = new Date(parseInt(tokens1[2].trimLeft().trimRight()), parseInt(tokens1[1].trimLeft().trimRight()) - 1, parseInt(tokens1[0].trimLeft().trimRight()), parseInt(timetoken1[0].trimLeft().trimRight()), parseInt(timetoken1[1].trimLeft().trimRight()));

            let maintoken2 = b.lastLoginDate.split(" ");
            let timetoken2 = maintoken2[1].split(":");
            let tokens2 = maintoken2[0].split("/");
            let bDate = new Date(parseInt(tokens2[2].trimLeft().trimRight()), parseInt(tokens2[1].trimLeft().trimRight()) - 1, parseInt(tokens2[0].trimLeft().trimRight()), parseInt(timetoken2[0].trimLeft().trimRight()), parseInt(timetoken2[1].trimLeft().trimRight()));
            if (aDate < bDate) { return -1; }
            if (aDate > bDate) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setLastLoginDescending(true)
          }
        }
        setUsersList(result);
        break;

        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) {
              return -1;
            }
            if (a === null || a.lastName === null) {
              return 1;
            }
            if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
              return -1;
            }
            if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) {
              return 1;
            }
            if (a === null || a.lastName === null) {
              return -1;
            }
            if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
              return -1;
            }
            if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastNameDescending(true);
          }
        }
        setUsersList(result);
        break;
      case "AssignType":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.onlyAccountGranted === null) {
              return -1;
            }
            if (a === null || a.onlyAccountGranted === null) {
              return 1;
            }
            if (a.onlyAccountGranted.toString().toLowerCase() > b.onlyAccountGranted.toString().toLowerCase()) {
              return -1;
            }
            if (a.onlyAccountGranted.toString().toLowerCase() < b.onlyAccountGranted.toString().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setAssignTypeDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.onlyAccountGranted === null) {
              return 1;
            }
            if (a === null || a.onlyAccountGranted === null) {
              return -1;
            }
            if (a.onlyAccountGranted.toString().toLowerCase() < b.onlyAccountGranted.toString().toLowerCase()) {
              return -1;
            }
            if (a.onlyAccountGranted.toString().toLowerCase() > b.onlyAccountGranted.toString().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setAssignTypeDescending(true);
          }
        }
        setUsersList(result);
        break;
      default:
        break;
    }
    return result.slice(0);
  };

  const sortResultsBy = async (
    sortBy: "FirstName" | "LastName" | "Status" | "Email" | "UnitPathway" | "UserID" | "LastLogin" | "AssignType",
    sortGivenItemInDesc: boolean
  ) => {
    setSortInProgress(true);
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy);
      setUsersList(result);
      setPage(0);
      setSortInProgress(false);
    });
  };

  const clickFirstNameAsc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(false);
    sortResultsBy("FirstName", false);
  };

  const clickFirstNameDesc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(true);
    sortResultsBy("FirstName", true);
  };

  const clickLastNameAsc = () => {
    setReverseNextSort(false);
    setLastNameDescending(false);
    sortResultsBy("LastName", false);
  };

  const clickLastNameDesc = () => {
    setReverseNextSort(false);
    setLastNameDescending(true);
    sortResultsBy("LastName", true);
  };

  const clickStatusAsc = () => {
    setReverseNextSort(false);
    setStatusDescending(false);
    sortResultsBy("Status", false);
  };

  const clickStatusDesc = () => {
    setReverseNextSort(false);
    setStatusDescending(true);
    sortResultsBy("Status", true);
  };

  const clickUnitPathwayAsc = () => {
    setReverseNextSort(false);
    setUnitPathwayDescending(false);
    sortResultsBy("UnitPathway", false);
  };

  const clickUnitPathwayDesc = () => {
    setReverseNextSort(false);
    setUnitPathwayDescending(true);
    sortResultsBy("UnitPathway", true);
  };

  const clickEmailAsc = () => {
    setReverseNextSort(false);
    setEmailDescending(false);
    sortResultsBy("Email", false);
  };

  const clickEmailDesc = () => {
    setReverseNextSort(false);
    setEmailDescending(true);
    sortResultsBy("Email", true);
  };

  const clickUserIDAsc = () => {
    setReverseNextSort(false);
    setUserIDDescending(false);
    sortResultsBy("UserID", false);
  };

  const clickUserIDDesc = () => {
    setReverseNextSort(false);
    setUserIDDescending(true);
    sortResultsBy("UserID", true);
  };


  const clickLastLoginAsc = () => {
    setReverseNextSort(false);
    setLastLoginDescending(false);
    sortResultsBy("LastLogin", false);
  };

  const clickLastLoginDesc = () => {
    setReverseNextSort(false);
    setLastLoginDescending(true);
    sortResultsBy("LastLogin", true);
  };


  const clickAssignTypeAsc = () => {
    setReverseNextSort(false);
    setAssignTypeDescending(false);
    sortResultsBy("AssignType", false);
  };

  const clickAssignTypeDesc = () => {
    setReverseNextSort(false);
    setAssignTypeDescending(true);
    sortResultsBy("AssignType", true);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleUserDetails = (userID: any) => {
    if(isCalledFrom === "AccountManagement") {
      history.push({
        pathname: `accountDetails/` + userID
      })
    }
    else if ((!!isCalledFrom && isCalledFrom.trim().toLowerCase() == "profile") || (!!isCalledFrom && isCalledFrom.trim().toLowerCase() == "profile-account-search")) {
      if (!!history.location && !!history.location.pathname) {
        let idx = history.location.pathname.indexOf("/cpdprofile/");
        if (idx >= 0) {
          history.location.pathname = history.location.pathname.substring(0, idx) + `/accountDetails/` + userID;                
          history.push({
              pathname: ``
          });
        }
        else {
          history.push({
            pathname: `../accountDetails/` + userID
          })
        }
      }
      else {
        history.push({
          pathname: `../accountDetails/` + userID
        })
      }
    }
    else{
      history.push({
        pathname: `../accountDetails/` + userID
      })
    }
  }

  const handleAllAssignUnassign = (event: any) => {
    event.stopPropagation();
    setAssignAll(!assignAll);
		let usersListData = [...usersList];
		usersListData.map((user: any) => {
			user.granted = !assignAll;
      user.grantedInThisSession = user.granted;
		});
    setUpdatedSelectedUsers(usersListData, "userlistsection");
		setUsersList(usersListData);
	}

  const handleAssignUnassign = (event: any, userId: number) => {
    event.stopPropagation();
    let usersListData = [...usersList];
    usersListData.map((user: any) => {
      if (user.userID === userId) {
        user.granted = !user.granted;
        user.grantedInThisSession = user.granted;
      }
		})
    setUpdatedSelectedUsers(usersListData, "userlistsection");
		setUsersList(usersListData);
  }

  useEffect(() => {   
    if (!!isCalledFrom && isCalledFrom.trim().toLowerCase() == "profile"){
      if ((!!usersData !== true || usersData.length <= 0) && profileID > 0 && profilePeriodID > 0) {           
        setLoading(true);
        GetProfileUsers(
            oidcUser.access_token,
            userId,
            orgDomain,
            parseInt(orgId),
            profileID,
            profilePeriodID,
            "", 
            "",
            "",
            "",
            "",
            "",
            "view"
          ).then((response) => {
            if (
              response !== null &&
              response !== undefined &&
              response.isSuccess &&
              response.profileUsers !== null &&
              response.profileUsers !== undefined
            ) {
              if(response.profileUsers.length > 0) {
                let updatedUsersList = response.profileUsers.map((item: any) => {
                  return {
                    ...item,
                    isChecked: false,
                  };
                });
                setUsersList(updatedUsersList);
                setUpdatedSelectedUsers(updatedUsersList, "userlistsection");
        
              } else {
                console.log("Fetching profile assigned users resulted as no users");
              }
            } else {
              console.log("Fetching profile assigned users failed");
            }
            setLoading(false);
          });
      }
      else {
        setUsersList(usersData);
        setUpdatedSelectedUsers(usersData, "userlistsection");
      }
    }
    if (!!isCalledFrom && isCalledFrom.trim().toLowerCase() == "profile-account-search"){
      if (profileID > 0 && profilePeriodID > 0) {           
        setLoading(true);
        GetProfileUsers(
            oidcUser.access_token,
            userId,
            orgDomain,
            parseInt(orgId),
            profileID,
            profilePeriodID,
            ((!!selectedUnitIDs !== true || selectedUnitIDs.length <= 0) ? ((!!allUnitIDs !== true || allUnitIDs.length <= 0) ? "" : allUnitIDs.toString()) : selectedUnitIDs.toString()), 
            filterFirstName,
            filterLastName,
            filterUserName,
            filterEmail,
            "",
            "search"
          ).then((response) => {
            if (
              response !== null &&
              response !== undefined &&
              response.isSuccess &&
              response.profileUsers !== null &&
              response.profileUsers !== undefined
            ) {
              if(response.profileUsers.length > 0) {
                let updatedUsersList = response.profileUsers.map((item: any) => {
                  return {
                    ...item,
                    isChecked: false,
                  };
                });
                setUsersList(updatedUsersList);
                setUpdatedSelectedUsers(updatedUsersList, "userlistsection");
        
              } else {
                console.log("Fetching profile assigned users resulted as no users");
              }
            } else {
              console.log("Fetching profile assigned users failed");
            }
            setLoading(false);
          });
      }
    }
  }, []);

  useEffect(() => {   
    if (needLatestUsers) {
      setUpdatedSelectedUsers(usersList, "userlistsection");
    }
  }, [needLatestUsers]);

  if (loading) return <LoaderContainer />;
  return (
    <div style={{ marginTop: "20px" }}>
      <UnbrandedCard>
        <div style={{ padding: "10px 30px 0px 23px" }}>

          <div className={styles.expandContainer} style={{ backgroundColor: '#fff' }}>
                <div className={styles.listField}><b>Search Results</b></div>
                <div className={styles.lowerButtonPanel}><b>{usersList.length.toString()} Accounts</b></div>
          </div>

          <div className={styles.paginationControlsContainer}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "95%",
              }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 100]}
                component="div"
                count={usersList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />            
            </div>
          </div>

          <div className={styles.tableSection} id="userResultTableOfSearch">
            <div className={styles.tableContainer}>
              <BrandedCard>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                      {!(isCalledFrom === "AccountManagement") && 
                        <TableCell id="acMgmtAssignCol"
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                            borderRadius: "8px 0 0 0",
                          }}
                          align="left"
                        >
                          <div
                            className={styles.tableHeadCellContainer}
                            style={{ display: "flex", }}
                          >
                            <div>
                              <Checkbox
																									size="small"
																									color="primary"
																									checked={assignAll}
																									className={styles.checkBox}				
                                                  style={{color: 'white'}}		
                                                  onClick={(e: any) => handleAllAssignUnassign(e)}
																								/>
                              {isCalledFrom === 'ArchiveUsers' ? 'Archive': allResourcesToTranslations.assign }
                            </div>
                          </div>
                        </TableCell>
                      }
                      {(isCalledFrom === "AccountManagement")  && 
                        <TableCell id="acMgmtAssignCol"
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                            borderRadius: "8px 0 0 0",
                          }}
                          align="left"
                        >
                          <div
                            className={styles.tableHeadCellContainer}
                            style={{ display: "flex", }}
                          >
                            <div
                              onClick={() => {
                                setUserIDDescending(!userIDDescending);
                                sortResultsBy("UserID", !userIDDescending);
                              }}
                            >
                              {"Account ID" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "UserID" &&
                                      userIDDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickUserIDAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "UserID" &&
                                      userIDDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickUserIDDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                      }
                        <TableCell id="acMgmtUPCol"
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                          }}
                          align="left"
                        >
                          <div
                            className={styles.tableHeadCellContainer}
                            style={{ display: "flex" }}
                          >
                            <div
                              onClick={() => {
                                setUnitPathwayDescending(!unitPathwayDescending);
                                sortResultsBy("UnitPathway", !unitPathwayDescending);
                              }}
                            >
                              {"Unit Pathway" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "UnitPathway" &&
                                      unitPathwayDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickUnitPathwayAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "UnitPathway" &&
                                      unitPathwayDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickUnitPathwayDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell  id="acMgmtLNCol"
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                          }}
                          align="left"
                        >
                          <div
                            className={styles.tableHeadCellContainer}
                            style={{ display: "flex" }}
                          >
                            <div
                              onClick={() => {
                                setLastNameDescending(!lastNameDescending);
                                sortResultsBy("LastName", !lastNameDescending);
                              }}
                            >
                              {"Last Name" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "LastName" &&
                                      lastNameDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickLastNameAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "LastName" &&
                                      lastNameDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickLastNameDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell  id="acMgmtFNCol"
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                          }}
                          align="left"
                        >
                          <div
                            className={styles.tableHeadCellContainer}
                            style={{ display: "flex" }}
                          >
                            <div
                              onClick={() => {
                                setFirstNameDescending(!firstNameDescending);
                                sortResultsBy(
                                  "FirstName",
                                  !firstNameDescending
                                );
                              }}
                            >
                              {"First Name" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "FirstName" &&
                                      firstNameDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickFirstNameAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "FirstName" &&
                                      firstNameDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickFirstNameDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        {(isCalledFrom === "AccountManagement")  && (
                        <TableCell
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                          }}
                          align="left"
                        >
                          <div
                            className={styles.tableHeadCellContainer}
                            style={{ display: "flex" }}
                          >
                            <div
                              onClick={() => {
                                setStatusDescending(!statusDescending);
                                sortResultsBy("Status", !statusDescending);
                              }}
                            >
                              {"Status" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "Status" &&
                                      statusDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickStatusAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "Status" &&
                                      statusDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickStatusDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        )}
                        {(isCalledFrom === "AccountManagement" || isCalledFrom === "ArchiveUsers")  && (
                        <TableCell id="acMgmtEmailCol"
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                          }}
                          align="left"
                        >
                          <div
                            className={styles.tableHeadCellContainer}
                            style={{ display: "flex" }}
                          >
                            <div
                              onClick={() => {
                                setEmailDescending(!emailDescending);
                                sortResultsBy("Email", !emailDescending);
                              }}
                            >
                              {"Email" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "Email" &&
                                      emailDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickEmailAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "Email" &&
                                      emailDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickEmailDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        )}
                        {(isCalledFrom === "AccountManagement" || isCalledFrom === "ArchiveUsers")  && (
                        <TableCell id="acMgmtLastLoginCol"
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                            borderRadius: "0 8px 0 0",
                          }}
                          align="left"
                        >
                          <div
                            className={styles.tableHeadCellContainer}
                            style={{ display: "flex" }}
                          >
                            <div
                              onClick={() => {
                                setLastLoginDescending(!lastLoginDescending);
                                sortResultsBy("LastLogin", !lastLoginDescending);
                              }}
                            >
                              {"Last Login" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "LastLogin" &&
                                      lastLoginDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickLastLoginAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "LastLogin" &&
                                      lastLoginDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickLastLoginDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        )}
                        {(!!isCalledFrom && (isCalledFrom.trim().toLowerCase() == "profile" || isCalledFrom.trim().toLowerCase() == "profile-account-search"))  && (
                        <TableCell align="right" id="acMgmtAsignTypeCol"
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                            borderRadius: "0 8px 0 0",
                          }}
                        >
                        <span
                          className={styles.tableHeadCellContainer}
                          style={{ display: "absolute" }}
                        >
                          <span
                            onClick={() => {
                              setAssignTypeDescending(!assignTypeDescending);
                              sortResultsBy("AssignType", !assignTypeDescending);
                            }}
                          >
                          {"Assignment Type" /* TODO translate */}
                            </span>
                            <span className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "AssignType" &&
                                    assignTypeDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickAssignTypeAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "AssignType" &&
                                      assignTypeDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickAssignTypeDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </span>
                          </span>
                        </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {usersList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((result: any, i) => {
                          return (
                            <TableRow
                              key={`reportScheduler-${i}`}
                              style={rowBackgroundColor(i)}
                            >
                               {!(isCalledFrom === "AccountManagement")  && 
                                <TableCell align="left" className={"assignCheckbox"}>
                                    <Checkbox
																									size="small"
																									color="primary"
																									checked={result.granted}
																									className={styles.checkBox}
                                                  onClick={(e: any) => handleAssignUnassign(e, result.userID)}
																								/>
                               </TableCell>
                              }              

                              {(isCalledFrom === "AccountManagement")  && 
                              <TableCell align="left">
                                <Link style={{color: '#63C2FF', textDecoration: 'underline'}} to={`/accountDetails/${result.userID}`}>{result.userID}</Link>
                                {/* <span style={{ color: '#63C2FF', textDecoration: 'underline', cursor: "pointer" }} onClick={() => handleUserDetails(result.userID)}>{result.userID}</span> */}
                              </TableCell>
                              }
                              <TableCell align="left"  className={"unitpathwayCol"}>
                                {result.unitPathway}
                              </TableCell>
                              <TableCell align="left"  className={"lastnameCol"}>
                                <Link style={{color: '#63C2FF', textDecoration: 'underline'}} to={`/accountDetails/${result.userID}`}>{result.lastName}</Link> 
                               {/* <span style={{ color: '#63C2FF', textDecoration: 'underline', cursor: "pointer" }} onClick={() => handleUserDetails(result.userID)}>{result.lastName}</span>  */}
                              </TableCell>
                              <TableCell align="left" className={"firstnameCol"}>
                                {result.firstName}
                              </TableCell>
                              {(isCalledFrom === "AccountManagement")  && (
                              <TableCell align="left">
                                {result.active ?
                                  <CustomTooltip displayText={["Active"]}>
                                    <span style={{ paddingRight: "5px" }}><ActiveIcon></ActiveIcon></span>
                                  </CustomTooltip>
                                  :
                                  <CustomTooltip displayText={["Inactive"]}>
                                    <span style={{ paddingRight: "5px" }}><InActiveIcon></InActiveIcon></span>
                                  </CustomTooltip>
                                }
                                {result.active ? "Active" : "Inactive"}</TableCell>
                              )}
                              {(isCalledFrom === "AccountManagement" || isCalledFrom === "ArchiveUsers")  && (
                              <TableCell align="left">{result.email}</TableCell> )}
                              {(isCalledFrom === "AccountManagement" || isCalledFrom === "ArchiveUsers")  && (
                              <TableCell align="left">
                                {result.lastLogin}
                              </TableCell>
                              )}
                              {(!!isCalledFrom && (isCalledFrom.trim().toLowerCase() == "profile" || 
                                                   isCalledFrom.trim().toLowerCase() == "profile-account-search"))  && (
                              <TableCell align="right">
                                {result.onlyAccountGranted || !!result.grantedInThisSession ? 
                                <CustomTooltip displayText={[allResourcesToTranslations.assignedbyuser]}>
                                  <span id="userBasedAccessIndicationCPDAView" style={{right: "60px", position: "absolute", marginTop: "-15px"}}><AssignedByUserIcon /></span>
                                </CustomTooltip> :
                                (result.granted ? 
                                  <CustomTooltip displayText={[allResourcesToTranslations.assignedbyunit]}>
                                    <span id="unitBasedAccessIndicationCPDAView" style={{right: "60px", position: "absolute", marginTop: "-15px"}}><AssignedByUnitIcon /></span>
                                  </CustomTooltip> : <></>)}
                              </TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </BrandedCard>

              <div className={styles.paginationAddedUsersControl}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "95%",
                  }}
                >
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={usersList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </div>        
        </div>
      </UnbrandedCard>
    </div>
  );
};

export default UserListSection;
UserListSection.propTypes = {
  usersData: PropTypes.any,
  isCalledFrom: PropTypes.string,
  setUpdatedSelectedUsers: PropTypes.func,
  needLatestUsers: PropTypes.bool
}

UserListSection.defaultProps = {
  usersData: [],
  isCalledFrom: '',
  setUpdatedSelectedUsers: () => {},
  needLatestUsers: false
}
