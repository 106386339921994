import React, { useEffect, useContext, useState, useRef } from "react";

// Libs
import { useReactOidc } from "@axa-fr/react-oidc-context";

import { UIContext } from "../../contexts/UIContext";
import { TranslationsContext } from '../../contexts/TranslationsContext';

import BrandedCard from "../../Components/BrandedCard";
import LinearProgressWithLabel from '../../Components/LinearProgressBar';
//Styles
import styles from "../../styles/emailReport.module.css";
import CommonPopupModalForResult from '../../Components/CommonPopupModalForResult';
import { getEmailingReportProgress } from "../../API/GetEmailingReportProgress";
import CustomTooltip from "../../Components/CustomTooltip";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const SendProgress = (props: any) => {

  const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
  const { setSubComponentName } = useContext(UIContext);
  const {emailReportId, handleBackButtonclick, isQueueInProgress, setPageReset, setIsProcessFinished} = props;
  const [progress, setProgress] = useState(0);
  const [showQueueModal, setShowQueueModal] = useState<boolean>(false);
  const [modalPopupMessage, setModalPopupMessage] = useState<string>('');
  const [showQueueOrSuccess, setShowQueueOrSuccess] = useState<boolean>(false);
  const [totalEmailsSent, setTotalEmailsSent] = useState<number>(0);
  const [totalEmailsTobeSend, setTotalEmailsTobeSend] = useState<number>(0);
  const [totalFailedEmailsToSent, setTotalFailedEmailsToSent] = useState<number>(0);
  const [disableBackButton, setDisableBackButton] = useState<boolean>(true);
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const { oidcUser } = useReactOidc();
  const { profile: { user_id, website } } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";

  useEffect(() => {

    if (emailReportId > 0) {
      const timer = setInterval(() => {
          getEmailingReportProgress(oidcUser.access_token, orgDomain, userId ,parseInt(orgId), emailReportId).then(response => {
              if (response.isSuccess && response.emailingReportProgressItem !== null && response.emailingReportProgressItem.length > 0) {
                  let emailingReportProgressItem = response.emailingReportProgressItem[0];
                  setTotalEmailsTobeSend(emailingReportProgressItem.totalMailsToGoOut);
                  setTotalEmailsSent(emailingReportProgressItem.totalMailsProcessed);
                  setTotalFailedEmailsToSent(emailingReportProgressItem.totalMailsFailed);
                  // Email report is added and it's in queue
                  if (emailingReportProgressItem.isInprogress === true && emailingReportProgressItem.datetimeStarted === "" && emailingReportProgressItem.datetimeCompleted === "") {
                      setShowQueueModal(true); // Show warning modal
                      setTotalEmailsTobeSend(emailingReportProgressItem.totalMailsToGoOut);
                      setModalPopupMessage('Your report has been added to the queue and will start automatically.');
                  } 
                  // This is for inProgress
                  else if(emailingReportProgressItem.isInprogress === true && emailingReportProgressItem.datetimeStarted !== "" && emailingReportProgressItem.datetimeCompleted === "") {
                      setShowQueueModal(false); // hide warning modal
                      let progessCompleted = ((emailingReportProgressItem.totalMailsFailed + emailingReportProgressItem.totalMailsProcessed) * 100) / emailingReportProgressItem.totalMailsToGoOut;
                      setProgress(progessCompleted);       

                      if (emailingReportProgressItem.totalMailsFailed > 0) {
                          setShowQueueModal(true);
                          setHasErrored(true);
                          setDisableBackButton(false);
                          setModalPopupMessage('Sending emails have been failed for Your Email Report. <br/> Total Processed emails: ' + emailingReportProgressItem.totalMailsProcessed + '<br/> Total Failed emails: + ' + emailingReportProgressItem.totalMailsFailed);
                      }

                      if (progress >= 100){
                        setProgress(100);
                        setShowQueueModal(true);
                        setHasErrored(false);
                        setShowQueueOrSuccess(true);
                        setDisableBackButton(false);
                        setModalPopupMessage('Your Email Report has been sent successfully.');                       
                        clearInterval(timer);
                        return;
                      }
                  } 
                  // If isInProgress not set but finished the process
                  else if (emailingReportProgressItem.isInprogress === true && emailingReportProgressItem.datetimeStarted !== "" && emailingReportProgressItem.datetimeCompleted !== "") {
                      if (emailingReportProgressItem.totalMailsFailed + emailingReportProgressItem.totalMailsProcessed === emailingReportProgressItem.totalMailsToGoOut) {
                          setShowQueueModal(true);
                          setHasErrored(false);
                          setShowQueueOrSuccess(true);
                          setDisableBackButton(false);
                          setModalPopupMessage('Your Email Report has been sent successfully.');
                      } else {
                          setShowQueueModal(true);
                          setHasErrored(true);
                          setShowQueueOrSuccess(false);
                          setModalPopupMessage('Your Email Report has been processed. <br/> Total Processed emails: ' + emailingReportProgressItem.totalMailsProcessed + '<br/> Total Failed emails: + ' + emailingReportProgressItem.totalMailsFailed);
                      }
                      clearInterval(timer);
                      return;
                  }
                  else {
                      // When completed
                      if (emailingReportProgressItem.isInprogress === false && emailingReportProgressItem.datetimeStarted !== "" && emailingReportProgressItem.datetimeCompleted !== "") {
                        if (emailingReportProgressItem.totalMailsProcessed !== emailingReportProgressItem.totalMailsToGoOut) {
                          setHasErrored(true);
                          setModalPopupMessage('Your Email Report has been processed. <br/> Total Processed emails: ' + emailingReportProgressItem.totalMailsProcessed + '<br/> Total Failed emails: + ' + emailingReportProgressItem.totalMailsFailed);
                        } else {
                          setShowQueueModal(true); 
                          setHasErrored(false);
                          setShowQueueOrSuccess(true);
                          setProgress(100);
                          setDisableBackButton(false);
                          setIsProcessFinished();
                          setModalPopupMessage('Your Email Report has been sent successfully.');
                        }
                      } else {
                          setShowQueueModal(true); // Show warning modal
                          setModalPopupMessage('Your report has been added to the queue and will start automatically.');
                      }
                      clearInterval(timer);
                      return;
                  }

                  if (isQueueInProgress || emailingReportProgressItem.datetimeCompleted === "") {
                    setDisableBackButton(true);
                  } else {
                    setIsProcessFinished();
                    setDisableBackButton(false);
                  }
              } else {
                  setShowQueueModal(true);
                  setModalPopupMessage('Sending emails have been failed for Your Email Report');
                  setDisableBackButton(false);
                  setHasErrored(true);
                  clearInterval(timer);
                  return;
              }  
            }).catch((error) => {
              setShowQueueModal(true);
              setModalPopupMessage(error !== null ?  error.message : 'Sending emails have been failed for Your Email Report');
              setDisableBackButton(false);
              setHasErrored(true);
              clearInterval(timer);
              return;
            });
      }, 2000);
      return () => {
          //setSubComponentName("");
          clearInterval(timer);
      };
    }
    //setSubComponentName("EmailReportSend");

  }, [emailReportId,progress,totalEmailsTobeSend,totalEmailsSent,disableBackButton,isQueueInProgress]);

  useEffect(() => {  
    if (setPageReset) {
      ResetAll();
    }
    //setSubComponentName("EmailReportSend");
  },[setPageReset])


  const handleClose = () => {
    setShowQueueModal(false);
   };

   const ResetAll = () => {
    setProgress(0);
    setShowQueueModal(false);
    setShowQueueOrSuccess(false);
    setTotalEmailsSent(0);
    setTotalEmailsTobeSend(0);
    setTotalFailedEmailsToSent(0);
};

  return (
    <>
        <div style={{ width: '100%' }}>
        <div className={styles.backButtonSpecific}>
            <CustomTooltip displayText={["Back"]}>
              <span>
              <Button
                onClick={() => handleBackButtonclick(false)}
                variant="contained"
                color="primary"
                disabled={disableBackButton}
                className="back"
              >
                <ArrowBackIcon />
              </Button>
              </span>
            </CustomTooltip>
          </div>
            <BrandedCard>
                <p
                    className={styles.emailStatus}
                >
                    {allResourcesToTranslations.emrepgmsendstatus}
                </p>
                
                <div className={styles.emailSentStatus}><span>{allResourcesToTranslations.emrepgmemsent}: {totalEmailsSent} / {totalEmailsTobeSend}</span> </div> 
                <div style={{ padding: '50px 25px' }}>
                    <LinearProgressWithLabel value={progress} />
                </div>
                <div hidden={totalFailedEmailsToSent == 0} className={styles.emailFailedStatus}><span>{allResourcesToTranslations.emrepgmemfailed}: {totalFailedEmailsToSent}</span> </div> 
            </BrandedCard>
            {showQueueModal && <CommonPopupModalForResult saveSuccess={showQueueOrSuccess} hasErrored={hasErrored} handleClose={handleClose} titleText={showQueueOrSuccess ? 'Email Report' : 'Email Report Status'} bodyText={modalPopupMessage} />}
        </div>

    </>
)
};

export default SendProgress;
