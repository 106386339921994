import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const AssignPointsIcon = () => (    
<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M7.69043 8.36151C7.69043 10.6709 9.56284 12.5433 11.8722 12.5433C14.1817 12.5433 16.0541 10.6709 16.0541 8.36151C16.0541 6.0521 14.1817 4.17969 11.8722 4.17969C9.56284 4.17969 7.69043 6.0521 7.69043 8.36151ZM9.78134 8.36151C9.78134 9.51673 10.717 10.4524 11.8722 10.4524C13.0275 10.4524 13.9632 9.51673 13.9632 8.36151C13.9632 7.20628 13.0275 6.2706 11.8722 6.2706C10.717 6.2706 9.78134 7.20628 9.78134 8.36151Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23.3721 11.5C23.3721 5.14886 18.2232 0 11.8721 0C5.52093 0 0.37207 5.14886 0.37207 11.5C0.37207 17.8511 5.52093 23 11.8721 23C12.2592 23 12.6419 22.9809 13.0192 22.9435C12.7234 22.3061 12.5206 21.6168 12.4285 20.8929C12.2444 20.9036 12.0589 20.9091 11.8721 20.9091C9.58462 20.9091 7.48744 20.0926 5.85653 18.7356C7.1853 16.8862 9.35462 15.6818 11.8052 15.6818C12.4534 15.6818 13.0818 15.7661 13.6803 15.9243C14.1374 15.2871 14.6989 14.7298 15.3397 14.2774C14.2484 13.8347 13.0552 13.5909 11.8052 13.5909C8.79321 13.5909 6.11265 15.0054 4.39079 17.2061C3.1812 15.6233 2.46298 13.6453 2.46298 11.5C2.46298 6.30304 6.67512 2.09091 11.8721 2.09091C17.069 2.09091 21.2812 6.30304 21.2812 11.5C21.2812 12.0878 21.2272 12.663 21.124 13.221C21.8264 13.402 22.4861 13.6893 23.085 14.0648C23.2729 13.24 23.3721 12.3816 23.3721 11.5Z" fill="white"/>
<circle cx="19.3721" cy="20" r="5.5" stroke="white"/>
<path d="M23.2091 21.2593C23.3373 20.8305 24 20.4755 24 20.0006C24 19.5258 23.3373 19.1708 23.2091 18.742C23.081 18.3132 23.3947 17.6217 23.1384 17.239C22.8822 16.8563 22.1443 16.9762 21.7909 16.6996C21.4374 16.423 21.3137 15.6668 20.8851 15.5239C20.4566 15.381 19.9485 15.925 19.4934 15.925C19.0383 15.925 18.5346 15.3902 18.0928 15.5285C17.651 15.6669 17.5538 16.4368 17.2091 16.6996C16.8645 16.9624 16.1178 16.8609 15.8616 17.2344C15.6053 17.6078 15.9278 18.2948 15.7909 18.7374C15.6539 19.18 15 19.5258 15 20.0006C15 20.4755 15.6495 20.8489 15.782 21.2593C15.9146 21.6696 15.5965 22.3934 15.8527 22.7622C16.109 23.1311 16.8468 23.025 17.2003 23.2971C17.5538 23.5691 17.6819 24.3298 18.0839 24.4727C18.486 24.6156 19.0383 24.0762 19.4934 24.0762C19.9485 24.0762 20.4786 24.6156 20.8851 24.4773C21.2916 24.339 21.433 23.5552 21.7688 23.3017C22.1046 23.0481 22.8601 23.1403 23.1208 22.7669C23.3814 22.3934 23.0677 21.7019 23.2091 21.2593ZM19.4934 23.0804C18.9096 23.0804 18.339 22.8997 17.8537 22.5613C17.3683 22.2229 16.99 21.7419 16.7666 21.1792C16.5433 20.6164 16.4848 19.9972 16.5987 19.3998C16.7126 18.8024 16.9937 18.2536 17.4064 17.8229C17.8192 17.3922 18.3451 17.0989 18.9176 16.9801C19.4901 16.8612 20.0835 16.9222 20.6228 17.1553C21.1621 17.3884 21.6231 17.7831 21.9474 18.2896C22.2717 18.7961 22.4448 19.3915 22.4448 20.0006C22.4448 20.8174 22.1338 21.6008 21.5803 22.1783C21.0268 22.7559 20.2761 23.0804 19.4934 23.0804Z" fill="white"/>
<path d="M20.1502 21.2102V20.5371H20.9441C21.0838 20.5029 21.2065 20.4314 21.2939 20.3335C21.3812 20.2356 21.4284 20.1166 21.4284 19.9942C21.4284 19.8718 21.3812 19.7528 21.2939 19.6549C21.2065 19.557 21.0838 19.4855 20.9441 19.4513H20.1502V18.7952C20.1794 18.7109 20.1849 18.6221 20.1663 18.5357C20.1476 18.4493 20.1052 18.3678 20.0426 18.2975C19.9799 18.2273 19.8986 18.1703 19.8052 18.131C19.7118 18.0917 19.6088 18.0713 19.5044 18.0713C19.3999 18.0713 19.2969 18.0917 19.2035 18.131C19.1101 18.1703 19.0288 18.2273 18.9662 18.2975C18.9035 18.3678 18.8612 18.4493 18.8425 18.5357C18.8238 18.6221 18.8293 18.7109 18.8585 18.7952V19.4569H18.0445C17.9074 19.4927 17.7875 19.5645 17.7024 19.6616C17.6172 19.7588 17.5713 19.8763 17.5713 19.997C17.5713 20.1177 17.6172 20.2353 17.7024 20.3324C17.7875 20.4296 17.9074 20.5013 18.0445 20.5371H18.8316V21.2045C18.8024 21.2888 18.7969 21.3777 18.8156 21.464C18.8342 21.5504 18.8766 21.632 18.9393 21.7022C19.0019 21.7724 19.0832 21.8295 19.1766 21.8687C19.27 21.908 19.373 21.9284 19.4775 21.9284C19.5819 21.9284 19.6849 21.908 19.7783 21.8687C19.8717 21.8295 19.953 21.7724 20.0157 21.7022C20.0783 21.632 20.1207 21.5504 20.1394 21.464C20.158 21.3777 20.1525 21.2888 20.1233 21.2045L20.1502 21.2102Z" fill="white"/>
</svg>
);

export default AssignPointsIcon