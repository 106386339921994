import axios from 'axios';

export const updateSAPassLaunchPageDetails = async (token: string, userId: number | null, moduleId: number, isReviewOnly: boolean, isPageResetRequired: boolean, domain: string) => {
    if (!userId) return null
    try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/usersessions/UpdateSAPassLaunchPageDetails`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        ModuleID: moduleId,
        UserID: userId,
        DomainName: domain,
        IsReviewOnly: isReviewOnly,
        IsPageResetRequired: isPageResetRequired
      },
    });
    const data = await response.data;
    // if (data.isSuccess) {    
    //   console.log("****postApiRequest URL= " + data.postApiRequest)
    //  console.log("****apiPrefix= " + data.apiPrefix)
    //  console.log("****commandToReset = " + data.commandToReset)
    //  console.log("****callback URL= " + data.callbackURL)
     
    // }

    return data;
    
  } catch (error: any) {
    if (error.response) {
      console.log(error.response);
    } else if (error.request) {
      console.log(error.request);
    } else if (error.message) {
      console.log(error.message);
    }
    return null;
  }
};

export default updateSAPassLaunchPageDetails;