import React,{useEffect, useState, useContext, useRef } from "react";
// Global types
import { UnitCourseAccessItem, UnitModuleAccessItem, CourseModuleAccessChanges, ModuleAccessChanges, ModuleUserAccess } from "../../../globalTypes";
//Intro Lib
import { Steps, Hints } from "intro.js-react";
// MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from "@material-ui/icons/Search";
import { IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
// Custom Icons
import SelectAllIcon from "../../../CustomIcons/SelectAllIcon";
import UnSelectAllIcon from "../../../CustomIcons/UnSelectAllIcon";
import SaveIcon from "../../../CustomIcons/SaveIcon";
import GuideIcon from '../../../CustomIcons/GuideIcon';
import ProceedIcon from '../../../CustomIcons/ProceedIcon';
import CloseIcon from '../../../CustomIcons/CloseIcon';
import ErrorIcon from "../../../CustomIcons/Administration/errorIcon";
// Componenets
import BrandedCard from "../../../Components/BrandedCard";
import LoaderContainer from "../../../Components/LoaderContainer";
import CustomTooltip from "../../../Components/CustomTooltip";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";
import Modal from '../../../Components/Modal';
// APIs

import getFullUnitComplianceReport from "../../../API/GetFullUnitComplianceReport";
import getOneUnitDetails from "../../../API/Administration/Unit/GetOneUnitDetails";
import getUnitAllCourseModulesAccess from "../../../API/Administration/Unit/GetUnitAllCourseModulesAccess";
import saveUnitMultiCourseModuleAccess from "../../../API/Administration/Unit/SaveUnitMultiCourseModuleAccess";
import { addError } from '../../../API/AddError'
// OIDC
import { useHistory } from 'react-router-dom';
import { useReactOidc, getUserManager } from "@axa-fr/react-oidc-context";
// Contexts
import { TranslationsContext } from '../../../contexts/TranslationsContext';
import { UIContext } from '../../../contexts/UIContext';
import { LoaderContext } from '../../../contexts/LoaderContext';
// Styles
import styles from '../../../styles/setUserModuleAccess.module.css' 
import UnitComplianceReportSection from "./UnitComplianceReportSection";
import TriggerStatusUpdateUnitAndCourseBased from "../../../API/Administration/Users/TriggerStatusUpdateUnitAndCourseBased";

const useStyles = makeStyles({
  root: {
    height: '1px !important',
    width: '90% !important',
    marginRight: '5px !important',
    padding: '0px !important',
    //float: 'left !important'
  }
});

const UnitSetUnitModuleAccess = (props: any) => {
  const { translations: { allResourcesToTranslations, currentPageLang }, pageTitles} = useContext(TranslationsContext)
  const { setTitle, setTitleEnglish, updateBackButtonRoute } =  useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { unitIdToEdit } = props;
  const { oidcUser } = useReactOidc();
  const { profile: { user_id, website }} = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  const [courseList, setCourseList] = useState<Array<UnitCourseAccessItem>>([]);
  const [courseListOriginal, setCourseListOriginal] = useState<Array<UnitCourseAccessItem>>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [showResponseModal, setShowResponseModal] = React.useState(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [saveResultTitle, setSaveResultTitle] = React.useState("");
  const [saveResultMessage, setSaveResultMessage] = React.useState("");
  const [saveResultIsWarning, setSaveResultIsWarning] = React.useState(false);
  const [filterCourseOrModuleName, setFilterCourseOrModuleName] = React.useState("");

  //********************************************************************************************* */
  // For unsaved warning
  const { IsLoggingOut, setIsLoggingOut, EnableActivityLogs, setIsUserModuleAccessChangedAndNotSaved } = useContext(UIContext);
  const [showModal,setModalOpen] = useState<boolean>(false);
  const { setMessage } = useContext(LoaderContext)
  const { logout } = useReactOidc();
  const [goBack, setGoBack] = useState<boolean>(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [previousLocation,setPreviousLocation] = useState<string>('');
  const history = useHistory();
  const isMounted = useRef<boolean>(false);
  const [modifiedModuleUserAccesses, setModifiedModuleUserAccesses] = useState<Array<UnitModuleAccessItem>>([]);

  const [unitActive, setUnittActive] = useState<boolean>(false);   
  const [assignSubunit, setAssignSubunit] = useState<boolean>(false);
  const [pathway, setPathway] = useState(""); 
  const [unitComplianceList, setUnitComplianceList] = React.useState<Array<any>>([]);
  const [unitComplianceListOpen, setUnitComplianceListOpen] = useState<boolean>(false);
  const [expandUnitComplianceList, setExpandUnitComplianceList] = useState<boolean>(false);
 

  const handleModal = (value:boolean) => {
    setModalOpen(value);
  }
  //********************************************************************************************* */
  
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: "#subunitDetails",
        title: "Assign to all sub units",
        intro: "Check this box to assign all the below selected courses/modules to all sub units of this unit.",
      },
    ],
  });

 
  useEffect(() => {
    setGuideMe(true);
  }, [courseList,expandUnitComplianceList]);

  const setGuideMe = (clearOldList: boolean = false) => {
    let updateSteps = clearOldList ? [] : [...intro.steps] 
    updateSteps.push(
      {
        element: "#subunitDetails",
        title: "Assign to all sub units",
        intro: "Check this box to assign all the below selected courses/modules to all sub units of this unit.",
      },
      {
        element: "#attentionInfo",
        title: "Attention Info",
        intro: "This will override the Account's default Unit Module Access.",
      },
      {
        element: "#courseModuleNameSearch",
        title: "Filter",
        intro: allResourcesToTranslations.coursemodulenamesearch,
      },      
      {
        element: "#selectAllButton",
        title: allResourcesToTranslations.selectall,
        intro: allResourcesToTranslations.selectallbuttoncoursemodule,
      },
      {
        element: "#unselectAllButton",
        title: allResourcesToTranslations.unselectall,
        intro: allResourcesToTranslations.unselectallbuttoncoursemodule,
      },
      {
        element: "#orgGrantedCourseList",
        title: "Course List",
        intro: "This list shows all courses your organisation has access to. This view may be filtered by entering a search term above.",
      });
      if (!!courseList && courseList.length > 0) {
       
        if (courseList.some((c) => !!c && c.isAnyUnitGranted)) {
          updateSteps.push(
          {
            element: "#unitBasedAccessIndication",
            title: "Icon",
            intro: `This icon indicates that this specific course has been assigned to the account at a unit level for one or more of its modules.`,
          });
        }       
        updateSteps.push(        
        {
          element: ".MuiAccordionSummary-expandIcon",
          title: "Click",
          intro: `Click the expander to expand the module list of this specific course.`,
        });
      }
      updateSteps.push(
      {
        element: "#saveButton",
        title: "Save",
        intro:"Click this button to save the access changes made to the course and modules in the above list.",
      });

      if (expandUnitComplianceList) {           
        updateSteps.push(        
          {
            element: ".unitComplianceListSection",
            title: "Unit Compliance Report", intro: "This area will display a Unit Compliance Report for this unit, this shows all currently assigned training, in addition to showing the current compliance period OR date-based compliance date, as well as passmark and if these are the default settings of custom to this unit."
          },{
          element: ".xlxs",
          title: "Unit Compliance Report", intro: "Click here to download an export of the displayed Unit Compliance Report for this Unit."
        }
        );
      }
      
   
    setIntro({ ...intro, steps: [...updateSteps] });
  }

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  const loadUnitComplianceReport = () => {
    setUnitComplianceListOpen(false); 
    getFullUnitComplianceReport(
      oidcUser.access_token, userId, orgDomain, parseInt(orgId),
      unitIdToEdit, false          
    ).then((response) => {
      if (
        response !== null &&
        response !== undefined &&
        response.isSuccess &&
        response.unitComplianceReportItems !== null &&
        response.unitComplianceReportItems !== undefined
      ) {
        
        if(response.unitComplianceReportItems.length > 0) {
          let updatedUnitComplianceList = response.unitComplianceReportItems.map((item: any) => {
            return {
              ...item,
              isChecked: false,
            };
          });
          setUnitComplianceList(updatedUnitComplianceList);
          setUnitComplianceListOpen(true);              
          let element = document.getElementById("unitComplianceReportList");
          if (!!element)
            element.scrollIntoView();
  
        }      
      } else {                          
        return;
      }
    });
  };

  useEffect(() => {
    isMounted.current = true;

    setTitle(allResourcesToTranslations.tabassignmoduletounit);
    setTitleEnglish(pageTitles.unitmoduleaccess);
    updateBackButtonRoute("/UnitManagement");
    getOneUnitDetails(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(unitIdToEdit)).then((result) => {
      if (result.isSuccess && result.error == null) {
        setPathway(result.unitDetailsItems[0].pathway);               
        setUnittActive(result.unitDetailsItems[0].active);
        setLoading(false);                
      }
    });
    
    let courseAccessChanges = collectCourseModuleAccessChanges();
    if (!(!!courseAccessChanges && !!courseAccessChanges.accessChangedCourseIDs &&
          !!courseAccessChanges.isAnyChanged && courseAccessChanges.isAnyChanged)){
      setGuideMe(true);     
      refreshCourseModuleList(true);
    }
    loadUnitComplianceReport();
    return () => {
      isMounted.current = false
    }
  }, []);

  useEffect(() => {
    setFilteredCoursesOnly(courseList);
  },[filterCourseOrModuleName])


  const refreshCourseModuleList = (resetLoading: boolean) => {
    getUnitAllCourseModulesAccess(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(unitIdToEdit)).then((courseListRes) => {
      if (isMounted.current) {
        if (!!courseListRes && !!courseListRes.isSuccess && courseListRes.isSuccess && !!courseListRes.unitCourseAccessItems) {
          setCourseList(courseListRes.unitCourseAccessItems);
          setFilteredCoursesOnly(courseListRes.unitCourseAccessItems);
          cloneGivenListAndSetOriginalCourseList(courseListRes.unitCourseAccessItems);
        }
      }
      if (resetLoading) {
        setLoading(false);
      }
    }).catch((error) => {
      if (resetLoading) {
        setLoading(false);
      }
    })
  }

  const cloneGivenList = (givenCourseList : Array<UnitCourseAccessItem>) => {
    if (!!givenCourseList !== true){
      return givenCourseList;
    }
    let originalList : Array<UnitCourseAccessItem>  = [];
        originalList = givenCourseList.map((c : UnitCourseAccessItem) => {
          let curCItem : UnitCourseAccessItem = {
            isAnyUnitGranted: c.isAnyUnitGranted,          
            courseID: c.courseID,
            courseName: c.courseName,
            expanded: c.expanded,
            areAllModulesGranted: c.areAllModulesGranted,
            isSearchMatch: c.isSearchMatch,
            unitModuleAccessItems: c.unitModuleAccessItems.map((m : UnitModuleAccessItem) => {
                                      let curMItem : UnitModuleAccessItem = {
                                        isUnitGranted: m.isUnitGranted,                                      
                                        moduleID: m.moduleID,
                                        moduleName: m.moduleName,
                                        isSearchMatch: m.isSearchMatch
                                      };
                                      return curMItem; 
                                  })
          }
          return curCItem;
        });
    return originalList;
  }  

  const cloneGivenListAndSetOriginalCourseList = (givenCourseList : Array<UnitCourseAccessItem>) => {    
    let localList : Array<UnitCourseAccessItem>  = [];
    localList = cloneGivenList(givenCourseList);
    setCourseListOriginal(localList);
  }

  useEffect(() => {
    if(showResponseModal) {
      setTimeout(() => {
        setShowResponseModal(false);
        setSaveSuccess(false);
      }, saveSuccess ? 3000 : 20000)
    }
  },[showResponseModal])

  const handleCourseExpansion = (courseData: UnitCourseAccessItem) => {
    if (!!courseData.expanded) {
      courseData.expanded = !courseData.expanded;
    }
    else {
      courseData.expanded = true;
    }

    const updatedCourseData = courseList.map((item: UnitCourseAccessItem) => {
      if (!!item && !!courseData && !!item.courseID && !!courseData.courseID && item.courseID == courseData.courseID) {
        item.unitModuleAccessItems = item.unitModuleAccessItems.slice(0).map((elem) => 
          {
            return {
              ...elem
            }
          }
        );
        return {
          ...item,
          expanded: courseData.expanded
        }
      }
      return item; 
    });

    setCourseList([...updatedCourseData]);
  }
  
  // Set the filter matched courses/modules 
  const setFilteredCoursesOnly = (givenCourseList : Array<UnitCourseAccessItem>) => {
    const filteredCourseList = givenCourseList.map((item: UnitCourseAccessItem) => {
      if (!!item && !!item.courseID) {
        if (!!item.courseName && (!!filterCourseOrModuleName !== true || 
          (!!filterCourseOrModuleName && item.courseName.trim().toLowerCase().includes(filterCourseOrModuleName.trim().toLowerCase())))) {
          
          return {
            ...item,
            isSearchMatch: true
          }
        }
        if (!!item.unitModuleAccessItems) {
          item.unitModuleAccessItems = item.unitModuleAccessItems.slice(0).map((elem) => {
            if (!!elem.moduleName && (!!filterCourseOrModuleName !== true || 
              (!!filterCourseOrModuleName && elem.moduleName.trim().toLowerCase().includes(filterCourseOrModuleName.trim().toLowerCase())))) {
          
              return {
                ...elem,
                isSearchMatch: true
              }
            }
            else {
              return {
                ...elem,
                isSearchMatch: false
              }
            }
          });
        }
        return {
          ...item,
          isSearchMatch: false
        }
      }
      return item; 
    });

    setCourseList([...filteredCourseList]);

  }
  
  // Only the filtered courses are selected
  const handleCourseSelect = (courseData: UnitCourseAccessItem) => {
    const updatedCourseData = courseList.map((item: UnitCourseAccessItem) => {
      if (!(!!item && (item.isSearchMatch || 
            (!!item.unitModuleAccessItems && item.unitModuleAccessItems.length > 0 && 
              item.unitModuleAccessItems.some((m : UnitModuleAccessItem) => !!m && m.isSearchMatch))))) {
          return {
            ...item
          }
      }
      if (!!item && !!courseData && !!item.courseID && !!courseData.courseID && item.courseID == courseData.courseID) {
        item.areAllModulesGranted = !item.areAllModulesGranted;
        item.unitModuleAccessItems = item.unitModuleAccessItems.slice(0).map((elem) => 
          {
            return {
              ...elem,              
              isUnitGranted: item.areAllModulesGranted
            }
          }
        );
        return {
          ...item,        
          isAnyUnitGranted: (!!item.unitModuleAccessItems && 
            ((item.unitModuleAccessItems.filter((val) => { return val.isUnitGranted;})).length > 0)) ? true : false,
        }
      }
      return item; 
    });

    setCourseList([...updatedCourseData]);
    let courseAccessChanges = collectCourseModuleAccessChanges();
    setIsChanged(!!courseAccessChanges && !!courseAccessChanges.accessChangedCourseIDs && !!courseAccessChanges.isAnyChanged && courseAccessChanges.isAnyChanged);

  }
  

  // Only the filtered courses are selected or unselected
  const handleAllCoursesForGivenSelection = (givenFlag: boolean) => {

      const updatedCourseData = courseList.map((item: UnitCourseAccessItem) => {
        if (!(!!item && (item.isSearchMatch || 
              (!!item.unitModuleAccessItems && item.unitModuleAccessItems.length > 0 && 
                item.unitModuleAccessItems.some((m : UnitModuleAccessItem) => !!m && m.isSearchMatch))))) {
            return {
              ...item
            }
        }
        if (!!item && !!item.courseID) {
            item.areAllModulesGranted = givenFlag;
            item.unitModuleAccessItems = item.unitModuleAccessItems.slice(0).map((elem) => {
                return {
                    ...elem,                   
                    isUnitGranted: item.areAllModulesGranted
                }
            }
            );
            return {
                ...item,               
                isAnyUnitGranted: (!!item.unitModuleAccessItems &&
                  ((item.unitModuleAccessItems.filter((val) => { return val.isUnitGranted; })).length > 0)) ? true : false,
            }
        }
        return item;
      });

      setCourseList([...updatedCourseData]);

  }

  // Only the filtered data is selected
  const handleModuleSelect = (moduleData: UnitModuleAccessItem, courseData: UnitCourseAccessItem) => {
    
    const updatedCourseData = courseList.map((item: UnitCourseAccessItem) => {
      if (!(!!item && (item.isSearchMatch || 
            (!!item.unitModuleAccessItems && item.unitModuleAccessItems.length > 0 && 
              item.unitModuleAccessItems.some((m : UnitModuleAccessItem) => !!m && m.isSearchMatch))))) {
          return {
            ...item
          }
      }
      if (!!item && !!courseData && !!item.courseID && !!courseData.courseID && item.courseID == courseData.courseID) {
        moduleData.isUnitGranted = moduleData.isUnitGranted && !moduleData.isUnitGranted ? false : !moduleData.isUnitGranted;
        item.areAllModulesGranted = (!!item.unitModuleAccessItems && 
          ((item.unitModuleAccessItems.filter((val) => { return val.isUnitGranted;})).length == item.unitModuleAccessItems.length)) ? true : false;
        item.unitModuleAccessItems = item.unitModuleAccessItems.slice(0).map((elem) => 
          {
            if (moduleData.moduleID === elem.moduleID) {
              return {
                ...elem,
                isUnitGranted: moduleData.isUnitGranted
              }
            }
            return {
              ...elem,
              isUnitGranted: elem.isUnitGranted
            }
          }
        );
        return {
          ...item,
         isAnyUnitGranted: (!!item.unitModuleAccessItems && 
            ((item.unitModuleAccessItems.filter((val) => { return val.isUnitGranted;})).length > 0)) ? true : false,
        }
      }
      return item; 
    });

    setCourseList([...updatedCourseData]);
    let courseAccessChanges = collectCourseModuleAccessChanges();
    setIsChanged(!!courseAccessChanges && !!courseAccessChanges.accessChangedCourseIDs && !!courseAccessChanges.isAnyChanged && courseAccessChanges.isAnyChanged);
  }

  const handleCourseExpandClick = (e: any, courseData: UnitCourseAccessItem) => {    
    handleCourseExpansion(courseData);
  }

  const handleAllCourseSelection = () => {  
    handleAllCoursesForGivenSelection(true);
    let courseAccessChanges = collectCourseModuleAccessChanges();
    setIsChanged(!!courseAccessChanges && !!courseAccessChanges.accessChangedCourseIDs && !!courseAccessChanges.isAnyChanged && courseAccessChanges.isAnyChanged);
  }

  const handleAllCourseUnselection = () => {
    handleAllCoursesForGivenSelection(false);
    let courseAccessChanges = collectCourseModuleAccessChanges();
    setIsChanged(!!courseAccessChanges && !!courseAccessChanges.accessChangedCourseIDs && !!courseAccessChanges.isAnyChanged && courseAccessChanges.isAnyChanged);
  }

  const handleAllExpansion = (expand: boolean) => {

    const updatedCourseData = courseList.map((item: UnitCourseAccessItem) => {
      if (!(!!item && (item.isSearchMatch || 
            (!!item.unitModuleAccessItems && item.unitModuleAccessItems.length > 0 && 
              item.unitModuleAccessItems.some((m : UnitModuleAccessItem) => !!m && m.isSearchMatch))))) {
          return {
            ...item
          }
      }
      if (!!item && !!item.courseID) {
        item.unitModuleAccessItems = item.unitModuleAccessItems.slice(0).map((elem) => 
          {
            return {
              ...elem
            }
          }
        );
        return {
          ...item,
          expanded: expand,
        }
      }
      return item; 
    });

    setCourseList([...updatedCourseData]);

  }

  const handleExpandAllClick = () => {
    handleAllExpansion(true);
  }

  const handleCollapseAllClick = () => {
    handleAllExpansion(false);
  }
  
  const checkIfBothModuleListsAreInvalid = (modules1: UnitModuleAccessItem[], modules2: UnitModuleAccessItem[], moduleAccessChanges: ModuleAccessChanges) => {
    // Check if both the modules are invalid
    if ((modules1 == null || modules1 == undefined) && (modules2 == null || modules2 == undefined)) {
      moduleAccessChanges.reason = "Both of the given two Module lists are invalid";
      moduleAccessChanges.isAnyChanged = false;
      moduleAccessChanges.grantedModuleIDs = "";
      moduleAccessChanges.differedModuleIDs = "";
      return true;
    }
    return false;
  }

  const checkIfAnyModuleListIsInvalid = (modules1: UnitModuleAccessItem[], modules2: UnitModuleAccessItem[], moduleAccessChanges: ModuleAccessChanges) => {
    // Check if any module list is invalid compared to other
    if (!!modules1 && modules1.length > 0 && (modules2 == null || modules2 == undefined)) {
      moduleAccessChanges.reason = "Second of the given two Module lists is invalid";
      moduleAccessChanges.isAnyChanged = true;
      moduleAccessChanges.grantedModuleIDs = 
        modules1.filter(m => m.isUnitGranted ).map(m => m.moduleID.toString()).toString();
        moduleAccessChanges.differedModuleIDs = 
          modules1.map(m => m.moduleID.toString()).toString();
      return true;
    }
    // Check if any module list is invalid compared to other
    if (!!modules2 && (modules1 == null || modules1 == undefined)) {
      moduleAccessChanges.reason = "First of the given two Module lists is invalid";
      moduleAccessChanges.isAnyChanged = true;
      moduleAccessChanges.grantedModuleIDs = 
        modules2.filter(m => m.isUnitGranted ).map(m => m.moduleID.toString()).toString();
      moduleAccessChanges.differedModuleIDs = 
          modules2.map(m => m.moduleID.toString()).toString();
      return true;
    }
    return false;
  }

  const collectDifferedModules = (modules1: UnitModuleAccessItem[], modules2: UnitModuleAccessItem[], moduleAccessChanges: ModuleAccessChanges, grantedModulesFromFirst: boolean = false) => {

    // Iterate modules list1 and collect extra or different
    for (let i = 0; i < modules1.length; i++) {
      let curLoopModule = modules1[i];
      // Skip invalid modules of first list
      if (!!curLoopModule !== true) {
        continue;
      }
      // Get the already collected differed and granted modules array
      let curGrantedArr = moduleAccessChanges.grantedModuleIDs.split(",");
      let curDiffArr = moduleAccessChanges.differedModuleIDs.split(",");
      // Get matched modules from second list and compare access changes 
      // Collect all the differed modules and collect only the granted modules of only one list based on the given flag grantedModulesFromFirst
      let mod2Matched = modules2.filter((curModule) => !!curModule && !!curModule.moduleID && 
                          curModule.moduleID == curLoopModule.moduleID);
      // If there is a match found for the current module
      if (!!mod2Matched && mod2Matched.length > 0 && !!mod2Matched[0]) {
        let moduleIDTxtToAdd = curLoopModule.moduleID.toString();
        if (mod2Matched[0].isUnitGranted !== curLoopModule.isUnitGranted) {
            // If there is any access change
            moduleAccessChanges.isAnyChanged = true;
            if (!curDiffArr.includes(moduleIDTxtToAdd)) {
              moduleAccessChanges.differedModuleIDs = (moduleAccessChanges.differedModuleIDs.trim() === "") ? 
                    moduleIDTxtToAdd : moduleAccessChanges.differedModuleIDs + "," + moduleIDTxtToAdd;
            }
            // If the new granting is to the second list
            if (!grantedModulesFromFirst && (mod2Matched[0].isUnitGranted) && !curGrantedArr.includes(moduleIDTxtToAdd)) {
              moduleAccessChanges.grantedModuleIDs = (moduleAccessChanges.grantedModuleIDs.trim() === "") ? 
                    moduleIDTxtToAdd : moduleAccessChanges.grantedModuleIDs + "," + moduleIDTxtToAdd;
            }
            // If the new granting is to the first list
            if (grantedModulesFromFirst && (curLoopModule.isUnitGranted) && !curGrantedArr.includes(moduleIDTxtToAdd)) {
              moduleAccessChanges.grantedModuleIDs = (moduleAccessChanges.grantedModuleIDs.trim() === "") ? 
                    moduleIDTxtToAdd : moduleAccessChanges.grantedModuleIDs + "," + moduleIDTxtToAdd;
            }
        }
        else {
          // Take unmodified grant from the second list
          if (!grantedModulesFromFirst && ( mod2Matched[0].isUnitGranted) && !curGrantedArr.includes(moduleIDTxtToAdd)) {
            moduleAccessChanges.grantedModuleIDs = (moduleAccessChanges.grantedModuleIDs.trim() === "") ? 
                  moduleIDTxtToAdd : moduleAccessChanges.grantedModuleIDs + "," + moduleIDTxtToAdd;
          }
          // Take unmodified grant from the first list
          if (grantedModulesFromFirst && ( curLoopModule.isUnitGranted) && !curGrantedArr.includes(moduleIDTxtToAdd)) {
            moduleAccessChanges.grantedModuleIDs = (moduleAccessChanges.grantedModuleIDs.trim() === "") ? 
                  moduleIDTxtToAdd : moduleAccessChanges.grantedModuleIDs + "," + moduleIDTxtToAdd;
          }
        }
      }
      // If there is NO match found for the current module
      else {
        // If there is any access change
        moduleAccessChanges.isAnyChanged = true;
        let moduleIDTxtToAdd = curLoopModule.moduleID.toString();
        if (!curDiffArr.includes(moduleIDTxtToAdd)) {
          moduleAccessChanges.differedModuleIDs = (moduleAccessChanges.differedModuleIDs.trim() === "") ? 
                moduleIDTxtToAdd : moduleAccessChanges.differedModuleIDs + "," + moduleIDTxtToAdd;
        }
        // If the new granting is to the first list
        if (grantedModulesFromFirst && ( curLoopModule.isUnitGranted) && !curGrantedArr.includes(moduleIDTxtToAdd)) {
          moduleAccessChanges.grantedModuleIDs = (moduleAccessChanges.grantedModuleIDs.trim() === "") ? 
                moduleIDTxtToAdd : moduleAccessChanges.grantedModuleIDs + "," + moduleIDTxtToAdd;
        }
      }
    }
    return moduleAccessChanges;
  }

  // Compare the modules and collect
  const collectModuleAccessChanges = (modules1: UnitModuleAccessItem[], modules2: UnitModuleAccessItem[]) => {
    let moduleAccessChanges : ModuleAccessChanges = {
      isAnyChanged: false,
      grantedModuleIDs: "",
      differedModuleIDs: "",
      reason: ""
    };
    if (checkIfBothModuleListsAreInvalid(modules1, modules2, moduleAccessChanges)) {
      return moduleAccessChanges;
    }
    if (checkIfAnyModuleListIsInvalid(modules1, modules2, moduleAccessChanges)) {
      return moduleAccessChanges;
    }
    moduleAccessChanges = collectDifferedModules(modules1, modules2, moduleAccessChanges, false);
    moduleAccessChanges = collectDifferedModules(modules2, modules1, moduleAccessChanges, true);
    return moduleAccessChanges;
  }

  // Compare the courses and their modules and collect the access changes
  const collectCourseModuleAccessChanges = () => {    

    let courseAccessChanges : CourseModuleAccessChanges = {
      accessChangedCourseIDs: "",
      isAnyChanged: false,
      grantedModuleIDs: "",
      differedModuleIDs: "",
      reason: ""
    };   
    // Collect course and module access changes
    for (let i = 0; i < courseListOriginal.length; i++) {
      let curCourseOriginal = courseListOriginal[i];
      let curModuleAccessChanges : ModuleAccessChanges = {
        isAnyChanged: false,
        grantedModuleIDs: "",
        differedModuleIDs: "",
        reason: ""
      };
      let courseMatched = courseList.filter((curCourse) => !!curCourse && !!curCourse.courseID && curCourse.courseID == curCourseOriginal.courseID);
      
      if (!!courseMatched && courseMatched.length > 0 &&
               (((curModuleAccessChanges=collectModuleAccessChanges(curCourseOriginal.unitModuleAccessItems, courseMatched[0].unitModuleAccessItems)) != null &&
                  curModuleAccessChanges != undefined &&
                  curModuleAccessChanges.isAnyChanged
                ))) {
        let curCourseIDTxt = curCourseOriginal.courseID.toString();
        courseAccessChanges.isAnyChanged = true;
        courseAccessChanges.accessChangedCourseIDs = (courseAccessChanges.accessChangedCourseIDs.trim() === "") ? 
              curCourseIDTxt : courseAccessChanges.accessChangedCourseIDs + "," + curCourseIDTxt;
        courseAccessChanges.grantedModuleIDs = (courseAccessChanges.grantedModuleIDs.trim() === "") ? 
              curModuleAccessChanges.grantedModuleIDs : courseAccessChanges.grantedModuleIDs + "," + curModuleAccessChanges.grantedModuleIDs;
        courseAccessChanges.differedModuleIDs = (courseAccessChanges.differedModuleIDs.trim() === "") ? 
              curModuleAccessChanges.differedModuleIDs : courseAccessChanges.differedModuleIDs + "," + curModuleAccessChanges.differedModuleIDs;
      }
    }
    return courseAccessChanges;
  }

   // Compare the courses and their modules and collect the access changes
   const collectOriginalCourseModuleSelected = () => {    

    let SelectedCourseAccess = {
      selectedCourseIDs: "",
      selectedModuleIDs: ""
    };   
    // Collect course and module access changes
    for (let i = 0; i < courseListOriginal.length; i++) {
      let curCourseOriginal = courseListOriginal[i];
      if (curCourseOriginal.isAnyUnitGranted) 
      {
          SelectedCourseAccess.selectedCourseIDs = (SelectedCourseAccess.selectedCourseIDs.trim() === "") ? 
          curCourseOriginal.courseID.toString() : SelectedCourseAccess.selectedCourseIDs + "," + curCourseOriginal.courseID.toString();
          for (let i = 0; i < curCourseOriginal.unitModuleAccessItems.length; i++) {
            let curModuleOriginal = curCourseOriginal.unitModuleAccessItems[i];
            if (curModuleOriginal.isUnitGranted) 
            {
              SelectedCourseAccess.selectedModuleIDs = (SelectedCourseAccess.selectedModuleIDs.trim() === "") ? 
              curModuleOriginal.moduleID.toString() : SelectedCourseAccess.selectedModuleIDs + "," + curModuleOriginal.moduleID.toString();
            }
          }
      }     
    }
    return SelectedCourseAccess;
  }

  const setValues = (list: any, value: string) => {
    let separator = ",";
    let values = list.split(separator);
    //let resultarr = [];
    for (let i = 0 ; i < values.length ; i++) {
      if (values[i] !== value) {
       // resultarr.add(values[i]);
        values.splice(i, 1);        
      }
      return values.join(separator);
    }
    return list;
  }

  const handleSaveModuleAccess = () => {
    // Save
    setShowResponseModal(false);
    setIsSaving(true)
    setSaveClicked(true)
    let courseAccessChanges = collectCourseModuleAccessChanges();
    let finalExistingSelectedCourses = "";
    let finalExistingSelectedModules = "";
    let originalSeletedCourseAndModules;

    if (assignSubunit) {
      originalSeletedCourseAndModules = collectOriginalCourseModuleSelected();    
      if (courseAccessChanges.isAnyChanged)
      {
        if (courseAccessChanges.accessChangedCourseIDs.length > 0)
        {
          var values = courseAccessChanges.accessChangedCourseIDs.split(",");
          let tmpList =  originalSeletedCourseAndModules.selectedCourseIDs;
          let arrTmpCourse = tmpList.split(",");
          for (let m = 0 ; m < values.length ; m++) {
            arrTmpCourse = arrTmpCourse.filter(item => item !==  values[m])
          }
          finalExistingSelectedCourses = arrTmpCourse.join(",");
        }
        if (courseAccessChanges.differedModuleIDs.length > 0)
        {
          var values = courseAccessChanges.differedModuleIDs.split(",");
          let tmpListmodule = originalSeletedCourseAndModules.selectedModuleIDs;
          let arrTmp = tmpListmodule.split(",");          

          for (let m = 0 ; m < values.length ; m++) {
            arrTmp = arrTmp.filter(item => item !==  values[m])
          }
          finalExistingSelectedModules = arrTmp.join(",");
        }
        if (courseAccessChanges.grantedModuleIDs.length > 0)
        {
          var values = courseAccessChanges.grantedModuleIDs.split(",");
          let tmpListmodule = finalExistingSelectedModules !== "" ? finalExistingSelectedModules : originalSeletedCourseAndModules.selectedModuleIDs;
          let arrTmp = tmpListmodule.split(",");          

          for (let m = 0 ; m < values.length ; m++) {
            arrTmp = arrTmp.filter(item => item !==  values[m])
          }
          finalExistingSelectedModules = arrTmp.join(",");  
        }
                
      finalExistingSelectedCourses =  finalExistingSelectedCourses.substring(finalExistingSelectedCourses.length - 1,finalExistingSelectedCourses.length)== "," ? finalExistingSelectedCourses.substring(0, finalExistingSelectedCourses.length - 1) : finalExistingSelectedCourses;
      finalExistingSelectedModules =  finalExistingSelectedModules.substring(finalExistingSelectedModules.length - 1,finalExistingSelectedModules.length)== "," ? finalExistingSelectedModules.substring(0, finalExistingSelectedModules.length - 1) : finalExistingSelectedModules;
           
      }
      else
      {
        finalExistingSelectedCourses = originalSeletedCourseAndModules.selectedCourseIDs;
        finalExistingSelectedModules = originalSeletedCourseAndModules.selectedModuleIDs;
      }
    }

    if ((!!courseAccessChanges && !!courseAccessChanges.accessChangedCourseIDs && !!courseAccessChanges.isAnyChanged) || assignSubunit) {
       saveUnitMultiCourseModuleAccess(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(unitIdToEdit),
       assignSubunit ? (finalExistingSelectedCourses.trim() === "" ? courseAccessChanges.accessChangedCourseIDs : finalExistingSelectedCourses + "," + courseAccessChanges.accessChangedCourseIDs) : courseAccessChanges.accessChangedCourseIDs, 
       assignSubunit ? (finalExistingSelectedModules.trim() === "" ? courseAccessChanges.grantedModuleIDs : finalExistingSelectedModules + "," + courseAccessChanges.grantedModuleIDs) : courseAccessChanges.grantedModuleIDs, 
       assignSubunit).  
      then((courseListSaveRes) => {
        if (!!courseListSaveRes) {
          let isSuccess = !!courseListSaveRes.isSuccess && courseListSaveRes.isSuccess;
          if (!!isSuccess && isSuccess) {                  

                let checkIfStatusUpdateRequired: number = 0;     
                if (!!courseAccessChanges.accessChangedCourseIDs && courseAccessChanges.accessChangedCourseIDs.trim() != '')
                {
                  checkIfStatusUpdateRequired = courseAccessChanges.accessChangedCourseIDs.split(',').length;
                }
                // Status update is now part of the saveUnitMultiCourseModuleAccess API itself, hence commented the below call
                // // Update status for saved Courses
                // if (checkIfStatusUpdateRequired > 0) {                 
                //   TriggerStatusUpdateUnitAndCourseBased(oidcUser.access_token, userId, orgDomain, parseInt(orgId),  unitIdToEdit, courseAccessChanges.accessChangedCourseIDs);
                // }
                cloneGivenListAndSetOriginalCourseList(courseList);  
            }
            let isStatusUpdateFailed = (isSuccess && courseListSaveRes.statusUpdateError != null && courseListSaveRes.statusUpdateError != undefined &&
                courseListSaveRes.statusUpdateError.trim() !== "");

            loadUnitComplianceReport();
            setSaveResultIsWarning(isSuccess ? isStatusUpdateFailed : isSuccess);
            setSaveResultTitle(isSuccess ? "Success" : "Failed")
            setSaveResultMessage(isSuccess ? ("Your selected course/module accesses have been saved." + (isStatusUpdateFailed ? " But status updated is failed!" : "")) : "Failed to save selected course/module accesses!" +
                (!!courseListSaveRes.error && courseListSaveRes.error.trim() != "" ? (" Reason: " + courseListSaveRes.error) : ""))
            setSaveSuccess(isSuccess);
            setSaveClicked(false)
            setExpandUnitComplianceList(false);
            setShowResponseModal(true);
            setIsChanged(false);

        }
        else {
          setSaveResultIsWarning(false);
          setSaveResultTitle("Failed")
          setSaveResultMessage("Failed to save selected course/module accesses!" + (" Reason: Invalid result returned from save API"))
          setSaveSuccess(false);
          setSaveClicked(false)
          setShowResponseModal(true);
          setIsChanged(true);
        }      
        setIsSaving(false)
      }).catch((error) => {
        setSaveResultIsWarning(false);
        setSaveResultTitle("Failed")
        setSaveResultMessage("Failed to save selected course/module accesses!" + (!!error && error.toString().trim() != "" ? (" Reason: Exception while saving as " + error) : ""))
        setSaveSuccess(false);
        setSaveClicked(false)
        setShowResponseModal(true);
        setIsSaving(false)
        setIsChanged(true);
      });
    }
    else {
      if (!!courseAccessChanges && !courseAccessChanges.isAnyChanged) {
        setSaveResultIsWarning(true);
        setSaveResultTitle("No changes")
        setSaveResultMessage("Please change any course/module access to save")
        setSaveSuccess(false);
        setSaveClicked(false)
        setShowResponseModal(true);
        setIsChanged(false);
      }
      setIsSaving(false)
    }
  }

  const onAccordianChange = (event: any, expanded: boolean) => {
    if (!!event && !!event.currentTarget && !!event.currentTarget.ariaExpanded) {
      event.currentTarget.ariaExpanded = !!expanded ? "true" : "false";
    }
  }

  const handleClosePopup = () => {    
    setShowResponseModal(false);
  };

  const isAnyChanged = () => {
    let courseAccessChanges = collectCourseModuleAccessChanges();
    if ((!!courseAccessChanges && !!courseAccessChanges.accessChangedCourseIDs &&
          !!courseAccessChanges.isAnyChanged && courseAccessChanges.isAnyChanged)){
            return true;
    }
    return false;
  }
  const handleUnitComplianceExpandClick = () => {   	
    setExpandUnitComplianceList(!expandUnitComplianceList);
  }
  if (loading) return <LoaderContainer />;

  return (
    <>
      
      <Modal type = "warning" modalOpen={showModal} setModalOpen={handleModal} style={{height:'232px', width:'381px'}}
            hideCloseBtn={true}>
          <div style={{position:'relative', top:'-30px'}}>
              <h3 style={{color:'#e7a652'}}>Unsaved Changes</h3>
              <p>Do you want to proceed without saving changes?</p>
          </div>
          <div style={{display:'flex', justifyContent:'center', position:'relative', top:'10px'}}> 
            <CustomTooltip displayText={["Return"]}>
              <span className={styles.returnIcon} >
                <span
                    onClick={() => 
                      {
                        setModalOpen(false)
                        setIsLoggingOut(false)
                      }}                    
                  >
                    <CloseIcon />
                </span>
              </span>            
            </CustomTooltip>
            <CustomTooltip displayText={["Proceed"]}>
              <span className={styles.proceedIcon}>
                <span
                    onClick={() => {
                      if(IsLoggingOut) {
                        setMessage(allResourcesToTranslations.loggingout) 
                        logout(); 
                        addError(oidcUser.access_token, userId, orgDomain, "Logged out", null, "UserControls.tsx", 6, EnableActivityLogs)
                        getUserManager().signoutRedirect()
                              .catch((error) => {
                                //TODO Error API here
                              });
                        getUserManager().signoutRedirectCallback()
                              .then(() => {
                              getUserManager().removeUser()
                              })
                              .catch((error) => {
                                //TODO Error API here
                              });
                      } else{
                        setSaveClicked(false)
                        setIsUserModuleAccessChangedAndNotSaved(false); 
                        setIsSaving(false);
                        setGoBack(true)
                      }
                    }}                    
                  >
                    <ProceedIcon />
                </span>
              </span>              
            </CustomTooltip>                
          </div>
      </Modal>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }}
      />
      <CustomTooltip displayText={["Guide Me"]}>
        <span
          onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific_UserModuleAccess}
        >
          <GuideIcon />
        </span>
      </CustomTooltip>

      <div style={{paddingTop:"20px", paddingBottom: "20px"}}>
          <div className={"unitDetails"} style={{display:'flex', justifyContent:'center', alignItems:'center', border:'1px solid #D3D6DF', height:'42px', borderRadius:'5px'}}>
          <div>
              <p>{allResourcesToTranslations.unitid}: {unitIdToEdit}&nbsp;|&nbsp;{allResourcesToTranslations.unitpathwaydetails}: {pathway}</p>            
            </div>
            <div className="checkInactive">
                <Checkbox
                  size="small"
                  disabled
                  color="primary"
                  checked={unitActive}
                  className={styles.checkBox}
                  onClick={() => setUnittActive(!unitActive)}
                />{" "}
                <span className={unitActive ? styles.accountActiveText : styles.accountInactiveText} >{unitActive ? allResourcesToTranslations.activeunit : allResourcesToTranslations.inactiveunit}</span>
           </div>
          </div>         
        </div>    

        <div id="subunitDetails" style={{fontWeight:"bold"}}>
                <Checkbox                
                  size="small"                  
                  color="primary"
                  checked={assignSubunit}
                  className={styles.checkBox}
                  onClick={() => setAssignSubunit(!assignSubunit)}
                />{allResourcesToTranslations.assigntoallsubunits}
               
        </div>

        <div id="attentionInfo" className={styles.icon_and_text_container_leftshift}>
                <div className={styles.icon_and_text_container_icon}>
                <ErrorIcon></ErrorIcon>
                </div>
                <div className={styles.icon_and_text_container_text}>
                  <div>
                      <span style={{fontWeight: "normal", color: "red", fontSize:"12px", fontStyle: "normal", marginTop:"20px"}}>
                        {allResourcesToTranslations.unitsavemoduleaccesnotes}</span>
                  </div>
                </div>
            </div>    
      <BrandedCard>
        <div style={{ justifyContent: "space-between", padding: "20px 0px 20px 20px"}}>
          <div style={{display: "grid"}}>
            <span style={{fontWeight: "bold", fontSize: "15px", marginTop: "10px"}}>{allResourcesToTranslations.courseenrolments}</span>
            
            <div className={styles.search_longtextbox_and_buttons_container} style={{ paddingTop: "15px"}}>  
                <div id="courseModuleNameSearch" className={styles.courseModuleNameSearch}>
                  <TextField style={{width: "100%"}}
                  label={"Course/Module Name Search"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                  value={filterCourseOrModuleName} onChange={(e) => setFilterCourseOrModuleName(e.target.value)} 
                  InputProps={{endAdornment: ( <IconButton><SearchIcon style={{height: "20px"}} /></IconButton> )}}/>
                  </div>          
              <div className={styles.courseModuleNameButtons}>
                <span>
                  <CustomTooltip displayText={[allResourcesToTranslations.selectall]}>
                    <Button id="selectAllButton" variant="contained" color="primary" onClick={handleAllCourseSelection}>
                      <SelectAllIcon />
                    </Button>
                  </CustomTooltip>
                </span>
                <span style={{paddingRight: "2px"}}>
                  <CustomTooltip displayText={[allResourcesToTranslations.unselectall]}>
                    <Button id="unselectAllButton" variant="contained" color="primary" onClick={handleAllCourseUnselection}>
                      <UnSelectAllIcon />
                    </Button>
                  </CustomTooltip>
                </span>
              </div>
              <div style={{clear: "both"}}></div>
            </div>
          </div>
        </div>

        <div id="orgGrantedCourseList" style={{overflowY:"scroll", scrollbarWidth:"auto", maxHeight: "400px", borderTop: "3px solid #D3D6DF" }}>
          {courseList.length > 0 && courseList.map((course) => {
            return (!!course && (course.isSearchMatch || (!!course.unitModuleAccessItems && course.unitModuleAccessItems.length > 0 &&
                          course.unitModuleAccessItems.some((m) => !!m && m.isSearchMatch)))) ? 
            (
              
              <Accordion key={course.courseID.toString() + course.courseName.toString()} expanded={course.expanded} aria-expanded={course.expanded}
                        style={ course.expanded ? 
                        {backgroundColor: "#E0E0E0", marginTop: "-14px", marginBottom: "16px"} : {backgroundColor: "#FFFFFF"}}
                        onChange={(event, isexpanded) => { onAccordianChange(event, isexpanded); }}
                        >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content" 
                  id="panel1a-header"
                  onClick={(e) => handleCourseExpandClick(e, course)}           
                >
                  <Checkbox size="small" id="unitBasedAccessIndication"
                            checked={course.areAllModulesGranted || course.isAnyUnitGranted}
                            indeterminate={!!course && course.areAllModulesGranted == false && (course.isAnyUnitGranted)}
                            onClick={(e) => { e.stopPropagation(); handleCourseSelect(course); }}
                            color="primary" style={{marginTop: "-5px"}}/>
                  <Typography style={(course.isAnyUnitGranted) ? {marginTop:'6px', fontWeight: "bold"} : {marginTop:'6px'}}>
                    {course.courseName}</Typography>                  
                </AccordionSummary>                
                {
                  course.unitModuleAccessItems.length > 0 && course.unitModuleAccessItems.map((module) => {
                    return (
                      <>
                        <span key={module.moduleID.toString() + module.moduleName.toString()} 
                              style={{display: "inline", marginLeft: "30px", marginTop: "-20px"}}>
                            <Checkbox size="small" id={course.expanded ? "moduleSelectionCheckbox" : "_moduleSelectionCheckbox"}
                                      checked={module.isUnitGranted}
                                      onClick={() => handleModuleSelect(module, course)}
                                      color="primary"/>
                            <span style={( module.isUnitGranted) ? {marginTop:'6px', fontWeight: "bold"} : {marginTop:'6px'}}>
                              {module.moduleName}</span>
                        </span> 
                        <br />
                      </>
                    )
                  })
                }                
            </Accordion>
            ) :
            (<></>)
          })}
          
        </div>
      </BrandedCard>
      <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", paddingTop: "10px"  }}>
        <CustomTooltip displayText={[allResourcesToTranslations.saveusermoduleaccess]}>
          <Button id="saveButton" variant="contained" color="primary" onClick={handleSaveModuleAccess} disabled={isSaving || (isAnyChanged() || assignSubunit) === false}>
            <SaveIcon />
          </Button>
        </CustomTooltip>
        <div className={styles.loaderContainer}>
          <CircularProgress id="saveProgress"
            style={{ width: isSaving ? "20px" : "0px" }}
            color="primary" 
            size="10"
            />
        </div>
      </div>
      <div style={{ paddingTop: "20px"}}>         
          {unitComplianceListOpen &&
            <Accordion style={{ margin: "0px 0px", position: 'inherit', boxShadow: "none" }}
                expanded={expandUnitComplianceList}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="unitadmin-content"
                id="unitadmin-header"   
                style={{borderBottom: '1px solid #D3D6DF', padding:"0px"}}        
                onClick={(e) => handleUnitComplianceExpandClick()}
              >
              
              <Typography ><b>{allResourcesToTranslations.unitcompliancereport}</b></Typography>
              </AccordionSummary>
              <AccordionDetails style={{padding: "0px"}}>              
                {unitComplianceListOpen && unitComplianceList != null && unitComplianceList.length > 0 && (
                  <div id="unitComplianceReportList" style={{width: "100%"}}>
                    <UnitComplianceReportSection unitComplianceData={unitComplianceList}/>
                  </div>
                )}         
              </AccordionDetails>
            </Accordion>
            }
        </div>     

       {showResponseModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={!saveResultIsWarning} handleClose={handleClosePopup} titleText={saveSuccess ? saveResultTitle : !saveResultIsWarning ? 'Error' : 'Warning'} bodyText={saveResultMessage} />}
    </>
  );
};

export default UnitSetUnitModuleAccess;

