import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const UnitSettingAddUser = () => (    
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M10.6154 0C16.478 0 21.2308 4.7528 21.2308 10.6154C21.2308 11.1962 21.1841 11.7662 21.0944 12.3217C20.5408 11.9218 19.9265 11.601 19.2679 11.3755C19.2896 11.125 19.3007 10.8715 19.3007 10.6154C19.3007 5.8182 15.4126 1.93007 10.6154 1.93007C5.8182 1.93007 1.93007 5.8182 1.93007 10.6154C1.93007 12.5956 2.59304 14.4215 3.70959 15.8826C5.299 13.8512 7.77336 12.5455 10.5536 12.5455C11.1811 12.5455 11.7931 12.612 12.3829 12.7385C11.8141 13.238 11.327 13.8282 10.9442 14.4866C10.815 14.4793 10.6847 14.4755 10.5536 14.4755C8.29158 14.4755 6.28914 15.5873 5.06258 17.2944C6.44901 18.448 8.20048 19.1784 10.118 19.2867C10.2459 19.9749 10.4744 20.6278 10.7878 21.2294C10.7305 21.2303 10.673 21.2308 10.6154 21.2308C4.7528 21.2308 0 16.478 0 10.6154C0 4.7528 4.7528 0 10.6154 0ZM6.75545 7.71941C6.75545 9.85117 8.48383 11.5796 10.6156 11.5796C12.7474 11.5796 14.4757 9.85117 14.4757 7.71941C14.4757 5.58765 12.7474 3.85927 10.6156 3.85927C8.48383 3.85927 6.75545 5.58765 6.75545 7.71941ZM8.68552 7.71941C8.68552 8.78578 9.54923 9.64948 10.6156 9.64948C11.682 9.64948 12.5457 8.78578 12.5457 7.71941C12.5457 6.65305 11.682 5.78934 10.6156 5.78934C9.54923 5.78934 8.68552 6.65305 8.68552 7.71941Z" fill="#FFFFFF"/>
<circle cx="17" cy="18" r="5.5" stroke="#FFFFFF"/>
<rect x="16.5" y="16" width="1" height="4" fill="#FFFFFF"/>
<rect x="19" y="17.5" width="1" height="4" transform="rotate(90 19 17.5)" fill="#FFFFFF"/>
</svg>


);

export default UnitSettingAddUser
