import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 
import { Icon } from '@material-ui/core';

export const HardDiskIcon = () => (

<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.7871 12.6399H2.78711" stroke="#21394F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.23711 5.74989L2.78711 12.6399V18.6399C2.78711 19.1703 2.99782 19.679 3.3729 20.0541C3.74797 20.4292 4.25668 20.6399 4.78711 20.6399H20.7871C21.3175 20.6399 21.8262 20.4292 22.2013 20.0541C22.5764 19.679 22.7871 19.1703 22.7871 18.6399V12.6399L19.3371 5.74989C19.1715 5.41668 18.9163 5.13626 18.6001 4.94017C18.2838 4.74408 17.9192 4.64009 17.5471 4.63989H8.02711C7.65503 4.64009 7.29037 4.74408 6.97415 4.94017C6.65793 5.13626 6.40269 5.41668 6.23711 5.74989V5.74989Z" stroke="#21394F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.7871 16.6399H10.7971" stroke="#21394F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.78711 16.6399H6.79711" stroke="#21394F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);

export default HardDiskIcon
