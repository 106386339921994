import React from "react";

const ManagementIcon = () => {

    return (
<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.417 30.083a12.667 12.667 0 1 0 0 -25.333 12.667 12.667 0 0 0 0 25.333Z" stroke="#21394F" stroke-width="3.1666666666666665" stroke-linecap="round" stroke-linejoin="round"/><path d="m33.25 33.25 -6.887 -6.887" stroke="#21394F" stroke-width="3.1666666666666665" stroke-linecap="round" stroke-linejoin="round"/></svg>
    

    )
}

export default ManagementIcon;