import React, { useContext } from "react";
import { UIContext } from "../../contexts/UIContext";
import styles from "../../styles/sideMenu.module.css";

interface Props {
    sectionName: string,
}

export const SideMenuDivider = ({ sectionName }: Props) => {
    const { sideMenuOpen } = useContext(UIContext);

    return (
        <div className={ styles.sideMenuDivider }>
           <span> { sideMenuOpen ? sectionName : "‎"} </span>
        </div>
    )
}

export default SideMenuDivider;
