import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const SSOGoogleIcon = () => (
<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_66_131)">
<rect x="3" y="3" width="40" height="40" rx="8" fill="#63C2FF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M23 21.28V25.39H29.81C29.54 27.15 27.75 30.57 23 30.57C18.9 30.57 15.56 27.18 15.56 22.99C15.56 18.8 18.9 15.41 23 15.41C25.33 15.41 26.89 16.4 27.78 17.26L31.04 14.12C28.95 12.18 26.24 11 23 11C16.36 11 11 16.36 11 23C11 29.64 16.37 35 23 35C29.63 35 34.52 30.13 34.52 23.27C34.52 22.48 34.43 21.88 34.33 21.28H23Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_66_131" x="0" y="0" width="50" height="50" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="2"/>
<feGaussianBlur stdDeviation="2.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_66_131"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_66_131" result="shape"/>
</filter>
</defs>
</svg>


);

export default SSOGoogleIcon