//React + Typescript
import React, { useEffect, useState, useContext } from 'react';
import { UnitTreeItem, CourseTreeItem } from '../../globalTypes';
import { useHistory } from 'react-router-dom';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Steps, Hints } from "intro.js-react";
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import oidcConfig from '../../configuration';
//API Functions
import { getFullSentEmailReport } from '../../API/GetFullSentEmailReport' 
import { addError } from '../../API/AddError'
//Components
import LoaderContainer from '../../Components/LoaderContainer';
import BrandedCard from '../../Components/BrandedCard'
import ButtonPanel from '../CurrentAdminReport/ButtonPanel'
import CustomTooltip from '../../Components/CustomTooltip'
import GuideIcon from '../../CustomIcons/GuideIcon';
//MUI Components
import TextField from '@material-ui/core/TextField';
//Styles
import styles from '../../styles/currentAdminReport.module.css'


interface Props {    
}

const SentEmailReport = (props: Props) => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [filterfromDate, setFromDate] = useState("");
    const [filtertoDate, setToDate] = useState("");
    const [filterBody, setFilterBody]  = useState("");
    const [filterSubject, setFilterSubject] = useState("");    
    const [filterEmail, setFilterEmail] = useState("");
    const [filterEmailId, setFilterEmailId] = useState<number>(0)

    const date = new Date()
    const values = {
        defaultDate: date
    };

    const { setMessage } = useContext(LoaderContext);
    const { setTitle, setTitleEnglish, updateBackButtonRoute } = useContext(UIContext);
    const { oidcUser } = useReactOidc();
    const { profile: { user_id, website }} = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const history = useHistory(); 

    const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
    const { showSSOExitButton, EnableActivityLogs} = useContext(UIContext);
    const orgDomainWithAltLogin = window.location.host.toLowerCase();
    const [intro, setIntro] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element:  ".dateFrom",
            title: allResourcesToTranslations.emrepgmfdft,
            intro: allResourcesToTranslations.emrepgmfdfm,
          },      
        ]
      })

      useEffect(() => 
      {
        let updateSteps = [...intro.steps]
        updateSteps.push( {
            element:  ".dateTo",
            title: allResourcesToTranslations.emrepgmfdtt,
            intro: allResourcesToTranslations.emrepgmfdtm,
        },
        {
            element:  ".email",
            title: allResourcesToTranslations.emrepgmfet,
            intro: allResourcesToTranslations.sntemrepgmmem,
        } ,
        {
            element:  ".subject",
            title: allResourcesToTranslations.sntemrepgmtsub,
            intro: allResourcesToTranslations.sntemrepgmmsub,
        },
        {
            element:  ".body",
            title: allResourcesToTranslations.sntemrepgmtbdy,
            intro: allResourcesToTranslations.sntemrepgmmbdy,
        },
        {
            element:  ".runReport",
            title: allResourcesToTranslations.sntemrepgmtrunrep,
            intro: allResourcesToTranslations.sntemrepgmmrunrep,
        },
        {
            element:  ".resetAll",
            title: allResourcesToTranslations.archiveresettitle,
            intro: allResourcesToTranslations.emrepgmram,
        }         
        )
        setIntro({...intro, steps: [...updateSteps]})              
    },[])
      
      const onExit = () => {
        setIntro({...intro, stepsEnabled: false });
      };
    const resetAll = () => {
       
    }   

    const runReport = () => {
        let unitIds: Array<number> = []
        let courseIds: Array<number> = []

        const toEmail: string =  filterEmail ?? ""
        const subject: string =  filterSubject ?? ""
        const body: string = filterBody?? ""       
        const emailid: number = filterEmailId ?? 0   
        setMessage("Fetching your report");
        setLoading(true)

        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started SentEmail Report in NEWSALT ClientApp", null, "SentEmailReport.tsx", 6, EnableActivityLogs);

        getFullSentEmailReport(oidcUser.access_token,
            userId, 
            orgDomain, 
            parseInt(orgId), 
            filterfromDate,
            filtertoDate,
            toEmail,
            subject,
            body, 
            emailid
            ).then((result) => {                
                setLoading(false)
                history.push({
                    pathname: `sentemailreport/result`,
                    state: {  
                            inputFilterFromDate: (result === null ? null : result.givenFilterFromDate),
                            inputFilterToDate: (result === null ? null : result.givenFilterToDate),
                            inputFilterToEmail: (result === null ? null : result.givenFilterToEmail),
                            inputFilterSubject: (result === null ? null : result.givenFilterSubject),
                            inputFilterBody: (result === null ? null : result.givenFilterBody),
                            resultItems: (result === null ? null : result.sentemailreportItem),
                            resultError: ((result === null || result === undefined || result.isSuccess || result.error === null || result.error === undefined) ? "" : result.error)
                    },
           });
       })
      
    }

    const parseAdminConsoleUrl = (url: string): string => {
        if (url === "https://" + oidcConfig.stagingdomain || url.includes('localhost')) {
            return "https://" + oidcConfig.stagingdomain + ":444";
        } else if (url === "https://" + oidcConfig.stagingdomainSA || url.includes('localhost')) {
            return "https://" + oidcConfig.stagingdomainSA + ":444";
        } else if (url === "https://" + oidcConfig.stagingdomainForDeactTest || url.includes('localhost')) {
            return "https://" + oidcConfig.stagingdomainForDeactTest + ":444";
        } else {
            if (showSSOExitButton && orgDomainWithAltLogin.toLowerCase().indexOf("altlogin-") < 0) {
                if (url.toLowerCase().includes('https:')) {
                    return `${url}:444`;
                }
                else if  (url.toLowerCase().includes('http:')) {
                    var locUrl = url.toLowerCase().replace("http:", "https:")
                    return `${locUrl}:444`;
                }
                else {
                    return `https://${url}:444`;
                }
            }
            else {
                return `${url}:444/altlogin.aspx`;
            }
        }
    }

    const setModalOpen = (isOpen: boolean) => 
    {
        const adminConsoleUrl = parseAdminConsoleUrl("https://" + orgDomain)
        addError(oidcUser.access_token, userId, orgDomain, "Opened admin console", null, "Dashboard.tsx", 6, EnableActivityLogs)
        window.open(adminConsoleUrl + "/Reporting/Advanced/EmailSentReport.aspx", "_blank") //TODO need to redirect user to correct admin console
        setShowModal(isOpen)
    }

    const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
    {
        if (givenDate === null || givenDate === undefined)
        {
            return "";
        }

        const year = new Intl.DateTimeFormat('en-AU', {year: 'numeric'}).format(givenDate);
        const month = new Intl.DateTimeFormat('en-AU', {month: 'numeric'}).format(givenDate);
        const day = new Intl.DateTimeFormat('en-AU', {day: 'numeric'}).format(givenDate);

        return (year.toString() + "-" + 
        (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" + 
        (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
    }

    useEffect(() => {
        setTitle(allResourcesToTranslations.sentemailreport)
        setTitleEnglish(pageTitles.sentemailreport)
        updateBackButtonRoute("");
        var curDate = formatDate(new Date()); // in yyyy-MM-dd format
        setFromDate(curDate)        
        setToDate(curDate)   
        setLoading(false)
    },[]) 

    if (loading) return <LoaderContainer/>   
    return (
        <> 
            <Steps enabled={intro.stepsEnabled} steps={intro.steps} initialStep={intro.initialStep} onExit={onExit}
            options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
            }} />      
            <CustomTooltip displayText={[allResourcesToTranslations.guideme]}>               
                <span onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
                        <GuideIcon />
                    </span>
            </CustomTooltip>    
            <BrandedCard>               
                <div className={styles.reportWrraper}>
                    <div className={styles.unittreesection}>
                        <h4 className={styles.sectionTitle}>{allResourcesToTranslations.sntemrepgmmseldtsl}</h4>      
                        <div className={styles.inputContainer}>
                            <div className="dateFrom"><TextField  className={styles.input}  label={allResourcesToTranslations.sntemrepgmmdtfrml} variant="outlined" autoComplete="on"  InputLabelProps={{shrink: true,}}                    
                            type="date" onChange={(e) => setFromDate(formatDate(new Date(e.target.value)))}  defaultValue={formatDate(values.defaultDate)} id="dateFromInputNewSaltSERReport" />   </div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="dateTo"><TextField  className={styles.input}  label={allResourcesToTranslations.sntemrepgmmdttol} variant="outlined" autoComplete="on"  InputLabelProps={{shrink: true,}}                      
                            type="date" onChange={(e) => setToDate(formatDate(new Date(e.target.value)))}  defaultValue={formatDate(values.defaultDate)} id="dateToInputNewSaltSERReport" /> </div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="email"><TextField  className={styles.input}  label={allResourcesToTranslations.sntemrepgmmreceml} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                            value={filterEmail} onChange={(e) => setFilterEmail(e.target.value)}/></div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="subject"><TextField   className={styles.input}  label={allResourcesToTranslations.emrepprvgmtsub} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}} 
                            value={filterSubject} onChange={(e) => setFilterSubject(e.target.value)} /></div>
                        </div>
                        <div className={styles.inputContainer}>
                        <div className="body"> <TextField  className={styles.inputarea} label={allResourcesToTranslations.sntemrepgmmbdyl} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}} 
                            multiline  value={filterBody} onChange={(e) => setFilterBody(e.target.value)} /></div>
                        </div>                        
                        <div style={{float:'left'}}>
                            <ButtonPanel openReportSchedulerModal={setModalOpen} runReport={runReport}  resetAll={resetAll}/>
                        </div>
                    </div>
                    <div className={styles.coursetreesection}>                                              
                    </div>    
                </div>
            </BrandedCard>
        </>
    )
}

export default SentEmailReport
