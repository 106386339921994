import React from 'react'
import styles from "../../styles/accountSettings.module.css";

interface Props {
    firstName: string;
    lastName: string;
    swapFirstLastNames: boolean
}

export const NameDisplay = ({ firstName, lastName, swapFirstLastNames}: Props) => {
    return (
        <span className={styles.nameDisplayText}>
          { !swapFirstLastNames? `${firstName} ${lastName}` : `${lastName} ${firstName}` }
        </span>
    )
}

export default NameDisplay;
