import { Button, ButtonGroup, Checkbox, IconButton, TextField } from '@material-ui/core';
import React,{useState, useEffect} from 'react';
import SelectAllIcon from '../../CustomIcons/SelectAllIcon';
import UnSelectAllIcon from '../../CustomIcons/UnSelectAllIcon';
//Styles
import styles from '../../styles/currentAdminReport.module.css'
//MUI Components
import SearchIcon from "@material-ui/icons/Search";

interface Props {
    selectAllPolicies: Function
    deselectAllPolicies: Function
    orgPolicyOptions: any
    updatePolicyOptions: Function
    handlePolicySearch: any
    searchPolicy: string
    sectionFlag: boolean
}

const PolicySelection = ({ selectAllPolicies,searchPolicy,handlePolicySearch, deselectAllPolicies, orgPolicyOptions, updatePolicyOptions, sectionFlag }: Props) => {

    //const [searchPolicy, setSearchPolicy] = useState("");
    const [policies, setPolicies] = useState<Array<any>>(orgPolicyOptions);

    const handleSelectAll = () => {
        selectAllPolicies()
    }

    const handleDeselect = () => {
        deselectAllPolicies()
    }

    useEffect(() => {
        setPolicies(orgPolicyOptions);
        //updatePolicyOptions(orgPolicyOptions);
    },[orgPolicyOptions])

    useEffect(() => {
       if(policies.length > 0) {
        let filteredData = policies.map((item:any) => {
            if(!item.policyName.toLowerCase().includes(searchPolicy.toLowerCase())) {
                return {
                    ...item,
                    isHidden : true
                }
            }else {
                return {
                    ...item,
                    isHidden: false
                }
            }
            return item;
        })

        setPolicies(filteredData);
        updatePolicyOptions(filteredData);
       }
    },[searchPolicy])

    const handleSelection = (event:any,policy: any) => {
        event.stopPropagation();

        let updatePolicies = policies.map((item:any) => {
            if(policy.policyID == item.policyID) {
                return {
                    ...item,
                    isSelected: !item.isSelected
                }
            }

            return item
        })
        setPolicies(updatePolicies);
        updatePolicyOptions(updatePolicies);
    }


    return (
        <div>
            <div>
                    <h4 className={styles.sectionTitle}>Policy</h4>
                    <div className={sectionFlag ? "policyFilterButtons" : "policyFilterButtons1" } style={{marginTop:'1px'}}>
                    <p className={styles.blankContent}>Leave blank for all Policies</p>
                    <ButtonGroup variant="contained" color="primary" aria-label="Filter">
                <Button
                    className={styles.button}
                    size="small"
                    onClick={handleSelectAll}
                    
                    >

                        <SelectAllIcon />
                        <span style={{marginLeft:'5px',fontSize:'12px'}}>{` Select All` /*TODO Add translation */}</span>
                </Button>
                <Button 
                    className={styles.button} 
                    size="small"
                    onClick={handleDeselect}
                    >
                        <UnSelectAllIcon />
                        <span style={{marginLeft:'5px',fontSize:'12px'}}>{"Unselect All" /*TODO Add translation */}</span>
                </Button>
            </ButtonGroup>
                    </div>
                </div>
                <div className={`${styles.inputContainer}` }>
                    <div className={sectionFlag ? "policySearch" : "policySearch1"}><TextField  className={styles.input} label={"Policy Search"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                    value={searchPolicy} onChange={handlePolicySearch} 
                    InputProps={{endAdornment: ( <IconButton><SearchIcon /></IconButton> )}}/></div>
                </div>
            <div className={styles.policyContainer}><ul className={styles.PolicyUl}>
            { policies && policies.map((policy:any) => (
                <li className={styles.treeLi} hidden={!!policy.isHidden !== false && policy.isHidden === true} key={`policy-builder-${!!policy ? policy.policyID : -1}`}>
                    <div className={styles.treeRow}>
                        <Checkbox
                            color="primary"
                            checked={!!policy.isSelected !== false && policy.isSelected === true}
                            //disabled={unit.isDisabled}
                            style={{padding: "3px", marginLeft:'11px' }}
                            onClick={(e) => handleSelection(e, policy)}
                            />
                    <div>                    
                        <span onClick={(e) => handleSelection(e, policy)}>
                            { !!policy ? policy.policyName : ""}
                        </span>
                    </div>
                </div>
        </li>
            ))}
        </ul></div>
        </div>
    )

}

export default PolicySelection;