function hexToRgb(hex) {
  var bigint = parseInt(hex, 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;
  return { R: r, G: g, B: b };
}

export default function getColor(rgb) {
  const color = hexToRgb(rgb);
  const rgbTotal = color.R + color.G + color.B;
  return rgbTotal > 550 ? "#000" : "#fff";
}
