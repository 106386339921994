import React, { useState, useEffect, useContext } from "react";
import { UnitTreeItem } from "../../../globalTypes";

//libs
import { Steps } from "intro.js-react";

//MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
//Contexts
import { UIContext } from "../../../contexts/UIContext";
import { LoaderContext } from "../../../contexts/LoaderContext";

//API Functions
import { getRelevantUnitTree } from "../../../API/GetRelevantUnitTree";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import validateEmail from "../../../functions/valudateEmail";
//Components
import LoaderContainer from "../../../Components/LoaderContainer";
import CustomTooltip from "../../../Components/CustomTooltip";
import Units from "../../ReportCommonComponents/Units";
import GuideIcon from "../../../CustomIcons/GuideIcon";

//Styles
import styles from "../../../styles/addAccount.module.css";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { useHistory } from "react-router-dom";
import SaveIcon from "../../../CustomIcons/SaveIcon";
import UserDetailIcon from "../../../CustomIcons/Administration/UnitSettingAddUser";
import AddEmailAccountIcon from "../../../CustomIcons/Administration/AddEmailAccountIcon";
import RestartIcon from "../../../CustomIcons/RestartIcon";
import PasswordChecklist, { RuleNames } from "react-password-checklist";
import addError from "../../../API/AddError";
import PasswordValidIcon from "../../../CustomIcons/Administration/PasswordValidIcon";
import PasswordInValidIcon from "../../../CustomIcons/Administration/PasswordInvalidIcon";
import addOrUpdateUser from "../../../API/Administration/Users/AddOrUpdateUser";
import getTimezoneList from "../../../API/Administration/Users/GetTimezoneList";
import getOneUnitDetails from "../../../API/Administration/Unit/GetOneUnitDetails";
import MUICustomSelect from "../../../Components/MUICustomSelect";
import RadioUnitTree from "../AddAccount/RadioUnitTree";
import RadioUnits from "../AddAccount/RadioUnits";
import getOrgDomainConfig from "../../../API/GetOrgDomainConfig";
import { getDefaultPassword } from "../../../API/Administration/Users/GetDefaultPassword";
import getOneUserDetails from "../../../API/Administration/Users/GetOneUserDetails";
import RandomIcon from "../../../CustomIcons/Administration/RandomIcon";
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';
import getClassificationType from "../../../API/GetClassificationType";
import getClassificationListByType from "../../../API/GetClassificationListByType";
import UnbrandedCard from "../../../Components/UnbrandedCard";


const UnitAddAccount = (props: any) => {
  const { unitIdToEdit } = props;
  //const { userIdToEdit } = props; //uncomment if need to redirect specific tab
  const { setMessage } = useContext(LoaderContext);
  const history = useHistory();
  const {
    translations: { allResourcesToTranslations }, pageTitles
  } = useContext(TranslationsContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute, IsEbookEnabled, EnableActivityLogs, timeZoneId, superAdminSelectedBasicOrgData } =
    useContext(UIContext);
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
      .toLowerCase()
      .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";

  const [units, setUnits] = useState<Array<UnitTreeItem>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isResetClicked, setisResetClicked] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [isSaving, setIsSaving] = useState(false);
  const [isAccountDetailEnable, setIsAccountDetailEnable] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [accountActive, setAccountActive] = useState<boolean>(true);
  const [notifyManager, setNotifyManager] = useState<boolean>(false);
  const [alertUnitAdmin, setAlertUnitAdmin] = useState<boolean>(false);
  const [alertOrgAdmin, setAlertOrgAdmin] = useState<boolean>(false);
  const [sendEbook, setSendEbook] = useState<boolean>(false);
  const [filterFirstName, setFilterFirstName] = useState("");
  const [timeZone, setTimeZone] = useState<any>({});
  const [selectedTimezone, setSelectedTimezone] = useState<any>({});
  const [timezoneList, setTimezoneList] = useState<any>([]);
  const [isTimezoneOptionFocused, setIsTimezoneOptionFocused] = useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [filterLastName, setFilterLastName] = useState("");
  const [filterUserName, setFilterUserName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [filterExternalId, setFilterExternalId] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<
    null | string
  >(null);

  const [enablePasswordRule, setEnablePasswordRule] = useState<boolean>(false);
  const [IsPasswordRuleCapital, setIsPasswordRuleCapital] = useState<boolean>(false)
  const [IsPasswordRuleNumber, setIsPasswordRuleNumbere] = useState<boolean>(false)
  const [IsPasswordRuleSpecialChar, setIsPasswordRuleSpecialChar] = useState<boolean>(false)
  const [expandManagerSetting, setExpandManagerSetting] = useState(false);
  // booleans for password validations
  const [containsUL, setContainsUL] = useState(false); // uppercase letter
  const [containsLL, setContainsLL] = useState(false); // lowercase letter
  const [containsN, setContainsN] = useState(false); // number
  const [containsSC, setContainsSC] = useState(false); // special character
  const [contains8C, setContains8C] = useState(false); // min 8 characters
  const [contains16C, setContains16C] = useState(false); // max 16 characters
  const [passwordMatch, setPasswordMatch] = useState(false); // passwords match
  const [classificationList, setClassificationList] = useState([]);
  const [groupingOption, setGroupingOption] = useState<boolean>(false);
  const [selectedClassification, setSelectedClassification] = useState<any>({ value: 0 });
  const [selectedUnitCallback, setSelectedUnitCallback] = useState<number>(0);
  const [groupingOptionName, setGroupingOptionName] = useState<string>("Grouping Option");

  const [unitActive, setUnittActive] = useState<boolean>(false);
  const [pathway, setPathway] = useState("");
  const [unitID, setUnitID] = useState(0);
  const [unitsPrivileges, setUnitsPrivileges] = useState<Array<UnitTreeItem>>([]);
  const [addedUserID, setaddedUserID] = useState(0);
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".unitFilterButtons",
        title: "Units",
        intro: "Expand and collapse all units.",
      },
    ],
  });

  const setGuideMe = (groupingOption: boolean) => {
    let updateSteps = [...intro.steps];
    updateSteps.push(
      {
        element: ".unitFilterSearch",
        title: "Filter Units",
        intro: "Search unit/subunits by typing unit name.",
      },
      {
        element: ".firstName",
        title: "First Name",
        intro: "Enter Account's First Name.",
      },
      {
        element: ".lastName",
        title: "Last Name",
        intro: "Enter Account's Last Name.",
      },
      {
        element: ".userName",
        title: "Username",
        intro: "Enter Account's Username.",
      },
      {
        element: ".email",
        title: "Email Address",
        intro: "Enter Account's Email Address.",
      },
      {
        element: ".externalID",
        title: "External ID",
        intro: "Enter Account's External ID.",
      });
    if (groupingOption) {
      updateSteps.push({
        element: ".groupby",
        title: "Classification", intro: "Select classification for this Account."
      });
    }

    updateSteps.push(
      {
        element: ".password",
        title: "Password",
        intro: `Enter a new password for the account.
                    `,
      },
      {
        element: ".confirmPassword",
        title: "Confirm Password",
        intro: ` confirm a new password for the account.
                    `,
      },

      {
        element: ".passwordRequirements",
        title: "Password Requirements",
        intro:
          "When specifying a new password, if set up, password complexity requirements will check off when the entered password complies to set rules.",
      },

      {
        element: ".accountActive",
        title: "Account Active",
        intro:
          "Enable checkbox to activate account and uncheck to deactivate an account.",
      },

      {
        element: ".notifyManager",
        title: "Notify Manager",
        intro:
          "Check this to enable the notification of a manager of overdue courses for the account by email, enter the email address of the Manager to notify. NOTE: This must be enabled on a course-by-course basis under Organisation Mail Setup in Salt Admin.",
      },

      {
        element: ".emailAlert",
        title: "Send Email Alerts to Unit Admin",
        intro:
          " When an account is overdue for any given coursemodule, check this to also send an overdue notification to any relevant Unit Administrators based on where the account resides in Salt.",
      },
      {
        element: ".alertOrgAdmin",
        title: "Send Email Alerts to Org Admin",
        intro:
          "When an account is overdue for any given coursemodule, check this to also send an overdue notification to all Organisation Administrators. NOTE: This will cause a high volume of email traffic to Organisation Administrators.",
      });

    if (IsEbookEnabled) {
      updateSteps.push(
        {
          element: ".sendEbook",
          title: "Send Email about new version of Ebook",
          intro:
            "Check this to send Email about new version of Ebook.",
        });
    }
    updateSteps.push(
      {
        element: ".timezone",
        title: "Timezone",
        intro:
          "Set the time zone the account resides in; this will ensure all date/time are in the account's local time.",
      },
      {
        element: ".saveData",
        title: "Save",
        intro: "Click here to save all changes",
      });

    setIntro({ ...intro, steps: [...updateSteps] });
  };

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  useEffect(() => {
    setTitle(allResourcesToTranslations.tabaddaccounttounit);
		setTitleEnglish(pageTitles.addaccounttounit);
    updateBackButtonRoute("/UnitManagement");
    setLoading(true);

    //#region UnitTree
    getRelevantUnitTree(
      oidcUser.access_token,
      userId,
      orgDomain,
      true,
      parseInt(orgId)
    ).then((unitTreeRes) => {
      if (unitTreeRes.isSuccess) {
        let unitsArray = [...unitTreeRes.unitTreeItems];
        setUnits([...unitsArray]);
        setLoading(false);
        getOneUnitDetails(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(unitIdToEdit)).then((result) => {
          if (result.isSuccess && result.error == null) {
            setUnitID(result.unitDetailsItems[0].unitID);
            setPathway(result.unitDetailsItems[0].pathway);
            setUnittActive(result.unitDetailsItems[0].active);
            setLoading(false);
            setSelectedUnit(unitsArray, result.unitDetailsItems[0].unitID);
          }
        });
        //uncomment if need to redirect specific tab
        // if(userIdToEdit>0)
        // {
        //   getOneUserDetails(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(userIdToEdit)).then((result) => {
        //     if (result.isSuccess && result.error == null) {

        //       setFilterFirstName(result.userDetailsItems[0].firstName);
        //       setFilterLastName(result.userDetailsItems[0].lastName);
        //       setFilterEmail(result.userDetailsItems[0].email);
        //       setFilterUserName(result.userDetailsItems[0].userName);
        //       setPassword("");
        //       setConfirmPassword("");
        //       setAccountActive(result.userDetailsItems[0].active);
        //       setNotifyManager(result.userDetailsItems[0].notifyMgr);
        //       setManagerEmail(result.userDetailsItems[0].delinquencyManagerEmail);
        //       setAlertOrgAdmin(result.userDetailsItems[0].notifyOrgAdmin);
        //       setAlertUnitAdmin(result.userDetailsItems[0].notifyUnitAdmin);
        //       setSendEbook(result.userDetailsItems[0].ebookNotification);
        //       setUnitID(result.userDetailsItems[0].unitID);
        //       setFilterExternalId(result.userDetailsItems[0].externalID);

        //       setTimeZone(result.userDetailsItems[0].timeZoneID ?? timeZoneId);
        //       setSelectedClassification({value : result.userDetailsItems[0].classificationID});

        //       setSelectedUnit(unitsArray, result.userDetailsItems[0].unitID);


        //       if (parseInt(result.userDetailsItems[0].userTypeID) == 3) {
        //         getRelevantUnitTree(
        //           oidcUser.access_token,
        //           parseInt(userIdToEdit),
        //           orgDomain,
        //           true,
        //           parseInt(orgId)
        //         ).then((unitprivilegeTreeRes) => {
        //           if (unitprivilegeTreeRes.isSuccess) {        
        //               let unitsPrivilegeArray = [...unitprivilegeTreeRes.unitTreeItems];


        //               const setSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
        //                 if (unitArray === null) return //Break if no children
        //                 if (unitArray.length === 0) return
        //                 for (let i = 0; i < unitArray.length; i++) {
        //                   const unit = unitArray[i];
        //                   unit.isDisabled = true; 
        //                   unit.isExpanded = true;
        //                   unit.isSelected = true;
        //                   setSelectedUnitIds(unit.childUnitTreeItems);
        //                 }
        //               }
        //               setSelectedUnitIds(unitsPrivilegeArray);
        //               setUnitsPrivileges([...unitsPrivilegeArray]);
        //           }
        //         });
        //       }
        //       setLoading(false);      
        //     }
        //   })
        // }
      }
    });
    //#endregion

    console.log("Unit Add Account getOrgDomainConfig " + ((superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.siteName : ""))
    getOrgDomainConfig(oidcUser.access_token, userId, orgDomain, 
      (superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.orgID : -1,
      (superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.domainName : "",
      (superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.siteName : "").then((configResult) => {
      if (configResult.isSuccess) {
        setEnablePasswordRule(configResult.config.enablePasswordRule);
        setIsPasswordRuleCapital(configResult.config.isPasswordRuleCapital)
        setIsPasswordRuleNumbere(configResult.config.isPasswordRuleNumber)
        setIsPasswordRuleSpecialChar(configResult.config.isPasswordRuleSpecialChar)
      }
    });

    handleGeneratePassword();

    //#region  ClassificationType
    getClassificationType(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((classType) => {
      if (classType !== null && classType.classificationTypeIDs !== null &&
        classType.classificationTypeIDs.length > 0 && classType.classificationTypeIDs[0] > 0) {
        setGroupingOptionName(classType.classificationTypeName[0]);
        getClassificationListByType(oidcUser.access_token, userId, orgDomain, parseInt(orgId), classType.classificationTypeIDs[0]).then((data) => {

          if (data !== null && data.classificationlistItem !== null) {
            setClassificationList(data.classificationlistItem)
            setSelectedClassification({ value: 0 });
            setGroupingOption(true);
            setGuideMe(true);
          }
          else {
            setGroupingOption(false);
            setGuideMe(false);
          }
        })
      }
      else {
        setGroupingOption(false);
      }
      setGuideMe(groupingOption);
    })
    //#endregion


    //#region  TimeZone
    getTimezoneList(oidcUser.access_token, userId, orgDomain).then((result) => {
      if (result != null && result.isSuccess) {
        let updatedData = result.timeZoneItems.map((item: any) => {
          return {
            value: item.timeZoneID,
            text: item.flB_Name,
          };
        });

        let orgTimeZone = result.timeZoneItems.filter((tz: any) => tz.timeZoneID == timeZoneId);
        if (orgTimeZone != null && orgTimeZone.length > 0) {
          setSelectedTimezone({
            value: orgTimeZone[0].timeZoneID,
            text: orgTimeZone[0].flB_Name
          });
        }
        setTimezoneList([...updatedData]);
      }
    });
    //#endregion

  }, []);

  const getRulesArray = () => {
    var arr: RuleNames[] = ["minLength", "maxLength", "match", "capital", "number", "specialChar"]
    if (!IsPasswordRuleCapital) {
      const index = arr.indexOf("capital");
      if (index > -1) {
        arr.splice(index, 1);
      }
    }
    if (!IsPasswordRuleNumber) {
      const index = arr.indexOf("number");
      if (index > -1) {
        arr.splice(index, 1);
      }
    }
    if (!IsPasswordRuleSpecialChar) {
      const index = arr.indexOf("specialChar");
      if (index > -1) {
        arr.splice(index, 1);
      }
    }
    return arr;
  };

  const validatePassword = () => {
    if (!enablePasswordRule) {
      return;
    }
    if (password === "" && confirmPassword === "") {
      setIsPasswordValid(true);
      return;
    }

    setIsPasswordValid(false);

    var allValid = true;
    // has uppercase letter
    if (password.toLowerCase() != password) {
      setContainsUL(true);
    } else {
      setContainsUL(false);
      allValid = false;
    }

    // has number
    if (/[0-9]/g.test(password)) {
      setContainsN(true);
    } else {
      setContainsN(false);
      allValid = false;
    }

    // has special character
    if (/[^0-9A-Za-z]/g.test(password)) {
      setContainsSC(true);
    } else {
      setContainsSC(false);
      allValid = false;
    }

    // has 8 characters
    if (password.length >= 8) {
      setContains8C(true);
    } else {
      setContains8C(false);
      allValid = false;
    }

    // has <16   characters
    if ((password.length >= 8 && password.length <= 16, EnableActivityLogs)) {
      setContains16C(true);
    } else {
      setContains16C(false);
      allValid = false;
    }

    // passwords match
    if (password !== "" && password === confirmPassword) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
      allValid = false;
    }

    // all validations passed
    setIsPasswordValid(allValid && passwordMatch);
  };

  const handleResetPassword = () => {
    setPassword("");
    setConfirmPassword("");
  };

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  const handleGeneratePassword = () => {
    getDefaultPassword(oidcUser.access_token,
      userId,
      orgDomain,
      parseInt(orgId)).then((result) => {
        if (result.isSuccess) {
          result.getDefaultPasswordItems.map((item: any) => {
            setPassword(item.password);
            setConfirmPassword(item.password);
          })
        }
      })
  }

  // const validateEmail = (email: string) => {
  //   let reg = /^[\w-']+(\.[\w-']+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
  //   if (reg.test(email) === false) {
  //     return false;
  //   }
  //   else {
  //     return true;
  //   }
  // }
  const handleAccountDetails = () => {

    history.push({
      pathname: `../accountDetails/` + addedUserID,
      state: {
        inputuserID: addedUserID,
        inputunitID: unitIdToEdit,
      },
    });
  }

  const handleSubmit = () => {

    let errorString = '';

    if (!validateEmail(filterEmail)) {
      errorString = errorString + 'Please enter valid email;'
    }

    if (selectedUnitCallback === 0) {
      errorString = errorString + 'Please select the unit;'
    }

    if ((managerEmail !== '' && !validateEmail(managerEmail)) || (notifyManager && !validateEmail(managerEmail))) {
      setExpandManagerSetting(true);
      errorString = errorString + 'Please enter valid manager email;'
    }

    if (errorString !== '') {
      setShowWarningModal(true);
      setModalPopupMessage(errorString);
      setHasErrored(true);
      return;
    }

    setIsSaving(true);
    setSaveClicked(true);
    addOrUpdateUser(
      "add",
      oidcUser.access_token,
      userId,
      parseInt(orgId),
      orgDomain,
      selectedUnitCallback,
      filterFirstName,
      filterLastName,
      filterUserName,
      filterEmail,
      accountActive,
      4,
      filterExternalId,
      selectedTimezone.value,
      managerEmail,
      alertUnitAdmin,
      alertOrgAdmin,
      notifyManager,
      sendEbook,
      password,
      0,
      parseInt(selectedClassification.value)
    ).then((result) => {
      if (result !== null) {
        if (result.isSuccess && result.error == "" && result.addedUserID != -1) {
          setaddedUserID(result.addedUserID);
          setShowWarningModal(true);
          setModalPopupMessage('Account Details have been Saved! Use Account Management to view or update account.');
          setSaveSuccess(true);
          setHasErrored(false);
          setIsSaving(false);
          setIsAccountDetailEnable(true);
          setSaveClicked(false);
        } else {
          setShowWarningModal(true);
          setModalPopupMessage(result.error);
          setHasErrored(true);
          setIsSaving(false);
          setSaveClicked(false);
          return;
        }
      } else {
        setShowWarningModal(true);
        setModalPopupMessage('Saving record has failed, please try again.');
        setHasErrored(true);
        setIsSaving(false);
        return;
      }
    });
  };

  useEffect(() => {
    if (showWarningModal) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      }, 5000)
    }
  }, [showWarningModal])


  const setSelectedUnit = (unitsArray: any, selectedUnitId: number) => {
    let unitHierarchy = "";
    let parentUnits: any = [];
    const setSelectOfUnit = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.unitID === selectedUnitId) {
          unit.isSelected = true;
          if (unit.unitHierarchy.indexOf(">") > 0) {
            unitHierarchy = unit.unitHierarchy;
            parentUnits = unit.unitHierarchy.split(" > ");
          }
        }
        setSelectOfUnit(unit.childUnitTreeItems);
      }

      if (unitHierarchy !== "" && parentUnits.length > 0) {
        const setExpandOfUnit = (unitArray: Array<UnitTreeItem> | null) => {
          if (unitArray === null) return; //Break if no children
          if (unitArray.length === 0) return;
          for (let i = 0; i < unitArray.length; i++) {
            const unit = unitArray[i];
            if (parentUnits.includes(unit.name)) {
              unit.isExpanded = true;
            }
            setExpandOfUnit(unit.childUnitTreeItems);
          }
        };
        setExpandOfUnit(unitsArray);
      }
    };
    setSelectOfUnit(unitsArray);

    setUnits([...unitsArray]);
  }


  if (loading) return <LoaderContainer />;

  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }}
      />
      <CustomTooltip displayText={["Guide Me"]}>
        <span
          onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonback}
        >
          <GuideIcon />
        </span>
      </CustomTooltip>


      <div style={{ paddingTop: "20px" }}>
        <div className={"unitDetails"} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #D3D6DF', height: '42px', borderRadius: '5px' }}>
          <div>
            <p>{allResourcesToTranslations.unitid}: {unitIdToEdit}&nbsp;|&nbsp;{allResourcesToTranslations.unitpathwaydetails}: {pathway}</p>
          </div>
          <div className="checkInactive">
            <Checkbox
              size="small"
              color="primary"
              disabled
              checked={unitActive}
              className={styles.checkBox}
              onClick={() => setUnittActive(!unitActive)}
            />{" "}
            <span className={unitActive ? styles.accountActiveText : styles.accountInactiveText} >{unitActive ? allResourcesToTranslations.activeunit : allResourcesToTranslations.inactiveunit}</span>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: "20px" }}>
        <div className={styles.unittreesection}>
          <div>
            <RadioUnits isResetClicked={isResetClicked} givenUnits={units} selectedUnitId={unitID} setSelectedUnitCallback={setSelectedUnitCallback} />
          </div>
        </div>
        <div className={styles.accountRightSection}>
          <p
            style={{
              fontWeight: 700,
              color: "#D3D6DF",
              fontSize: "14px",
              fontStyle: "italic",
              marginTop: "5px",
            }}
          >
            {allResourcesToTranslations.enteraccountdetails}
          </p>
          <form autoComplete="false">

            <div className={styles.firstNameInput}>
              <div className="firstName">
                <TextField
                  className={styles.input}
                  label={"First Name"}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  value={filterFirstName}
                  onChange={(e) => setFilterFirstName(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className="lastName">
                <TextField
                  className={styles.input}
                  label={"Last Name"}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  value={filterLastName}
                  onChange={(e) => setFilterLastName(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className="userName">
                <TextField
                  className={styles.input}
                  label={"Username"}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  value={filterUserName}
                  onChange={(e) => setFilterUserName(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className="email">
                <TextField
                  className={styles.input}
                  label={"Email"}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  value={filterEmail}
                  onChange={(e) => setFilterEmail(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className="externalID">
                <TextField
                  className={styles.input}
                  label={"External ID"}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  value={filterExternalId}
                  onChange={(e) => setFilterExternalId(e.target.value)}
                />
              </div>
            </div>
            {groupingOption &&
              <div className="groupby">
                <div className={styles.groupingOption} >
                  <FormControl variant="outlined" className={styles.input}>
                    <InputLabel id="grouping">{groupingOptionName}</InputLabel>
                    <Select
                      value={selectedClassification.value}
                      native
                      onChange={(e) => setSelectedClassification({ value: e.target.value })}
                      className={styles.input}
                      variant="outlined"
                      label={groupingOptionName}
                      id="grouping"
                    >
                      {classificationList.map((item: any) => {
                        return <option key={'select' + item.value} value={item.value}>{item.text}</option>
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
            }
            <div style={{ width: "100%", clear: "both" }}>
              <div style={{ minWidth: "300px", float: "left", paddingRight: "20px" }}>
                <div className={styles.inputContainer}>
                  <TextField
                    name="password"
                    className={`${styles.input} password`}
                    type="password"
                    label={allResourcesToTranslations.newpassword}
                    variant="outlined"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      validatePassword();
                    }}
                    value={password}
                    error={passwordError}
                    autoComplete="off"
                    InputProps={{
                      readOnly: false,
                      style: {
                        backgroundColor: "white",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className={styles.inputContainer}>
                  <TextField
                    name="confirmPassword"
                    className={`${styles.input} confirmPassword`}
                    type="password"
                    label={allResourcesToTranslations.confirmnewpassword}
                    variant="outlined"
                    error={passwordError}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      validatePassword();
                    }}
                    value={confirmPassword}
                    helperText={passwordErrorMessage}
                    autoComplete="off"
                    InputProps={{
                      readOnly: false,
                      style: {
                        backgroundColor: "white",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <CustomTooltip displayText={["reset"]}>
                    <span>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={styles.restartButton}
                        onClick={handleResetPassword}
                        style={{ padding: "7px 15px 3px 15px" }}
                      >
                        <RestartIcon />
                      </Button>
                    </span>
                  </CustomTooltip>
                  <CustomTooltip displayText={["Generate Random Password"]}>
                    <span>
                      <Button
                        size="small"
                        onClick={handleGeneratePassword}
                        variant="contained"
                        color="primary"
                      >
                        <RandomIcon />
                      </Button>
                    </span>
                  </CustomTooltip>
                </div>
                <div style={{ marginTop: "10px", marginBottom: "20px" }}>
                  <div className="accountActive">
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={accountActive}
                      className={styles.checkBox}
                      onClick={() => setAccountActive(!accountActive)}
                    />{" "}
                    {"Account Active"}
                  </div>
                </div>
              </div>
              {enablePasswordRule && <div
                style={{ minWidth: "250px", float: "left" }}
                className="passwordRequirements"
              >
                <div className={styles.inputContainer}>
                  <span className={styles.passwordFinePrint}>
                    Password Requirements
                  </span>
                </div>
                <div className={styles.passwordChecklist}>
                  <PasswordChecklist
                    rules={getRulesArray()}
                    minLength={8}
                    maxLength={16}
                    value={password}
                    iconComponents={{
                      ValidIcon: <PasswordValidIcon />,
                      InvalidIcon: <PasswordInValidIcon />,
                    }}
                    valueAgain={confirmPassword}
                    onChange={(isValid: boolean) => {
                      setIsPasswordValid(isValid);
                    }}
                    messages={{
                      minLength: `${allResourcesToTranslations.passwordminlength}`,
                      maxLength: `${allResourcesToTranslations.passwordmaxlength}`,
                      capital: `${allResourcesToTranslations.passworduppercase}`,
                      number: `${allResourcesToTranslations.passwordnumber}`,
                      specialChar: `${allResourcesToTranslations.passwordspecial}`,
                      match: `${allResourcesToTranslations.passwordmatch}`,
                    }}
                  />
                </div>
              </div>
              }
            </div>
            <div style={{ clear: "both" }}></div>
            <div>
              <Accordion style={{ margin: "5px 0px", borderRadius: "6px 6px 6px 6px", border: '1px solid #D3D6DF', position: 'inherit', boxShadow: "none" }} expanded={expandManagerSetting}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="managerSettings-content"
                  id="managerSettings-header"
                  onClick={(e) => setExpandManagerSetting(!expandManagerSetting)}
                >
                  <Typography className="typographyFonts"><b>Manager Settings</b></Typography>
                </AccordionSummary>
                <AccordionDetails style={{ paddingTop: "0px", display: "block" }}>
                  <Typography className="typographyFonts" style={{ display: "inline" }}>
                    <div className="notifyManager">
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={notifyManager}
                        className={styles.checkBox}
                        onClick={() => setNotifyManager(!notifyManager)}
                      />{" "}
                      {"Notify Manager of overdue items by email"}
                    </div>
                    <div className={styles.inputContainer}>
                      <div>
                        <TextField
                          className={styles.input}
                          label={"Manager Email"}
                          variant="outlined"
                          autoComplete="off"
                          InputLabelProps={{ shrink: true }}
                          value={managerEmail}
                          onChange={(e) => setManagerEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="emailAlert">
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={alertUnitAdmin}
                        className={styles.checkBox}
                        onClick={() => setAlertUnitAdmin(!alertUnitAdmin)}
                      />{" "}
                      {"Send email alerts to Unit Admin"}
                    </div>
                    <div className="alertOrgAdmin">
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={alertOrgAdmin}
                        className={styles.checkBox}
                        onClick={() => setAlertOrgAdmin(!alertOrgAdmin)}
                      />{" "}
                      {"Send email alerts to Org Admin"}
                    </div>
                    {IsEbookEnabled && <div className="sendEbook">
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={sendEbook}
                        className={styles.checkBox}
                        onClick={() => setSendEbook(!sendEbook)}
                      />{" "}
                      {"Send email about new version of Ebook"}
                    </div>}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div style={{ marginTop: "20px" }} className="timezone">
              <MUICustomSelect
                idName={"timezoneOption"}
                labelName={"Timezone"}
                currentValue={selectedTimezone.value}
                setCurrentValue={(e: any) => {
                  if (!!e.target) {
                    setSelectedTimezone({
                      value: e.target.value,
                      text: (e.target as HTMLSelectElement).options[
                        (e.target as HTMLSelectElement).selectedIndex
                      ].text,
                    });
                  } else {
                    let filtered = !!timezoneList
                      ? timezoneList.filter(
                        (cur: any) =>
                          !!cur && parseInt(cur.value) === parseInt(e)
                      )
                      : null;
                    if (!!filtered && filtered.length > 0) {
                      setSelectedTimezone({
                        value: filtered[0].value,
                        text: filtered[0].text,
                      });
                    }
                  }
                }}
                isFocussed={isTimezoneOptionFocused}
                setIsFocussed={setIsTimezoneOptionFocused}
                inputLabelStyle={{}}
                listOfItems={timezoneList}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", paddingTop: "10px" }}>

              <CustomTooltip displayText={["Save"]}>
                <span className="saveData">
                  <Button
                    disabled={!saveClicked ?
                      (password == "" || confirmPassword == "" ||
                        !isPasswordValid || filterEmail == "" || filterFirstName == "" || filterLastName == "" || filterUserName == "" || selectedTimezone.value == 0 || isSaving)
                      : saveClicked
                    }
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    className="save"
                  >
                    <SaveIcon />
                  </Button>
                </span>
              </CustomTooltip>  &nbsp;&nbsp;
              <CustomTooltip displayText={["Account Details"]}>
                <span className="saveData">
                  <Button
                    disabled={
                      (!isAccountDetailEnable)
                    }
                    onClick={handleAccountDetails}
                    variant="contained"
                    color="primary"
                    className="save"
                  >
                    <UserDetailIcon />
                  </Button>
                </span>
              </CustomTooltip>

              <div className={styles.loaderContainer}>
                <CircularProgress
                  style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
                  color="primary"
                  size="10"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div style={{ clear: "both" }}></div>

      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
    </>
  );
};

export default UnitAddAccount;
