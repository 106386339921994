import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const AssignAccountsIcon = () => (    
<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M7.34753 8.37518C7.34753 10.6846 9.21994 12.557 11.5294 12.557C13.8388 12.557 15.7112 10.6846 15.7112 8.37518C15.7112 6.06577 13.8388 4.19336 11.5294 4.19336C9.21994 4.19336 7.34753 6.06577 7.34753 8.37518ZM9.43844 8.37518C9.43844 9.5304 10.3741 10.4661 11.5294 10.4661C12.6846 10.4661 13.6203 9.5304 13.6203 8.37518C13.6203 7.21995 12.6846 6.28427 11.5294 6.28427C10.3741 6.28427 9.43844 7.21995 9.43844 8.37518Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23.0292 11.5137C23.0292 5.16254 17.8803 0.0136719 11.5292 0.0136719C5.17804 0.0136719 0.0291748 5.16254 0.0291748 11.5137C0.0291748 17.8648 5.17804 23.0137 11.5292 23.0137C11.9163 23.0137 12.299 22.9945 12.6763 22.9572C12.3805 22.3198 12.1777 21.6305 12.0856 20.9066C11.9015 20.9173 11.716 20.9228 11.5292 20.9228C9.24172 20.9228 7.14454 20.1063 5.51363 18.7493C6.8424 16.8999 9.01172 15.6955 11.4623 15.6955C12.1105 15.6955 12.739 15.7798 13.3374 15.938C13.7945 15.3008 14.356 14.7435 14.9968 14.2911C13.9055 13.8484 12.7123 13.6046 11.4623 13.6046C8.45032 13.6046 5.76976 15.0191 4.0479 17.2198C2.8383 15.637 2.12008 13.6589 2.12008 11.5137C2.12008 6.31672 6.33222 2.10458 11.5292 2.10458C16.7261 2.10458 20.9383 6.31672 20.9383 11.5137C20.9383 12.1015 20.8843 12.6767 20.7811 13.2347C21.4835 13.4157 22.1433 13.703 22.7421 14.0784C22.93 13.2537 23.0292 12.3953 23.0292 11.5137Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M16.8474 18.3762C16.8474 19.5811 17.8243 20.558 19.0292 20.558C20.2341 20.558 21.211 19.5811 21.211 18.3762C21.211 17.1712 20.2341 16.1943 19.0292 16.1943C17.8243 16.1943 16.8474 17.1712 16.8474 18.3762ZM17.9383 18.3762C17.9383 18.9789 18.4265 19.4671 19.0292 19.4671C19.632 19.4671 20.1201 18.9789 20.1201 18.3762C20.1201 17.7734 19.632 17.2852 19.0292 17.2852C18.4265 17.2852 17.9383 17.7734 17.9383 18.3762Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M19.0292 14.0137C22.3428 14.0137 25.0292 16.7 25.0292 20.0137C25.0292 23.3273 22.3428 26.0137 19.0292 26.0137C15.7155 26.0137 13.0292 23.3273 13.0292 20.0137C13.0292 16.7 15.7155 14.0137 19.0292 14.0137ZM23.9383 20.0137C23.9383 21.1537 23.5494 22.2031 22.8975 23.0366C22.0008 21.8622 20.5859 21.1046 18.9943 21.1046C17.4228 21.1046 16.0243 21.8426 15.1259 22.9908C14.4948 22.1649 14.1201 21.1329 14.1201 20.0137C14.1201 17.3022 16.3177 15.1046 19.0292 15.1046C21.7406 15.1046 23.9383 17.3022 23.9383 20.0137ZM19.0292 24.9228C20.2019 24.9228 21.2786 24.5115 22.123 23.8253C21.4324 22.8397 20.2886 22.1955 18.9943 22.1955C17.7157 22.1955 16.5839 22.8239 15.8906 23.7888C16.7415 24.4968 17.8357 24.9228 19.0292 24.9228Z" fill="white"/>
</svg>
);

export default AssignAccountsIcon
