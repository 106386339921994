import React, { useState, useEffect, useContext, useCallback } from "react";
import { UnitTreeItem } from "../../../globalTypes";

//libs
import { Steps } from "intro.js-react";

//MUI
import { Button, Checkbox, CircularProgress, Tab, Tabs, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

//Contexts
import { UIContext } from "../../../contexts/UIContext";
import { LoaderContext } from "../../../contexts/LoaderContext";

//API Functions
import { getRelevantUnitTree } from "../../../API/GetRelevantUnitTree";
import { useReactOidc } from "@axa-fr/react-oidc-context";

//Components
import Modal from "../../../Components/Modal";
import BrandedCard from "../../../Components/BrandedCard";
import LoaderContainer from "../../../Components/LoaderContainer";
import CustomTooltip from "../../../Components/CustomTooltip";
import GuideIcon from "../../../CustomIcons/GuideIcon";

//Styles
import styles from "../../../styles/accountManagement.module.css";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { useHistory, useLocation } from "react-router-dom";
import ResetIcon from "../../../CustomIcons/ResetIcon";
import { CorperateThemeContext } from "../../../contexts/CorperateThemeContext";
import UnitListSection from "./UnitListSection";
import Units from "../../ReportCommonComponents/Units";
import searchUnitList from "../../../API/Administration/Unit/SearchUnitList";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";

const UnitManagement = () => {
  const location = useLocation<any>();
  const { setMessage } = useContext(LoaderContext);
  const history = useHistory();
  const {
    translations: { allResourcesToTranslations }, pageTitles
  } = useContext(TranslationsContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute,setMenuSelection, EnableActivityLogs, setUnitMgmntInputProps, unitMgmntInputProps } =
    useContext(UIContext);
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const requestingUserId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const {
    theme: { color },
  } = useContext(CorperateThemeContext);
  const orgDomain = website
    ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";

  const [activeKey, setActiveKey] = React.useState("");

  const [units, setUnits] = useState<Array<UnitTreeItem>>(!!location.state && !!location.state.units ? location.state.units : []);
  const [loading, setLoading] = useState<boolean>(true);
  const [isResetClicked, setisResetClicked] = useState<boolean>(false);
  const [includeInactive, setincludeInactive] = useState<boolean>(!!location.state ? location.state.includeInactive : false);
  const [unitID, setunitID] = useState(!!location.state && !!location.state.unitID ? location.state.unitID : "");
  const [filterUnitName, setFilterUnitName]  = useState(!!location.state && !!location.state.filterUnitName ? location.state.filterUnitName.toString() : "");
  const [filterUnittreeName, setFilterUnittreeName]  = useState(!!location.state && !!location.state.filterUnittreeName ? location.state.filterUnittreeName.toString() : "");
     
  const [unitList, setUnitList] = React.useState<Array<any>>([]);
  const [unitListOpen, setUnitListOpen] = useState<boolean>(!!location.state ? location.state.unitListOpen : false);
  const [userID, setuserID] = useState(!!location.state && !!location.state.userID ? location.state.userID : "");
  const [isPopupOpen, setIsPopupOpen] = React.useState<boolean>(false);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);

  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".unitFilterButtons",
        title: "Units",
        intro: "Select, unselect, expand and collapse all units.",
      },
    ],
  });

  useEffect(() => {
    let updateSteps = [...intro.steps];
    updateSteps.push(
      {
        element: ".unitFilterSearch",
        title: "Filter Units",
        intro: "Search unit/subunits by typing unit name.",
      },      
      {
        element: ".unitName",
        title: "Filter",
        intro: "Filter Results by Unit Name.",
      },
      {
        element: ".unitID",
        title: "Filter",
        intro: "Filter Results by UnitID.",
      },      
      {
        element: ".includeInactive",
        title: "Filter",
        intro: "Filter Results by Including Inactive.",
      },
      {
        element: ".searchUsers",
        title: "Search Unit",
        intro: "Search for Unit.",
      },
      {
        element: ".resetAll",
        title: "Reset",
        intro: "Reset all the Fields.",
      }
    );
    setIntro({ ...intro, steps: [...updateSteps] });
  }, []);

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  useEffect(() => {   
    setTitle(allResourcesToTranslations.unitmanagement);
    setTitleEnglish(pageTitles.unitmanagement);
    updateBackButtonRoute("");
    setMenuSelection(allResourcesToTranslations.unitmanagement)
    if (!!location && !!location.state) {
      if (location.state.unitListOpen) { 
        handleSearch(); 
      }
      setLoading(false);
    }
    else {
      setLoading(true);
      getRelevantUnitTree(
        oidcUser.access_token,
        requestingUserId,
        orgDomain,
        true,
        parseInt(orgId) 
      ).then((unitTreeRes) => {
        if (unitTreeRes.isSuccess) {
          setUnits(unitTreeRes.unitTreeItems);
          let curUnits = unitTreeRes.unitTreeItems;
          setUnitMgmntInputProps({
            unitID, filterUnitName, includeInactive, units: curUnits, unitListOpen
          });   
          setLoading(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!!unitMgmntInputProps !== true || 
        unitMgmntInputProps.userID !== userID ||
     unitMgmntInputProps.filterUnitName !== filterUnitName ||
     unitMgmntInputProps.includeInactive !== includeInactive ||
     unitMgmntInputProps.units !== units) {
        setUnitMgmntInputProps({
          unitID, filterUnitName, includeInactive, units, unitListOpen
      });   
    } 
  }, [userID, filterUnitName, includeInactive, units, unitListOpen]);

  const deselectAllUnits = () => {
    let updatedUnits = units
    const setAllDeselected = (unitArray: Array<UnitTreeItem> | null) => {
        if (unitArray === null) return //Break if no children
        if (unitArray.length === 0) return
        for (let i = 0; i < unitArray.length; i++) {
            if (!unitArray[i].isHidden) {
                const unit = unitArray[i];
                if (!unit.isDisabled) {
                    unit.isSelected = false;
                }
                setAllDeselected(unit.childUnitTreeItems);
            }
        }
    }
    setAllDeselected(updatedUnits)
    setUnits(updatedUnits)
    setUnitMgmntInputProps({
      unitID, filterUnitName, includeInactive, units: updatedUnits, unitListOpen
    });   
}

  const handleResetData = () => {
   
    setuserID('');
    setincludeInactive(true);
    setUnitMgmntInputProps({
      unitID: '', filterUnitName: '', includeInactive: true, units
    });
    deselectAllUnits();
    setUnitList([]);
    setUnitListOpen(false);
  }

  const validateNumber = (userId: string) => {
    let reg = /^[1-9]\d*$/;
    if (reg.test(userId) === false) {
         return false;
    }
    else {
      return true;
    }
  }

  const handleKeyPress = useCallback((event) => {
    //console.log(`Key pressed: ${event.key}`);
    if (!!event && !!event.key && event.key.toString() == "Enter" && !isSearchClicked) {
      //handleSearch();
      setActiveKey(event.key.toString());
      setTimeout(() => {
        setActiveKey("");
      }, 600);
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (!!activeKey && activeKey == "Enter") {
      handleSearch();
      setActiveKey("");
    }
  }, [activeKey]);

  const handleSearch = () => {
    let errorString = '';
    setUnitList([]);
    setUnitListOpen(false);
    setUnitMgmntInputProps({
      unitID, filterUnitName, includeInactive: true, units, unitListOpen: false
    });   

    if ((unitID !== '' && !validateNumber(unitID))) {
      errorString = errorString + 'Please enter valid Unit ID,'
    }
    if (errorString !== ''){
      setActiveKey("");
      setShowWarningModal(true);
      setModalPopupMessage(errorString);
      setHasErrored(true);
      return;
    }

    setIsSearchClicked(true);
    let unitIds: Array<number> = [];
    let allUnitIds: Array<number> = [];
    const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return //Break if no children
      if (unitArray.length === 0) return
      for (let i = 0; i < unitArray.length; i++) {
          const unit = unitArray[i];
          if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnittreeName.toLowerCase())) {  // && unit.name.toLowerCase().includes(filterUnittreeName.toLowerCase())
            if (filterUnittreeName !== "") {
              if (unit.name.toLowerCase().includes(filterUnittreeName.toLowerCase())) {
                unitIds.push(unit.unitID)
              }
            } else {
              unitIds.push(unit.unitID)
            }
          } 
          allUnitIds.push(unit.unitID);
          getSelectedUnitIds(unit.childUnitTreeItems)
      }
  }
  getSelectedUnitIds(units)
  searchUnitList(
    oidcUser.access_token,
    requestingUserId,
    orgDomain,
    unitIds.toString(),  
    filterUnitName,  
    includeInactive,
    unitID == "" ? 0 : parseInt(unitID),
    parseInt(orgId)
  ).then((response) => {
    if (
      response !== null &&
      response !== undefined &&
      response.isSuccess &&
      response.listOfUnit !== null &&
      response.listOfUnit !== undefined
    ) {
      setActiveKey("");
      if(response.listOfUnit.length > 0) {
        let updatedUsersList = response.listOfUnit.map((item: any) => {
          return {
            ...item,
            isChecked: false,
          };
        });
        setUnitList(updatedUsersList);
        setUnitListOpen(true);
        setUnitMgmntInputProps({
          unitID, filterUnitName, includeInactive, units, unitListOpen: true
        });   
        setIsSearchClicked(false);

        let element = document.getElementById("userList");
        if (!!element)
          element.scrollIntoView();

      } else {
        setShowWarningModal(true);
        setModalPopupMessage('No Records found, please try again.');
        setHasErrored(false);
        setSaveSuccess(false);
        setIsSearchClicked(false);
        return;
      }
    } else {
      setShowWarningModal(true);
      setModalPopupMessage('Fetching record has failed, please try again.');
      setHasErrored(true);
      setIsSearchClicked(false);
      return;
    }
  });
  };

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };


  if (loading) return <LoaderContainer />;

  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }}
      />
      <CustomTooltip displayText={["Guide Me"]}>
        <span
          onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific}
        >
          <GuideIcon />
        </span>
      </CustomTooltip>
    

      <BrandedCard>
        <div className={styles.reportWrraper}>
          <div className={styles.unittreesection}>
            <div>
              <Units isResetClicked={isResetClicked} givenUnits={units} givenFilterUnitName={filterUnittreeName} setGivenFilterUnitName={setFilterUnittreeName}/>
            </div>
          </div>
          <div className={styles.accountRightSection}>
            <p
              style={{
                fontWeight: 700,
                color: "#D3D6DF",
                fontSize: "14px",
                fontStyle: "italic",
                marginTop: "5px",
              }}
            >
              Enter as many details as required to searchfor the Unit
            </p>
            <div className={styles.firstNameInput}>
              <div className="unitName">
                <TextField
                  className={styles.input}
                  label={"Unit Name"}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  value={filterUnitName}
                  onChange={(e) => setFilterUnitName(e.target.value)}/>
              </div>
            </div>           
            <div className={styles.inputContainer}>
              <div className="unitID">
                <TextField
                  className={styles.input}
                  label={"Unit ID"}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  value={unitID}
                  onChange={(e) => setunitID(e.target.value)}/>
              </div>
            </div>
            <div className={`${styles.inactiveContainer} includeInactive`}>                      
              
              <Checkbox
                size="small"
                color="primary"
                checked={includeInactive}
                className={styles.checkBox}
                onClick={() => {
                  setincludeInactive(!includeInactive);                  
                  setUnitMgmntInputProps({
                     unitID, filterUnitName, includeInactive: !includeInactive, units, unitListOpen
                  });   
                }}
              />{" "}
              {"Include Inactive Units"}
            </div>
            <div style={{ display: "flex", flexDirection: "row-reverse",paddingTop:"10px" }}>
              <div className="resetAll" style={{ marginBottom: "10px", marginRight: "-5px" }}>
                <CustomTooltip displayText={["Reset"]}>
                  <span className={styles.emailResetIcon} onClick={handleResetData}>
                    <ResetIcon />
                  </span>
                </CustomTooltip>
              </div>
              <div className="searchUsers">
                <CustomTooltip displayText={["Search"]}>
                  <Button
                    onClick={handleSearch}
                    disabled={isSearchClicked}
                    className={styles.moduleListButton}
                    variant="contained"
                    color="primary">
                    <SearchIcon />
                  </Button>
                </CustomTooltip>
              </div>
              <div className={styles.loaderContainer}>
                <CircularProgress
                  style={{ width: isSearchClicked ? "20px" : "0px", padding: "15px 10px" }}
                  color="primary"
                  size="10"/>
              </div>
            </div>
          </div>

          <div></div>
        </div>
      </BrandedCard>
      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
       
      {unitListOpen && unitList != null && unitList.length > 0 && (
        <div id="userList">
          <UnitListSection usersData={unitList}/>
        </div>
      )}
    </>
  );
};

export default UnitManagement;
