import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { MyTrainingItem } from '../../globalTypes'
//Contexts
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Components
import CustomTooltip from '../../Components/CustomTooltip';
//MUI Components
import Button from '@material-ui/core/Button';
import CertificateIcon from '../../CustomIcons/CertificateIcon';
//styles
import styles from '../../styles/courseList.module.css';

interface Props {
    trainingListItem: MyTrainingItem
    logNavigation: (message: string) => void
}

const CertificateButton = ({ trainingListItem, logNavigation }: Props) => {
    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
    const { status, id, name } = trainingListItem

    if (status !== 2 ) return null
    return (
        <Link
        onClick={() => logNavigation(`Opened ${name} certificate`)}
        to={{
            pathname: `mytraining/${id}/certificate`,
        }}
        >
        <CustomTooltip displayText={[allResourcesToTranslations.viewcertificate]}>
            <Button
            className={styles.courseListButton}
            variant="contained"
            color="primary"
            >
            <CertificateIcon />
            </Button>
        </CustomTooltip>
        </Link>
    );
}

export default CertificateButton
