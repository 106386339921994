import React, { useState, useRef, useContext, useEffect } from "react";
import styles from "../../../styles/organisationmanagement.module.css";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { UIContext } from "../../../contexts/UIContext";
import UnbrandedCard from "../../../Components/UnbrandedCard";
import { Button, CircularProgress, Select, TextField, } from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import currentstyles from '../../../styles/currentAdminReport.module.css'
import validateEmail from "../../../functions/valudateEmail";
import Modal from '../../../Components/Modal';
import { useReactOidc } from "@axa-fr/react-oidc-context";
import CustomTooltip from "../../../Components/CustomTooltip";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";
import LoaderContainer from "../../../Components/LoaderContainer";

import addError from "../../../API/AddError";
import ToggleRightIcon from "../../../CustomIcons/ToggleRightIcon";
import ToggleLeftIcon from "../../../CustomIcons/ToggleLeftIcon";
import responsivestyles from "../../../styles/responsiveGridLayout.module.css";
import getOrgSupportDetails from "../../../API/GetOrgSupportDetails";
import ButtonPanel from "../../CurrentAdminReport/ButtonPanel";
import SaveIcon from "../../../CustomIcons/SaveIcon";
import SaveOrgDeactivations from "../../../API/SuperAdmin/SaveOrgDeactivations";
import GetOrgDeactivationDetails from "../../../API/SuperAdmin/GetOrgDeactivationDetails";
import GetOrgAccountManagers from "../../../API/SuperAdmin/GetOrgAccountManagers";
import DeactivationSentEmailReport from "./DeactivationSentEmailReport";
import { SentEmailReportResultItem } from "../../../globalTypes";


import CloseWhiteIcon from "@material-ui/icons/Close";
import ProceedIcon from "../../../CustomIcons/ProceedIcon";
import getFullSentEmailOrgDeactivationReport from "../../../API/SuperAdmin/GetFullSentEmailOrgDeactivationReport";


const OrganisationDeactivation = () => {
	const history = useHistory();
	const { translations: { allResourcesToTranslations }, currentLanguage, pageTitles } = useContext(TranslationsContext);
	const { setTitle, setTitleEnglish, showSuperAdminUI, updateBackButtonRoute, EnableActivityLogs, superAdminSelectedBasicOrgData } = useContext(UIContext);

	const { oidcUser } = useReactOidc();
	const {
		profile: { user_id, website, },
	} = oidcUser || {};
	const userId = user_id ? parseInt(user_id) : 0;
	const orgId = oidcUser.profile.organisation_id ?? -1;
	const orgDomain = website
		? website
			.toLowerCase()
			.replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
		: "";

	const [loading, setLoading] = useState<boolean>(true);
	const [isRunReportClicked, setIsRunReportClicked] = useState<boolean>(false);
	const [isDeactivateToogle, setIsDeactivateToogle] = useState<boolean>(false);
	const [showSentEmailReport, setShowSentEmailReport] = useState<boolean>(false);

	const [removeOrgDeaatcivationDetails, setRemoveOrgDeaatcivationDetails] = useState<boolean>(false);

	const [deactivationID, setdeactivationID] = useState<number>(0);
	const [deactivationOrganisationID, setdeactivationOrganisationID] = useState<number>(0);
	const [deactivationStartDate, setDeactivationStartDate] = useState("");

	const [scrubbingDays, setScrubbingDays] = useState<number>(730)
	const [scrubbingDate, setScrubbingDate] = useState<string>("")	
	const [isScrubbed, setIsScrubbed] = useState<boolean>(false);
	const [isExtendedbtnvisisble, setIsExtendedbtnvisisble] = useState<boolean>(false);
	const [isExtended, setIsExtended] = useState<boolean>(false);
	const [isActive, setIsActive] = useState<boolean>(true);

	const [toEmail, setToEmail] = useState<string>("")
	const [accountManagerID, setAccountManagerID] = useState<number>(0);
	const [cc, setCC] = useState<string>("")
	const [updatedBy, setUpdatedBy] = useState<string>("")
	const [dateUpdated, setDateUpdated] = useState<string>("")
	const [accountManagerList, setAccountManagerList] = useState([]);
	const [selectedAccountManager, setSelectedAccountManager] = useState<any>({ value: 0 });

	const [filterfromDate, setFromDate] = useState("");
	const [filtertoDate, setToDate] = useState("");
	const [sentEmailReportResult, setSentEmailReportResult] = useState<Array<SentEmailReportResultItem>>([]);
	const [resultError, setResultError] = useState<string>("");

	const [emailSubject, setEmailSubject] = useState<string>("");
	const [emailIsValid, setEmailIsValid] = useState<boolean>(false);
	const [isHandlerBusy, setHandlerBusy] = useState<boolean>(false);
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [saveSuccess, setSaveSuccess] = React.useState(false);
	const [modalPopupMessage, setModalPopupMessage] = useState('');
	const [hasErrored, setHasErrored] = useState<boolean>(false);
	const todayDate = new Date();
	const [showConfirmModalInactive, setshowConfirmModalInactive] = useState<boolean>(false);



	const resetAll = () => {			
		setdeactivationID(0);
		setdeactivationOrganisationID(0);
		setIsExtendedbtnvisisble(false);
		setIsExtended(false);
		setIsActive(true);
		setToEmail("");
		setSelectedAccountManager(0);
		setCC("");
		setUpdatedBy("");
		setFromDate("");
		setToDate("");
	}

	useEffect(() => {
		resetAll();
		OrgDeactivationDetails(false);
		return () => { };
	}, [superAdminSelectedBasicOrgData]);

	useEffect(() => {
		setTitle(allResourcesToTranslations.organisationdeactivation);
		setTitleEnglish(pageTitles.organisationdeactivation);
		updateBackButtonRoute("");		
		OrgDeactivationDetails(false);
	}, []);

	const OrgDeactivationDetails = (flag: boolean) => {
		setLoading(true);
		if (superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData && superAdminSelectedBasicOrgData.orgID > 0) {
			GetOrgAccountManagers(oidcUser.access_token, userId,
				orgDomain,
				parseInt(orgId),
				(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : parseInt(orgId)),
				(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : orgDomain),
				(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((data) => {
					if (data !== null && data.orgAccountManagers !== null) {
						let activeAccList = data.orgAccountManagers.filter((item: any) => item.active === true);
						setAccountManagerList(activeAccList);
					}
				})
			GetOrgDeactivationDetails(oidcUser.access_token, userId, orgDomain, parseInt(orgId),
				(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : parseInt(orgId)),
				(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : orgDomain),
				(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((result) => {
					if (result.isSuccess && result.error == '') {
						if (result.orgDeactivations !== null) {
							setDeactivationStartDate(result.orgDeactivations.deactivationStartDate !== null ? formatDate(new Date(result.orgDeactivations.deactivationStartDate)) : "");
							setdeactivationOrganisationID(result.orgDeactivations.organisationID);
							setdeactivationID(result.orgDeactivations.deactivationID);
							setToEmail(result.orgDeactivations.toEmail);
							setCC(result.orgDeactivations.cc);
							setUpdatedBy(result.orgDeactivations.updatedBy);
							setSelectedAccountManager(result.orgDeactivations.accountManagerID)
							setDateUpdated(result.orgDeactivations.dateUpdated !== null ? formatDateddmmyy(new Date(result.orgDeactivations.dateUpdated)) : "");
							setIsDeactivateToogle(true);
							setIsExtended(result.orgDeactivations.isExtended);
							getscrubremainingdays(new Date(result.orgDeactivations.deactivationStartDate), result.orgDeactivations.isExtended ? true : false);
							DataScrubbFinalDate(result.orgDeactivations.deactivationStartDate !== null ? formatDate(new Date(result.orgDeactivations.deactivationStartDate)) : "", result.orgDeactivations.isExtended ? true : false);
							setIsScrubbed(result.orgDeactivations.isScrubbed);
							setEmailSubject(result.orgDeactivations.orgDeactivationSubject !== null ? result.orgDeactivations.orgDeactivationSubject : "");							
							if (result.orgDeactivations.isExtended) {
								setIsExtendedbtnvisisble(result.orgDeactivations.isExtended ? true : false);
							}

							if (!result.orgDeactivations.isExtended) {
								let ExtendedDate = new Date(result.orgDeactivations.deactivationStartDate);
								ExtendedDate.setDate(ExtendedDate.getDate() + 365);
								setIsExtendedbtnvisisble((todayDate >= ExtendedDate) ? true : false);
							}
							setShowSentEmailReport(true);
							setIsRunReportClicked(false);							
						}
					}
					else {
						setShowSentEmailReport(false);
						if (result.error == "Given organisation deactivation details not found" && !flag) {
							setIsDeactivateToogle(false);
							setIsRunReportClicked(false);
						}
						if (flag) {
							let newDate = new Date(todayDate);
							setDeactivationStartDate(newDate.toString() !== null ? formatDate(new Date(newDate.toString())) : "");
							DataScrubbFinalDate(newDate, false);
							setSelectedAccountManager(0);
							getOrgSupportDetails(oidcUser.access_token, userId, orgDomain,
								(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : -1)).then(dataResult => {
									setToEmail(dataResult.supportDetails.emailAddress == "" ? dataResult.supportDetails.defaultEmailAddress : dataResult.supportDetails.emailAddress);
									setCC(dataResult.supportDetails.defaultEmailAddress);
									setLoading(false);

								}).catch((error: any) => {
									addError(oidcUser.access_token, userId, orgDomain, "SupportModel getOrgSupportDetails in NEWSALT ClientApp", null, "SupportModel.tsx", 6, EnableActivityLogs);
								});
						}
					}
					setLoading(false);
				});
		}
		else {
			setShowModal(true);
			setSaveSuccess(false);
			setHasErrored(true);
			setLoading(false);
			setModalPopupMessage('Getting Organisation Deactivation details failed! Please select Organisation!');

		}
	};

	const saveOrgDeactivation = () => {
		let str = toEmail;
		let isvalidemail = true;

		{
			str.split(';').map((value, index) => {
				if (!validateEmail(value.trim())) {
					setShowModal(true);
					setModalPopupMessage('Please enter valid email.');
					setHasErrored(true);
					isvalidemail = false;
					return;
				}
			})
		}

		let strcc = cc;
		{
			strcc.split(';').map((value, index) => {
				if (!validateEmail(value.trim())) {
					setShowModal(true);
					setModalPopupMessage('Please enter valid cc email.');
					setHasErrored(true);
					isvalidemail = false;
					return;
				}
			})
		}

		if (isvalidemail) {
			let filterDateTonew = deactivationStartDate !== null ? formatDate(new Date(deactivationStartDate)) : "";
			SaveOrgDeactivations(oidcUser.access_token, userId,
				orgDomain,
				parseInt(orgId), filterDateTonew, isExtended, isActive, toEmail, parseInt(selectedAccountManager), cc, deactivationID
				, (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : parseInt(orgId)),
				(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : orgDomain),
				(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")
			).then((result: { isSuccess: any; error: string; }) => {
				if (!!result && result.isSuccess && result.error == '') {
					setShowModal(true);
					setHasErrored(false);
					setSaveSuccess(true);
					setModalPopupMessage("Organisation deactivation details saved successfully!");
					setLoading(false);
					setIsSaving(false);
					OrgDeactivationDetails(true);
				}
				else {
					setShowModal(true);
					setHasErrored(true);
					setSaveSuccess(false);
					setModalPopupMessage("Can not save Organisation deactivation details! Please try again." + result.error !== "" ? ";" + result.error : "");
				}

			});
		}

	};

	const handleProceedInactive = () => {
		if (deactivationOrganisationID > 0) {
			//inactive org deactivation detail
			let filterDateTonew = deactivationStartDate !== null ? formatDate(new Date(deactivationStartDate)) : "";
			SaveOrgDeactivations(oidcUser.access_token, userId, orgDomain, deactivationOrganisationID, filterDateTonew, isExtended, false, toEmail,
				 parseInt(selectedAccountManager), cc, deactivationID,
				 (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : parseInt(orgId)),
				 (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : orgDomain),
				 (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((result: { isSuccess: any; error: string; }) => {
				if (!!result && result.isSuccess && result.error == '') {
					setShowModal(true);
					setHasErrored(false);
					setSaveSuccess(true);
					setModalPopupMessage("Remove the deactivation for this organisation successfully!");
					setLoading(false);
					setIsSaving(false);
					setIsDeactivateToogle(false);
					OrgDeactivationDetails(false);
					handleConfirmModalInactive(false);
				}
				else {
					setShowModal(true);
					setHasErrored(true);
					setSaveSuccess(false);
					setModalPopupMessage("Can not remove organisation deactivation details! Please try again." + result.error !== "" ? ";" + result.error : "");
				}
			});
		}
	}

	const handleConfirmModalInactive = (value: boolean) => {
		setshowConfirmModalInactive(value);
	}

	const handleToogleonOff = (deactivateToogle: boolean) => {	
		if(deactivateToogle)
		{
			resetAll();
			OrgDeactivationDetails(true);

		}
		if (!deactivateToogle && deactivationOrganisationID > 0) {
			setIsDeactivateToogle(true);
			handleConfirmModalInactive(true);
		}
	};
	
	const handleCancelConfirmPopupInactive = () => {
		handleConfirmModalInactive(false);
		setIsSaving(false);
	}
	const getscrubremainingdays = (deactivationStartDate: Date, Extended: boolean) => {
		let newScrubbDate = deactivationStartDate;
		let date1 = new Date(todayDate.toDateString());
		let date2 = null;
		if (Extended) {
			date2 = new Date(newScrubbDate.setDate(newScrubbDate.getDate() + 1095));
		}
		else {
			date2 = new Date(newScrubbDate.setDate(newScrubbDate.getDate() + 730));
		}
		// Calculating the time difference
		// of two dates
		let Difference_In_Time =
			date2.getTime() - date1.getTime();

		// Calculating the no. of days between
		// two dates
		let Difference_In_Days =
			Math.round
				(Difference_In_Time / (1000 * 3600 * 24));

		// To display the final no. of days (result)
		// console.log
		// 	("Total number of days between dates:\n" +
		// 		date1.toDateString() + " and " +
		// 		date2.toDateString() +
		// 		" is: " + Difference_In_Days + " days");

		setScrubbingDays(Difference_In_Days);
	}	

	const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
	{ 		
		if (!isNaN(givenDate.getTime())) {
			const year = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(givenDate);
			const month = new Intl.DateTimeFormat('en-AU', { month: 'numeric' }).format(givenDate);
			const day = new Intl.DateTimeFormat('en-AU', { day: 'numeric' }).format(givenDate);			
			return (year.toString() + "-" +
				(parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" +
				(parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
		}
		else {
			return "";
		}		
	};

	const formatDateddmmyy = (givenDate: Date) => // To format as yyyy-MM-dd
	{
		if (!isNaN(givenDate.getTime())) {
			const year = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(givenDate);
			const month = new Intl.DateTimeFormat('en-AU', { month: 'numeric' }).format(givenDate);
			const day = new Intl.DateTimeFormat('en-AU', { day: 'numeric' }).format(givenDate);

			return ((parseInt(day) < 10 ? "0" + day.toString() : day.toString()) + "-" +
				(parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" +
				year.toString());
		}
		else {
			return "";
		}
	};
	const IsValidDate = (givenDate: Date) => // To format as yyyy-MM-dd
	{ 
		let givenDatenew = givenDate.toDateString()
		let cuurdate = new Date().toDateString()		

		if(givenDatenew < cuurdate)
		{
					setShowModal(true);
					setHasErrored(true);
					setSaveSuccess(false);
					setModalPopupMessage("Please select valid date, Past date not allowed");
		}
		else
		{
				setShowModal(false);
		}
	};

	const handleEmailSubject = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if ((event.target.value != '')) {
			setEmailIsValid(false)
		}
		setEmailSubject(event.target.value);
	};

	const handleClosePopup = () => {
		setShowModal(false);
	};

	const runReport = () => {

		const toEmail: string = "";
		const subject: string = emailSubject;
		const body: string = "";
		const emailid: number = 0;
		setIsRunReportClicked(true);
		// Activity log 
		addError(oidcUser.access_token, userId, orgDomain, "Started SentEmail Deactivation Report in NEWSALT ClientApp", null, "SentEmailReport.tsx", 6, EnableActivityLogs);

		getFullSentEmailOrgDeactivationReport(oidcUser.access_token,
			userId,
			orgDomain,
			parseInt(orgId),
			formatDate(new Date("1/1/1900")),
			formatDate(new Date()),
			toEmail,
			subject,
			body,
			emailid,
			deactivationID,
			(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : parseInt(orgId)),        
			(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : orgDomain),
			(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")
		).then((result) => {
			setResultError(((result === null || result === undefined || result.isSuccess || result.error === null || result.error === undefined) ? "" : result.error));
			setSentEmailReportResult(result === null ? null : result.sentemailOrgDeactivationreportItem);
		})
	}

	const setModalOpen = (isOpen: boolean) => {

	}

	const DataScrubbFinalDate = (date: any, Extended: boolean) => {
		let newDate = new Date(date);
		if (Extended) {
			newDate.setDate(newDate.getDate() + 1095);
		}
		else {
			newDate.setDate(newDate.getDate() + 730);
		}
		setScrubbingDate(newDate.toDateString());

		return newDate;
	}

	const handleextend = () => {

		let filterDateTonew = deactivationStartDate !== null ? formatDate(new Date(deactivationStartDate)) : "";
		SaveOrgDeactivations(oidcUser.access_token, userId, orgDomain, deactivationOrganisationID, filterDateTonew, true, true, toEmail, parseInt(selectedAccountManager), cc, deactivationID
			, (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : parseInt(orgId)),
			(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : orgDomain),
			(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((result: { isSuccess: any; error: string; }) => {
				if (!!result && result.isSuccess && result.error == '') {
					setShowModal(true);
					setHasErrored(false);
					setSaveSuccess(true);
					setModalPopupMessage("Organisation Extended Delete Date extended successfully!");
					setLoading(false);
					setIsSaving(false);
					OrgDeactivationDetails(true);
					setIsRunReportClicked(false);
				}
				else {
					setShowModal(true);
					setHasErrored(true);
					setSaveSuccess(false);
					setModalPopupMessage("Can not save Organisation Extended Delete Date details! Please try again." + result.error !== "" ? ";" + result.error : "");
				}
			});
	}

	const addDays = (date: any, days: number) => {
		let newDate = new Date(date);
		newDate.setDate(date.getDate() - days);
		return newDate.toDateString();
	}


	const GenerateNotificationTimeline = (Extended: boolean) => {
		let deactivationDate = new Date(deactivationStartDate);
		if (Extended) {
			new Date(deactivationDate.setDate(deactivationDate.getDate() + 1095));
		}
		else {
			new Date(deactivationDate.setDate(deactivationDate.getDate() + 730));
		}

		return <>
			{Extended ?
				<><div className={`${responsivestyles.columnrule_s_12}`}>
					<div className={`${responsivestyles.columnrule_s_2}`}>
						{addDays(deactivationDate, 1095)}
					</div>
					<div className={`${responsivestyles.columnrule_s_2}`}>
						1st Reminder
					</div>
					<div className={`${responsivestyles.columnrule_s_8}`}>
						1095 days
					</div>
				</div><div className={`${responsivestyles.columnrule_s_12}`}>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							{addDays(deactivationDate, 730)}
						</div>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							2nd Reminder
						</div>
						<div className={`${responsivestyles.columnrule_s_8}`}>
							730 days
						</div>
					</div><div className={`${responsivestyles.columnrule_s_12}`}>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							{addDays(deactivationDate, 365)}
						</div>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							3rd Reminder
						</div>
						<div className={`${responsivestyles.columnrule_s_8}`}>
							365 days
						</div>
					</div><div className={`${responsivestyles.columnrule_s_12}`}>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							{addDays(deactivationDate, 180)}
						</div>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							4th Reminder
						</div>
						<div className={`${responsivestyles.columnrule_s_8}`}>
							180 days
						</div>
					</div><div className={`${responsivestyles.columnrule_s_12}`}>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							{addDays(deactivationDate, 30)}
						</div>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							5th Reminder
						</div>
						<div className={`${responsivestyles.columnrule_s_8}`}>
							30 days
						</div>
					</div><div className={`${responsivestyles.columnrule_s_12}`}>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							{addDays(deactivationDate, 7)}
						</div>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							6th Reminder
						</div>
						<div className={`${responsivestyles.columnrule_s_8}`}>
							7 days
						</div>
					</div><div className={`${responsivestyles.columnrule_s_12}`}>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							{addDays(deactivationDate, 0)}
						</div>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							8th Reminder
						</div>
						<div className={`${responsivestyles.columnrule_s_8}`}>
							Data deleted
						</div>
					</div>
				</>
				:
				<><div className={`${responsivestyles.columnrule_s_12}`}>
					<div className={`${responsivestyles.columnrule_s_2}`}>
						{addDays(deactivationDate, 730)}
					</div>
					<div className={`${responsivestyles.columnrule_s_2}`}>
						1st Reminder
					</div>
					<div className={`${responsivestyles.columnrule_s_8}`}>
						730 days
					</div>
				</div><div className={`${responsivestyles.columnrule_s_12}`}>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							{addDays(deactivationDate, 365)}
						</div>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							2nd Reminder
						</div>
						<div className={`${responsivestyles.columnrule_s_8}`}>
							365 days
						</div>
					</div><div className={`${responsivestyles.columnrule_s_12}`}>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							{addDays(deactivationDate, 180)}
						</div>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							3rd Reminder
						</div>
						<div className={`${responsivestyles.columnrule_s_8}`}>
							180 days
						</div>
					</div><div className={`${responsivestyles.columnrule_s_12}`}>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							{addDays(deactivationDate, 30)}
						</div>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							4th Reminder
						</div>
						<div className={`${responsivestyles.columnrule_s_8}`}>
							30 days
						</div>
					</div><div className={`${responsivestyles.columnrule_s_12}`}>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							{addDays(deactivationDate, 7)}
						</div>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							5th Reminder
						</div>
						<div className={`${responsivestyles.columnrule_s_8}`}>
							7 days
						</div>
					</div>
					<div className={`${responsivestyles.columnrule_s_12}`}>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							{addDays(deactivationDate, 0)}
						</div>
						<div className={`${responsivestyles.columnrule_s_2}`}>
							7th Reminder
						</div>
						<div className={`${responsivestyles.columnrule_s_8}`}>
							Data deleted
						</div>
					</div></>
			}
		</>
	}

	


	if (loading) return <LoaderContainer />;
	return (
		<>
			<Modal modalOpen={showConfirmModalInactive} setModalOpen={handleConfirmModalInactive} type="warning">
				<div className={styles.modalContentWrapper}>
					<h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>{allResourcesToTranslations.confirmation}</h2>
					<div className={styles.inputContainer}>
						<p>
						Are you sure you want to cancel the deactivation for this organisation<span><b>{superAdminSelectedBasicOrgData?.orgName}</b></span> ? 
						<br></br>By cancelling the deactivation, all deactivation settings for this organisation will be removed.
						</p>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							position: "relative",
							top: "10px",
						}}
					>
						<CustomTooltip displayText={[allResourcesToTranslations.return]}>
							<span className={styles.returnIcon} onClick={handleCancelConfirmPopupInactive}>
								<CloseWhiteIcon />
							</span>
						</CustomTooltip>
						<CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
							<span className="saveData">
								<Button
									onClick={handleProceedInactive}
									variant="contained"
									color="primary"
									className="save"
								>
									<ProceedIcon />
								</Button>
							</span>
						</CustomTooltip>
					</div>
				</div>
			</Modal>
			<UnbrandedCard>
				<div className={styles.mainWrapper}>
					<div className={styles.Wrapper}>					
						<div className={`${styles.inputContainer}`}>
							<div className='ShowExpireCertificate'>
								
								<div onClick={() => {setIsDeactivateToogle(!isDeactivateToogle); handleToogleonOff(!isDeactivateToogle);}}>
									<span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} >{isDeactivateToogle ? <ToggleRightIcon /> : <ToggleLeftIcon />}</span>
								</div>
								<div className={styles.inputContainer}>
									<span id="allowLearnerswitch" className={styles.labelText}>
										{allResourcesToTranslations.deactivateorganisation}</span>
								</div>
							</div>
						</div>
						{isDeactivateToogle &&
							<div>
								<div className={responsivestyles.rowrule} style={{ width: '100%', paddingTop: "30px" }}>
									<div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
										<div className={`${styles.inputContainer}`}>
											{deactivationID > 0 ?
											<>						
													<TextField
													className={styles.input}
													disabled	
													label={allResourcesToTranslations.deactivationstartdate}												
													variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
													value={formatDateddmmyy(new Date((deactivationStartDate)))}
													/>	
													</>  
											:
											<>												
													<TextField className={styles.input} label={allResourcesToTranslations.deactivationstartdate} variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
													type="date"
													disabled={deactivationID > 0}
													inputProps={{ min: formatDate(new Date()) }}	
													defaultValue={deactivationStartDate !== null ? formatDate(new Date(deactivationStartDate)) : formatDate(new Date(todayDate.toString()))}
													onChange={(e) => {
														setDeactivationStartDate(formatDate(new Date(e.target.value)));
														DataScrubbFinalDate(new Date(e.target.value), false);	
														IsValidDate(new Date(e.target.value));												
													} } /></>
											}
										</div> 									
									</div>
									<div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
										<div className={`${styles.inputContainer}`} style={{ paddingLeft: "10px" }}>
											<div style={{ height: "53px", width: "100%", backgroundColor: "red", color: "white", border: "solid 1px red", borderRadius: "5px" }}>
												<div style={{ padding: "10px" }}>{allResourcesToTranslations.accDatadeletedindays} {scrubbingDays} days ({scrubbingDate}) </div>
											</div>
										</div>
									</div>
								</div>
								<div className={styles.lableContainer} >
									<div className={`${styles.inputContainer}}`}>
										<span className={styles.labelText}>{allResourcesToTranslations.notificationwillbe}:</span>
									</div>
								</div>
								<div className={responsivestyles.rowrule} style={{ width: '100%', paddingTop: "10px" }}>
									<div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
										<div className={`${styles.inputContainer}`}>

											<div style={{ paddingBottom: "30px" }}>
												<div className="labelText">
													<TextField
														className={styles.input}
														label={"ToEmail"}
														variant="outlined"
														autoComplete="off"
														InputLabelProps={{ shrink: true }}
														value={toEmail}
														onChange={(e) => setToEmail(e.target.value)}
													/>
												</div>
											</div>

											{accountManagerList &&
												<div className={`${styles.inputContainer}`} style={{ margin: "0 0 25px 0" }} >
													<Select className={`${styles.input} accountmanager`} style={{ height: "50px" }}
														value={selectedAccountManager}			
														onChange={(e) => setSelectedAccountManager(e.target.value)}
														native
														variant="outlined"
														label={"Account Manager"}
														id="accountmanager">
														<option value="0"> -- Select Account Manager -- </option>
														{accountManagerList.map((item: any) => {
															return <option key={item.accountManagerID.toString()} value={item.accountManagerID}>{item.firstName}</option>
														})}
													</Select>

												</div>

											}
											<div style={{ paddingBottom: "30px" }}>
												<div className="email">
													<TextField
														className={styles.input}
														label={"cc"}
														variant="outlined"
														autoComplete="off"
														InputLabelProps={{ shrink: true }}
														value={cc}
														onChange={(e) => setCC(e.target.value)}
													/>
												</div>
											</div>
										</div>
									</div>

									<div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
										<div className={`${styles.inputContainer}`} style={{ paddingLeft: "15px" }}>
											{isExtendedbtnvisisble &&
												<div className="btnextendseactivation" >
													<CustomTooltip displayText={[allResourcesToTranslations.extenddate]}>
														<Button
															type="submit"
															disabled={isExtended || isScrubbed}
															variant="contained"
															style={!isExtended ? { backgroundColor: '#63C2FF' } : { backgroundColor: 'gray' }}
															color="primary"
															className="saveData"
															onClick={handleextend}

														>
															<span style={{ paddingTop: '5px' }}>
																{allResourcesToTranslations.extenddate}
															</span>
														</Button>
													</CustomTooltip>
												</div>
											}
											{updatedBy != "" &&
												<><div className={styles.labelTextNormal} style={isExtendedbtnvisisble ? { paddingTop: "30px" } : { paddingTop: "0px" }}>
													Last Modified By : {updatedBy}
												</div><div className={styles.labelTextNormal} style={{ paddingTop: "10px" }}>
														Last Modified Date : {dateUpdated}
													</div></>
											}
											{isExtended &&
												<div className={styles.labelTextNormal} style={{ paddingTop: "10px" }}>
													Action : Extended By 12 months
												</div>
											}
											{isScrubbed &&
												<div className={styles.labelTextNormal} style={{ paddingTop: "10px", paddingLeft:"57px" }}>
												Data is already scrubbed.
												</div>												
											}
										</div>
									</div>

								</div>
								<div className={styles.lableContainer} >
									<div className={`${styles.inputContainer}`}>
										<span className={styles.labelText}>{allResourcesToTranslations.emailnotificationtimeline}:</span>
									</div>
								</div>

								<div className={responsivestyles.rowrule} style={{ width: '100%', paddingTop: "10px" }}>
									<div className={`${responsivestyles.columnrule_s_12}`}>
										<div className={`${styles.inputContainer}`}>
											<div style={{ height: "160px", width: "100%", border: "2px dashed #c4c4c4", borderRadius: "5px" }}>
												<div style={{ margin: "15px" }} className={styles.labelTextNormal}>
													{GenerateNotificationTimeline(isExtended)}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", paddingTop: "10px" }}>

									<CustomTooltip displayText={["Save"]}>
										<span className="saveData">
											<Button
												disabled={
													(toEmail == "" || cc == "" || selectedAccountManager == 0 || selectedAccountManager.value == 0 || deactivationStartDate == "" || isSaving  || isScrubbed || ( formatDate(new Date(deactivationStartDate)) < formatDate(new Date())))
												}
												onClick={saveOrgDeactivation}
												variant="contained"
												color="primary"
												className="save"
											>
												<SaveIcon />
											</Button>
										</span>
									</CustomTooltip>

									{/* <div className={styles.loaderContainer}>
										<CircularProgress
											style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
											color="primary"
											size="10"
										/>
									</div> */}
								</div>
								{showSentEmailReport &&
								<div>
									<div className={`${styles.inputContainer}`} >
										<h4 className={styles.sectionTitle}>{allResourcesToTranslations.sentemailreport}:</h4>
									</div>
									<div className={`${styles.inputContainer} `} >
										<div className="emailSubject">
											<TextField
												className={styles.input}
												label={allResourcesToTranslations.emrepgmemsub}
												style={{ marginBottom: "10px" }}
												variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
												value={emailSubject}
												onChange={handleEmailSubject}
												error={emailIsValid}
												placeholder={"*" + allResourcesToTranslations.emrepgmfldreqd}
											/>
										</div>
									</div>
									<div style={{ textAlign: "right" }}>
										<div className={styles.loaderContainer}>
											<CircularProgress
												style={{ width: isHandlerBusy ? "20px" : "0px", marginTop: '13px' }}
												color="primary"
												size="10"
											/>
										</div>
									</div>
									<div className={`${styles.inputContainer} `} >
										<ButtonPanel openReportSchedulerModal={setModalOpen} runReport={runReport} resetAll={resetAll} />
									</div>
									{isRunReportClicked &&
									<div className={`${styles.inputContainer} `} >
										<div className="sendEmailData">
											<DeactivationSentEmailReport sentEmailReportResult={sentEmailReportResult} inputFilterFromDate={filterfromDate} inputFilterToDate={filtertoDate} inputFilterToEmail={''} inputFilterSubject={emailSubject} inputFilterBody={''} resultError={resultError}></DeactivationSentEmailReport>
										</div>
									</div>
									}
									</div>
								}
							</div>
						}
					</div>
				</div>
				{showModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
			</UnbrandedCard>
		</>
	);
};

export default OrganisationDeactivation;
