import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export const MyTraining_PieChartComponent = ({ChartTitle , DisplayPercentage,themecolor}) => {

Highcharts.setOptions({
  //colors: [themecolor,'#21394f'],
  colors: ['#20B786','#21394f'],
  font:['Open Sans sans-serif']
  
  });  
  const pieChart = {
    chart: {
      align: 'center',
      minwidth:90,
      width:  150,
      height: 120,
      type: 'pie'     
       
  }, 
  title: {
    text: ChartTitle,     
  }, 
  exporting: {
    enabled: false
  }, 
  credits: {
   //text: 'Example.com',
    //href: 'http://www.example.com',
    enabled: false
  },      
 legend: {
     align: 'center',
     x:0,
     verticalAlign: 'top',
     y: 0,
     floating: false,        
     shadow: false,
 },
 tooltip: {
   enable:false,
  },
  plotOptions: {
    pie: {
        innerSize: '50%',
        //borderWidth: 5,
        borderColor: null,
        size:  100 ,                
        dataLabels: {
            connectorWidth: false,
            enabled: false,
            format: '<b>{point.name}</b><br>{point.percentage:.1f} %'
        }
    }
},
 title: {
      verticalAlign: 'middle',
      floating: false,
      text: DisplayPercentage.toString() + "%" ,
      style: {
        fontSize: "18px",
          color: "#21394f",
        fontWeight: 'bold !important',
        fontFamily: "'Open Sans', sans-serif",
      }
    },
 series: [{    
 enableMouseTracking :false,
 data: [
        {
        name: 'Course Completion',
        y: DisplayPercentage
        },
        {
        name: 'Course Completion',
        y: (100 - DisplayPercentage)
        }
       ]
   } ]
  };
  return( 
  <>
  <HighchartsReact highcharts={Highcharts} options={pieChart} />
  </>
  )

}

export default MyTraining_PieChartComponent;
