import React from "react";

const PDFWhiteIcon = () => {

 return (    
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_102_3144)">
    <path d="M9.363 2C13.518 2 12 8 12 8C12 8 18 6.35 18 10.457V22H2V2H9.363ZM10.189 0H0V24H20V9.614C20 7.223 13.352 0 10.189 0ZM15 13H12.372V16.686H13.279V15.214H14.769V14.482H13.279V13.784H15V13ZM10.1 13H8.501V16.686H10.1C10.637 16.686 11.061 16.505 11.362 16.151C11.917 15.493 11.949 14.117 11.3 13.459C11.002 13.159 10.588 13 10.1 13ZM9.408 13.783H9.904C10.377 13.783 10.706 13.956 10.819 14.427C10.883 14.694 10.896 15.106 10.798 15.375C10.67 15.726 10.417 15.903 10.044 15.903H9.407V13.783H9.408ZM6.668 13H5V16.686H5.907V15.409H6.668C7.287 15.409 7.732 15.132 7.892 14.646C7.987 14.355 7.987 14.049 7.892 13.761C7.732 13.277 7.286 13 6.668 13ZM5.907 13.732H6.453C6.688 13.732 6.92 13.76 7.029 13.96C7.096 14.083 7.096 14.326 7.029 14.449C6.92 14.648 6.688 14.676 6.453 14.676H5.907V13.732Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_102_3144">
    <rect width="20" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
)
}

export default PDFWhiteIcon;