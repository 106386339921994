import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const LicenseReportMenuIcon = () => (    
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 4.07568H18C18.5304 4.07568 19.0391 4.2864 19.4142 4.66147C19.7893 5.03654 20 5.54525 20 6.07568V20.0757C20 20.6061 19.7893 21.1148 19.4142 21.4899C19.0391 21.865 18.5304 22.0757 18 22.0757H6C5.46957 22.0757 4.96086 21.865 4.58579 21.4899C4.21071 21.1148 4 20.6061 4 20.0757V6.07568C4 5.54525 4.21071 5.03654 4.58579 4.66147C4.96086 4.2864 5.46957 4.07568 6 4.07568H8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15 2.07568H9C8.44772 2.07568 8 2.5234 8 3.07568V5.07568C8 5.62797 8.44772 6.07568 9 6.07568H15C15.5523 6.07568 16 5.62797 16 5.07568V3.07568C16 2.5234 15.5523 2.07568 15 2.07568Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.5522 16.2168H16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.99426 16.2168H8.00001" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.5522 12.0757H16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.99426 12.0757H8.00001" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


);

export default LicenseReportMenuIcon
