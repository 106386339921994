import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const ThemeIconMenu = () => (    
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1079_3705)">
    <path d="M19.4504 6.80811L5.99684 6.8081" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.81069 14.3073V16.8451C9.23699 17.3432 8.79809 17.9823 8.54004 18.7035C8.21888 19.601 8.19488 20.5781 8.47159 21.4903C8.74831 22.4025 9.31106 23.2016 10.0767 23.7695C10.8424 24.3373 11.7704 24.6439 12.7237 24.6439C13.677 24.6439 14.605 24.3373 15.3707 23.7695C16.1363 23.2016 16.6991 22.4025 16.9758 21.4903C17.2525 20.5781 17.2285 19.601 16.9074 18.7035C16.6493 17.9823 16.2104 17.3432 15.6367 16.8451V14.3073L13.6367 14.3073V17.3373C13.6367 17.6713 13.8035 17.9833 14.0812 18.1688C14.517 18.46 14.8477 18.8837 15.0243 19.3773C15.2009 19.8708 15.2141 20.4081 15.0619 20.9097C14.9098 21.4114 14.6003 21.8508 14.1793 22.1631C13.7582 22.4753 13.2479 22.6439 12.7237 22.6439C12.1995 22.6439 11.6892 22.4753 11.2681 22.1631C10.8471 21.8508 10.5376 21.4114 10.3855 20.9097C10.2333 20.4081 10.2465 19.8708 10.4231 19.3773C10.5997 18.8837 10.9304 18.46 11.3662 18.1688C11.6439 17.9833 11.8107 17.6713 11.8107 17.3373V14.3073L9.81069 14.3073Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5059 2.58844C18.0276 2.58844 18.4505 3.01132 18.4505 3.53297L18.4505 12.3628C18.4505 12.8845 18.0276 13.3073 17.5059 13.3073H13.6638V15.3073H17.5059C19.1322 15.3073 20.4505 13.989 20.4505 12.3628L20.4505 3.53297C20.4505 1.90675 19.1322 0.588444 17.5059 0.588444L7.94135 0.58844C6.31514 0.58844 4.99683 1.90675 4.99683 3.53297L4.99683 12.3628C4.99683 13.989 6.31513 15.3073 7.94135 15.3073H11.7833V13.3073H7.94135C7.4197 13.3073 6.99683 12.8845 6.99683 12.3628L6.99683 3.53297C6.99683 3.01132 7.4197 2.58844 7.94135 2.58844L17.5059 2.58844Z" fill="white"/>
    <circle cx="12.7238" cy="20.0977" r="1.09021" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_1079_3705">
    <rect width="24" height="24" fill="white" transform="translate(0.723633 0.61615)"/>
    </clipPath>
    </defs>
    </svg>
    
);

export default ThemeIconMenu
