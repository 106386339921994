import React, { useContext} from 'react'
import { LearningModule } from '../../globalTypes';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { Link } from 'react-router-dom';
//Components
import CPDScore from './CPDScore'
import CustomTooltip from '../../Components/CustomTooltip';
//MUI Components
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import styles from '../../styles/learningModuleList.module.css';

interface Props {
    learningModule: LearningModule;
    showCPD: boolean;
    logNavigation: (message: string) => void
}

const LessonRow = ({ learningModule, showCPD, logNavigation }: Props) => {
    const getStatusCSSClass = (status: string) => {
        switch(status) {
          case "Passed":
            return styles.passed
          case "Failed":
            return styles.failed
          case "Completed":
            return styles.passed
          default:
            return
        }
      };
    
      const getStatusTranslation = (status: string) => {
        switch(status) {
          case "Passed":
            return allResourcesToTranslations.passed
          case "Failed":
            return allResourcesToTranslations.failed
          case "Completed":
            return allResourcesToTranslations.completed
          case "In Progress":
            return allResourcesToTranslations.inprogress
          case "Not Started":
            return allResourcesToTranslations.notstarted
          case "Expired (Time Elapsed)":
            return allResourcesToTranslations.expiredtimeelapsed
          case "Expired (New Content)":
            return allResourcesToTranslations.expirednewcontent
          default:
            return ""
        }
      };

    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
    const { quizType, lessonType, lessonPoints, lessonStatusName, name, courseID, profilePointsGainedByUser, id, isCPDReady} = learningModule
    if (quizType === "SA" || quizType === "Rise360" || quizType === "Storyline360" || lessonType === "InfoPath" || quizType === "LectoraLQ") return null
    return (
      <TableRow >
        <TableCell align="left">
          <span className={styles.rowTitle}>
            {allResourcesToTranslations.lesson}
          </span>
        </TableCell>
        <CPDScore isLesson={true} showCPD={showCPD} type={lessonType} id={id} isCPDReady={isCPDReady}
                  pointsAvailable={lessonPoints != null && lessonPoints != undefined && lessonPoints >= 0 && (lessonPoints - profilePointsGainedByUser) >= 0 ? (lessonPoints - profilePointsGainedByUser) : 0.0} isQuickFacts={false}/>
        <TableCell align="center" >
            <span className={ getStatusCSSClass(lessonStatusName) }>
            {getStatusTranslation(lessonStatusName)}
          </span>
        </TableCell>
        <TableCell align="center">
          <Link
            onClick={() => logNavigation(`Started ${name} lesson - Type is ${quizType}`)}
            to={{
              pathname: `${courseID}/lesson`,
              state: {
                learningModule: learningModule,
                moduleType: lessonType,
                isLesson: true,
              },
            }}
          >
            <CustomTooltip displayText={[allResourcesToTranslations.start]}>
              <Button
                className={`${styles.moduleListButton} `}
                variant="contained"
                color="primary"
              >
                <PlayArrowIcon />
              </Button>
            </CustomTooltip>
          </Link>
        </TableCell>
      </TableRow>
    );
}

export default LessonRow