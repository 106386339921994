import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import RoundCorners from './RoundedCorners';

export const PolicyProgressChartComponent = ({ totalAssignedPolicies, acceptedPolicies, notAcceptedPolicies }) => {

  Highcharts.setOptions({
    colors: ['#21394f', '#20B786'],
  });
  const progressbarChart = {
    chart: {
      renderTo: 'container',
      type: 'bar',
      height: '200px'
    },
    title: {
      text: null
    },
    xAxis: {
      categories: [''],
      gridLineWidth: 1,
      lineWidth: 0
    },
    yAxis: {
      min: 0,
      max: totalAssignedPolicies + 0.1,
      allowDecimals: false,
      endOnTick: false,
      title: {
        text: ''
      }
    },
    credits: {
      //text: 'Example.com',
      //href: 'http://www.example.com',
      enabled: false
    },  
    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          enabled: false
        }
      }
    },
    series: [{
      name: 'Not Accepted',
      data: [notAcceptedPolicies],
        borderRadiusTopRight: '30%',
        borderRadiusTopLeft : '30%'
    }, {
      name: 'Accepted',
      data: [acceptedPolicies],
      borderRadiusTopRight: notAcceptedPolicies == 0 ? '30%' : '0%',
      borderRadiusTopLeft : notAcceptedPolicies == 0 ? '30%' : '0%'
    }]
  };

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={progressbarChart} />
    </>
  );
};

export default PolicyProgressChartComponent;
