import axios from 'axios';
import { addError } from './AddError';

export const getFullSummaryReport = async ( token: string, 
    userId: number,
    orgDomain: string,
    orgId: number,
    unitIds: string,
    courseIds: string,    
    effectiveFromDateOrg: string,
    effectiveToDateOrg: string,
    groupBy: string, 
    sortBy: string,
    includeInactive: boolean,
    classificationId: number, 
    complete:number,
    incomplete:number,
    count:number
    ) => {
      
  //     console.log({
  //     OrganisationID: orgId,
  //     ReportRequestingUserID : userId,
  //     EffectiveFromDateOrg: effectiveFromDateOrg,  
  //     EffectiveToDateOrg: effectiveToDateOrg,     
  //     classificationId: classificationId,
  //     unitIds: unitIds,
  //     courseIds: courseIds, 
      
  // })
    try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/api/userreport/GetFullSummaryReport`,
          headers:{
            'Content-Type': 'application/json',
            'Authorization' : `Bearer ${token}`
          },
         
    
          data: {
            ReportRequestingUserID : userId,
            OrganisationID : orgId,
            UnitIDs : unitIds,
            CourseIDs : courseIds,    
            ClassificationID : classificationId,
            EffectiveToDateOrg : effectiveToDateOrg,
            EffectiveFromDateOrg : effectiveFromDateOrg
        }
        });
        const data = await response.data;
        //console.log(data)
        return data;
      }catch (error: any) {
        if (error.response) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.response);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.response, "GetFullSummaryReport.tsx", 1)
        } else if (error.request) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.request);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.request, "GetFullSummaryReport.tsx", 1)
        } else if (error.message) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.message);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.message, "GetFullSummaryReport.tsx", 1)
        }
        return null;
      }
}