//React + Typescript
import React, { useContext, useEffect, useState, useRef } from 'react';
import { QuizResults } from '../../globalTypes';
import { Redirect } from 'react-router-dom';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { SessionContext } from '../../contexts/SessionContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
//API Functions
import { getQuizSessionDetails } from '../../API/GetQuizSessionDetails'
//Components
import QuizSummaryOverviewCard from './QuizSummaryOverviewCard'
import ReportSummaryReportCard from '../LearningModules/ReportSummaryReportCard'
//MUI Components
import LoaderContainer from '../../Components/LoaderContainer';

export const QuizSummary = () => {
    const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
    const { setTitle, setTitleEnglish, checkScrollReminder, setShowScrollArrow } = useContext(UIContext);
    const { curSessionID, setCurSessionID, curCourseName, setCurCourseName, curBrowserTabUniqueID, curSelectedModuleTechType } = useContext(SessionContext)
    const [ resultData, setResultData] = useState<null | QuizResults>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const { oidcUser } = useReactOidc();
    const userName = oidcUser?.profile.given_name? oidcUser.profile.given_name : "";
    const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0;
    const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";

    const isMounted = useRef<boolean>(false)
    useEffect(() => {
        isMounted.current = true
        setTitle(allResourcesToTranslations.quizsummary)
        setTitleEnglish(pageTitles.quizsummary)
        if (curSessionID !== "" || curCourseName !== "") {
            getQuizSessionDetails(oidcUser.access_token, userId, orgDomain, curSessionID, !!curSelectedModuleTechType ? curSelectedModuleTechType : "").then((data) => {
                if(isMounted.current) {
                    setResultData(data)
                    setLoading(false)
                    checkScrollReminder()
                }
            })
        }  

        return () => { //Reset course context to "" when summary is closed
            setCurSessionID("")
            setCurCourseName("")
            setShowScrollArrow(false)
            isMounted.current = false
        }
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    if (curCourseName === "" || curSessionID === "") {
        return <Redirect to={`/myreport`} push />; //Redirects to my reports if the pages is reloaded and no selection is made
    }

    const renderOverviewCard = () => {
        let mName = "..."
        let qScore = 0;
        let qPM = 0;
        if (resultData != null){
            mName = resultData.moduleName? resultData.moduleName : "...";
            qScore = resultData.quizScore? resultData.quizScore : 0;
            qPM = resultData.quizPassmark? resultData.quizPassmark : 0;
        }
            return  <QuizSummaryOverviewCard courseName={curCourseName} moduleName={mName} userName={userName} quizScore={qScore} quizPassmark={qPM}/>
        }
    
    const renderReportSummaryCard = (data: null | QuizResults) => {
        let answers = null;
        if (data != null){
            answers = data.quizQADetails
            if (data.quizQADetails.length !== 0){
                return <ReportSummaryReportCard quizQADetailItems={answers}/>
            }
        } 
    }

    if(loading) {
        return <LoaderContainer />
    }
    
    return (
      <>
        <div>
          {renderOverviewCard()}
          <br></br>
          {renderReportSummaryCard(resultData)}
        </div>
      </>
    );
}

export default QuizSummary;
