import React, { useContext, useEffect, useState }  from 'react';
import TopNavbar from '../../Layout/TopNavbar';
import SideMenu from '../../Layout/SideMenu/SideMenu';
import { LearningModule } from '../../globalTypes';

interface Props { 
    learningModuleData: LearningModule,
    showExitButton: boolean | false,
    handleCancel: (() => void) | undefined,
    handleExitProceed: (() => void) | undefined,
    handleModal: (() => any),
    showConfirmModal: boolean | false,
    courseWindow: Window | null,
    curDocumentElement: HTMLElement | null,
    currentCompletedSection: string | ""
}

export const ModuleMultiSectionLayout = ({learningModuleData, showExitButton, handleExitProceed, handleCancel, 
                showConfirmModal, handleModal, courseWindow, curDocumentElement, currentCompletedSection}: Props): JSX.Element => {
    
    const [sectionCount, setSectionCount] = useState<number>(0);
    const [sectionList, setSectionList] = useState<Array<string>>([]);

    useEffect(() => {
        const curSecList =  !!learningModuleData && !!learningModuleData.quizInfo &&
                            !!learningModuleData.quizInfo.toString().split("<%$%>").
                                filter((word) => word.toString().startsWith('SectionSCOStartList<:#:>')) &&
                            learningModuleData.quizInfo.toString().split("<%$%>").
                                filter((word) => word.toString().startsWith('SectionSCOStartList<:#:>')).length > 0 ? 
                            learningModuleData.quizInfo.toString().split("<%$%>").
                                filter((word) => word.toString().startsWith('SectionSCOStartList<:#:>'))[0].
                                    replace('SectionSCOStartList<:#:>', '').split("<&>").
                                    filter(e => !!e && e.toString().trim() != "") :
                                    [];
        setSectionList(curSecList)
        setSectionCount(curSecList.length)
    },[learningModuleData.quizInfo])

    const handleExitProceedLocal = () => {
        if (typeof handleExitProceed == 'function') {
            //console.log('Success handleExitProceedLocal');
            handleExitProceed();
        }
        else {
            //console.log('Failed handleExitProceedLocal');
        }
    }
    
    const handleCancelLocal = () => {
        if (typeof handleCancel == 'function') {
            //console.log('Success handleCancelLocal');
            handleCancel();
        }
        else {
            //console.log('Failed handleCancelLocal');
        }
    }
    
    const handleModalLocal = () => {
        if (typeof handleModal == 'function') {
            //console.log('Success handleModalLocal');
            handleModal();
        }
        else {
            //console.log('Failed handleModalLocal');
        }
    }

    return (
        <>
            <TopNavbar  isMultiSectionModulePlayer={true} 
                        moduleName={!!learningModuleData ? learningModuleData.name : ""} 
                        sectionCount={sectionCount} 
                        learningModuleData={learningModuleData}
                        //showExitButton={showExitButton}
                        showExitButton={false}
                        handleExitProceed={handleExitProceedLocal}
                        handleCancel={handleCancelLocal}
                        showConfirmModal={showConfirmModal}
                        handleModal={handleModalLocal} />
                        
            <div style={{display: "flex"}}>
                <div>
                    <SideMenu   isMultiSectionModulePlayer={true} 
                                moduleName={!!learningModuleData ? learningModuleData.name : ""} 
                                sectionCount={sectionCount} 
                                learningModuleData={learningModuleData}
                                showExitButton={showExitButton}
                                handleExitProceed={handleExitProceed}
                                handleCancel={handleCancel}
                                showConfirmModal={showConfirmModal}
                                handleModal={handleModal}
                                courseWindow={courseWindow}
                                currentCompletedSection={currentCompletedSection}
                                moduleSectionList={sectionList} />
                </div>
            </div>            
        </>
    )
}

export default ModuleMultiSectionLayout