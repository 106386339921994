import axios from 'axios';
import { addError } from './AddError';

export const getActualOutgoingEmailsCount = async ( 
    token: string,
    orgDomain: string,
    reportRequestingUserID: number,
    orgId: number,
    selectedUnitIDs: string,
    selectedCourseIDs: string,
    selectedUserIDs: string,
    classificationId: number,
    courseModuleStatus: number,
    dateFrom: string,
    dateTo: string,
    isIncludeInactive : number, 
    recipientType: number,
    
    emailReportType: number,
    selectedPolicyIDs: string,
    selectedPolicyStatus: number,
    selectedExternalCertificateIDs: string,
    selectedExternalCertificateStatus: number,
    selectedCPDProfileStatus: number,
    selectedCPDProfileID: number,
    newStarterOnlyFlag: boolean
    ) => {
      //debugger
    try {     
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/api/userreport/GetActualOutgoingEmailsCount`,
          headers:{
            'Content-Type': 'application/json',
            'Authorization' : `Bearer ${token}`
          },
    
          data: {
            ReportRequestingUserID : reportRequestingUserID,
            OrgId : orgId, 
            SelectedUnitIDs :selectedUnitIDs,
            SelectedCourseIDs: selectedCourseIDs,
            SelectedUserIDs: selectedUserIDs,
            ClassificationId: classificationId, 
            CourseModuleStatus: courseModuleStatus,
            DateFrom: dateFrom,
            DateTo: dateTo,
            IsIncludeInactive: isIncludeInactive,
            RecipientType: recipientType,
            
            EmailReportType: emailReportType,
            SelectedPolicyIDs: selectedPolicyIDs,
            SelectedPolicyStatus: selectedPolicyStatus,
            SelectedExternalCertificateIDs: selectedExternalCertificateIDs,
            SelectedExternalCertificateStatus: selectedExternalCertificateStatus,
            SelectedCPDProfileStatus: selectedCPDProfileStatus,
            SelectedCPDProfileID: selectedCPDProfileID,
            NewStarterOnlyFlag: newStarterOnlyFlag
        }
        });
        const data = await response.data;
        return data;
      } catch (error: any) {
        if (error.response) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.response);
          };    
          addError(token, reportRequestingUserID, orgDomain, "Rest Exception", error.response, "GetActualOutgoingEmailsCount.ts", 1)
        } else if (error.request) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.request);
          };    
          addError(token, reportRequestingUserID, orgDomain, "Rest Exception", error.request, "GetActualOutgoingEmailsCount.ts", 1)
        } else if (error.message) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.message);
          };    
          addError(token, reportRequestingUserID, orgDomain, "Rest Exception", error.message, "GetActualOutgoingEmailsCount.ts", 1)
        }
        return null;
      }
}
export default getActualOutgoingEmailsCount;