import React, { useEffect } from "react";
import LoaderContainer from '../../Components/LoaderContainer';

interface Props {
  redirectFunction: Function;
}

export const Landing = ({redirectFunction} : Props) => {

  useEffect(() => {
    redirectFunction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return <LoaderContainer/>
};

export default Landing;
