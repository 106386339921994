//React + Typescript
import React, { useContext } from 'react';
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
//MUI Components
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
//Styles
import styles from '../../styles/myTraining.module.css'


interface Props {
  currentSelection: string;
  setCurrentSelection: (myTrainingSelection: string) => void;
}

export const MyTrainingFilterButtons = ({currentSelection, setCurrentSelection}: Props): JSX.Element | null => {
  const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
  const { showCourseUI, showPolicyUI } = useContext(UIContext)

  if (showPolicyUI && showCourseUI) {
    return (
        <ButtonGroup variant="contained" color="primary" aria-label="Filter" id="filterButtonsGroup">
          <Button
            size="small"
            className={currentSelection === "All" ?  styles.buttonSelected : styles.button}
            onClick={() => setCurrentSelection("All")}
          >
            {allResourcesToTranslations.all}
          </Button>
          <Button
            size="small"
            className={currentSelection === "Course" ?  styles.buttonSelected : styles.button}
            onClick={() => setCurrentSelection("Course")}
          >
            <SchoolRoundedIcon/>
            <span style={{ marginLeft: "5px" }}>
              {allResourcesToTranslations.course}
            </span>
          </Button>
          <Button
            size="small"
            className={currentSelection === "Policy" ?  styles.buttonSelected : styles.button}
            onClick={() => setCurrentSelection("Policy")}
          >
            <PlaylistAddCheckIcon/>
            <span style={{ marginLeft: "5px" }}>
              {allResourcesToTranslations.policy}
            </span>
          </Button>
        </ButtonGroup>
    );
  } else {
    return null // If user has no policies or no courses we have nothing to filter between so we dont render the filter buttons 
  }
};

export default MyTrainingFilterButtons;
