import axios from 'axios';
import { addError } from './AddError';



export const reassignSelectedUsers = async ( 
    token: string,
    userId: number,
    orgDomain: string,
    orgId: number,
    scheduleIds: string,
    reassignUserId: number
    ) => {

    try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/api/userreport/GetFullSchedulerReportReassign`,
          headers:{
            'Content-Type': 'application/json',
            'Authorization' : `Bearer ${token}`
          },
    
          data:{
            ReportRequestingUserID :userId,  //Use 1 for SaltSadmin 
            OrganisationID : orgId,
            ScheduleIds : scheduleIds,
            UserId :reassignUserId
        }
        });
        const data = await response.data;
       
        return data;
      } catch (error: any) {
        console.log("error");
        console.log(error);
        if (error.response) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.response);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.response, "checkIfSessionIsStillActive.ts", 1)
        } else if (error.request) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.request);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.request, "checkIfSessionIsStillActive.ts", 1)
        } else if (error.message) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.message);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.message, "checkIfSessionIsStillActive.ts", 1)
        }
        return null;
      }

}

export default reassignSelectedUsers;