import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 
import { Icon } from '@material-ui/core';

export const GuideIcon = () => (
//   <SvgIcon width="36" height="31" viewBox="0 0 36 31" fill="none" xmlns="http://www.w3.org/2000/svg">
  
// <path d="M20.1016 13.4766C20.4609 13.4766 20.7578 13.5938 20.9922 13.8281C21.2266 14.0469 21.3438 14.375 21.3438 14.8125C21.3438 14.9844 21.3125 15.2891 21.25 15.7266C21.1875 16.1484 21.1406 16.4219 21.1094 16.5469L20.2422 21C20.2109 21.0938 20.1953 21.2734 20.1953 21.5391C20.1953 21.9141 20.2734 22.1016 20.4297 22.1016C20.6484 22.1016 20.9531 21.9375 21.3438 21.6094C21.75 21.2812 22.0156 21.0312 22.1406 20.8594C22.1875 20.8594 22.2734 20.9375 22.3984 21.0938C22.5234 21.25 22.5859 21.3594 22.5859 21.4219C22.4141 21.8438 21.8516 22.4141 20.8984 23.1328C19.9609 23.8359 19.1641 24.1875 18.5078 24.1875C18.1641 24.1875 17.8672 24.0781 17.6172 23.8594C17.3828 23.625 17.2656 23.2891 17.2656 22.8516C17.2656 22.6797 17.2969 22.3828 17.3594 21.9609C17.4219 21.5234 17.4688 21.2422 17.5 21.1172L18.3672 16.6641C18.3984 16.5078 18.4141 16.3281 18.4141 16.125C18.4141 15.75 18.3359 15.5625 18.1797 15.5625C17.9609 15.5625 17.6484 15.7266 17.2422 16.0547C16.8516 16.3828 16.5938 16.6328 16.4688 16.8047C16.4375 16.8047 16.3516 16.7266 16.2109 16.5703C16.0859 16.4141 16.0234 16.3047 16.0234 16.2422C16.1953 15.8203 16.75 15.2578 17.6875 14.5547C18.6406 13.8359 19.4453 13.4766 20.1016 13.4766ZM20.8516 7.89844C21.2422 7.89844 21.5625 8.03906 21.8125 8.32031C22.0781 8.60156 22.2109 8.94531 22.2109 9.35156C22.2109 9.86719 22.0234 10.3281 21.6484 10.7344C21.2891 11.1406 20.8672 11.3438 20.3828 11.3438C19.9922 11.3438 19.6641 11.2031 19.3984 10.9219C19.1484 10.6406 19.0234 10.2891 19.0234 9.86719C19.0234 9.35156 19.2031 8.89844 19.5625 8.50781C19.9375 8.10156 20.3672 7.89844 20.8516 7.89844Z" fill="#63C2FF"/>
// <mask id="path-2-inside-1" fill="white">
// <path fillRule="evenodd" clipRule="evenodd" d="M19.8268 31C28.3872 31 35.3268 24.0604 35.3268 15.5C35.3268 6.93959 28.3872 0 19.8268 0C11.2664 0 4.32678 6.93959 4.32678 15.5C4.32678 18.1294 4.98152 20.606 6.13688 22.7754C4.77868 24.801 2.79684 26.9958 0.750033 28.0192C-2.7381 29.7634 6.81162 30.241 11.5037 28.5781C13.9081 30.1115 16.7637 31 19.8268 31Z"/>
// </mask>
// <path d="M6.13688 22.7754L7.79801 23.8893L8.4657 22.8935L7.90215 21.8353L6.13688 22.7754ZM0.750033 28.0192L-0.14443 26.2304L-0.144431 26.2304L0.750033 28.0192ZM11.5037 28.5781L12.5791 26.8918L11.7559 26.3668L10.8356 26.693L11.5037 28.5781ZM33.3268 15.5C33.3268 22.9558 27.2826 29 19.8268 29V33C29.4918 33 37.3268 25.165 37.3268 15.5H33.3268ZM19.8268 2C27.2826 2 33.3268 8.04416 33.3268 15.5H37.3268C37.3268 5.83502 29.4918 -2 19.8268 -2V2ZM6.32678 15.5C6.32678 8.04416 12.3709 2 19.8268 2V-2C10.1618 -2 2.32678 5.83502 2.32678 15.5H6.32678ZM7.90215 21.8353C6.89716 19.9482 6.32678 17.7937 6.32678 15.5H2.32678C2.32678 18.4652 3.06589 21.2637 4.3716 23.7155L7.90215 21.8353ZM1.6445 29.8081C4.14742 28.5565 6.36524 26.026 7.79801 23.8893L4.47575 21.6616C3.19213 23.5759 1.44626 25.435 -0.14443 26.2304L1.6445 29.8081ZM10.8356 26.693C8.82314 27.4062 5.54862 27.7008 3.16125 27.5789C2.58235 27.5493 2.09901 27.497 1.73281 27.4319C1.32075 27.3586 1.2107 27.295 1.26589 27.3255C1.29029 27.339 1.53548 27.4711 1.74238 27.8031C2.00858 28.2302 2.08144 28.7848 1.90025 29.2888C1.75906 29.6816 1.52696 29.8659 1.51316 29.8773C1.47798 29.9061 1.50325 29.8787 1.6445 29.8081L-0.144431 26.2304C-0.439202 26.3778 -0.748086 26.5585 -1.02431 26.7851C-1.27914 26.9943 -1.65916 27.3661 -1.86389 27.9356C-2.10861 28.6163 -2.01491 29.337 -1.65222 29.9189C-1.34883 30.4056 -0.931507 30.6814 -0.668066 30.8269C-0.134795 31.1215 0.500865 31.2755 1.03222 31.3701C1.60945 31.4728 2.26726 31.5385 2.95732 31.5737C5.64579 31.7109 9.49215 31.4129 12.1718 30.4632L10.8356 26.693ZM19.8268 29C17.1559 29 14.6718 28.2264 12.5791 26.8918L10.4283 30.2644C13.1444 31.9965 16.3714 33 19.8268 33V29Z" fill="#63C2FF" mask="url(#path-2-inside-1)"/>


  //</SvgIcon>

  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
    <circle cx="12" cy="12" r="11.5" fill="white" stroke="#63C2FF"/>
    <path d="M12.6294 9.97402C12.9131 9.97402 13.1474 10.0665 13.3324 10.2515C13.5173 10.4242 13.6098 10.6831 13.6098 11.0284C13.6098 11.1641 13.5852 11.4046 13.5358 11.7499C13.4865 12.0828 13.4495 12.2987 13.4248 12.3973L12.7404 15.912C12.7157 15.986 12.7034 16.1278 12.7034 16.3375C12.7034 16.6334 12.7651 16.7814 12.8884 16.7814C13.061 16.7814 13.3015 16.6519 13.6098 16.393C13.9305 16.134 14.1401 15.9367 14.2388 15.801C14.2758 15.801 14.3436 15.8627 14.4423 15.986C14.5409 16.1093 14.5902 16.1956 14.5902 16.245C14.4546 16.5779 14.0106 17.0281 13.2584 17.5954C12.5184 18.1503 11.8895 18.4278 11.3715 18.4278C11.1002 18.4278 10.8659 18.3415 10.6686 18.1688C10.4836 17.9838 10.3911 17.7187 10.3911 17.3734C10.3911 17.2377 10.4158 17.0034 10.4651 16.6704C10.5144 16.3251 10.5514 16.1032 10.5761 16.0045L11.2605 12.4898C11.2852 12.3665 11.2975 12.2247 11.2975 12.0643C11.2975 11.7684 11.2359 11.6204 11.1125 11.6204C10.9399 11.6204 10.6933 11.7499 10.3726 12.0088C10.0643 12.2678 9.86083 12.4651 9.76217 12.6008C9.7375 12.6008 9.66968 12.5391 9.55868 12.4158C9.46003 12.2925 9.4107 12.2062 9.4107 12.1568C9.54635 11.8239 9.98415 11.3799 10.7241 10.8249C11.4763 10.2577 12.1115 9.97402 12.6294 9.97402ZM13.2214 5.57141C13.5297 5.57141 13.7825 5.6824 13.9798 5.90438C14.1894 6.12636 14.2943 6.39767 14.2943 6.71831C14.2943 7.12527 14.1463 7.48908 13.8503 7.80971C13.5667 8.13035 13.2337 8.29067 12.8514 8.29067C12.5431 8.29067 12.2841 8.17968 12.0745 7.9577C11.8771 7.73572 11.7785 7.45825 11.7785 7.12527C11.7785 6.71831 11.9203 6.36067 12.204 6.05237C12.4999 5.73173 12.8391 5.57141 13.2214 5.57141Z" fill="#63C2FF"/>
  </SvgIcon>

  // <SvgIcon width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
  //   <circle cx="18.8446" cy="18" r="17.5" fill="white" stroke="#63C2FF"/>
  //   <path d="M19.7887 14.9611C20.2142 14.9611 20.5656 15.0998 20.8431 15.3773C21.1206 15.6363 21.2593 16.0248 21.2593 16.5427C21.2593 16.7462 21.2223 17.1069 21.1483 17.6249C21.0743 18.1243 21.0189 18.448 20.9819 18.596L19.9552 23.8681C19.9182 23.9791 19.8997 24.1918 19.8997 24.5063C19.8997 24.9502 19.9922 25.1722 20.1772 25.1722C20.4362 25.1722 20.7969 24.978 21.2593 24.5895C21.7403 24.201 22.0548 23.9051 22.2027 23.7016C22.2582 23.7016 22.36 23.7941 22.508 23.9791C22.656 24.164 22.73 24.2935 22.73 24.3675C22.5265 24.867 21.8605 25.5422 20.7321 26.3931C19.6222 27.2255 18.6788 27.6417 17.9019 27.6417C17.4949 27.6417 17.1434 27.5122 16.8475 27.2533C16.57 26.9758 16.4313 26.5781 16.4313 26.0601C16.4313 25.8566 16.4682 25.5052 16.5422 25.0057C16.6162 24.4878 16.6717 24.1548 16.7087 24.0068L17.7354 18.7348C17.7724 18.5498 17.7909 18.337 17.7909 18.0966C17.7909 17.6526 17.6984 17.4306 17.5134 17.4306C17.2544 17.4306 16.8845 17.6249 16.4035 18.0133C15.941 18.4018 15.6358 18.6978 15.4878 18.9013C15.4508 18.9013 15.3491 18.8088 15.1826 18.6238C15.0346 18.4388 14.9606 18.3093 14.9606 18.2353C15.1641 17.7359 15.8208 17.0699 16.9307 16.2375C18.0591 15.3866 19.0118 14.9611 19.7887 14.9611ZM20.6766 8.35718C21.1391 8.35718 21.5183 8.52366 21.8143 8.85663C22.1288 9.1896 22.286 9.59657 22.286 10.0775C22.286 10.688 22.064 11.2337 21.62 11.7146C21.1946 12.1956 20.6951 12.4361 20.1217 12.4361C19.6592 12.4361 19.2708 12.2696 18.9563 11.9366C18.6603 11.6036 18.5123 11.1874 18.5123 10.688C18.5123 10.0775 18.7251 9.54107 19.1505 9.07861C19.5945 8.59766 20.1032 8.35718 20.6766 8.35718Z" fill="#63C2FF"/>
  // </SvgIcon>

  // <SvgIcon width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
  //   <circle cx="23.8446" cy="23.6908" r="22.5" fill="white" stroke="#63C2FF"/>
  //   <path d="M25.051 19.8076C25.5946 19.8076 26.0437 19.9849 26.3983 20.3394C26.7528 20.6703 26.9301 21.1667 26.9301 21.8286C26.9301 22.0886 26.8828 22.5495 26.7883 23.2113C26.6937 23.8495 26.6228 24.2631 26.5755 24.4522L25.2637 31.1887C25.2164 31.3305 25.1928 31.6024 25.1928 32.0042C25.1928 32.5715 25.311 32.8551 25.5473 32.8551C25.8782 32.8551 26.3392 32.6069 26.9301 32.1106C27.5446 31.6142 27.9465 31.236 28.1356 30.976C28.2065 30.976 28.3365 31.0942 28.5256 31.3305C28.7147 31.5669 28.8092 31.7324 28.8092 31.8269C28.5492 32.4651 27.6983 33.3279 26.2564 34.4152C24.8382 35.4788 23.6327 36.0106 22.64 36.0106C22.12 36.0106 21.6709 35.8452 21.2927 35.5143C20.9381 35.1597 20.7609 34.6515 20.7609 33.9897C20.7609 33.7297 20.8081 33.2806 20.9027 32.6424C20.9972 31.9806 21.0681 31.5551 21.1154 31.366L22.4273 24.6295C22.4745 24.3931 22.4982 24.1213 22.4982 23.814C22.4982 23.2468 22.38 22.9631 22.1436 22.9631C21.8127 22.9631 21.34 23.2113 20.7254 23.7077C20.1345 24.2041 19.7445 24.5822 19.5554 24.8422C19.5081 24.8422 19.3781 24.7241 19.1654 24.4877C18.9763 24.2513 18.8817 24.0859 18.8817 23.9913C19.1417 23.3531 19.9809 22.5022 21.3991 21.4385C22.8409 20.3513 24.0582 19.8076 25.051 19.8076ZM26.1855 11.3693C26.7764 11.3693 27.261 11.582 27.6392 12.0075C28.041 12.4329 28.2419 12.9529 28.2419 13.5675C28.2419 14.3475 27.9583 15.0448 27.391 15.6593C26.8474 16.2739 26.2092 16.5812 25.4764 16.5812C24.8855 16.5812 24.3891 16.3684 23.9873 15.943C23.6091 15.5175 23.42 14.9857 23.42 14.3475C23.42 13.5675 23.6918 12.882 24.2355 12.2911C24.8028 11.6765 25.4528 11.3693 26.1855 11.3693Z" fill="#63C2FF"/>  
  // </SvgIcon>

  // <SvgIcon width="72" height="72" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
  // <circle cx="39" cy="39" r="36" fill="white" stroke="#63C2FF"/>
  // <path d="M19.7887 14.9611C20.2142 14.9611 20.5656 15.0998 20.8431 15.3773C21.1206 15.6363 21.2593 16.0248 21.2593 16.5427C21.2593 16.7462 21.2223 17.1069 21.1483 17.6249C21.0743 18.1243 21.0189 18.448 20.9819 18.596L19.9552 23.8681C19.9182 23.9791 19.8997 24.1918 19.8997 24.5063C19.8997 24.9502 19.9922 25.1722 20.1772 25.1722C20.4362 25.1722 20.7969 24.978 21.2593 24.5895C21.7403 24.201 22.0548 23.9051 22.2027 23.7016C22.2582 23.7016 22.36 23.7941 22.508 23.9791C22.656 24.164 22.73 24.2935 22.73 24.3675C22.5265 24.867 21.8605 25.5422 20.7321 26.3931C19.6222 27.2255 18.6788 27.6417 17.9019 27.6417C17.4949 27.6417 17.1434 27.5122 16.8475 27.2533C16.57 26.9758 16.4313 26.5781 16.4313 26.0601C16.4313 25.8566 16.4682 25.5052 16.5422 25.0057C16.6162 24.4878 16.6717 24.1548 16.7087 24.0068L17.7354 18.7348C17.7724 18.5498 17.7909 18.337 17.7909 18.0966C17.7909 17.6526 17.6984 17.4306 17.5134 17.4306C17.2544 17.4306 16.8845 17.6249 16.4035 18.0133C15.941 18.4018 15.6358 18.6978 15.4878 18.9013C15.4508 18.9013 15.3491 18.8088 15.1826 18.6238C15.0346 18.4388 14.9606 18.3093 14.9606 18.2353C15.1641 17.7359 15.8208 17.0699 16.9307 16.2375C18.0591 15.3866 19.0118 14.9611 19.7887 14.9611ZM20.6766 8.35718C21.1391 8.35718 21.5183 8.52366 21.8143 8.85663C22.1288 9.1896 22.286 9.59657 22.286 10.0775C22.286 10.688 22.064 11.2337 21.62 11.7146C21.1946 12.1956 20.6951 12.4361 20.1217 12.4361C19.6592 12.4361 19.2708 12.2696 18.9563 11.9366C18.6603 11.6036 18.5123 11.1874 18.5123 10.688C18.5123 10.0775 18.7251 9.54107 19.1505 9.07861C19.5945 8.59766 20.1032 8.35718 20.6766 8.35718Z" fill="#63C2FF"/>
  // </SvgIcon>
);

export default GuideIcon
