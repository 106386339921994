import React, { useState, useEffect, useContext } from "react";

//MUI
import {
  AppBar,
  Box,
  Tab,
  Tabs,
  Typography,
  createStyles,
  withStyles,
} from "@material-ui/core";

//Styles
import styles from "../../../styles/organisationmanagement.module.css";
import { useHistory } from "react-router";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { UIContext } from "../../../contexts/UIContext";
import LoaderContainer from "../../../Components/LoaderContainer";
import GetOrganisationEmailTemplates from "../../../API/Administration/Organisation/GetOrganisationEmailTemplates";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";
import EmailTemplateList from "./EmailTemplateList";
import { EmailTemplates } from "../../../globalTypes";
import { Steps } from "intro.js-react";
import CustomTooltip from "../../../Components/CustomTooltip";
import GuideIcon from "../../../CustomIcons/GuideIcon";

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: 'white',
    },
  },
})((props: any) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme: any) =>
  createStyles({
    root: {
      textTransform: 'none',
      fontWeight: 'bold',
      fontSize: '18px',
      background: '#E0E0E0',
      color: '#21394F',
      boxShadow:'2px 2px 10px rgba(0, 0, 0, 0.25)',
      borderRadius:'8px 8px 0px 0px',
    },
    selected: {
      backgroundColor:'#FFF',    
      boxShadow:'2px 2px 10px rgba(0, 0, 0, 0.25)',  
      color: '#21394F !important',
    }
  }),
)((props: any) => <Tab disableRipple {...props} />);

const OrgEmailTemplates = () => {
  const {
    translations: { allResourcesToTranslations }, pageTitles,
  } = useContext(TranslationsContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute} =
    useContext(UIContext);
    const { oidcUser } = useReactOidc();
    const {
      profile: { user_id, website },
    } = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const orgDomain = website
      ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
      : "";
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [allEmailTemplatesResult, setAllEmailTemplatesResult] = useState<Array<EmailTemplates>>([]);
  const [welcomeCertyTemplates, setWelcomeCertyTemplates] = useState<Array<EmailTemplates>>([]);
  const [summaryPrePostTemplates, setSummaryPrePostTemplates] = useState<Array<EmailTemplates>>([]);
  const [managerNotificationTemplates, setManagerNotificationTemplates] = useState<Array<EmailTemplates>>([]);
  const [coursesTemplates, setCoursesTemplates] = useState<Array<EmailTemplates>>([]);
  const [cpdTemplates, setCPDTemplates] = useState<Array<EmailTemplates>>([]);
  const [policyTemplates, setPolicyTemplates] = useState<Array<EmailTemplates>>([]);
  const [externalcertificateTemplates, setExternalCertificateTemplates] = useState<Array<EmailTemplates>>([]);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`account-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3} className={styles.tabContainer} >
          <Typography  component={"div"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const handleTabViewChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const [intro, setIntro] = useState({
		stepsEnabled: false,
		initialStep: 0,
		steps: [
			{
				element: ".usedefault0",
				title: "Use Default Template",
				intro: "This indicates if the default template is being used.",
			}
		],
	});

	const onExit = () => {
		setIntro({ ...intro, stepsEnabled: false });
	};

  
  const setGuideMe = () => {
    let updateSteps = [...intro.steps];
    if(updateSteps.length>1)
    { 
      updateSteps.splice(1, updateSteps.length -1); 
    }
    if (tabValue === 0) {
      updateSteps.push(
        {
          element: ".Student_Summary_NewStarter",
          title: "Welcome Email",
          intro: "This email template will be used when sending a Welcome Email to any new Accounts added to Salt. This template is also used in the Email Report when sending Welcome Emails, and when sending a Welcome Email from the Account Management page. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to Accounts.",
        },
        {
          element: ".Course_Completion_Certificate",
          title: "Course Completion Certificate",
          intro: "This email template will be used to send a learners Course Completion Certificate upon course completion. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to Accounts.",
        }
      );
    } else if(tabValue === 1) {
      updateSteps.push(
        {
          element: ".Student_Summary_Subject",
          title: "Subject",
          intro: "This is the subject of the Summary email. Take note of the macros used (eg. %ORG_NAME%), these are dynamically replaced in emails to Accounts.",
        },
        {
          element: ".Student_Summary_Header",
          title: "Header",
          intro: "This is the salutation row for the Daily Summary email. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to Accounts.",
        },
        {
          element: ".Student_Summary_PassedCourses",
          title: "Passed Courses",
          intro: "This is the partial body of the Daily Summary email, this summarises the completed courses for an Account on that day, noted by the macro %AUTO_LIST%.",
        },
        {
          element: ".Student_Summary_PassedModules",
          title: "Passed Modules",
          intro: "This is the rest of the body of the Daily Summary email, this summarises the completed modules for an Account on that day, noted by the macro %AUTO_LIST%.",
        },
        {
          element: ".Student_Summary_AtRiskOfBeingOverdue",
          title: "Pre Expiry Reminder",
          intro: "This email template will be used when sending an Account a Pre-Expiry email reminder, if enabled, before the initial enrolment period ends or an Accounts current course status is to set to expire. This template applies to those Accounts in the initial enrolment period, as well as the Resit period. Both cases need to be taken into consideration when updating this template. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to Accounts.",
        },
        {
          element: ".Student_Summary_AtRiskOfExpiry",
          title: "Post Expiry Reminder",
          intro: "This email template will be used when sending an Account a Post-Expiry email reminder, if enabled, this template applies to those Accounts in the initial enrolment period, as well as the Resit period. Both cases need to be taken into consideration when updating this template. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to Accounts.",
        },
        {
          element: ".Student_Summary_ExpiredContent",
          title: "Expired Content",
          intro: "This is an internal GRC-used email template.",
        },
        {
          element: ".Student_Summary_Sig",
          title: "Signature",
          intro: "This is the sign-off\signature for the Student Summary email. Take note of the macros used (eg. %ORG_NAME%), these are dynamically replaced in emails to Accounts.",
        }
      );
    } else if (tabValue === 2) {
      updateSteps.push(
        {
          element: ".Overdue_Summary_Subject",
          title: "Subject",
          intro: "If Manager Notifications are enabled, this is the subject of the overdue email. Take note of the macros used (eg. %ORG_NAME%), these are dynamically replaced in emails to Accounts.",
        },
        {
          element: ".Overdue_Summary_Header",
          title: "Header",
          intro: "If Manager Notifications are enabled, this template is the header used in the Manager Notifications email.",
        },
        {
          element: ".Overdue_Summary_Sig",
          title: "Signature",
          intro: "If Manager Notifications are enabled, this template is the sign-off\signature used in the Manager Notifications email.",
        }
      );
    } else if(tabValue === 3) {
      updateSteps.push(
        {
          element: ".Email_Report_Complete_To_Administrators",
          title: "Email Report",
          intro: "This email template is used in the Email Report when choosing to send an email based on Course Status, then selecting a Quiz Status of Completed and sending to Administrators. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to users.",
        },
        {
          element: ".Email_Report_Complete_To_Users",
          title: "Email Report",
          intro: "This email template is used in the Email Report when choosing to send an email based on Course Status, then selecting a Quiz Status of Completed and sending to Accounts. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to users.",
        },
        {
          element: ".Email_Report_Expired_New_Content_To_Administrators",
          title: "Email Report",
          intro: "This email template is used in the Email Report when choosing to send an email based on Course Status, then selecting a Quiz Status of Expired (New Content) and sending to Administrators. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to users.",
        },
        {
          element: ".Email_Report_Expired_New_Content_To_Users",
          title: "Email Report",
          intro: "This email template is used in the Email Report when choosing to send an email based on Course Status, then selecting a Quiz Status of Expired (New Content) and sending to Accounts. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to users.",
        },
        {
          element: ".Email_Report_Expired_Time_Elapsed_To_Administrators",
          title: "Email Report",
          intro: "This email template is used in the Email Report when choosing to send an email based on Course Status, then selecting a Quiz Status of Expired (Time Elapsed) and sending to Administrators. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to users.",
        },
        {
          element: ".Email_Report_Expired_Time_Elapsed_To_Users",
          title: "Email Report",
          intro: "This email template is used in the Email Report when choosing to send an email based on Course Status, then selecting a Quiz Status of Expired (Time Elapsed) and sending to Accounts. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to users.",
        },
        {
          element: ".Email_Report_Failed_To_Administrators",
          title: "Email Report",
          intro: "This email template is used in the Email Report when choosing to send an email based on Course Status, then selecting a Quiz Status of Failed and sending to Administrators. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to users.",
        },
        {
          element: ".Email_Report_Failed_To_Users",
          title: "Email Report",
          intro: "This email template is used in the Email Report when choosing to send an email based on Course Status, then selecting a Quiz Status of Failed and sending to Accounts. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to users.",
        },
        {
          element: ".Email_Report_InComplete_To_Administrators",
          title: "Email Report",
          intro: "This email template is used in the Email Report when choosing to send an email based on Course Status, then selecting a Course Status of Incomplete and sending to Administrators. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to users.",
        },
        {
          element: ".Email_Report_Incomplete_To_Users",
          title: "Email Report",
          intro: "This email template is used in the Email Report when choosing to send an email based on Course Status, then selecting a Course Status of Incomplete and sending to Accounts. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to users.",
        },
        {
          element: ".Email_Report_Not_Started_Administrators",
          title: "Email Report",
          intro: "This email template is used in the Email Report when choosing to send an email based on Course Status, then selecting a Quiz Status of Not Started and sending to Administrators. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to users.",
        },
        {
          element: ".Email_Report_Not_Started_To_Users",
          title: "Email Report",
          intro: "This email template is used in the Email Report when choosing to send an email based on Course Status, then selecting a Quiz Status of Not Started and sending to Accounts. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to users.",
        }
      );
    } else if (tabValue === 4) {
      updateSteps.push(
        {
          element: ".Email_Incomplete_CPD_Administrator",
          title: "Email to Incomplete CPD Administrator",
          intro: "This email template is used in the Email Report when choosing to send an email based on CPD Profiles and sending to Administrators. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to users.",
        },
        {
          element: ".Email_Incomplete_CPD_User",
          title: "Email to Incomplete CPD Account",
          intro: "This email template is used in the Email Report when choosing to send an email based on CPD Profiles and sending to Accounts. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to users.",
        }
      );
    } else if(tabValue === 6) {
      updateSteps.push(
        {
          element: ".Policy_Email_Report_Accepted_To_Administrators",
          title: "Policy Email",
          intro: "This email template is used in the Email Report when choosing to send an email based on Policy Acceptances, choosing Policy Status ‘Accepted’ and sending to Administrators. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to users.",
        },
        {
          element: ".Policy_Email_Report_Accepted_To_Users",
          title: "Policy Email",
          intro: "This email template is used in the Email Report when choosing to send an email based on Policy Acceptances, choosing Policy Status ‘Accepted’ and sending to Accounts. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to users",
        },
        {
          element: ".Policy_Email_Report_Not_Accepted_To_Administrators",
          title: "Policy Email",
          intro: "This email template is used in the Email Report when choosing to send an email based on Policy Acceptances, choosing Policy Status ‘Not Accepted’ and sending to Administrators. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to users",
        },
        {
          element: ".Policy_Email_Report_Not_Accepted_To_Users",
          title: "Policy Email",
          intro: "This email template is used in the Email Report when choosing to send an email based on Policy Acceptances, choosing Policy Status ‘Not Accepted’ and sending to Accounts. Take note of the macros used (eg. %FirstName%), these are dynamically replaced in emails to users.",
        }
      );
    }

    updateSteps.push(
    {
      element: ".edit0",
      title: "Edit Template",
      intro: "Click this button to edit the email template.",
    },
    {
      element: ".revert0",
      title: "Revert to Default",
      intro: "When a template has been customized, you may revert to the default template by clicking this button.",
    });

    setIntro({ ...intro, steps: [...updateSteps] });        
};
  

  const handleClosePopup = () => {
		setShowWarningModal(false);
	};
  
  useEffect(() => {
    setTitle(allResourcesToTranslations.orgemailtemplates);
    setTitleEnglish(pageTitles.emailtemplates);
    updateBackButtonRoute("");
    setLoading(true);
    GetOrganisationEmailTemplates(oidcUser.access_token,
      userId,
      orgDomain,
      parseInt(orgId)).then((result) => {
        
        setLoading(false);
        if (result.isSuccess && result.error == null) {
          setAllEmailTemplatesResult([...result.orgEmailTemplateList]);

          let filterwelcome = result.orgEmailTemplateList.filter((template:EmailTemplates) => template.name === 'Student_Summary_NewStarter');
          let filterCerty = result.orgEmailTemplateList.filter((template:EmailTemplates) => template.name === 'Course_Completion_Certificate');
          setWelcomeCertyTemplates([...filterwelcome, ...filterCerty]);

          let subject = result.orgEmailTemplateList.filter((template:EmailTemplates) => template.name === 'Student_Summary_Subject');
          let header = result.orgEmailTemplateList.filter((template:EmailTemplates) => template.name === 'Student_Summary_Header');
          let passedCourse = result.orgEmailTemplateList.filter((template:EmailTemplates) => template.name === 'Student_Summary_PassedCourses');
          let passedModules = result.orgEmailTemplateList.filter((template:EmailTemplates) => template.name === 'Student_Summary_PassedModules');
          let preExpiryReminder = result.orgEmailTemplateList.filter((template:EmailTemplates) => template.name === 'Student_Summary_AtRiskOfBeingOverdue');
          let postExpirtReminder = result.orgEmailTemplateList.filter((template:EmailTemplates) => template.name === 'Student_Summary_AtRiskOfExpiry');
          let expiredContent = result.orgEmailTemplateList.filter((template:EmailTemplates) => template.name === 'Student_Summary_ExpiredContent');
          let signature = result.orgEmailTemplateList.filter((template:EmailTemplates) => template.name === 'Student_Summary_Sig');

          setSummaryPrePostTemplates([...subject, ...header, ...passedCourse, ...passedModules, ...preExpiryReminder, ...postExpirtReminder, ...expiredContent, ...signature]);

          let overdueSubject = result.orgEmailTemplateList.filter((template:EmailTemplates) => template.name === 'Overdue_Summary_Subject');
          let overdueHeader = result.orgEmailTemplateList.filter((template:EmailTemplates) => template.name === 'Overdue_Summary_Header');
          let overdueSignature = result.orgEmailTemplateList.filter((template:EmailTemplates) => template.name === 'Overdue_Summary_Sig');

          setManagerNotificationTemplates([...overdueSubject, ...overdueHeader, ...overdueSignature]);

          let filterCoursesTemplates = result.orgEmailTemplateList.filter((template:EmailTemplates) => template.name === 'Email_Report_Complete_To_Administrators'
                                                                                                    || template.name === 'Email_Report_Complete_To_Users'
                                                                                                    || template.name === 'Email_Report_Expired_New_Content_To_Administrators'
                                                                                                    || template.name === 'Email_Report_Expired_New_Content_To_Users'
                                                                                                    || template.name === 'Email_Report_Expired_Time_Elapsed_To_Administrators'
                                                                                                    || template.name === 'Email_Report_Expired_Time_Elapsed_To_Users'
                                                                                                    || template.name === 'Email_Report_Failed_To_Administrators'
                                                                                                    || template.name === 'Email_Report_Failed_To_Users'
                                                                                                    || template.name === 'Email_Report_InComplete_To_Administrators'
                                                                                                    || template.name === 'Email_Report_Incomplete_To_Users'
                                                                                                    || template.name === 'Email_Report_Not_Started_Administrators'
                                                                                                    || template.name === 'Email_Report_Not_Started_To_Users')
          setCoursesTemplates([...filterCoursesTemplates]);

          let filterCPDTemplates = result.orgEmailTemplateList.filter((template:EmailTemplates) => template.name === 'Email_Incomplete_CPD_Administrator' || template.name === 'Email_Incomplete_CPD_User')
          setCPDTemplates([...filterCPDTemplates]);

          let filterPolicyTemplates = result.orgEmailTemplateList.filter((template:EmailTemplates) => template.name === 'Policy_Email_Report_Accepted_To_Administrators'
                                                                                                  ||  template.name === 'Policy_Email_Report_Accepted_To_Users'
                                                                                                  ||  template.name === 'Policy_Email_Report_Not_Accepted_To_Administrators'
                                                                                                  ||  template.name === 'Policy_Email_Report_Not_Accepted_To_Users');
          setPolicyTemplates([...filterPolicyTemplates]);
          let filterExternalCertificateTemplates = result.orgEmailTemplateList.filter((template:EmailTemplates) => template.name === 'External_Certificate_Init' 
                                                                                                                || template.name === 'External_Certificate_Init_Admin'
                                                                                                                || template.name === 'External_Certificate_Provided'
                                                                                                                || template.name === 'External_Certificate_Provided_Admin'
                                                                                                                || template.name === 'External_Certificate_Expired'
                                                                                                                || template.name === 'External_Certificate_Expired_Admin');
          setExternalCertificateTemplates([...filterExternalCertificateTemplates]);

          setGuideMe();
        } else {
          setShowWarningModal(true);
          setSaveSuccess(false);
          setHasErrored(true);
          setModalPopupMessage('Getting Email Templates has failed! Please try again!');
        }
      })
  }, []);

  useEffect(() => {
    setGuideMe();
  }, [tabValue]);

  if (loading) return <LoaderContainer />;
  
  return (
    <>
      <Steps
				enabled={intro.stepsEnabled}
				steps={intro.steps}
				initialStep={intro.initialStep}
				onExit={onExit}
				options={{
					nextLabel: "Next",
					prevLabel: "Back",
					tooltipClass: "sample-class",
					showBullets: true,
				}}
			/>
			<CustomTooltip displayText={["Guide Me"]}>
				<span
					onClick={() => setIntro({ ...intro, stepsEnabled: true })}
					className={styles.guideMeButtonSpecific}
				>
					<GuideIcon />
				</span>
			</CustomTooltip>
      <span style={{fontSize: "13px", color: "#21394F"}}><b>Note: </b>(A) = Auto Email Template, (ER) = Email Report Template. </span><br></br><br></br>
      <AppBar position="static" color="default">
        <StyledTabs
          variant="fullWidth"
          value={tabValue}
          onChange={handleTabViewChange}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          aria-label="Organisation Email Templates"
        >
          <StyledTab label="Welcome and Certificate (A)"  />
          <StyledTab label="Summary, Pre & Post Expiry (A)" />
          <StyledTab label="Manager Notification (A)" />
          <StyledTab label="Course (ER)"  />
          <StyledTab label="CPD (ER)" />
          <StyledTab label="External Certificate (ER)" />
          <StyledTab label="Policy (ER)" />
        </StyledTabs>
      </AppBar>
        <TabPanel value={tabValue} index={0}>
          <EmailTemplateList templates={welcomeCertyTemplates} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <EmailTemplateList templates={summaryPrePostTemplates} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <EmailTemplateList templates={managerNotificationTemplates} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <EmailTemplateList templates={coursesTemplates} />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <EmailTemplateList templates={cpdTemplates} />
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          <EmailTemplateList templates={externalcertificateTemplates} />
        </TabPanel>
        <TabPanel value={tabValue} index={6}>
          <EmailTemplateList templates={policyTemplates} />
        </TabPanel>
        {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}

    </>
  );
};

export default OrgEmailTemplates;
