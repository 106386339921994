//React + Typescript
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LearningModule } from '../../globalTypes';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { SessionContext } from '../../contexts/SessionContext';
import { UIContext } from '../../contexts/UIContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
//API Functions
import { addError } from '../../API/AddError'
// import { updateSAPassLaunchPageDetails } from '../../API/UpdateSAPassLaunchPageDetails';
//MUI Components
import Button from '@material-ui/core/Button';
//Styles
import styles from '../../styles/restartModalStyle.module.css';

interface Props {
    setModalOpen: (isOpen: boolean) => any; 
    selectedLearningModule: LearningModule | null;
}

export const SARestartModalContent = ({setModalOpen, selectedLearningModule}: Props): JSX.Element => {
    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
    const { setSaReviewOnlyValue, setSaIsRestarted } = useContext(SessionContext);
    const history = useHistory();
    const { oidcUser } = useReactOidc();
    const { EnableActivityLogs } = useContext(UIContext) 
    const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0;
    const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    
    const createHeaderMarkup = () => {
    // Need to sanitze data for security in dangerouslySetInnerHTML. Only required if strings have html tags
    // more info -> https://zhenyong.github.io/react/tips/dangerously-set-inner-html.html
        return {
           __html: allResourcesToTranslations.youhaveselectedrestart };
     }; 

    const handleContinue = () => {
      addError(oidcUser.access_token, userId, orgDomain, "Accepted restart conditions", null, "SARestartModalContent.tsx", 6, EnableActivityLogs)
      setSaReviewOnlyValue(-1);
      setSaIsRestarted(-1);
      if (selectedLearningModule != null){
        setSaReviewOnlyValue(0);
        setSaIsRestarted(1);
        let ticks = Math.floor(Date.now()/1000);
        history.push({
          pathname: `${selectedLearningModule.courseID}/quiz/${selectedLearningModule.id}/${ticks}`,
          state: {
            learningModule: selectedLearningModule,
            moduleType: selectedLearningModule.lessonType,
            isLesson: false,
          },
        });
      }  
    }

    const handleReturn = () => {
      addError(oidcUser.access_token, userId, orgDomain, "Closed Salt Adaptive warning popup", null, "SARestartModalContent.tsx", 6, EnableActivityLogs)
      setModalOpen(false)
    }

    return (
      <div className={styles.container}>
          <div>
            <h1 style={{ color: "#e7a652" }}>
              {allResourcesToTranslations.confirmation}
            </h1>
          </div>
        <div className={styles.divider}></div>
        <div>
          {/* <p dangerouslySetInnerHTML={createHeaderMarkup()}></p> */}
          <p>You have selected the <b>Restart</b> course button.</p>         
          <p>
            {/* {allResourcesToTranslations.yourcoursestatuswillbereset} */}
            By choosing the Continue button below, this Module will be <b>Reset</b> and you will be required to complete this Module again. To satisfy a new Passed status you will need to successfully answer all Assessment questions.
            <br></br><br></br>
            Your progress will be bookmarked in case you need to exit and return to it later.
          </p>
        </div>
        <div>
            <Button color="primary" variant="contained" onClick={ () => handleReturn() }>
              {allResourcesToTranslations.return}
            </Button>
            <Button color="primary" variant="contained" onClick={ () => handleContinue() } >
              {allResourcesToTranslations.continue}
            </Button>
        </div>
      </div>
    );
}

export default SARestartModalContent;
