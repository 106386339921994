import React from 'react';
import styles from '../styles/warningCard.module.css';

const ErrorCard = ({children}: any): JSX.Element => {
  return (
    <div className={styles.warningCard}>
      {children}
      <div className={styles.colorBar} style={{ backgroundColor: "#ff0000" }} />
    </div>
  );
};

export default ErrorCard;
