//React + Typescript
import React, { useEffect, useState, useRef, useContext }from 'react';
import { LearningModule } from '../../globalTypes';
//Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Context
import { InfopathContext } from '../../contexts/InfopathContext';
import { UIContext } from '../../contexts/UIContext';
import { SessionContext } from '../../contexts/SessionContext';
//API Functions 
import { addError } from '../../API/AddError'
import { getInfoPathQuizData } from '../../API/GetInfoPathQuizData';
//Components
import LoaderContainer from '../../Components/LoaderContainer';
import InfopathQuizPlayer from './InfopathQuizPlayer';


interface Props {
    learningModuleData: LearningModule
    isLesson: boolean
}

declare global {
    interface Window {
        _currentIsLesson:boolean;
    }
}

export const InfopathQuiz = ({learningModuleData, isLesson}: Props): JSX.Element => {
    const [ loading, setLoading ] = useState<boolean>(true); 
    const [ quiz, setQuiz ] = useState<any>(null)
    const { oidcUser } = useReactOidc();
    const { infopathQuestionCount, EnableActivityLogs } = useContext(UIContext)
    const { setIsCurSessionLesson } = useContext(SessionContext)
    const { setHasLoadedSessionData } = useContext(InfopathContext)
    const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0;
    const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";

    const isMounted = useRef<boolean>(false)
    useEffect(() => {
        setHasLoadedSessionData (false)
        isMounted.current = true;
        getInfoPathQuizData(oidcUser.access_token, userId, orgDomain, learningModuleData.courseID, learningModuleData.id, infopathQuestionCount).then((data) => { 
                if(!data) {
                    addError(oidcUser.access_token, userId, orgDomain, "no data return from API", null, "InfopathQuiz.tsx", 1)
                } 
                if (isMounted.current){
                    if(data.length > 0) {
                        setQuiz(data[0])
                        setIsCurSessionLesson(false)
                        setLoading(false)
                    } else {
                        setQuiz(null)
                    }
                }
                setHasLoadedSessionData (true)
            })   
            return () => {
                setHasLoadedSessionData (false)
                isMounted.current = false
            } 
    },[])// eslint-disable-line react-hooks/exhaustive-deps

    if(loading) return <LoaderContainer/>

    return  <InfopathQuizPlayer quiz={quiz} learningModuleData={learningModuleData} />
}

export default InfopathQuiz;