import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const SSOAzureIcon = () => (
<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_67_136)">
<rect x="3" y="3" width="40" height="40" rx="8" fill="#63C2FF"/>
<path d="M22.3302 32.709C26.3066 32.0118 29.5986 31.418 29.6374 31.418H29.7019L25.9451 26.9382C23.8794 24.4852 22.1882 22.4584 22.1882 22.4454C22.1882 22.4196 26.0742 11.743 26.0871 11.7042C26.0871 11.7042 28.7337 16.2486 32.4905 22.7553C36.0021 28.8489 38.9068 33.8709 38.9326 33.9096L38.9843 34H15.1006L22.3431 32.7219L22.3302 32.709Z" fill="white"/>
<path d="M8 31.3533C8 31.3533 9.76869 28.2678 11.9376 24.5239L15.8752 17.7073L20.4583 13.8601C22.9757 11.7429 25.0543 10.0129 25.0543 10C25.0543 10 25.0284 10.0775 24.9768 10.1807C24.9252 10.284 22.6917 15.0866 19.9935 20.8574L15.1006 31.3533H11.5503C9.58794 31.3533 8 31.3533 8 31.3533Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_67_136" x="0" y="0" width="50" height="50" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="2"/>
<feGaussianBlur stdDeviation="2.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_67_136"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_67_136" result="shape"/>
</filter>
</defs>
</svg>


);

export default SSOAzureIcon