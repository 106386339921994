import React from 'react';

export const ComplianceRulesIcon = () => (    
    <svg width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M22.2884 3.28229H14.6156C13.3444 3.28229 12.3138 4.31285 12.3138 5.58411V7.11866H24.5902V5.58411C24.5902 4.31285 23.5596 3.28229 22.2884 3.28229ZM26.892 7.11866H33.0302C34.7252 7.11866 36.0993 8.49274 36.0993 10.1878V20.9296H33.7975V16.3259H26.892V19.395H20.7538V16.3259H16.1502V19.395H10.012V16.3259H3.10651V30.1369C3.10651 30.5606 3.45003 30.9041 3.87378 30.9041H23.8229V33.206H3.87378C2.17877 33.206 0.804688 31.8319 0.804688 30.1369V10.1878C0.804688 8.49274 2.17877 7.11866 3.87378 7.11866H10.012V5.58411C10.012 3.04159 12.0731 0.980469 14.6156 0.980469H22.2884C24.8309 0.980469 26.892 3.04159 26.892 5.58411V7.11866ZM3.87378 9.42048H33.0302C33.4539 9.42048 33.7975 9.764 33.7975 10.1878V13.2569H3.10651V10.1878C3.10651 9.764 3.45003 9.42048 3.87378 9.42048Z" fill="#21394F"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M36.2311 24.1352H27.8671C27.4054 24.1352 27.0307 24.5099 27.0307 24.9716V35.0084C27.0307 35.4701 27.4054 35.8448 27.8671 35.8448H36.2311C36.6928 35.8448 37.0675 35.4701 37.0675 35.0084V24.9716C37.0675 24.5099 36.6928 24.1352 36.2311 24.1352ZM27.8671 22.4624C26.4812 22.4624 25.3579 23.5857 25.3579 24.9716V35.0084C25.3579 36.3943 26.4812 37.5176 27.8671 37.5176H36.2311C37.617 37.5176 38.7403 36.3943 38.7403 35.0084V24.9716C38.7403 23.5857 37.617 22.4624 36.2311 22.4624H27.8671Z" fill="#21394F"/>
        <path d="M31.0102 31.3119V30.9093C31.0102 30.5612 31.0846 30.2584 31.2332 30.0009C31.3819 29.7434 31.6539 29.4696 32.0492 29.1795C32.43 28.9075 32.6802 28.6863 32.7999 28.5159C32.9232 28.3454 32.9848 28.155 32.9848 27.9447C32.9848 27.709 32.8978 27.5295 32.7237 27.4062C32.5497 27.2829 32.3067 27.2212 31.9948 27.2212C31.4508 27.2212 30.8307 27.3989 30.1344 27.7543L29.5415 26.563C30.3502 26.1097 31.2079 25.8831 32.1145 25.8831C32.8615 25.8831 33.4545 26.0626 33.8933 26.4216C34.3357 26.7806 34.5569 27.2593 34.5569 27.8577C34.5569 28.2566 34.4662 28.6011 34.2849 28.8912C34.1036 29.1813 33.7591 29.5077 33.2514 29.8703C32.9032 30.1278 32.682 30.3237 32.5877 30.4578C32.4971 30.592 32.4517 30.7679 32.4517 30.9855V31.3119H31.0102ZM30.8361 33.1722C30.8361 32.8676 30.9177 32.6373 31.0809 32.4814C31.2441 32.3255 31.4817 32.2475 31.7935 32.2475C32.0945 32.2475 32.3266 32.3273 32.4898 32.4868C32.6566 32.6464 32.74 32.8749 32.74 33.1722C32.74 33.4587 32.6566 33.6854 32.4898 33.8522C32.323 34.0154 32.0909 34.097 31.7935 34.097C31.4889 34.097 31.2532 34.0172 31.0864 33.8576C30.9196 33.6944 30.8361 33.466 30.8361 33.1722Z" fill="#21394F"/>
    </svg>
);

export default ComplianceRulesIcon
