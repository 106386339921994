// React
import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context';
//import { debounce } from 'lodash'

// MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from '@material-ui/core/Checkbox';
import SearchIcon from "@material-ui/icons/Search";
import { IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ExpandAllIcon from '../../../CustomIcons/ExpandAllIcon';
import CollapseAllIcon from '../../../CustomIcons/CollapseAllIcon';

// Types
import { ProfileCourseToDisplay } from "../../../globalTypes";
import { ProfileModuleToDisplay } from "../../../globalTypes";
import { ProfilePolicyToDisplay } from "../../../globalTypes";
import { ProfileExtCertToDisplay } from "../../../globalTypes";
import { CourseModuleProfilePoint } from "../../../globalTypes";
import { PolicyProfilePoint } from "../../../globalTypes";
import { ExtCertProfilePoint } from "../../../globalTypes";

// Coponents
import BrandedCard from '../../../Components/BrandedCard';
import UnbrandedCard from '../../../Components/UnbrandedCard';
import LoaderContainer from "../../../Components/LoaderContainer";
import CustomTooltip from "../../../Components/CustomTooltip";

// Styles
import styles from "../../../styles/assignpoints.module.css";

// Contexts
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { UIContext } from "../../../contexts/UIContext";

// APIs
import GetOrgCourseModuleProfilePoints from "../../../API/Administration/CPDProfiles/GetOrgCourseModuleProfilePoints";
import GetOrgPolicyProfilePoints from "../../../API/Administration/CPDProfiles/GetOrgPolicyProfilePoints";
import GetOrgExtCertProfilePoints from "../../../API/Administration/CPDProfiles/GetOrgExtCertProfilePoints";

interface Props {
    isCalledFrom: string;
    profileID: number;
    profilePeriodID: number;
    applyToQuizValue: boolean;
    applyToLessonValue: boolean;
    excludeCompletedUsersValue: boolean;
    guideMeClickTriggered: boolean;
    handleApplyToQuiz: ((flag: boolean) => void);
    handleApplyToLesson: ((flag: boolean) => void);
    handleModuleProfilePointChanges: ((changedValues: string) => void);
    handlePolicyProfilePointChanges: ((changedValues: string) => void);
    handleExtCertProfilePointChanges: ((changedValues: string) => void);
    handleModuleProfilePointInitialValues: ((initialValues: string) => void);
    handlePolicyProfilePointInitialValues: ((initialValues: string) => void);
    handleExtCertProfilePointInitialValues: ((initialValues: string) => void);
    handleExclusionOfCompletedUsers: ((isExcluded: boolean) => void);
    getLatestValueOfModifiedModules: (() => string);
    getLatestValueOfModifiedPolicies: (() => string);
    getLatestValueOfModifiedExtCerts: (() => string);
    getLatestValueOfInitialModules: (() => string);
    getLatestValueOfInitialPolicies: (() => string);
    getLatestValueOfInitialExtCerts: (() => string);
    setPointsLoaded: ((isLoaded: boolean) => void);
}

const AssignPoints = ({ isCalledFrom, profileID, profilePeriodID, handleApplyToQuiz, 
                        handleApplyToLesson, applyToQuizValue, applyToLessonValue, 
                        excludeCompletedUsersValue, handleExclusionOfCompletedUsers, 
                        handleModuleProfilePointChanges, handlePolicyProfilePointChanges, 
                        handleExtCertProfilePointChanges, handleModuleProfilePointInitialValues, 
                        handlePolicyProfilePointInitialValues, handleExtCertProfilePointInitialValues, 
                        getLatestValueOfModifiedModules, getLatestValueOfModifiedPolicies, 
                        getLatestValueOfModifiedExtCerts, getLatestValueOfInitialModules, getLatestValueOfInitialPolicies, 
                        getLatestValueOfInitialExtCerts, setPointsLoaded, guideMeClickTriggered }: Props) => {
    
  // Translations
  const { translations: { allResourcesToTranslations }, } = useContext(TranslationsContext);
  const { isCPDAssignPointsGuidemeClicked } = useContext(UIContext);
  
  // User & Org Details
  const { oidcUser } = useReactOidc();
  const { profile: { user_id, website }, } = oidcUser || {};
  const requestingUserId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";

  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [isDataRead, setDataRead] = useState<boolean>(false);
  const [coursePointsLoading, setCoursePointsLoading] = useState<boolean>(false);
  const [policyPointsLoading, setPolicyPointsLoading] = useState<boolean>(false);
  const [extCertPointsLoading, setExtCertPointsLoading] = useState<boolean>(false);
  const [filterCourseOrModuleNameCSV, setFilterCourseOrModuleNameCSV]  = useState("");
  const [courseList, setCourseList] = useState<Array<ProfileCourseToDisplay>>([]);
  const [courseListOriginal, setCourseListOriginal] = useState<Array<ProfileCourseToDisplay>>([]);
  const [filterPolicyNameCSV, setFilterPolicyNameCSV]  = useState("");
  const [policyList, setPolicyList] = useState<Array<ProfilePolicyToDisplay>>([]);
  const [policyListOriginal, setPolicyListOriginal] = useState<Array<ProfilePolicyToDisplay>>([]);
  const [filterExtCertNameCSV, setFilterExtCertNameCSV]  = useState("");
  const [extCertList, setExtCertList] = useState<Array<ProfileExtCertToDisplay>>([]);
  const [extCertListOriginal, setExtCertListOriginal] = useState<Array<ProfileExtCertToDisplay>>([]);
  const [courseModuleCardExpanded, setCourseModuleCardExpanded] = useState<boolean>(true);
  const [policyCardExpanded, setPolicyCardExpanded] = useState<boolean>(true);
  const [extCertCardExpanded, setExtCertCardExpanded] = useState<boolean>(true);
  const [courseExpandOrCollapseChanged, setCourseExpandOrCollapseChanged] = useState<boolean>(false);
  const [showOnlyPointsAssigned, setShowOnlyPointsAssigned] = useState<boolean>(false);
  const [showOnlyPointsAssignedPolicy, setShowOnlyPointsAssignedPolicy] = useState<boolean>(false);
  const [totalPointsOfCourseModules, setTotalCourseModulePoints] = useState<number>(0.0);
  const [totalPointsOfPolicies, setTotalPolicyPoints] = useState<number>(0.0);
  const [showOnlyPointsAssignedExtCert, setShowOnlyPointsAssignedExtCert] = useState<boolean>(false);
  const [totalPointsOfExtCerts, setTotalExtCertPoints] = useState<number>(0.0);
  //const [assignToType, setAssignToType] = useState<any>("allaccountsincludingcompleted");
  const [assignToType, setAssignToType] = useState<any>("allaccountsexcludingcompleted");
  const [moduleIDsToPointsInitial, setModuleIDsToPointsInitial] = useState<string>(""); // <moduleid1>:<points>&<moduleid2>:<points>.....
  const [policyIDsToPointsInitial, setPolicyIDsToPointsInitial] = useState<string>(""); // <policyid1>:<points>&<policyid2>:<points>.....
  const [extCertIDsToPointsInitial, setExtCertIDsToPointsInitial] = useState<string>(""); // <policyid1>:<points>&<policyid2>:<points>.....
  const [changedModuleIDsToPoints, setChangedModuleIDsToPoints] = useState<string>(""); // <moduleid1>:<points>&<moduleid2>:<points>.....
  const [changedPolicyIDsToPoints, setChangedPolicyIDsToPoints] = useState<string>(""); // <policyid1>:<points>&<policyid2>:<points>.....
  const [changedExtCertIDsToPoints, setChangedExtCertIDsToPoints] = useState<string>(""); // <policyid1>:<points>&<policyid2>:<points>.....
  // const [curEditedModuleVal, setCurEditedModuleVal] = useState<string>("");
  // const [curEditedPolicyVal, setCurEditedPolicyVal] = useState<string>("");
  // const [policyCardExpanded, setPolicyCardExpanded] = useState<boolean>(false);
  // const [policyCardExpanded, setPolicyCardExpanded] = useState<boolean>(false);
  // const [policyCardExpanded, setPolicyCardExpanded] = useState<boolean>(false);
  // const [policyCardExpanded, setPolicyCardExpanded] = useState<boolean>(false);
  const [isCommonPointsToCourseModules, setIsCommonPointsToCourseModules] = useState<boolean>(false);
  const [isCommonPointsToPolicies, setIsCommonPointsToPolicies] = useState<boolean>(false);
  const [commonPointsOfCourseModules, setCommonPointsOfCourseModules] = useState<string>("0.00");
  const [commonPointsOfPolicies, setCommonPointsOfPolicies] = useState<string>("0.00");
  const [commonPointsOfExtCerts, setCommonPointsOfExtCerts] = useState<string>("0.00");
  const [isCommonPointsToExtCerts, setIsCommonPointsToExtCerts] = useState<boolean>(false);

  const [firstCourseExpansion, setFirstCourseExpansion] = useState<boolean | null>(null);
  const [firstPolicyExpansion, setFirstPolicyExpansion] = useState<boolean | null>(null);
  const [firstExtCertExpansion, setFirstExtCertExpansion] = useState<boolean | null>(null);
  
  
  const handleCourseExpandClick = (e: any, courseData: ProfileCourseToDisplay) => {    
    handleCourseExpansion(courseData);
  }

  const handleCourseExpansion = (courseData: ProfileCourseToDisplay) => {
    if (!!courseData.expanded) {
      courseData.expanded = !courseData.expanded;
    }
    else {
      courseData.expanded = true;
    }

    const updatedCourseData = courseList.map((item: ProfileCourseToDisplay) => {
      if (!!item && !!courseData && !!item.courseID && !!courseData.courseID && item.courseID == courseData.courseID) {
        item.modules = item.modules.slice(0).map((elem) => 
          {
            return {
              ...elem
            }
          }
        );
        return {
          ...item,
          expanded: courseData.expanded
        }
      }
      return item; 
    });

    setCourseList([...updatedCourseData]);
  }
  
  // Set the filter matched courses/modules 
  const setFilteredCoursesOnly = (givenCourseList : Array<ProfileCourseToDisplay>) => {
    setCoursePointsLoading(true);
    //console.log("T1 " + new Date().toISOString());
    const filteredCourseList = givenCourseList.map((item: ProfileCourseToDisplay) => {
      if (!!item && !!item.courseID) {
        if ((!!filterCourseOrModuleNameCSV !== true || 
          (!!filterCourseOrModuleNameCSV && !!item.courseName && 
            //item.courseName.trim().toLowerCase().includes(filterCourseOrModuleName.trim().toLowerCase())))) {
            filterCourseOrModuleNameCSV.toLowerCase().split(",").some(f => !!f && item.courseName.trim().toLowerCase().includes(f.toLowerCase().trim()))))) {
          
          return {
            ...item,
            isSearchMatch: true
          }
        }
        if (!!item.modules) {
          item.modules = item.modules.slice(0).map((elem) => {
            if ((!!filterCourseOrModuleNameCSV !== true || 
              (!!filterCourseOrModuleNameCSV && !!elem.moduleName && 
                //elem.moduleName.trim().toLowerCase().includes(filterCourseOrModuleName.trim().toLowerCase())))) {
                filterCourseOrModuleNameCSV.toLowerCase().split(",").some(f => !!f && elem.moduleName.trim().toLowerCase().includes(f.toLowerCase().trim()))))) {
          
              return {
                ...elem,
                isSearchMatch: true
              }
            }
            else {
              return {
                ...elem,
                isSearchMatch: false
              }
            }
          });
        }
        return {
          ...item,
          isSearchMatch: false
        }
      }
      return item; 
    });

    //setCourseList(filteredCourseList);
    setCourseList([...filteredCourseList]);
    setCoursePointsLoading(false);

    //console.log("T2 " + new Date().toISOString());
  }
  
  // Set the filter matched courses/modules 
  const setFilteredExtCertsOnly = (givenExtCertList : Array<ProfileExtCertToDisplay>) => {
    
    setExtCertPointsLoading(true);
    //console.log("T1 " + new Date().toISOString());
    const filteredExtCertList = givenExtCertList.map((item: ProfileExtCertToDisplay) => {
      if (!!item && !!item.extCertID) {
        if ((!!filterExtCertNameCSV !== true || 
          (!!filterExtCertNameCSV && !!item.extCertName && 
            //item.extCertName.trim().toLowerCase().includes(filterExtCertName.trim().toLowerCase())))) {
            filterExtCertNameCSV.toLowerCase().split(",").some(f => !!f && item.extCertName.trim().toLowerCase().includes(f.trim().toLowerCase()))))) {
          
          return {
            ...item,
            isSearchMatch: true
          }
        }
        return {
          ...item,
          isSearchMatch: false
        }
      }
      return item; 
    });

    //setExtCertList(filteredExtCertList);
    setExtCertList([...filteredExtCertList]);
    setExtCertPointsLoading(false);
    

    //console.log("T2 " + new Date().toISOString());
  }
  
  // Set the filter matched courses/modules 
  const setFilteredPoliciesOnly = (givenPolicyList : Array<ProfilePolicyToDisplay>) => {
    
    setPolicyPointsLoading(true);
    //console.log("T1 " + new Date().toISOString());
    const filteredPolicyList = givenPolicyList.map((item: ProfilePolicyToDisplay) => {
      if (!!item && !!item.policyID) {
        if ((!!filterPolicyNameCSV !== true || 
          (!!filterPolicyNameCSV && !!item.policyName && 
            //item.policyName.trim().toLowerCase().includes(filterPolicyName.trim().toLowerCase())))) {
            filterPolicyNameCSV.toLowerCase().split(",").some(f => !!f && item.policyName.trim().toLowerCase().includes(f.trim().toLowerCase()))))) {
          
          return {
            ...item,
            isSearchMatch: true
          }
        }
        return {
          ...item,
          isSearchMatch: false
        }
      }
      return item; 
    });

    //setPolicyList(filteredPolicyList);
    setPolicyList([...filteredPolicyList]);
    setPolicyPointsLoading(false);
    

    //console.log("T2 " + new Date().toISOString());
  }

  const cloneGivenListAndSetOriginalCourseList = (givenCourseList : Array<ProfileCourseToDisplay>) => {    
    let localList : Array<ProfileCourseToDisplay>  = [];
    localList = cloneGivenList(givenCourseList);
    setCourseListOriginal(localList);
  }

  const cloneGivenList = (givenCourseList : Array<ProfileCourseToDisplay>) => {
    if (!!givenCourseList !== true){
      return givenCourseList;
    }
    let originalList : Array<ProfileCourseToDisplay>  = [];
    originalList = givenCourseList.map((c : ProfileCourseToDisplay) => {
      let curCItem : ProfileCourseToDisplay = {
        courseID: c.courseID,
        courseIDTxt: c.courseID.toString(),
        courseName: c.courseName,
        isCourseActive: c.isCourseActive,
        isCourseOrgGranted: c.isCourseOrgGranted,
        anyModulesAssingedPoints: c.anyModulesAssingedPoints,
        allModulesAssingedPoints: c.allModulesAssingedPoints,
        totalAssignedPoints: c.totalAssignedPoints,
        expanded: c.expanded,
        isSearchMatch: c.isSearchMatch,
        modules: c.modules.map((m : ProfileModuleToDisplay) => {
                                  let curMItem : ProfileModuleToDisplay = {
                                    moduleID: m.moduleID,
                                    moduleIDTxt: m.moduleID.toString(),
                                    moduleName: m.moduleName,
                                    isModuleActive: m.isModuleActive,
                                    assignedPoints: m.assignedPoints,
                                    assignedPointsTxt: m.assignedPoints.toFixed(2),
                                    isSearchMatch: m.isSearchMatch
                                  };
                                  return curMItem; 
                              })
      }
      return curCItem;
    });
    return originalList;
  } 

  const cloneGivenListAndSetOriginalPolicyList = (givenPolicyList : Array<ProfilePolicyToDisplay>) => {    
    let localList : Array<ProfilePolicyToDisplay>  = [];
    localList = cloneGivenPolicyList(givenPolicyList);
    setPolicyListOriginal(localList);
  }

  const cloneGivenListAndSetOriginalExtCertList = (givenExtCertList : Array<ProfileExtCertToDisplay>) => {    
    let localList : Array<ProfileExtCertToDisplay>  = [];
    localList = cloneGivenExtCertList(givenExtCertList);
    setExtCertListOriginal(localList);
  }

  const cloneGivenPolicyList = (givenPolicyList : Array<ProfilePolicyToDisplay>) => {
    if (!!givenPolicyList !== true){
      return givenPolicyList;
    }
    let originalList : Array<ProfilePolicyToDisplay>  = [];
    originalList = givenPolicyList.map((p : ProfilePolicyToDisplay) => {
      let curPItem : ProfilePolicyToDisplay = {
        policyID: p.policyID,
        policyIDTxt: p.policyID.toString(),
        policyName: p.policyName,
        isPolicyActive: p.isPolicyActive,
        assignedPoints: p.assignedPoints,
        assignedPointsTxt: p.assignedPoints.toFixed(2),
        expanded: p.expanded,
        isSearchMatch: p.isSearchMatch,
      }
      return curPItem;
    });
    return originalList;
  }  

  const cloneGivenExtCertList = (givenExtCertList : Array<ProfileExtCertToDisplay>) => {
    if (!!givenExtCertList !== true){
      return givenExtCertList;
    }
    let originalList : Array<ProfileExtCertToDisplay>  = [];
    originalList = givenExtCertList.map((p : ProfileExtCertToDisplay) => {
      let curPItem : ProfileExtCertToDisplay = {
        extCertID: p.extCertID,
        extCertIDTxt: p.extCertID.toString(),
        extCertName: p.extCertName,
        isExtCertActive: p.isExtCertActive,
        isCPDEnabled: p.isCPDEnabled,
        assignedPoints: p.assignedPoints,
        assignedPointsTxt: p.assignedPoints.toFixed(2),
        expanded: p.expanded,
        isSearchMatch: p.isSearchMatch,
      }
      return curPItem;
    });
    return originalList;
  }   
  
  const onAccordianChange = (event: any, expanded: boolean) => {
    if (!!event && !!event.currentTarget && !!event.currentTarget.ariaExpanded) {
      event.currentTarget.ariaExpanded = !!expanded ? "true" : "false";
    }    
  }

  const onlyUnique = (value: number, index: number, array: Array<number>) => {
    return array.indexOf(value) === index;
  }

  const calculateTotalCourseModulePoints = (givenList: Array<ProfileCourseToDisplay>) => {
    return (!!givenList && givenList.length > 0) ? givenList.reduce((accumulator, curCourse) => accumulator + (!!curCourse && !!curCourse.totalAssignedPoints && curCourse.totalAssignedPoints >= 0.0 ? curCourse.totalAssignedPoints : 0.0), 0) : 0.0;
  }

  const calculateTotalPolicyPoints = (givenList: Array<ProfilePolicyToDisplay>) => {
    return (!!givenList && givenList.length > 0) ? givenList.reduce((accumulator, policy) => accumulator + (!!policy && !!policy.assignedPoints && policy.assignedPoints >= 0.0 ? policy.assignedPoints : 0.0), 0) : 0.0;
  }

  const calculateTotalExtCertPoints = (givenList: Array<ProfileExtCertToDisplay>) => {
    return (!!givenList && givenList.length > 0) ? givenList.reduce((accumulator, policy) => accumulator + (!!policy && !!policy.assignedPoints && policy.assignedPoints >= 0.0 ? policy.assignedPoints : 0.0), 0) : 0.0;
  }

  const fillProfileCoursesForDisplay = (givenList: Array<CourseModuleProfilePoint>) => {
    if (givenList == null || givenList == undefined || givenList.length <= 0) {
      setCourseList([]);
      setCourseListOriginal([]);
    }
    else {
      let profileCoursesToDisplay: Array<ProfileCourseToDisplay> = [];
      let courseIDList: Array<number> = givenList.filter(l => !!l && l.courseID > 0).map(l => l.courseID).filter(onlyUnique);
      if (courseIDList == null || courseIDList == undefined || courseIDList.length <= 0) {
        setCourseList([]);
        setCourseListOriginal([]);
      }
      else {
        let collectedInitialValues = "";
        for (let i=0; i<courseIDList.length; i++) {
          let curVal = courseIDList[i];
          if (curVal == null || curVal == undefined || curVal <= 0) {
            continue;
          }
          let courseIDMappedList: Array<CourseModuleProfilePoint> = givenList.filter(l => !!l && l.courseID == curVal);
          if (courseIDMappedList == null || courseIDMappedList == undefined || courseIDMappedList.length <= 0) {
            continue;
          }
          let isCourseDetailsDone = false;
          let curCItem : ProfileCourseToDisplay = {
            courseID: -1, // To change
            courseIDTxt: "", // To change
            courseName: "", // To change
            isCourseActive: false, // To change
            isCourseOrgGranted: false, // To change
            anyModulesAssingedPoints: false, // To change
            allModulesAssingedPoints: false, // To change
            totalAssignedPoints: 0.0, // To change
            expanded: false,
            isSearchMatch: false,
            modules: [] // To change
          };
          //curCItem.expanded = i == 0 ? true : curCItem.expanded; // Only at the first load expand the first one by default
          let isAnyModuleNotAssignedPoints = false;
          for (let j=0; j<courseIDMappedList.length; j++) {
            let curMItem : ProfileModuleToDisplay = {
              moduleID: -1, // To change
              moduleIDTxt: "", // To change
              moduleName: "", // To change
              isModuleActive: false, // To change
              assignedPoints: 0.0, // To change
              assignedPointsTxt: "0.00", // To change
              isSearchMatch: false
            };
            let curCMVal = courseIDMappedList[j];
            if (curCMVal == null || curCMVal == undefined || curCMVal.courseID <= 0 || curCMVal.moduleID <= 0) {
              continue;
            }
            if (isCourseDetailsDone === false) {
              curCItem.courseID = curCMVal.courseID;
              curCItem.courseIDTxt = curCMVal.courseID.toString();
              curCItem.courseName = curCMVal.courseName;
              curCItem.isCourseActive = curCMVal.isCourseActive;
              curCItem.isCourseOrgGranted = curCMVal.isCourseOrgGranted;
            }
            curCItem.modules = curCItem.modules == null || curCItem.modules == undefined ? [] : curCItem.modules.slice(0);
            curMItem.moduleID = curCMVal.moduleID;
            curMItem.moduleIDTxt = curCMVal.moduleID.toString();
            curMItem.moduleName = curCMVal.moduleName;
            curMItem.isModuleActive = curCMVal.isModuleActive;
            curMItem.assignedPoints = curCMVal.assignedPoints;
            curMItem.assignedPointsTxt = curCMVal.assignedPoints.toFixed(2);
            curMItem.isSearchMatch = false;
            if (!curCItem.modules.some(m => m.moduleID == curCMVal.moduleID)) {
              collectedInitialValues = (!!collectedInitialValues && collectedInitialValues.trim() != "") ? 
                                          collectedInitialValues.trim() + "&" + curCMVal.moduleID.toString() + ":" + curMItem.assignedPoints.toFixed(2).toString() :
                                          curCMVal.moduleID.toString() + ":" + curMItem.assignedPoints.toFixed(2).toString();
            }
            curCItem.modules.push(curMItem);
            curCItem.totalAssignedPoints = curCItem.totalAssignedPoints + curCMVal.assignedPoints;
            curCItem.anyModulesAssingedPoints = curCMVal.assignedPoints > 0.0 ? true : curCItem.anyModulesAssingedPoints;
            isAnyModuleNotAssignedPoints = curCMVal.assignedPoints <= 0.0 ? true : isAnyModuleNotAssignedPoints;
            curCItem.allModulesAssingedPoints = isAnyModuleNotAssignedPoints ? false : (j == courseIDMappedList.length-1);
            if (isCourseDetailsDone === false) {
              profileCoursesToDisplay.push(curCItem);
            }
            isCourseDetailsDone = true;
          }
        }
        setModuleIDsToPointsInitial(collectedInitialValues);
        handleModuleProfilePointInitialValues(collectedInitialValues);
        setTotalCourseModulePoints(calculateTotalCourseModulePoints(profileCoursesToDisplay));
        cloneGivenListAndSetOriginalCourseList(profileCoursesToDisplay);
        setCourseList(profileCoursesToDisplay);
        //setCourseList(profileCoursesToDisplay.map((courseIni, cindexIni) => { if (cindexIni == 0) {courseIni.expanded = true; } return courseIni;}));
        //setFilteredCoursesDebounce(filterCourseOrModuleName);
        setFilteredCoursesOnly(profileCoursesToDisplay);
        setFilterCourseOrModuleNameCSV(" ");
      }
    }
  }

  const fillProfilePoliciesForDisplay = (givenList: Array<PolicyProfilePoint>) => {
    if (givenList == null || givenList == undefined || givenList.length <= 0) {
      setPolicyList([]);
      setPolicyListOriginal([]);
    }
    else {
      let profilePoliciesToDisplay: Array<ProfilePolicyToDisplay> = [];
      let profileIDList: Array<number> = givenList.filter(l => !!l && l.policyID > 0).map(l => l.policyID).filter(onlyUnique);
      if (profileIDList == null || profileIDList == undefined || profileIDList.length <= 0) {
        setPolicyList([]);
        setPolicyListOriginal([]);
      }
      else {
        let collectedInitialValues = "";
        for (let i=0; i<profileIDList.length; i++) {
          let curVal = profileIDList[i];
          if (curVal == null || curVal == undefined || curVal <= 0) {
            continue;
          }
          let policyIDMappedList: Array<PolicyProfilePoint> = givenList.filter(l => !!l && l.policyID == curVal);
          if (policyIDMappedList == null || policyIDMappedList == undefined || policyIDMappedList.length <= 0) {
            continue;
          }
          let isPolicyDetailsDone = false;
          let curPItem : ProfilePolicyToDisplay = {
            policyID: -1, // To change
            policyIDTxt: "", // To change
            policyName: "", // To change
            isPolicyActive: false, // To change
            assignedPoints: 0.0, // To change
            assignedPointsTxt: "0.00", // To change
            expanded: false,
            isSearchMatch: false
          };
          for (let j=0; j<policyIDMappedList.length; j++) {
            let curPVal = policyIDMappedList[j];
            if (curPVal == null || curPVal == undefined || curPVal.policyID <= 0 || curPVal.policyID <= 0) {
              continue;
            }
            if (isPolicyDetailsDone === false) {
              curPItem.policyID = curPVal.policyID;
              curPItem.policyIDTxt = curPVal.policyID.toString();
              curPItem.policyName = curPVal.policyName;
              curPItem.isPolicyActive = curPVal.isPolicyActive;
              curPItem.assignedPoints = curPVal.assignedPoints;
              curPItem.assignedPointsTxt = curPVal.assignedPoints.toFixed(2);
              if (!profilePoliciesToDisplay.some(p => p.policyID == curPItem.policyID)) {
                collectedInitialValues = (!!collectedInitialValues && collectedInitialValues.trim() != "") ? 
                                            collectedInitialValues.trim() + "&" + curPItem.policyID.toString() + ":" + curPItem.assignedPoints.toFixed(2).toString() :
                                            curPItem.policyID.toString() + ":" + curPItem.assignedPoints.toFixed(2).toString();
              }
              profilePoliciesToDisplay.push(curPItem);
            }
            isPolicyDetailsDone = true;
          }
        }
        setPolicyIDsToPointsInitial(collectedInitialValues);
        handlePolicyProfilePointInitialValues(collectedInitialValues);
        setTotalPolicyPoints(calculateTotalPolicyPoints(profilePoliciesToDisplay));
        cloneGivenListAndSetOriginalPolicyList(profilePoliciesToDisplay);
        setPolicyList(profilePoliciesToDisplay);
        //setFilteredPoliciesDebounce(filterPolicyName);
        setFilteredPoliciesOnly(profilePoliciesToDisplay);
        setFilterPolicyNameCSV(" ");
      }
    }
  }

  const fillProfileExtCertificatesForDisplay = (givenList: Array<ExtCertProfilePoint>) => {
    if (givenList == null || givenList == undefined || givenList.length <= 0) {
      setExtCertList([]);
      setExtCertListOriginal([]);
    }
    else {
      let profileExtCertsToDisplay: Array<ProfileExtCertToDisplay> = [];
      let profileIDList: Array<number> = givenList.filter(l => !!l && l.extCertID > 0).map(l => l.extCertID).filter(onlyUnique);
      if (profileIDList == null || profileIDList == undefined || profileIDList.length <= 0) {
        setExtCertList([]);
        setExtCertListOriginal([]);
      }
      else {
        let collectedInitialValues = "";
        for (let i=0; i<profileIDList.length; i++) {
          let curVal = profileIDList[i];
          if (curVal == null || curVal == undefined || curVal <= 0) {
            continue;
          }
          let extCertIDMappedList: Array<ExtCertProfilePoint> = givenList.filter(l => !!l && l.extCertID == curVal);
          if (extCertIDMappedList == null || extCertIDMappedList == undefined || extCertIDMappedList.length <= 0) {
            continue;
          }
          let isExtCertDetailsDone = false;
          let curPItem : ProfileExtCertToDisplay = {
            extCertID: -1, // To change
            extCertIDTxt: "", // To change
            extCertName: "", // To change
            isExtCertActive: false, // To change
            isCPDEnabled: false, // To change
            assignedPoints: 0.0, // To change
            assignedPointsTxt: "0.00", // To change
            expanded: false,
            isSearchMatch: false
          };
          for (let j=0; j<extCertIDMappedList.length; j++) {
            let curPVal = extCertIDMappedList[j];
            if (curPVal == null || curPVal == undefined || curPVal.extCertID <= 0 || curPVal.extCertID <= 0) {
              continue;
            }
            if (isExtCertDetailsDone === false) {
              curPItem.extCertID = curPVal.extCertID;
              curPItem.extCertIDTxt = curPVal.extCertID.toString();
              curPItem.extCertName = curPVal.extCertName;
              curPItem.isExtCertActive = curPVal.isExtCertActive;
              curPItem.isCPDEnabled = curPVal.isCPDEnabled;
              curPItem.assignedPoints = curPVal.assignedPoints;
              curPItem.assignedPointsTxt = curPVal.assignedPoints.toFixed(2);
              if (!profileExtCertsToDisplay.some(p => p.extCertID == curPItem.extCertID)) {
                collectedInitialValues = (!!collectedInitialValues && collectedInitialValues.trim() != "") ? 
                                            collectedInitialValues.trim() + "&" + curPItem.extCertID.toString() + ":" + curPItem.assignedPoints.toFixed(2).toString() :
                                            curPItem.extCertID.toString() + ":" + curPItem.assignedPoints.toFixed(2).toString();
              }
              profileExtCertsToDisplay.push(curPItem);
            }
            isExtCertDetailsDone = true;
          }
        }
        setExtCertIDsToPointsInitial(collectedInitialValues);
        handleExtCertProfilePointInitialValues(collectedInitialValues);
        setTotalExtCertPoints(calculateTotalExtCertPoints(profileExtCertsToDisplay));
        cloneGivenListAndSetOriginalExtCertList(profileExtCertsToDisplay);
        setExtCertList(profileExtCertsToDisplay);
        //setFilteredExtCertsDebounce(filterExtCertName);
        setFilteredExtCertsOnly(profileExtCertsToDisplay);
        setFilterExtCertNameCSV(" ");
      }
    }
  }

  // const moduleProfilePointsAccumulator = (accumulator: number, currentModule: ProfileModuleToDisplay, index: number) => {
  //   const returns = accumulator + (!!currentModule && !!currentModule.assignedPoints && currentModule.assignedPoints >= 0.0 ? currentModule.assignedPoints : 0.0);
  //   return returns;
  // }

  // const courseProfilePointsAccumulator = (accumulator: number, currentCourse: ProfileCourseToDisplay, index: number) => {
  //   const returns = accumulator + (!!currentCourse && !!currentCourse.totalAssignedPoints && currentCourse.totalAssignedPoints >= 0.0 ? currentCourse.totalAssignedPoints : 0.0);
  //   return returns;
  // }

  const updateModifiedModuleProfilePoint = (newVal: string, moduleData: ProfileModuleToDisplay, courseData: ProfileCourseToDisplay, givenChangedModuleIDsToPoints: string) => {
    let latestInitialVals = getLatestValueOfInitialModules();
    let mIDToPointArrInitial = (!!latestInitialVals == false || latestInitialVals.trim() == "") ?
                                  [] : latestInitialVals.trim().split("&");
    mIDToPointArrInitial = (!!mIDToPointArrInitial !== true || mIDToPointArrInitial.length <= 0 || 
                            mIDToPointArrInitial.every(e => !!e === false || !e.trim().includes(":"))) ? 
                            [] : mIDToPointArrInitial;
    if (!!newVal && !!moduleData && !!courseData) {
      if (!isValidFloatString(newVal)) {
        return givenChangedModuleIDsToPoints;
      }
      let givenIdMapStart = moduleData.moduleID.toString() + ":";
      let givenIdMapEnd = parseFloat(newVal).toFixed(2); // moduleData.assignedPoints.toFixed(2);
      let givenIdPtMap = givenIdMapStart + givenIdMapEnd;
      if (!!mIDToPointArrInitial && mIDToPointArrInitial.length > 0 && 
          mIDToPointArrInitial.some(e => !!e && e.trim() == givenIdPtMap)) {
        let temp = givenChangedModuleIDsToPoints;
        let idx = temp.indexOf(givenIdMapStart);
        if (idx >= 0) {
          let start = temp.substring(0, idx);
          let idx2 = temp.indexOf("&", idx + givenIdMapStart.length);
          let end = idx2 <= 0 ? "" : temp.substring(idx2);
          // Replace different value
          setChangedModuleIDsToPoints(start + end);
          handleModuleProfilePointChanges(start + end);
          return start + end;
        }
        return givenChangedModuleIDsToPoints; // No change as the given value is exactly same as initial
      }
      if (!!givenChangedModuleIDsToPoints == false || givenChangedModuleIDsToPoints.trim() == "") { 
        // Create new
        setChangedModuleIDsToPoints(givenIdPtMap);
        handleModuleProfilePointChanges(givenIdPtMap);
        return givenIdPtMap;
      }
      else {
        let mIDToPointArr = givenChangedModuleIDsToPoints.trim().split("&");
        if (!!mIDToPointArr !== true || mIDToPointArr.length <= 0 || 
            mIDToPointArr.every(e => !!e === false || !e.trim().includes(":"))) {
            // Create new
            setChangedModuleIDsToPoints(givenIdPtMap);
            handleModuleProfilePointChanges(givenIdPtMap);
            return givenIdPtMap;
        }
        if (mIDToPointArr.some(e => !!e && e.trim() == givenIdPtMap)) {
          return givenChangedModuleIDsToPoints; // Change is already there as same value
        }
        else if (!mIDToPointArr.some(e => !!e && e.trim().startsWith(givenIdMapStart))) {
          // Append
          setChangedModuleIDsToPoints(givenChangedModuleIDsToPoints + "&" + givenIdPtMap);
          handleModuleProfilePointChanges(givenChangedModuleIDsToPoints + "&" + givenIdPtMap);
          return givenChangedModuleIDsToPoints + "&" + givenIdPtMap;
        }
        else if (mIDToPointArr.some(e => !!e && e.trim().startsWith(givenIdMapStart))) {
          let temp = givenChangedModuleIDsToPoints;
          let idx = temp.indexOf(givenIdMapStart);
          if (idx >= 0) {
            let start = temp.substring(0, idx + givenIdMapStart.length);
            let idx2 = temp.indexOf("&", idx + givenIdMapStart.length);
            let end = idx2 <= 0 ? "" : temp.substring(idx2);
            // Replace different value
            setChangedModuleIDsToPoints(start + givenIdMapEnd + end);
            handleModuleProfilePointChanges(start + givenIdMapEnd + end);
            return start + givenIdMapEnd + end;
          }
          else {
            // Append
            setChangedModuleIDsToPoints(givenChangedModuleIDsToPoints + "&" + givenIdPtMap);
            handleModuleProfilePointChanges(givenChangedModuleIDsToPoints + "&" + givenIdPtMap);
            return givenChangedModuleIDsToPoints + "&" + givenIdPtMap;
          }
        }
      }
    }
    return givenChangedModuleIDsToPoints;
  }

  const isValidFloatString = (newVal: string): boolean => {
    if (!(!!newVal && newVal.trim() != "")) {
      return false;
    }
    let givenPoints = 0.0;
    try {
      givenPoints = parseFloat(newVal);
      if (Number.isNaN(givenPoints)) {
        return false;
      }
      return true;
    }
    catch {
      return false;
    }
  }

  const updateModifiedPolicyProfilePoint = (newVal: string, policyData: ProfilePolicyToDisplay, givenChangedPolicyIDsToPoints: string) => {
    let latestInitialVals = getLatestValueOfInitialPolicies();    
    let pIDToPointArrInitial = (!!latestInitialVals == false || latestInitialVals.trim() == "") ?
                                  [] : latestInitialVals.trim().split("&");
    pIDToPointArrInitial = (!!pIDToPointArrInitial !== true || pIDToPointArrInitial.length <= 0 || 
                            pIDToPointArrInitial.every(e => !!e === false || !e.trim().includes(":"))) ? 
                            [] : pIDToPointArrInitial;
    if (!!newVal && !!policyData) {
      if (!isValidFloatString(newVal)) {
        return givenChangedPolicyIDsToPoints;
      }
      let givenIdMapStart = policyData.policyID.toString() + ":";
      let givenIdMapEnd = parseFloat(newVal).toFixed(2);// policyData.assignedPoints.toFixed(2);
      let givenIdPtMap = givenIdMapStart + givenIdMapEnd;
      if (!!pIDToPointArrInitial && pIDToPointArrInitial.length > 0 && 
          pIDToPointArrInitial.some(e => !!e && e.trim() == givenIdPtMap)) {
        let temp = givenChangedPolicyIDsToPoints;
        let idx = temp.indexOf(givenIdMapStart);
        if (idx >= 0) {
          let start = temp.substring(0, idx);
          let idx2 = temp.indexOf("&", idx + givenIdMapStart.length);
          let end = idx2 <= 0 ? "" : temp.substring(idx2);
          // Replace different value
          setChangedPolicyIDsToPoints(start + end);
          handlePolicyProfilePointChanges(start + end);
          return start + end;
        }
        return givenChangedPolicyIDsToPoints; // No change as the given value is exactly same as initial
      }
      if (!!givenChangedPolicyIDsToPoints == false || givenChangedPolicyIDsToPoints.trim() == "") { 
        // Create new
        setChangedPolicyIDsToPoints(givenIdPtMap);
        handlePolicyProfilePointChanges(givenIdPtMap);
        return givenIdPtMap;
      }
      else {
        let pIDToPointArr = givenChangedPolicyIDsToPoints.trim().split("&");
        if (!!pIDToPointArr !== true || pIDToPointArr.length <= 0 || 
            pIDToPointArr.every(e => !!e === false || !e.trim().includes(":"))) {
            // Create new
            setChangedPolicyIDsToPoints(givenIdPtMap);
            handlePolicyProfilePointChanges(givenIdPtMap);
            return givenIdPtMap;
        }
        if (pIDToPointArr.some(e => !!e && e.trim() == givenIdPtMap)) {
          return givenChangedPolicyIDsToPoints; // Change is already there as same value
        }
        else if (!pIDToPointArr.some(e => !!e && e.trim().startsWith(givenIdMapStart))) {
          // Append
          setChangedPolicyIDsToPoints(givenChangedPolicyIDsToPoints + "&" + givenIdPtMap);
          handlePolicyProfilePointChanges(givenChangedPolicyIDsToPoints + "&" + givenIdPtMap);
          return givenChangedPolicyIDsToPoints + "&" + givenIdPtMap;
        }
        else if (pIDToPointArr.some(e => !!e && e.trim().startsWith(givenIdMapStart))) {
          let temp = givenChangedPolicyIDsToPoints;
          let idx = temp.indexOf(givenIdMapStart);
          if (idx >= 0) {
            let start = temp.substring(0, idx + givenIdMapStart.length);
            let idx2 = temp.indexOf("&", idx + givenIdMapStart.length);
            let end = idx2 <= 0 ? "" : temp.substring(idx2);
            // Replace different value
            setChangedPolicyIDsToPoints(start + givenIdMapEnd + end);
            handlePolicyProfilePointChanges(start + givenIdMapEnd + end);
            return start + givenIdMapEnd + end;
          }
          else {
            // Append
            setChangedPolicyIDsToPoints(givenChangedPolicyIDsToPoints + "&" + givenIdPtMap);
            handlePolicyProfilePointChanges(givenChangedPolicyIDsToPoints + "&" + givenIdPtMap);
            return givenChangedPolicyIDsToPoints + "&" + givenIdPtMap;
          }
        }
      }
    }
    return givenChangedPolicyIDsToPoints;
  }

  const updateModifiedExtCertProfilePoint = (newVal: string, extCertData: ProfileExtCertToDisplay, givenChangedExtCertIDsToPoints: string) => {
    let latestInitialVals = getLatestValueOfInitialExtCerts();
    //console.log("latestInitialVals: " + latestInitialVals);
    let pIDToPointArrInitial = (!!latestInitialVals == false || latestInitialVals.trim() == "") ?
                                  [] : latestInitialVals.trim().split("&");
    pIDToPointArrInitial = (!!pIDToPointArrInitial !== true || pIDToPointArrInitial.length <= 0 || 
                            pIDToPointArrInitial.every(e => !!e === false || !e.trim().includes(":"))) ? 
                            [] : pIDToPointArrInitial;
    if (!!newVal && !!extCertData) {
      if (!isValidFloatString(newVal)) {
        return givenChangedExtCertIDsToPoints;
      }
      let givenIdMapStart = extCertData.extCertID.toString() + ":";
      let givenIdMapEnd = parseFloat(newVal).toFixed(2);// extCertData.assignedPoints.toFixed(2);
      let givenIdPtMap = givenIdMapStart + givenIdMapEnd;
      if (!!pIDToPointArrInitial && pIDToPointArrInitial.length > 0 && 
          pIDToPointArrInitial.some(e => !!e && e.trim() == givenIdPtMap)) {
        let temp = givenChangedExtCertIDsToPoints;
        let idx = temp.indexOf(givenIdMapStart);
        if (idx >= 0) {
          let start = temp.substring(0, idx);
          let idx2 = temp.indexOf("&", idx + givenIdMapStart.length);
          let end = idx2 <= 0 ? "" : temp.substring(idx2);
          // Replace different value
          setChangedExtCertIDsToPoints(start + end);
          handleExtCertProfilePointChanges(start + end);
          //console.log("start + end: " + start + end);
          return start + end;
        }
        return givenChangedExtCertIDsToPoints; // No change as the given value is exactly same as initial
      }
      if (!!givenChangedExtCertIDsToPoints == false || givenChangedExtCertIDsToPoints.trim() == "") { 
        // Create new
        setChangedExtCertIDsToPoints(givenIdPtMap);
        handleExtCertProfilePointChanges(givenIdPtMap);
        //console.log("givenIdPtMap1: " + givenIdPtMap);
        return givenIdPtMap;
      }
      else {
        let pIDToPointArr = givenChangedExtCertIDsToPoints.trim().split("&");
        if (!!pIDToPointArr !== true || pIDToPointArr.length <= 0 || 
            pIDToPointArr.every(e => !!e === false || !e.trim().includes(":"))) {
            // Create new
            setChangedExtCertIDsToPoints(givenIdPtMap);
            handleExtCertProfilePointChanges(givenIdPtMap);
            //console.log("givenIdPtMap2: " + givenIdPtMap);
            return givenIdPtMap;
        }
        if (pIDToPointArr.some(e => !!e && e.trim() == givenIdPtMap)) {
          //console.log("givenChangedExtCertIDsToPoints: " + givenChangedExtCertIDsToPoints);
          return givenChangedExtCertIDsToPoints; // Change is already there as same value
        }
        else if (!pIDToPointArr.some(e => !!e && e.trim().startsWith(givenIdMapStart))) {
          // Append
          setChangedExtCertIDsToPoints(givenChangedExtCertIDsToPoints + "&" + givenIdPtMap);
          handleExtCertProfilePointChanges(givenChangedExtCertIDsToPoints + "&" + givenIdPtMap);
          //console.log("givenChangedExtCertIDsToPoints + & + givenIdPtMap: " + givenChangedExtCertIDsToPoints + "&" + givenIdPtMap);
          return givenChangedExtCertIDsToPoints + "&" + givenIdPtMap;
        }
        else if (pIDToPointArr.some(e => !!e && e.trim().startsWith(givenIdMapStart))) {
          let temp = givenChangedExtCertIDsToPoints;
          //console.log("start + givenIdMapEnd + end temp.length: " + temp.length.toString());
          //console.log("start + givenIdMapEnd + end givenIdMapStart.length: " + givenIdMapStart.length.toString());
          //console.log("start + givenIdMapEnd + end givenIdMapEnd: " + givenIdMapStart);
          //console.log("start + givenIdMapEnd + end temp: " + temp);
          let idx = temp.indexOf(givenIdMapStart);
          //console.log("start + givenIdMapEnd + end idx: " + idx.toString());
          if (idx >= 0) {
            let start = temp.substring(0, idx + givenIdMapStart.length);
            //console.log("start + givenIdMapEnd + end start: " + start);
            let idx2 = temp.indexOf("&", idx + givenIdMapStart.length);
            //console.log("start + givenIdMapEnd + end idx2: " + idx2.toString());
            let end = idx2 <= 0 ? "" : temp.substring(idx2);
            //console.log("start + givenIdMapEnd + end end: " + end);
            // Replace different value
            setChangedExtCertIDsToPoints(start + givenIdMapEnd + end);
            handleExtCertProfilePointChanges(start + givenIdMapEnd + end);
            //console.log("start + givenIdMapEnd + end: " + start + givenIdMapEnd + end);
            return start + givenIdMapEnd + end;
          }
          else {
            // Append
            setChangedExtCertIDsToPoints(givenChangedExtCertIDsToPoints + "&" + givenIdPtMap);
            handleExtCertProfilePointChanges(givenChangedExtCertIDsToPoints + "&" + givenIdPtMap);
            //console.log("givenChangedExtCertIDsToPoints + & + givenIdPtMap 2: " + givenChangedExtCertIDsToPoints + "&" + givenIdPtMap);
            return givenChangedExtCertIDsToPoints + "&" + givenIdPtMap;
          }
        }
      }
    }
    //console.log("givenChangedExtCertIDsToPoints2: " + givenChangedExtCertIDsToPoints);
    return givenChangedExtCertIDsToPoints;
  }

  // Only the filtered data is selected
  const setModuleProfilePoints = (newVal: string, moduleData: ProfileModuleToDisplay, courseData: ProfileCourseToDisplay) => {

    let latestVals = getLatestValueOfModifiedModules();
    setChangedModuleIDsToPoints(latestVals);
    let retval = updateModifiedModuleProfilePoint(newVal, moduleData, courseData, latestVals);

    // setChangedModuleIDsToPoints((!!changedModuleIDsToPoints && changedModuleIDsToPoints.trim() != "") ? 
    //                                 changedModuleIDsToPoints.trim() + "&" + curPItem.policyID.toString() + ":" + curPItem.assignedPoints.toFixed(2).toString() :
    //                                           curPItem.policyID.toString() + ":" + curPItem.assignedPoints.toFixed(2).toString());
    const updatedCourseData = courseList.map((item: ProfileCourseToDisplay) => {
      if (!(!!item && (item.isSearchMatch || 
            (!!item.modules && item.modules.length > 0 && 
              item.modules.some((m : ProfileModuleToDisplay) => !!m && m.isSearchMatch))))) {
          return {
            ...item
          }
      }
      if (!!item && !!courseData && !!item.courseID && !!courseData.courseID && item.courseID == courseData.courseID) {
        let givenPoints = 0.0;
        try {
          givenPoints = !!newVal && newVal.trim() != "" ? parseFloat(newVal) : 0.0;
          givenPoints = Number.isNaN(givenPoints) ? 0.0 : givenPoints;
        }
        catch {
          givenPoints = 0.0;
        }
        moduleData.assignedPoints = givenPoints;
        moduleData.assignedPointsTxt = newVal;
        item.anyModulesAssingedPoints = (!!item.modules && item.modules.some((val) => val.assignedPoints > 0.0));
        item.allModulesAssingedPoints = (!!item.modules && item.modules.every((val) => val.assignedPoints > 0.0));
        item.totalAssignedPoints = !!item.modules ? item.modules.reduce((accumulator, curMod) => accumulator + (!!curMod && !!curMod.assignedPoints && curMod.assignedPoints >= 0.0 ? curMod.assignedPoints : 0.0), 0) : 0.0;
        item.modules = item.modules.slice(0).map((elem) => 
          {
            if (moduleData.moduleID === elem.moduleID) {
              return {
                ...elem,
                assignedPoints: moduleData.assignedPoints,
                assignedPointsTxt: moduleData.assignedPointsTxt
              }
            }
            return {
              ...elem
            }
          }
        );
        return {
          ...item,
          anyModulesAssingedPoints: (!!item.modules && item.modules.some((val) => val.assignedPoints > 0.0)),
          allModulesAssingedPoints: (!!item.modules && item.modules.every((val) => val.assignedPoints > 0.0)),
          totalAssignedPoints: !!item.modules ? item.modules.reduce((accumulator, curMod) => accumulator + (!!curMod && !!curMod.assignedPoints && curMod.assignedPoints >= 0.0 ? curMod.assignedPoints : 0.0), 0) : 0.0,
        }
      }
      return item; 
    });
    
    setTotalCourseModulePoints(calculateTotalCourseModulePoints(updatedCourseData));
    setCourseList([...updatedCourseData]);
  }

  // Only the filtered data is selected
  const setPolicyProfilePoints = (newVal: string, policyData: ProfilePolicyToDisplay) => {
    
    let latestVals = getLatestValueOfModifiedPolicies();
    setChangedPolicyIDsToPoints(latestVals);
    let changes = updateModifiedPolicyProfilePoint(newVal, policyData, latestVals);

    // setPolicyIDsToPointsInitial((!!policyIDsToPointsInitial && policyIDsToPointsInitial.trim() != "") ? 
    //                                           policyIDsToPointsInitial.trim() + "&" + curPItem.policyID.toString() + ":" + curPItem.assignedPoints.toFixed(2).toString() :
    //                                           curPItem.policyID.toString() + ":" + curPItem.assignedPoints.toFixed(2).toString());
    const updatedPolicyData = policyList.map((item: ProfilePolicyToDisplay) => {
      if (!(!!item && item.isSearchMatch)) {
          return {
            ...item
          }
      }
      if (!!item && !!policyData && !!item.policyID && !!policyData.policyID && item.policyID == policyData.policyID) {
        let givenPoints = 0.0;
        try {
          givenPoints = !!newVal && newVal.trim() != "" ? parseFloat(newVal) : 0.0;
          givenPoints = Number.isNaN(givenPoints) ? 0.0 : givenPoints;
        }
        catch {
          givenPoints = 0.0;
        }
        policyData.assignedPoints = givenPoints;
        policyData.assignedPointsTxt = newVal;
        return {
          ...item,
          assignedPoints: givenPoints,
          assignedPointsTxt: newVal,
        }
      }
      return item; 
    });
    
    setTotalPolicyPoints(calculateTotalPolicyPoints(updatedPolicyData));
    setPolicyList([...updatedPolicyData]);
  }

  // Only the filtered data is selected
  const setExtCertProfilePoints = (newVal: string, extCertData: ProfileExtCertToDisplay) => {
    
    let latestVals = getLatestValueOfModifiedExtCerts();
    setChangedExtCertIDsToPoints(latestVals);
    let changes = updateModifiedExtCertProfilePoint(newVal, extCertData, latestVals);

    // setExtCertIDsToPointsInitial((!!extCertIDsToPointsInitial && extCertIDsToPointsInitial.trim() != "") ? 
    //                                           extCertIDsToPointsInitial.trim() + "&" + curPItem.extCertID.toString() + ":" + curPItem.assignedPoints.toFixed(2).toString() :
    //                                           curPItem.extCertID.toString() + ":" + curPItem.assignedPoints.toFixed(2).toString());
    const updatedExtCertData = extCertList.map((item: ProfileExtCertToDisplay) => {
      if (!(!!item && item.isSearchMatch)) {
          return {
            ...item
          }
      }
      if (!!item && !!extCertData && !!item.extCertID && !!extCertData.extCertID && item.extCertID == extCertData.extCertID) {
        let givenPoints = 0.0;
        try {
          givenPoints = !!newVal && newVal.trim() != "" ? parseFloat(newVal) : 0.0;
          givenPoints = Number.isNaN(givenPoints) ? 0.0 : givenPoints;
        }
        catch {
          givenPoints = 0.0;
        }
        extCertData.assignedPoints = givenPoints;
        extCertData.assignedPointsTxt = newVal;
        return {
          ...item,
          assignedPoints: givenPoints,
          assignedPointsTxt: newVal,
        }
      }
      return item; 
    });
    
    setTotalExtCertPoints(calculateTotalExtCertPoints(updatedExtCertData));
    setExtCertList([...updatedExtCertData]);
  }

  // const setFilteredCourses = useCallback(async function (filterquery: string) {
  //   if (filterquery == null || filterquery == undefined || !!courseList !== true) {
  //     return;
  //   }

  //   console.log("T1 " + new Date().toISOString());
  //   const filteredCourseList = courseList.map((item: ProfileCourseToDisplay) => {
  //     if (!!item && !!item.courseID) {
  //       if ((!!filterquery !== true || 
  //         (!!filterquery && !!item.courseName && item.courseName.trim().toLowerCase().includes(filterquery.trim().toLowerCase())))) {
          
  //         return {
  //           ...item,
  //           isSearchMatch: true
  //         }
  //       }
  //       if (!!item.modules) {
  //         item.modules = item.modules.slice(0).map((elem) => {
  //           if ((!!filterquery !== true || 
  //             (!!filterquery && !!elem.moduleName && elem.moduleName.trim().toLowerCase().includes(filterquery.trim().toLowerCase())))) {
          
  //             return {
  //               ...elem,
  //               isSearchMatch: true
  //             }
  //           }
  //           else {
  //             return {
  //               ...elem,
  //               isSearchMatch: false
  //             }
  //           }
  //         });
  //       }
  //       return {
  //         ...item,
  //         isSearchMatch: false
  //       }
  //     }
  //     return item; 
  //   });

  //   setCourseList(filteredCourseList);
  //   //setCourseList([...filteredCourseList]);

  //   console.log("T2 " + new Date().toISOString());
  // }, []);

  // const setFilteredCoursesDebounce = useMemo(
  //   () => debounce(setFilteredCourses, 500),
  //   [setFilteredCourses],
  // );

  // const onInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   const newQuery = event.currentTarget.value;
  //   setFilterCourseOrModuleName(newQuery);
  //   setFilteredCoursesDebounce(newQuery);
  // }

  const handleAssignToTypeChange = (event:any) => {
    const val = (event.target as HTMLInputElement).value;
    setAssignToType(val);
    if (!!val && val.trim().toLowerCase().includes("excluding")) {
      handleExclusionOfCompletedUsers(true);
    }
    if (!!val && val.trim().toLowerCase().includes("including")) {
      handleExclusionOfCompletedUsers(false);
    }
  }

  useEffect(() => {
    if (isCPDAssignPointsGuidemeClicked) {
      if (!!guideMeClickTriggered && guideMeClickTriggered === true) 
      {
        if (!!courseList && courseList.length > 0) {
          setFilterCourseOrModuleNameCSV("");
          setFirstCourseExpansion(courseList[0].expanded);
          courseList[0].expanded = true;
        }
        setFilterPolicyNameCSV("");
        setFilterExtCertNameCSV("");
        // if (!!policyList && policyList.length > 0) {
        //   setFirstPolicyExpansion(policyList[0].expanded);
        //   policyList[0].expanded = true;
        // }
        // if (!!extCertList && extCertList.length > 0) {
        //   setFirstExtCertExpansion(extCertList[0].expanded);
        //   extCertList[0].expanded = true;
        // }
      }
    }
    else {
      if (!!courseList && courseList.length > 0) {
        courseList[0].expanded = firstCourseExpansion != null ? firstCourseExpansion : courseList[0].expanded;
      }
      // if (!!policyList && policyList.length > 0) {
      //   policyList[0].expanded = firstPolicyExpansion != null ? firstPolicyExpansion : policyList[0].expanded;
      // }
      // if (!!extCertList && extCertList.length > 0) {
      //   extCertList[0].expanded = firstExtCertExpansion != null ? firstExtCertExpansion : extCertList[0].expanded;
      // }
    }

  }, [isCPDAssignPointsGuidemeClicked, guideMeClickTriggered])

  useEffect(() => {
    if (filterCourseOrModuleNameCSV == null || filterCourseOrModuleNameCSV == undefined || !!courseList != true){
      return;
    }
    setCoursePointsLoading(true);
    //console.log("filterCourseOrModuleName " + filterCourseOrModuleName);
    //setFilteredCoursesOnly(courseList);
    
    const timeOutId = setTimeout(() => setFilteredCoursesOnly(courseList), 500);
    return () => clearTimeout(timeOutId);
  },[courseExpandOrCollapseChanged])

  useEffect(() => {
    if (filterCourseOrModuleNameCSV == null || filterCourseOrModuleNameCSV == undefined || !!courseList != true){
      return;
    }
    setCoursePointsLoading(true);
    //console.log("filterCourseOrModuleName " + filterCourseOrModuleName);
    //setFilteredCoursesOnly(courseList);
    
    const timeOutId = setTimeout(() => setFilteredCoursesOnly(courseList), 500);
    return () => clearTimeout(timeOutId);
  },[filterCourseOrModuleNameCSV])

  useEffect(() => {
      if (filterPolicyNameCSV == null || filterPolicyNameCSV == undefined || !!policyList != true){
      return;
    }
    setPolicyPointsLoading(true);
    //console.log("filterPolicyName " + filterPolicyName);
    //setFilteredPoliciesOnly(policyList);
    
    const timeOutId = setTimeout(() => setFilteredPoliciesOnly(policyList), 500);
    return () => clearTimeout(timeOutId);
  },[filterPolicyNameCSV])

  useEffect(() => {
      if (filterExtCertNameCSV == null || filterExtCertNameCSV == undefined || !!extCertList != true){
      return;
    }
    setExtCertPointsLoading(true);
    //console.log("filterExtCertName " + filterExtCertName);
    //setFilteredExtCertsOnly(extCertList);
    
    const timeOutId = setTimeout(() => setFilteredExtCertsOnly(extCertList), 500);
    return () => clearTimeout(timeOutId);
  },[filterExtCertNameCSV])

  useEffect(() => {
    //if (!isDataRead) {
      setLoading(true);
      setCoursePointsLoading(true);
      GetOrgCourseModuleProfilePoints(
          oidcUser.access_token,
          requestingUserId,
          orgDomain,
          parseInt(orgId), 
          profileID, 
          profilePeriodID).then((result: any) => {
              if (!!result && result.isSuccess && (result.error == null || result.error.trim() == "")) {
                  if (!!result.courseModuleProfilePoints && result.courseModuleProfilePoints.length > 0) {     
                    fillProfileCoursesForDisplay(result.courseModuleProfilePoints);
                  }
                  else if (!!result.courseModuleProfilePoints && result.courseModuleProfilePoints.length <= 0) {   
                    console.log("Organisation course module profile points list returned as empty");
                  }
                  else {
                    console.log("Organisation course module profile points list returned as invalid");
                  }
              } else {
                console.log("Failed to get organisation course module profile points list");
              }
              ////setLoading(false);
              setCoursePointsLoading(false);
              GetPolicyPoints();
              GetExtCertPoints();
          }).catch((error) => {
              console.log("Getting organisation course module profile points list failed with error");
              ////setLoading(false);
              setCoursePointsLoading(false);
              GetPolicyPoints();
              GetExtCertPoints();
          });
    //}
    return () => { };
  }, []);

  const handleExpandAllCourses = () => {
    if (!!courseList && courseList.length > 0) {
      courseList.map(c => { if (!!c && (c.isSearchMatch || (!!c.modules && !!filterCourseOrModuleNameCSV && 
        c.modules.some(m => !!m && !!(m as ProfileModuleToDisplay).moduleName && 
        //(m as ProfileModuleToDisplay).moduleName.toLowerCase().trim().includes(filterCourseOrModuleName.trim().toLowerCase())))) && (!showOnlyPointsAssigned || c.totalAssignedPoints > 0.0)) { c.expanded = true; } });                
        filterCourseOrModuleNameCSV.toLowerCase().split(",").
        some(f => !!f && (m as ProfileModuleToDisplay).moduleName.toLowerCase().trim().includes(f.toLowerCase().trim()))))) && (!showOnlyPointsAssigned || c.totalAssignedPoints > 0.0)) { c.expanded = true; } });        
    }
  }

  const toggleCommonPointsToCourseModules = () => {
    let flag = isCommonPointsToCourseModules;
    setIsCommonPointsToCourseModules(!flag);
    if (!flag) {
      applyCommonPointsToCourseModules(commonPointsOfCourseModules);
    }
  }

  const toggleCommonPointsToPolicies = () => {
    let flag = isCommonPointsToPolicies;
    setIsCommonPointsToPolicies(!flag);
    if (!flag) {
      applyCommonPointsToPolicies(commonPointsOfPolicies);
    }
  }

  const toggleCommonPointsToExtCerts = () => {
    let flag = isCommonPointsToExtCerts;
    setIsCommonPointsToExtCerts(!flag);
    if (!flag) {
      applyCommonPointsToExtCerts(commonPointsOfExtCerts);
    }
  }

  const applyCommonPointsToCourseModules = (givenCommonPointsOfCourseModules: string) => { 
    let latestVals = getLatestValueOfModifiedModules();
    setChangedModuleIDsToPoints(latestVals);   
    let changedModuleIDs = latestVals;
    if (isValidFloatString(givenCommonPointsOfCourseModules)) {
      let updatedVal = parseFloat(givenCommonPointsOfCourseModules);
      let updated = updatedVal.toFixed(2);
      setCommonPointsOfCourseModules(updated);

      if (!!courseList && courseList.length > 0) {
        courseList.map(c => { 
          if (
            !!c && 
            (c.isSearchMatch || (!!c.modules && !!filterCourseOrModuleNameCSV && 
              c.modules.some(m => !!m && !!(m as ProfileModuleToDisplay).moduleName && 
              //(m as ProfileModuleToDisplay).moduleName.toLowerCase().trim().includes(filterCourseOrModuleName.trim().toLowerCase())))) && (!showOnlyPointsAssigned || c.totalAssignedPoints > 0.0)) { c.expanded = true; } });                
              filterCourseOrModuleNameCSV.toLowerCase().split(",").
                some(f => !!f && (m as ProfileModuleToDisplay).moduleName.toLowerCase().
                trim().includes(f.toLowerCase().trim()))))) && 
            (!showOnlyPointsAssigned || c.totalAssignedPoints > 0.0)
            ) 
          { 
            c.modules.map(mCur => { 
              let mCurCast = (mCur as ProfileModuleToDisplay); 
              if (!!mCurCast) {
                mCurCast.assignedPoints = updatedVal;
                mCurCast.assignedPointsTxt = updated;
              } 
              return mCurCast;
            }); 
          } 
        });   
        for (let cidx = 0; cidx < courseList.length; cidx++) {
          let c = courseList[cidx];
          if (
            !!c && 
            (c.isSearchMatch || (!!c.modules && !!filterCourseOrModuleNameCSV && 
              c.modules.some(m => !!m && !!(m as ProfileModuleToDisplay).moduleName && 
              //(m as ProfileModuleToDisplay).moduleName.toLowerCase().trim().includes(filterCourseOrModuleName.trim().toLowerCase())))) && (!showOnlyPointsAssigned || c.totalAssignedPoints > 0.0)) { c.expanded = true; } });                
              filterCourseOrModuleNameCSV.toLowerCase().split(",").
                some(f => !!f && (m as ProfileModuleToDisplay).moduleName.toLowerCase().
                trim().includes(f.toLowerCase().trim()))))) && 
            (!showOnlyPointsAssigned || c.totalAssignedPoints > 0.0)
          ) {

            if (!!c.modules) {
              for (let midx = 0; midx < c.modules.length; midx++) {
                let mCurCast = c.modules[midx];
                if (!!mCurCast && !!(mCurCast as ProfileModuleToDisplay).moduleName && 
                    (c.isSearchMatch || filterCourseOrModuleNameCSV.toLowerCase().split(",").
                    some(f => !!f && (mCurCast as ProfileModuleToDisplay).moduleName.toLowerCase().
                    trim().includes(f.toLowerCase().trim())))) {
                    changedModuleIDs = updateModifiedModuleProfilePoint(updated, mCurCast, c, changedModuleIDs);
                }
              }
            }

            c.anyModulesAssingedPoints = (!!c.modules && c.modules.some((valm) => valm.assignedPoints > 0.0));
            c.allModulesAssingedPoints = (!!c.modules && c.modules.every((valm) => valm.assignedPoints > 0.0));
            c.totalAssignedPoints = !!c.modules ? 
              c.modules.reduce((accumulator, curMod) => 
                accumulator + (!!curMod && !!curMod.assignedPoints && curMod.assignedPoints >= 0.0 ? 
                    curMod.assignedPoints : 0.0), 0) : 0.0;
          }
        }
        setTotalCourseModulePoints(calculateTotalCourseModulePoints(courseList));   
      }
    }
  }

  const applyCommonPointsToPolicies = (givenCommonPointsOfPolicies: string) => {  
    let latestVals = getLatestValueOfModifiedPolicies();
    setChangedPolicyIDsToPoints(latestVals);  
    let changedPolicyIDs = latestVals;
    if (isValidFloatString(givenCommonPointsOfPolicies)) {
      let updatedVal = parseFloat(givenCommonPointsOfPolicies);
      let updated = updatedVal.toFixed(2);
      setCommonPointsOfPolicies(updated);

      if (!!policyList && policyList.length > 0) { 
        for (let pidx = 0; pidx < policyList.length; pidx++) {
          let p = policyList[pidx];
          if (
            !!p && 
            (p.isSearchMatch || (!!filterPolicyNameCSV && 
              filterPolicyNameCSV.toLowerCase().split(",").
                some(f => !!f && (p as ProfilePolicyToDisplay).policyName.toLowerCase().
                trim().includes(f.toLowerCase().trim())))) && 
            (!showOnlyPointsAssignedPolicy || p.assignedPoints > 0.0)
          ) {

            let pCurCast = (p as ProfilePolicyToDisplay); 
            if (!!pCurCast) {
              pCurCast.assignedPoints = updatedVal;
              pCurCast.assignedPointsTxt = updated;
              changedPolicyIDs = updateModifiedPolicyProfilePoint(updated, pCurCast, changedPolicyIDs);
            } 
          }
        }
        setTotalPolicyPoints(calculateTotalPolicyPoints(policyList));   
      }
    }
  }

  const applyCommonPointsToExtCerts = (givenCommonPointsOfExtCerts: string) => {   
    let latestVals = getLatestValueOfModifiedExtCerts();
    setChangedExtCertIDsToPoints(latestVals); 
    let changedExtCertIDs = latestVals;
    if (isValidFloatString(givenCommonPointsOfExtCerts)) {
      let updatedVal = parseFloat(givenCommonPointsOfExtCerts);
      let updated = updatedVal.toFixed(2);
      setCommonPointsOfExtCerts(updated);

      if (!!extCertList && extCertList.length > 0) { 
        for (let pidx = 0; pidx < extCertList.length; pidx++) {
          let p = extCertList[pidx];
          if (
            !!p && p.isCPDEnabled &&
            (p.isSearchMatch || (!!filterExtCertNameCSV && 
              filterExtCertNameCSV.toLowerCase().split(",").
                some(f => !!f && (p as ProfileExtCertToDisplay).extCertName.toLowerCase().
                trim().includes(f.toLowerCase().trim())))) && 
            (!showOnlyPointsAssignedExtCert || p.assignedPoints > 0.0)
          ) {

            let pCurCast = (p as ProfileExtCertToDisplay); 
            if (!!pCurCast) {
              pCurCast.assignedPoints = updatedVal;
              pCurCast.assignedPointsTxt = updated;
              changedExtCertIDs = updateModifiedExtCertProfilePoint(updated, pCurCast, changedExtCertIDs);
            } 
          }
        }
        setTotalExtCertPoints(calculateTotalExtCertPoints(extCertList));   
      }
    }  
  }

  const handleChangeOfCommonPointsOfCourseModules = (val: string) => {
    setCommonPointsOfCourseModules(val);    
    if (isCommonPointsToCourseModules)   {
      applyCommonPointsToCourseModules(val);
    }
  }

  const handleChangeOfCommonPointsOfPolicies = (val: string) => {
    setCommonPointsOfPolicies(val);    
    if (isCommonPointsToPolicies)   {
      applyCommonPointsToPolicies(val);
    }
  }

  const handleChangeOfCommonPointsOfExtCerts = (val: string) => {
    setCommonPointsOfExtCerts(val);    
    if (isCommonPointsToExtCerts)   {
      applyCommonPointsToExtCerts(val);
    }
  }

  const handleCollapseAllCourses = () => {
    if (!!courseList && courseList.length > 0) {
      courseList.map(c => { if (!!c && (c.isSearchMatch || (!!c.modules && !!filterCourseOrModuleNameCSV && 
        c.modules.some(m => !!m && !!(m as ProfileModuleToDisplay).moduleName && 
        //(m as ProfileModuleToDisplay).moduleName.toLowerCase().trim().includes(filterCourseOrModuleName.trim().toLowerCase())))) && (!showOnlyPointsAssigned || c.totalAssignedPoints > 0.0)) { c.expanded = false; } });        
        filterCourseOrModuleNameCSV.toLowerCase().split(",").
        some(f => !!f && (m as ProfileModuleToDisplay).moduleName.toLowerCase().trim().includes(f.toLowerCase().trim()))))) && (!showOnlyPointsAssigned || c.totalAssignedPoints > 0.0)) { c.expanded = false; } });        
    }
  }

  const GetPolicyPoints = () => {
    //if (!isDataRead) {
      setPolicyPointsLoading(true);
      GetOrgPolicyProfilePoints(
        oidcUser.access_token,
        requestingUserId,
        orgDomain,
        parseInt(orgId), 
        -1, 
        profileID, 
        profilePeriodID).then((result: any) => {
            if (!!result && result.isSuccess && (result.error == null || result.error.trim() == "")) {
                if (!!result.policyProfilePoints && result.policyProfilePoints.length > 0) {     
                  fillProfilePoliciesForDisplay(result.policyProfilePoints);
                }
                else if (!!result.policyProfilePoints && result.policyProfilePoints.length <= 0) {   
                  console.log("Organisation policy profile points list returned as empty");
                }
                else {
                  console.log("Organisation policy profile points list returned as invalid");
                }
            } else {
              console.log("Failed to get organisation policy profile points list");
            }
            setLoading(false);
            setPolicyPointsLoading(false);
            setDataRead(true);
            setPointsLoaded(true);
        }).catch((error: any) => {
            console.log("Getting organisation policy profile points list failed with error");
            setLoading(false);
            setPolicyPointsLoading(false);
            setPointsLoaded(true);
        });
    //}
  }

  const GetExtCertPoints = () => {
    //if (!isDataRead) {
      setExtCertPointsLoading(true);
      GetOrgExtCertProfilePoints(
        oidcUser.access_token,
        requestingUserId,
        orgDomain,
        parseInt(orgId), 
        -1, 
        profileID, 
        profilePeriodID).then((result: any) => {
            if (!!result && result.isSuccess && (result.error == null || result.error.trim() == "")) {
                if (!!result.extCertProfilePoints && result.extCertProfilePoints.length > 0) {     
                  fillProfileExtCertificatesForDisplay(result.extCertProfilePoints);
                }
                else if (!!result.extCertProfilePoints && result.extCertProfilePoints.length <= 0) {   
                  console.log("Organisation external-certificate profile points list returned as empty");
                }
                else {
                  console.log("Organisation external-certificate profile points list returned as invalid");
                }
            } else {
              console.log("Failed to get organisation external-certificate profile points list");
            }
            setLoading(false);
            setExtCertPointsLoading(false);
            setDataRead(true);
            setPointsLoaded(true);
        }).catch((error: any) => {
            console.log("Getting organisation external-certificate profile points list failed with error");
            setLoading(false);
            setExtCertPointsLoading(false);
            setPointsLoaded(true);
        });
    //}
  }

  if (loading) return <LoaderContainer />;
  return (
    <>
      <BrandedCard>
        <Accordion key={"accordianCourseModuleAccordian"} expanded={courseModuleCardExpanded} aria-expanded={courseModuleCardExpanded}
                  style={{ margin: "0px 0px", borderRadius: "6px 6px 6px 6px", border: '1px solid #D3D6DF', position: 'inherit', boxShadow: "none", backgroundColor: "#FFFFFF" }} 
                  // style={{backgroundColor: "#FFFFFF"}}
                  onChange={(event, isexpanded) => { onAccordianChange(event, isexpanded); }}
                  >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            //className={course.expanded ? "MuiButtonBase-root MuiAccordionSummary-root Mui-expanded" : "MuiButtonBase-root MuiAccordionSummary-root"}
            aria-controls="panel1a-content" 
            id={"summaryCourseModuleAccordian panel1a-header"}
            key={"summaryCourseModuleAccordian panel1a-header"}
            onClick={(e) => setCourseModuleCardExpanded(!courseModuleCardExpanded)}           
          >
            <Typography key={"typoCourseModuleAccordian"}>
              <div id="courseModuleAssignPointsToLessonQuiz" style={{ justifyContent: "space-between", padding: "15px 0px 20px 20px", marginLeft: "-15px"}} key={"0mainDivOfProfilePoints"}>
                <div style={{display: "grid"}} key={"pt5mainDivOfProfilePoints"}>
                  <span key="pt75mainDivOfProfilePoints" style={{fontWeight: "bold", fontSize: "15px", marginTop: "10px", fontFamily: "'Open Sans', 'Segoe UI', sans-serif"}}>
                    {allResourcesToTranslations.coursemoduleprofilepoints}
                    {
                      coursePointsLoading ?     
                          <span hidden={courseModuleCardExpanded}><CircularProgress key={"coursePointsLoading2"} hidden={courseModuleCardExpanded}
                              style={{ width: coursePointsLoading ? "20px" : "0px" }}
                              color="primary"
                              size="10"
                          /></span>
                      :
                      <></>
                    } 
                    <span style={{marginLeft:'50px', textAlign: "center"}}></span>
                  </span>
                </div>
              </div>
            </Typography>
          </AccordionSummary> 
          <>
            <div style={{ marginTop: "-15px", justifyContent: "space-between", padding: "20px 0px 20px 20px"}} key={"1mainDivOfProfilePoints"}>
              <div style={{display: "grid"}} key={"2mainDivOfProfilePoints"}>
                {/* <span key="3mainDivOfProfilePoints" style={{fontWeight: "bold", fontSize: "15px", marginTop: "10px"}}>{allResourcesToTranslations.coursemoduleprofilepoints} </span>
                <br/> */}
                <div id="courseModuleAssignPointsToLessonQuizOnly" style={{marginTop: "-15px", fontWeight: "bold", fontSize: '14px', paddingTop: "3px", paddingBottom: "3px"}}>
                  <span className={styles.labelTextitalic} style={{fontSize: '14px', fontWeight: "bold"}}>{allResourcesToTranslations.assignbuttonspanelcpdpointaccrualrequirement}</span>
                  {/* <div className={`${styles.inactiveContainer} profilequiz`} >                           
                      <Checkbox
                          disabled={true}
                          size="small"
                          color="primary"
                          checked={applyToQuizValue}
                          className={styles.checkBox}
                          onClick={(e) => { handleApplyToQuiz(!applyToQuizValue); e.stopPropagation(); }}
                      /> {allResourcesToTranslations.quiz}                             
                  </div>
                  <div className={`${styles.inactiveContainer} profilelesson`} >                           
                      <Checkbox
                          disabled={true}
                          size="small"
                          color="primary"
                          checked={applyToLessonValue}
                          className={styles.checkBox}
                          onClick={(e) => { handleApplyToLesson(!applyToLessonValue); e.stopPropagation(); } }
                      /> {allResourcesToTranslations.lesson}                             
                  </div> */}
                </div>
                <br />
                <div id="showOnlyPointsAssignedCoursesWithLabel" style={{marginTop: "-10px", marginLeft: "-10px"}}>                    
                  <span >
                    <Checkbox disabled={false} id="showOnlyPointsAssignedCourses"
                            size="small"
                            color="primary"
                            checked={showOnlyPointsAssigned}
                            className={styles.checkBox}
                            onClick={(e) => { setIsCommonPointsToCourseModules(false); setShowOnlyPointsAssigned(!showOnlyPointsAssigned); e.stopPropagation(); }}
                        /> 
                    <span onClick={(e) => { setIsCommonPointsToCourseModules(false); setShowOnlyPointsAssigned(!showOnlyPointsAssigned); e.stopPropagation(); }}
                          style={{fontSize: '14px'}}>{allResourcesToTranslations.showonlypointsassigned}</span>   
                  </span>
                </div>
                <br />
                <div id="applyCommonPointsToFilteredCMList" style={{marginTop: "-10px", marginLeft: "-10px"}}>                    
                  <span >
                    <Checkbox disabled={false} id="applyCommonPointsToFilteredListCheck"
                            size="small"
                            color="primary"
                            checked={isCommonPointsToCourseModules}
                            className={styles.checkBox}
                            onClick={(e) => { toggleCommonPointsToCourseModules(); e.stopPropagation(); }}
                        /> 
                    <span id="applyCommonPointsToFilteredListLabel1" style={{fontSize: '14px'}}
                          onClick={(e) => { toggleCommonPointsToCourseModules(); e.stopPropagation(); }}>
                            {allResourcesToTranslations.assignbuttonspanelpointsapplycommontofcmods1 + ": "}</span>  

                    <TextField style={{width: commonPointsOfCourseModules.length <= 4 ? "30px" : "60px", fontSize: '14px', marginTop: '3px'}} 
                        key="applyCommonPointsToFilteredListValue" id="applyCommonPointsToFilteredListValue" 
                        InputLabelProps={{shrink: true,}}                      
                        value={commonPointsOfCourseModules} onChange={(e) => { handleChangeOfCommonPointsOfCourseModules(e.target.value); e.stopPropagation(); }}/>  

                    <span id="applyCommonPointsToFilteredListLabel2" style={{fontSize: '14px'}}
                          onClick={(e) => { toggleCommonPointsToCourseModules(); e.stopPropagation(); }}>
                            {allResourcesToTranslations.assignbuttonspanelpointsapplycommontofcmods2}</span>
                  </span>
                </div>
                <br />
                <div id="courseModuleAssignPointsSearch" key="4mainDivOfProfilePoints" className={styles.search_longtextbox_and_buttons_container}>  
                    <div key="5mainDivOfProfilePoints" id="courseModuleNameSearch" className={styles.courseModuleNameSearch}>
                      <span id="courseModuleAssignPointsSearchTextField">
                        <TextField style={{width: "80%"}} key="6mainDivOfProfilePoints" 
                          label={"Course/Module Name Search"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                          value={filterCourseOrModuleNameCSV} 
                          onChange={(e) => { setIsCommonPointsToCourseModules(false); setFilterCourseOrModuleNameCSV(e.target.value); }} 
                          //value={filterCourseOrModuleName} onChange={onInputChange} 
                          InputProps={{endAdornment: ( <IconButton><SearchIcon style={{height: "20px"}} /></IconButton> )}}/>   
                      </span>                     
                      <span style={{marginLeft:'10px', width: "10%", textAlign: "center"}} id="expandAllCourseListAssignPointsspan" >  
                        <CustomTooltip displayText={["Expand All"]}>
                          {/* <Button className={`${styles.button} expandallunit`} size="small" onClick={(e) => { handleExpandAllCourses(); e.stopPropagation(); setCourseExpandOrCollapseChanged(!courseExpandOrCollapseChanged); }}> */}
                          <Button variant="contained" color="primary" id="expandAllCourseListAssignPoints" className="save" onClick={(e) => { handleExpandAllCourses(); e.stopPropagation(); setCourseExpandOrCollapseChanged(!courseExpandOrCollapseChanged); }}>
                                <ExpandAllIcon />
                                {/* <span className={styles.unitButtonSpanStyle}>{"Expand All" }</span> */}
                          </Button>
                        </CustomTooltip>
                      </span>
                      <span style={{marginLeft:'10px', width: "10%", textAlign: "center"}} id="collapseAllCourseListAssignPointsspan" >
                        <CustomTooltip displayText={["Collapse All"]}>
                          {/* <Button className={`${styles.button} collapseallunit`} size="small" onClick={(e) => { handleCollapseAllCourses(); e.stopPropagation(); setCourseExpandOrCollapseChanged(!courseExpandOrCollapseChanged); }}> */}
                          <Button variant="contained" color="primary" id="collapseAllCourseListAssignPoints" className="save" onClick={(e) => { handleCollapseAllCourses(); e.stopPropagation(); setCourseExpandOrCollapseChanged(!courseExpandOrCollapseChanged); }}>
                                <CollapseAllIcon />
                                {/* <span className={styles.unitButtonSpanStyle}>{"Collapse All"}</span> */}
                          </Button> 
                        </CustomTooltip>
                      </span>
                    </div>
                  <div style={{clear: "both"}} key="7mainDivOfProfilePoints" ></div>
                </div>
              </div>
            </div>

            {
              coursePointsLoading ?               
              <div className={styles.loaderContainer} hidden={!courseModuleCardExpanded}>
                  <span hidden={!courseModuleCardExpanded}><CircularProgress key={"coursePointsLoading"}
                      style={{ width: coursePointsLoading ? "20px" : "0px", padding: "15px 10px" }}
                      color="primary"
                      size="10"
                  /></span>
              </div>
              :
              <div key="8mainDivOfProfilePoints" id={"orgCourseModulePointsAssignment"} style={{overflowY:"scroll", scrollbarWidth:"auto", maxHeight: "400px", borderTop: "3px solid #D3D6DF" }}>
                {courseList.length > 0 && courseList.map((course, cindex) => {
                  return (!!course && ((!showOnlyPointsAssigned || (showOnlyPointsAssigned && course.totalAssignedPoints > 0.0)) && (course.isSearchMatch || 
                                        (!!course.modules && course.modules.length > 0 && course.modules.some((m) => !!m && m.isSearchMatch))))) ? 
                  (
                    <Accordion key={course.courseID.toString() + course.courseName.toString()} expanded={course.expanded} aria-expanded={course.expanded}
                              style={ course.expanded ? 
                              {backgroundColor: "#E0E0E0", marginTop: ((cindex > 0 && courseList.length > cindex) ? (courseList[cindex-1].expanded ? "-9px" : "-1px") : "-5px"), marginBottom: ((cindex >= 0 && courseList.length > (cindex+1)) ? (courseList[cindex+1].expanded ? "12px" : "2px") : "12px")} : {backgroundColor: "#FFFFFF"}}
                              onChange={(event, isexpanded) => { onAccordianChange(event, isexpanded); }}
                              >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        //className={course.expanded ? "MuiButtonBase-root MuiAccordionSummary-root Mui-expanded" : "MuiButtonBase-root MuiAccordionSummary-root"}
                        aria-controls="panel1a-content" 
                        id={course.courseID.toString() + "panel1a-header"}
                        key={course.courseID.toString() + "panel1a-header"}
                        onClick={(e) => handleCourseExpandClick(e, course)}           
                      >
                        {/* <Checkbox size="small" id={"profileCourseSelectionCheckbox" + course.courseID.toString()} 
                                  key={course.courseID.toString() + "profileCourseSelectionCheckbox" }
                                  checked={course.totalAssignedPoints > 0.0}
                                  indeterminate={!!course && course.allModulesAssingedPoints == false && course.anyModulesAssingedPoints == true}
                                  //onClick={(e) => { e.stopPropagation(); handleCourseSelect(course); }}
                                  onClick={(e) => {}}
                                  //disabled={true}
                                  color="primary" style={{marginTop: "-5px"}}/> */}
                        <Typography id={cindex == 0 ? "firstCourseRowOfAssignPoints" : (course.courseID.toString() + "profileCourseSelectionTypo")} style={(course.anyModulesAssingedPoints == true) ? {marginTop:'6px', fontWeight: "bold", fontSize: "15px", fontFamily: "'Open Sans', 'Segoe UI', sans-serif"} : {marginTop:'6px', fontSize: "15px", fontFamily: "'Open Sans', 'Segoe UI', sans-serif"}} 
                                  key={course.courseID.toString() + "profileCourseSelectionTypo"}>
                          {course.courseName}</Typography>
                          {
                            course.totalAssignedPoints > 0.0 && 
                            <span style={{right: "40px", position: "absolute", marginTop: "7px", marginLeft: "30px", fontWeight: "bold", fontFamily: "'Open Sans', 'Segoe UI', sans-serif"}}
                            key={course.courseID.toString() + "profileCourseSelectionSpan1"}>{course.totalAssignedPoints.toString()}</span>
                          }
                          {
                            course.totalAssignedPoints <= 0.0 && 
                            <span style={{right: "40px", position: "absolute", marginTop: "7px", marginLeft: "30px", fontFamily: "'Open Sans', 'Segoe UI', sans-serif"}}
                            key={course.courseID.toString() + "profileCourseSelectionSpan2"}>{course.totalAssignedPoints.toString()}</span>
                          }                  
                      </AccordionSummary>  
                      {              
                        course.modules.length > 0 && course.modules.map((module, mindex) => {
                          return (
                            <div id={(cindex == 0 && mindex == 0) ? "firstModuleOfFirstCourseRowOfAssignPoints" : module.moduleIDTxt + "fragmet" + module.moduleName} key={module.moduleIDTxt + "fragmet" + module.moduleName}>
                              <span key={module.moduleIDTxt + "span" + module.moduleName} 
                                    style={{display: "inline"}}>
                                  <div style={{display: "flex", width: "100%", marginLeft: "15px"}} 
                                      key={module.moduleIDTxt + "div1" + module.moduleName} >
                                    <div style={{width: "2%"}} 
                                      key={module.moduleIDTxt + "div2" + module.moduleName} >
                                    </div>
                                    <div style={{width: "90%"}} 
                                      key={module.moduleIDTxt + "div3" + module.moduleName} >
                                      {/* <Checkbox size="small" id={"profileModulePointsCheck" + module.moduleIDTxt}  
                                                key={module.moduleIDTxt + "profileModulePointsCheck"}
                                                checked={module.assignedPoints > 0.0}
                                                onClick={(e) => {}}
                                                //onClick={() => handleModuleSelect(module, course)}
                                                //disabled={true}
                                                color="primary"/> */}
                                      <span style={{fontWeight: module.assignedPoints > 0.0 ? "bold" : "normal", fontSize: "14px", fontFamily: "'Open Sans', 'Segoe UI', sans-serif"}} 
                                            key={module.moduleIDTxt + "div4" + module.moduleName} >
                                        {module.moduleName}</span>
                                    </div>
                                    {/* <CustomTooltip displayText={[allResourcesToTranslations.profilepointsedit]}> style={{right: "40px", position: "absolute", width: "5%", height: "1.7em", background: "white", outlineStyle: "height: 1.7em, background: white"}}*/}
                                    <div style={{width: "5%", minWidth: "55px"}} 
                                          key={module.moduleIDTxt + "div5" + module.moduleName} >
                                      <TextField id={"profileModulePointsText" + module.moduleIDTxt} 
                                        key={module.moduleIDTxt + "profileModulePointsText"}
                                        className={styles.inputmidsize} variant="outlined"
                                        style={{ padding: "5px" }} InputLabelProps={{shrink: true,}}
                                        value={module.assignedPointsTxt} onChange={(e) => setModuleProfilePoints(e.target.value, module, course)} />
                                    </div>
                                    <div style={{width: "3%"}} 
                                        key={module.moduleIDTxt + "div6" + module.moduleName} >
                                    </div>                              
                                    {/* </CustomTooltip> */}
                                  </div>
                              </span> 
                            </div>
                          )
                        })
                      }              
                  </Accordion>
                  ) :
                  (<div key={cindex.toString() + "9mainDivOfProfilePoints"} ></div>)
                })}
                
              </div>
            }
          </>
        </Accordion>
      </BrandedCard>
      <br/>
      <div style={{display: "normal", width: "100%", marginLeft: "16px"}} key={"mainDiv1CourseModuleTotalPoints"}  >
        <span id="totalPointsOfCourseModulesOfAssignPoints" key="mainSpanCourseModuleTotalPoints" style={{fontWeight: "bold", fontSize: "15px", paddingRight: "20px", float: "right"}}>{allResourcesToTranslations.coursemoduletotalprofilepoints2 + ": " + totalPointsOfCourseModules.toFixed(2) + " " +  allResourcesToTranslations.points} </span>        
      </div>
      <br/>
      <br/>
      <BrandedCard>
        <Accordion key={"accordianPolicyAccordian"} expanded={policyCardExpanded} aria-expanded={policyCardExpanded}
                  style={{ margin: "0px 0px", borderRadius: "6px 6px 6px 6px", border: '1px solid #D3D6DF', position: 'inherit', boxShadow: "none", backgroundColor: "#FFFFFF" }} 
                  // style={{backgroundColor: "#FFFFFF"}}
                  onChange={(event, isexpanded) => { onAccordianChange(event, isexpanded);}}
                  >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            //className={course.expanded ? "MuiButtonBase-root MuiAccordionSummary-root Mui-expanded" : "MuiButtonBase-root MuiAccordionSummary-root"}
            aria-controls="panel1a-content" 
            id={"summaryPolicyAccordian panel1a-header"}
            key={"summaryPolicyAccordian panel1a-header"}
            onClick={(e) => setPolicyCardExpanded(!policyCardExpanded)}           
          >
            <Typography key={"typoPolicyAccordian"}>
              <div style={{ justifyContent: "space-between", padding: "15px 0px 20px 20px", marginLeft: "-15px"}} key={"pol0mainDivOfProfilePoints"}>
                <div style={{display: "grid"}} key={"polpt5mainDivOfProfilePoints"}>
                  <span key="polpt75mainDivOfProfilePoints" style={{fontWeight: "bold", fontSize: "15px", marginTop: "10px"}}>
                    {allResourcesToTranslations.policyprofilepoints} 
                    {
                      policyPointsLoading ?     
                          <span hidden={policyCardExpanded}><CircularProgress key={"coursePointsLoading2"} hidden={policyCardExpanded}
                              style={{ width: policyPointsLoading ? "20px" : "0px" }}
                              color="primary"
                              size="10"
                          /></span>
                      :
                      <></>
                    }
                  </span>                  
                </div>
              </div>
            </Typography>
          </AccordionSummary> 
          <>
            <div id="policiesAssignPointsWhenAcceptedOnly" style={{marginTop: "-15px", marginLeft: "18px", fontWeight: "bold", fontSize: '14px', paddingTop: "3px", paddingBottom: "3px"}}>
              <span className={styles.labelTextitalic} style={{fontSize: '14px', fontWeight: "bold"}}>{allResourcesToTranslations.assignbuttonspanelcpdpointaccrualrequirementpol}</span>              
            </div>
            <br />
            <div id="showOnlyPointsAssignedPoliciesWithLabel" style={{marginTop: "-10px", marginLeft: "10px"}}>                    
              <span >
                <Checkbox disabled={false} id="showOnlyPointsAssignedPolicies"
                      size="small"
                      color="primary"
                      checked={showOnlyPointsAssignedPolicy}
                      className={styles.checkBox}
                      onClick={(e) => { setIsCommonPointsToPolicies(false); setShowOnlyPointsAssignedPolicy(!showOnlyPointsAssignedPolicy); e.stopPropagation(); }}
                  /> 
                <span onClick={(e) => { setIsCommonPointsToPolicies(false); setShowOnlyPointsAssignedPolicy(!showOnlyPointsAssignedPolicy); e.stopPropagation(); }}
                      style={{fontSize: '14px'}}>{allResourcesToTranslations.showonlypointsassignedpolicy}</span>  
              </span>
            </div>
            <br />
            <div id="applyCommonPointsToFilteredPList" style={{marginTop: "-10px", marginLeft: "10px"}}>                    
              <span >
                <Checkbox disabled={false} id="applyCommonPointsToFilteredPListCheck"
                        size="small"
                        color="primary"
                        checked={isCommonPointsToPolicies}
                        className={styles.checkBox}
                        onClick={(e) => { toggleCommonPointsToPolicies(); e.stopPropagation(); }}
                    /> 
                <span id="applyCommonPointsToFilteredPListLabel1" style={{fontSize: '14px'}}
                      onClick={(e) => { toggleCommonPointsToPolicies(); e.stopPropagation(); }}>
                        {allResourcesToTranslations.assignbuttonspanelpointsapplycommontofcmods1 + ": "}</span>  

                <TextField style={{width: commonPointsOfPolicies.length <= 4 ? "30px" : "60px", fontSize: '14px', marginTop: '3px'}} 
                    key="applyCommonPointsToFilteredPListValue" id="applyCommonPointsToFilteredPListValue" 
                    InputLabelProps={{shrink: true,}}                      
                    value={commonPointsOfPolicies} onChange={(e) => { handleChangeOfCommonPointsOfPolicies(e.target.value); e.stopPropagation(); }}/>  

                <span id="applyCommonPointsToFilteredPListLabel2" style={{fontSize: '14px'}}
                      onClick={(e) => { toggleCommonPointsToPolicies(); e.stopPropagation(); }}>
                        {allResourcesToTranslations.assignbuttonspanelpointsapplycommontofpols2}</span>
              </span>
            </div>
            <br />
            <div id="policyAssignPointsSearch" style={{ marginTop: "-15px", justifyContent: "space-between", padding: "20px 0px 20px 20px"}} key={"p1mainDivOfProfilePoints"}>
              <div style={{display: "grid"}} key={"p2mainDivOfProfilePoints"}>
                <div key="p4mainDivOfProfilePoints" className={styles.search_longtextbox_and_buttons_container}>  
                    <div key="p5mainDivOfProfilePoints" id="policyNameSearch" className={styles.courseModuleNameSearch}>
                      <TextField style={{width: "100%"}} key="p6mainDivOfProfilePoints" 
                        label={"Policy Name Search"} variant="outlined" autoComplete="off" InputLabelProps={{shrink: true,}}                      
                        value={filterPolicyNameCSV} onChange={(e) => { setIsCommonPointsToPolicies(false); setFilterPolicyNameCSV(e.target.value); }} 
                        InputProps={{endAdornment: ( <IconButton><SearchIcon style={{height: "20px"}} /></IconButton> )}}/>
                    </div>
                  <div style={{clear: "both"}} key="p7mainDivOfProfilePoints" ></div>
                </div>
              </div>
            </div>

            {
              policyPointsLoading ?               
                <div className={styles.loaderContainer} hidden={!policyCardExpanded}>
                    <span hidden={!policyCardExpanded}><CircularProgress key={"policyPointsLoading"}
                        style={{ width: policyPointsLoading ? "20px" : "0px", padding: "15px 10px" }}
                        color="primary"
                        size="10"
                    /></span>
                </div>
                :
                <div key="p8mainDivOfProfilePoints" id={"orgPolicyPointsAssignment"} 
                      style={{overflowY:"scroll", scrollbarWidth:"auto", maxHeight: "400px", borderTop: "3px solid #D3D6DF" }}>
                  {policyList.length > 0 && policyList.map((policy, pindex) => {
                    return (!!policy && policy.isSearchMatch && (!showOnlyPointsAssignedPolicy || policy.assignedPoints > 0.0)) ? 
                    (
                      <div id={pindex == 0 ? "firstPolicyRowOfAssignPoints" : "p" + policy.policyIDTxt + "fragmet" + policy.policyName} key={"p" + policy.policyIDTxt + "fragmet" + policy.policyName}>
                        <span key={"p" + policy.policyIDTxt + "span" + policy.policyName} 
                              style={{display: "inline"}}>
                            <div style={{display: "flex", width: "100%", marginLeft: "16px"}} 
                                key={"p" + policy.policyIDTxt + "div1" + policy.policyName} >
                              <div style={{width: "92%"}} 
                                key={"p" + policy.policyIDTxt + "div3" + policy.policyName} >
                                {/* <Checkbox size="small" id={"profilePolicySelectionCheckbox" + policy.policyIDTxt}  
                                          key={"p" + policy.policyIDTxt + "profilePolicySelectionCheckbox"}
                                          checked={policy.assignedPoints > 0.0}
                                          onClick={(e) => {}}
                                          color="primary"/> */}
                                <span style={{fontWeight: (policy.assignedPoints > 0.0 ? "bold" : "normal"), fontSize: "14px", fontFamily: "'Open Sans', 'Segoe UI', sans-serif"}} 
                                      key={"p" + policy.policyIDTxt + "div4" + policy.policyName} >
                                  {policy.policyName}</span>
                              </div>
                              <div style={{width: "5%", minWidth: "55px"}} 
                                    key={"p" + policy.policyIDTxt + "div5" + policy.policyName} >
                                <TextField id={"profilePolicyPointsText" + policy.policyIDTxt} 
                                  key={"p" + policy.policyIDTxt + "profilePolicyPointsText"}
                                  className={styles.inputmidsize} variant="outlined"
                                  style={{ padding: "5px" }} InputLabelProps={{shrink: true,}}
                                  value={policy.assignedPointsTxt} onChange={(e) => setPolicyProfilePoints(e.target.value, policy)} />
                              </div>
                              <div style={{width: "3%"}} 
                                  key={"p" + policy.policyIDTxt + "div6" + policy.policyName} >
                              </div>  
                            </div>
                        </span> 
                      </div>
                    ) :
                    (<div key={"p" + pindex.toString() + "9mainDivOfProfilePoints"} ></div>)
                  })}
                  
                </div>
            }
          </>
        </Accordion>
      </BrandedCard>
      <br/>
      <div  style={{display: "normal", width: "100%", marginLeft: "16px"}} key={"mainDiv1PolicyTotalPoints"} >
        <span id="totalPointsOfPoliciesOfAssignPoints" key="mainSpanPolicyTotalPoints" style={{fontWeight: "bold", fontSize: "15px", paddingRight: "20px", float: "right"}}>{allResourcesToTranslations.policytotalprofilepoints2 + ": " + totalPointsOfPolicies.toFixed(2) + " " +  allResourcesToTranslations.points} </span>        
      </div>
      <br/>
      <br/>
      <BrandedCard>
        <Accordion key={"accordianExtCertAccordian"} expanded={extCertCardExpanded} aria-expanded={extCertCardExpanded}
                  style={{ margin: "0px 0px", borderRadius: "6px 6px 6px 6px", border: '1px solid #D3D6DF', position: 'inherit', boxShadow: "none", backgroundColor: "#FFFFFF" }} 
                  // style={{backgroundColor: "#FFFFFF"}}
                  onChange={(event, isexpanded) => { onAccordianChange(event, isexpanded);}}
                  >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            //className={course.expanded ? "MuiButtonBase-root MuiAccordionSummary-root Mui-expanded" : "MuiButtonBase-root MuiAccordionSummary-root"}
            aria-controls="panel1a-content" 
            id={"summaryExtCertAccordian panel1a-header"}
            key={"summaryExtCertAccordian panel1a-header"}
            onClick={(e) => setExtCertCardExpanded(!extCertCardExpanded)}           
          >
            <Typography key={"typoExtCertAccordian"}>
              <div style={{ justifyContent: "space-between", padding: "15px 0px 20px 20px", marginLeft: "-15px"}} key={"pol0mainDivOfProfilePoints"}>
                <div style={{display: "grid"}} key={"polpt5mainDivOfProfilePoints"}>
                  <span key="polpt75mainDivOfProfilePoints" style={{fontWeight: "bold", fontSize: "15px", marginTop: "10px"}}>
                    {allResourcesToTranslations.extcertprofilepoints} 
                    {
                      extCertPointsLoading ?     
                          <span hidden={extCertCardExpanded}><CircularProgress key={"coursePointsLoading2"} hidden={extCertCardExpanded}
                              style={{ width: extCertPointsLoading ? "20px" : "0px" }}
                              color="primary"
                              size="10"
                          /></span>
                      :
                      <></>
                    }
                  </span>                  
                </div>
              </div>
            </Typography>
          </AccordionSummary> 
          <>
            <div id="certsAssignPointsWhenProvidedOnly" style={{marginTop: "-15px", marginLeft: "18px", fontWeight: "bold", fontSize: '14px', paddingTop: "3px", paddingBottom: "3px"}}>
              <span className={styles.labelTextitalic} style={{fontSize: '14px', fontWeight: "bold"}}>{allResourcesToTranslations.assignbuttonspanelcpdpointaccrualrequirementcert}</span>              
            </div>
            <br />
            <div id="showOnlyPointsAssignedCertsWithLabel" style={{marginTop: "-10px", marginLeft: "10px"}}>                    
              <span >
                <Checkbox disabled={false} id="showOnlyPointsAssignedExtCerts"
                      size="small"
                      color="primary"
                      checked={showOnlyPointsAssignedExtCert}
                      className={styles.checkBox}
                      onClick={(e) => { setIsCommonPointsToExtCerts(false); setShowOnlyPointsAssignedExtCert(!showOnlyPointsAssignedExtCert); e.stopPropagation(); }}
                  /> 
                <span onClick={(e) => { setIsCommonPointsToExtCerts(false); setShowOnlyPointsAssignedExtCert(!showOnlyPointsAssignedExtCert); e.stopPropagation(); }}
                      style={{fontSize: '14px'}}>{allResourcesToTranslations.showonlypointsassignedextCert}</span>  
              </span>
            </div>
            <br />
            <div id="applyCommonPointsToFilteredECList" style={{marginTop: "-10px", marginLeft: "10px"}}>                    
              <span >
                <Checkbox disabled={false} id="applyCommonPointsToFilteredECListCheck"
                        size="small"
                        color="primary"
                        checked={isCommonPointsToExtCerts}
                        className={styles.checkBox}
                        onClick={(e) => { toggleCommonPointsToExtCerts(); e.stopPropagation(); }}
                    /> 
                <span id="applyCommonPointsToFilteredECListLabel1" style={{fontSize: '14px'}}
                      onClick={(e) => { toggleCommonPointsToExtCerts(); e.stopPropagation(); }}>
                        {allResourcesToTranslations.assignbuttonspanelpointsapplycommontofcmods1 + ": "}</span>  

                <TextField style={{width: commonPointsOfExtCerts.length <= 4 ? "30px" : "60px", fontSize: '14px', marginTop: '3px'}} 
                    key="applyCommonPointsToFilteredECListValue" id="applyCommonPointsToFilteredECListValue" 
                    InputLabelProps={{shrink: true,}}                      
                    value={commonPointsOfExtCerts} onChange={(e) => { handleChangeOfCommonPointsOfExtCerts(e.target.value); e.stopPropagation(); }}/>  

                <span id="applyCommonPointsToFilteredECListLabel2" style={{fontSize: '14px'}}
                      onClick={(e) => { toggleCommonPointsToExtCerts(); e.stopPropagation(); }}>
                        {allResourcesToTranslations.assignbuttonspanelpointsapplycommontofextcerts}</span>
              </span>
            </div>
            <br />
            <div id="certAssignPointsSearch" style={{ marginTop: "-15px", justifyContent: "space-between", padding: "20px 0px 20px 20px"}} key={"p1mainDivOfProfilePoints"}>
              <div style={{display: "grid"}} key={"p2mainDivOfProfilePoints"}>
                <div key="p4mainDivOfProfilePoints" className={styles.search_longtextbox_and_buttons_container}>  
                    <div key="p5mainDivOfProfilePoints" id="extCertNameSearch" className={styles.courseModuleNameSearch}>
                      <TextField style={{width: "100%"}} key="p6mainDivOfProfilePoints" 
                        label={"External Certificate Name Search"} variant="outlined" autoComplete="off" InputLabelProps={{shrink: true,}}                      
                        value={filterExtCertNameCSV} onChange={(e) => { setIsCommonPointsToExtCerts(false); setFilterExtCertNameCSV(e.target.value); }} 
                        InputProps={{endAdornment: ( <IconButton><SearchIcon style={{height: "20px"}} /></IconButton> )}}/>
                    </div>
                  <div style={{clear: "both"}} key="p7mainDivOfProfilePoints" ></div>
                </div>
              </div>
            </div>

            {
              extCertPointsLoading ?               
                <div className={styles.loaderContainer} hidden={!extCertCardExpanded}>
                    <span hidden={!extCertCardExpanded}><CircularProgress key={"extCertPointsLoading"}
                        style={{ width: extCertPointsLoading ? "20px" : "0px", padding: "15px 10px" }}
                        color="primary"
                        size="10"
                    /></span>
                </div>
                :
                <div key="p8mainDivOfProfilePoints" id={"orgExtCertPointsAssignment"} 
                      style={{overflowY:"scroll", scrollbarWidth:"auto", maxHeight: "400px", borderTop: "3px solid #D3D6DF" }}>
                  {extCertList.length > 0 && extCertList.map((extCert, pindex) => {
                    return (!!extCert && extCert.isSearchMatch && (!showOnlyPointsAssignedExtCert || extCert.assignedPoints > 0.0)) ? 
                    (
                      <div id={pindex == 0 ? "firstCertRowOfAssignPoints" : "p" + extCert.extCertIDTxt + "fragmet" + extCert.extCertName} key={"p" + extCert.extCertIDTxt + "fragmet" + extCert.extCertName}>
                        <span key={"p" + extCert.extCertIDTxt + "span" + extCert.extCertName} 
                              style={{display: "inline"}}>
                            <div style={{display: "flex", width: "100%", marginLeft: "16px"}} 
                                key={"p" + extCert.extCertIDTxt + "div1" + extCert.extCertName} >
                              <div style={{width: "92%"}} 
                                key={"p" + extCert.extCertIDTxt + "div3" + extCert.extCertName} >
                                {/* <Checkbox size="small" id={"profileExtCertSelectionCheckbox" + extCert.extCertIDTxt}  
                                          key={"p" + extCert.extCertIDTxt + "profileExtCertSelectionCheckbox"}
                                          checked={extCert.assignedPoints > 0.0}
                                          onClick={(e) => {}}
                                          color="primary"/> */}
                                <span style={{fontWeight: (extCert.assignedPoints > 0.0 ? "bold" : "normal"), fontSize: "14px", fontFamily: "'Open Sans', 'Segoe UI', sans-serif"}} 
                                      key={"p" + extCert.extCertIDTxt + "div4" + extCert.extCertName} >
                                  {extCert.extCertName}</span>
                              </div>
                              {
                                !extCert.isCPDEnabled ? 
                                <CustomTooltip displayText={[allResourcesToTranslations.cpddisabledandguide]}>
                                  <div style={{width: "5%", minWidth: "55px"}} 
                                      key={"p" + extCert.extCertIDTxt + "div5" + extCert.extCertName} >
                                    <TextField id={"profileExtCertPointsText" + extCert.extCertIDTxt} disabled={!extCert.isCPDEnabled}
                                      key={"p" + extCert.extCertIDTxt + "profileExtCertPointsText"}
                                      className={styles.inputmidsize} variant="outlined"
                                      style={{ padding: "5px" }} InputLabelProps={{shrink: true,}}
                                      value={extCert.assignedPointsTxt} onChange={(e) => setExtCertProfilePoints(e.target.value, extCert)} />
                                  </div>
                                </CustomTooltip>
                                :
                                <div style={{width: "5%", minWidth: "55px"}} 
                                    key={"p" + extCert.extCertIDTxt + "div5" + extCert.extCertName} >
                                  <TextField id={"profileExtCertPointsText" + extCert.extCertIDTxt} disabled={!extCert.isCPDEnabled}
                                    key={"p" + extCert.extCertIDTxt + "profileExtCertPointsText"}
                                    className={styles.inputmidsize} variant="outlined"
                                    style={{ padding: "5px" }} InputLabelProps={{shrink: true,}}
                                    value={extCert.assignedPointsTxt} onChange={(e) => setExtCertProfilePoints(e.target.value, extCert)} />
                                </div>
                              }
                              <div style={{width: "3%"}} 
                                  key={"p" + extCert.extCertIDTxt + "div6" + extCert.extCertName} >
                              </div>  
                            </div>
                        </span> 
                      </div>
                    ) :
                    (<div key={"p" + pindex.toString() + "9mainDivOfProfilePoints"} ></div>)
                  })}
                  
                </div>
            }
          </>
        </Accordion>
      </BrandedCard>
      <br/>
      <div  style={{display: "normal", width: "100%", marginLeft: "16px"}} key={"mainDiv1ExtCertTotalPoints"} >
        <span id="totalPointsOfCertsOfAssignPoints" key="mainSpanExtCertTotalPoints" style={{fontWeight: "bold", fontSize: "15px", paddingRight: "20px", float: "right"}}>{allResourcesToTranslations.extcerttotalprofilepoints2 + ": " + totalPointsOfExtCerts.toFixed(2) + " " +  allResourcesToTranslations.points} </span>        
      </div>
      <br/>
      <br/>
      <UnbrandedCard>
        <div style={{ justifyContent: "space-between", padding: "15px 0px 20px 20px"}} key={"mainDiv1OfAssignPointsTo"}>
          <div style={{display: "grid"}} key={"mainDiv2OfAssignPointsTo"}>
            <span key="mainSpan1OfAssignPointsTo" style={{fontWeight: "bold", fontSize: "15px", marginTop: "10px"}}>{allResourcesToTranslations.assignpointsto} </span>
          </div>
          <FormControl component="fieldset" style={{ width: '100%' }} className="recipient">
            {/* <FormLabel component="legend">{allResourcesToTranslations.assignpointsto}</FormLabel> */}
            <div className={styles.childContainer}>
                <RadioGroup
                    name="assignToType"
                    value={assignToType}
                    onChange={handleAssignToTypeChange}
                    style={{ display: 'flex'}}
                >
                    <FormControlLabel
                        id="includePreviousCompletionsInAssignPoints"
                        value={"allaccountsincludingcompleted"}
                        control={<Radio color="primary" />}
                        label={allResourcesToTranslations.allaccountsincludingcompletednew}
                    />
                    <FormControlLabel
                        id="excludePreviousCompletionsInAssignPoints"
                        value={"allaccountsexcludingcompleted"}
                        control={<Radio color="primary" />}
                        label={allResourcesToTranslations.allaccountsexcludingcompletednew}
                    />
                </RadioGroup>
            </div>
          </FormControl>
        </div>
      </UnbrandedCard>
    </>
  )
}

export default AssignPoints
