import axios from 'axios';
import { CourseProgressPanelProvider } from '../contexts/CourseProgressContext';
import { addError } from './AddError';

const checkLessonCompletedXML2 = (xmlString: string) => {
  let result = xmlString;
  const xml = new DOMParser().parseFromString(xmlString,"text/xml");
  const dmeValues = xml.getElementsByTagName('dme')
  const dmeValuesArray = Array.from(dmeValues)
  const currentPageNumber = dmeValuesArray.find(dme => dme?.firstElementChild?.innerHTML === "salt.variables.VarPageInChapter")?.children[1].innerHTML
  const maxPageNumber = dmeValuesArray.find(dme => dme?.firstElementChild?.innerHTML === "salt.variables.VarPagesInChapter")?.children[1].innerHTML

  if (currentPageNumber === maxPageNumber) {
    for (let i = 0; i < dmeValues.length; i ++) {
      if (dmeValues[i].children[0].innerHTML === "cmi.core.lesson_status") {
        dmeValues[i].children[1].innerHTML = "completed";
        result = new XMLSerializer().serializeToString(xml);
        break
      }
    }    
  }
  return result;
}
export const decideAndUpdateCourseCompletion = async (token: string, userId: number, orgDomain: string, isLesson: boolean, 
    sessionId: string, profileId: number, selectedProfileId: number, moduleId: number, write: boolean, dme: string, 
    value: string, xmlData: string, currentCulture: string, isTypeLectora: boolean, uniqueID: string,
    courseID: number, IsSACourseRestartedInSALT: boolean, IsSALTRestartSelected: boolean, 
    CurrentScormDMERawScore: number, CurrentScormDMEQuizPassmark: number, CurrentScormDMECoreExitValue: string,
    CurrentQuizStatus: number, CurrentQuizType: string ) => {


  try {
    //debugger
    const xmlPostData = isLesson ? checkLessonCompletedXML2(xmlData) : xmlData; 
    //debugger
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/usersessions/ResolveCourseCompletion`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        IsSACourseRestartedInSALT: IsSACourseRestartedInSALT,
        CurrentScormDMERawScore: CurrentScormDMERawScore,
        CurrentScormDMEQuizPassmark: CurrentScormDMEQuizPassmark,
        CurrentScormDMECoreExitValue: CurrentScormDMECoreExitValue,
        CurrentQuizType: CurrentQuizType,
        CurrentQuizStatus: CurrentQuizStatus,
        IsSALTRestartSelected: IsSALTRestartSelected,
        OrgDomain: orgDomain,
        Write: write,
        UserID : userId,
        ProfileID : profileId,
        SelectedProfileID : selectedProfileId,
        ModuleID : moduleId,
        CourseID: courseID,
        DME: dme,
        value: value,
        xmlData: xmlPostData,
        SessionID: sessionId,
        IsLesson: isLesson,
        CurrentCulture: currentCulture,
        IsTypeLectora: isTypeLectora,
        UniqueID: uniqueID,
      },
    });
    //debugger
    const data = await response.data;
    //console.log(data);
    //debugger
    return data;
  } catch (error: any) {
    //debugger
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "DecideAndUpdateCourseCompletion.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "DecideAndUpdateCourseCompletion.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "DecideAndUpdateCourseCompletion.ts", 1)
    }
    return null;
  }
};

export default decideAndUpdateCourseCompletion
