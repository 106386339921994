import axios from 'axios';
import { addError } from '../../AddError';

export const SaveMyExternalCertificates = async (token: string,requestedUserID: number, userId: number, orgDomain: string, orgId: number | null, 
ValidFromDate: string,ValidToDate: string,CertificateId: number,FileName: string,
 byteArray: string,  fileExtension: string) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/externalcertificate/SaveMyExternalCertificates`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {  
        requestedUserID:requestedUserID,
        userId: userId,
        orgId: orgId,      
        fileName:FileName,
        validFromDate: ValidFromDate === "" ? null : ValidFromDate,
        validToDate: ValidToDate === "" ? null : ValidToDate,
        certificateId:CertificateId,
        fileData: byteArray,
        fileExtension: fileExtension
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "SaveMyExternalCertificates.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "SaveMyExternalCertificates.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "SaveMyExternalCertificates.ts", 1)
    }
    return null;
  }
};

export default SaveMyExternalCertificates;