import React, { useEffect, useState } from 'react';
import './styles/globals.css';
import 'intro.js/introjs.css';
import "intro.js/themes/introjs-modern.css";
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import getOIDCConfig from './API/GetOIDCConfig'
import GenericError from './Pages/ErrorPage/GenericError';
import { AuthenticationProvider, InMemoryWebStorage } from '@axa-fr/react-oidc-context'; //Add this enable console log debugging

import CustomCallback from './Pages/CustomCallback';
import Routes from './Router';
import LoaderContainer from './Components/LoaderContainer'

const App = () => {
  const [OIDCConfig, setOIDCConfig] = useState(null)

  useEffect(() => {
    getOIDCConfig().then((res) => {
      if(res?.isSuccess) {
        setOIDCConfig(res.config)
      } else {
        //Throw error?
      }
    })
  },[])

  if (OIDCConfig === null) {
    return (
      <LoaderContainer/>
    )
  } else {
    return (
      <div style={{ height: "100%" }}>
          <AuthenticationProvider
              configuration={OIDCConfig}
              // loggerLevel={oidcLog.DEBUG} //Add this enable console log debugging
              isEnabled={true}
              callbackComponentOverride={CustomCallback}
              UserStore={InMemoryWebStorage}
              notAuthenticated={()=>(<GenericError>{"Ooops, we've detected an error on login (auth flow not complete)"}</GenericError>)}
              notAuthorized={()=>(<GenericError>{"Oops, we've detected an error on login (auth flow not complete)"}</GenericError>)}
          >
              <Router>
                  <Routes />
              </Router>
          </AuthenticationProvider>
      </div>
    );
  }
}

render(<App />, document.getElementById('root'));