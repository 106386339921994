import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const PasswordValidIcon = () => (
 
<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.7827 5.52747C10.7827 2.76604 8.54414 0.527466 5.78271 0.527466C3.02129 0.527466 0.782715 2.76604 0.782715 5.52747C0.782715 8.28889 3.02129 10.5275 5.78271 10.5275C8.54414 10.5275 10.7827 8.28889 10.7827 5.52747Z" fill="#32C671"/>
<path d="M9.00442 4.18411L8.40056 3.58025C8.31772 3.49735 8.217 3.45593 8.09863 3.45593C7.9802 3.45593 7.87954 3.49735 7.79665 3.58025L4.88362 6.49763L3.57818 5.18745C3.49529 5.10456 3.39463 5.06314 3.27625 5.06314C3.15782 5.06314 3.05716 5.10456 2.97427 5.18745L2.37041 5.79131C2.28751 5.87421 2.24609 5.97487 2.24609 6.0933C2.24609 6.21167 2.28751 6.31239 2.37041 6.39523L3.97778 8.00259L4.58164 8.60646C4.66448 8.68935 4.76514 8.73077 4.88357 8.73077C5.00194 8.73077 5.10266 8.6893 5.1855 8.60646L5.78936 8.00259L9.00409 4.78786C9.08693 4.70496 9.12841 4.6043 9.12841 4.48587C9.12841 4.3675 9.08693 4.26678 9.00409 4.18394" fill="white"/>
</svg>

);

export default PasswordValidIcon