import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const SelectAllIcon = () => (
    <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M22.9999 4.88232V23.0004H4.88187V4.88232H22.9999ZM7.47016 7.47061H20.4116V20.4121H7.47016V7.47061Z" fill="#FFFFFF"/>
    <path d="M3.58836 3.58791H19.1181V0.999615H1.00006V19.1177H3.58836V3.58791Z" fill="#FFFFFF"/>
    <path d="M12.6816 18.413L9.15428 15.1212L12.2407 15.9431C13.1225 14.7085 15.768 11.0059 18.4135 11.0057C14.8861 13.4743 14.1513 16.2186 12.6816 18.413Z" fill="#FFFFFF"/>
    </svg>
);

export default SelectAllIcon
