import axios from 'axios';
import { addError } from './AddError';

export const getProgressReport = async ( token: string, 
    userId: number,
    orgDomain: string,
    orgId: number,
    unitIds: string,
    courseIds: string,
    userFirstName: string,
    userLastName: string,
    userName: string,
    userEmail: string,
    effectiveDateOrg: string,
    groupBy: string, 
    sortBy: string,
    includeInactive: boolean,
    classificationId: number, 
    courseStatus: string,
    fromDate: string,
    toDate: string,
    filterFirstName: string,
    filterLastName: string,
    filterUserName: string,
    filterEmail: string
    ) => {
    //   console.log({
    //     ReportRequestingUserID : userId,
    //     OrganisationID : orgId,
    //     UnitIDs : unitIds,
    //     CourseIDs : courseIds,    
    //     classificationID : classificationId,
    //     inclInactive : includeInactive,
    //     userFirstName : filterFirstName, //Filter params
    //     userLastName : filterLastName, //Filter params
    //     userName :  filterUserName, //Filter params
    //     userEmail : filterEmail,//Filter params
    // })
    
    try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/api/userreport/GetFullProgressReport`,
          headers:{
            'Content-Type': 'application/json',
            'Authorization' : `Bearer ${token}`
          },
          data: {
            ReportRequestingUserID : userId,
            OrganisationID : orgId,
            UnitIDs : unitIds,
            CourseIDs : courseIds,    
            ClassificationID : classificationId,
            IncludeInactive : includeInactive,
            UserFirstName : filterFirstName, //Filter params
            UserLastName : filterLastName, //Filter params
            UserName :  filterUserName, //Filter params
            UserEmail : filterEmail,//Filter params
        }
        });
        const data = await response.data;
        
        return data;
      }
      catch (error: any) {
        if (error.response) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.response);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.response, "GetProgressReport.tsx", 1)
        } else if (error.request) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.request);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.request, "GetProgressReport.tsx", 1)
        } else if (error.message) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.message);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.message, "GetProgressReport.tsx", 1)
        }
        return null;
      }
}