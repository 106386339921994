import * as React from "react"

function GlobeFlag() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.3 28.3">
      <path
        d="M14.1 0C6.3 0 0 6.3 0 14.1s6.3 14.1 14.1 14.1 14.1-6.3 14.1-14.1S21.9 0 14.1 0zm9.8 8.5h-4.2c-.5-1.8-1.1-3.5-1.9-5 2.6.8 4.7 2.6 6.1 5zm-9.8-5.6c1.2 1.7 2.1 3.6 2.7 5.6h-5.4c.6-2 1.6-3.9 2.7-5.6zM3.2 17c-.2-1-.4-1.9-.4-2.9s.1-1.9.4-2.8H8c-.1.9-.2 1.9-.2 2.8S7.9 16 8 17H3.2zm1.2 2.8h4.2c.5 1.8 1.1 3.5 1.9 5-2.6-.9-4.8-2.7-6.1-5zM8.5 8.5H4.4c1.4-2.3 3.5-4.1 6.1-5-.9 1.5-1.5 3.2-2 5zm5.6 16.9c-1.2-1.7-2.1-3.6-2.7-5.6h5.4c-.6 2-1.5 3.9-2.7 5.6zm3.3-8.4h-6.6c-.1-.9-.2-1.9-.2-2.8s.1-1.9.2-2.8h6.6c.1.9.2 1.9.2 2.8s0 1.8-.2 2.8zm.4 7.8c.8-1.6 1.5-3.3 1.9-5h4.2c-1.4 2.3-3.5 4.1-6.1 5zm2.5-7.8c.1-.9.2-1.9.2-2.8s-.1-1.9-.2-2.8h4.8c.2.9.4 1.9.4 2.8s-.2 1.8-.4 2.8h-4.8z"
        opacity={0.8}
        fill="#21394f"
      />
    </svg>
  )
}

export default GlobeFlag