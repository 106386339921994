import axios from 'axios';
import { addError } from './AddError';

export const getFullWarningReport = async (
    token: string, 
    userId: number,
    orgDomain: string,
    orgId: number,
    unitIds: string,
    courseIds: string,
    warningPeriod: number,
    warningUnit: string,
    classificationID: number,
    includeInactive: boolean
    ) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/userreport/GetFullWarningReport`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },

      data:{

           "ReportRequestingUserID" :userId,
           "OrganisationID" : orgId,
           "UnitIDs" : unitIds, // use "" blank for all units
           "CourseIDs" : courseIds,  // use "" blank for all Course
           "WarningPeriod" : warningPeriod,
     "WarningUnit" : warningUnit, // "mm" for months "dd" for days
     "ClassificationID" : classificationID,
     "IncludeInactive" :includeInactive
       
       }
    });
    const data = await response.data;
    if (data.isSuccess) {
      let rowBackGroundColour = "";
    
      if(data.warningreportItem.length > 0) {
        for (let i = 0; i < data.warningreportItem.length; i++) {
          let lastRow = false;
          let grandTotalRow = false;
          let totalAccount = 0;
          data.warningreportItem[i].showUnit = true;
          data.warningreportItem[i].showCourse = true;
          if (i > 0 && data.warningreportItem[i-1].unitPathway === data.warningreportItem[i].unitPathway){
            data.warningreportItem[i].showUnit = false;
          }
          if (i > 0 && data.warningreportItem[i-1].courseName === data.warningreportItem[i].courseName){
            data.warningreportItem[i].showCourse = false;
          }
          if (i > 0 && data.warningreportItem[i-1].courseName !== data.warningreportItem[i].courseName){
              rowBackGroundColour = (rowBackGroundColour === "" ? "#eee" : "");      
          }
          if (i !== data.warningreportItem.length - 1 ) {
            lastRow = true;
            totalAccount =  data.warningreportItem.filter((item:any) => item.unitPathway.toString() ===  data.warningreportItem[i].unitPathway && item.courseName.toString() ===  data.warningreportItem[i].courseName).length;
          }
          if (i === data.warningreportItem.length - 1){
            grandTotalRow = true;
          }
          
          data.warningreportItem[i].grandTotalRow = grandTotalRow;
          data.warningreportItem[i].rowBackGroundColour = grandTotalRow ? "" : rowBackGroundColour;
          data.warningreportItem[i].lastRow = lastRow;
          data.warningreportItem[i].totalAccount = totalAccount;
        }

      }
      return data;
    }
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      };    
      addError(token, userId, orgDomain, "Rest Exception", error.response, "GetFullWarningReport.tsx", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      };    
      addError(token, userId, orgDomain, "Rest Exception", error.request, "GetFullWarningReport.tsx", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      };    
      addError(token, userId, orgDomain, "Rest Exception", error.message, "GetFullWarningReport.tsx", 1)
    }
    return null;
  }
};

export default getFullWarningReport;
