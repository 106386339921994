import React from 'react';
import styles from '../styles/unbrandedCard.module.css';

const UnbrandedCard = ({children}: any): JSX.Element => {
  return (
    <div className={styles.unbrandedCard}>
      {children}
    </div>
  );
};

export default UnbrandedCard;
