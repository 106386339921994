//React + Typescript
import React from 'react';
// Libs
import { AuthenticationContext } from '@axa-fr/react-oidc-context';
//Contexts
import { UIProvider } from '../contexts/UIContext'
import { LoaderProvider } from '../contexts/LoaderContext'
import { TranslationsProvider } from '../contexts/TranslationsContext'
import { CorperateThemeProvider } from '../contexts/CorperateThemeContext';
//Components
import Layout from '../Layout/index';
import Landing from '../Pages/Landing/index';
import LoaderContainer from '../Components/LoaderContainer';
//MUI Components
import theme from '../styles/MUITheme';
import { ThemeProvider } from '@material-ui/core/styles';

const Routes = () => {
  return (
    <CorperateThemeProvider>
      <TranslationsProvider>
        <ThemeProvider theme={theme}>
          <UIProvider>
            <LoaderProvider>
              <AuthenticationContext.Consumer>
                {(props) => {
                  if (props.isLoggingOut) {
                    return <LoaderContainer />; //Shows loader while logout is in progress
                  } else {
                    return (
                      <>
                        {props.oidcUser || !props.isEnabled? (
                          <Layout /> 
                        ) : (
                          <Landing redirectFunction={props.login} />
                        )}
                      </>
                    );
                  }
                }}
              </AuthenticationContext.Consumer>
            </LoaderProvider>
          </UIProvider>
        </ThemeProvider>
      </TranslationsProvider>
    </CorperateThemeProvider>
  );
}

export default Routes;

/////////////////////////////////
// Original code for reference //
//////////////////////////////////
//Below are examples of how each route can be secured//

// import React from 'react';
// import { Switch, Route } from 'react-router-dom';
// import { withOidcSecure, OidcSecure } from '@axa-fr/react-oidc-context';
// import Home from '../Pages/Home';
// import Dashboard from '../Pages/Dashboard';
// import Admin from '../Pages/Admin';
// import SimpleComponent from '../Pages/SimpleComponent';

// const PageNotFound = () => <div>Page not found</div>;
// const ProtectedSimpleComponent = withOidcSecure(SimpleComponent);
// const ProtectedDashboard = withOidcSecure(Dashboard);

// const Routes = () => (
//   <Switch>
//     <Route exact path="/">

//     </Route>
//     <Route path="/dashboard">
//       <ProtectedDashboard />
//     </Route>
//     <Route path="/admin">
//       <OidcSecure>
//         <Admin />
//       </OidcSecure>
//     </Route>
//     <Route path="/home" component={Home} />
//     <Route path="/protected1">
//       <OidcSecure>
//         <SimpleComponent type="Component" />
//       </OidcSecure>
//     </Route>
//     <Route path="/protected2">
//       <ProtectedSimpleComponent type="HOC" />
//     </Route>
//     <Route component={PageNotFound} />
//   </Switch>
// );

// export default Routes;
