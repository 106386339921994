import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UnitTreeItem } from "../../globalTypes";

//libs
import { Steps, Hints } from "intro.js-react";

//MUI
import { Button, Checkbox } from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";

//Contexts
import { UIContext } from "../../contexts/UIContext";
import { LoaderContext } from "../../contexts/LoaderContext";
import { TranslationsContext } from '../../contexts/TranslationsContext';
//API Functions
import { getRelevantUnitTree } from "../../API/GetRelevantUnitTree";
import { useReactOidc } from "@axa-fr/react-oidc-context";

//Components
import BrandedCard from "../../Components/BrandedCard";
import LoaderContainer from "../../Components/LoaderContainer";
import CustomTooltip from "../../Components/CustomTooltip";
import ResetIcon from "../../CustomIcons/ResetIcon";
import Units from "../ReportCommonComponents/Units";
import GuideIcon from '../../CustomIcons/GuideIcon';

//Styles
import styles from "../../styles/userDetailReport.module.css";
import getFullUserDetailReport from "../../API/GetFullUserDetailReport";
import ButtonPanel from "../CurrentAdminReport/ButtonPanel";

const UserDetailReport = () => {
  const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext);
  const history = useHistory();
  const { setMessage } = useContext(LoaderContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute } = useContext(UIContext);
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";

  const [units, setUnits] = useState<Array<UnitTreeItem>>([]);
  const [filterUnitName, setFilterUnitName] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const [isResetClicked, setisResetClicked] = useState<boolean>(false);
  const [filterIncludeInactive, setFilterIncludeInactive] =
    useState<boolean>(false);

    const [intro, setIntro] = useState({
      stepsEnabled: false,
      initialStep: 0,
      steps: [
        {
          element: ".unitFilterButtons",
          title: "Units", intro: allResourcesToTranslations.emrepgmunitsm,
        },      
      ]
    })

    useEffect(() => 
    {
                  let updateSteps = [...intro.steps]
                  updateSteps.push( {
                    element: ".unitFilterSearch",
                    title: "Filter Units", intro: allResourcesToTranslations.unitfiltersearch
                  },
                  {
                    element:  ".includeInactive",
                    title: "Filter", intro: allResourcesToTranslations.prrepgminactiveaccount
                }, 
                  {
                    element:  ".runReport",
                    title: "Run Report", intro: allResourcesToTranslations.sntemrepgmmrunrep
                },
                {
                  element:  ".resetAll",
                  title: "Reset Filters", intro: allResourcesToTranslations.emrepgmram
              } 

                  )
                setIntro({...intro, steps: [...updateSteps]})              
      },[])
      
      const onExit = () => {
        setIntro({...intro, stepsEnabled: false });
      };

    
  useEffect(() => {
    setTitle(allResourcesToTranslations.userdetailreport);
    setTitleEnglish(pageTitles.userdetailreport);
    updateBackButtonRoute("");
    setLoading(true);
    getRelevantUnitTree(
      oidcUser.access_token,
      userId,
      orgDomain,
      true,
      parseInt(orgId)
    ).then((unitTreeRes) => {
      if (unitTreeRes.isSuccess) {
        setUnits(unitTreeRes.unitTreeItems);
        setLoading(false);
      }
    });
  }, []);

  const handlerunReport = () => {

    let unitIds: Array<number> = [];
    let allUnitIds: Array<number> = [];
    let unitData: Array<string> = [];
    setLoading(true);
    setMessage("Fetching your report");
    const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return //Break if no children
      if (unitArray.length === 0) return
      for (let i = 0; i < unitArray.length; i++) {
          const unit = unitArray[i];
          if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) { 
            if (filterUnitName !== "") {
                if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
                unitIds.push(unit.unitID);
                unitData.push(unit.name);
                }
            } else {
                unitIds.push(unit.unitID);
                unitData.push(unit.name);
            }
          }  
          allUnitIds.push(unit.unitID);
          getSelectedUnitIds(unit.childUnitTreeItems);
      }
  }
  getSelectedUnitIds(units);



    getFullUserDetailReport(oidcUser.access_token, userId, orgDomain, parseInt(orgId), unitIds.length > 0 ? unitIds.toString() : allUnitIds.toString(),filterIncludeInactive).then((reportRes) => {
      
      setLoading(false);
      history.push({
        pathname: `userdetailreport/result`,               
        state: {
            includeInactive: filterIncludeInactive,
            selectedUnits: unitData.toString(),               
            resultItems: (reportRes === null ? null : reportRes.userDetailReportReportItems),
            resultError: ((reportRes === null || reportRes === undefined || reportRes.isSuccess || reportRes.error === null || reportRes.error === undefined) ? "" : reportRes.error)
        },
    });

    })
  };

  const resetAll = () => {
    setFilterIncludeInactive(false);
    setisResetClicked(!isResetClicked);
  };

  if (loading) return <LoaderContainer />;

  return (
    <>
         <Steps  enabled={intro.stepsEnabled}   steps={intro.steps}  initialStep={intro.initialStep}
          onExit={onExit}
            options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
          }}/>
         <CustomTooltip displayText={["Guide Me"]}>
            <span onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
            <GuideIcon />
            </span>
         </CustomTooltip>
      <BrandedCard>
        <div className={styles.reportWrraper}>
          <div style={{ width:'100%'}}>           
              <Units isResetClicked={isResetClicked} givenUnits={units} givenFilterUnitName={filterUnitName} setGivenFilterUnitName={setFilterUnitName} />          
          </div>
          <div style={{paddingTop:'10px'}}>
            <div className={`${styles.inactiveContainer} includeInactive`} >
              <Checkbox
                size="small"
                color="primary"
                checked={filterIncludeInactive}
                className={styles.checkBox}
                onClick={() => setFilterIncludeInactive(!filterIncludeInactive)}
              />{" "}
              {"Include Inactive Accounts"}
            </div>
            <div>
              <ButtonPanel openReportSchedulerModal={() => false} showScheduler={false} runReport={handlerunReport} resetAll={resetAll}/>
            </div> 
          </div>

          <div></div>
        </div>
      </BrandedCard>
    </>
  );
};

export default UserDetailReport;
