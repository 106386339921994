import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const ProceedIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
<path d="M9.14286 22.1539L0 13.1291L8 15.3825C10.2857 11.9977 17.1429 1.84693 24 1.84619C14.8571 8.61401 12.9524 16.1378 9.14286 22.1539Z" fill="#FFFFFF"/>
</svg>
    
);

export default ProceedIcon
