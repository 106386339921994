import React, { useState, createContext } from 'react';
import { MyTrainingItem } from '../globalTypes'

interface IMyTrainingListContext {
    myTrainingList: Array<MyTrainingItem>,
    setMyTrainingList: (trainingList: Array<MyTrainingItem>) => void,
    getCourseFromContext: (courseId: number) => MyTrainingItem | null,
    trainingListUpdateRequired: boolean,
    setTrainingListUpdateRequired: (trainingListUpdateRequired: boolean) => void,
    dashboardUpdateRequired: boolean,
    setDashboardUpdateRequired: (trainingListUpdateRequired: boolean) => void,
    myExtCertReloadRequired: boolean,
    setMyExtCertReloadRequired: (myExtCertReloadRequired: boolean) => void
}

export const MyTrainingListContext = createContext<IMyTrainingListContext>({ 
    myTrainingList: [],
    setMyTrainingList: () => {},
    getCourseFromContext: () => null,
    trainingListUpdateRequired: true,
    setTrainingListUpdateRequired: () => {},
    dashboardUpdateRequired: true,
    setDashboardUpdateRequired: () => {},
    myExtCertReloadRequired: true,
    setMyExtCertReloadRequired: () => {}
});

export const MyTrainingListProvider = (props: any): JSX.Element => {
    const [ myTrainingList, setMyTrainingList] = useState<Array<MyTrainingItem>>([])
    const [ trainingListUpdateRequired, setTrainingListUpdateRequired ] = useState<boolean>(true)
    const [ dashboardUpdateRequired, setDashboardUpdateRequired ] = useState<boolean>(true)
    const [ myExtCertReloadRequired, setMyExtCertReloadRequired ] = useState<boolean>(true)


    const getCourseFromContext = (courseId: number) : MyTrainingItem | null => {
        for (let i = 0; i < myTrainingList.length; i++) {
            const trainingListItem = myTrainingList[i];
            if (trainingListItem.id === courseId) {
                return trainingListItem
            } 
        }
        return null;
    }

  return (
    <MyTrainingListContext.Provider value={{ 
        myTrainingList, 
        setMyTrainingList, 
        getCourseFromContext, 
        trainingListUpdateRequired, 
        setTrainingListUpdateRequired,
        dashboardUpdateRequired, 
        setDashboardUpdateRequired,
        myExtCertReloadRequired, 
        setMyExtCertReloadRequired
        }}>
      {props.children}
    </MyTrainingListContext.Provider>
  );
};
