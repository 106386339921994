import React, { useContext, useState, useEffect } from 'react';
import { useReactOidc } from "@axa-fr/react-oidc-context";
//Styles
import styles from "../../../styles/emailReport.module.css";
import { Button, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';
import getColor from "../../../functions/getColor";
import { CorperateThemeContext } from "../../../contexts/CorperateThemeContext";
import CustomTooltip from '../../../Components/CustomTooltip';
import EditIcon from '../../../CustomIcons/SupportDetailsEditIcon';
import ReverttIcon from '../../../CustomIcons/SupportDetailsReverttIcon';
import SaveIcon from '../../../CustomIcons/SupportDetailSaveIcon';
import CancelIcon from '../../../CustomIcons/SupportDetailCancelIcon';
import UnbrandedCard from '../../../Components/UnbrandedCard';
import { EmailTemplates } from '../../../globalTypes';
import TinyMCEEditor from '../../../Components/TinyMCEEditorComponent';
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';
import RemoveOrgEmailTemplate from '../../../API/Administration/Organisation/RemoveOrgEmailTemplate';
import SaveOrgEmailTemplate from '../../../API/Administration/Organisation/SaveOrgEmailTemplate';
import { UIContext } from '../../../contexts/UIContext';
import SaveOrgEmailDefaultTemplate from '../../../API/SuperAdmin/SaveOrgEmailDefaultTemplate';

const EmailTemplateList = (data: any) => {
    const { superAdminSelectedBasicOrgData} =    useContext(UIContext);
    const { templates } = data;
    const [selectedEmailTemplates, setSelectedEmailTemplates] = React.useState(templates);
    const { theme: { color } } = useContext(CorperateThemeContext);
    const { oidcUser } = useReactOidc();
    const {
      profile: { user_id, website },
    } = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const orgDomain = website
      ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
      : "";

    const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true);
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
    const [saveSuccess, setSaveSuccess] = React.useState(false);
    const [modalPopupMessage, setModalPopupMessage] = useState('');
    const [hasErrored, setHasErrored] = useState<boolean>(false);


    const rowBackgroundColor = (index: number) => {
        if (enableRowBanding) {
            return { backgroundColor: index % 2 == 0 ? "#eee" : "#fff" }
        } else {
            return {}
        }
    }

    const handleEdit = (name: any) => {
        
        let updatedSelectedEmailTemplates = selectedEmailTemplates.map((item: EmailTemplates) => {
            if (!!item) {
                handleCancel(item.name);
            }
            if (item.name === name) {
                // Empty edited content
                if(!!(document.getElementById('editorText') as HTMLInputElement)) {
                    (document.getElementById('editorText') as HTMLInputElement).value = "";
                }
                item.isBeingEdit = true;
            }
            return item; 
        });
    
        setSelectedEmailTemplates([...updatedSelectedEmailTemplates]);
    };

    const handleCancel = (name: any) => {
        
        const updatedSelectedEmailTemplates = selectedEmailTemplates.map((item: EmailTemplates) => {
        if (!!item && item.name === name) {
            item.isBeingEdit = false;
        }
        return item; 
        });
    
        setSelectedEmailTemplates([...updatedSelectedEmailTemplates]);
        
    };

    const handleSave = (name: string, description: string) => {
        var editedcontent = (document.getElementById('editorText') as HTMLInputElement).value;
        var initialcontent = (document.getElementById('editorChangedText') as HTMLInputElement).value;
        
        if (editedcontent === "") {
            const updatedSelectedEmailTemplates = selectedEmailTemplates.map((item: EmailTemplates) => {
                if (!!item && item.name === name) {
                   
                    item.value = initialcontent;
                    item.isDefault = true;
                    item.isBeingEdit = false;
                }
                return item; 
                });        
                setSelectedEmailTemplates([...updatedSelectedEmailTemplates]);
        }

        if(editedcontent !== "" && initialcontent.trim().toLowerCase() !== editedcontent.trim().toLowerCase()) {
            SaveOrgEmailDefaultTemplate(oidcUser.access_token,
                userId,
                orgDomain, 
                parseInt(orgId),  
                 name, description, editedcontent ,                
                   (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : parseInt(orgId)),        
                   (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : orgDomain),
                     (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((result) => {
                if (result.isSuccess && result.error === "") {
                    const updatedSelectedEmailTemplates = selectedEmailTemplates.map((item: EmailTemplates) => {
                        if (!!item && item.name === name) {                           
                            item.value = editedcontent;
                            item.isDefault = false;
                            item.isBeingEdit = false;
                        }
                        return item; 
                        });        
                        setSelectedEmailTemplates([...updatedSelectedEmailTemplates]);
                } else {
                    setShowWarningModal(true);
                    setSaveSuccess(false);
                    setHasErrored(true);
                    setModalPopupMessage('Saving Organisation application template has failed! Please try again!');
                }
            });
        }
    };

  

    const handleClosePopup = () => {
		setShowWarningModal(false);
	};

    const handleTextEditorChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        if (event !== undefined) {
      }
    };

    useEffect(() => {
        selectedEmailTemplates.map((item: EmailTemplates) => {
            if (!!item) {
                handleCancel(item.name);
            }
            return item; 
        });

		// at the end, return a cleanup method
		return () => { };
	}, []);

    return (
        <>
            <UnbrandedCard>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>

                                <TableCell
                                    style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                                    align="left">
                                    <div className={styles.tableHeadCellContainer}>
                                        <div >

                                            {"Description" /* TODO translate */}
                                        </div>

                                    </div>
                                </TableCell>
                                <TableCell
                                    style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                    align="left">
                                    <div className={styles.tableHeadCellContainer}>
                                        <div >
                                            {"Value" /* TODO translate */}
                                        </div>

                                    </div>
                                </TableCell>
                                <TableCell
                                     style={{ color: getColor(color.substring(1)), backgroundColor: color,width: "150px", borderRadius: "0 8px 0 0" }}
                                    align="left">
                                    <div className={styles.tableHeadCellContainer}>
                                        <div>
                                            {"Action" /* TODO translate */}
                                        </div>

                                    </div>
                                </TableCell>                             
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                selectedEmailTemplates.map((result: any, i: number) => {
                                    return (
                                        <TableRow key={`reportScheduler-${i}`}
                                            style={rowBackgroundColor(i)}
                                        >   
                                            <TableCell align="left">
                                                <span className={result.name}>{result.description}</span>
                                            </TableCell>
                                            <TableCell align="left">
                                                {result.isBeingEdit ?
                                                   <TinyMCEEditor index={i} key={"tinyMCEEditor" + i} editorData={result.value} isTemplate={false} is200Tall={true} handleTextEditorChange={handleTextEditorChange} />
                                                :
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: result.value //bodyFormatted
                                                    }}></div>
                                                } 
                                            </TableCell>
                                            <TableCell align="left" className={`edit${i}`}>
                                            {result.isBeingEdit ? 
                                            <>
                                                   <CustomTooltip displayText={["Save"]}>
                                                   <Button onClick={() => handleSave(result.name, result.description)} className={styles.moduleListButton} variant="contained" color="primary" >
                                                     <SaveIcon />
                                                   </Button>
                                                 </CustomTooltip> &nbsp;&nbsp;
                                                 <CustomTooltip displayText={["Cancel"]}>
                                                   <Button onClick={() => handleCancel(result.name)} className={styles.moduleListButton} variant="contained" color="primary">
                                                     <CancelIcon />
                                                   </Button>
                                                 </CustomTooltip>
                                                 </>
                                                :
                                                <CustomTooltip displayText={["Edit"]}>
                                                    <Button onClick={() => handleEdit(result.name)} className={`${styles.moduleListButton}`} variant="contained" color="primary">
                                                        <EditIcon />
                                                    </Button>
                                                </CustomTooltip>
                                            }
                                            </TableCell>
                                          
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
            </UnbrandedCard>
        </>
    )
}

export default EmailTemplateList