import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

//libs
import { Steps } from "intro.js-react";

//MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
//Contexts
import { UIContext } from "../../../contexts/UIContext";
import { LoaderContext } from "../../../contexts/LoaderContext";

//API Functions
import { useReactOidc } from "@axa-fr/react-oidc-context";

//Components
import LoaderContainer from "../../../Components/LoaderContainer";
import CustomTooltip from "../../../Components/CustomTooltip";
import GuideIcon from "../../../CustomIcons/GuideIcon";

//Styles
import styles from "../../../styles/UnitDetails.module.css";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import SaveIcon from "../../../CustomIcons/SaveIcon";
import AddEmailAccountIcon from "../../../CustomIcons/Administration/AddEmailAccountIcon";
import RestartIcon from "../../../CustomIcons/RestartIcon";
import PasswordChecklist, { RuleNames } from "react-password-checklist";
import addError from "../../../API/AddError";
import RadioUnits from "../AddAccount/RadioUnits";
import getOneUnitDetails from "../../../API/Administration/Unit/GetOneUnitDetails";
import addOrUpdateUnit from "../../../API/Administration/Unit/AddOrUpdateUnit";

import MUICustomSelect from "../../../Components/MUICustomSelect";
import RandomIcon from "../../../CustomIcons/Administration/RandomIcon";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";

import Modal from "../../../Components/Modal";

import { Units } from "../../ReportCommonComponents/Units";
import UnitTree from "../../Administration/UnitDetails/UnitTree";
import validateEmail from "../../../functions/valudateEmail";
import { UnitTreeItem } from "../../../globalTypes";
import getUnitTreeByUnit from "../../../API/Administration/Unit/GetUnitTreeByUnit";
import UserListSection from "./UserListSection";
import UnitComplianceReportSection from "./UnitComplianceReportSection";
import { BorderBottom } from "@material-ui/icons";
import getFullUnitComplianceReport from "../../../API/GetFullUnitComplianceReport";

import getFullUserDetailReport from "../../../API/GetFullUserDetailReport";

const UnitDetails = (props: any) => {
  const { unitIdToEdit } = props;
  const location = useLocation<any>();
  const { setMessage } = useContext(LoaderContext);
  const {translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext);
  const { setTitle, setTitleEnglish, setMenuSelection , updateBackButtonRoute } =    useContext(UIContext);
  const { oidcUser } = useReactOidc();
  const { profile: { user_id, website  }} = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";

  const [units, setUnits] = useState<Array<UnitTreeItem>>([]);
  const [unitsPrivileges, setUnitsPrivileges] = useState<Array<UnitTreeItem>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false); 

  const [unitIdToUpdate, setunitIdToUpdate] = useState(0);

  const [unitID, setUnitID] = useState(0); 
  const [unitActive, setUnittActive] = useState<boolean>(false); 
  const [dateCreated, setDateCreated] = useState<string>("");
  const [dateUpdated, setDateUpdated] = useState<string>("");
  const [createdByName, setCreatedByName] = useState<string>("");
  const [updatedByName, setUpdatedByName] = useState<string>("");
  const [showModal,setModalOpen] = useState<boolean>(false);
  const [filterName, setFilterName] = useState( ""); 
  const [hierarchy, setHierarchy] = useState( ""); 
  const [pathway, setPathway] = useState( ""); 
  const [organisation, setOrganisation] = useState( ""); 
  const [organisationID, setOrganisationID] = useState(0); 
  const [parentUnitID, setParentUnitID] = useState(0); 

  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);

  const [interactionCount, setInteractionCount] = useState<number>(0) //Required to force update the tree
 
  //UserList of Unit
  const [usersList, setUsersList] = React.useState<Array<any>>([]);
  const [userListOpen, setUserListOpen] = useState<boolean>(!!location.state ? location.state.userListOpen : false); 
  const [unitComplianceList, setUnitComplianceList] = React.useState<Array<any>>([]);
  const [unitComplianceListOpen, setUnitComplianceListOpen] = useState<boolean>(!!location.state ? location.state.unitComplianceListOpen : false);
 	const [expandUserList, setExpandUserList] = useState<boolean>(false);
 	const [expandUnitComplianceList, setExpandUnitComplianceList] = useState<boolean>(false);
 
   const [showLastUpdatedRow, setShowLastUpdatedRow] = useState<boolean>(true);
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".unitDetails",
        title: "Units",
        intro: "Toggle the unit active/inactive.",
      },
    ],
  });

  const setGuideMe = (isUsersListEnable:boolean,isunitComplianEnable:boolean,issubunit:boolean,expandUserList:boolean,expandUnitComplianceList:boolean) => {  
   let subunit = true;
   let userList = true;   
   let unitList = true;
   
   

    let updateSteps = [...intro.steps];
        if (issubunit && isUsersListEnable && isunitComplianEnable && expandUserList && expandUnitComplianceList)
        { 
            updateSteps.splice(1, updateSteps.length - 1);     
            updateSteps.push( {
              element: ".unitName",
              title: "Units",
              intro: "Update name of current unit.",
            },
            {
              element: ".unittreesection",
              title: "Sub Units",
              intro: "The sub units of the current unit are displayed here.",
            },{
              element: ".userListSection",
              title: "Accounts in this Unit", intro: "Any accounts that reside in this unit will be displayed here."        
            },
            {
              element: ".includeinactivesuser",
              title: "Accounts in this Unit", intro: "Click here to include inactive users of this Unit."
            },
            {
              element: ".xlxsuser",
              title: "Accounts in this Unit", intro: "Click here to download an export of the accounts that reside in this Unit."
            },
            {
              element: ".unitComplianceListSection",
              title: "Unit Compliance Report", intro: "This area will display a Unit Compliance Report for this unit, this shows all currently assigned training, in addition to showing the current compliance period OR date-based compliance date, as well as passmark and if these are the default settings of custom to this unit."
            },
            {
              element: ".xlxs",
              title: "Unit Compliance Report", intro: "Click here to download an export of the displayed Unit Compliance Report for this Unit."
            },
            {
              element: ".lastLoginDetails",
              title: "Login Details",
              intro: "This shows who and when created this unit, as well as who and when the unit was last modified by.",
            },
            {
              element: ".saveUnitDetailData",
              title: "Save",
              intro: "Click to save any changes made to this unit.",
            });
        }
        else if (issubunit && isUsersListEnable && isunitComplianEnable && expandUserList )
        { 
            updateSteps.splice(1, updateSteps.length - 1);     
            updateSteps.push( {
              element: ".unitName",
              title: "Units",
              intro: "Update name of current unit.",
            },
            {
              element: ".unittreesection",
              title: "Sub Units",
              intro: "The sub units of the current unit are displayed here.",
            },{
              element: ".userListSection",
              title: "Accounts in this Unit", intro: "Any accounts that reside in this unit will be displayed here."        
            },
            {
              element: ".includeinactivesuser",
              title: "Accounts in this Unit", intro: "Click here to include inactive users of this Unit."
            },
            {
              element: ".xlxsuser",
              title: "Accounts in this Unit", intro: "Click here to download an export of the accounts that reside in this Unit."
            },           
            {
              element: ".lastLoginDetails",
              title: "Login Details",
              intro: "This shows who and when created this unit, as well as who and when the unit was last modified by.",
            },
            {
              element: ".saveUnitDetailData",
              title: "Save",
              intro: "Click to save any changes made to this unit.",
            });
        }
        else if (issubunit && isUsersListEnable && isunitComplianEnable  && expandUnitComplianceList)
        { 
            updateSteps.splice(1, updateSteps.length - 1);     
            updateSteps.push( {
              element: ".unitName",
              title: "Units",
              intro: "Update name of current unit.",
            },
            {
              element: ".unittreesection",
              title: "Sub Units",
              intro: "The sub units of the current unit are displayed here.",
            },
            {
              element: ".unitComplianceListSection",
              title: "Unit Compliance Report", intro: "This area will display a Unit Compliance Report for this unit, this shows all currently assigned training, in addition to showing the current compliance period OR date-based compliance date, as well as passmark and if these are the default settings of custom to this unit."
            },
            {
              element: ".xlxs",
              title: "Unit Compliance Report", intro: "Click here to download an export of the displayed Unit Compliance Report for this Unit."
            },
            {
              element: ".lastLoginDetails",
              title: "Login Details",
              intro: "This shows who and when created this unit, as well as who and when the unit was last modified by.",
            },
            {
              element: ".saveUnitDetailData",
              title: "Save",
              intro: "Click to save any changes made to this unit.",
            });
        }
        else if (!issubunit && !isUsersListEnable && !isunitComplianEnable)
        { 
            updateSteps.splice(1, updateSteps.length - 1);     
            updateSteps.push( {
              element: ".unitName",
              title: "Units",
              intro: "Update name of current unit.",
            },
            {
              element: ".lastLoginDetails",
              title: "Login Details",
              intro: "This shows who and when created this unit, as well as who and when the unit was last modified by.",
            },
            {
              element: ".saveUnitDetailData",
              title: "Save",
              intro: "Click to save any changes made to this unit.",
            });
        }
        else if (issubunit && !isUsersListEnable && !isunitComplianEnable)
        { 
            updateSteps.splice(1, updateSteps.length - 1);     
            updateSteps.push( {
              element: ".unitName",
              title: "Units",
              intro: "Update name of current unit.",
            },
            {
              element: ".unittreesection",
              title: "Sub Units",
              intro: "The sub units of the current unit are displayed here.",
            },
            {
              element: ".lastLoginDetails",
              title: "Login Details",
              intro: "This shows who and when created this unit, as well as who and when the unit was last modified by.",
            },
            {
              element: ".saveUnitDetailData",
              title: "Save",
              intro: "Click to save any changes made to this unit.",
            });
        }
        else if (issubunit && isUsersListEnable && !isunitComplianEnable && expandUserList  )
        { 
            updateSteps.splice(1, updateSteps.length - 1);     
            updateSteps.push( {
              element: ".unitName",
              title: "Units",
              intro: "Update name of current unit.",
            },
            {
              element: ".unittreesection",
              title: "Sub Units",
              intro: "The sub units of the current unit are displayed here.",
            },{
              element: ".userListSection",
              title: "Accounts in this Unit", intro: "Any accounts that reside in this unit will be displayed here."        
            },
            {
              element: ".includeinactivesuser",
              title: "Accounts in this Unit", intro: "Click here to include inactive users of this Unit."
            },
            {
              element: ".xlxsuser",
              title: "Accounts in this Unit", intro: "Click here to download an export of the accounts that reside in this Unit."
            },
            {
              element: ".lastLoginDetails",
              title: "Login Details",
              intro: "This shows who and when created this unit, as well as who and when the unit was last modified by.",
            },
            {
              element: ".saveUnitDetailData",
              title: "Save",
              intro: "Click to save any changes made to this unit.",
            });
        }
        else if (issubunit && !isUsersListEnable && isunitComplianEnable && expandUnitComplianceList)
        { 
            updateSteps.splice(1, updateSteps.length - 1);     
            updateSteps.push( {
              element: ".unitName",
              title: "Units",
              intro: "Update name of current unit.",
            },
            {
              element: ".unittreesection",
              title: "Sub Units",
              intro: "The sub units of the current unit are displayed here.",
            },{
              element: ".unitComplianceListSection",
              title: "Unit Compliance Report", intro: "This area will display a Unit Compliance Report for this unit, this shows all currently assigned training, in addition to showing the current compliance period OR date-based compliance date, as well as passmark and if these are the default settings of custom to this unit."
            },
            {
              element: ".xlxs",
              title: "Unit Compliance Report", intro: "Click here to download an export of the displayed Unit Compliance Report for this Unit."
            },
            {
              element: ".lastLoginDetails",
              title: "Login Details",
              intro: "This shows who and when created this unit, as well as who and when the unit was last modified by.",
            },
            {
              element: ".saveUnitDetailData",
              title: "Save",
              intro: "Click to save any changes made to this unit.",
            });
        }
        else if (!issubunit && isUsersListEnable && !isunitComplianEnable && expandUserList )
        { 
            updateSteps.splice(1, updateSteps.length - 1);     
            updateSteps.push( {
              element: ".unitName",
              title: "Units",
              intro: "Update name of current unit.",
            },
            {
              element: ".userListSection",
              title: "Accounts in this Unit", intro: "Any accounts that reside in this unit will be displayed here."        
            },
            {
              element: ".includeinactivesuser",
              title: "Accounts in this Unit", intro: "Click here to include inactive users of this Unit."
            },
            {
              element: ".xlxsuser",
              title: "Accounts in this Unit", intro: "Click here to download an export of the accounts that reside in this Unit."
            },
            {
              element: ".lastLoginDetails",
              title: "Login Details",
              intro: "This shows who and when created this unit, as well as who and when the unit was last modified by.",
            },
            {
              element: ".saveUnitDetailData",
              title: "Save",
              intro: "Click to save any changes made to this unit.",
            });
        }
        else if (!issubunit && !isUsersListEnable && isunitComplianEnable && expandUnitComplianceList)
        { 
            updateSteps.splice(1, updateSteps.length - 1);     
            updateSteps.push( {
              element: ".unitName",
              title: "Units",
              intro: "Update name of current unit.",
            },
            {
              element: ".unitComplianceListSection",
              title: "Unit Compliance Report", intro: "This area will display a Unit Compliance Report for this unit, this shows all currently assigned training, in addition to showing the current compliance period OR date-based compliance date, as well as passmark and if these are the default settings of custom to this unit."
            },
            {
              element: ".xlxs",
              title: "Unit Compliance Report", intro: "Click here to download an export of the displayed Unit Compliance Report for this Unit."
            },
            {
              element: ".lastLoginDetails",
              title: "Login Details",
              intro: "This shows who and when created this unit, as well as who and when the unit was last modified by.",
            },
            {
              element: ".saveUnitDetailData",
              title: "Save",
              intro: "Click to save any changes made to this unit.",
            });
        }
        
     
    setIntro({ ...intro, steps: [...updateSteps] });        
  
};

useEffect(() => {   
  setGuideMe(userListOpen,unitComplianceListOpen, unitsPrivileges.length>0 ? true : false,expandUserList , expandUnitComplianceList);
}, [userListOpen, unitComplianceListOpen, unitsPrivileges,expandUserList,expandUnitComplianceList]);


  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
  {
      if (!isNaN(givenDate.getTime())) {
          const year = new Intl.DateTimeFormat('en-AU', {year: 'numeric'}).format(givenDate);
          const month = new Intl.DateTimeFormat('en-AU', {month: 'numeric'}).format(givenDate);
          const day = new Intl.DateTimeFormat('en-AU', {day: 'numeric'}).format(givenDate);
  
          return ((parseInt(day) < 10 ? "0" + day.toString() : day.toString()) + "/" + (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "/" + year.toString());
      }
      else {
          return "";
      }
  }

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  const handleModal = (value:boolean) => {
    setModalOpen(value);
  }


  const handleSubmit = () => {
    let errorString = '';
     
 
    if (errorString !== ''){
      setShowWarningModal(true);
      setModalPopupMessage(errorString);
      setHasErrored(true);
      return;
    }

    setIsSaving(true);
    setSaveClicked(true);
    addOrUpdateUnit(
      "update",
      oidcUser.access_token,
      userId,
      parseInt(orgId),
      orgDomain,
      unitIdToUpdate,
      filterName,
      unitActive ).then((result) => {
      if (result !== null) {
      if (result.isSuccess && result.error == "") {       
        setShowWarningModal(true);
        setModalPopupMessage('Unit updated successfully!');
        setSaveSuccess(true);
        setHasErrored(false);
        setIsSaving(false);
      } else {
        setShowWarningModal(true);
        setModalPopupMessage(result.error);
        setHasErrored(true);
        setIsSaving(false);
        return;
      }
      } else {
        setShowWarningModal(true);
        setModalPopupMessage('Saving record has failed, please try again.');
        setHasErrored(true);
        setIsSaving(false);
        return;
      }
    });
  };

  useEffect(() => {
      
      if (parseInt(unitIdToEdit) > 0) {
      setunitIdToUpdate(parseInt(unitIdToEdit));
      setTitle(allResourcesToTranslations.tabunitdetails);
      setTitleEnglish(pageTitles.unitdetails);
      updateBackButtonRoute("/UnitManagement");      
      setMenuSelection(allResourcesToTranslations.unitmanagement)
      setLoading(true);      
      getOneUnitDetails(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(unitIdToEdit)).then((result) => {
        if (result.isSuccess && result.error == null) {
          setUnitID(result.unitDetailsItems[0].unitID);
          setFilterName(result.unitDetailsItems[0].name);
          setParentUnitID(result.unitDetailsItems[0].parentUnitID);
          setHierarchy(result.unitDetailsItems[0].hierarchy);
          setPathway(result.unitDetailsItems[0].pathway);               
          setUnittActive(result.unitDetailsItems[0].active);
          setOrganisationID(result.unitDetailsItems[0].organisationID);
          setOrganisation(result.unitDetailsItems[0].organisation);          
          let createdDate = formatDate(new Date(result.unitDetailsItems[0].dateCreated));
          setDateCreated(createdDate);        
          let updatedDate = formatDate(new Date(result.unitDetailsItems[0].dateUpdated));
          setDateUpdated(updatedDate);               
          setCreatedByName(result.unitDetailsItems[0].createdByUserName);
          setUpdatedByName(result.unitDetailsItems[0].updatedByUserName);    
          setShowLastUpdatedRow(result.unitDetailsItems[0].updatedByUserName !== '');

          getUnitTreeByUnit(oidcUser.access_token,
             userId, orgDomain, parseInt(orgId),'','','',parseInt(unitIdToEdit) ).then((result:any) => {         
                if (result.isSuccess && result.error == null) {        
                let unitsPrivilegeArray = [...result.unitTreeItems];   
                    const setSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
                      if (unitArray === null) return //Break if no children
                      if (unitArray.length === 0) return
                      for (let i = 0; i < unitArray.length; i++) {
                        const unit = unitArray[i];
                        unit.isDisabled = true; 
                        unit.isExpanded = true;
                        unit.isSelected = true;
                        setSelectedUnitIds(unit.childUnitTreeItems);
                      }
                    }
                    setSelectedUnitIds(unitsPrivilegeArray);
                    setUnitsPrivileges([...unitsPrivilegeArray]);
                }
          });    
          
          getFullUserDetailReport(oidcUser.access_token, userId, orgDomain, parseInt(orgId), unitIdToEdit,false
          ).then((response) => {
            if (
              response !== null &&
              response !== undefined &&
              response.isSuccess &&
              response.userDetailReportReportItems !== null &&
              response.userDetailReportReportItems !== undefined
            ) {
              
              if(response.userDetailReportReportItems.length > 0) {
                let updatedUsersList = response.userDetailReportReportItems.map((item: any) => {
                  return {
                    ...item,
                    isChecked: false,
                  };
                });
                setUsersList(updatedUsersList);
                setUserListOpen(true);             
                
                let element = document.getElementById("userList");
                if (!!element)
                  element.scrollIntoView();        
              } 
            } 
          });
         

          getFullUnitComplianceReport(
            oidcUser.access_token, userId, orgDomain, parseInt(orgId),
            unitIdToEdit, false          
          ).then((response) => {
            if (
              response !== null &&
              response !== undefined &&
              response.isSuccess &&
              response.unitComplianceReportItems !== null &&
              response.unitComplianceReportItems !== undefined
            ) {
              
              if(response.unitComplianceReportItems.length > 0) {
                let updatedUnitComplianceList = response.unitComplianceReportItems.map((item: any) => {
                  return {
                    ...item,
                    isChecked: false,
                  };
                });
                setUnitComplianceList(updatedUnitComplianceList);
                setUnitComplianceListOpen(true);              
                let element = document.getElementById("unitComplianceReportList");
                if (!!element)
                  element.scrollIntoView();
        
              } 
            } else {                          
              return;
            }
          });
       
          setLoading(false);      
        }
      });
      
    }


  },[])

  useEffect(() => {
    if (units.length > 0) {
      setUnits(units);
    }    
  }, [units, unitID]);


  useEffect(() => {
    if(showWarningModal) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      },5000)
    }
  },[showWarningModal, unitActive])

const updateSelectedUnit = (id: number) => {
  let updatedUnits = unitsPrivileges;
  const updateSelected = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return //Break if no children
      if (unitArray.length === 0) return
      for (let i = 0; i < unitArray.length; i++) {
          const unit = unitArray[i];
          if (unit.unitID === id) {
              unit.isSelected = !unit.isSelected
          } 
          updateSelected(unit.childUnitTreeItems);
      }
  }
  updateSelected(updatedUnits);
  setInteractionCount(interactionCount + 1);
  setUnitsPrivileges(updatedUnits);
}

const updateExpandedUnit = (id: number) => {
  let updatedUnits = unitsPrivileges;
  const checkForExpanded = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return //Break if no children
      if (unitArray.length === 0) return
      for (let i = 0; i < unitArray.length; i++) {
          const unit = unitArray[i];
          if (unit.unitID === id) {
              unit.isExpanded = !unit.isExpanded
          } 
          checkForExpanded(unit.childUnitTreeItems)
      }
  }
  checkForExpanded(updatedUnits)
  setInteractionCount(interactionCount + 1)
  setUnitsPrivileges(updatedUnits);
}

const selectAllChildUnits = (id: number) => {
  let updatedUnits = unitsPrivileges;
  let parentList = [id];
  const updateAllChildUnits = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return //Break if no children
      if (unitArray.length === 0) return
      for (let i = 0; i < unitArray.length; i++) {
          const unit = unitArray[i];
          if (parentList.includes(unit.parentUnitID) || unit.unitID === id) {
              parentList.push(unit.unitID)
              unit.isSelected = true;
          }
          updateAllChildUnits(unit.childUnitTreeItems)
      }
  }
  updateAllChildUnits(updatedUnits)
  setInteractionCount(interactionCount + 1)
  setUnitsPrivileges(updatedUnits);
}

const deselectAllChildUnits = (id: number) => {
  let updatedUnits = unitsPrivileges;
  let parentList = [id]
  const updateAllChildUnitsToUnselected = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return //Break if no children
      if (unitArray.length === 0) return
      for (let i = 0; i < unitArray.length; i++) {
          const unit = unitArray[i];
          if (parentList.includes(unit.parentUnitID) || unit.unitID === id) {
              parentList.push(unit.unitID)
              unit.isSelected = false;
          }
          updateAllChildUnitsToUnselected(unit.childUnitTreeItems)
      }
  }
  updateAllChildUnitsToUnselected(updatedUnits)
  setInteractionCount(interactionCount + 1)
  setUnitsPrivileges(updatedUnits);
}

const isAnyUnitSelected = (givenUnits: Array<UnitTreeItem> | null) => {
  let updatedUnits = givenUnits
  const checkIfAnyUnitSelected = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return false //Break if no children
      if (unitArray.length === 0) return false;
      for (let i = 0; i < unitArray.length; i++) {
          const unit = unitArray[i];
          if (unit.isSelected) {
              return true;
          }
          if (checkIfAnyUnitSelected(unit.childUnitTreeItems)) {
              return true;
          }
      }
      return false;
  }
  return checkIfAnyUnitSelected(updatedUnits);
}

const handleExpandClick = () => {		 
  setExpandUserList(!expandUserList);
}
const handleUnitComplianceExpandClick = () => {		
  setExpandUnitComplianceList(!expandUnitComplianceList);
}


  if (loading) return <LoaderContainer />;

  return (
    <>

      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }}
      />
      <CustomTooltip displayText={["Guide Me"]}>
        <span
          onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific}
        >
          <GuideIcon />
        </span>
      </CustomTooltip>
    
      <div className={styles.mainWrapper}>
        <div className={styles.Wrapper}>
          <div className={"unitDetails"} style={{display:'flex', justifyContent:'center', alignItems:'center', border:'1px solid #D3D6DF', height:'42px', borderRadius:'5px'}}>
            <div>
              <p>{allResourcesToTranslations.unitid}: {unitIdToUpdate}&nbsp;|&nbsp;{allResourcesToTranslations.unitpathwaydetails}: {pathway}</p>            
            </div>
            <div className="checkInactive">
                <Checkbox
                  size="small"
                  color="primary"
                  checked={unitActive}
                  className={styles.checkBox}
                  onClick={() => setUnittActive(!unitActive)}
                />{" "}
                <span className={unitActive ? styles.accountActiveText : styles.accountInactiveText} >{unitActive ? allResourcesToTranslations.activeunit : allResourcesToTranslations.inactiveunit}</span>
           </div>
          </div>         
        </div>  
        <div className={styles.Wrapper}>
            <p
              style={{
                fontWeight: 700,
                color: "#D3D6DF",
                fontSize: "14px",
                fontStyle: "italic",
                marginTop: "5px",
              }}>
              {allResourcesToTranslations.addunitdetails}
            </p>
            <div className="unitName">
                <TextField
                  className={styles.input}
                  label={"Unit Name"}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  value={filterName}
                  onChange={(e) => setFilterName(e.target.value)}
                />
              </div>
        </div> 
        
        
        {unitsPrivileges.length>0 &&
        <div className={styles.Wrapper} style={{ paddingTop: "30px"}}>
          <div className={`${styles.unittreesection} unittreesection`}  > 
              <b>{allResourcesToTranslations.subunit}</b> 
              <div className={styles.treeContianer}>  
              <UnitTree units={unitsPrivileges} updateSelectedUnit={updateSelectedUnit} updateExpandedUnit={updateExpandedUnit} selectAllChildUnits={selectAllChildUnits} deselectAllChildUnits={deselectAllChildUnits} isAnyUnitSelected={isAnyUnitSelected} ></UnitTree> 
              </div>
          </div>
        </div>      
        }

        <div style={{ paddingTop: "20px"}}>
          {userListOpen &&
            <Accordion style={{ marginTop: "10px", position: 'inherit', boxShadow: "none" }}
                expanded={expandUserList}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="unitadmin-content"
                id="unitadmin-header"   
                style={{borderBottom: '1px solid #D3D6DF', padding:"0px"}}        
                onClick={(e) => handleExpandClick()}
              >
              
              <Typography ><b>{allResourcesToTranslations.accountsinunit}</b></Typography>
              </AccordionSummary>
              <AccordionDetails style={{padding: "0px"}}>              
                {userListOpen && usersList != null && usersList.length > 0 && (
                  <div id="userList" style={{width: "100%"}}>
                    <UserListSection usersData={usersList} unitIdToEdit={unitIdToEdit}/>
                  </div>
                )}         
              </AccordionDetails>
            </Accordion>        
          }  
          {unitComplianceListOpen &&
            <Accordion style={{ margin: "0px 0px", position: 'inherit', boxShadow: "none" }}
                expanded={expandUnitComplianceList}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="unitadmin-content"
                id="unitadmin-header"   
                style={{borderBottom: '1px solid #D3D6DF', padding:"0px"}}        
                onClick={(e) => handleUnitComplianceExpandClick()}
              >
              
              <Typography ><b>{allResourcesToTranslations.unitcompliancereport}</b></Typography>
              </AccordionSummary>
              <AccordionDetails style={{padding: "0px"}}>              
                {unitComplianceListOpen && unitComplianceList != null && unitComplianceList.length > 0 && (
                  <div id="unitComplianceReportList" style={{width: "100%"}}>
                    <UnitComplianceReportSection unitComplianceData={unitComplianceList}/>
                  </div>
                )}         
              </AccordionDetails>
            </Accordion>
            }
        </div>

        <div className={styles.Wrapper}>
          <div className="lastLoginDetails">
                <table>
                  <tbody>
                    <tr style={{height:'30px'}}>
                        <td style={{fontSize:'14px', fontWeight:700, color:'#767676'}}>{allResourcesToTranslations.createdby}:</td>
                        <td style={{paddingLeft:'10px'}}>{createdByName} on {dateCreated} </td>
                    </tr> 
                    <tr style={{height: showLastUpdatedRow ? '30px' : '0px'}}>
                      <td style={{fontSize:'14px', fontWeight:700, color:'#767676'}}>{showLastUpdatedRow ? allResourcesToTranslations.lastmodifiedby :''} </td>
                      <td style={{paddingLeft:'10px'}}>{showLastUpdatedRow ? `${updatedByName} on ${dateUpdated}` :'' } </td>
                  </tr>                                  
                    </tbody>
                </table>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", paddingTop: "10px"  }}>         
            <CustomTooltip displayText={["Save"]}>
              <span  className="saveUnitDetailData">
                <Button
                  disabled={ isSaving || (filterName == "")}
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  className="save" >
                  <SaveIcon />
                </Button>
              </span>
            </CustomTooltip>
            <div className={styles.loaderContainer}>
              <CircularProgress
                style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
                color="primary"
                size="10"
              />
            </div>
        </div>
      </div>
      <div className={styles.Wrapper}>



     
      </div>
      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
    </>
  );
};

export default UnitDetails;
