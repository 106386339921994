import axios from 'axios';
import { addError } from './AddError';

export const getEndQuizInfo = async (token: string, userId: number, orgDomain: string, quizSessionId: string, duration: number = 46664, score: number, uniqueID: string) => {

    try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/usersessions/GetEndQuizInfo`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        quizSessionid: quizSessionId,
        duration: duration, // This is a hardcoded value in the original system too :'(
        score: score,
        UniqueID: uniqueID 
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "GetEndQuizInfo.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "GetEndQuizInfo.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "GetEndQuizInfo.ts", 1)
    }
    return null;
  }
};

export default getEndQuizInfo;
