import React from 'react'
import SideMenuDivider from './SideMenuDivider'

interface Props {
    showAdminMenuItems: boolean
    children: React.ReactNode
}

const SideMenuAdmin = ({ showAdminMenuItems, children }: Props) => {
    if (!showAdminMenuItems) return null
    return (
        <>
            <SideMenuDivider sectionName="Administration"/>
            {children} 
        </>
    )
}

export default SideMenuAdmin
