//React + Typescript
import React, { useState, useContext } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { UIContext } from '../../contexts/UIContext';
//API Functions
import { addError } from '../../API/AddError'
import { updatePolicyAsAccepted } from '../../API/UpdatePolicyAsAccepted'
//Components
import Modal from '../../Components/Modal';
import PDFPlayer from '../../Players/PDFPlayer/PDFPlayer'
import { MyTrainingListContext } from '../../contexts/MyTrainingListContext'
//MUI Components
import Button from '@material-ui/core/Button';
import UndoIcon from '@material-ui/icons/Undo';
import CheckIcon from '@material-ui/icons/Check';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
//Styles
import styles from '../../styles/pdfplayer.module.css';
import DocumentViewer from '../../Players/DocumentViewer/DocumentViewer';

enum StatusEnum { //Typescript doesnt allow this to be exported from global type files unfortunatley
    //Course Enums
    None = -1,
    Unassigned = 0,
    Incomplete = 1,
    Complete = 2,
    //Policy Emums
    NotAccepted = 4,
    Acceped = 5,
    'Not Accepted' = NotAccepted // Creating alias
  }

export const PolicyViewerWrapper = (props: any): JSX.Element => {
    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
    const { setTrainingListUpdateRequired, setDashboardUpdateRequired } = useContext(MyTrainingListContext)
    const { oidcUser } = useReactOidc();
    const { EnableActivityLogs} = useContext(UIContext);
    const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0;
    const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const history = useHistory();
    const policyId: number = parseInt(props.match.params.policyId);
    const [ modalOpen, setModalOpen] = useState<boolean>(false);
    
    if (!props.location.state) return <Redirect to={`/mytraining`} push />;
    const { policyFileName, organisationId, confirmationMessage, status } = props.location.state;

    const handleExit = () => {
        addError(oidcUser.access_token, userId, orgDomain, "Exited Policy Viewer", null, "PolicyViewerWrapper.tsx", 6, EnableActivityLogs)
        history.goBack()
      };

    const handleGoBack = () => {
        addError(oidcUser.access_token, userId, orgDomain, "Navigated back to view policy", null, "PolicyViewerWrapper.tsx", 6, EnableActivityLogs)
        setModalOpen(false)
    }

    const handleAcceptButton = () => {
        addError(oidcUser.access_token, userId, orgDomain, "Opened accept popup", null, "PolicyViewerWrapper.tsx", 6, EnableActivityLogs)
        setModalOpen(true)
    }

    const handleAcceptAndExit = () => {
      addError(oidcUser.access_token, userId, orgDomain, "Reviewed and accepted policy", null, "PolicyViewerWrapper.tsx", 6, EnableActivityLogs)
        if (userId) {
          setTrainingListUpdateRequired(true)
          setDashboardUpdateRequired(true)
          updatePolicyAsAccepted(oidcUser.access_token, userId, orgDomain, policyId)
          history.goBack()
        }
    };

    const renderAcceptButton = (status: number) => {
        if (status !== StatusEnum.Acceped) {
            return(
                <Button
                id="acceptButton"
                onClick={() => handleAcceptButton()}
                variant="contained"
                color="primary"
              >
                <PlaylistAddCheckIcon />
                { allResourcesToTranslations.reviewandaccept }
              </Button>
            )
        }
    }

    const renderModal = (show: boolean) : JSX.Element | void => {
        if (show) {
            return (
              <div>
                <Modal modalOpen={true} setModalOpen={setModalOpen} type = "warning">
                  <p>{confirmationMessage}</p>
                  <div className={styles.acceptBtnContainer}>
                    <Button
                      id="goBackButton"
                      onClick={() => handleGoBack()}
                      variant="contained"
                      color="primary"
                    >
                      <UndoIcon />
                      { allResourcesToTranslations.goback }
                    </Button>
                    <Button
                      id="AcceptAndExit"
                      onClick={() => handleAcceptAndExit()}
                      variant="contained"
                      color="primary"
                    >
                      <CheckIcon />
                      { allResourcesToTranslations.acceptandexit }
                    </Button>
                  </div>
                </Modal>
              </div>
            );
        }
    }

    const getExtension = (filename: string) => {  
      return filename.split('.').pop()?.toLowerCase();
    }

    return (
      <>
        {getExtension(policyFileName) === "pdf" &&
        <PDFPlayer
        url={`/General/Policy/${organisationId}/${policyFileName}`}
        />}
        {/* Note: DocumentViewer not able to open document in localhost due to microsoft app */}
        {getExtension(policyFileName) !== "pdf" &&
         <DocumentViewer
          url={orgDomain + `/General/Policy/${organisationId}/${policyFileName}`}
        /> }
        <div className={styles.controls}>
          <Button
            id="exitButton"
            onClick={() => handleExit()}
            variant="contained"
            color="primary"
          >
            <ExitToAppIcon />
            { allResourcesToTranslations.exit }
          </Button>
        {renderAcceptButton(status)}
        </div>
        {renderModal(modalOpen)}
      </>
    ); 
}

export default PolicyViewerWrapper;
