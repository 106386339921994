import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const CloseIcon = () => (
//     <svg width="24" height="24" viewBox="0 0 24 24" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
// <g clipPath="url(#clip0_9_72)">
// <line x1="1.06066" y1="2.61024" x2="18.9742" y2="20.5237" stroke="#FFFFFF" stroke-width="3"/>
// <line x1="0.731466" y1="20.5237" x2="18.645" y2="2.61018" stroke="#FFFFFF" stroke-width="3"/>
// </g>
// <defs>
// <clipPath id="clip0_9_72">
// <rect width="24" height="24" fill="white"/>
// </clipPath>
// </defs>
// </svg>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M20.0607 22.0987L2.14718 4.18516L4.2685 2.06384L22.182 19.9773L20.0607 22.0987Z" fill="#FFFFFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M21.8528 4.18523L3.93931 22.0987L1.81799 19.9774L19.7315 2.06391L21.8528 4.18523Z" fill="#FFFFFF"/>
</svg>
  
);

export default CloseIcon
