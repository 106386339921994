import React from 'react';
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 20,
      borderRadius: 10,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 50 : 700],
      color:'red'
    },
    bar: {
      borderRadius: 10,
      backgroundColor: '#3CB371',
      boxShadow: 'inset 0 0 10px green',
    },
    bar1Determinate: {
        color: 'green'
    }
  }),
)(LinearProgress);

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
  return (
    <div>
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <BorderLinearProgress variant="determinate" {...props} ></BorderLinearProgress>
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value
        )}%`}</Typography>
      </Box>
    </Box>
    </div>
  );
}

export default LinearProgressWithLabel;