//React + Typescript
import React, { useState, useContext, useEffect } from "react";
import { PolicyBuilderReportResultItem } from "../../globalTypes";
import { PolicyBuilderTotalsReportItems } from "../../globalTypes";
import { PolicyBuilderGrandTotalReportItems } from "../../globalTypes";
// Libs
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { Steps, Hints } from "intro.js-react";
//Context
import { LoaderContext } from "../../contexts/LoaderContext";
import { CorperateThemeContext } from "../../contexts/CorperateThemeContext";
import { UIContext } from "../../contexts/UIContext";
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Components
import BrandedCard from "../../Components/BrandedCard";
import LoaderContainer from "../../Components/LoaderContainer";
import CustomTooltip from "../../Components/CustomTooltip";

//General Functions
import getColor from "../../functions/getColor";
import serialToArray from "../../functions/serialToArray";
import { getCurrentTimeDDMMYYYYFormat } from "../../functions/getCurrentTimeDDMMYYYYFormat";
//API functions
import { getExportedXLSXData } from "../../API/GetExportedXLSXData";
import { getExportedCSVData } from "../../API/GetExportedCSVData";
import { getExportedPDFData } from "../../API/GetExportedPDFData";
import { addError } from "../../API/AddError";
//Components
import ExcelFileIcon from "../../CustomIcons/ExcelFileIcon";
import CSVFileIcon from "../../CustomIcons/CSVFileIcon";
import PDFIcon from "../../CustomIcons/PDFFileIcon";
import UpArrowIcon from "../../CustomIcons/UpArrowIcon";
import DownArrowIcon from "../../CustomIcons/DownArrowIcon";
//MUI Components
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import CalendarViewDayIcon from "@material-ui/icons/CalendarViewDay";
import BrushIcon from "@material-ui/icons/Brush";

// Style
import styles from "../../styles/currentAdminReport.module.css";
import GuideIcon from "../../CustomIcons/GuideIcon";
import ActiveIcon from "../../CustomIcons/Status_ActiveIcon";
import InActiveIcon from "../../CustomIcons/Status_InActiveIcon";


const PolicyBuilderReportResult = (props: any) => {
  const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, family_name, given_name, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";
  const {
    theme: { color },
  } = useContext(CorperateThemeContext);
  const { setMessage } = useContext(LoaderContext);
  const [policyBuilderReport, setpolicyBuilderReportResult] = useState<
    Array<PolicyBuilderReportResultItem>
  >(props?.location?.state?.resultItems ?? []);

  const [policyBuilderTotalsReport, setpolicyBuilderTotalsReportResult] = useState<
  Array<PolicyBuilderTotalsReportItems>
>(props?.location?.state?.resultTotalItems ?? []);

const [policyBuilderGrandTotalReport, setpolicyBuilderGrandTotalReportResult] = useState<
Array<PolicyBuilderGrandTotalReportItems>
>(props?.location?.state?.resultGrandTotalItems ?? []);

const [policyAcceptance, setPolicyAcceptance] = useState<string>(props?.location?.state?.policyAcceptance ?? "");
const [filterDateFrom, setFilterDateFrom] = useState<string>(props?.location?.state?.filterDateFrom ?? "");
const [filterDateTo, setFilterDateTo] = useState<string>(props?.location?.state?.filterDateTo ?? "");

const [policyIDs, setPolicyIDs] = useState<string>(props?.location?.state?.policyIDs ?? "");
const [unitIDs, setUnitIDs] = useState<string>(props?.location?.state?.unitIDs ?? "");


  const [exporting, setExporting] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true);
  const [currentSortBy, setCurrentSortBy] = useState<string>("");
  const [openCriteria, setOpenCriteria] = useState(false);
  const [sortInProgress, setSortInProgress] = useState<boolean>(false);
  const [prevPolicyName, setPrevPolicyName] = useState<string>("");
  const [policyDescending, setPolicyDescending] = useState<boolean>(false);
  const [unitPathwayDescending, setUnitPathwayDescending] = useState<boolean>(false);
  const [lastNameDescending, setLastNameDescending] = useState<boolean>(false); 
  const [firstNameDescending, setFirstNameDescending] = useState<boolean>(false);
  const [emailDescending, setEmailDescending] = useState<boolean>(false);
  const [userIDDescending, setUserIDDescending] = useState<boolean>(false);
  const [acceptedDescending, setAcceptedDescending] = useState<boolean>(false);
  const [dateAcceptedDescending, setDateAcceptedDescending] = useState<boolean>(false);
  const [assignedDateUserLevelDescending, setAssignedDateUserLevelDescending] = useState<boolean>(false);
  const [assignedDateUnitLevelDescending, setAssignedDateUnitLevelDescending] = useState<boolean>(false);



  const [reverseNextSort, setReverseNextSort] = useState<boolean>(false);
  const [reportRunTimeTaken, setReportRunTimeTaken] = useState<string>("");
  const { setTitle, setTitleEnglish, updateBackButtonRoute, EnableActivityLogs } = useContext(UIContext);
  const [exportError, setExportError] = useState<string>("");
  const [resultError, setResultError] = useState<string>(
    props?.location?.state?.resultError ?? ""
  );
  const [includeInactiveUsers, setIncludeInactive] = useState<boolean>(
    props?.location?.state?.includeInactiveUsers ?? false
  );
  const [selectedUnitNames, setSelectedUnitNames] = useState<string>(
    props?.location?.state?.selectedUnits ?? []
  );
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".policyBuilderReportTotal",
		    title: "Total Policies",
        intro: "Policy, name of policy. Total Users assigned the relevant policy, those who have Accepted and those who have yet to accept the policy, 'Not Accepted'"
        
      },      
    ]
  })

  useEffect(() => 
  {
      let updateSteps = [...intro.steps];
        updateSteps.push( 
        {
        element: ".policyBuilderReportList",
        title: "Report Data", intro: allResourcesToTranslations.emrepprvgmmrepdta
        },
        {
        element: ".xlxs",
        title: "Export", intro:allResourcesToTranslations.sntemrepgmmexpxls
        },
        {
        element: ".csv",
        title: "Export", intro:allResourcesToTranslations.sntemrepgmmexpcsv
        },
        {
            element: ".pdf",
            title: "Export", intro:allResourcesToTranslations.sntemrepgmmexppdf
        }
        // {
        //     element: ".rowShading",
        //     intro: "Click icon to Show/Hide Row Shading, also applies to Export XLSX file."
        // }           
    );
  setIntro({...intro, steps: [...updateSteps]});
  },[])
  
  const onExit = () => {
    setIntro({...intro, stepsEnabled: false });
  };


 // const toggleBanding = () => setEnableRowBanding(!enableRowBanding);

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
    } else {
      return {};
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const GetDate_yymmdd = () => {
    var d =  new Date(Date.now());
    let day = d.getFullYear().toString().substring(2,4)  + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0")
     return day;         
  }

  const buildRequestStringArray = () => {
    let resultArray: Array<string> = [];
    for (let i = 0; i < policyBuilderReport.length; i++) {
        const r = policyBuilderReport[i];        
        resultArray.push(`${r.unitPathway}<#>${r.lastName}<#>${r.firstName}<#>${r.email}<#>${r.policy}<#>${r.accepted}<#>${r.dateAcceptedTxt}<#>${r.assignedDateUnitLevelTxt}<#>${r.assignedDateUserLevelTxt}<#>${r.active}`);     
    }
    return resultArray; 
  };
  

  const buildRequestStringArrayExtraSummary = () => {
    //debugger
    let resultArray: Array<string> = [];
    for (let i = 0; i < policyBuilderTotalsReport.length; i++) {
        const r = policyBuilderTotalsReport[i];        
        if (!!policyAcceptance && policyAcceptance.trim().toLowerCase() === "both") {
          resultArray.push(`${r.policy}<#>${r.totalusers}<#>${r.totalaccepted}<#>${r.totalnotaccepted}<#>${r.active}`);     
        }
        else if (!!policyAcceptance && policyAcceptance.trim().toLowerCase() === "accepted") {
          resultArray.push(`${r.policy}<#>${r.totalusers}<#>${r.totalaccepted}<#>${r.active}`);     
        }
        else if (!!policyAcceptance && policyAcceptance.trim().toLowerCase() === "not_accepted") {
          resultArray.push(`${r.policy}<#>${r.totalusers}<#>${r.totalnotaccepted}<#>${r.active}`);     
        }
    }
    for (let i = 0; i < policyBuilderGrandTotalReport.length; i++) {
        const r = policyBuilderGrandTotalReport[i];          
        if (!!policyAcceptance && policyAcceptance.trim().toLowerCase() === "both") {
          resultArray.push(`Grand Total<#>${r.grandTotalUsers.toString()}<#>${r.grandTotalAccepted.toString()}<#>${r.grandTotalNotaccepted.toString()}<#>${""}`);     
        }
        else if (!!policyAcceptance && policyAcceptance.trim().toLowerCase() === "accepted") {
          resultArray.push(`Grand Total<#>${r.grandTotalUsers.toString()}<#>${r.grandTotalAccepted.toString()}<#>${""}`);          
        }
        else if (!!policyAcceptance && policyAcceptance.trim().toLowerCase() === "not_accepted") {
          resultArray.push(`Grand Total<#>${r.grandTotalUsers.toString()}<#>${r.grandTotalNotaccepted.toString()}<#>${""}`);     
        }            
    }
    return resultArray; 
  };

  const handleXLSXExport = () => {
    setMessage("Exporting XLSX");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started Policy Builder Report XLSX-EXPORT in NEWSALT ClientApp",
      null,
      "PolicyBuilderReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), 10, "PolicyBuilderReport", enableRowBanding, "","",filterDateFrom,filterDateTo,includeInactiveUsers,false, "", "", "", "", "", "", "",false,includeInactiveUsers,1,"","",-1,-1,unitIDs,policyIDs,policyAcceptance,buildRequestStringArrayExtraSummary(),(!!policyAcceptance && policyAcceptance.trim().toLowerCase() === "both") ? 5 : 4).then((xlxsRes) => {
        if (xlxsRes.isSuccess) {
            const fileData = xlxsRes.xlsxDataOutput.fileData
            var a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(
                new Blob([serialToArray(atob(fileData))], {
                    type: ''
                })
            );

            a.download = "PolicyBuilderReport-"+ GetDate_yymmdd() + ".xlsx" ;
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)

        }
        else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
            setExportError(xlxsRes.error);
        }
        setExporting(false);
        // Activity log
        addError(oidcUser.access_token, userId, orgDomain, "Completed Policy Builder Report XLSX-EXPORT in NEWSALT ClientApp", null, "PolicyBuilderReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const handleCSVExport = () => {
    setMessage("Exporting CSV");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started Policy Builder Report CSV-EXPORT in NEWSALT ClientApp",
      null,
      "PolicyBuilderReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), 10, "PolicyBuilderReport", "","",filterDateFrom,filterDateTo,includeInactiveUsers,false, "",false, "", "", "", "", "", "",false,includeInactiveUsers,1,"","",-1,-1,unitIDs,policyIDs,policyAcceptance,buildRequestStringArrayExtraSummary(),(!!policyAcceptance && policyAcceptance.trim().toLowerCase() === "both") ? 5 : 4).then((csvRes) => {
        if (csvRes.isSuccess) {
            const fileData = csvRes.csvDataOutput.fileData
            var a = window.document.createElement('a');

            a.href = window.URL.createObjectURL(
                new Blob([serialToArray(atob(fileData))], {
                    type: ''
                })
            )
            a.download = "PolicyBuilderReport-"+ GetDate_yymmdd() + ".csv" ;
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)

        }
        else if (csvRes.error !== undefined && csvRes.error !== null) {
            setExportError(csvRes.error);
        }
        setExporting(false)
        // Activity log
        addError(oidcUser.access_token, userId, orgDomain, "Completed Policy Builder Report CSV-EXPORT in NEWSALT ClientApp", null, "PolicyBuilderReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const handlePDFExport = () => {
    setMessage("Exporting PDF");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started Policy Builder Report PDF-EXPORT in NEWSALT ClientApp",
      null,
      "PolicyBuilderReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedPDFData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), 10, "PolicyBuilderReport", enableRowBanding,"","",filterDateFrom,filterDateTo,includeInactiveUsers,false, "", "", "", "", "","","",false,includeInactiveUsers,1,"","",-1,-1,unitIDs,policyIDs,policyAcceptance,buildRequestStringArrayExtraSummary(),(!!policyAcceptance && policyAcceptance.trim().toLowerCase() === "both") ? 5 : 4).then((pdfRes) => {
        if (pdfRes.isSuccess) {
            const fileData = pdfRes.pdfDataOutput.fileData
            var a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(
                new Blob([serialToArray(atob(fileData))], {
                    type: ''
                })
            )
            a.download = "PolicyBuilderReport-"+ GetDate_yymmdd() + ".PDF" ;
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)
        }
        else if (pdfRes.error !== undefined && pdfRes.error !== null) {
            setExportError(pdfRes.error);
        }
        setExporting(false)
        // Activity log
        addError(oidcUser.access_token, userId, orgDomain, "Completed Policy Builder Report PDF-EXPORT in NEWSALT ClientApp", null, "PolicyBuilderReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const sortResultsByAsync = async (
    sortBy:
      | "Policy"
      | "UnitPathway"
      | "LastName"
      | "FirstName"
      | "Email"
      | "Accepted"
      | "LastDateAccepted"
      | "AssignedDateUserLevel"
      | "AssignedDateUnitLevel",

    sortGivenItemInDesc: boolean
  ) => {
    if (policyBuilderReport == null || policyBuilderReport.length <= 0) {
      return policyBuilderReport;
    }
    var result = policyBuilderReport.slice(0);
    setCurrentSortBy(sortBy);
    switch (sortBy) {
      case "UnitPathway":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || !!b.unitPathway === false) {
              return -1;
            }
            if (a === null || !!a.unitPathway === false) {
              return 1;
            }
            if (a.unitPathway.trim().toLowerCase() > b.unitPathway.trim().toLowerCase()) {
              return -1;
            }
            if (a.unitPathway.trim().toLowerCase() < b.unitPathway.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitPathwayDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || !!b.unitPathway === false) {
              return 1;
            }
            if (a === null || !!a.unitPathway === false) {
              return -1;
            }
            if (a.unitPathway.trim().toLowerCase() > b.unitPathway.trim().toLowerCase()) {
              return 1;
            }
            if (a.unitPathway.trim().toLowerCase() < b.unitPathway.trim().toLowerCase()) {
              return -1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitPathwayDescending(true);
          }
        }
        setpolicyBuilderReportResult(result);
        break;     
      case "Policy":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.policy === null) {
              return -1;
            }
            if (a === null || a.policy === null) {
              return 1;
            }
            if (a.policy.trim().toLowerCase() > b.policy.trim().toLowerCase()) {
              return -1;
            }
            if (a.policy.trim().toLowerCase() < b.policy.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setPolicyDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.policy === null) {
              return 1;
            }
            if (a === null || a.policy === null) {
              return -1;
            }
            if (a.policy.trim().toLowerCase() < b.policy.trim().toLowerCase()) {
              return -1;
            }
            if (a.policy.trim().toLowerCase() > b.policy.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setPolicyDescending(true);
          }
        }
        setpolicyBuilderReportResult(result);
        break;
      case "LastName":
          if (sortGivenItemInDesc) {
            result.sort((a, b) => {
              if (b === null || b.lastName === null) {
                return -1;
              }
              if (a === null || a.lastName === null) {
                return 1;
              }
              if (a.lastName.trim().toLowerCase() > b.lastName.trim().toLowerCase()) {
                return -1;
              }
              if (a.lastName.trim().toLowerCase() < b.lastName.trim().toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setLastNameDescending(false);
            }
          } else {
            result.sort((a, b) => {
              if (b === null || b.lastName === null) {
                return 1;
              }
              if (a === null || a.lastName === null) {
                return -1;
              }
              if (a.lastName.trim().toLowerCase() < b.lastName.trim().toLowerCase()) {
                return -1;
              }
              if (a.lastName.trim().toLowerCase() > b.lastName.trim().toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setLastNameDescending(true);
            }
          }
          setpolicyBuilderReportResult(result);
          break;
      case "FirstName":
          if (sortGivenItemInDesc) {
            result.sort((a, b) => {
              if (b === null || b.firstName === null) {
                return -1;
              }
              if (a === null || a.firstName === null) {
                return 1;
              }
              if (a.firstName.trim().toLowerCase() > b.firstName.trim().toLowerCase()) {
                return -1;
              }
              if (a.firstName.trim().toLowerCase() < b.firstName.trim().toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setFirstNameDescending(false);
            }
          } else {
            result.sort((a, b) => {
              if (b === null || b.firstName === null) {
                return 1;
              }
              if (a === null || a.firstName === null) {
                return -1;
              }
              if (a.firstName.trim().toLowerCase() > b.firstName.trim().toLowerCase()) {
                return 1;
              }
              if (a.firstName.trim().toLowerCase() < b.firstName.trim().toLowerCase()) {
                return -1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setFirstNameDescending(true);
            }
          }
          setpolicyBuilderReportResult(result);
          break;
      case "Email":
            if (sortGivenItemInDesc) {
              result.sort((a, b) => {
                if (b === null || b.email === null) {
                  return -1;
                }
                if (a === null || a.email === null) {
                  return 1;
                }
                if (a.email.trim().toLowerCase() > b.email.trim().toLowerCase()) {
                  return -1;
                }
                if (a.email.trim().toLowerCase() < b.email.trim().toLowerCase()) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setEmailDescending(false);
              }
            } else {
              result.sort((a, b) => {
                if (b === null || b.email === null) {
                  return 1;
                }
                if (a === null || a.email === null) {
                  return -1;
                }
                if (a.email.trim().toLowerCase() < b.email.trim().toLowerCase()) {
                  return -1;
                }
                if (a.email.trim().toLowerCase() > b.email.trim().toLowerCase()) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setEmailDescending(true);
              }
            }
            setpolicyBuilderReportResult(result);
            break;
      case "Accepted":
          if (sortGivenItemInDesc) {
            result.sort((a, b) => {
              if (b === null || b.accepted === null) {
                return -1;
              }
              if (a === null || a.accepted === null) {
                return 1;
              }
              if (a.accepted.trim().toLowerCase() > b.accepted.trim().toLowerCase()) {
                return -1;
              }
              if (a.accepted.trim().toLowerCase() < b.accepted.trim().toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setAcceptedDescending(false);
            }
          } else {
            result.sort((a, b) => {
              if (b === null || b.accepted === null) {
                return 1;
              }
              if (a === null || a.accepted === null) {
                return -1;
              }
              if (a.accepted.trim().toLowerCase() < b.accepted.trim().toLowerCase()) {
                return -1;
              }
              if (a.accepted.trim().toLowerCase() > b.accepted.trim().toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setAcceptedDescending(true);
            }
          }
          setpolicyBuilderReportResult(result);
          break;
      case "LastDateAccepted":
            if (sortGivenItemInDesc) {
                result.sort((a, b) => {
                    if (b === null || b.dateAcceptedTxt === null || b.dateAcceptedTxt.trim() === "") { return -1; }
                    if (a === null || a.dateAcceptedTxt === null || a.dateAcceptedTxt.trim() === "") { return 1; }
                    var dta = a.dateAccepted; // new Date(a.dateCreated.split("/").reverse().join("-"))
                    var dtb = b.dateAccepted; // new Date(b.dateCreated.split("/").reverse().join("-"))
                    if(dta > dtb) { return -1; }
                    if(dta < dtb) { return 1; }
                    return 0;
                })
                if (reverseNextSort) {
                  setDateAcceptedDescending(false)
                }
            } else {
                result.sort((a, b) => {
                    if (b === null || b.dateAcceptedTxt === null || b.dateAcceptedTxt.trim() === "") { return 1; }
                    if (a === null || a.dateAcceptedTxt === null || a.dateAcceptedTxt.trim() === "") { return -1; }
                    var dta = a.dateAccepted; // new Date(a.dateCreated.split("/").reverse().join("-"))
                    var dtb = b.dateAccepted; // new Date(b.dateCreated.split("/").reverse().join("-"))
                    if(dta > dtb) { return 1; }
                    if(dta < dtb) { return -1; }
                    return 0;
                })
                if (reverseNextSort) {
                  setDateAcceptedDescending(true)
                }
            }
            setpolicyBuilderReportResult(result);
            break;
      case "AssignedDateUserLevel":
        if (sortGivenItemInDesc) {
            result.sort((a, b) => {
                if (b === null || b.assignedDateUserLevelTxt === null || b.assignedDateUserLevelTxt.trim() === "") { return -1; }
                if (a === null || a.assignedDateUserLevelTxt === null || a.assignedDateUserLevelTxt.trim() === "") { return 1; }
                var dta = a.assignedDateUserLevel; // new Date(a.dateCreated.split("/").reverse().join("-"))
                var dtb = b.assignedDateUserLevel; // new Date(b.dateCreated.split("/").reverse().join("-"))
                if(dta > dtb) { return -1; }
                if(dta < dtb) { return 1; }
                return 0;
            })
            if (reverseNextSort) {
              setAssignedDateUserLevelDescending(false)
            }
        } else {
            result.sort((a, b) => {
                if (b === null || b.assignedDateUserLevelTxt === null || b.assignedDateUserLevelTxt.trim() === "") { return 1; }
                if (a === null || a.assignedDateUserLevelTxt === null || a.assignedDateUserLevelTxt.trim() === "") { return -1; }
                var dta = a.assignedDateUserLevel; // new Date(a.dateCreated.split("/").reverse().join("-"))
                var dtb = b.assignedDateUserLevel; // new Date(b.dateCreated.split("/").reverse().join("-"))
                if(dta > dtb) { return 1; }
                if(dta < dtb) { return -1; }
                return 0;
            })
            if (reverseNextSort) {
              setAssignedDateUserLevelDescending(true)
            }
        }
        setpolicyBuilderReportResult(result);
      break;
      case "AssignedDateUnitLevel":
          if (sortGivenItemInDesc) {
            result.sort((a, b) => {
                if (b === null || b.assignedDateUnitLevelTxt === null || b.assignedDateUnitLevelTxt.trim() === "") { return -1; }
                if (a === null || a.assignedDateUnitLevelTxt === null || a.assignedDateUnitLevelTxt.trim() === "") { return 1; }
                var dta = a.assignedDateUnitLevel; // new Date(a.dateCreated.split("/").reverse().join("-"))
                var dtb = b.assignedDateUnitLevel; // new Date(b.dateCreated.split("/").reverse().join("-"))
                if(dta > dtb) { return -1; }
                if(dta < dtb) { return 1; }
                return 0;
            })
            if (reverseNextSort) {
              setAssignedDateUnitLevelDescending(false)
            }
          } else {
              result.sort((a, b) => {
                  if (b === null || b.assignedDateUnitLevelTxt === null || b.assignedDateUnitLevelTxt.trim() === "") { return 1; }
                  if (a === null || a.assignedDateUnitLevelTxt === null || a.assignedDateUnitLevelTxt.trim() === "") { return -1; }
                  var dta = a.assignedDateUnitLevel; // new Date(a.dateCreated.split("/").reverse().join("-"))
                  var dtb = b.assignedDateUnitLevel; // new Date(b.dateCreated.split("/").reverse().join("-"))
                  if(dta > dtb) { return 1; }
                  if(dta < dtb) { return -1; }
                  return 0;
              })
              if (reverseNextSort) {
                setAssignedDateUnitLevelDescending(true)
              }
          }
          setpolicyBuilderReportResult(result);
        break;

      default:
        break;
    }
    return result.slice(0);
  };

  const sortResultsBy = async (
    sortBy:
    | "Policy"
    | "UnitPathway"
    | "LastName"
    | "FirstName"
    | "Email"
    | "Accepted"
    | "LastDateAccepted"
    | "AssignedDateUserLevel"
    | "AssignedDateUnitLevel",
    sortGivenItemInDesc: boolean
  ) => {
    setSortInProgress(true);
    setExportError("");
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy);
      setpolicyBuilderReportResult(result);
      setPage(0);
      setSortInProgress(false);
    });
  };

  const clickUnitPathwayAsc = () => {
    setReverseNextSort(false);
    setUnitPathwayDescending(false);
    sortResultsBy("UnitPathway", false);
  };

  const clickUnitPathwayDesc = () => {
    setReverseNextSort(false);
    setUnitPathwayDescending(true);
    sortResultsBy("UnitPathway", true);
  };

  const clickPolicyAsc = () => {
    setReverseNextSort(false);
    setPolicyDescending(false);
    sortResultsBy("Policy", false);
  };

  const clickPolicyDesc = () => {
    setReverseNextSort(false);
    setPolicyDescending(true);
    sortResultsBy("Policy", true);
  };

  const clickLastNameAsc = () => {
    setReverseNextSort(false);
    setLastNameDescending(false);
    sortResultsBy("LastName", false);
  };

  const clickLastNameDesc = () => {
    setReverseNextSort(false);
    setLastNameDescending(true);
    sortResultsBy("LastName", true);
  };

  const clickFirstNameAsc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(false);
    sortResultsBy("FirstName", false);
  };

  const clickFirstNameDesc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(true);
    sortResultsBy("FirstName", true);
  };

  const clickEmailAsc = () => {
    setReverseNextSort(false);
    setEmailDescending(false);
    sortResultsBy("Email", false);
  };

  const clickEmailDesc = () => {
    setReverseNextSort(false);
    setEmailDescending(true);
    sortResultsBy("Email", true);
  };
  
  const clickAcceptedAsc = () => {
    setReverseNextSort(false);
    setAcceptedDescending(false);
    sortResultsBy("Accepted", false);
  };

  const clickAcceptedDesc = () => {
    setReverseNextSort(false);
    setAcceptedDescending(true);
    sortResultsBy("Accepted", true);
  };

  const clickLastDateAcceptedAsc = () => {
    setReverseNextSort(false);
    setDateAcceptedDescending(false);
    sortResultsBy("LastDateAccepted", false);
  };

  const clickLastDateAcceptedDesc = () => {
    setReverseNextSort(false);
    setDateAcceptedDescending(true);
    sortResultsBy("LastDateAccepted", true);
  };

  const clickAssignedDateUserLeveldAsc = () => {
    setReverseNextSort(false);
    setAssignedDateUserLevelDescending(false);
    sortResultsBy("AssignedDateUserLevel", false);
  };

  const clickAssignedDateUserLevelDesc = () => {
    setReverseNextSort(false);
    setAssignedDateUserLevelDescending(true);
    sortResultsBy("AssignedDateUserLevel", true);
  };

  const clickAssignedDateUnitLevelAsc = () => {
    setReverseNextSort(false);
    setAssignedDateUnitLevelDescending(false);
    sortResultsBy("AssignedDateUnitLevel", false);
  };

  const clickAssignedDateUnitLevelDesc = () => {
    setReverseNextSort(false);
    setAssignedDateUnitLevelDescending(true);
    sortResultsBy("AssignedDateUnitLevel", true);
  };

  const initialSort = () => {
    setCurrentSortBy("UnitPathway"); 
    setUnitPathwayDescending(false);
    setPolicyDescending(false);
    setLastNameDescending(false);  
    setFirstNameDescending(false);  
    setEmailDescending(false); 
    setAcceptedDescending(false); 
    setDateAcceptedDescending(false); 
    setAssignedDateUserLevelDescending(false);
    setAssignedDateUnitLevelDescending(false);
    setReportRunTimeTaken(getCurrentTimeDDMMYYYYFormat()); 
    setPage(0);
  };

  useEffect(() => {
    initialSort();
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Completed Policy Builder Report in NEWSALT ClientApp",
      null,
      "PolicyBuilderReportResult.tsx",
      6, EnableActivityLogs
    );
  }, []);

  if (
    resultError !== null &&
    resultError !== undefined &&
    resultError.trimLeft().trimRight().length > 0
  ) {
    setTitle(allResourcesToTranslations.policybuilderreport);
    setTitleEnglish(pageTitles.policybuilderreport)
    updateBackButtonRoute("/policybuilderreport");
    //setExportError("");
    return (
      <BrandedCard>
        <div className={styles.noResultsCard}>
          <div style={{ color: "red" }}>Error: {resultError}</div>
          <br />
          <div>Select the Back Button to return to Previous Page</div>
        </div>
      </BrandedCard>
    );
  } else if (policyBuilderReport === null || policyBuilderReport.length < 1) {
    setTitle(allResourcesToTranslations.policybuilderreport);
    setTitleEnglish(pageTitles.policybuilderreport)
    //setExportError("");
    updateBackButtonRoute("/policybuilderreport");
    return (
      <BrandedCard>
        <div className={styles.noResultsCard}>
          <div>No Results to Display</div>
          <div>Select the Back Button to return to Previous Page</div>
        </div>
      </BrandedCard>
    );
  } else {
    setTitle(allResourcesToTranslations.policybuilderreport);
    setTitleEnglish(pageTitles.policybuilderreport)
    updateBackButtonRoute("/policybuilderreport");
  }

  const GetSelectedUnitsSummary = () => {
    if (!!selectedUnitNames) {
      let arr = selectedUnitNames.split(",");
      return selectedUnitNames != "" && arr.length > 5 ? (
        <div className={styles.reportDetailContainer}>
          <span className={styles.reportDetailTitle}>
            {"Unit(s) where applicable" /* TODO Tranlation */}
          </span>
          {selectedUnitNames.split(",", 5).map((step: any) => (
            <span key={step} className={styles.reportDetail}>
              {step}
            </span>
          ))}
          <CustomTooltip
            displayText={selectedUnitNames.split(",").splice(5, arr.length)}
            placement="bottom"
          >
            <span className={styles.reportDetail}>
              {arr.length > 5 ? "+" + (arr.length - 5) + " more..." : ""}
            </span>
          </CustomTooltip>
        </div>
      ) : (
        <div className={styles.reportDetailContainer}>
          <span className={styles.reportDetailTitle}>
            {"Unit(s) where applicable" /* TODO Tranlation */}
          </span>
          {selectedUnitNames.split(",").map((step: any) => (
            <span key={step} className={styles.reportDetail}>
              {step}
            </span>
          ))}
        </div>
      );
    } else {
      return "";
    }
  };

  if (exporting) return <LoaderContainer />;

  return (
    <>
      {
        <div style={{ color: "red" }}>
          {exportError === undefined ||
          exportError === null ||
          exportError === ""
            ? ""
            : "Export Error: " + exportError}
        </div>
      }
      <hr />
      <Steps  enabled={intro.stepsEnabled}   steps={intro.steps}  initialStep={intro.initialStep}
          onExit={onExit}
            options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
          }}/>
         <CustomTooltip displayText={["Guide Me"]}>
            <span style={{right:"105px"}} onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
            <GuideIcon />
            </span>
         </CustomTooltip>
    <BrandedCard>
      <div className={styles.reportPageTitleSection}>                
          <div className={styles.exportBtnContainer}>
              <div className={styles.reportContainer}>
                  <div className={styles.reportDetailContainer}>
                      <span className={styles.reportDetailTitle}>{"Report run by" /* TODO Tranlation */}</span>
                      <span className={styles.reportDetail}> {`${given_name} ${family_name}`}</span>
                  </div>
                  <div className={styles.reportDetailContainer}>
                      <span className={styles.reportDetailTitle}>{"Report run at" /* TODO Tranlation */}</span>
                      <span className={styles.reportDetail}>
                          { reportRunTimeTaken }
                      </span>
                  </div>
                  {GetSelectedUnitsSummary()}
                  <div className={styles.reportDetailContainer}>
                    <span className={styles.reportDetailTitle}>
                      {"Include Inactive Accounts" /* TODO Tranlation */}
                    </span>
                    <span className={styles.reportDetail}>
                      {" "}
                      {includeInactiveUsers ? "Yes" : "No"}
                    </span>
                  </div>
                  {/* <div className={styles.reportDetailContainer}>
                    <span className={styles.reportDetailTitle}>
                      {"Selected Policy IDs"}
                    </span>
                    <span className={styles.reportDetail}>
                      {" "}
                      {policyIDs}
                    </span>
                  </div> */}
                  <div className={styles.reportDetailContainer}>
                    <span className={styles.reportDetailTitle}>
                      {"Policy Acceptance Status" /* TODO Tranlation */}
                    </span>
                    <span className={styles.reportDetail}>
                      {" "}
                      {policyAcceptance === "not_accepted" ? "Not Accepted" : (policyAcceptance === "accepted" ? "Accepted" : (policyAcceptance === "both" ? "Both" : policyAcceptance))}
                    </span>
                  </div>
                  <div className={styles.reportDetailContainer}>
                    <span className={styles.reportDetailTitle}>
                      {"DateFrom" /* TODO Tranlation */}
                    </span>
                    <span className={styles.reportDetail}>
                      {" "}
                      {filterDateFrom}
                    </span>
                  </div>
                  <div className={styles.reportDetailContainer}>
                    <span className={styles.reportDetailTitle}>
                      {"DateTo" /* TODO Tranlation */}
                    </span>
                    <span className={styles.reportDetail}>
                      {" "}
                      {filterDateTo}
                    </span>
                  </div>
              </div>   
              
              <div className={styles.reportExportContainer}>
                          <CustomTooltip displayText={["Export to XLSX"] /*TODO: Add translations */}>                                    
                                  <Button  className="xlxs" color="primary" onClick={handleXLSXExport} id="btnNewSaltCARReportExportXLSX">
                                      <ExcelFileIcon/>
                                  </Button>
                          </CustomTooltip>
                          <CustomTooltip displayText={["Export to CSV"] /*TODO: Add translations */}>         
                              <Button   className="csv" color="primary" onClick={handleCSVExport} id="btnNewSaltCARReportExportCSV">
                                  <CSVFileIcon/>
                              </Button>
                          </CustomTooltip>
                          <CustomTooltip displayText={["Export to PDF"] /*TODO: Add translations */}>        
                              <Button  className="pdf" color="primary" onClick={handlePDFExport} id="btnNewSaltCARReportExportPDF">
                                  <PDFIcon/>
                              </Button>
                          </CustomTooltip>
                          {/* <CustomTooltip displayText={["Show/Hide Row Shading"]}>        
                              <Button className="rowShading" color="primary" onClick={toggleBanding}>
                                  <BrushIcon/>
                                  <CalendarViewDayIcon/>
                              </Button>
                          </CustomTooltip> */}
              </div>
              
          </div>
          
      </div>
    </BrandedCard>

      <div><span>&nbsp;</span></div>
      <div className={styles.tableSection} style={{width:"100%" }}>
        <div className={styles.tableContainer} >
          <BrandedCard>
            <TableContainer>
              <Table>
                <TableHead className="policyBuilderReportTotal">
                  <TableRow>                   
                    <TableCell style={{color: getColor(color.substring(1)), backgroundColor: color,borderRadius: "8px 0 0 0" }} align="right">
                      <div className={styles.tableHeadCellContainer}>
                        <div>
                          {"Policy" /* TODO translate */}
                        </div>                        
                      </div>
                    </TableCell>
                    <TableCell style={{color: getColor(color.substring(1)), backgroundColor: color }} align="center">
                      <div className={styles.tableHeadCellContainer}>
                        <div>
                          {"Total Users" /* TODO translate */}
                        </div>
                      </div>
                    </TableCell>
                    { (policyAcceptance === "accepted" || policyAcceptance === "both") ?               
                    <TableCell style={{color: getColor(color.substring(1)), backgroundColor: color }} align="center">
                    <div className={styles.tableHeadCellContainer}>
                        <div>
                          {"Accepted" /* TODO translate */}
                        </div>
                      </div>
                    </TableCell>    : ""
                    }
                    { (policyAcceptance === "not_accepted" || policyAcceptance === "both") ?               
                    <TableCell style={{color: getColor(color.substring(1)), backgroundColor: color }} align="center">
                    <div className={styles.tableHeadCellContainer}>
                        <div>
                          {"Not Accepted" /* TODO translate */}
                        </div>
                      </div>
                    </TableCell> : ""
                    }                               
                    <TableCell style={{color: getColor(color.substring(1)), backgroundColor: color,borderRadius: "0 8px 0 0" }} align="center">
                    <div className={styles.tableHeadCellContainer}>
                        <div>
                          {"Policy Status" /* TODO translate */}
                        </div>
                      </div>
                    </TableCell>
                   
                  </TableRow>
                </TableHead>
                <TableBody>
                  {policyBuilderTotalsReport                    
                    .map((resultpolicySummary: PolicyBuilderTotalsReportItems, i) => {
                      return (                        
                        <TableRow>                           
                          <TableCell align="right">{resultpolicySummary.policy}</TableCell>
                          <TableCell align="center">{resultpolicySummary.totalusers}</TableCell>
                          { (policyAcceptance === "accepted" || policyAcceptance === "both") ?               
                          <TableCell align="center">{resultpolicySummary.totalaccepted}</TableCell> : ""
                          }
                          { (policyAcceptance === "not_accepted" || policyAcceptance === "both") ?               
                          <TableCell align="center">{resultpolicySummary.totalnotaccepted}</TableCell> : ""
                          }        
                          <TableCell align="center">                          
                            {resultpolicySummary.active.toString().toLowerCase()  === 'active'? 
                              <CustomTooltip displayText={["Active"]}>
                              <span><ActiveIcon></ActiveIcon></span>
                              </CustomTooltip>  
                            : 
                              <CustomTooltip displayText={["Inactive"]}>
                              <span><InActiveIcon></InActiveIcon></span>
                              </CustomTooltip>
                             }</TableCell>                
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableBody>
                  {policyBuilderGrandTotalReport                   
                    .map((resultpolicySummary: PolicyBuilderGrandTotalReportItems, i) => {
                      return (                        
                        <TableRow> 
                          <TableCell align="right"style={{fontWeight: "bold"}}>{"Grand Total"}</TableCell>
                          <TableCell align="center" style={{fontWeight: "bold"}}>{resultpolicySummary.grandTotalUsers}</TableCell>
                          { (policyAcceptance === "accepted" || policyAcceptance === "both") ?               
                          <TableCell align="center" style={{fontWeight: "bold"}}>{resultpolicySummary.grandTotalAccepted}</TableCell> : ""
                          }
                          { (policyAcceptance === "not_accepted" || policyAcceptance === "both") ?               
                          <TableCell align="center" style={{fontWeight: "bold"}}>{resultpolicySummary.grandTotalNotaccepted}</TableCell> : ""
                          }                        
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </BrandedCard>
         
        </div>
      </div>

      <div
        className={styles.paginationControlsContainer}
        style={{ justifyContent: "center" }}
      >
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 100]}
          component="div"
          count={policyBuilderReport.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>   

      <div className={styles.tableSection}>
        <div className={styles.tableContainer}>
          <BrandedCard>
            <TableContainer>
              <Table>
                <TableHead className="policyBuilderReportList">
                  <TableRow>  
                    <TableCell style={{color: getColor(color.substring(1)), backgroundColor: color,borderRadius: "8px 0 0 0" }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                            setUnitPathwayDescending(!unitPathwayDescending);
                            sortResultsBy("UnitPathway", !unitPathwayDescending);
                          }}>
                          {"Unit Pathway" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span  style={{opacity:currentSortBy === "UnitPathway" &&unitPathwayDescending === false? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex",}} onClick={clickUnitPathwayAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span  style={{opacity:currentSortBy === "UnitPathway" && unitPathwayDescending === true? "100%": "50%", margin: "0px",height: "6px",display: "inline-flex",}} onClick={clickUnitPathwayDesc}>                           <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                            setLastNameDescending(!lastNameDescending);
                            sortResultsBy("LastName", !lastNameDescending);
                          }}>
                          {"Last Name" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span  style={{opacity:currentSortBy === "LastName" &&lastNameDescending === false? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex",}} onClick={clickLastNameAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span  style={{opacity:currentSortBy === "LastName" && lastNameDescending === true? "100%": "50%", margin: "0px",height: "6px",display: "inline-flex",}} onClick={clickLastNameDesc}>                           <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                            setFirstNameDescending(!firstNameDescending);
                            sortResultsBy("FirstName", !firstNameDescending);
                          }}>
                          {"First Name" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span  style={{opacity:currentSortBy === "FirstName" &&firstNameDescending === false? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex",}} onClick={clickFirstNameAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span  style={{opacity:currentSortBy === "FirstName" && firstNameDescending === true? "100%": "50%", margin: "0px",height: "6px",display: "inline-flex",}} onClick={clickFirstNameDesc}>                           <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                            setEmailDescending(!emailDescending);
                            sortResultsBy("Email", !emailDescending);
                          }}>
                          {"Email" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span  style={{opacity:currentSortBy === "Email" &&emailDescending === false? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex",}} onClick={clickEmailAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span  style={{opacity:currentSortBy === "Email" && emailDescending === true? "100%": "50%", margin: "0px",height: "6px",display: "inline-flex",}} onClick={clickEmailDesc}>                           <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>                   
                    <TableCell style={{color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                            setPolicyDescending(!policyDescending);
                            sortResultsBy("Policy", !policyDescending);
                          }}>
                          {"Policy" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span  style={{opacity:currentSortBy === "Policy" &&policyDescending === false? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex",}} onClick={clickPolicyAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span  style={{opacity:currentSortBy === "Policy" && policyDescending === true? "100%": "50%", margin: "0px",height: "6px",display: "inline-flex",}} onClick={clickPolicyDesc}>                           <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{color: getColor(color.substring(1)), backgroundColor: color }} align="center">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                            setAcceptedDescending(!acceptedDescending);
                            sortResultsBy("Accepted", !acceptedDescending);
                          }}>
                          {"Accepted" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span  style={{opacity:currentSortBy === "Accepted" &&acceptedDescending === false? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex",}} onClick={clickAcceptedAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span  style={{opacity:currentSortBy === "Accepted" && acceptedDescending === true? "100%": "50%", margin: "0px",height: "6px",display: "inline-flex",}} onClick={clickAcceptedDesc}>                           
                          <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                            setDateAcceptedDescending(!dateAcceptedDescending);
                            sortResultsBy("LastDateAccepted", !dateAcceptedDescending);
                          }}>
                          {"Last Date Accepted" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span  style={{opacity:currentSortBy === "LastDateAccepted" &&dateAcceptedDescending === false? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex",}} onClick={clickLastDateAcceptedAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span  style={{opacity:currentSortBy === "LastDateAccepted" && dateAcceptedDescending === true? "100%": "50%", margin: "0px",height: "6px",display: "inline-flex",}} onClick={clickLastDateAcceptedDesc}>                           <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{color: getColor(color.substring(1)), backgroundColor: color}} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                            setAssignedDateUnitLevelDescending(!assignedDateUnitLevelDescending);
                            sortResultsBy("AssignedDateUnitLevel", !assignedDateUnitLevelDescending);
                          }}>
                          {"Assigned Date (Unit Level)" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span  style={{opacity:currentSortBy === "AssignedDateUnitLevel" &&assignedDateUnitLevelDescending === false? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex",}} onClick={clickAssignedDateUnitLevelAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span  style={{opacity:currentSortBy === "AssignedDateUnitLevel" && assignedDateUnitLevelDescending === true? "100%": "50%", margin: "0px",height: "6px",display: "inline-flex",}} onClick={clickAssignedDateUnitLevelDesc}>                           
                          <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{color: getColor(color.substring(1)), backgroundColor: color,borderRadius: "0 8px 0 0"  }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                            setAssignedDateUserLevelDescending(!assignedDateUserLevelDescending);
                            sortResultsBy("AssignedDateUserLevel", !assignedDateUserLevelDescending);
                          }}>
                          {"Assigned Date (User Level)" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span  style={{opacity:currentSortBy === "AssignedDateUserLevel" && assignedDateUserLevelDescending === false? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex",}} onClick={clickAssignedDateUserLeveldAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span  style={{opacity:currentSortBy === "AssignedDateUserLevel" && assignedDateUserLevelDescending === true? "100%": "50%", margin: "0px",height: "6px",display: "inline-flex",}} onClick={clickAssignedDateUserLevelDesc}>                           
                          <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {policyBuilderReport
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((result: PolicyBuilderReportResultItem, i) => {
                      return (                        
                        <TableRow
                          key={`PolicyBuilderReportResult-${i}`}
                          style={rowBackgroundColor(i)}>                         
                          <TableCell align="left">{result.unitPathway}</TableCell>
                          <TableCell align="left">{result.lastName}</TableCell>
                          <TableCell align="left">{result.firstName}</TableCell>
                          <TableCell align="left">{result.email}</TableCell>
                          <TableCell align="left">
                            <div style={{clear:'both', width:'100%'}}>
                            <div style={{float:'left', width:'8%'}}>{result.active.toLowerCase()  === 'active'?
                             <CustomTooltip displayText={["Active"]}>
                             <span><ActiveIcon></ActiveIcon></span>
                             </CustomTooltip>  
                           : 
                             <CustomTooltip displayText={["Inactive"]}>
                             <span><InActiveIcon></InActiveIcon></span>
                             </CustomTooltip>
                            
                            }</div>
                            <div style={{float:'right', width:'92%'}}>{result.policy}</div>
                            </div>
                           </TableCell>  
                          <TableCell align="center">{result.accepted}</TableCell>
                          <TableCell align="left">{result.dateAcceptedTxt}</TableCell>
                          <TableCell align="left">{result.assignedDateUnitLevelTxt}</TableCell>
                          <TableCell align="left">{result.assignedDateUserLevelTxt}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </BrandedCard>
        </div>
      </div>
      <div
        className={styles.paginationControlsContainer}
        style={{ justifyContent: "center" }}
      >
        <TablePagination
          component="div"
          count={policyBuilderReport.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default PolicyBuilderReportResult;
