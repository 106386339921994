import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const PlayerSectionProgressIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="17" height="17" rx="2.5" fill="white" stroke="white"/>
    <path d="M13 5L5 13" stroke="#21394F" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
    <path d="M5 5L13 13" stroke="#21394F" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
    </svg>
);

export default PlayerSectionProgressIcon