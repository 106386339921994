import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const AlertGreenIcon = () => (    
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1048_615)">
    <path d="M8.21025 14.7166C11.8921 14.7166 14.8769 11.7319 14.8769 8.04997C14.8769 4.36807 11.8921 1.3833 8.21025 1.3833C4.52835 1.3833 1.54358 4.36807 1.54358 8.04997C1.54358 11.7319 4.52835 14.7166 8.21025 14.7166Z" fill="#107C41" stroke="#107C41" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
    <path d="M8.21021 10.7163H8.21687" stroke="white" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
    <path d="M8.21021 5.3833V8.04997" stroke="white" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_1048_615">
    <rect width="16" height="16" fill="white" transform="translate(0.210205 0.0498047)"/>
    </clipPath>
    </defs>
    </svg>

);

export default AlertGreenIcon
