//React + Typescript
import React, { useEffect, useContext, useState } from 'react';
import { Redirect } from 'react-router-dom'
//Contexts
import { SessionContext } from '../../contexts/SessionContext';
//Components
import LectoraQuizPlayer from '../../Players/Lectora/LectoraQuizPlayer';
import InfopathQuiz from '../../Players/Infopath/InfopathQuiz'
//Styles
import styles from '../../styles/quizWrapper.module.css';

export const Quiz = (props: any): JSX.Element => {
    const { setCurSelectedModuleTechType, multiSectionCurrentSelection } = useContext(SessionContext)

    useEffect(() => {
        if (learningModule !== undefined) {
            setCurSelectedModuleTechType(learningModule.quizType);
        }
    });

    if (!props.location.state) return <Redirect to={`/mytraining`} push/> 
    const {learningModule, moduleType } = props.location.state

    switch (moduleType) {
        case "Lectora":
            return(
                <div className={styles.fillScreen}>
                    <LectoraQuizPlayer key={"qprefix1" + (!!multiSectionCurrentSelection ? multiSectionCurrentSelection : "")} learningModuleData={learningModule} isLesson={false}/>
                </div>
            ) 
        case "SaltAdaptive":
        case "SA":
        case "Rise360":
        case "LectoraLQ":
        case "Storyline360":
            return(
                <div className={styles.fillScreen}>
                    <LectoraQuizPlayer learningModuleData={learningModule} isLesson={false}/>
                </div>
            )
        case "InfoPath" || "Infopath":
            return(
                <div className={styles.fillScreen}>
                    <InfopathQuiz learningModuleData={learningModule} isLesson={false}/>
                </div>
            )
    
        default:
            return <h1>Course Type invalid</h1>
    }
}

export default Quiz
