import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const ExpandAllIcon = () => (
    <svg  width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.20006 3.19968V16.3998H1.00003V0.999653H16.4002V3.19968H3.20006Z" fill="#FFFFFF"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M23 7.60017V23.0003H7.59979V7.60017H23ZM9.79982 9.8002H20.7999V20.8003H9.79982V9.8002Z" fill="#FFFFFF"/>
    <path d="M6.50032 6.49994H19.7005V4.29991H4.30029V19.7001H6.50032V6.49994Z" fill="#FFFFFF"/>
    <rect x="14.405" y="12.5696" width="1.83646" height="5.50938" fill="#FFFFFF"/>
    <rect x="12.5688" y="14.4065" width="5.50938" height="1.83646" fill="#FFFFFF"/>
    </svg>

);

export default ExpandAllIcon
