import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const UpArrowIcon = () => (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.88184 0.5L0.118697 5.75L9.64498 5.75L4.88184 0.5Z" fill="white"/>
    </svg>
);

export default UpArrowIcon
