import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const ResetAccountsIcon = () => (    
<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.12073 8.47967C8.12073 10.7891 9.99314 12.6615 12.3025 12.6615C14.612 12.6615 16.4844 10.7891 16.4844 8.47967C16.4844 6.17026 14.612 4.29785 12.3025 4.29785C9.99314 4.29785 8.12073 6.17026 8.12073 8.47967ZM10.2116 8.47967C10.2116 9.6349 11.1473 10.5706 12.3025 10.5706C13.4578 10.5706 14.3935 9.6349 14.3935 8.47967C14.3935 7.32444 13.4578 6.38876 12.3025 6.38876C11.1473 6.38876 10.2116 7.32444 10.2116 8.47967Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.3024 0.118164C18.6535 0.118164 23.8024 5.26703 23.8024 11.6182C23.8024 12.4998 23.7032 13.3582 23.5153 14.1829C22.9164 13.8075 22.2567 13.5202 21.5543 13.3392C21.6575 12.7812 21.7115 12.206 21.7115 11.6182C21.7115 6.42121 17.4993 2.20907 12.3024 2.20907C7.10541 2.20907 2.89328 6.42121 2.89328 11.6182C2.89328 13.7634 3.6115 15.7414 4.82109 17.3243C6.36406 15.3522 8.67686 14.0115 11.3081 13.7542V15.8582C9.24256 16.1194 7.44526 17.2414 6.28682 18.8538C7.91773 20.2108 10.0149 21.0273 12.3024 21.0273C12.4892 21.0273 12.6747 21.0218 12.8588 21.0111C12.9509 21.7349 13.1537 22.4243 13.4495 23.0617C13.0722 23.099 12.6895 23.1182 12.3024 23.1182C5.95123 23.1182 0.802368 17.9693 0.802368 11.6182C0.802368 5.26703 5.95123 0.118164 12.3024 0.118164Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.6238 14.3213C12.967 14.3213 13.2452 14.5995 13.2452 14.9427V18.0497H16.3522C16.6954 18.0497 16.9736 18.3279 16.9736 18.6711C16.9736 19.0142 16.6954 19.2924 16.3522 19.2924H12.6238C12.2806 19.2924 12.0024 19.0142 12.0024 18.6711V14.9427C12.0024 14.5995 12.2806 14.3213 12.6238 14.3213Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.5926 13.7662C19.9266 13.5785 21.2856 13.8294 22.4646 14.4812C23.6437 15.133 24.579 16.1503 25.1296 17.3799C25.6802 18.6095 25.8163 19.9847 25.5173 21.2983C25.2184 22.612 24.5006 23.7929 23.4722 24.6631C22.4437 25.5334 21.1603 26.0458 19.8153 26.1232C18.4703 26.2006 17.1366 25.8388 16.0151 25.0923C14.8936 24.3458 14.0451 23.2551 13.5975 21.9844C13.4834 21.6607 13.6534 21.3059 13.9771 21.1918C14.3007 21.0778 14.6556 21.2477 14.7696 21.5714C15.1278 22.5879 15.8066 23.4606 16.7038 24.0577C17.601 24.6549 18.6679 24.9444 19.7439 24.8825C20.8199 24.8205 21.8467 24.4106 22.6694 23.7144C23.4922 23.0182 24.0664 22.0735 24.3055 21.0226C24.5447 19.9717 24.4358 18.8715 23.9953 17.8878C23.5548 16.9042 22.8066 16.0903 21.8634 15.5689C20.9201 15.0475 19.833 14.8467 18.7657 14.9968C17.6984 15.147 16.7089 15.64 15.9461 16.4014C15.9417 16.4058 15.9372 16.4102 15.9326 16.4145L13.0494 19.1238C12.7993 19.3588 12.406 19.3465 12.171 19.0964C11.936 18.8463 11.9482 18.4531 12.1983 18.2181L15.0749 15.5151C16.0274 14.5671 17.2617 13.9534 18.5926 13.7662Z" fill="white"/>
</svg>

    

);

export default ResetAccountsIcon
