//Context required for this component as it is used inside multiple routes and needs to remain persitent
import React, { useState, createContext } from 'react';

interface ICourseProgressPanelContext {
  coursesAssigned: number;
  setCoursesAssigned: (assignedCourseCount: number) => void;
  coursesCompleted: number;
  setCoursesCompleted: (completedCourseCount: number) => void;
  totalCourseProgress: number
  setTotalCourseProgress: (totalCourseProgress: number) => void;
  assignedQuizesCount: number;
  setAssignedQuizesCount: (passedQuizesCount: number) => void;
  passedQuizesCount: number;
  setPassedQuizesCount: (passedQuizesCount: number) => void;
  totalQuizProgress: number;
  setTotalQuizProgress: (totalQuizProgress: number) => void;
  totalLessonProgress: number;
  setTotalLessonProgress: (totalLessonProgress: number) => void;
  totalPolicyProgress: number;
  setTotalPolicyProgress: (totalPolicyProgress: number) => void;
  isProgressReloadRequired: boolean;
  setProgressReloadRequired: (isProgressReloadRequired: boolean) => void;
  totalCertificateProgress: number
  setTotalCertificateProgress: (totalCertificateProgress: number) => void;
  

}

export const CourseProgressPanelContext = createContext<ICourseProgressPanelContext>({ 
    coursesAssigned: 0,
    setCoursesAssigned: () => {},
    coursesCompleted: 0,
    setCoursesCompleted: () => {},
    totalCourseProgress: 0,
    setTotalCourseProgress: () => {},
    assignedQuizesCount: 0,
    setAssignedQuizesCount: () => {},
    passedQuizesCount: 0,
    setPassedQuizesCount: () => {},
    totalQuizProgress: 0,
    setTotalQuizProgress: () => {},
    totalLessonProgress: 0,
    setTotalLessonProgress: () => {},
    totalPolicyProgress: 0,
    setTotalPolicyProgress: () => {},
    isProgressReloadRequired: false,
    setProgressReloadRequired: () => {},
    totalCertificateProgress:0,
    setTotalCertificateProgress: () => {},
});

export const CourseProgressPanelProvider = (props: any): JSX.Element => {
  const [coursesAssigned, setCoursesAssigned] = useState<number>(0)
  const [coursesCompleted, setCoursesCompleted] = useState<number>(0)
  const [totalCourseProgress, setTotalCourseProgress] = useState<number>(0)
  const [assignedQuizesCount, setAssignedQuizesCount] = useState<number>(0)
  const [passedQuizesCount, setPassedQuizesCount] = useState<number>(0)
  const [totalQuizProgress, setTotalQuizProgress] = useState<number>(0)
  const [totalLessonProgress, setTotalLessonProgress] = useState<number>(0)
  const [totalPolicyProgress, setTotalPolicyProgress] = useState<number>(0)
  const [isProgressReloadRequired, setProgressReloadRequired] = useState<boolean>(false)
  const [totalCertificateProgress, setTotalCertificateProgress] = useState<number>(0)
 
  return (
    <CourseProgressPanelContext.Provider
      value={{
        coursesAssigned,
        setCoursesAssigned,
        coursesCompleted,
        setCoursesCompleted,
        totalCourseProgress,
        setTotalCourseProgress,
        assignedQuizesCount,
        setAssignedQuizesCount,
        passedQuizesCount, 
        setPassedQuizesCount,
        totalQuizProgress, 
        setTotalQuizProgress,
        totalLessonProgress, 
        setTotalLessonProgress,
        totalPolicyProgress, 
        setTotalPolicyProgress,
        isProgressReloadRequired, 
        setProgressReloadRequired,
        totalCertificateProgress,
        setTotalCertificateProgress,
      }}
    >
      {props.children}
    </CourseProgressPanelContext.Provider>
  );
};
