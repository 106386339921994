import React, { useState, createContext } from 'react';
import { PageTranslations } from '../globalTypes'
import { useReactOidc } from "@axa-fr/react-oidc-context";

const initialTranslations: PageTranslations = 
  {
    givenPageName: "Full Client Side Translation",
    currentPageLang: "en-au",
    // IMPORTANT: Always use full lowercase letters for the resource names here
    allResourcesToTranslations: {
        theme: "Theme (Testing area)",
        theme0: "Theme",
        addaccount: 'Add Account',
        choosebranding: "Choose Branding",
        choosecolor: "Choose colour",
        selectaccentcolor: "Select an accent colour",
        logourl: "Logo URL",
        unabletoupdatelogo: "Unable to update logo, please check the url",
        unabletoupdatebackground: "Unable to update background, please check the url",
        pleasecheckimagelinks: "Please check the image links",
        pixorhigher: "*500px wide or higher will provide the best quality",
        pixorhigher0: "*1920px x 1080px or higher will provide the best quality" ,
        leaveemptytokeep: "*Leave empty to keep exisiting images",
        savetheme: "Save Theme",
        reverttodefaulttheme: "Revert to default Theme",
        personalreportfor: "Personal Report for {NAME} ",
        course: "Course",        
        module: "Module",
        total: "Grand Total",
        duedate: "Due Date",
        pointsrequired: "Points Required",
        lessonstatus: "Lesson Status",
        quizstatus: "Quiz Status",
        score: "Score",
        shortfall: "Shortfall",
        cpdperiod: "CPD Period",
        viewhistory: "View History",
        history: "History",
        passmark: "Pass Mark",
        expiredtimeelapsed: "Expired (Time Elapsed)",
        expirednewcontent: "Expired (New Content)",
        passed: "Passed",
        failed: "Failed",
        notstarted: "Not Started",
        inprogress: "In Progress",
        exporttopdf: "Export to PDF" ,
        showhideduedate: "Show/Hide Next Due Date",
        showhidecoursecompletiondate: "Show/Hide Course Completion Date",       
        showduedate: "Show Next Due Date",
        hideduedate: "Hide Next Due Date",
        personalreportforquizhistory: "Personal Report for {NAME} / Quiz History ",
        quizstatusdate: "Quiz Status Date",
        policystatusdate: "Policy Status Date",
        slashquizhistory: "/ Quiz History",
        quizscore: "Quiz Score",
        completiondate: "Completion Date",
        status: "Status",
        yourscore: "Your Score",
        summary: "Summary",
        nosummaryavaliable: "No Summary avaliable for this Quiz Type",
        correct: "Correct",
        incorrect: "Incorrect",
        question: "Question",
        youranswer: "Your Answer",
        quizsummary: "Quiz Summary",
        name: "Name",
        correctanswer: "Correct Answer",
        myprogress: "My Progress",
        coursecompletion: "Course Completion",
        quizcompleteion: "Quiz Completion",
        policyacceptance: "Policy Acceptance",
        cpdprofile: "CPD Profile",
        period: "Period", 
        requiredpoints: "Required Points", 
        pointsearned: "Points Earned", 
        contenttype: "Content Type", 
        cpdpointsavaliable: "CPD Points Available",
        actions: "Actions",
        start: "Start", 
        review: "Review",
        restart: "Restart",
        viewquickfacts: "View Quick Facts", 
        lessonandquiz: "Lesson and Quiz",
        lesson: "Lesson",
        quiz: "Quiz",
        quickfacts: "Quick Facts",
        lastpassdate: "Last Pass Date {LASTCOMPLETIONDATE}", 
        dueinxdays: "Due in {DAYSUNTILDUE} days", 
        duein1day: "Due in 1 day",
        dueon: "Due on {DUEDATE}", 
        overdue: "Overdue", 
        noduedate: "No due date",
        return: "Return",
        continue: "Continue",
        viewquizsummaryreport: "View Quiz Summary Report",
        close: "Close", 
        confirmation: "Confirmation",
        youthavecompletedyourquiz: "You have completed your quiz",
        youhaveselectedrestart: "You have selected restart", 
        yourcoursestatuswillbereset: `Your Course status will be reset and you will need to complete this Module again. To obtain a new Pass by selecting the "Restart Course" button on the next page`,
        youthaveselectedreview: "You have selected review only",
        warningconfirmessagepopup:"Warning",
        donotselecttherestartcoursebutton: `Do not select the "Restart Course" button on the next page as your attempt will not be recorded`,
        congratulationsyouhavepassed: "Congratulations you have successfully Passed the {QUIZNAME} Quiz",
        thepassmarkforthisquizis: "The passmark for this Quiz is {PASSMARK}% and you scored {SCORE}% ",
        youfailedthequiz: "You failed the {QUIZNAME} Quiz",
        mytraining: "My Training",
        checkingforcpds: "Checking for CPD updates", 
        fetchingyourtrainingplan: "Fetching your Training Plan", 
        fetchingyourprogress: "Fetching your progress", 
        all: "All", 
        policy: "Policy", 
        cpdpoints: "CPD Points", 
        viewpolicy: "View Policy",
        viewcertificate: "View Certificate", 
        certificate: "Certificate",
        unassigned: "Unassigned",
        incomplete: "Incomplete",
        complete: "Complete",
        notaccepted: "Not Accepted",
        accepted: "Accepted",
        lastcompletiondate: "Last Completion Date", 
        viewpdf: "View PDF",
        exit: "Exit",
        goback: "Go Back",
        reviewandaccept: "Review and accept",
        acceptandexit: "Accept and Exit",
        accountsettings: "Account Settings",
        pleaseenterfirstname: "Please enter a First Name",
        pleaseenterlastname: "Please enter a Last Name",
        passwordsdonotmatch: "Passwords do not match, please try again",
        emailsdonotmatch: "Email Address does not match, please try again",
        settingsupdated: "Your Account Settings have been updated",
        leaveemptytokeeppw: "*Leave empty to keep existing Password",
        changeorderofdisplayname: "Swap first and last name display",
        username: "Username",
        firstname: "First Name",
        lastname: "Last Name",
        emailaddress: "Email Address",
        newpassword: "New Password",
        confirmnewpassword: "Confirm New Password",
        savesettings: "Save Settings",
        quizcompletion: "Quiz Completion",
        help: "Help",
        logout: "Logout",
        dashboard: "Dashboard",
        mydashboard: "My Dashboard",
        quizhistory: "Quiz History for {DISPLAYNAME}",
        currentadminreportquizhistory: "Quiz History for {DISPLAYNAME}",
        myreport: "My Report",
        feedback: "Leave Feedback",
        support: "Support",
        loading: "Loading",
        loggingout: "Logging Out",
        fetchingmodules: "Fetching Modules",
        calculatingresult: "Calculating Result",
        buildingmodulelist: "Building Module list",
        exportingreport: "Exporting Report",
        fetchingyourreport: "Fetching your Report",
        generatingcertificate: "Generating Certificate",
        releasenotes: "Release Notes",
        back: "Back",
        useriseithernotauthenticated: "User is either not authenticated or not authorized",
        pleaseloginagain: "Please log in again",
        takemehome: "Take me home",
        oppssomethingwentwrong: "Opps, something went wrong",
        leavefeedback: "Leave Feedback",
        clickbelowtoleavefeedback: "Click below to leave Feedback",
        clickbelowtoseewelcomemessage: "Click below to see your welcome message again",
        showwelcomemessage: "Show Welcome Message",
        saltadminandreports: "Salt Admin",
        language: "Language",
        home: "Home",
        next: "Next",
        submit: "Submit",
        completed: "Completed", 
        forurgentqueries: "For urgent queries please contact our support lines below", 
        contactsupport: "Contact Support",
        clickbelowtocontactsupport: "Click below to contact support",
        privacypolicy: "Privacy Policy",
        screennotsupported: "Please rotate your device to landscape to best view this site",
        screennotsupportedsalt: "Your device is not supported to display site correctly",
        dropanddragfile: "Drop file here or enter URL below",// New from here
        filesmustnotexceed: "Files must be less that {sizeLimitMb}MB",
        therewasanissueuploading: "There was an issue uploading your image",
        pleaseselectanimagefile: "Please select an image file",
        organisationsettings: "Organisation Settings",
        useractivityreports: "Account Activity Reports",
        coursestatusreport: "Course Status Report",
        currentadminreport: "Current Admin Report",
        progressreport: "Progress Report",
        sentemailreport:"Sent Email Report",
        summaryreport:"Summary Report",
        cpdreports: "CPD Reports",
        cpdreport: "CPD Report",
        quizhistoryreport: "Quiz History Report",
        accountmanagement:"Account Management",
        coursecompletiondate: "Course Completion Date",
        reportyrunby: "Report run by" ,
        reportruntime: "Report run at" ,
        passwordminlength:"Password is at least 8 characters.",
        passwordmaxlength:"Password is not longer than 16 characters.",
        passwordmatch:"Passwords match",
        passworduppercase:"Password has one uppercase letter.",
        passwordnumber:"Password has one number.",
        passwordspecial:"Password has one special character."   ,
        supportdetails: "Support Details",
        selectall: "Select All",
        unselectall: "Unselect All",
        expandall: "Expand All",
        collapseall: "Collapse All",
        saveusermoduleaccess: "Save Account Module Access",
        assignmodulestoaccount: "Assign Modules to Account",
        assignedbyunit: "Assigned by Unit",
        assignedbyuser: "Assigned by Account",  
        saltpersonaldata: "Click below to see how we manage your personal data in Salt" ,  
        techsupportclick: "For techincal support click the button below",
        termsofuse:"Terms of Use",
        authorisedusertermsofuse:"AUTHORISED USER TERMS OF USE",
        currency:"currency",
        welcomebyusingtheapplication:"Welcome! By using the application which GRC Solutions provides at this Website, you agree to be bound by the following terms and conditions (the 'Terms of Use'). GRC Solutions may change these Terms of Use at any time without notice. Any amendment will be effective immediately. Your use of this Website after any amendment constitutes an agreement by you to comply with and be bound by the amended Terms of Use. Accordingly, you should read these Terms of Use from time to time for changes.",
        licence:"Licence",
        grcsolutionshasgranted:"GRC Solutions has granted you a licence to use this application. GRC Solutions has not granted you any other rights in relation to this application.",
        account:"Account",
        yourusernameandpassword:"Your username and password are secret and confidential. You must not disclose your username or password to anyone. You are responsible for maintaining the confidentiality of your username and password. You are responsible for all uses of your account, whether or not actually or expressly authorised by you.",
        restrictions:"Restrictions",
        youmustnot:"You must not: ",
        sublicenseassignortransfer:"sublicense, assign or transfer your licence to use this application to any other Person (whether for commercial or non-commercial purposes);",
        reproducethisapplication:"reproduce this application, or allow any other Person to reproduce this application;",
        altermodifyormerge:"alter, modify or merge this application with any other information, data or material without GRC Solutions's prior written consent;",
        excepttotheextent:"except to the extent permitted by applicable law, reverse assemble, reverse compile, reverse engineer or otherwise translate this application; or",
        causeorpermit:"cause or permit anything which may damage or endanger the intellectual property of GRC Solutions or others in this application, or assist or allow any other Person to do so.",
        copyright:"Copyright",
        thisapplicationis:"This application is the copyright property of GRC Solutions and its licensors. You gain no rights to the application other than the licence expressly provided. All rights are reserved.",
        trademarks:"Trade Marks",
        alltrademarks:" All trade marks, displayed in the application and otherwise on the Website (the 'Trade Marks') are proprietary to their respective owners and are protected by applicable trade mark and copyright laws. These Trade Marks may be registered or unregistered marks of GRC Solutions or others. The following trade marks and other GRC Solutions products and services referred to in the application Website are trade marks of GRC Solutions:",
        governaceriskandcompliance:"Governance Risk and Compliance Solutions",
        nothingcontainedonthe:"Nothing contained on the Website should be construed as granting any licence or right of use of any other person or entities trade mark which is displayed in the application or on this Website without their express permission.",
        ifyouuseanyofourtrademarks:"If you use any of our Trade Marks to refer to our activities, products or services, you must include a statement attributing that Trade Mark to us. You must not use any of our Trade Marks:",
        inorasthewholepart:"in or as the whole or part of your own trade marks;",
        inconnectionwithactivities:"in connection with activities, products or services which are not ours;",
        inamannerwhichmaybeconfusing:"in a manner which may be confusing, misleading or deceptive; and",
        inamannerthatpisparages:"in a manner that disparages us or our information, products or services.",        
        linkedwebsites:"Linked Web Sites",
        thiswebsitecontainslinks:"This Website may contain links to other websites. Those links are provided for convenience only and may not remain current or be maintained.",
        linkstothoselinked:"Links to those linked websites should not be construed as any endorsement, approval, recommendation, or preference by us of the owners of operators of the websites, or for any information, products or services referred to on those other websites. Your use of any link to a linked website is entirely at your risk.",
        privacy:"Privacy",
        youacknowledgethat:"You acknowledge that GRC Solutions will collect personal information about you (such as your e-mail address) for the purposes of providing Self Administered Legal Training ('Salt®'), recording the outcome of the Salt® training and disclosing that information to your employer. GRC Solutions may also disclose your personal information to GRC Solutions employees and entities controlled by it for the same purposes. The GRC Solutions Privacy Policy can be found at <a href='https://www.grcsolutions.com.au/privacy-policy'>https://www.grcsolutions.com.au/privacy-policy</a>. By engaging in this Salt® training, you consent to GRC Solutions collecting, using and disclosing your personal information for the purposes set out above.",
        spam:"Spam",
        youconsenttogrc:"You consent to GRC Solutions sending to you commercial electronic messages for the purposes of providing the Salt™ application.",
        indemnity:"Indemnity",
        youmustindemnity:"You must indemnify, defend and hold harmless GRC Solutions, its officers and agents from any and all third party claims, liability, damages and/or costs (including, but not limited to, legal fees) arising from your violation of the Terms of Use or your infringement, or infringement by any other user of your account, of any intellectual property or other right of any Person or entity.",
      
        //#region Import User start
        importaccounts:"Import Accounts",
        sampleimportfile:"Sample import file",
        sampleimportfiledownload:"Select a button below to download a CSV or XLSX sample import file.",
        uploadfile:"Upload File",
        uploadfileexample:"Import file for  XYZ organisation with 10000 accounts.csv",
        dropanddropimportfile: "Drag and drop a supported file here or browse for a file.",      
        supportedfiles: "Supported file formats: .csv and .xlsx",        
        therewasanissueuploadingfile: "There was an issue uploading your file",
        downloadsamplecsv:"Download Sample CSV File",
        downloadsamplexlsx:"Download Sample XLSX File",
        guideme:"Guide Me",
        draganddropcustomtooltip:"Drag / Upload a file"   ,
        warningreport:"Warning Report",
        atriskreport:"At Risk Report",
        emailsendingreport:"Email Sending Reports",
        emailreport:"Email Report",
        administrationreports:"Administration Reports",
        userdetailreport:"Account Detail Report",
        unitpathwayreport:"Unit Pathway Report",
        unitcompliancereport:"Unit Compliance Report",
        unitadminreport:"Unit Admin Report",
        licensereport:"License Report",
        organisationreports:"Organisation Reports",
        policyreports:"Policy Reports",
        policybuilderreport:"Policy Builder Report",
        scheduledreports:"Scheduled Reports",
        showaaccounts:"Show Accounts",
        accountsaddedmessage:"Accounts will be Added.",
        accountsupdatedmessage:"Accounts will be Updated.",
        accountsdeactivateddmessage:"Accounts will be Deactivated.",
        showaddedmessage:"Show Added Accounts.",
        showsupdatedmessage:"Show Updated Accounts.",
        showsdeactivateddmessage:"Show Deactivated Accounts."   ,     
        showserrors:"Show Errors.",
        errors:" Errors",
        errorsdetected:"errors detected.",
        exporttocsv:"Export to CSV",      
        exporttoxlsx:"Export to XLSX",
        accounts:"Accounts",
        externalid: "External ID",
        password:"Password",
        unitid: "Unit ID",
        archive:"Archive",
        groupby:"Group By",
        groupbyvalue:"Value",
        notifyunitadmin:"Notify Unit Admin",
        notifyorgadmin:"Notify Org Admin",
        notifymanager:"Notify Manager",
        manageremailaddress:"Manager Email Address",
        importaccountstatus:"Import account Status",
        accountsimported:"Accounts Imported",
        importaccountspagenavigationwarrningmessage:"Do not navigate away from this page as this will cancel this import.",
        commitaccountswithouterrors:"Commit - Accounts without errors",
        canceluploadedfile:"Cancel uploaded file",
        proceed:"Proceed",
        previewreportreviewcommit:"Preview Report, Review and Commit.",
        reason0 :"Valid Record",
        reason1 :"Organisation Admin: Permission Denied.",
        reason2 :"Unit Admin: Permission Denied.",
        reason3 :"Specified unit does not exist",
        reason4 :"User Name exists in database already",
        reason5 :"Email Address exists in database already",
        reason6 :"User Name duplicates found in import",
        reason7 :"Username is the same as password",
        reason8 :"Email Address duplicates found in import",
        reason9 :"First or Last Name missing.",
        reason10:"Invalid email address",
        reason11:"Archive field cannot be blank or invalid value (Valid values are 1 or 0).",
        reason12:"Password cannot exceed 50 characters in length",
        reason13:"First Name cannot exceed 50 characters in length",
        reason14:"Last Name cannot exceed 50 characters in length",
        reason15:"Email Address cannot exceed 100 characters in length",
        reason16:"External ID cannot exceed 50 characters in length",
        reason17:"Unit ID cannot exceed 50 characters in length",
        reason18:"Archive cannot exceed 1 character in length",
        reason19:"Group By cannot exceed 50 characters in length",
        reason20:"Notify Unit Admin cannot exceed 3 characters in length",
        reason21:"Notify Org Admin cannot exceed 3 characters in length",
        reason22:"Notify Manager cannot exceed 3 characters in length",
        reason23:"Manager Email Address cannot exceed 100 characters in length",
        reason24:"Username cannot exceed 100 characters in length",
        reason25:"Password is at least 8 characters.",
        reason26:"Password is not longer than 16 characters",
        reason27:"Password does not contain special character",
        reason28:"Password does not contain uppercase character",
        reason29:"Password does not contain number",
        reason30:"Please use organisation defined Group By.",
        reason31:"Please enter valid email address",
        reason32:"Value cannot exceed 50 characters in length",        
        reason33:"Username cannot be empty",
        reason34:"Email cannot be empty",
        reason35:"Archive allows only 1 or 0 as valid values",
        reason36:"UnitID cannot be empty or invalid", 
        reason37:"Email cannot be empty when adding new user",
        reason38:"Username must be at least 3 characters",
        reason39:"UserId cannot exceed 50 characters in length",
        reason40:"UserID cannot be empty or invalid",
        reason41:"UserId  exists in database already",
        //#endregion Import User start

        //#region Organisation Mgmt Start
        organisationmanagement: "Organisation Management",
        welcomemessage: "Welcome Message",
        groupingoption: "Grouping Option",
        groupingby: "Grouping By",
        groupingoptionnote: "Grouping Options description and instructions goes here",
        orgdomainname: "Organisation Domain Name",
        orgdomainnamenote: "This Setting is to modify the organisation domain name",
        compliancerules: "Compliance Rules",
        configurationitems: "Configuration Items",
        singlesignon: "Single Sign On",
        saveorgmanagement: "Save Organisation Management",
        requiredfield: "*This Field is required",
        //#endregion 

        //#region SSO Page
        ssosetupguides: "SSO Setup Guides",
        ssosetupguidesnotes: "Select a button below to download a guide relevant to your SSO setup",
        oktassosetupguides: "OKTA SSO Setup Guide",
        azureadssosetupguides: "AzureAD SSO Setup Guide",
        adfsssosetupguides: "ADFS SSO Setup Guide",      
        enablesso: "Enable SAML SSO",
        samlconsumerendpoint: "SAML Consumer Endpoint",
        samlcertificatekey: "SAML Certificate Key",
        samlproviderendpoint: "SAML Provider Endpoint",
        samlcertificatekeyadmin: "SAML Certificate Key (Admin)",
        samlproviderendpointadmin: "SAML Provider Endpoint (Admin)",
        savessodetails: "Save SSO Details",
        
        url: "URL",
        enablessonote: "Check to enable SSO login for your Salt Site",
        samlconsumerendpointnote: "SSO Endpoint URL used in the setup of SSO",
        samlcertificatekeynote: "Paste SSO Certificate contents here",
        samlproviderendpointnote: "Paste Provider Endpoint URL here",
        samlcertificatekeyadminnote: "Paste SSO Certificate contents for Admin Site here",
        samlproviderendpointadminnote: "Paste Provider Endpoint URL for Admin Site here",

        oktassosetupguideme: "Click to download an OKTA SSO Setup Guide",
        azureadssosetupguideme: "Click to download an AzureAD SSO Setup Guide",
        adfsssosetupguideme: "Click to download an ADFS SSO Setup Guide",
        enablessoguideme: "Check to enable SSO login on your Salt Site, checking will enable for both User and Admin interfaces.",
        samlconsumerendpointguideme: "The URL displayed is to be used in the setup of SSO for your Salt Site, refer to the relevant SSO Setup guide for usage.",
        samlcertificatekeyguideme: "Paste the contents of the Certificate provided from your SSO setup, relevant for the User interface, refer to the relevant SSO Setup guide for more information.",
        samlproviderendpointguideme: "Paste the URL of the Provider Endpoint from your SSO setup, relevant for the User Interface, refer to the relevant SSO Setup guide for more information.",
        samlcertificatekeyadminguideme: "Paste the contents of the Certificate provided from your SSO setup, relevant for the Admin interface, refer to the relevant SSO Setup guide for more information.",
        samlproviderendpointadminguideme: "Paste the URL of the Provider Endpoint from your SSO setup, relevant for the Admin Interface, refer to the relevant SSO Setup guide for more information.",
        saveguideme: "Click here to Save all SSO information.",
        //#endregion

        //#region Config Items 
        basicsettings: "Basic Settings",
        selecttext: "Select the checkboxes below to enable or disable the settings",
        orglogocerti: "Include the Organisation logo on the certificate",
        saltlogocerti: "Include the Salt logo on the certificate",
        activatepassword: "Activate Password Lockout",      
        showlastpass: "Show 'Last Pass Date' and 'Due Date' on 'My Training' page",
        disablepassword: "Disable Password field",
        activateurlreq: "Activate URL Request",
        usercpdevent: "User CPD Event",
        enableebook: "Enable Ebook email notification",
        advancedreporting: "Advanced Reporting",
        showwelcomepopup: "Show Welcome Popup",
        additionalsettings: "Additional Settings",
        additionalsettingstext: "Enter the required values or select from the dropdown menus",
        timezone: "Time Zone",
        enablepasswordrule: "Enable Password Complexity Rules",
        saveconfigitems: "Save Configuration Items",

        orglogocertinote: "Your uploaded organisation logo will appear on Completion Certificates.",
        saltlogocertinote: "The Salt logo will appear on the Completion Certificate.",
        activatepasswordnote: "Three incorrect login attempts will lock the particular account.",      
        showlastpassnote: "'Last Pass Date' and 'Due Date' will appear on an account's My Training page.",
        disablepasswordnote: "Password field disabled.",
        activateurlreqnote: "One-time use login URL sent to an account instead of Password Reset email.",
        usercpdeventnote: "Enable CPD Events.",
        enableebooknote: "When an EBook is updated, an email notification is sent to accounts.",
        advancedreportingnote: "Enable Advanced Reporting.",
        showwelcomepopupnote: "Show/Hide Welcome Message for all Accounts upon login to Salt.",
        enablepasswordrulenote: "Complex password rules will be available to set",
        maxchartext: "Maximum number of characters allowed are 16	",
        minchartext: "Minimum number of characters allowed are 8",
        mincapital: "At least 1 capital letter",
        minnumberrequired: "At least 1 numeric digit",
        minspecialcharreq: "At least 1 special character",

        orglogocertiguideme: "When enabled, your uploaded organistion logo will appear on Completion Certificates.",
        saltlogocertiguideme: "When enabled, the Salt logo will appear on Completion Certificates.",
        activatepasswordguideme: "When enabled, after three incorrect login attempts, the user's Salt account will disable, requiring reactivation by an admin.",      
        showlastpassguideme: "When enabled, 'Last Pass Date' and 'Due Date' values will appear on an account's My Training Page.",
        disablepasswordguideme: "When enabled, Accounts are not able to update their own passwords, admin's are only able to via Administration",
        activateurlreqguideme: "When enabled, accounts will use a one-time URL sent via email, using the Forgot Your Password feature, to log into Salt.",
        usercpdeventguideme: "When enabled, CPD Events will be enabled on your Salt Site.",
        enableebookguideme: "When enabled, an email notification will be sent to relevant accounts when an EBook has been updated.",
        advancedreportingguideme: "Enable Advanced Reporting.",
        enablepasswordrulenoteguideme: "Check to enable Password Complexity Rules, when enabled, the minimum password length is 8 characters and a maximum of 16 characters. You may toggle the requirement for passwords to include a capital letter, a number and a special character. These rules are enforced when a user resets their password for their account via the Forgot Password procedure, and are also enforced when a Salt Administrator is manually updating an account, and during the Import Accounts process.",
        timezoneguideme: "Set the timezone to be used for your Salt Site.",
        showwelcomepopupguideme: "When enabled, the configured Welcome Message, via Organisation Management, will show for all Accounts upon login to Salt, disabling will not show",

        globalcompliancerules: "Global Compliance Rules",
        informationpanel:"Please Note: Global Compliance Rules will apply to all User-based Enrolments",
        coursebasedcompliancerules: "Course based Compliance Rules",
        quizsettings: "Quiz Settings",
        quizsettingsdetail: "Enter the required values below",
        defaultquizpassmark: "Default Quiz Pass Mark (%)", 
        noofquiztoask: "No. of Quiz to ask learners (Infopath only)",
        expirysettings: "Expiry Settings",
        expirysettingsinfo: "Select one of the options below",
        expireperiod: "Expire lessons & quizzes after certain period",
        expirelesson: "Expire lesson Result after",
        expirequiz: "Expire Quiz Result after",
        expiredate: "Expire lessons & quizzes on certain date",
        expirelessondate: "Lesson Expiry Date",
        expirequizdate: "Quiz Expiry Date",
        enableonetime: "Enable One Time Course Completion",
        applylesson: "Apply this to the entire unit tree - overriding any existing exception Lesson compliance rules?",
        applylessontext: "Please note that the results of attempted lessons and quizzes up to this date will become 'Expired - Time Elapsed'. Users who have 'Expired - Time Elapsed' one or more modules will be Incomplete in their training status.",
        applyquiz: "Apply this to the entire unit tree - overriding any existing exception Quiz compliance rules?",
        applyquiztext: "Please note that the results of attempted lessons and quizzes up to this date will become 'Expired - Time Elapsed'. Users who have 'Expired - Time Elapsed' one or more modules will be Incomplete in their training status.",
        confirmpopupheader: "Confirmation",
        confirmmessage: "Saving Global changes will override all course based compliance rules and may affect Learners Quiz statuses.",
        proceedmessage: "Do you wish to proceed?",
        defaultquizpassmarkguideme: "Enter the default quiz pass mark for all courses, default is 100%",
        noofquiztoaskguideme: "Enter the number of quiz questions to ask the learner on a per quiz basis, applicable only for courses with Infopath quizzes.",
        expirelessonguideme: "Expire lesson statuses, where applicable, after the given time period. <br><br/><b>Note: </b>Salt Adaptive courses only consider quizzes when expiring statuses.",
        expirequizguideme: "Expire quiz statuses after the given time period.",
        expirelessondateguideme: "Expire lesson statuses, where applicable, on a given date.  <br><br/><b>Note: </b>Salt Adaptive courses only consider quizzes when expiring statuses.",
        expirequizdateguideme: "Expire quiz statuses on a given date",
        applylessonguideme: "Apply the above date to expire lesson statuses, where applicable, on a given date to the entire unit tree, doing so will override all existing compliance rules for lessons. Use with caution, as it is not reversible.",
        applyquizguideme: "Apply the above date to expire quiz statuses on the given date to the entire unit tree, doing so will override all existing compliance rules for quizzes. Use with caution, as it is not reversible.",
        onetimecompletionguideme: "Enable the ability for a course to be completed by users only once.",
        saveglobalcompliancerulesguideme: "Save and apply all changes above.",
        selectcourses: "1. Select course(s)",
        selectcoursesinfo: "Select the course(s) to apply the compliance rules to",
        unitpathway: "4. Unit Pathway",
        unitpathwayinfo: "Select the unit to apply on Course based compliance rules to",
        coursebasedconfirmmessage: "It will change the selected course based compliance rules.",
        selectallcourses: "Select all courses/modules",
        unselectallcourses: "Unselect all courses/modules",
        modulelistguide: "Select the courses/modules to apply the Compliance Rules for, the selected courses/modules will be assigned to the units chosen under Part 4 - Unit Pathway.",
        coursebaseddefaultquizpassmarkguideme: "Enter the default quiz pass mark for selected courses/modules, default is 100%",
        cbexpiregiventime: "Check to expire lessons/quizzes of the selected courses/modules after the given time period",
        cbexpirelessonguideme: "Expire lesson statuses, where applicable, after the given time period.  <br><br/><b>Note: </b>Salt Adaptive courses only consider quizzes when expiring statuses.",
        cbexpirequizguideme: "Expire quiz statuses after the given time period.",
        cbexpireongivendate: "Check to expire lessons/quizzes of the selected courses/modules on a given date.",
        cbexpirelessondateguideme: "Expire lesson statuses, where applicable, of the selected courses/modules on a given date.  <br><br/><b>Note: </b>Salt Adaptive courses only consider quizzes when expiring statuses.",
        cbexpirequizdateguideme: "Expire quiz statuses of the selected courses/modules on a given date.",
        cbapplylessonguideme: "Apply the above date to expire lesson statuses, where applicable, for the selected courses. Doing so will override all existing compliance rules for lessons applicable to the selected units below. Use with caution, as it is not reversible.",
        cbapplyquizguideme: "Apply the above date to expire quiz statuses, where applicable, on a given date for the selected courses. Doing so will override all existing compliance rules for quizzes applicable to the selected units below. Use with caution, as it is not reversible.",
        cbonetimecompletionguideme: "Apply One-time Course Completions to the selected courses.",
        cbexpandallunit: "Expand the unit tree to show all units",
        cbcollapseallunit: "Collapse the unit tree.",
        selectallunits: "Select all units.",
        deselectallunits: "Deselect all units.",
        cbunittree: "Select the units which the selected courses/modules and Compliance Rules above will apply to.",
        yearlyincrement: "Yearly Increment",
        lessonyearincrement: "Select the increment for Lesson Expiry compliance date, once the selected expiry date to the left has been reached",
        quizyearincrement: "Select the increment for Quiz Expiry compliance date, once the selected expiry date, to the left has been reached",
        //#endregion

        //#region Email Settings start
        orgemailtemplates: "Email Templates",        
        emailsettings: "Email Settings",
        orgemailrule:"Reminder Email Rules",     
        addorgemailrule:"Add Reminder Email Rules",       
        automatedemailing:"Automated Email Rules",
        addnewemailrule:"Add New Reminder Email Rules",
        //automatedemailingnotes:"Enable/disable notification emailing if you do not wish for any emails to be automatically sent (this does not affect any scheduled Reporting emails)",
        automatedemailingnotes:"Start/Stop Automated Emails, this covers Welcome Emails, Completion Emails, Manager Notifications and all Email Reminders. (This does not affect any scheduled report emails)",

        onoffemailnotification:"Stop / Start Emails notification",
        applytocourse:"Apply to (select Courses)",
        selectedcourse:"Selected Course",
        applytoselectedcourse:"Select the course(s) to apply the reminder email Rules to",
        initialenrolmentreminder:"Initial Enrolment Reminder",
        initialenrolmentremindernotes:"The My Training page will display the # of days until each quiz is due",
        initialenrolmentnumber:"Number of days for users to complete course(s)",
        enableemailrule: "Enable",
        preexpiryreminderemails:"Pre Expiry Reminder Emails",
        preexpiryreminderemailsnotes:"Passed Quiz results will expire after the designated expiry setting. Warnings may be sent to users that one of their modules will expire soon",
        preexpirynumber:"How many days before expiry?",
        preexpiryduedate:"The due date is calculated from date of enrolment plus the number of days in the Initial Enrolment period. eg. 01/01/2013 + 30 days = 31/01/2013",
        chkinitialenrolment:"Initial Enrolment",
        chkresitperiod:"Resit Period",
        applyto:"Apply To",
        postexpiryreminderemails:"Post Expiry Reminder Emails",
        postexpiryreminderemailsnotes:"Reminders may be sent to Learners when their Initial Enrolment Period ends and/or once they become Expired (Time Elapsed)",
        postexpirynumber:"Number of reminder notifications",
        postexpirynumbersendevery:" Send Reminder every",      
        expiryexample:"For example, 3 reminders repeated every 7 days: Expired users will be reminded on the day of their course completion deadline, a second email will be sent 7 days after their course completion deadline and third reminder will be sent 14 days after their course completion deadline.",
        managernotifications:"Manager Notifications",
        managernotificationsnotes:"Send a Manager Notification detailing the Learners with an Incomplete Course Status every # days",
        escalationoptions:" Escalation options:",
        cumulativelist:"Cumulative list",
        addorgemailruleguideme:"Add new reminder email rule.",
        coursenamecolumnguideme:"Course Name column is sortable by clicking on the column title or relevant arrows.",
        ieguideme:"Indicates if Initial Enrolment Period email notifications are enabled or disabled for the given course",
        preguideme:"Indicates if Pre-Expiry email notifications are enabled or disabled for the given course",
        postguideme:"Indicates if Post Expiry email notifications are enabled or disabled for the given course",
        ruleedit:"Click to edit the Email Reminder Rule for the given course.",
        ruledelete:"Click to delete the Email Reminder Rule for the given course.",
        ruledisable:"Toggle to enable/disable all notifications for the given course.",
        initialenrolmentperiod:"Initial Enrolment Period",
        coursename:"Course Name",
        preexpirynotification:"Pre Expiry Notification",
        postexpirynotification:"Post Expiry Notification",
        guidemeinitialenrolment:"When enabled, the number of days specified is how long an account has to complete an assigned course before becoming overdue. Only applicable for the first time they are assigned a course. The Initial Enrollment period is used to determine the Due Date displayed on an account’s My Training page.",
        guidemepreexpiry:"Enable Pre-Expiry reminder emails, an email reminder will be sent to an account when their course module status will expire on the specified days before it expires. Course modules can expire independently of each other, which could send reminder emails on different days.",
        guidemepreexpiryinitial:"Enable Pre-Expiry reminder emails for accounts in the Initial Enrollment Period. When enabled, accounts in the Initial Enrollment Period will be sent a reminder email on the specified days before their course module becomes overdue. Course modules can expire independently of each other, which could send reminder emails on different days.",
        guidemepreexpiryresitperiod:"Enable Pre-Expiry reminder emails for accounts approaching the resit period. When enabled, accounts that are approaching their resit period will be sent a reminder email on the specified days before their course module becomes due (course module status expires). Course modules can expire independently of each other, which could send reminder emails on different days.",
        guidemepostexpiry:"Enable Post-Expiry reminder emails, an email reminder will be sent to an account when their course module status has expired. When enabled a reminder will be sent on the day that a course module expires, then every specified days for the number of notifications specified. Course modules can expire independently of each other, which could send reminder emails on different days.",
        guidemepostexpiryinitial:"Enable Post-Expiry reminder emails for accounts in the Initial Enrollment Period. When enabled, accounts in the Initial Enrollment Period will be sent a reminder email after their course module becomes overdue, for every specified days and the number of notifications specified.  Course modules can expire independently of each other, which could send reminder emails on different days.",
        guidemepostexpiryresitperiod:"Enable Post-Expiry reminder emails for accounts in the resit period. When enabled, accounts in their resit period will be sent a reminder email after their course module becomes due (course module status expires) for every specified days and the number of notifications specified. Course modules can expire independently of each other, which could send reminder emails on different days.",
        guidememanage:"Enable Manager Notifications. When enabled, those accounts that have a manager email specified under their account setup and they have overdue courses, a manager notification will be sent every specified days to that manager.",
        guidememanagecumulative:"Enable Cumulative List for Manager Notifications. When enabled a cumulative list of users will be sent to the manager, which avoids multiple notifications being sent. ",
        //#endregion Email Settings end

        //#region Unit Mgmt Start
        tabunitdetails:"Unit Details",
        tabaddaccounttounit:"Add New Account to Unit",
        tabassignmoduletounit:"Assign Modules to Unit" ,
        tabsetmodulerule:"Set Unit Compliance Rule",
        tabmanageunitadmin:"Manage Unit Administrators",
        unitsettings: "Unit Settings",
        addunit: 'Add Unit',       
        moveunit:'Move Unit',
        unitmanagement:"Unit Management",
        addunitdetails:"Enter the unit details, then select save",
        unitpathwaydetails:"Unit Pathway",
        activeunit:"Unit Active",
        inactiveunit:"Unit Inactive",
        accountsinunit:"Accounts in this unit",
        createdby:"Created By",
        lastmodifiedby:"Last Modified By",
        subunit:"Sub - Unit",
        enteraccountdetails:"Enter the account details below",
        assigntoallsubunits:"Assign to all sub units",
        assigntoallsubunitsnotes:"Check this checkbox to enable the ability apply the selected courses/compliance rules to the sub units of this unit.",
        unitsavemoduleaccesnotes:"Please Note: When you Grant Access to a module for the first time, the Global Compliance Rules will apply. To set other compliance rule for this unit, select the 'Set Unit Compliance Rule' tab above. This will override Module Assignments for Account in this unit. ",
        courseenrolments:"Course Enrolments",
        coursenitbasedconfirmmessage: "Are you sure you want to over-write any existing Compliance Rules with these selected values?",
        selectallbuttoncoursemodule:"Triggers the check-box selection of all displayed courses.",
        unselectallbuttoncoursemodule:"Triggers the check-box unselection of all displayed courses.",
        coursemodulenamesearch:"Type a search term for course(s) or module name(s) to filter course(s) or module name(s) by this search term.",
        modulerulelistguide: "Select the courses/modules to apply the Compliance Rules for, the selected courses/modules will be assigned to the selected unit.",
        existingunitadminreport:"Existing Unit Administrators",
        //#endregion unit mgmt end

        //#region Policy builder settings start
        policysetting: "Policy Settings",
        policybuilder: "Policy Builder",
        addnewpolicy: "Add New Policy",
        policyname: "Policy Name",
        filename: "File Name(Size)",
        active: "Active",
        action: "Action",
        policies: "Policies",
        addpolicyguideme: "Click here to add a new Policy. ",
        policynamecolumnguideme:"This column will list the names of all created Policies. Sortable by clicking the column title. ",
        activecolumnguideme:"This column will indicate if the currently listed Policies are currently active or not. Sortable by clicking the column title.  ",
        filenamesizeguideme:"This column will list the filenames and file sizes of all created Policies, clicking will open the uploaded Policy document. Sortable by clicking the column title.  ",
        actioncolumnguideme:"Click this button in the Action column to edit the listed Policy.",
        confirmationmessage: "Confirmation Message",
        choosefile: "Choose file",
        inactive: "Inactive",
        policysupportedfiles: "Supported file formats: .pdf, .doc, .docx, .ppt, .pptx",  
        saveopolicybuilder: "Save Policy Builder",
        policynameguideme: "Enter a name for the new policy. ",
        policyconfirmguideme: "Enter a confirmation message that will be displayed to the user when they attempt to accept/attest to the policy. ",
        policystatusguideme: "Toggle the new policy active or inactive",
        policydocguideme: "Drag and drop your policy document, or click to browse for your policy document to be uploaded to Salt.  Accepted formats are .pdf, .doc, .docx, .ppt and .pptx.",
        policyacceptancegraph: "A graphical representation of attestation data, accepted/not accepted for the policy. ",
        
        policydetails: "Policy Details",
        assignunits: "Assign Units",
        assignpoints: "Assign Points",
        assignaccounts: "Assign Accounts",
        viewaccounts: "View Accounts",
        resetaccounts: "Reset Accounts",
        resetaccceptances: "Reset Acceptances",
        assign: "Assign",
        unassign: "Unassign",
        //#endregion Policy builder settings end

        //#region cpd Profile settings start
        cpdsetting: "CPD Settings",
        profileenable: "Enable",
	profilecloninglabel: "Clone CPD Details from",
        profilecloningnoselection: "No profile selected",
        profilecloningguidemetitle: "CPD Details Cloning Source",
        profilecloningguideme: "This dropdown list shows all current CPD Profiles for your organisation, selecting one will clone all basic details to a new profile, including Assigned Points.",
        profileenableguidemetitle: "Enable/Disable Profile Period",
        profileenableguideme: "Check to enable/disable the below Profile Period",
        addnewprofile: "Create New Profile",
        editprofile: "Edit Profile",
        addnewprofileguideme: "This will be saved as CPD Profile Name. ",
        editprofileguideme: "Edit here to change the CPD Profile Name. ",
        profilenamefilter: "Profile Name Filter",
        profilename: "Profile Name",
        currentperiod: "Current Period",
        futureperiod: "Future Period",
        profilestatus: "Status",
        addprofileguideme: "Click to create a new CPD Profile. ",
        profilenamefilterguideme:"Use this filter to filter the listing of CPD Profiles by Name. ",
        profilenamecolumnguideme:"This column shows Profile name, clickable to sort ascending and descending. ",
        currentperiodguideme:"This column shows the Current Period for CPD Profiles, clickable to sort ascending and descending. ",
        futureperiodguideme:"This column shows the Future Period, if applicable, clickable to sort ascending and descending. ",
        profilestatusguideme:"This column indicates if the CPD Profile status is active or inactive, clickable to sort ascending and descending. ",
        profilestatusguideme2:"This column indicates if the CPD Profile status is enabled or disabled based on the current period, clickable to sort ascending and descending. ",
        profileactioncolumnguideme:"Click the edit button in the Action column to edit a CPD Profile. ",
        profilenameguideme:"Enter a name for your CPD Profile. ",
        profilenewperiod: "New Period",
        profilenewperiodguideme:"This section is for CPD Profile new period date range input. ",
        profilenewperiodguidemefrom:"Enter a start date for your CPD Profile. ",
        profilenewperiodguidemeto:"Enter an end date for your CPD Profile. ", //        
        profilenewperiodrequiredpointsguideme:"Enter the required amount of CPD points for your new CPD Profile. ",
        profilenewperiodrequiredpoints:"Required Points",
        profilecurrentperiod: "Current Period",
        profilecurrentperiodguideme:"This section is for CPD Profile current period date range. ",
        profilecurrentperiodguidemefrom:"Edit here to change the start date of your CPD Profile. ",
        profilecurrentperiodguidemeto:"Edit here to change the end date of your CPD Profile. ", //        
        profilecurrentperiodrequiredpointsguideme:"Edit here to change the required amount of CPD points of your CPD Profile. ",
        profilecurrentperiodrequiredpoints:"Required Points",
        attheendofperiod:"At the End of the CPD Period",
        attheendofperiodguideme: "At the end of the current CPD period, choose a rollover option for the new CPD period. ",
        attheendofperiodnoaction:"No action",
        attheendofperiodnoactionguideme:"No action, do not create a new period ",
        attheendofperiodautoincrement:"Automatic increment by the duration of the current period",
        attheendofperiodautoincrementguideme:"Automatic increment by duration of current, create a new period with the same duration as the previous ",
        attheendofperiodgivenmonthincrement1:"Automatic increment by ",
        attheendofperiodgivenmonthincrement2:" month",
        attheendofperiodgivenmonthincrement3:" months",
        attheendofperiodgivenmonthincrementguideme:"Automatic increment by so many months, enter number of months to increment by",
        attheendofperiodgivenfutureperiod:"Create a new Future Period with following details",
        attheendofperiodgivenfutureperiodguideme:"Create a new future period by the following date range ",
        //attheendofperiodgivenfutureperiodrange: "Profile Period:",
        //attheendofperiodgivenfutureperiodpoints: "Required Points:",
        attheendofperiodgivenfutureperiodfromguideme:"Enter a start date for your Future CPD Period. ",
        attheendofperiodgivenfutureperiodtoguideme:"Enter an end date for your Future CPD Period. ",
        attheendofperiodgivenfutureperiodpointsguideme:"Enter the required amount of CPD points for the future CPD period. ",
        saveprofiledata:"Save Profile",
        saveprofiledataguideme:"Click here to save your new CPD Profile general details. ",
        saveprofiledatapoints:"Save Profile Points",
        saveprofiledatapointsguideme:"Click here to save the given points for CPD Profile. ",
        saveprofiledataunits:"Save Profile Units",
        saveprofiledataunitsguideme:"Click here to save the given Unit-assignments for CPD Profile. ",
        saveprofiledatausers:"Save Profile Accounts",
        saveprofiledatausersguideme:"Click here to save the given Account-assignments for CPD Profile. ",
        profilepointsedit: "Edit the profile points",
        coursemoduleprofilepoints: "Course/Module Points Assignment",
        points: "Points",
        coursemoduletotalprofilepoints: "Total Points for course modules: %1 Points",
        coursemoduletotalprofilepoints2: "Total Points for course modules",
        policytotalprofilepoints: "Total Points for policies: %1 Points",
        policytotalprofilepoints2: "Total Points for policies",
        policyprofilepoints: "Policy Points Assignment",
        assignpointsto: "Assign points to",
        allaccountsincludingcompleted: "All accounts, including the accounts who have completed the modules and/or attested to policies",
        allaccountsexcludingcompleted: "All accounts, excluding the accounts who have completed the modules and/or attested to policies",
        showonlypointsassigned: "Show Only Courses with Points",
        showonlypointsassignedpolicy: "Show Only Policies with Points",
        profilequiz: "Quiz",
        profilelesson: "Lesson",
        assignbuttonspaneltitle: "CPD Profile Advanced Settings",
        assignbuttonspanelmessage: "CPD Profile different set tings such as assigning CPD points or granting or denying accesses to units/accounts",
        assignbuttonspanelpointstitle: "Assign Points",
        assignbuttonspanelpointsmessage: "Click here to assign CPD points per course/module and policy.",
        assignbuttonspanelpointslqtitle: "Requirements to Earn Points",
        assignbuttonspanelcpdpointaccrualrequirement: "*CPD points are earned for quiz completion only. ",
        //assignbuttonspanelpointslqmessage: "By default both lesson and quiz are expected to be completed to accrue CPD points. If lesson is not present separately, completing the quiz earns the points. ",
        assignbuttonspanelpointslqmessage: "By default completing the quiz alone earns the points. ",
        assignbuttonspanelpointscmseektitle: "Course/Module Filter",
        assignbuttonspanelpointscmseekmessage: "Use the search function to filter the course/module list below. Multiple filters can be applied in a single search by separating the filters by comma to get a combined filtered list. ",
        assignbuttonspanelpointscmptfiltertitle: "Points Assignment Filter - Courses",
        assignbuttonspanelpointscmptfiltermessage: "Check this to only show Courses that currently have CPD Points assigned to them. ",
        assignbuttonspanelpointscmexpalltitle: "Expand All (Filtered) Courses",
        assignbuttonspanelpointscmexpallmessage: "Expand to show all courses/modules in the below listing. ",
        assignbuttonspanelpointscmclpalltitle: "Collapse All (Filtered) Courses",
        assignbuttonspanelpointscmclpallmessage: "Collapse all courses/modules in the below listing. ",
        assignbuttonspanelpointspptfiltertitle: "Points Assignment Filter - Policies",
        assignbuttonspanelpointspptfiltermessage: "Check this to only show Policies that currently have CPD Points assigned to them. ",
        assignbuttonspanelpointsclvltitle: "Course Level with Expander",
        assignbuttonspanelpointsclvlmessage: "Click to expand the course modules and to assign points so that those CPD points are accrued upon completion. Bold font indicates that this course has valid points assigned for one or more modules of it",
        assignbuttonspanelpointsmlvltitle: "Module item with Point input",
        assignbuttonspanelpointsmlvlmessage: "Enter the amount of CPD points to be accrued upon completion of the relevant course module. ",
        assignbuttonspanelpointscmtottitle: "Total points assigned to course-modules",
        assignbuttonspanelpointscmtotmessage: "This will display the total number of CPD points that can be accrued if the account were to complete all selected course modules. ",
        assignbuttonspanelpointspseektitle: "Policy Filter",
        assignbuttonspanelpointspseekmessage: "Use the search function to filter the policy list below. Multiple filters can be applied in a single search by separating the filters by comma to get a combined filtered list. ",
        assignbuttonspanelpointsplvltitle: "Policy item with Point input",
        assignbuttonspanelpointsplvlmessage: "Enter the amount of CPD points to be accrued when the account attests to the relevant policy. ",
        assignbuttonspanelpointsptottitle: "Total points assigned to policies",
        assignbuttonspanelpointsptotmessage: "This will display the total number of CPD points that can be accrued if the account were to complete all selected policies. ",
        assignbuttonspanelpointsincltitle: "Points Assigning Type to include previous completions",
        assignbuttonspanelpointsinclmessage: "Selecting ‘All accounts, including the accounts who have completed the modules’ will assign points based on previous completions. ",
        assignbuttonspanelpointsexcltitle: "Points Assigning Type to exclude previous completions",
        assignbuttonspanelpointsexclmessage: "Selecting ‘All accounts, excluding the accounts who have completed the modules’ will NOT assign points based on previous completions. ",
        assignbuttonspanelpointssavetitle: "Save Assign Points",
        assignbuttonspanelpointssavemessage: "Click here to save the assigned CPD points per course/module and policy.",
        assignbuttonspanelunittitle: "Assign Units",
        assignbuttonspanelunitmessage: "Click here to assign this CPD Profile to specific Units. ",
        assignbuttonspanelunitpaneltitle: "Unit Selection Controls",
        assignbuttonspanelunitpanelmessage: "Use these controls to Select/Deselect all units, as well as Expand/Collapse the entire unit tree. ",
        assignbuttonspanelunitsearchtitle: "Unit Filter",
        assignbuttonspanelunitsearchmessage: "Use the search function to filter the unit tree. ",
        assignbuttonspanelunittreetitle: "Unit Tree",
        assignbuttonspanelunittreemessage: "Select the relevant units by clicking the checkboxes, you can select all units under a parent unit by clicking on the unit name. ",
        assignbuttonspanelunitsavetitle: "Save Assign Units",
        assignbuttonspanelunitsavemessage: "Click here to save the assigned Units to this CPD Profile. ",
        assignbuttonspanelaccounttitle: "Assign Accounts",
        assignbuttonspanelaccountmessage: "Click here to assign this CPD Profile to specific Accounts. ",
        assignbuttonspanelaccountpaneltitle: "Account Selection Controls",
        assignbuttonspanelaccountpanelmessage: "Use these controls to Select/Deselect all units, as well as Expand/Collapse the entire unit tree. ",
        assignbuttonspanelaccountsearchtitle: "Unit Filter",
        assignbuttonspanelaccountsearchmessage: "Use the search function to filter the unit tree. ",
        assignbuttonspanelaccountunittreetitle: "Unit Tree",
        assignbuttonspanelaccountunittreemessage: "Select the relevant units to filter accounts by clicking the checkboxes, you can select all units under a parent unit by clicking on the unit name. ",
        assignbuttonspanelaccountfntitle: "Firstname filter",
        assignbuttonspanelaccountfnmessage: "Enter in First name to filter accounts by. ",
        assignbuttonspanelaccountlntitle: "Lastname filter",
        assignbuttonspanelaccountlnmessage: "Enter Last name to filter accounts by. ",
        assignbuttonspanelaccountuntitle: "Username filter",
        assignbuttonspanelaccountunmessage: "Enter username to filter accounts by. ",
        assignbuttonspanelaccountemtitle: "Email Filter",
        assignbuttonspanelaccountemmessage: "Enter Email address to filter accounts by. ",
        assignbuttonspanelaccountaidtitle: "Account ID Filter",
        assignbuttonspanelaccountaidmessage: "Enter Account ID to filter accounts by. ",
        assignbuttonspanelaccountininatitle: "Inactive Account Filter",
        assignbuttonspanelaccountininamessage: "Check/Uncheck to include/exclude inactive accounts. ",
        assignbuttonspanelaccountsrchtitle: "Account Search",
        assignbuttonspanelaccountsrchmessage: "Click to search for accounts to assign. ",
        assignbuttonspanelaccountfclrtitle: "Clear Filters",
        assignbuttonspanelaccountfclrmessage: "Click to clear all filters. ",
        assignbuttonspanelaccountasnttitle: "Assign/Unassign Accounts",
        assignbuttonspanelaccountasntmessage: "Check/uncheck to assign/unassign an account to/from this CPD profile. ",
        assignbuttonspanelaccountupttitle: "Unitpathways of Accounts",
        assignbuttonspanelaccountuptmessage: "Shows Unit Pathway of each account based on search terms. ",
        assignbuttonspanelaccountfnttitle: "Firstnames of Accounts",
        assignbuttonspanelaccountfntmessage: "Shows First Name of each account based on search terms. ",
        assignbuttonspanelaccountlnttitle: "Lastnames of Accounts",
        assignbuttonspanelaccountlntmessage: "Shows Last Name of each account based on search terms. ",
        assignbuttonspanelaccountsavetitle: "Save Assign Accounts",
        assignbuttonspanelaccountsavemessage: "Click here to save the assigned/unassigned Accounts to/from this CPD Profile. ",
        assignbuttonspanelviewaccounttitle: "View/Remove Accounts",
        assignbuttonspanelviewaccountmessage: "Once you have assigned this CPD Profile to Units and/or Accounts, click here to view/remove the Accounts that are currently assigned this CPD Profile. ",
        assignbuttonspanelviewaccountasntitle: "Assign/Unassign Accounts",
        assignbuttonspanelviewaccountasnmessage: "Shows that the current CPD Profile is assigned to this account. Check/uncheck to assign/unassign an account to/from this CPD profile. ",
        assignbuttonspanelviewaccountuptitle: "Unitpathways of Accounts",
        assignbuttonspanelviewaccountupmessage: "Shows the Unit Pathway of the relevant account that has this CPD Profile assigned. ",
        assignbuttonspanelviewaccountfntitle: "Firstnames of Accounts",
        assignbuttonspanelviewaccountfnmessage: "Shows the First Name of the relevant account that has this CPD Profile assigned. ",
        assignbuttonspanelviewaccountlntitle: "Lastnames of Accounts",
        assignbuttonspanelviewaccountlnmessage: "Shows the Last Name of the relevant account that has this CPD Profile assigned. ",
        assignbuttonspanelviewaccountsavetitle: "Save Removed Accounts",
        assignbuttonspanelviewaccountsavemessage: "Click here to save the removed Accounts as unassinged from this CPD Profile. ",
        assignbuttonspanelpointsapplycommontofcmods1: "Check this option to set the same Points value of",
        assignbuttonspanelpointsapplycommontofcmods2: " for all Course/Modules filtered below",
        assignbuttonspanelpointsapplycommontofpols1: "Check this option to set the same Points value of",
        assignbuttonspanelpointsapplycommontofpols2: " for all Policies filtered below",
        assignbuttonspanelpointsapplycommontofcmodsguidemet: "Same Points for Course Modules",
        assignbuttonspanelpointsapplycommontofpolsguidemet: "Same Points for Policies",
        assignbuttonspanelpointsapplycommontofcmodsguidemem: "When this checkbox is ticked, the amount of points specified will be applied to the filtered course list, any/all filters can be used to filter the course list before checking this box.  Please remember to save the changes made after checking this box. ",
        assignbuttonspanelpointsapplycommontofpolsguidemem: "When this checkbox is ticked, the amount of points specified will be applied to the filtered policy list, any/all filters can be used to filter the policy list before checking this box.  Please remember to save the changes made after checking this box. ",
        cpdmsgvalidname: "You need to specify a valid 'Profile Name'",
        cpdmsgnamelen: "'Profile Name' length cannot exceed 255 characters",
        cpdmsgvalidstdtcur: "You need to specify a valid 'Start Date' for the Current CPD period",
        cpdmsgvalidendtcur: "You need to specify a valid 'End Date' for the Current CPD period",
        cpdmsgvalidendttdcur: "You need to specify an 'End Date' which is equal to or later than today for the Current CPD period",
        cpdmsgvalidsteddtcur: "'Start Date' should be smaller or equal to 'End Date' for the Current CPD period",
        cpdmsgvalidrqptcur: "You need to specify a valid 'Required Points' for the Current CPD period",
        cpdmsgvalidincmncur: "You need to specify a valid 'increment months' for the Future CPD period",
        cpdmsgvalidstdtfut: "You need to specify a valid 'Start Date' for the Future CPD period",
        cpdmsgvalidendtfut: "You need to specify a valid 'End Date' for the Future CPD period",
        cpdmsgvalidstdtfutwithcur: "You need to specify the Future CPD period 'Start Date' to be equal to or later than the end date of the Current CPD period",
        cpdmsgvalideddtfutwithcur: "You need to specify the Future CPD period 'End Date' to be equal to or later than the end date of the Current CPD period",
        cpdmsgvalidrqptfut: "You need to specify a valid 'Required Points' for the Future CPD period",
        cpdmsgvalidsteddtfut: "'Start Date' should be smaller or equal to 'End Date' for the Future CPD period",
        cpdmsgsavedefp3: "As the existing current period has been disabled, it will be saved as an empty period. ",
        cpdmsgsavedefp4: "As the given current period is in future, it will be saved as future period. ",
        cpdmsgsavedefp1: "You are saving only general CPD Profile details. ",
        cpdmsgsavedefp2: "Please close this warning to save changes to CPD Profile details. ",
        cpdmsgsavepts1: "You are saving only updates to Assign Points. Please close this warning to save changes to Assign Points. ",
        cpdmsgsaveunits1: "You are saving only updates to Assign Units. Please close this warning to save changes to Assign Units. ",
        cpdmsgsaveac1: "You are saving only updates to Assign Accounts. Please close this warning to save changes to Assign Accounts. ",
        cpdmsgsavevac1: "You are saving only updates to Accounts. Please close this warning to save changes to Accounts. ",
        cpdmsgsavedefpass: "CPD details have been saved successfully! ",
        cpdmsgsavedeffail: "Failed to save CPD Profile details!",
        cpdmsgsaveptspass: "CPD profile points changes have been saved successfully! ",
        cpdmsgsaveptsfail: "Failed to save CPD profile points changes!",
        cpdmsgreason: "Reason",
        cpdmsgsaveunitpass: "CPD profile Unit-assignment changes have been saved successfully! ",
        cpdmsgsaveunitfail: "Failed to save CPD profile Unit-assignment changes!",
        cpdmsgsaveuserpass: "CPD profile User-unassignment changes have been saved successfully! ",
        cpdmsgsaveuserfail: "Failed to save CPD profile User-unassignment changes!",
        cpdmsgsaveunitwarn: "There are no changes to Unit Assignments. Do you want to re-apply the existing Unit Assignments which will override any Account specific assignments? ",
        cpdmsgsaveunitwarn2: "You are saving Unit Assignments, any specific assignments for Accounts in these Units will be overridden. Please confirm to proceed or cancel to back out. ",
        cpdmsggetuserwarn: "Fetching records have failed, please try again. ",
        cpdmsggetcpdwarn: "Getting CPD Profile has failed! Please try again! ",
        //#endregion cpd Profile settings end
      
        //#region MyTraining
        coursepublishtype: "Course Publish Type: ",
        onetimeonlycourse: "One Time Completion Only",
        onetimeonlycourselistpage:"One time completion",
        //#endregion MyTraining
        
        //#region Links
        links: "Links",
        modifylinks: "Modify Links",
        addnewlink:"Add New Link",
        caption:"Caption",
        showdisclaimer:"Show Disclaimer",
        linknote: "Links without a URL will be treated as dividers.",
        //#endregion Links

        //#region Organisation Administrators        
        organisationadministrators:"Organisation Administrators",
        addneworgadmin: "Add Organisation Administrator",
        sendanemailto:"Send an email to",
	      shibbolethguideme: "SAML-based identity systems supported, including Shibboleth and the following guides for AzureAD, ADFS and Okta.",
        reportscheduler:"Report Scheduler",
        addorganisation: "Add Organisation",
        remainingdiskspace: "Remaining Disk Space",
        //#endregion Organisation Administrators

        //#region move users to unit
        moveaccountstounit: "Move Accounts to Unit",
        //#endregion move users to unit

        //#region MoveUnit
        moveastoplevelunitlabel: "Top Level",
        invalidunittomoveerror: "Select a valid unit to move",
        invalidunittargetformoveerror: "Select a valid target unit or check the Top Level if the unit will not be a child unit after move",
        unitstobedifferenterror: "Select different source and target units",
        unitmovedsuccessmessage: "Unit Moved successfully!",
        unitmovedfailuremessage: "Moving Unit has failed",
        unitmovelabelforcommondnd: "Drag and Drop any unit to change its hierarchy (Moving any root unit of sub-tree will move the whole sub-tree)",
        unitmovelabelforsource: "Select a Unit to be moved",
        unitmovelabelfortarget: "Select a target Parent unit or check Top Level",
        unitmoveconfirmationmessage: "This may alter previous administrative rights. Are you sure you wish to proceed? ",
        unitmovecommonguidemetitle: "Unit Pathway Drag And Drop to Move",
        unitmovecommonguidememsg: "Drag and Drop any unit to change its hierarchy. Moving any root unit of sub-tree will move the whole sub-tree. The final child node of every unit sub-tree is indicated as '--drop here--' which can be used as a drop location. ",
        unitmoveselectionareaguidemetitle: "Unit Pathway tree source and target",
        unitmoveselectionareaguidememsg: "Select from-unit on left side and to-unit on the right side",
        unitmoveswitchtodndguidemetitle: "Switch to drag and drop",
        unitmoveswitchtodndguidememsg: "Click to switch to the Drag and Drop method of moving units. ",
        unitmoveswitchtotreeselectionguidemetitle: "Switch to source and target tree selection",
        unitmoveswitchtotreeselectionguidememsg: "Click to switch to the Source and Target selection method of moving units. ",
        unitmovesourcetreeexpandguidememsg: "Click to expand the unit tree to show all units of source tree. ",
        unitmovesourcetreecollapseguidememsg: "Click to Collapse the unit tree of source. ",
        unitmovesourcetreesearchguidememsg: "Filter units by name by typing a partial or full unit name of source tree. ",
        unitmovetargettreeexpandguidemetitle: "Expand All Units",
        unitmovetargettreeexpandguidememsg: "Click to expand the unit tree to show all units of target tree. ",
        unitmovetargettreecollapseguidemetitle: "Collapse All Units",
        unitmovetargettreecollapseguidememsg: "Click to Collapse the unit tree of target. ",
        unitmovetargettreesearchguidemetitle: "Search Units",
        unitmovetargettreesearchguidememsg: "Filter units by name by typing a partial or full unit name of target tree. ",
        unitmovesourcetreeguidemetitle: "Unit Pathway source tree",
        unitmovesourcetreeguidememsg: "Select the unit you want to move. ",
        unitmovetargettreeguidemetitle: "Unit Pathway target tree",
        unitmovetargettreeguidememsg: "Select a destination unit you want to move the source unit to. ",
        unitmovetargettreetoplevelguidemetitle: "Top level selection",
        unitmovetargettreetoplevelguidememsg: "Check this checkbox if you want the selected source unit to become a top level unit. ",
        unitmovesavetriggerguidemetitle: "Move Unit Trigger",
        unitmovesavetriggerguidememsg: "Click Save to move the selected source unit to the selected destination location. ",
        moveunitmethodtoggle1label: "Switch to Drag and Drop based move unit",
        moveunitmethodtoggle2label: "Switch to source and target selection based move unit",
        switchtoselectionmove: "Switch to selection based unit move",
        switchtodndnmove: "Switch to drag-and-drop based unit move",
        //#endregion MoveUnit

        //#region SSO Guide       
        googlesssosetupguideme: "Click to download an Google SAML SSO Setup Guide",  
        googlessosetupguides: "Google SAML SSO Setup Guide", 
        //#endregion SSO Guide
    
        //#region Archive Accounts
        archiveaccounts: "Archive Accounts" ,
        archiveaccountstitle: "Archive Accounts from the following dates" ,
        archiveaccountssubtitle: "Archive Accounts who have not logged in since the date entered." ,
        archiveaccountsnotes: "Include users who have never logged in and have not had their user details modified since the date entered.",
        archivedatetitle:"Date Filter",
        archivedatemsg:"Specify a date to select Accounts that have not logged in since this date.",
        archiveincludeaccountstitle: "Include Accounts Filter",
        archiveincludeaccountsmsg: "Check to include users who have never logged in and have not had their Account details modified since the entered date.",
        archiveresettitle: "Reset Filters",
        archiveresetmsg: "Click to clear the current criteria.",
        archivesearchtitle: "Search Results",
        archivesearchmsg: "Click to search for Accounts based on the provided criteria.",
        archiveupmsg: "Shows the Unit Pathway of the Accounts that fulfil the search criteria.",
        archivefnmsg: "Shows the First Name of the Accounts that fulfill the search criteria.",
        archivelnmsg: "Shows the Last Name of the Accounts that fulfill the search criteria.",
        archiveemailmsg: "Shows the Email Address of the Accounts that fulfill the search criteria.",
        archivelastlogintitle: "Last Login",
        archivelastloginmsg: "Shows the last login date of the Accounts that fulfil the search criteria, if applicable.",
        archivearchivemsg: "Check the archive field to select this Account to be archived.",
        archivesavetitle: "Save",
        archivesavemsg: "Click save to archive the selected Accounts.",
        //#endregion Archive Accounts

        // CAR Account Details
        caraccountspecificguidemetitle: "Account's Current Admin Report",
        caraccountspecificguidememsg: "Click to expand to view a Current Admin Report of the account being viewed. You are also able to export this report after expanding the section.",
        // CAR Account Details


        //#region Accounts management
        filter: "Filter",
        unitfiltersearch: "Search unit/subunits by typing unit name.",
        unitfiltersearchtitle: "Filter Units",
        firstnamemsg: "Filter Results by First Name.",
        lastnamemsg: "Filter Results by Last Name.",
        usernamemsg: "Filter Results by Username.",
        useridmsg: "Filter Results by Account ID.",
        useriderr: "Please enter valid Account ID",
        emailmsg: "Filter Results by Email Address.",
        includeinactiveguideme: "Filter Results by Including Inactive.",
        searchusers: "Search Accounts",
        searchusersmsg: "Search for Accounts.",
        resetall: "Reset",
        resetallmsg: "Reset all the Fields.",
        searchforaccounttomove: "Search for accounts to move.",
        leaveblankforunit: "Leave blank for all Units",
        norecords: "No Records found, please try again.",
        fetchfailed: "Fetching records have failed, please try again.", 
        //#endregion Accounts management
       
        //untill above  language transaltion's added to all live sites
        
        //#region My Extername Certificate
        myextcertificate:"My External Certificates",
        extcertificate:"External Certificates",
        extcertificatedetails:"External Certificate Details",
        addextcertificate: "Add External Certificate",
        certificatename: "Certificate Name",
        assigntounit: "Assign to Unit",
        assigntounitinfo: "Select the Unit(s) to assign the certificate to",
        assignduedate: "Assign due date",
        allowlearnertoedit: "Allow account to edit entry",
        allowlearnertoeditinfo: "Enter the number of days that account can reupload and edit valit to and from dates.",
        entereditdurationdays: "Enter edit duration (days)",
        enablecpdpoints: "Enable CPD Points",
        enternumofpoints: "Enter number of points",
        activeinactive: "Active / Inactive",
        showexpiredcerificate:"Show Closed Certificates",
        certificateuploadfile: "Drag file to Upload", 
        certificateuploadfilesize: "Max file size:", 
        certificateuploadfilesupportedfiles: "Supported file type: PDF",
        validfrom: "Valid From",
        validto: "Valid To",
        entervalidfrom: "Enter Valid From Date",
        entervalidto: "Enter Valid To Date",
        lastmodifieddate: "Last Modified Date",
        modifiedby: "Modified By",
        uploadcertificate:"Upload External Certificate",
        editcertificate:"Edit External Certificate",
        certificatenameguideme:"Displays the Name of the External Certificate.",
        validfromguideme:"Displays the Valid From Date for the uploaded External Certificate.",
        validtoguideme:"Displays the Valid To Date for the uploaded External Certificate.",
        lastmodifieddateguideme:"Displays the last modified date for the uploaded External Certificate.",
        modifiedbyguideme:"Displays who modified the External Certificate.",
        statusguideme:"Displays the status of the External Certificate, New, Valid and Closed. New: a new certificate is to be uploaded. Valid: you have uploaded a certificate. Expired: your certificate has expired.",
        viewpdfguideme:"Click to view uploaded External Certificate.",
        actionsguideme:"Click to show more actions.",
        showexpiredcerificateguideme:"Check this option to see your closed External certificates.",
        learnereditentryguideme:"Account not allowed to Edit Certificate",
        certificatexportdatatitle: "Export",
        certificatexportdatainfo: "Click on relevant icon to export current list of External Certificates to XLS, CSV or PDF.",

        selectcertitiviewusers:"Select certificate to view assigned users",
        viewproperties:"View Properties",
        providedcertificates:"Provided",
        expiredcertificates:"Closed",
        notprovidedcertificates:"Not Provided",
        hasnovaliddates: "Enable Certificate validity period",
        assignedlearners:"# Assigned Accounts",
        provided:"% Provided",
        createddate:"Created Date",
        addextcertificateguideme:"Click to add new External Certificate.",
        externalcertificateguideme:"Displays all current External Certificate Names.",        
        assignedlearnersguideme:"Displays how many accounts the relevant External Certificate is assigned to.",
        providedguideme:"Displays how many accounts have provided their certificate.",
        createddateguideme:"Displays the date this External Certificate was created.",        
        modifieddateextcertiguideme:"Displays the last modified date for this External Certificate.",
        modifiedextcertiguideme:"Displays the account that last created/modified this External Certificate.",
        statusdextcertiguideme:"Displays the status of the External Certificate, either Active or Inactive.",
        actionextcertiguideme:"Click to edit an existing External Certificate.",

        //#endregion My Extername Certificate

        //#region TipVideos
        selectpagereference: "None",
        tipvideo: "Tip Video",
        tipvideos: "Tip Videos",
        tvgotomanage: "Click here to add or modify the Tip Videos",
        tvtotaltitle: "Total Videos Count",
        tvtotalmsg: "This shows the total number of Tip Videos in the below table",
        tipvideosearch: "Video Search",
        tipvideostopheading: "Browse Videos",
        tvlistsectiontitle: "Tip Videos Section",
        tvlistsectionmsg: "Here is the list of all the help Tip videos for Salt",
        tvfiltertitle: "Tip Videos Search",
        tvfiltermsg: "Search the tip videos by entering filtering keys",
        tvmnovideos: "There are no Tip Videos present",
        managetipvideosmenu: "Manage Tip Videos",
        managetipvideostitle: "Add or Modify Tip Videos",
        addnewtipvideo:"Add New Video",
        addnewtipvideomsg:"Click to add the new Tip Video details",
        videocategory:"Video Category",
        videourl:"Video URL",
        videomodify:"Modify",
        videodelete:"Delete",
        videoisdefault: "Is Default?",
        videoorgname: "Org Name",
        videopageref: "Page Reference",
        tvccaptionadd: "Add Video Title",
        tvccaptionaddmsg: "Enter a title for the Tip Video.",
        tvcurladd: "Add URL",
        tvcurladdmsg: "Enter a URL for the Tip Video.",
        tvccategoryadd: "Add Category",
        tvccategoryaddmsg: "Enter a Category for the Tip Video.",
        tvcsave: "Save Tip Video",
        tvcsavemsg: "Click to save the new Tip Video.",
        tvccaption: "Video Title",
        tvccaptionmsg: "Column displays all Tip Video titles.",
        tvcurlmsg: "Column displays all Tip Video URLs.",
        tvdefault: "Is Default Tip Video?",
        tvdefaultmsg: "Check if this is the default video for this page, if custom, leave unchecked.",
        tvorgname: "Mapped Organisation Name",
        tvorgnamemsg: "If the tip video is custom, the Org Name will be shown here.",
        tvpageref: "Mapped Page Reference",
        tvpagerefmsg: "Choose a page that the video icon will be shown on for this tip video.",
        tvccategory: "Category",
        tvccategorymsg: "Column displays all Tip Video categories.",
        tvceditmsg: "Click the edit button for the relevant Tip Video to modify the details of the Tip Video.",
        tvccancel: "Cancel",
        tvccancelmsg: "Click the cancel button for cancelling the editing of the relevant Tip Video.",
        tvcdeletemsg: "Click the delete button for the relevant Tip Video to remove the Tip Video.",
        tvmloadfail: "Failed to load the tip videos",
        tvmdelsuccess: "Tip Video deletion has succeeded.",
        tvmdelfail: "Tip Video deletion has failed.",
        tvminvalidurl: "Invalid URL is given",
        tvmsavesuccess: "Tip Video save has succeeded.",
        tvminvalidtitle: "Please select valid title to continue!",
        tvmconfirmdelete: "Are you sure you want to delete this Tip Video?",
        tvmanageredirecttitle: "Redirect to manage tip videos",
        tvmanageredirectmsg: "Click here to redirect to manage tip videos",
        //#endregion TipVideos

        //#region MyTraining
        mytraininggmtassignedcourses: "Assigned Courses",
        mytraininggmmassignedcourses: "This area will show you all your assigned Courses.",
        mytraininggmtcoursestatuses: "Course statuses",
        mytraininggmmcoursestatuses1: "The Course statuses shown here will be either Incomplete or Complete",
        mytraininggmmcoursestatuses2: "You need to successfully Pass all assigned Modules for the Course to present as Complete",
        mytraininggmmaction: "Select the blue Start button to view the Module(s) that comprise this Course.",
        mytraininggmtcpdcard: "CPD Card",
        mytraininggmmcpdcard1: `CPD stands for Continuing Professional Development and this card lists all the assigned CPD profiles and their details`,
        mytraininggmmcpdpoints: `This column shows the total available CPD points for the specific course`,
        mytraininggmmlastcompdt: `This area will show any Last Completion Dates for a Course`,
        mytraininggmmduedate: `This section will guide you on when this course is next due to be completed again`,
        mytraininggmmcoursecomp: "The Course Completion Indicator shows your Completed progress for all your assigned courses.",
        mytraininggmmpolicyacc: "The Policy Acceptance Indicator shows your progress for all your assigned Policies",
        mytraininggmmquizcomp: "The Quiz Completion Indicator shows your Passed progress of all your assigned Quizzes. The % will change as you complete courses, or if they required to be completed again, or if new training is assigned to you. This % may be different to your overall Course Completion Indicator and depends on the amount of Quizzes you are assigned to.",
        mytraininggmmfilter: "Selecting one of these buttons will filter items on this page.",
        mytraininggmtcpdprof: "CPD Profiles",
        mytraininggmmcpdprof: "If you are assigned more than one CPD Profile, a drop-down menu will appear, listing the CPD Profile titles, the displayed points will reflect the selected CPD Profile.",
        mytraininggmmcpdprd: "The CPD Period specifies the date range that CPD Points can be earned.",
        mytraininggmmreqd: "The Required Points states the total # of CPD Points that may earned during the CPD Period.",
        mytraininggmtearned: "Earned CPD Points",
        mytraininggmmearned: "The Points Earned value is the total count of CPD Points you have earned during the current CPD Period. Points are reset to 0 when the new CPD Period commences.",
        //#endregion MyTraining

        //#region MyDashboard
        mydashgmmcrscomp: "The Course Completion Indicator shows your completed progress of all your assigned courses. The % will change as you complete courses, or if they required to be completed again, or if new training is assigned to you.",
        mydashgmtcnt: "Contact",
        mydashgmmcnt: "Select this button to email support for any technical difficulties with Salt.",
        mydashgmmwelmsg: "Select this button to view the Welcome Message again.",
        mydashgmtprifea: "Privacy Feature",
        mydashgmmprifea: "Select this to button to see how we manage your personal data in Salt.",
        mydashgmtextcert: "Provided External Certificates",
        mydashgmmextcert: "The External Certificates Indicator shows your import progress for all your assigned External Certificates",
        mydashgmmmytrain: "Select the My Training button to view your assigned training and/or Policies",
        mydashgmmmyrep: "Select the My Report button to view your assigned training and their progress. Export your Personal Report as a PDF to provide evidence of training and/or CPD Points earned.",
        mydashgmmaccset1: `Select the Account Settings button to`,
        mydashgmmaccset2: `Change your name`,
        mydashgmmaccset3: `Reset your password`,
        mydashgmmaccset4: `Swap your First and Last Names on your Course Completion Certificate`,
        mydashgmmaccset5: `Change your preferred system language`,
        mydashgmtcpdpts: "Total CPD Points",
        mydashgmterncpdpts: "Earned Points",
        //#endregion MyDashboard

        //#region LearningModules
        learnmodsgmmwelcome1: `This page lists all Modules that comprise this Course which may be more than one.`,
        learnmodsgmmwelcome2: `Most courses contain a combined Lesson and Quiz however some courses have these as separate items and they will present on separate rows. Both might be required to be completed by your Organisation.`,
        learnmodsgmmwelcome3: `The Quick Facts Sheet will provide you with an overview of each Module.`,
        learnmodsgmmwelcome4: `You need to successfully Pass all assigned Module Quizzes for the Course to be updated to Complete.`,
        learnmodsgmmwelcome5: `Training is often repeated on a 12, 24 or 36 basis or as specified by your Organisation.`,
        learnmodsgmmcpdpttop: "This area shows the total value of CPD Points that may be earned for successfully Passing a Module.",
        learnmodsgmmstatuses1: `Not Started – Your initial Quiz status`,
        learnmodsgmmstatuses2: `Passed – You have successfully Passed a module`,
        learnmodsgmmstatuses3: `Expired (Time Elapsed) – More than 12 or 24 months or a specific date has elapsed since you last Passed a module and you required to do this Module again.`,
        learnmodsgmmstatuses4: `Expired (New Content) – A change has occurred in the course content and your Organisation requires you to do this again.`,
        learnmodsgmmstatuses5: `Failed – You may reattempt a Failed Quiz as often as you need to satisfy a Pass mark.`,
        learnmodsgmmstart: 'Select the blue Start button to start the Lesson and/or Quiz.',
        learnmodsgmmdue1: `This area will advise a date of when a Module is due or if it is Overdue which will present in red.`,
        learnmodsgmmdue2: `If a Module has been previously Passed it will state the date it was Last Passed and when it is due to be revisited again in the future.`,
        //#endregion LearningModules 

        //#region EmailReport
        emrepsrchgmecsrch: "External Certificate Search",
        emrepsrchgmecsrchi: "Leave blank for all External Certificates",
        emrepprvgmtselusrs: "Select Users",
        emrepprvgmmselusrs: "Select or Unselect all users.",
        emrepprvgmtrepdta: "Report Data",
        emrepprvgmmrepdta: "All columns are sortable by clicking on the column title or relevant arrows.",
        emrepprvgmtselusr: "Users",
        emrepprvgmmselusr: "Select users to be send an email.",
        emrepprvgmtsub: "Subject",
        emrepprvgmmsub: "Enter subject of an Email.",
        emrepprvgmtembod: "Email",
        emrepprvgmmembod: "Enter Email Body here",
        emrepprvgmtsndem: "Send email",
        emrepprvgmmsndem: "Click to Send Emails to selected users",
        emrepgmvwrepcri: "View Report Criteria",
        emrepgmclrepcri: "Close Report Criteria",
        emrepgmembased: "Emailing Based-on",
        emrepgmcs: "Course Status",
        emrepgmpas: "Policy Acceptances",
        emrepgmcpd: "CPD Status",
        emrepgmnsom: "Include only users who have been added before overnight processing or Application Auditing",
        emrepgmsf: "Fail",
        emrepgmstm: "Checking the pending quiz status updates",
        emrepgmwe: "Welcome emails",
        emrepgmcpdname: "CPD Profile Name",
        emrepgmcrsqz: "Course/Quiz Status",
        emrepgmpol: "Policy Status",
        emrepgmecsta: "External Certificate Status",
        emrepgmecam: "Please add/assign ExternalCertificates to get data",
        emrepgmpam: "Please add/assign policies to get data",
        emrepgmral: "Administrators",
        emrepgmrept: "Recipient",
        emrepgmprem: "Preview Email",
        emrepgmoutst: "Outstanding",
        emrepgmnew: "New",
        emrepgmvld: "Valid",
        emrepgmcmpl: "Complying",
        emrepgmclsd: "Closed",
        emrepgmnwstronly: "New Starters Only",
        emrepgmyes: "Yes",
        emrepgmno: "No",
        emrepgmgrping: "Grouping",
        emrepgmrecpt: "Recipients",
        emrepgmrefsrchmsg: "Please refine your search to see data",
        emrepgmsel: "Select",
        emrepgmpsel: "Please select",
        emrepgmemtylbl: "I want to send emails based on",
        emrepgmunit: "Unit",
        emrepgmunitst: "Units",
        emrepgmunitsm: "Select, unselect, expand and collapse all units.",
        emrepgmemtt: "Email Report Type",
        emrepgmemtm: "Select Course/Policy/CPD/Welcome-based intimation type.",
        emrepgmemtm2: "Select Course/Policy/CPD/Welcome-Email/External-Certificate-based intimation type.",
        emrepgmcst: "Courses",
        emrepgmcsm: "Select or Unselect all courses.",
        emrepgmfcst: "Filter Courses",
        emrepgmfcsm: "Search course by typing coursename.",
        emrepgmfcstt: "Filter Course Status",
        emrepgmfcstm: "Select course or quiz status.",
        emrepgmpsm: "Select or Unselect all policies.",
        emrepgmecsm: "Select or Unselect all External Certificates.",
        emrepgmfecfst: "Filter External Certificates",
        emrepgmfecfsm: "Select all or Unselect all or Search or Select some External Certificates",
        emrepgmfpfst: "Filter Policies",
        emrepgmfpfsm: "Select all or Unselect all or Search or Select some Policies",
        emrepgmfpst: "Filter Policy",
        emrepgmfecst: "Filter External Certificate",
        emrepgmfpsm: "Search policy by typing policyname.",
        emrepgmfecsm: "Search External Certificates by typing the External Certificate name",
        emrepgmfpstt: "Filter Policy Status",
        emrepgmfpstm: "Select policy status.",
        emrepgmfecstt: "Filter External Certificate Status",
        emrepgmfecstm: "Select External Certificate Status",
        emrepgmfdft: "Filter Date From",
        emrepgmfdfm: "Filter Results by From Date.",
        emrepgmfdfma: "Filter Results by From Date (Accepted Date).",
        emrepgmfdtt: "Filter Date To",
        emrepgmfdtm: "Filter Results by To Date.",
        emrepgmfdtma: "Filter Results by To Date (Accepted Date).",
        emrepgmfct: "Filter Classification",
        emrepgmfcm: "Select (if applicable) to Filter Results by Grouping Option.",
        emrepgmfnt: "Filter Firstname",
        emrepgmlnt: "Filter Lastname",
        emrepgmunt: "Filter Username",
        emrepgmfet: "Filter Email",
        emrepgmfrtt: "Filter Recipient Type",
        emrepgmfrtm: "Select recipient user types.",
        emrepgmfnst: "Filter New Starters",
        emrepgmfnsm: "Select the checkbox to send welcome emails only to new-starters who have been added before overnight processing or before Application Auditing.",
        emrepgmfcpt: "Filter CPD Profiles",
        emrepgmfcpm: "Select the any specific CPD profile to send emails only related to it.",
        emrepgmbpt: "Button Preview",
        emrepgmbpm: "Click to Preview email report.",
        emrepgmrat: "Button Reset Filters",
        emrepgmram: "Reset all applied filters.",

        emrepgmdtcreate: "Date Created",
        emrepgmadmtyp: "Admin Type",
        emrepgmselac: "Please select account(s)",
        emrepgmemsub: "Email Subject",
        emrepgmfldreqd: "This Field is required",
        emrepgmum: "User Email",
        emrepgmmore: "more",
        emrepgmcrseappl: "Course(s) where applicable",
        emrepgmqzdtrng: "Quiz Date Range",
        emrepgmpoldtrng: "Policy Date Range",
        emrepgmalldata: "ALL DATA",
        emrepgmsendstatus: "Email Sending Status",
        emrepgmemsent: "Emails Sent",
        emrepgmemfailed: "Emails failed",
        //#endregion EmailReport

        //#region SentEmailReport
        sntemrepgmmem: "Search/Filter Results by Recipient's Email Address.",
        sntemrepgmtsub: "Filter Subject",
        sntemrepgmmsub: "Search/Filter Results by Email Subject.",
        sntemrepgmtbdy: "Filter Body",
        sntemrepgmmbdy: "Search/Filter Results by Email Body.",
        sntemrepgmtrunrep: "Run Report",
        sntemrepgmmrunrep: "Click to Generate Report.",
        sntemrepgmmdtsntl: "Date Sent",
        sntemrepgmmdtfrml: "Date From",
        sntemrepgmmdttol: "Date To",
        sntemrepgmmemtgtl: "To",
        sntemrepgmmemccl: "CC",
        sntemrepgmmreceml: "Recipient's Email Address",
        sntemrepgmmbdyl: "Body",
        sntemrepgmmerrl: "Error",
        sntemrepgmreseml: "Resend Email",
        sntemrepgmresemlm: "Click the mail icon to resend the email to the account(s) specified in the To and CC columns.",
        sntemrepgmmselbkprevm: "Select the Back Button to return to Previous Page",
        sntemrepgmmnoresm: "No Results to Display",
        sntemrepgmmseldtsl: "Select Dates",
        sntemrepgmtexpxls: "Export Excel",
        sntemrepgmmexpxls: "Click to export an XLSX file containing all Scheduled Reports.",
        sntemrepgmtexppdf: "Export PDF",
        sntemrepgmmexppdf: "Click to export an PDF file containing all Scheduled Reports.",
        sntemrepgmtexpcsv: "Export CSV",
        sntemrepgmmexpcsv: "Click to export an CSV file containing all Scheduled Reports.",
        sntemrepgmmxlsm: "Exporting XLSX",
        sntemrepgmmcsvm: "Exporting CSV",
        sntemrepgmmpdfm: "Exporting PDF",
        sntemrepgmmexerrm: "Export Error",
        sntemrepgmmcsvrfm: "Exporting CSV (Rows Fixed)",
        sntemrepgmsndem: "Sending Email",
        //#endregion SentEmailReport

        //#region ViewSentEmails
        vwsntemgmtlist: "View Sent Emails List",
        vwsntemgmmlist: "These columns provide the details of previously sent emails, date sent, to and cc'ed email addresses, email subject and email body.",
        vwsntemgmmaccid: "Account ID",
        //#endregion ViewSentEmails

        //BELOW FOR RELEASE 2024.004
        

        assigneddatetitle:"Assigned Date",
        usernametitle:"User Name",
        validfromdate:"Valid From Date",
        validtodate:"Valid To Date",
        enterdate:"Enter Date",
        extcertificatereport: "External Certificates Report",
        downloadcertificates:"Download Certificates",
        applytopolicy:"Apply to (Select Policies)",
        applytoselectedpolicy:"Select the policy(s) to apply the reminder email Rules to",
        selectedpolicy:"Selected Policy",
        applytocertificate:"Apply to (Select Certificate)",
        selectedcertificate:"Selected Certificate",
        applytoselectedcertificate:"Select the certificate(s) to apply the reminder email Rules to",
        includearchivedcertificates:"Include Archived Certificates",
        repgmgroupingoption:"Select(if applicable) to Filter Results by Grouping Option.",       
        repgmschedulereport:"Click to Schedule Report.",
        prrepgminactiveaccount:"Select to Include Inactive Accounts.",
        wrrptgmexval:"Filter Results by Expired Value.",
        cpdrptgmfilter:"Filter Results by CPD Profile.",
        cpdrptgmppfilter:"Filter Results by CPD Profile Period.",
        cpdrptgmcpdpointss:"Select to show only the users CPD points shortfall.",
        rptcsrgmcoursestatus:"Filter Results by Course Status.",
        exrptgmselectcert:"Select or Unselect all certificates.",
        exrptgmsearchcert:"Search certificate by typing certificate name.",
        exrptgmarccert: "Select to include Archived Certificates.",
        exrptgmarccertstatus: "Select type of Certificate upload status.",
        pbrptgmpolicyacceptance:"Select type of Policy Acceptance.",
        downloadcertificatesgm: "Once checked, click this icon to download a zip of all selected provided External Certificates.",
        fileselectiongm:	"Check the checkbox in the column header to select all provided External Certificates for downloading, or alternatively select individual rows to download those provided External Certificates.",
        extrptgmpiechart:"Grand Total position graph for all selected External Certificates, Provided versus not Provided.",
        extrptgmchartfilter:"When multiple External Certificates are selected, use this dropdown to view a graph for a specific External Certificate.",
        extrptgmcolumchart:"By default, if more than one External Certificate is chosen, a graph per External Certificate will be shown. If a specific External Certificate is chosen from the dropdown above, it will be shown here.",
        extrptcerfilter:"Certificates Summary Filter",
        extrptcersummary:"Certificates Summary",

        //#region External Certificate CPD
        extcerttotalprofilepoints: "Total Points for external certificates: %1 Points",
        extcerttotalprofilepoints2: "Total Points for external certificates",
        extcertprofilepoints: "External Certificate Points Assignment",
        showonlypointsassignedextCert: "Show Only External Certificates with Points",
        assignbuttonspanelpointsapplycommontofextcerts: " for all External Certificates filtered below",
        profileforextcertlabel: "CPD Profile",
        profileperiodforextcertlabel: "CPD Profile Period",
        profileforextcertguidemetitle: "CPD Profile Selection Source",
        profileforextcertguideme: "This dropdown list shows all current CPD Profiles for your organisation, selecting one will show the Assigned Points of this external certificate which can be modified.",
        enablecpdpointsforextcertlabel: "Enable CPD Points for this certificate",        
        profileperiodnoselectionforextcert: "No profile period selected",
        allaccountsincludingcompletednew: "All accounts, including the accounts who have completed the modules and/or attested to policies and/or provided external certificates",
        allaccountsexcludingcompletednew: "All accounts, excluding the accounts who have completed the modules and/or attested to policies and/or provided external certificates",
        allaccountsincludingcompletedforextcert: "All accounts, including the accounts who have provided external certificates",
        allaccountsexcludingcompletedforextcert: "All accounts, excluding the accounts who have provided external certificates",
        allaccountsincludingcompletedforextcertprof: "All accounts of selected profile, including the accounts who have provided external certificates",
        allaccountsexcludingcompletedforextcertprof: "All accounts of selected profile, excluding the accounts who have provided external certificates",
        cpdpointinputlabelforextcert: "Enter the number of points that the learner can obtain for the selected profile",
        cpdpointchangelooseconfirmforextcert: "You will lose the updated CPD Points if you proceed without saving. Do you still want to proceed?",
        assignpointstoextcert: "Assign points to the selected CPD profile for this certificate to",
        removepointsfromextcert: "Remove points of all CPD profiles for this certificate from",
        extcertstatusdate: "Certificate Status Date",
        cpddisabledandguide: "CPD Disabled for this External Certificate. Please go to External Certificates to enable CPD.",
        assignbuttonspanelmessage2: "CPD Profile advanced settings, assigning CPD points, assigning/unassigning units and accounts",
        assignbuttonspanelpointsmessage2: "Click here to assign CPD Points per Course/Module and/or Policy and/or External Certificate.",
        guidemewithassignpointswarning: "Guide Me is clicked along with Assign Points tab is open in this page. Please close this message to proceed.",
        assignbuttonspanelcpdpointaccrualrequirementpol: "*CPD points are earned while accepting the policies. ",
        assignbuttonspanelcpdpointaccrualrequirementcert: "*CPD points are earned while providing the valid external certificates. ",
        assignbuttonspanelpointspmessage: "By default accepting the policy earns the points. ",
        assignbuttonspanelpointsecmessage: "By default providing the external certificate earns the points. ",
        assignbuttonspanelpointsecptfiltertitle: "Points Assignment Filter - External Certificates",
        assignbuttonspanelpointsecptfiltermessage: "Check this to only show External Certificates that currently have CPD Points assigned to them.",
        assignbuttonspanelpointsecseektitle: "External Certificate Filter",
        assignbuttonspanelpointsecseekmessage: "Use the search function to filter the External Certificate list below. Multiple filters can be applied in a single search by separating the filters by comma to get a combined filtered list. ",
        assignbuttonspanelpointseclvltitle: "External Certificate item with Point input",
        assignbuttonspanelpointseclvlmessage: "Enter the amount of CPD points to be accrued when the account provides their External Certificate. ",
        assignbuttonspanelpointsectottitle: "Total points assigned to external certificates",
        assignbuttonspanelpointsectotmessage: "This will display the total number of CPD points that can be accrued if the account were to provide all assigned External Certificates.",
        assignbuttonspanelpointsapplycommontofcertsguidemet: "Same Points for External Certificates",
        assignbuttonspanelpointsapplycommontofcertsguidemem: "When this checkbox is ticked, the amount of points specified will be applied to the filtered External Certificates list, any/all filters can be used to filter the External Certificates list before checking this box. Please remember to save the changes made after checking this box. ",
        assignbuttonspanelpointsinclmessagenew: "Selecting ‘All accounts, including the accounts who have completed the modules and/or attested to policies and/or provided external certificates’ will accrue CPD Points for all completed items in the current CPD Period.",
        assignbuttonspanelpointsexclmessagenew: "Selecting ‘All accounts, including the accounts who have completed the modules and/or attested to policies and/or provided external certificates’ will NOT accrue CPD Points for all completed items in the current CPD Period. Accounts will only accrue points for configured courses/modules/policies/external certificates from now on.",
        assignbuttonspanelpointssavemessagenew: "Click here to save the assigned CPD points per course/module, Policy and/or External Certificate.",
        extcertprofgmt: "CPD Profile",
        extcertprofgmm: "Select a CPD Profile for this External Certificate.",
        extcertprofprdgmt: "CPD Active Current Profile Period",
        extcertprofprdgmm: "Select a CPD Profile Period for this External Certificate. ",
        extcertprofprdptgmt: "CPD Profile Points",
        extcertprofprdptgmm: "Enter the number of CPD Points an account can accrue for providing this External Certificate.",
        extcertprofprdptprincgmt: "CPD Profile Points Assign - Include Previous Completions",
        extcertprofprdptprincgmm: "Assign CPD Points to all accounts, including those that have previously provided this External Certificate. ",
        extcertprofprdptprexcgmt: "CPD Profile Points Assign - Exclude Previous Completions",
        extcertprofprdptprexcgmm: "Assign CPD Points to all accounts, excluding those that have previously provided this External Certificate. ",
        myexcertcpdpointsgmt: "CPD Points Earnable",
        myexcertcpdpointsgmm: "This column shows the CPD Points that can be accrued by providing the respective External Certificate",
        mytrainingcpdpointsgmt: "CPD Points Earnable",
        mytrainingcpdpointsgmm: "This column shows the CPD Points that can be accrued by completing the respective assigned course modules/policies",
        nosummaryavailable: "No Summary available for this Quiz Type",
        assigntypegmt: "Assignment Types of Account",
        assigntypegmm: "This column indicates how this CPD Profile has been assigned to the accounts listed, hover over the icon to determine how it was assigned, either by Account or by Unit.",
        //#endregion External Certificate CPD

        //Externalcertificate guideme
        certificatenametitle: "Certificate Name",
        certificatelistgmtext: "Click on an External Certificate to view Provided, Not Provided and Closed details including the ability to download provided\closed certificates.",
        allowlearnertoedittitle: "Allow account To Edit",
        allowlearnertoeditguideme: "Toggle Allow account to edit entry, then enter the number of days in which the account can edit an uploaded certificate, from the day they initially upload it.",
        iscpdenabledtoggletitle: "Enable CPD Points",
        iscpdenabledtoggleguideme: "Toggle Enable CPD Points, allow the accrual of CPD Points for uploading an External Certificate",
        hasnovaliddatestitle: "Enable Certificate validity period",
        hasnovaliddatesguideme: "Check this checkbox if you want to disable the valid date fields when an account uploads their External Certificate",
        activeinactivetitle: "Status",
        activeinactiveguideme: "Toggle Active/Inactive this External Certificate. ",
        savedatatitle: "Save Data",
        savedataguideme: "Click to Save new External Certificate.",
        sendwelcomeemailtitle: "Send Email",
        sendwelcomeemailguideme: "Click to Send an email notification for the new External Certificate.",
        assignunitstitle: "Assign Units",
        assignunitsguideme: "Click Assign Units to select which Units to assign this External Certificate to. ",
        assignaccountstitle: "Assign Accounts",
        assignaccountsguideme: "Click Assign Accounts to select specific Accounts to assign this External Certificate to.",
        viewaccountstitle: "View Accounts",
        viewaccountsguideme: "Click View Accounts to be provided with a listing of Accounts this External Certificate is currently assigned to.",
        unitfilterbuttonstitle: "Units",
        unitfilterbuttonsguideme: "Use these controls to Select/Deselect all units, as well as Expand/Collapse the entire unit tree.",
        unitfiltersearchtitletext: "Filter Unit",
        unitfiltersearchguideme: "Use the search function to filter the unit tree.",
        unittreetitle: "Assign Units",
        unittreeguideme: "Select the relevant units by clicking the checkboxes, you can select all units under a parent unit by clicking on the unit name.  ",
        saveassigneddatatitle: "Save Assignment",
        saveassigneddataguideme: "Click to save unit assignments. ",
        assigncheckboxtitle: "Assign All",
        assigncheckboxguideme: "Shows that the current External Certificate is assigned to this account. ",
        unitpathwaycoltitle: "Unit Pathway",
        unitpathwaycolguideme: "Shows the Unit Pathway of the relevant account that has this External Certificate assigned. ",
        lastnamecoltitle: "Last Name",
        lastnamecolguideme: "Shows the Last Name of the relevant account that has this External Certificate assigned.",
        firstnamecoltitle: "First Name",
        firstnamecolguideme: "Shows the First Name of the relevant account that has this External Certificate assigned. ",
        saveassigneddataguidemegrid: "Click save to assign/unassign this External Certificate to all checked/unchecked accounts.",

	    ecprovidedguideme:"Click to see a listing of accounts who have Provided an External Certificate.",
        ecnotprovidedguideme:"Click to see a listing of accounts who have Not Provided an External Certificate.",
        eccloseguideme:"Click to see a listing of accounts who have Provided an External Certificate but they have since closed.",
        ecviedpdfgm:"Click to view the provided External Certificate or Upload New Certificate.",
        ecactiongm:"Click to upload a new External Certificate.",
        eclastmodifiedgm:"The last modified date and who modified the External Certificate will be shown here.",
        
	//Policy and External Certificate email reminder rule guide me
        policynameemailrulecolumnguideme: "Policy name column is sortable by clicking on the column title or relevant arrows.",
        certificatenamecolumnguideme: "External certificate name column is sortable by clicking on the column title or relevant arrows.",
        policyieguideme:"Indicates if Initial Enrolment Period email notifications are enabled or disabled for the given policy",
        policypreguideme:"Indicates if Pre-Expiry email notifications are enabled or disabled for the given policy",
        policypostguideme:"Indicates if Post Expiry email notifications are enabled or disabled for the given policy",
        certyieguideme:"Indicates if Initial Enrolment Period email notifications are enabled or disabled for the given external certificate",
        certypreguideme:"Indicates if Pre-Expiry email notifications are enabled or disabled for the given external certificate",
        certypostguideme:"Indicates if Post Expiry email notifications are enabled or disabled for the given external certificate",
        policyruleedit:"Click to edit the Email Reminder Rule for the given policy.",
        policyruledelete:"Click to delete the Email Reminder Rule for the given policy.",
        policyruledisable:"Toggle to enable/disable all notifications for the given policy.",
        certyruleedit:"Click to edit the Email Reminder Rule for the given external certificate.",
        certyruledelete:"Click to delete the Email Reminder Rule for the given external certificate.",
        certyruledisable:"Toggle to enable/disable all notifications for the given external certificate.",
        emailruleenabledisable: "Enable/Disable notifications",
        editemailreminder: "Edit Email Reminder",
        deleteemailreminder: "Delete Email Reminder",
        policyinitialenrolmentremindernotes:"My Training page will display the # of days until the assigned policy is due",
        policyinitialenrolmentnumber:"Number of days for accounts to attest to assigned policy",
        certyinitialenrolmentremindernotes:"External Certificates page will display the # of days until the assigned External Certificate is due to be uploaded.",
        certyinitialenrolmentnumber:"Number of days for accounts to upload External Certificate",
        policypreexpiryreminderemailsnotes:"An email reminder may be sent to accounts # days before a policy is due",
        policypreexpirynumber:"How many days before a policy is due?",
        certypreexpiryreminderemailsnotes:"An email reminder may be sent to accounts # days before an External Certificate is due to be uploaded.",
        certypreexpirynumber:"How many days before an External Certificate is due?",
        policypostexpiryreminderemailsnotes:"An email reminder may be sent to accounts # days after a policy is due for # times.",
        certypostexpiryreminderemailsnotes:"An email reminder may be sent to accounts # days after an External Certificate is due to be uploaded for # times.",
        policyexpiryexample:"For example, 3 reminders repeated every 7 days: accounts that have not attested to an assigned policy will be reminded on the day they are expected to have attested to an assigned policy, a second email will be sent 7 days after the due date and third reminder will be sent 14 days after the due date.",
        certyexpiryexample:"For example, 3 reminders repeated every 7 days: accounts that have not uploaded an External Certificate will be reminded on the day they are expected to have uploaded an External Certificate, a second email will be sent 7 days after the due date and a third reminder will be sent 14 days after the due date.",
        policymanagernotificationsnotes:"Send a Manager Notification detailing the Learners with an overdue policy attestation every # days.",
        certymanagernotificationsnotes:"Send a Manager Notification detailing the Learners with an overdue External Certificate requirement every # days.",
        searchaccountbyfirstname: "Search account by typing firstname.",
        courselist: "Course List",
        courselistinfo:"This list shows all courses your organisation has access to. Select the courses to apply the reminder email rule.",
        preexpiryreminder: "Pre expiry reminder",
        postexpiryreminder: "Post expiry reminder",
        selectcourse: "Please select valid Course to continue!",
        selectcertificate: "Please select valid Certifictae to continue!",
        selectpolicy: "Please select valid Policy to continue!",
        successrulemessage: "Organisation reminder rule has been saved successfully!",
        policyguidemeinitialenrolment:"When enabled, the number of days specified is how long an account has to complete an attest policy before becoming overdue. Only applicable for the first time they are attested a policy. The Initial Enrollment period is used to determine the Due Date displayed on an account’s My Training page.",
        certyguidemeinitialenrolment:"When enabled, the number of days specified is how long an account has to provide an External Certificate before becoming overdue. Only applicable for the first time they are assigned an External Certificate. The Initial Enrollment period is used to determine the Due Date displayed on an account’s My Training page.",
        policyguidemepreexpiry:"Enable Pre-Expiry reminder emails, an email reminder will be sent to an account when their attestation on policy will expire on the specified days before it expires.",
        certyguidemepreexpiry:"Enable Pre-Expiry reminder emails, an email reminder will be sent to an account when their external certificate will expire on the specified days before it expires.",
        policyguidemepostexpiry:"Enable Post-Expiry reminder emails, an email reminder will be sent to an account when their policy is not attested in given time. When enabled a reminder will be sent on the day that a policy is due, then every specified days for the number of notifications specified. ",
        certyguidemepostexpiry:"Enable Post-Expiry reminder emails, an email reminder will be sent to an account when their external certificate has closed. When enabled a reminder will be sent on the day that a External certificate reached due date, then every specified days for the number of notifications specified. ",
        policyguidememanage:"Enable Manager Notifications. When enabled, those accounts that have a manager email specified under their account setup and they have overdue policy, a manager notification will be sent every specified days to that manager.",
        certyguidememanage:"Enable Manager Notifications. When enabled, those accounts that have a manager email specified under their account setup and they have overdue to provide external certificate, a manager notification will be sent every specified days to that manager.",
        //Email reminder Graph
        emailexpiryexample: "The below example is applicable to learners when a course is first assigned, their initial enrolment and an initial enrolment period is specified.",
        emailexpiryexamplepolicy: "The below example is applicable to learners when a policy is first assigned, their initial enrolment and an initial enrolment period is specified.",
        emailexpiryexamplecerty: "The below example is applicable to learners when a external certificate is first assigned, their initial enrolment and an initial enrolment period is specified.",
        enrollmentdate: "Given an enrolment date of:",
        coursecomplitiondate: "Course completion is due in ",
        policycomplitiondate: "Policy acceptance is due in ",
        certycomplitiondate: "External Certificate attesting is due in ",
        daysduedateis: " days (Due Date is ",
        daysbefore: " days before (",
        preexpirytext: ") learners will receive a pre-expiry reminder email that the course is due",
        preexpirytextpolicy: ") learners will receive a pre-expiry reminder email that the policy is due",
        preexpirytextcerty: ") learners will receive a pre-expiry reminder email that the external certificate is due",
        lernersreceive: "Learners will receive ",
        postexpiry: " post-expiry(overdue) reminders every",
        days: "days.",
        managerevery: "(optional) A Manager will receive a notification every",
        mgrnotification: "day(s) detailing learners who have incomplete courses.",
        mgrnotificationpolicy: "day(s) detailing learners who have unaccepted policies.",
        mgrnotificationcerty: "day(s) detailing learners who have unattached external certificates.",
        managernotification: "Manager Notification",
        //BELOW FOR RELEASE 2024.005	      
        assigncourses: "Assign Courses",
        selecttrainingplan: "1. Select Training Plan",
        selecttrainingplanmessage: "Select the course(s) to assign the units to",
        howtoassigntrainingplan: "2. How to assign this training plan?",
        howtooption1:"Apply only new course Modules (This will not change any other currently assigned courses that are not selected above)",
        howtooption2:"Overwrite training plan (This will apply the exact Training plan specified above and may unassign Course Modules not selected above)",
        howtooption3:"Unassign the Modules selected above",
        unitstrainingplan: "3. Select Units to apply this Training Plan to",
        unitstrainingplaninfo: "Select the unit to apply on Course based Training Plan to",
        assigncoursetrainingplansuccess: "Course(s) have been assigned to the selected unit(s) successfully!",
        unassigncoursetrainingplansuccess: "Course(s) have been removed from the selected unit(s) successfully!",
        overwriteassigncoursetrainingplansuccess: "Course(s) have been overwritten to the selected unit(s) successfully!",
        assigncoursetrainingplanerror: "Error while assigning the course(s) to selected unit(s)! Please try again.",
        unassigncoursetrainingplanerror: "Error while unassigning the course(s) to selected unit(s)! Please try again.",
        overwritecoursetrainingplanerror: "Error while overwriting the course(s) to selected unit(s)! Please try again.",
        unitselecterror: "Please select units to assign Training plan to.",
        savetrainingplan: "Save Training Plan",
        howtoassign: "How to Assign",
        howtoassignmessage: "Select a method of assigning\\unassigning the selected courses.",
        howtooption1guideme: "Apply only new courses\\modules, the selected courses\\modules will be added to any courses\\modules already assigned to the selected units. ",
        howtooption2guideme: "Overwrite training plan, the selected courses\\modules will overwrite any existing assigned courses\\modules to the selected units. ",
        howtooption3guideme: "Unassign the Courses\\Modules, this will unassign the selected courses\\modules from the selected units. ",
        modulelisttranngplanguide:"Select the Courses\\Modules, the selected courses\\modules will be assigned\\unassigned to the units chosen under Section 3 below.",
        unitstrainingplanguide: "Select the units which the above selected Courses\\Modules will be assigned\\unassigned.",
        unitfilter: "Search unit/subunits by typing unit name.",
	      //#region SuperAdmin        
        allorgsnoselection: "None selected",
        superadminsearchorgname: "Search Organisation",
        superadminselectorg: "Select Organisation",
        techsupportclick2: "For technical support click the button below",
	      //#region SuperAdmin 
	
        // For sprint 2024.007
        emrepgmadminreporttype: "Select 'Current Policies Report' to get a snapshot of currently assigned policy(ies). Select 'Inactive Policies Report' to get a snapshot of policy(ies), which are made inactive on your Salt Site.",
        
        //#region -   "Organisation Diactivation",
        organisationdeactivation: "Organisation Deactivation",
        deactivateorganisation: "Deactivate Organisation",
        notificationwillbe:"Notification will be sent to",
        emailnotificationtimeline:"Email Notification Timeline",   
        accDatadeletedindays:"The Account Data in this site will be  deleted in ",
        deactivationstartdate:"Deactivation Start Date",
        extenddate:"Extend Delete Date by 12 months",

        //#endregion -   "Organisation Diactivation",
        
        addorassignpolicy: "Please add/assign policies to get data",

        //Accountmanagers
        orgaccountmanagers: "Account Managers",      
        applicationemailtemplates:"Application Email Templates"

    },
  }

  // If any new page is added, add the title here as well and use the setTitleEnglish to set this title to UIContext so that tip video will display for that specific page if added with below as its title
  const saltPages = {
    addaccount: "Add Account",
    accountdetails: "Account Details",
    accountsettings: "Account Settings",
    accountmanagement: "Account Management",
    accountmoduleaccess: "Account Module Access",
    importaccounts: "Import Accounts",
    moveaccountstounit: "Move Accounts to Unit",
    viewsentemails: "View Sent Emails",
    addunit: "Add Unit",
    moveunit: "Move Unit",
    customsupportdetails: "Custom Support Details",
    globalcompliancerules: "Global Compliance Rules",
    coursebasedcompliancerules: "Course Based Compliance Rules",
    datebasedcompliancerules: "Date Based Compliance Rules",
    onetimecoursecompletion: "One Time Course Completion",
    emailtemplates: "Email Templates",
    reminderemailrules: "Reminder Email Rules",
    emailreport: "Email Report",
    cpdprofile: "CPD Profile",
    archiveaccounts: "Archive Accounts",
    tipvideos: "Tip Videos",
    managetipvideos: "Manage Tip Videos",
    extcertificatedetails: "External Certificate Details",
    extcertificate: "External Certificate",
    myextcertificates: "My External Certificates",
    modifylinks: "Modify Links",    
    orgadmindetails: "Organisation Admin Details",
    orgadmin: "Organisation Admin",
    configurationitems: "Configuration Items",
    organisationmanagement: "Organisation Management",
    singlesignon: "Single Sign On",
    policybuilder: "Policy Builder Details",
    policybuilderlist: "Policy Builder List",
    addaccounttounit: "Add Account To Unit",
    unitdetails: "Unit Details",
    manageunitadmins: "Manage Unit Admins",
    unitcompliancerule: "Unit Compliance Rules",
    unitmoduleaccess: "Unit Module Access",
    unitmanagement: "Unit Management",
    atriskreport: "At Risk Report",
    coursestatusreport: "Course Status Report",
    cpdreport: "CPD Report",
    currentadminreport: "Current Admin Report",
    mydashboard: "My Dashboard",
    moduleslist: "Modules List",
    quizsummary: "Quiz Summary",
    links: "Links",
    //myextcertificate: "My External Certificate",
    mytraining: "My Training",
    policybuilderreport: "Policy Builder Report",
    progressreport: "Progress Report",
    quizhistory: "Quiz History",
    quizhistoryreport: "Quiz History Report",
    myreport: "My Report",
    reportscheduler: "Report Scheduler",
    sentemailreport: "Sent Email Report",
    unitadminreport: "Unit Admin Report",
    theme: "Theme",
    unitcompliancereport: "Unit Compliance Report",
    unitpathwayreport: "Unit Pathway Report",
    userdetailreport: "User Detail Report",
    warningreport: "Warning Report",
    assigncourses: "Assign Courses",
    organisationdeactivation: "Organisation Deactivation",
    orgaccountmanagers: "Account Managers",
   
  };

interface ITranslationsContext {
  translations: PageTranslations;
  pageTitles: any,
  updateSiteLanguage: (language: PageTranslations, langName: string) => void
  currentLanguage: string
  setCurrentLanguage: (currentLanguage: string) => void 
}

export const TranslationsContext = createContext<ITranslationsContext>({ 
    translations: initialTranslations,
    pageTitles: saltPages,
    updateSiteLanguage: () => {},
    currentLanguage: "en-AU",
    setCurrentLanguage: () => {}
});

export const TranslationsProvider = (props: any): JSX.Element => {
  const { oidcUser } = useReactOidc();
  const [translations, setTranslations] = useState<PageTranslations>(initialTranslations);
  const userLastLang = oidcUser?.profile.locale ? oidcUser.profile.locale.toLowerCase() : "en-AU";
  const [currentLanguage, setCurrentLanguage] = useState<string>(userLastLang);
  const [pageTitles, setPageTitles] = useState<any>(saltPages);

  const updateSiteLanguage = (selectedLanguage: PageTranslations, langName: string) => {
    setCurrentLanguage(langName)
    setTranslations({
      givenPageName: "Full Client Side Translation",
      currentPageLang: langName,
      allResourcesToTranslations: {
        ...selectedLanguage,
        //coursestatusreport: "Course Status Report", // Need to be added in API Response
        //showhideduedate: "Show/Hide Next Due Date", // Need to be added in API Response
        //showduedate: "Show Next Due Date", // Need to be added in API Response
        //hideduedate: "Hide Next Due Date", // Need to be added in API Response
        //progressreport: "Progress Report",  // Need to be added in API Response
        //summaryreport: "Summary Report",  // Need to be added in API Response
        //quizhistory: "Quiz History for {DISPLAYNAME}", // Need to be added in API Response
        //currentadminreportquizhistory: "Quiz History for {DISPLAYNAME}",  // Need to be added in API Response
        //quizhistoryreport: "Quiz History Report", // Need to be added in API Response
      
        
       
       
      }
    })
  }

  return (
    <TranslationsContext.Provider value={{  
        translations, 
        pageTitles,
        updateSiteLanguage,
        currentLanguage,
        setCurrentLanguage
      }}>
      {props.children}
    </TranslationsContext.Provider>
  );
};
