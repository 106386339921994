//React + Typescript
import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { AtRiskResultItem, CurrentAdminReportResultItem, QuizHistoryItem } from '../../globalTypes';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
import moment from 'moment';
//Contexts
import { LoaderContext } from '../../contexts/LoaderContext';
import { UIContext } from '../../contexts/UIContext';
import { SessionContext } from '../../contexts/SessionContext';
import { CPDPanelContext } from '../../contexts/CPDPanelContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';
//API Functions
import { addError } from '../../API/AddError'
import { getQuizHistory } from '../../API/GetQuizHistory';
import { getUserCourseModules } from '../../API/UserCourseModules'
//General Functions
import getColor from '../../functions/getColor';
import { getCurrentTimeDDMMYYYYFormat } from '../../functions/getCurrentTimeDDMMYYYYFormat'
import serialToArray from '../../functions/serialToArray'
//API functions
import { getExportedXLSXData } from '../../API/GetExportedXLSXData'
import { getExportedCSVData } from '../../API/GetExportedCSVData'
import { getExportedPDFData } from '../../API/GetExportedPDFData'

//Components
import BrandedCard from '../../Components/BrandedCard';
import UnbrandedCard from '../../Components/UnbrandedCard';
import CustomTooltip from '../../Components/CustomTooltip';
import LoaderContainer from '../../Components/LoaderContainer';
import HistoryProgressBar from '../../Components/HistoryProgressBar'
import ExcelFileIcon from '../../CustomIcons/ExcelFileIcon'
import CSVFileIcon from '../../CustomIcons/CSVFileIcon'
import PDFIcon from '../../CustomIcons/PDFFileIcon';
//MUI ComponentsExpandAllIconOnCard
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TablePagination from '@material-ui/core/TablePagination';
//Styles

import styles from '../../styles/currentAdminReport.module.css'


const CurrentAdminReportQuizHistory = (props: any): JSX.Element => {
  const { color } = useContext(CorperateThemeContext).theme;
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext)
  const { setTitle, setTitleEnglish, updateBackButtonRoute, checkScrollReminder, setShowScrollArrow, displayName ,menuSelection, EnableActivityLogs} = useContext(UIContext);
  const { moduleId } = useParams<{ moduleId: string }>();
  const { userID } = useParams<{ userID: string }>();
  const { cpdList, currentCpd } = useContext(CPDPanelContext);
  const [quizHistoryItems, setQuizHistoryItems] = useState<Array<QuizHistoryItem>>([]);
  const [adminReportResult, setAdminReportResult] = useState<Array<CurrentAdminReportResultItem>>(props?.location?.state?.adminReportResultItems ?? [])
  const [atRiskReportResult, setAtRiskReportResult] = useState<Array<AtRiskResultItem>>(props?.location?.state?.atRiskReportResultItems ?? [])
  const [backPage, setBackPage] = useState<string>(props?.location?.state?.page ?? "")
  const [inputFilterFailureCount, setInputFilterFailureCount] = useState<number>(props?.location?.state?.inputFilterFailureCount ?? 0)
  const [inputSelectedClassification, setInputSelectedClassification] = useState<string>(props?.location?.state?.inputSelectedClassification ?? "")
  const [inputFilterFirstName, setInputFilterFirstName] = useState<string>(props?.location?.state?.inputFilterFirstName ?? "")
  const [inputFilterLastName, setInputFilterLastName] = useState<string>(props?.location?.state?.inputFilterLastName ?? "")
  const [inputFilterUserName, setInputFilterUserName] = useState<string>(props?.location?.state?.inputFilterUserName ?? "")
  const [inputFilterIncludeInactive, setInputFilterIncludeInactive] = useState<boolean>(props?.location?.state?.inputFilterIncludeInactive ?? "")
  const [inputHistoricData, setInputHistoricData] = useState<boolean>( props?.location?.state?.inputHistoricData ?? false); 
  const [filterDateTo, setFilterDateTo] = useState<string>(props?.location?.state?.inputFilterDateTo ?? "");   
  const [filterDateFrom, setFilterDateFrom] = useState<string>(props?.location?.state?.inputFilterDateFrom ?? "");   
  const [inputFilterEmail, setInputFilterEmail] = useState<string>(props?.location?.state?.inputFilterEmail ?? "")
  const [reportTitle, setReportTitle] = useState<string>(props?.location?.state?.reportTitle)
  const [exportReportTitle, setExportReportTitle] = useState<string>(props?.location?.state?.exportreportTitle)   
  const [resultError, setResultError] =  useState<string>(props?.location?.state?.resultError ?? "")
  const { setCurSessionID, curCourseName, setCurCourseName } = useContext(SessionContext)
  const [curModuleName, setCurModuleName] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true);
  const { oidcUser } = useReactOidc();
  const history = useHistory();
  const { profile: { profile, user_id, website, family_name, given_name } } = oidcUser || {};
  const [reportRunTimeTaken, setReportRunTimeTaken] = useState<string>("")
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const { setMessage } = useContext(LoaderContext);
  const [exportError, setExportError] = useState<string>("");
  const [exporting, setExporting] = useState<boolean>(false);
  const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true);
  const getStatusCSSClass = (status: string) => {
    switch (status) {
      case "Passed":
        return styles.passed
      case "Failed":
        return styles.failed
      default:
        return
    }
  }

  const getStatusTranslation = (status: string) => {
    switch (status) {
      case "Passed":
        return allResourcesToTranslations.passed
      case "Failed":
        return allResourcesToTranslations.failed
      case "Not Started":
        return allResourcesToTranslations.notstarted
      case "Completed":
        return allResourcesToTranslations.completed
      case "Expired (Time Elapsed)":
        return allResourcesToTranslations.expiredtimeelapsed
      case "Expired (New Content)":
        return allResourcesToTranslations.expirednewcontent
      case "In Progress":
        return allResourcesToTranslations.inprogress
      default:
        return ""
    }
  }

  const initialSort = () => {
    setReportRunTimeTaken(getCurrentTimeDDMMYYYYFormat())
    setPage(0)
  }

  useEffect(() => {
    initialSort();
    updateBackButtonRoute("")
    getQuizHistory(oidcUser.access_token, parseInt(userID), orgDomain, parseInt(moduleId), !orgId ? -1 : parseInt(orgId)).then((data) => {
      setCurCourseName(data.courseName);
      setCurModuleName(data.moduleName);
      setTitle(`${allResourcesToTranslations.quizhistory.replace('{DISPLAYNAME}', data.displayName)}`)
      setTitleEnglish(pageTitles.currentadminreport)
      setQuizHistoryItems(data.listOfQuizEntries);
      checkScrollReminder()
      let cpdId = -1; 
      if(cpdList != null)
      {
      cpdId = cpdList.length > 0 ? cpdList[currentCpd].id : -1 //If no CPD list value is defaulted to -1
      }
      getUserCourseModules(oidcUser.access_token, userId, orgDomain, data.courseID, cpdId).then((res) => {
         setLoading(false)       
      })
    })
    return () => {
      setShowScrollArrow(false)
    }
  }, [menuSelection]) // eslint-disable-line react-hooks/exhaustive-deps


  const formatedDateTimestamp_DDMMMYYYYhhmmss = (d: Date) => {
    if (d === null)
      return '';
    var datearr = (d.toLocaleDateString().split('T')[0]).split('/').reverse();
    [datearr[1], datearr[2]] = [datearr[2], datearr[1]]
    const date = datearr.join('-');
    const time = d.toTimeString().split(' ')[0];
    const datestring = `${date} ${time}`
    //console.log("datestring " + datestring);
    let momentVariable = moment(datestring, 'YYYY-MM-DD hh:mm:ss a');
    let finalStringValue = momentVariable.format('YYYY-MM-DD hh:mm:ss a');
    finalStringValue = finalStringValue.replace('T', ' ')

    return finalStringValue;
  }

  const formatToDateTimestamp = (dateString: string) => {
    if (dateString === null)
      return '';
    return formatedDateTimestamp_DDMMMYYYYhhmmss(new Date(dateString));
  }

  const renderQuizHistoryRows = () => {
    if (quizHistoryItems != null) {
      return quizHistoryItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((hItem: QuizHistoryItem, i) => {
        return (
          <TableRow key={`historyrow-${i}`}>
            <TableCell align="left">
              <span className={styles.courseName}>{i === 0 ? curCourseName : ""}</span>
            </TableCell>
            <TableCell align="left">
              {curModuleName}
            </TableCell>
            <TableCell align="center">
              {/* { !hItem.dateCreated ? "": formatToDateTimestamp(hItem.dateCreated) }  */}
              {!hItem.dateCompletedLocal ? "" : hItem.dateCompletedLocal}
            </TableCell>
            <TableCell align="center">
              <span className={getStatusCSSClass(hItem.status)}>
                {getStatusTranslation(hItem.status)}
              </span>
            </TableCell>
            <TableCell align="center" height="45px">
              <div style={{ display: (hItem.status !== "Passed" && hItem.status !== "Failed") ? "none" : "block" }}>
                <HistoryProgressBar
                  userScore={hItem.quizScore}
                  passmark={hItem.quizPassmark}
                />
              </div>
            </TableCell>

          </TableRow>
        );
      }
      );
    }
  }

  const renderQuizHistoryHead = () => {
    return (
      <TableRow>
        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }} align="left">
          {allResourcesToTranslations.course}
        </TableCell>
        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
          {allResourcesToTranslations.module}
        </TableCell>
        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="center">
          {allResourcesToTranslations.quizstatusdate}
        </TableCell>
        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="center">
          {allResourcesToTranslations.status}
        </TableCell>
        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }} align="center">
          {allResourcesToTranslations.yourscore}
        </TableCell>
      </TableRow>
    )
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const GetDate_yymmdd = () => {
    var d =  new Date(Date.now());
    let day = d.getFullYear().toString().substring(2,4)  + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0")
     return day;         
  }

  const buildRequestStringArray = () => {
    let resultArray: Array<string> = []
    for (let i = 0; i < quizHistoryItems.length; i++) {
      const r = quizHistoryItems[i]
      resultArray.push(`${curCourseName}<#>${curModuleName}<#>${r.dateCompletedLocal}<#>${r.status}<#>${r.quizPassmark + "%"}<#>${r.quizScore+ "%"}`)

    }
    return resultArray
  }

  const handleBackButtonclick = () => {
    if(backPage === "AtRisk") {
      history.push({
        pathname: `/atriskreport/result`,               
        state: {
          inputFilterFailureCount: inputFilterFailureCount,
          inputSelectedClassification: inputSelectedClassification,
          inputFilterDateFrom: filterDateFrom,    
          inputFilterDateTo: filterDateTo,               
          resultItems: atRiskReportResult,
          resultError: resultError       
        },
      });
    }
    else {
      history.push({
        pathname: `/currentadminreport/result`,               
        state: {
            inputFilterFirstName: inputFilterFirstName,
            inputFilterLastName: inputFilterLastName,
            inputFilterEmail: inputFilterEmail,
            inputFilterUserName: inputFilterUserName,  
            inputFilterDateTo: filterDateTo,                
            inputFilterIncludeInactive: inputFilterIncludeInactive,          
            inputHistoricData: inputHistoricData,
            reportTitle: reportTitle,
            exportreportTitle: exportReportTitle ,
            resultItems: adminReportResult,
            resultError: resultError
        },
      });
    }
  }

  const handleXLSXExport = () => {
    setMessage("Exporting XLSX");
    setExportError("");
    // Activity log 
    addError(oidcUser.access_token, userId, orgDomain, "Started CurrentAdmin Report XLSX-EXPORT in NEWSALT ClientApp", null, "CurrentAdminReportQuizHistory.tsx", 6, EnableActivityLogs);
    setExporting(true)
    const r = quizHistoryItems[0]

    getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId), buildRequestStringArray(), 6, "CurrentAdminReportQuizHistory", enableRowBanding, "", "", "", "", false, false, "").then((xlxsRes) => {
      if (xlxsRes.isSuccess) {
        const fileData = xlxsRes.xlsxDataOutput.fileData
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(
          new Blob([serialToArray(atob(fileData))], {
            type: ''
          })
        )

        a.download = "QuizHistory-" + GetDate_yymmdd() + ".xlsx";
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)

      }
      else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
        setExportError(xlxsRes.error);
      }
      setExporting(false)
      // Activity log 
      addError(oidcUser.access_token, userId, orgDomain, "Completed CurrentAdmin Report XLSX-EXPORT in NEWSALT ClientApp", null, "CurrentAdminReportQuizHistory.tsx", 6, EnableActivityLogs);
    })
  }

  const handleCSVExport = () => {
    setMessage("Exporting CSV");
    setExportError("");
    // Activity log 
    addError(oidcUser.access_token, userId, orgDomain, "Started CurrentAdmin Report CSV-EXPORT in NEWSALT ClientApp", null, "CurrentAdminReportResult.tsx", 6, EnableActivityLogs);
    setExporting(true)
    const r = quizHistoryItems[0]
    getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId), buildRequestStringArray(), 6, "CurrentAdminReportQuizHistory", "", "", "", "", false, false, "", false).then((csvRes) => {
      if (csvRes.isSuccess) {
        const fileData = csvRes.csvDataOutput.fileData
        var a = window.document.createElement('a');

        a.href = window.URL.createObjectURL(
          new Blob([serialToArray(atob(fileData))], {
            type: ''
          })
        )
        a.download = "QuizHistory-" + GetDate_yymmdd() + ".csv";
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)

      }
      else if (csvRes.error !== undefined && csvRes.error !== null) {
        setExportError(csvRes.error);
      }
      setExporting(false)
      // Activity log 
      addError(oidcUser.access_token, userId, orgDomain, "Completed CurrentAdmin Report CSV-EXPORT in NEWSALT ClientApp", null, "CurrentAdminReportQuizHistory.tsx", 6, EnableActivityLogs);
    })
  }

  const handlePDFXExport = () => {
    setMessage("Exporting PDF");
    setExportError("");
    // Activity log 
    addError(oidcUser.access_token, userId, orgDomain, "Started CurrentAdmin Report PDF-EXPORT in NEWSALT ClientApp", null, "CurrentAdminReportResult.tsx", 6, EnableActivityLogs);
    setExporting(true)
    const r = quizHistoryItems[0]
    getExportedPDFData(oidcUser.access_token, userId, parseInt(orgId), buildRequestStringArray(), 6, "CurrentAdminReportQuizHistory", enableRowBanding, "", "", "", "", false, false, "").then((pdfRes) => {
      if (pdfRes.isSuccess) {
        const fileData = pdfRes.pdfDataOutput.fileData
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(
          new Blob([serialToArray(atob(fileData))], {
            type: ''
          })
        )
        a.download = "QuizHistory-" + GetDate_yymmdd() + ".PDF";
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)
      }
      else if (pdfRes.error !== undefined && pdfRes.error !== null) {
        setExportError(pdfRes.error);
      }
      setExporting(false)
      // Activity log 
      addError(oidcUser.access_token, userId, orgDomain, "Completed CurrentAdmin Report PDF-EXPORT in NEWSALT ClientApp", null, "CurrentAdminReportResult.tsx", 6, EnableActivityLogs);
    })
  }

  if (loading) {
    return <LoaderContainer />
  }

  return (
    <>
      <div className={styles.backButtonSpecific}>
          <CustomTooltip displayText={["Back"]}>
            <Button
              onClick={() => handleBackButtonclick()}
              variant="contained"
              color="primary"
              disabled={false}
              className="back"
            >
              <ArrowBackIcon />
            </Button>
          </CustomTooltip>
        </div>
      <BrandedCard>
        <div className={styles.reportPageTitleSection}>
          <div className={styles.exportBtnContainer}>
            <div className={styles.reportContainer} style={{ width: "85%" }}>
              <div className={styles.reportDetailContainer}>
                <span className={styles.reportDetailTitle}>{"Report run by" /* TODO Tranlation */}</span>
                <span className={styles.reportDetail}> {`${given_name} ${family_name}`}</span>
              </div>
              <div className={styles.reportDetailContainer} >
                <span className={styles.reportDetailTitle}>{"Report run at" /* TODO Tranlation */}</span>
                <span className={styles.reportDetail}>
                  {reportRunTimeTaken}
                </span>
              </div>
            </div>
            <div className={styles.reportExportContainer} style={{ width: "15%" }}>
              <CustomTooltip displayText={["Export to XLSX"] /*TODO: Add translations */}>
                <Button className="xlxs" color="primary" onClick={handleXLSXExport} id="btnNewSaltCARReportExportXLSX">
                  <ExcelFileIcon />
                </Button>
              </CustomTooltip>
              <CustomTooltip displayText={["Export to CSV"] /*TODO: Add translations */}>
                <Button className="csv" color="primary" onClick={handleCSVExport} id="btnNewSaltCARReportExportCSV">
                  <CSVFileIcon />
                </Button>
              </CustomTooltip>
              <CustomTooltip displayText={["Export to PDF"] /*TODO: Add translations */}>
                <Button className="pdf" color="primary" onClick={handlePDFXExport} id="btnNewSaltCARReportExportPDF">
                  <PDFIcon />
                </Button>
              </CustomTooltip>
            </div>            
          </div>
        </div>
      </BrandedCard>
      <hr />
      {<div style={{ color: "red" }}>{(exportError === undefined || exportError === null || exportError === "") ? "" : ("Export Error: " + exportError)}</div>}
      <hr />
      <div className={styles.paginationControlsContainer}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 100]}
          component="div"
          count={quizHistoryItems.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      <div>
        <UnbrandedCard>
          <Table>
            <TableHead>
              {renderQuizHistoryHead()}
            </TableHead>
            <TableBody>
              {renderQuizHistoryRows()}
            </TableBody>
          </Table>
        </UnbrandedCard>
      </div>

      <div className={styles.paginationControlsContainer}>
        <TablePagination
          component="div"
          count={quizHistoryItems.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );

}
export default CurrentAdminReportQuizHistory
