import React, { useContext, useEffect, useState } from 'react'
import styles from "../../../styles/organisationmanagement.module.css";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import UnbrandedCard from '../../../Components/UnbrandedCard';
import Units from '../../ReportCommonComponents/Units';
import { UnitTreeItem } from '../../../globalTypes';
import getRelevantUnitTree from '../../../API/GetRelevantUnitTree';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import LoaderContainer from '../../../Components/LoaderContainer';

interface Props {
  isCalledFrom: string;
  selectedUnitIds: string;
  setUpdatedSelectedUnits: Function;
  needLatestUnits: boolean;
  setUnitsLoaded: Function;
  setAllDeniedUnitsAsCSV: Function;
}

const AssignUnits = ({ isCalledFrom, selectedUnitIds, setUpdatedSelectedUnits, needLatestUnits, setUnitsLoaded, setAllDeniedUnitsAsCSV }: Props) => {
  const {
    translations: { allResourcesToTranslations },
  } = useContext(TranslationsContext);
  const [units, setUnits] = useState<Array<UnitTreeItem>>([]);
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const requestingUserId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";
  const [filterUnitName, setFilterUnitName]  = useState("");
  const [loading, setLoading] = useState<boolean>(true);

  const collectUnselectedUnitsCSV = (unititems: Array<UnitTreeItem>) => {
    let retValCSV = "";
    if (!!unititems && unititems.length > 0) {
      for (let idx=0; idx<unititems.length; idx++) {
        let curunit = unititems[idx];
        if (!!curunit && !curunit.isSelected) {
          retValCSV = retValCSV === "" ? curunit.unitID.toString() : retValCSV + "," + curunit.unitID.toString();
        }
        if (!!curunit && !!curunit.childUnitTreeItems && curunit.childUnitTreeItems.length > 0) {
          let childUnitsCSV = collectUnselectedUnitsCSV(curunit.childUnitTreeItems);
          if (!!childUnitsCSV && childUnitsCSV != "") {
            retValCSV = retValCSV === "" ? childUnitsCSV : retValCSV + "," + childUnitsCSV;
          }
        }
      }
    }
    return retValCSV;
  }
  
  useEffect(() => {
    setLoading(true);
      getRelevantUnitTree(
        oidcUser.access_token,
        requestingUserId,
        orgDomain,
        true,
        parseInt(orgId) 
      ).then((unitTreeRes) => {
        if (!!unitTreeRes && unitTreeRes.isSuccess) {
          if (!!unitTreeRes.unitTreeItems) {
            setUnits(unitTreeRes.unitTreeItems);
          }
          if(selectedUnitIds != "" && !!unitTreeRes.unitTreeItems) {
            const arSelectedUnits = selectedUnitIds.split(',');
            for(let i=0; i< arSelectedUnits.length; i++){
              setSelectedUnit(unitTreeRes.unitTreeItems, parseInt(arSelectedUnits[i]));
            }
          }
          else{
            setLoading(false);
            setUnitsLoaded(true);
          }  
          let allUnits = !!unitTreeRes.unitTreeItems ? unitTreeRes.unitTreeItems as Array<UnitTreeItem> : [];
          //console.log("allUnits " + allUnits);
          let allDeniedUnits : string = "";
          if (!!allUnits && allUnits.length > 0) {
            allDeniedUnits = collectUnselectedUnitsCSV(allUnits);
            //let allDeniedArr = allUnits.filter(u => !!u && !u.isSelected);
            //allDeniedUnits = (!!allDeniedArr && allDeniedArr.length > 0) ? allDeniedArr.map(u => u.unitID.toString()).join(",") : "";
          }
          //console.log("allDeniedUnits " + allDeniedUnits);
          setAllDeniedUnitsAsCSV(allDeniedUnits);      
        }
      });
  }, []);
  
  const setSelectedUnit = (unitsArray: any, selectedUnitId: number) => {
    let unitHierarchy = "";
    let parentUnits: any = [];
    const setSelectOfUnit = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.unitID === selectedUnitId) {
          unit.isSelected = true;
          if (unit.unitHierarchy.indexOf(">") > 0) {
             unitHierarchy = unit.unitHierarchy;       
             parentUnits = unit.unitHierarchy.split(" > ");     
          }
        }
        setSelectOfUnit(unit.childUnitTreeItems);
      }

      if (unitHierarchy !== "" && parentUnits.length > 0) {      
        const setExpandOfUnit = (unitArray: Array<UnitTreeItem> | null) => {
          if (unitArray === null) return; //Break if no children
          if (unitArray.length === 0) return;
          for (let i = 0; i < unitArray.length; i++) {
            const unit = unitArray[i];
            if (parentUnits.includes(unit.name)) {
              unit.isExpanded = true;
            }
            setExpandOfUnit(unit.childUnitTreeItems);
          }
        };
        setExpandOfUnit(unitsArray);
      }   
    };
    setSelectOfUnit(unitsArray);
  
    setUnits([...unitsArray]);
    setLoading(false);
    setUnitsLoaded(true);
  }

  useEffect(() => {   
    if (needLatestUnits){ 
        let unitIds: Array<number> = [];
        let allUnitIds: Array<number> = [];
        const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
          if (unitArray === null) return //Break if no children
          if (unitArray.length === 0) return
          for (let i = 0; i < unitArray.length; i++) {
              const unit = unitArray[i];
              if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) { 
                if (filterUnitName !== "") {
                  if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
                    unitIds.push(unit.unitID)
                  }
                } else {
                  unitIds.push(unit.unitID)
                }
              }
              allUnitIds.push(unit.unitID);
              getSelectedUnitIds(unit.childUnitTreeItems)
          }
      }
      getSelectedUnitIds(units);
      setUpdatedSelectedUnits(unitIds);
    }
}, [needLatestUnits]);

if (loading) return <LoaderContainer />;
  return (
    <UnbrandedCard>
      <div className={styles.mainWrapper}>
        <div className={styles.Wrapper}>
          <div className={styles.unittreesection}>
              <Units isResetClicked={false} givenUnits={units} givenFilterUnitName={filterUnitName} 
                      setGivenFilterUnitName={setFilterUnitName} untitreeTitle={allResourcesToTranslations.assignunits} /> 
          </div>
        </div>
      </div>
    </UnbrandedCard>
  )
}

export default AssignUnits
