//React + Typescript
import React, { useState, useContext, useEffect } from 'react';
import { CurrentAdminReportResultItem } from '../../globalTypes';
import { useHistory } from 'react-router-dom';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Steps, Hints } from "intro.js-react";
//Context
import { LoaderContext } from '../../contexts/LoaderContext';
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';
import { UIContext } from '../../contexts/UIContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Components
import BrandedCard from '../../Components/BrandedCard';
import LoaderContainer from '../../Components/LoaderContainer';
import CustomTooltip from '../../Components/CustomTooltip'
//General Functions
import getColor from '../../functions/getColor';
import serialToArray from '../../functions/serialToArray'
import { getCurrentTimeDDMMYYYYFormat } from '../../functions/getCurrentTimeDDMMYYYYFormat'
//API functions
import { getExportedXLSXData } from '../../API/GetExportedXLSXData'
import { getExportedCSVData } from '../../API/GetExportedCSVData'
import { getExportedPDFData } from '../../API/GetExportedPDFData'
import { addError } from '../../API/AddError'
//Components
import ExcelFileIcon from '../../CustomIcons/ExcelFileIcon';
import CSVFileIcon from '../../CustomIcons/CSVFileIcon';
import PDFIcon from '../../CustomIcons/PDFFileIcon';
import UpArrowIcon from '../../CustomIcons/UpArrowIcon';
import DownArrowIcon from '../../CustomIcons/DownArrowIcon';
import GuideIcon from '../../CustomIcons/GuideIcon';

//MUI Components
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table';
import GetAppIcon from '@material-ui/icons/GetApp';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import BrushIcon from '@material-ui/icons/Brush';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

// Style
import styles from '../../styles/currentAdminReport.module.css'

const CurrentAdminReportResult = (props: any) => {
    const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
    const { oidcUser } = useReactOidc();
    const { profile: { user_id, family_name, given_name, website  }} = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const { theme: { color }} = useContext(CorperateThemeContext);
    const { setMessage } = useContext(LoaderContext);
    const [inputClassificationID, setInputClassificationID] = useState<number>(props?.location?.state?.inputClassificationID ?? -1)
    //const [inputClassificationName, setInputClassificationName] = useState<string>(props?.location?.state?.inputClassificationName ?? "")
    //const [adminReportResultClassID, setAdminReportResultClassID] = useState<number>(props?.location?.state?.resultClassID ?? -1)
    const [adminReportResultClassTypeName, setAdminReportResultClassTypeName] = useState<string>(props?.location?.state?.resultClassTypeName ?? "Group")
    const [adminReportResult, setAdminReportResult] = useState<Array<any>>(props?.location?.state?.resultItems ?? [])
    const [inputFilterFirstName, setInputFilterFirstName] = useState<string>(props?.location?.state?.inputFilterFirstName ?? "")
    const [inputFilterLastName, setInputFilterLastName] = useState<string>(props?.location?.state?.inputFilterLastName ?? "")
    const [inputFilterUserName, setInputFilterUserName] = useState<string>(props?.location?.state?.inputFilterUserName ?? "")
    const [inputFilterEmail, setInputFilterEmail] = useState<string>(props?.location?.state?.inputFilterEmail ?? "")
    const [inputFilterIncludeInactive, setInputFilterIncludeInactive] = useState<string>(props?.location?.state?.inputFilterIncludeInactive ?? "")
    const [exporting, setExporting] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true)
    const [currentSortBy, setCurrentSortBy] = useState<string>("")
    const [sortInProgress, setSortInProgress] = useState<boolean>(false)
    const [firstNameDescending, setFirstNameDescending] = useState<boolean>(true)
    const [lastNameDescending, setLastNameDescending] = useState<boolean>(true)
    const [unitDescending, setUnitDescending] = useState<boolean>(true)
    const [emailDescending, setEmailDescending] = useState<boolean>(true)
    const [externalIDDescending, setExternalIDDescending] = useState<boolean>(true)
    const [userStatusDescending, setUserStatusDescending] = useState<boolean>(true)
    const [courseDescending, setCourseDescending] = useState<boolean>(true)
    const [previousStatusDescending, setPreviousStatusDescending] = useState<boolean>(true)
    const [currentStatusDescending, setCurrentStatusDescending] = useState<boolean>(true)
    const [managerEmailDescending, setManagerEmailDescending] = useState<boolean>(true)
    const [groupDescending, setGroupDescending] = useState<boolean>(true)
    const [statusDateDescending, setStatusDateDescending] = useState<boolean>(true)
    const [completionDateDescending, setCompletionDateDescending] = useState<boolean>(true)
    const [userNameDesceding, setUserNameDesceding] = useState<boolean>(true)
    // const [completionReasonDesceding, setCompletionReasonDesceding] = useState<boolean>(true)
    const [reverseNextSort, setReverseNextSort] = useState<boolean>(false)
    const [reportRunTimeTaken, setReportRunTimeTaken] = useState<string>("")
    const { setTitle, setTitleEnglish, updateBackButtonRoute, EnableActivityLogs } = useContext(UIContext);
    const [exportError, setExportError] = useState<string>("")
    const [resultError, setResultError] =  useState<string>(props?.location?.state?.resultError ?? "")

    const toggleBanding = () => setEnableRowBanding(!enableRowBanding)

    const [intro, setIntro] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: ".progressReportList",
            title: "Report Data", intro: allResourcesToTranslations.emrepprvgmmrepdta   
          },      
        ]
      })

      useEffect(() => 
      {
                    let updateSteps = [...intro.steps]
                    updateSteps.push( 
                    {
                      element: ".xlxs",
                      title: "Export", intro:allResourcesToTranslations.sntemrepgmmexpxls
                    },
                    {
                      element: ".csv",
                      title: "Export", intro:allResourcesToTranslations.sntemrepgmmexpcsv
                    },
                    {
                        element: ".pdf",
                        title: "Export", intro:allResourcesToTranslations.sntemrepgmmexppdf
                    }
                    // {
                    //     element: ".rowShading",
                    //     intro: "Click icon to Show/Hide Row Shading, also applies to Export XLSX file."
                    // }           
                )      
      
                          setIntro({...intro, steps: [...updateSteps]})              
      },[])
      
      const onExit = () => {
        setIntro({...intro, stepsEnabled: false });
      };

    const rowBackgroundColor = (index: number) => {
        if (enableRowBanding) {
            return { backgroundColor: index % 2 == 0 ? "#eee" : ""}
        } else {
            return {}
        }
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }
    const GetDate_yymmdd = () => {
        var d =  new Date(Date.now());
        let day = d.getFullYear().toString().substring(2,4)  + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0")
         return day;         
      }
    
    const buildRequestStringArray = () => {
        let resultArray : Array<string> = []
        for (let i = 0; i < adminReportResult.length; i++) {
            const r = adminReportResult[i]
            // Using lastCourseCompletionStatusChangeDate instead of actualLastCourseCompletionDate to match the OldSalt report first 
            resultArray.push(`${r.unitPathway}<#>${r.lastName}<#>${r.firstName}<#>${r.email}<#>${r.externalID}<#>${r.userStatus}<#>${r.courseName}<#>${r.currentStatus.replace('InComplete', 'Incomplete')}<#>${r.previousStatus.replace('InComplete', 'Incomplete')}<#>${r.lastCourseCompletionStatusChangeDate}<#>${r.delinquencyManagerEmail}<#>${r.classificationValue}`)
        }
        return resultArray
    }

    const handleXLSXExport = () => {
        setMessage("Exporting XLSX");
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started Progress Report XLSX-EXPORT in NEWSALT ClientApp", null, "ProgressReportResult.tsx", 6, EnableActivityLogs);
        setExporting(true);
        const sortedArr = buildRequestStringArray();
        const r = adminReportResult[0]
        let ClassificationValue  = (!!inputClassificationID !== true || inputClassificationID <= 0) ? "" : r.classificationValue ;
        getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId), sortedArr, 12, "progressreport", enableRowBanding,ClassificationValue,"","","", inputFilterIncludeInactive === "Yes", inputFilterIncludeInactive === "Yes", "", inputFilterFirstName, inputFilterLastName, inputFilterUserName, inputFilterEmail).then((xlxsRes) => {            
            if (xlxsRes.isSuccess) {
                const fileData = xlxsRes.xlsxDataOutput.fileData
                
                var a = window.document.createElement('a');
                
                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )
                a.download = "ProgressReport-"+ GetDate_yymmdd() + ".xlsx";
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)

            }
            else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
                setExportError(xlxsRes.error);
            }
            setExporting(false)
            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed Progress Report XLSX-EXPORT in NEWSALT ClientApp", null, "ProgressReportResult.tsx", 6, EnableActivityLogs);
        })
    }

    const handleCSVExport = () => {
        setMessage("Exporting CSV");
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started Progress Report CSV-EXPORT in NEWSALT ClientApp", null, "ProgressReportResult.tsx", 6, EnableActivityLogs);
        setExporting(true);
        const sortedArr = buildRequestStringArray();
        const r = adminReportResult[0]
        let ClassificationValue  = (!!inputClassificationID !== true || inputClassificationID <= 0) ? "" : r.classificationValue ;
        getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId), sortedArr, 12, "progressreport",ClassificationValue,"","","", inputFilterIncludeInactive === "Yes", inputFilterIncludeInactive === "Yes", "", false, inputFilterFirstName, inputFilterLastName, inputFilterUserName, inputFilterEmail).then((csvRes) => {            
            if (csvRes.isSuccess) {
                const fileData = csvRes.csvDataOutput.fileData
                var a = window.document.createElement('a');
                
                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )
                a.download = "ProgressReport-"+ GetDate_yymmdd() + ".csv";
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)

            }
            else if (csvRes.error !== undefined && csvRes.error !== null) {
                setExportError(csvRes.error);
            }
            setExporting(false)
            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed Progress Report CSV-EXPORT in NEWSALT ClientApp", null, "ProgressReportResult.tsx", 6, EnableActivityLogs);
        })
    }

    const handlePDFXExport = () => {
        setMessage("Exporting PDF");
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started Progress Report PDF-EXPORT in NEWSALT ClientApp", null, "ProgressReportResult.tsx", 6, EnableActivityLogs);
        setExporting(true);
        const sortedArr = buildRequestStringArray();
        const r = adminReportResult[0]
        let ClassificationValue  = (!!inputClassificationID !== true || inputClassificationID <= 0) ? "" : r.classificationValue ;
        getExportedPDFData(oidcUser.access_token, userId, parseInt(orgId), sortedArr, 12, "progressreport", enableRowBanding,ClassificationValue,"","","", inputFilterIncludeInactive === "Yes", inputFilterIncludeInactive === "Yes", "", inputFilterFirstName, inputFilterLastName, inputFilterUserName, inputFilterEmail).then((pdfRes) => {            
             if (pdfRes.isSuccess) {
                const fileData = pdfRes.pdfDataOutput.fileData
                var a = window.document.createElement('a');
                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )
                a.download = "ProgressReport-"+ GetDate_yymmdd() + ".PDF";
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)

             }
             else if (pdfRes.error !== undefined && pdfRes.error !== null) {
                 setExportError(pdfRes.error);
             }
            setExporting(false)
            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed Progress Report PDF-EXPORT in NEWSALT ClientApp", null, "ProgressReportResult.tsx", 6, EnableActivityLogs);
        })
    }
   
    const sortResultsByAsync = async (sortBy: "FirstName" | "LastName" | "Email" | "ExternalID" | "Unit" | "UserStatus" | "Course" | "CompletionDate" | "CurrentState" | "PreviousState" | "ManagerEmail" | "Group", sortGivenItemInDesc: boolean ) => {
        if (adminReportResult == null || adminReportResult.length <= 0) {
            return adminReportResult;
        }
        var result = adminReportResult.slice(0)
        setCurrentSortBy(sortBy)
        switch(sortBy) {
            case "Unit":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) =>{
                        if(b === null || b.unitPathway === null) { return -1; }
                        if(a === null || a.unitPathway === null) { return 1; }
                        if(a.unitPathway.toLowerCase() > b.unitPathway.toLowerCase()) { return -1; }
                        if(a.unitPathway.toLowerCase() < b.unitPathway.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setUnitDescending(false)
                    }
                } else {
                    result.sort((a, b) =>{
                        if(b === null || b.unitPathway === null) { return 1; }
                        if(a === null || a.unitPathway === null) { return -1; }
                        if(a.unitPathway.toLowerCase() > b.unitPathway.toLowerCase()) { return 1; }
                        if(a.unitPathway.toLowerCase() < b.unitPathway.toLowerCase()) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setUnitDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "Email":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) =>{
                        if (b === null || b.email === null) { return -1; }
                        if (a === null || a.email === null) { return 1; }
                        if (a.email.toLowerCase() > b.email.toLowerCase()) { return -1; }
                        if (a.email.toLowerCase() < b.email.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setEmailDescending(false)
                    }
                } else {
                    result.sort((a, b) =>{
                        if (b === null || b.email === null) { return 1; }
                        if (a === null || a.email === null) { return -1; }
                        if (a.email.toLowerCase() > b.email.toLowerCase()) { return 1; }
                        if (a.email.toLowerCase() < b.email.toLowerCase()) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setEmailDescending(true) 
                    }
                }
                setAdminReportResult(result);
                break
            case "FirstName":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) =>{
                        if (b === null || b.firstName === null) { return -1; }
                        if (a === null || a.firstName === null) { return 1; }
                        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) { return -1; }
                        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setFirstNameDescending(false)
                    }
                } else {
                    result.sort((a, b) =>{
                        if (a === null || a.firstName === null) { return -1; }
                        if (b === null || b.firstName === null) { return 1; }
                        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) { return -1; }
                        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setFirstNameDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "LastName":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) =>{
                        if (b === null || b.lastName === null) { return -1; }
                        if (a === null || a.lastName === null) { return 1; }
                        if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) { return -1; }
                        if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setLastNameDescending(false)
                    }
                } else {
                    result.sort((a, b) =>{
                        if (a === null || a.lastName === null) { return -1; }
                        if (b === null || b.lastName === null) { return 1; }
                        if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) { return -1; }
                        if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setLastNameDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "Course":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) =>{
                        if (b === null || b.courseName === null) { return -1; }
                        if (a === null || a.courseName === null) { return 1; }
                        if (a.courseName.toLowerCase() > b.courseName.toLowerCase()) { return -1; }
                        if (a.courseName.toLowerCase() < b.courseName.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCourseDescending(false)
                    }
                } else {
                    result.sort((a, b) =>{
                        if (a === null || a.courseName === null) { return -1; }
                        if (b === null || b.courseName === null) { return 1; }
                        if (a.courseName.toLowerCase() < b.courseName.toLowerCase()) { return -1; }
                        if (a.courseName.toLowerCase() > b.courseName.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCourseDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "ExternalID":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) =>{
                        if (b === null || b.externalID === null) { return -1; }
                        if (a === null || a.externalID === null) { return 1; }
                        if (a.externalID.toLowerCase() > b.externalID.toLowerCase()) { return -1; }
                        if (a.externalID.toLowerCase() < b.externalID.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setExternalIDDescending(false)
                    }
                } else {
                    result.sort((a, b) =>{
                        if (a === null || a.externalID === null) { return -1; }
                        if (b === null || b.externalID === null) { return 1; }
                        if (a.externalID.toLowerCase() < b.externalID.toLowerCase()) { return -1; }
                        if (a.externalID.toLowerCase() > b.externalID.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setExternalIDDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "UserStatus":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) =>{
                        if (b === null || b.userStatus === null) { return -1; }
                        if (a === null || a.userStatus === null) { return 1; }
                        if (a.userStatus.toLowerCase() > b.userStatus.toLowerCase()) { return -1; }
                        if (a.userStatus.toLowerCase() < b.userStatus.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setUserStatusDescending(false)
                    }
                } else {
                    result.sort((a, b) =>{
                        if (a === null || a.userStatus === null) { return -1; }
                        if (b === null || b.userStatus === null) { return 1; }
                        if (a.userStatus.toLowerCase() < b.userStatus.toLowerCase()) { return -1; }
                        if (a.userStatus.toLowerCase() > b.userStatus.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setUserStatusDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "CurrentState":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) =>{
                        if (b === null || b.currentStatus === null) { return -1; }
                        if (a === null || a.currentStatus === null) { return 1; }
                        if (a.currentStatus.toLowerCase() > b.currentStatus.toLowerCase()) { return -1; }
                        if (a.currentStatus.toLowerCase() < b.currentStatus.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCurrentStatusDescending(false)
                    }
                } else {
                    result.sort((a, b) =>{
                        if (a === null || a.currentStatus === null) { return -1; }
                        if (b === null || b.currentStatus === null) { return 1; }
                        if (a.currentStatus.toLowerCase() < b.currentStatus.toLowerCase()) { return -1; }
                        if (a.currentStatus.toLowerCase() > b.currentStatus.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCurrentStatusDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "PreviousState":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) =>{
                        if (b === null || b.previousStatus === null) { return -1; }
                        if (a === null || a.previousStatus === null) { return 1; }
                        if (a.previousStatus.toLowerCase() > b.previousStatus.toLowerCase()) { return -1; }
                        if (a.previousStatus.toLowerCase() < b.previousStatus.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setPreviousStatusDescending(false)
                    }
                } else {
                    result.sort((a, b) =>{
                        if (a === null || a.previousStatus === null) { return -1; }
                        if (b === null || b.previousStatus === null) { return 1; }
                        if (a.previousStatus.toLowerCase() < b.previousStatus.toLowerCase()) { return -1; }
                        if (a.previousStatus.toLowerCase() > b.previousStatus.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setPreviousStatusDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "Group":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) =>{
                        if (b === null || b.classificationValue === null) { return -1; }
                        if (a === null || a.classificationValue === null) { return 1; }
                        if (a.classificationValue.toLowerCase() > b.classificationValue.toLowerCase()) { return -1; }
                        if (a.classificationValue.toLowerCase() < b.classificationValue.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setGroupDescending(false)
                    }
                } else {
                    result.sort((a, b) =>{
                        if (a === null || a.classificationValue === null) { return -1; }
                        if (b === null || b.classificationValue === null) { return 1; }
                        if (a.classificationValue.toLowerCase() < b.classificationValue.toLowerCase()) { return -1; }
                        if (a.classificationValue.toLowerCase() > b.classificationValue.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setGroupDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "ManagerEmail":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) =>{
                        if (b === null || b.delinquencyManagerEmail === null) { return -1; }
                        if (a === null || a.delinquencyManagerEmail === null) { return 1; }
                        if (a.delinquencyManagerEmail.toLowerCase() > b.delinquencyManagerEmail.toLowerCase()) { return -1; }
                        if (a.delinquencyManagerEmail.toLowerCase() < b.delinquencyManagerEmail.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setManagerEmailDescending(false)
                    }
                } else {
                    result.sort((a, b) =>{
                        if (a === null || a.delinquencyManagerEmail === null) { return -1; }
                        if (b === null || b.delinquencyManagerEmail === null) { return 1; }
                        if (a.delinquencyManagerEmail.toLowerCase() < b.delinquencyManagerEmail.toLowerCase()) { return -1; }
                        if (a.delinquencyManagerEmail.toLowerCase() > b.delinquencyManagerEmail.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setManagerEmailDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "CompletionDate":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) =>{   
                        if (b === null || b.lastCourseCompletionStatusChangeDate === null || b.lastCourseCompletionStatusChangeDate.trimLeft().trimRight() === "") { return -1; }
                        if (a === null || a.lastCourseCompletionStatusChangeDate === null || a.lastCourseCompletionStatusChangeDate.trimLeft().trimRight() === "") { return 1; }
                        let tokens1 = a.lastCourseCompletionStatusChangeDate.split("/");
                        let aDate = new Date(parseInt(tokens1[2].trimLeft().trimRight()), parseInt(tokens1[1].trimLeft().trimRight()) - 1,  parseInt(tokens1[0].trimLeft().trimRight()));
                        let tokens2 = b.lastCourseCompletionStatusChangeDate.split("/");
                        let bDate = new Date(parseInt(tokens2[2].trimLeft().trimRight()), parseInt(tokens2[1].trimLeft().trimRight()) - 1,  parseInt(tokens2[0].trimLeft().trimRight()));
                        if (aDate > bDate) { return -1; }
                        if (aDate < bDate) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCompletionDateDescending(false)
                    }
                } else {
                    result.sort((a, b) =>{
                        if (a === null || a.lastCourseCompletionStatusChangeDate === null || a.lastCourseCompletionStatusChangeDate.trimLeft().trimRight() === "") { return -1; }
                        if (b === null || b.lastCourseCompletionStatusChangeDate === null || b.lastCourseCompletionStatusChangeDate.trimLeft().trimRight() === "") { return 1; }
                        let tokens1 = a.lastCourseCompletionStatusChangeDate.split("/");
                        let aDate = new Date(parseInt(tokens1[2].trimLeft().trimRight()), parseInt(tokens1[1].trimLeft().trimRight()) - 1,  parseInt(tokens1[0].trimLeft().trimRight()));
                        let tokens2 = b.lastCourseCompletionStatusChangeDate.split("/");
                        let bDate = new Date(parseInt(tokens2[2].trimLeft().trimRight()), parseInt(tokens2[1].trimLeft().trimRight()) - 1,  parseInt(tokens2[0].trimLeft().trimRight()));
                        if (aDate < bDate) { return -1; }
                        if (aDate > bDate) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCompletionDateDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            default:
                break;
        }

        return result.slice(0);
    }
   
    const sortResultsBy = async (sortBy: "FirstName" | "LastName" | "Email" | "ExternalID" | "Unit" | "UserStatus" | "Course" | "CompletionDate" | "CurrentState" | "PreviousState" | "ManagerEmail" | "Group", sortGivenItemInDesc: boolean ) => {
        setSortInProgress(true);
        setExportError("");
        await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
            setCurrentSortBy(sortBy)
            setAdminReportResult(result)
            setPage(0)
            setSortInProgress(false);
        })
    }
    
    const clickUnitAsc = () => {
        setReverseNextSort(false);
        setUnitDescending(false)
        sortResultsBy("Unit", false)
    }

    const clickUnitDesc = () => {
        setReverseNextSort(false);
        setUnitDescending(true)
        sortResultsBy("Unit", true)
    }

    const clickFirstNameAsc = () => {
        setReverseNextSort(false);
        setFirstNameDescending(false);        
        sortResultsBy("FirstName", false);
    }

    const clickFirstNameDesc = () => {
        setReverseNextSort(false);
        setFirstNameDescending(true)
        sortResultsBy("FirstName", true)
    }

    const clickLastNameAsc = () => {
        setReverseNextSort(false);
        setLastNameDescending(false);        
        sortResultsBy("LastName", false);
    }

    const clickLastNameDesc = () => {
        setReverseNextSort(false);
        setLastNameDescending(true)
        sortResultsBy("LastName", true)
    }

    const clickManagerEmailAsc = () => {
        setReverseNextSort(false);
        setManagerEmailDescending(false);
        sortResultsBy("ManagerEmail", false);
    }

    const clickManagerEmailDesc = () => {
        setReverseNextSort(false);
        setManagerEmailDescending(true)
        sortResultsBy("ManagerEmail", true)
    }

    const clickGroupAsc = () => {
        setReverseNextSort(false);
        setGroupDescending(false);
        sortResultsBy("Group", false);
    }

    const clickGroupDesc = () => {
        setReverseNextSort(false);
        setGroupDescending(true)
        sortResultsBy("Group", true)
    }

    const clickEmailAsc = () => {
        setReverseNextSort(false);
        setEmailDescending(false);
        sortResultsBy("Email", false);
    }

    const clickEmailDesc = () => {
        setReverseNextSort(false);
        setEmailDescending(true)
        sortResultsBy("Email", true)
    }

    const clickExternalIDAsc = () => {
        setReverseNextSort(false);
        setExternalIDDescending(false);        
        sortResultsBy("ExternalID", false);
    }

    const clickExternalIDDesc = () => {
        setReverseNextSort(false);
        setExternalIDDescending(true)
        sortResultsBy("ExternalID", true)
    }

    const clickCourseAsc = () => {
        setReverseNextSort(false);
        setCourseDescending(false);
        sortResultsBy("Course", false);
    }

    const clickCourseDesc = () => {
        setReverseNextSort(false);
        setCourseDescending(true)
        sortResultsBy("Course", true)
    }

    const clickUserStatusAsc = () => {
        setReverseNextSort(false);
        setUserStatusDescending(false);
        sortResultsBy("UserStatus", false);
    }

    const clickUserStatusDesc = () => {
        setReverseNextSort(false);
        setUserStatusDescending(true)
        sortResultsBy("UserStatus", true)
    }

    const clickCurrentStatusAsc = () => {
        setReverseNextSort(false);
        setCurrentStatusDescending(false);
        sortResultsBy("CurrentState", false);
    }

    const clickCurrentStatusDesc = () => {
        setReverseNextSort(false);
        setCurrentStatusDescending(true)
        sortResultsBy("CurrentState", true)
    }

    const clickPreviousStatusAsc = () => {
        setReverseNextSort(false);
        setPreviousStatusDescending(false);
        sortResultsBy("PreviousState", false);
    }

    const clickPreviousStatusDesc = () => {
        setReverseNextSort(false);
        setPreviousStatusDescending(true)
        sortResultsBy("PreviousState", true)
    }

    const clickCompletionDateAsc = () => {
        setReverseNextSort(false);
        setCompletionDateDescending(false);
        sortResultsBy("CompletionDate", false);
    }

    const clickCompletionDateDesc = () => {
        setReverseNextSort(false);
        setCompletionDateDescending(true)
        sortResultsBy("CompletionDate", true)
    }

    const initialSort = () => {
        setFirstNameDescending(false)
        setLastNameDescending(false)
        setUnitDescending(false)
        setCourseDescending(false)
        setCurrentSortBy("Unit")
        setReportRunTimeTaken(getCurrentTimeDDMMYYYYFormat())
        setPage(0)
    }

    useEffect(() => {
        initialSort()
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Completed Progress Report in NEWSALT ClientApp", null, "ProgressReportResult.tsx", 6, EnableActivityLogs);            
    },[])

    const GetClassificationname = () => {    
        if (!!inputClassificationID !== true || inputClassificationID <= 0) {
            return "";
        }
        // else if (!!inputClassificationName && inputClassificationName.trim() != "") {
        //     return <div className={styles.reportDetailContainer}>
        //     <span className={styles.reportDetailTitle}>{"Grouping Option" /* TODO Tranlation */}</span>
        //     <span className={styles.reportDetail}> {inputClassificationName.trim()}</span></div>;
        // }
        const r = adminReportResult[0]
        let classificationValue  = r.classificationValue != null ? r.classificationValue : ""; 
        return classificationValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Grouping Option" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {r.classificationValue}</span></div> : ""
    }

    const GetInputFilterFirstName = () => {        
        let curValue  = inputFilterFirstName != null ? inputFilterFirstName : ""; 
        return curValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"First Name" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {inputFilterFirstName}</span></div> : ""
    }

    const GetInputFilterLastName = () => {       
        let curValue  = inputFilterLastName != null ? inputFilterLastName : ""; 
        return curValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Last Name" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {inputFilterLastName}</span></div> : ""
    }

    const GetInputFilterUserName = () => {        
        let curValue  = inputFilterUserName != null ? inputFilterUserName : ""; 
        return curValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Username" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {inputFilterUserName}</span></div> : ""
    }

    const GetInputFilterEmail = () => {       
        let curValue  = inputFilterEmail != null ? inputFilterEmail : ""; 
        return curValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Email" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {inputFilterEmail}</span></div> : ""
    }

    const GetInputFilterIncludeInactive = () => {      
        let curValue  = inputFilterIncludeInactive != null ? inputFilterIncludeInactive : ""; 
        return curValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Include Inactive Accounts" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {inputFilterIncludeInactive}</span></div> : ""
    }

    if (resultError !== null && resultError !== undefined && resultError.trimLeft().trimRight().length > 0)
    {
        setTitle(allResourcesToTranslations.progressreport)
        setTitleEnglish(pageTitles.progressreport)
        updateBackButtonRoute("/progressreport");
        //setExportError("");
        return (<BrandedCard>
            <div className={styles.noResultsCard}>
                <div style={{color:"red"}}>Error: {resultError}</div>
                <br/>
                <div>Select the Back Button to return to Previous Page</div>
            </div>
        </BrandedCard>)
    }
    else if (adminReportResult === null || adminReportResult.length < 1) {
        setTitle(allResourcesToTranslations.progressreport)
        setTitleEnglish(pageTitles.progressreport)
        //setExportError("");
        updateBackButtonRoute("/progressreport");
            return (<BrandedCard>
            <div className={styles.noResultsCard}>
                <div>No Results to Display</div>
                <div>Select the Back Button to return to Previous Page</div>
                </div>
                </BrandedCard>)
    } else {
        setTitle(allResourcesToTranslations.progressreport)
        setTitleEnglish(pageTitles.progressreport)
        updateBackButtonRoute("/progressreport");
    }
 

    if (exporting) return <LoaderContainer/> 
    
    return (
        <>
          <Steps  enabled={intro.stepsEnabled}   steps={intro.steps}  initialStep={intro.initialStep}
          onExit={onExit}
            options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
          }}/>
         <CustomTooltip displayText={["Guide Me"]}>
            <span style={{right:"105px"}} onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
            <GuideIcon />
            </span>
         </CustomTooltip>
         <BrandedCard>
           <div className={styles.reportPageTitleSection}>       
           	<div className={styles.exportBtnContainer}>
                    <div className={styles.reportContainer}>
                        <div className={styles.reportDetailContainer}>
                            <span className={styles.reportDetailTitle}>{"Report run by" /* TODO Tranlation */}</span>
                            <span className={styles.reportDetail}> {`${given_name} ${family_name}`}</span>
                        </div>
                        <div className={styles.reportDetailContainer}>
                            <span className={styles.reportDetailTitle}>{"Report run at" /* TODO Tranlation */}</span>
                            <span className={styles.reportDetail}>
                                { reportRunTimeTaken }
                            </span>
                        </div> 
                        { GetClassificationname() }      
                        { GetInputFilterFirstName() }      
                        { GetInputFilterLastName() }      
                        { GetInputFilterUserName() }      
                        { GetInputFilterEmail() }      
                        { GetInputFilterIncludeInactive() }                      
                    </div>   
                   
                    <div className={styles.reportExportContainer}>
                            <CustomTooltip displayText={["Export to XLSX"] /*TODO: Add translations */}>                                    
                                <Button className="xlxs" color="primary" onClick={handleXLSXExport} id="btnNewSaltPRReportExportXLSX">
                                    <ExcelFileIcon/>
                                    {/* <GetAppIcon/> */}
                                </Button>
                            </CustomTooltip>
                            <CustomTooltip displayText={["Export to CSV"] /*TODO: Add translations */}>         
                                <Button  className="csv" color="primary" onClick={handleCSVExport} id="btnNewSaltPRReportExportCSV">
                                    <CSVFileIcon/>
                                    {/* <GetAppIcon/> */}
                                </Button>
                            </CustomTooltip>
                            <CustomTooltip displayText={["Export to PDF"] /*TODO: Add translations */}>        
                                <Button className="pdf" color="primary" onClick={handlePDFXExport} id="btnNewSaltPRReportExportPDF">
                                <PDFIcon/>
                                    {/* <GetAppIcon/> */}
                                </Button>
                            </CustomTooltip>
                            {/* <CustomTooltip displayText={["Show/Hide Row Shading"]}>        
                                <Button className="rowShading"  color="primary"  onClick={toggleBanding}>
                                    <BrushIcon/> 
                                    <CalendarViewDayIcon/>
                                </Button>
                            </CustomTooltip> */}
                    </div>
                   
                </div>               
            </div>
            </BrandedCard>
            <hr/>
            {<div style={{color:"red"}}>{(exportError === undefined || exportError === null  || exportError === "") ? "" : ("Export Error: " + exportError)}</div>}
            <hr/>
            <div className={ styles.paginationControlsContainer }>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={adminReportResult.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div> 
            <div className={styles.tableSection}>
                <div className={styles.tableContainer}> 
                    <BrandedCard>
                        <TableContainer>
                            <Table>
                            <TableHead className="progressReportList">
                                    <TableRow>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0", width:"100px"  }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setUnitDescending(!unitDescending);
                                                        sortResultsBy("Unit", !unitDescending);
                                                    }}>
                                                {"Unit Pathway"}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "Unit" && unitDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickUnitAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "Unit" && unitDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickUnitDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setLastNameDescending(!lastNameDescending);
                                                        sortResultsBy("LastName", !lastNameDescending)
                                                    }}>
                                                {"Last Name"}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "LastName" && lastNameDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickLastNameAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "LastName" && lastNameDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickLastNameDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setFirstNameDescending(!firstNameDescending);
                                                        sortResultsBy("FirstName", !firstNameDescending)
                                                    }}>
                                                {"First Name"}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "FirstName" && firstNameDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickFirstNameAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "FirstName" && firstNameDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickFirstNameDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setEmailDescending(!emailDescending);
                                                        sortResultsBy("Email", !emailDescending);
                                                    }}>
                                                {"Email"}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "Email" && emailDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickEmailAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "Email" && emailDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickEmailDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setExternalIDDescending(!externalIDDescending);
                                                        sortResultsBy("ExternalID", !externalIDDescending);
                                                    }}>
                                                {"External ID"}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "ExternalID" && externalIDDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickExternalIDAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "ExternalID" && externalIDDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickExternalIDDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setUserStatusDescending(!userStatusDescending);
                                                        sortResultsBy("UserStatus", !userStatusDescending);
                                                    }}>
                                                {"Account Status"}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "UserStatus" && userStatusDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickUserStatusAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "UserStatus" && userStatusDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickUserStatusDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setCourseDescending(!courseDescending);
                                                        sortResultsBy("Course", !courseDescending);
                                                    }}>
                                                {"Course"}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "Course" && courseDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickCourseAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "Course" && courseDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickCourseDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setCurrentStatusDescending(!currentStatusDescending);
                                                        sortResultsBy("CurrentState", !currentStatusDescending);
                                                    }}>
                                                {"Current State"}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "CurrentStatus" && currentStatusDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickCurrentStatusAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "CurrentStatus" && currentStatusDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickCurrentStatusDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setPreviousStatusDescending(!previousStatusDescending);
                                                        sortResultsBy("PreviousState", !previousStatusDescending);
                                                    }}>
                                                {"Previous State"}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "PreviousStatus" && previousStatusDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickPreviousStatusAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "PreviousStatus" && previousStatusDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickPreviousStatusDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setCompletionDateDescending(!completionDateDescending);
                                                        sortResultsBy("CompletionDate", !completionDateDescending);
                                                    }}>
                                                {"Last Completion Date"}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "CompletionDate" && completionDateDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickCompletionDateAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "CompletionDate" && completionDateDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickCompletionDateDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color}} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setManagerEmailDescending(!managerEmailDescending);
                                                        sortResultsBy("ManagerEmail", !managerEmailDescending);
                                                    }}>
                                                {"Manager Email"}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "ManagerEmail" && managerEmailDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickManagerEmailAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "ManagerEmail" && managerEmailDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickManagerEmailDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color, borderRadius: "0 8px 0 0"}} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setGroupDescending(!groupDescending);
                                                        sortResultsBy("Group", !groupDescending);
                                                    }}>
                                                {(!!adminReportResultClassTypeName !== true || adminReportResultClassTypeName.trim() == "") ? "Group" : adminReportResultClassTypeName.trim()}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "Group" && groupDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickGroupAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "Group" && groupDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickGroupDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        adminReportResult.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((result: any, i ) => {
                                            return(
                                                <TableRow key={`progressreportresult-${i}`} style={rowBackgroundColor(i)}>                                                    
                                                    <TableCell align="left">
                                                        {result.unitPathway}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {result.lastName}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {result.firstName}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {result.email}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {result.externalID}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {result.userStatus}
                                                    </TableCell>
                                                    <TableCell align="left"> 
                                                        {result.courseName}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {result.currentStatus.replace('InComplete', 'Incomplete')}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {result.previousStatus.replace('InComplete', 'Incomplete')}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {result.lastCourseCompletionStatusChangeDate} 
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {result.delinquencyManagerEmail}
                                                    </TableCell> 
                                                    <TableCell align="left">
                                                        {result.classificationValue}
                                                    </TableCell>                                                
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </BrandedCard>
                </div>
            </div>
            <div className={ styles.paginationControlsContainer }>
                <TablePagination
                    component="div"
                    count={adminReportResult.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </>
      );    
}

export default CurrentAdminReportResult
