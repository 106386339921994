import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { CourseLicenseResultItem, QuizHistoryReportResultItem, UnitTreeItem } from "../../globalTypes";

//libs
import { Steps, Hints } from "intro.js-react";

//MUI
import { Button, Checkbox, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";

//Contexts
import { UIContext } from "../../contexts/UIContext";
import { LoaderContext } from "../../contexts/LoaderContext";
import { TranslationsContext } from '../../contexts/TranslationsContext';

//API Functions
import { getRelevantUnitTree } from "../../API/GetRelevantUnitTree";
import { useReactOidc } from "@axa-fr/react-oidc-context";

//Components
import BrandedCard from "../../Components/BrandedCard";
import LoaderContainer from "../../Components/LoaderContainer";
import CustomTooltip from "../../Components/CustomTooltip";
import ResetIcon from "../../CustomIcons/ResetIcon";
import Units from "../ReportCommonComponents/Units";
import GuideIcon from '../../CustomIcons/GuideIcon';

//Styles
import styles from "../../styles/licenseReport.module.css";
import GetCourseListByLicenses from "../../API/GetCourseListByLicenses";
import DownArrowIcon from "../../CustomIcons/DownArrowIcon";
import UpArrowIcon from "../../CustomIcons/UpArrowIcon";
import getColor from "../../functions/getColor";
import { CorperateThemeContext } from "../../contexts/CorperateThemeContext";
import GetFullLicensingReport from "../../API/GetFullLicensingReport";
import ButtonPanel from "../CurrentAdminReport/ButtonPanel";

const LicensingReport = () => {
  const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext);
  const history = useHistory();
  const { setMessage } = useContext(LoaderContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute } = useContext(UIContext);
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";
    const {
      theme: { color },
    } = useContext(CorperateThemeContext);
    const [exporting, setExporting] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true);
    const [currentSortBy, setCurrentSortBy] = useState<string>(""); 
    const [sortInProgress, setSortInProgress] = useState<boolean>(false);
  const [courseLicensing, setCourseLicensing] = useState<Array<CourseLicenseResultItem>>([]);
  const [selectedCourses, setSelectedCourses] = useState<Array<CourseLicenseResultItem>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isResetClicked, setisResetClicked] = useState<boolean>(false);
  const [filterIncludeAll, setFilterIncludeAll] =
    useState<boolean>(false);
  const [courseNameDescending, setCourseNameDescending] = useState<boolean>(false)   
  const [quizStatusDateDescending, setQuizStatusDateDescending] = useState<boolean>(false)
  const [reverseNextSort, setReverseNextSort] = useState<boolean>(false)
  const [exportError, setExportError] = useState<string>("")

  

    const [intro, setIntro] = useState({
      stepsEnabled: false,
      initialStep: 0,
      steps: [
        {
          element: ".LicensingReportList",
          title: "Course License Periods", intro: "Period refers to the configured license period, check Include to include the course and selected license period in your report."
        },      
      ]
    })

    useEffect(() => 
    {
                  let updateSteps = [...intro.steps]
                  updateSteps.push( 
                  {
                    element:  ".includeInactive",
                    title: "Filter", intro: "Click Include All to select all courses with selected applicable time periods to be included in your report."
                }, 
                  {
                    element:  ".runReport",
                    title: "Run Report", intro: "Click to Generate Report."
                },
                {
                  element:  ".resetAll",
                  title: "Reset Filters", intro: "Reset all applied filters."
              } 

                  )
                setIntro({...intro, steps: [...updateSteps]})              
      },[])
      
      const onExit = () => {
        setIntro({...intro, stepsEnabled: false });
      };
      const rowBackgroundColor = (index: number) => {
        if (enableRowBanding) {
          return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
        } else {
          return {};
        }
      };
    
      const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
      ) => {
        setPage(newPage);
      };
    
      const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
  {
      if (!isNaN(givenDate.getTime())) {
          const year = new Intl.DateTimeFormat('en-AU', {year: 'numeric'}).format(givenDate);
          const month = new Intl.DateTimeFormat('en-AU', {month: 'numeric'}).format(givenDate);
          const day = new Intl.DateTimeFormat('en-AU', {day: 'numeric'}).format(givenDate);
  
          return (year.toString() + "-" + 
          (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" + 
          (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
      }
      else {
          return "";
      }
  }

      const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

      
    const handleCourseLicenseSelection = (item:any) => {

      let updatedData = courseLicensing.map((content:any) => {
          if(item.courseId == content.courseId) {
            return {
              ...content,
              isSelected: !content.isSelected
            }
          }
          return content
        })
      setCourseLicensing(updatedData);
      setSelectedCourses(updatedData);
  }

  const handlePeriodSelection = (selectedCourseLicensingId:any, selectedCourse: any) => {

    let updatedData = courseLicensing.map((content:any) => {
        if(selectedCourse == content.courseId) {
          return {
            ...content,
            selectedPeriodCourseLicenseId: selectedCourseLicensingId
          }
        }
        return content;
      })
      setCourseLicensing(updatedData);
      setSelectedCourses(updatedData);
}


  useEffect(() => {
    setTitle(allResourcesToTranslations.licensereport);
    setTitleEnglish(pageTitles.licensereport)
    updateBackButtonRoute("");
    setLoading(true);
    GetCourseListByLicenses(
      oidcUser.access_token,
      userId,
      orgDomain,
      parseInt(orgId)
    ).then((result : any) => {
      if (result.isSuccess) {
        setCourseLicensing(result.courseLicencingItems);
        setLoading(false);
      }
    });
  }, []);

  const handlerunReport = () => {
    setLoading(true);
    setMessage("Fetching your report");
    let courseLicenseIds: Array<number> = [];
    const getSelectedCourseLicenseIds = (courseLicensingArray: Array<CourseLicenseResultItem> | null) => {
      if (courseLicensingArray === null) return //Break if no children
      if (courseLicensingArray.length === 0) return
      for (let i = 0; i < courseLicensingArray.length; i++) {
          const courseLicense = courseLicensingArray[i];
          if (courseLicense.isSelected) {
            courseLicenseIds.push(courseLicense.selectedPeriodCourseLicenseId);              
          }
      }
    }
    getSelectedCourseLicenseIds(courseLicensing);
   
    GetFullLicensingReport(oidcUser.access_token, userId, orgDomain, parseInt(orgId), !!courseLicenseIds ? courseLicenseIds.join(',') : "",filterIncludeAll).then((reportRes) => {        
        setLoading(false);
        history.push({
            pathname: `licensingreport/result`,              
            state: {
              includeAll: filterIncludeAll,
              courseLicensingIDs: !!courseLicenseIds ? courseLicenseIds.join(',') : "",                           
                resultItems: (reportRes === null ? null : reportRes.licensingReportItems),
                resultTotalItems: (reportRes === null ? null : reportRes.licensingReportSummaryItems),
                resultError: ((reportRes === null || reportRes === undefined || reportRes.isSuccess || reportRes.error === null || reportRes.error === undefined) ? "" : reportRes.error)
            },
        });
      })
}

  const resetAll = () => {
    setFilterIncludeAll(false);
    let updatedData = courseLicensing.map((content:any) => {
        return {
          ...content,
          isSelected: false,
        };
    })
    setCourseLicensing(updatedData);
    setSelectedCourses(updatedData);

    setisResetClicked(!isResetClicked);
  };

  const sortResultsByAsync = async (sortBy:  "CourseName" | "QuizStatusDate", sortGivenItemInDesc: boolean ) => {
    if (courseLicensing == null || courseLicensing.length <= 0) {
        return courseLicensing;
    }
    var result = courseLicensing.slice(0)
    setCurrentSortBy(sortBy)
    switch(sortBy) {
        
        case "CourseName":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.courseName === null) { return -1; }
                        if (a === null || a.courseName === null) { return 1; }
                        if(a.courseName > b.courseName) { return -1; }
                        if(a.courseName < b.courseName) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCourseNameDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.courseName === null) { return 1; }
                        if (a === null || a.courseName === null) { return -1; }
                        if(a.courseName > b.courseName) { return 1; }
                        if(a.courseName < b.courseName) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCourseNameDescending(true)
                    }
                }
                setCourseLicensing(result);
                break;

                case "QuizStatusDate":
                // if (sortGivenItemInDesc) {
                //   console.log('result',result)
                //     result.sort((a, b) => {
                //         if (b === null || b.periods[0]?.period === null) { return -1; }
                //         if (a === null || a.periods[0]?.period === null) { return 1; }
                //         if(a.periods[0].period.toLowerCase() > b.periods[0].period.toLowerCase()) { return -1; }
                //         if(a.periods[0].period.toLowerCase() < b.periods[0].period.toLowerCase()) { return 1; }
                //         return 0;
                //     })
                //     if (reverseNextSort) {
                //         setQuizStatusDateDescending(false)
                //     }
                // } else {
                //     result.sort((a, b) => {
                //         if (b === null || b.periods[0].period === null) { return 1; }
                //         if (a === null || a.periods[0].period === null) { return -1; }
                //         if(a.periods[0].period.toLowerCase() < b.periods[0].period.toLowerCase()) { return -1; }
                //         if(a.periods[0].period.toLowerCase() > b.periods[0].period.toLowerCase()) { return 1; }
                //         return 0;
                //     })
                //     if (reverseNextSort) {
                //         setQuizStatusDateDescending(true)
                //     }
                // }
                setCourseLicensing(result);
                break;
        default:
            break;
    }
    return result.slice(0);
}

  const sortResultsBy = async (sortBy:  "CourseName" | "QuizStatusDate", sortGivenItemInDesc: boolean ) => {
    setSortInProgress(true);
    setExportError("");
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
        setCurrentSortBy(sortBy)
        setCourseLicensing(result)
        setPage(0)
        setSortInProgress(false);
    }); 
}

  const clickCourseNameAsc = () => {
    setReverseNextSort(false);
    setCourseNameDescending(false)
    sortResultsBy("CourseName", false)
}

const clickCourseNameDesc = () => {
    setReverseNextSort(false);
    setCourseNameDescending(true)
    sortResultsBy("CourseName", true)
}


 
  if (loading) return <LoaderContainer />;

  return (
    <>
         <Steps  enabled={intro.stepsEnabled}   steps={intro.steps}  initialStep={intro.initialStep}
          onExit={onExit}
            options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
          }}/>
         <CustomTooltip displayText={["Guide Me"]}>
            <span onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
            <GuideIcon />
            </span>
         </CustomTooltip>
    <BrandedCard>
      <div className={styles.reportWrraper}>
      <div
        className={styles.paginationControlsContainer}
        style={{ display:'flex',justifyContent: "center" }}
      >
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 100]}
          component="div"
          count={courseLicensing.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      <div className={styles.tableSection}>
        <div className={styles.tableContainer}>
          <BrandedCard>
            <TableContainer>
              <Table>
                <TableHead className="LicensingReportList">
                  <TableRow>
                  <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color,borderRadius: "8px 0 0 0",}}align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => 
                                                    {
                                                        setCourseNameDescending(!courseNameDescending);
                                                        sortResultsBy("CourseName", !courseNameDescending);
                                                    }}>
                          {"Course Name" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{ opacity: (currentSortBy === "CourseName" && courseNameDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} onClick={clickCourseNameAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity: (currentSortBy === "CourseName" && courseNameDescending === true) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} onClick={clickCourseNameDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color,}}align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div
                        // onClick={() => 
                        //   {
                        //       setQuizStatusDateDescending(!quizStatusDateDescending);
                        //       sortResultsBy("QuizStatusDate", !quizStatusDateDescending);
                        //   }}
                          >
                          {"Period" /* TODO translate */}
                        </div>
                        {/* <div className={styles.columnHeadArea}>
                          <span style={{ opacity:  (currentSortBy === "QuizStatusDate" && quizStatusDateDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity:  (currentSortBy === "QuizStatusDate" && quizStatusDateDescending === false) ? "100%" :"50%", margin: "2px", height: "6px", display:"inline-flex" }}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div> */}
                      </div>
                    </TableCell>
                    <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color, borderRadius: "0 8px 0 0"}} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div>
                          {"Include" /* TODO translate */}
                        </div>
                        {/* <div className={styles.columnHeadArea}>
                          <span style={{ opacity: "50%", margin: "2px", height: "6px", display:"inline-flex" }}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity: "50%", margin: "2px", height: "6px", display:"inline-flex" }}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div> */}
                      </div>
                    </TableCell> 
                  </TableRow>
                </TableHead>
                <TableBody>
                  {courseLicensing
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((result: CourseLicenseResultItem, i) => {
                      return (
                        <TableRow
                          key={`courseLicensing-${i}`}
                          style={rowBackgroundColor(i)}>
                          <TableCell align="left">{result.courseName}</TableCell>
                          <TableCell align="left">
                          <Select name="period"
                                            value={result.selectedPeriodCourseLicenseId}
                                            native
                                            className={styles.input}
                                            variant="outlined"                                           
                                            id={"period: " + result.courseId}
                                            onChange={(e) => handlePeriodSelection(e.target.value, result.courseId)}                   
                                        >
                                            {result.periods.map((item: any) => {
                                                return <option key={"Option: " + item.period} value={item.courseLicensingId}>{item.period}</option>
                                            })}
                                        </Select>
                          </TableCell>                          
                          <TableCell align="left">
                          <Checkbox
                                      size="small"
                                      color="primary"
                                      checked={result.isSelected}
                                      className={styles.checkBox}
                                      name="selectedCourse"
                                      onClick={() => handleCourseLicenseSelection(result)}
                                    />{" "}  
                          </TableCell>     
                        </TableRow>
                      );
                    })}
                        

                </TableBody>
              </Table>
            </TableContainer>
          </BrandedCard>
        </div>
      </div>
      <div style={{display:'flex', flexDirection:'row-reverse'}} >
        <div className={`${styles.inactiveContainer} includeInactive`} >
                                    <Checkbox
                                      size="small"
                                      color="primary"
                                      checked={filterIncludeAll}
                                      className={styles.checkBox}
                                      onClick={() => setFilterIncludeAll(!filterIncludeAll)}
                                    />{" "}
                                     {"Include All"}{/*  To do Tranaslate */}
                                  </div>
      </div>
      <div
        className={styles.paginationControlsContainer}
        style={{ display:'flex',justifyContent: "center" }}
      >
        <TablePagination
          component="div"
          count={courseLicensing.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
        <div className={styles.unittreesection}>
          <div>
          
          </div>
        </div>
        <div className={styles.rightSection}>
         
          {/* <div className={styles.buttonSection}>
          <CustomTooltip displayText={["Run Report"]}>
            <Button
              onClick={handlerunReport}
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              className='runReport'
              id="btnNewSaltScheduleReport"
            >
              <AssessmentIcon />
            </Button>
            </CustomTooltip>
            <CustomTooltip displayText={["Reset"]}>
              <span className={`${styles.emailResetIcon} resetAll`} onClick={resetAll}>
                <ResetIcon />
              </span>
            </CustomTooltip>
          </div> */}
           <div>
              <ButtonPanel openReportSchedulerModal={() => false} showScheduler={false} runReport={handlerunReport} resetAll={resetAll}/>
          </div>
        </div>

        <div></div>
      </div>
    </BrandedCard>
    </>
  );
};

export default LicensingReport;
