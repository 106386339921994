import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const SaveIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_201_23)">
<path d="M23.1213 3.62133L20.3787 0.878672C19.816 0.316031 19.053 0 18.2573 0H16.5V5.25C16.5 5.66419 16.1642 6 15.75 6H3.75C3.33581 6 3 5.66419 3 5.25V0H1.5C0.671625 0 0 0.671531 0 1.5V22.5C0 23.3284 0.671625 24 1.5 24H22.5C23.3284 24 24 23.3284 24 22.5V5.74266C24 4.94695 23.684 4.18392 23.1213 3.62133ZM21 21H3V12H21V21Z" fill="#FFFFFF"/>
<path d="M13.5 0H10.5V4.5H13.5V0Z" fill="#FFFFFF"/>
</g>
<defs>
<clipPath id="clip0_201_23">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

);

export default SaveIcon
