import axios from 'axios';
import { addError } from '../../AddError';

export const SaveOrgEmailSetup = async (token: string, userId: number, orgDomain: string, orgId: number | null, remescid:number,
  courseIds:string, policyIds: string, externalCertificateIds: string, reminderType: string,
  initialenrolmentnumber: number, initialEnrolmentEnable: boolean,
  preExpiryEnable: boolean, preExpirynumber: number, preExpiryInitialEnrolment: boolean, preExpiryResitPeriod: boolean,
  postExpiryEnable: boolean, postExpirynumber: number,postExpirynumberdays: number,postExpiryInitialEnrolment: boolean, postExpiryResitPeriod: boolean,
  managerEnable: boolean, managerExpirynumber: number, managerCumulativeEnable: boolean
  ) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/organisation/SaveOrgEmailSetup`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {       
        UserId: userId,
        OrgId: orgId,
        RemEscId: remescid,
        CourseIds: courseIds,
        PolicyIds: policyIds,
        ExternalCertificateIds: externalCertificateIds,
        ReminderType: reminderType,

        DaysToCompleteCourse: initialenrolmentnumber,
        RemindUsers: initialEnrolmentEnable,

        QuizExpiryWarn: preExpiryEnable,
        DaysQuizExpiry: preExpirynumber,
        PreExpInitEnrolment: preExpiryInitialEnrolment,
        PreExpResitPeriod: preExpiryResitPeriod,

        PostExpReminder: postExpiryEnable,
        NumOfRemNotfy: postExpirynumber,        
        RepeatRem: postExpirynumberdays,        
        PostExpInitEnrolment: postExpiryInitialEnrolment,
        PostExpResitPeriod:postExpiryResitPeriod,

        NotifyMgr: managerEnable,
        IsCumulative: managerCumulativeEnable,        
        NotifyMgrDays: managerExpirynumber
        
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "SaveOrgEmailSetup.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "SaveOrgEmailSetup.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "SaveOrgEmailSetup.ts", 1)
    }
    return null;
  }
};

export default SaveOrgEmailSetup;