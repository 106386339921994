import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const ManageTipVideosIconNonWhiteMenu = () => (    
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.5401 6.5118C22.4213 6.03721 22.1794 5.60237 21.8387 5.2512C21.4981 4.90004 21.0708 4.64498 20.6001 4.5118C18.8801 4.0918 12.0001 4.0918 12.0001 4.0918C12.0001 4.0918 5.12008 4.0918 3.40008 4.5518C2.92933 4.68498 2.50206 4.94004 2.16143 5.2912C1.8208 5.64237 1.57887 6.07721 1.46008 6.5518C1.1453 8.29735 0.991319 10.0681 1.00008 11.8418C0.988863 13.6288 1.14285 15.4131 1.46008 17.1718C1.59104 17.6316 1.83839 18.0499 2.17823 18.3863C2.51806 18.7226 2.9389 18.9656 3.40008 19.0918C5.12008 19.5518 12.0001 19.5518 12.0001 19.5518C12.0001 19.5518 18.8801 19.5518 20.6001 19.0918C21.0708 18.9586 21.4981 18.7036 21.8387 18.3524C22.1794 18.0012 22.4213 17.5664 22.5401 17.0918C22.8524 15.3594 23.0064 13.6021 23.0001 11.8418C23.0113 10.0548 22.8573 8.2705 22.5401 6.5118V6.5118Z" stroke="#21394F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.75 15.1118L15.5 11.8418L9.75 8.57178V15.1118Z" stroke="#21394F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    
    );

export default ManageTipVideosIconNonWhiteMenu
