import React from 'react'
import CustomTooltip from '../../Components/CustomTooltip';
import WrapTextIcon from '@material-ui/icons/WrapText';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import SelectAllIcon from '../../CustomIcons/SelectAllIcon';
import UnSelectAllIcon from '../../CustomIcons/UnSelectAllIcon';
import ExpandAllIcon from '../../CustomIcons/ExpandAllIcon';
import CollapseAllIcon from '../../CustomIcons/CollapseAllIcon';

import styles from '../../styles/currentAdminReport.module.css'

interface Props {
    expandAllUnits: Function
    colapseAllUnits: Function 
    selectAllUnits: Function
    deselectAllUnits: Function
}

const UnitFilterButtons = ({ expandAllUnits, colapseAllUnits, selectAllUnits, deselectAllUnits }: Props) => {

    const handleExpandAll = () => {
        expandAllUnits()
    }

    const handleColapseAll = () => {
        colapseAllUnits()
    }

    const handleSelectAll = () => {
        selectAllUnits()
    }

    const handleDeselect = () => {
        deselectAllUnits()
    }

    return (
        <>
            <ButtonGroup variant="contained" color="primary" aria-label="Filter">
                <Button 
                    className={`${styles.button} selectallunit`}
                    size="small"
                    onClick={handleSelectAll}                    
                    >

                        <SelectAllIcon />
                        <span className={styles.unitButtonSpanStyle}>{` Select All` }</span>
                </Button>
                <Button 
                    className={`${styles.button} deselectallunit`}
                    size="small"
                    onClick={handleDeselect}
                    >
                        <UnSelectAllIcon />
                        <span className={styles.unitButtonSpanStyle}>{"Unselect All" }</span>
                </Button>
                <Button 
                    className={`${styles.button} expandallunit`}
                    size="small"
                    onClick={handleExpandAll}
                    >
                        <ExpandAllIcon />
                        <span className={styles.unitButtonSpanStyle}>{"Expand All" }</span>
                </Button>
                <Button 
                    className={`${styles.button} collapseallunit`}
                    size="small"
                    onClick={handleColapseAll}
                    >
                        <CollapseAllIcon />
                        <span className={styles.unitButtonSpanStyle}>{"Collapse All"}</span>
                </Button>
            </ButtonGroup>
        </>
    )
}

export default UnitFilterButtons
