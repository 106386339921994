import axios from 'axios';
import { addError } from '../../AddError';

const SaveExternalCertificate = async (token: string, requestinguserId: number, orgDomain: string, orgId:number, externalCertificateId:number,
  active: boolean, certificateName: string, isUserEditable: boolean, editableGracePeriod: number, hasNoValidDates: boolean,
  isCpdEnabled: boolean, isCPDPointsChanged: boolean = false, changedCPDPoints: number = 0.0, selectedProfileID: number = -1, 
  selectedProfilePeriodID: number = -1, excludePreviousActivities: boolean = true //, csvOfAllProfilesToPeriodIDsToClearPoints: string = ""
  ) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/externalcertificate/SaveExternalCerificate`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        "AccessingUserID": requestinguserId,
        "OrganisationID": orgId,
        "ExternalCertificateId": externalCertificateId,
        "Active": active,
        "CertificateName": certificateName,
        "IsUserEditable": isUserEditable,
        "EditableGracePeriod": editableGracePeriod,
        "IsCpdEnabled": isCpdEnabled,
        "HasNoValidDates": hasNoValidDates,
        "IsCPDPointsChanged": isCPDPointsChanged,
        "ChangedCPDPoints": changedCPDPoints,
        "SelectedProfileID": selectedProfileID,
        "SelectedProfilePeriodID": selectedProfilePeriodID,
        "ExcludePreviousActivities": excludePreviousActivities,
        //"CSVOfAllProfilesToPeriodIDsToClearPoints": csvOfAllProfilesToPeriodIDsToClearPoints
    },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.response, "SaveExternalCertificate.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.request, "SaveExternalCertificate.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.message, "SaveExternalCertificate.ts", 1)
    }
    return null;
  }
};

export default SaveExternalCertificate;