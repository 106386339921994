import React from 'react';

export const RestartIcon = () => (
<svg width="25" height="24" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M18.7,24c6.7-0.9,11.7-7.4,10.1-14.7c-1-4.7-4.6-8.1-9.3-9C11.7-1.3,5,4.6,5,12.1H0.7c-0.7,0-1,0.8-0.5,1.3L5.9,19    c0.3,0.3,0.8,0.3,1.1,0l5.7-5.7c0.5-0.5,0.2-1.3-0.5-1.3H8C8,6.4,13.1,2,19,3.2c3.4,0.7,6,3.2,6.8,6.6c1.3,5.5-2.4,10.5-7.5,11.2
    c-0.7,0.1-1.3,0.7-1.3,1.5C17,23.4,17.8,24.1,18.7,24z M19.7,12c0,1.3-1.1,2.4-2.4,2.4s-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4
    S19.7,10.7,19.7,12z" fill="#63C2FF"/>
</svg>

);

export default RestartIcon
