import axios from 'axios';
import { addError } from '../../AddError';

const getUnitTreeByUnit = async (token: string, requestingUserId: number, orgDomain: string, orgId:number,disabledUnitIDs:string,selectedUnitIDs:string,
  expandedUnitIDs:string, parentUnitID: number) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/unit/GetUnitTreeByUnit`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        "RequestingUserID": requestingUserId,
        "OrganisationID": orgId,
        "DisabledUnitIDs" : disabledUnitIDs,
        "SelectedUnitIDs" : selectedUnitIDs,
        "ExpandedUnitIDs" : expandedUnitIDs,
        "ParentUnitID" : parentUnitID,
        "includingInactiveUnits":false
    },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, parentUnitID, orgDomain, "Rest Exception", error.response, "getUnitTreeByUnit.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, parentUnitID, orgDomain, "Rest Exception", error.request, "getUnitTreeByUnit.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, parentUnitID, orgDomain, "Rest Exception", error.message, "getUnitTreeByUnit.ts", 1)
    }
    return null;
  }
};

export default getUnitTreeByUnit;