import React from 'react';
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 5,
      borderRadius: 1,     
    },
    colorPrimary: {
      backgroundColor: '#fff',
      color:'#cdcdcd'
    },
    bar: {
      borderRadius: 1,
      backgroundColor: '#cdcdcd',
      //boxShadow: 'inset 0 0 10px green',
       backgroundImage: `repeating-linear-gradient(
          -45deg, 
          transparent, 
          transparent 0.6rem,
          #dedede 0.6rem,
          #dedede 1.2rem
        )`,
      backgroundSize: `200% 200%`,
      animation: `${stripes} 8s linear infinite`,
    },
    bar1Determinate: {
        color: 'grey'
    },

  }),
)(LinearProgress);

const stripes = `
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 100%;
  }
`;

const LinearProgressBarWithStripes = (props: LinearProgressProps & { value: number }) => {
  return (
    <div>
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <BorderLinearProgress variant="determinate" {...props} ></BorderLinearProgress>
      </Box>
    </Box>
    </div>
  );
}

export default LinearProgressBarWithStripes;