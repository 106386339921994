import axios from 'axios';
import addError from './AddError';

export const getOrgDensityDetails = async (
    token: string, 
    userId: number,
    orgDomain: string,
    orgId: number,
    unitIds: string,
    courseIds: string,
    effectiveDateOrg: string,
    groupBy: string, 
    sortBy: string,
    includeInactive: boolean,
    classificationId: number,
    filterFirstName: string,
    filterLastName: string,
    filterUserName: string,
    filterEmail: string
    ) => {
    //debugger
    try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/userreport/GetOrgDensityDetails`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        ReportRequestingUserID: userId,
        OrganisationID: orgId,
        UnitIDs: unitIds,
        CourseIDs: courseIds,
        UserFirstName : filterFirstName,
        UserLastName : filterLastName,
        UserName : filterUserName,
        UserEmail: filterEmail,
        EffectiveDateOrg : effectiveDateOrg,
        GroupBy: groupBy, 
        SortBy: sortBy,
        IncludeInactive: includeInactive,
        ClassificationID: classificationId
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
      //debugger
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
        addError(token, userId, orgDomain, "Rest Exception", error.response, "GetOrgDensityDetails.ts", 1)
      } else if (error.request) {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          console.log(error.request);
        }; 
        addError(token, userId, orgDomain, "Rest Exception", error.request, "GetOrgDensityDetails.ts", 1)
      } else if (error.message) {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          console.log(error.message);
        }; 
        addError(token, userId, orgDomain, "Rest Exception", error.message, "GetOrgDensityDetails.ts", 1)
      }
      return null
  }
};

export default getOrgDensityDetails
