import axios from 'axios';
import { addError } from '../../AddError';

const GetOrganisationEmailRule = async (token: string, requestinguserId: number, orgDomain: string, orgId:number,langCode:string) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/organisation/GetOrgEmailRuleList`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        "ReportRequestingUserID": requestinguserId,
        "OrganisationID": orgId,
        "LangCode":langCode
    },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.response, "GetOrganisationEmailTemplates.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.request, "GetOrganisationEmailTemplates.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.message, "GetOrganisationEmailTemplates.ts", 1)
    }
    return null;
  }
};

export default GetOrganisationEmailRule;