import React, { useContext } from 'react';
import styles from '../styles/mainPanel.module.css';
import TitleBar from '../Components/TitleBar'
import { UIContext } from '../contexts/UIContext';

const MainPanel = ({children}: any): JSX.Element => {
  const { sideMenuOpen } = useContext(UIContext);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.mainPanel} style={{ marginLeft: sideMenuOpen? "220px" : "55px" }}>
      <TitleBar/>
          {children}
      </div>
    </div>
  );
}

export default MainPanel