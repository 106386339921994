import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const closeIcon = () => (
    <svg
    width="24px"
    height="24px"
    viewBox="0 0 2.28 2.28"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    baseProfile="full"
    enableBackground="new 0 0 76.00 76.00"
    xmlSpace="preserve"   
  >
    <path
      fill="#000000"
      fillOpacity={1}
      strokeWidth={0.006}
      strokeLinejoin="round"
      d="m0.808 0.666 0.333 0.331 0.333 -0.333 0.142 0.142 -0.333 0.334 0.333 0.333 -0.142 0.142L1.141 1.283l-0.333 0.331L0.666 1.472 0.997 1.141 0.666 0.808 0.808 0.666Z"
    />
  </svg>
);

export default closeIcon