import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const DownArrowIcon = () => (
<svg
    fill="#000000"
    width="24px"
    height="24px"
    viewBox="0 0 0.72 0.72"
    id="down"
    data-name="Flat Color"
    xmlns="http://www.w3.org/2000/svg"
    className="icon flat-color"
   
  >
    <path
      id="primary"
      d="M0.591 0.399a0.03 0.03 0 0 0 -0.043 0L0.39 0.558V0.09a0.03 0.03 0 0 0 -0.06 0v0.468l-0.159 -0.159a0.03 0.03 0 0 0 -0.043 0.043l0.21 0.21a0.03 0.03 0 0 0 0.043 0l0.21 -0.21a0.03 0.03 0 0 0 0 -0.043Z"
      style={{
        fill: "#63c2ff",
      }}
    />
  </svg>
);

export default DownArrowIcon
