//React + Typescript
import React, { useEffect, useState, useContext, useRef } from 'react';
import { User, Language, UnitTreeItem, CourseTreeItem } from '../../globalTypes';
import { useHistory } from 'react-router-dom';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Steps, Hints } from "intro.js-react";
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { CPDPanelContext } from '../../contexts/CPDPanelContext';
//API Functions
import { getRelevantUnitTree } from '../../API/GetRelevantUnitTree';
import { getCourseListByOrganisation } from '../../API/GetCourseListByOrganisation'
import { addError } from '../../API/AddError'
import { getClassificationListByType } from '../../API/GetClassificationListByType';
import getClassificationType from '../../API/GetClassificationType';
//Components
import LoaderContainer from '../../Components/LoaderContainer';
import BrandedCard from '../../Components/BrandedCard';
import ButtonPanel from '../CurrentAdminReport/ButtonPanel'
import CustomTooltip from "../../Components/CustomTooltip";
import GuideIcon from '../../CustomIcons/GuideIcon';
//MUI Components
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

//Styles
import styles from '../../styles/currentAdminReport.module.css';
import GetFullAtRiskReport from '../../API/GetFullAtRiskReport';
import Units from '../ReportCommonComponents/Units';
import Courses from '../ReportCommonComponents/Courses';


interface Props {

}

const AtRiskReport = (props: Props) => {
    const [units, setUnits] = useState<Array<UnitTreeItem>>([])
    const [courseList, setCourseList] = useState<Array<CourseTreeItem>>([])
    const [interactionCount, setInteractionCount] = useState<number>(0) //Required to force update the tree
    const [loading, setLoading] = useState<boolean>(true)
    const [isSmallUnitTree, setSmallUnitTree] = useState<boolean>(true);
    const [isResetClicked, setisResetClicked] = useState<boolean>(false);
    const [filterCourseStatus, setFilterCourseStatus] = useState<any>(0);
    const [filterDateFrom, setFilterDateFrom] = useState("");
    const [filterDateTo, setFilterDateTo] = useState("");
    const [filterUnitName, setFilterUnitName] = useState("");
    const [filterCourseName, setFilterCourseName] = useState("");
    const [failCounter, setFailCounter] = useState("");

    const [classificationList, setClassificationList] = useState([]);
    const [selectedClassification, setSelectedClassification] = useState<any>({});
    const [groupingOption, setGroupingOption] = useState<boolean>(false);
    const { setMessage } = useContext(LoaderContext);
    const { setTitle, setTitleEnglish, updateBackButtonRoute, EnableActivityLogs } = useContext(UIContext);
    const { oidcUser } = useReactOidc();
    const { profile: { user_id, website } } = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    //const orgId = oidcUser. ?? -1;
    const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const history = useHistory();

    const { translations: { allResourcesToTranslations }, currentLanguage, pageTitles } = useContext(TranslationsContext)
    const { cpdList, currentCpd } = useContext(CPDPanelContext);
    const [intro, setIntro] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
            {
                element: ".unitFilterButtons",
                title: 'Units',
                intro: allResourcesToTranslations.emrepgmunitsm,
            },
        ]
    })

    const setGuideMe = (groupingOption: boolean) => {
        let updateSteps = [...intro.steps]
            updateSteps.push({
                element: ".unitFilterSearch",
                title: 'Search units',
                intro: allResourcesToTranslations.unitfiltersearch
            },     
            {
                element:  (units.length <= 10) ? ".courseFilterButtonsnew" : ".courseFilterButtons",
                title: "Courses", intro: allResourcesToTranslations.emrepgmcsm
            },
            {
                element:  (units.length <= 10) ? ".courseFilterSearchnew" : ".courseFilterSearch",
                title: "Filter Courses", intro: allResourcesToTranslations.emrepgmfcsm
            },
            {
                element: ".failCounter",
                title: 'Filter',
                intro: "Filter Results by Fail Counter."
            },
            {
                element: ".dateFrom",
                title: 'Filter',
                intro: allResourcesToTranslations.emrepgmfdfm
            },
            {
                element: ".dateTo",
                title: 'Filter',
                intro: allResourcesToTranslations.emrepgmfdtm
            } );
            if (groupingOption)
            {
                updateSteps.push({
                    element: ".groupby",
                    title: "Filter", intro: allResourcesToTranslations.repgmgroupingoption
                });
            }
            updateSteps.push(
            {
                element: ".runReport",
                title: 'Report',
                intro: allResourcesToTranslations.sntemrepgmmrunrep
            },       
            {
                element: ".resetAll",
                title: 'Reset',
                intro: allResourcesToTranslations.emrepgmram
            }                
            )
        setIntro({ ...intro, steps: [...updateSteps] })
    };

    const onExit = () => {
        setIntro({ ...intro, stepsEnabled: false });
    };


    const deselectAllUnits = () => {
        let updatedUnits = units
        const setAllDeselected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                if (!unitArray[i].isHidden) {
                    const unit = unitArray[i];
                    if (!unit.isDisabled) {
                        unit.isSelected = false;
                    }
                    setAllDeselected(unit.childUnitTreeItems);
                }
            }
        }
        setAllDeselected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }


    const deselectAllCourses = () => {
        let updatedCourses = courseList
        for (let i = 0; i < updatedCourses.length; i++) {
            if (!updatedCourses[i].isHidden) {
                updatedCourses[i].isSelected = false;
            }
        }
        setInteractionCount(interactionCount + 1)
        setCourseList(updatedCourses)
    }

    const resetAll = () => {
        deselectAllCourses();
        deselectAllUnits();
        setFilterDateFrom("");
        setFilterDateTo("");
        setSelectedClassification({ value: 0, classificationID: 0 });
        setFailCounter("");
        setisResetClicked(!isResetClicked);
    }

    const getValidClassificationID = (selectedClassification: any) => {
        var classID: number = 0;
        if ((!!selectedClassification) && (!!selectedClassification.classificationID)) {
            classID = ((typeof selectedClassification.classificationID) !== 'number') ?
                parseInt(selectedClassification.classificationID.toString()) :
                selectedClassification.classificationID;
        }
        return classID;
    }

    const runReport = () => {
        let unitIds: Array<number> = []
        let courseIds: Array<number> = []
        let allUnitIds: Array<number> = [];
        let allCourseIds: Array<number> = [];
        const date = new Date()
        const nextDate = new Date()
        nextDate.setDate(nextDate.getDate() + 1);
        const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) { 
                    if (filterUnitName !== "") {
                        if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
                        unitIds.push(unit.unitID)
                        }
                    } else {
                        unitIds.push(unit.unitID)
                    }
                }
                allUnitIds.push(unit.unitID);
                getSelectedUnitIds(unit.childUnitTreeItems)
            }
        }
        getSelectedUnitIds(units)

        const getSelectedCourses = () => {
            for (let i = 0; i < courseList.length; i++) {
                if (courseList[i].isSelected) {
                    courseIds.push(courseList[i].courseID)
                }
                allCourseIds.push(courseList[i].courseID);
            }
        }
        getSelectedCourses()
        setMessage("Fetching your report");
        setLoading(true);
        let filterClassification: Array<any> = [];
        filterClassification = classificationList.slice(0).filter((val: any, idx: number, arr: any[]) => val.value === selectedClassification.value); //classificationList.find((item) => item.value === selectedClassification.value).text;
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started CourseStatus Report in NEWSALT ClientApp", null, "CourseStatusReport.tsx", 6, EnableActivityLogs);
        GetFullAtRiskReport(oidcUser.access_token,
            userId,
            orgDomain,
            parseInt(orgId),
            unitIds.length > 0 ? unitIds.toString() : allUnitIds.toString(),
            courseIds.length > 0 ? courseIds.toString() : allCourseIds.toString(),
            failCounter == "" ? 0 : parseInt(failCounter),
            ((filterDateFrom === null || filterDateFrom === undefined || filterDateFrom.length < 8) ? "1990-01-01" : filterDateFrom),
            ((filterDateTo === null || filterDateTo === undefined || filterDateTo.length < 8) ? formatDate(nextDate) : filterDateTo),
            getValidClassificationID(selectedClassification)).then((result) => {
                setLoading(false);
                history.push({
                    pathname: `atriskreport/result`,
                    state: {
                        inputFilterFailureCount: failCounter == "" ? 0 : parseInt(failCounter),
                        inputSelectedClassification: filterClassification.length > 0 ? filterClassification[0].text : "",
                        inputFilterDateFrom: (filterDateFrom === null || filterDateFrom === undefined || filterDateFrom.length < 8) ? "1990-01-01" : filterDateFrom,
                        inputFilterDateTo: (filterDateTo === null || filterDateTo === undefined || filterDateTo.length < 8) ? formatDate(nextDate) : filterDateTo,
                        resultItems: (result === null ? null : result.atRiskreportItem),
                        resultError: ((result === null || result === undefined || result.isSuccess || result.error === null || result.error === undefined) ? "" : result.error)
                    },
                });
            });

    }


    const setModalOpen = () => {

    }

    const isGivenItemNameMatched = (item: UnitTreeItem, givenFilterUnitName: string): boolean | undefined => {
        if (item === null || item === undefined || item.name === null || item.name === undefined) {
            return false;
        }
        if (givenFilterUnitName === null || givenFilterUnitName === undefined || givenFilterUnitName.trimLeft().trimRight() === "") {
            return true;
        }
        else {
            if (item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase())) {
                return true;
            }
            return false;
        }
    }

    const isAnyChildItemNameMatched = (list: UnitTreeItem[] | null, givenFilterUnitName: string): boolean | undefined => {
        if (list === null || list === undefined || list.length <= 0) {
            return false;
        }
        if (givenFilterUnitName === null || givenFilterUnitName === undefined || givenFilterUnitName.trimLeft().trimRight() === "") {
            return true;
        }
        else {
            var newList = list.filter((e) => { return (isGivenItemNameMatched(e, givenFilterUnitName) === true || isAnyChildItemNameMatched(e.childUnitTreeItems, givenFilterUnitName) === true); });
            if (newList !== null && newList !== undefined && newList.length > 0) {
                return true;
            }
            return false;
        }
    }

    const updateUnitItemBasedOnNameMatch = (item: UnitTreeItem, givenFilterUnitName: string): boolean | undefined => {
        let isMatchFound = false;
        if ((givenFilterUnitName === null || givenFilterUnitName === undefined || givenFilterUnitName.trimLeft().trimRight() === "") &&
            item !== null && item !== undefined) {
            isMatchFound = true;
            item.isDisabled = !isMatchFound;
            item.isHidden = !isMatchFound;
            if (item.childUnitTreeItems !== null && item.childUnitTreeItems !== undefined && item.childUnitTreeItems.length > 0) {
                item.childUnitTreeItems.forEach(function (e) {
                    let isMatchFoundSub: boolean | undefined = false;
                    isMatchFoundSub = updateUnitItemBasedOnNameMatch(e, givenFilterUnitName);
                });
            }
        }
        else if (givenFilterUnitName !== null && givenFilterUnitName !== undefined && givenFilterUnitName.trimLeft().trimRight() !== "" &&
            item !== null && item !== undefined) {

            isMatchFound = (item.name !== null && item.name !== undefined && item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase()));
            if (isMatchFound) {
                item.isDisabled = false;
                item.isHidden = false;
            }
            else if (!isMatchFound && (item.childUnitTreeItems == null || item.childUnitTreeItems === undefined || item.childUnitTreeItems.length <= 0)) {
                item.isDisabled = true;
                item.isHidden = true;
            }
            else if (!isMatchFound) {
                let curAnyChildNameMatched = isAnyChildItemNameMatched(item.childUnitTreeItems, givenFilterUnitName);
                item.isDisabled = (curAnyChildNameMatched === true);
                item.isHidden = (curAnyChildNameMatched === false);
                isMatchFound = (curAnyChildNameMatched === true);
            }
            if (item.childUnitTreeItems !== null && item.childUnitTreeItems !== undefined && item.childUnitTreeItems.length > 0) {
                item.childUnitTreeItems.forEach(function (e) {
                    let isMatchFoundSub: boolean | undefined = false;
                    isMatchFoundSub = updateUnitItemBasedOnNameMatch(e, givenFilterUnitName);
                });
            }
        }
        return isMatchFound;
    }

    const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
    {
        if (!isNaN(givenDate.getTime())) {
            const year = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(givenDate);
            const month = new Intl.DateTimeFormat('en-AU', { month: 'numeric' }).format(givenDate);
            const day = new Intl.DateTimeFormat('en-AU', { day: 'numeric' }).format(givenDate);

            return (year.toString() + "-" +
                (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" +
                (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
        }
        else {
            return "";
        }
    }

    useEffect(() => {
        setTitle(allResourcesToTranslations.atriskreport)
        setTitleEnglish(pageTitles.atriskreport)
        updateBackButtonRoute("");
        //getUnitTree(oidcUser.access_token, userId, orgDomain, true, "A", parseInt(orgId)).then((unitTreeRes) => {
        getRelevantUnitTree(oidcUser.access_token, userId, orgDomain, true, parseInt(orgId)).then((unitTreeRes) => {
            if (unitTreeRes.isSuccess) {
                setUnits(unitTreeRes.unitTreeItems)
                setSmallUnitTree(unitTreeRes.unitTreeItems === null || unitTreeRes.unitTreeItems.length <= 10);
            }
            getCourseListByOrganisation(oidcUser.access_token, userId, orgDomain, parseInt(orgId), true).then((courseListRes) => {
                if (courseListRes.isSuccess) {
                    setCourseList(courseListRes.courselistItem)
                }

                getClassificationType(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((classType) => {

                    if (classType !== null && classType.classificationTypeIDs !== null &&
                        classType.classificationTypeIDs.length > 0 && classType.classificationTypeIDs[0] > 0) {
                        getClassificationListByType(oidcUser.access_token, userId, orgDomain, parseInt(orgId), classType.classificationTypeIDs[0]).then((data) => {

                            if (data !== null && data.classificationlistItem !== null) {
                                setClassificationList(data.classificationlistItem)
                                setGroupingOption(true);
                                units.length = unitTreeRes.unitTreeItems.length;
                                setGuideMe(true);
                            }
                            else {
                                setGroupingOption(false);
                            }
                        })
                    }
                    else {
                        setGroupingOption(false);
                    }
                    units.length = unitTreeRes.unitTreeItems.length;
                    setGuideMe(groupingOption);
                    setLoading(false);
                })               
            })
             
        })
    }, [])

    useEffect(() => {
        if (units !== null && units !== undefined && units.length > 0) {
            var newUnits = units.map(function (e) {
                // const retFlag = !(e !== null && e !== undefined && e.name !== null && e.name !== undefined && findUnitItemByText(e, filterUnitName.toLowerCase())); 
                //     e.isHidden = retFlag;
                //     e.isDisabled = retFlag;
                const retFlag = !(e !== null && e !== undefined && e.name !== null && e.name !== undefined && updateUnitItemBasedOnNameMatch(e, filterUnitName.toLowerCase()));
                return e;
            });
            setUnits(newUnits);
        }
    }, [filterUnitName])

    useEffect(() => {
        if (courseList !== null && courseList !== undefined && courseList.length > 0) {
            var newCourses = courseList.map(function (e) {
                if (filterCourseName === null || filterCourseName === undefined || filterCourseName.trimRight().trimLeft() === "") {
                    e.isHidden = false;
                }
                else {
                    e.isHidden = !(e !== null && e !== undefined && e.name !== null && e.name !== undefined && e.name.toLowerCase().includes(filterCourseName.toLowerCase()));
                }
                return e;
            });
            setCourseList(newCourses);
        }
    }, [filterCourseName])

    if (loading) return <LoaderContainer />

    return (
        <>
            <Steps enabled={intro.stepsEnabled} steps={intro.steps} initialStep={intro.initialStep} onExit={onExit}
                options={{
                    nextLabel: 'Next',
                    prevLabel: 'Back',
                    tooltipClass: 'sample-class',
                    showBullets: true,
                }} />
            <CustomTooltip displayText={["Guide Me"]}>
                <span onClick={() => setIntro({ ...intro, stepsEnabled: true })} className={styles.guideMeButtonSpecific}>
                    <GuideIcon />
                </span>
            </CustomTooltip>
            <BrandedCard>
                <div className={styles.reportWrraper}>
                    <div className={styles.unittreesection}>
                        <div>
                            <Units isResetClicked={isResetClicked} givenUnits={units} givenFilterUnitName={filterUnitName} setGivenFilterUnitName={setFilterUnitName} />
                        </div>
                        <div hidden={(units === null || units.length <= 10) === false} className={styles.paddingTop}>
                            <Courses isResetClicked={isResetClicked} givenCourses={courseList} hasSmallUnits={true} />
                        </div>
                    </div>
                    <div className={styles.coursetreesection}>
                        <div hidden={(units === null || units.length <= 10) === true}>
                            <Courses isResetClicked={isResetClicked} givenCourses={courseList} hasSmallUnits={false} />
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="failCounter"><TextField className={styles.input} label={"Fail Counter"} variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                                value={failCounter} onChange={(e) => setFailCounter(e.target.value)} /></div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="dateFrom"><TextField className={styles.input} label={"Date From"} variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                                type="date" defaultValue={filterDateFrom} onChange={(e) => setFilterDateFrom(formatDate(new Date(e.target.value)))} /></div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="dateTo"><TextField className={styles.input} label={"Date To"} variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                                type="date" defaultValue={filterDateTo} onChange={(e) => setFilterDateTo(formatDate(new Date(e.target.value)))} /></div>
                        </div>

                        <div className={styles.daysField} style={{ textAlign: 'right' }} >

                        </div>
                        {groupingOption &&
                            <div className="groupby">
                                <div className={styles.groupingOption}>
                                    <FormControl variant="outlined" className={styles.formControl}>

                                        <InputLabel id="grouping">Grouping Option</InputLabel>
                                        <Select
                                            value={selectedClassification.value}
                                            native
                                            onChange={(e) => setSelectedClassification({ value: e.target.value, classificationID: e.target.value })}
                                            className={styles.input}
                                            variant="outlined"
                                            label="Grouping Option"
                                            id="grouping"
                                        >
                                            {classificationList.map((item: any) => {
                                                return <option value={item.value}>{item.text}</option>
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        }

                        <div>
                            <ButtonPanel openReportSchedulerModal={() => setModalOpen()} runReport={runReport} resetAll={resetAll} />
                        </div>
                    </div>
                </div>
            </BrandedCard>
        </>
    )
}

export default AtRiskReport
