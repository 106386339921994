//React + Typescript
import React, { useContext } from 'react';
//Contexts
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { UIContext } from '../../contexts/UIContext';
//Components
import BrandedCard from '../../Components/BrandedCard';
import MyTrainingListTableBody from './MyTrainingListTableBody';
//MUI Components
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
//Styles
import styles from '../../styles/courseList.module.css';

interface Props {
  logNavigation: (message: string) => void
}

export const MyTrainingList = ({ logNavigation }: Props): JSX.Element => {
  const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
  const { showCPD, showLastPassDate} = useContext(UIContext)

  return (
    <>
      <div className={styles.courseListContainer}>
        <BrandedCard>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <span className={styles.nameHeading}>
                      {allResourcesToTranslations.name}
                    </span>
                  </TableCell>
                  { showCPD ? <TableCell align="center" id="CPDPointsColumn">{allResourcesToTranslations.cpdpoints}</TableCell> : null }
                  { showLastPassDate ? <TableCell align="center" className="lastCompletionDate">{allResourcesToTranslations.lastcompletiondate}</TableCell> : null }
                  { showLastPassDate ? <TableCell align="center" className="courseDueDate">{allResourcesToTranslations.duedate}</TableCell> : null }
                  <TableCell align="center" className="status">
                    {allResourcesToTranslations.status}
                  </TableCell>
                  <TableCell align="center" style={{ width:"170px" }}>
                    {allResourcesToTranslations.actions}
                  </TableCell>
                </TableRow>
              </TableHead>
              <MyTrainingListTableBody logNavigation={logNavigation}/>
            </Table>
        </BrandedCard>
      </div>
    </>
  )
}


export default MyTrainingList;
