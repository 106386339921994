// React + Typescript
import React, { useState, useContext, useEffect } from "react";
import { LicensingReportResultItem } from "../../globalTypes";
import { LicensingTotalsReportItems } from "../../globalTypes";
// Libs
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { Steps, Hints } from "intro.js-react";
//Context
import { LoaderContext } from "../../contexts/LoaderContext";
import { CorperateThemeContext } from "../../contexts/CorperateThemeContext";
import { UIContext } from "../../contexts/UIContext";
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Components
import BrandedCard from "../../Components/BrandedCard";
import LoaderContainer from "../../Components/LoaderContainer";
import CustomTooltip from "../../Components/CustomTooltip";

//General Functions
import getColor from "../../functions/getColor";
import serialToArray from "../../functions/serialToArray";
import { getCurrentTimeDDMMYYYYFormat } from "../../functions/getCurrentTimeDDMMYYYYFormat";
//API functions
import { getExportedXLSXData } from "../../API/GetExportedXLSXData";
import { getExportedCSVData } from "../../API/GetExportedCSVData";
import { getExportedPDFData } from "../../API/GetExportedPDFData";
import { addError } from "../../API/AddError";
//Components
import ExcelFileIcon from "../../CustomIcons/ExcelFileIcon";
import CSVFileIcon from "../../CustomIcons/CSVFileIcon";
import PDFIcon from "../../CustomIcons/PDFFileIcon";
import UpArrowIcon from "../../CustomIcons/UpArrowIcon";
import DownArrowIcon from "../../CustomIcons/DownArrowIcon";
//MUI Components
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import CalendarViewDayIcon from "@material-ui/icons/CalendarViewDay";
import BrushIcon from "@material-ui/icons/Brush";

//Chart
import StakedColumnChart from "../../Components/HighChart/StakedColumnChartComponent";
// Style
import styles from "../../styles/currentAdminReport.module.css";
import GuideIcon from "../../CustomIcons/GuideIcon";

const LicensingReportResult = (props: any) => {
  const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, family_name, given_name, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
      .toLowerCase()
      .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";
  const {
    theme: { color },
  } = useContext(CorperateThemeContext);
  const { setMessage } = useContext(LoaderContext);
  const [LicensingReport, setLicensingReportResult] = useState<
    Array<LicensingReportResultItem>
  >(props?.location?.state?.resultItems ?? []);

  const [LicensingTotalsReport, setLicensingTotalsReportResult] = useState<
    Array<LicensingTotalsReportItems>
  >(props?.location?.state?.resultTotalItems ?? []);

  const [courseLicensingIDs, setcourseLicensingIDs] = useState<string>(props?.location?.state?.courseLicensingIDs ?? "");
  const [exporting, setExporting] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true);
  const [currentSortBy, setCurrentSortBy] = useState<string>("");
  const [openCriteria, setOpenCriteria] = useState(false);
  const [sortInProgress, setSortInProgress] = useState<boolean>(false);
  const [courseNameDescending, setcourseNameDescending] = useState<boolean>(false);
  const [unitPathwayDescending, setUnitPathwayDescending] = useState<boolean>(false);
  const [lastNameDescending, setLastNameDescending] = useState<boolean>(false);
  const [firstNameDescending, setFirstNameDescending] = useState<boolean>(false);
  const [emailDescending, setEmailDescending] = useState<boolean>(false);

  const [externalIdescending, setexternalIddescending] = useState<boolean>(false);
  const [archivedDescending, setArchivedDescending] = useState<boolean>(false);
  const [dateArchiveddescending, setDateArchiveddescending] = useState<boolean>(false);
  const [createdDateDescending, setCreatedDateDescending] = useState<boolean>(false);

  const [reverseNextSort, setReverseNextSort] = useState<boolean>(false);
  const [reportRunTimeTaken, setReportRunTimeTaken] = useState<string>("");
  const { setTitle, setTitleEnglish, updateBackButtonRoute, EnableActivityLogs } = useContext(UIContext);
  const [exportError, setExportError] = useState<string>("");
  const [resultError, setResultError] = useState<string>(
    props?.location?.state?.resultError ?? ""
  );
  const [includeAll, setincludeAll] = useState<boolean>(
    props?.location?.state?.includeAll ?? false
  );
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".LicensingReportTotal",
        title: "Total Licenses",
        intro: "This shows summary of courses' Total Licenses,Licenses remaining and Licenses used for a specific period."
      },
      {
        element: ".LicensingReportGraph",
        title: "Licenses",
        intro: "This shows graphical representation of Licenses available and used."
      },
    ]
  })

  useEffect(() => {
    let updateSteps = [...intro.steps];
    updateSteps.push(
      {
        element: ".LicensingReportList",
        title: "Report Data", intro: allResourcesToTranslations.emrepprvgmmrepdta
      },
      {
        element: ".xlxs",
        title: "Export", intro:allResourcesToTranslations.sntemrepgmmexpxls
      },
      {
        element: ".csv",
        title: "Export", intro:allResourcesToTranslations.sntemrepgmmexpcsv
      },
      {
        element: ".pdf",
        title: "Export", intro:allResourcesToTranslations.sntemrepgmmexppdf
      }
      // {
      //   element: ".rowShading",
      //   intro: "Click icon to Show/Hide Row Shading, also applies to Export XLSX file."
      // }
    );
    setIntro({ ...intro, steps: [...updateSteps] });
  }, [])

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  const toggleBanding = () => setEnableRowBanding(!enableRowBanding);

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
    } else {
      return {};
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const GetDate_yymmdd = () => {
    var d =  new Date(Date.now());
    let day = d.getFullYear().toString().substring(2,4)  + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0")
     return day;         
  }

  const buildRequestStringArray = () => {
    let resultArray: Array<string> = [];
    for (let i = 0; i < LicensingReport.length; i++) {
      const r = LicensingReport[i];
      resultArray.push(`${r.courseName}<#>${r.unitPathway}<#>${r.lastName}<#>${r.firstName}<#>${r.email}<#>${r.externalId}<#>${r.dateCreatedTxt}<#>${r.dateArchieved}`);
    }
    return resultArray;
  };


  const buildRequestStringArrayExtraSummary = () => {
    //debugger
    let resultArray: Array<string> = [];
    for (let i = 0; i < LicensingTotalsReport.length; i++) {
      const r = LicensingTotalsReport[i];
      resultArray.push(`${r.courseName}<#>${r.period}<#>${r.licenseQuota}<#>${r.licensesUsed}<#>${r.licensesRemaining}`);
    }

    return resultArray;
  };

  const handleXLSXExport = () => {
    setMessage("Exporting XLSX");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started License Report XLSX-EXPORT in NEWSALT ClientApp",
      null,
      "LicensingReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId), buildRequestStringArray(), 8, "LicenseReport", enableRowBanding, "", "", "", "",includeAll ? true : false, false, "", "", "", "", "", "", "", false, includeAll, 1, "", "", -1, -1, "", courseLicensingIDs, "", buildRequestStringArrayExtraSummary(), 5).then((xlxsRes) => {
      if (xlxsRes.isSuccess) {
        const fileData = xlxsRes.xlsxDataOutput.fileData
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(
          new Blob([serialToArray(atob(fileData))], {
            type: ''
          })
        );

        a.download = "LicenseReport-" + GetDate_yymmdd() + ".xlsx";
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)

      }
      else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
        setExportError(xlxsRes.error);
      }
      setExporting(false);
      // Activity log
      addError(oidcUser.access_token, userId, orgDomain, "Completed License Report XLSX-EXPORT in NEWSALT ClientApp", null, "LicensingReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const handleCSVExport = () => {
    setMessage("Exporting CSV");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started License Report CSV-EXPORT in NEWSALT ClientApp",
      null,
      "LicensingReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId), buildRequestStringArray(),8, "LicenseReport", "", "", "", "", includeAll ? true : false, false, "", false, "", "", "", "", "", "", false, includeAll, 1, "", "", -1, -1, "", courseLicensingIDs, "", buildRequestStringArrayExtraSummary(), 5).then((csvRes) => {
      if (csvRes.isSuccess) {
        const fileData = csvRes.csvDataOutput.fileData
        var a = window.document.createElement('a');

        a.href = window.URL.createObjectURL(
          new Blob([serialToArray(atob(fileData))], {
            type: ''
          })
        )
        a.download = "LicenseReport-" + GetDate_yymmdd() + ".csv";
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)

      }
      else if (csvRes.error !== undefined && csvRes.error !== null) {
        setExportError(csvRes.error);
      }
      setExporting(false)
      // Activity log
      addError(oidcUser.access_token, userId, orgDomain, "Completed License Report CSV-EXPORT in NEWSALT ClientApp", null, "LicensingReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const handlePDFExport = () => {
    setMessage("Exporting PDF");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started License Report PDF-EXPORT in NEWSALT ClientApp",
      null,
      "LicensingReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedPDFData(oidcUser.access_token, userId, parseInt(orgId), buildRequestStringArray(), 8, "LicenseReport", enableRowBanding, "", "", "", "", includeAll ? true : false, false, "", "", "", "", "", "", "", false, includeAll, 1, "", "", -1, -1, "", courseLicensingIDs, "", buildRequestStringArrayExtraSummary(), 5).then((pdfRes) => {
      if (pdfRes.isSuccess) {
        const fileData = pdfRes.pdfDataOutput.fileData
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(
          new Blob([serialToArray(atob(fileData))], {
            type: ''
          })
        )
        a.download = "LicenseReport-" + GetDate_yymmdd() + ".PDF";
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)
      }
      else if (pdfRes.error !== undefined && pdfRes.error !== null) {
        setExportError(pdfRes.error);
      }
      setExporting(false)
      // Activity log
      addError(oidcUser.access_token, userId, orgDomain, "Completed License Report PDF-EXPORT in NEWSALT ClientApp", null, "LicensingReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const sortResultsByAsync = async (
    sortBy:
      | "CourseName"
      | "UnitPathway"
      | "LastName"
      | "FirstName"
      | "Email"     
      | "externalId"
      | "CreatedDate"
      | "ArchivedDate",

    sortGivenItemInDesc: boolean
  ) => {
    if (LicensingReport == null || LicensingReport.length <= 0) {
      return LicensingReport;
    }
    var result = LicensingReport.slice(0);
    setCurrentSortBy(sortBy);
    switch (sortBy) {
      case "UnitPathway":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || !!b.unitPathway === false) {
              return -1;
            }
            if (a === null || !!a.unitPathway === false) {
              return 1;
            }
            if (a.unitPathway.trim().toLowerCase() > b.unitPathway.trim().toLowerCase()) {
              return -1;
            }
            if (a.unitPathway.trim().toLowerCase() < b.unitPathway.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitPathwayDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || !!b.unitPathway === false) {
              return 1;
            }
            if (a === null || !!a.unitPathway === false) {
              return -1;
            }
            if (a.unitPathway.trim().toLowerCase() > b.unitPathway.trim().toLowerCase()) {
              return 1;
            }
            if (a.unitPathway.trim().toLowerCase() < b.unitPathway.trim().toLowerCase()) {
              return -1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitPathwayDescending(true);
          }
        }
        setLicensingReportResult(result);
        break;
      case "CourseName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.courseName === null) {
              return -1;
            }
            if (a === null || a.courseName === null) {
              return 1;
            }
            if (a.courseName.trim().toLowerCase() > b.courseName.trim().toLowerCase()) {
              return -1;
            }
            if (a.courseName.trim().toLowerCase() < b.courseName.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setcourseNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.courseName === null) {
              return 1;
            }
            if (a === null || a.courseName === null) {
              return -1;
            }
            if (a.courseName.trim().toLowerCase() < b.courseName.trim().toLowerCase()) {
              return -1;
            }
            if (a.courseName.trim().toLowerCase() > b.courseName.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setcourseNameDescending(true);
          }
        }
        setLicensingReportResult(result);
        break;
      case "LastName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) {
              return -1;
            }
            if (a === null || a.lastName === null) {
              return 1;
            }
            if (a.lastName.trim().toLowerCase() > b.lastName.trim().toLowerCase()) {
              return -1;
            }
            if (a.lastName.trim().toLowerCase() < b.lastName.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) {
              return 1;
            }
            if (a === null || a.lastName === null) {
              return -1;
            }
            if (a.lastName.trim().toLowerCase() < b.lastName.trim().toLowerCase()) {
              return -1;
            }
            if (a.lastName.trim().toLowerCase() > b.lastName.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastNameDescending(true);
          }
        }
        setLicensingReportResult(result);
        break;
      case "FirstName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.firstName === null) {
              return -1;
            }
            if (a === null || a.firstName === null) {
              return 1;
            }
            if (a.firstName.trim().toLowerCase() > b.firstName.trim().toLowerCase()) {
              return -1;
            }
            if (a.firstName.trim().toLowerCase() < b.firstName.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.firstName === null) {
              return 1;
            }
            if (a === null || a.firstName === null) {
              return -1;
            }
            if (a.firstName.trim().toLowerCase() > b.firstName.trim().toLowerCase()) {
              return 1;
            }
            if (a.firstName.trim().toLowerCase() < b.firstName.trim().toLowerCase()) {
              return -1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstNameDescending(true);
          }
        }
        setLicensingReportResult(result);
        break;
      case "Email":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return -1;
            }
            if (a === null || a.email === null) {
              return 1;
            }
            if (a.email.trim().toLowerCase() > b.email.trim().toLowerCase()) {
              return -1;
            }
            if (a.email.trim().toLowerCase() < b.email.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setEmailDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return 1;
            }
            if (a === null || a.email === null) {
              return -1;
            }
            if (a.email.trim().toLowerCase() < b.email.trim().toLowerCase()) {
              return -1;
            }
            if (a.email.trim().toLowerCase() > b.email.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setEmailDescending(true);
          }
        }
        setLicensingReportResult(result);
        break;
      case "externalId":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.externalId === null) { return -1; }
            if (a === null || a.externalId === null) { return 1; }
            if (a.externalId.toLowerCase() > b.externalId.toLowerCase()) { return -1; }
            if (a.externalId.toLowerCase() < b.externalId.toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setexternalIddescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.externalId === null) { return 1; }
            if (a === null || a.externalId === null) { return -1; }
            if (a.externalId.toLowerCase() < b.externalId.toLowerCase()) { return -1; }
            if (a.externalId.toLowerCase() > b.externalId.toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setexternalIddescending(true)
          }
        }
        setLicensingReportResult(result);
        break;
      case "CreatedDate":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.dateCreatedTxt === null || b.dateCreatedTxt.trim() === "") { return -1; }
            if (a === null || a.dateCreatedTxt === null || a.dateCreatedTxt.trim() === "") { return 1; }
            var dta = a.dateCreated; // new Date(a.dateCreated.split("/").reverse().join("-"))
            var dtb = b.dateCreated; // new Date(b.dateCreated.split("/").reverse().join("-"))
            if (dta > dtb) { return -1; }
            if (dta < dtb) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setDateArchiveddescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.dateCreatedTxt === null || b.dateCreatedTxt.trim() === "") { return 1; }
            if (a === null || a.dateCreatedTxt === null || a.dateCreatedTxt.trim() === "") { return -1; }
            var dta = a.dateCreated; // new Date(a.dateCreated.split("/").reverse().join("-"))
            var dtb = b.dateCreated; // new Date(b.dateCreated.split("/").reverse().join("-"))
            if (dta > dtb) { return 1; }
            if (dta < dtb) { return -1; }
            return 0;
          })
          if (reverseNextSort) {
            setDateArchiveddescending(true)
          }
        }
        setLicensingReportResult(result);
        break;
      case "ArchivedDate":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.dateArchieved === null) {
              return -1;
            }
            if (a === null || a.dateArchieved === null) {
              return 1;
            }
            if (a.dateArchieved.trim().toLowerCase() > b.dateArchieved.trim().toLowerCase()) {
              return -1;
            }
            if (a.dateArchieved.trim().toLowerCase() < b.dateArchieved.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setArchivedDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return 1;
            }
            if (a === null || a.email === null) {
              return -1;
            }
            if (a.dateArchieved.trim().toLowerCase() < b.dateArchieved.trim().toLowerCase()) {
              return -1;
            }
            if (a.dateArchieved.trim().toLowerCase() > b.dateArchieved.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setArchivedDescending(true);
          }
        }
        setLicensingReportResult(result);
        break;
       
      default:
        break;
    }
    return result.slice(0);
  };

  const sortResultsBy = async (
    sortBy:
      | "CourseName"
      | "UnitPathway"
      | "LastName"
      | "FirstName"
      | "Email"     
      | "externalId"
      | "CreatedDate"
      | "ArchivedDate",
    sortGivenItemInDesc: boolean
  ) => {
    setSortInProgress(true);
    setExportError("");
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy);
      setLicensingReportResult(result);
      setPage(0);
      setSortInProgress(false);
    });
  };

  const clickUnitPathwayAsc = () => {
    setReverseNextSort(false);
    setUnitPathwayDescending(false);
    sortResultsBy("UnitPathway", false);
  };

  const clickUnitPathwayDesc = () => {
    setReverseNextSort(false);
    setUnitPathwayDescending(true);
    sortResultsBy("UnitPathway", true);
  };

  const clickPolicyAsc = () => {
    setReverseNextSort(false);
    setcourseNameDescending(false);
    sortResultsBy("CourseName", false);
  };

  const clickPolicyDesc = () => {
    setReverseNextSort(false);
    setcourseNameDescending(true);
    sortResultsBy("CourseName", true);
  };

  const clickLastNameAsc = () => {
    setReverseNextSort(false);
    setLastNameDescending(false);
    sortResultsBy("LastName", false);
  };

  const clickLastNameDesc = () => {
    setReverseNextSort(false);
    setLastNameDescending(true);
    sortResultsBy("LastName", true);
  };

  const clickFirstNameAsc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(false);
    sortResultsBy("FirstName", false);
  };

  const clickFirstNameDesc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(true);
    sortResultsBy("FirstName", true);
  };

  const clickEmailAsc = () => {
    setReverseNextSort(false);
    setEmailDescending(false);
    sortResultsBy("Email", false);
  };

  const clickEmailDesc = () => {
    setReverseNextSort(false);
    setEmailDescending(true);
    sortResultsBy("Email", true);
  };

  const clickExternalIdAsc = () => {
    setReverseNextSort(false);
    setexternalIddescending(false);
    sortResultsBy("externalId", false);
  };

  const clickExternalIdDesc = () => {
    setReverseNextSort(false);
    setexternalIddescending(true);
    sortResultsBy("externalId", true);
  };

  const clickArchiveDateAsc = () => {
    setReverseNextSort(false);
    setDateArchiveddescending(false);
    sortResultsBy("ArchivedDate", false);
  };

  const clickArchivedDateDesc = () => {
    setReverseNextSort(false);
    setDateArchiveddescending(true);
    sortResultsBy("ArchivedDate", true);
  };

  const clickdateCreatedAsc = () => {
    setReverseNextSort(false);
    setCreatedDateDescending(false);
    sortResultsBy("CreatedDate", false);
  };

  const clickdateCreatedDesc = () => {
    setReverseNextSort(false);
    setCreatedDateDescending(true);
    sortResultsBy("CreatedDate", true);
  };

  const initialSort = () => {
    setCurrentSortBy("UnitPathway");
    setUnitPathwayDescending(false);
    setcourseNameDescending(false);
    setLastNameDescending(false);
    setFirstNameDescending(false);
    setEmailDescending(false);
    setexternalIddescending(false);
    setDateArchiveddescending(false);
    setCreatedDateDescending(false);
    setReportRunTimeTaken(getCurrentTimeDDMMYYYYFormat());
    setPage(0);
  };

  useEffect(() => {
    initialSort();
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Completed License Report in NEWSALT ClientApp",
      null,
      "LicensingReportResult.tsx",
      6, EnableActivityLogs
    );
  }, []);

  if (
    resultError !== null &&
    resultError !== undefined &&
    resultError.trimLeft().trimRight().length > 0
  ) {
    setTitle(allResourcesToTranslations.licensereport);
    setTitleEnglish(pageTitles.licensereport)
    updateBackButtonRoute("/Licensingreport");
    //setExportError("");
    return (
      <BrandedCard>
        <div className={styles.noResultsCard}>
          <div style={{ color: "red" }}>Error: {resultError}</div>
          <br />
          <div>Select the Back Button to return to Previous Page</div>
        </div>
      </BrandedCard>
    );
  } else if (LicensingReport === null || LicensingReport.length < 1) {
    setTitle(allResourcesToTranslations.licensereport);
    setTitleEnglish(pageTitles.licensereport)
    //setExportError("");
    updateBackButtonRoute("/Licensingreport");
    return (
      <BrandedCard>
        <div className={styles.noResultsCard}>
          <div>No Results to Display</div>
          <div>Select the Back Button to return to Previous Page</div>
        </div>
      </BrandedCard>
    );
  } else {
    setTitle(allResourcesToTranslations.licensereport);
    setTitleEnglish(pageTitles.licensereport)
    updateBackButtonRoute("/Licensingreport");
  }


  //#region HIghChart

  let categoriesArray: Array<string> = [];
  let seriesData: Array<any> = [];
  let arrLicenseQuota: Array<number> = [];
  let arrLicensesUsed: Array<number> = [];
  let arrLicensesRemaining: Array<number> = [];

  for (let i = 0; i < LicensingTotalsReport.length; i++) {
    const r = LicensingTotalsReport[i];
    if (!!LicensingTotalsReport) {
      categoriesArray.push(`${r.courseName}<br>${r.period}`);
     // arrLicenseQuota.push(parseInt(r.licenseQuota.toString()))
      arrLicensesUsed.push(parseInt(r.licensesUsed.toString()))
      arrLicensesRemaining.push(parseInt(r.licensesRemaining.toString()))
    }
  }

  if (!!LicensingTotalsReport) {
    // seriesData.push({
    //   name: "License Quota",       
    //   data: arrLicenseQuota
    // })
    seriesData.push({
      name: "Licenses Used",
      data: arrLicensesUsed
    })
    seriesData.push({
      name: "Licenses Remaining",
      data: arrLicensesRemaining
    })
  }

  //#endregion


  if (exporting) return <LoaderContainer />;

  return (
    <>
      {
        <div style={{ color: "red" }}>
          {exportError === undefined ||
            exportError === null ||
            exportError === ""
            ? ""
            : "Export Error: " + exportError}
        </div>
      }
      <hr />
      <Steps enabled={intro.stepsEnabled} steps={intro.steps} initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: 'Next',
          prevLabel: 'Back',
          tooltipClass: 'sample-class',
          showBullets: true,
        }} />
      <CustomTooltip displayText={["Guide Me"]}>
        <span style={{ right: "105px" }} onClick={() => setIntro({ ...intro, stepsEnabled: true })} className={styles.guideMeButtonSpecific}>
          <GuideIcon />
        </span>
      </CustomTooltip>
      <BrandedCard>
        <div className={styles.reportPageTitleSection}>
          <div className={styles.exportBtnContainer}>
            <div className={styles.reportContainer}>
              <div className={styles.reportDetailContainer}>
                <span className={styles.reportDetailTitle}>{"Report run by" /* TODO Tranlation */}</span>
                <span className={styles.reportDetail}> {`${given_name} ${family_name}`}</span>
              </div>
              <div className={styles.reportDetailContainer}>
                <span className={styles.reportDetailTitle}>{"Report run at" /* TODO Tranlation */}</span>
                <span className={styles.reportDetail}>
                  {reportRunTimeTaken}
                </span>
              </div>
              <div className={styles.reportDetailContainer}>
                <span className={styles.reportDetailTitle}>
                  {"Include All?" /* TODO Tranlation */}
                </span>
                <span className={styles.reportDetail}>
                  {" "}
                  {includeAll ? "Yes" : "No"}
                </span>
              </div>
            </div>

            <div className={styles.reportExportContainer}>
              <CustomTooltip displayText={["Export to XLSX"] /*TODO: Add translations */}>
                <Button className="xlxs" color="primary" onClick={handleXLSXExport} id="btnNewSaltCARReportExportXLSX">
                  <ExcelFileIcon />
                </Button>
              </CustomTooltip>
              <CustomTooltip displayText={["Export to CSV"] /*TODO: Add translations */}>
                <Button className="csv" color="primary" onClick={handleCSVExport} id="btnNewSaltCARReportExportCSV">
                  <CSVFileIcon />
                </Button>
              </CustomTooltip>
              <CustomTooltip displayText={["Export to PDF"] /*TODO: Add translations */}>
                <Button className="pdf" color="primary" onClick={handlePDFExport} id="btnNewSaltCARReportExportPDF">
                  <PDFIcon />
                </Button>
              </CustomTooltip>
              {/* <CustomTooltip displayText={["Show/Hide Row Shading"] }>
                <Button className="rowShading" color="primary" onClick={toggleBanding}>
                  <BrushIcon />
                  <CalendarViewDayIcon />
                </Button>
              </CustomTooltip> */}
            </div>

          </div>

        </div>
      </BrandedCard>

      <div><span>&nbsp;</span></div>
      <div className={styles.tableSection} style={{ width: "100%" }}>
        <div className={styles.tableContainer} >
          <BrandedCard>
            <TableContainer>
              <Table>
                <TableHead className="LicensingReportTotal">
                  <TableRow>
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div>
                          {"Course" /* TODO translate */}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div>
                          {"Period" /* TODO translate */}
                        </div>
                      </div>
                    </TableCell>

                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="center">
                      <div className={styles.tableHeadCellContainer}>
                        <div>
                          {"License Quota" /* TODO translate */}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="center">
                      <div className={styles.tableHeadCellContainer}>
                        <div>
                          {"Licenses Used" /* TODO translate */}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }} align="center">
                      <div className={styles.tableHeadCellContainer}>
                        <div>
                          {"Licenses Remaining" /* TODO translate */}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {LicensingTotalsReport
                    .map((resultcourseLicensingSummary: LicensingTotalsReportItems, i) => {
                      return (
                        <TableRow>
                          <TableCell align="left">{resultcourseLicensingSummary.courseName}</TableCell>
                          <TableCell align="left">{resultcourseLicensingSummary.period}</TableCell>
                          <TableCell align="center">{resultcourseLicensingSummary.licenseQuota}</TableCell>
                          <TableCell align="center">{resultcourseLicensingSummary.licensesUsed}</TableCell>
                          <TableCell align="center">{resultcourseLicensingSummary.licensesRemaining}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </BrandedCard>

        </div>
      </div>
      <div><span>&nbsp;</span></div>
      <BrandedCard>
        <div style={{ paddingTop: "20px",paddingBottom: "20px", width:"100%" }} className="LicensingReportGraph">          
            <StakedColumnChart categoriesArray={categoriesArray} seriesData={seriesData} ChartTitle={"License Report"} 
             yAxisTitle={"Total License"} themecolor={color}   />                
        </div>
      </BrandedCard>
      <div><span>&nbsp;</span></div>
      <div  className={styles.paginationControlsContainer} style={{ justifyContent: "center" }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 100]}
          component="div"
          count={LicensingReport.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      <div className={styles.tableSection}>
        <div className={styles.tableContainer}>
          <BrandedCard>
            <TableContainer>
              <Table>
                <TableHead className="LicensingReportList">
                  <TableRow>
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                          setcourseNameDescending(!courseNameDescending);
                          sortResultsBy("CourseName", !courseNameDescending);
                        }}>
                          {"CourseName" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{ opacity: currentSortBy === "CourseName" && courseNameDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickPolicyAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity: currentSortBy === "CourseName" && courseNameDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickPolicyDesc}>                           <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                          setUnitPathwayDescending(!unitPathwayDescending);
                          sortResultsBy("UnitPathway", !unitPathwayDescending);
                        }}>
                          {"Unit Pathway" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{ opacity: currentSortBy === "UnitPathway" && unitPathwayDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickUnitPathwayAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity: currentSortBy === "UnitPathway" && unitPathwayDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickUnitPathwayDesc}>                           <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                          setLastNameDescending(!lastNameDescending);
                          sortResultsBy("LastName", !lastNameDescending);
                        }}>
                          {"Last Name" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{ opacity: currentSortBy === "LastName" && lastNameDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickLastNameAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity: currentSortBy === "LastName" && lastNameDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickLastNameDesc}>                           <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                          setFirstNameDescending(!firstNameDescending);
                          sortResultsBy("FirstName", !firstNameDescending);
                        }}>
                          {"First Name" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{ opacity: currentSortBy === "FirstName" && firstNameDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickFirstNameAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity: currentSortBy === "FirstName" && firstNameDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickFirstNameDesc}>                           <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                          setEmailDescending(!emailDescending);
                          sortResultsBy("Email", !emailDescending);
                        }}>
                          {"Email" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{ opacity: currentSortBy === "Email" && emailDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickEmailAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity: currentSortBy === "Email" && emailDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickEmailDesc}>                           <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>                  
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                          setexternalIddescending(!externalIdescending);
                          sortResultsBy("externalId", !externalIdescending);
                        }}>
                          {"ExternalID" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{ opacity: currentSortBy === "externalId" && externalIdescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickExternalIdAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity: currentSortBy === "externalId" && externalIdescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickExternalIdDesc}>                           <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                          setCreatedDateDescending(!createdDateDescending);
                          sortResultsBy("CreatedDate", !createdDateDescending);
                        }}>
                          {"Created Date" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{ opacity: currentSortBy === "CreatedDate" && createdDateDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickdateCreatedAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity: currentSortBy === "CreatedDate" && createdDateDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickdateCreatedDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                          setArchivedDescending(!archivedDescending);
                          sortResultsBy("ArchivedDate", !archivedDescending);
                        }}>
                          {"Archive Date" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{ opacity: currentSortBy === "ArchivedDate" && archivedDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickArchiveDateAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity: currentSortBy === "ArchivedDate" && archivedDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickArchivedDateDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {LicensingReport
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((result: LicensingReportResultItem, i) => {
                      return (
                        <TableRow
                          key={`LicensingReportResult-${i}`}
                          style={rowBackgroundColor(i)}>
                          <TableCell align="left">{result.courseName}</TableCell>
                          <TableCell align="left">{result.unitPathway}</TableCell>
                          <TableCell align="left">{result.lastName}</TableCell>
                          <TableCell align="left">{result.firstName}</TableCell>
                          <TableCell align="left">{result.email}</TableCell>
                          <TableCell align="center">{result.externalId}</TableCell>
                          <TableCell align="left">{result.dateCreatedTxt}</TableCell>
                          <TableCell align="left">{result.dateArchieved}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </BrandedCard>
        </div>
      </div>
      <div className={styles.paginationControlsContainer} style={{ justifyContent: "center" }}>
        <TablePagination
          component="div"
          count={LicensingReport.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};
export default LicensingReportResult;
