import React, { useState, useEffect, useContext } from "react";

//MUI
import {
  AppBar,
  Box,
  Tab,
  Tabs,
  TextField,
  Typography,
  createStyles,
  withStyles,
} from "@material-ui/core";

//Styles
import { useParams } from "react-router";

import styles from "../../../styles/organisationmanagement.module.css";
import GlobalComplianceRules from "./GlobalComplianceRules";
import CourseBasedComplianceRules from "./CourseBasedComplianceRules";

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: 'white',
    },
  },
})((props: any) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme: any) =>
  createStyles({
    root: {
      textTransform: 'none',
      fontWeight: 'bold',
      fontSize: '18px',
      background: '#E0E0E0',
      color: '#21394F',
      boxShadow:'2px 2px 10px rgba(0, 0, 0, 0.25)',
      borderRadius:'8px 8px 0px 0px',
    },
    selected: {
      backgroundColor:'#FFF',    
      boxShadow:'2px 2px 10px rgba(0, 0, 0, 0.25)',  
      color: '#21394F !important',
    }
  }),
)((props: any) => <Tab disableRipple {...props} />);

const ComplianceRules = () => {
  
  const [tabValue, setTabValue] = useState(0);
  const { userIdToEdit } = useParams<{ userIdToEdit: string }>();
  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`account-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3} className={styles.tabContainer} >
          <Typography  component={"div"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const handleTabViewChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <AppBar position="static" color="default">
        <StyledTabs
          variant="fullWidth"
          value={tabValue}
          onChange={handleTabViewChange}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <StyledTab label="Global Compliance Rules"  />
          <StyledTab label="Course based Compliance Rules" />
        </StyledTabs>
      </AppBar>
        <TabPanel value={tabValue} index={0}>
          <GlobalComplianceRules />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <CourseBasedComplianceRules  />
        </TabPanel>
    </>
  );
};

export default ComplianceRules;
