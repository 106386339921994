import React, { useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { LearningModule } from "../../globalTypes";
//Contexts
import { SessionContext } from "../../contexts/SessionContext";
import { TranslationsContext } from "../../contexts/TranslationsContext";

//Components
import CustomTooltip from "../../Components/CustomTooltip";
//MUI Components
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import ReplayIcon from "@material-ui/icons/Replay";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
//Styles
import styles from "../../styles/learningModuleList.module.css";

interface Props {
    learningModule: LearningModule;
    logNavigation: (message: string) => void;
    setSASelectedModule: (selectedModule: LearningModule) => void;
    setReviewModalOpen: (reviewModalOpen: boolean) => void;
    setRestartModalOpen: (restartModalOpen: boolean) => void; 
}

const ActionButtons = ({learningModule, logNavigation, setSASelectedModule, setReviewModalOpen, setRestartModalOpen }: Props) => {
    const { translations: { allResourcesToTranslations },} = useContext(TranslationsContext);
    const { setSaReviewOnlyValue, setSaIsRestarted, multiSectionCurrentSelection, setMultiSectionCurrentSelectionOnQuizOpen } = useContext(SessionContext);
    const history = useHistory();
    const { name, courseID, lessonType, quizType, showReviewRestart } = learningModule;

    const handleSAReviewClick = () => {
        logNavigation(`Reviewing ${name} Lesson & Quiz (${quizType})`)
        setSASelectedModule(learningModule)
        setReviewModalOpen(true)
      }
  
      const handleSARestartClick = () => {
        logNavigation(`Restarting ${name} Lesson & Quiz (${quizType})`)
        setSASelectedModule(learningModule)
        setRestartModalOpen(true)
      }

	  let ticks = Math.floor(Date.now()/1000);
  
      const handleSAFirstTimeLaunch = () => {
        logNavigation(`Starting ${name} Lesson & Quiz (${quizType})`)
        setSASelectedModule(learningModule);
        setSaReviewOnlyValue(0);
		setSaIsRestarted(1);
        history.push({
          pathname: `${courseID}/quiz/${learningModule.id}/${ticks}`,
          state: {
            learningModule: learningModule,
            moduleType: lessonType,
            isLesson: false,
          },
        });
      }

	if (quizType === "SA") {
		if (showReviewRestart) {
			return (
				<>
					<CustomTooltip displayText={[allResourcesToTranslations.review]}>
						<Button onClick={() => handleSAReviewClick()} className={styles.moduleListButton} variant="contained" color="primary">
							<PlayArrowIcon />
							<SearchIcon />
						</Button>
					</CustomTooltip>
					<CustomTooltip displayText={[allResourcesToTranslations.restart]}>
						<Button onClick={() => handleSARestartClick()} className={styles.moduleListButton} variant="contained" color="primary">
							<PlayArrowIcon />
							<ReplayIcon />
						</Button>
					</CustomTooltip>
				</>
			);
		} else {
			return (
				<CustomTooltip displayText={[allResourcesToTranslations.start]}>
					<Button onClick={() => handleSAFirstTimeLaunch()} className={styles.moduleListButton} variant="contained" color="primary">
						<PlayArrowIcon />
					</Button>
				</CustomTooltip>
			);
		}
	} else {
		return (
			<Link
				onClick={() => { 
					logNavigation(`Started ${name} Quiz - Type is ${quizType}`);
					if (!!learningModule && !!learningModule.quizInfo && 
						learningModule.quizInfo.trim().toLowerCase().includes("<%$%>ismultisection<:#:>yes<%$%>sectionscostartlist<:#:>") &&
						(!!multiSectionCurrentSelection === false || multiSectionCurrentSelection.trim() !== "Section 1")) {
							setMultiSectionCurrentSelectionOnQuizOpen("Section 1"); // By default start with Section 1
					}
				}}
				to={{
					pathname: `${courseID}/quiz/${learningModule.id}/${ticks}`,
					state: {
						learningModule: learningModule,
						moduleType: quizType,
						isLesson: false,
					},
				}}
			>
				<CustomTooltip displayText={[allResourcesToTranslations.start]}>
					<Button className={styles.moduleListButton} variant="contained" color="primary">
						<PlayArrowIcon />
					</Button>
				</CustomTooltip>
			</Link>
		);
	}
};

export default ActionButtons;
