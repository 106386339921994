import React, { useState, useEffect, useContext } from "react";

//Styles
import styles from "../../../styles/organisationmanagement.module.css";

import { useHistory, useLocation, useParams } from "react-router";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { UIContext } from "../../../contexts/UIContext";
import LoaderContainer from "../../../Components/LoaderContainer";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import BrandedCard from "../../../Components/BrandedCard";
import { Steps } from "intro.js-react";
import CustomTooltip from "../../../Components/CustomTooltip";
import GuideIcon from "../../../CustomIcons/GuideIcon";
import { Button, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import UnbrandedCard from "../../../Components/UnbrandedCard";
import getColor from "../../../functions/getColor";
import { CorperateThemeContext } from "../../../contexts/CorperateThemeContext";
import UpArrowIcon from "../../../CustomIcons/UpArrowIcon";
import DownArrowIcon from "../../../CustomIcons/DownArrowIcon";
import EditIcon from "../../../CustomIcons/SupportDetailsEditIcon"; 
import { CPDProfile } from "../../../globalTypes";
import GetCPDProfilesList from "../../../API/Administration/CPDProfiles/GetCPDProfilesList";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";

const CPDProfilesList = () => {
  const history = useHistory();
  const {
    translations: { allResourcesToTranslations }, pageTitles
  } = useContext(TranslationsContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute } =
    useContext(UIContext);
    const { oidcUser } = useReactOidc();
    const {
      profile: { user_id, website },
    } = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const orgDomain = website
      ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
      : "";
  const [loading, setLoading] = useState<boolean>(true);
  const { theme: { color } } = useContext(CorperateThemeContext);
  const [cpdProfilesResult, setCPDProfilesResult] = useState<Array<CPDProfile>>([]);
  const [cpdProfilesResultInitial, setCPDProfilesResultInitial] = useState<Array<CPDProfile>>([]);

  const [currentSortBy, setCurrentSortBy] = React.useState<string>("")
  const [reverseNextSort, setReverseNextSort] = React.useState<boolean>(false)
  const [sortInProgress, setSortInProgress] = React.useState<boolean>(false)
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);
  
  const [filterProfileName, setFilterProfileName] = useState<string>("");
  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true)
  const [profileNameDescending, setProfileNameDescending] = React.useState<boolean>(false);
  const [currentPeriodDescending, setCurrentPeriodDescending] = React.useState<boolean>(false);  
  const [futurePeriodDescending, setFuturePeriodDescending] = React.useState<boolean>(false);  
  const [profileStatusDescending, setProfileStatusDescending] = React.useState<boolean>(false); 
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [showConfirmModal, setshowConfirmModal] = useState<boolean>(false);  

  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element:  ".addNewProfile",
        title: "Add New", intro: allResourcesToTranslations.addprofileguideme 
      }
    ]
});


const setGuideMe = () => {
  let updateSteps = [...intro.steps];
      updateSteps.push(        
      {
        element:  "#profileNameFilter",
        title: allResourcesToTranslations.profilenamefilter, intro: allResourcesToTranslations.profilenamefilterguideme
      },         
      {
        element:  ".profilenamecolumnguideme",
        title: allResourcesToTranslations.profilename, intro: allResourcesToTranslations.profilenamecolumnguideme
      },      
      {
        element:  ".currentperiodguideme",
        title: allResourcesToTranslations.currentperiod, intro: allResourcesToTranslations.currentperiodguideme
      },
      {
        element:  ".futureperiodguideme",
        title: allResourcesToTranslations.futureperiod, intro: allResourcesToTranslations.futureperiodguideme
      },
      {
        element:  ".profilestatusguideme",
        title: allResourcesToTranslations.status, intro: allResourcesToTranslations.profilestatusguideme2
      },
      {
        element:  ".profileactioncolumnguideme0",
        title: "Edit Profile", intro: allResourcesToTranslations.profileactioncolumnguideme
      });
  
  
  setIntro({ ...intro, steps: [...updateSteps] });
  };  

  const handleClosePopup = () => {
		setShowWarningModal(false);
	};
 
  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };
  
  useEffect(() => {
    setTitle(allResourcesToTranslations.cpdprofile); /* Removed capital letter */
    setTitleEnglish(pageTitles.cpdprofile);
    updateBackButtonRoute("");   
    LoadCPDProfiles();
  }, []);

  useEffect(() => {
    if (filterProfileName == null || filterProfileName == undefined){
      return;
    }
    setCPDProfilesResult(!!cpdProfilesResultInitial !== true ? [] : cpdProfilesResultInitial.filter(c => !!c && !!c.profileName && c.profileName.toLowerCase().includes(filterProfileName.toLowerCase())));   
  },[filterProfileName])

  const LoadCPDProfiles = () => {
    GetCPDProfilesList(oidcUser.access_token,
      userId,
      orgDomain,
      parseInt(orgId),"en-au").then((result:any) => {       
        if (result.isSuccess && (result.error == null || result.error.trim() == "")) {
          setCPDProfilesResult([...result.cpdProfiles]);   
          setCPDProfilesResultInitial([...result.cpdProfiles]);   
          setLoading(false);
          setGuideMe();    
        } else {
          setShowWarningModal(true);
          setHasErrored(true);
          setSaveSuccess(false);
          setModalPopupMessage('Getting CPD profiles list has failed! Please try again!');
        }
      });
  }

  const handleEdit = (profileID: number, profilePeriodID: number) => {
    history.push({
      pathname: `cpdprofile/`+ profileID.toString() + `/` + profilePeriodID.toString()
  });
};

  const handleAddNewProfile = () => {
    history.push({
      pathname: `cpdprofile/-1/-1` 
  });
  }
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const sortResultsByAsync = async (sortBy: 
    "ProfileName" 
    | "CurrentPeriod"
    | "FuturePeriod"
    | "ProfileStatus" , sortGivenItemInDesc: boolean) => {
    if (cpdProfilesResult == null || cpdProfilesResult.length <= 0) {
      return cpdProfilesResult;
    }
    var result = cpdProfilesResult.slice(0)
    setCurrentSortBy(sortBy)
    switch (sortBy) {
  
      case "ProfileName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.profileName === null) { return -1; }
            if (a === null || a.profileName === null) { return 1; }
            if (a.profileName.toLowerCase() > b.profileName.toLowerCase()) { return -1; }
            if (a.profileName.toLowerCase() < b.profileName.toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setProfileNameDescending(false)
          }
        } else {
          result.sort((a, b) => { 
            if (b === null || b.profileName === null) { return 1; }
            if (a === null || a.profileName === null) { return -1; }
            if (a.profileName.toLowerCase() < b.profileName.toLowerCase()) { return -1; }
            if (a.profileName.toLowerCase() > b.profileName.toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setProfileNameDescending(true)
          }
        }
        setCPDProfilesResult(result);
        break;
     
      case "CurrentPeriod":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.dateStart === null) { return -1; }
            if (a === null || a.dateStart === null) { return 1; }
            if (a.dateStart > b.dateStart) { return -1; }
            if (a.dateStart < b.dateStart) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setCurrentPeriodDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.dateStart === null) { return 1; }
            if (a === null || a.dateStart === null) { return -1; }
            if (a.dateStart < b.dateStart) { return -1; }
            if (a.dateStart > b.dateStart) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setCurrentPeriodDescending(true)
          }
        }
        setCPDProfilesResult(result);
        break;
     
        case "FuturePeriod":
          if (sortGivenItemInDesc) {
            result.sort((a, b) => {
              if (b === null || b.futureDateStart === null) { return -1; }
              if (a === null || a.futureDateStart === null) { return 1; }
              if (a.futureDateStart > b.futureDateStart) { return -1; }
              if (a.futureDateStart < b.futureDateStart) { return 1; }
              return 0;
            })
            if (reverseNextSort) {
              setFuturePeriodDescending(false)
            }
          } else {
            result.sort((a, b) => {
              if (b === null || b.futureDateStart === null) { return 1; }
              if (a === null || a.futureDateStart === null) { return -1; }
              if (a.futureDateStart < b.futureDateStart) { return -1; }
              if (a.futureDateStart > b.futureDateStart) { return 1; }
              return 0;
            })
            if (reverseNextSort) {
              setFuturePeriodDescending(true)
            }
          }
          setCPDProfilesResult(result);
          break;
      
      case "ProfileStatus":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.profileStatus === null) { return -1; }
            if (a === null || a.profileStatus === null) { return 1; }
            if (a.profileStatus.toString().toLowerCase() > b.profileStatus.toString().toLowerCase()) { return -1; }
            if (a.profileStatus.toString().toLowerCase() < b.profileStatus.toString().toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setProfileStatusDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.profileStatus === null) { return 1; }
            if (a === null || a.profileStatus === null) { return -1; }
            if (a.profileStatus.toString().toLowerCase() < b.profileStatus.toString().toLowerCase()) { return -1; }
            if (a.profileStatus.toString().toLowerCase() > b.profileStatus.toString().toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setProfileStatusDescending(true)
          }
        }
        setCPDProfilesResult(result);
      break;
         
      default:
        break;
    }
    return result.slice(0);
  }
  
  const sortResultsBy = async (sortBy: 
    "ProfileName" 
    | "CurrentPeriod"
    | "FuturePeriod"
    | "ProfileStatus" , sortGivenItemInDesc: boolean) => {
    setSortInProgress(true);
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy)
      setCPDProfilesResult(result)
      setPage(0)
      setSortInProgress(false);
    });
  }
  
  const clickProfileNameAsc = () => {
    setReverseNextSort(false);
    setProfileNameDescending(false);
    sortResultsBy("ProfileName", false);
  }
  
  const clickProfileNameDesc = () => {
    setReverseNextSort(false);
    setProfileNameDescending(true)
    sortResultsBy("ProfileName", true)
  }

  const clickCurrentPeriodAsc = () => {
    setReverseNextSort(false);
    setCurrentPeriodDescending(false);
    sortResultsBy("CurrentPeriod", false);
  }
  
  const clickCurrentPeriodDesc = () => {
    setReverseNextSort(false);
    setCurrentPeriodDescending(true)
    sortResultsBy("CurrentPeriod", true)
  }

  const clickFuturePeriodAsc = () => {
    setReverseNextSort(false);
    setFuturePeriodDescending(false);
    sortResultsBy("FuturePeriod", false);
  }
  
  const clickFuturePeriodDesc = () => {
    setReverseNextSort(false);
    setFuturePeriodDescending(true)
    sortResultsBy("FuturePeriod", true)
  }

  const clickProfileStatusAsc = () => {
    setReverseNextSort(false);
    setProfileStatusDescending(false);
    sortResultsBy("ProfileStatus", false);
  }
  
  const clickProfileStatusDesc = () => {
    setReverseNextSort(false);
    setProfileStatusDescending(true)
    sortResultsBy("ProfileStatus", true)
  }
  
  

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" }
    } else {
      return {}
    }
  }

  const handleConfirmModal = (value: boolean) => {
		setshowConfirmModal(value);
	}
  
  if (loading) return <LoaderContainer />;  
  return ( 
   <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }}/>
      <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific}>
          <GuideIcon />
        </span>
      </CustomTooltip>   

      <UnbrandedCard>
        <div className={styles.mainWrapper}>
            <div className={styles.Wrapper}>             
              <div style={{ width: '100%' }} >
                <div style={{float:"left"}}>
                  <CustomTooltip displayText={[allResourcesToTranslations.addnewprofile]}>               
                      <Button                    
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={`saveData addNewProfile`}
                          onClick={handleAddNewProfile}                     
                        >
                          <span style={{marginBottom: "-5px"}}>
                            <EditIcon/>
                          </span><span style={{marginLeft: "5px"}}> {allResourcesToTranslations.addnewprofile}</span>
                        </Button>                     
                  </CustomTooltip>
                </div>
                <div style={{float:"right", width: "45%"}}>
                  <div key="profileNameSearch" id="profileNameSearch" style={{padding: '2px', flexGrow: 1, width: "100%"}}>
                    <TextField style={{width: "100%"}} id="profileNameFilter"
                      label={allResourcesToTranslations.profilenamefilter} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                      value={filterProfileName} onChange={(e) => setFilterProfileName(e.target.value)} 
                      InputProps={{endAdornment: ( <IconButton><SearchIcon style={{height: "20px"}} /></IconButton> )}}/>
                  </div>
                </div>
              </div>            
                        
              <div style={{ width: '100%', clear:"both" }} >
              {cpdProfilesResult != null && cpdProfilesResult.length > 0 && (
                <div>              

                  <div className={styles.paginationControlsContainer}>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '90%' }}>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 100]}
                        component="div"
                        count={cpdProfilesResult.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'right', width: '10%', fontSize: '0.875rem', padding:'18px', fontFamily: '"Open Sans","Segoe UI",sans-serif', fontWeight: 400, lineHeight: 1.43}}>
                      {!!cpdProfilesResult ? cpdProfilesResult.length.toString() + " Profiles" : ""}
                    </div>
                  </div>
                  <div  className={`${styles.tableSection}`}>
                    <div className={styles.tableContainer}>
                      <BrandedCard>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>                              

                                <TableCell
                                  style={{
                                    color: getColor(color.substring(1)),
                                    backgroundColor: color,
                                    borderRadius: "8px 0 0 0",
                                  }}   className="profilenamecolumnguideme"
                                  align="left"
                                >
                                  <div className={styles.tableHeadCellContainer}>
                                    <div
                                      onClick={() => {
                                        setProfileNameDescending(!profileNameDescending);
                                        sortResultsBy("ProfileName", !profileNameDescending);
                                      }}
                                    >
                                    {allResourcesToTranslations.profilename}
                                    </div>
                                    <div className={styles.columnHeadArea}>
                                      <span
                                        style={{
                                          opacity:
                                            currentSortBy === "ProfileName" &&
                                            profileNameDescending === false
                                              ? "100%"
                                              : "50%",
                                          margin: "2px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickProfileNameAsc}
                                      >
                                        <UpArrowIcon />{" "}
                                      </span>
                                      <span
                                        style={{
                                          opacity:
                                            currentSortBy === "ProfileName" &&
                                            profileNameDescending === true
                                              ? "100%"
                                              : "50%",
                                          margin: "0px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickProfileNameDesc}
                                      >
                                        <DownArrowIcon />{" "}
                                      </span>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: getColor(color.substring(1)),
                                    backgroundColor: color,
                                  }} className="currentperiodguideme"
                                  align="left"
                                >
                                  <div className={styles.tableHeadCellContainer}>
                                  <div
                                      onClick={() => {
                                        setCurrentPeriodDescending(!currentPeriodDescending);
                                        sortResultsBy("CurrentPeriod", !currentPeriodDescending);
                                      }}
                                    >
                                    {allResourcesToTranslations.currentperiod}
                                    </div>
                                    <div className={styles.columnHeadArea}>
                                      <span
                                        style={{
                                          opacity:
                                            currentSortBy === "CurrentPeriod" &&
                                            currentPeriodDescending === false
                                              ? "100%"
                                              : "50%",
                                          margin: "2px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickCurrentPeriodAsc}
                                      >
                                        <UpArrowIcon />{" "}
                                      </span>
                                      <span
                                        style={{
                                          opacity:
                                            currentSortBy === "CurrentPeriod" &&
                                            currentPeriodDescending === true
                                              ? "100%"
                                              : "50%",
                                          margin: "0px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickCurrentPeriodDesc}
                                      >
                                        <DownArrowIcon />{" "}
                                      </span>
                                    </div>
                              
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: getColor(color.substring(1)),
                                    backgroundColor: color,
                                  }} className="futureperiodguideme"
                                  align="left"
                                >
                                  <div className={styles.tableHeadCellContainer}>
                                  <div
                                      onClick={() => {
                                        setFuturePeriodDescending(!futurePeriodDescending);
                                        sortResultsBy("FuturePeriod", !futurePeriodDescending);
                                      }}
                                    >
                                    {allResourcesToTranslations.futureperiod}
                                    </div>
                                    <div className={styles.columnHeadArea}>
                                      <span
                                        style={{
                                          opacity:
                                          currentSortBy === "FuturePeriod" &&
                                            futurePeriodDescending === false
                                              ? "100%"
                                              : "50%",
                                          margin: "2px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickFuturePeriodAsc}
                                      >
                                        <UpArrowIcon />{" "}
                                      </span>
                                      <span
                                        style={{
                                          opacity:
                                          currentSortBy === "FuturePeriod" &&
                                            futurePeriodDescending === true
                                              ? "100%"
                                              : "50%",
                                          margin: "0px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickFuturePeriodDesc}
                                      >
                                        <DownArrowIcon />{" "}
                                      </span>
                                    </div>
                              
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: getColor(color.substring(1)),
                                    backgroundColor: color,
                                  }} className="profilestatusguideme"
                                  align="left"
                                >
                                  <div className={styles.tableHeadCellContainer}>
                                  <div
                                      onClick={() => {
                                        setProfileStatusDescending(!profileStatusDescending);
                                        sortResultsBy("ProfileStatus", !profileStatusDescending);
                                      }}
                                    >
                                    {allResourcesToTranslations.status}
                                    </div>
                                    <div className={styles.columnHeadArea}>
                                      <span
                                        style={{
                                          opacity:
                                            currentSortBy === "ProfileStatus" &&
                                            profileStatusDescending === false
                                              ? "100%"
                                              : "50%",
                                          margin: "2px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickProfileStatusAsc}
                                      >
                                        <UpArrowIcon />{" "}
                                      </span>
                                      <span
                                        style={{
                                          opacity:
                                            currentSortBy === "ProfileStatus" &&
                                            profileStatusDescending === true
                                              ? "100%"
                                              : "50%",
                                          margin: "0px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickProfileStatusDesc}
                                      >
                                        <DownArrowIcon />{" "}
                                      </span>
                                    </div>
                              
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "200px",
                                    color: getColor(color.substring(1)),
                                    backgroundColor: color,
                                    borderRadius: "0 8px 0 0",
                                  }}
                                  align="center"
                                >
                                  <div className={styles.tableHeadCellContainer}>
                                    <div>
                                      {allResourcesToTranslations.actions}
                                    </div>                              
                                  </div>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {cpdProfilesResult
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((result: CPDProfile, i) => {
                                  return (
                                    <TableRow
                                      key={`cpdprofiles-${i}`}
                                      style={rowBackgroundColor(i)}
                                    >
                                      <TableCell align="left" width={"40%"}>
                                        {result.profileName}
                                      </TableCell>
                                      
                                      <TableCell align="left" className="dateEnabledToggele">
                                        {result.currentPeriod}
                                      </TableCell>
                                      
                                      <TableCell align="left" className="dateEnabledToggele">
                                        {result.futurePeriod}
                                      </TableCell>
                                      
                                      <TableCell align="left" className="dateEnabledToggele">
                                        {result.profileStatus}
                                      </TableCell>

                                      {/* <TableCell align="left" className="dateEnabledToggele">
                                        <a href={`${result.strPolicyLink}`} target="_blank">
                                        {`${result.policyFileName}` + ` (` + `${result.policyFileSize})`}</a>
                                      </TableCell>

                                      <TableCell align="center" className="dateEnabledToggele">
                                          <Checkbox
                                                    size="small"
                                                    color="primary"
                                                    checked={result.active}
                                                    className={styles.checkBox}			
                                                    disabled																						
                                                  />
                                      </TableCell> */}

                                      <TableCell align="center" className="dateEnabledToggele">
                                        <CustomTooltip displayText={["Edit"]}>
                                            <Button onClick={() => handleEdit(result.profileID, result.profilePeriodID)} 
                                                    className={`${styles.moduleListButton} profileactioncolumnguideme${i}`} 
                                                    variant="contained" color="primary">
                                                <EditIcon />
                                            </Button>
                                        </CustomTooltip>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </BrandedCard>
                    </div>
                  </div>
                  <div className={styles.paginationAddedUsersControl}>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '90%' }}>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 100]}
                        component="div"
                        count={cpdProfilesResult.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </div>
                  </div>               
                </div>
              )}
            </div>
          </div>
        </div>
        </UnbrandedCard>
        {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}      
      </>
  );
};

export default CPDProfilesList;
