import React, { useContext } from 'react'
//Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Context
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { UIContext } from '../../contexts/UIContext';
import oidcConfig from '../../configuration';
//API Functions
import { addError } from '../../API/AddError'
//Components 
import CustomTooltip from '../../Components/CustomTooltip';
import BrandedCard from '../../Components/BrandedCardDashBoard';
//MUI Components
import Button from '@material-ui/core/Button';
import PersonalDataIcon from '../../CustomIcons/PersonalDataIcon';
// Styles
import styles from '../../styles/dashboard.module.css'

interface Props {
    isAdmin: boolean
}

export const PrivacyFeatureCard = ({ isAdmin }: Props) => {
    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
    const { showSSOExitButton, EnableActivityLogs} = useContext(UIContext);
    const { oidcUser } = useReactOidc();
    const { profile: { user_id, website }} = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const orgDomainWithAltLogin = window.location.host.toLowerCase();

   

    const handleOpenprivacyLink = () => {
        const adminConsoleUrl = "https://www.grcsolutions.com.au/privacy-policy/";
        addError(oidcUser.access_token, userId, orgDomain, "Opened Privacy feature", null, "Dashboard.tsx", 6, EnableActivityLogs)
        window.open(adminConsoleUrl, "_blank") //TODO need to redirect user to correct admin consol
    }

   
    return (
        
		<BrandedCard Width='100'>       
        <div className="PrivacyFeature">
        	<div className={styles.showPrivacyFeature}>
                <p style={{height:"45px"}}>
	                {allResourcesToTranslations.saltpersonaldata}
	            </p>
	            <CustomTooltip displayText={[allResourcesToTranslations.saltpersonaldata]}>
	                <Button variant="contained" color="primary" onClick={() => handleOpenprivacyLink()}>
	                    <PersonalDataIcon />
	                </Button>
	            </CustomTooltip>
	        </div>
	    </div>
        </BrandedCard>
       
    )
}

export default PrivacyFeatureCard;