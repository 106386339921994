import { createMuiTheme } from "@material-ui/core"


const theme = createMuiTheme({
    typography: {
      fontFamily: [
        '"Open Sans"',
        '"Segoe UI"',
        'sans-serif',
      ].join(','),
    },
    palette: {
      primary: {
        light: "#83CEFF",
        main: "#63C2FF",
        dark: "#58ABE0",
        contrastText: "#ffffff"
      },
      secondary: {
        light: "magenta",
        main: "#ff0000", //Put something real here
        darl: "maroon",
        contrastText: "#fff"
      },
      error: { // These are default colors to be updated
        contrastText: "#fff",
        dark: "#d32f2f",
        light: "#e57373",
        main: "#f44336",
      }
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 8,
          margin: "5px",
          fontWeight: "700",
          // width: "80px"
          minWidth:"0px"
        },
        outlinedPrimary: {
          // color: "#21394F",
          borderWidth: "2px",
          '&:hover': {
            borderWidth: "2px"
          }
        } 
      },
      MuiButtonGroup: {
         root:{
          //  margin: "15px"
          
         },
        contained: {
          boxShadow: "none"
        }
      },
      MuiTableRow: {
        head: {
          borderBottom: "2px solid #c4c4c4"
        }
      },
      MuiTableCell: {
        root: {
          padding: "10px",
          color: "#21394F"
        },
        head: {
          fontWeight: 700,
          padding: "10px",
          color: "#21394F"
        }
      },
      MuiListItem: {
        root: {
          color: "#21394F"
        }
      },
      MuiNativeSelect: {
        root: {
          color: "#21394F",
          padding: "0 0 10px 10px",
          fontSize:"0.875rem"
        }
      }      
    },
  })

export default theme;