import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const MoveIcon = () => (    

<svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 25.5737L16 29.5737L12 25.5737" stroke="#21394F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.3334 12.2404L29.3334 16.2404L25.3334 20.2404" stroke="#21394F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.66663 12.2404L2.66663 16.2404L6.66663 20.2404" stroke="#21394F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.66663 16.2404H29.3333" stroke="#21394F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 6.90698L16 2.90698L20 6.90698" stroke="#21394F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16 2.90698V29.5736" stroke="#21394F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);

export default MoveIcon
