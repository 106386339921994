import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const PDFFileIcon = () => (
  // <SvgIcon>
  //   <path d="M19.5,2h-15C3.1,2,2,3.1,2,4.5v15C2,20.9,3.1,22,4.5,22h15c1.4,0,2.5-1.1,2.5-2.5v-15C22,3.1,20.9,2,19.5,2z M16.5,17.5
  //       c-0.6,0-1.2,0-1.8,0c-0.1,0-0.2,0-0.3-0.2c-0.7-1.3-1.5-2.5-2.2-3.8c0-0.1-0.1-0.1-0.1-0.2c0,0.1-0.1,0.1-0.1,0.2
  //       c-0.7,1.3-1.5,2.5-2.2,3.8c-0.1,0.1-0.1,0.2-0.3,0.2c-0.6,0-1.2,0-1.8,0c-0.1,0-0.1,0-0.2,0c0-0.1,0.1-0.1,0.1-0.2
  //       c1.1-1.7,2.2-3.4,3.3-5.1c0.1-0.1,0.1-0.2,0-0.3c-1.1-1.7-2.2-3.4-3.3-5.1c0-0.1-0.1-0.1-0.1-0.2c0.5,0,0.9,0,1.4,0
  //       c0.2,0,0.4,0,0.6,0c0.1,0,0.2,0,0.3,0.2c0.7,1.3,1.5,2.5,2.2,3.8c0,0.1,0.1,0.1,0.1,0.2c0-0.1,0.1-0.1,0.1-0.2
  //       c0.7-1.3,1.5-2.5,2.2-3.8c0.1-0.1,0.1-0.2,0.3-0.2c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0c0,0.1-0.1,0.1-0.1,0.2
  //       c-1.1,1.7-2.2,3.4-3.3,5.1c-0.1,0.1-0.1,0.2,0,0.3c1.1,1.7,2.2,3.4,3.3,5.1c0,0.1,0.1,0.1,0.1,0.2C16.6,17.5,16.5,17.5,16.5,17.5z"
  //       />
  // </SvgIcon>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2.47866 8.51123e-05C1.11227 8.51123e-05 0 1.11315 0 2.47949V21.5206C0 22.8869 1.11227 24 2.47866 24H17.0388C18.4052 24 19.5174 22.887 19.5174 21.5206V5.86351C19.5174 5.86351 19.5564 5.40504 19.3574 4.9589C19.1709 4.5407 18.8668 4.24964 18.8668 4.24964C18.866 4.24861 18.8653 4.24758 18.8645 4.24656L15.2521 0.703259C15.2501 0.701238 15.2481 0.699237 15.2461 0.697257C15.2461 0.697257 14.9377 0.403575 14.4805 0.207386C13.9422 -0.0236164 13.3873 0.000768945 13.3873 0.000768945L13.3955 0L2.47866 8.51123e-05Z" fill="#FF2116"/>
  <path d="M2.47901 0.814819H13.3959C13.3987 0.814847 13.4014 0.814847 13.4042 0.814819C13.4042 0.814819 13.8407 0.81905 14.16 0.95607C14.4673 1.08795 14.6853 1.28758 14.6867 1.28892C14.6867 1.28892 14.6882 1.29045 14.6882 1.29045L18.2916 4.82548C18.2916 4.82548 18.5087 5.05469 18.6139 5.29057C18.6989 5.48095 18.7041 5.82928 18.7041 5.82928C18.7037 5.83503 18.7035 5.84078 18.7033 5.84654V21.5209C18.7033 22.4503 17.9685 23.1859 17.0391 23.1859H2.47901C1.54964 23.1859 0.814796 22.4503 0.814796 21.5209V2.47981C0.814796 1.5504 1.54964 0.814849 2.47901 0.814849V0.814819Z" fill="#F5F5F5"/>
  <path d="M4.87325 14.2889C4.31288 13.7286 4.91916 12.9586 6.5645 12.1409L7.59978 11.6264L8.0031 10.7439C8.22493 10.2586 8.55601 9.46664 8.73883 8.98408L9.07123 8.10668L8.84217 7.45737C8.56051 6.65897 8.46008 5.45917 8.63878 5.02775C8.88083 4.44338 9.67369 4.50334 9.98758 5.12975C10.2327 5.61898 10.2077 6.50495 9.91707 7.6223L9.67884 8.53832L9.88868 8.89455C10.0041 9.09048 10.3412 9.5557 10.6379 9.92837L11.1954 10.6218L11.8891 10.5313C14.0929 10.2437 14.8478 10.7325 14.8478 11.4333C14.8478 12.3178 13.1173 12.3908 11.6641 11.3701C11.3371 11.1405 11.1126 10.9124 11.1126 10.9124C11.1126 10.9124 10.2022 11.0977 9.75401 11.2185C9.29134 11.3432 9.06052 11.4213 8.3828 11.6501C8.3828 11.6501 8.14501 11.9954 7.99006 12.2464C7.41358 13.1804 6.74048 13.9544 6.25978 14.2361C5.72157 14.5515 5.15733 14.573 4.87325 14.2889V14.2889ZM5.75266 13.9748C6.06765 13.7801 6.70519 13.0261 7.14652 12.3262L7.3252 12.0429L6.51161 12.4519C5.25502 13.0837 4.68016 13.6792 4.97908 14.0394C5.14701 14.2417 5.3479 14.2249 5.75266 13.9748V13.9748ZM13.9149 11.6837C14.223 11.4679 14.1783 11.033 13.83 10.8577C13.5589 10.7212 13.3404 10.6932 12.6362 10.7036C12.2035 10.7331 11.5077 10.8203 11.3897 10.8468C11.3897 10.8468 11.772 11.111 11.9417 11.2082C12.1676 11.3372 12.7167 11.5767 13.1175 11.6994C13.5129 11.8204 13.7416 11.8077 13.9149 11.6837V11.6837ZM10.633 10.3196C10.4465 10.1236 10.1295 9.71453 9.92842 9.41054C9.66548 9.0657 9.53362 8.82241 9.53362 8.82241C9.53362 8.82241 9.34143 9.44057 9.18376 9.81256L8.69184 11.0285L8.54922 11.3043C8.54922 11.3043 9.30745 11.0556 9.69323 10.9549C10.1018 10.8481 10.9311 10.6851 10.9311 10.6851L10.633 10.3196ZM9.57533 6.0782C9.62289 5.67909 9.64308 5.28057 9.51485 5.07961C9.15917 4.69078 8.72987 5.01503 8.80259 5.93944C8.82705 6.25042 8.90431 6.78203 9.00759 7.10969L9.19538 7.70542L9.32755 7.25674C9.40025 7.00996 9.51175 6.47962 9.57533 6.0782V6.0782Z" fill="#FF2116"/>
  <path d="M5.82629 16.8848H6.73606C7.02675 16.8848 7.26272 16.9126 7.44396 16.9682C7.6252 17.022 7.77772 17.1432 7.90154 17.3316C8.02535 17.5182 8.08726 17.7434 8.08726 18.0072C8.08726 18.2494 8.03702 18.4576 7.93653 18.6316C7.83604 18.8057 7.70056 18.9313 7.53009 19.0085C7.36142 19.0856 7.10122 19.1242 6.74952 19.1242H6.4346V20.5588H5.82629V16.8848ZM6.4346 17.3558V18.637H6.73606C7.00522 18.637 7.19095 18.5868 7.29323 18.4863C7.3973 18.3858 7.44934 18.2225 7.44934 17.9964C7.44934 17.8277 7.41525 17.6914 7.34706 17.5873C7.27887 17.4814 7.20351 17.4168 7.12096 17.3935C7.04021 17.3684 6.91191 17.3558 6.73606 17.3558L6.4346 17.3558Z" fill="#2C2C2C"/>
  <path d="M8.55303 16.885H9.37936C9.77952 16.885 10.0989 16.9559 10.3376 17.0976C10.578 17.2394 10.7593 17.4502 10.8813 17.7302C11.0051 18.0101 11.067 18.3205 11.067 18.6615C11.067 19.0204 11.0114 19.3407 10.9001 19.6224C10.7907 19.9023 10.622 20.1284 10.3941 20.3007C10.168 20.473 9.84502 20.5591 9.42512 20.5591H8.55303V16.885ZM9.16134 17.3722V20.0719H9.41435C9.76786 20.0719 10.0245 19.9499 10.1842 19.7058C10.3439 19.46 10.4237 19.1325 10.4237 18.7234C10.4237 17.8226 10.0873 17.3722 9.41435 17.3722H9.16134Z" fill="#2C2C2C"/>
  <path d="M11.6509 16.885H13.6912V17.3722H12.2592V18.4704H13.4059V18.9576H12.2592V20.5591H11.6509V16.885Z" fill="#2C2C2C"/>
  </svg>
  
);

export default PDFFileIcon