import React, { useContext } from 'react'
import Modal from '../Components/Modal';
import styles from '../styles/supportModal.module.css'
import { TranslationsContext } from '../contexts/TranslationsContext';

interface Props {
    showModal: boolean
    setShowModal: (isOpen: boolean) => any
}

const TermsAndConditionsModal = ({ showModal, setShowModal }: Props) => {
    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext) 
     
    return (
        <div>
             <Modal modalOpen={showModal} setModalOpen={setShowModal}>
                <div className={styles.modalContentWrapper}>                   
                    <div className={styles.termsAndConditionsText}>                        
                        <div>
                            <h5 className={styles.tandctitle}>{allResourcesToTranslations.authorisedusertermsofuse }</h5> 
                        </div>
                        <div className={styles.tandccontent}>
                            <h5>{allResourcesToTranslations.currency }</h5>
                            <p>{allResourcesToTranslations.welcomebyusingtheapplication}</p>
                            <h5>{allResourcesToTranslations.licence}</h5>
                            <p>{allResourcesToTranslations.grcsolutionshasgranted}</p>
                            <h5>{allResourcesToTranslations.account}</h5>
                            <p>{allResourcesToTranslations.yourusernameandpassword}</p>
                            <h5>{allResourcesToTranslations.restrictions}</h5>
                            <p>{allResourcesToTranslations.youmustnot}</p>
                            <ol>
                                <li>{allResourcesToTranslations.sublicenseassignortransfer}</li>
                                <li>{allResourcesToTranslations.reproducethisapplication}</li>
                                <li>{allResourcesToTranslations.altermodifyormerge}</li>
                                <li>{allResourcesToTranslations.excepttotheextent}</li>
                                <li>{allResourcesToTranslations.causeorpermit}</li>
                            </ol>
                            <h5>{allResourcesToTranslations.copyright}</h5>
                            <p>{allResourcesToTranslations.thisapplicationis}</p>
                            <h5>{allResourcesToTranslations.trademarks}</h5>
                            <p>{allResourcesToTranslations.alltrademarks}</p>
                            <ul>
                                <li>{allResourcesToTranslations.governaceriskandcompliance}</li>
                                <li>GRC Solutions</li>
                                <li> Salt Compliance</li>
                            </ul>
                            <p>{allResourcesToTranslations.nothingcontainedonthe}</p>
                            <p>{allResourcesToTranslations.ifyouuseanyofourtrademarks}</p>
                            <ul>
                                <li>{allResourcesToTranslations.inorasthewholepart}</li>
                                <li>{allResourcesToTranslations.inconnectionwithactivities}</li>
                                <li>{allResourcesToTranslations.inamannerwhichmaybeconfusing}</li>
                                <li>{allResourcesToTranslations.inamannerthatpisparages}</li>
                            </ul>
                            <h5>{allResourcesToTranslations.linkedwebsites}</h5>
                            <p>{allResourcesToTranslations.thiswebsitecontainslinks}</p>
                            <p>{allResourcesToTranslations.linkstothoselinked}</p>
                            <h5>{allResourcesToTranslations.privacy}</h5>
                            <p>{allResourcesToTranslations.youacknowledgethat}</p>
                            <h5>{allResourcesToTranslations.spam}</h5>
                            <p>{allResourcesToTranslations.youconsenttogrc}</p>
                            <h5>{allResourcesToTranslations.indemnity}</h5>
                            <p>{allResourcesToTranslations.youmustindemnity}</p>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default TermsAndConditionsModal;
