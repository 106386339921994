//React + Typescript
import React, { useState, useContext, useEffect } from "react";
import { ExternalCertificatesReportItem } from "../../globalTypes";

// Libs
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { Steps, Hints } from "intro.js-react";
//Context
import { LoaderContext } from "../../contexts/LoaderContext";
import { CorperateThemeContext } from "../../contexts/CorperateThemeContext";
import { UIContext } from "../../contexts/UIContext";
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Components
import BrandedCard from "../../Components/BrandedCard";
import LoaderContainer from "../../Components/LoaderContainer";
import CustomTooltip from "../../Components/CustomTooltip";

//General Functions
import getColor from "../../functions/getColor";
import serialToArray from "../../functions/serialToArray";
import { getCurrentTimeDDMMYYYYFormat } from "../../functions/getCurrentTimeDDMMYYYYFormat";
//API functions
import { getExportedXLSXData } from "../../API/GetExportedXLSXData";
import { getExportedCSVData } from "../../API/GetExportedCSVData";
import { getExportedPDFData } from "../../API/GetExportedPDFData";
import { addError } from "../../API/AddError";
//Components
import ExcelFileIcon from "../../CustomIcons/ExcelFileIcon";
import CSVFileIcon from "../../CustomIcons/CSVFileIcon";
import PDFIcon from "../../CustomIcons/PDFFileIcon";
import UpArrowIcon from "../../CustomIcons/UpArrowIcon";
import DownArrowIcon from "../../CustomIcons/DownArrowIcon";
//MUI Components
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";

// Style
import styles from "../../styles/currentAdminReport.module.css";
import GuideIcon from "../../CustomIcons/GuideIcon";
import { Checkbox, CircularProgress, Select } from "@material-ui/core";
import PieChart from "../../Components/HighChart/PieChartComponent";
import DualaxeslineStakedColumnChart from "../../Components/HighChart/SummaryReport_StakedColumn_ChartComponent";
import DownloadButtonIcon from "../../CustomIcons/DownloadButtonIcon";


import JSZip from 'jszip';
// import JSZipUtils from 'jszip-utils';


// @ts-ignore
import * as saveAs from 'file-saver';

// @ts-ignore
import * as JSZipUtils from 'jszip-utils';
import CommonPopupModalForResult from "../../Components/CommonPopupModalForResult";

const ExternalCertificatesReportResult = (props: any) => {
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext)
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, family_name, given_name, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
      .toLowerCase()
      .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";
  const {
    theme: { color },
  } = useContext(CorperateThemeContext);
  const { setMessage } = useContext(LoaderContext);
  const [orginalexternalCertificatesReport, setOrginalExternalCertificatesReport] = useState<Array<ExternalCertificatesReportItem>>(props?.location?.state?.resultItems ?? []); 
  const [externalCertificatesReport, setExternalCertificatesReport] = useState<Array<ExternalCertificatesReportItem>>(props?.location?.state?.resultItems ?? []);
  const [orginalexternalCertificatesReportUniqueCertificateListItems, setOrginalexternalCertificatesReportUniqueCertificateListItems] = useState<Array<any>>(props?.location?.state?.resultExternalCertificatesReportUniqueCertificateListItems ?? []) 
  const [externalCertificatesReportUniqueCertificateListItems, setexternalCertificatesReportUniqueCertificateListItems] = useState<Array<any>>(props?.location?.state?.resultExternalCertificatesReportUniqueCertificateListItems ?? [])
  const [certificatestatus, setcertificatestatus] = useState<string>(props?.location?.state?.certificatestatus ?? "");
  const [filterDateFrom, setFilterDateFrom] = useState<string>(props?.location?.state?.filterDateFrom ?? "");
  const [filterDateTo, setFilterDateTo] = useState<string>(props?.location?.state?.filterDateTo ?? "");
  const [inputFilterFirstName, setInputFilterFirstName] = useState<string>(props?.location?.state?.inputFilterFirstName ?? "")
  const [inputFilterLastName, setInputFilterLastName] = useState<string>(props?.location?.state?.inputFilterLastName ?? "")
  const [inputFilterUserName, setInputFilterUserName] = useState<string>(props?.location?.state?.inputFilterUserName ?? "")
  const [inputFilterEmail, setInputFilterEmail] = useState<string>(props?.location?.state?.inputFilterEmail ?? "")
  const [certificateIDs, setCertificateIDs] = useState<string>(props?.location?.state?.certificateIDs ?? "");
  const [unitIDs, setUnitIDs] = useState<string>(props?.location?.state?.unitIDs ?? "");
  const [includeArchivedCertificates, setIncludeArchivedCertificates] = useState<boolean>(props?.location?.state?.includeArchivedCertificates ?? false);
  const [selectedUnitNames, setSelectedUnitNames] = useState<string>(props?.location?.state?.selectedUnits ?? []);



  const [exporting, setExporting] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true);
  const [currentSortBy, setCurrentSortBy] = useState<string>("");
  const [sortInProgress, setSortInProgress] = useState<boolean>(false);


  const [unitPathwayDescending, setUnitPathwayDescending] = useState<boolean>(false);
  const [lastNameDescending, setLastNameDescending] = useState<boolean>(false);
  const [firstNameDescending, setFirstNameDescending] = useState<boolean>(false);
  const [emailDescending, setEmailDescending] = useState<boolean>(false);
  const [userNameDescending, setUserNameDescending] = useState<boolean>(false);
  const [certificateNameDescending, setCertificateNameDescending] = useState<boolean>(false);
  const [providedDescending, setProvidedDescending] = useState<boolean>(false);
  const [assignedDateDescending, setAssignedDateDescending] = useState<boolean>(false);
  const [validFromDateDescending, setValidFromDateDescending] = useState<boolean>(false);
  const [validToDateDescending, setValidToDateDescending] = useState<boolean>(false);

  const [reverseNextSort, setReverseNextSort] = useState<boolean>(false);
  const [reportRunTimeTaken, setReportRunTimeTaken] = useState<string>("");
  const { setTitle, setTitleEnglish, updateBackButtonRoute, EnableActivityLogs } = useContext(UIContext);
  const [exportError, setExportError] = useState<string>("");
  const [resultError, setResultError] = useState<string>(
    props?.location?.state?.resultError ?? ""
  );

  const [selectedCertificate, setSelectedCertificate] = useState<string>(props?.location?.state?.
    resultSelectedCertificate ?? "")
  const [selectedCategory, setSelectedCategory] = useState<any>({value: 0});
  const [certificateArray, setCertificateArray] = useState<any>([])
  const [arrTotalProvided, setArrTotalProvided] = useState<any>([])
  const [arrTotalNotProvided, setArrTotalNotProvided] = useState<any>([])
  const [arrTotalProvidedPercentage, setArrTotalProvidedPercentage] = useState<any>([])
  const [arrTotalNotProvidedPercentage, setArrTotalNotProvidedPercentage] = useState<any>([])
  const [seriesPieChart, setSeriesDataPieChart] = useState<any>([])

  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".certificateReportList",
        title: "Report Data", intro: allResourcesToTranslations.emrepprvgmmrepdta
      },
    ]
  })

  useEffect(() => {
    let updateSteps = [...intro.steps];
    updateSteps.push(
      {
        element: ".certificateReportList",
        title: allResourcesToTranslations.emrepprvgmtrepdta, intro: allResourcesToTranslations.emrepprvgmmrepdta
      },
      {
        element: ".xlxs",
        title: allResourcesToTranslations.sntemrepgmtexpxls, intro: allResourcesToTranslations.sntemrepgmmexpxls
      },
      {
        element: ".csv",
        title: allResourcesToTranslations.sntemrepgmtexpcsv, intro: allResourcesToTranslations.sntemrepgmmexpcsv
      },
      {
        element: ".pdf",
        title: allResourcesToTranslations.sntemrepgmtexppdf, intro: allResourcesToTranslations.sntemrepgmmexppdf
      },
      {
        element: ".pichart",
        title: allResourcesToTranslations.total, intro: allResourcesToTranslations.extrptgmpiechart
      },
      {
        element: ".chartfilter",
        title: allResourcesToTranslations.extrptcerfilter, intro: allResourcesToTranslations.extrptgmchartfilter
      },
      {
        element: ".dualaxeshart",
        title: allResourcesToTranslations.extrptcersummary, intro: allResourcesToTranslations.extrptgmcolumchart
      }
    );
    setIntro({ ...intro, steps: [...updateSteps] });
  }, [])

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };


  // const toggleBanding = () => setEnableRowBanding(!enableRowBanding);

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
    } else {
      return {};
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const GetDate_yymmdd = () => {
    var d = new Date(Date.now());
    let day = d.getFullYear().toString().substring(2, 4) + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0")
    return day;
  }

  const buildRequestStringArray = () => {
    let resultArray: Array<string> = [];
    let externalCertificatesReportNoGrandTotal = externalCertificatesReport;

    for (let i = 0; i < externalCertificatesReportNoGrandTotal.length; i++) {
      const r = externalCertificatesReportNoGrandTotal[i];
      resultArray.push(`${r.unitPathway}<#>${r.lastName}<#>${r.firstName}<#>${r.email}<#>${r.userName}<#>${r.certificateName}<#>${r.provided}<#>${r.assignedDateTxt}<#>${r.validFromDateTxt}<#>${r.validToDateTxt}`);
    }
    
    return resultArray;
  };

  const handleXLSXExport = () => {
    setMessage("Exporting XLSX");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started External Certificate Report XLSX-EXPORT in NEWSALT ClientApp",
      null,
      "ExternalCertificatesReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId), buildRequestStringArray(), 10, "externalcertificatesreport", enableRowBanding, "", "", filterDateFrom, filterDateTo, false, includeArchivedCertificates, "", inputFilterFirstName, inputFilterLastName, inputFilterUserName, inputFilterEmail, "", "", false, false, 1, "", "", -1, -1, unitIDs, certificateIDs, certificatestatus).then((xlxsRes) => {
      if (xlxsRes.isSuccess) {
        const fileData = xlxsRes.xlsxDataOutput.fileData
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(
          new Blob([serialToArray(atob(fileData))], {
            type: ''
          })
        );

        a.download = "ExternalCertificatesReport-" + GetDate_yymmdd() + ".xlsx";
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)

      }
      else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
        setExportError(xlxsRes.error);
      }
      setExporting(false);
      // Activity log
      addError(oidcUser.access_token, userId, orgDomain, "Completed External Certificate Report XLSX-EXPORT in NEWSALT ClientApp", null, "ExternalCertificatesReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const handleCSVExport = () => {
    setMessage("Exporting CSV");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started External Certificate Report CSV-EXPORT in NEWSALT ClientApp",
      null,
      "ExternalCertificatesReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId), buildRequestStringArray(), 10, "externalcertificatesreport", "", "", filterDateFrom, filterDateTo, false, includeArchivedCertificates, "", false, inputFilterFirstName, inputFilterLastName, inputFilterUserName, inputFilterEmail, "", "", false, false, 1, "", "", -1, -1, unitIDs, certificateIDs, certificatestatus).then((csvRes) => {
      if (csvRes.isSuccess) {
        const fileData = csvRes.csvDataOutput.fileData
        var a = window.document.createElement('a');

        a.href = window.URL.createObjectURL(
          new Blob([serialToArray(atob(fileData))], {
            type: ''
          })
        )
        a.download = "ExternalCertificatesReport-" + GetDate_yymmdd() + ".csv";
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)

      }
      else if (csvRes.error !== undefined && csvRes.error !== null) {
        setExportError(csvRes.error);
      }
      setExporting(false)
      // Activity log
      addError(oidcUser.access_token, userId, orgDomain, "Completed External Certificate Report CSV-EXPORT in NEWSALT ClientApp", null, "ExternalCertificatesReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const handlePDFExport = () => {
    setMessage("Exporting PDF");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started External Certificate Builder Report PDF-EXPORT in NEWSALT ClientApp",
      null,
      "ExternalCertificatesReportsResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedPDFData(oidcUser.access_token, userId, parseInt(orgId), buildRequestStringArray(), 10, "externalcertificatesreport", enableRowBanding, "", "", filterDateFrom, filterDateTo, false, includeArchivedCertificates, "", inputFilterFirstName, inputFilterLastName, inputFilterUserName, inputFilterEmail, "", "", false, false, 1, "", "", -1, -1, unitIDs, certificateIDs, certificatestatus).then((pdfRes) => {
      if (pdfRes.isSuccess) {
        const fileData = pdfRes.pdfDataOutput.fileData
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(
          new Blob([serialToArray(atob(fileData))], {
            type: ''
          })
        )
        a.download = "ExternalCertificatesReport-" + GetDate_yymmdd() + ".PDF";
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)
      }
      else if (pdfRes.error !== undefined && pdfRes.error !== null) {
        setExportError(pdfRes.error);
      }
      setExporting(false)
      // Activity log
      addError(oidcUser.access_token, userId, orgDomain, "Completed External Certificate Report PDF-EXPORT in NEWSALT ClientApp", null, "ExternalCertificatesReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const sortResultsByAsync = async (
    sortBy:
      | "CertificateName"
      | "UnitPathway"
      | "LastName"
      | "FirstName"
      | "Email"
      | "UserName"
      | "Provided"
      | "AssignedDate"
      | "ValidFromDate"
      | "ValidToDate",

    sortGivenItemInDesc: boolean
  ) => {
    if (externalCertificatesReport == null || externalCertificatesReport.length <= 0) {
      return externalCertificatesReport;
    }
    var result = externalCertificatesReport.slice(0);
    setCurrentSortBy(sortBy);
    switch (sortBy) {
      case "UnitPathway":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || !!b.unitPathway === false) {
              return -1;
            }
            if (a === null || !!a.unitPathway === false) {
              return 1;
            }
            if (a.unitPathway.trim().toLowerCase() > b.unitPathway.trim().toLowerCase()) {
              return -1;
            }
            if (a.unitPathway.trim().toLowerCase() < b.unitPathway.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitPathwayDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || !!b.unitPathway === false) {
              return 1;
            }
            if (a === null || !!a.unitPathway === false) {
              return -1;
            }
            if (a.unitPathway.trim().toLowerCase() > b.unitPathway.trim().toLowerCase()) {
              return 1;
            }
            if (a.unitPathway.trim().toLowerCase() < b.unitPathway.trim().toLowerCase()) {
              return -1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitPathwayDescending(true);
          }
        }
        setExternalCertificatesReport(result);
        break;
      case "CertificateName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.certificateName === null) {
              return -1;
            }
            if (a === null || a.certificateName === null) {
              return 1;
            }
            if (a.certificateName.trim().toLowerCase() > b.certificateName.trim().toLowerCase()) {
              return -1;
            }
            if (a.certificateName.trim().toLowerCase() < b.certificateName.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setCertificateNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.certificateName === null) {
              return 1;
            }
            if (a === null || a.certificateName === null) {
              return -1;
            }
            if (a.certificateName.trim().toLowerCase() < b.certificateName.trim().toLowerCase()) {
              return -1;
            }
            if (a.certificateName.trim().toLowerCase() > b.certificateName.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setCertificateNameDescending(true);
          }
        }
        setExternalCertificatesReport(result);
        break;
      case "LastName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) {
              return -1;
            }
            if (a === null || a.lastName === null) {
              return 1;
            }
            if (a.lastName.trim().toLowerCase() > b.lastName.trim().toLowerCase()) {
              return -1;
            }
            if (a.lastName.trim().toLowerCase() < b.lastName.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) {
              return 1;
            }
            if (a === null || a.lastName === null) {
              return -1;
            }
            if (a.lastName.trim().toLowerCase() < b.lastName.trim().toLowerCase()) {
              return -1;
            }
            if (a.lastName.trim().toLowerCase() > b.lastName.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastNameDescending(true);
          }
        }
        setExternalCertificatesReport(result);
        break;
      case "FirstName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.firstName === null) {
              return -1;
            }
            if (a === null || a.firstName === null) {
              return 1;
            }
            if (a.firstName.trim().toLowerCase() > b.firstName.trim().toLowerCase()) {
              return -1;
            }
            if (a.firstName.trim().toLowerCase() < b.firstName.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.firstName === null) {
              return 1;
            }
            if (a === null || a.firstName === null) {
              return -1;
            }
            if (a.firstName.trim().toLowerCase() > b.firstName.trim().toLowerCase()) {
              return 1;
            }
            if (a.firstName.trim().toLowerCase() < b.firstName.trim().toLowerCase()) {
              return -1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstNameDescending(true);
          }
        }
        setExternalCertificatesReport(result);
        break;
      case "Email":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return -1;
            }
            if (a === null || a.email === null) {
              return 1;
            }
            if (a.email.trim().toLowerCase() > b.email.trim().toLowerCase()) {
              return -1;
            }
            if (a.email.trim().toLowerCase() < b.email.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setEmailDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return 1;
            }
            if (a === null || a.email === null) {
              return -1;
            }
            if (a.email.trim().toLowerCase() < b.email.trim().toLowerCase()) {
              return -1;
            }
            if (a.email.trim().toLowerCase() > b.email.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setEmailDescending(true);
          }
        }
        setExternalCertificatesReport(result);
        break;
      case "UserName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.userName === null) {
              return -1;
            }
            if (a === null || a.userName === null) {
              return 1;
            }
            if (a.userName.trim().toLowerCase() > b.userName.trim().toLowerCase()) {
              return -1;
            }
            if (a.userName.trim().toLowerCase() < b.userName.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUserNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.userName === null) {
              return 1;
            }
            if (a === null || a.userName === null) {
              return -1;
            }
            if (a.userName.trim().toLowerCase() < b.userName.trim().toLowerCase()) {
              return -1;
            }
            if (a.email.trim().toLowerCase() > b.email.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUserNameDescending(true);
          }
        }
        setExternalCertificatesReport(result);
        break;
      case "Provided":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.provided === null) {
              return -1;
            }
            if (a === null || a.provided === null) {
              return 1;
            }
            if (a.provided.trim().toLowerCase() > b.provided.trim().toLowerCase()) {
              return -1;
            }
            if (a.provided.trim().toLowerCase() < b.provided.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setProvidedDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.provided === null) {
              return 1;
            }
            if (a === null || a.provided === null) {
              return -1;
            }
            if (a.provided.trim().toLowerCase() < b.provided.trim().toLowerCase()) {
              return -1;
            }
            if (a.provided.trim().toLowerCase() > b.provided.trim().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setProvidedDescending(true);
          }
        }
        setExternalCertificatesReport(result);
        break;
      case "AssignedDate":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.assignedDateTxt === null || b.assignedDateTxt.trim() === "") { return -1; }
            if (a === null || a.assignedDateTxt === null || a.assignedDateTxt.trim() === "") { return 1; }
            var dta = a.assignedDate; // new Date(a.dateCreated.split("/").reverse().join("-"))
            var dtb = b.assignedDate; // new Date(b.dateCreated.split("/").reverse().join("-"))
            if (dta > dtb) { return -1; }
            if (dta < dtb) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setAssignedDateDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.assignedDateTxt === null || b.assignedDateTxt.trim() === "") { return 1; }
            if (a === null || a.assignedDateTxt === null || a.assignedDateTxt.trim() === "") { return -1; }
            var dta = a.assignedDate; // new Date(a.dateCreated.split("/").reverse().join("-"))
            var dtb = b.assignedDate; // new Date(b.dateCreated.split("/").reverse().join("-"))
            if (dta > dtb) { return 1; }
            if (dta < dtb) { return -1; }
            return 0;
          })
          if (reverseNextSort) {
            setAssignedDateDescending(true)
          }
        }
        setExternalCertificatesReport(result);
        break;
      case "ValidFromDate":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.validFromDateTxt === null || b.validFromDateTxt.trim() === "") { return -1; }
            if (a === null || a.validFromDateTxt === null || a.validFromDateTxt.trim() === "") { return 1; }
            var dta = a.validFromDate; // new Date(a.dateCreated.split("/").reverse().join("-"))
            var dtb = b.validFromDate; // new Date(b.dateCreated.split("/").reverse().join("-"))
            if (dta > dtb) { return -1; }
            if (dta < dtb) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setValidFromDateDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.validFromDateTxt === null || b.validFromDateTxt.trim() === "") { return 1; }
            if (a === null || a.validFromDateTxt === null || a.validFromDateTxt.trim() === "") { return -1; }
            var dta = a.validFromDate; // new Date(a.dateCreated.split("/").reverse().join("-"))
            var dtb = b.validFromDate; // new Date(b.dateCreated.split("/").reverse().join("-"))
            if (dta > dtb) { return 1; }
            if (dta < dtb) { return -1; }
            return 0;
          })
          if (reverseNextSort) {
            setValidFromDateDescending(true)
          }
        }
        setExternalCertificatesReport(result);
        break;
      case "ValidToDate":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.validToDateTxt === null || b.validToDateTxt.trim() === "") { return -1; }
            if (a === null || a.validToDateTxt === null || a.validToDateTxt.trim() === "") { return 1; }
            var dta = a.validToDate; // new Date(a.dateCreated.split("/").reverse().join("-"))
            var dtb = b.validToDate; // new Date(b.dateCreated.split("/").reverse().join("-"))
            if (dta > dtb) { return -1; }
            if (dta < dtb) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setValidToDateDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.validToDateTxt === null || b.validToDateTxt.trim() === "") { return 1; }
            if (a === null || a.validToDateTxt === null || a.validToDateTxt.trim() === "") { return -1; }
            var dta = a.validToDate; // new Date(a.dateCreated.split("/").reverse().join("-"))
            var dtb = b.validToDate; // new Date(b.dateCreated.split("/").reverse().join("-"))
            if (dta > dtb) { return 1; }
            if (dta < dtb) { return -1; }
            return 0;
          })
          if (reverseNextSort) {
            setValidToDateDescending(true)
          }
        }
        setExternalCertificatesReport(result);
        break;

      default:
        break;
    }
    return result.slice(0);
  };

  const sortResultsBy = async (
    sortBy:
      | "CertificateName"
      | "UnitPathway"
      | "LastName"
      | "FirstName"
      | "Email"
      | "UserName"
      | "Provided"
      | "AssignedDate"
      | "ValidFromDate"
      | "ValidToDate",
    sortGivenItemInDesc: boolean
  ) => {
    setSortInProgress(true);
    setExportError("");
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy);
      setExternalCertificatesReport(result);
      setPage(0);
      setSortInProgress(false);
    });
  };

  const clickUnitPathwayAsc = () => {
    setReverseNextSort(false);
    setUnitPathwayDescending(false);
    sortResultsBy("UnitPathway", false);
  };

  const clickUnitPathwayDesc = () => {
    setReverseNextSort(false);
    setUnitPathwayDescending(true);
    sortResultsBy("UnitPathway", true);
  };

  const clickCertificateNameAsc = () => {
    setReverseNextSort(false);
    setCertificateNameDescending(false);
    sortResultsBy("CertificateName", false);
  };

  const clickCertificateNameDesc = () => {
    setReverseNextSort(false);
    setCertificateNameDescending(true);
    sortResultsBy("CertificateName", true);
  };

  const clickLastNameAsc = () => {
    setReverseNextSort(false);
    setLastNameDescending(false);
    sortResultsBy("LastName", false);
  };

  const clickLastNameDesc = () => {
    setReverseNextSort(false);
    setLastNameDescending(true);
    sortResultsBy("LastName", true);
  };

  const clickFirstNameAsc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(false);
    sortResultsBy("FirstName", false);
  };

  const clickFirstNameDesc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(true);
    sortResultsBy("FirstName", true);
  };

  const clickEmailAsc = () => {
    setReverseNextSort(false);
    setEmailDescending(false);
    sortResultsBy("Email", false);
  };

  const clickEmailDesc = () => {
    setReverseNextSort(false);
    setEmailDescending(true);
    sortResultsBy("Email", true);
  };

  const clickUserNameAsc = () => {
    setReverseNextSort(false);
    setUserNameDescending(false);
    sortResultsBy("UserName", false);
  };

  const clickUserNameDesc = () => {
    setReverseNextSort(false);
    setUserNameDescending(true);
    sortResultsBy("UserName", true);
  };

  const clickProvidedAsc = () => {
    setReverseNextSort(false);
    setProvidedDescending(false);
    sortResultsBy("Provided", false);
  };

  const clickProvidedDesc = () => {
    setReverseNextSort(false);
    setProvidedDescending(true);
    sortResultsBy("Provided", true);
  };

  const clickAssignedDateAsc = () => {
    setReverseNextSort(false);
    setAssignedDateDescending(false);
    sortResultsBy("AssignedDate", false);
  };

  const clickAssignedDateDesc = () => {
    setReverseNextSort(false);
    setAssignedDateDescending(true);
    sortResultsBy("AssignedDate", true);
  };

  const clickValidFromDateAsc = () => {
    setReverseNextSort(false);
    setValidFromDateDescending(false);
    sortResultsBy("ValidFromDate", false);
  };

  const clickValidFromDateDesc = () => {
    setReverseNextSort(false);
    setValidFromDateDescending(true);
    sortResultsBy("ValidFromDate", true);
  };


  const clickValidToDateAsc = () => {
    setReverseNextSort(false);
    setValidToDateDescending(false);
    sortResultsBy("ValidToDate", false);
  };

  const clickValidToDateDesc = () => {
    setReverseNextSort(false);
    setValidToDateDescending(true);
    sortResultsBy("ValidToDate", true);
  };

  const initialSort = () => {
    setCurrentSortBy("UnitPathway");
    setUnitPathwayDescending(false);
    setCertificateNameDescending(false);
    setLastNameDescending(false);
    setFirstNameDescending(false);
    setEmailDescending(false);
    setUserNameDescending(false);
    setProvidedDescending(false);
    setAssignedDateDescending(false);
    setValidFromDateDescending(false);
    setValidToDateDescending(false);
    setReportRunTimeTaken(getCurrentTimeDDMMYYYYFormat());
    setPage(0);
  };

  useEffect(() => {
    initialSort();
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Completed External Certificate Report in NEWSALT ClientApp",
      null,
      "ExternalCertificatesReportResult.tsx",
      6, EnableActivityLogs
    );
    if (externalCertificatesReport !== null && externalCertificatesReport.length > 0) {
      GetSummaryReportChart("");
    }
  }, []);

  if (
    resultError !== null &&
    resultError !== undefined &&
    resultError.trimLeft().trimRight().length > 0
  ) {
    setTitle(allResourcesToTranslations.extcertificatereport);
    setTitleEnglish(pageTitles.extcertificatereport)
    updateBackButtonRoute("/externalcertificatesreport");
    //setExportError("");
    return (
      <BrandedCard>
        <div className={styles.noResultsCard}>
          <div style={{ color: "red" }}>Error: {resultError}</div>
          <br />
          <div>Select the Back Button to return to Previous Page</div>
        </div>
      </BrandedCard>
    );
  } else if (externalCertificatesReport === null || externalCertificatesReport.length < 1) {
    setTitle(allResourcesToTranslations.extcertificatereport);
    setTitleEnglish(pageTitles.extcertificatereport)
    //setExportError("");
    updateBackButtonRoute("/externalcertificatesreport");
    return (
      <BrandedCard>
        <div className={styles.noResultsCard}>
        <div>{allResourcesToTranslations.sntemrepgmmnoresm}</div>
                <br/>
                <div>{allResourcesToTranslations.sntemrepgmmselbkprevm}</div>
        </div>
      </BrandedCard>
    );
  } else {
    setTitle(allResourcesToTranslations.extcertificatereport);
    setTitleEnglish(pageTitles.extcertificatereport)
    updateBackButtonRoute("/externalcertificatesreport");
  }

  const GetSelectedUnitsSummary = () => {
    if (!!selectedUnitNames) {
      let arr = selectedUnitNames.split(",");
      return selectedUnitNames != "" && arr.length > 5 ? (
        <div className={styles.reportDetailContainer}>
          <span className={styles.reportDetailTitle}>
            {"Unit(s) where applicable" /* TODO Tranlation */}
          </span>
          {selectedUnitNames.split(",", 5).map((step: any) => (
            <span key={step} className={styles.reportDetail}>
              {step}
            </span>
          ))}
          <CustomTooltip
            displayText={selectedUnitNames.split(",").splice(5, arr.length)}
            placement="bottom"
          >
            <span className={styles.reportDetail}>
              {arr.length > 5 ? "+" + (arr.length - 5) + " more..." : ""}
            </span>
          </CustomTooltip>
        </div>
      ) : (
        <div className={styles.reportDetailContainer}>
          <span className={styles.reportDetailTitle}>
            {"Unit(s) where applicable" /* TODO Tranlation */}
          </span>
          {selectedUnitNames.split(",").map((step: any) => (
            <span key={step} className={styles.reportDetail}>
              {step}
            </span>
          ))}
        </div>
      );
    } else {
      return "";
    }
  };

  const GetInputFilterFirstName = () => {
    let curValue = inputFilterFirstName != null ? inputFilterFirstName : "";
    return curValue != "" ? <div className={styles.reportDetailContainer}>
      <span className={styles.reportDetailTitle}>{"First Name" /* TODO Tranlation */}</span>
      <span className={styles.reportDetail}> {inputFilterFirstName}</span></div> : ""
  }

  const GetInputFilterLastName = () => {
    let curValue = inputFilterLastName != null ? inputFilterLastName : "";
    return curValue != "" ? <div className={styles.reportDetailContainer}>
      <span className={styles.reportDetailTitle}>{"Last Name" /* TODO Tranlation */}</span>
      <span className={styles.reportDetail}> {inputFilterLastName}</span></div> : ""
  }

  const GetInputFilterUserName = () => {
    let curValue = inputFilterUserName != null ? inputFilterUserName : "";
    return curValue != "" ? <div className={styles.reportDetailContainer}>
      <span className={styles.reportDetailTitle}>{"Username" /* TODO Tranlation */}</span>
      <span className={styles.reportDetail}> {inputFilterUserName}</span></div> : ""
  }

  const GetInputFilterEmail = () => {
    let curValue = inputFilterEmail != null ? inputFilterEmail : "";
    return curValue != "" ? <div className={styles.reportDetailContainer}>
      <span className={styles.reportDetailTitle}>{"Email" /* TODO Tranlation */}</span>
      <span className={styles.reportDetail}> {inputFilterEmail}</span></div> : ""
  }

  const GetInputFilterArchivedCertificates = () => {
    let curValue = includeArchivedCertificates != null ? includeArchivedCertificates : "";
    return curValue != "" ? <div className={styles.reportDetailContainer}>
      <span className={styles.reportDetailTitle}>{allResourcesToTranslations.includearchivedcertificates}</span>
      <span className={styles.reportDetail}> {includeArchivedCertificates ? "Yes" : "No"}</span></div> : ""
  }
  const GetSelectedCourseNameSummary = () => {
    let arr = selectedCertificate.split(',');
    return (selectedCertificate != "" && arr.length > 5) ? <div className={styles.reportDetailContainer}>
      <span className={styles.reportDetailTitle}>{"Selected Certificate" /* TODO Tranlation */}</span>
      {selectedCertificate.split(',', 5).map((step: any) => <span className={styles.reportDetail}>{step}</span>)}
      <span className={styles.reportDetail}>{arr.length > 6 ? "+" + (arr.length - 6) + " more..." : ""}</span>
    </div> :

      <div className={styles.reportDetailContainer}>
        <span className={styles.reportDetailTitle}>{"Selected Certificate" /* TODO Tranlation */}</span>
        {selectedCertificate.split(',').map((step: any) => <span className={styles.reportDetail}>{step}</span>)}
      </div>
  }


  //#region HIghChart 
  const GetRoundedIncompletePercentage = (incomplPercentage: number, complPercentage: number) => {
    const incomplPercentageStr = incomplPercentage.toString();
    const incomplPercentageRnd = Math.round(incomplPercentage);
    const complPercentageRnd = Math.round(complPercentage);
    return (
      incomplPercentageRnd === 100 ?
        parseInt(incomplPercentageStr) :
        (
          (incomplPercentage !== 0 && incomplPercentage <= 1) ?
            1 :
            (
              (incomplPercentageRnd + complPercentageRnd) > 100 ?
                parseInt(incomplPercentageStr) :
                incomplPercentageRnd
            )
        )
    );
  }

  const GetRoundedCompletePercentage = (incomplPercentage: number, complPercentage: number) => {
    const complPercentageStr = complPercentage.toString();
    const incomplPercentageRnd = Math.round(incomplPercentage);
    const complPercentageRnd = Math.round(complPercentage);
    return (
      complPercentageRnd === 100 ?
        parseInt(complPercentageStr) :
        (
          (complPercentage !== 0 && complPercentage <= 1) ?
            1 :
            complPercentageRnd
        )
    );
  }

  const GetSummaryReportChart = (givenSelectedcategory: any) => {
    let certificatecategories: Array<string> = [];
    let providedUsers: Array<number> = [];
    let notProvidedUsers: Array<number> = [];
    let providedUsersPercentage: Array<number> = [];
    let notProvidedUsersPercentage: Array<number> = [];
    let seriesDataPieChart: Array<any> = [];
    let notProvidedCount: number;
    let providedCount: number;
    notProvidedCount = 0;
    providedCount = 0;
    if (!!externalCertificatesReportUniqueCertificateListItems) {
      let filterdSummaryReportItem = orginalexternalCertificatesReportUniqueCertificateListItems;
      let filterdReportItem = orginalexternalCertificatesReport;
      setPage(0);     
      if (givenSelectedcategory != "") {
        filterdSummaryReportItem = orginalexternalCertificatesReportUniqueCertificateListItems.filter((item: any) => item.certificateName.toString() === givenSelectedcategory.toString());
        filterdReportItem = orginalexternalCertificatesReport.filter((item: any) => item.certificateName.toString() === givenSelectedcategory.toString());
        setExternalCertificatesReport(filterdReportItem)
        setexternalCertificatesReportUniqueCertificateListItems(filterdReportItem)
      }
      else {
        filterdSummaryReportItem = orginalexternalCertificatesReportUniqueCertificateListItems;
        filterdReportItem = orginalexternalCertificatesReport;

        setExternalCertificatesReport(orginalexternalCertificatesReport)
        setexternalCertificatesReportUniqueCertificateListItems(orginalexternalCertificatesReportUniqueCertificateListItems)
      }
      for (let i = 0; i < filterdSummaryReportItem.length; i++) {
        const r1 = filterdSummaryReportItem[i];
        certificatecategories.push(`${r1.certificateName}`);
        providedUsers.push(parseInt(r1.providedCount.toString()))
        notProvidedUsers.push(parseInt(r1.notProvidedCount.toString()))
        providedUsersPercentage.push(parseInt(r1.providedPercentage.toString()))
        notProvidedUsersPercentage.push(parseInt(r1.notProvidedPercentage.toString()))

        notProvidedCount += r1.notProvidedCount;
        providedCount += r1.providedCount;
      }
      setCertificateArray(certificatecategories);
      setArrTotalProvided(providedUsers);
      setArrTotalNotProvided(notProvidedUsers);
      setArrTotalProvidedPercentage(providedUsersPercentage);
      setArrTotalNotProvidedPercentage(notProvidedUsersPercentage);


      let IncompletePercentage = GetRoundedIncompletePercentage(notProvidedCount, providedCount);
      let completePercentage = GetRoundedCompletePercentage(notProvidedCount, providedCount);

      seriesDataPieChart.push({
        name: "Provided (%)",
        y: completePercentage
      })
      seriesDataPieChart.push({
        name: "Not Provided (%)",
        y: IncompletePercentage
      })

      setSeriesDataPieChart(seriesDataPieChart);

    }
  }
  //#endregion

  if (exporting) return <LoaderContainer />;
  return (
    <>
      {
        <div style={{ color: "red" }}>
          {exportError === undefined ||
            exportError === null ||
            exportError === ""
            ? ""
            : "Export Error: " + exportError}
        </div>
      }
      <hr />
      <Steps enabled={intro.stepsEnabled} steps={intro.steps} initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: 'Next',
          prevLabel: 'Back',
          tooltipClass: 'sample-class',
          showBullets: true,
        }} />
      <CustomTooltip displayText={["Guide Me"]}>
        <span style={{ right: "105px" }} onClick={() => setIntro({ ...intro, stepsEnabled: true })} className={styles.guideMeButtonSpecific}>
          <GuideIcon />
        </span>
      </CustomTooltip>
      <BrandedCard>
        <div className={styles.reportPageTitleSection}>
          <div className={styles.exportBtnContainer}>
            <div className={styles.reportContainer}>
              <div className={styles.reportDetailContainer}>
                <span className={styles.reportDetailTitle}>{allResourcesToTranslations.reportyrunby}</span>
                <span className={styles.reportDetail}> {`${given_name} ${family_name}`}</span>
              </div>
              <div className={styles.reportDetailContainer}>
                <span className={styles.reportDetailTitle}>{allResourcesToTranslations.reportruntime}</span>
                <span className={styles.reportDetail}>
                  {reportRunTimeTaken}
                </span>
              </div>
              {GetSelectedUnitsSummary()}
              {(selectedCertificate != "") ? GetSelectedCourseNameSummary() : ""}
              {GetInputFilterArchivedCertificates()}
              <div className={styles.reportDetailContainer}>
                <span className={styles.reportDetailTitle}>
                  {"Certificate upload status" /* TODO Tranlation */}
                </span>
                <span className={styles.reportDetail}>
                  {" "}
                  {certificatestatus === "1" ? "Provided" : (certificatestatus === "2" ? "Not Provided" : (certificatestatus === "3" ? "Both" : certificatestatus))}
                </span>
              </div>
              {filterDateFrom != "" &&
                <div className={styles.reportDetailContainer}>
                  <span className={styles.reportDetailTitle}>
                    {"DateFrom" /* TODO Tranlation */}
                  </span>
                  <span className={styles.reportDetail}>
                    {" "}
                    {filterDateFrom}
                  </span>
                </div>
              }
              {filterDateTo != "" &&
                <div className={styles.reportDetailContainer}>
                  <span className={styles.reportDetailTitle}>
                    {"DateTo" /* TODO Tranlation */}
                  </span>
                  <span className={styles.reportDetail}>
                    {" "}
                    {filterDateTo}
                  </span>
                </div>
              }
              {GetInputFilterFirstName()}
              {GetInputFilterLastName()}
              {GetInputFilterUserName()}
              {GetInputFilterEmail()}
            </div>
            <div className={styles.reportExportContainer}>
              <CustomTooltip displayText={[allResourcesToTranslations.exporttoxlsx]}>
                <Button className="xlxs" color="primary" onClick={handleXLSXExport} id="btnNewSaltCARReportExportXLSX">
                  <ExcelFileIcon />
                </Button>
              </CustomTooltip>
              <CustomTooltip displayText={[allResourcesToTranslations.exporttocsv]}>
                <Button className="csv" color="primary" onClick={handleCSVExport} id="btnNewSaltCARReportExportCSV">
                  <CSVFileIcon />
                </Button>
              </CustomTooltip>
              <CustomTooltip displayText={[allResourcesToTranslations.exporttopdf] }>
                <Button className="pdf" color="primary" onClick={handlePDFExport} id="btnNewSaltCARReportExportPDF">
                  <PDFIcon />
                </Button>
              </CustomTooltip>                            
            </div>  
            
          </div>
        </div>
      </BrandedCard>
      <div><span>&nbsp;</span></div>
      <BrandedCard>
        <div style={{ padding: "15px", display: "flex" }}>
          <div className={styles.row} style={{ width: "100%" }}>
            <div className={styles.column} style={{ paddingTop: "10px", width: "37%", fontWeight: "bold" }}>
              Grand Total
            </div>
            <div className={styles.column} style={{ width: "63%" }}>
              <div className={styles.row} style={{ width: "100%" }}>
                <div className={styles.column} style={{ paddingTop: "10px", float: "left", fontWeight: "bold" }}> Certificates Summary</div>
                <div className={`${styles.column} chartfilter`}  style={{ float: "right" }} >
                  <Select style={{ width: "500px" }}
                    onChange={(e) => {setSelectedCategory({value: e.target.value});  GetSummaryReportChart(e.target.value);}}
                    className={styles.input}
                    variant="outlined"
                    value={selectedCategory.value}
                    native
                  >
                    <option value="" selected> -- All Certificates -- </option>
                    {orginalexternalCertificatesReportUniqueCertificateListItems.map((item: any) => <option value={item.certificateName}>{item.certificateName}</option>
                    )}
                  </Select>    </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.row} style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <hr></hr>
        </div>
        <div style={{ width: "100%", display: "flex" }}>
          <div style={{ paddingTop: "20px", paddingBottom: "20px", paddingRight: "20px", width: "37%" }} className={"pichart"}>
            <PieChart seriesData={seriesPieChart} ChartTitle={"Grand Total"} seriesName={"Total Certificates"} innerSize="40%" themecolor={color} />
          </div>
          <div style={{ paddingTop: "20px", paddingBottom: "20px", width: "63%", flex: "1" }}  className={"dualaxeshart"}>
            <DualaxeslineStakedColumnChart categoriesArray={certificateArray} incomplete={arrTotalNotProvided} complete={arrTotalProvided}
              incompletePercentage={arrTotalNotProvidedPercentage} completePercentage={arrTotalProvidedPercentage} seriesIncompleteName={"Not Provided"} seriesCompleteName={"Provided"}
              ChartTitle={"Certificates Summary"}
              yAxisPrimaryTitle={""} yAxisSecondaryTitle={""} themecolor={color} />
          </div>
        </div>
      </BrandedCard>      
      <div
        className={styles.paginationControlsContainer}
        style={{ justifyContent: "center" }}
      >
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 100]}
          component="div"
          count={externalCertificatesReport.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <div className={styles.tableSection}>
        <div className={styles.tableContainer}>
          <BrandedCard>
            <TableContainer>
              <Table>
                <TableHead className="certificateReportList">
                  <TableRow>                    
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                          setUnitPathwayDescending(!unitPathwayDescending);
                          sortResultsBy("UnitPathway", !unitPathwayDescending);
                        }}>
                          {allResourcesToTranslations.unitpathwaydetails}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{ opacity: currentSortBy === "UnitPathway" && unitPathwayDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickUnitPathwayAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity: currentSortBy === "UnitPathway" && unitPathwayDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickUnitPathwayDesc}>                           <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                          setLastNameDescending(!lastNameDescending);
                          sortResultsBy("LastName", !lastNameDescending);
                        }}>
                          {allResourcesToTranslations.lastnamecoltitle}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{ opacity: currentSortBy === "LastName" && lastNameDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickLastNameAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity: currentSortBy === "LastName" && lastNameDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickLastNameDesc}>                           <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                          setFirstNameDescending(!firstNameDescending);
                          sortResultsBy("FirstName", !firstNameDescending);
                        }}>
                          {allResourcesToTranslations.firstnamecoltitle}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{ opacity: currentSortBy === "FirstName" && firstNameDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickFirstNameAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity: currentSortBy === "FirstName" && firstNameDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickFirstNameDesc}>                           <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                          setUserNameDescending(!userNameDescending);
                          sortResultsBy("UserName", !userNameDescending);
                        }}>
                          {allResourcesToTranslations.usernametitle}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{ opacity: currentSortBy === "UserName" && userNameDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickUserNameAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity: currentSortBy === "UserName" && userNameDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickUserNameDesc}>                           <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                          setEmailDescending(!emailDescending);
                          sortResultsBy("Email", !emailDescending);
                        }}>
                          {allResourcesToTranslations.emrepprvgmtembod}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{ opacity: currentSortBy === "Email" && emailDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickEmailAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity: currentSortBy === "Email" && emailDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickEmailDesc}>                           <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                          setCertificateNameDescending(!certificateNameDescending);
                          sortResultsBy("CertificateName", !certificateNameDescending);
                        }}>
                          {allResourcesToTranslations.certificatenametitle}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{ opacity: currentSortBy === "CertificateName" && certificateNameDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickCertificateNameAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity: currentSortBy === "CertificateName" && certificateNameDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickCertificateNameDesc}>                           <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="center">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                          setProvidedDescending(!providedDescending);
                          sortResultsBy("Provided", !providedDescending);
                        }}>
                          {allResourcesToTranslations.providedcertificates}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{ opacity: currentSortBy === "Accepted" && providedDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickProvidedAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity: currentSortBy === "Accepted" && providedDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickProvidedDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                          setAssignedDateDescending(!assignedDateDescending);
                          sortResultsBy("AssignedDate", !assignedDateDescending);
                        }}>
                          {allResourcesToTranslations.assigneddatetitle}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{ opacity: currentSortBy === "AssignedDate" && assignedDateDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickAssignedDateAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity: currentSortBy === "AssignedDate" && assignedDateDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickAssignedDateDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                          setValidFromDateDescending(!validFromDateDescending);
                          sortResultsBy("ValidFromDate", !validFromDateDescending);
                        }}>
                          {allResourcesToTranslations.validfromdate}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{ opacity: currentSortBy === "ValidFromDate" && validFromDateDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickValidFromDateAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity: currentSortBy === "ValidFromDate" && validFromDateDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickValidFromDateDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                          setValidToDateDescending(!validToDateDescending);
                          sortResultsBy("ValidToDate", !validToDateDescending);
                        }}>
                          {allResourcesToTranslations.validtodate}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{ opacity: currentSortBy === "ValidToDate" && validToDateDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickValidToDateAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity: currentSortBy === "ValidToDate" && validToDateDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickValidToDateDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {externalCertificatesReport
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((result: ExternalCertificatesReportItem, i) => {
                      return (
                        <TableRow
                          key={`ExternalCertificatesReport-${i}`}
                          style={rowBackgroundColor(i)}>                          
                          <TableCell align="left">{result.unitPathway}</TableCell>
                          <TableCell align="left">{result.lastName}</TableCell>
                          <TableCell align="left">{result.firstName}</TableCell>
                          <TableCell align="left">{result.userName}</TableCell>
                          <TableCell align="left">{result.email}</TableCell>
                          <TableCell align="left">{result.certificateName}</TableCell>
                          <TableCell align="left">{result.provided}</TableCell>
                          <TableCell align="left">{result.assignedDateTxt}</TableCell>
                          <TableCell align="left">{result.validFromDateTxt}</TableCell>
                          <TableCell align="left">{result.validToDateTxt}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </BrandedCard>
        </div>
      </div>
      <div
        className={styles.paginationControlsContainer}
        style={{ justifyContent: "center" }}
      >
        <TablePagination
          component="div"
          count={externalCertificatesReport.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default ExternalCertificatesReportResult;