//React + Typescript
  import React, { useEffect, useState, useContext, useRef } from 'react';
  import { useHistory, useParams } from 'react-router-dom'
import { InfopathQuiz, LearningModule, InfopathPreQuizSessionDataType } from '../../globalTypes';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { SessionContext } from '../../contexts/SessionContext';
import { InfopathContext } from '../../contexts/InfopathContext';
import { MyTrainingListContext } from '../../contexts/MyTrainingListContext';
import { UIContext } from '../../contexts/UIContext';
//API Functions
import { addError } from '../../API/AddError'
import { startQuiz } from '../../API/StartQuiz';
import { deleteDME } from '../../API/DeleteDME';
import { getQuizScore } from '../../API/GetQuizScore';
import { getEndQuizInfo } from '../../API/GetEndQuizInfo';
//import { addScormDmeValue } from '../../API/AddScormDmeValue';
//import { checkIfAllQuizAuditsPresent } from '../../API/CheckIfAllQuizAuditsPresent';
//import { checkIfSessionIsStillActive } from '../../API/CheckIfSessionIsStillActive';
import { createQuizAudit } from '../../API/CreateQuizAudit';
import { setSessionActivityFlag } from '../../API/SetSessionActivityFlag';
import { updateEndQuizInfo } from '../../API/UpdateEndQuizInfo';
import { getPreQuizStartResult } from '../../API/PreQuizStartResult';
import { applyAllProfilePoints } from '../../API/ApplyAllProfilePoints';
import { createQuizAnswerAudit } from '../../API/CreateQuizAnswerAudit';
import { generateAndSendCertificate } from '../../API/GenerateAndSendCertificate';
//Components
import BrandedCard from '../../Components/BrandedCard';
import IntroductionPage from './InfopathIntroduction';
import InfopathQuestion from './InfopathQuestion';
import InfopathComplete from './InfopathComplete';
import InfopathProgressBar from './InfopathProgressBar';
import InfopathButtonPanel from './InfopathButtonPanel';
import InfopathPageNavigation from './InfopathPageNavigation';
//Styles
import styles from '../../styles/infopathQuiz.module.css'

interface Props {
  quiz: InfopathQuiz,
  learningModuleData: LearningModule
}

export const CoursePlayer = ({ quiz, learningModuleData }:Props, props: any) => {
  const history = useHistory()
  let { courseId } = useParams<{ courseId: string }>();
  const { setCurrentQuizQuestions, currentSelection, setIpQuizSubmissionStillInProgress, setQuizStillInProgress, setHasLoadedSessionData } = useContext(InfopathContext);
  const { setTrainingListUpdateRequired, setDashboardUpdateRequired } = useContext(MyTrainingListContext)
  const { curSessionID, setCurSessionID, setCurSelectedModuleID, setShowResultsModal  } = useContext(SessionContext)
  const [currentQuizSessionData, setCurrentQuizSessionData] = useState<null | Promise<any> | Array<InfopathPreQuizSessionDataType>>(null); 
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
  const [questionCount] = useState<number>(quiz.quizQuestions.length)
  const { oidcUser } = useReactOidc();
  const { EnableActivityLogs } = useContext(UIContext)
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0;
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  
  const setUpQuizAnswers = (currentQuiz:InfopathQuiz ) => {
    var answers = currentQuiz.quizQuestions.map((q) => {
      q.userSelection = 0; //Adding the users selection to the question object, default 0 - invalid selection
     return q
    })
    return answers;
  };

  const introductionPage = (pageNumber: number) => {
    if (pageNumber === 0) { // Page 0  is the introdution page
      return <IntroductionPage 
        introPage={quiz.introductionPage}
        questionCount={questionCount}
        />;
    }
  };

  const currentQuestion = (pageNumber: number) => {
    for (let i = 0; i < quiz.quizQuestions.length; i++) {
      const question = quiz.quizQuestions[i];
      if (question.questionIndex === pageNumber) {
        return <InfopathQuestion 
          question={question} 
          updatePageAudit={updatePageAudit} 
          currentSelection={currentSelection}
          />;
      }
    }
  };

  const completedPage = (pageNumber: number) => {
    if (pageNumber === quiz.quizQuestions.length + 1) {
      return <InfopathComplete completedPage={quiz.completedPage}/>;
    }
  };

  const questionNumberText = () => {
    if (currentPageNumber === 0 ) return "Introduction"
    if (currentPageNumber === (questionCount + 1)) return "Quiz Complete"
    return `Question ${currentPageNumber} of ${questionCount}`
  };

  const calculatePercentage = () => {
    if (currentPageNumber === 0) return 0;
    if (currentPageNumber >= (questionCount + 1)) return 100;
    const percentage = (currentPageNumber / (questionCount + 1)) * 100;
    return Math.round(percentage) 
  };

  const increasePageCount = () => {
    if (currentPageNumber < (quiz.quizQuestions.length + 1)) {
      setCurrentPageNumber(currentPageNumber + 1);
    }
    addError(oidcUser.access_token, userId, orgDomain, "Clicked right arrow in quiz", null, "InfopathQuizPlayer.tsx", 6, EnableActivityLogs)
  };

  const decreasePageCount = () => {
    if (currentPageNumber === 0) return;
    setCurrentPageNumber(currentPageNumber - 1);    
    addError(oidcUser.access_token, userId, orgDomain, "Clicked left arrow in quiz", null, "InfopathQuizPlayer.tsx", 6, EnableActivityLogs)
  };

  const updatePageAudit = (questionDuration: number, answerId: number | null, questionId: number ) => {
      if (currentQuizSessionData != null && Array.isArray(currentQuizSessionData)) {
        setQuizStillInProgress(true);
        setSessionActivityFlag(oidcUser.access_token, userId, orgDomain, false, currentQuizSessionData[0].sessionID, true).then((resActFlag) => {
          // if (resActFlag !== null && resActFlag === false) {
          //   console.log('setSessionActivityFlag setting true FAILED');
          // }
          // else {
          //   console.log('setSessionActivityFlag setting true PASSED');
          // }
          createQuizAudit(oidcUser.access_token, userId, orgDomain, currentQuizSessionData[0].sessionID, ("Q" + questionId), questionDuration).then((res) => {
            if (res >= 1){
              const toolbookAnswerId = answerId === null ? 0 : answerId; //API accepts int only
              createQuizAnswerAudit(oidcUser.access_token, userId, orgDomain, currentQuizSessionData[0].sessionID, "Q" + questionId, toolbookAnswerId).then((res2) => {
                setSessionActivityFlag(oidcUser.access_token, userId, orgDomain, false, currentQuizSessionData[0].sessionID, false).then((resActFlag2) => {
                  // if (resActFlag2 !== null && resActFlag2 === false) {
                  //   console.log('setSessionActivityFlag2 setting false FAILED');
                  // }
                  // else {
                  //   console.log('setSessionActivityFlag2 setting false PASSED');
                  // }
                  if (res2 < 1) {
                    addError(oidcUser.access_token, userId, orgDomain, `createQuizAnswerAudit returned ${res2} from API`, null, "InfopathQuizPlayer.tsx", 1)
                  }
                  setQuizStillInProgress(false);
                })
              })
            } else {
              addError(oidcUser.access_token, userId, orgDomain, `createQuizAudit returned ${res} from API (ERROR)`, null, "InfopathQuizPlayer.tsx", 1)
              setSessionActivityFlag(oidcUser.access_token, userId, orgDomain, false, currentQuizSessionData[0].sessionID, false).then((resActFlag2) => {
                // if (resActFlag2 !== null && resActFlag2 === false) {
                //   console.log('setSessionActivityFlag2 setting false FAILED');
                // }
                // else {
                //   console.log('setSessionActivityFlag2 setting false PASSED');
                // }
                setQuizStillInProgress(false);
              })
            }
          })
        })
      }
    }

  const handleExit = () => {
    setSessionActivityFlag(oidcUser.access_token, userId, orgDomain, false, curSessionID, false).then((resActFlag2) => {
      setTrainingListUpdateRequired(true) 
      setDashboardUpdateRequired(true)
      history.goBack()
    })
  }

  const submitScore = async () => {
    
      setIpQuizSubmissionStillInProgress(true);
      addError(oidcUser.access_token, userId, orgDomain, `Completed ${quiz.title} Quiz`, null, "InfopathQuizPlayer.tsx", 6, EnableActivityLogs)

      let scoreResult = 0;

      // const addScormDMEValue = async () => {
      //   const uniquePlaceHolder = "SessionInprogress_" + (curSessionID !== null ? curSessionID : "") + "_" + (curSessionID !== null ? curSessionID : "");
      //   const result = await addScormDmeValue(oidcUser.access_token, userId, learningModuleData.id, uniquePlaceHolder, uniquePlaceHolder)
      //   return result
      // }

      // const checkIfAllQuizAuditsArePresent = async () => {
      //   const result = await checkIfAllQuizAuditsPresent(oidcUser.access_token, userId, learningModuleData.id, curSessionID, questionCount)
      //   return result
      // }

      const sendGetQuizScore = async () => {
        const result = await getQuizScore(oidcUser.access_token, userId, orgDomain, curSessionID)
        scoreResult = result
        return result
      }

      const applyAllTheProfilePoints = async () => {
        const resultFinal = await applyAllProfilePoints(oidcUser.access_token, userId, orgDomain, learningModuleData.id, curSessionID, ((oidcUser?.session_state === undefined || oidcUser?.session_state === null) ? "" : oidcUser?.session_state))
        return resultFinal
      }

      const sendCertificate = async () => {
        const isProductionServer = process.env.REACT_APP_ENV === "prodserver" || process.env.REACT_APP_ENV === "production";
        const targetFolder = isProductionServer? `${process.env.REACT_APP_BASE_DIR}\\Files\\GeneratedCertificates` : `${process.env.REACT_APP_BASE_DIR}\\files\\General\\Scorm\\Content\\${courseId}`            
        const resultFinal = await generateAndSendCertificate(oidcUser.access_token, userId, orgDomain, learningModuleData.courseID, 'en-AU', true, targetFolder) // TO DO change the culture & target folder hard code later
        return resultFinal
      }

      const sendGetEndQuizInfo = async (data: any) => {
        const result = await getEndQuizInfo(oidcUser.access_token, userId, orgDomain, curSessionID, 46664, scoreResult, ((oidcUser?.session_state === undefined || oidcUser?.session_state === null) ? "" : oidcUser?.session_state))
        return result
      }

      const sendUpdateEndQuizInfo = async (data: any) => {
        const result = await updateEndQuizInfo(
          oidcUser.access_token, 
          data[0].userID,
          orgDomain,
          data[0].moduleID,
          data[0].quizID,
          data[0].courseID,
          data[0].passMark,
          curSessionID,
          46664, // TO DO
          scoreResult,
          data[0].oldCourseStatus,
          data[0].newCourseStatus,
          data[0].newQuizStatus,
          data[0].quizFrequency,
          data[0].quizCompletionDate
        )
        return result;
      }

      setTrainingListUpdateRequired(false)
      setSessionActivityFlag(oidcUser.access_token, userId, orgDomain, false, curSessionID, true).then((resActFlag3) => {
        // if (resActFlag3 !== null && resActFlag3 === false) {
        //   console.log('setSessionActivityFlag3 setting true FAILED');
        // }
        // else {
        //   console.log('setSessionActivityFlag3 setting true PASSED');
        // }
          sendGetQuizScore().then((score) => {
              sendGetEndQuizInfo(score).then((eqi) => {
                sendUpdateEndQuizInfo(eqi).then((dataRes) => {
                  applyAllTheProfilePoints().then((ppUpdate) => {
                    if (dataRes.generateCertificate) {
                        sendCertificate()
                    }
                    deleteDME(oidcUser.access_token, userId, orgDomain, learningModuleData.id).then(() => {
                    }) 
                    setSessionActivityFlag(oidcUser.access_token, userId, orgDomain, false, curSessionID, false).then((resActFlag4) => {
                      // if (resActFlag4 !== null && resActFlag4 === false) {
                      // console.log('setSessionActivityFlag4 setting false FAILED');
                      // }
                      // else {
                      // console.log('setSessionActivityFlag4 setting false PASSED');
                      // }
                      setTrainingListUpdateRequired(true) 
                      setDashboardUpdateRequired(true)                     
                      setIpQuizSubmissionStillInProgress(false);
                      setShowResultsModal(true)
                      history.push( `/mytraining/${courseId}`);
                    })   
                  })
                })
              })
          })
      })
  };

  const isMounted = useRef<boolean>(false)
  useEffect(() => {     
    setHasLoadedSessionData (false)
    setTrainingListUpdateRequired(false)
    isMounted.current = true;
    setCurSelectedModuleID(learningModuleData.id);
    const quizAnswers = setUpQuizAnswers(quiz)
    setCurrentQuizQuestions(quizAnswers);

    if (quiz) {
        getPreQuizStartResult(oidcUser.access_token, userId, orgDomain, learningModuleData.id, false, ((oidcUser?.session_state === undefined || oidcUser?.session_state === null) ? "" : oidcUser?.session_state), "infopath").then((data) => {
          if (isMounted.current){
            setCurrentQuizSessionData(data);
          }
          startQuiz(oidcUser.access_token, userId, orgDomain, data[0].sessionID, ((oidcUser?.session_state === undefined || oidcUser?.session_state === null) ? "" : oidcUser?.session_state)).then((isStarted) => {
            if (isStarted) {
              if (isMounted.current){
                setCurSessionID(data[0].sessionID);
              }
            } else {
              addError(oidcUser.access_token, userId, orgDomain, "Failed to start quiz", null, "InfopathQuizPlayer.tsx", 1);
              ;
            }
            setHasLoadedSessionData (true)
          });
          return data;
        });
    }
    return () => {
      setHasLoadedSessionData (false)
      isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      <h3>{learningModuleData.name} \ <span style={{ color: "#63C2FF" }}>{quiz.title}</span></h3>
        <BrandedCard>
          <div className={styles.questionBar}>
            <div style={{display:"inline-block"}}>
              <h3 className={styles.questionNumber}>{questionNumberText()}</h3>
            </div>
            <div className={styles.topButtonPanel}>
              <InfopathButtonPanel handleExit={handleExit}/>
            </div>
          </div>
            <InfopathProgressBar percentage={calculatePercentage()} />
          <div className={styles.questionContainer}>
            {introductionPage(currentPageNumber)}
            {currentQuestion(currentPageNumber)}
            {completedPage(currentPageNumber)}
          </div>
          <div className={styles.bottomButtonPanel}>
            <InfopathPageNavigation 
              currentPageNumber={currentPageNumber} 
              currentSelection={currentSelection}
              questionCount={questionCount}
              submitScore={submitScore}
              increasePageCount={increasePageCount} 
              decreasePageCount={decreasePageCount}
              />
          </div>
      </BrandedCard>
    </div>
  );
}

export default CoursePlayer;
