//React + Typescript
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from "react-router";

// Libsimport
import { useReactOidc } from '@axa-fr/react-oidc-context';

//Contexts
import { UIContext } from '../../../contexts/UIContext';
import { LoaderContext } from '../../../contexts/LoaderContext';
import { TranslationsContext } from '../../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';

//Intro Lib
import { Steps, Hints } from "intro.js-react";

//API Functions 
import { addError } from '../../../API/AddError'
import { GetTipVideos } from '../../../API/Administration/Organisation/GetTipVideos';
import CloseWhiteIcon from "@material-ui/icons/Close";
import Modal from '../../../Components/Modal';
import ProceedIcon from "../../../CustomIcons/ProceedIcon";
import styles from "../../../styles/managetipvideos.module.css";
import responsivestyles from "../../../styles/responsiveGridLayout.module.css";

//General Functions
import getColor from '../../../functions/getColor';
import validateEmail from '../../../functions/valudateEmail';

//Components
import CustomTooltip from '../../../Components/CustomTooltip';
import UnbrandedCard from '../../../Components/UnbrandedCard';
import LoaderContainer from '../../../Components/LoaderContainer';
import GuideIcon from '../../../CustomIcons/GuideIcon';

//MUI Components
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';
import CachedIcon from '@material-ui/icons/Cached';

import EditIcon from '../../../CustomIcons/SupportDetailsEditIcon';
import AddIconMenu from '../../../CustomIcons/NavigationMenu/AddIconMenu';
import ReverttIcon from '../../../CustomIcons/SupportDetailsReverttIcon';
import SaveIcon from '../../../CustomIcons/SupportDetailSaveIcon';
import CancelIcon from '../../../CustomIcons/SupportDetailCancelIcon';

import moment from 'moment';
import BrandedCard from '../../../Components/BrandedCard';
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';
import { TipVideo } from '../../../globalTypes';
import { Checkbox, CircularProgress, TableContainer, TablePagination, FormControl, Select } from '@material-ui/core';
import DeleteIcon from '../../../CustomIcons/DeleteIcon';
import SaveTipVideos from '../../../API/Administration/Organisation/SaveTipVideos';
import UpArrowIcon from '../../../CustomIcons/UpArrowIcon';
import DownArrowIcon from '../../../CustomIcons/DownArrowIcon';

const ManageTipVideos = (props: any): JSX.Element => {
  const history = useHistory();
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext)
  const { color } = useContext(CorperateThemeContext).theme;
  const { setMessage } = useContext(LoaderContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection, showBackButton, setTipVideosReloadRequired} = useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { oidcUser } = useReactOidc();
  const [tipVideos, setTipVideos] = useState<Array<TipVideo>>([]);
  const [showConfirmModalDelete, setshowConfirmModalDelete] = useState<boolean>(false);

  const [vtitle, setVTitle] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [videoID, setVideoID] = useState<number>(0);

  const [rowsPerPage, setRowsPerPage] = React.useState<number>(20);
  const [page, setPage] = React.useState<number>(0);

  const [vtitleForAdd, setVTitleForAdd] = useState<string>("");
  const [categoryForAdd, setCategoryForAdd] = useState<string>("");
  const [urlForAdd, setUrlForAdd] = useState<string>("");
  const [isDefaultForAdd, setDefaultForAdd] = useState<boolean>(true);
  const [pageReferenceForAdd, setPageReferenceForAdd] = useState<string>("");

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [isTipVideoGettingAdded, setTipVideoGettingAdded] = useState<boolean>(false);
  const [isTipVideoDefault, setTipVideoDefault] = useState<boolean>(false);
  const [tipVideoFilter, setTipVideoFilter] = useState<string>("");
  const [selectedPageReference, setSelectedPageReference] = useState<any>({value : ""});
  const [pageTitleValues, setPageTitleValues] = useState<string[]>([]);

  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  const language = useRef<string>("en-AU");
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: "#addNewVideo",
        title: allResourcesToTranslations.addnewtipvideo,
        intro: allResourcesToTranslations.addnewtipvideomsg
      },

    ]
  })

  // Sorting  
  const [captionDescending, setCaptionDescending] = useState<boolean>(false)
  const [currentSortBy, setCurrentSortBy] = useState<string>("")
  const [reverseNextSort, setReverseNextSort] = useState<boolean>(false)
  const [sortInProgress, setSortInProgress] = useState<boolean>(false)

  const sortResultsByAsync = async (sortBy: "Caption", sortGivenItemInDesc: boolean ) => {
      if (tipVideos == null || tipVideos.length <= 0) {
          return tipVideos;
      }
      var result = tipVideos.slice(0)
      setCurrentSortBy(sortBy)
      switch(sortBy) {
          case "Caption":
              if (sortGivenItemInDesc) {
                  result.sort((a, b) => {
                      if (b === null || b.title === null) { return -1; }
                      if (a === null || a.title === null) { return 1; }
                      if(a.title.toLowerCase() > b.title.toLowerCase()) { return -1; }
                      if(a.title.toLowerCase() < b.title.toLowerCase()) { return 1; }
                      return 0;
                  })
                  if (reverseNextSort) {
                      setCaptionDescending(false)
                  }
              } else {
                  result.sort((a, b) => {
                      if (b === null || b.title === null) { return 1; }
                      if (a === null || a.title === null) { return -1; }
                      if(a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
                      if(a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
                      return 0;
                  })
                  if (reverseNextSort) {
                    setCaptionDescending(true)
                  }
              }
              setTipVideos(result);
              break;
          default:
              break;
      }
      return result.slice(0);
  }
  
  const areTipVideosManagedBySuperAdmin = () => {
    if (!!history.location.pathname && history.location.pathname.includes('/managetipvideossuper')) {
      return true;
    }
    return false;
  }

  const sortResultsBy = async (sortBy: "Caption", sortGivenItemInDesc: boolean ) => {
      setSortInProgress(true);
      await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
          setCurrentSortBy(sortBy)
          setTipVideos(result)
          setPage(0)
          setSortInProgress(false);
      }); 
  }

  const clickCaptionAsc = () => {
      setReverseNextSort(false);
      setCaptionDescending(false)
      sortResultsBy("Caption", false)
  }

  const clickCaptionDesc = () => {
      setReverseNextSort(false);
      setCaptionDescending(true)
      sortResultsBy("Caption", true)
  }

  const handlePageReferenceChange = (tv: any, pageRefNewVal: string) => {    
    if (!!tv && !!pageRefNewVal) {
      if (tv.isBeingAdded) {
        setPageReferenceForAdd(pageRefNewVal);
      }
      // setTipVideos(tipVideos.map(tvc => {
      //   if (!!tvc && (tvc === tv || (tvc.title == tv.title && tvc.category == tv.category && tvc.url == tv.url && tvc.active == tv.active))) {  
      //     tvc.pageReference = pageRefNewVal;
      //     return tvc;
      //   }
      //   return tvc;
      // }));
      let arr : TipVideo[] = [];
      for (let tvidx=0; tvidx<tipVideos.length; tvidx++) {
        let tvc : TipVideo = tipVideos[tvidx];
        if (!!tvc && (tvc === tv || 
          (tvc.title == tv.title && tvc.category == tv.category && tvc.url == tv.url && tvc.active == tv.active))) {  
          tvc.pageReference = pageRefNewVal;
        }
        arr.push(tvc);
      }
      setTipVideos(!!arr ? [...arr] : arr);
    }
  }

  const handleTipVideoDefaultFlag = (tv: any, givenDefault: boolean) => {    
    if (!!tv) {
      if (tv.isBeingAdded) {
        setDefaultForAdd(givenDefault);
      }
      if (givenDefault === true) {
        tv.orgID = null;
      }
      else if (givenDefault === false) {
        tv.orgID = parseInt(orgId);
      }
      let arr : TipVideo[] = [];
      for (let tvidx=0; tvidx<tipVideos.length; tvidx++) {
        let tvc : TipVideo = tipVideos[tvidx];
        if (!!tvc && (tvc === tv || 
          (tvc.title == tv.title && tvc.category == tv.category && tvc.url == tv.url && tvc.active == tv.active))) {  
          tvc.orgID = tv.orgID; //tvc.orgID == null ? parseInt(orgId) : null;
        }
        arr.push(tvc);
      }
      // tipVideos.map(tvc => {
      //   if (!!tvc && (tvc === tv || 
      //       (tvc.title == tv.title && tvc.category == tv.category && tvc.url == tv.url && tvc.active == tv.active))) {  
      //     tvc.orgID = tvc.orgID == null ? parseInt(orgId) : null;
      //     return tvc;
      //   }
      //   return tvc;
      // });
      setTipVideos(!!arr ? [...arr] : arr);
    }
  }

  const setGuideMe = () => {
    if (tipVideos == null || tipVideos == undefined || tipVideos.length <= 0) {
      let updateSteps = []
      updateSteps.push(
        {
          element: "#addNewVideo",
          title: allResourcesToTranslations.addnewtipvideo,
          intro: allResourcesToTranslations.addnewtipvideomsg
        }
      )
      setIntro({ ...intro, steps: [...updateSteps] })
      return;
    }
    
    let isSAdmin = areTipVideosManagedBySuperAdmin();
    let updateSteps = []
    if (tipVideos.some(tv => !!tv && (tv.isBeingAdded || tv.isBeingEdited)))
    {
      if (tipVideos.filter(tv => !!tv && tv.isBeingEdited).length <= 1 && tipVideos.length <= 1) {
        updateSteps.push(
          {
            element: "#addNewVideo",
            title: allResourcesToTranslations.addnewtipvideo,
            intro: allResourcesToTranslations.addnewtipvideomsg
          },
          {
            element: ".tipVideoFilter",
            title: allResourcesToTranslations.tvfiltertitle,
            intro: allResourcesToTranslations.tvfiltermsg
          },
          {
            element: "#totalCountOfTipVideos",
            title: allResourcesToTranslations.tvtotaltitle,
            intro: allResourcesToTranslations.tvtotalmsg
          },
          {
            element: "#CaptionForAdd",
            title: allResourcesToTranslations.tvccaptionadd,
            intro: allResourcesToTranslations.tvccaptionaddmsg,
          },
          {
            element: "#CategoryForAdd",
            title: allResourcesToTranslations.tvccategoryadd,
            intro: allResourcesToTranslations.tvccategoryaddmsg,
        
          },
          {
            element: "#URLForAdd",
            title: allResourcesToTranslations.tvcurladd,
            intro: allResourcesToTranslations.tvcurladdmsg,
          },
          {
            element: "#Save",
            title: allResourcesToTranslations.tvcsave,
            intro: allResourcesToTranslations.tvcsavemsg,
          },
          {
            element: "#Cancel",
            title: allResourcesToTranslations.tvccancel,
            intro: allResourcesToTranslations.tvccancelmsg,
          },
          {
            element: ".Caption",
            title: allResourcesToTranslations.tvccaption,
            intro: allResourcesToTranslations.tvccaptionmsg,
          },
          {
            element: ".Category",
            title: allResourcesToTranslations.tvccategory,
            intro: allResourcesToTranslations.tvccategorymsg,
          },
          {
            element: ".Url",
            title: allResourcesToTranslations.url,
            intro: allResourcesToTranslations.tvcurlmsg,
          },
          {
            element: ".IsDefault",
            title: allResourcesToTranslations.tvdefault,
            intro: allResourcesToTranslations.tvdefaultmsg,
          },
          {
            element: ".OrgName",
            title: allResourcesToTranslations.tvorgname,
            intro: allResourcesToTranslations.tvorgnamemsg,
          },
          {
            element: ".PageReference",
            title: allResourcesToTranslations.tvpageref,
            intro: allResourcesToTranslations.tvpagerefmsg,
          },
          {
            element: "#Delete",
            title: allResourcesToTranslations.videodelete,
            intro: allResourcesToTranslations.tvcdeletemsg,
          }
        )
        setIntro({ ...intro, steps: [...updateSteps] })
      }
      else {
        updateSteps.push(
          {
            element: "#addNewVideo",
            title: allResourcesToTranslations.addnewtipvideo,
            intro: allResourcesToTranslations.addnewtipvideomsg
          },
          {
            element: ".tipVideoFilter",
            title: allResourcesToTranslations.tvfiltertitle,
            intro: allResourcesToTranslations.tvfiltermsg
          },
          {
            element: "#totalCountOfTipVideos",
            title: allResourcesToTranslations.tvtotaltitle,
            intro: allResourcesToTranslations.tvtotalmsg
          },
          {
            element: "#CaptionForAdd",
            title: allResourcesToTranslations.tvccaptionadd,
            intro: allResourcesToTranslations.tvccaptionaddmsg,
          },
          {
            element: "#CategoryForAdd",
            title: allResourcesToTranslations.tvccategoryadd,
            intro: allResourcesToTranslations.tvccategoryaddmsg,
        
          },
          {
            element: "#URLForAdd",
            title: allResourcesToTranslations.tvcurladd,
            intro: allResourcesToTranslations.tvcurladdmsg,
          },
          {
            element: ".IsDefault",
            title: allResourcesToTranslations.tvdefault,
            intro: allResourcesToTranslations.tvdefaultmsg,
          },
          {
            element: ".OrgName",
            title: allResourcesToTranslations.tvorgname,
            intro: allResourcesToTranslations.tvorgnamemsg,
          },
          {
            element: ".PageReference",
            title: allResourcesToTranslations.tvpageref,
            intro: allResourcesToTranslations.tvpagerefmsg,
          },
          {
            element: "#Save",
            title: allResourcesToTranslations.tvcsave,
            intro: allResourcesToTranslations.tvcsavemsg,
          },
          {
            element: "#Cancel",
            title: allResourcesToTranslations.tvccancel,
            intro: allResourcesToTranslations.tvccancelmsg,
          },
          {
            element: ".Caption",
            title: allResourcesToTranslations.tvccaption,
            intro: allResourcesToTranslations.tvccaptionmsg,
          },
          {
            element: ".Category",
            title: allResourcesToTranslations.tvccategory,
            intro: allResourcesToTranslations.tvccategorymsg,
          },
          {
            element: ".Url",
            title: allResourcesToTranslations.url,
            intro: allResourcesToTranslations.tvcurlmsg,
          },
          {
            element: ".IsDefault",
            title: allResourcesToTranslations.tvdefault,
            intro: allResourcesToTranslations.tvdefaultmsg,
          },
          {
            element: ".OrgName",
            title: allResourcesToTranslations.tvorgname,
            intro: allResourcesToTranslations.tvorgnamemsg,
          },
          {
            element: ".PageReference",
            title: allResourcesToTranslations.tvpageref,
            intro: allResourcesToTranslations.tvpagerefmsg,
          },
          {
            element: "#Edit",
            title: allResourcesToTranslations.videomodify,
            intro: allResourcesToTranslations.tvceditmsg,
          },
          {
            element: "#Delete",
            title: allResourcesToTranslations.videodelete,
            intro: allResourcesToTranslations.tvcdeletemsg,
          }
        )
        if (!isSAdmin) {
          updateSteps = updateSteps.filter(s => !!s && s.element != ".IsDefault" && s.element != ".IsDefaultForAdd");
        }
        setIntro({ ...intro, steps: [...updateSteps] })
      }
    }
    else {
      updateSteps.push(
        {
          element: "#addNewVideo",
          title: allResourcesToTranslations.addnewtipvideo,
          intro: allResourcesToTranslations.addnewtipvideomsg
        },
        {
          element: ".tipVideoFilter",
          title: allResourcesToTranslations.tvfiltertitle,
          intro: allResourcesToTranslations.tvfiltermsg
        },
        {
          element: "#totalCountOfTipVideos",
          title: allResourcesToTranslations.tvtotaltitle,
          intro: allResourcesToTranslations.tvtotalmsg
        },
        {
          element: ".Caption",
          title: allResourcesToTranslations.tvccaption,
          intro: allResourcesToTranslations.tvccaptionmsg,
        },
        {
          element: ".Category",
          title: allResourcesToTranslations.tvccategory,
          intro: allResourcesToTranslations.tvccategorymsg,
        },
        {
          element: ".Url",
          title: allResourcesToTranslations.url,
          intro: allResourcesToTranslations.tvcurlmsg,
        },
        {
          element: ".IsDefault",
          title: allResourcesToTranslations.tvdefault,
          intro: allResourcesToTranslations.tvdefaultmsg,
        },
        {
          element: ".OrgName",
          title: allResourcesToTranslations.tvorgname,
          intro: allResourcesToTranslations.tvorgnamemsg,
        },
        {
          element: ".PageReference",
          title: allResourcesToTranslations.tvpageref,
          intro: allResourcesToTranslations.tvpagerefmsg,
        },
        {
          element: "#Edit",
          title: allResourcesToTranslations.videomodify,
          intro: allResourcesToTranslations.tvceditmsg,
        },
        {
          element: "#Delete",
          title: allResourcesToTranslations.videodelete,
          intro: allResourcesToTranslations.tvcdeletemsg,
        }
      )
      if (!isSAdmin) {
        updateSteps = updateSteps.filter(s => !!s && s.element != ".IsDefault" && s.element != ".IsDefaultForAdd");
      }
      setIntro({ ...intro, steps: [...updateSteps] })
    }
  }

  useEffect(() => {
    setGuideMe();
  }, [])

  useEffect(() => {
    setGuideMe();
  }, [tipVideos, isTipVideoGettingAdded])

  const loadTipVideos = () => {
    GetTipVideos(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((result: any) => {
      if (result.isSuccess && result.error == '') {
        if (result.tipVideos !== null) {
          let isSAdmin = areTipVideosManagedBySuperAdmin();
          let curOrgId = parseInt(orgId);
          setTipVideos(isSAdmin ? [...result.tipVideos] : [...(result.tipVideos as TipVideo[]).filter(tv => !!tv && tv.orgID != null && tv.orgID == curOrgId)]);
          //setTipVideos([...result.tipVideos]);
        }
      }
      else {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage(allResourcesToTranslations.tvmloadfail);
      }
      setLoading(false);
    });
  };

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  useEffect(() => {
    setTitle(allResourcesToTranslations.managetipvideostitle)
    setTitleEnglish(pageTitles.managetipvideos);
    updateBackButtonRoute("");
    setMenuSelection(allResourcesToTranslations.managetipvideosmenu);
    setLoading(true);
    loadTipVideos();
    return () => { };
  }, []);

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  useEffect(() => {
    if (showWarningModal) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      }, 5000)
    }
  }, [showWarningModal])

  useEffect(() => {
    if (!!pageTitles)  {
      var resArr : string[] = Object.values(pageTitles) as string[];
      resArr = [...resArr].sort((a, b) => a.localeCompare(b) * 1); // Sort by ascending
      resArr.splice(0, 0, allResourcesToTranslations.selectpagereference);
      setPageTitleValues(resArr);
    }
  }, [pageTitles])

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "#fff" }
    } else {
      return {}
    }
  }

  const handleEdit = (name: any, url: string, category: string, index: number) => {
    setVideoID(index);
    setVTitle(name);
    setCategory(category);   
    setUrl(url);   
    let updatedSelectedTipVideo = tipVideos.map((item: TipVideo) => {
      if (!!item) {
        handleCancel(item.title, item.videoID);
      }
      if (item.videoID == index && item.title === name) {        
        item.isBeingEdited = true;
      }
      return item;
    });
    setTipVideos([...updatedSelectedTipVideo]);
  };

  const handleCancel = (name: any, index: number) => {
    const updatedSelectedTipVideo = tipVideos.map((item: TipVideo) => {
      if (!!item && item.title === name && item.videoID == index) {
        item.isBeingEdited = false;
      }
      return item;
    }).filter(tv => !!tv && !tv.isBeingAdded);
    setTipVideos([...updatedSelectedTipVideo]);
  };

  const handleDelete = (index: number) => {
    if (index <= 0) {
      const updatedSelectedTipVideo = tipVideos.filter(tv => !!tv && !tv.isBeingAdded);
      setTipVideos([...updatedSelectedTipVideo]);
    }
    else {      
      handleConfirmModalDelete(true);
      setVideoID(index);
    }
  }

  const handleConfirmModalDelete = (value: boolean) => {
    setshowConfirmModalDelete(value);
  }

  const handleCancelConfirmPopupDelete = () => {
    handleConfirmModalDelete(false);
    setIsSaving(false);
  }

  const handleResetData = () => {
    setVTitle('');
    setUrl('');
    setCategory('');
    setVideoID(0);
    setVTitleForAdd('');
    setUrlForAdd('');  
    setCategoryForAdd('');
  }

  const handleProceedDelete = () => {
    handleConfirmModalDelete(false);
    if (videoID > 0) {
      let tvcurs = !!tipVideos ? tipVideos.filter(tvv => !!tvv && tvv.videoID == videoID) : null;
      let tvcur = tvcurs == null || tvcurs.length <= 0 ? null : tvcurs[0];
      SaveTipVideos(oidcUser.access_token, userId, orgDomain, parseInt(orgId), videoID, vtitle, url, category, "delete", !!tvcur ? tvcur.pageReference : "", areTipVideosManagedBySuperAdmin() ? isDefaultForAdd : false).then((result) => {
        if (!!result && result.isSuccess) {
          setShowWarningModal(true);
          setHasErrored(false);
          setSaveSuccess(true);
          loadTipVideos();
          setModalPopupMessage(allResourcesToTranslations.tvmdelsuccess);
          handleResetData();
        }
        else {
          setShowWarningModal(true);
          setHasErrored(true);
          setSaveSuccess(false);
          setModalPopupMessage(allResourcesToTranslations.tvmdelfail);
        }
      })
    }
  }

  const handleSave = () => {
    setIsSaving(true);
    let curVideoID = 0;
    //Add New Video  
    if ((videoID == null || videoID == undefined)) {
      curVideoID = 0;
    }
    else {
      //Update Video
      curVideoID = videoID;
    }
    let errorString = '';

    let cururl = curVideoID <= 0 ? urlForAdd :url;
       
    const regex = new RegExp('(https://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');    
      
    if(!regex.test(cururl))
    {
      errorString = errorString + allResourcesToTranslations.tvminvalidurl + ';';
    } 
    
    if (errorString !== ''){
      setShowWarningModal(true);
      setModalPopupMessage(errorString);
      setHasErrored(true);
      setSaveSuccess(false);
      return;
    }
    
    setTipVideosReloadRequired(true);
    let tvcurs = (!!tipVideos && videoID > 0) ? tipVideos.filter(tvv => !!tvv && tvv.videoID == videoID) : null;
    let tvcur = (videoID <= 0 || tvcurs == null || tvcurs.length <= 0) ? null : tvcurs[0];
    if (curVideoID === 0 && !!tipVideos && tipVideos.length > 0) {
      let gettingAdded = tipVideos.filter(tv => !!tv && !!tv.active && !!tv.isBeingAdded && !!tv.videoID && tv.videoID <= 0);
      if (!!gettingAdded && gettingAdded.length > 0) {
        tvcur = gettingAdded[0];
      }
    }
    SaveTipVideos(oidcUser.access_token, userId, orgDomain, parseInt(orgId), curVideoID, (curVideoID == 0 ? vtitleForAdd : vtitle),
                    (curVideoID == 0 ? urlForAdd : url), (curVideoID == 0 ? categoryForAdd : category), 
                    curVideoID == 0 ? "add" : "update", !!tvcur ? tvcur.pageReference : pageReferenceForAdd, 
                    areTipVideosManagedBySuperAdmin() ? ((curVideoID === 0 && !!tvcur && !!tvcur.isBeingAdded && isDefaultForAdd) || (curVideoID > 0 && !(!!tvcur && !!tvcur.isBeingEdited && !!tvcur.orgID))) : false).then((result) => {
      if (!!result && result.isSuccess) {
        setShowWarningModal(true);
        setHasErrored(false);
        setSaveSuccess(true); 
        setIsSaving(false);      
        loadTipVideos();
        setModalPopupMessage(allResourcesToTranslations.tvmsavesuccess);
        handleResetData();
      }
      else {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage(!!result && !!result.error ? result.error : allResourcesToTranslations.tvminvalidtitle);
      }
    })
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const handleAddNewTipVideo = () => {
    setVideoID(0);
    setTipVideoGettingAdded(true);

    if (!tipVideos.some(tv => !!tv && tv.isBeingAdded)) {
      let newTV : TipVideo = { videoID: -1, active: true, category: "", categoryID: -1, isBeingAdded: true, isBeingEdited: false, url: "", title: "", orgID: parseInt(orgId), pageReference: null, orgName: null};
      setDefaultForAdd(false);
      tipVideos.splice(0, 0, newTV);
    }
    setTipVideos([...tipVideos]);
  }

  const isAnyValueEmpty = () => {
    if (tipVideos == null || tipVideos == undefined || tipVideos.length <= 0 || 
        (!tipVideos.some(tv => !!tv && tv.isBeingAdded) && (!!vtitle === false || !!url === false || !!category === false || 
        vtitle.trim() === "" || url.trim() === "" || category.trim() === "")) || 
        (tipVideos.some(tv => !!tv && tv.isBeingAdded) &&  (!!vtitleForAdd === false || !!urlForAdd === false || !!categoryForAdd === false || 
        vtitleForAdd.trim() === "" || urlForAdd.trim() === "" || categoryForAdd.trim() === ""))) {
          return true;
        }
    return false;
  }

  if (loading) return <LoaderContainer />;
  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }} />
      <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => { setGuideMe(); setIntro({ ...intro, stepsEnabled: true }); }}
          className={styles.guideMeButtonSpecific} style={{ right: showBackButton ? '95px' : '25px', top: '100px' }}>
          <GuideIcon />
        </span>
      </CustomTooltip>

      <Modal modalOpen={showConfirmModalDelete} setModalOpen={handleConfirmModalDelete} type="warning">
        <div className={styles.modalContentWrapper}>
          <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>{allResourcesToTranslations.confirmation}</h2>
          <div className={styles.inputContainer}>
            <p>
              {allResourcesToTranslations.tvmconfirmdelete}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "10px",
            }}
          >
            <CustomTooltip displayText={[allResourcesToTranslations.return]}>
              <span className={styles.returnIcon} onClick={handleCancelConfirmPopupDelete}>
                <CloseWhiteIcon />
              </span>
            </CustomTooltip>
            <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
              <span className="saveData">
                <Button
                  onClick={handleProceedDelete}
                  variant="contained"
                  color="primary"
                  className="save"
                >
                  <ProceedIcon />
                </Button>
              </span>
            </CustomTooltip>
          </div>
        </div>
      </Modal>

      <div style={{float:"left"}}>
        <CustomTooltip displayText={[allResourcesToTranslations.addnewtipvideo]}>               
            <Button id="addNewVideo"                 
                type="submit"
                variant="contained"
                color="primary" disabled={!!tipVideos ? tipVideos.some(tv => !!tv && tv.isBeingAdded) : false}
                className={`saveData addNewTipVideo`}
                onClick={handleAddNewTipVideo}                     
              >
                <span style={{marginBottom: "-5px", marginLeft: "-5px"}}>
                  <AddIconMenu />
                </span>
                <span style={{marginLeft: "5px"}}> {allResourcesToTranslations.addnewtipvideo}</span>
              </Button>                     
        </CustomTooltip>
      </div>
      <div className={styles.mainWrapper}>
        <div style={{ width: '100%', clear: "both" }} >
          {tipVideos != null && tipVideos.length > 0 && (
            <div>
              <div className={styles.paginationControlsContainer}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={tipVideos.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
                <div className={`${styles.inputContainer}`}>
                  <TextField className={`${styles.input} tipVideoFilter`} variant="outlined" label={allResourcesToTranslations.tipvideosearch}
                      autoComplete="off" InputLabelProps={{ shrink: true, }}
                      value={tipVideoFilter} onChange={(e) => setTipVideoFilter(e.target.value)} />
                </div>
                <div id={"totalCountOfTipVideos"} style={{ display: 'flex', justifyContent: 'right', width: '10%', fontSize: '0.875rem', padding:'18px', fontFamily: '"Open Sans","Segoe UI",sans-serif', fontWeight: 400, lineHeight: 1.43}}>
                  {!!tipVideos ? tipVideos.filter(tv => !!tv && !tv.isBeingAdded).length.toString() + " Videos" : ""}
                </div>
              </div>
              <div className={`${styles.tableSection}`}>
                <div className={styles.tableContainer}>
                  <BrandedCard>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow key={"tvrowhead"} >
                            <TableCell key={"capcolhead"} className="Caption"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                              align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div onClick={() => {
                                                        setCaptionDescending(!captionDescending);
                                                        sortResultsBy("Caption", !captionDescending);
                                                    }}>
                                  {allResourcesToTranslations.tvccaption}
                                </div>
                                <div className={styles.columnHeadArea}>
                                    <span   style={{ opacity: (currentSortBy === "Caption" && captionDescending === false) ? "100%" : "50%", marginTop: "5px", margin: "2px", height: "6px", display:"inline-flex" }} 
                                            onClick={clickCaptionAsc}><UpArrowIcon /> </span>
                                    <span   style={{ opacity: (currentSortBy === "Caption" && captionDescending === true) ? "100%" : "50%", marginTop: "5px", margin: "0px", height: "6px", display:"inline-flex" }} 
                                            onClick={clickCaptionDesc}><DownArrowIcon /> </span>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell key={"catcolhead"} className="Category"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                              align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div >
                                  {allResourcesToTranslations.videocategory}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell key={"urlcolhead"} className="Url"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                              align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div >
                                  {allResourcesToTranslations.videourl}
                                </div>
                              </div>
                            </TableCell>
                            {
                              areTipVideosManagedBySuperAdmin() &&
                              <TableCell key={"defcolhead"} className="IsDefault"
                                style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                align="left">
                                <div className={styles.tableHeadCellContainer}>
                                  <div >
                                    {allResourcesToTranslations.videoisdefault}
                                  </div>
                                </div>
                              </TableCell>
                            }
                            <TableCell key={"namcolhead"} className="OrgName"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                              align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div >
                                  {allResourcesToTranslations.videoorgname}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell key={"pgecolhead"} className="PageReference"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                              align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div >
                                  {allResourcesToTranslations.videopageref}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell key={"edtcolhead"} className="Edit"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color, width: "150px" }}
                              align="center">
                              <div className={styles.tableHeadCellContainer}>
                                <div>
                                  {allResourcesToTranslations.videomodify}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell key={"delcolhead"} className="Delete"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color, width: "150px" }}
                              align="center">
                              <div className={styles.tableHeadCellContainer}>
                                <div>
                                  {allResourcesToTranslations.videodelete}
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tipVideos
                            .filter(tv => !!tv && ( (!!tipVideoFilter === false || tipVideoFilter.trim() == "") || 
                                          (!!tv.title && tv.title.trim().toLowerCase().includes(tipVideoFilter.trim().toLowerCase())) ||
                                          (!!tv.url && tv.url.trim().toLowerCase().includes(tipVideoFilter.trim().toLowerCase())) ||
                                          (!!tv.orgName && tv.orgName.trim().toLowerCase().includes(tipVideoFilter.trim().toLowerCase())) ||
                                          (!!tv.pageReference && tv.pageReference.trim().toLowerCase().includes(tipVideoFilter.trim().toLowerCase())) ||
                                          (!!tv.category && tv.category.trim().toLowerCase().includes(tipVideoFilter.trim().toLowerCase()))))
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((result: any, i) => {
                              return (
                                <TableRow key={i.toString() + "_tvrowval"} style={rowBackgroundColor(i)}>
                                  <TableCell key={i.toString() + "_capcolval"} align="left" width={"15%"}>
                                      {
                                      result.isBeingEdited || result.isBeingAdded ?                                       
                                      <TextField style={{width: '100%'}} variant="outlined" id="CaptionForAdd"
                                      autoComplete="off" InputLabelProps={{ shrink: true, }}
                                      defaultValue={result.title} onChange={(e) => result.isBeingAdded ? setVTitleForAdd(e.target.value) : setVTitle(e.target.value)} />
                                      :
                                      <span className={result.title}>{result.title}</span>
                                      }
                                  </TableCell>
                                  <TableCell key={i.toString() + "_catcolval"} align="left" width={"20%"}>
                                      {
                                      result.isBeingEdited || result.isBeingAdded ?                                       
                                      <TextField style={{ width: '100%' }} variant="outlined" id="CategoryForAdd"
                                      autoComplete="off" InputLabelProps={{ shrink: true, }}
                                      defaultValue={result.category} onChange={(e) => result.isBeingAdded ? setCategoryForAdd(e.target.value) : setCategory(e.target.value)} />
                                      :
                                      <span className={result.category}>{result.category}</span>
                                      }                                    
                                  </TableCell>
                                  <TableCell key={i.toString() + "_urlcolval"} align="left" className={`usedefault${i}`} width={"15%"}>                                
                                      {
                                      result.isBeingEdited || result.isBeingAdded ?                                       
                                      <TextField style={{ width: '100%' }} variant="outlined" id="URLForAdd"
                                      autoComplete="off" InputLabelProps={{ shrink: true, }}
                                      defaultValue={result.url} onChange={(e) =>  result.isBeingAdded ? setUrlForAdd(e.target.value) : setUrl(e.target.value)} />
                                      :
                                      <span className={result.url}>{result.url}</span>
                                      }                                    
                                  </TableCell>
                                  {
                                    areTipVideosManagedBySuperAdmin() &&
                                    <TableCell key={i.toString() + "_defcolval"} align="left" className={`usedefault${i}`} width={"15%"}>                                
                                      {
                                        <Checkbox
                                                  disabled={!(result.isBeingEdited || result.isBeingAdded)}
                                                  id="isTipVideoDefault"
                                                  size="small"
                                                  color="primary"
                                                  checked={result.orgID == null}
                                                  className={styles.checkBox}
                                                  onClick={(e) => { 
                                                    //setTipVideoDefault(!isTipVideoDefault); 
                                                    handleTipVideoDefaultFlag(result, !(areTipVideosManagedBySuperAdmin() ? (!!result !== true || !!result.orgID !== true ? true : false) : false));
                                                    e.stopPropagation(); 
                                                  }}
                                                  /> 
                                      }
                                    </TableCell>
                                  }
                                  <TableCell key={i.toString() + "_namcolval"} align="left" width={"20%"}>
                                      <span>{!!result.orgName && !!result.orgID ? result.orgName : ""}</span>
                                  </TableCell>
                                  <TableCell key={i.toString() + "_pgecolval"} align="left" className={`usedefault${i}`} width={"15%"}>                                
                                    {
                                      <FormControl variant="outlined" className={`${styles.formControl} tipVideoPageRefFormCtrl`}>                      
                                        <Select
                                          disabled={!(result.isBeingEdited || result.isBeingAdded)}
                                          value={
                                              !!result && !!result.pageReference ? result.pageReference : ""
                                          }
                                          native
                                          onChange={(e : any) => { 
                                              let pagerefsel : string = !!e.target.value ? e.target.value.toString() : "";
                                              let matched = !!pagerefsel && pagerefsel.trim() != "" && !!pageTitleValues ? 
                                                                pageTitleValues.filter((cur : string) => !!cur && 
                                                                cur.trim().toLowerCase() === pagerefsel.trim().toLowerCase()) : null;
                                              if (!!matched)
                                              {
                                                  var curSel = {value: e.target.value};
                                                  setSelectedPageReference(curSel);
                                                  handlePageReferenceChange(result, e.target.value);
                                              }
                                          }}
                                          defaultValue={{value: ""}}
                                          className={styles.inputSelect}
                                          variant="outlined" 
                                          label={allResourcesToTranslations.profilecloninglabel + ":"}
                                          id="tipVideoPageRefFormCtrlSel1"
                                          >
                                          {
                                              !!pageTitleValues ? pageTitleValues.map((item:any, idx: number) => {
                                              return <option key={idx} value={item}>
                                                {
                                                  (!!item && item.trim() != "") ? item : allResourcesToTranslations.selectpagereference
                                                }</option>
                                              }) : <></>
                                          }
                                        </Select>
                                      </FormControl>
                                    }                                    
                                  </TableCell>
                                  {
                                    ((areTipVideosManagedBySuperAdmin() && !!result && result.orgID == null) || (!!result && result.orgID != null && result.orgID > 0)) ?
                                    <TableCell key={i.toString() + "_savcolval"} align="center" width={"15%"}>                                    
                                        {
                                            result.isBeingEdited || result.isBeingAdded ? 
                                            <>
                                              <CustomTooltip displayText={["Save"]}>
                                                <Button  disabled={isAnyValueEmpty()} onClick={() => handleSave()} className={styles.moduleListButton} 
                                                        variant="contained" color="primary"  id="Save">
                                                  <SaveIcon />
                                                </Button>
                                              </CustomTooltip>
                                              <CustomTooltip displayText={["Cancel"]}>
                                                <Button onClick={() => handleCancel(result.title, result.videoID)} className={styles.moduleListButton} variant="contained" color="primary" id="Cancel">
                                                  <CancelIcon />
                                                </Button>
                                              </CustomTooltip>
                                            </>
                                            :
                                            <CustomTooltip displayText={["Edit"]}>
                                              <Button className="ruleedit" onClick={() => handleEdit(result.title,result.url,result.category,result.videoID)} 
                                                      variant="contained" color="primary"  id="Edit">
                                                <EditIcon />
                                              </Button>
                                            </CustomTooltip>
                                        }
                                    </TableCell>
                                    :
                                    <TableCell key={i.toString() + "_savcolval"} align="center" width={"15%"}>
                                      <></>
                                    </TableCell>
                                  }
                                  {
                                    ((areTipVideosManagedBySuperAdmin() && !!result && result.orgID == null) || (!!result && result.orgID != null && result.orgID > 0)) ?
                                    <TableCell key={i.toString() + "_delcolval"} align="center" width={"5%"}>
                                      <CustomTooltip displayText={["Delete"]}>
                                        <Button className="ruledelete" onClick={() => handleDelete(result.videoID)} variant="contained" style={{ backgroundColor: "red" }}
                                                  id="Delete">
                                          <DeleteIcon />
                                        </Button>
                                      </CustomTooltip>
                                    </TableCell>
                                    :
                                    <TableCell key={i.toString() + "_delcolval"} align="center" width={"5%"}>
                                      <></>
                                    </TableCell>
                                  }
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </BrandedCard>
                </div>
              </div>
              <div className={styles.paginationAddedUsersControl}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={tipVideos.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}

      </div>

    </>
  );
};

export default ManageTipVideos;
