import React,{useContext, useEffect} from 'react';

import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import Checkbox from '@material-ui/core/Checkbox';
import Button from "@material-ui/core/Button";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";


//General Functions
import getColor from '../../functions/getColor';
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';
import RemoveUserIcon from "../../CustomIcons/RemoveUserIcon"; 
import SaveIcon from '../../CustomIcons/SaveIcon';

//components
import CustomTooltip from "../../Components/CustomTooltip";

//Styles
import styles from "../../styles/currentAdminReport.module.css";

const AddedUsers = (data:any) => {

    const { isCCAddedUserSectionOpen, handleAddedUserSection } = data;
    const [selectedUsers, setSelectedUsers] = React.useState(data.users);
    const { theme: { color }} = useContext(CorperateThemeContext);
    
    const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true);

    useEffect(() => {
        setSelectedUsers(data.users)
    },[data.users])

    const handleUsersData = () => {
        
        let filterRemovedUsers = selectedUsers.filter((item:any) => item.isChecked);
        let updateSelectedUsers = selectedUsers.filter((item:any) => !item.isChecked);
        
        let updateRemovedUsers = filterRemovedUsers.map((content:any) => {
            if(content.isChecked) {
                return {
                    ...content,
                    isChecked: false
                }
            }
            return content
        })

        setSelectedUsers(updateSelectedUsers);

        data.handleRemoveUsers(updateRemovedUsers)
    }

    const handleRemoveData = (item:any) => {

        let updatedData = selectedUsers.map((content:any) => {
            if(item.userID == content.userID) {
              return {
                ...content,
                isChecked: !content.isChecked
              }
            }
            return content
          })
      
          setSelectedUsers(updatedData)
    }

    const rowBackgroundColor = (index: number) => {
        if (enableRowBanding) {
            return { backgroundColor: index % 2 == 0 ? "#eee" : "#fff"}
        } else {
            return {}
        }
    }

    return (
        <>
          <div className={styles.expandContainer} onClick={handleAddedUserSection}>
            <b style={{ marginLeft: "-5px" }}>Added CC Accounts</b>
            <div
              className={
                !isCCAddedUserSectionOpen
                  ? styles.sortArrowAccending
                  : styles.sortArrowDescending
              }
            >
              <KeyboardArrowDownIcon />
            </div>
          </div>
          {/* <p>Added Users</p> */}
          {isCCAddedUserSectionOpen && (
            <>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                
                            <TableCell 
                                    style={{color: getColor(color.substring(1)),backgroundColor: color, borderRadius: "8px 0 0 0"}}
                                    align="left">
                                    <div className={styles.tableHeadCellContainer}>
                                        <div 
                                        >
                                        {"Select"}
                                        </div>
                                        
                                    </div>
                                </TableCell>
                                
                                <TableCell 
                                style={{color: getColor(color.substring(1)),backgroundColor: color}}
                                    align="left">
                                    <div className={styles.tableHeadCellContainer}>
                                        <div >
                                        
                                        {"Last Name" /* TODO translate */}
                                        </div>
                                        
                                    </div>
                                </TableCell>
                                <TableCell 
                                style={{color: getColor(color.substring(1)),backgroundColor: color}} 
                                align="left">
                                    <div className={styles.tableHeadCellContainer}>
                                        <div >
                                        {"First Name" /* TODO translate */}
                                        </div>
                                        
                                    </div>
                                </TableCell>                                       
                                <TableCell 
                                style={{color: getColor(color.substring(1)),backgroundColor: color, borderRadius: "0 8px 0 0"}} 
                                align="left">
                                    <div className={styles.tableHeadCellContainer}>
                                        <div>
                                        {"Email" /* TODO translate */}
                                        </div>
                                        
                                    </div>
                                </TableCell>
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                selectedUsers.map((result: any, i:number ) => {
                                    return(
                                        <TableRow key={`reportScheduler-${i}`} 
                                        style={rowBackgroundColor(i)}
                                        >
                                            <TableCell align="left">
                                            <Checkbox
                            size="small"
                            color="primary"
                            className={styles.checkBox}
                            name="selectedUser"
                            checked={result.isChecked}
                            onClick={() => handleRemoveData(result)}
                    />  
                                            </TableCell>
                                            
                                            <TableCell align="left">
                                                {result.lastName}
                                            </TableCell>
                                            <TableCell align="left">
                                                {result.firstName}
                                            </TableCell>                                                    
                                            <TableCell align="left" >
                                                {result.email}
                                            </TableCell>
                                            
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

                <div style={{display:'flex', justifyContent:'flex-end', marginRight:"5px"}}>
                    <CustomTooltip displayText={["Remove Account(s)"]} >
                        <span>
                            <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className="save"
                            onClick={handleUsersData}
                            >
                            <RemoveUserIcon />
                            <span style={{marginLeft:'10px'}}>
                            <SaveIcon />
                            </span>
                            </Button>
                        </span>
                    </CustomTooltip>
                </div>
            </>
          )}
        </>
    )
}

export default AddedUsers