import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const PlayerSectionCompletionIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="17" height="17" rx="2.5" fill="white" stroke="white"/>
    <path d="M7.0105 15L2.33325 10.1116L6.42584 11.3322C7.59516 9.49876 11.1031 4.0004 14.611 4C9.93378 7.6659 8.95935 11.7413 7.0105 15Z" fill="#21394F"/>
    </svg>
);

export default PlayerSectionCompletionIcon