import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const ManagementDeactiveMenuIcon = () => (    
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 22.7403C17.5228 22.7403 22 18.2631 22 12.7403C22 7.21745 17.5228 2.7403 12 2.7403C6.47715 2.7403 2 7.21745 2 12.7403C2 18.2631 6.47715 22.7403 12 22.7403Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15 9.7403L9 15.7403" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 9.7403L15 15.7403" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


);

export default ManagementDeactiveMenuIcon
