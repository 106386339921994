import React, { useContext } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { UIContext } from '../contexts/UIContext';
import BrandedCard from './BrandedCard'
import WarningCard from './WarningCard'
import styles from '../styles/modal.module.css'
import { addError } from '../API/AddError'
import CloseIcon from '@material-ui/icons/Close';
import ErrorCard from './ErrorCard';
import SuccessCard from './SuccessCard';

interface Props {
    modalOpen: boolean;
    setModalOpen: (isOpen: boolean) => any;
    children: Array<JSX.Element> | JSX.Element;
    type?: string; // "warning" | "error" 
    hideCloseBtn?: boolean;
    style?: any;
}

export const Modal = ({ modalOpen, children, setModalOpen, type, hideCloseBtn, style }: Props): JSX.Element | null => {
    const { EnableActivityLogs} = useContext(UIContext);
    const { oidcUser } = useReactOidc();
    const orgDomain = oidcUser.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0;

    const handleClose = (e:any) => {
        e.stopPropagation();
        setModalOpen(false);
        addError(oidcUser.access_token, userId, orgDomain, "Closed popup", null, "Modal.tsx", 6, EnableActivityLogs);
    }

    if (!modalOpen) return null
    // Added onClick={handleClose} is given for background Click, for (type !== "warning"), 
    // Please make sure if you want your popup doesn't close out clicking outside, please add type = "warning" to ur tag
    if (type === "UnhidePopup") {
        return (
            <div className={styles.modalBackground} >
                <div className={styles.modalCard}>
                    <BrandedCard >
                        <div className={styles.exitButtonContainer} style={{ display: hideCloseBtn? "none" : "flex"}}>
                            <CloseIcon onClick={handleClose} fontSize="large" style={{ paddingTop: "10px", paddingRight: "10px"}} ></CloseIcon>
                        </div>
                        <div onClick={(e) => e.stopPropagation()} className={styles.modalContentWrapper}>
                            {children}
                        </div>
                    </BrandedCard>
                </div>
            </div>
        )
    } else if (type === "success") {
        return (
            <div className={styles.modalBackground} >
                <div className={styles.modalCard}>
                    <SuccessCard >
                        <div className={styles.exitButtonContainer} style={{ display: hideCloseBtn? "none" : "flex"}}>
                            <CloseIcon onClick={handleClose} fontSize="large" style={{ paddingTop: "10px", paddingRight: "10px"}} ></CloseIcon>
                        </div>
                        <div onClick={(e) => e.stopPropagation()} className={styles.modalContentWrapper}>
                            {children}
                        </div>
                    </SuccessCard>
                </div>
            </div>
        )
    } else if (type === "warning") {
        return (
            <div className={styles.modalBackground} >
                <div className={styles.modalCard}>
                    <WarningCard >
                        <div className={styles.exitButtonContainer} style={{ display: hideCloseBtn? "none" : "flex"}}>
                            <CloseIcon onClick={handleClose} fontSize="large" style={{ paddingTop: "10px", paddingRight: "10px"}} ></CloseIcon>
                        </div>
                        <div onClick={(e) => e.stopPropagation()} className={styles.modalContentWrapper}>
                            {children}
                        </div>
                    </WarningCard>
                </div>
            </div>
        )
    }else if (type === "fileviewer") {
        return (
            <div className={styles.modalBackground} >
                <div className={styles.modalCard} style={{width:"90%" , height:"90%"}}>
                    <BrandedCard>
                        <div className={styles.exitButtonContainer} style={{ display: hideCloseBtn? "none" : "flex"}}>
                            <CloseIcon onClick={handleClose} fontSize="large" style={{ paddingTop: "10px", paddingRight: "10px"}} ></CloseIcon>
                        </div>
                        <div onClick={(e) => e.stopPropagation()} className={styles.modalContentWrapper} style={{ paddingTop: "45px"}}>
                            {children}
                        </div>
                    </BrandedCard>
                </div>
            </div>
        )
    } else if (type === "error") {
        return (
            <div className={styles.modalBackground} >
                <div className={styles.modalCard}>
                    <ErrorCard >
                        <div className={styles.exitButtonContainer} style={{ display: hideCloseBtn? "none" : "flex"}}>
                            <CloseIcon onClick={handleClose} fontSize="large" style={{ paddingTop: "10px", paddingRight: "10px"}} ></CloseIcon>
                        </div>
                        <div onClick={(e) => e.stopPropagation()} className={styles.modalContentWrapper}>
                            {children}
                        </div>
                    </ErrorCard>
                </div>
            </div>
        )
    } else {
        return (
            // Added onClick={handleClose} for background Click, so it will be hidden on outside click
            <div className={styles.modalBackground} onClick={handleClose}>
                <div className={styles.modalCard} style={style}>
                    <BrandedCard >
                        <div className={styles.exitButtonContainer} style={{ display: hideCloseBtn? "none" : "flex"}}>
                            <CloseIcon onClick={handleClose} fontSize="large" style={{ paddingTop: "10px", paddingRight: "10px"}} ></CloseIcon>
                        </div>
                        <div onClick={ (e) => e.stopPropagation()} className={styles.modalContentWrapper}>
                            {children}
                        </div>
                    </BrandedCard>
                </div>
            </div>
        )
    }
}

export default Modal
