import axios from 'axios';

export const getSpecificAppConfig = async (token: string, configName: string) => {

    try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/usersessions/GetAppConfigOnlyGiven`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        configName: configName
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      console.log(error.response);
    } else if (error.request) {
      console.log(error.request);
    } else if (error.message) {
      console.log(error.message);
    }
    return null;
  }
};

export default getSpecificAppConfig;
