import axios from 'axios';
import { addError } from '../../AddError';

export const SaveOrgConfigItemDetails = async (token: string, userId: number, orgId: number | null, orgDomain: string, includeCertificateLogo: boolean, isCertificateWithoutSaltLogo: boolean, passwordLockout: boolean, 
  showLastPassed: boolean, disablePasswordField: boolean, advancedReporting: boolean, showWelcomePopup: boolean, timezoneId: number,
  enablePasswordRule: boolean, isPasswordRuleCapital: boolean, isPasswordRuleNumber: boolean, isPasswordRuleSpecialChar: boolean, ConfigurationItemsType:string = "configurationitems") => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/organisation/SaveOrgConfigItemDetails`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        userId: userId,
        orgId: orgId,
        IncludeCertificateLogo: includeCertificateLogo,
        IsCertificateWithoutSaltLogo: isCertificateWithoutSaltLogo,
        PasswordLockout: passwordLockout, 
        ShowLastPassed: showLastPassed, 
        DisablePasswordField: disablePasswordField,         
        AdvancedReporting: advancedReporting,
        ShowWelcomePopup: showWelcomePopup,
        TimezoneId: timezoneId,
        EnablePasswordRule: enablePasswordRule,
        IsPasswordRuleCapital: isPasswordRuleCapital,
        IsPasswordRuleNumber: isPasswordRuleNumber,
        IsPasswordRuleSpecialChar: isPasswordRuleSpecialChar,
        ConfigurationItemsType:ConfigurationItemsType
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "SaveOrgConfigItemDetails.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "SaveOrgConfigItemDetails.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "SaveOrgConfigItemDetails.ts", 1)
    }
    return null;
  }
};

export default SaveOrgConfigItemDetails;