import axios from 'axios';
import { addError } from '../../AddError';
import { GroupingOptionsList } from '../../../globalTypes';

export const SaveOrgDetails = async (token: string, userId: number, orgDomain: string, orgId: number | null, languageCode: string, orgName: string, domainName: string, 
  orgNotes: string, groupBy: string, groupByOption: GroupingOptionsList[] ) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/organisation/SaveOrgDetails`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        langCode: languageCode,
        userId: userId,
        orgId: orgId,
        orgName: orgName,
        domainName: domainName,
        orgNotes: orgNotes,
        groupBy: groupBy,
        groupByOption: groupByOption
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "SaveOrgDetails.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "SaveOrgDetails.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "SaveOrgDetails.ts", 1)
    }
    return null;
  }
};

export default SaveOrgDetails;