import React, { useState, useEffect, useContext } from "react";

//Styles
import styles from "../../../styles/organisationmanagement.module.css";
import responsivestyles from "../../../styles/responsiveGridLayout.module.css";

import { useHistory, useLocation, useParams } from "react-router";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { UIContext } from "../../../contexts/UIContext";
import LoaderContainer from "../../../Components/LoaderContainer";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";
import { Steps } from "intro.js-react";
import CustomTooltip from "../../../Components/CustomTooltip";
import GuideIcon from "../../../CustomIcons/GuideIcon";
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, CircularProgress, FormControl, InputLabel, Select, TextField, Typography } from "@material-ui/core";
import UnbrandedCard from "../../../Components/UnbrandedCard";
import getColor from "../../../functions/getColor";
import { CorperateThemeContext } from "../../../contexts/CorperateThemeContext";
import CloseWhiteIcon from "@material-ui/icons/Close";
import Modal from '../../../Components/Modal';
import ProceedIcon from "../../../CustomIcons/ProceedIcon";
import { Policies, PolicyBuilderReportResultItem, PolicyUserList, UnitTreeItem } from "../../../globalTypes";
import FileInfoIcon from "../../../CustomIcons/Administration/ImportAccounts_FileInfoIcon";
import FileDropBox from "../ImportAccounts/FileDropBox";
import SaveIcon from "../../../CustomIcons/SaveIcon";
import CloseIcon from "../../../CustomIcons/Administration/closeIcon";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SavePolicyBuilder from "../../../API/Administration/PolicyBuilder/SavePolicyBuilder";
import PolicyFileDropBox from "./PolicyFileDropBox";
import GetOnePolicyBuilder from "../../../API/Administration/PolicyBuilder/GetOnePolicyBuilder";
import PieChart from "../../../Components/HighChart/MyTraining_PieChartComponent";
import BarChart from "../../../Components/HighChart/BarChartComponent";
import PolicyProgressBarChartComponent, { PolicyProgressChartComponent } from "../../../Components/HighChart/PolicyProgressBarChartComponent";
import getFullPolicyBuilderReport from "../../../API/GetFullPolicyBuilderReport";
import PolicyBuilderReportResultList from "./PolicyBuilderReportResultList";
import AssignButtonPanel from "./AssignButtonPanel";
import AssignUnits from "./AssignUnits";
import AccountManagement from "../AccountManagement/AccountManagement";
import UserListSection from "../AccountManagement/UserListSection";
import ViewPolicyAssignedUsers from "../../../API/Administration/PolicyBuilder/ViewPolicyAssignedUsers";
import ResetPolicyAcceptances from "../../../API/Administration/PolicyBuilder/ResetPolicyAcceptances";
import BrandedCard from "../../../Components/BrandedCard";
import AssignUnitsToPolicy from "../../../API/Administration/PolicyBuilder/AssignUnitsToPolicy";
import AssignUnassignUserToPolicy from "../../../API/Administration/PolicyBuilder/AssignUnassignUserToPolicy";

const PolicyBuilderDetails = () => {
    const { policyIdToEdit } = useParams<{ policyIdToEdit: string }>();
    const history = useHistory();
    const {
        translations: { allResourcesToTranslations }, pageTitles
    } = useContext(TranslationsContext);
    const { setTitle, setTitleEnglish, updateBackButtonRoute } =
        useContext(UIContext);
    const { oidcUser } = useReactOidc();
    const {
        profile: { user_id, website },
    } = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const orgDomain = website
        ? website
            .toLowerCase()
            .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
        : "";
    const [loading, setLoading] = useState<boolean>(false);
    const { theme: { color } } = useContext(CorperateThemeContext);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isResettingAcceptances, setIsResettingAcceptances] = useState<boolean>(false);
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
    const [saveSuccess, setSaveSuccess] = React.useState(false);
    const [modalPopupMessage, setModalPopupMessage] = useState('');
    const [hasErrored, setHasErrored] = useState<boolean>(false);
    const [showConfirmModal, setshowConfirmModal] = useState<boolean>(false);
    const [showSaveConfirmModal, setshowSaveConfirmModal] = useState<boolean>(false);
    const [statusfield, setStatusfield] = useState<number>(1);
    const [confirmationMessage, setConfirmationMessage] = useState<string>("I have read and understood the policy");
    const [policyName, setPolicyName] = useState<string>("");
    const [selectedFile, setSelectedFile] = useState<string>("");
    const [selectedFileName, setSelectedFileName] = useState<string>("");
    const [selectedFileData, setSelectedFileData] = useState<string>("");
    const [selectedFileSize, setSelectedFileSize] = useState<number>(0);
    const [selectedFileExtension, setSelectedFileExtension] = useState<string>("");
    const [selectedUnits, setselectedUnits] = useState<string>("");
    const [updatedSelectedUnits, setupdatedSelectedUnits] = useState<Array<string>>([]);

    const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
    const [acceptedUsers, setAcceptedUsers] = useState<number>(0);
    const [totalAssignedUsersToPolicy, setTotalAssignedUsersToPolicy] = useState<number>(0);
    const [completedPolicyPercentage, setCompletedPolicyPercentage] = useState<number>(0);
    const [expandPolicyAcceptanceChart, setExpandPolicyAcceptanceChart] = useState<boolean>(false);

    const [expandPolicyBuilderReport, setExpandPolicyBuilderReport] = useState<boolean>(true);
    const [policyBuilderReportListData, setPolicyBuilderReportListData] = React.useState<Array<PolicyBuilderReportResultItem>>([]);
    const [policyBuilderReportListError, setPolicyBuilderReportListError] = React.useState<string>("");
    const [showAssignUnits, setshowAssignUnits] = useState<boolean>(false);
    const [showAssignAccounts, setshowAssignAccounts] = useState<boolean>(false);
    const [showViewAccounts, setshowViewAccounts] = useState<boolean>(false);
    const [showResetAccounts, setshowResetAccounts] = useState<boolean>(false);
    const [initaialUsersList, setInitaialUsersList] = React.useState<Array<any>>([]);
    const [finallySelectedUsersList, setFinallySelectedUsersList] = React.useState<Array<any>>([]);
    const [finallySelectedUnitList, setFinallySelectedUnitList] = React.useState<Array<any>>([]);
    const [policyAcceptedUsersList, setPolicyAcceptedUsersList] = React.useState<Array<PolicyUserList>>([]);
    const [initialpolicyAcceptedUsersList, setInititalPolicyAcceptedUsersList] = React.useState<Array<any>>([]);
    const [needLatestUnits, setNeedLatestUnits] = useState<boolean>(false);
    const [needLatestUsers, setNeedLatestUsers] = useState<boolean>(false);
    const [hasSelectedUsersChanged, setHasSelectedUsersChanged] = useState<boolean>(false);
    const [assignUsers, setAssignUsers] = useState<boolean>(false);
    const [isSavingAssignment, setIsSavingAssignment] = useState<boolean>(false);
    const [resetAcceptances, setResetAcceptances] = useState<boolean>(false);
    const [viewPolicyLoading, setViewPolicyLoading] = useState<boolean>(false);
    const [hasAccountsSearched, setHasAccountsSearched] = useState<boolean>(false);
    const [hasUnitsLoaded, setHasUnitsLoaded] = useState<boolean>(false);
    const [selectedUsersCSVInitial, setSelectedUsersCSVInitial] = useState<string>("");
    const [sameUnitModelMessage, setSameUnitModelMessage] = useState<string>("");
    const [showSameUnitModalForConfirmation, setShowSameUnitModalForConfirmation] = useState<boolean>(false);
    const [hasSameUnitSelected, setHasSameUnitSelected] = useState<boolean>(false);   
    const [csvOfAllDeniedUnits, setCSVOfAllDeniedUnits] = useState<string>("");

    let categoriesArray: Array<string> = [];
    let seriesData: Array<any> = [];

    const [intro, setIntro] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
            {
                element: ".policynameguideme",
                title: allResourcesToTranslations.policyname, intro: allResourcesToTranslations.policynameguideme
            }
        ]
    });

    const setGuideMe = (acceptedUsersCount: number, expandPolicyAcceptanceChart: boolean = false) => {
        let updateSteps = [...intro.steps];
        if (updateSteps.length>1) {
            updateSteps.splice(1, updateSteps.length - 1);     
        }
        updateSteps.push(
            {
                element: ".policystatusguideme",
                title: allResourcesToTranslations.status, intro: allResourcesToTranslations.policystatusguideme
            },
            {
                element: ".policyconfirmguideme",
                title: allResourcesToTranslations.confirmationmessage, intro: allResourcesToTranslations.policyconfirmguideme
            },
            {
                element: ".policydocguideme",
                title: allResourcesToTranslations.choosefile, intro: allResourcesToTranslations.policydocguideme
            },
            {
                element: ".saveData",
                title: "Save",
                intro: "Click here to save your policy",
            });

            if(parseInt(policyIdToEdit) > 0 && acceptedUsersCount > 0 && expandPolicyAcceptanceChart)
            {
                updateSteps.push(
                {
                    element: ".policyacceptance",
                    title: allResourcesToTranslations.policyacceptance, intro: allResourcesToTranslations.policyacceptancegraph
                });

                if(expandPolicyBuilderReport) {
                    updateSteps.push(
                        {
                            element: ".expandPolicyBuilderReport",
                            title: "Policy Builder Report", intro: "The report is displayed here to see the details of policy assignment and acceptance."
                        });
                }
            }

            if ((parseInt(policyIdToEdit) > 0)) {
                updateSteps.push(
                    {
                        element: ".AssignUnits",
                        title: "Assign Units", intro: "Click Assign Units to select which Units to assign this Policy to. "
                    },
                    {
                        element: ".AssignAccounts",
                        title: "Assign Accounts", intro: "Click Assign Accounts to select specific Accounts to assign this Policy to. "
                    },
                    {
                        element: ".ViewAccounts",
                        title: "View Accounts", intro: "Click View Accounts to be provided with a listing of Accounts this Policy is currently assigned to. "
                    },
                    {
                        element: ".ResetAccounts",
                        title: "Reset Accounts",
                        intro: "Click Reset Accounts to reset all current attestations for this Policy, you will be provided with confirmation before this is actioned. ",
                    });
            }

            if ((parseInt(policyIdToEdit) > 0) && showAssignUnits)
            {
                updateSteps.push(
                {
                    element: ".unitFilterButtons",
                    title: "Units", intro: "Use these controls to Select\Deselect all units, as well as Expand\Collapse the entire unit tree.  "
                },{
                    element: ".unitFilterSearch",
                    title: "Filter Unit", intro: "Use the search function to filter the unit tree. "
                },
                {
                    element: ".unittree",
                    title: "Assign Units", intro: "Select the relevant units by clicking the checkboxes, you can select all units under a parent unit by clicking on the unit name.  "
                },
                {
                    element: ".saveAssignedData",
                    title: "Save Assignment",
                    intro: "Click to save unit assignments. ",                
                });
            }

            if ((parseInt(policyIdToEdit) > 0) && showAssignAccounts)
            {
                updateSteps.push(
                {
                    element: ".unitFilterButtons",
                    title: "Units", intro: "Use these controls to Select\Deselect all units, as well as Expand\Collapse the entire unit tree.  "
                },
                {
                    element: ".unitFilterSearch",
                    title: "Filter Unit", intro: "Use the search function to filter the unit tree. "
                },
                {
                    element: ".unittree",
                    title: "Assign Units", intro: "Select the relevant units by clicking the checkboxes, you can select all units under a parent unit by clicking on the unit name.  "
                },
                {
                    element: ".firstName",
                    title: "Filter First name", intro: "Enter in First name to filter accounts by."
                },
                {
                    element: ".lastName",
                    title: "Filter Last name", intro: "Select the relevant units by clicking the checkboxes, you can select all units under a parent unit by clicking on the unit name.  "
                },
                {
                    element: ".userName",
                    title: "Filter Username",
                    intro: "Click to save unit assignments. ",                
                },
                {
                    element: ".email",
                    title: "Filter Email",
                    intro: "Click to save unit assignments. ",                
                },
                {
                    element: ".userID",
                    title: "Filter Account Id",
                    intro: "Click to save unit assignments. ",                
                },
                {
                    element: ".searchUsers",
                    title: "Search Users",
                    intro: "Click to search for accounts to assign. ",                
                },
                {
                    element: ".resetAll",
                    title: "Clear filters",
                    intro: "Click to clear all filters. ",                
                });
            }
            if (parseInt(policyIdToEdit) > 0 && showAssignAccounts && hasAccountsSearched)
            {
                updateSteps.push(
                    {
                        element: ".assignCheckbox",
                        title: "Assign All",
                        intro: "Shows that the current Policy is assigned to this account.  ",                
                    },
                    {
                        element: ".unitpathwayCol",
                        title: "Unit Pathway",
                        intro: "Shows the Unit Pathway of the relevant account that has this Policy assigned. ",                
                    },
                    {
                        element: ".lastnameCol",
                        title: "Last Name",
                        intro: "Shows the Last Name of the relevant account that has this Policy assigned. ",                
                    },
                    {
                        element: ".firstnameCol",
                        title: "First Name",
                        intro: "Shows the First Name of the relevant account that has this Policy assigned. ",                
                    },
                    {
                        element: ".saveAssignedData",
                        title: "Save Assignment",
                        intro: "Click save to assign\\unassign this Policy to all checked\\unchecked accounts.  ",                
                    }
                );
            } 
            if (parseInt(policyIdToEdit) > 0 && showViewAccounts && policyAcceptedUsersList.length > 0)
            {
                updateSteps.push(
                    {
                        element: ".assignCheckbox",
                        title: "Assign All",
                        intro: "Shows that the current Policy is assigned to this account.  ",                
                    },
                    {
                        element: ".unitpathwayCol",
                        title: "Unit Pathway",
                        intro: "Shows the Unit Pathway of the relevant account that has this Policy assigned. ",                
                    },
                    {
                        element: ".lastnameCol",
                        title: "Last Name",
                        intro: "Shows the Last Name of the relevant account that has this Policy assigned. ",                
                    },
                    {
                        element: ".firstnameCol",
                        title: "First Name",
                        intro: "Shows the First Name of the relevant account that has this Policy assigned. ",                
                    },
                    {
                        element: ".saveAssignedData",
                        title: "Save Assignment",
                        intro: "Click save to assign\\unassign this Policy to all checked\\unchecked accounts.  ",                
                    }
                );
            }
        setIntro({ ...intro, steps: [...updateSteps] });
    };

    const onExit = () => {
        setIntro({ ...intro, stepsEnabled: false });
    };

    const handleFileUpload = (byteArray: string, fileExtension: string, filename: string) => {
        setShowWarningModal(false);
        setModalPopupMessage("");
        setSelectedFile(filename);
        setSelectedFileName(filename);
        setSelectedFileData(byteArray);
        setSelectedFileExtension(fileExtension);
        setIsFileUploading(false);
    }

    const handleSetSelectedFileName = (filename: string) => {
        setSelectedFile(filename);
        setSelectedFileName(filename);
    }

    const handleSetSelectedFileSize = (filesize: number) => {
        setSelectedFileSize(filesize);
    }

    const handleResetFileUpload = () => {
        setSelectedFile("");
        setSelectedFileName("");
        setSelectedFileSize(0);
    };

    const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
    {
        if (!isNaN(givenDate.getTime())) {
            const year = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(givenDate);
            const month = new Intl.DateTimeFormat('en-AU', { month: 'numeric' }).format(givenDate);
            const day = new Intl.DateTimeFormat('en-AU', { day: 'numeric' }).format(givenDate);

            return (year.toString() + "-" +
                (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" +
                (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
        }
        else {
            return "";
        }
    }

    const handleSaveAssignment = () => {
        if (showAssignUnits) {
            setNeedLatestUnits(true);
        }
        if (showViewAccounts || showAssignAccounts) {
            setNeedLatestUsers(true);
            SavePolicyBuilderAssignClick([], finallySelectedUsersList);
        }
    }

    const AssignUnitsToPolicyFunction = (unitIds: string[]) => {
        handleSameUnitModalForConfirmation(false);
        setIsSavingAssignment(true);
        AssignUnitsToPolicy(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(policyIdToEdit), unitIds.join(","), hasSameUnitSelected)
            .then((res) => {
                setselectedUnits(unitIds.join(','));
                setIsSavingAssignment(false);
                setNeedLatestUnits(false);
                setNeedLatestUsers(false);         
                if (!!res && res.isSuccess) {
                    setShowWarningModal(true);
                    setHasErrored(false);
                    setSaveSuccess(true);
                    setModalPopupMessage("Assign units to this policy have been saved successful. ");
                }
                else {
                    setShowWarningModal(true);
                    setHasErrored(true);
                    setSaveSuccess(false);
                    setModalPopupMessage("Assign units to this policy have been failed.");
                }
            })
    }

    const SavePolicyBuilderAssignClick = (unitIds: string[], usersData: any[]) => {
        if (showAssignUnits) {
            let anyChangeFound = false;
            if (unitIds.length === selectedUnits.split(",").length){
                // Setting external variable using the lambda of map function is avoided
                //{ 
                    //unitIds.map((value: string) => {   
                    for (let curIdx = 0; curIdx < unitIds.length; curIdx++) {
                        let currentValue = unitIds[curIdx];
                        let unit = (selectedUnits.split(",").find(v => !!v && !!currentValue && v.toString().toLowerCase().trim() === currentValue.toString().toLowerCase().trim()));        
                        if (anyChangeFound === false && (unit === null || unit === undefined)) {
                            anyChangeFound = true;
                            break;
                        }
                    }
                    //}) 
                //}
            }
            else {
                anyChangeFound = true;
                setHasSameUnitSelected(true);
            }
            if(anyChangeFound) {
                AssignUnitsToPolicyFunction(unitIds);
            }
            else {
                handleSameUnitModalForConfirmation(true);
                setHasSameUnitSelected(true);
                setSameUnitModelMessage("There are no changes to Unit Assignments. Do you want to re-apply the existing Unit Assignments which will override any Account specific assignments?");
            }
        }
        if (showViewAccounts) {
            let anyUserChangeFound: boolean = false;
            let currentUserGrantedData = !!usersData !== true ? null : usersData.filter((item) => item.granted === true);
            let initialUserGrantedData = !!initialpolicyAcceptedUsersList !== true ? null : initialpolicyAcceptedUsersList.filter((initialItem) => initialItem.granted === true);
            if (!!currentUserGrantedData && !!initialUserGrantedData && currentUserGrantedData.length === initialUserGrantedData.length){
                // Setting external variable using the lambda of map function is avoided
                //{ 
                    //usersData.map((currentItem: any) => {   
                    for (let curIdx = 0; curIdx < usersData.length; curIdx++) {
                        let currentItem = usersData[curIdx];
                        let userdetail = (initialpolicyAcceptedUsersList.find(v => !!v && !!currentItem && v.userID.toString().toLowerCase() === currentItem.userID.toString().toLowerCase() && v.granted !== currentItem.granted));        
                        if (anyUserChangeFound === false && !!userdetail) {
                            anyUserChangeFound = true;
                            break;
                        }
                    }
                    //}) 
                //}
            }
            else {
                anyUserChangeFound = true;
            }
            if(anyUserChangeFound) {
                let newlyGrantedUserIDsCSV = "";
                let newlyDeniedUserIDsCSV = "";
                let ids = getNewlyModifiedUserIDs(usersData);
                newlyGrantedUserIDsCSV = !!ids ? ids.newlyGrantedIDsCSV : newlyGrantedUserIDsCSV;
                newlyDeniedUserIDsCSV = !!ids ? ids.newlyDeniedIDsCSV : newlyDeniedUserIDsCSV;

                setIsSavingAssignment(true);
                AssignUnassignUserToPolicy(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(policyIdToEdit), assignUsers, usersData, newlyGrantedUserIDsCSV, newlyDeniedUserIDsCSV)
                .then((res) => {
                    setIsSavingAssignment(false);
                    setNeedLatestUnits(false);
                    setNeedLatestUsers(false);
                    if (!!res && res.isSuccess) {
                        setShowWarningModal(true);
                        setHasErrored(false);
                        setSaveSuccess(true);
                        setModalPopupMessage("Assign/Unasssign users to the policy have been saved successful. ");
                        if (showViewAccounts) {
                            setPolicyAcceptedUsersList([]);
                            setNeedLatestUnits(false);
                            setNeedLatestUsers(false);
                            LoadPolicyAssignedUsers(parseInt(policyIdToEdit));
                        }
                    }
                    else {
                        setShowWarningModal(true);
                        setHasErrored(true);
                        setSaveSuccess(false);
                        setModalPopupMessage("Assign/Unasssign users to the policy have been failed.");
                    }
                });
            }
            else {
                setShowWarningModal(true);
                setHasErrored(false);
                setSaveSuccess(false);
                setModalPopupMessage("Please select the users to assign/unassign to the policy");
            }
        } 
        if (showAssignAccounts) {
            let anyUserChangeFound: boolean = false;
            let currentUserGrantedData = !!usersData !== true ? null : usersData.filter((item) => item.granted === true);
            let initialUserGrantedData = !!initaialUsersList !== true ? null : initaialUsersList.filter((initialItem) => initialItem.granted === true);
            if (!!currentUserGrantedData && !!initialUserGrantedData && currentUserGrantedData.length === initialUserGrantedData.length){
                // Setting external variable using the lambda of map function is avoided
                //{ 
                    //usersData.map((currentItem: any) => {   
                    for (let curIdx = 0; curIdx < usersData.length; curIdx++) {
                        let currentItem = usersData[curIdx];
                        let userdetail = (initaialUsersList.find(v => !!v && !!currentItem && v.userID.toString().toLowerCase() === currentItem.userID.toString().toLowerCase() && v.granted !== currentItem.granted));
                        if (anyUserChangeFound === false && !!userdetail) {
                            anyUserChangeFound = true;
                        }
                    }
                    //}) 
                //}
            }
            else {
                anyUserChangeFound = true;
            }
            if(anyUserChangeFound) {
                let newlyGrantedUserIDsCSV = "";
                let newlyDeniedUserIDsCSV = "";
                let ids = getNewlyModifiedUserIDs(usersData);
                newlyGrantedUserIDsCSV = !!ids ? ids.newlyGrantedIDsCSV : newlyGrantedUserIDsCSV;
                newlyDeniedUserIDsCSV = !!ids ? ids.newlyDeniedIDsCSV : newlyDeniedUserIDsCSV;

                setIsSavingAssignment(true);
                AssignUnassignUserToPolicy(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(policyIdToEdit), assignUsers, usersData, newlyGrantedUserIDsCSV, newlyDeniedUserIDsCSV)
                    .then((res) => {
                        setIsSavingAssignment(false);
                        setNeedLatestUnits(false);
                        setNeedLatestUsers(false);
                        if (!!res && res.isSuccess) {
                            let initialUsersList = usersData.map((item: any) => {
                                return {
                                  ...item,
                                  isInitialChanged: true,
                                };
                              });
                                setInitaialUsersList(initialUsersList);
                                let initialGrantedList = !!initialUsersList !== true ? null : initialUsersList.filter((initialItem: any) => initialItem.granted === true);
                                const selectedIds = !!initialGrantedList ? initialGrantedList.map( ({userID}: any) => userID) : [];
                                setSelectedUsersCSVInitial(selectedIds.join(','));

                                setShowWarningModal(true);
                                setHasErrored(false);
                                setSaveSuccess(true);
                                setModalPopupMessage("Assign/Unasssign users to the policy have been saved successful. ");
                        }
                        else {
                            setShowWarningModal(true);
                            setHasErrored(true);
                            setSaveSuccess(false);
                            setModalPopupMessage("Assign/Unasssign users to the policy have been failed.");
                        }
                    });
            }
            else {
                setShowWarningModal(true);
                setHasErrored(false);
                setSaveSuccess(false);
                setModalPopupMessage("Please select the user to assign/unassign to the policy");
            }
        }
    };

    const getValidIntNumberValue = (givenTxt: string) => {
        let numval = -1;
        if (!!givenTxt === false || givenTxt.trim() == "") {
            return -1;
        }
        try
        {
            numval = parseInt(givenTxt);
            if (!!numval == false || isNaN(numval) || Number.isNaN(numval)) {
                numval = -1;
            }
        }
        catch { numval = -1; }
        return numval;
    }

    const getNewlyModifiedUserIDs = (updatedSelectedUsers: any[]) => {
        let retVal = { newlyDeniedIDsCSV: "", newlyGrantedIDsCSV: "" }
        let newlyGrantedUserIDsCSV = "";
        let newlyDeniedUserIDsCSV = "";
      
        let initialSelectedUserIDs : string[] = [];
        initialSelectedUserIDs = selectedUsersCSVInitial.split(',');
        // Get the newly granted and newly denied users
        if (!!updatedSelectedUsers && updatedSelectedUsers.length > 0 &&
            updatedSelectedUsers.some(id => !!id && !!id.userID && id.userID.toString().trim() != "")) {
            // Collect only newly granted in first loop of updated user-list checked against initial granted user-list
            for (let i=0; i < updatedSelectedUsers.length; i++) {
                let curVal = updatedSelectedUsers[i];
                let curValId = "";
                if (!!curVal === false || !!curVal.userID === false || (curValId=curVal.userID.toString().trim()) == "" || curVal.granted !== true) {
                    continue;
                }
                let numVal = getValidIntNumberValue(curValId);
                let numvalTxt = numVal.toString();
                // Extra not overlapping with initial list - collect only the denied to granted
                if (!initialSelectedUserIDs.some(v => !!v && v.toString().trim() == numvalTxt)) {
                    if (curVal.granted === true) {
                        newlyGrantedUserIDsCSV = ((newlyGrantedUserIDsCSV == "") ? numvalTxt : (newlyGrantedUserIDsCSV + "," + numvalTxt));
                    }
                }
            }
            // Collect only newly denied in second loop of initial granted user-list checked against updated user-list
            for (let i=0; i<initialSelectedUserIDs.length; i++) {
                let curVal = initialSelectedUserIDs[i];
                if (!!curVal === false || (curVal=curVal.toString().trim()) == "") {
                    continue;
                }
                let numVal = getValidIntNumberValue(curVal);
                let numvalTxt = numVal.toString();
                // Overlapping with initial list - collect only granted to denied
                if (updatedSelectedUsers.some(v => !!v && !!v.userID && v.userID.toString().trim() == numvalTxt) &&
                    updatedSelectedUsers.some(v => !!v && !!v.userID && v.userID.toString().trim() == numvalTxt && v.granted === false)) {
                    newlyDeniedUserIDsCSV = ((newlyDeniedUserIDsCSV == "") ? numvalTxt : (newlyDeniedUserIDsCSV + "," + numvalTxt));
                }
            }
        }
        retVal.newlyDeniedIDsCSV = newlyDeniedUserIDsCSV;
        retVal.newlyGrantedIDsCSV = newlyGrantedUserIDsCSV;
        return retVal;
    }

    const handleSave = () => {
        if (parseInt(policyIdToEdit) > 0 && selectedFileSize > 0) {
            handleSaveConfirmModal(true);
        }
        else {
            handleSavePolicyBuilder();
        }
    }

    const handleSavePolicyBuilder = () => {
        setIsSaving(true);

        SavePolicyBuilder(oidcUser.access_token, userId, orgDomain, parseInt(orgId), "en-au", parseInt(policyIdToEdit), statusfield, policyName, selectedFileName,
            confirmationMessage, selectedFileData, selectedFileExtension, selectedFileSize, resetAcceptances) 
            .then((res) => {
                setIsSaving(false);
                if (!!res && res.isSuccess) {
                    if (!!res.error && res.error !== '') {
                        setShowWarningModal(true);
                        setHasErrored(true);
                        setSaveSuccess(false);
                        setModalPopupMessage(res.error);
                    }
                    else {
                        if (parseInt(policyIdToEdit) <= 0 && res.policyId > 0) {
                            setSelectedFileSize(0);
                            setShowWarningModal(true);
                            setHasErrored(false);
                            setSaveSuccess(true);
                            setModalPopupMessage("Policy details have been saved successfully! ");                           
                            history.push({
                                pathname: `../policybuilderdetails/` + res.policyId.toString()
                            });
                        }
                        else {
                            setShowWarningModal(true);
                            setHasErrored(false);
                            setSaveSuccess(true);
                            setModalPopupMessage("Policy details have been saved successfully! ");
                        }
                    }
                }
                else {
                    setShowWarningModal(true);
                    setHasErrored(true);
                    setSaveSuccess(false);
                    setModalPopupMessage("Policy details saving have been failed!;" + res.error !== '' ? res.error : "Please try again.");
                }
            })
    };

    const setupdatedSelectedUnitsFunction = (unitIds: string[]) => {
        if (parseInt(policyIdToEdit) && selectedFileSize > 0) {
            setupdatedSelectedUnits(unitIds);
            handleSaveConfirmModal(true);
        }
        else {
            setFinallySelectedUnitList(unitIds);
            SavePolicyBuilderAssignClick(unitIds, []);
        }
    }

    const setupdatedSelectedUsersFunction = (usersData: Array<any>[]) => {
        setFinallySelectedUsersList(usersData);
        //SavePolicyBuilderAssignClick([], usersData);
    }

    const setInitialUserListFunction =  (usersData: Array<any>[]) => {
        setInitaialUsersList(usersData);
        let initialGrantedList = !!usersData !== true ? null : usersData.filter((initialItem: any) => initialItem.granted === true);
        const selectedIds = !!initialGrantedList ? initialGrantedList.map( ({userID}: any) => userID) : [];
        setSelectedUsersCSVInitial(selectedIds.join(','));
        setGuideMe(acceptedUsers);
    }

    const setRecordsSearchStartedFunction = (hasSearchStarted: boolean) => {
    }

    const setRecordsSearchedFunction = (hasSearched: boolean) => {
        setHasAccountsSearched(hasSearched);        
    }

    const setUnitsLoadedFunction = (hasUnitsLoaded: boolean) => {
        setHasUnitsLoaded(hasUnitsLoaded);
        setGuideMe(acceptedUsers);
    }

    const setUserSelectionChangedFunction = (hasSelectedUsersChanged: boolean) => {
        setHasSelectedUsersChanged(hasSelectedUsersChanged);
    }

    const handleClosePopup = () => {
        setShowWarningModal(false);
    };

    const handlePolicyBuilderReportExpandClick = () => {
        setExpandPolicyBuilderReport(!expandPolicyBuilderReport);
        setGuideMe(acceptedUsers, expandPolicyAcceptanceChart)
    }

    const handleAssignUnits = () => {
        setNeedLatestUnits(false);
        setNeedLatestUsers(false);
        setShowWarningModal(false);
        setHasUnitsLoaded(false);
        setHasAccountsSearched(false);
        setViewPolicyLoading(false);

        setshowAssignUnits(!showAssignUnits);
        setshowAssignAccounts(false);
        setshowViewAccounts(false);
        setshowResetAccounts(false);
        setAssignUsers(false);

        let element = document.getElementById("assign");
          if (!!element)
            element.scrollIntoView();
    }

    const handleAssignAccounts = () => {
        setNeedLatestUnits(false);
        setNeedLatestUsers(false);
        setShowWarningModal(false);
        setHasUnitsLoaded(false);
        setHasAccountsSearched(false);
        setViewPolicyLoading(false);

        setshowAssignAccounts(!showAssignAccounts);
        setshowAssignUnits(false);
        setshowViewAccounts(false);
        setshowResetAccounts(false);
        setAssignUsers(true);
        let element = document.getElementById("assign");
        if (!!element)
          element.scrollIntoView();
    }

    const handleViewAccounts = () => {
        setNeedLatestUnits(false);
        setNeedLatestUsers(false);
        setShowWarningModal(false);
        setHasUnitsLoaded(false);
        setHasAccountsSearched(false);
        setViewPolicyLoading(false);

        setshowViewAccounts(!showViewAccounts);
        setshowAssignAccounts(false);
        setshowAssignUnits(false);
        setshowResetAccounts(false);
        setPolicyAcceptedUsersList([]);
        setInititalPolicyAcceptedUsersList([]);
        LoadPolicyAssignedUsers(parseInt(policyIdToEdit));
        setAssignUsers(true);
        
        let element = document.getElementById("assign");
        if (!!element)
          element.scrollIntoView();
    }

    const handleResetAccounts = () => {
        setNeedLatestUnits(false);
        setNeedLatestUsers(false);
        setShowWarningModal(false);
        setHasUnitsLoaded(false);
        setHasAccountsSearched(false);
        setViewPolicyLoading(false);

        handleConfirmModal(true);
        setshowAssignAccounts(false);
        setshowAssignUnits(false);
        setshowViewAccounts(false);
        setAssignUsers(false);
        let element = document.getElementById("assign");
        if (!!element)
          element.scrollIntoView();
    }

    const LoadPolicyAssignedUsers = (policyId: number) => {
        setViewPolicyLoading(true);
        ViewPolicyAssignedUsers(
            oidcUser.access_token,
            userId,
            orgDomain,
            policyId,
            parseInt(orgId),
            "",
            "", "", "", "", "", "view"
        ).then((response) => {
            setViewPolicyLoading(false);
            if (
                response !== null &&
                response !== undefined &&
                response.isSuccess &&
                response.policyAssignedUserItem !== null &&
                response.policyAssignedUserItem !== undefined
            ) {
                if (response.policyAssignedUserItem.length > 0) {
                    let updatedUsersList = response.policyAssignedUserItem.map((item: any) => {
                        return {
                            ...item,
                            isChecked: false,
                        };
                    });
                    let initialUsersList = response.policyAssignedUserItem.map((item: any) => {
                        return {
                            ...item,
                            isInitial: true,
                        };
                    });   
                    setPolicyAcceptedUsersList(updatedUsersList);
                    setInititalPolicyAcceptedUsersList(initialUsersList);
                    let initialGrantedList = initialUsersList.filter((initialItem: any) => initialItem.granted === true);
                    const selectedIds = initialGrantedList.map( ({userID}: any) => userID);
                    setSelectedUsersCSVInitial(selectedIds.join(','));
                    return;
                  
                } else {
                    setHasErrored(false);
                    setSaveSuccess(true);
                    return;
                }
            } else {
                setShowWarningModal(true);
                setModalPopupMessage('Fetching record has failed, please try again.');
                setSaveSuccess(false);
                setHasErrored(true);                
                return;
            }
        });
    }

    const LoadPolicy = (policyId: number) => {
        GetOnePolicyBuilder(oidcUser.access_token,
            userId,
            orgDomain,
            parseInt(orgId), policyId, "en-au").then((result: any) => {
                if (result.isSuccess && result.error == null) {
                    if (!!result.policyBuilderItem) {
                        let policy = result.policyBuilderItem;
                        setPolicyName(policy.policyName);
                        setSelectedFileName(policy.policyFileName);
                        setConfirmationMessage(policy.confirmationMessage);
                        setTotalAssignedUsersToPolicy(policy.totalAssignedUsersToPolicy);
                        setAcceptedUsers(policy.acceptedUsers);
                        setCompletedPolicyPercentage(Math.round((policy.acceptedUsers / policy.totalAssignedUsersToPolicy) * 100));
                        categoriesArray.push('');
                        categoriesArray.push('Policies Acceptance');
                        seriesData.push(policy.totalAssignedUsersToPolicy);
                        seriesData.push(policy.acceptedUsers);

                        setselectedUnits(policy.strSelectedUnits);

                        if (policyId > 0 && policy.acceptedUsers > 0) {
                            const date = new Date()
                            const nextDate = new Date()
                            nextDate.setDate(nextDate.getDate() + 1);
                            const dateString = `${date.getUTCFullYear()}-${("0" + (date.getUTCMonth() + 1)).slice(-2)}-${('0' + date.getUTCDate()).slice(-2)}`
                            const nextDateString = formatDate(nextDate);
                            getFullPolicyBuilderReport(oidcUser.access_token, userId, orgDomain, parseInt(orgId),
                                '', policyId.toString(), "1996-12-31", nextDateString, 'both', false, false).then((reportRes) => {
                                    if (!!reportRes && reportRes.isSuccess) {
                                        setPolicyBuilderReportListData(reportRes.policyBuilderReportItems);
                                        setPolicyBuilderReportListError((reportRes === null || reportRes === undefined || reportRes.isSuccess || reportRes.error === null || reportRes.error === undefined) ? "" : reportRes.error)
                                    }
                                    setLoading(false);
                                })
                        }

                        setGuideMe(policy.acceptedUsers);
                    }
                    setLoading(false);
                    
                } else {
                    setShowWarningModal(true);
                    setHasErrored(true);
                    setSaveSuccess(false);
                    setModalPopupMessage('Getting Policy Buider has failed!, Please try again!');
                }
            });
    }
    const handleSaveConfirmModal = (value: boolean) => {
        setshowSaveConfirmModal(value);
    }

    const handleSaveCancelFromConfirmPopup = () => {
        handleSaveConfirmModal(false);
    }

    const handleSaveConfirmProceed = () => {
        setResetAcceptances(true);
        handleSaveConfirmModal(false);
        handleSavePolicyBuilder();
    }

    const handleConfirmModal = (value: boolean) => {
        setshowConfirmModal(value);
    }

    const handleCancelFromConfirmPopup = () => {
        handleConfirmModal(false);
    }

    const handleResetAccountsProceed = () => {
        setIsResettingAcceptances(true);
        handleConfirmModal(false);
        ResetPolicyAcceptances(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(policyIdToEdit))
            .then((res) => {
                setIsResettingAcceptances(false);
                setIsSaving(false);
                if (!!res && res.isSuccess) {
                    setHasErrored(false);
                    setSaveSuccess(true);
                    setShowWarningModal(true);
                    setModalPopupMessage("Resetting Policy Acceptances has been successful.");
                    setselectedUnits('');
                    setPolicyAcceptedUsersList([]);
                    setInititalPolicyAcceptedUsersList([]);
                    setAcceptedUsers(0);
                }
                else {
                    setShowWarningModal(true);
                    setHasErrored(true);
                    setSaveSuccess(false);
                    setModalPopupMessage("Reset Policy Acceptancies have been failed!, Please try again.");
                }
            })
    }

    const handleSameUnitModalForConfirmation = (value: boolean) => {
        setShowSameUnitModalForConfirmation(value);
    }

    const handleCancelSameUnitModalForConfirmation = () => {
        setShowSameUnitModalForConfirmation(false);
        setNeedLatestUnits(false);
        setNeedLatestUsers(false);
    }

    const handleSaveConfirmProceedSameUnitModalForConfirmation = () => {
        AssignUnitsToPolicyFunction(selectedUnits.split(','));
    }

    const setAllDeniedUnitsAsCSVFunction = (csvGiven: string) => {
        setCSVOfAllDeniedUnits(csvGiven);
    }

    useEffect(() => {
        setTitle(allResourcesToTranslations.policybuilder);
		setTitleEnglish(pageTitles.policybuilder);
        updateBackButtonRoute("/policybuilderlist");
        if (parseInt(policyIdToEdit) > 0) {
            LoadPolicy(parseInt(policyIdToEdit));
        }
        
        return () => { };
    }, []);

    useEffect(() => {
        if (showWarningModal) {
          setTimeout(() => {
            setShowWarningModal(false);
            setSaveSuccess(false);
          }, 3000)
        }
      }, [showWarningModal])

      useEffect(() => {
        if (policyAcceptedUsersList.length > 0 && showViewAccounts) {
         setGuideMe(acceptedUsers);
        }
      }, [policyAcceptedUsersList])

      useEffect(() => {
         setGuideMe(acceptedUsers);
      }, [showAssignAccounts, hasAccountsSearched])

    if (loading) return <LoaderContainer />;
    return (
        <>
            <Steps
                enabled={intro.stepsEnabled}
                steps={intro.steps}
                initialStep={intro.initialStep}
                onExit={onExit}
                options={{
                    nextLabel: "Next",
                    prevLabel: "Back",
                    tooltipClass: "sample-class",
                    showBullets: true,
                }} />
            <CustomTooltip displayText={["Guide Me"]}>
                <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
                    className={styles.guideMeButtonSpecific} style={{ right: '100px' }}>
                    <GuideIcon />
                </span>
            </CustomTooltip>
            <Modal modalOpen={showSameUnitModalForConfirmation} setModalOpen={handleSameUnitModalForConfirmation} type="warning" hideCloseBtn={true}>
                <div className={styles.modalContentWrapper}>
                    <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>Confirmation</h2>
                    <div className={styles.inputContainer}>

                        <p>{sameUnitModelMessage}</p>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                            top: "10px",
                        }}
                    >
                        <CustomTooltip displayText={[allResourcesToTranslations.return]}>
                            <span className={styles.returnIcon} onClick={handleCancelSameUnitModalForConfirmation}>
                                <CloseWhiteIcon />
                            </span>
                        </CustomTooltip>
                        <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
                            <span className="saveData">
                                <Button
                                    onClick={handleSaveConfirmProceedSameUnitModalForConfirmation}
                                    variant="contained"
                                    color="primary"
                                    className="save"
                                >
                                    <ProceedIcon />
                                </Button>
                            </span>
                        </CustomTooltip>
                    </div>
                </div>
            </Modal>
            <Modal modalOpen={showSaveConfirmModal} setModalOpen={handleSaveConfirmModal} type="warning">
                <div className={styles.modalContentWrapper}>
                    <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>Confirmation</h2>
                    <div className={styles.inputContainer}>
                        <p>You are about to overwrite a document with a new file.</p>
                        <p>Do you wish to reset previous policy acceptances?</p>
                        <div>
                            <Checkbox
                                size="small"
                                color="primary"
                                checked={resetAcceptances}
                                className={styles.checkBox}
                                onClick={() => setResetAcceptances(!resetAcceptances)}
                            />
                            Reset Acceptances
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                            top: "10px",
                        }}
                    >
                        <CustomTooltip displayText={[allResourcesToTranslations.return]}>
                            <span className={styles.returnIcon} onClick={handleSaveCancelFromConfirmPopup}>
                                <CloseWhiteIcon />
                            </span>
                        </CustomTooltip>
                        <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
                            <span className="saveData">
                                <Button
                                    onClick={handleSaveConfirmProceed}
                                    variant="contained"
                                    color="primary"
                                    className="save"
                                >
                                    <ProceedIcon />
                                </Button>
                            </span>
                        </CustomTooltip>
                    </div>
                </div>
            </Modal>
            <Modal modalOpen={showConfirmModal} setModalOpen={handleConfirmModal} type="warning">
                <div className={styles.modalContentWrapper}>
                    <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>Policy Acceptances</h2>
                    <div className={styles.inputContainer}>

                        <p>Do you wish to reset policy acceptances?</p>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                            top: "10px",
                        }}
                    >
                        <CustomTooltip displayText={[allResourcesToTranslations.return]}>
                            <span className={styles.returnIcon} onClick={handleCancelFromConfirmPopup}>
                                <CloseWhiteIcon />
                            </span>
                        </CustomTooltip>
                        <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
                            <span className="saveData">
                                <Button
                                    onClick={handleResetAccountsProceed}
                                    variant="contained"
                                    color="primary"
                                    className="save"
                                >
                                    <ProceedIcon />
                                </Button>
                            </span>
                        </CustomTooltip>
                    </div>
                </div>
            </Modal>
            <UnbrandedCard>
                <div className={styles.mainWrapper}>
                    <div className={styles.Wrapper}>
                        <div className={styles.inputContainer} >
                            {(parseInt(policyIdToEdit) <= 0) &&
                                <span className={styles.labelText}>{allResourcesToTranslations.addnewpolicy}</span>
                            }
                            {(parseInt(policyIdToEdit) > 0) &&
                                <span className={styles.labelText}>{allResourcesToTranslations.policydetails}</span>
                            }
                        </div>
                        <div className={responsivestyles.rowrule} style={{ width: '100%' }} >
                            <div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
                                <div className={`${styles.leftrightPadding10} ${styles.inputContainer} policynameguideme`}>
                                    <TextField className={styles.input} label={allResourcesToTranslations.name} variant="outlined"
                                        disabled={isFileUploading} autoComplete="off" InputLabelProps={{ shrink: true, }}
                                        value={policyName} onChange={(e) => setPolicyName(e.target.value)} />
                                </div>
                            </div>
                            <div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
                                <div className={`${styles.leftrightPadding10} ${styles.inputContainer} policystatusguideme`}>
                                    <div style={{ width: "100%" }}>
                                        <FormControl variant="outlined" className={styles.formControl}>
                                            <InputLabel>{allResourcesToTranslations.status}</InputLabel>
                                            <Select
                                                style={{ width: "100%" }}
                                                value={statusfield}
                                                native
                                                disabled={isFileUploading}
                                                onChange={(e: any) => setStatusfield(parseInt(e.target.value))}
                                                variant="outlined"
                                                className={allResourcesToTranslations.status}
                                                label={allResourcesToTranslations.status}>
                                                <option value={1}>{allResourcesToTranslations.active}</option>
                                                <option value={2}>{allResourcesToTranslations.inactive}</option>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={responsivestyles.rowrule} style={{ width: '100%', paddingTop: '20px' }} >
                            <div className={`${responsivestyles.columnrule_12} ${responsivestyles.columnrule_s_12} policyconfirmguideme`}>
                                <div className={`${styles.leftrightPadding10} ${styles.inputContainer}`}>
                                    <TextField
                                        className={styles.input}
                                        label={allResourcesToTranslations.confirmationmessage}
                                        disabled={isFileUploading}
                                        style={{ width: "100%", marginBottom: "15px" }}
                                        variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                                        value={confirmationMessage}
                                        onChange={(e) => setConfirmationMessage(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.inputContainer} >
                            <span className={styles.labelText}>{allResourcesToTranslations.choosefile}</span>
                        </div>
                        <div className={responsivestyles.rowrule} style={{ width: '100%' }} >
                            <div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
                                <div className={`${styles.leftrightPadding10} ${styles.inputContainer}`}>
                                    <CustomTooltip displayText={[allResourcesToTranslations.draganddropcustomtooltip]}>
                                        <div className="policydocguideme">
                                            <PolicyFileDropBox selectedFile={selectedFile} uploadFile={handleFileUpload} setSelectedFile={handleSetSelectedFileName} setSelectedFileSize={handleSetSelectedFileSize} />
                                        </div>
                                    </CustomTooltip>
                                </div>
                            </div>

                            <div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
                                <div className={`${styles.leftrightPadding10} ${styles.inputContainer}`}>

                                    {selectedFileName.toString().length > 0 &&
                                        <div className={styles.importfileContainer}>
                                            <div style={{ width: "100%", display: "inline-flex" }}>
                                                <div style={{ float: "left", width: "95%", display: "flex" }}>
                                                    <div> <FileInfoIcon></FileInfoIcon></div>
                                                    <div><span>{selectedFileName.toString()}</span>
                                                    </div>
                                                </div>
                                                <div style={{ float: "right", width: "5%" }}>
                                                    <span onClick={handleResetFileUpload} style={{ cursor: "pointer" }}>
                                                        <CloseIcon />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* Add new Save button */}

                        <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", width: "100%", paddingTop: "10px", clear: "both" }}>
                            <CustomTooltip displayText={["Save Policy"]}>
                                <span className="saveData" style={{ marginRight: "-5px" }}>
                                    <Button
                                        disabled={policyName.trim() === "" || confirmationMessage.trim() === "" || selectedFileName.toString().length <= 0}
                                        onClick={handleSave}
                                        variant="contained"
                                        color="primary"
                                        className="save"
                                    >
                                        <SaveIcon />
                                    </Button>
                                </span>
                            </CustomTooltip>
                            <div className={styles.loaderContainer}>
                                <CircularProgress
                                    style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
                                    color="primary"
                                    size="10"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </UnbrandedCard>
            {(parseInt(policyIdToEdit) > 0 && acceptedUsers > 0) && (
                <UnbrandedCard>
                    <Accordion style={{ margin: "20px 0px", borderRadius: "6px 6px 6px 6px", border: '1px solid #D3D6DF', position: 'inherit', boxShadow: "none" }} expanded={expandPolicyAcceptanceChart} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="unitadmin-content"
                            id="unitadmin-header"
                            onClick={(e) => {setExpandPolicyAcceptanceChart(!expandPolicyAcceptanceChart); setGuideMe(acceptedUsers, !expandPolicyAcceptanceChart);}}
                        >
                            <Typography><div className={styles.lableContainer} >
                                <span className={styles.labelText}> {allResourcesToTranslations.policyacceptance}</span>
                                {(expandPolicyAcceptanceChart && <p
                                    style={{
                                        color: "#D3D6DF",
                                        fontSize: "12px",
                                    }}> {acceptedUsers + ' Accounts / ' + totalAssignedUsersToPolicy + ' Accounts'}</p>)}
                            </div>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ paddingTop: "0px", width: '98%' }}>
                            <Typography style={{ width: '100%' }}>
                                <div style={{ width: "100%" }}>
                                    <div className="policyacceptance">
                                        <PolicyProgressChartComponent totalAssignedPolicies={totalAssignedUsersToPolicy} acceptedPolicies={acceptedUsers} notAcceptedPolicies={totalAssignedUsersToPolicy - acceptedUsers} />
                                    </div>
                                </div>
                                <div style={{ width: "100%" }} className="expandPolicyBuilderReport">
                                    <div style={{ paddingTop: "20px" }}>

                                        <Accordion style={{ margin: "0px 0px", position: 'inherit', boxShadow: "none" }}
                                            expanded={expandPolicyBuilderReport}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="unitadmin-content"
                                                id="unitadmin-header"
                                                style={{ borderBottom: '1px solid #D3D6DF', padding: "0px" }}
                                                onClick={(e) => handlePolicyBuilderReportExpandClick()}
                                            >

                                                <Typography>
                                                    <b className={styles.labelText}> {allResourcesToTranslations.policybuilderreport}</b>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ padding: "0px" }}>
                                                <div style={{ width: "100%", paddingTop: "20px" }}>
                                                    <div style={{ width: '100%', paddingLeft: '0px', display: "inline-block" }} >
                                                        <div style={{ width: '100%', display: "inline-block" }} >
                                                            <PolicyBuilderReportResultList policyId={parseInt(policyIdToEdit)} policyBuilderReportListData={policyBuilderReportListData} resultErrorData={policyBuilderReportListError}></PolicyBuilderReportResultList>
                                                        </div>
                                                    </div>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </UnbrandedCard>
            )}
           
            {(parseInt(policyIdToEdit) > 0) && (
                <div className="buttonspanel">
                    <AssignButtonPanel isCalledFrom="policy" handleAssignUnitsClick={handleAssignUnits} handleAssignAccountsClick={handleAssignAccounts} handleViewAccountsClick={handleViewAccounts} handleResetAccountsClick={handleResetAccounts} isResetInProgress={isResettingAcceptances}></AssignButtonPanel>
                </div>
            )}
            
            <div id="assign">
                {(parseInt(policyIdToEdit) > 0) && showAssignUnits && (
            
                    <AssignUnits isCalledFrom="policy" selectedUnitIds={selectedUnits} setUpdatedSelectedUnits={setupdatedSelectedUnitsFunction} setUnitsLoaded={setUnitsLoadedFunction} needLatestUnits={needLatestUnits} setAllDeniedUnitsAsCSV={setAllDeniedUnitsAsCSVFunction}></AssignUnits>
                )}

                {(parseInt(policyIdToEdit) > 0) && showAssignAccounts && (
                    <div className={styles.mainWrapper}>
                        <AccountManagement isCalledFrom="PolicyBuilder" needLatestUsers={needLatestUsers} setUpdatedSelectedUsers={setupdatedSelectedUsersFunction} 
                        setInitialUsersData={setInitialUserListFunction} setSearchStarted={setRecordsSearchStartedFunction} setRecordsSearched={setRecordsSearchedFunction} 
                        policyId={parseInt(policyIdToEdit)} sethasSelectionChanged={setUserSelectionChangedFunction}></AccountManagement>
                    </div>
                )}

                {(parseInt(policyIdToEdit) > 0) && showViewAccounts && !viewPolicyLoading && policyAcceptedUsersList.length > 0 && (
                    <UserListSection usersData={policyAcceptedUsersList} isCalledFrom="PolicyBuilder" setUpdatedSelectedUsers={setupdatedSelectedUsersFunction} needLatestUsers={needLatestUsers}
                    profileID={-1} profilePeriodID={-1} selectedUnitIDs={[]} allUnitIDs={[]} filterFirstName={""} filterLastName={""} filterUserName={""} filterEmail={""}/>
                )}

                {(parseInt(policyIdToEdit) > 0) && showViewAccounts && viewPolicyLoading && (
                    <LoaderContainer />
                )}

                {(parseInt(policyIdToEdit) > 0) && showViewAccounts && !viewPolicyLoading && policyAcceptedUsersList.length <= 0 && (
                    <BrandedCard>
                        <div className={styles.noResultsCard} style={{ marginTop: "20px" }}>
                            <div>No Results to Display</div>
                        </div>
                    </BrandedCard>
                )}
            </div>
            {(parseInt(policyIdToEdit) > 0 && 
            ((showAssignUnits && hasUnitsLoaded) || 
            (showAssignAccounts && hasAccountsSearched) || 
            (showViewAccounts && !viewPolicyLoading && policyAcceptedUsersList.length > 0))) && (
                <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", width: "100%", paddingTop: "10px", clear: "both" }}>
                    <CustomTooltip displayText={showAssignUnits ? ["Assign Units"] : (showAssignAccounts ? ["Assign Accounts"] : (showViewAccounts ? ["Unassign Accounts"] : ["Save"]))}>
                        <span className="saveAssignedData" style={{ marginRight: "-5px" }}>
                            <Button
                                onClick={handleSaveAssignment}
                                variant="contained"
                                color="primary"
                                className="save"
                            >
                                <SaveIcon />
                            </Button>
                        </span>
                    </CustomTooltip>
                    <div className={styles.loaderContainer}>
                        <CircularProgress
                            style={{ width: isSavingAssignment ? "20px" : "0px", padding: "15px 10px" }}
                            color="primary"
                            size="10"
                        />
                    </div>
                </div>
            )}
            {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
        </>
    );
};

export default PolicyBuilderDetails;

