import React, { Children, useContext } from 'react'
import styles from '../../styles/sideMenu.module.css';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { UIContext } from '../../contexts/UIContext';

interface Props {
    icon: React.ReactNode
    optionName: string
    displayName: string
    customClickEvent: Function
    sideMenuOpen: boolean
    isSelected: boolean
    showAdminMenuItems: boolean
    children: React.ReactNode
    noOfChildrenToRemove: number
}

export const SideMenuOptionExpandable = ({ icon, optionName, displayName, customClickEvent, sideMenuOpen, isSelected, showAdminMenuItems, children, noOfChildrenToRemove}: Props) => {

    const numberOfChildren = Children.count(children) - noOfChildrenToRemove;

    const handleClick = () => {
        customClickEvent( isSelected ? "" : optionName )
    }
    if (!showAdminMenuItems) return null
    return (
        <>
            <div className={ isSelected ? styles.accordionSelected : styles.accordion} onClick={handleClick}>
                <div className={styles.sideMenuTextIconContainer}>
                    { icon }
                    <span className={styles.sideMenuText} style={{ width: sideMenuOpen? "155px" : "0px" }}>
                        { !!displayName ? displayName : "" }
                    </span>
                    <div className={ isSelected? styles.accordionArrowExpanded : styles.accordionArrow } style={{ display: sideMenuOpen ? "grid" : "none"}} >
                        <KeyboardArrowDownIcon />
                    </div>
                </div>
            </div>
            <div style={{ height: isSelected ? `${ (numberOfChildren > 8 ? 38.5 : 43) * numberOfChildren }px` : '0px' }} className={styles.exapandedAccordionContainer}>
                { children }
            </div>
        </>
    )
}

export default SideMenuOptionExpandable;
