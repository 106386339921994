import axios from 'axios';
import { addError } from '../../AddError';

const SavePolicyBuilder = async (token: string, requestinguserId: number, orgDomain: string, orgId:number,langCode:string,
  policyId: number, active: number, policyName: string, policyFileName: string, confirmationMessage: string, byteArray: string, 
  fileExtension: string, fileSize: number, resetAcceptances: boolean
  ) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/administration/SavePolicyBuilder`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        "ReportRequestingUserID": requestinguserId,
        "OrganisationID": orgId,
        "LangCode": langCode,
        "PolicyId": policyId,
        "Active": active,
        "PolicyName": policyName,
        "PolicyFileName": policyFileName,
        "ConfirmationMessage": confirmationMessage,
        "FileData": byteArray,
        "FileExtension": fileExtension,
        "PolicyFileSize": fileSize,
        "ResetAcceptances": resetAcceptances
    },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.response, "SavePolicyBuilder.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.request, "SavePolicyBuilder.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.message, "SavePolicyBuilder.ts", 1)
    }
    return null;
  }
};

export default SavePolicyBuilder;