import axios from 'axios';
import { addError } from './AddError';

export const getPreQuizStartResult = async (token: string, userId: number, orgDomain: string, moduleId: number, isLesson: boolean, uniqueID: string, moduleType: string) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/usersessions/PreQuizStartResult`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        userid: userId,
        moduleid: moduleId,
        iscourseitemlesson: + isLesson,
        UniqueID: uniqueID,
        ModuleType: moduleType
      },
    });
    const data = await response.data;
    return data;
    
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "GetPreQuizStartResult.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "GetPreQuizStartResult.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "GetPreQuizStartResult.ts", 1)
    }
    return null;
  }
};

export default getPreQuizStartResult;
