import * as React from "react"

function AustraliaFlag() {
  return (
    <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.3 28.3"
    style={{
      enableBackground: "new 0 0 28.3 28.3",
    }}
    xmlSpace="preserve"    
  >
    <style type="text/css">
      {
        "\n\t.st0{fill:#00008B;}\n\t.st1{fill:#FFFFFF;}\n\t.st2{fill:#FF0000;}\n"
      }
    </style>
    <path
      className="st0"
      d="M1.6,0C0.7,0,0,0.7,0,1.6v25c0,0.9,0.7,1.6,1.6,1.6h12.6h12.4c0.9,0,1.6-0.7,1.6-1.6v-25c0-0.9-0.7-1.6-1.6-1.6 H14.2H1.6z"
    />
    <path
      className="st1"
      d="M8.4,22.2l-1.5-1.6l-1.6,1.6L5.6,20l-2.2-0.3l1.9-1.2l-1.2-1.9l2.1,0.8l0.7-2.1l0.7,2.1l2.1-0.7l-1.2,1.9 l1.9,1.2L8.1,20L8.4,22.2z"
    />
    <path
      className="st1"
      d="M22.4,4.7L21.7,4L21,4.7l0.1-1l-1-0.1L21,3.1l-0.5-0.9l1,0.4l0.3-1l0.3,1l1-0.3l-0.6,0.9l0.9,0.5l-1,0.1 L22.4,4.7z"
    />
    <path
      className="st1"
      d="M25.8,8.7L25.1,8l-0.7,0.7l0.1-1l-1-0.1l0.9-0.5l-0.5-0.9l1,0.4l0.3-1l0.3,1l1-0.3l-0.6,0.9l0.9,0.5l-1,0.1 L25.8,8.7z"
    />
    <path
      className="st1"
      d="M19.2,9.8l-0.7-0.7l-0.7,0.7l0.1-1l-1-0.1l0.9-0.5l-0.5-0.9l1,0.4l0.3-1l0.3,1l1-0.3l-0.6,0.9l0.9,0.5l-1,0.1 L19.2,9.8z"
    />
    <path
      className="st1"
      d="M23.7,12.5l-0.4-0.4l-0.4,0.4L23,12l-0.5-0.1l0.5-0.3l-0.3-0.5l0.5,0.2l0.2-0.5l0.2,0.5l0.5-0.2l-0.3,0.5 l0.5,0.3l-0.5,0L23.7,12.5z"
    />
    <path
      className="st1"
      d="M22.4,22.7L21.7,22L21,22.7l0.1-1l-1-0.1l0.9-0.5l-0.5-0.9l1,0.4l0.3-1l0.3,1l1-0.3l-0.6,0.9l0.9,0.5l-1,0.1 L22.4,22.7z"
    />
    <path
      className="st2"
      d="M0,2.4v1.2l6,3.5l-6,3.5v1.2l7.1-4.1l7.1,4.1v-1.2l-6-3.5l6-3.5V2.4L7.1,6.5L0,2.4z"
    />
    <path
      className="st2"
      d="M5.9,0v5.9H0v2.4h5.9v5.9h1.3h1.2V8.3h5.9V5.9H8.3V0H7.1H5.9z"
    />
    <path
      className="st1"
      d="M5.6,0v5.3L0,2.1v0.5l5.1,3H4L0,3.3v0.5l3,1.7H0v0.5h3.8h0.9h0.5c0.3,0,0.5,0,0.8,0V5.6V0H5.6z M8.1,0v5.6v0.5 c0.3,0,0.6,0,0.8,0h0.5h0.9h3.8V5.6h-3l3-1.7V3.3l-4,2.3H9l5.1-3V2.1L8.5,5.3V0L8.1,0z M6.1,8.1c-0.3,0-0.6,0-0.8,0H4.8H3.8H0v0.5h3 l-3,1.7v0.5l4-2.3h1.2l-5.1,3V12l5.6-3.2v5.3h0.5V8.5V8.1z M8.1,8.1v0.5v5.6h0.5V8.8l5.6,3.2v-0.5L9,8.5h1.2l4,2.3v-0.5l-3-1.7h3 V8.1h-3.8H9.4H8.9C8.6,8.1,8.1,8.1,8.1,8.1L8.1,8.1z"
    />
  </svg>
  )
}

export default AustraliaFlag