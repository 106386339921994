import axios from 'axios';
import { addError } from './AddError';

export const getFullCurrentAdminReport = async (
    token: string, 
    userId: number,
    orgDomain: string,
    orgId: number,
    unitIds: string,
    courseIds: string,
    userFirstName: string,
    userLastName: string,
    userName: string,
    userEmail: string,
    effectiveDateOrg: string,
    groupBy: string, 
    sortBy: string,
    includeInactive: boolean,
    classificationId: number,
    filterFirstName: string,
    filterLastName: string,
    filterUserName: string,
    filterEmail: string,
    isHistoricReport: boolean
    ) => {

    // console.log({
    //     OrganisationID: orgId,
    //     ReportRequestingUserID : userId,
    //     UnitIDs: unitIds,
    //     CourseIDs: courseIds,
    //     UserFirstName : filterFirstName,
    //     UserLastName : filterLastName,
    //     UserName : filterUserName,
    //     UserEmail: filterEmail,
    //     EffectiveDateOrg : effectiveDateOrg,
    //     GroupBy: groupBy, 
    //     SortBy: sortBy,
    //     AdminUserID: userId,
    //     IncludeInactive:includeInactive,
    //     ClassificationID: classificationId
    // })

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/userreport/GetFullCurrentAdminReport`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      // data: {
      //   OrganisationID: orgId,
      //   ReportRequestingUserID : userId,
      //   UnitIDs: "3666",
      //   CourseIDs: "882",
      //   UserFirstName : userFirstName,
      //   UserLastName : userLastName,
      //   UserName : userName,
      //   UserEmail: userEmail,
      //   EffectiveDateOrg : effectiveDateOrg,
      //   GroupBy: groupBy, 
      //   SortBy: sortBy,
      //   AdminUserID: userId,
      //   inclInactive:includeInactive,
      //   ClassificationID: classificationId
      // },

      data: {
        ReportRequestingUserID : userId,
        OrganisationID : orgId,
        UnitIDs : unitIds,
        CourseIDs : courseIds,
        userFirstName : filterFirstName, //Filter params
        userLastName : filterLastName, //Filter params
        userName :  filterUserName, //Filter params
        userEmail : filterEmail,//Filter params
        effectiveDateOrg : effectiveDateOrg,
        groupBy : groupBy, //Filter params
        sortBy : sortBy, //Filter params
        adminUserID : userId, // Pass the user id given
        ClassificationID : classificationId,
        inclInactive : includeInactive,
        IsHistoricReport: isHistoricReport
      }
    });
    const data = await response.data;
  
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      };    
      addError(token, userId, orgDomain, "Rest Exception", error.response, "GetFullCurrentAdminReport.tsx", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      };    
      addError(token, userId, orgDomain, "Rest Exception", error.request, "GetFullCurrentAdminReport.tsx", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      };    
      addError(token, userId, orgDomain, "Rest Exception", error.message, "GetFullCurrentAdminReport.tsx", 1)
    }
    return null;
  }
};

export default getFullCurrentAdminReport;
