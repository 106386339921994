import axios from 'axios';
import { addError } from '../../AddError';

const saveUnitAdministrator = async (token: string, adminuserId: number, orgDomain: string, orgId:number, unitId: number,  givenuserIDs: string,adminSubUnit: boolean,reportingAccessOnly: boolean) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/unit/SaveUnitAdministrator`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        "RequestingUserID": adminuserId,
        "OrganisationID": orgId,
        "UnitID" : unitId,
        "givenuserID":givenuserIDs,
        "adminSubUnit":adminSubUnit,
        "reportingAccessOnly":reportingAccessOnly

    },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, adminuserId, orgDomain, "Rest Exception", error.response, "SaveUnitAdministrator.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, adminuserId, orgDomain, "Rest Exception", error.request, "SaveUnitAdministrator.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, adminuserId, orgDomain, "Rest Exception", error.message, "SaveUnitAdministrator.ts", 1)
    }
    return null;
  }
};

export default saveUnitAdministrator;