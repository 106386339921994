import React, { useContext, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import styles from '../../styles/SAExitButton.module.css';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import Modal from '../../Components/Modal';
import { useHistory} from 'react-router-dom';
import ProceedIcon from '../../CustomIcons/ProceedIcon';
import CustomTooltip from "../../Components/CustomTooltip";
import CloseIcon from '../../CustomIcons/CloseIcon';

interface Props { 
    handleCancel: (() => void) | undefined
    handleExitProceed: (() => void) | undefined
    handleModal: (() => any)
    showModal: boolean | false
    quizType: string | ""
}

export const SAExitButton = ({handleCancel, handleExitProceed, handleModal, showModal, quizType}: Props): JSX.Element => {
    const { translations: { allResourcesToTranslations } } = useContext(TranslationsContext)
    const history = useHistory();
  
    return (
        <>           
        <div className={(!!quizType && (quizType.toString().toLowerCase() === "rise360" || quizType.toString().toLowerCase() === "storyline360")) ? 
                            styles.container_artrise360 : styles.container}>
            <Button onClick={ handleModal} variant="contained" color="primary">
                <ExitToAppIcon/>
                { allResourcesToTranslations.exit }
            </Button>
        </div>
        <Modal modalOpen={showModal} setModalOpen={handleModal} type = "warning"  hideCloseBtn={true} >
            <div style={{position:'relative', top:'-30px'}}>
                <h3 style={{color:'#e7a652'}}>Exit</h3>
                <p>Do you want to exit course?</p>                      
            </div>           
            <div style={{display:'flex', justifyContent:'center', position:'relative'}}>     
            <CustomTooltip displayText={["Return"]}>
              <span className={styles.returnIcon} >
                <span onClick={handleCancel}>
                    <CloseIcon />
                </span>
              </span>
              
            </CustomTooltip>     
            <CustomTooltip displayText={["Proceed"]}>
                <span className={styles.proceedIcon} >
                    <span onClick={handleExitProceed}>
                    <ProceedIcon />
                    </span>
                </span>             
            </CustomTooltip>
            </div>
        </Modal>
        </>
    )
}

export default SAExitButton
