import React from "react";
import { UnitTreeItem } from "../../../globalTypes";
import styles from "../../../styles/treeComponentDND.module.css";
import { FormControlLabel } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {v4 as uuidv4} from 'uuid';

interface TreePropsWithDND {
  parentUnit: UnitTreeItem | null;
  units: Array<UnitTreeItem> | null;
  originalUnits: Array<UnitTreeItem> | null;
  updateSelectedUnit: Function;
  updateExpandedUnit: Function;
  isAnyUnitSelected: Function;
  handleDragEndOnUnitTree: Function;
}

interface TreeNodePropsWithDND {
  parentUnit: UnitTreeItem | null;
  originalUnits: Array<UnitTreeItem> | null;
  uniquePrefix: string;
  unit: UnitTreeItem;
  updateSelectedUnit: Function;
  updateExpandedUnit: Function;
  isAnyUnitSelected: Function;
  unitUniqueID: number;
  unitIndex: number;
  handleDragEndOnUnitTreeLocal: Function;
}

const RadioUnitTreeNodeWithDND = ({
  parentUnit,
  originalUnits,
  unitIndex,
  uniquePrefix,
  unit,
  updateSelectedUnit,
  updateExpandedUnit,
  isAnyUnitSelected,
  unitUniqueID,
  handleDragEndOnUnitTreeLocal
}: TreeNodePropsWithDND) => {
  const hasChild = unit.childUnitTreeItems !== null;

  const handleClick = (event: any) => {
    event.stopPropagation();
  };

  return (
    <li className={styles.treeLi} hidden={unit.isHidden === true}>
      <div
        className={styles.treeRow}
        id={uniquePrefix + (unit.name == "--drop here--" ? uuidv4() : unit.name.replaceAll(" ", "_s_"))}
      >
        {hasChild ? (
          <span
            className={styles.treeArrow}
            style={{ marginRight: "7px", marginLeft:'-19px' }}
            onClick={(e) => handleClick(e)}
          >
            {" "}
            {unit.isExpanded ? "⯆" : "⯈"}{" "}
          </span>
        ) : null}

        <Droppable droppableId={unit.unitID <= 0 ? (parentUnit == null ? uuidv4() : parentUnit.unitID.toString() + "_" + "1") : unitUniqueID.toString()} >  
          {(providedDrop, snapshotDrop) => (  
              <div  
                  {...providedDrop.droppableProps}  
                  ref={providedDrop.innerRef}  
              >                 
                {
                  snapshotDrop.isDraggingOver && 
                  <nav style={{margin: "0px auto", border: "2px dotted darkgreen", width: "100%"}}>
                    <span style={{margin: "0px auto", color: "black", background: "lightgreen", width: "100%"}}>{"--drop here--"}</span>
                  </nav>
                }
                {
                  unit.name == "--drop here--" ? 
                  <FormControlLabel
                    value={unit.unitID <= 0 ? (parentUnit == null ? uuidv4() : parentUnit.unitID.toString() + "_" + "1") : unit.unitID}
                    //control={<Radio color="primary" size="small" style={{padding: "15px"}} /> }
                    control={<span></span>}
                    label={unit.name}
                    className={styles.labelContent}
                  />
                  :
                  <Draggable draggableId={unit.unitID <= 0 ? (parentUnit == null ? uuidv4() : parentUnit.unitID.toString() + "_" + "1") : unitUniqueID.toString()} index={unitIndex}>  
                      {(provided, snapshot) => (  
                          <div  
                            ref={provided.innerRef}  
                            {...provided.draggableProps}  
                            {...provided.dragHandleProps}  
                          > 
                            <nav id={(unit.unitID <= 0 ? (parentUnit == null ? uuidv4() : parentUnit.unitID.toString() + "_" + "1") : unitUniqueID.toString()) + "_nav"} 
                                  style={{margin: "0px auto", border: snapshot.isDragging ? "2px solid darkgrey" : "0px", background: snapshot.isDragging ? "white" : "#EEEEEE"}} 
                                  onMouseOver={() => { 
                                    if (snapshot.isDragging && snapshot.dropAnimation) {
                                      return;
                                    }
                                    let elm = document.getElementById((unit.unitID <= 0 ? (parentUnit == null ? uuidv4() : parentUnit.unitID.toString() + "_" + "1") : unitUniqueID.toString()) + "_nav"); 
                                    if (!!elm && !!elm.style && elm.style.cssText !== null && elm.style.cssText !== undefined) {
                                      elm.style.cssText += 'border:2px solid darkgrey';
                                    }
                                  }}
                                  onMouseLeave={() => {  
                                    if (snapshot.isDragging) {
                                      return;
                                    }
                                    let elm = document.getElementById((unit.unitID <= 0 ? (parentUnit == null ? uuidv4() : parentUnit.unitID.toString() + "_" + "1") : unitUniqueID.toString()) + "_nav"); 
                                    if (!!elm && !!elm.style && elm.style.cssText !== null && elm.style.cssText !== undefined) {
                                      elm.style.cssText += 'border:0px';
                                    }
                                  }}
                                  >
                              <FormControlLabel
                                value={unit.unitID <= 0 ? (parentUnit == null ? uuidv4() : parentUnit.unitID.toString() + "_" + "1") : unit.unitID}
                                // control={<Radio color="primary" size="small" style={{padding: "5px"}} /> }
                                control={<span></span>}
                                label={unit.name}
                                className={styles.labelContent}
                              />
                            </nav>
                        </div>)}
                  </Draggable>
                } 
              </div>  
          )}  
        </Droppable>  
      </div>
      {hasChild && unit.isExpanded ? (
        <RadioUnitTreeWithDND
          parentUnit={unit}
          originalUnits={originalUnits}
          units={unit.childUnitTreeItems}
          updateSelectedUnit={updateSelectedUnit}
          updateExpandedUnit={updateExpandedUnit}
          isAnyUnitSelected={isAnyUnitSelected}
          handleDragEndOnUnitTree={handleDragEndOnUnitTreeLocal}
        />
      ) : null}
    </li>
  );
};

const RadioUnitTreeWithDND = ({
  parentUnit,
  units,
  originalUnits,
  updateSelectedUnit,
  updateExpandedUnit,
  isAnyUnitSelected,
  handleDragEndOnUnitTree
}: TreePropsWithDND) => {

  const getNumberValueOrDefault = (givenTxt: string, defaultVal: number) => {
      if (!!givenTxt === false) {
          return defaultVal;
      }
      try {
          let givenVal = parseInt(givenTxt);
          if (givenVal <= 0 || Number.isNaN(givenVal)) {
              return defaultVal;
          }
          return givenVal;
      }
      catch {
          return defaultVal;
      }
  }

  const getParentUnitID = (unitToCompare: string, originalUnitsGiven: UnitTreeItem[] | null, parentIDGiven: number = -1) => {
    if (!!unitToCompare && !!originalUnitsGiven && originalUnitsGiven.length > 0) {
      let unitToCompareTrim = unitToCompare.trim();
      for (let idx=0; idx < originalUnitsGiven.length; idx++) {
        let curPUnit = originalUnitsGiven[idx];
        if (!!curPUnit && curPUnit.unitID.toString() === unitToCompareTrim) {
          return parentIDGiven;
        }
        if (!!curPUnit && !!curPUnit.childUnitTreeItems && curPUnit.childUnitTreeItems.length > 0) {
          for (let idx2 = 0; idx2 < curPUnit.childUnitTreeItems.length; idx2++) {
            let curCUnit = curPUnit.childUnitTreeItems[idx2];
            if (!!curCUnit && !!curCUnit.unitID && curCUnit.unitID.toString() === unitToCompareTrim) {
              return curPUnit.unitID;
            }  
            let curParUnitID : number = 0;
            curParUnitID = getParentUnitID(unitToCompare, curCUnit.childUnitTreeItems, curCUnit.unitID);
            if (curParUnitID > 0) {
              return curParUnitID;
            }
          }          
        }
      }
    }
    return -1;
  }

  const onDragEnd = (result: any) => {
      if (!!result === false || !!result.destination === false || !!result.source === false || 
          result.destination.index == null || result.destination.index == undefined || result.destination.index < 0 ||
          !!result.source.droppableId === false || !!result.destination.droppableId === false ||
          !!originalUnits === false || originalUnits == null || result.source.droppableId.includes("_1")) {
          return;
      }
      let toUnitId = 0;
      let fromUnitId = 0;
      let toTopLevel = false;
      let trimmedDestUnit = result.destination.droppableId.toString().trim();
      let idx = trimmedDestUnit.indexOf("_1");
      if (idx >= 0) {
        let uTxt = trimmedDestUnit.substring(0, idx);
        toUnitId = getNumberValueOrDefault(uTxt, 0);
      }
      else {
        toUnitId = getParentUnitID(trimmedDestUnit, originalUnits);
      }
      if (originalUnits.length > result.destination.index && originalUnits[result.destination.index] != null && 
              originalUnits[result.destination.index].unitID.toString() == trimmedDestUnit) {
        toTopLevel = true;
        toUnitId = 0;
      }
      else {
        toTopLevel = false;
      }
      fromUnitId = getNumberValueOrDefault(result.source.droppableId.toString().trim(), 0);
      handleDragEndOnUnitTree(fromUnitId, toUnitId, toTopLevel);
  }

  return (
    (parentUnit == null) ?
    <DragDropContext onDragEnd={onDragEnd}>  
    {
      <ul className={styles.treeUl}>
        {units &&
          units.map((unit, index) => (
            <RadioUnitTreeNodeWithDND
                          originalUnits={parentUnit == null ? units : originalUnits}
                          unit={unit}
                          parentUnit={parentUnit}
                          key={unit.unitID <= 0 ? uuidv4() : unit.unitID}
                          updateSelectedUnit={updateSelectedUnit}
                          updateExpandedUnit={updateExpandedUnit}
                          isAnyUnitSelected={isAnyUnitSelected}
                          uniquePrefix="chkUnitNewSalt_"
                          unitUniqueID={unit.unitID}
                          unitIndex={index}
                          handleDragEndOnUnitTreeLocal={handleDragEndOnUnitTree}
                        />
          ))}
      </ul>
    } 
    </DragDropContext>
    :
    <ul className={styles.treeUl}>
      {units &&
        units.map((unit, index) => (
          <RadioUnitTreeNodeWithDND
            originalUnits={parentUnit == null ? units : originalUnits}
            unit={unit}
            parentUnit={parentUnit}
            key={unit.unitID <= 0 ? (parentUnit == null ? index.toString() : parentUnit.unitID.toString() + "_" + "1") : unit.unitID}
            updateSelectedUnit={updateSelectedUnit}
            updateExpandedUnit={updateExpandedUnit}
            isAnyUnitSelected={isAnyUnitSelected}
            uniquePrefix="chkUnitNewSalt_"
            unitUniqueID={unit.unitID}
            unitIndex={index}
            handleDragEndOnUnitTreeLocal={handleDragEndOnUnitTree}
          />
        ))}
    </ul>
  );
};

export default RadioUnitTreeWithDND;
