import axios from 'axios';
import { addError } from '../../AddError';

export const addOrUpdateUnit = async ( 
    type: string,
    token: string,
    userId: number,
    orgId: number,
    orgDomain: string,
    unitID: number,
    name: string,   
    active: boolean
    ) => {

    let payloadData;
    if(type == "add") {
      payloadData = {
        "RequestingUserID" : userId,  
        "OrganisationID" : orgId,
        "ParentUnitID" : unitID,
        "Name" : name,       
        "Active" : active        
     }
    } else {
      payloadData = {
        "RequestingUserID" : userId,  
        "OrganisationID" : orgId,
        "UnitID" : unitID,
        "Name" : name,       
        "Active" : active,
        "UpdatedByUserID" : userId       
     }
    }

    try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/api/unit/AddOrUpdateUnit`,
          headers:{
            'Content-Type': 'application/json',
            'Authorization' : `Bearer ${token}`
          },    
          data: {...payloadData}
        });
        const data = await response.data;
        console.log(data)
        return data;
      } catch (error: any) {
        if (error.response) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.response);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.response, "checkIfSessionIsStillActive.ts", 1)
        } else if (error.request) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.request);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.request, "checkIfSessionIsStillActive.ts", 1)
        } else if (error.message) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.message);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.message, "checkIfSessionIsStillActive.ts", 1)
        }
        return null;
      }

}

export default addOrUpdateUnit;