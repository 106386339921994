import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const AddIcon = () => (    
<svg
    id="Uploaded to svgrepo.com"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="25px"
    height="25px"
    viewBox="0 0 1 1"
    xmlSpace="preserve"
  >
    <style type="text/css">{".afiado_een{fill:#fff;}"}</style>
    <path
      className="afiado_een"
      d="M0.75 0.563h-0.188v0.188h-0.125v-0.188H0.25v-0.125h0.188V0.25h0.125v0.188h0.188v0.125z"
    />
  </svg>
);

export default AddIcon
