import React from 'react';
import { InfopathIntroductionPageType } from '../../globalTypes'

interface Props {
    introPage: InfopathIntroductionPageType
    questionCount: number
}

export default function InfopathIntroduction({introPage, questionCount}: Props): JSX.Element {
    const { textList } = introPage
    return (
        <>
            {
                textList.map((text, i ) => (
                    <p 
                        key={i}>{ 
                            text.replace(/\s+/g, ' ')
                                .replace("%System_NumberOfQuestions%", questionCount.toString())
                                .trim().split('<NLCR/>').filter(function(str) {
                                    if (str === null || str.length <= 0 || str.trimLeft().trimRight().length <= 0) {
                                        return false;
                                    }
                                    return true;
                                  }).map(str => <p>{str}</p> )
                        }</p>
                    )
                )
            }
        </>
    )
}
