import React from 'react'

const SaltLogo = () => {
    return (
        <svg
          id="prefix__Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x={0}
          y={0}
          width={220.9}
          height={111.6}
          viewBox="0 0 220.9 111.6"
          xmlSpace="preserve"
        >
          <style>{".prefix__st0{fill:#58595b}"}</style>
          <path
            className="prefix__st0"
            d="M52 32.7c-2.6-9.7-6.7-20.3-20.1-20.3-11.8 0-17 8.4-17 16.7 0 11.2 7.4 16.6 19.5 22.7s26.5 13.4 26.5 30.7c0 16.1-13.7 29-34.1 29-5.1 0-10.1-.8-15-2.3-2.7-.9-5.2-2-7.7-3.3-1-3.1-3-16.3-4.1-23.6l4.6-1.1c2.3 9.4 9.2 24.8 25.2 24.8 11.7 0 18.4-7.2 18.4-18.4 0-11.3-8.5-16.7-20-22.9-9.8-5.3-25.3-12.8-25.3-30.2 0-14.7 11.8-27.3 31.6-27.3 7.1 0 13.5 1.7 19.3 3.5.6 5.4 1.4 11.5 2.6 21.2l-4.4.8zM115.2 111.1c-2.7-.2-5.3-1.3-7.2-3.1-1.8-1.9-3.1-4.2-3.7-6.8-6.1 4.1-13.5 9.8-18.1 9.8-10.2 0-18.5-8.4-18.4-18.6 0-7.5 4-12.3 12.4-15.2 9.2-3.1 20.4-7.2 23.8-10v-2.6c0-11.2-5.2-17.3-13-17.3-2.6-.1-5.1 1-6.9 2.9-1.8 2.2-3.2 5.5-4.4 10.1-.8 2.5-2.3 3.5-4.4 3.5-2.8 0-6.6-2.9-6.6-6.6 0-2.2 1.8-4 4.8-6.1 4.1-3.1 14.3-8.9 23-10.7 4.6-.1 9 1.4 12.6 4.1 5.4 4.6 6.9 10.7 6.9 18.9v28.4c0 6.9 2.8 9.2 5.4 9.2 2-.1 3.9-.7 5.5-1.7l1.5 4-13.2 7.8zM104 72.4c-3.2 1.7-10.6 4.9-13.8 6.4-6 2.8-9.4 5.7-9.4 11.4-.3 6.2 4.5 11.4 10.7 11.7h.2c4 0 9.4-2.6 12.3-5.5v-24z"
          />
          <path
            className="prefix__st0"
            d="M127.7 109.3v-4c9.7-.9 11.1-1.8 11.1-12V20.7c0-10.4-.9-10.8-10.6-11.7V5.4c7.7-1 15.3-2.8 22.7-5.4v93.3c0 10.1 1.2 11 11 12v4h-34.2zM193 109.1c-2 1.2-4.2 1.8-6.4 2-9.7 0-15-6.1-15-18.3V48.3H161l-.6-1.7 4.3-4.5h6.9v-11l10-10.3 2.1.3v21H201c1.5 1.9 1.1 4.6-.8 6-.1 0-.1.1-.2.1h-16.4v39.3c0 12.4 5.1 14.7 9.1 14.7 3.3-.1 6.5-1 9.4-2.6l1.4 4-10.5 5.5zM221 31.9c0 4.6-3.7 8.2-8.3 8.2-4.5.1-8.2-3.5-8.3-8v-.3c0-4.6 3.8-8.3 8.4-8.3 4.5.2 8.2 3.8 8.2 8.4zm-14.8 0c0 3.6 2.9 6.5 6.5 6.5s6.5-2.9 6.5-6.5c.1-3.6-2.7-6.7-6.4-6.8h-.2c-3.6 0-6.5 2.9-6.5 6.5.1.1.1.2.1.3zm11 4.4c-1.2.2-2.5-.3-3.2-1.3-.5-.8-.9-1.6-1.1-2.1-.2-.3-.5-.5-.8-.4h-.3V34c0 1.2.1 1.2 1.1 1.2v1h-4.3v-1c1.2-.1 1.2-.1 1.2-1.2v-4.4c0-1.1-.1-1.1-1.1-1.2v-1h4.4c1.4 0 3.1.4 3.1 2.3 0 1-.7 1.9-1.7 2.2.6 1.1 1.3 2.1 2.1 3 .3.3.6.5.8.6l-.2.8zm-4.8-4.8c.4 0 .8-.1 1.2-.3.4-.3.7-.8.6-1.4 0-.8-.6-1.4-1.3-1.5h-.2c-.6 0-.8.1-.8.8v2.3h.5z"
          />
        </svg>
      )
}

export default SaltLogo