import React, { useState, useRef, useContext, useEffect } from "react";
import styles from "../../styles/theme.module.css";
import CustomTooltip from '../../Components/CustomTooltip';
import CircularProgress from "@material-ui/core/CircularProgress";
import { TranslationsContext } from "../../contexts/TranslationsContext";

interface Props {
	selectedImage: string;
	uploadImage: Function;
	imageType: string;
	sizeLimitMb: number;
	maxHeight?: number;
}

const ImageDropBox = ({ selectedImage, uploadImage, imageType, sizeLimitMb, maxHeight }: Props) => {
	const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext);
	const inputEl = useRef<HTMLInputElement>(null);
	const [imageIsMounted, setImageIsMounted] = useState<boolean>(false);
	const [dragBoxMessage, setDragBoxMessage] = useState<string>(allResourcesToTranslations.dropanddragfile);
	const [draggedOver, setDraggedOver] = useState<boolean>(false);
	const [uploadingImage, setUploadingImage] = useState<boolean>(false);
	const [dragError, setDragError] = useState<boolean>(false);
	const [thumbnail, setThumbnail] = useState<any>(null);
	const [thumbnailFilename, setThumnailFilename] = useState<string>("");
	const [isUrlValid, setIsUrlValid] = useState<boolean>(true);

	const handleDragOver = (event: any) => {
		event.preventDefault();
		setDraggedOver(true);
	};

	const handleDragEnd = () => {
		setDraggedOver(false);
	};

    const handleClick = (event: any) => {
        inputEl?.current?.click()
    }

	const showError = (errorMessage: string) => {
		setDragError(true);
		setImageIsMounted(false);
		setUploadingImage(false);
		setDragBoxMessage(errorMessage);
		setTimeout(() => {
			setDragError(false);
			setDragBoxMessage(allResourcesToTranslations.dropanddragfile);
			setDraggedOver(false);
		}, 3000);
	};

	const handleFileDrop = (event: any) => {
		event.preventDefault();

	if (event.nativeEvent.dataTransfer !== undefined) {
		if (event.nativeEvent.dataTransfer.files.length) {
			const file = event.nativeEvent.dataTransfer.files[0];
			let splitedFileName = file.name.split(".");
			let fileExtension = splitedFileName[1];
			if (file.type.includes("image/")) {
				if (file.size / 1000000 > sizeLimitMb) return showError(allResourcesToTranslations.filesmustnotexceed.replace("{sizeLimitMb}", sizeLimitMb));
				setUploadingImage(true);
				if (inputEl.current) inputEl.current.files = event.nativeEvent.dataTransfer.files;
				setDragError(false);
				setImageIsMounted(true);
				setThumnailFilename(file.name);
				let base64String;
				const reader = new FileReader()
				reader.onload = () => {
					setThumbnail(reader.result)
					base64String = reader?.result?.toString().replace("data:", "").replace(/^.+,/, "");
					uploadImage(base64String, fileExtension, imageType,).then((res: any) => {
						if (res.success) {
							setUploadingImage(false);
						} else {
							showError(allResourcesToTranslations.therewasanissueuploading);
						}	
					})
				}
				reader.readAsDataURL(file)
			} else {
				showError(allResourcesToTranslations.pleaseselectanimagefile);
			}
		}
	} else {
		if (event.nativeEvent.srcElement.files.length) {
			let file = event.nativeEvent.srcElement.files[0];
			let splitedFileName = file.name.split(".");
			let fileExtension = splitedFileName[1];
			if (file.type.includes("image/")) {
				if (file.size / 1000000 > sizeLimitMb) return showError(allResourcesToTranslations.filesmustnotexceed.replace("{sizeLimitMb}", sizeLimitMb));
				setUploadingImage(true);
				if (inputEl.current) inputEl.current.files = event.nativeEvent.srcElement.files;
				setDragError(false);
				setImageIsMounted(true);
				setThumnailFilename(file.name);
				let base64String;
				const reader = new FileReader()
				reader.onload = () => {
					setThumbnail(reader.result)
					base64String = reader?.result?.toString().replace("data:", "").replace(/^.+,/, "");
					uploadImage(base64String, fileExtension, imageType).then((res: any) => {
						if (res.success) {
							setUploadingImage(false);
						} else {
							showError(allResourcesToTranslations.therewasanissueuploading);
						}	
					})
				}
				reader.readAsDataURL(file)
			} else {
				showError(allResourcesToTranslations.pleaseselectanimagefile);
			}
		}
	}
	};

	useEffect(() => {
		//Checking for default images
		if (
			selectedImage === "https://content.saltcompliance.com/contentfiles/common/salt-logo.svg" ||
			selectedImage === "https://content.saltcompliance.com/contentfiles/common/salt-login-background.jpg"
		) {
			setImageIsMounted(false);
			setDraggedOver(false);
		}
	}, [selectedImage]);

	return (
		<>
		<form className={styles.dropAreaForm}>
		<CustomTooltip displayText={["Drag / Upload a file"] /*TODO: Add translations */}>
			<div
				className={`${styles.dropZone} ${draggedOver ? styles.dropZoneOver : styles.dragZoneExit} ${dragError ? styles.dropZoneError : ""}`}
				onDrop={(e) => handleFileDrop(e)}
				onDragOver={(e) => handleDragOver(e)}
				onDragEnd={handleDragEnd}
				onDragLeave={handleDragEnd}
				onClick={(e) => handleClick(e)}
			>
				{!imageIsMounted ? (
					<p className={styles.dropZonePrompts}>{dragBoxMessage}</p>
				) : (
					<div className={styles.dropZoneThumb} data-label={thumbnailFilename} style={{ backgroundImage: `url('${thumbnail}')` }} />
				)}
			</div>
			</CustomTooltip>
			<input ref={inputEl} onInput={handleFileDrop} className={styles.hiddenInput} name="selected-image" type="file" accept="image/jpg, image/jpeg, image/svg, image/gif, image/png"></input>
			
			<div>
				{ uploadingImage ? (
					<CircularProgress style={{ width: "20px", marginLeft: "20px" }} color="primary" size="10" />
				) : (
					<img
						src={selectedImage}
						style={{ height: maxHeight ? `${maxHeight}px` : "66px", marginLeft: "33px", opacity: isUrlValid ? "1" : "0" }}
						alt={"Uploaded selection"}
						onError={() => {
							setIsUrlValid(false);
						}}
						onLoad={() => {
							setIsUrlValid(true);
						}}
					/>
				)}
			</div>
		</form>
		</>
	);
};

export default ImageDropBox;
