import axios from 'axios';
import { addError } from '../../AddError';

const sendWelcomeEmail = async (token: string, requestingUserID: number, orgDomain: string, orgId:number, userIDToSendEmail: number, 
  userFirstName: string, userLastName: string, userEmailAddress: string, emailSubject: string, emailBody: string) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/administration/SendWelcomeEmail`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        "RequestingUserID": requestingUserID,
        "OrgId": orgId,
        "UserID" : userIDToSendEmail,
        "UserFirstName": userFirstName,
        "UserLastName": userLastName,
        "UserEmailAddress": userEmailAddress,
        "EmailSubject" : emailSubject,
        "EmailBody" : emailBody
    },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, requestingUserID, orgDomain, "Rest Exception", error.response, "SendWelcomeEmail.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, requestingUserID, orgDomain, "Rest Exception", error.request, "SendWelcomeEmail.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, requestingUserID, orgDomain, "Rest Exception", error.message, "SendWelcomeEmail.ts", 1)
    }
    return null;
  }
};

export default sendWelcomeEmail;