import axios from 'axios';
import { addError } from './AddError';

export const GetCourseListByLicenses = async (token: string, userId: number, orgDomain: string, orgId: number) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/userreport/GetCourseLicensing`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
          UserID: userId,
          OrgID: orgId,
      },
    });
    const data = await response.data;
    if (data.isSuccess) {
      for (let i = 0; i < data.courseLicencingItems.length; i++) {
        data.courseLicencingItems[i].isSelected = false;
        for (let j = 0; j < data.courseLicencingItems[i].periods.length; j++) {
          if(j == 0) {
            data.courseLicencingItems[i].selectedPeriodCourseLicenseId = data.courseLicencingItems[i].periods[0].courseLicensingId;
          }
        }
      }
    }
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "GetCourseListByLicenses.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "GetCourseListByLicenses.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "GetCourseListByLicenses.ts", 1)
    }
    return null;
  }
};

export default GetCourseListByLicenses;