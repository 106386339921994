import axios from 'axios';
import { addError } from './AddError';

export const getFullExternalCertificatesReport = async (
    token: string, 
    userId: number,
    orgDomain: string,
    orgId: number,
    unitIds: string,
    externalCertificateIDs:string,
    dateFromOrg:string,
    dateToOrg:string,
    status:number,
    includeArchivedUsers: boolean,
    filterFirstName: string, 
    filterLastName: string,
    filterUserName: string,
    filterEmail: string,
    ) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/userreport/GetFullExternalCertificatesReport`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },

      data: {
        ReportRequestingUserID : userId,
        OrganisationID : orgId,
        UnitIDs : unitIds,
        ExternalCertificateIDs : externalCertificateIDs,
        DateFromOrg : dateFromOrg,
        DateToOrg : dateToOrg,
        Status : status,
        IncludeArchivedUsers : includeArchivedUsers,
        UserFirstName : filterFirstName, //Filter params
        UserLastName : filterLastName, //Filter params
        UserName :  filterUserName, //Filter params
        UserEmail : filterEmail,//Filter params
      }
    });
    const data = await response.data;
    console.log(data);
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      };    
      addError(token, userId, orgDomain, "Rest Exception", error.response, "GetFullExternalCertificatesReport.tsx", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      };    
      addError(token, userId, orgDomain, "Rest Exception", error.request, "GetFullExternalCertificatesReport.tsx", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      };    
      addError(token, userId, orgDomain, "Rest Exception", error.message, "GetFullExternalCertificatesReport.tsx", 1)
    }
    return null;
  }
};

export default getFullExternalCertificatesReport;
