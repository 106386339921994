import React, { useEffect,useContext } from "react";


//MUI Components
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';

//Components
import BrandedCard from '../../Components/BrandedCard';
import LoaderContainer from '../../Components/LoaderContainer';
import CustomTooltip from '../../Components/CustomTooltip';
import UpArrowIcon from '../../CustomIcons/UpArrowIcon';
import DownArrowIcon from '../../CustomIcons/DownArrowIcon';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import SelectAllIcon from '../../CustomIcons/SelectAllIcon';
import UnSelectAllIcon from '../../CustomIcons/UnSelectAllIcon';
import CollapseAllIcon from '../../CustomIcons/CollapseAllIcon';
import CourseFilterButtons from "../CurrentAdminReport/CourseFilterButtons";
import AddedUsers from './AddedUsers';
import AddUserIcon from "../../CustomIcons/AddUserIcon";
import SaveIcon from "../../CustomIcons/SaveIcon";
import { getSchedulerCCList } from '../../API/GetSchedulerCCList';

// Libs
import { useReactOidc } from "@axa-fr/react-oidc-context";

//API
import getUsersList from "../../API/GetUsersList";

//General Functions
import getColor from '../../functions/getColor';
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';

//Styles
import styles from "../../styles/currentAdminReport.module.css";

const UserSection = (props: any) => {
  const { isCCSectionOpen, isCCAddedUserSectionOpen, isCCSearchResultSectionOpen, 
    handleUserSection, handleAddedUserSection, handleUserSearchResultSection, 
    handleUserDetails, userDetails, scheduleId, handleCCSearchClick, isResetClicked } =
    props;
  const [usersList, setUsersList] = React.useState<Array<any>>([]);
  const [addedUsers, setAddedUsers] = React.useState<Array<any>>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true)
  const [page, setPage] = React.useState<number>(0);
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";
  const { theme: { color }} = useContext(CorperateThemeContext);
  const [firstNameDescending, setFirstNameDescending] = React.useState<boolean>(false)
  const [lastNameDescending, setLastNameDescending] = React.useState<boolean>(false)
  const [emailDescending, setEmailDescending] = React.useState<boolean>(false)
  const [currentSortBy, setCurrentSortBy] = React.useState<string>("")
  const [reverseNextSort, setReverseNextSort] = React.useState<boolean>(false)
  const [sortInProgress, setSortInProgress] = React.useState<boolean>(false)

  useEffect(() => {
    if (userId > 0 && scheduleId > 0) {
      getSchedulerCCList(oidcUser.access_token,
        userId,orgDomain,scheduleId).then((result) => {
        
          if (result !== null && result !== undefined && result.isSuccess && 
              result.listOfSchedulerCCs !== null && result.listOfSchedulerCCs !== undefined) {
            let savedUsersList = result.listOfSchedulerCCs.map((data:any) => {
              return {
                ...data,
                isChecked: false
              }
            })
            setAddedUsers([...addedUsers,...savedUsersList])
          }
        })
    }
  },[scheduleId])
  
  const handleSearch = () => {
    if (userDetails === null || userDetails === undefined ||
      ((userDetails.lastName === null || userDetails.lastName === undefined || userDetails.lastName.trimLeft().trimRight() === "")) &&
      (userDetails.firstName === null || userDetails.firstName === undefined || userDetails.firstName.trimLeft().trimRight() === "")) {
        setUsersList([]);
    }
    getUsersList(oidcUser.access_token, userId, orgDomain, userDetails.firstName, userDetails.lastName, parseInt(orgId)).then(
      (response) => {
        if (response !== null && response !== undefined && response.isSuccess && 
          response.listOfUsers !== null && response.listOfUsers !== undefined) {
          let updatedUsersList = response.listOfUsers.map((item:any) => {
            return {
              ...item,
              isChecked:false
            }
          })
          setUsersList(updatedUsersList);
          handleCCSearchClick();
        }
      }
    );
  };

  useEffect(() => {
    props.handleAddedUsers(addedUsers)
  },[addedUsers])

  useEffect(() => {
    setUsersList([]);
    setAddedUsers([]);
  },[isResetClicked])

  const handleAddUsers = () => {

    let filterUsers = usersList.filter((user:any) => user.isChecked)
    let updatedUsers = usersList.filter((item:any) => !item.isChecked)

    let updateFilteredUsers = filterUsers.map((data:any) => ({
      ...data,
      isChecked:false
    }))
    setUsersList([...updatedUsers]);
    setAddedUsers([...addedUsers,...updateFilteredUsers.filter((user:any) => !!user && !!user.userID &&
       (!(!!addedUsers) || addedUsers.length <= 0 || (!!addedUsers && addedUsers.length > 0 && addedUsers.filter((user2:any) => !!user2 && !!user2.userID && user2.userID === user.userID).length <= 0)))]);

  };


  const handleSelectedUsers = (e:any,data:any) => {
    e.stopPropagation();

    let updatedData = usersList.map((item:any) => {
      if(data.userID == item.userID) {
        return {
          ...item,
          isChecked: !item.isChecked
        };
      }
      return item;
    })

    setUsersList(updatedData);
  }

  const handleRemoveUsers = (data:any) => {
    let updatedList = usersList;

    if(updatedList.length > 0) {
    setUsersList([...updatedList, ...data]);
    }
    let updatedAddedUsers = [...addedUsers]
    data.map((item:any) => {
      updatedAddedUsers = updatedAddedUsers.filter((value:any) => value.userID != item.userID);
     
    })

    setAddedUsers([...updatedAddedUsers])
  }

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
        return { backgroundColor: index % 2 == 0 ? "#eee" : ""}
    } else {
        return {}
    }
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortResultsByAsync = async (sortBy: "FirstName" | "LastName" | "Email", sortGivenItemInDesc: boolean ) => {
    if (usersList == null || usersList.length <= 0) {
        return usersList;
    }
    var result = usersList.slice(0)
    setCurrentSortBy(sortBy)
    switch(sortBy) {
        
      case "Email":
          if (sortGivenItemInDesc) {
              result.sort((a, b) => {
                  if (b === null || b.email === null) { return -1; }
                  if (a === null || a.email === null) { return 1; }
                  if(a.email.toLowerCase() > b.email.toLowerCase()) { return -1; }
                  if(a.email.toLowerCase() < b.email.toLowerCase()) { return 1; }
                  return 0;
              })
              if (reverseNextSort) {
                  setEmailDescending(false)
              }
          } else {
              result.sort((a, b) => {
                  if (b === null || b.email === null) { return 1; }
                  if (a === null || a.email === null) { return -1; }
                  if(a.email.toLowerCase() < b.email.toLowerCase()) { return -1; }
                  if(a.email.toLowerCase() > b.email.toLowerCase()) { return 1; }
                  return 0;
              })
              if (reverseNextSort) {
                  setEmailDescending(true) 
              }
          }
          setUsersList(result);
          break;
      case "FirstName":
          if (sortGivenItemInDesc) {
              result.sort((a, b) => {
                  if (b === null || b.firstName === null) { return -1; }
                  if (a === null || a.firstName === null) { return 1; }
                  if(a.firstName.toLowerCase() > b.firstName.toLowerCase()) { return -1; }
                  if(a.firstName.toLowerCase() < b.firstName.toLowerCase()) { return 1; }
                  return 0;
              })
              if (reverseNextSort) {
                  setFirstNameDescending(false)
              }
          } else {
              result.sort((a, b) => {
                  if (b === null || b.firstName === null) { return 1; }
                  if (a === null || a.firstName === null) { return -1; }
                  if(a.firstName.toLowerCase() < b.firstName.toLowerCase()) { return -1; }
                  if(a.firstName.toLowerCase() > b.firstName.toLowerCase()) { return 1; }
                  return 0;
              })
              if (reverseNextSort) {
                  setFirstNameDescending(true)
              }
          }
          setUsersList(result);
          break;
      case "LastName":
          if (sortGivenItemInDesc) {
              result.sort((a, b) => {
                  if (b === null || b.lastName === null) { return -1; }
                  if (a === null || a.lastName === null) { return 1; }
                  if(a.lastName.toLowerCase() > b.lastName.toLowerCase()) { return -1; }
                  if(a.lastName.toLowerCase() < b.lastName.toLowerCase()) { return 1; }
                  return 0;
              })
              if (reverseNextSort) {
                  setLastNameDescending(false)
              }
          } else {
              result.sort((a, b) => {
                  if (b === null || b.lastName === null) { return 1; }
                  if (a === null || a.lastName === null) { return -1; }
                  if(a.lastName.toLowerCase() < b.lastName.toLowerCase()) { return -1; }
                  if(a.lastName.toLowerCase() > b.lastName.toLowerCase()) { return 1; }
                  return 0;
              })
              if (reverseNextSort) {
                  setLastNameDescending(true)
              }
          }
          setUsersList(result);
          break;
      
      
      default:
          break;
    }
    return result.slice(0);
  }

  const sortResultsBy = async (sortBy: "FirstName" | "LastName" | "Email" , sortGivenItemInDesc: boolean ) => {
    setSortInProgress(true);
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
        setCurrentSortBy(sortBy)
        setUsersList(result)
        setPage(0)
        setSortInProgress(false);
    }); 
  }

  const clickFirstNameAsc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(false);        
    sortResultsBy("FirstName", false);
  }

  const clickFirstNameDesc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(true)
    sortResultsBy("FirstName", true)
  }

  const clickLastNameAsc = () => {
    setReverseNextSort(false);
    setLastNameDescending(false);        
    sortResultsBy("LastName", false);
  }

  const clickLastNameDesc = () => {
    setReverseNextSort(false);
    setLastNameDescending(true)
    sortResultsBy("LastName", true)
  }

  const clickEmailAsc = () => {
    setReverseNextSort(false);
    setEmailDescending(false);
    sortResultsBy("Email", false);
  }

  const clickEmailDesc = () => {
    setReverseNextSort(false);
    setEmailDescending(true)
    sortResultsBy("Email", true)
  }

  const handleSelectAll = () => {
    let udpatedList = usersList.map((user:any) => (
      {
        ...user,
        isChecked: true
      }
    ))
    setUsersList(udpatedList);
  }

  const handleDeselect = () => {
    let udpatedList = usersList.map((user:any) => (
      {
        ...user,
        isChecked: false
      }
    ))
    setUsersList(udpatedList);
  }

  return (
    <>
      <FormControl
        component="fieldset"
        style={{ margin: "10px", width:'100%'}}
      >
        <FormLabel component="legend" style={{marginBottom:'5px'}}> CC Accounts</FormLabel>

        <div
          style={{
            backgroundColor: "#eee",
            borderRadius: "5px",
            width: "99%",
          }}
        >
          <div className={styles.expandContainer} onClick={handleUserSection}>
            <b>CC List Actions</b>
            <div
              className={
                !isCCSectionOpen
                  ? styles.sortArrowAccending
                  : styles.sortArrowDescending
              }
            >
              <KeyboardArrowDownIcon />
            </div>
          </div>

          {isCCSectionOpen && (
            <>
              <div>
                <div className={styles.expandContainer}>
                  <div className={styles.listField} style={{marginLeft:'30px'}}>
                    <TextField
                      className={styles.input}
                      label={"First Name"}
                      name="firstName"
                      variant="outlined"
                      autoComplete="off"
                      InputLabelProps={{ shrink: true }}
                      value={userDetails.firstName}
                      onChange={handleUserDetails}
                      style={{marginLeft: "-30px"}}
                    />
                  </div>

                  <div className={styles.listField}>
                    <TextField
                      className={styles.input}
                      label={"Last Name"}
                      name="lastName"
                      variant="outlined"
                      autoComplete="off"
                      InputLabelProps={{ shrink: true }}
                      value={userDetails.lastName}
                      onChange={handleUserDetails}
                    />
                  </div>
                </div>

                <div className={styles.lowerButtonPanel} style={{marginTop:'10px', marginRight:'4px'}}>
                  <CustomTooltip displayText={["Search"]}>
                    <Button
                      onClick={handleSearch}
                      className={styles.moduleListButton}
                      variant="contained"
                      color="primary"
                    >
                      <SearchIcon />
                    </Button>
                  </CustomTooltip>
                </div>
              </div>

              <div style={{ margin: "0px 5px" }}>
                {addedUsers.length > 0 && (
                  <AddedUsers
                    isCCAddedUserSectionOpen={isCCAddedUserSectionOpen}
                    handleAddedUserSection={handleAddedUserSection}
                    users={addedUsers}
                    handleRemoveUsers={handleRemoveUsers}
                  />
                )}
              </div>
            </>
          )}
        </div>

        <div
          style={{
            backgroundColor: "#eee",
            marginTop:'10px',
            borderRadius: "5px",
            width: "99%",
          }} >
        <div className={styles.expandContainer} onClick={handleUserSearchResultSection}>
            <b>CC Accounts Search Result</b>
            <div
              className={
                !isCCSearchResultSectionOpen
                  ? styles.sortArrowAccending
                  : styles.sortArrowDescending
              }
            >
              <KeyboardArrowDownIcon />
            </div>
        </div>

        {isCCSearchResultSectionOpen && usersList != null && usersList.length > 0 && (
          <div style={{ marginLeft: "5px" }}>
            <div className={styles.paginationControlsContainer}>
              <ButtonGroup
                variant="contained"
                color="primary"
                aria-label="Filter"
                style={{ height: "35px" }}
              >
                <Button
                  className={styles.button}
                  size="small"
                  onClick={handleSelectAll}
                >
                  <SelectAllIcon />
                  <span style={{ marginLeft: "5px", fontSize: "12px" }}>{
                    ` Select All` /*TODO Add translation */
                  }</span>
                </Button>
                <Button
                  className={styles.button}
                  size="small"
                  onClick={handleDeselect}
                >
                  <CollapseAllIcon />
                  <span style={{ marginLeft: "5px", fontSize: "12px" }}>{
                    ` Unselect All` /*TODO Add translation */
                  }</span>
                </Button>
              </ButtonGroup>
              <div  style={{display:'flex', justifyContent:'center', width:'65%'}}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 100]}
                component="div"
                count={usersList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
              </div>
            </div>

            <div className={styles.tableSection}>
              <div className={styles.tableContainer}>
                <BrandedCard>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              color: getColor(color.substring(1)),
                              backgroundColor: color,
                              borderRadius: "8px 0 0 0",
                            }}
                            align="left"
                          >
                            <div className={styles.tableHeadCellContainer}>
                              <div>{"Select"}</div>
                            </div>
                          </TableCell>

                          <TableCell
                            style={{
                              color: getColor(color.substring(1)),
                              backgroundColor: color,
                            }}
                            align="left"
                          >
                            <div className={styles.tableHeadCellContainer}>
                              <div
                                onClick={() => {
                                  setLastNameDescending(!lastNameDescending);
                                  sortResultsBy("LastName", !lastNameDescending);
                                }}
                              >
                                {"Last Name" /* TODO translate */}
                              </div>
                              <div className={styles.columnHeadArea}>
                                <span
                                  style={{
                                    opacity:
                                      currentSortBy === "LastName" &&
                                      lastNameDescending === false
                                        ? "100%"
                                        : "50%",
                                    margin: "2px",
                                    height: "6px",
                                    display: "inline-flex",
                                  }}
                                  onClick={clickLastNameAsc}
                                >
                                  <UpArrowIcon />{" "}
                                </span>
                                <span
                                  style={{
                                    opacity:
                                      currentSortBy === "LastName" &&
                                      lastNameDescending === true
                                        ? "100%"
                                        : "50%",
                                    margin: "0px",
                                    height: "6px",
                                    display: "inline-flex",
                                  }}
                                  onClick={clickLastNameDesc}
                                >
                                  <DownArrowIcon />{" "}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            style={{
                              color: getColor(color.substring(1)),
                              backgroundColor: color,
                            }}
                            align="left"
                          >
                            <div className={styles.tableHeadCellContainer}>
                              <div
                                onClick={() => {
                                  setFirstNameDescending(!firstNameDescending);
                                  sortResultsBy(
                                    "FirstName",
                                    !firstNameDescending
                                  );
                                }}
                              >
                                {"First Name" /* TODO translate */}
                              </div>
                              <div className={styles.columnHeadArea}>
                                <span
                                  style={{
                                    opacity:
                                      currentSortBy === "FirstName" &&
                                      firstNameDescending === false
                                        ? "100%"
                                        : "50%",
                                    margin: "2px",
                                    height: "6px",
                                    display: "inline-flex",
                                  }}
                                  onClick={clickFirstNameAsc}
                                >
                                  <UpArrowIcon />{" "}
                                </span>
                                <span
                                  style={{
                                    opacity:
                                      currentSortBy === "FirstName" &&
                                      firstNameDescending === true
                                        ? "100%"
                                        : "50%",
                                    margin: "0px",
                                    height: "6px",
                                    display: "inline-flex",
                                  }}
                                  onClick={clickFirstNameDesc}
                                >
                                  <DownArrowIcon />{" "}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            style={{
                              color: getColor(color.substring(1)),
                              backgroundColor: color,
                              borderRadius: "0 8px 0 0",
                            }}
                            align="left"
                          >
                            <div className={styles.tableHeadCellContainer}>
                              <div
                                onClick={() => {
                                  setEmailDescending(!emailDescending);
                                  sortResultsBy("Email", !emailDescending);
                                }}
                              >
                                {"Email" /* TODO translate */}
                              </div>
                              <div className={styles.columnHeadArea}>
                                <span
                                  style={{
                                    opacity:
                                      currentSortBy === "Email" &&
                                      emailDescending === false
                                        ? "100%"
                                        : "50%",
                                    margin: "2px",
                                    height: "6px",
                                    display: "inline-flex",
                                  }}
                                  onClick={clickEmailAsc}
                                >
                                  <UpArrowIcon />{" "}
                                </span>
                                <span
                                  style={{
                                    opacity:
                                      currentSortBy === "Email" &&
                                      emailDescending === true
                                        ? "100%"
                                        : "50%",
                                    margin: "0px",
                                    height: "6px",
                                    display: "inline-flex",
                                  }}
                                  onClick={clickEmailDesc}
                                >
                                  <DownArrowIcon />{" "}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {usersList
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((result: any, i) => {
                            return (
                              <TableRow
                                key={`reportScheduler-${i}`}
                                style={rowBackgroundColor(i)}
                              >
                                <TableCell align="left">
                                  <Checkbox
                                    size="small"
                                    color="primary"
                                    className={styles.checkBox}
                                    name="selectedUser"
                                    key={result.userID}
                                    checked={result.isChecked}
                                    onChange={(e) =>
                                      handleSelectedUsers(e, result)
                                    }
                                  />
                                </TableCell>

                                <TableCell align="left">
                                  {result.lastName}
                                </TableCell>
                                <TableCell align="left">
                                  {result.firstName}
                                </TableCell>
                                <TableCell align="left">{result.email}</TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </BrandedCard>

                <div className={styles.paginationAddedUsersControl}>
                  <div  style={{display:'flex', justifyContent:'center', width:'70%'}}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={usersList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  </div>
                  <CustomTooltip displayText={["Add Account(s)"]}>
                    <span style={{marginRight:'7px'}}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="save"
                        onClick={handleAddUsers}
                      >
                        <AddUserIcon />
                        <span style={{ marginLeft: "10px" }}>
                          <SaveIcon />
                        </span>
                      </Button>
                    </span>
                  </CustomTooltip>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      </FormControl>

      
    </>
  );
};

export default UserSection;