import React from 'react'
import generateGradientCss from '../../functions/generateGradientCss.js'

interface Props {
  percentage: number
}

export const InfopathProgressBar = ({percentage}: Props): JSX.Element => {
    const backgroundCSS = generateGradientCss("#1DBA89");

    const style = {
        container: {
            width: "100%",
            backgroundColor: "#dcdcdc"
        }
    }

    return (
      <div style={style.container}>
        <div
          style={{
            backgroundImage: backgroundCSS,
            width: percentage + "%",
            height: "10px",
            transition: "width ease-in 200ms"
          }}
        ></div>
      </div>
    );
}

export default InfopathProgressBar
