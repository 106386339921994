import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
require("highcharts/modules/exporting")(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

export const SummaryReport_StakedColumn_ChartComponent = ({ categoriesArray, incomplete, complete, incompletePercentage, completePercentage, ChartTitle, yAxisPrimaryTitle, yAxisSecondaryTitle,themecolor,seriesIncompleteName , seriesCompleteName }) => {

  Highcharts.setOptions({
    //colors: [themecolor, '#21394f', '#63c2ff','#f7a35c'],
    colors: ['#20B786', '#21394f', '#63c2ff','#f7a35c'],
    font:['Open Sans sans-serif']
  }); 

  const stakedColumnChart = {
    chart: {
      zoomType: 'xy'
    },   
     title: {
       text: ChartTitle,
       align: 'center',
       enabled: false
     },
    credits: {
      //text: 'Example.com',
      //href: 'http://www.example.com',
      enabled: false
    },     
    xAxis: [{
      categories: categoriesArray,
      crosshair: true
    }],
    yAxis: [{ // Primary yAxis  leftside Axis
      min: 0, 
      title: {
        text: yAxisPrimaryTitle,
        style: {
          color: Highcharts.getOptions().colors[1]
        }
      },
      labels: {              
        format: '{value}%',
        style: {
          color: Highcharts.getOptions().colors[1]
        }
      }          
    }, { // Secondary yAxis rightside Axis
      min: 0, 
      title: {
        text: yAxisSecondaryTitle,
        style: {
          color: Highcharts.getOptions().colors[1]
        }
      },
      labels: {
        format: '{value}',
        style: {
          color: Highcharts.getOptions().colors[1]
        }
      }
      ,opposite: true 
    }],
    tooltip: {
       valueSuffix: ' Users'
    },      
    legend: {
      align: 'center',
      x:0,
      verticalAlign: 'top',
      y: 0,
      floating: true,
      backgroundColor:
      Highcharts.defaultOptions.legend.backgroundColor || 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: true,
      font: Highcharts.defaultOptions.font
    },  
    plotOptions: {    
      column: {
        stacking: 'percent',        
        dataLabels: {
          enabled: true,           
          color:'white',  
          fontWeight: 'bold',      
          format: '<b>{point.percentage:.0f}%'
        }
      }
    },
    series: [
    {    
      maxPointWidth: 70,
      name: seriesCompleteName +' (%)',
      type: 'column',     
      text: yAxisPrimaryTitle,
      data: completePercentage,      
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.percentage:.0f}%</b><br/>',
        shared: false,
        fontWeight: 'bold'
     },
    },
    {
      maxPointWidth: 70,
      name: seriesIncompleteName +' (%)',
      type: 'column',     
      text: yAxisPrimaryTitle,
      data: incompletePercentage,      
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.percentage:.0f}%</b><br/>',
        shared: false,
        fontWeight: 'bold'        
     },
    },
    { 
      name: seriesCompleteName,   
      type: 'spline',
      data: complete,
      text: yAxisSecondaryTitle,
      lineWidth: 4,
      yAxis:1,
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
        shared: false,
        fontWeight: 'bold'        
     },
    },
    {    
      name: seriesIncompleteName,
      type: 'spline',
      data: incomplete,
      text: yAxisSecondaryTitle,
      lineWidth: 4,
      yAxis:1,
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
        shared: false,
        fontWeight: 'bold'
     },
    }]
  }; 
 
  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={stakedColumnChart} />
    </>
  )

}

export default SummaryReport_StakedColumn_ChartComponent;
