import React, { useContext } from 'react'
//Libs
import { TranslationsContext } from '../contexts/TranslationsContext';
//Components 
import CustomTooltip from '../Components/CustomTooltip';
import BrandedCard from '../Components/BrandedCardDashBoard';
//MUI Components
import Button from '@material-ui/core/Button';
import PageviewIcon from '@material-ui/icons/Pageview';
// Styles
import styles from '../styles/dashboard.module.css'
import { UIContext } from '../contexts/UIContext';
import TermsAndConditionsModal from './TermsAndConditionsModal';

interface Props {
    handleOpenTermsAndConditions: Function;
}

export const TermsAndConditionsCard = ( ) => {
    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
    const {  showTermsAndConditionsModel, setshowTermsAndConditionsModel} = useContext(UIContext);
 
    const handleOpenTermsAndConditions = () => {
   
        setshowTermsAndConditionsModel(true)
    }
    
    return(	
	<>
    <span><a style={{ cursor: 'pointer' }} onClick={() => handleOpenTermsAndConditions()}>{allResourcesToTranslations.termsofuse}</a></span>
    {showTermsAndConditionsModel? 
     ( <TermsAndConditionsModal showModal={showTermsAndConditionsModel} setShowModal={setshowTermsAndConditionsModel} />
     )
    :( <span></span>)
    }
    </>
    )
}

export default TermsAndConditionsCard;