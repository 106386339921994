import React, { useContext } from "react";
import { LearningModule } from "../../globalTypes";
import { Link } from "react-router-dom";
//Context
import { TranslationsContext } from "../../contexts/TranslationsContext";
//Components
import CPDScore from "./CPDScore";
import CustomTooltip from "../../Components/CustomTooltip";
//MUI Components
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import FindInPageIcon from "@material-ui/icons/FindInPage";
//Styles
import styles from "../../styles/learningModuleList.module.css";

interface Props {
	showCPD: boolean;
	learningModule: LearningModule;
	logNavigation: (message: string) => void;
}

const QuickFactsRow = ({ showCPD, learningModule, logNavigation }: Props) => {
	const {translations: { allResourcesToTranslations }} = useContext(TranslationsContext);
	const { name, doesQFSExist, courseID, qfsPath, id, isCPDReady } = learningModule;
	if (!doesQFSExist) return null;
	return (
		<TableRow>
			<TableCell align="left">
				<span className={styles.rowTitle}>{allResourcesToTranslations.quickfacts}</span>
			</TableCell>
			<TableCell align="center">
				<span></span> {/* This is left here so MUI can auto format the table */}
			</TableCell>
			<CPDScore isLesson={false} showCPD={showCPD} pointsAvailable={0} isQuickFacts={true} type={"qf"} id={id} isCPDReady={isCPDReady} />
			<TableCell align="center">
				<Link
					onClick={() => logNavigation(`Viewing ${name} Quick Facts`)}
					to={{
						pathname: `${courseID}/quickfacts`,
						state: {
							url: qfsPath,
						},
					}}
				>
					<CustomTooltip displayText={[allResourcesToTranslations.viewquickfacts]}>
						<Button className={styles.moduleListButton} variant="contained" color="primary">
							<FindInPageIcon />
						</Button>
					</CustomTooltip>
				</Link>
			</TableCell>
		</TableRow>
	);
};

export default QuickFactsRow;
