export const getCurrentTimeDDMMYYYYFormat = () => {
    const d = new Date();
    let day = d.getDate().toString();
    if (day.length < 2) {
        day = "0" + day;
    }
    let month = (d.getMonth()+1).toString();
    if (month.length < 2) {
        month = "0" + month;
    }
    return `${day}/${month}/${d.getFullYear()} ${d.toLocaleTimeString("en-US", { hour12: false, hour: "numeric", minute: "numeric" , second:"numeric" })}`
}

export default getCurrentTimeDDMMYYYYFormat