//React + Typescript
import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from "react-router-dom";
//Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { UIContext } from '../../contexts/UIContext';
//API functions
import { addError } from '../../API/AddError'
//Components
import PDFPlayer from '../../Players/PDFPlayer/PDFPlayer'
import LoaderContainer from '../../Components/LoaderContainer';
//MUI Components
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
//Styles
import styles from "../../styles/pdfplayer.module.css";

export const HelpViewerWrapper = () => {
  const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const { oidcUser } = useReactOidc();
  const { EnableActivityLogs} = useContext(UIContext);
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0;
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";

  const handleExit = () => {
    addError(oidcUser.access_token, userId, orgDomain, "Exited help viewer", null, "HelpViewerWrapper.tsx", 6, EnableActivityLogs)
    history.goBack();
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return <LoaderContainer />;
  } 
  return (
    <>
      <PDFPlayer url="help.pdf" />
      <div className={styles.controls}>
        <Button
          id="exitButton"
          onClick={() => handleExit()}
          variant="contained"
          color="primary"
        >
          <ExitToAppIcon />
          { allResourcesToTranslations.exit }
        </Button>
      </div>
    </>
  );
};

export default HelpViewerWrapper;