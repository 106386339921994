import { CloseOutlined } from '@material-ui/icons';
import axios from 'axios';
import { addError } from './AddError';

export const sendOrGetFullSentEmailReport = async (
  token: string, 
  userId: number,
  orgDomain: string,
  orgId: number,    
  datefrom: string,   
  dateto: string,
  toEmail: string, 
  subject: string,
  body: string,
  emailid:number,
  inputType: string 
    ) => {
      

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/userreport/SendOrGetFullSentEmailReport`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },     
      data: {        
        ReportRequestingUserID : userId,
        OrganisationID : orgId,     
        DateFrom : datefrom,
        DateTo : dateto,//Filter params
        ToEmail:  toEmail,//Filter params
        //adminUserID: 1,//Always 1 ( Salt admin ) // Not used
        Subject:subject,//Filter params,
        Body: body, //Filter params
        EmailId:emailid,
        InputType: inputType,

      },
    });
    const data = await response.data;  
    //console.log(data);
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      };    
      addError(token, userId, orgDomain, "Rest Exception", error.response, "SendOrGetFullSentEmailReport.tsx", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      };    
      addError(token, userId, orgDomain, "Rest Exception", error.request, "SendOrGetFullSentEmailReport.tsx", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      };    
      addError(token, userId, orgDomain, "Rest Exception", error.message, "SendOrGetFullSentEmailReport.tsx", 1)
    }
    return null;
  }
};

export default sendOrGetFullSentEmailReport;
