//React + Typescript
import React, { useContext, useEffect, useState, useRef } from 'react';

// Libsimport
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../../contexts/UIContext';
import { LoaderContext } from '../../../contexts/LoaderContext';
import { TranslationsContext } from '../../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';
//Intro Lib
import { Steps, Hints } from "intro.js-react";
//API Functions 
import { addError } from '../../../API/AddError'
import { GetOrgLinks } from '../../../API/Administration/Organisation/GetOrgLinks';
import CloseWhiteIcon from "@material-ui/icons/Close";
import Modal from '../../../Components/Modal';
import ProceedIcon from "../../../CustomIcons/ProceedIcon";
import styles from "../../../styles/organisationmanagement.module.css";
import responsivestyles from "../../../styles/responsiveGridLayout.module.css";


//General Functions
import getColor from '../../../functions/getColor';
import validateEmail from '../../../functions/valudateEmail';
//Components
import CustomTooltip from '../../../Components/CustomTooltip';
import UnbrandedCard from '../../../Components/UnbrandedCard';
import LoaderContainer from '../../../Components/LoaderContainer';
import GuideIcon from '../../../CustomIcons/GuideIcon';
//MUI Components
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';

import CachedIcon from '@material-ui/icons/Cached';
import EditIcon from '../../../CustomIcons/SupportDetailsEditIcon';
import ReverttIcon from '../../../CustomIcons/SupportDetailsReverttIcon';
import SaveIcon from '../../../CustomIcons/SupportDetailSaveIcon';
import CancelIcon from '../../../CustomIcons/SupportDetailCancelIcon';

import moment from 'moment';
import BrandedCard from '../../../Components/BrandedCard';
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';
import { OrgLinks } from '../../../globalTypes';
import { Checkbox, CircularProgress, TableContainer, TablePagination } from '@material-ui/core';
import DeleteIcon from '../../../CustomIcons/DeleteIcon';
import SaveOrgLinks from '../../../API/Administration/Organisation/SaveOrgLinks';
import UpArrowIcon from '../../../CustomIcons/Administration/UpArrowIcon';
import DownArrowIcon from '../../../CustomIcons/Administration/DownArrowIcon';

const ModifyLinks = (props: any): JSX.Element => {
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext)
  const { color } = useContext(CorperateThemeContext).theme;
  const { setMessage } = useContext(LoaderContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection} = useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { oidcUser } = useReactOidc();
  const [orgLinks, setorgLinks] = useState<Array<OrgLinks>>([]);
  const [showConfirmModalDelete, setshowConfirmModalDelete] = useState<boolean>(false);

  const [caption, setCaption] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [linkID, setlinkID] = useState<number>(0);
  const [linkOrder, setlinkOrder] = useState<number>(999);
  const [showDisclaimer, setshowDisclaimer] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);


  const [captionadd, setCaptionadd] = useState<string>("");
  const [urladd, setUrladd] = useState<string>("");
  const [showDisclaimeradd, setshowDisclaimeradd] = useState<boolean>(false);

  const [disclaimermessage, setdisclaimermessage] = useState<string>("");


  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);

  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  const language = useRef<string>("en-AU");
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".Captionadd",
        title: "Caption",
        intro: "Enter a caption displayed with the Link."
      },

    ]
  })

  useEffect(() => {
    let updateSteps = [...intro.steps]
    updateSteps.push(
      {
        element: ".Urladd",
        title: "Url",
        intro: "Enter a URL for the Link."
      },
      {
        element: ".Disclaimeradd",
        title: "Disclaimer",
        intro: "Check to enable a disclaimer when the Link is clicked, the disclaimer is:  DISCLAIMER  The site you are about to visit is not part of this Salt compliance training program.  GRC Solutions has no control over the content, goods and services offered on or through that site. This link to that site does not indicate endorsement, approval or recommendation of that site or the operators of that site by GRC Solutions."
     
      },
      {
        element: ".Save",
        title: "Save Link",
        intro: "Click to save the new Link.",
      },
      {
        element: ".Caption",
        title: "Caption",
        intro: "Column displays all Link captions."
      },
      {
        element: ".Url",
        title: "Url",
        intro: "Column displays all Link URLs."
      },
      {
        element: ".Disclaimer",
        title: "Disclaimer",
        intro: "Column displays whether the disclaimer is enabled for Links or not.",
      },
      {
        element: ".Edit",
        title: "Modify",
        intro: "Click the modify button for the relevant Link to modify the details of the Link.",
      },
      {
        element: ".Delete",
        title: "Delete",
        intro: "Click the delete button for the relevant Link to remove the Link.",
      },
      {
        element: ".Sort",
        title: "Sort",
        intro: "Click the arrows next to the relevant Link to modify the display order of the Link.",
      }
    )
    setIntro({ ...intro, steps: [...updateSteps] })

  }, [])

  const loadOrgLinks = () => {
    GetOrgLinks(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((result: any) => {
      if (result.isSuccess && result.error == '') {
        if (result.orgLinks !== null) {
          setorgLinks([...result.orgLinks]);
          setdisclaimermessage(result.orgAppConfigItems[0].disclaimer)
        }
      }
      else {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage("Can not load Organisation links!");
      }
      setLoading(false);
    });
  };
  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };
  useEffect(() => {
    setTitle(allResourcesToTranslations.modifylinks)
    setTitleEnglish(pageTitles.modifylinks);
    updateBackButtonRoute("");
    setMenuSelection(allResourcesToTranslations.modifylinks);
    setLoading(true);
    loadOrgLinks();
    return () => { };
  }, []);
  const handleClosePopup = () => {
    setShowWarningModal(false);
  };
  useEffect(() => {
    if (showWarningModal) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      }, 5000)
    }
  }, [showWarningModal])

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "#fff" }
    } else {
      return {}
    }
  }
  const handleEdit = (name: any,url:string,index: number,disclaimer: boolean) => {
    setlinkID(index);
    setCaption(name);
    setUrl(url);
    setshowDisclaimer(disclaimer);    
    let updatedSelectedLink = orgLinks.map((item: OrgLinks) => {
      if (!!item) {
        handleCancel(item.caption);
      }
      if (item.caption === name) {        
        item.isBeingEdit = true;
      }
      return item;
    });
    setorgLinks([...updatedSelectedLink]);
  };
  const handleCancel = (name: any) => {
    const updatedSelectedLink = orgLinks.map((item: OrgLinks) => {
      if (!!item && item.caption === name) {
        item.isBeingEdit = false;
      }
      return item;
    });
    setorgLinks([...updatedSelectedLink]);
  };
  const handleDelete = (index: number) => {
    handleConfirmModalDelete(true);
    setlinkID(index);
  }
  const handleConfirmModalDelete = (value: boolean) => {
    setshowConfirmModalDelete(value);
  }
  const handleCancelConfirmPopupDelete = () => {
    handleConfirmModalDelete(false);
    setIsSaving(false);
  }
  const handleResetData = () => {
    setCaption('');
    setUrl('');
    setlinkID(0);
    setshowDisclaimer(false);
    setCaptionadd('');
    setUrladd('');  
    setshowDisclaimeradd(false);
  }
  const handleUP = (index: number, linkOrder: number) => {   
    SaveOrgLinks(oidcUser.access_token, userId, orgDomain, parseInt(orgId), index, caption, url, showDisclaimer, linkOrder, "up").then((result) => {
      if (result.isSuccess) {        
        loadOrgLinks();
        handleResetData();
      }
      else {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage("Organisation link  reorder failed!");
      }
    })
  }
  const handleDown = (index: number, linkOrder: number) => {
    SaveOrgLinks(oidcUser.access_token, userId, orgDomain, parseInt(orgId), index, caption, url, showDisclaimer, linkOrder, "down").then((result) => {
      if (result.isSuccess) {        
        loadOrgLinks();
        handleResetData();
      }
      else {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage("Organisation link  reorder failed!");
      }
    })
  }
  const handleProceedDelete = () => {
    handleConfirmModalDelete(false);
    if (linkID > 0) {

      SaveOrgLinks(oidcUser.access_token, userId, orgDomain, parseInt(orgId), linkID, caption, url, showDisclaimer, linkOrder, "delete").then((result) => {
        if (result.isSuccess) {
          setShowWarningModal(true);
          setHasErrored(false);
          setSaveSuccess(true);
          loadOrgLinks();
          setModalPopupMessage("Organisation link has been deleted successfully!");
          handleResetData();
        }
        else {
          setShowWarningModal(true);
          setHasErrored(true);
          setSaveSuccess(false);
          setModalPopupMessage("Organisation link  deleted failed!");
        }
      })
    }
  }
  const handleSave = () => {
    setIsSaving(true);
    let curlinkID = 0;
    //Add New Link  
    if ((linkID == null || linkID == undefined)) {
      curlinkID = 0;
    }
    else {
      //Update Link
      curlinkID = linkID;
    }
    let errorString = '';

    let cururl = curlinkID== 0 ? urladd :url;
       
    const regex = new RegExp('(https://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');    
      
    if(!regex.test(cururl))
    {
      errorString = errorString + 'Please enter valid URL.;'
    } 
    
    if (errorString !== ''){
      setShowWarningModal(true);
      setModalPopupMessage(errorString);
      setHasErrored(true);
      setSaveSuccess(false);
      return;
    }    
    SaveOrgLinks(oidcUser.access_token, userId, orgDomain, parseInt(orgId),curlinkID, (curlinkID == 0 ? captionadd : caption), (curlinkID== 0 ? urladd :url ), (curlinkID== 0 ? showDisclaimeradd : showDisclaimer), linkOrder, curlinkID== 0 ? "add" : "update").then((result) => {
      if (result.isSuccess) {
        setShowWarningModal(true);
        setHasErrored(false);
        setSaveSuccess(true); 
        setIsSaving(false);      
        loadOrgLinks();
        setModalPopupMessage("Organisation link has been saved successfully!");
        handleResetData();
      }
      else {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage("Please select valid Caption to continue!");
      }
    })
  }
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  if (loading) return <LoaderContainer />;
  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }} />
      <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific} style={{ right: '95px', top: '100px' }}>
          <GuideIcon />
        </span>
      </CustomTooltip>

      <Modal modalOpen={showConfirmModalDelete} setModalOpen={handleConfirmModalDelete} type="warning">
        <div className={styles.modalContentWrapper}>
          <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>{allResourcesToTranslations.confirmation}</h2>
          <div className={styles.inputContainer}>
            <p>
              Are you sure you want to delete this link?
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "10px",
            }}
          >
            <CustomTooltip displayText={[allResourcesToTranslations.return]}>
              <span className={styles.returnIcon} onClick={handleCancelConfirmPopupDelete}>
                <CloseWhiteIcon />
              </span>
            </CustomTooltip>
            <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
              <span className="saveData">
                <Button
                  onClick={handleProceedDelete}
                  variant="contained"
                  color="primary"
                  className="save"
                >
                  <ProceedIcon />
                </Button>
              </span>
            </CustomTooltip>
          </div>
        </div>
      </Modal>


      <UnbrandedCard>


        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                align="left">
                <div className={styles.tableHeadCellContainer}>
                  <div>
                    {"Add Link"/* TODO translate */}
                  </div>
                </div>
              </TableCell>
              <TableCell
                style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }}
                align="left">
                <div className={styles.tableHeadCellContainer}>
                  &nbsp;
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={{ backgroundColor: '#fff' }}>
              <TableCell align="left">
                <span>{allResourcesToTranslations.caption}</span>
              </TableCell>
              <TableCell align="left">
                <TextField style={{ width: '100%' }} variant="outlined" className="Captionadd"
                  autoComplete="off" InputLabelProps={{ shrink: true, }}
                  value={captionadd} onChange={(e) => setCaptionadd(e.target.value)} />
              </TableCell>
            </TableRow>
            <TableRow style={{ backgroundColor: '#eee' }}>
              <TableCell align="left">
                <span>{allResourcesToTranslations.url}</span>
              </TableCell>
              <TableCell align="left">
                <TextField style={{ width: '100%' }} variant="outlined" className="Urladd"
                  autoComplete="off" InputLabelProps={{ shrink: true, }}
                  value={urladd} onChange={(e) => setUrladd(e.target.value)} />
              </TableCell>
            </TableRow>
            <TableRow style={{ backgroundColor: '#fff' }}>
              <TableCell align="left" colSpan={2}>
            
            <Checkbox
                  style={{ paddingLeft: '0px' }}
                  size="small"
                  color="primary"
                  checked={showDisclaimeradd}
                  className="Disclaimeradd"
                  onClick={() => setshowDisclaimeradd(!showDisclaimeradd)}
                />{" "}
                <CustomTooltip displayText={[disclaimermessage]}>
                <span className={styles.labelText}>{allResourcesToTranslations.showdisclaimer}</span>
                </CustomTooltip>
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>



      </UnbrandedCard>
      <div>

        {/* Add new Save button */}
        <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px",paddingTop:"20px", width: "100%", clear: "both" }}>
          <CustomTooltip displayText={[allResourcesToTranslations.saveorgmanagement]}>
            <span className="saveData" style={{ marginRight: "-5px" }}>
              <Button
                disabled={captionadd.trim() === "" || urladd.trim() === ""}
                onClick={handleSave}
                variant="contained"
                color="primary"
                className="Save"
              >
                <SaveIcon />
              </Button>
            </span>
          </CustomTooltip>
          <div className={styles.loaderContainer}>
            <CircularProgress
              style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
              color="primary"
              size="10"
            />
          </div>
        </div>

      </div>

      <div className={styles.mainWrapper}>
        <div style={{ width: '100%', clear: "both" }} >
          {orgLinks != null && orgLinks.length > 0 && (
            <div>

              <div className={styles.paginationControlsContainer}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={orgLinks.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
              <div className={`${styles.tableSection}`}>
                <div className={styles.tableContainer}>
                  <BrandedCard>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className="Caption"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                              align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div
                                >
                                  {"Link Caption"/* TODO translate */}
                                </div>

                              </div>
                            </TableCell>
                            <TableCell className="Url"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                              align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div >

                                  {"Url" /* TODO translate */}
                                </div>

                              </div>
                            </TableCell>
                            <TableCell className="Disclaimer"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                              align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div >
                                  {"Show Disclaimer" /* TODO translate */}
                                </div>

                              </div>
                            </TableCell>
                            <TableCell className="Edit"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color, width: "150px" }}
                              align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div>
                                  {"Modify" /* TODO translate */}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell  className="Delete"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color, width: "150px" }}
                              align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div>
                                  {"Delete" /* TODO translate */}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell  className="Sort"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0", paddingRight: "60px" }}
                              align="right">
                              <div className={styles.tableHeadCellContainer}>
                                <div>
                                  {"Sort" /* TODO translate */}
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {orgLinks.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                            .map((result: any, i) => {
                              return (
                                <TableRow  style={rowBackgroundColor(i)}>
                                  <TableCell align="left" width={"15%"}>
                                      {result.isBeingEdit ?                                       
                                      <TextField style={{width: '100%'}} variant="outlined"
                                      autoComplete="off" InputLabelProps={{ shrink: true, }}
                                      defaultValue={result.caption} onChange={(e) => setCaption(e.target.value)} />
                                      :
                                      <span className={result.caption}>{result.caption}</span>
                                      }
                                  </TableCell>
                                  <TableCell align="left" width={"20%"}>
                                  {result.isBeingEdit ?                                       
                                      <TextField style={{ width: '100%' }} variant="outlined"
                                      autoComplete="off" InputLabelProps={{ shrink: true, }}
                                      defaultValue={result.url} onChange={(e) => setUrl(e.target.value)} />
                                      :
                                      <span className={result.url}>{result.url}</span>
                                      }                                    
                                  </TableCell>
                                  <TableCell align="left" className={`usedefault${i}`} width={"15%"}>
                                   
                                  {result.isBeingEdit ?                                       
                                      <Checkbox
                                      size="small"
                                      color="primary"                                     
                                      className={styles.checkBox}
                                      name="selectedshowDisclaimeredit"
                                      checked={showDisclaimer}
                                      onClick={() => setshowDisclaimer(!showDisclaimer)}
                                    />
                                      :
                                      <Checkbox
                                      size="small"
                                      color="primary"
                                      className={styles.checkBox}
                                      name="selectedshowDisclaimer"
                                      checked={result.showDisclaimer}
                                      disabled
                                    />
                                      } 
                                    
                                  </TableCell>

                                  <TableCell align="left" width={"15%"}>
                                    
                                       {result.isBeingEdit ? 
                                            <>
                                                   <CustomTooltip displayText={["Save"]}>
                                                   <Button  disabled={caption.trim() === "" || url.trim() === ""} onClick={() => handleSave()} className={styles.moduleListButton} variant="contained" color="primary" >
                                                     <SaveIcon />
                                                   </Button>
                                                 </CustomTooltip> &nbsp;&nbsp;
                                                 <CustomTooltip displayText={["Cancel"]}>
                                                   <Button onClick={() => handleCancel(result.caption)} className={styles.moduleListButton} variant="contained" color="primary">
                                                     <CancelIcon />
                                                   </Button>
                                                 </CustomTooltip>
                                                 </>
                                                :
                                                <CustomTooltip displayText={["Edit"]}>
                                                  <Button className="ruleedit" onClick={() => handleEdit(result.caption,result.url,result.linkID,result.showDisclaimer)} variant="contained" color="primary">
                                                    <EditIcon />
                                                  </Button>
                                                </CustomTooltip>
                                            }                                   
                                   
                                  </TableCell>
                                  <TableCell align="left" width={"5%"}>
                                    <CustomTooltip displayText={["Delete"]}>
                                      <Button className="ruledelete" onClick={() => handleDelete(result.linkID)} variant="contained" style={{ backgroundColor: "red" }}>
                                        <DeleteIcon />
                                      </Button>
                                    </CustomTooltip>
                                  </TableCell>
                                  <TableCell align="right" width={"10%"} >
                                   {/* <span className={result.linkOrder}>{result.linkOrder}</span> */}
                                    <CustomTooltip displayText={["Up"]}>
                                      <Button onClick={() => handleUP(result.linkID, result.linkOrder)}>
                                        <UpArrowIcon />
                                      </Button>
                                    </CustomTooltip>
                                    <CustomTooltip displayText={["Down"]}>
                                      <Button onClick={() => handleDown(result.linkID, result.linkOrder)}>
                                        <DownArrowIcon />
                                      </Button>
                                    </CustomTooltip>

                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </BrandedCard>
                </div>
              </div>
              <div className={styles.paginationAddedUsersControl}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={orgLinks.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}

      </div>

    </>
  );
};
export default ModifyLinks;
