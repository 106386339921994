import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const warningIcon = () => (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1412_2145)">
    <path d="M8.85783 15.0899C12.5397 15.0899 15.5245 12.1052 15.5245 8.42326C15.5245 4.74136 12.5397 1.75659 8.85783 1.75659C5.17593 1.75659 2.19116 4.74136 2.19116 8.42326C2.19116 12.1052 5.17593 15.0899 8.85783 15.0899Z" fill="#F9A23B" stroke="#F9A23B" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
    <path d="M8.85791 11.0901H8.86458" stroke="white" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
    <path d="M8.85791 5.75659V8.42326" stroke="white" stroke-width="2" stroke-linecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_1412_2145">
    <rect width="16" height="16" fill="white" transform="translate(0.85791 0.42334)"/>
    </clipPath>
    </defs>
    </svg>
    

);

export default warningIcon