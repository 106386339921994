import * as React from "react"

export function NZFlag() {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 28.3 28.3"
      xmlSpace="preserve"
    >
      <style>
        {
          ".prefix__st1{stroke:#fff;stroke-width:.1}.prefix__st1,.prefix__st2{fill:red}"
        }
      </style>
      <path
        d="M1.6 0C.7 0 0 .7 0 1.6v25c0 .9.7 1.6 1.6 1.6h25c.9 0 1.6-.7 1.6-1.6v-25c0-.9-.7-1.6-1.6-1.6h-25z"
        fill="#00008b"
      />
      <path
        className="prefix__st1"
        d="M21.1 3.6l-.9-.1.8-.4-.5-.7.8.3.3-.8.4.7.8-.3-.4.7.7.5-.9.1h-.1v.1l.1.9-.5-.6-.7.6.1-1zM24.5 7.7l-.9-.1.7-.4-.3-.9.8.3.3-.8.3.8.8-.3-.5.7.7.5-.9.1h-.1v.1l.1.9-.3-.6-.6.6-.1-.9zM17.9 8.7l-.9-.1.7-.4-.5-.7.8.3.3-.8.3.8.8-.3-.5.7 1.1.5-.9.1H19v.1l.1.9-.5-.8-.6.6-.1-.9zM21.1 21.6l-.9-.1.7-.4-.5-.7.8.3.3-.8.3.8.8-.3-.2.6.7.5-.9.1h-.1v.1l.1.9-.6-.6-.6.6.1-1z"
      />
      <path
        className="prefix__st2"
        d="M0 2.4v1.2l6 3.5-6 3.5v1.2l7.1-4.1 7.1 4.1v-1.2l-6-3.5 6-3.5V2.4L7.1 6.5 0 2.4z"
      />
      <path
        className="prefix__st2"
        d="M5.9 0v5.9H0v2.4h5.9v5.9h2.5V8.3h5.9V5.9h-6V0H5.9z"
      />
      <path
        d="M5.6 0v5.3L0 2.1v.5l5.1 3H4L0 3.3v.5l3 1.7H0V6h6V0h-.4zm2.5 0v6.1h6v-.5h-3l3-1.7v-.6l-4 2.3H9l5.1-3v-.5L8.5 5.3V0h-.4zm-2 8.1H0v.5h3l-3 1.7v.5l4-2.3h1.2l-5.1 3v.5l5.6-3.2v5.3h.5v-6zm2 0v6.1h.5V8.8l5.6 3.2v-.5L9 8.5h1.2l4 2.3v-.5l-3-1.7h3v-.5H8.1z"
        fill="#fff"
      />
    </svg>
  )
}

export default NZFlag