import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { MyTrainingItem } from '../../globalTypes'
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Components
import CustomTooltip from '../../Components/CustomTooltip';
//MUI Components
import Button from '@material-ui/core/Button';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import styles from '../../styles/courseList.module.css';

interface Props {
    trainingListItem: MyTrainingItem
    logNavigation: (message: string) => void
}

const ViewPolicyButton = ({ trainingListItem, logNavigation }: Props) => {
    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
    const { id, name, policyFileName, policyConfirmationMessage, status } = trainingListItem
    const { oidcUser } = useReactOidc();
    const { profile: { profile }} = oidcUser || {};
    const orgId = profile ? parseInt(profile) : -1;

    return(
        <Link
        onClick={() => logNavigation(`Viewed ${name}`)}
          to={{
            pathname: `mytraining/${id}/policy`,
            state: {
              policyFileName: policyFileName,
              organisationId: orgId,
              confirmationMessage: policyConfirmationMessage,
              status: status,
            },
          }}
        >
          <CustomTooltip displayText={[allResourcesToTranslations.viewpdf]}>
            <Button
              className={styles.courseListButton}
              variant="contained"
              color="primary"
            >
              <PictureAsPdfIcon />
            </Button>
          </CustomTooltip>
        </Link>
    )
}

export default ViewPolicyButton
