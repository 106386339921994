import axios from 'axios';
import addError from '../AddError';

export const GetOrgDeactivationDetails = async (token: string, userId: number, orgDomain:string, orgId: number
  ,selectedOrgID: number = -1, selectedOrgDomainName: string = "", selectOrgSiteName: string = ""
) => {

    try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/SuperAdministration/GetOrgDeactivations`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        orgID: orgId,      
        orgDomainName: orgDomain,
        userId:userId,
        selectedOrgID: selectedOrgID,
        selectedOrgDomainName: selectedOrgDomainName, 
        selectOrgSiteName: selectOrgSiteName
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
        addError(token, userId, orgDomain, "Rest Exception", error.response, "GetOrgDeactivationDetails.ts", 1)
      } else if (error.request) {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          console.log(error.request);
        }; 
        addError(token, userId, orgDomain, "Rest Exception", error.request, "GetOrgDeactivationDetails.ts", 1)
      } else if (error.message) {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          console.log(error.message);
        }; 
        addError(token, userId, orgDomain, "Rest Exception", error.message, "GetOrgDeactivationDetails.ts", 1)
      }
      return null
  }
};

export default GetOrgDeactivationDetails
