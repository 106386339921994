import React from 'react';
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 20,
      borderRadius: 10,
    },
    colorPrimary: {
      backgroundColor: '#C4C4C4',
    },
    barColorPrimary: {
      backgroundColor: '#475A6B',
    }
  }),
)(LinearProgress);

const LinearProgressBarNoLabel = (props: LinearProgressProps & { value: number }) => {

  return (
    <div>
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <BorderLinearProgress variant="determinate" {...props} ></BorderLinearProgress>
      </Box>
    </Box>
    </div>
  );
  
}

export default LinearProgressBarNoLabel;