import React, { useState, useRef, useContext, useEffect } from "react";
import styles from "../../../styles/organisationmanagement.module.css";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { UIContext } from "../../../contexts/UIContext";
import CustomTooltip from "../../../Components/CustomTooltip";
import SaveIcon from "../../../CustomIcons/SaveIcon";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import LoaderContainer from "../../../Components/LoaderContainer";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import SearchIcon from "@material-ui/icons/Search";
import GuideIcon from "../../../CustomIcons/GuideIcon";
import { Steps } from "intro.js-react";
import getOneUnitDetails from "../../../API/Administration/Unit/GetOneUnitDetails";

import getFullUnitAdministratorReport from "../../../API/GetFullUnitAdministratorReport";

import UpArrowIcon from '../../../CustomIcons/UpArrowIcon';
import DownArrowIcon from '../../../CustomIcons/DownArrowIcon';

import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, FormControl, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@material-ui/core";
import UnitAdministratorReportSection from "./UnitAdministratorReportSection";
import getUsersList from "../../../API/GetUsersList";
import saveUnitAdministrator from "../../../API/Administration/Unit/SaveUnitAdministrator";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";
import BrandedCard from "../../../Components/BrandedCard";
import getColor from "../../../functions/getColor";

import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';

const UnitManageUnitAdministrators = (props: any) => {
const { unitIdToEdit } = props;
const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext);
const { setTitle, setTitleEnglish, updateBackButtonRoute } = useContext(UIContext);
const { theme: { color } } = useContext(CorperateThemeContext);

const { oidcUser } = useReactOidc();
const {
profile: { user_id, website,  },
} = oidcUser || {};
const userId = user_id ? parseInt(user_id) : 0;
const orgId = oidcUser.profile.organisation_id ?? -1;
const orgDomain = website
? website
.toLowerCase()
.replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
: "";

const isMounted = useRef<boolean>(false);
const [loading, setLoading] = useState<boolean>(true);



const [unitActive, setUnittActive] = useState<boolean>(false);   
const [pathway, setPathway] = useState("");   
const [expandUnitAdministratorList, setExpandUnitAdministratorList] = useState<boolean>(true); 
const [unitAdministratorListOpen, setUnitAdministratorListOpen] = useState<boolean>(false);
const [unitAdministratorList, setunitAdministratorList] = React.useState<Array<any>>([]);

 
const [assignSubunit, setAssignSubunit] = useState<boolean>(false);
const [assignReportOnlyUnitAdmin, setAssignReportOnlyUnitAdmin] = useState<boolean>(false);
//user section
const [usersList, setUsersList] = React.useState<Array<any>>([]);
const [firstNameDescending, setFirstNameDescending] = React.useState<boolean>(false)
  const [lastNameDescending, setLastNameDescending] = React.useState<boolean>(false)
  const [emailDescending, setEmailDescending] = React.useState<boolean>(false)
  const [currentSortBy, setCurrentSortBy] = React.useState<string>("")
  const [reverseNextSort, setReverseNextSort] = React.useState<boolean>(false)
  const [sortInProgress, setSortInProgress] = React.useState<boolean>(false)
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true)
  const [page, setPage] = React.useState<number>(0);
  
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false); 
//user section





const [userDetails, setUserDetails] = React.useState({
    firstName:'',
    lastName:''
})

const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element:  ".firstName",
        title: "Filter", intro: "Search account by typing firstname."
    }
    ]
});

const setGuideMe = (clearOldList: boolean = false) => {
let updateSteps = clearOldList ? [] : [...intro.steps]         
    updateSteps.push(
      {
        element:  ".firstName",
        title: "Filter", intro: "Search account by typing firstname."
    } ,
    {
        element:  ".lastName",
        title: "Filter", intro: "Search account by typing lastname."
    }, 
    {
      element:  ".search",
      title: "Filter", intro: "Click to search accounts."
    });

    if(usersList.length > 0 )
    {
      updateSteps.push(      
      {
        element:  ".accountlist",
        title: "Filter", intro: "All columns are sortable by clicking on the column title or relevant arrow."
      },
      {
        element: ".subunitDetails",
        title: "Assign to all sub units",
        intro: "Check this box to assign all the selected unit administrator to all sub units of this unit.",
      },   
      {
        element: ".saveData",
        title: "Save",
        intro: allResourcesToTranslations.saveglobalcompliancerulesguideme
      }
      );
    }         
    if (expandUnitAdministratorList) {           
      updateSteps.push(        
        {
          element: ".unitAdministratorReportList",
          title: "Unit Administrator Report", intro: "This area will display a Unit Administrator Report for this unit."
        },{
        element: ".xlxs",
        title: "Unit Administrator Report", intro: "Click here to download an export of the displayed Unit Administrator Report for this Unit."
      }
      );
    }

setIntro({ ...intro, steps: [...updateSteps] });
};  

useEffect(() => {		    
 setGuideMe(true);
  }, [usersList]); 


const onExit = () => {
setIntro({ ...intro, stepsEnabled: false });
};
	
useEffect(() => {		    
setTitle(allResourcesToTranslations.tabmanageunitadmin);
setTitleEnglish(pageTitles.manageunitadmins);
updateBackButtonRoute("/UnitManagement");

isMounted.current = true;

getOneUnitDetails(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(unitIdToEdit)).then((result) => {
  if (result.isSuccess && result.error == null) {
    setPathway(result.unitDetailsItems[0].pathway);               
    setUnittActive(result.unitDetailsItems[0].active);        
    setGuideMe(true);       
    loadUnitAdministratorReport();     
  }
});
setLoading(false);
// at the end, return a cleanup method
return () => {
    isMounted.current = false
}
}, [usersList]);


const handleUnitAdministratorExpandClick = () => {		
  setExpandUnitAdministratorList(!expandUnitAdministratorList);
}
const loadUnitAdministratorReport = () => {
  setUnitAdministratorListOpen(false); 
  getFullUnitAdministratorReport(
    oidcUser.access_token, userId, orgDomain, parseInt(orgId),
    unitIdToEdit, true          
  ).then((response) => {
    if (
      response !== null &&
      response !== undefined &&
      response.isSuccess &&
      response.unitAdministratorReportItems !== null &&
      response.unitAdministratorReportItems !== undefined
    ) {
      
      if(response.unitAdministratorReportItems.length > 0) {
        let updatedUnitAdministratorList = response.unitAdministratorReportItems.map((item: any) => {
          return {
            ...item,
            isChecked: false,
          };
        });
        setunitAdministratorList(updatedUnitAdministratorList);
        setUnitAdministratorListOpen(true);                      

      }
    } else {                          
      return;
    }
  });  

};


const handleUserDetails = (
  event: React.ChangeEvent<HTMLInputElement>
) => {

    setUserDetails({
      ...userDetails,
      [event.target.name]: event.target.value,
    });

};

//#region user Section 
const handleSearch = () => {
  if (userDetails === null || userDetails === undefined ||
    ((userDetails.lastName === null || userDetails.lastName === undefined || userDetails.lastName.trimLeft().trimRight() === "")) &&
    (userDetails.firstName === null || userDetails.firstName === undefined || userDetails.firstName.trimLeft().trimRight() === "")) {
    setUsersList([]);
  }

  if(userDetails.firstName.trimLeft().trimRight() != "" || userDetails.lastName.trimLeft().trimRight() != "")
  {
    getUsersList(oidcUser.access_token, userId, orgDomain, userDetails.firstName, userDetails.lastName, parseInt(orgId)).then(
      (response) => {
        if (response !== null && response !== undefined && response.isSuccess &&
          response.listOfUsers !== null && response.listOfUsers !== undefined) {   
         let filterusertype = response.listOfUsers.filter((u: any) => u.userTypeId === 3 || u.userTypeId === 4);
         setUsersList(filterusertype);

          if(filterusertype.length <=0)
          {
            setShowWarningModal(true);
            setModalPopupMessage('No records found, please try again!!');
            setHasErrored(true);
            setExpandUnitAdministratorList(false);
          }       
        }
        
      }
    );
  }
  else
  {
    setShowWarningModal(true);
    setModalPopupMessage('Enter as many details as required to search for the account.');
    setHasErrored(false);
    setExpandUnitAdministratorList(false);
  }
};

const handleAddUsers = () => {
  let filterUsers = usersList.filter((user: any) => user.isChecked)
  let updatedUsers = usersList.filter((item: any) => !item.isChecked)
  let updateFilteredUsers = filterUsers.map((data: any) => ({
    ...data,
    isChecked: false
  }))
  setUsersList([...updatedUsers]);
  // setAddedUsers([...addedUsers, ...updateFilteredUsers.filter((user: any) => !!user && !!user.userID &&
  //   (!(!!addedUsers) || addedUsers.length <= 0 || (!!addedUsers && addedUsers.length > 0 && addedUsers.filter((user2: any) => !!user2 && !!user2.userID && user2.userID === user.userID).length <= 0)))]);
    let data = updateFilteredUsers;
    let updatedData = [];

    let givenuserid = "";
    if(data !== null && data !== undefined && data.length > 0) 
    {
      updatedData = data.map((item:any) => item.userID);       
      givenuserid = updatedData.join(",");
          saveUnitAdministrator(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(unitIdToEdit),givenuserid.toString() , assignSubunit,assignReportOnlyUnitAdmin).
          then((unitadminSaveRes) => {
            if (!!unitadminSaveRes) {
              let isSuccess = !!unitadminSaveRes.isSuccess && unitadminSaveRes.isSuccess;
              if (unitadminSaveRes.isSuccess)
              {
                let isStatusUpdateFailed = (isSuccess && unitadminSaveRes.statusUpdateError != null && unitadminSaveRes.statusUpdateError != undefined &&
                unitadminSaveRes.statusUpdateError.trim() !== "");
                setShowWarningModal(true);
                setModalPopupMessage('Unit admin(s) have been saved successfully!');
                setSaveSuccess(true);
                setHasErrored(false);
                setExpandUnitAdministratorList(false);
                loadUnitAdministratorReport();
              }
              else {
                setShowWarningModal(true);
                setModalPopupMessage(unitadminSaveRes.error);
                setHasErrored(true);                
                return;
              }
            }            
          }).catch((error) => {
            setShowWarningModal(true);
            setModalPopupMessage('Saving record has failed, please try again.');
            setHasErrored(true);
            return;
          });
    } else {
      setShowWarningModal(true);
      setModalPopupMessage('Saving record has failed, please try again.');
      setHasErrored(true);
      return;
    }
};


const handleSelectedUsers = (e: any, data: any) => {
  e.stopPropagation();

  let updatedData = usersList.map((item: any) => {
    if (data.userID == item.userID) {
      return {
        ...item,
        isChecked: !item.isChecked
      };
    }
    return item;
  })

  setUsersList(updatedData);
}


const rowBackgroundColor = (index: number) => {
  if (enableRowBanding) {
    return { backgroundColor: index % 2 == 0 ? "#eee" : "" }
  } else {
    return {}
  }
}

const handleChangePage = (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
  newPage: number
) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

const sortResultsByAsync = async (sortBy: "FirstName" | "LastName" | "Email", sortGivenItemInDesc: boolean) => {
  if (usersList == null || usersList.length <= 0) {
    return usersList;
  }
  var result = usersList.slice(0)
  setCurrentSortBy(sortBy)
  switch (sortBy) {

    case "Email":
      if (sortGivenItemInDesc) {
        result.sort((a, b) => {
          if (b === null || b.email === null) { return -1; }
          if (a === null || a.email === null) { return 1; }
          if (a.email.toLowerCase() > b.email.toLowerCase()) { return -1; }
          if (a.email.toLowerCase() < b.email.toLowerCase()) { return 1; }
          return 0;
        })
        if (reverseNextSort) {
          setEmailDescending(false)
        }
      } else {
        result.sort((a, b) => {
          if (b === null || b.email === null) { return 1; }
          if (a === null || a.email === null) { return -1; }
          if (a.email.toLowerCase() < b.email.toLowerCase()) { return -1; }
          if (a.email.toLowerCase() > b.email.toLowerCase()) { return 1; }
          return 0;
        })
        if (reverseNextSort) {
          setEmailDescending(true)
        }
      }
      setUsersList(result);
      break;
    case "FirstName":
      if (sortGivenItemInDesc) {
        result.sort((a, b) => {
          if (b === null || b.firstName === null) { return -1; }
          if (a === null || a.firstName === null) { return 1; }
          if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) { return -1; }
          if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) { return 1; }
          return 0;
        })
        if (reverseNextSort) {
          setFirstNameDescending(false)
        }
      } else {
        result.sort((a, b) => {
          if (b === null || b.firstName === null) { return 1; }
          if (a === null || a.firstName === null) { return -1; }
          if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) { return -1; }
          if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) { return 1; }
          return 0;
        })
        if (reverseNextSort) {
          setFirstNameDescending(true)
        }
      }
      setUsersList(result);
      break;
    case "LastName":
      if (sortGivenItemInDesc) {
        result.sort((a, b) => {
          if (b === null || b.lastName === null) { return -1; }
          if (a === null || a.lastName === null) { return 1; }
          if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) { return -1; }
          if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) { return 1; }
          return 0;
        })
        if (reverseNextSort) {
          setLastNameDescending(false)
        }
      } else {
        result.sort((a, b) => {
          if (b === null || b.lastName === null) { return 1; }
          if (a === null || a.lastName === null) { return -1; }
          if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) { return -1; }
          if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) { return 1; }
          return 0;
        })
        if (reverseNextSort) {
          setLastNameDescending(true)
        }
      }
      setUsersList(result);
      break;


    default:
      break;
  }
  return result.slice(0);
}

const sortResultsBy = async (sortBy: "FirstName" | "LastName" | "Email", sortGivenItemInDesc: boolean) => {
  setSortInProgress(true);
  await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
    setCurrentSortBy(sortBy)
    setUsersList(result)
    setPage(0)
    setSortInProgress(false);
  });
}

const clickFirstNameAsc = () => {
  setReverseNextSort(false);
  setFirstNameDescending(false);
  sortResultsBy("FirstName", false);
}

const clickFirstNameDesc = () => {
  setReverseNextSort(false);
  setFirstNameDescending(true)
  sortResultsBy("FirstName", true)
}

const clickLastNameAsc = () => {
  setReverseNextSort(false);
  setLastNameDescending(false);
  sortResultsBy("LastName", false);
}

const clickLastNameDesc = () => {
  setReverseNextSort(false);
  setLastNameDescending(true)
  sortResultsBy("LastName", true)
}

const clickEmailAsc = () => {
  setReverseNextSort(false);
  setEmailDescending(false);
  sortResultsBy("Email", false);
}

const clickEmailDesc = () => {
  setReverseNextSort(false);
  setEmailDescending(true)
  sortResultsBy("Email", true)
}
const handleClosePopup = () => {
  setShowWarningModal(false);
};


//endregion


if (loading) return <LoaderContainer />;
return (
<>
    <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
            nextLabel: "Next",
            prevLabel: "Back",
            tooltipClass: "sample-class",
            showBullets: true,
        }}
    />
    <CustomTooltip displayText={[allResourcesToTranslations.guideme]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
            className={styles.guideMeButtonback}>
            <GuideIcon />
        </span>
    </CustomTooltip>
	
    <div style={{paddingTop:"20px", paddingBottom: "20px"}}>
        <div className={"unitDetails"} style={{display:'flex', justifyContent:'center', alignItems:'center', border:'1px solid #D3D6DF', height:'42px', borderRadius:'5px'}}>
            <div>
            <p>{allResourcesToTranslations.unitid}: {unitIdToEdit}&nbsp;|&nbsp;{allResourcesToTranslations.unitpathwaydetails}: {pathway}</p>            
            </div>
            <div className="checkInactive">
            <Checkbox
                size="small"
                disabled
                color="primary"              
                checked={unitActive}
                className={styles.checkBox}
                onClick={() => setUnittActive(!unitActive)}
            />{" "}
            <span className={unitActive ? styles.accountActiveText : styles.accountInactiveText} >{unitActive ? allResourcesToTranslations.activeunit : allResourcesToTranslations.inactiveunit}</span>
            </div>
        </div>         
        
    </div>
    <div style={{paddingTop:"20px", paddingBottom: "20px"}}>
      <FormControl
        component="fieldset"
        style={{ margin: "10px", width: '100%' }}
      >
        <b className={styles.labelText}>Add Unit Administrator</b>
        <p
          style={{
            color: "#D3D6DF",
            fontSize: "12px",
            fontStyle: "italic",
          }}> Enter as many details as required to search for the account</p>


        <div
          style={{
            borderRadius: "5px",
            width: "99%",
          }}
        >

          <>
            <div>
              <div className={styles.expandContainer}  style={{ backgroundColor: '#fff' }}>
                <div className={`${styles.listField}`} style={{ marginLeft: '30px' }}>
                  <TextField
                    className={`${styles.input} firstName`}
                    label={"First Name"}
                    name="firstName"
                    variant="outlined"
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                    value={userDetails.firstName}
                    onChange={handleUserDetails}
                    style={{ marginLeft: "-30px" }}
                  />
                </div>

                <div className={`${styles.listField}`}>
                  <TextField
                    className={`${styles.input} lastName`}
                    label={"Last Name"}
                    name="lastName"
                    variant="outlined"
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                    value={userDetails.lastName}
                    onChange={handleUserDetails}
                  />
                </div>
              </div>
              <div   className={styles.lowerButtonPanel} style={{ marginTop: '10px', marginRight: '4px' }}>
                <CustomTooltip displayText={["Search"]}>
                  <Button
                    onClick={handleSearch}
                    className={`${styles.moduleListButton} search`}
                    variant="contained"
                    color="primary"
                  >
                    <SearchIcon />
                  </Button>
                </CustomTooltip>
              </div>
            </div>

          </>

        </div>

        <div
          style={{
            marginTop: '10px',
            borderRadius: "5px",
            width: "99%",
          }} >
          {usersList != null && usersList.length > 0 && (
            <div style={{ marginLeft: "5px" }}>
              <div className={styles.expandContainer} style={{ backgroundColor: '#fff' }}> 
                <div className={styles.listField}><b>Search Results</b></div>
                <div className={styles.lowerButtonPanel}><b>{usersList.length.toString()} Results</b></div>
              </div>

              <div className={styles.paginationControlsContainer}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={usersList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
              <div  className={`${styles.tableSection} accountlist`}>
                <div className={styles.tableContainer}>
                  <BrandedCard>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                color: getColor(color.substring(1)),
                                backgroundColor: color,
                                borderRadius: "8px 0 0 0",
                              }}
                              align="left"
                            >
                              <div className={styles.tableHeadCellContainer}>
                                <div>{"Select"}</div>
                              </div>
                            </TableCell>

                            <TableCell
                              style={{
                                color: getColor(color.substring(1)),
                                backgroundColor: color,
                              }}
                              align="left"
                            >
                              <div className={styles.tableHeadCellContainer}>
                                <div
                                  onClick={() => {
                                    setLastNameDescending(!lastNameDescending);
                                    sortResultsBy("LastName", !lastNameDescending);
                                  }}
                                >
                                  {"Last Name" /* TODO translate */}
                                </div>
                                <div className={styles.columnHeadArea}>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "LastName" &&
                                          lastNameDescending === false
                                          ? "100%"
                                          : "50%",
                                      margin: "2px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickLastNameAsc}
                                  >
                                    <UpArrowIcon />{" "}
                                  </span>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "LastName" &&
                                          lastNameDescending === true
                                          ? "100%"
                                          : "50%",
                                      margin: "0px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickLastNameDesc}
                                  >
                                    <DownArrowIcon />{" "}
                                  </span>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                color: getColor(color.substring(1)),
                                backgroundColor: color,
                              }}
                              align="left"
                            >
                              <div className={styles.tableHeadCellContainer}>
                                <div
                                  onClick={() => {
                                    setFirstNameDescending(!firstNameDescending);
                                    sortResultsBy(
                                      "FirstName",
                                      !firstNameDescending
                                    );
                                  }}
                                >
                                  {"First Name" /* TODO translate */}
                                </div>
                                <div className={styles.columnHeadArea}>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "FirstName" &&
                                          firstNameDescending === false
                                          ? "100%"
                                          : "50%",
                                      margin: "2px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickFirstNameAsc}
                                  >
                                    <UpArrowIcon />{" "}
                                  </span>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "FirstName" &&
                                          firstNameDescending === true
                                          ? "100%"
                                          : "50%",
                                      margin: "0px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickFirstNameDesc}
                                  >
                                    <DownArrowIcon />{" "}
                                  </span>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                color: getColor(color.substring(1)),
                                backgroundColor: color,
                                borderRadius: "0 8px 0 0",
                              }}
                              align="left"
                            >
                              <div className={styles.tableHeadCellContainer}>
                                <div
                                  onClick={() => {
                                    setEmailDescending(!emailDescending);
                                    sortResultsBy("Email", !emailDescending);
                                  }}
                                >
                                  {"Email" /* TODO translate */}
                                </div>
                                <div className={styles.columnHeadArea}>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "Email" &&
                                          emailDescending === false
                                          ? "100%"
                                          : "50%",
                                      margin: "2px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickEmailAsc}
                                  >
                                    <UpArrowIcon />{" "}
                                  </span>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "Email" &&
                                          emailDescending === true
                                          ? "100%"
                                          : "50%",
                                      margin: "0px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickEmailDesc}
                                  >
                                    <DownArrowIcon />{" "}
                                  </span>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {usersList
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((result: any, i) => {
                              return (
                                <TableRow
                                  key={`reportScheduler-${i}`}
                                  style={rowBackgroundColor(i)}
                                >
                                  <TableCell align="left">
                                    <Checkbox
                                      size="small"
                                      color="primary"
                                      className={styles.checkBox}
                                      name="selectedUser"
                                      key={result.userID}
                                      checked={result.isChecked}
                                      onChange={(e) =>
                                        handleSelectedUsers(e, result)
                                      }
                                    />
                                  </TableCell>

                                  <TableCell align="left">
                                    {result.lastName}
                                  </TableCell>
                                  <TableCell align="left">
                                    {result.firstName}
                                  </TableCell>
                                  <TableCell align="left">{result.email}</TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </BrandedCard>
                </div>
              </div>
              <div className={styles.paginationAddedUsersControl}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={usersList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
              <div className={styles.lowerButtonPanel}>
               <div style={{fontWeight:"bold"}}>
               <Checkbox                
                  size="small"                  
                  color="primary"
                  checked={assignReportOnlyUnitAdmin}
                  className={`${styles.checkBox} subunitDetails`}
                  onClick={() => setAssignReportOnlyUnitAdmin(!assignReportOnlyUnitAdmin)}
                />Reporting Access Only (Removes Admin Access)&nbsp;&nbsp;&nbsp;&nbsp;
                <Checkbox                
                  size="small"                  
                  color="primary"
                  checked={assignSubunit}
                  className={`${styles.checkBox} subunitDetails`}
                  onClick={() => setAssignSubunit(!assignSubunit)}
                />{allResourcesToTranslations.assigntoallsubunits}&nbsp;&nbsp;
                
               </div>
               
               
              </div>
              <div className={styles.lowerButtonPanel}>
               <div style={{fontWeight:"bold"}}>           
                <CustomTooltip displayText={[allResourcesToTranslations.saveglobalcompliancerulesguideme]}>
                  <span style={{ marginRight: '7px' }}>
                    <Button
                      disabled={(usersList.filter((user: any) => user.isChecked)).length == 0}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="saveData"
                      onClick={handleAddUsers}
                    >
                      <span>
                        <SaveIcon />
                      </span>
                    </Button>
                  </span>
                </CustomTooltip>
               </div>
               
               
              </div>
            </div>
          )}
        </div>

        {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
      </FormControl>
    </div>
    <div style={{ paddingTop: "20px"}}>         
          {unitAdministratorListOpen &&
            <Accordion style={{ margin: "0px 0px", position: 'inherit', boxShadow: "none" }}
                expanded={expandUnitAdministratorList}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="unitadmin-content"
                id="unitadmin-header"   
                style={{borderBottom: '1px solid #D3D6DF', padding:"0px"}}        
                onClick={(e) => handleUnitAdministratorExpandClick()}
              >              
              <Typography ><b>{allResourcesToTranslations.existingunitadminreport}</b></Typography>
              </AccordionSummary>
              <AccordionDetails style={{padding: "0px"}}>              
                {unitAdministratorListOpen && unitAdministratorList != null && unitAdministratorList.length > 0 && (
                  <div id="unitComplianceReportList" style={{width: "100%"}}>
                    <UnitAdministratorReportSection unitAdministratorData={unitAdministratorList} unitIdToEdit={unitIdToEdit}/>
                  </div>
                )}         
              </AccordionDetails>
            </Accordion>
            }
    </div>   
</>
);

};

export default UnitManageUnitAdministrators;

