import React, { useContext } from 'react';
import { UIContext } from '../../contexts/UIContext';
import styles from '../../styles/sideMenu.module.css';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

export const SideMenuCloseButton = (props: any) => {
	const { isMultiSectionModulePlayer } = props;
    const { sideMenuOpen, setSideMenuOpen } = useContext(UIContext);
    const { modulePlayerLayoutSideMenuOpen, setModulePlayerLayoutSideMenuOpen } = useContext(UIContext);
    
    return (
        <div 
            className={(!!props && !!isMultiSectionModulePlayer && isMultiSectionModulePlayer ? modulePlayerLayoutSideMenuOpen : sideMenuOpen) ? 
                            (!!props && !!isMultiSectionModulePlayer && isMultiSectionModulePlayer ? styles.closeSideMenuBtn_MulSecModPlayer : styles.closeSideMenuBtn) : 
                            (!!props && !!isMultiSectionModulePlayer && isMultiSectionModulePlayer ? styles.closeSideMenuBtnClosed_MulSecModPlayer : styles.closeSideMenuBtnClosed) }
            onClick={() => 
                {
                    if (!!props && !!isMultiSectionModulePlayer && isMultiSectionModulePlayer) {
                        setModulePlayerLayoutSideMenuOpen(!modulePlayerLayoutSideMenuOpen)
                    }
                    else {
                        setSideMenuOpen(!sideMenuOpen)
                    }
                }
            }
        >
            <div className={(!!props && !!isMultiSectionModulePlayer && isMultiSectionModulePlayer ? modulePlayerLayoutSideMenuOpen : sideMenuOpen) ? 
                                (!!props && !!isMultiSectionModulePlayer && isMultiSectionModulePlayer ? styles.arrowContainer_MulSecModPlayer : styles.arrowContainer) : 
                                    (!!props && !!isMultiSectionModulePlayer && isMultiSectionModulePlayer ? styles.arrowContainerClosed_MulSecModPlayer : styles.arrowContainerClosed) }>
                <KeyboardArrowLeftIcon style={{ color: "white"}} fontSize="default"/>
            </div>
        </div>
    )
}

export default SideMenuCloseButton;