//React + Typescript
import React, { useEffect, useContext, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
//API call
import { getUser } from '../API/GetUser';
import { getUpdatedPageTranslation } from '../API/GetUpdatedPageTranslation'
//Functions
import { startMidnightLogout } from '../functions/startMidnightLogout';
//Contexts
import { UIContext } from '../contexts/UIContext'
import { SessionProvider } from '../contexts/SessionContext';
import { CPDPanelProvider } from '../contexts/CPDPanelContext';
import { InfopathProvider } from '../contexts/InfopathContext'; // The InfopathContext saves the state of the course
import { CorperateThemeContext } from '../contexts/CorperateThemeContext';
import { MyTrainingListProvider } from '../contexts/MyTrainingListContext';
import { TranslationsContext } from '../contexts/TranslationsContext';
import { CourseProgressPanelProvider } from '../contexts/CourseProgressContext';
import { getOrgConfig } from '../API/GetOrgConfig'
import { getOrgFeatures } from '../API/GetOrgFeatures'
import { getOrgDomainConfig } from '../API/GetOrgDomainConfig';
import { getOrgSamlSSOSettings } from '../API/GetOrgSamlSSOSettings';
import oidcConfig from '../configuration';
//Components
import Quiz from '../Pages/Quiz';
import Lesson from '../Pages/Lesson';
import Report from '../Pages/Report'
import SideMenu from './SideMenu/SideMenu';
import MainPanel from '../Components/MainPanel';
import ThemePage from '../Pages/Theme/index';
import TopNavbar from './TopNavbar';
import Dashboard from '../Pages/Dashboard'
import MyTraining from '../Pages/MyTraining/index';
import QuizHistory from '../Pages/QuizHistory';
import QuizSummary from '../Pages/QuizSummary/QuizSummary';
import SSOExitPage from '../Pages/SSOExitPage/index';
import ErrorPage from '../Pages/ErrorPage';
import PolicyWrapper from '../Pages/Policy';
import BouncingArrow from '../Components/BouncingArrow';
import LoaderContainer from '../Components/LoaderContainer';
import AccountSettings from '../Pages/AccountSettings'
import QuickfactsWrapper from '../Pages/Quickfacts';
import HelpViewerWrapper from '../Pages/Help'
import LearningModuleList from '../Pages/LearningModules/index';
import CertificateWrapper from '../Pages/Certificate';
// import ScreenOrientation from './ScreenOrientation';
import CurrentAdminReport from '../Pages/CurrentAdminReport';
import CurrentAdminReportResult from '../Pages/CurrentAdminReportResult';
import CourseStatusReport from '../Pages/CourseStatusReport';
import CourseStatusReportResult from '../Pages/CourseStatusReportResult';
import ProgressReport from '../Pages/ProgressReport';
import ProgressReportResult from '../Pages/ProgressReportResult';
import SentEmailReport from '../Pages/SentEmailReport';
import TestLargeData from '../Pages/TestLargeData';
import SentEmailReportResult from '../Pages/SentEmailReportResult';
import SummaryReport from '../Pages/SummaryReport';
import SummaryReportResult from '../Pages/SummaryReportResult';
import EmailReport from '../Pages/EmailReport';
import ReportScheduler from '../Pages/ReportScheduler/ReportScheduler';
import ReportSchedulerList from '../Pages/ReportSchedulerList/ReportSchedulerList';
import UserDetailReport from '../Pages/UserDetailReport/UserDetailReport';
import UserDetailReportResult from '../Pages/UserDetailReportResult/UserDetailreportResult';
import UnitComplianceReport from '../Pages/UnitComplianceReport/UnitComplianceReport';
import UnitComplianceReportResult from '../Pages/UnitComplianceReportResult/UnitComplianceReportResult';
import CPDReport from '../Pages/CPDReport/CPDReport';
import CPDReportResult from '../Pages/CPDReportResult/CPDReportResult';
import UnitPathwayReport from '../Pages/UnitPathwayReport/UnitPathwayReport';
import UnitPathwayReportResult from '../Pages/UnitPathwayReportResult/UnitPathwayReportResult';
import UnitAdministratorReport from '../Pages/UnitAdministratorReport/UnitAdministratorReport';
import UnitAdministratorReportResult from '../Pages/UnitAdministratorReportResult/UnitAdministratorReportResult';
import PolicyBuilderReport from '../Pages/PolicyBuilderReport/PolicyBuilderReport';
import PolicyBuilderReportResult from '../Pages/PolicyBuilderReportResult/PolicyBuilderReportResult';

import { OrgFeatureItem } from '../globalTypes';
import CurrentAdminReportQuizHistory from '../Pages/CurrentAdminReport/CurrentAdminReportQuizHistory';
import QuizHistoryReport from '../Pages/QuizHistoryReport';
import QuizHistoryReportResult from '../Pages/QuizHistoryReportResult';
import LicensingReport from '../Pages/LicensingReport/LicensingReport';
import LicensingReportResult from '../Pages/LicensingReportResult/LicensingReportResult';
import WarningReport from '../Pages/WarningReport/WarningReport';
import WarningReportResult from '../Pages/WarningReportResult/WarningReportResult';
import AddAccount from '../Pages/Administration/AddAccount/AddAccount';
import AtRiskReport from '../Pages/AtRiskReport/AtRiskReport';
import AtRiskReportResult from '../Pages/AtRiskReportResult/AtRiskReportResult';
import AccountDetails from '../Pages/Administration/AccountDetails/AccountDetails';
import AccountManagement from '../Pages/Administration/AccountManagement/AccountManagement';
import UnitManagement from '../Pages/Administration/UnitManagement/UnitManagement';

import ImportAccounts from '../Pages/Administration/ImportAccounts/ImportAccounts';
import AccountDetailsTabView from '../Pages/Administration/AccountDetails/AccountDetailsTabView';
import SupportDetails from '../Pages/SupportDetails/SupportDetails';
import OrganisationManagement from '../Pages/Administration/OrganisationManagement/OrganisationManagement';
import OrganisationDeactivation from '../Pages/Administration/OrganisationDeactivation/OrganisationDeactivation';
import ComplianceRules from '../Pages/Administration/OrganisationManagement/ComplianceRules';
import ConfigurationItems from '../Pages/Administration/OrganisationManagement/ConfigurationItems';
import SingleSignOn from '../Pages/Administration/OrganisationManagement/SingleSignOn';
import AddUnit from '../Pages/Administration/AddUnit/AddUnit';
import MoveUnit from '../Pages/Administration/MoveUnit/MoveUnit';
import MoveUnitWithDND from '../Pages/Administration/MoveUnit/MoveUnitWithDND';
import MoveAccountsToUnit from '../Pages/Administration/MoveAccountsToUnit/MoveAccountsToUnit';
import UnitDetailsTabView from '../Pages/Administration/UnitDetails/UnitDetailsTabView';
import OrgEmailTemplates from '../Pages/Administration/EmailSettings/OrgEmailTemplates';
import OrgReminderEmailRules from '../Pages/Administration/EmailSettings/OrgReminderEmailRules';
import OrgReminderEmailRulesAdd from '../Pages/Administration/EmailSettings/OrgReminderEmailRulesAdd';
import PolicyBuilderList from '../Pages/Administration/PolicyBuilderSettings/PolicyBuilderList';
import CPDProfilesList from '../Pages/Administration/CPDProfileSettings/CPDProfilesList';
import PolicyBuilderDetails from '../Pages/Administration/PolicyBuilderSettings/PolicyBuilderDetails';
import CPDProfileDetails from '../Pages/Administration/CPDProfileSettings/CPDProfileDetails';
import ModifyLinks from '../Pages/Administration/ModifyLinks/ModifyLinks';
import ManageTipVideos from '../Pages/Administration/ManageTipVideos/ManageTipVideos';
import TipVideos from '../Pages/Administration/TipVideos/TipVideos';
import Links from '../Pages/Links';
import GetOrgLinks from '../API/Administration/Organisation/GetOrgLinks';
import OrganisationAdministrators from '../Pages/Administration/OrganisationAdministrators';
import OrganisationAdministratordetails from '../Pages/Administration/OrganisationAdministrators/OrganisationAdministratordetails';
import ArchiveAccounts from '../Pages/Administration/ArchiveAccounts/ArchiveAccounts';
import MyExternalCertificate from '../Pages/MyExternalCertificate';
import ExternalCertificateDetails from '../Pages/Administration/ExternalCertificates/ExternalCertificateDetails';
import ExternalCertificates from '../Pages/Administration/ExternalCertificates/ExternalCertificates';
import GetMyExternalCertificates from '../API/Administration/ExternalCertificate/GetMyExternalCertificates';
import ExternalCertificatesReport from '../Pages/ExternalCertificatesReport/ExternalCertificatesReport';
import ReminderEmailRulesTabView from "../Pages/Administration/EmailSettings/ReminderEmailRulesTabView";
import ExternalCertificatesReportResult from '../Pages/ExternalCertificatesReportResult/ExternalCertificatesReportResult';
import AssignCoursestoUnits from '../Pages/Administration/AssignCoursestoUnits';
import AccountManagers from '../Pages/Administration/AccountManagers/AccountManagers';
import OrganisationApplicationtemplate from '../Pages/Administration/OrganisationApplicationtemplate/OrganisationApplicationtemplate';

export const Layout = (): JSX.Element => {
  const [ superAdminDomain, setSuperAdminDomain ] = useState<string>(oidcConfig.currentdomainSA);
  const { oidcUser, logout } = useReactOidc();
  const { updateSiteLanguage } = useContext(TranslationsContext)
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0;
	const orgId = oidcUser?.profile.profile ? parseInt(oidcUser.profile.profile) : -1;
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  const { setTheme } = useContext(CorperateThemeContext)
  const { setAdminLevel, showUnitAdminUI, showOrgAdminUI, showSuperAdminUI, setShowSSOExitButton, setShowScrollArrow, setShowLastPassDate, setshowReportDueDate, setInfopathQuestionCount, setDisplayName,
          IsCPDEnabled,
          setIsCPDEnabled,
          IsPolicyEnabled,
          setIsPolicyEnabled,
          IsExtCertEnabled,
          setIsExtCertEnabled,
          setEnableActivityLogs,
          IsEbookEnabled,
          setIsEbookEnabled, setTimeZoneId, setPasswordLockout, setDisablePasswordField, setShowWelcomeModal, setShowWelcomePopup,
          IsSSOEnabled,
          setIsSSOEnabled,
          IsLinkEnabled,
          setIsLinkEnabled,
          showMyCertificateUI,
          setShowMyCertificateUI, 
          superAdminSelectedBasicOrgData} = useContext(UIContext);
  const [ loading, setLoading ] = useState(true) 
  const [ languageLoading, setLanguageLoading ] = useState(true)

  // const showThemeRoute = () => {
  //   if(showUnitAdminUI || showOrgAdminUI || showSuperAdminUI) {
  //       return <Route path="/theme" exact component={ThemePage}></Route>
  //   }
  // }

  const showSupportDetailsRoute = () => {
    if(showUnitAdminUI || showOrgAdminUI || showSuperAdminUI) {
        return <Route path="/supportdetails" exact component={SupportDetails}></Route>
    }
  }

  const showOrgManagementRoute = () => {
    if(showOrgAdminUI || showSuperAdminUI) {
        return <Route path="/organisationManagement" exact component={OrganisationManagement}></Route>
    }
  } 
  
  const showOrgDeactivationRoute = () => {
    if(showOrgAdminUI || showSuperAdminUI) {
        return <Route path="/organisationDeactivation" exact component={OrganisationDeactivation}></Route>
    }
  }
  const showModifyLinksRoute = () => {
    if(showUnitAdminUI || showOrgAdminUI || showSuperAdminUI) {
        return <Route path="/modifylinks" exact component={ModifyLinks}></Route>
    }
  }
  
  const showAssignCoursesRoute = () => {
    if(showUnitAdminUI || showOrgAdminUI || showSuperAdminUI) {
        return <Route path="/assigncourses" exact component={AssignCoursestoUnits}></Route>
    }
  }
  
  const showManageTipVideosRoute = () => {
    if(showOrgAdminUI || showSuperAdminUI) {
        return <Route path="/managetipvideos" exact component={ManageTipVideos}></Route>
    }
  }
  
  const showManageTipVideosSuperAdminRoute = () => {
    if(showOrgAdminUI || showSuperAdminUI) {
        return <Route path="/managetipvideossuper" exact component={ManageTipVideos}></Route>
    }
  }
  
  const showTipVideosRoute = () => {
    return <Route path="/tipvideos" exact component={TipVideos}></Route>
  }
  
  const showTipVideosSuperAdminRoute = () => {
    return <Route path="/tipvideossuper" exact component={TipVideos}></Route>
  }

  const showComplianceRulesRoute = () => {
    if(showOrgAdminUI || showSuperAdminUI) {
        return <Route path="/organisationManagement/compliancerules" exact component={ComplianceRules}></Route>
    }
  }

  const showConfigurationItemsRoute = () => {
    if(showOrgAdminUI || showSuperAdminUI) {
        return <Route path="/organisationManagement/configurationItems" exact component={ConfigurationItems}></Route>
    }
  }

  const showSingleSignOnRoute = () => {
    if((showOrgAdminUI || showSuperAdminUI) && IsSSOEnabled) {
        return <Route path="/organisationManagement/singlesignon" exact component={SingleSignOn}></Route>
    }
  }

  const showCPDProfilesListRoute = () => {
    if(showOrgAdminUI || showSuperAdminUI) {
        return <Route path="/cpdProfilesList" exact component={CPDProfilesList}></Route>
    }
  }

  const showPolicyBuilderListRoute = () => {
    if(showOrgAdminUI || showSuperAdminUI) {
        return <Route path="/policyBuilderList" exact component={PolicyBuilderList}></Route>
    }
  }
  
  
  useEffect(()=> {
    const chatbotScript = document.createElement('script')
    chatbotScript.src = "//www.socialintents.com/api/socialintents.1.3.js#2c9fa0e179636200017963d69b710078";
    chatbotScript.async = true;
    document.body.appendChild(chatbotScript)
    startMidnightLogout(logout)
    return (() => {
      document.body.removeChild(chatbotScript)
    }) 
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  const LoadOrgConfigBasedOnGivenDomain = (givenDomainForConfig: string, givenDomainForFeatures: string, isSuperAdmin: boolean = false) => {
    //console.log("Layout LoadOrgConfigBasedOnGivenDomain isSuperAdmin: " + (isSuperAdmin ? 1 : 0).toString() + " getOrgDomainConfig: " + givenDomainForConfig + " givenDomainForFeatures: " + givenDomainForFeatures)
    //(window.location.host.toLowerCase().indexOf("localhost") !== -1 || (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomain) !== -1 && window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ?   oidcConfig.stagingdomain : window.location.host
    getOrgDomainConfig(oidcUser.access_token, userId, givenDomainForConfig, 
      (isSuperAdmin && superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.orgID : -1,
      (isSuperAdmin && superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.domainName : "",
      (isSuperAdmin && superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.siteName : "")
      .then((configResult) => {
      if (!!configResult && configResult.isSuccess){
        setTheme({ color: configResult.config.primaryColor, logoUri:  configResult.config.logo, backgroundUri: configResult.config.background });
        setShowLastPassDate(configResult.config.isShowLastPassEnabled)
        setInfopathQuestionCount(configResult.config.quizQuestionsToAsk)
        setshowReportDueDate(configResult.config.isShowLastPassEnabled);
        setTimeZoneId(configResult.config.timeZoneId);
        setShowWelcomePopup(configResult.config.showWelcomePopup);
        setPasswordLockout(configResult.config.passwordLockout);
        setDisablePasswordField(configResult.config.disablePasswordField);
      }
      if (!!configResult && !!configResult.orgID){
        getOrgFeatures(oidcUser.access_token, userId, givenDomainForFeatures, configResult.orgID, 
          isSuperAdmin && !!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : -1,
          isSuperAdmin && !!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : "",
          isSuperAdmin && !!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "").then((featureResult) => {
          if (!!featureResult && featureResult.isSuccess){
            var features = featureResult.orgFeatureList as Array<OrgFeatureItem>;
            if (!!features && features.length > 0) {
              setIsCPDEnabled(features.some((el) => !!el.featureName && el.featureName.toLowerCase() === "cpd profile" && !!el.isEnabled && el.isEnabled === true));
              setIsPolicyEnabled(features.some((el) => !!el.featureName && el.featureName.toLowerCase() === "policy" && !!el.isEnabled && el.isEnabled === true));
              setIsEbookEnabled(features.some((el) => !!el.featureName && el.featureName.toLowerCase() === "ebook" && !!el.isEnabled && el.isEnabled === true));
              setIsSSOEnabled(features.some((el) => !!el.featureName && el.featureName.toLowerCase() === "sso" && !!el.isEnabled && el.isEnabled === true));
              setIsExtCertEnabled(features.some((el) => !!el.featureName && el.featureName.toLowerCase() === "external certificate" && !!el.isEnabled && el.isEnabled === true));
            }
          }
          
          setLoading(false)
        }).catch((error) => { setLoading(false) })
      }
      else {
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      setAdminLevel(parseInt(oidcUser?.profile.nickname ? "1": "4")) // Have this to show the theme page always
    }
    else {
      setAdminLevel(parseInt(oidcUser?.profile.nickname ? oidcUser.profile.nickname: "4")) // Have this to show theme based on user admin level
    }

    getUser(oidcUser.access_token, userId, orgDomain).then((userData) => {
      if (userData !== null) {
        const {firstName, lastName} = userData
        setDisplayName(!userData.swapFirstLastNames? `${firstName} ${lastName}` : `${lastName} ${firstName}`)
        if (!!userData && !!userData.langCode && userData.langCode.toLowerCase() !== "en-au") {
          getUpdatedPageTranslation(oidcUser.access_token, userId, orgDomain, "Full Client Side Translation", userData.langCode).then((langRes) => {
            if (langRes.isSuccess){
              updateSiteLanguage(langRes.allResourcesToTranslations, userData.langCode)
              setLanguageLoading(false)
            }
          })
        } else {
          setLanguageLoading(false)
        }
      }
    })

    var currentDomain = window.location.host
    const key1 = "https://";
    const key2 = "http://";
    const key3 = ".com";
    const idx1 = currentDomain.indexOf(key1);
    const idx2 = currentDomain.indexOf(key2);
    const idx3 = currentDomain.indexOf(key3);
    currentDomain = (idx1 !== -1 && idx3 !== -1) ? currentDomain.substring(idx1 + key1.length, idx3 + key3.length) : 
                      ((idx2 !== -1 && idx3 !== -1) ? currentDomain.substring(idx2 + key2.length, idx3 + key3.length) : currentDomain);
                      currentDomain = (window.location.host.toLowerCase().indexOf("localhost") !== -1 || 
                      (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomain) !== -1 && 
                        window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ? 
                        oidcConfig.stagingdomain : 
                        currentDomain.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    currentDomain = (window.location.host.toLowerCase().indexOf("localhost") !== -1 || 
                      (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomainSA) !== -1 && 
                        window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ? 
                        oidcConfig.stagingdomainSA : 
                        currentDomain.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    currentDomain = (window.location.host.toLowerCase().indexOf("localhost") !== -1 || 
                        (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomainForDeactTest) !== -1 && 
                          window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ? 
                          oidcConfig.stagingdomainForDeactTest : 
                          currentDomain.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    if(orgId !== 0 && !!superAdminSelectedBasicOrgData !== true) {
      getOrgConfig(oidcUser.access_token, userId, currentDomain, orgId).then((configResult) => {
        if (configResult.isSuccess){
          setTheme({ color: configResult.config.primaryColor, logoUri:configResult.config.logo, backgroundUri:configResult.config.background });
          setShowLastPassDate(configResult.config.isShowLastPassEnabled);
          setInfopathQuestionCount(configResult.config.quizQuestionsToAsk);
          setshowReportDueDate(configResult.config.isShowLastPassEnabled);
          setEnableActivityLogs(configResult.config.enableActivityLogs);
          setTimeZoneId(configResult.config.timeZoneId);
          setShowWelcomePopup(configResult.config.showWelcomePopup);          
          setPasswordLockout(configResult.config.passwordLockout);
          setDisablePasswordField(configResult.config.disablePasswordField);
        }
        getOrgFeatures(oidcUser.access_token, userId, currentDomain, orgId).then((featureResult) => {
          if (!!featureResult && featureResult.isSuccess){
            var features = featureResult.orgFeatureList as Array<OrgFeatureItem>;
            if (!!features && features.length > 0) {
              setIsCPDEnabled(features.some((el) => !!el.featureName && el.featureName.toLowerCase() === "cpd profile" && !!el.isEnabled && el.isEnabled === true));
              setIsPolicyEnabled(features.some((el) => !!el.featureName && el.featureName.toLowerCase() === "policy" && !!el.isEnabled && el.isEnabled === true));
              setIsEbookEnabled(features.some((el) => !!el.featureName && el.featureName.toLowerCase() === "ebook" && !!el.isEnabled && el.isEnabled === true));
              setIsSSOEnabled(features.some((el) => !!el.featureName && el.featureName.toLowerCase() === "sso" && !!el.isEnabled && el.isEnabled === true));
              setIsExtCertEnabled(features.some((el) => !!el.featureName && el.featureName.toLowerCase() === "external certificate" && !!el.isEnabled && el.isEnabled === true));            
            }
          } 
          setLoading(false)
        }).catch((error) => {setLoading(false)})

        
      });
    } else {
      if (!!superAdminSelectedBasicOrgData && superAdminSelectedBasicOrgData.orgID > 0 && !!superAdminSelectedBasicOrgData.domainName) {
        LoadOrgConfigBasedOnGivenDomain(currentDomain, currentDomain, true);
      }
      else {
        LoadOrgConfigBasedOnGivenDomain((window.location.host.toLowerCase().indexOf("localhost") !== -1 || 
                                          (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomain) !== -1 && 
                                            window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ?   
                                              oidcConfig.stagingdomain : 
                                                ((window.location.host.toLowerCase().indexOf("localhost") !== -1 || 
                                                 (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomainSA) !== -1 && 
                                                  window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ?   
                                                    oidcConfig.stagingdomainSA : 
                                                      ((window.location.host.toLowerCase().indexOf("localhost") !== -1 || 
                                                        (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomainForDeactTest) !== -1 && 
                                                          window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ?   
                                                            oidcConfig.stagingdomainForDeactTest : window.location.host)),
                                        currentDomain);
      }
    }

    
    if(orgId !== 0) {
      GetOrgLinks(oidcUser.access_token, userId, currentDomain, orgId).then((linkResult) => {
        if (linkResult.isSuccess && linkResult.orgLinks.length > 0) {
          if (linkResult.orgLinks !== null) {
            setIsLinkEnabled(true);           
          }
        
        } else{ setIsLinkEnabled(false); }
        setLoading(false)
      });

      GetMyExternalCertificates(oidcUser.access_token,userId, currentDomain, orgId,"chkUserAccess").then((result) => {
        if (result.isSuccess && result.myExternalCertificates.length > 0) {
          if (result.myExternalCertificates !== null) {
            setShowMyCertificateUI(true);           
          }
        
        } else{ setShowMyCertificateUI(false); }
        setLoading(false)
      });
    }


    getOrgSamlSSOSettings(oidcUser.access_token, userId, 
      (window.location.host.toLowerCase().indexOf("localhost") !== -1 || 
        (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomain) !== -1 && 
          window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ? 
          oidcConfig.stagingdomain : 
          ((window.location.host.toLowerCase().indexOf("localhost") !== -1 || 
            (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomainSA) !== -1 && 
              window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ? 
              oidcConfig.stagingdomainSA : 
              ((window.location.host.toLowerCase().indexOf("localhost") !== -1 || 
                (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomainForDeactTest) !== -1 && 
                  window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ? 
                    oidcConfig.stagingdomainForDeactTest : 
                    window.location.host))
    ).then((settingsResult) => {
      if(settingsResult.isSuccess){
        setShowSSOExitButton(settingsResult.isSamlSSOEnabled)
      }
    })
    window.onscroll = () => {
      setShowScrollArrow(false)
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    var currentDomain = window.location.host
    const key1 = "https://";
    const key2 = "http://";
    const key3 = ".com";
    const idx1 = currentDomain.indexOf(key1);
    const idx2 = currentDomain.indexOf(key2);
    const idx3 = currentDomain.indexOf(key3);
    currentDomain = (idx1 !== -1 && idx3 !== -1) ? currentDomain.substring(idx1 + key1.length, idx3 + key3.length) : 
                      ((idx2 !== -1 && idx3 !== -1) ? currentDomain.substring(idx2 + key2.length, idx3 + key3.length) : currentDomain);
    currentDomain = (window.location.host.toLowerCase().indexOf("localhost") !== -1 || 
                      (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomain) !== -1 && 
                        window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ? 
                        oidcConfig.stagingdomain : 
                        ((window.location.host.toLowerCase().indexOf("localhost") !== -1 || 
                          (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomainSA) !== -1 && 
                            window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ? 
                            oidcConfig.stagingdomainSA : 
                            ((window.location.host.toLowerCase().indexOf("localhost") !== -1 || 
                              (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomainForDeactTest) !== -1 && 
                                window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ? 
                                oidcConfig.stagingdomainForDeactTest : 
                                  currentDomain.toLowerCase().replace("altlogin-", ""))) /* To support the non-sso users of SSO orgs */
    
    if (!!superAdminSelectedBasicOrgData && superAdminSelectedBasicOrgData.orgID > 0 && !!superAdminSelectedBasicOrgData.domainName) {
      LoadOrgConfigBasedOnGivenDomain(currentDomain, currentDomain, true);
    }
    else {
      LoadOrgConfigBasedOnGivenDomain(((window.location.host.toLowerCase().indexOf("localhost") !== -1 || 
                                        (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomain) !== -1 && 
                                          window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ?   
                                            oidcConfig.stagingdomain : 
                                            ((window.location.host.toLowerCase().indexOf("localhost") !== -1 || 
                                              (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomainSA) !== -1 && 
                                                window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ?   
                                                  oidcConfig.stagingdomainSA : 
                                                    ((window.location.host.toLowerCase().indexOf("localhost") !== -1 || 
                                                      (window.location.host.toLowerCase().indexOf(oidcConfig.stagingdomainForDeactTest) !== -1 && 
                                                        window.location.host.toLowerCase().indexOf("altlogin-") < 0)) ?   
                                                          oidcConfig.stagingdomainForDeactTest : window.location.host))),
                                      currentDomain);
    }
  }, [superAdminSelectedBasicOrgData])

  if (loading || languageLoading) {
    return <LoaderContainer/>
  } else {
    return (
      <>
        <TopNavbar />
        <div style={{display: "flex"}}>
            <div>
                <SideMenu />
            </div>
            <div style={{flex: "1"}}> 
              <MainPanel>
                <CPDPanelProvider>
                  <CourseProgressPanelProvider>
                    <SessionProvider>
                    <InfopathProvider>
                      <MyTrainingListProvider>
                        <Switch>
                        <Route path="/authentication/not-authenticated" component={ErrorPage} />
                          <Route path="/index" component={Dashboard} />
                          <Route path="/" exact component={Dashboard}></Route>
                          <Route path="/mydashboard" exact component={Dashboard}></Route>
                          <Route
                            path="/mytraining"
                            exact
                            component={MyTraining}
                          ></Route>
                          <Route
                            path={`/mytraining/:courseId`}
                            exact
                            component={LearningModuleList}
                          ></Route>
                          <Route
                            path={`/mytraining/:courseId/quiz/:moduleId/:ticks`}
                            exact
                            component={Quiz}
                          ></Route>
                          <Route
                            path={`/mytraining/:courseId/quiz/summary`}
                            exact
                            component={QuizSummary}
                          ></Route>
                          <Route
                            path={`/mytraining/:courseId/lesson`}
                            exact
                            component={Lesson}
                          ></Route>
                          <Route
                            path={`/mytraining/:courseId/quickfacts`}
                            exact
                            component={QuickfactsWrapper}
                          ></Route>
                          <Route
                            path={`/mytraining/:courseId/certificate`}
                            exact
                            component={CertificateWrapper}
                          ></Route>
                          <Route
                            path={`/mytraining/:policyId/policy`}
                            exact
                            component={PolicyWrapper}
                          ></Route>
                          <Route path={`/myreport`} exact component={Report}></Route>
                          <Route
                            path={`/myreport/:moduleId/history`}
                            exact
                            component={QuizHistory}
                          ></Route>
                          <Route
                            path={`/currentadminreport/:moduleId/:userID/quizhistory`}
                            exact
                            component={CurrentAdminReportQuizHistory}
                          ></Route>
                          <Route
                            path={`/myreport/:moduleId/history/summary`}
                            exact
                            component={QuizSummary}
                          ></Route>
                          <Route
                            path={`/accountsettings`}
                            exact
                            component={AccountSettings}
                          ></Route>
                          <Route
                            path={`/help`}
                            exact
                            component={HelpViewerWrapper}
                          ></Route>
                          <Route
                            path={`/loggedout`}
                            exact
                            component={SSOExitPage}
                          ></Route>
                          {
                            (showUnitAdminUI || showOrgAdminUI || showSuperAdminUI) &&
                            <Route
                              path={`/theme`}
                              exact
                              component={ThemePage} // Page will 404 if user has the incorrect permissions
                            ></Route>
                          }                             
                          {
                            (!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                            (showOrgAdminUI || showSuperAdminUI) &&
                            <>
                              <Route 
                                path="/organisationDeactivation" 
                                exact 
                                component={OrganisationDeactivation}
                              ></Route>
                              <Route
                              path={`/accountmanagers`}
                              exact
                              component={AccountManagers}
                              ></Route>
                              <Route
                              path={`/organisationapplicationtemplate`}
                              exact
                              component={OrganisationApplicationtemplate}
                              ></Route>
                            </>
                          }
                          {
                            (showUnitAdminUI || showOrgAdminUI || showSuperAdminUI) &&
                           <Route
                          path={`/orgemailtemplates`}
                          exact
                          component={OrgEmailTemplates}
                          ></Route>} 
                           {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/warningreport`}
                            exact
                            component={WarningReport}
                          ></Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/warningreport/result`}
                            exact
                            component={WarningReportResult}
                          ></Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/addaccount`}
                            exact
                            component={AddAccount}
                          ></Route>}
                            {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/addunit`}
                            exact
                            component={AddUnit}
                          ></Route>}
                          {                            
								            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                            (showOrgAdminUI || showSuperAdminUI) &&
                              <Route
                                path={`/moveunit`}
                                exact
                                component={MoveUnit}
                              ></Route>
                          }
                          {                            
								            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                            (showOrgAdminUI || showSuperAdminUI) &&
                              <Route
                                path={`/moveunitdnd`}
                                exact
                                component={MoveUnitWithDND}
                              ></Route>
                          }
                           {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/moveaccountstounit`}
                            exact
                            component={MoveAccountsToUnit}
                          ></Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/moveunit`}
                            exact
                            component={MoveUnit}
                          ></Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/unitDetails/:unitIdToEdit`}
                            exact
                            component={UnitDetailsTabView}
                          ></Route>}
                           {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/orgemailrulesadd/:courseIdToUpdate/:remescIdToUpdate`}
                            exact
                            component={OrgReminderEmailRulesAdd}
                          ></Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/policybuilderdetails/:policyIdToEdit`}
                            exact
                            component={PolicyBuilderDetails}
                          ></Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/cpdprofile/:cpdProfileIdToEdit/:cpdProfilePeriodIdToEdit`}
                            exact
                            component={CPDProfileDetails}
                          ></Route>}
                          {/*//uncomment if need to redirect specific tab 
                           <Route
                            path={`/unitDetails/:unitIdToEdit/:userIdToEdit`}
                            exact
                            component={UnitDetailsTabView}
                          ></Route> */}
                           {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/unitmanagement`}
                            exact
                            component={UnitManagement}
                          ></Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/accountDetails/:userIdToEdit`}
                            exact
                            component={AccountDetailsTabView}
                          ></Route> }                       
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/accountmanagement`}
                            exact
                            component={AccountManagement}
                          ></Route>}
                           {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/archiveaccounts`}
                            exact
                            component={ArchiveAccounts}
                          ></Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/ImportAccounts`}
                            exact
                            component={ImportAccounts}
                          ></Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/atriskreport`}
                            exact
                            component={AtRiskReport}
                          ></Route>}
                           {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/atriskreport/result`}
                            exact
                            component={AtRiskReportResult}
                          ></Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/currentadminreport`}
                            exact
                            component={CurrentAdminReport}
                          ></Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/currentadminreport/result`}
                            exact
                            component={CurrentAdminReportResult}
                          ></Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/coursestatusreport`}
                          exact
                          component={CourseStatusReport}
                        >
                          </Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/coursestatusreport/result`}
                            exact
                            component={CourseStatusReportResult}
                          ></Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/progressreport`}
                          exact
                          component={ProgressReport}
                        >
                          </Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/progressreport/result`}
                            exact
                            component={ProgressReportResult}
                          ></Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/quizhistoryreport`}
                          exact
                          component={QuizHistoryReport}
                        >
                          </Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/quizhistoryreport/result`}
                            exact
                            component={QuizHistoryReportResult}
                          ></Route>}
                        {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/sentemailreport`}
                          exact
                          component={SentEmailReport}
                        >
                          </Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/sentemailreport/result`}
                            exact
                            component={SentEmailReportResult}
                          ></Route>}                                   
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/summaryreport`}
                          exact
                          component={SummaryReport}
                          ></Route>}   
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/emailreport`}
                          exact
                          component={EmailReport}
                          ></Route>}  
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/userdetailreport`}
                          exact
                          component={UserDetailReport}
                          ></Route>} 
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/licensingreport`}
                          exact
                          component={LicensingReport}
                          ></Route>}  
                            {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/licensingreport/result`}
                          exact
                          component={LicensingReportResult}
                          ></Route>}  
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route 
                          path={`/userdetailreport/result`}
                          exact
                          component={UserDetailReportResult}>
                            </Route>}      
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/unitpathwayreport`}
                          exact
                          component={UnitPathwayReport}
                          ></Route>}  
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route 
                          path={`/unitpathwayreport/result`}
                          exact
                          component={UnitPathwayReportResult}>
                          </Route>}   
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/unitcompliancereport`}
                          exact
                          component={UnitComplianceReport}
                          ></Route>}  
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route 
                          path={`/unitcompliancereport/result`}
                          exact
                          component={UnitComplianceReportResult}>
                          </Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                              path={`/cpdreport`}
                              exact
                              component={IsCPDEnabled ? CPDReport : ErrorPage}
                          ></Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                              path={`/cpdreport/result`}
                              exact
                              component={IsCPDEnabled ? CPDReportResult : ErrorPage}>
                          </Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/unitadministratorreport`}
                          exact
                          component={UnitAdministratorReport}
                          ></Route>}  
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route 
                          path={`/unitadministratorreport/result`}
                          exact
                          component={UnitAdministratorReportResult}>
                          </Route>}               
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/testlargedata`}
                          exact
                          component={TestLargeData}
                          ></Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/summaryreport/result`}
                            exact
                            component={SummaryReportResult}
                          ></Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/reportschedulerlist`}
                          exact
                          component={ReportSchedulerList}
                          ></Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/:report/reportscheduler/:scheduleIdToEdit`}
                            exact
                            component={ReportScheduler}
                          ></Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/policybuilderreport`}
                          exact
                          component={IsPolicyEnabled ? PolicyBuilderReport : ErrorPage}
                          ></Route>} 
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/orgemailrules`}
                          exact
                          component={ReminderEmailRulesTabView}
                          ></Route>} 
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/organisationadmin`}
                          exact
                          component={OrganisationAdministrators}
                          ></Route>} 
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                            path={`/organisationadmindetail`}
                            exact
                            component={OrganisationAdministratordetails}
                          ></Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/orgemailrulesadd`}
                          exact
                          component={OrgReminderEmailRulesAdd}
                          ></Route>}
                           {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/links`}
                          exact
                          component={Links}
                          ></Route>} 
                           {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/mycertificates`}
                          exact
                          component={IsExtCertEnabled ? MyExternalCertificate : ErrorPage}
                          ></Route>} 
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route 
                          path={`/policybuilderreport/result`}
                          exact
                          component={IsPolicyEnabled ? PolicyBuilderReportResult : ErrorPage}>
                          </Route>}
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/externalcertificates`}
                          exact
                          component={IsExtCertEnabled ? ExternalCertificates : ErrorPage}
                          ></Route>} 
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/externalcertificatedetails/:externalCertificateId`}
                          exact
                          component={IsExtCertEnabled ? ExternalCertificateDetails : ErrorPage}
                          ></Route>} 
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route
                          path={`/externalcertificatesreport`}
                          exact
                          component={IsExtCertEnabled ? ExternalCertificatesReport : ErrorPage}
                          ></Route>} 
                           {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                           <Route 
                          path={`/externalcertificatesreport/result`}
                          exact
                          component={IsPolicyEnabled ? ExternalCertificatesReportResult : ErrorPage}>
                          </Route>} 
                          {
                            !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
                            <>
                            {
                              showSupportDetailsRoute()
                            }
                            {
                              showModifyLinksRoute()
                            }
                            {
                              showAssignCoursesRoute()
                            }
                            {
                              showTipVideosRoute()
                            }
                            {
                              showTipVideosSuperAdminRoute()
                            }
                            {
                              showManageTipVideosRoute()
                            }
                            {
                              showManageTipVideosSuperAdminRoute()
                            }
                            {
                              showOrgManagementRoute()
                            }                       
                            {
                              showSingleSignOnRoute()
                            }
                            {
                              showComplianceRulesRoute()
                            }
                            {
                              showConfigurationItemsRoute()
                            }
                            {
                              showCPDProfilesListRoute()
                            }
                            {
                              showPolicyBuilderListRoute()
                            }
                            </>
                          }
                          <Route path="/timeout" exact>
                            <ErrorPage errorMessage="Our severs are a little slow today. Please navigate to the My Training page and try again." />
                          </Route>
                          <Route>
                            <ErrorPage errorMessage="404 - Sorry we cant find that page" />
                          </Route>
                        </Switch>
                      </MyTrainingListProvider>
                      </InfopathProvider>
                    </SessionProvider>
                  </CourseProgressPanelProvider>
                </CPDPanelProvider>
              </MainPanel>
              <BouncingArrow/>
            </div>       
        </div>  
        {/* <ScreenOrientation/> */}
      </>
    );
  }
  
};

export default Layout;

//////////////////////
//  Original Code   //
//////////////////////

// import React from 'react';
// import { AuthenticationContext } from '@axa-fr/react-oidc-context';
// import { Link } from 'react-router-dom';

// const headerStyle = {
//   display: 'flex',
//   backgroundColor: '#26c6da',
//   justifyContent: 'space-between',
//   padding: 10,
// };

// const linkStyle = {
//   color: 'white',
//   textDecoration: 'underline',
// };

// export default () => (
//   <header>
//     <AuthenticationContext.Consumer>
//       {props => {
//         return (
//           <div style={headerStyle}>
//             <h3>
//               <Link style={linkStyle} to="/">
//                 HOME
//               </Link>
//             </h3>

//             {props.oidcUser || !props.isEnabled ? (
//               <ul>
//                 <li>
//                   <Link style={linkStyle} to="/dashboard">
//                     Dashboard
//                   </Link>
//                 </li>
//                 <li>
//                   <Link style={linkStyle} to="/admin">
//                     Admin
//                   </Link>
//                 </li>
//                 <li>
//                   <Link style={linkStyle} to="/protected1">
//                     Direct Protected
//                   </Link>
//                 </li>
//                 <li>
//                   <Link style={linkStyle} to="/protected2">
//                     HOC Protected
//                   </Link>
//                 </li>
//                 <button onClick={props.logout}>logout</button>
//               </ul>
//             ) : (
//               <button onClick={props.login}>login</button>
//             )}
//           </div>
//         );
//       }}
//     </AuthenticationContext.Consumer>
//   </header>
// );
