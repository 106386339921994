import React from 'react';

export const UnitComplianceIconMenu = () => (
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 2.57568H6C5.46957 2.57568 4.96086 2.7864 4.58579 3.16147C4.21071 3.53654 4 4.04525 4 4.57568V20.5757C4 21.1061 4.21071 21.6148 4.58579 21.9899C4.96086 22.365 5.46957 22.5757 6 22.5757H18C18.5304 22.5757 19.0391 22.365 19.4142 21.9899C19.7893 21.6148 20 21.1061 20 20.5757V9.57568L13 2.57568Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13 2.57568V9.57568H20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.9919 13.8087L10.7764 17.9965L8.93647 16.1527" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);

export default UnitComplianceIconMenu
