import React, { useContext, useEffect, useRef } from 'react'
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { MyTrainingListContext } from '../../contexts/MyTrainingListContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Components
import CourseIcon from '../../Components/CourseIcon'
import ToggleLeftIcon from '../../CustomIcons/ToggleLeftIcon'
import MyTrainingListActionButtons from './MyTrainingListActionButtons';
//MUI Components
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
//Styles
import styles from '../../styles/courseList.module.css'
import { CircularProgress } from '@material-ui/core';

import loadingGif from '../../CustomIcons/GIFs/loading_dots_blue.gif';

enum StatusEnum { //Typescript doesnt allow this to be exported from global type files unfortunatley
    //Course Enums
    None = -1,
    Unassigned = 0,
    Incomplete = 1,
    Complete = 2,
    //Policy Emums
    NotAccepted = 4,
    Accepted = 5,
    'Not Accepted' = NotAccepted // Creating alias
  }

interface Props {
    logNavigation: (message: string) => void;
}

const FilteredMyTrainingList = ({ logNavigation }: Props) => {
    //const [ windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    const { showCPD, showLastPassDate, myTrainingSelection } = useContext(UIContext)
    const { myTrainingList } = useContext(MyTrainingListContext)
    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
    const screenSize = useRef<number>(0);

    const getStatusCSSClass = (status: StatusEnum) => {
        if (StatusEnum[status] === "Complete" || StatusEnum[status] === "Accepted") {
            return styles.complete
        } 
    }   

    const getStatusTranslation = (status: string) => {
        switch(status) {
            case "Complete":
            return allResourcesToTranslations.complete
            case "Accepted":
            return allResourcesToTranslations.accepted
            case "Incomplete":
            return allResourcesToTranslations.incomplete
            case "Not Accepted":
            return allResourcesToTranslations.notaccepted
            case "Unassinged":
            return allResourcesToTranslations.unassigned
            default:
            return ""
        }
    }

    useEffect(() => {
        window.addEventListener("resize", () => {
            screenSize.current = window.innerWidth;
        });
        return () => {
            window.removeEventListener("resize", () => {
                screenSize.current = window.innerWidth;
            })
        }
    }, []);

    // useEffect(() => {
    //   setWindowWidth(window.innerWidth);
    // }, [window.innerWidth]);

    if (myTrainingList != null && Array.isArray(myTrainingList)) {
        let filteredTrainingListItems = myTrainingList;
        if (myTrainingSelection !== "All") {
          filteredTrainingListItems = myTrainingList.filter((tli) => {
            return tli.type === myTrainingSelection;
          });
        }
  
        const getFirstPolicyIndex = () => { //For coloring the devider between Course and Policy
          let index = -1
          if (myTrainingSelection === "All") {
            for (let i = 1; i < filteredTrainingListItems.length; i++) {
              if (filteredTrainingListItems[i].type === "Policy" && filteredTrainingListItems[i - 1].type === "Course") {
                index = i;
              } 
            }
          }
          return index;
        }

        return (
          <TableBody>
            {
                filteredTrainingListItems.map((filteredTrainingListItem, i) => {                    
                    const { type, id, name, lastCompletionDate, isColorRed, due, status, pointsAvailable, pointsCalculated, coursePublishType, oneTimeCourseCompletion, isCPDReady } = filteredTrainingListItem
                    const firstPolicyIndex = getFirstPolicyIndex()
                    return (
                      
                    <TableRow key={`${type}-${id}`} className={ firstPolicyIndex === i ? `${styles.divider} list ` : "list" }>
                        <TableCell align="left">                     
                        <div className={styles.courseTitleContainer}>                                                       
                            <CourseIcon iconType={type} IsOneTime={oneTimeCourseCompletion} CoursePublishType={coursePublishType} />
                            <span className={styles.courseName}>{name}</span>
                        </div>
                        </TableCell>
                        {/* {showCPD ? <TableCell align="center">{isCPDReady ? (((pointsAvailable - pointsCalculated) < 0.0) ? 0.0 : (pointsAvailable - pointsCalculated)).toFixed(2) : <><div id={"loadingGif" + `${type}-${id}` } className="loadingGif"></div></>}</TableCell> : null } */}
                        {/* {showCPD ? <TableCell align="center">{isCPDReady ? (((pointsAvailable - pointsCalculated) < 0.0) ? 0.0 : (pointsAvailable - pointsCalculated)).toFixed(2) : <nav  style={{width:"24px", height: "24px"}}><CircularProgress id={"loading_" + `${type}-${id}` }  /></nav>}</TableCell> : null } */}
                        {/* { showCPD ? <TableCell align="center">{isCPDReady ? (((pointsAvailable - pointsCalculated) < 0.0) ? 0.0 : (pointsAvailable - pointsCalculated)).toFixed(2) : <nav  style={{width:"40px", height: "40px"}}><img src={loadingGif} id={"loading_" + `${type}-${id}` } style={{width:"40px", height: "40px", marginLeft: "15px"}}  /></nav>}</TableCell> : null } */}
                        { showCPD ? <TableCell align="center">{isCPDReady ? (((pointsAvailable - pointsCalculated) < 0.0) ? 0.0 : (pointsAvailable - pointsCalculated)).toFixed(2) : <div style={{alignContent: "center"}}><img src={loadingGif} id={"loading_" + `${type}-${id}` } style={{width:"40px", height: "40px", marginLeft: screenSize.current <= 1000 ? "0px" : "20px"}}  /></div>}</TableCell> : null }
                        { showLastPassDate ? <TableCell align="center">{lastCompletionDate}</TableCell> : null }
                        { showLastPassDate ? <TableCell align="center" style={{ color: isColorRed ? "red" : "black" }}>{due}</TableCell> : null }
                        <TableCell align="center">
                        <span className={getStatusCSSClass(status) }>
                            {getStatusTranslation(StatusEnum[status]) }
                        </span>
                        </TableCell>
                        <TableCell align="center" className="action">
                            <MyTrainingListActionButtons
                                trainingListItem={filteredTrainingListItem}
                                logNavigation={logNavigation}
                            />
                        </TableCell>
                    </TableRow>
                    );
                })
            }
          </TableBody>
        );
      } else {
          return null
      }
}

export default FilteredMyTrainingList
