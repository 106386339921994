import axios from 'axios';
import { addError } from '../../AddError';

const saveUserMultiCourseModuleAccess = async (token: string, adminuserId: number, orgDomain: string, orgId:number, userId: number, accessChangedCourseIDs: string, grantedModuleIDs: string) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/administration/SaveUserMultiCourseModuleAccess`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        "RequestingUserID": adminuserId,
        "OrganisationID": orgId,
        "UserID" : userId,
        "AccessChangedCourseIDs":accessChangedCourseIDs,
        "GrantedModuleIDs":grantedModuleIDs
    },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "SaveUserMultiCourseModuleAccess.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "SaveUserMultiCourseModuleAccess.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "SaveUserMultiCourseModuleAccess.ts", 1)
    }
    return null;
  }
};

export default saveUserMultiCourseModuleAccess;