import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
// Contexts
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { InfopathContext } from '../../contexts/InfopathContext';

interface Props {
  handleExit: () => void
}

export const InfopathButtonPanel = ({handleExit}:Props): JSX.Element => {
  const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
  const { quizStillInProgress, hasLoadedSessionData, ipQuizSubmissionStillInProgress } = useContext(InfopathContext)
  const history = useHistory();

  const handleHomeNavigation = () => {
    if (!quizStillInProgress || !ipQuizSubmissionStillInProgress || hasLoadedSessionData) {
      history.push('/')
    }
  }

  return (
    <>
      <Button variant="outlined" 
          color="primary"
          disabled={ quizStillInProgress || ipQuizSubmissionStillInProgress || !hasLoadedSessionData }
          onClick={handleHomeNavigation}>
        { allResourcesToTranslations.home }
      </Button>
      <Button
          onClick={() => handleExit() }
          variant="outlined"
          color="primary"
          disabled={quizStillInProgress || ipQuizSubmissionStillInProgress || !hasLoadedSessionData }>
          { allResourcesToTranslations.exit }
      </Button>
    </>
  );
};

export default InfopathButtonPanel;
