import React, { useState, createContext } from "react";

interface ISessionContext {
  curSessionID: string;
  setCurSessionID: (curSessionId: string) => void;
  curBookmark: string;
  setCurBookmark: (curBookmark: string) => void;
  curQuizPassmark: number;
  setCurQuizPassmark: (curQuizPassmark: number) => void;
  curCustomCourseProgress: number;
  setCurCustomCourseProgress: (curCustomCourseProgress: number) => void;
  curBrowserTabUniqueID: string;
  setCurBrowserTabUniqueID: (curBrowserTabUniqueId: string) => void;
  curSelectedModuleID: number;
  setCurSelectedModuleID: (curSelectedModuleID: number) => void;
  curCourseName: string;
  setCurCourseName: (curCourseName: string) => void;
  isCurSessionLesson: boolean;
  setIsCurSessionLesson: (isCurSessionLesson: boolean) => void;
  multiSectionCurrentSelection: string;
  setMultiSectionCurrentSelection: (multiSectionCurrentSelection: string) => void;
  multiSectionCurrentSelectionOnQuizOpen: string;
  setMultiSectionCurrentSelectionOnQuizOpen: (multiSectionCurrentSelectionOnQuizOpen: string) => void;
  multiSectionSessionDetails: string;
  setMultiSectionSessionDetails: (multiSectionSessionDetails: string) => void;
  currentCourseID: number;
  setCurrentCourseID: (currentCourseID: number) => void;
  curSelectedModuleTechType: string;
  setCurSelectedModuleTechType: (curSelectedModuleTechType: string) => void;
  showResultsModal: boolean;
  setShowResultsModal: (showScoreModal: boolean) => void;
  // quizStillInProgress: boolean;
  // setQuizStillInProgress: (quizStillInProgress: boolean) => void;
  saReviewOnlyValue: number;
  setSaReviewOnlyValue: (saReviewOnlyValue: number) => void;
  saIsRestarted: number;
  setSaIsRestarted: (saIsRestarted: number) => void;
}

export const SessionContext = createContext<ISessionContext>({ //Setting the inital state  for context
    curSessionID: "",
    setCurSessionID: () => {},
    curBookmark: "",
    setCurBookmark: () => {},
    curQuizPassmark: 80,
    setCurQuizPassmark: () => {},
    curCustomCourseProgress: 80,
    setCurCustomCourseProgress: () => {},
    curBrowserTabUniqueID: "",
    setCurBrowserTabUniqueID: () => {},
    curSelectedModuleID: 0,
    setCurSelectedModuleID: () => {},
    curCourseName: "",
    setCurCourseName: () => {},
    isCurSessionLesson: false,
    setIsCurSessionLesson: () => { },
    multiSectionCurrentSelection: "Section 1",
    setMultiSectionCurrentSelection: () => { },
    multiSectionCurrentSelectionOnQuizOpen: "Section 1",
    setMultiSectionCurrentSelectionOnQuizOpen: () => { },
    multiSectionSessionDetails: "",
    setMultiSectionSessionDetails: () => { },
    currentCourseID: 0,
    setCurrentCourseID: () => { },
    curSelectedModuleTechType: "",
    setCurSelectedModuleTechType: () => { },
    showResultsModal: false,
    setShowResultsModal: () => {},
    // quizStillInProgress: false,
    // setQuizStillInProgress: () => {},
    saReviewOnlyValue: -1,
    setSaReviewOnlyValue: () => { },
    saIsRestarted: -1,
    setSaIsRestarted: () => { },
});

export const SessionProvider = (props: any): JSX.Element => {
  const [curSessionID, setCurSessionID] = useState<string>("") // current session ID
  const [curBookmark, setCurBookmark] = useState<string>("") // current bookmark from quiz 
  const [curQuizPassmark, setCurQuizPassmark] = useState<number>(80) // current bookmark from quiz 
  const [curCustomCourseProgress, setCurCustomCourseProgress] = useState<number>(-1) // current bookmark from quiz 
  const [curBrowserTabUniqueID, setCurBrowserTabUniqueID] = useState<string>("") // current session ID
  const [curSelectedModuleID, setCurSelectedModuleID] = useState<number>(-1) // selected module ID
  const [curCourseName, setCurCourseName] = useState("") // current course name
  const [isCurSessionLesson, setIsCurSessionLesson] = useState(false) // Is current session lesson?
  const [multiSectionCurrentSelection, setMultiSectionCurrentSelection] = useState("Section 1") // Is current session with mult section and its current section
  const [multiSectionCurrentSelectionOnQuizOpen, setMultiSectionCurrentSelectionOnQuizOpen] = useState("Section 1") // Is current session with mult section and its current section 
  const [multiSectionSessionDetails, setMultiSectionSessionDetails] = useState("") // Is current session with mult section and its current section 
  const [currentCourseID, setCurrentCourseID] = useState<number>(0) // selected current course ID
  const [curSelectedModuleTechType, setCurSelectedModuleTechType] = useState<string>("") // current Selected Module Tech Type  (Lectora/SA/Infopath)
  const [showResultsModal, setShowResultsModal] = useState<boolean>(false)
  //const [quizStillInProgress, setQuizStillInProgress] = useState<boolean>(false)
  const [saReviewOnlyValue, setSaReviewOnlyValue] = useState<number>(-1)
  const [saIsRestarted, setSaIsRestarted] = useState<number>(-1)

  return (
    <SessionContext.Provider
      value={{
        curSessionID,
        setCurSessionID,
        curBookmark,
        setCurBookmark,
        curQuizPassmark,
        setCurQuizPassmark,
        curCustomCourseProgress,
        setCurCustomCourseProgress,
        curBrowserTabUniqueID,
        setCurBrowserTabUniqueID,
        curSelectedModuleID,
        setCurSelectedModuleID,
        curCourseName,
        setCurCourseName,
        isCurSessionLesson,
        setIsCurSessionLesson,
        multiSectionCurrentSelection,
        setMultiSectionCurrentSelection,
        multiSectionCurrentSelectionOnQuizOpen,
        setMultiSectionCurrentSelectionOnQuizOpen,
        multiSectionSessionDetails,
        setMultiSectionSessionDetails,
        currentCourseID,
        setCurrentCourseID,
        curSelectedModuleTechType,
        setCurSelectedModuleTechType,
        showResultsModal,
        setShowResultsModal,
        // quizStillInProgress,
        // setQuizStillInProgress,
        saReviewOnlyValue,
        setSaReviewOnlyValue,
        saIsRestarted,
        setSaIsRestarted
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
}; 