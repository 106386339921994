import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const AddUserIcon = () => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M10.6154 0C16.478 0 21.2308 4.7528 21.2308 10.6154C21.2308 11.4292 21.1392 12.2216 20.9658 12.9828C20.413 12.6363 19.804 12.3711 19.1557 12.204C19.2509 11.689 19.3007 11.158 19.3007 10.6154C19.3007 5.8182 15.4126 1.93007 10.6154 1.93007C5.8182 1.93007 1.93007 5.8182 1.93007 10.6154C1.93007 12.5956 2.59304 14.4215 3.70959 15.8826C5.299 13.8512 7.77336 12.5455 10.5536 12.5455C11.7075 12.5455 12.8089 12.7705 13.8162 13.1792C13.2247 13.5967 12.7065 14.1112 12.2845 14.6994C11.7321 14.5533 11.152 14.4755 10.5536 14.4755C8.29158 14.4755 6.28914 15.5873 5.06258 17.2944C6.56803 18.547 8.50389 19.3007 10.6154 19.3007C10.7878 19.3007 10.9591 19.2957 11.129 19.2858C11.2141 19.954 11.4012 20.5903 11.6743 21.1786C11.326 21.2131 10.9727 21.2308 10.6154 21.2308C4.7528 21.2308 0 16.478 0 10.6154C0 4.7528 4.7528 0 10.6154 0ZM6.75545 7.71944C6.75545 9.8512 8.48382 11.5796 10.6156 11.5796C12.7473 11.5796 14.4757 9.8512 14.4757 7.71944C14.4757 5.58768 12.7473 3.8593 10.6156 3.8593C8.48382 3.8593 6.75545 5.58768 6.75545 7.71944ZM8.68552 7.71944C8.68552 8.7858 9.54922 9.64951 10.6156 9.64951C11.682 9.64951 12.5457 8.7858 12.5457 7.71944C12.5457 6.65308 11.682 5.78937 10.6156 5.78937C9.54922 5.78937 8.68552 6.65308 8.68552 7.71944Z" fill="#FFFFFF"/>
<circle cx="17.5385" cy="18.4616" r="5.03846" stroke="#FFFFFF"/>
<rect x="17.0769" y="16.6154" width="0.923077" height="3.69231" fill="#FFFFFF"/>
<rect x="19.3846" y="18" width="0.923077" height="3.69231" transform="rotate(90 19.3846 18)" fill="#FFFFFF"/>
</svg>    
);

export default AddUserIcon
