import React, { useState, useRef, useContext, useEffect } from "react";
import styles from "../../../styles/organisationmanagement.module.css";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { UIContext } from "../../../contexts/UIContext";
import LoaderContainer from "../../../Components/LoaderContainer";
import { Steps } from "intro.js-react";
import CustomTooltip from "../../../Components/CustomTooltip";
import GuideIcon from "../../../CustomIcons/GuideIcon";
import UnbrandedCard from "../../../Components/UnbrandedCard";
import { Button, Checkbox, CircularProgress, TextField } from "@material-ui/core";
import SaveIcon from "../../../CustomIcons/SaveIcon";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";
import PDFFileIcon from "../../../CustomIcons/PDFFileIcon";
import getOrgSamlSSOSettings from "../../../API/GetOrgSamlSSOSettings";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import serialToArray from "../../../functions/serialToArray";
import addError from "../../../API/AddError";
import GetOrgSamlDetails from "../../../API/Administration/Organisation/GetOrgSamlDetails";
import SaveOrgSamlDetails from "../../../API/Administration/Organisation/SaveOrgSamlDetails";
import GetSAMLSetupGuide from "../../../API/Administration/Organisation/GetSAMLSetupGuide";
import SSOGoogleIcon from "../../../CustomIcons/Administration/SSOGoogleIcon";
import SSOAzureIcon from "../../../CustomIcons/Administration/SSOAzureIcon";
import SSOOktaIcon from "../../../CustomIcons/Administration/SSOOktaIcon";
import SSOMSIcon from "../../../CustomIcons/Administration/SSOMSIcon";

const SingleSignOn = () => {
	const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext);
	const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection, IsEbookEnabled, EnableActivityLogs, timeZoneId } = useContext(UIContext);
	const { oidcUser } = useReactOidc();
	const {
		profile: { user_id, website,  },
	} = oidcUser || {};
	const userId = user_id ? parseInt(user_id) : 0;
	const orgId = oidcUser.profile.organisation_id ?? -1;
	const orgDomain = website
		? website
			.toLowerCase()
			.replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
		: "";

	const [enablesso, setEnablesso] = useState<boolean>(true);
	const [samlcertificatekey, setSamlcertificatekey] = useState<string>('');
	const [samlcertificatekeyadmin, setSamlCertificatekeyadmin] = useState<string>('');
	const [samlconsumerendpoint, setSamlconsumerendpoint] = useState<string>('');
	const [samlproviderendpoint, setSamlproviderendpoint] = useState<string>('');
	const [samlproviderendpointadmin, setSamlproviderendpointadmin] = useState<string>('');

	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [saveSuccess, setSaveSuccess] = React.useState(false);
	const [modalPopupMessage, setModalPopupMessage] = useState('');
	const [hasErrored, setHasErrored] = useState<boolean>(false);

	const [intro, setIntro] = useState({
		stepsEnabled: false,
		initialStep: 0,
		steps: [
			{
				element: ".shibbolethguideme",
				title: allResourcesToTranslations.ssosetupguides,
				intro: allResourcesToTranslations.shibbolethguideme,
				
			},
			{
				element: ".oktassosetupguides",
				title: allResourcesToTranslations.oktassosetupguides,
				intro: allResourcesToTranslations.oktassosetupguideme,
			},
			{
				element: ".azureadssosetupguides",
				title: allResourcesToTranslations.azureadssosetupguides,
				intro: allResourcesToTranslations.azureadssosetupguideme,
			},
			{
				element: ".adfsssosetupguides",
				title: allResourcesToTranslations.adfsssosetupguides,
				intro: allResourcesToTranslations.adfsssosetupguideme,
			},
			{
				element: ".googlessosetupguides",
				title: allResourcesToTranslations.googlessosetupguides,
				intro: allResourcesToTranslations.googlesssosetupguideme,
			}, 
			{
				element: ".enablesso",
				title: allResourcesToTranslations.enablesso,
				intro: allResourcesToTranslations.enablessoguideme,
			},
			{
				element: ".consumerEndPoint",
				title: allResourcesToTranslations.consumerEndPoint,
				intro: allResourcesToTranslations.samlconsumerendpointguideme,
			},
			{
				element: ".samlcertificatekey",
				title: allResourcesToTranslations.samlcertificatekey,
				intro: allResourcesToTranslations.samlcertificatekeyguideme,
			},
			{
				element: ".samlproviderendpoint",
				title: allResourcesToTranslations.samlproviderendpoint,
				intro: allResourcesToTranslations.samlproviderendpointguideme,
			},
			{
				element: ".samlcertificatekeyadmin",
				title: allResourcesToTranslations.samlcertificatekeyadmin,
				intro: allResourcesToTranslations.samlcertificatekeyadminguideme,
			},
			{
				element: ".samlproviderendpointadmin",
				title: allResourcesToTranslations.samlproviderendpointadmin,
				intro: allResourcesToTranslations.samlproviderendpointadminguideme,
			},
			{
				element: ".saveData",
				title: "Save",
				intro: "Click here to save all changes",
			}
		],
	});

	const onExit = () => {
		setIntro({ ...intro, stepsEnabled: false });
	};

	const handleClosePopup = () => {
		setShowModal(false);
	};

	useEffect(() => {
		setTitle(allResourcesToTranslations.singlesignon);
		setTitleEnglish(pageTitles.singlesignon);
		updateBackButtonRoute("");
		setLoading(true);
		setMenuSelection("Support Details");
		GetOrgSamlDetails(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((result) => {
			if (result.isSuccess && result.error == '') {
				if (result.orgSamlDetail !== null) {
					setSamlconsumerendpoint(result.orgSamlDetail.samlConsumerEndPoint);
					setSamlcertificatekey(result.orgSamlDetail.samlCertificate === null ? "" : result.orgSamlDetail.samlCertificate);
					setSamlproviderendpoint(result.orgSamlDetail.samlProviderEndpoint === null ? "" : result.orgSamlDetail.samlProviderEndpoint);
					setSamlCertificatekeyadmin(result.orgSamlDetail.samlCertificateAdmin === null ? "" : result.orgSamlDetail.samlCertificateAdmin);
					setSamlproviderendpointadmin(result.orgSamlDetail.samlProviderEndpointAdmin === null ? "" : result.orgSamlDetail.samlProviderEndpointAdmin);
					setEnablesso(result.orgSamlDetail.enableSamlSSO);
				}
			}
			else {
				setShowModal(true);
				setHasErrored(true);
				setSaveSuccess(false);
				setModalPopupMessage("Can not load Organisation details!");
			}
			setLoading(false);
		});
		// at the end, return a cleanup method
		return () => { };
	}, []);

	const handleEnableSsoChange = () => {
		setEnablesso(!enablesso);
	}

	const handleSave = () => {
		setIsSaving(true);
		SaveOrgSamlDetails(oidcUser.access_token, userId, parseInt(orgId), orgDomain, samlcertificatekey, samlproviderendpoint, samlcertificatekeyadmin, samlproviderendpointadmin, enablesso).then((res) => {
				setIsSaving(false);
				if (!!res && res.isSuccess) {
					setShowModal(true);
					setHasErrored(false);
					setSaveSuccess(true);
					setModalPopupMessage("Single Sign On details have been saved successfully!");
				}
				else {
					setShowModal(true);
					setHasErrored(true);
					setSaveSuccess(false);
					setModalPopupMessage("Single Sign On details saving have been failed!");
				}
			})
	}

	const handleDownloadSetupguides = (fileType: string) => {
		GetSAMLSetupGuide(oidcUser.access_token, userId, orgDomain, parseInt(orgId), fileType).then((Res) => {
			if (Res.isSuccess) {
				const fileData = Res.fileDataOutput.data;
				let a = window.document.createElement('a');
				a.href = window.URL.createObjectURL(
					new Blob([serialToArray(atob(fileData))], {
						type: ''
					})
				);

				a.download = Res.fileDataOutput.resultFileFullName;
				document.body.appendChild(a)
				a.click();
				document.body.removeChild(a)

			}
			else if (Res.error !== undefined && Res.error !== null) {
			}
			// Activity log
			addError(oidcUser.access_token, userId, orgDomain, "Completed Download Sample SAML setup Guide in NEWSALT ClientApp", null, "OrganisationManagement.tsx", 6, EnableActivityLogs);
		})
	}
	useEffect(() => {
		if (showModal && !hasErrored) {
			setTimeout(() => {
				setShowModal(false);
				setSaveSuccess(false);
			}, 3000)
		}
	}, [showModal])

	if (loading) return <LoaderContainer />;


	return (
		<>
			<Steps
				enabled={intro.stepsEnabled}
				steps={intro.steps}
				initialStep={intro.initialStep}
				onExit={onExit}
				options={{
					nextLabel: "Next",
					prevLabel: "Back",
					tooltipClass: "sample-class",
					showBullets: true,
				}}
			/>
			<CustomTooltip displayText={["Guide Me"]}>
				<span
					onClick={() => setIntro({ ...intro, stepsEnabled: true })}
					className={styles.guideMeButtonSpecific}
				>
					<GuideIcon />
				</span>
			</CustomTooltip>
			<UnbrandedCard>
				<div className={styles.mainWrapper} style={{marginTop: "0px"}}>
					<div className={styles.Wrapper}>
						<div className={`${styles.lableContainer} shibbolethguideme`} >
							<span className={styles.labelText}>{allResourcesToTranslations.ssosetupguides}</span>
						</div>
						<div className={styles.inputContainer}>
							<span className={styles.labelTextitalic}>{allResourcesToTranslations.ssosetupguidesnotes}</span>
						</div>
						<div className={styles.inputContainer}>
							{/*<FileDownloadIcon /> */}
							<CustomTooltip displayText={[allResourcesToTranslations.oktassosetupguides]}>
								<Button onClick={() => { handleDownloadSetupguides('okta'); }}
									className="oktassosetupguides">
									<SSOOktaIcon></SSOOktaIcon>
								</Button>
							</CustomTooltip>
							<CustomTooltip displayText={[allResourcesToTranslations.azureadssosetupguides]}>
								<Button onClick={() => { handleDownloadSetupguides('azuread'); }}
									className="azureadssosetupguides">
									<SSOAzureIcon></SSOAzureIcon>
								</Button>
							</CustomTooltip>
							<CustomTooltip displayText={[allResourcesToTranslations.adfsssosetupguides]}>
								<Button onClick={() => { handleDownloadSetupguides('adfs'); }}
									className="adfsssosetupguides">
									<SSOMSIcon></SSOMSIcon>
								</Button>
							</CustomTooltip>							
							<CustomTooltip displayText={[allResourcesToTranslations.googlessosetupguides]}>
								<Button onClick={() => { handleDownloadSetupguides('google'); }}
									className="googlessosetupguides">
									<SSOGoogleIcon></SSOGoogleIcon>
								</Button>
							</CustomTooltip>
						</div>
						<div className="enablesso" style={{ width: '100%', paddingLeft: '0px' }} >
							<Checkbox
								style={{ paddingLeft: '0px' }}
								size="small"
								color="primary"
								checked={enablesso}
								className={styles.checkBox}
								onClick={() => handleEnableSsoChange()}
							/>{" "}
							<span className={styles.labelText}>{allResourcesToTranslations.enablesso}</span>
						</div>
						<div className={styles.inputContainer}>
							<span className={styles.labelTextitalic}>{allResourcesToTranslations.enablessonote}</span>
						</div>
						<div className={styles.lableContainer} >
							<span className={styles.labelText}>{allResourcesToTranslations.samlconsumerendpoint}</span>
						</div>
						<div className={styles.inputContainer}>
							<span className={styles.labelTextitalic}>{allResourcesToTranslations.samlconsumerendpointnote}</span>
						</div>
						<div className={styles.inputContainer}>
							<div className="consumerEndPoint">
								<TextField
									className={styles.input}
									style={{ width: "100%", marginBottom: "15px" }}
									label={allResourcesToTranslations.url}
									name="consumerEndPointUrl"
									variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
									value={samlconsumerendpoint}
									disabled
								/>
							</div>
						</div>
						<div className={styles.lableContainer} >
							<span className={styles.labelText}>{allResourcesToTranslations.samlcertificatekey}</span>
						</div>
						<div className={styles.inputContainer}>
							<span className={styles.labelTextitalic}>{allResourcesToTranslations.samlcertificatekeynote}</span>
						</div>
						<div className={styles.inputContainer}>
							<div className="samlcertificatekey" style={{ height: "130px" }}>
								<TextField
									className={styles.input}
									name="samlcertificatekey"
									style={{ width: "100%", marginBottom: "15px" }}
									variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
									value={samlcertificatekey}
									multiline={true}
									rows={5}
									onChange={(e) => setSamlcertificatekey(e.target.value)}
								/>
							</div>
						</div>
						<div className={styles.lableContainer} >
							<span className={styles.labelText}>{allResourcesToTranslations.samlproviderendpoint}</span>
						</div>
						<div className={styles.inputContainer}>
							<span className={styles.labelTextitalic}>{allResourcesToTranslations.samlproviderendpointnote}</span>
						</div>
						<div className={styles.inputContainer}>
							<div className="samlproviderendpoint">
								<TextField
									className={styles.input}
									label={allResourcesToTranslations.url}
									name="samlproviderendpoint"
									style={{ width: "100%", marginBottom: "15px" }}
									variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
									value={samlproviderendpoint}
									onChange={(e) => setSamlproviderendpoint(e.target.value)}
								/>
							</div>
						</div>
					</div>
				</div>
			</UnbrandedCard>

			<UnbrandedCard>
				<div className={styles.mainWrapper}>
					<div className={styles.Wrapper}>
						<div className={styles.lableContainer} >
							<span className={styles.labelText}>{allResourcesToTranslations.samlcertificatekeyadmin}</span>
						</div>
						<div className={styles.inputContainer}>
							<span className={styles.labelTextitalic}>{allResourcesToTranslations.samlcertificatekeyadminnote}</span>
						</div>
						<div className={styles.inputContainer}>
							<div className="samlcertificatekeyadmin" style={{ height: "130px" }}>
								<TextField
									className={styles.input}
									name="samlcertificatekeyadmin"
									style={{ width: "100%", marginBottom: "15px" }}
									variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
									value={samlcertificatekeyadmin}
									multiline={true}
									rows={5}
									onChange={(e) => setSamlCertificatekeyadmin(e.target.value)}
								/>
							</div>
						</div>
						<div className={styles.lableContainer} >
							<span className={styles.labelText}>{allResourcesToTranslations.samlproviderendpointadmin}</span>
						</div>
						<div className={styles.inputContainer}>
							<span className={styles.labelTextitalic}>{allResourcesToTranslations.samlproviderendpointadminnote}</span>
						</div>
						<div className={styles.inputContainer}>
							<div className="samlproviderendpointadmin">
								<TextField
									className={styles.input}
									label={allResourcesToTranslations.url}
									name="samlproviderendpointadmin"
									style={{ width: "100%", marginBottom: "15px" }}
									variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
									value={samlproviderendpointadmin}
									onChange={(e) => setSamlproviderendpointadmin(e.target.value)}
								/>
							</div>
						</div>
					</div>
				</div>
			</UnbrandedCard>
			<div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", width: "100%", paddingTop: "10px" }}>
				<CustomTooltip displayText={[allResourcesToTranslations.savessodetails]}>
					<span className="saveData" style={{ marginRight: "-5px" }}>
						<Button
							disabled={samlcertificatekey.trim() === "" || samlproviderendpoint.trim() === "" || (samlcertificatekeyadmin.trim() !== "" && samlproviderendpointadmin.trim() === "")}
							onClick={handleSave}
							variant="contained"
							color="primary"
							className="save"
						>
							<SaveIcon />
						</Button>
					</span>
				</CustomTooltip>
				<div className={styles.loaderContainer}>
					<CircularProgress
						style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
						color="primary"
						size="10"
					/>
				</div>
			</div>
			{showModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}

		</>
	);
};

export default SingleSignOn;
