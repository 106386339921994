import React, { useState } from "react";
import CloseIcon from '@material-ui/icons/Close';

//Styles
import styles from "../styles/currentAdminReport.module.css";

interface CommonPopupModalForResultProps {
    saveSuccess : boolean;
    hasErrored : boolean;
    titleText: string;
    bodyText: string;
    handleClose: any;
}

const CommonPopupModalForResult = (props:CommonPopupModalForResultProps) => {

    const { saveSuccess, hasErrored, titleText, bodyText, handleClose } = props;
    const renderBodyText = () => {
      var htmlBodyText = !!bodyText ? bodyText.split(';').map(function (message, index) {
        return <span key={index} >{ message } <br></br></span>;
        }) : "Unknown";
        
        return <div className={styles.textContent}>{htmlBodyText}</div>;
      };
    return  (
        <div className={styles.resultCard}>
           <div className={styles.modalTitleContainer}>
              <h3 className={saveSuccess ? styles.savedContent : (hasErrored ? styles.errorContent : styles.warningContent)}>{titleText}</h3>
           <div className={styles.exitButtonContainer}> <CloseIcon onClick={handleClose} fontSize="small"/></div>
        </div>
        {renderBodyText()}
          {saveSuccess ? (
            <div
              className={styles.colorBar}
              style={{ backgroundColor: "rgb(75, 202, 129)" }}
            />
          ) : hasErrored ? (
            <div
              className={styles.colorBar}
              style={{ backgroundColor: "#ff0000" }}
            />
          ) : (
            <div
              className={styles.colorBar}
              style={{ backgroundColor: "#F2B05C" }}
            />
          )}
        </div>
      )
}

export default CommonPopupModalForResult;