function generateGradientCss(baseColor){

    function hexToRgb(hex) {
        var bigint = parseInt(hex, 16);
        var r = (bigint >> 16) & 255;
        var g = (bigint >> 8) & 255;
        var b = bigint & 255;
        return { R: r, G: g, B: b };
      }

    function generateGradient(hexValue) {
        const range = 55; // This changes how dark the gradient will be
        const color = hexToRgb(hexValue)
        let colorDark = { R: 0, G: 0, B: 0 }
        
        if ((color.R - range) > 0) colorDark.R = color.R - range
        if ((color.G - range) > 0) colorDark.G = color.G - range;
        if ((color.B - range) > 0) colorDark.B = color.B - range;
     
        let colorOutputString = `rgb(${color.R},${color.G},${color.B})`
        let colorDarkOutputString = `rgb(${colorDark.R},${colorDark.G},${colorDark.B})`
        
        return `linear-gradient(-90deg, ${colorOutputString} 0%, ${colorDarkOutputString} 100%)`
     }

     return generateGradient(baseColor.substring(1));
}

export default generateGradientCss;