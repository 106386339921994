import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import  TooltipLectora  from '../CustomIcons/MyTrainingTooltipLectora';
import  TooltipRise360  from '../CustomIcons/MyTrainingTooltipRise360';
import  TooltipSA  from '../CustomIcons/MyTrainingTooltipSA';
import  TooltipStoryLine  from '../CustomIcons/MyTrainingTooltipStoryLine';

export interface Props {
 children: any;
 displayText: Array<string>; //Each item in the array is a new line in the modal
 placement?: "top" | "bottom" | "left" | "right";
 hidden?: boolean
}

export const CustomTooltip  = ({children, displayText, placement, hidden}: Props): JSX.Element => {

    const StyledTooltip = withStyles(() => ({
        tooltip: {
            backgroundColor: "#21394f",
            boxShadow: "0px 1px 20px 1px rgba(0,0,0,0.46)",
            maxWidth: "none",
            fontSize: "14px",
            border: "1px solid #21394f",
            color: "#fff",
            textAlign: "left" 
        },
        arrow: {
            fontSize: 20,
            color: "#4A4A4A",
            "&::before": {
              backgroundColor: "#21394f",
            }
          }
      }))(Tooltip);

      const createHtmlMessage = (dt:any) => {
        return {
           __html: dt };
     };

      const generateDisplayText = () => ( //We are generating a new line for each of the array items in the tool tip
        displayText.map((dt, i) => (  
        // <div key={`line-${i}`}>{dt}</div> 
        (dt !== undefined && dt !== null) ?  
        dt.toLowerCase().includes('coursepublishtype' + 'lectora')    ? <><div style={{ paddingTop: "5px", textAlign:"center", paddingBottom: "5px"}}><TooltipLectora></TooltipLectora></div></>  
        : dt.toLowerCase().includes('coursepublishtype' +'rise360')   ? <><div style={{ paddingTop: "5px", textAlign:"center", paddingBottom: "5px"}}><TooltipRise360></TooltipRise360></div></> 
        : dt.toLowerCase().includes('coursepublishtype' +'sa')        ? <><div style={{ paddingTop: "5px", textAlign:"center", paddingBottom: "5px"}}><TooltipSA></TooltipSA></div></> 
        : dt.toLowerCase().includes('coursepublishtype' +'storyline') ? <><div style={{ paddingTop: "5px", textAlign:"center", paddingBottom: "5px"}}><TooltipStoryLine></TooltipStoryLine></div></> 
        : dt.toLowerCase().includes('<br></br>') ? <><div dangerouslySetInnerHTML={createHtmlMessage(dt)}></div></> 
        :  <div key={`line-${i}`} style={{ textAlign:"center"}}>{dt}</div>  
        :<div key={`line-${i}`}>{dt}</div>
        ))
      )

  return (hidden ? (<></>) :
  (
    <StyledTooltip title={ generateDisplayText() } placement={ placement? placement : "top"} arrow>
      { children }
    </StyledTooltip>
  ));
}

export default CustomTooltip
