import axios from "axios";
import unicodeToAscii from '../functions/unicodeToAscii'
import { detect } from 'detect-browser'
import { UIContext } from "../contexts/UIContext";

const browser = detect()

enum ErrorLevel {
    High = 1,
    Medium = 2,
    Low = 3,
    Warning = 4,
    Information = 5,
    Activity = 6
}

export const addError = async (token: string, userId: number, orgDomain: string, shortMessage: string, responseObject: any, 
  errLocation: string, errorLevel: ErrorLevel, enableActivityLog: boolean = false, 
  isResObjString: boolean = false, selectedOrgID: number = -1, selectedOrgDomainName: string = "", selectOrgSiteName: string = "") => {
  try {
    let messageResponse = "- no message";
    let stackTraceResponse = "- no stack";
    if (responseObject !== null) {
      messageResponse = isResObjString ? responseObject : responseObject?.request?.response.split('\n')[0]; //Taking just the first line of the error message
      //stackTraceResponse = isResObjString ? errLocation : responseObject?.data?.substring(0, 7500) //8000 char limit
      stackTraceResponse = errLocation + ' ' + messageResponse?.substring(0, 7500) ;
    }

    // const stackTraceAscii = unicodeToAscii(`[${browser?.name}-${browser?.version}][${window.screen?.width}x${window.screen?.height}] ${stackTraceResponse}`)
    // const messageAscii = unicodeToAscii(messageResponse)

    let str = `[${browser?.name}-${browser?.version}][${window.screen?.width}x${window.screen?.height}] ${stackTraceResponse}`;
    let stackTraceAscii = '';
    let messageAscii = '';
    try
    {
        var result = '';
        var code = str.match(/&#(d+);/g);

        if (code === null) {
          result = str;
        } else {

          for (var i = 0; i < code.length; i++) {
              //result += String.fromCharCode(code[i].replace(/[&#;]/g, ''));
              result += code[i].replace(/[&#;]/g, '');
          }
        }
    }
    catch (error: any)
    {
        result = str;
    }
    stackTraceAscii = result;
    str = messageResponse;
    try
    {
        var result = '';
        var code = str.match(/&#(d+);/g);

        if (code === null) {
          result = str;
        }
        else {
          for (var i = 0; i < code.length; i++) {
              //result += String.fromCharCode(code[i].replace(/[&#;]/g, ''));
              result += code[i].replace(/[&#;]/g, '');
          }
        }
    }
    catch (error: any)
    {
        result = str;
    }
    messageAscii = result;

    if(enableActivityLog && errorLevel == 6) {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/log/add`,
        headers: {
          "Content-Type": "application/json",
          'Authorization' : `Bearer ${token}`
        },
        
        data: {
          Module: errLocation,
          Code: shortMessage,
          Message: messageAscii,
          StackTrace: stackTraceAscii,
          ErrorLevel: errorLevel,
          ErrorStatus: 1,
          Resolution: "Resolution",
          UserID: userId,
          OrgDomain: orgDomain,
          SelectedOrgID: selectedOrgID,
          SelectedOrgDomainName: selectedOrgDomainName, 
          SelectOrgSiteName: selectOrgSiteName
        },
      });
      const data = await response.data;
      return data;
    } else {
      return '';
    }
  } catch (error: any) {    
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      if (error.response) {
        console.error("Error Logging Failed")
        console.log(error.response);
      } else if (error.request) {
        console.error("Error Logging Failed")
        console.log(error.request);
      } else if (error.message) {
        console.error("Error Logging Failed")
        console.log(error.message);
      }
      return null;
    }
  }
};

export default addError;
