import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const UnSelectAllIcon = () => (
    <svg  width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_9:34)">
<path fillRule="evenodd" clipRule="evenodd" d="M24 4.88232V23.0004H5.88194V4.88232H24ZM8.47024 7.47061H21.4117V20.4121H8.47024V7.47061Z" fill="#FFFFFF"/>
<path d="M4.58843 3.58791H20.1182V0.999615H2.00014V19.1177H4.58843V3.58791Z" fill="#FFFFFF"/>
<rect x="11.9876" y="11.7551" width="1.05055" height="7.2341" transform="rotate(-45 11.9876 11.7551)" fill="#FFFFFF"/>
<rect x="17.1029" y="11.0123" width="1.05055" height="7.2341" transform="rotate(45 17.1029 11.0123)" fill="#FFFFFF"/>
</g>
<defs>
<clipPath id="clip0_9:34">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
);

export default UnSelectAllIcon
