import React from 'react'
import CustomTooltip from '../../Components/CustomTooltip';
import WrapTextIcon from '@material-ui/icons/WrapText';
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import SelectAllIcon from '../../CustomIcons/SelectAllIcon';
import UnSelectAllIcon from '../../CustomIcons/UnSelectAllIcon';

import styles from '../../styles/currentAdminReport.module.css'

interface Props {
    selectAllCourses: Function
    deselectAllCourses: Function
}

const CourseFilterButtons = ({ selectAllCourses, deselectAllCourses }: Props) => {


    const handleSelectAll = () => {
        selectAllCourses()
    }

    const handleDeselect = () => {
        deselectAllCourses()
    }

    return (
        <>
            <ButtonGroup variant="contained" color="primary" aria-label="Filter">
                <Button 
                    className={styles.button}
                    size="small"
                    onClick={handleSelectAll}
                    >
                    <SelectAllIcon />
                        <span style={{marginLeft:'5px',fontSize:'12px'}}>{` Select All` /*TODO Add translation */}</span>
                </Button>
                <Button 
                    className={styles.button} 
                    size="small"
                    onClick={handleDeselect}
                    >
                    <UnSelectAllIcon />
                        <span style={{marginLeft:'5px',fontSize:'12px'}}>{` Unselect All` /*TODO Add translation */}</span>
                </Button>
            </ButtonGroup>
        </>
    )
}

export default CourseFilterButtons