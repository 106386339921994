//React + Typescript
import React, { useContext, useEffect, useState, useRef } from 'react';

import { OrgSupportDetailsItem } from '../../globalTypes';
// Libsimport
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';
//Intro Lib
import { Steps, Hints } from "intro.js-react";
//API Functions 
import { addError } from '../../API/AddError'
import { getOrgSupportDetails } from '../../API/GetOrgSupportDetails';
import { addOrUpdateOrgSupportDetails } from '../../API/AddOrUpdateOrgSupportDetails';



//General Functions
import getColor from '../../functions/getColor';
import validateEmail from '../../functions/valudateEmail';
//Components
import CustomTooltip from '../../Components/CustomTooltip';
import UnbrandedCard from '../../Components/UnbrandedCard';
import LoaderContainer from '../../Components/LoaderContainer';
import GuideIcon from '../../CustomIcons/GuideIcon';
//MUI Components
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';

import CachedIcon from '@material-ui/icons/Cached';
import EditIcon from '../../CustomIcons/SupportDetailsEditIcon';
import ReverttIcon from '../../CustomIcons/SupportDetailsReverttIcon';
import SaveIcon from '../../CustomIcons/SupportDetailSaveIcon';
import CancelIcon from '../../CustomIcons/SupportDetailCancelIcon';
//Styles
import styles from '../../styles/suppoerDetails.module.css'
import moment from 'moment';
import BrandedCard from '../../Components/BrandedCard';
import CommonPopupModalForResult from '../../Components/CommonPopupModalForResult';

const SupportDetails = (props: any): JSX.Element => {
  const { translations: { allResourcesToTranslations, currentPageLang }, pageTitles } = useContext(TranslationsContext)
  const { color } = useContext(CorperateThemeContext).theme;
  const { setMessage } = useContext(LoaderContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection, EnableActivityLogs, setShowScrollArrow } = useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { oidcUser } = useReactOidc();

  const [defaultEmailAddress, setdefaultEmailAddress] = useState<string>("")
  const [defaultPhoneNumber, setdefaultPhoneNumber] = useState<string>("")
  const [defaultReplyToAddress, setdefaultReplyToAddress] = useState<string>("")

  const [emailAddress, setemailAddress] = useState<string>("")
  const [phoneNumber, setphoneNumber] = useState<string>("")
  const [replyToAddress, setreplyToAddress] = useState<string>("")


  const [emailAddressUpdated, setemailAddressUpdated] = useState<string>("")
  const [phoneNumberUpdated, setphoneNumberUpdated] = useState<string>("")
  const [replyToAddressUpdated, setreplyToAddressUpdated] = useState<string>("")

  const [isSaving, setIsSaving] = useState<boolean>(false);


  const [isEmailEdit, setEmailEdit] = React.useState(false);
  const [isPhoneEdit, setPhoneEdit] = React.useState(false);
  const [isReplyEdit, setReplyEdit] = React.useState(false);


  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);

  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  const language = useRef<string>("en-AU");
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".email",
        title: "Support Email Address",
        intro: "Specify an email Address, this email address will present on the login page when the Support button is clicked and the Support modal is shown, and when Contact Support is clicked on a Account's Dashboard.<br><br>You may specify multiple email addresses, by separating them with semi-colons ';' e.g. example1@email.com;example2@email.com"

      },

    ]
  })

  useEffect(() => {
    let updateSteps = [...intro.steps]
    updateSteps.push(
      {
        element: ".phone",
        title: "Support Phone Number",
        intro: "Specify a Phone Number or contact details, this will present on the login page when the support button is clicked and the Support modal is shown, and also via the Support modal in the front-end account menu."
      },
      {
        element: ".replyTo",
        title: "Support Reply To",
        intro: "Specify an email address, this email address will present as the Reply-to address for all Salt-generated system emails. When a user replies to any Salt-generated email, emails will be sent to this Reply-to address.<br><br>You may specify multiple email addresses, by separating them with semi-colons ';' e.g. example1@email.com;example2@email.com"
      }
    )

    setIntro({ ...intro, steps: [...updateSteps] })

  }, [])

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  useEffect(() => {
    setTitle(allResourcesToTranslations.supportdetails)
    setTitleEnglish(pageTitles.customsupportdetails)
    updateBackButtonRoute("");
    setMenuSelection("Support Details")
    getOrgSupportDetails(oidcUser.access_token, userId, orgDomain, !orgId ? -1 : parseInt(orgId)).then(dataResult => {
      setemailAddress(dataResult.supportDetails.emailAddress=="" ? dataResult.supportDetails.defaultEmailAddress : dataResult.supportDetails.emailAddress );
      setphoneNumber(dataResult.supportDetails.phoneNumber=="" ? dataResult.supportDetails.defaultPhoneNumber : dataResult.supportDetails.phoneNumber );
      setreplyToAddress(dataResult.supportDetails.replyToAddress=="" ? dataResult.supportDetails.defaultReplyToAddress : dataResult.supportDetails.replyToAddress );
      setdefaultEmailAddress(dataResult.supportDetails.defaultEmailAddress);
      setdefaultPhoneNumber(dataResult.supportDetails.defaultPhoneNumber);
      setdefaultReplyToAddress(dataResult.supportDetails.defaultReplyToAddress);
      setLoading(false);
      setIsSaving(true);
    }).catch((error: any) => {
      addError(oidcUser.access_token, userId, orgDomain, "SupportModel getOrgSupportDetails in NEWSALT ClientApp", null, "SupportModel.tsx", 6, EnableActivityLogs);
    });
    return () => {

      setShowScrollArrow(false)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleonChange = (event: any, title: string) => {

    setIsSaving(false);    
    if (title == "email")   
    {       
      setemailAddressUpdated(event.target.value)
    }

    if (title == "phone")
    { 
      setphoneNumberUpdated(event.target.value)
    }

    if (title == "replyto")
    {     
      setreplyToAddressUpdated(event.target.value)
    }
  }
  const handleEdit = (title: any) => {
    setIsSaving(false); 

      if (title == "email") {
        setEmailEdit(true);
        setemailAddress(emailAddress)
        setemailAddressUpdated(emailAddress)
      }
  
      if (title == "phone") {
        setPhoneEdit(true);
        setphoneNumber(phoneNumber)
        setphoneNumberUpdated(phoneNumber)
      }
  
      if (title == "replyto") {
        setReplyEdit(true);
        setreplyToAddress(replyToAddress)
        setreplyToAddressUpdated(replyToAddress)
      } 

  };
  const handleCancel = (title: any) => {
    setIsSaving(true);

    if (title == "email") {
      setEmailEdit(false);
      setemailAddress(emailAddress)
      setemailAddressUpdated(emailAddress)
    }

    if (title == "phone") {
      setPhoneEdit(false);
      setphoneNumber(phoneNumber)
      setphoneNumberUpdated(phoneNumber)
    }

    if (title == "replyto") {
      setReplyEdit(false);
      setreplyToAddress(replyToAddress)
      setreplyToAddressUpdated(replyToAddress)
    }


  };
  const handleRevert = (title: any) => {

    setIsSaving(false);

    if (title == "email")
      setemailAddress(defaultEmailAddress);
      setemailAddressUpdated("");

    if (title == "phone")
      setphoneNumber(defaultPhoneNumber);
      setphoneNumberUpdated("");


    if (title == "replyto")
      setreplyToAddress(defaultReplyToAddress);
      setreplyToAddressUpdated("");


  };
   


  const handleSaveemail = () => {
    let str = emailAddressUpdated;  
    let isvalidemail = true;

    {str.split(';').map((value, index) => {     
      if (!validateEmail(value.trim())) {           
        setShowWarningModal(true);
        setModalPopupMessage('Please enter valid support email.');
        setHasErrored(true);
        isvalidemail= false;
        return;
      }    
    })}

    if(isvalidemail)
    {
        addOrUpdateOrgSupportDetails(oidcUser.access_token, userId, orgDomain, !orgId ? -1 : parseInt(orgId),
        emailAddressUpdated.trim() == "" ? (emailAddress== "" ? defaultEmailAddress : emailAddress) : emailAddressUpdated.trim(),
        phoneNumber ,replyToAddress
        ).then((response: any) => {
        if (response !== undefined && response !== null && response.isSuccess === true) {
          setemailAddress(response.supportDetails[0].emailAddress);
          setphoneNumber(response.supportDetails[0].phoneNumber);
          setreplyToAddress(response.supportDetails[0].replyToAddress);
  
          setEmailEdit(false);
          setShowWarningModal(true);
          setModalPopupMessage('Support email address updated successfully!');
          setSaveSuccess(true);
          setHasErrored(false);
          setIsSaving(true);

        }
        else {
          setEmailEdit(false);       
          setIsSaving(true);
          setShowWarningModal(true);
          setModalPopupMessage('Unable to save support email address!');
          setHasErrored(true);
          setIsSaving(false);
          return;
        }
        }).catch((error: any) => {

          const errorVal = (error !== undefined && error !== null) ? error : "Saving report scheduler settings failed as exception"
        });

      }

  };

  const handleSavephone = () => {
 
     addOrUpdateOrgSupportDetails(oidcUser.access_token, userId, orgDomain, !orgId ? -1 : parseInt(orgId),
       emailAddress,
       phoneNumberUpdated.trim() == "" ? (phoneNumber =="" ? defaultPhoneNumber : phoneNumber) : phoneNumberUpdated.trim(),
       replyToAddress
 
     ).then((response: any) => {
       if (response !== undefined && response !== null && response.isSuccess === true) {
     
        setemailAddress(response.supportDetails[0].emailAddress);
        setphoneNumber(response.supportDetails[0].phoneNumber);
        setreplyToAddress(response.supportDetails[0].replyToAddress);
        
         setPhoneEdit(false);      
         setShowWarningModal(true);
         setModalPopupMessage('Support phone number updated successfully!');
         setSaveSuccess(true);
         setHasErrored(false);
         setIsSaving(true);
 
       }
       else {
         setPhoneEdit(false);       
         setIsSaving(true);
         setShowWarningModal(true);
         setModalPopupMessage('Unable to save support phone number!');
         setHasErrored(true);
         setIsSaving(false);
         return;
       }
     }).catch((error: any) => { 
       const errorVal = (error !== undefined && error !== null) ? error : "Saving report scheduler settings failed as exception"
     });
  };

   const handleSavereplyTo = () => {
    let str = replyToAddressUpdated ==""  ? replyToAddress : replyToAddressUpdated ;   
    let isvalidemail = true;


    {str.split(';').map((value, index) => {     
      if (!validateEmail(value.trim())) {           
        setShowWarningModal(true);
        setModalPopupMessage('Please enter valid support reply to.');
        setHasErrored(true);
        isvalidemail= false;
        return;
      }    
    })}

    if(isvalidemail)
    {
        addOrUpdateOrgSupportDetails(oidcUser.access_token, userId, orgDomain, !orgId ? -1 : parseInt(orgId),
        emailAddress,phoneNumber,
        replyToAddressUpdated.trim() == "" ? (replyToAddress =="" ? defaultReplyToAddress : replyToAddress) : replyToAddressUpdated.trim()
        ).then((response: any) => {
        if (response !== undefined && response !== null && response.isSuccess === true) {       
          setemailAddress(response.supportDetails[0].emailAddress);
        setphoneNumber(response.supportDetails[0].phoneNumber);
        setreplyToAddress(response.supportDetails[0].replyToAddress);
     
          setReplyEdit(false); 
          setShowWarningModal(true);
          setModalPopupMessage('Support reply to details updated successfully!');
          setSaveSuccess(true);
          setHasErrored(false);
          setIsSaving(true); 
        }
        else {
          setReplyEdit(false);
          setIsSaving(true);
          setShowWarningModal(true);
          setModalPopupMessage('Unable to save support reply toails!');
          setHasErrored(true);
          setIsSaving(false);
          return;
        }
      }).catch((error: any) => {

        const errorVal = (error !== undefined && error !== null) ? error : "Saving report scheduler settings failed as exception"
      });

    

    }

  };
  

  const handleSubmit = () => {
    let isvalidemail = true; 
    let stremailAddress =  emailAddressUpdated.trim() == "" ? emailAddress : emailAddressUpdated.trim();
    {stremailAddress.split(';').map((value, index) => {     
      if (!validateEmail(value.trim())) {           
        setShowWarningModal(true);
        setModalPopupMessage('Please enter valid support email.');
        setHasErrored(true);
        isvalidemail= false;
        return;
      }    
    })}

    let strreplyToAddress =  replyToAddressUpdated.trim() == "" ? replyToAddress : replyToAddressUpdated.trim();
    {strreplyToAddress.split(';').map((value, index) => {     
      if (!validateEmail(value.trim())) {           
        setShowWarningModal(true);
        setModalPopupMessage('Please enter valid support reply to.');
        setHasErrored(true);
        isvalidemail= false;
        return;
      }    
    })}

 if(isvalidemail)
 {
    addOrUpdateOrgSupportDetails(oidcUser.access_token, userId, orgDomain, !orgId ? -1 : parseInt(orgId),
    emailAddressUpdated.trim() == "" ? (emailAddress== "" ? defaultEmailAddress : emailAddress) : emailAddressUpdated.trim(),
    phoneNumberUpdated.trim() == "" ? (phoneNumber =="" ? defaultPhoneNumber : phoneNumber) : phoneNumberUpdated.trim(),
    replyToAddressUpdated.trim() == "" ? (replyToAddress =="" ? defaultReplyToAddress : replyToAddress) : replyToAddressUpdated.trim()

    ).then((response: any) => {
      if (response !== undefined && response !== null && response.isSuccess === true) {
        setemailAddress(response.supportDetails[0].emailAddress);
        setphoneNumber(response.supportDetails[0].phoneNumber);
        setreplyToAddress(response.supportDetails[0].replyToAddress);
        setEmailEdit(false);
        setPhoneEdit(false);
        setReplyEdit(false);
        setIsSaving(true);
        setShowWarningModal(true);
        setModalPopupMessage('Support details updated successfully!');
        setSaveSuccess(true);
        setHasErrored(false);

      }
      else {
        setEmailEdit(false);
        setPhoneEdit(false);
        setReplyEdit(false);
        setIsSaving(true);
        setModalPopupMessage('Unable to save Support details!');
        setHasErrored(true);
      }
    }).catch((error: any) => {
      const errorVal = (error !== undefined && error !== null) ? error : "Saving report scheduler settings failed as exception"

    });
  }

  }

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  useEffect(() => {
    if (showWarningModal) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      }, 5000)
    }
  }, [showWarningModal])


  if (loading) {
    return <LoaderContainer />
  }

  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: 'Next',
          prevLabel: 'Back',
          tooltipClass: 'sample-class',
          showBullets: true,
        }}
      />
      <div className={styles.exportBtnContainer}>
        <CustomTooltip displayText={["Guide Me"]}>
          <span onClick={() => setIntro({ ...intro, stepsEnabled: true })} className={styles.guideMeButtonSupport}>
            <GuideIcon />
          </span>
        </CustomTooltip>
      </div>
      <div style={{ clear: 'both' }}>
        <BrandedCard>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={styles.columnDesc}  style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0", fontFamily: `${language.current}, sans-serif` }} align="left">
                  <span>Description</span>
                </TableCell>
                <TableCell className={styles.columnValue}  style={{ color: getColor(color.substring(1)), backgroundColor: color, fontFamily: `${language.current}, sans-serif` }} align="left">
                  <span>Value</span>
                </TableCell>
                <TableCell className={styles.columnAction}  style={{ color: getColor(color.substring(1)), backgroundColor: color, fontFamily: `${language.current}, sans-serif` }} align="left">
                  <span>Action</span>
                </TableCell>
                <TableCell className={styles.columnRevert}  style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }} align="left">
                  Revert to default
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmailEdit ? (
                <TableRow style={{ backgroundColor: "#eee" }}>
                  <TableCell align="left" className={styles.columnDesc}>
                    Support Email Address
                  </TableCell>
                  <TableCell className={styles.columnValue}>
                    <div className="email" style={{width:"100%"}}> {emailAddress == "" ? defaultEmailAddress : emailAddress}</div>
                  </TableCell>
                  <TableCell className={styles.columnAction}>
                    <CustomTooltip displayText={["Edit"]}>
                      <Button onClick={() => handleEdit('email')} className={styles.moduleListButton} variant="contained" color="primary">
                        <EditIcon />
                      </Button>
                    </CustomTooltip>
                  </TableCell>
                  <TableCell className={styles.columnRevert}>
                    <CustomTooltip displayText={["Revert to default"]}>
                      <Button onClick={() => handleRevert("email")} className={styles.moduleListButton} variant="contained" color="primary" disabled={emailAddress==defaultEmailAddress}>
                        <ReverttIcon />
                      </Button>
                    </CustomTooltip>
                  </TableCell>
                </TableRow>
              ) :
                (
                  <TableRow style={{ backgroundColor: "#eee" }}>
                    <TableCell align="left" className={styles.columnDesc}>
                      Support Email Address
                    </TableCell>
                    <TableCell className={styles.columnValue}>
                       <div className="email" style={{width:"100%"}}>  <TextField name="emailAddress" className={styles.inputmidsize} label={"Support Email Address"} variant="outlined"
                        defaultValue={emailAddress == "" ? defaultEmailAddress : emailAddress} autoComplete="off"
                        onChange={(e) => { handleonChange(e, 'email') }} InputLabelProps={{ shrink: true, }} />
                        </div>
                    </TableCell>
                    <TableCell className={styles.columnAction}>
                      <CustomTooltip displayText={["Save"]}>
                        <Button onClick={() => handleSaveemail()} className={styles.moduleListButton} variant="contained" color="primary">
                          <SaveIcon />
                        </Button>
                      </CustomTooltip> &nbsp;&nbsp;
                      <CustomTooltip displayText={["Cancel"]}>
                        <Button onClick={() => handleCancel('email')} className={styles.moduleListButton} variant="contained" color="primary">
                          <CancelIcon />
                        </Button>
                      </CustomTooltip>
                    </TableCell>
                    <TableCell className={styles.columnRevert}>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </TableCell>
                  </TableRow>
                )
              }
              {!isPhoneEdit ? (
                <TableRow >
                  <TableCell align="left" className={styles.columnDesc}>
                    Support Phone Number
                  </TableCell>
                  <TableCell className={styles.columnValue}>
                  <div className="phone" style={{width:"100%"}}> {phoneNumber == "" ? defaultPhoneNumber : phoneNumber}</div>
                  </TableCell>
                  <TableCell className={styles.columnAction}>
                    <CustomTooltip displayText={["Edit"]}>
                      <Button onClick={() => handleEdit('phone')} className={styles.moduleListButton} variant="contained" color="primary">
                        <EditIcon />
                      </Button>
                    </CustomTooltip>
                  </TableCell>
                  <TableCell className={styles.columnRevert}>
                    <CustomTooltip displayText={["Revert to default"]}>
                      <Button onClick={() => handleRevert("phone")} className={styles.moduleListButton} variant="contained" color="primary" disabled={phoneNumber==defaultPhoneNumber}>
                        <ReverttIcon />
                      </Button>
                    </CustomTooltip>
                  </TableCell>
                </TableRow>
              ) :
                (
                  <TableRow >
                    <TableCell align="left" className={styles.columnDesc}>
                      Support Phone Number
                    </TableCell>
                    <TableCell className={styles.columnValue}>
                    <div className="phone" style={{width:"100%"}}>
                      <TextField name="emailAddress" className={styles.inputmidsize} label={"Support Phone Number"} variant="outlined"
                        defaultValue={phoneNumber == "" ? defaultPhoneNumber : phoneNumber}
                        autoComplete="off" onChange={(e) => { handleonChange(e, 'phone') }} InputLabelProps={{ shrink: true, }} />
                        </div>
                    </TableCell>
                    <TableCell className={styles.columnAction}>
                      <CustomTooltip displayText={["Save"]}>
                        <Button onClick={() => handleSavephone()} className={styles.moduleListButton} variant="contained" color="primary">
                          <SaveIcon />
                        </Button>
                      </CustomTooltip> &nbsp;&nbsp;
                      <CustomTooltip displayText={["Cancel"]}>
                        <Button onClick={() => handleCancel('phone')} className={styles.moduleListButton} variant="contained" color="primary">
                          <CancelIcon />
                        </Button>
                      </CustomTooltip>
                    </TableCell>
                    <TableCell className={styles.columnRevert}>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </TableCell>
                  </TableRow>
                )
              }
              {!isReplyEdit ? (
                <TableRow style={{ backgroundColor: "#eee" }}>
                  <TableCell align="left" className={styles.columnDesc}>
                    Support Reply To
                  </TableCell>
                  <TableCell className={styles.columnValue}>
                    <div className="replyTo" style={{width:"100%"}}>{replyToAddress ==  "" ? defaultReplyToAddress : replyToAddress}</div>
                  </TableCell>
                  <TableCell className={styles.columnAction}>
                    <CustomTooltip displayText={["Edit"]}>
                      <Button onClick={() => handleEdit('replyto')} className={styles.moduleListButton} variant="contained" color="primary">
                        <EditIcon />
                      </Button>
                    </CustomTooltip>
                  </TableCell>
                  <TableCell className={styles.columnRevert}>
                    <CustomTooltip displayText={["Revert to default"]}>
                      <Button onClick={() => handleRevert("replyto")} className={styles.moduleListButton} variant="contained" color="primary" disabled={replyToAddress==defaultReplyToAddress}>
                        <ReverttIcon />
                      </Button>
                    </CustomTooltip>
                  </TableCell>
                </TableRow>
              ) :
                (
                  <TableRow style={{ backgroundColor: "#eee" }}>
                    <TableCell align="left" className={styles.columnDesc}>
                      Support Reply To
                    </TableCell>
                    <TableCell className={styles.columnValue}>
                    <div className="replyTo" style={{width:"100%"}}>
                       <TextField name="RepltToAddress" className={styles.inputmidsize} label={"Support Reply To"} variant="outlined"
                        defaultValue={replyToAddress == "" ? defaultReplyToAddress : replyToAddress}
                        autoComplete="off" onChange={(e) => { handleonChange(e, 'replyto') }} InputLabelProps={{ shrink: true, }} />
                        </div>
                    </TableCell>
                    <TableCell className={styles.columnAction}>
                      <CustomTooltip displayText={["Save"]}>
                        <Button onClick={() => handleSavereplyTo()} className={styles.moduleListButton} variant="contained" color="primary" >
                          <SaveIcon />
                        </Button>
                      </CustomTooltip> &nbsp;&nbsp;
                      <CustomTooltip displayText={["Cancel"]}>
                        <Button onClick={() => handleCancel('replyto')} className={styles.moduleListButton} variant="contained" color="primary">
                          <CancelIcon />
                        </Button>
                      </CustomTooltip>
                    </TableCell>
                    <TableCell className={styles.columnRevert}>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </TableCell>
                  </TableRow>
                )
              }


            </TableBody>
          </Table>

          <div style={{ width: '100%' }}>
            <div style={{ width: '100%', marginTop: '10px' }}>
              <div className={styles.saveButtonContainer} style={{ float: 'right' }}>
                <CustomTooltip displayText={["Save"]}>
                  <span>
                    <Button onClick={() => { handleSubmit(); }} variant="contained" color="primary" disabled={isSaving}>
                      <SaveIcon />
                    </Button>
                  </span>
                </CustomTooltip>
              </div>
            </div>
          </div>

          {/* <div style={{width: '100%',marginTop: '23px'}}>
                            <div className={styles.saveButtonContainer} style={{ float:'right'}}>      
                              <span style={{ opacity: savedMessageFlash ? "1" : "0" }} className={styles.themeUpdatedText}>
                                {savedMessage}
                              </span>  
                            </div>
                      </div>  */}
          {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
        </BrandedCard>
      </div>
    </>
  );
};
export default SupportDetails;
