// const configuration = {
//   origin: 'https://stagingdemo.saltcompliance.com',
//   client_id: 'clientapp1id',
//   client_secret: 'clientapp1secret',
//   redirect_uri: 'https://stagingdemo.saltcompliance.com:3000/authentication/callback',
//   response_type: 'code',
//   post_logout_redirect_uri: 'https://stagingdemo.saltcompliance.com:3000/',
//   scope: 'openid profile NewSaltAPIs',
//   authority: 'https://ids.saltcompliance.com',
//   silent_redirect_uri: 'https://stagingdemo.saltcompliance.com:3000/silentrefresh',
//   automaticSilentRenew: true,
//   loadUserInfo: true,
//   monitorSession: false //https://github.com/maxmantz/redux-oidc/issues/52 https://stackoverflow.com/questions/52260648/identity-server-4-check-iframe-session-issue-oidc-client
// };

const configuration = {
  origin: 'https://site6.saltcompliance.com',
  //client_id: 'clientapp2id',
  //client_secret: 'clientapp2secret',
  client_id: 'Site6_ClientID_DefaultID2',
  client_secret: 'Site6_ClientSecret_DefaultDomain2',
  redirect_uri: 'https://site6.saltcompliance.com/authentication/callback',
  response_type: 'code',
  post_logout_redirect_uri: 'https://site6.saltcompliance.com/',
  scope: 'openid profile NewSaltAPIsSite6',
  authority: 'https://idssite6.saltcompliance.com',
  silent_redirect_uri: 'https://site6.saltcompliance.com/silentrefresh',
  automaticSilentRenew: true,
  loadUserInfo: true,
  monitorSession: false, //https://github.com/maxmantz/redux-oidc/issues/52 https://stackoverflow.com/questions/52260648/identity-server-4-check-iframe-session-issue-oidc-client
  stagingdomain: 'stagingsite6.saltcompliance.com',
  stagingdomainSA: 'ads2456.saltcompliance.com',

  stagingdomainForDeactTest: 'deactivationteststaging.saltcompliance.com',
  currentdomainSA: 'ads2456.saltcompliance.com',
  
  //Site6
  site6_origin: 'http://localhost:3000',
  site6_client_id: 'Site6_ClientID_45',
  site6_client_secret: 'Site6_ClientSecret_45_devstagingsite6.saltcompliance.com',
  site6_redirect_uri: 'http://localhost:3000/authentication/callback',
  site6_response_type: 'code',
  site6_post_logout_redirect_uri: 'http://localhost:3000/',
  site6_scope: 'openid profile NewSaltAPIsSite6',
  site6_authority: 'https://localhost:5001',
  site6_silent_redirect_uri: 'http://localhost:3000/silentrefresh',
  site6_automaticSilentRenew: true,
  site6_loadUserInfo: true,
  site6_monitorSession: false,
  site6_domain: 'stagingsite6.saltcompliance.com',
  
  //Site5
  site5_origin: 'http://localhost:3000',
  site5_client_id: 'Site5_ClientID_45',
  site5_client_secret: 'Site5_ClientSecret_45_devstagingsite5.saltcompliance.com',
  site5_redirect_uri: 'http://localhost:3000/authentication/callback',
  site5_response_type: 'code',
  site5_post_logout_redirect_uri: 'http://localhost:3000/',
  site5_scope: 'openid profile NewSaltAPIsSite5',
  site5_authority: 'https://localhost:5001',
  site5_silent_redirect_uri: 'http://localhost:3000/silentrefresh',
  site5_automaticSilentRenew: true,
  site5_loadUserInfo: true,
  site5_monitorSession: false,
  site5_domain: 'stagingsite5.saltcompliance.com',  
  
  //Site4
  site4_origin: 'http://localhost:3000',
  site4_client_id: 'Site4_ClientID_45',
  site4_client_secret: 'Site4_ClientSecret_45_devstagingsite4.saltcompliance.com',
  site4_redirect_uri: 'http://localhost:3000/authentication/callback',
  site4_response_type: 'code',
  site4_post_logout_redirect_uri: 'http://localhost:3000/',
  site4_scope: 'openid profile NewSaltAPIsSite4',
  site4_authority: 'https://localhost:5001',
  site4_silent_redirect_uri: 'http://localhost:3000/silentrefresh',
  site4_automaticSilentRenew: true,
  site4_loadUserInfo: true,
  site4_monitorSession: false,
  site4_domain: 'stagingsite4.saltcompliance.com',
  
  //Site2
  site2_origin: 'http://localhost:3000',
  site2_client_id: 'Site2_ClientID_45',
  site2_client_secret: 'Site2_ClientSecret_45_devstagingsite2.saltcompliance.com',
  site2_redirect_uri: 'http://localhost:3000/authentication/callback',
  site2_response_type: 'code',
  site2_post_logout_redirect_uri: 'http://localhost:3000/',
  site2_scope: 'openid profile NewSaltAPIsSite2',
  site2_authority: 'https://localhost:5001',
  site2_silent_redirect_uri: 'http://localhost:3000/silentrefresh',
  site2_automaticSilentRenew: true,
  site2_loadUserInfo: true,
  site2_monitorSession: false,
  site2_domain: 'stagingsite2.saltcompliance.com',
  
  //SiteDemo
  siteDemo_origin: 'http://localhost:3000',
  siteDemo_client_id: 'SiteDemo_ClientID_45',
  siteDemo_client_secret: 'SiteDemo_ClientSecret_45_devstagingdemo.saltcompliance.com',
  siteDemo_redirect_uri: 'http://localhost:3000/authentication/callback',
  siteDemo_response_type: 'code',
  siteDemo_post_logout_redirect_uri: 'http://localhost:3000/',
  siteDemo_scope: 'openid profile NewSaltAPIsSiteDemo',
  siteDemo_authority: 'https://localhost:5001',
  siteDemo_silent_redirect_uri: 'http://localhost:3000/silentrefresh',
  siteDemo_automaticSilentRenew: true,
  siteDemo_loadUserInfo: true,
  siteDemo_monitorSession: false,
  siteDemo_domain: 'stagingdemo.saltcompliance.com',
  
  //Site6Local
  site6Local_origin: 'http://localhost:3000',
  site6Local_client_id: 'Site6_ClientID_45',
  site6Local_client_secret: 'Site6_ClientSecret_45_devstagingsite6.saltcompliance.com',
  site6Local_redirect_uri: 'http://localhost:3000/authentication/callback',
  site6Local_response_type: 'code',
  site6Local_post_logout_redirect_uri: 'http://localhost:3000/',
  site6Local_scope: 'openid profile NewSaltAPIsSite6',
  site6Local_authority: 'https://localhost:5001',
  site6Local_silent_redirect_uri: 'http://localhost:3000/silentrefresh',
  site6Local_automaticSilentRenew: true,
  site6Local_loadUserInfo: true,
  site6Local_monitorSession: false,
  site6Local_domain: 'stagingsite6.saltcompliance.com'
};

export default configuration;   
